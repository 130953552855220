import React from 'react'
import BoardTop from './BoardTopContainer.js'
import BoardSecond from './BoardSecondContainer.js'
import BoardState from './BoardStateContainer.js'
import BoardCharts from './BoardChartsContainer.js'
import BoardTable from './BoardTableContainer.js'
import ElecDetailBlock from './ElecDetailBlockContainer.js'

import style from './MainPage.module.scss'

export default class MainPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      create_time: null
    }
  }

  componentDidMount() {
    // this.initPage();
    // this.getPageLayout();
    window.onresize = this.resizeCallback
    // console.log(this.props);
    const { getAlarmList, getEvents, getLatestOne, getChartLayout, getHisTagListData } = this.props
    getChartLayout().then(isSucceed => {
      // console.log(isSucceed);
      if (isSucceed) {
        // 这里可以做一定的优化，因为部分getLatestData，getAlarmList，getEvents不依赖于getLatestOne
        // const { getLatestData } = this.props;
        // getLatestData()
        //     .then(isSucceed => {
        //         // console.log(isSucceed);
        //     });
        // getHisTagListData()
        //     .then(isSucceed => {
        //         // console.log(isSucceed);
        //     });;
        // getAlarmList()
        //     .then(isSucceed => {
        //         // console.log(isSucceed);
        //     });
        // getEvents()
        //     .then(isSucceed => {
        //         // console.log(isSucceed);
        //     });
        this.reloadOneResult()
      }
    })
    this.runOtherPromiseTasks()
  }

  componentWillUnmount() {
    // this.myInterval && clearInterval(this.myInterval);
    this.mySetTimeout && clearTimeout(this.mySetTimeout)
    this.myOtherSetTimeout && clearTimeout(this.myOtherSetTimeout)
    const { resetState } = this.props
    resetState()
  }

  reloadOneResult = () => {
    this.mySetTimeout && clearTimeout(this.mySetTimeout)
    const { getLatestOne } = this.props
    getLatestOne().then(isSucceed => {
      // console.log(isSucceed);
      //请求一次数据，加入10s队列
      if (isSucceed) {
        this.runPromiseTasks()
        //10s任务
        // this.myInterval && clearInterval(this.myInterval);
        // this.myInterval = setInterval(this.runPromiseTasks, 10000);
      }
    })
  }

  runOtherPromiseTasks = () => {
    this.myOtherSetTimeout && clearTimeout(this.myOtherSetTimeout)
    const { getExamDetailData } = this.props
    Promise.all([getExamDetailData()]).then(retDatas => {
      // console.log(retDatas);
      //10s任务
      this.myOtherSetTimeout = setTimeout(this.runOtherPromiseTasks, 10000)
    })
  }

  runPromiseTasks = () => {
    this.mySetTimeout && clearTimeout(this.mySetTimeout)
    const {
      getAlarmList,
      getEvents,
      getHisTagListData,
      getLastExecStatus,
      getLatestData
    } = this.props
    Promise.all([
      getLatestData(),
      getHisTagListData(),
      getLastExecStatus()
      // getAlarmList(),
      // getEvents(),
      // this.getExamDetailData(),
    ]).then(retDatas => {
      // console.log(retDatas);
      const [d1, d2, { isSuccess, result }] = retDatas
      if (isSuccess === true) {
        const { status, create_time } = result
        const { create_time: last_create_time } = this.state
        if (status == 2 || status == 3) {
          if (last_create_time === null) {
            this.setState({ create_time })
          } else if (create_time !== last_create_time) {
            this.setState({ create_time })
            // this.initPage();
            this.reloadOneResult()
            return
          }
        }
      }
      //10s任务
      this.mySetTimeout = setTimeout(this.runPromiseTasks, 10000)
    })
  }

  resizeCallback = () => {
    // console.log('resizeCallback');
    this.setContentItemWidth()
  }

  contentViewRef = node => {
    if (node) {
      this.contentViewNode = node
      this.setContentItemWidth()
    }
  }

  setContentItemWidth = () => {
    if (this.contentViewNode) {
      const { contentItemWidth, updateContentWidth } = this.props
      const newContentItemWidth = this.contentViewNode.offsetWidth - 48
      if (newContentItemWidth !== contentItemWidth) {
        updateContentWidth(newContentItemWidth)
      }
    }
  }

  selectSystemOnChange = id => {
    const { sys_id, selectSystemOnChange } = this.props
    if (sys_id === id) {
      return
    }
    selectSystemOnChange(id)
    this.reloadOneResult()
  }

  render() {
    const { layout, sys_id, tabRect } = this.props

    if (!layout) return null

    let tabs_left = null
    if (!!tabRect) {
      if (typeof tabRect.width === 'number') {
        tabs_left = 72 + tabRect.width + 40
      }
    }

    const { paddingStyle, tabsStyle } = this.props

    return (
      <div className={style['new_main_page_wrapper']} style={paddingStyle}>
        <div
          className="tabs_wrapper"
          style={{ left: typeof tabs_left === 'number' ? tabs_left : '', ...tabsStyle }}
        >
          <div className="content_header">
            {layout.results.map(({ tab_name, sys_id: id }, index) => (
              <div
                key={index}
                className={id === sys_id ? 'selected_system' : ''}
                onClick={() => this.selectSystemOnChange(id)}
              >
                <div className="text">{tab_name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="new_main_page_content_wrapper" ref={this.contentViewRef}>
          <div className="content_body_wrapper">
            <div className="left_wrapper">
              <BoardTop />
              <div style={{ height: 12 }} />
              <BoardSecond />
              <div className="board_block_left_3">
                <ElecDetailBlock />
              </div>
            </div>
            <div className="mid_wrapper">
              <div className="mid_top">
                <BoardTable />
              </div>
              <BoardState />
            </div>
            <div className="right_wrapper">
              <BoardCharts />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
