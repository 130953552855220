import { useEffect, useState } from 'react';
import { post } from 'common/utils';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!conf) {
            setResult(null);
            setLoading(false);
            return;
        };
        const { tplCode, page, keyword = "" } = conf;
        let delay = true;
        setLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + 'api/form/get_list', {
            tpl_code: tplCode,
            page: page,
            page_size: 20,
            ...(
                typeof keyword === 'string' ? {
                    keyword,
                } : {}
            ),
        })
            .then((retData) => {
                if (delay) {
                    setLoading(false);
                    // console.log('hook', retData);
                    if (typeof retData?.data?.count === 'number') {
                        let nextResult = retData.data;
                        let parsed_results = nextResult?.results ?? null;
                        if (!!parsed_results) {
                            parsed_results = parsed_results
                                .map((item) => {
                                    const { content } = item;
                                    let contentObj = null;
                                    try {
                                        contentObj = JSON.parse(content);
                                    } catch (e) { };
                                    return Object.assign({}, item, contentObj ?? {});
                                });
                        };
                        nextResult = Object.assign({}, nextResult, {
                            parsed_results,
                        });
                        // console.log(nextResult);
                        setResult(nextResult);
                    } else {
                        // 处理错误
                        setResult(null);
                    };
                };
            });
        return () => {
            delay = false;
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
