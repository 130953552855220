import React, { Component } from 'react'
import { Modal, Input, Button, message, Form, TimePicker, Tabs } from 'antd'
import { get, post, patch, reqDelete } from 'common/utils.js'
import moment from 'moment'
import Style from './index.module.scss'
import DELETE_ICON from '../../assets/delete.png'
import ADD_ICON from '../../assets/add.png'
import FullScreenLoading from '../../components/FullScreenLoading'

const { TabPane } = Tabs
const defaultArr = {
  id: null,
  name: '',
  day: 0,
  beginTime: '',
  endTime: '',
  isDelete:0
} // 排班时间设置默认行结构
class Time extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmLoading: false,
      showPrompt: false,
      lists: [] // 排班时间列表
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  /** 添加班次 */
  add = () => {
    const { lists } = this.state
    lists.push({ ...defaultArr })
    this.setState({ lists })
  }

  /** 移除班次 */
  delete(index) {
    const { lists } = this.state
    if (lists.length > 1) {
        lists[index].isDelete = 1
      // lists.splice(index, 1)
      this.setState({ lists })
    } else {
      message.info('排班时间至少保留一条')
    }
  }

  handleSelectedTime() {
    console.log(this.state.lists)
    let count = 0
    this.state.lists.map(item => {
      count = this.handleTimeFormat(item.beginTime, item.endTime, item.day) + count
    })
    return count
  }

  handleTimeFormat(beginTime, endTime, day) {
    let start = 0
    let end = Number(endTime.split(':')[0]) * 60 + Number(endTime.split(':')[1])
    let timeRange = 0
    if (day == 0) {
      start = 1440 - (Number(beginTime.split(':')[0]) * 60 + Number(beginTime.split(':')[1]))
      timeRange = Math.abs(end + start)
    } else {
      start = Number(beginTime.split(':')[0]) * 60 + Number(beginTime.split(':')[1])
      timeRange = Math.abs(end - start)
    }
    return timeRange
  }

  // 提交表单
  handleOk = e => {
    if (!this.checkFormValidate()) return
    this.state.lists.map(item=>{
      if(item.day===0){
        item.beginTime = item.beginTime?item.beginTime.replace('昨日 ', ''):''
      }
    })
    this.setState({ confirmLoading: true })
    post(
      process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/shiftConfigUpdate',
      this.state.lists.filter(n => n.name != '')
    ).then(retData => {
      this.setState({ confirmLoading: false })
      if (!!retData.data && retData.data.error === 0) {
        message.success('添加成功')
        this.setState({ showPrompt: false })
        this.props.getResults('success')
      } else {
        message.error(retData.data.msg)
      }
    })
  }

  /** 点击取消/X */
  handleCancel = () => {
    //点击取消按钮触发的事件
    console.log('Clicked cancel button')
    this.setState({ showPrompt: false })
  }

  onShow() {
    const { lists: propsList } = this.props
    const tempList = (propsList || []).map(info => {
      const { id, name, day, beginTime, endTime } = info
      return { id, name, day, beginTime, endTime }
    })
    this.setState({
      showPrompt: true,
      lists: tempList.length > 0 ? tempList : [{ ...defaultArr }]
    })
  }

  /** 处理开始时间变更 */
  handleSelectBeginTime(timeString, id, index) {
    const { lists } = this.state
    lists[index].beginTime = timeString
    this.setState({ lists })
  }

  /** 处理结束时间变更 */
  handleSelectEndTime(timeString, id, index) {
    const { lists } = this.state
    lists[index].endTime = timeString
    this.setState({ lists })
  }

  /** 修改班次名 */
  changeInput = (index, e) => {
    const { lists } = this.state
    lists[index].name = e.target.value
    this.setState({ lists })
  }

  /** 切换今日和昨日 */
  selectDay(value, id, index) {
    const { lists } = this.state
    lists[index].day = Number(value)
    this.setState({ lists })
  }

  /** 校验表单
   * @returns true 校验通过 false 校验不通过
   */
  checkFormValidate = () => {
    const { lists } = this.state
    const maxRange = 24 * 60 * 60
    let totalTimeStamp = 0
    const validStatus = !(
      !!lists &&
      lists.filter(item=>item.isDelete!=1).some((item, index) => {
        // 校验必填
        if (!item.name || !item.beginTime || !item.endTime) {
          message.error('请完整填写信息')
          return true
        }
        const beginTime =
          item.day === 0
            ? moment(item.beginTime, 'HH:mm').subtract(1, 'day')
            : moment(item.beginTime, 'HH:mm')
        const endTime = moment(item.endTime, 'HH:mm')
        // 校验同一班次起始时间的大小关系
        if (!beginTime.isBefore(endTime)) {
          message.error(`${item.name}(第${index + 1}行) 的开始时间应小于结束时间`)
          return true
        }
        // 每班的开始时间和结束时间需要大于1小时
        if (((endTime - beginTime) / 1000 / 60) < 60) {
          message.error(`${item.name}(第${index + 1}行) 的时间间隔需要大于1小时`)
          return true
        }
        // 校验时间重叠-排班开始时间不能小于前一个排班的结束时间
        if (index > 0 && beginTime.isBefore(moment(lists[index - 1].endTime, 'HH:mm'))) {
          message.error('各排班之间时间不能重复')
          return true
        }

        // TODO:校验总排班时间不能超过24小时
        totalTimeStamp += (endTime - beginTime) / 1000

        if (totalTimeStamp > maxRange) {
          console.log('totalTimeStamp', totalTimeStamp)
          message.error('总排班时间不能超过24小时')
          return true
        }
      })
    )
    console.log('validStatus', validStatus)
    return validStatus
  }

  render() {
    const { showPrompt, confirmLoading, lists } = this.state
    return (
      <Modal
        title="排班时间设置"
        visible={showPrompt}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className={Style['setting-modal']}
      >
        <Form className={Style['form']}>
          {lists &&
            lists.map((item, index) => item.isDelete!=1 && (
              <div className={Style['setting-info']} key={index}>
                <Input
                  className={Style['class-setting']}
                  value={item.name}
                  onChange={this.changeInput.bind(this, index)}
                  placeholder="请输入班次"
                />
                <TimePicker
                  showNow={false}
                  placeholder="开始时间"
                  value={item.beginTime != '' ? moment(item.beginTime, 'HH:mm') : ''}
                  format={item.day === 0 ? '昨日 HH:mm' : 'HH:mm'}
                  onChange={(time, timeString) =>
                    this.handleSelectBeginTime(timeString, item.id, index)
                  }
                  renderExtraFooter={() => (
                    <Tabs
                      defaultActiveKey={String(item.day > 1 ? 1 : item.day)}
                      onChange={key => this.selectDay(key, item.id, index)}
                    >
                      <TabPane tab="昨日" key="0"></TabPane>
                      <TabPane tab="今日" key="1"></TabPane>
                    </Tabs>
                  )}
                />
                <span>-</span>
                <TimePicker
                  showNow={false}
                  placeholder="结束时间"
                  value={item.endTime != '' ? moment(item.endTime, 'HH:mm') : ''}
                  format={'HH:mm'}
                  onChange={(time, timeString) =>
                    this.handleSelectEndTime(timeString, item.id, index)
                  }
                />
                <img
                  src={DELETE_ICON}
                  className={Style['class-info-delete']}
                  onClick={e => this.delete(index)}
                ></img>
              </div>
            ))}
          <img
            onClick={this.add}
            src={ADD_ICON}
            className={Style['class-info-delete']}
            style={{ float: 'right', marginTop: '16px' }}
          ></img>
        </Form>
        {confirmLoading && <FullScreenLoading loadingTip="数据提交中，请耐心等待..." />}
      </Modal>
    )
  }
}

export default Time
