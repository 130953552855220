import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
// const R = require('ramda');

// const initValue = {
// }

let store = createStore(reducers, applyMiddleware(thunk));

export default store;