import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import ICON from './res/cus_factor_2.png';
import ICON_DEL from './res/del.png';

import './CustomFactorPanel.scss';

const CustomFactorPanel = (props) => {
    const { onCancel, customFactors } = props;
    const cancel = () => {
        if (typeof onCancel === "function") { onCancel() };
    };
    let num = '-';
    if (!!customFactors) {
        num = customFactors.length;
    };
    const deleteOnClick = (id, factor_name) => {
        Modal.confirm({
            content: `确认删除自选指标【${factor_name}】吗?`,
            onOk: () => {
                const { deleteFromFavouriteKPI } = props;
                deleteFromFavouriteKPI(id)
                    .then(isSuccess => {
                        // console.log(isSuccess);
                        if (isSuccess) {
                            const { getCustomKPIList } = props;
                            getCustomKPIList();
                        };
                    });
            },
        });
    };
    const btnOnClick = () => {
        const { watchFavouriteKPIs } = props;
        if (typeof watchFavouriteKPIs === "function") {
            watchFavouriteKPIs();
        };
    };
    return (
        <div style={{ position: 'relative', height: '100%' }} className="factor_summary_v2_single_factor_detail_custom_factor_panel_wrapper">
            <div style={{ position: 'absolute', top: 30, right: 30, bottom: 30, left: -8, backgroundColor: '#0D1115', filter: 'blur(16px)' }} />
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={cancel} />
            <div style={{
                position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: '#212830',
                display: 'flex', flexDirection: 'column'
            }}>
                <div style={{ height: 46, border: '1px solid rgba(68,242,217,0.2)', backgroundColor: '#0D1115', display: 'flex', alignItems: 'center' }}>

                    <img alt="" src={ICON} width="24" height="24" style={{ marginLeft: 11 }} />
                    <div style={{ fontSize: 18, lineHeight: '18px', height: 18, color: '#FFFFFF', marginLeft: 4 }}>自选指标</div>
                    <CloseOutlined style={{ fontSize: 22, marginLeft: 'auto', marginRight: 19, color: '#596168' }} onClick={cancel} />

                </div>

                <div style={{ height: 0, flexGrow: 1, overflowY: 'auto' }} className="scroll_bar_restyle">
                    {
                        customFactors
                            .map((item, index) => {
                                const { id, factor_name } = item;
                                let rowStyle = { height: 48, paddingLeft: 12, paddingRight: 20, display: 'flex', alignItems: 'center' };
                                if (index % 2 === 1) {
                                    rowStyle = Object.assign({ backgroundColor: '#191F27' }, rowStyle);
                                };
                                return (
                                    <div key={index} style={rowStyle} className="row">
                                        <div style={{ fontSize: 14, lineHeight: '20px', height: 20, color: '#FFFFFF' }}>{factor_name}</div>
                                        <img
                                            alt="" src={ICON_DEL}
                                            width="24" height="24"
                                            className="icon" style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                            onClick={() => deleteOnClick(id, factor_name)}
                                        />
                                    </div>
                                );
                            })
                    }
                </div>

                <div style={{ height: 32, backgroundColor: '#191F27', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: 16, lineHeight: '16px', height: 16, color: '#41ECD4' }}>{num}</div>
                    <div style={{ marginLeft: 2, fontSize: 14, lineHeight: '14px', height: 14, color: '#9FA0BA' }}>个指标</div>
                </div>

                <div
                    style={{
                        height: 60, backgroundColor: '#41ECD4', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        fontSize: 20, lineHeight: '26px', color: '#0D1115',
                        cursor: 'pointer', userSelect: 'none'
                    }}
                    onClick={btnOnClick}
                >进行指标分析</div>
            </div>
        </div>
    );
};

export default CustomFactorPanel;
