import React from 'react';
import NewScreen from 'containers/NewScreen';
import ScaleWrapper from 'components/ScaleWrapper';

const Page = (props) => {
    return (
        <ScaleWrapper bckColor="rgb(31,40,49)">
            <NewScreen history={props.history} />
        </ScaleWrapper>
    );
};

export default Page;
