import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { DatePicker } from 'antd';
const moment = require('moment');

const topBarTexts = [
    {
        text: '日',
        picker: 'date',
    }, {
        text: '周',
        picker: 'week',
    }, {
        text: '月',
        picker: 'month',
    },
];
const allPickers = topBarTexts.map(({ picker }) => picker);
const getTimeInfo = (timeRange) => {
    if (typeof timeRange === "object" && !!timeRange) {
        let type = timeRange[0];
        let time = timeRange[1];
        if (!allPickers.includes(type)) {
            type = null;
        };
        if (!moment.isMoment(time)) {
            time = moment();
        };
        return [type, time];
    } else {
        return [null, moment()];
    };
};
const TimeRangePicker = (props) => {
    const { timeRange } = props;
    const [edittingSelect, setEdittingSelect] = useState(null);
    const refNode = useRef(null);
    useEffect(() => {
    });
    const [type, timeStamp] = getTimeInfo(timeRange);
    const periodRangeOnChange = (time) => {
        const { onChange } = props;
        if (typeof onChange === "function") {
            onChange([edittingSelect, time]);
        };
        setEdittingSelect(null);
    };
    // console.log("before return", edittingSelect);
    return (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            {
                topBarTexts
                    .map((item, index, arr) => {
                        const { text, picker } = item;
                        let style = {
                            width: 56, height: 32, backgroundColor: '#12191F',
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            cursor: 'pointer', userSelect: 'none',
                        };
                        let textStyle = { height: 16, fontSize: 16, color: '#9FA0BA', lineHeight: '16px' };
                        if (index < arr.length - 1);
                        style = Object.assign({}, style, { marginRight: 4 });
                        if (edittingSelect === picker || (edittingSelect === null && type === picker)) {
                            style = Object.assign({}, style, {
                                backgroundColor: '#343D47', paddingBottom: 2,
                                borderStyle: 'solid', borderColor: '#3DCDB9', borderWidth: '3px 1px 1px 1px',
                            });
                            textStyle = Object.assign({}, textStyle, { color: '#44F3DA' });
                        };
                        if (edittingSelect !== null) {
                            style = Object.assign({}, style, {
                                position: 'relative',
                                zIndex: 10001,
                            });
                        };
                        return (
                            <div
                                key={index}
                                onClick={() => setEdittingSelect(picker)}
                                style={style}
                            >
                                <div style={textStyle}>{text}</div>
                            </div>
                        );
                    })
            }
            {
                edittingSelect !== null && (
                    <div
                        style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000 }}
                        onClick={() => setEdittingSelect(null)}
                    />
                )
            }
            <div
                style={{ position: 'absolute', zIndex: 10001, width: '100%', bottom: 0, left: '50%', transform: 'translate(-50%, 100%)' }}
                ref={refNode}
            >
                {
                    edittingSelect !== null && (
                        <div style={{ width: '100%', backgroundColor: 'rgb(33, 40, 48)' }}>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={timeStamp}
                                open={true}
                                picker={edittingSelect}
                                onChange={periodRangeOnChange}
                                getPopupContainer={() => refNode.current}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default TimeRangePicker;
