import {
    Menu, Dropdown, Button,
} from 'antd';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';

const Page = (props) => {
    const { pageSizeOptions, pageSize, onChange } = props;

    const onClick = ({ key }) => {
        const nextPageSize = parseInt(key);
        if (nextPageSize !== pageSize) {
            onChange(nextPageSize); // new pageSize
        };
    };

    const menu = (
        <Menu onClick={onClick} selectedKeys={[pageSize + '']}>
            {
                pageSizeOptions
                    .map((v) => {
                        return (
                            <Menu.Item key={v + ""}>{v}条/页</Menu.Item>
                        );
                    })
            }
        </Menu >
    );

    return (
        <Dropdown overlay={menu} placement="topRight" trigger={['click']}>
            <Button>{pageSize}条/页<DownOutlined /></Button>
        </Dropdown>
    );
};

export default Page;
