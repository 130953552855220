import React from 'react';

import ProductionComp from './ProductionComp.js';
import EnergyConsumptionComp from './EnergyConsumptionComp.js';
import QualityComp from './QualityComp.js';
import ImproveChanceComp from './ImproveChanceComp.js';

import ProductionModal from './ProductionModal.js';
import EnergyConsumptionModal from './EnergyConsumptionModal.js';
import QualityModal from './QualityModal.js';
import ImproveChanceModal from './ImproveChanceModal.js';

import SHENCHANGAIKUANG_ICON from './res/shengchangaikuang.png';
import NENGHAOGAIKUANG_ICON from './res/nenghaogaikuang.png';
import ZHILIANGGAIKUANG_ICON from './res/zhilianggaikuang.png';
import GAISHANJIHUI_ICON from './res/gaishanjihui.png';

import ARROW_ICON from './res/arrow.png';

import './BottomPanels.scss';

const moment = require('moment');

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seemoreIndex: null,
            panels: [],
            panelIndexs: [],
        };
    }

    componentDidMount() {
        const panels = [
            {
                icon: SHENCHANGAIKUANG_ICON,
                name: '生产概况',
                component: <ProductionComp />,
                modalComponent: <ProductionModal onClose={this.onClose} />,
            },
            {
                icon: NENGHAOGAIKUANG_ICON,
                name: '能耗概况',
                component: <EnergyConsumptionComp />,
                modalComponent: <EnergyConsumptionModal onClose={this.onClose} />,
            },
            {
                icon: ZHILIANGGAIKUANG_ICON,
                name: '质量概况',
                component: <QualityComp />,
                modalComponent: <QualityModal onClose={this.onClose} />,
            },
            {
                icon: GAISHANJIHUI_ICON,
                name: '改善机会',
                component: <ImproveChanceComp />,
                modalComponent: <ImproveChanceModal onClose={this.onClose} />,
            },
        ];
        const panelIndexs = panels
            .map((_, index) => index);
        this.setState({
            panels,
            panelIndexs,
        });
    }

    onClose = () => {
        this.setState({ seemoreIndex: null });
    }

    render() {
        const { seemoreIndex, panels, panelIndexs } = this.state;

        return (
            <div className="main_page_bottom_panels_wrapper">
                {
                    panels
                        .map((panel, index) => {
                            const { icon, name, component } = panel;
                            return (
                                <div className="panel_wrapper" key={index}>
                                    <div className="title_wrapper">
                                        <img alt="" src={icon} className="title_icon" />
                                        <div className="text">{name}</div>
                                        <div className="see_more" onClick={() => this.setState({ seemoreIndex: index })}>
                                            <div className="see_more_text">查看全部</div>
                                            <img alt="" src={ARROW_ICON} className="see_more_icon" />
                                        </div>
                                    </div>
                                    <div className="bottom_panel_aLine" />
                                    <div className="bottom_panel_content_wrapper">
                                        {
                                            !!component && component
                                        }
                                    </div>
                                </div>
                            );
                        })
                }
                {
                    panelIndexs.includes(seemoreIndex) && panels[seemoreIndex].modalComponent
                }
            </div>
        );
    }
}
