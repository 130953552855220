import React from 'react';
import style from './index.module.scss';
import ScaleWrapper from 'components/ScaleWrapper';

const Page = (props) => {
    const { title, children, width, height } = props;

    return (
        <div className={style['wrapper']}>
            {
                typeof width === 'number' && typeof height === 'number' && (
                    <ScaleWrapper width={width} height={height}>
                        <div className={style['content']}>
                            <div className="top">
                                <div className="title">{title ?? ""}</div>
                            </div>
                            <div className="bottom">
                                {children}
                            </div>
                        </div>
                    </ScaleWrapper>
                )
            }
        </div>
    );
};

export default Page;
