import { useEffect, useState } from 'react';
import { post } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time, range, base_id } = conf;
            if (typeof time !== 'string' || typeof base_id !== 'number') {
                setResult(null);
                return;
            };
            let requesting = true;
            setLoading(true);
            const day = moment(time).startOf(range).format("YYYY-MM-DD");
            post(process.env.REACT_APP_SERVER_PATH + 'api/factors/get_factor_history_with_abnormals', {
                day, base_id,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData.data;
                            let parsed_data_history = nextResult?.data_history;
                            if (!!parsed_data_history) {
                                parsed_data_history = parsed_data_history
                                    .map((item) => {
                                        return [moment(item[0]).unix() * 1000, item[1]];
                                    });
                            };

                            let parsed_abnormals = nextResult?.abnormals;
                            if (!!parsed_abnormals) {
                                parsed_abnormals = parsed_abnormals
                                    .map((item) => {
                                        // time_begin_unix
                                        // time_end_unix
                                        const { time_begin, time_end } = item;
                                        const time_begin_unix = moment(time_begin).unix() * 1000;
                                        const time_end_unix = moment(time_end).unix() * 1000;
                                        return Object.assign({}, item, {
                                            time_begin_unix,
                                            time_end_unix,
                                        });
                                    })
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_data_history,
                                parsed_abnormals,
                            });

                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
