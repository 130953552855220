import { significantNum } from 'common/utils';
import moment from 'moment'

const tabs = [
    {
        name: '电量',
        columns: [
            {
                title: '电表',
                dataIndex: 'pm_name',
                width: 150
            }
            ,
            {
                title: '日期',
                dataIndex: 'date',
                render: t => {
                    const mT = moment(t)
                    if (mT.isValid()) {
                        return mT.format('YYYY/M/D')
                    }
                    return '--'
                },
                align: 'center'
            }
            ,
            {
                title: '当期电量(万kWh)',
                dataIndex: 'kWh',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '月累',
                dataIndex: 'kWh_mm',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '年累',
                dataIndex: 'kWh_yy',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '尖峰电量(万kWh)',
                dataIndex: 'kWhz3',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '峰段电量(万kWh)',
                dataIndex: 'kWhz2',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '平段电量(万kWh)',
                dataIndex: 'kWhz1',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '谷段电量(万kWh)',
                dataIndex: 'kWhz0',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '运行时间(hrs)',
                dataIndex: 'OPHR',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '负载率(%)',
                dataIndex: 'LDRT',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
        ]
    },
    {
        name: '电费',
        columns: [
            {
                title: '电表',
                dataIndex: 'pm_name',
                width: 150
            }
            ,
            {
                title: '日期',
                dataIndex: 'date',
                render: t => {
                    const mT = moment(t)
                    if (mT.isValid()) {
                        return mT.format('YYYY/M/D')
                    }
                    return '--'
                },
                align: 'center'
            }
            ,
            {
                title: '尖峰峰电费(万元)',
                dataIndex: 'elchrg3',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '峰段电费(万元)',
                dataIndex: 'elchrg2',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '平段电费(万元)',
                dataIndex: 'elchrg1',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '谷段电费(万元)',
                dataIndex: 'elchrg0',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '合计电价(元/kWh)',
                dataIndex: 'elprice',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
            ,
            {
                title: '平均电费(万元)',
                dataIndex: 'elchrg',
                render: t => {
                    const pT = typeof t === 'number' ? significantNum(t) : '--'
                    return pT
                },
                align: 'center'
            }
        ]
    },
];

export {
    tabs
};
