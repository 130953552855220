import moment from 'moment';
import { BOTTOM_CHART_HEIGHT } from './constants.js';

const getTopChartOption = (dataSource = []) => {
    const xAxisData = dataSource
        .map(({ factorName }) => factorName);
    const seriesRank1Data = dataSource
        .map(({ rank1TotalScore }) => rank1TotalScore);
    const seriesSelfScoreData = dataSource
        .map(({ total_score }) => total_score);

    //手动定义min和max，进一步定义interval
    const allValues = [
        ...new Set(
            []
                .concat(
                    ...dataSource
                        .map(({ total_score, rank1TotalScore }) => [total_score, rank1TotalScore])
                )
                .filter(v => typeof v === 'number'),
        ),
    ];
    const chartMin = 0;//分数总是>=0
    let maxV = Math.max(...allValues);
    let chartMax = 1.2 * maxV;
    if (chartMax <= 0) chartMax = 1;
    chartMax = Math.ceil(chartMax);
    const chartInterval = (chartMax - chartMin) / 4;

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'none'        // 默认为直线，可选为：'line' | 'shadow'
            },
            backgroundColor: 'rgba(255,255,255,1)',
            // borderColor:
            extraCssText: 'box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.08);border-radius:5px;opacity:0.95;',
            textStyle: {
                color: 'rgba(153, 153, 153, 1)',
            },
        },
        grid: {
            left: 50,
            right: 20,
            bottom: 20,
            top: 20,
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(235, 235, 235, 1)',
                },
            },
            axisLabel: {
                color: 'rgba(0, 0, 0, 0.44)',
                interval: 0,
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                color: 'rgba(152, 152, 152, 1)',
                fontSize: 14,
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(235, 235, 235, 1)',
                },
            },
            min: chartMin,
            max: chartMax,
            interval: chartInterval,
        },
        series: [
            {
                name: '标准分',
                type: 'bar',
                // stack: 'stack',
                data: seriesRank1Data,
                itemStyle: {
                    color: 'rgba(235,235,235,1)',
                },
                emphasis: {
                    itemStyle: {
                        color: 'rgba(205, 205, 205, 1)',
                    },
                },
                barWidth: "12%",
            },
            {
                name: '实际分',
                type: 'bar',
                // stack: 'stack',
                data: seriesSelfScoreData,
                itemStyle: {
                    color: 'rgba(255,188,52,1)',
                },
                emphasis: {
                    itemStyle: {
                        color: 'rgba(244, 149, 15, 1)',
                    },
                },
                barWidth: "12%",
                barGap: "-100%",
            },
        ],
    };
};

const getYAxisMinMax = (min, max) => {
    let yAxisMin = Math.floor(min - 0.2 * (max - min));
    let yAxisMax = Math.ceil(max + 0.2 * (max - min));
    if (yAxisMin === yAxisMax) {
        yAxisMin--;
        yAxisMax++;
    };
    return [yAxisMin, yAxisMax];
}

const getFactorDetailChartOption = (hisTag2Value, factor) => {
    // console.log(hisTag2Value);
    // console.log(factor);
    // const seriesData = [[1, 220], [2, 182], [3, 191], [4, 234], [5, 290], [6, 330], [9, 310]];
    // const seriesData = hisTag2Value
    //     .map(([t, v]) => [t * 1000, v]);
    const allXData = hisTag2Value
        .map(([v]) => v);
    const allYData = hisTag2Value
        .map(([_, v]) => v);
    const allYNumberData = allYData
        .filter(v => typeof v === 'number');
    // const [xMin, xMax] = [Math.min(...allXData), Math.max(...allXData)];
    // const [yMin, yMax] = [Math.min(...allYData), Math.max(...allYData)];
    // let yAxisMin = Math.floor(yMin - 0.2 * (yMax - yMin));
    // let yAxisMax = Math.ceil(yMax + 0.2 * (yMax - yMin));
    // if (yAxisMin === yAxisMax) {
    //     yAxisMin--;
    //     yAxisMax++;
    // };
    const [yAxisMin, yAxisMax] = getYAxisMinMax(Math.min(...allYNumberData), Math.max(...allYNumberData));
    const GridTop = 20;
    const GridBottom = 60;


    let lineColor = 'rgba(255, 188, 52, 1)';
    let lineShadowColor = 'rgba(255, 188, 52, 0.4)';
    let markArea = null;
    if (!!factor && !!factor.factorInfo) {
        const { factorInfo } = factor;
        const { score_method } = factorInfo;
        if (score_method !== 2) {
            //不是平均的时候颜色不一样
            lineColor = 'rgba(240, 56, 105, 1)';
            lineShadowColor = 'rgba(240, 56, 105, 0.49)';
        };

        const BestColor = 'rgba(232, 247, 235, 1)';
        const NormalColor = 'rgba(254, 254, 209, 1)';
        const WorstColor = 'rgba(250, 225, 225, 1)';
        //设置markArea
        // 限制和区间 各自 还需要区分 details_list 水泥磨系统，在水泥磨系统中，色块应该是分块的。
        if (score_method === 4) {
            //限制 
            const { details_list, score_details } = factorInfo;
            if (!details_list) {
                //非水泥磨，不分色块的情况
                const { dev_intervals } = score_details;
                markArea = {
                    silent: true,
                    data: dev_intervals
                        .reduce((pV, cV, index, arr) => {
                            let [v1, v2] = cV;
                            if ([0, arr.length - 1].includes(index)) {
                                if (v1 === null) {
                                    v1 = Math.min(yAxisMin, v2);
                                } else {
                                    //v2===null
                                    v2 = Math.max(yAxisMax, v1);
                                };
                            } else {
                                if (v1 === 0 && v2 === 0) {
                                    return pV;
                                };
                            };
                            pV.push([
                                {
                                    yAxis: v1,
                                    itemStyle: {
                                        color: index === 0 ?
                                            BestColor : (
                                                index === arr.length - 1 ?
                                                    WorstColor : NormalColor
                                            ),
                                    },
                                },
                                {
                                    yAxis: v2,
                                },
                            ]);
                            return pV;
                        }, []),
                }
            };
        } else if (score_method === 3) {
            //区间 
            const { details_list, score_details } = factorInfo;
            if (!details_list) {
                //非水泥磨，不分色块的情况
                const { dev_intervals, std_value } = score_details;
                markArea = {
                    silent: true,
                    data: dev_intervals
                        .reduce((pV, cV, index, arr) => {
                            let [v1, v2] = cV;
                            if (index === 0) {
                                pV.push([
                                    {
                                        yAxis: std_value - v2,
                                        itemStyle: {
                                            color: BestColor,
                                        },
                                    },
                                    {
                                        yAxis: std_value + v2,
                                    },
                                ]);
                            } else {
                                if (v1 === 0 && v2 === 0) {
                                    return pV;
                                };
                                if (index === arr.length - 1) {
                                    // v2 === null
                                    v2 = Math.max(
                                        v1,
                                        Math.abs(std_value - yAxisMax),
                                        Math.abs(std_value - yAxisMin),
                                    );
                                };
                                pV.push(
                                    ...[1, -1]
                                        .map(dir => [
                                            {
                                                yAxis: std_value + dir * v1,
                                                itemStyle: {
                                                    color: index === arr.length - 1 ? WorstColor : NormalColor,
                                                },
                                            },
                                            {
                                                yAxis: std_value + dir * v2,
                                            },
                                        ]),
                                )
                            };
                            return pV;
                        }, []),
                };
            };
        } else if (score_method === 2) {
            //平均
            const { rank_method } = factorInfo;
            // 1 参数低，得分高； 2 参数高，得分低
            if ([1, 2].includes(rank_method)) {
                const ColorAreaHeight = 4;
                const GreatColor = 'rgba(137, 234, 156, 1)';
                const BadColor = 'rgba(236, 144, 144, 1)';
                const topColor = rank_method === 1 ? BadColor : GreatColor;
                const bottomColor = rank_method === 1 ? GreatColor : BadColor;
                markArea = {
                    silent: true,
                    data: [
                        [
                            {
                                y: GridTop,
                                itemStyle: {
                                    color: topColor,
                                },
                            },
                            {
                                y: GridTop + ColorAreaHeight,
                            },
                        ],
                        [
                            {
                                y: BOTTOM_CHART_HEIGHT - GridBottom - ColorAreaHeight,
                                itemStyle: {
                                    color: bottomColor,
                                },
                            },
                            {
                                y: BOTTOM_CHART_HEIGHT - GridBottom,
                            },
                        ],
                    ],
                };
            };
        };
    };

    // console.log(markArea);

    return {
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                // console.log(params);
                return params.map(({ axisValue, marker, data }, index) => (
                    `${index === 0 ? `${moment.unix(axisValue).format('MM-DD HH:mm:ss')}` : ''}<br/>${marker} ${Math.floor(data * 1000) / 1000}`
                )).join('');
            },
        },
        grid: {
            left: 50,
            right: 20,
            bottom: GridBottom,
            top: GridTop,
        },
        xAxis: [
            {
                // type: 'value',
                type: 'category',
                data: allXData,
                // .map(value => moment.unix(value).format("HH:mm:ss")),
                boundaryGap: false,
                // type: 'time',
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(235, 235, 235, 1)',
                    },
                },
                axisLabel: {
                    color: 'rgba(0, 0, 0, 0.44)',
                    fontSize: 12,
                    formatter: (value, index) => {

                        return moment.unix(value).format("HH:mm:ss");
                    },
                },
                splitLine: {
                    show: false,
                },
                // min: xMin,
                // max: xMax,
            },
        ],
        yAxis: [
            {
                type: 'value',
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(235, 235, 235, 1)',
                    },
                },
                axisLabel: {
                    color: 'rgba(0, 0, 0, 0.45)',
                    fontSize: 12,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.04)',
                    },
                },
                min: (value) => {
                    // console.log(value);
                    const { min, max } = value;
                    return getYAxisMinMax(min, max)[0];
                },
                max: (value) => {
                    // console.log(value);
                    const { min, max } = value;
                    return getYAxisMinMax(min, max)[1];
                },
                // min: yAxisMin,
                // max: yAxisMax,
            },
        ],
        dataZoom: [
            {
                type: 'inside',
                // start: 0,
                // end: 20
            },
            {
                show: true,
                // height: 20,
                type: 'slider',
                // top: '90%',
                xAxisIndex: 0,
                // start: 0,
                // end: 20
                labelFormatter: (index) => {
                    const value = allXData[index];
                    // console.log(value);
                    const time = moment.unix(value);
                    const topStr = time.format('MM-DD');
                    const bottomStr = time.format('HH:mm:ss');
                    return `${topStr}\n${bottomStr}`;
                },
            }
        ],
        series: [
            {
                // name: '标准分',
                type: 'line',
                // data: seriesData,
                data: allYData,
                symbol: 'none',
                smooth: true,
                itemStyle: {
                    color: lineColor,
                },
                lineStyle: {
                    width: 1,
                    // color: 'rgba(255, 188, 52, 1)',
                    shadowColor: lineShadowColor,
                    shadowBlur: 3,
                },
                ...(
                    markArea === null ?
                        {} : {
                            markArea,
                        }
                ),
                // markArea: {
                //     silent: true,
                //     data: [
                //         [
                //             {
                //                 // x: 100,
                //                 // y: 50,
                //                 // coord: [0, 20],
                //                 yAxis: 20,
                //                 itemStyle: {
                //                     color: 'green',
                //                 },
                //             },
                //             {
                //                 // x: 200,
                //                 // y: 150,
                //                 // coord: [0, 19],
                //                 yAxis: 19,
                //             }
                //         ],
                //         // [
                //         //     {
                //         //         y: 52
                //         //     },
                //         //     {
                //         //         y: 54
                //         //     },
                //         // ],
                //     ]
                // },
            },
        ],
    };
};

export {
    getTopChartOption,
    getFactorDetailChartOption,
};