import React from 'react';
import style from './index.module.scss';
import SimpleCard from '../SimpleCard';
import { Cards_Info_List } from './constants';

const Comp = (props) => {
    // const { } = props;
    return (
        <div className={style['wrapper']}>
            {
                Cards_Info_List
                    .map((item, index) => {
                        return (
                            <div key={index} className="item_wrapper">
                                <SimpleCard info={item} />
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default Comp;
