
// const group_I_tags = {
//     tag_1: '_H',
//     tag_2: '_HDQOQ',
// };

const groups = {
    children: [
        {
            children: [
                {
                    name: '进厂原料气',
                    tag_1: 'PI_0106',
                    tag_2: 'SG_TI0271',
                },
            ],
        },
        {
            children: [
                {
                    name: 'MCL出口',
                    tag_1: 'SG_PI0279',
                    tag_2: 'SG_TI0279',
                },
                {
                    name: 'MCL性能',
                    tag_1: 'COMP_MOTOR_ELEC_RATE',
                    tag_2: 'COMP_TOT_EFF_RATE',
                },
            ],
        },
        {
            children: [
                {
                    name: '膨胀机入口',
                    tag_1: 'PIA0421',
                    tag_2: 'TI0421',
                },
                {
                    name: '膨胀机出口',
                    tag_1: 'PI0424',
                    tag_2: 'TI0426',
                },
            ],
        },
        {
            children: [
                {
                    name: '脱甲烷塔顶',
                    tag_1: 'PI_0501',
                    tag_2: 'TI_0501',
                },
                {
                    name: '脱甲烷塔底',
                    tag_1: 'TI_0504',
                    tag_2: 'LI_0501',
                },
            ],
        },
        {
            children: [
                {
                    name: '脱乙烷塔顶',
                    tag_1: 'PI_0502',
                    tag_2: 'TI_0506',
                },
                {
                    name: '脱乙烷塔底',
                    tag_1: 'TI_0508',
                    tag_2: 'LI_0504',
                },
            ],
        },
        {
            children: [
                {
                    name: '脱丙烷塔顶',
                    tag_1: 'PI_0509',
                    tag_2: 'TI_0510',
                },
                {
                    name: '脱丙烷塔底',
                    tag_1: 'TI_0512',
                    tag_2: 'LI_0510',
                },
            ],
        },
        {
            children: [
                {
                    name: '脱丁烷塔顶',
                    tag_1: 'PI_0514',
                    tag_2: 'TI_0516',
                },
                {
                    name: '脱丁烷塔底',
                    tag_1: 'TI_0518',
                    tag_2: 'LI_0516',
                },
            ],
        },
    ],
};

export {
    groups,
};
