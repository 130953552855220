import React from 'react';
import RightTable from './RightTable.js';
import BottomPanels from './BottomPanels.js';

import ICON_1_GREEN from './res/icon_1_green.png';
import ICON_1_RED from './res/icon_1_red.png';
import ICON_2_GREEN from './res/icon_2_green.png';
import ICON_2_RED from './res/icon_2_red.png';
import ICON_3_GREEN from './res/icon_3_green.png';
import ICON_3_RED from './res/icon_3_red.png';
import ICON_4_GREEN from './res/icon_4_green.png';
import ICON_4_RED from './res/icon_4_red.png';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import DomResize from 'components/DomResize';
import TimeRangePicker from 'components/System/Common/TimeRangePicker.js';
import { parseTimeRange } from './utils.js';
import { addDarkTheme, rmDarkTheme } from 'containers/System/utils';

import './MainPage.scss';

const ICON_4_ITEMS = [
    [ICON_1_GREEN, ICON_1_RED],
    [ICON_2_GREEN, ICON_2_RED],
    [ICON_3_GREEN, ICON_3_RED],
    [ICON_4_GREEN, ICON_4_RED],
];

export default class MainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoVisible: false,
            infoList: [
                {
                    name: '原料粉磨系统',
                    value: 77.7,
                    position: [235, 98],
                    deltaPos: [2, 2],
                    warning: true,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
                {
                    name: '生料库底系统',
                    value: 72.2,
                    position: [662, 33],
                    deltaPos: [0, 1],
                    warning: false,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
                {
                    name: '熟料烧成系统',
                    value: 82.6,
                    position: [890, 49],
                    deltaPos: [-1, 1],
                    warning: false,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
                {
                    name: '煤粉制备系统',
                    value: 86.4,
                    position: [84, 478],
                    deltaPos: [2, -2],
                    warning: false,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
                {
                    name: '熟料库底系统',
                    value: 84.4,
                    position: [450, 520],
                    deltaPos: [1, -2],
                    warning: true,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
                {
                    name: '水泥粉磨系统',
                    value: 82.4,
                    position: [833, 456],
                    deltaPos: [-1, -1],
                    warning: false,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
                {
                    name: '水泵站系统',
                    value: 81.1,
                    position: [1067, 426],
                    deltaPos: [-1, -1],
                    warning: false,
                    percent_4_union: new Array(4)
                        .fill(0)
                        .map(() => Math.random() < 0.9),
                },
            ],
            degAngle: null,
        };
    }

    componentDidMount() {
        // setTimeout(this.render3D, 3000);
        this.render3D();
        // 将全局背景色置灰
        if (typeof this.props.addDarkMode === 'function') {
            this.props.addDarkMode();
        };
        addDarkTheme();
    }

    componentWillUnmount() {
        // 将全局背景色恢复，存在默认的概念?
        if (typeof this.props.rmDarkMode === 'function') {
            this.props.rmDarkMode();
        };
        rmDarkTheme();
    }

    render3D = () => {
        const self = this;
        if (!this.refNode) {
            return;
        };
        // console.log(this.refNode.offsetWidth);
        const nodeWidth = this.refNode.offsetWidth;
        const nodeHeight = this.refNode.offsetHeight;

        const refNode = this.refNode;
        const startCameraPosition = [0, 128000, 0];
        const endCameraPosition = [-105000, 70000, 22500];
        const deltaCameraPosition = startCameraPosition
            .map((startAxis, index) => endCameraPosition[index] - startAxis);
        const renderStepNum = 100;
        var container;

        var camera, scene, renderer;

        init();
        animate();

        let currentStep = 0;
        const renderNextScene = () => {
            if (currentStep < renderStepNum) {
                currentStep++;
            } else {
                this.setState({ infoVisible: true });
                return;
            };
            const currentCameraPosition = startCameraPosition
                .map((startAxis, index) => startAxis + (deltaCameraPosition[index] * currentStep / renderStepNum));
            const [x, y, z] = currentCameraPosition;
            // console.log(currentCameraPosition);
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            camera.lookAt(0, 0, -13000);
            // render();
            setTimeout(renderNextScene, 20);
        };
        setTimeout(renderNextScene, 100);
        // renderNextScene();

        function init() {

            // container = document.createElement('div');
            // document.body.appendChild(container);

            container = refNode;

            camera = new THREE.PerspectiveCamera(45, nodeWidth / nodeHeight, 100, 1300000);
            const [x, y, z] = startCameraPosition;
            // const [x, y, z] = endCameraPosition;
            // console.log(startCameraPosition);
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            // camera.position.x = -150000;
            // camera.position.y = 100000;
            // camera.position.z = 45000;
            camera.lookAt(0, 0, -13000);

            // scene

            scene = new THREE.Scene();

            var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
            scene.add(ambientLight);

            var pointLight = new THREE.PointLight(0xffffff, 0.8);
            camera.add(pointLight);
            scene.add(camera);

            // model

            var onProgress = function (xhr) {
                if (xhr.lengthComputable) {
                    var percentComplete = xhr.loaded / xhr.total * 100;
                    console.log(Math.round(percentComplete, 2) + '% downloaded');
                }
            };

            var onError = function (xhr) { };

            var mtlLoader = new MTLLoader();
            mtlLoader.setPath('obj/yxsn/');
            mtlLoader.load('factory.mtl', function (materials) {

                materials.preload();

                var objLoader = new OBJLoader();
                objLoader.setMaterials(materials);
                objLoader.setPath('obj/yxsn/');
                objLoader.load('factory.obj', function (object) {
                    // console.log(object);

                    // object.position.y = - 95;
                    scene.add(object);

                }, onProgress, onError);

            });

            //

            renderer = new THREE.WebGLRenderer();
            // renderer = new THREE.WebGLRenderer({ alpha: true });
            // renderer.setClearColor(0xffffff, 0);
            renderer.setPixelRatio(window.devicePixelRatio);
            // renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setSize(nodeWidth, nodeHeight);
            container.appendChild(renderer.domElement);

            var controls = new OrbitControls(camera, renderer.domElement);
            // controls.addEventListener('change', render); // use only if there is no animation loop
            controls.addEventListener('change', (event) => {
                const { target } = event;
                const azimuthalAngle = target.getAzimuthalAngle();
                const degAngle = Math.floor(azimuthalAngle / Math.PI * 180);
                const { degAngle: prevDegAngle } = self.state;
                if (prevDegAngle !== degAngle && degAngle > -65 && degAngle < -20) {
                    self.setState({ degAngle });
                };
                // console.log(degAngle);
            });
            // controls.minDistance = 10;
            // controls.maxDistance = 100;
            controls.enableZoom = false;
            controls.enableKeys = false;//禁用键盘
            controls.target = new THREE.Vector3(0, 0, -13000);

            // 上下旋转范围
            // controls.minPolarAngle = Math.PI * (50 / 180);
            // controls.maxPolarAngle = Math.PI * (50 / 180);
            controls.minPolarAngle = Math.PI * (30 / 180);
            controls.maxPolarAngle = Math.PI * (80 / 180);
            // 左右旋转范围
            controls.minAzimuthAngle = -Math.PI * (130 / 180);
            controls.maxAzimuthAngle = Math.PI * (20 / 180);
            // controls.minAzimuthAngle = Math.PI * (60 / 180);
            // controls.maxAzimuthAngle = Math.PI * (150 / 180);

            controls.update();

            // document.addEventListener( 'mousemove', onDocumentMouseMove, false );

            //

            // window.addEventListener('resize', onWindowResize, false);
            self.renderer = renderer;
            self.camera = camera;

        }

        function animate() {

            requestAnimationFrame(animate);
            render();

        }

        function render() {
            // camera.position.x += ( mouseX - camera.position.x ) * .05;
            // camera.position.y += ( - mouseY - camera.position.y ) * .05;

            // camera.lookAt( scene.position );

            renderer.render(scene, camera);

        }
    }

    refNodeFunc = (refNode) => {
        this.refNode = refNode;
    }

    onResize = (conf) => {
        // console.log(conf);
        this.renderer.setSize(this.refNode.offsetWidth, this.refNode.offsetHeight);
        this.camera.aspect = this.refNode.offsetWidth / this.refNode.offsetHeight;
        this.camera.updateProjectionMatrix();
    }

    render() {
        const { infoVisible, infoList, degAngle } = this.state;
        let deltaAngle = 0;
        if (degAngle !== null) {
            deltaAngle = degAngle + 65;
        };
        const { timeRange } = this.props;
        let timeRangeText = "-年-月-日- -年-月-日";
        if (timeRange !== null) {
            timeRangeText = parseTimeRange(timeRange)
                .map(time => time.format('YYYY年MM月DD日'))
                .join('- ');
        };

        return (
            <div className="main_page_wrapper_2">
                <div className="header_out_wrapper">
                    <div className="title_text">全场总览</div>
                    {/* <div className="date_text">2020年09月06日</div> */}
                    <div className="date_text">{timeRangeText}</div>
                    <div style={{ marginRight: 10 }}>
                        <TimeRangePicker timeRange={timeRange} />
                    </div>
                </div>
                <div className="content_out_wrapper">
                    <div className="content_wrapper">
                        <div className="top_wrapper">
                            <div className="left_chart_wrapper" ref={this.refNodeFunc}>
                                <div style={{ height: 0, width: "100%" }}>
                                    <DomResize onResize={this.onResize} />
                                </div>
                                {
                                    infoVisible && (
                                        infoList
                                            .map((item, index) => {
                                                const { name, position, value, warning, percent_4_union, deltaPos } = item;

                                                const [originL, originT] = position;
                                                const [deltaL, deltaT] = deltaPos;
                                                let left = originL + deltaAngle * deltaL * 2;
                                                let top = originT + deltaAngle * deltaT * 2;
                                                if (top < 15) {
                                                    top = 15;
                                                } else if (top > 546) {
                                                    top = 546;
                                                };
                                                if (left < 10) {
                                                    left = 10;
                                                } else if (left > 1100) {
                                                    left = 1100;
                                                };

                                                const textColor = warning ? 'rgba(255, 97, 112, 1)' : 'rgba(255, 255, 255, 1)';
                                                const bckCircleColor = warning ? '0px 10px 8px 0px rgba(255, 97, 112, 0.34) inset' : '0px 10px 8px 0px rgba(68, 243, 218, 0.34) inset';
                                                const rotateDeg = 180 * value / 100;
                                                const outlineCircleColor = warning ? 'rgba(255, 97, 112, 1)' : 'rgba(68, 243, 218, 1)';
                                                return (
                                                    <div className="chart_info_wrapper" key={index} style={{ left, top }}>
                                                        <div className="top_block_wrapper">
                                                            <div className="circle_outline_wrapper">
                                                                <div className="circle_wrapper">
                                                                    <div className="rotate_block_1">
                                                                        <div className="colored_block" style={{
                                                                            transform: `rotate(${rotateDeg}deg)`,
                                                                            backgroundColor: outlineCircleColor,
                                                                        }}></div>
                                                                    </div>
                                                                    <div className="inner_hover_circle" />
                                                                </div>
                                                            </div>
                                                            <div className="back_circle_wrapper">
                                                                <div className="back_circle" style={{ boxShadow: bckCircleColor }} />
                                                            </div>
                                                            <div className="value_text" style={{ color: textColor }}>{value}</div>
                                                        </div>
                                                        <div className="icons_wrapper">
                                                            {
                                                                percent_4_union
                                                                    .map((isFirst, index) => {
                                                                        return (
                                                                            <div className="icon_wrapper" key={index}>
                                                                                <img alt="" src={ICON_4_ITEMS[index][isFirst ? 0 : 1]} className="icon_img" />
                                                                            </div>
                                                                        );
                                                                    })
                                                            }
                                                        </div>
                                                        <div className="name">{name}</div>
                                                    </div>
                                                );
                                            })
                                    )
                                }
                            </div>
                            <div className="right_table_wrapper">
                                <RightTable />
                            </div>
                        </div>
                        <div className="bottom_wrapper">
                            <BottomPanels />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
