import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const Wrapper = (props) => {
    const {
        // header,
        children, icon, title, titleOnClick, onCancel,
    } = props;
    // let headerNode = null;
    // if (typeof header === "function") {
    //     headerNode = header();
    // };
    const titleClickable = typeof titleOnClick === "function";
    let titleStyle = { height: '100%', paddingLeft: 12, display: 'flex', alignItems: 'center' };
    if (titleClickable) {
        titleStyle = Object.assign({ cursor: 'pointer', userSelect: 'none' }, titleStyle);
    };
    const cancelable = typeof onCancel === "function";
    return (
        <div style={{ border: '1px solid rgba(68,242,217,0.2)', backgroundColor: '#212830' }}>
            <div style={{ backgroundColor: '#0D1115', height: 46 }}>
                <div
                    style={titleStyle}
                    {
                    ...titleClickable ?
                        {
                            onClick: titleOnClick
                        } : {}
                    }
                >
                    <img alt="" src={icon} width="24" height="24" />
                    <div style={{ marginLeft: 4, fontSize: 18, lineHeight: '18px', height: 18, color: '#ffffff' }}>{title}</div>
                    {
                        cancelable && (
                            <CloseOutlined style={{ fontSize: 22, marginLeft: 'auto', marginRight: 19, color: '#596168' }} onClick={onCancel} />
                        )
                    }
                </div>
            </div>
            <div style={{ height: 1, backgroundColor: 'rgba(68,243,218,0.2)' }} />
            <div style={{ padding: 12 }}>
                {children}
            </div>
        </div>
    );
};

export default Wrapper;
