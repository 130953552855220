import React from 'react';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';

const default_color = [255, 255, 255];

const Comp = (props) => {
    const { width, height, src, color, alpha } = props;
    const [canvasSize, setCanvasSize] = useState(null);
    const [imgLoaded, setImgLoaded] = useState(false);
    const rgbStr = useMemo(() => {
        return !!color ? color.join(',') : null;
    }, [color]);
    const currAlpha = useMemo(() => {
        if (typeof alpha === 'number') {
            if (alpha >= 0 && alpha <= 255) {
                return alpha;
            };
        };
        return null;
    }, [alpha]);
    useEffect(() => {
        // 目前仅支持首次加载的width和height渲染，变化后不变
        if (!!imgNode.current) {
            setCanvasSize([imgNode.current.offsetWidth, imgNode.current.offsetHeight]);
        };
    }, []);
    const canvasNode = useRef(null);
    const imgNode = useRef(null);
    const renderCanvas = useCallback(() => {
        // console.log('renderCanvas');
        if (!!imgLoaded) {
            if (!!canvasSize) {
                if (!!canvasNode.current) {
                    if (!!imgNode.current) {
                        const ctx = canvasNode.current.getContext('2d');
                        ctx.drawImage(imgNode.current, 0, 0, imgNode.current.offsetWidth, imgNode.current.offsetHeight);
                        let imgData = ctx.getImageData(0, 0, canvasNode.current.offsetWidth, canvasNode.current.offsetHeight);
                        let imgColor = [].concat(default_color);
                        if (!!rgbStr) {
                            imgColor = rgbStr.split(',');
                        };

                        const alphas = [];
                        for (let i = 0; i < imgData.data.length; i += 4) {
                            if (!alphas.includes(imgData.data[i + 3])) {
                                alphas.push(imgData.data[i + 3]);
                            };
                        };
                        const maxAlpha = Math.max(...alphas);
                        let multiNum = 1;
                        if (typeof currAlpha === 'number') {
                            multiNum = currAlpha / maxAlpha;
                        };

                        for (let i = 0; i < imgData.data.length; i += 4) {
                            imgData.data[i] = imgColor[0];
                            imgData.data[i + 1] = imgColor[1];
                            imgData.data[i + 2] = imgColor[2];
                            imgData.data[i + 3] *= multiNum;
                        };
                        ctx.putImageData(imgData, 0, 0);
                    };
                };
            };
        };
    }, [
        canvasSize, rgbStr, imgLoaded, currAlpha,
        // width, height,
        // src, //暂不考虑src变化重绘
    ]);
    useEffect(() => {
        renderCanvas();
    }, [renderCanvas]);
    return (
        <div style={{ position: 'relative' }}>
            <div style={{
                opacity: 0,
                width: typeof width === 'number' ? width : 0,
                height: typeof height === 'number' ? height : 0
            }}>
                <img
                    alt="" src={src}
                    ref={imgNode}
                    width="100%"
                    height="100%"
                    onLoad={() => { setImgLoaded(true); }}
                />
            </div>
            {
                !!canvasSize && (
                    <canvas
                        ref={canvasNode}
                        width={canvasSize[0]}
                        height={canvasSize[1]}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                )
            }
        </div>
    );
};

export default Comp;
