import React from 'react'
import './index.scss'
import { Divider } from 'antd'

const ProductionCost = props => {
  const {
    // 生产成本
    PLANT_RAWG_RAW_CHARCUM_HW: raw_cost_num, // 原料气
    RAW_COSTRT_BCMK: raw_cost_bcmk, // 基准点位
    PLANT_ALL_ELEC_CHARCUM_HW: elec_cost_num, // 总耗电
    ELEC_COSTRT_BCMK: elec_cost_bcmk, // 基准点位
    PLANT_FUEL_FG_CHARCUM_HW: fuel_cost_num, // 自耗气
    FUEL_COSTRT_BCMK: fuel_cost_bcmk, // 基准点位
    PLANT_ALL_OTHER_CHARCUM_HW: other_cost_num, // 其他
    MISC_COSTRT_BCMK: misc_cost_bcmk, // 基准点位
    // 产值构成
    PLANT_C5P_PRDT_CHARCUM_HW: C5P_output_rate, // 烯烃
    PLANT_C4H10_PRDT_CHARCUM_HW: C4H10_output_rate, // 丁烷
    PLANT_C3H8_PRDT_CHARCUM_HW: C3H8_output_rate, // 丙烷
    PLANT_C2H6_PRDT_CHARCUM_HW: C2H6_output_rate, // 乙烷
    PLANT_CNG_PRDT_CHARCUM_HW: cng_output_rate, // 甲烷
    // 成本构成
    PLANT_ALL_CM_COSTRT_HW: cm_cost_rate, // CM
    PLANT_ALL_OTHER_COSTRT_HW: other_cost_rate, // 其他
    PLANT_ALL_FUEL_COSTRT_HW: fuel_cost_rate, // 燃料
    PLANT_ALL_ELEC_COSTRT_HW: elec_cost_rate, // 耗电
    PLANT_ALL_RAW_COSTRT_HW: raw_cost_rate // 原料
  } = props.productionCostObj

  // 处理生产成本 》 比标准
  const costBase = {
    raw: {
      value: raw_cost_rate ? Math.abs(raw_cost_rate * 100 - 55).toFixed(2) : '--',
      className: raw_cost_rate * 100 - 55 > 0 ? 'up' : raw_cost_rate * 100 - 55 < 0 ? 'down' : '',
      width: (raw_cost_rate * 100 || 0) - 55 > 0 ? `calc(${raw_cost_rate * 100}% + 2px)` : raw_cost_rate * 100+ '%'
    },
    elec: {
      value: elec_cost_rate ? Math.abs(elec_cost_rate * 100 - 5).toFixed(2) : '--',
      className: elec_cost_rate * 100 - 5 > 0 ? 'up' : elec_cost_rate * 100 - 5 < 0 ? 'down' : '',
      width:
        (elec_cost_rate * 100 || 0) - 5 > 0 ? `calc(${elec_cost_rate * 100}% + 2px)` : elec_cost_rate * 100 + '%'
    },
    fuel: {
      value: fuel_cost_rate ? Math.abs(fuel_cost_rate * 100 - 3).toFixed(2) : '--',
      className: fuel_cost_rate * 100 - 3 > 0 ? 'up' : fuel_cost_rate * 100 - 3 < 0 ? 'down' : '',
      width: (fuel_cost_rate * 100 || 0) - 3 > 0 ? `calc(${fuel_cost_rate * 100}% + 2px)` : fuel_cost_rate * 100 + '%'
    },
    other: {
      value: other_cost_rate ? Math.abs(other_cost_rate * 100 - 2).toFixed(2) : '--',
      className: other_cost_rate - 2 > 0 ? 'up' : other_cost_rate * 100 - 2 < 0 ? 'down' : '',
      width:
        (other_cost_rate * 100 || 0) - 2 > 0 ? `calc(${other_cost_rate * 100}% + 2px)` : other_cost_rate * 100 + '%'
    }
  }
  // 处理 产值构成/成本构成 数据
  const barHeightData = {
    C5P: {
      rate: C5P_output_rate ? C5P_output_rate.toFixed(1) : '--',
      // height: Math.ceil((200 * (C5P_output_rate || 0)) / 100),
      height: Math.ceil((200 * ((C5P_output_rate/ (C5P_output_rate + C4H10_output_rate + C3H8_output_rate + C2H6_output_rate + cng_output_rate)) || 0))),
    },
    C4H10: {
      rate: C4H10_output_rate ? C4H10_output_rate.toFixed(1) : '--',
      // height: Math.ceil((200 * (C4H10_output_rate || 0)) / 100)
      height: Math.ceil((200 * ((C4H10_output_rate / (C5P_output_rate + C4H10_output_rate + C3H8_output_rate + C2H6_output_rate + cng_output_rate)) || 0)))
    },
    C3H8: {
      rate: C3H8_output_rate ? C3H8_output_rate.toFixed(1) : '--',
      // height: Math.ceil((200 * (C3H8_output_rate || 0)) / 100)
      height: Math.ceil((200 * ((C3H8_output_rate / (C5P_output_rate + C4H10_output_rate + C3H8_output_rate + C2H6_output_rate + cng_output_rate)) || 0)))

    },
    C2H6: {
      rate: C2H6_output_rate ? C2H6_output_rate.toFixed(1) : '--',
      // height: Math.ceil((200 * (C2H6_output_rate || 0)) / 100)
      height: Math.ceil((200 * ((C2H6_output_rate / (C5P_output_rate + C4H10_output_rate + C3H8_output_rate + C2H6_output_rate + cng_output_rate)) || 0)))
    },
    CNG: {
      rate: cng_output_rate ? cng_output_rate.toFixed(1) : '--',
      // height: Math.ceil((200 * (cng_output_rate || 0)) / 100)
      height: Math.ceil((200 * ((cng_output_rate / (C5P_output_rate + C4H10_output_rate + C3H8_output_rate + C2H6_output_rate + cng_output_rate)) || 0)))
    },
    leftTotal: C5P_output_rate + C4H10_output_rate + C3H8_output_rate + C2H6_output_rate + cng_output_rate,
    CM: { // 毛利
      // rate: cm_cost_rate ? cm_cost_rate.toFixed(1) : '--',
      rate: Number(( 1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate) * 100).toFixed(1),
      height: Math.ceil((200 * (Number( 1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate) * 100|| 0)) / 100)
    },
    other: {
      rate: other_cost_rate ? (other_cost_rate * 100).toFixed(1) : '--',
      // height: Math.ceil((200 * (other_cost_rate * 100 || 0)) / 100)
      height: Math.ceil( 200 * (other_cost_rate / (other_cost_rate + fuel_cost_rate + elec_cost_rate + raw_cost_rate + (1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate))))
    },
    fuel: {
      rate: fuel_cost_rate ? (fuel_cost_rate * 100).toFixed(1) : '--',
      // height: Math.ceil((200 * (fuel_cost_rate || 0)) / 100)
      height: Math.ceil( 200 * (fuel_cost_rate / (other_cost_rate + fuel_cost_rate + elec_cost_rate + raw_cost_rate + (1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate))))
    },
    elec: {
      rate: elec_cost_rate ? (elec_cost_rate * 100).toFixed(1) : '--',
      // height: Math.ceil((200 * (elec_cost_rate * 100 || 0)) / 100)
      height: Math.ceil( 200 * (elec_cost_rate / (other_cost_rate + fuel_cost_rate + elec_cost_rate + raw_cost_rate + (1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate))))
    },
    raw: {
      rate: raw_cost_rate ? (raw_cost_rate * 100).toFixed(1) : '--',
      // height: Math.ceil((200 * (raw_cost_rate * 100 || 0)) / 100)
      height: Math.ceil( 200 * (raw_cost_rate / (other_cost_rate + fuel_cost_rate + elec_cost_rate + raw_cost_rate + (1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate))))

    },
    rightTotal: other_cost_rate + fuel_cost_rate + elec_cost_rate + raw_cost_rate + (1 - other_cost_rate - elec_cost_rate - raw_cost_rate - fuel_cost_rate)
  }
  return (
    <div className="productionConst">
      <div className="model-title">生产成本</div>
      <div className="productionConst-top">
        <div className="header">
          <div>产品分类</div>
          <div>本周 万元</div>
          <div>占比</div>
          <div>比标准</div>
        </div>
        <div className="row">
          <div className="row-title">
            <div>原料气</div>
            <div className={costBase.raw.className}>{Number(raw_cost_num).toFixed(2) || '--'}</div>
            <div>{Number(barHeightData.raw.rate).toFixed(1)}%</div>
            <div>
              <div className={`triangle ${costBase.raw.className}`}></div>
              <span>{costBase.raw.value}%</span>
            </div>
          </div>
          <div className="row-process part1">
            <div className={costBase.raw.className} style={{ width: costBase.raw.width }}></div>
            <div className="process-border" style={{ width: '55%' }}></div>
          </div>
        </div>
        <div className="row">
          <div className="row-title">
            <div>总耗电</div>
            <div className={costBase.elec.className}>{Number(elec_cost_num).toFixed(2) || '--'}</div>
            <div>{Number(barHeightData.elec.rate).toFixed(1)}%</div>
            <div>
              <div className={`triangle ${costBase.elec.className}`}></div>
              <span>{costBase.elec.value}%</span>
            </div>
          </div>
          <div className="row-process part2">
            <div className={costBase.elec.className} style={{ width: costBase.elec.width }}></div>
            <div className="process-border" style={{ width: '5%' }}></div>
          </div>
        </div>
        <div className="row">
          <div className="row-title">
            <div>自耗气</div>
            <div className={costBase.fuel.className}>{fuel_cost_num ? Number(fuel_cost_num).toFixed(2): '--'}</div>
            <div>{Number(barHeightData.fuel.rate).toFixed(1)}%</div>
            <div>
              <div className={`triangle ${costBase.fuel.className}`}></div>
              <span>{costBase.fuel.value}%</span>
            </div>
          </div>
          <div className="row-process part3">
            <div className={costBase.fuel.className} style={{ width: costBase.fuel.width }}></div>
            <div className="process-border" style={{ width: '3%' }}></div>
          </div>
        </div>
        <div className="row">
          <div className="row-title">
            <div>其他</div>
            {/*<div className={costBase.other.className}>{Number(other_cost_num).toFixed(2) || '--'}</div>*/}
            <div className={costBase.other.className}>--</div>
            <div>{Number(barHeightData.other.rate).toFixed(1)}%</div>
            <div>
              <div className={`triangle ${costBase.other.className}`}></div>
              <span>{costBase.other.value}%</span>
            </div>
          </div>
          <div className="row-process part4">
            <div className={costBase.other.className} style={{ width: costBase.other.width }}></div>
            <div className="process-border" style={{ width: '2%' }}></div>
          </div>
        </div>
        <Divider />
      </div>
      <div className="productionConst-bottom">
        <div className="partLeft">
          <div className="partLeft-title">产值构成</div>
          <div className="partLeft-main">
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#CBFCEC', height: barHeightData.C5P.height}}/>
            </div>
            <div className="part-item">
              <div
                className="bar-item"
                style={{
                  backgroundColor: '#CBFCEC',
                  height: barHeightData.C4H10.height
                }}
              />
            </div>
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#A7FFE1', height: barHeightData.C3H8.height }}/>
            </div>
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#6BEED2', height: barHeightData.C2H6.height }}/>
            </div>
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#33C2A4', height: barHeightData.CNG.height }}/>
            </div>
          </div>
        </div>
        <div className="part-text-left">
            <div className="part-text-left-item">
              <span>烯烃</span>
              <span>{Number((barHeightData.C5P.rate / barHeightData.leftTotal) * 100).toFixed(1)}%</span>
            </div>
            <div className="part-text-left-item">
              <span>丁烷</span>
              <span>{Number((barHeightData.C4H10.rate / barHeightData.leftTotal) * 100).toFixed(1)}%</span>
            </div>
            <div className="part-text-left-item">
              <span>丙烷</span>
              <span>{Number((barHeightData.C3H8.rate / barHeightData.leftTotal) * 100).toFixed(1)}%</span>
            </div>
            <div className="part-text-left-item">
                <span>乙烷</span>
                <span>{Number((barHeightData.C2H6.rate / barHeightData.leftTotal) * 100).toFixed(1)}%</span>
            </div>
            <div className="part-text-left-item">
              <span>甲烷</span>
              <span>{Number((barHeightData.CNG.rate / barHeightData.leftTotal) * 100).toFixed(1)}%</span>
            </div>
        </div>
        <div className="partRight">
          <div className="partRight-title">成本构成</div>
          <div className="partRight-main">
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#FEBC05', height: barHeightData.CM.height }}/>
            </div>
            <div className="part-item">
              <div className="bar-item" style={{backgroundColor: '#CBFCEC', height: barHeightData.other.height}}/>
            </div>
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#A7FFE1', height: barHeightData.fuel.height }}/>
            </div>
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#6BEED2', height: barHeightData.elec.height }}/>
            </div>
            <div className="part-item">
              <div className="bar-item" style={{ backgroundColor: '#33C2A4', height: barHeightData.raw.height }}/>
            </div>
          </div>
        </div>
          <div className="part-text-right">
              <div className="part-text-right-item">
                <span>毛利&nbsp;</span>
                <span>{Number(barHeightData.CM.rate / barHeightData.rightTotal).toFixed(1)}%</span>
              </div>
              <div className="part-text-right-item">
                  <span>其他</span>
                  <span>{Number(barHeightData.other.rate / barHeightData.rightTotal).toFixed(1)}%</span>
              </div>
              <div className="part-text-right-item">
                <span>燃料</span>
                <span>{Number(barHeightData.fuel.rate / barHeightData.rightTotal).toFixed(1)}%</span>
              </div>
              <div className="part-text-right-item">
                <span>耗电</span>
                <span>{Number(barHeightData.elec.rate / barHeightData.rightTotal).toFixed(1)}%</span>
              </div>
              <div className="part-text-right-item">
                <span>原料</span>
                <span>{Number(barHeightData.raw.rate / barHeightData.rightTotal).toFixed(1)}%</span>
              </div>
          </div>
      </div>
      <div></div>
    </div>
  )
}
export default ProductionCost
