import React, { useMemo } from 'react'
import style from './index.module.scss'

const getValueText = (v, precision = 2) => {
    if (typeof v === 'number') {
        const multiNum = Math.pow(10, precision);
        return Math.floor(v * multiNum) / multiNum;
    };
    return '--';
};

const Comp = (props) => {
    const { name, unit, values, precisions } = props;
    const valueTextList = useMemo(() => {
        const actualValues = !!values ? values : [];
        const actualPrecisions = !!precisions ? precisions : [];
        return new Array(6).fill(null).map((v, index) => getValueText(actualValues[index], typeof actualPrecisions[index] === 'number' ? actualPrecisions[index] : (index === 2 ? 1 : 2)))
    }, [values, precisions]);
    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="title">{name}</div>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2" style={{ height: 24 }}>
                                <div className="r1_value_wrapper">
                                    <div className="value">{valueTextList[0]}</div>
                                    <div className="space"></div>
                                    <div className="unit">{unit}</div>
                                </div>
                            </td>
                            <td style={{ width: 8 }}>
                                <div className="arrow_wrapper">
                                    <div className="arrow_top"></div>
                                </div>
                            </td>
                            <td style={{ width: 40 }}>
                                <div className="percent_wrapper">
                                    <div className="text">{valueTextList[3]}%</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ height: 8 }} />
                        </tr>
                        <tr>
                            <td style={{ height: 16, width: 40 }}>
                                <div className="value_wrapper">{valueTextList[1]}</div>
                            </td>
                            <td>
                                <div className="unit_wrapper">月</div>
                            </td>
                            <td style={{ width: 8 }}>
                                <div className="arrow_wrapper">
                                    <div className="arrow_down"></div>
                                </div>
                            </td>
                            <td>
                                <div className="percent_wrapper">
                                    <div className="text">{valueTextList[4]}%</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ height: 4 }} />
                        </tr>
                        <tr>
                            <td style={{ height: 16, width: 40 }}>
                                <div className="value_wrapper">{valueTextList[2]}</div>
                            </td>
                            <td>
                                <div className="unit_wrapper">年</div>
                            </td>
                            <td style={{ width: 8 }}>
                                <div className="arrow_wrapper">
                                    <div className="arrow_top"></div>
                                </div>
                            </td>
                            <td>
                                <div className="percent_wrapper">
                                    <div className="text">{valueTextList[5]}%</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Comp
