import Page from './ShiftCompareModal.js';
import { connect } from 'react-redux';
import { post } from '../../../../common/utils';
import { queryTimeRangeFormat } from '../utils.js';

const getShiftDetails = (kpi_factor_ids) => {
    return (dispatch, getState) => {
        const { factorSummaryV2 } = getState();
        const { timeRange } = factorSummaryV2;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        return post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_detailed_history_data_with_details', {
            time_begin,
            time_end,
            kpi_factor_ids,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                return {
                    isSuccess: true,
                    results: retData.data.results,
                };
            };
            return {
                isSuccess: false,
            };
        });
    };
};

const mapStateToProps = state => {
    // const { factorSummaryV2 } = state;
    // const { } = factorSummaryV2;
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getShiftDetails: (kpi_factor_ids) => dispatch(getShiftDetails(kpi_factor_ids)),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;