// import moment from "moment";

const DEFAULT_LINE_COLOR = '#40A1E1';
// const DATA_LIST = new Array(20)
//     .fill(0)
//     .map((v, index) => {
//         return [moment().add(index, "hours").unix() * 1000, Math.floor(Math.random() * 500)];
//     });

const DEFAULT_OPTION = {
    tooltip: {
        trigger: "axis",
    },
    animation: false,
    grid: {
        containLabel: true,
        left: 24,
        top: 24,
        right: 24,
        bottom: 24,
    },
    xAxis: {
        type: 'time',
        axisLine: {
            lineStyle: {
                color: 'rgba(225, 230, 240, 1)',
            },
        },
        axisLabel: {
            fontSize: 12,
            color: 'rgba(104, 125, 163, 1)',
        },
        axisTick: {
            show: false,
        },
    },
    yAxis: [
        {
            type: 'value',
            position: 'left',
            scale: true,// true时不一定显示0
            axisLine: {
                show: false,
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(225, 230, 240, 1)',
                    type: "dashed",
                },
            },
            axisLabel: {
                fontSize: 12,
                color: 'rgba(104, 125, 163, 1)',
            },
        },
        {
            type: 'value',
            position: 'right',
            scale: true,// true时不一定显示0
            axisLine: {
                show: false,
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(225, 230, 240, 1)',
                    type: "dashed",
                },
            },
            axisLabel: {
                fontSize: 12,
                color: 'rgba(104, 125, 163, 1)',
            },
        },
    ],
    series: [
        // {
        //     data: DATA_LIST,
        //     type: 'line',
        //     itemStyle: {
        //         color: DEFAULT_LINE_COLOR,
        //     },
        //     yAxisIndex: 0,
        //     symbol: 'none',
        // },
    ],
};

export {
    DEFAULT_OPTION,
    DEFAULT_LINE_COLOR,
};