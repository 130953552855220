import React from 'react'
import { Modal, Form, Input } from 'antd'
import style from './index.module.scss'

const AddShiftCycleModal = props => {
  const { addShiftClcle, closeAddModal } = props

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  const [addForm] = Form.useForm()

  /** 点击确定 */
  const onSubmitForm = async () => {
    try {
      const values = await addForm.validateFields()
      console.log('Success:', values)
      const { name, days } = values
      if (typeof addShiftClcle === 'function') addShiftClcle({ name, dayNum: Number(days) })
    } catch (errorInfo) {}
  }

  return (
    <Modal
      wrapClassName={style['addShiftClcleModal']}
      visible={true}
      onOk={onSubmitForm}
      onCancel={closeAddModal}
      width="344px"
    >
      <div className="title">添加排班周期</div>
      <Form {...layout} form={addForm} autoComplete="off">
        <Form.Item
          label="排班名称"
          name="name"
          rules={[{ required: true, message: '请输入排班名称!' }]}
        >
          <Input placeholder="请输入排班名称" maxLength={20} />
        </Form.Item>

        <Form.Item
          label="排班周期"
          name="days"
          rules={[
            { required: true, message: '请输入排班周期!' },
            { pattern: new RegExp(/^([1-9]\d{0,5})$/, 'g'), message: '只能输入正整数' }
          ]}
        >
          <Input placeholder="请输入排班周期" maxLength={5} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddShiftCycleModal
