import React from "react";
import './index.scss'
import AbnormalLineChart from "./LineChart";
const Abnormal = (props) => {
    const {
        // ethaneRate,
        // ethanePercent,
        // ethaneWeek,
        // ethaneMonth,
        // ethaneYear,
        // ethaneReason,
        // propaneRate,
        // propanePercent,
        // propaneWeek,
        // propaneMonth,
        // propaneYear,
        // propaneReason,
        // butaneRate,
        // butanePercent,
        // butaneWeek,
        // butaneMonth,
        // butaneYear,
        // butaneReason
        abnormalData
    } = props
    return (
        <div className="abnormal">
            <div className="abnormal-title">异常指标</div>
            <ul>
                {
                    abnormalData && abnormalData.map((item, index) => {
                        return (
                            <li className="itemList" key={item.tag_name} style={{borderBottom: index === 2? 'none': '1px solid #F1F1F1'}}>
                                <div className="item-left">{item.time_begin}</div>
                                <div className="item-center">
                                    <div className="item-center-top">{item.content}</div>
                                    <div className="item-center-center">
                                        <span>异常次数：</span>
                                        <span>本周{item.week_num}次，本月{item.month_num}次，本年{item.year_num}次</span>
                                    </div>
                                </div>
                                <AbnormalLineChart abnormalChart={item.lineChart} abnormalTime={item.time} basicTop={item.lower_limit}/>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
    // TODO 留着怕万一改变数据结构 以防万一
    {/*<li className="itemList">*/}
    {/*    <div className="item-left">--</div>*/}
    {/*    <div className="item-center">*/}
    {/*        <div className="item-center-top">乙烷效率 {ethaneRate}%，比标准{ethanePercent}%</div>*/}
    {/*        <div className="item-center-center">*/}
    {/*            <span>异常次数：</span>*/}
    {/*            <span>本周{ethaneWeek}次，本月{ethaneMonth}次，本年{ethaneYear}次</span>*/}
    {/*        </div>*/}
    {/*        <div className="item-center-bottom">*/}
    {/*            <span>原因分析：</span>*/}
    {/*            <span>{ethaneReason}</span>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*    /!*<div>*!/*/}
    {/*    /!*    <AbnormalLineChart />*!/*/}
    {/*    /!*</div>*!/*/}
    {/*</li>*/}
    {/*<li className="itemList">*/}
    {/*    <div className="item-left">--</div>*/}
    {/*    <div className="item-center">*/}
    {/*        <div className="item-center-top">丙烷效率 {propaneRate}%，比标准{propanePercent}%</div>*/}
    {/*        <div className="item-center-center">*/}
    {/*            <span>异常次数：</span>*/}
    {/*            <span>本周{propaneWeek}次，本月{propaneMonth}次，本年{propaneYear}次</span>*/}
    {/*        </div>*/}
    {/*        <div className="item-center-bottom">*/}
    {/*            <span>原因分析：</span>*/}
    {/*            <span>{propaneReason}</span>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*    /!*<div>*!/*/}
    {/*    /!*    <AbnormalLineChart />*!/*/}
    {/*    /!*</div>*!/*/}
    {/*</li>*/}
    {/*<li className="itemList">*/}
    {/*    <div className="item-left">--</div>*/}
    {/*    <div className="item-center">*/}
    {/*        <div className="item-center-top">丁烷效率 {butaneRate}%，比标准{butanePercent}%</div>*/}
    {/*        <div className="item-center-center">*/}
    {/*            <span>异常次数：</span>*/}
    {/*            <span>本周{butaneWeek}次，本月{butaneMonth}次，本年{butaneYear}次</span>*/}
    {/*        </div>*/}
    {/*        <div className="item-center-bottom">*/}
    {/*            <span>原因分析：</span>*/}
    {/*            <span>{butaneReason}</span>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*    /!*<div>*!/*/}
    {/*    /!*    <AbnormalLineChart />*!/*/}
    {/*    /!*</div>*!/*/}
    {/*</li>*/}
}
export default Abnormal
