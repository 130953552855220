import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, mockList } from './utils';

const Page = (props) => {

    const refBottom = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, mockList);
    }, []);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="left">当日产量与排放量趋势</div>
                <div className="right"></div>
            </div>
            <div className="bottom">
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
