import RaceList from './RaceList/RaceList.js';
import ExamRules from './ExamRules/ExamRules.js';
import AbnormalPage from './AbnormalPage/AbnormalPage.js';
import ExamAnalysis from './ExamAnalysis';

// 支持不存在children的tab
const tabs = [
    {
        name: '竞赛看板',
        children: [
            {
                name: '分析',
                comp: ExamAnalysis,
            },
            {
                name: '结果',
                comp: RaceList,
            },
        ],
    },
    {
        name: '竞赛设置',
        children: [
            {
                name: '规则',
                comp: ExamRules,
            },
            {
                name: '剔除时段',
                comp: AbnormalPage,
            },
        ],
    },
];

const tmp_users_exchange = {
    "admin": "admin",
    "楚理军": "耿欢",
    "刘慧": "陈大鹏",
    "张杰": "王华丰",
    "韩崇厚": "曾雪山",
    "向杰": "陈丽丽",
    "雷瑞敏": "李郑强",
    "李亮": "羊文君",
    "骆建良": "王大雷",
    "蔡新城": "张三峰",
    "梁鹏": "李仕庆",
};

export {
    tabs,
    tmp_users_exchange,
};
