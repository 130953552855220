import React, { useMemo, useEffect } from 'react';
import { withRouter } from 'react-router';
import style from './index.module.scss';
import Tabs from 'components/Tabs';
import PersonMng from 'containers/PersonMng';
import RoleMngSetting from 'containers/RoleMngSetting';
import SchedulingManagement from 'containers/SchedulingManagement'
import OrgMng from '../OrgMng';

const isDemo = ['xinchen', 'demo'].includes(process.env.REACT_APP_PROJECT_TAG);
const tabs = [
    {
        name: '用户管理',
        comp: <PersonMng />,
        key: 'PersonMng',
    },
    {
        name: '角色管理',
        comp: <RoleMngSetting />,
        key: 'RoleMngSetting',
    },
]
    .concat(
        isDemo ? [
            {
                name: '部门管理',
                comp: <OrgMng />,
                key: 'OrgMng',
            },
            {
                name: '排班管理',
                key: 'ShiftMng',
                comp: <SchedulingManagement />,
            },
        ] : [],
    );

const Comp = (props) => {
    const { selectedPageTab } = props;
    const selectedIndex = useMemo(() => {
        if (!!selectedPageTab) {
            let index = tabs.indexOf(selectedPageTab);
            if (index !== -1) {
                return index;
            };
        };
        return null;
    }, [selectedPageTab]);

    const pageKey = useMemo(() => {
        let nextPageKey = null;
        if (!!props.location) {
            nextPageKey = new URLSearchParams(props.location.search).get('pageKey');
        };
        return nextPageKey;
    }, [props]);

    useEffect(() => {
        // 通过url内部pageKey控制显示什么组件，起到url跳转到指定页面的作用
        let nextSelectedTab = null;
        const filteredTabs = tabs.filter(({ key }) => key === pageKey);
        if (filteredTabs.length > 0) {
            nextSelectedTab = filteredTabs[0];
        } else if (tabs.length > 0) {
            nextSelectedTab = tabs[0];
        };
        const { onChange } = props;
        if (typeof onChange === 'function') {
            onChange(nextSelectedTab);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageKey]);

    const onChange = (index) => {
        if (!!props.location) {
            const { pathname } = props.location;
            if (typeof pathname === 'string') {
                const nextPageKey = tabs[index].key;
                props.history.push(`${pathname}${typeof nextPageKey === 'string' ? `?pageKey=${nextPageKey}` : ""}`);
            };
        };
    };
    return (
        <div className={style['wrapper']}>
            <Tabs tabs={tabs} selectedIndex={selectedIndex} onChange={onChange} itemWidth={88} />
        </div>
    );
};

export default withRouter(Comp);
