import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts';

const initChart = (refNode, value) => {
    if (!!refNode) {
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (typeof value === "number" && value > 1) {
            value = 1
        }
        instance.setOption({
            series: [
                {
                    name: '负载率',
                    type: 'pie',
                    radius: ['78%', '100%'],
                    hoverAnimation: false,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    data: typeof value === "number" ? [
                        { value: value, name: '', itemStyle: { color: '#FEBC05' } },
                        { value: 1 - value, name: '', itemStyle: { color: '#DAE4E8' } },
                    ] : [
                        { value: 0, name: '', itemStyle: { color: '#DAE4E8' } },
                    ],
                }
            ]
        });
    }
};

const Comp = (props) => {
    const { data } = props;
    const chartNode = useRef(null);

    const [value, setValue] = useState(null);
    useEffect(() => {
        const val = data?.["LDRT"] ?? null;
        setValue(val);
        initChart(chartNode.current, val);
    }, [data]);

    const valueText = useMemo(() => {
        if (typeof value === 'number') {
            return Math.floor(value * 10000) / 100;
        } else {
            return '--';
        };
    }, [value]);
    return (
        <div className={style['wrapper']}>
            <div className="title">负载率</div>
            <div className="chart">
                <div className="chart_item" ref={chartNode}></div>
                <div className="value_wrapper">
                    <div className="value">{valueText}</div>
                    <div className="unit">%</div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
