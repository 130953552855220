import React, { useEffect, useState, useRef } from 'react'
import style from './index.module.scss'
import moment from 'moment'
import { TIME_RANGE_HOURS, debounceTime, urgencyAndColor } from '../utils'
import { Spin, Empty } from 'antd'
import { get, getCachedUserInfo } from '../../../../common/utils'
import {
  useTemplateClassification
  // useOrganization,
} from './hooks'
import { formatDateTimeToText } from '../../../../utils/dete-format'
import MiniCapsuleDetail from '../../MiniCapsuleDetail'
const baseUrl = process.env.REACT_APP_SERVER_PATH

const getCurrentUserId = () => {
  const userInfo = getCachedUserInfo()
  if (!userInfo || typeof userInfo.user_id !== 'number') {
    // 此处不提示，因为可能与其他地方重复提示
    return null
  }
  return userInfo.user_id
} // 写函数不写外部变量的原因在于，重新登陆后后者不会更新

const Comp = props => {
  const {
    isCurrent,
    endTime,
    newEventCount,
    isFilter,
    filterParams,
    navigateToEvent,
    refreshNum,
    readedUpdateId
  } = props
  const [loading, setLoading] = useState(false)
  const [capsules, setCapsules] = useState(null)
  const currUserId = useRef(getCurrentUserId())
  const [templateClassification] = useTemplateClassification()
  // const [organization] = useOrganization();
  const [activeCapsule, setActiveCapsule] = useState(null)
  const queryLock = useRef(null)
  const wrapperRef = useRef()
  /** 时间轴结束时间变更后重新获取胶囊面板数据 */
  useEffect(() => {
    !isFilter && getCapsules()
    return () => {
      queryLock.current = Symbol()
    }
  }, [endTime])

  /** 定时刷新胶囊面板 */
  useEffect(() => {
    if (!isFilter && refreshNum) {
      getCapsules()
    }
  }, [refreshNum])

  /** 被动更新new标记 */
  useEffect(() => {
    if (!isFilter && readedUpdateId) {
      const index = (capsules || []).findIndex(item => item.id === readedUpdateId)
      if (index !== -1) {
        const tempList = capsules.concat()
        tempList[index].new = 0
        setCapsules(tempList)
      }
    }
  }, [readedUpdateId])

  useEffect(() => {
    isFilter && filterParams && getFilterCapsule()
  }, [filterParams])

  /** 查询胶囊和最新评论 */
  const getCapsules = () => {
    setLoading(true)
    const currLock = (queryLock.current = Symbol())
    get(baseUrl + 'api/v1/event/capsule/capsulesCommentQueries', { 
      startDate: moment(endTime).subtract(TIME_RANGE_HOURS, 'hours').format('YYYY-MM-DD HH:mm:ss'),
      stopDate: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
      useId: 1 // 筛掉没有userId的
    }).then(resultData => {
      if (currLock !== queryLock.current) return
      // console.log('dialog', retData);
      setLoading(false)
      handleCapsules(resultData.data)
    })
  }

  const getFilterCapsule = () => {
    get(`${baseUrl}api/v1/event/capsule/screeningCapsule`, filterParams).then(res => {
      console.log(res)
      handleCapsules(res.data)
    })
  }

  const handleCapsules = resultData => {
    if (!!resultData && resultData.error === 0) {
      if (!!resultData.results) {
        const tempList = resultData.results.map(item => {
          const { name, json_content, orgName, occur_time } = item
          // urgency 紧急程度 目前传回了0，还未定义其他紧急情况时的值，后续再添加
          let timeText = null
          let titleText = ''
          let classifyId = null
          try {
            const jsonText = JSON.parse(json_content)
            if (!!jsonText) {
              if (typeof jsonText.title === 'string') {
                titleText = jsonText.title
              }
              if (typeof jsonText.classify === 'number') {
                classifyId = jsonText.classify
              }
            }
          } catch (error) {}
          const usernameText = typeof name === 'string' ? name : ''
          const orgNameText = typeof orgName === 'string' ? orgName : ''
          try {
            timeText = moment(occur_time).format('YYYY/MM/DD HH:mm:ss')
          } catch (err) {}
          return Object.assign({}, item, {
            timeText,
            titleText,
            usernameText,
            orgNameText,
            classifyId
          })
        })
        setCapsules(tempList)
      } else {
        setCapsules(null)
      }
      !isFilter && calculateEventTimeRange(resultData.results || [])
    }
  }

  /** 点击胶囊弹出胶囊详情缩小版 */
  const eventOnClick = (id, index, isRight) => {
    // 带有new标记的胶囊事件，点击后即消失
    if (capsules[index].new === 1) {
      const tempList = capsules.concat()
      tempList[index].new = 0
      setCapsules(tempList)
      if (typeof props.refreshNewNum === 'function') {
        setTimeout(() => {
          props.refreshNewNum(id)
        }, 500)
      }
    }

    isRight && handleAtRightClass('add', 'move-left')
    setActiveCapsule({ id, isRight })
  }

  /** 编辑胶囊,弹出放大版编辑状态,同时关闭缩小胶囊 */
  const editClick = id => {
    closeMiniCapsulePanel()
    if (typeof props.editEventOnClick === 'function') props.editEventOnClick(id, true)
  }

  /** 关闭缩小版胶囊 */
  const closeMiniCapsulePanel = () => {
    setTimeout(() => {
      setActiveCapsule(null)
      handleAtRightClass('add', 'move-right')
    }, 0)
  }

  /** 处理胶囊左移右移样式
   * @param type add/remove
   * @param className move-left/move-right
   */
  const handleAtRightClass = (type, className) => {
    if (wrapperRef.current) {
      const rightDoms = wrapperRef.current.getElementsByClassName('at_right')
      for (let i = 0; i < rightDoms.length; i++) {
        const dom = rightDoms[i]
        if (type === 'add') dom.classList.add(className)
        if (type === 'remove') dom.classList.remove(className)
      }
      if (type === 'add' && className === 'move-right') {
        setTimeout(() => {
          handleAtRightClass('remove', 'move-left')
          handleAtRightClass('remove', 'move-right')
        }, 500)
      }
    }
  }

  /** 点击放大胶囊,同时关闭缩小胶囊 */
  const enlargeClick = id => {
    closeMiniCapsulePanel()
    if (typeof props.editEventOnClick === 'function') props.editEventOnClick(id, false)
  }

  const scroll = debounceTime(e => {
    !isFilter && calculateEventTimeRange(capsules || [])
  }, 500)

  /** 计算胶囊面板可视范围内的胶囊起始时间 */
  const calculateEventTimeRange = sourceData => {
    if (wrapperRef.current) {
      const scrollRef = document.getElementsByClassName('scroll_bar_restyle')[0]
      const scrollHeight = scrollRef.clientHeight
      const scrollTop = scrollRef.scrollTop
      const events = wrapperRef.current.getElementsByClassName('single_capsule_wrapper')
      const eventRange = []
      let tempHeight = 0
      let start = null
      let end = null

      for (var i = 0; i < events.length; i++) {
        const singleHeight = events[i].clientHeight
        tempHeight += singleHeight
        if (scrollTop < tempHeight && tempHeight - scrollTop < scrollHeight + singleHeight) {
          eventRange.push(sourceData[i])
        }
      }
      if (eventRange.length > 0) {
        // 胶囊时间是倒序
        start = eventRange[eventRange.length - 1].occur_time
        end = eventRange[0].occur_time
      }
      if (typeof props.changeTimeRange === 'function') {
        props.changeTimeRange({ sourceData, start, end })
      }
    }
  }

  return (
    <div ref={wrapperRef} className={style['wrapper']}>
      {!!capsules && (
        <div className="content_wrapper scroll_bar_restyle" onScrollCapture={e => scroll(e)}>
          <div className="content" style={{ paddingLeft: isFilter ? 20 : 0 }}>
            {capsules.map((item, index) => {
              const {
                user_id,
                usernameText,
                timeText,
                titleText,
                id,
                title,
                orgNameText,
                classifyId,
                urgency,
                new: isNew
              } = item
              let tagText = null
              if (
                typeof classifyId === 'number' &&
                typeof templateClassification[classifyId] === 'string'
              ) {
                tagText = templateClassification[classifyId]
              }
              const isRight = user_id === currUserId.current
              return (
                <div
                  style={{ paddingRight: isNew === 1 ? 40 : '' }}
                  className={'single_capsule_wrapper' + (isRight ? ' at_right' : ' at_left')}
                  key={index}
                >
                  <div className="title_wrapper">
                    {!isRight && <div className="text">{`${orgNameText} ${usernameText}`}</div>}
                  </div>
                  <div className="capsule-content">
                    <div
                      className="capsule_content_wrapper"
                      style={{ backgroundColor: urgencyAndColor[urgency] }}
                      onClick={() => eventOnClick(id, index, isRight)}
                    >
                      <div className="time_tag_wrapper">
                        <div className="time" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                          {timeText}
                        </div>
                        {tagText !== null && (
                          <div className="tag_wrapper">
                            <div className="text">{tagText}</div>
                          </div>
                        )}
                      </div>
                      <div className="text_wrapper" style={{ color: '#fff' }}>
                        {titleText}
                      </div>
                      {isNew === 1 && <div className="new-icon" />}
                      {activeCapsule && activeCapsule.id === id && (
                        <>
                          <MiniCapsuleDetail
                            capsuleId={id}
                            classifyText={tagText}
                            detailInfo={item}
                            editClick={editClick}
                            enlargeClick={enlargeClick}
                            closeClick={closeMiniCapsulePanel}
                          />
                          <div className="active-tag" />
                        </>
                      )}
                    </div>
                    {item.comment && item.comment.length > 0 && (
                      <div className="comment_wrapper">
                        {/* <span>{formatDateTimeToText(item.COMMENT[0].create_time)}</span> */}
                        <span style={{ margin: '0 5px' }}>
                          {formatDateTimeToText(item.comment[0].create_time)}
                        </span>
                        <span style={{ marginRight: 5 }}></span>
                        <span>{item.comment[0].comment}</span>
                      </div>
                    )}
                    {isFilter && (
                      <div className="navigate-btn" onClick={() => navigateToEvent(item)}>
                        查看相邻事件
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {!loading && (!capsules || capsules.length === 0) && <Empty />}
        </div>
      )}
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255,0.7)'
          }}
        >
          <Spin tip="加载中..." />
        </div>
      )}
    </div>
  )
}

export default Comp
