import React from 'react';
import echarts from 'echarts';

import AVE_ICON from './res/ave_icon.png';
import STANDARD_ICON from './res/standard_icon.png';

import './FactorItem.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.renderChart();
    }

    refNodeFunc = refNode => {
        this.refNode = refNode;
    }

    renderChart = () => {
        const refName = "refNode";
        if (!!this[refName]) {
            // console.log(refName);
            let instance = echarts.getInstanceByDom(this[refName]);
            if (!instance) {
                instance = echarts.init(this[refName]);
            };
            const data = new Array(24).fill(0).map(() => Math.ceil(Math.random() * 100));
            const aveNum = Math.ceil(Math.random() * 10 + 80);
            const largerData = data.map(v => v > aveNum ? v : '-');
            const smallerData = data.map(v => v > aveNum ? '-' : v);
            // const [minV, maxV] = [Math.min(...data), Math.max(...data)];
            // console.log(data, minV, maxV);
            // return;
            instance.setOption({
                grid: {
                    left: 10,
                    top: 0,
                    right: 10,
                    bottom: 12,
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    boundaryGap: false,
                    // data: xData,
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    // min: yMin,
                    // max: yMax,
                },
                series: [
                    {
                        data: largerData,
                        type: 'bar',
                        stack: 'data',
                        itemStyle: {
                            color: 'rgba(150, 75, 82, 1)',
                            barBorderRadius: 2,
                        },
                        barWidth: 4,
                    },
                    {
                        data: smallerData,
                        type: 'bar',
                        stack: 'data',
                        itemStyle: {
                            color: 'rgba(74, 155, 135, 1)',
                            barBorderRadius: 2,
                        },
                        barWidth: 4,
                        markLine: {
                            silent: true,
                            animation: false,
                            symbol: ['none', 'none'],
                            data: [aveNum]
                                .map(v => ({
                                    yAxis: v,
                                    label: {
                                        show: false,
                                    },
                                    lineStyle: {
                                        color: 'rgba(255, 97, 112, 1)',
                                        type: 'dashed',
                                    },
                                })),
                        },
                    },
                ],
            });
        };
    }

    render() {
        const { factor = {} } = this.props;
        const { name } = factor;

        return (
            <div className="factor_item_wrapper">
                <div className="title_bar_wrapper">
                    <div className="class_wrapper">普通生料</div>
                    <div className="tag_wrapper">KPI</div>
                    <div className="title_text">{name}</div>
                    <div className="time">2020年12月20日</div>
                </div>
                <div className="block_and_chart">
                    <div className="block">
                        <div className="row_wrapper">
                            <img alt="" src={AVE_ICON} className="icon" width="14" height="14" />
                            <div className="text">日均值</div>
                            <div className="value">182.3</div>
                        </div>
                        <div className="row_wrapper">
                            <img alt="" src={STANDARD_ICON} className="icon" width="18" height="12" />
                            <div className="text">基准值</div>
                            <div className="value">182.3</div>
                        </div>
                    </div>
                    <div className="chart_wrapper">
                        <div className="chart_header_bar">
                            <div className="ave_color_icon" style={{ backgroundColor: 'rgba(68, 243, 218, 1)' }} />
                            <div className="ave_color_text">小时均值</div>
                            <div className="standard_color_icon" style={{ borderColor: `rgba(255, 97, 112, 1) transparent transparent transparent` }} />
                            <div className="standard_color_text">基准值</div>
                            <div className="value_text">PHI-</div>
                            <div className="value_number">74.3%</div>
                        </div>
                        <div className="chart_self_wrapper" ref={this.refNodeFunc} />
                    </div>
                </div>
            </div>
        );
    }
}
