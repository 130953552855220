import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import ScaleWrapper from 'components/ScaleWrapper';
const Page = (props) => {

    return (
        <ScaleWrapper width={1840} height={1016}>
            <div className={style['wrapper']}>
                {props.children}
            </div>
        </ScaleWrapper>
    );
};

export default withRouter(Page);
