import React, {Component}  from 'react';
import { Modal, Input, Button, message, Form} from 'antd';
import { post } from 'common/utils.js';
import Style from './index.module.scss';
import DELETE_ICON from '../../assets/delete.png';
import ADD_ICON from '../../assets/add.png';
class Station extends Component {

    constructor(props) {

        super(props);
        this.state = {
            confirmLoading: false,
            showPrompt : false,
            lists:[{'name':'','id':null,'isDelete':0}]
        }

    }

    componentDidMount() {
        this.props.onRef(this);
    }

    add = ()=> {
        var lists=this.state.lists;
        lists.push({'name':'','id':null,'isDelete':0})
        this.setState({lists:lists})
    }

    delete(index){
        console.log(index);
        var lists=this.state.lists;
        lists.map((item,itemIndex)=>{
            if(itemIndex === index){
                item.isDelete = 1
            }
        })
        // if(lists.length>1){
        //     lists.splice(index,1);
            this.setState({lists:lists})
        // }else{
        //     message.info("请至少输入一条岗位名称");
        // }
        
    }

    // 提交表单
    handleOk = (e) => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/postAdd', this.state.lists.filter(n=> n.name!='')).then((retData) => {
            this.setState({ confirmLoading: false });
            if (retData && retData.data) {
                if (retData.data.errcode === 501) {
                    message.error(retData.data.msg);
                    return;
                }
                message.success("添加成功");
                this.setState({
                    showPrompt: false
                });
                this.props.getResults('success')
            } else {
                message.error("岗位修改失败");
            }
        });
    }

    handleCancel = () => {//点击取消按钮触发的事件
        console.log('Clicked cancel button');
        this.setState({
            showPrompt: false,
        });
    }

    onShow(){
        console.log('1',this.props.lists);
        this.setState({
            showPrompt: true,
            lists: this.props.lists
        });
    }
    // 编辑岗位
    changeForm(info,index,e){
        if(info.id){
            this.state.lists.map(item=>{
                if(item.id===info.id){
                    item.name=e.target.value
                }
            })
        }else{
            this.state.lists[index].name=e.target.value
        }
    }
    render() {
        const { showPrompt, confirmLoading } = this.state;
        return (
            <Modal title="岗位设置"
              visible={showPrompt}
              onOk={this.handleOk}
              confirmLoading={confirmLoading}
              onCancel={this.handleCancel}
              className={Style["setting-modal"]}
            >
                <Form className={Style["form"]}>
                {this.state.lists.map((item,index) => (
                    item.isDelete!=1?
                    <div className={Style["setting-info"]} key={item.id?item.id:index} index={index}>
                        <Input onBlur={this.changeForm.bind(this,item,index)} defaultValue={item.name} className={Style["class-setting"]} placeholder="请输入岗位名称" />
                        <img src={DELETE_ICON} className={Style["class-info-delete"]} onClick={(e)=>this.delete(index)}></img>
                    </div>
                    :''
                ))
                }
                <img onClick={this.add} src={ADD_ICON} className={Style["class-info-delete"]} style={{float:'right',marginTop:'16px'}}></img>
                    
                </Form>
            </Modal>
        )
    }

}

export default Station;
