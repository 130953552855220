import React from 'react';
import style from './index.module.scss';
import TimeSelector from 'components/TimeSelectorFactor';
import TopBreadcrumb from 'components/TopBreadCrumb';

const breadcrumbList = [{ name: '看板' }, { name: '综合看板' }];

const Comp = (props) => {
    const { timeInfo, setTimeInfo } = props;
    return (
        <div className={style['wrapper']}>
            <div className="left">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                {/* <div className="text">全厂指标总览</div> */}
            </div>
            <div className="right">
                <TimeSelector setTimeInfo={setTimeInfo} timeInfo={timeInfo} />
            </div>
        </div>
    );
};

export default Comp;
