import React, { useMemo } from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import Header from './Header';
import Menu from 'components/Menu';
import ScaleWrapper from 'components/ScaleWrapper';

const hideSideMenu = (process.env.REACT_APP_HIDE_SIDE_MENU === "true");
const default_bck_color = "#EDF7F8";

const allPages = []
    .concat(
        [
            {
                key: "LnOilfield",
                menuName: '指标看板',
                menuPath: '/Big/DayPanel',
                // bckCOlor: "#002548",
            },
            {
                key: "ElecAnalysis",
                menuName: '用电分析',
                menuPath: '/Big/ElecAnalysis'
            },
            {
                key: "Monitor",
                menuName: '实时监控',
                menuPath: '/Big/Monitor'
            },
            {
                key: "NewScreen",
                menuName: '大屏',
                menuPath: '/BigScreenPanel'
            },
        ],
    );

let menuList = [];
// 默认全显示
menuList = allPages;

const minModePage = menuList.filter(({ supportMinMode }) => supportMinMode === true);

const minStyle = {
    minHeight: 600,
    minWidth: 1200,
};

const Page = (props) => {

    const supportMinMode = useMemo(() => {
        if (!!props) {
            if (!!props.location) {
                if (typeof props.location.pathname === 'string') {
                    if (props.location.pathname !== "") {
                        return minModePage.map(({ menuPath }) => menuPath).indexOf(props.location.pathname) !== -1;
                    };
                };
            };
        };
        return false;
    }, [props]);

    const bckColor = useMemo(() => {
        const pathname = props?.location?.pathname;//optional-chaining
        if (typeof pathname === 'string' && pathname !== "") {
            const filteredItems = menuList.filter(({ menuPath }) => menuPath === pathname);
            if (filteredItems.length > 0) {
                if (typeof filteredItems[0].bckCOlor === 'string') {
                    return filteredItems[0].bckCOlor;
                };
            };
        };
        return default_bck_color;
    }, [props]);

    return (
        <ScaleWrapper width={4224} height={1296}>
            <div className={style['wrapper']}>
                <div className='scroll_wrapper'>
                    <div className='content' style={supportMinMode ? minStyle : {}}>
                        <Header />
                        <div className="content">
                            {props.children}
                        </div>
                    </div>
                </div>
                {
                    !hideSideMenu && (
                        <Menu match={props.match} history={props.history} menuList={menuList} />
                    )
                }
            </div>
        </ScaleWrapper>
    );
};

export default withRouter(Page);
