import Page from './ReferenceFactors.js';
import { connect } from 'react-redux';
import {
    setValues,
} from '../../../common/redux/ReferenceFactors/actions.js';
import { post, get } from '../../../common/utils';

const getKPIDetail = (time_begin, time_end, factor_id) => {
    return (dispatch, getState) => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_kpi_detail', {
            time_begin,
            time_end,
            factor_id,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0 && !!retData.data.result) {
                dispatch(setValues({ kpiDetail: retData.data.result }));
                return true;
            };
            return false;
        });
    };
};

const getSysList = (dispatch, getState) => {
    return get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_prod_system_list', {})
        .then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ systems: retData.data.results }));
                return true;
            };
            return false;
        });
};

const getThemeList = (dispatch, getState) => {
    return get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_factor_theme_list', {})
        .then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ themes: retData.data.results }));
                return true;
            };
            return false;
        });
};

const getShiftDetails = (time_begin, time_end, kpi_factor_ids) => {
    return (dispatch, getState) => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_detailed_history_data_with_details', {
            time_begin,
            time_end,
            kpi_factor_ids,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ shiftDetails: retData.data.results }));
                return true;
            };
            return false;
        });
    };
};

const updateTimeRange = (time_begin, time_end) => {
    return (dispatch, getState) => {
        dispatch(setValues({ timeRange: [time_begin, time_end] }));
    };
};

const mapStateToProps = state => {
    const { referenceFactors } = state;
    const { systems, themes, kpiDetail, timeRange } = referenceFactors;
    return {
        systems,
        themes,
        kpiDetail,
        timeRange,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSysList: () => dispatch(getSysList),
        getThemeList: () => dispatch(getThemeList),
        getKPIDetail: (time_begin, time_end, factor_id) => dispatch(getKPIDetail(time_begin, time_end, factor_id)),
        getShiftDetails: (time_begin, time_end, kpi_factor_ids) => dispatch(getShiftDetails(time_begin, time_end, kpi_factor_ids)),
        updateTimeRange: (time_begin, time_end) => dispatch(updateTimeRange(time_begin, time_end)),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;