import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input, Select, message } from 'antd'
import style from './custom.module.scss'
const { Option } = Select

/** UI模版 templateId=3 */
const Com = props => {
  const { capsuleDetailInfo, cacheData, resetAction } = props
  const [headLabel, setHeadLabel] = useState(1)
  const [headNumber, setHeadNumber] = useState(0)
  const [complateLabel, setComplateLabel] = useState(1)
  const [complateNumber, setComplateNumber] = useState(0)

  const headOption = [
    { label: '抬高', value: 1 },
    { label: '降低', value: 2 }
  ]
  const complateOption = [
    { label: '移入', value: 1 },
    { label: '移出', value: 2 }
  ]

  useImperativeHandle(props.refInstance, () => ({
    checkValidate,
    getCustomData: () => {
      return { headLabel, headNumber, complateLabel, complateNumber }
    }
  }))

  useEffect(() => {
    if (capsuleDetailInfo && Object.keys(capsuleDetailInfo).length > 0) {
      // 编辑
      const { jsonContent } = capsuleDetailInfo
      setHeadLabel(jsonContent.headLabel)
      setHeadNumber(jsonContent.headNumber)
      setComplateLabel(jsonContent.complateLabel)
      setComplateNumber(jsonContent.complateNumber)
    } else {
      // 新建时会产生缓存数据
      const cacheInfo = cacheData ? cacheData : null
      if (cacheInfo) {
        const jsonContent = cacheInfo.json_content || {}
        if (Object.keys(jsonContent).length > 0) {
          setHeadLabel(jsonContent.headLabel)
          setHeadNumber(jsonContent.headNumber)
          setComplateLabel(jsonContent.complateLabel)
          setComplateNumber(jsonContent.complateNumber)
        }
      }
    }
  }, [])

  /** 重置 */
  useEffect(() => {
    if (resetAction) {
      setHeadLabel(1)
      setHeadNumber(0)
      setComplateLabel(1)
      setComplateNumber(0)
    }
  }, [resetAction])

  const changeHeadNumber = newValue => {
    const formatValue = checkNumberValue(newValue)
    if (formatValue !== 'error') {
      setHeadNumber(formatValue)
    }
  }
  const changeComplateNumber = newValue => {
    const formatValue = checkNumberValue(newValue)
    if (formatValue !== 'error') {
      setComplateNumber(formatValue)
    }
  }

  const checkNumberValue = value => {
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (value || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return 'error'
    }
    return formatValue
  }

  /** 字符串转数字 */
  const formatNumber = (newValue, index) => {
    if (!newValue) return
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    index === 1 ? setHeadNumber(formatValue) : setComplateNumber(formatValue)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (!headNumber && headNumber !== 0) {
      message.error(`请输入头部${headLabel === 1 ? '抬高' : '降低'}数值`)
      return false
    }
    if (!complateNumber && complateNumber !== 0) {
      message.error(`请输入整体${complateLabel === 1 ? '移入' : '移出'}数值`)
      return false
    }
    return true
  }
  return (
    <div className={style['custom_3']}>
      <div className="row-item" style={{ marginBottom: 16 }}>
        <div className="name">头部</div>
        <Select value={headLabel} onChange={e => setHeadLabel(e)}>
          {headOption.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
        <Input
          value={headNumber}
          onChange={e => changeHeadNumber(e.target.value)}
          onBlur={e => formatNumber(e.target.value, 1)}
        />
        <div className="unit">cm</div>
      </div>
      <div className="row-item">
        <div className="name">整体</div>
        <Select value={complateLabel} onChange={e => setComplateLabel(e)}>
          {complateOption.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
        <Input
          value={complateNumber}
          onChange={e => changeComplateNumber(e.target.value)}
          onBlur={e => formatNumber(e.target.value, 2)}
        />
        <div className="unit">mm</div>
      </div>
    </div>
  )
}

export default Com
