import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import style from './index.module.scss'
import timeImg from '../../../assets/bigTimeSelect.png'

const TimeSelect = props => {
  const {
    currentDate,
    toggleShowDatePicker,
    changeDate,
    nextDate,
    prevDate,
    isShowDatePicker,
    disabledDate
  } = props

  const [datePickerOpen, setDatePickerOpen] = useState(false)

  useEffect(() => {
    if (!isShowDatePicker) {
      setDatePickerOpen(false)
    } else {
      setDatePickerOpen(true)
    }
  }, [isShowDatePicker])

  return (
    <div className={style['timeSelect']}>
      <div className={style['timeSelect-content']}>
        <div style={{ display: isShowDatePicker === false ? 'none' : 'block' }}>
          <DatePicker open={datePickerOpen} onChange={changeDate} disabledDate={disabledDate}/>
        </div>
        <img src={timeImg} alt="" className={style['timeImg']} onClick={toggleShowDatePicker} />
        <div className={style['timeVal']}>
          <LeftOutlined onClick={prevDate} />
          <span>{currentDate.substring(0, 10)}</span>
          <RightOutlined onClick={nextDate} />
        </div>
      </div>
    </div>
  )
}
export default TimeSelect
