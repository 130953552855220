import React from 'react';
import { Button, Table, message, Modal, Space, Input, Tabs, Drawer } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { post, get, patch, reqDelete } from 'common/utils';
import style from './index.module.scss';
import TplGroupTree from './TplGroupTree';
import Schema from 'containers/Event/Schema';

const { TabPane } = Tabs;

export default class FormTplList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            page_size: 10,
            dataSource: [],
            modalVisible: false,
            selectedItem: null,
            loading: false,
            tableLoading: false,
            selectedCatId: null,
            visible: false,
            tplCode: null,
            tplName: null,
        };
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        let self = this;
        const { page, page_size, selectedCatId } = this.state;
        if (typeof selectedCatId === 'number') {
            this.setState({ tableLoading: true });
            get(process.env.REACT_APP_SERVER_PATH + 'api/form/form_tpls', {
                page: 1,
                page_size: 999,
                cat_id: selectedCatId,
            }).then(retData => {
                self.setState({ tableLoading: false });
                if (!!retData.data && !!retData.data.results) {
                    this.setState({
                        dataSource: retData.data.results
                    });
                }
            });
        };
    }

    createItem = (item) => {
        this.setState({
            modalVisible: true,
            selectedItem: { name: '', tpl_json: '', table_json: '' },
        });
    }

    delItem = (id) => {
        let self = this;
        const execDel = () => {
            this.setState({tableLoading: true});
            patch(process.env.REACT_APP_SERVER_PATH + 'api/form/form_tpls/' + id, {
                status: -1
            }).then((retData) => {
                self.setState({tableLoading: false});
                if (retData.error !== true) {
                    message.success("删除成功");
                    self.getList();
                } else {
                    message.error("删除失败");
                };
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    editItem = (item) => {
        let tpl_json = '';
        try{
            tpl_json = JSON.stringify(JSON.parse(item.tpl_json), null, 4);
        }catch(e){};

        let table_json = '';
        try{
            table_json = JSON.stringify(JSON.parse(item.table_json), null, 4);
        }catch(e){};

        this.setState({
            modalVisible: true,
            selectedItem: Object.assign({}, item, { name: item.name, tpl_json: tpl_json, table_json: table_json, code: item.code, remark: item.remark, permission: item.permission }),
        });
    }

    checkJsonFormat = (json_str)=>{
        if(json_str === null || json_str.trim() === ''){
            return true;
        }
        try{
            JSON.parse(json_str);
        }catch(e){
            return false;
        }
        return true;
    }

    saveData = () => {
        const { selectedItem, selectedCatId } = this.state;
        const { id } = selectedItem;
        if(!this.checkJsonFormat(selectedItem.tpl_json)){
            message.error('模板内容JSON格式异常，请检查后保存');
            return;
        }else if(!this.checkJsonFormat(selectedItem.table_json)){
            message.error('列表配置JSON格式异常，请检查后保存');
            return;
        }
        if(selectedItem.tpl_json === null || selectedItem.tpl_json.trim() === ''){
            selectedItem.tpl_json = "";
        }
        if(selectedItem.table_json === null || selectedItem.table_json.trim() === ''){
            selectedItem.table_json = "";
        }

        this.setState({ loading: true });
        if(id){
            patch(process.env.REACT_APP_SERVER_PATH + 'api/form/form_tpls/' + id, {
                name: selectedItem.name,
                tpl_json: selectedItem.tpl_json,
                table_json: selectedItem.table_json,
                code: selectedItem.code,
                permission: selectedItem.permission,
                remark: selectedItem.remark,
            }).then(retData => {
                this.setState({ loading: false }, () => {
                    if (!!retData.data && retData.data.id === id) {
                        message.success("保存成功");
                        this.handleCancel();
                        this.getList();
                    } else {
                        message.error("保存失败");
                    };
                });
            })
        }else{
            post(process.env.REACT_APP_SERVER_PATH + 'api/form/form_tpls', {
                name: selectedItem.name,
                tpl_json: selectedItem.tpl_json,
                table_json: selectedItem.table_json,
                code: selectedItem.code,
                permission: selectedItem.permission,
                remark: selectedItem.remark,
                cat_id: selectedCatId,
            }).then(retData => {
                this.setState({ loading: false }, () => {
                    if (!!retData.data && retData.data.id) {
                        message.success("保存成功");
                        this.handleCancel();
                        this.getList();
                    } else {
                        message.error("保存失败");
                    };
                });
            })
        }
    }

    handleCancel = () => {
        const { loading } = this.state;
        if (!!loading) return;
        this.setState({
            modalVisible: false,
            selectedItem: null,
        });
    }

    setId = (id) => {
        this.setState({ selectedCatId: id }, () => {
            this.getList();
        });
    }

    watchTemplate = (code, name) => {
        // console.log(code);
        this.setState({
            tplCode: code,
            tplName: name,
            visible: true,
        });
    }

    onClose = () => {
        this.setState({
            tplCode: null,
            tplName: null,
            visible: false,
        });
    };

    render() {
        let self = this;
        const { dataSource, modalVisible, selectedItem, loading, tableLoading, visible, tplCode, selectedCatId, tplName } = this.state;

        return (
            <div className={style["wrapper"]}>
                <div className="left">
                    <TplGroupTree setId={this.setId} />
                </div>
                <div className="right">
                    <div className="header">
                        <Button type="primary" onClick={this.createItem} disabled={typeof selectedCatId !== 'number'}>新建</Button>
                    </div>
                    <Table
                        dataSource={dataSource}
                        loading={tableLoading}
                        pagination={{
                            defaultCurrent: 1,
                            pageSize: 10,
                            // total: this.state.total,
                            // onChange: (page, pageSize) => self.getAlarmList(page, pageSize)
                        }}
                        columns={[
                            {
                                title: '名称',
                                dataIndex: 'name',
                            },
                            {
                                title: '分类',
                                dataIndex: 'cat_name'
                            },
                            {
                                title: '状态',
                                render: r => {
                                    if (r.status == 1) {
                                        return '已启用';
                                    } else if (r.status == 0) {
                                        return '已停用';
                                    } else {
                                        return '';
                                    }
                                }
                            },
                            {
                                title: '编码',
                                dataIndex: 'code'
                            },
                            {
                                title: '备注',
                                dataIndex: 'remark',
                            },
                            {
                                title: '操作',
                                key: 'action',
                                width: 380,
                                render: (text, record) => {
                                    return ([
                                        <Button key={1} type="link" onClick={() => {
                                            // window.open("https://puyuan.quantile.tech/h5/#/EventSchema/"+record.code);
                                            // this.props.history.push(`/PEIMSV2/EventSchema/${record.code}`);
                                            this.watchTemplate(record.code, record.name);
                                        }}>查看</Button>,
                                        <Button key={5} type="link" onClick={() => this.editItem(record)}>编辑</Button>,
                                        <Button key={4} type="link" onClick={() => this.pauseItem(record.id)}>停用</Button>,
                                        <Button key={3} type="link" onClick={() => this.delItem(record.id)} danger>删除</Button>,
                                    ]);
                                }
                            },
                        ]}
                    />
                    {
                        !!selectedItem && modalVisible && (
                            <Modal
                                title="表单模板"
                                width={760}
                                visible={true}
                                onOk={this.saveData}
                                onCancel={this.handleCancel}
                                okText="确认"
                                confirmLoading={loading}
                            >
                                <Space>
                                    <Space>
                                        <div>名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称：</div>
                                        <Input
                                            value={selectedItem.name}
                                            style={{ width: 260 }}
                                            placeholder="请输入名称"
                                            onChange={({ target: { value } }) => {
                                                self.state.selectedItem.name = value;
                                                self.forceUpdate();
                                            }}
                                        />
                                    </Space>
                                    <Space style={{ marginLeft: "30px" }}>
                                        <div>编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码：</div>
                                        <Input
                                            value={selectedItem.code}
                                            style={{ width: 260 }}
                                            placeholder="请输入编码"
                                            onChange={({ target: { value } }) => {
                                                self.state.selectedItem.code = value;
                                                self.forceUpdate();
                                            }}
                                        />
                                    </Space>
                                </Space>
                                <Space style={{ marginTop: "10px" }}>
                                    <Space>
                                        <div>备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：</div>
                                        <Input
                                            value={selectedItem.remark}
                                            style={{ width: 260 }}
                                            placeholder="请输入备注"
                                            onChange={({ target: { value } }) => {
                                                self.state.selectedItem.remark = value;
                                                self.forceUpdate();
                                            }}
                                        />
                                    </Space>
                                    <Space style={{ marginLeft: "28px" }}>
                                        <div>权&nbsp;&nbsp;限&nbsp;&nbsp;码：</div>
                                        <Input
                                            value={selectedItem.permission}
                                            style={{ width: 260 }}
                                            placeholder="请输入权限码"
                                            onChange={({ target: { value } }) => {
                                                self.state.selectedItem.permission = value;
                                                self.forceUpdate();
                                            }}
                                        />
                                    </Space>
                                </Space>
                                <Tabs defaultActiveKey="1" style={{ marginTop: "10px" }}>
                                    <TabPane tab="模板内容" key="1">
                                        <Input.TextArea
                                            value={selectedItem.tpl_json}
                                            style={{ width: 712 }}
                                            placeholder="请输入模板JSON"
                                            autoSize={{ minRows: 15, maxRows: 16 }}
                                            onChange={({ target: { value } }) => {
                                                self.state.selectedItem.tpl_json = value;
                                                self.forceUpdate();
                                            }}
                                        />
                                    </TabPane>
                                    <TabPane tab="列表配置" key="2">
                                        <Input.TextArea
                                            value={selectedItem.table_json}
                                            style={{ width: 712 }}
                                            placeholder="请输入表单列表配置JSON"
                                            autoSize={{ minRows: 15, maxRows: 16 }}
                                            onChange={({ target: { value } }) => {
                                                self.state.selectedItem.table_json = value;
                                                self.forceUpdate();
                                            }}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Modal>
                        )
                    }
                </div>
                <Drawer
                    title={tplName}
                    placement="right"
                    closable={true}
                    onClose={this.onClose}
                    visible={visible}
                    width={500}
                >
                    {
                        visible && (
                            <div className={style["drawer"]}>
                                <Schema code={tplCode} readOnly={true} />
                            </div>
                        )
                    }
                </Drawer>
            </div>
        );
    }
}
