import React from 'react';
import { Typography, Select, Space, Input, InputNumber, Popover, Tabs, Table, Button } from 'antd';
import style from './index.module.scss';
import LineChartWrapper from '../Comp/wrapper';
import { SwatchesPicker } from 'react-color';
import { withRouter } from 'react-router';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            yAxis: {
                "upper_limit": null,
                "lower_limit": null,
                "unit": null
            },
            metaList: [],
            metaOptions: [],
            selectedMeta: {
                name: '',
                y_axis: 'left',
                type: 'line',
                color: '#40A1E1',
            },
            config: {
                "title": "",
                "y_axis": {
                    "upper_limit": null,
                    "lower_limit": null,
                    "unit": "",
                },
                "meta_list": [
                    {
                        "tag_name": "A.B12.35CD",
                        "src_type": "1",
                        "type": "line",
                        "name": "综合产量",
                        "color": "#CCC123"
                    }, {
                        "tag_name": "A.B12.35CD",
                        "src_type": "1",
                        "type": "line",
                        "name": "综合产量",
                        "color": "#CCC123"
                    }
                ]
            }
        };
    }

    componentDidMount() {
        let self = this;
        let { registerDataFunc } = this.props;
        if (!!registerDataFunc) {
            registerDataFunc(() => {
                return Promise.resolve(self.state.config);
            });
        }
    }

    handleSearch = value => {
        let self = this;
        if (value) {
            console.log(value);
            setTimeout(() => {
                self.setState({
                    metaOptions: [
                        { "name": value + "-1", "tag_name": value + "-1" },
                        { "name": value + "-2", "tag_name": value + "-2" },
                        { "name": value + "-3", "tag_name": value + "-3" }
                    ]
                });
            }, 800);
        } else {
            this.setState({ data: [] });
        }
    };

    addMeta = meta => {
        this.state.metaList.push({
            'name': meta.name,
            'tag_name': meta.tag_name,
        });
        this.state.metaOptions = [];
        this.forceUpdate();
    }

    getConf = () => {
        const { title, yAxis, metaList } = this.state;
        return {
            title,
            y_axis: yAxis,
            meta_list: metaList,
        };
    };

    updateYAxis = (key) => {
        return (v) => {
            console.log(v, typeof v);
            if (typeof v === 'number' || v === null) {
                const { yAxis } = this.state;
                const nextYAxis = Object.assign(
                    {},
                    yAxis ?? {},
                    {
                        [key]: v,
                    },
                );
                this.setState({ yAxis: nextYAxis });
            };
        };
    };

    render() {
        let self = this;
        let { title, yAxis, srcType, metaOptions, metaList, selectedMeta } = this.state;

        return (
            <div className={style['wrapper']}>
                <div className="display_block">
                    <LineChartWrapper config={this.getConf()} />
                </div>
                <div className="edit_block">
                    <Tabs defaultActiveKey="1" tabPosition="left">
                        <TabPane tab="基本配置" key="1">
                            <Space direction="vertical">
                                <Space>
                                    <Text>标题</Text>
                                    <Input placeholder="请输入标题名" value={title} onChange={e => this.setState({ title: e.target.value })} style={{ width: 200 }} />
                                </Space>
                                <Space style={{ marginTop: 10 }}>
                                    <Text>Y轴</Text>
                                    <Text>上限：</Text>
                                    <InputNumber placeholder="请输入上限" value={yAxis.upper_limit} onChange={this.updateYAxis("upper_limit")} style={{ width: 100 }} />
                                    <Text style={{ marginLeft: 10 }}>下限：</Text>
                                    <InputNumber placeholder="请输入下限" value={yAxis.lower_limit} onChange={this.updateYAxis("lower_limit")} style={{ width: 100 }} />
                                </Space>
                            </Space>
                        </TabPane>
                        <TabPane tab="数据项" key="2">
                            <Space>
                                <Space className="leftMetaList" direction="vertical">
                                    <Space className="meta-options-line">
                                        <Select
                                            defaultValue="1"
                                            style={{ width: 100 }}
                                            value={srcType}
                                            onChange={v => this.setState({ srcType: v })}
                                        >
                                            <Option value="1">数据源1</Option>
                                            <Option value="2">数据源2</Option>
                                        </Select>
                                        <Select
                                            showSearch
                                            value={this.state.value}
                                            style={{ width: 250 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.handleSearch}
                                            onChange={this.addMeta}
                                            notFoundContent={null}
                                        >
                                            {metaOptions.map(item => <Option value={item.tag_name}>{item.name}</Option>)}
                                        </Select>
                                    </Space>
                                    <Table
                                        dataSource={metaList}
                                        // rowKey="tag_name"
                                        locale={{ emptyText: "请添加点位" }}
                                        columns={[
                                            {
                                                title: "序号",
                                                render: (r, index) => {
                                                    return index;
                                                }
                                            }, {
                                                title: "点位号",
                                                dataIndex: "tag_name"
                                                // render: (text, r)=>{
                                                //     return text;
                                                // }
                                            }, {
                                                title: "名称",
                                                dataIndex: "name"
                                                // render: (text, r)=>{
                                                //     return text;
                                                // }
                                            }
                                        ]}
                                    />
                                </Space>
                                <Space className="rightConf" direction="vertical">
                                    {selectedMeta && <>
                                        <Space>
                                            <Text>名称：</Text>
                                            <Input
                                                value={selectedMeta.name}
                                                onChange={e => { selectedMeta.name = e.target.value; self.forceUpdate(); }}
                                                style={{ width: 200 }}
                                            />
                                        </Space>
                                        {/*<Space>
                                        <Text>单位：</Text>
                                        <Input
                                            value={selectedMeta.unit}
                                            onChange={e=>{selectedMeta.unit=e.target.value; self.forceUpdate();}}
                                            style={{ width: 100 }}
                                        />
                                    </Space>*/}
                                        <Space>
                                            <Text>气泡颜色</Text>
                                            <Popover
                                                title="选择颜色"
                                                // trigger="click"
                                                placement="right"
                                                content={(
                                                    <Space direction="vertical">
                                                        <SwatchesPicker
                                                            color={selectedMeta.color}
                                                            onChangeComplete={color => {
                                                                if (color) {
                                                                    selectedMeta.color = color.hex;
                                                                    self.forceUpdate();
                                                                }
                                                            }}
                                                        />
                                                    </Space>
                                                )}
                                            >
                                                <div style={{ width: 100, height: 30, backgroundColor: selectedMeta.color, borderRadius: 5, cursor: 'pointer' }} />
                                            </Popover>
                                        </Space>
                                        <Button danger>删除</Button>
                                    </>}
                                </Space>
                            </Space>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
};

export default withRouter(Comp);
