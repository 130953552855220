import React, { useEffect, useState, useImperativeHandle } from 'react'
import { PhotoSlider } from 'react-photo-view'
import 'react-photo-view/dist/index.css'
import { Input, Modal, Upload, message } from 'antd'
import './enclosure.scss'
import enclosureImg from '../../../assets/enclosure.png'
import audioImg from '../../../assets/audio.png'
import videoImg from '../../../assets/video.png'
import chatImg from '../../../assets/chat.png'
import sendImg from '../../../assets/send.png'
import videoBgImg from '../../../assets/videoBg.jpg'
import videoPlayImg from '../../../assets/videoPlay.png'
import addImg from '../../../assets/add_1.png'
import delImg from '../../../assets/images/small-del.png'
import { post, get, reqDelete } from '../../../common/utils'
import { CloseCircleOutlined } from '@ant-design/icons'

const Enclosure = props => {
  const { capsuleId } = props
  const [comment, setComment] = useState('')
  const [otherRecord, setOtherRecord] = useState([])
  const [selfRecord, setSelfRecord] = useState([])
  const [imageList, setImageList] = useState([])
  const [audioList, setAudioList] = useState([])
  const [videoList, setVideoList] = useState([])
  const [visible, setVisible] = useState(false) // 图片弹出
  const [photoIndex, setPhotoIndex] = useState(0)
  const [audioVisible, setAudioVisible] = useState(false)
  const [videoVisible, setVideoVisible] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
  const [playVisible, setPlayVisible] = useState(false)
  const [filePath, setFilePath] = useState('')
  const [enclosureList, setEnclosureList] = useState([])
  const [attachArray, setAttachArray] = useState([])
  const [smallImageVisible, setSmallImageVisible] = useState(false)
  const [temImgArray, setTemImgArray] = useState([])
  const [temAudioArray, setTemAudioArray] = useState([])
  const [temVideoArray, setTemVideoArray] = useState([])
  useImperativeHandle(props.refInstance, () => ({
    attachArray
  }))
  // 发送
  const submitText = () => {
    post(process.env.REACT_APP_SERVER_PATH + '/api/v1/event/capsule/addComment', {
      evtInsId: capsuleId,
      comment: comment
    })
      .then(res => {
        console.log(res)
        setComment('')
        commentRecord(capsuleId)
      })
      .catch(err => {
        console.log(err)
      })
  }
  // change
  const changeComment = value => {
    setComment(value)
  }
  // 附件详情
  const enclosureDetail = id => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/queryAttachment', {
      id: id
    })
      .then(res => {
        const data = res.data.results
        setEnclosureList(data)
        const imageArr = []
        const audioArr = []
        const videoArr = []
        data.forEach(item => {
          if (item.fileType === '图片') {
            imageArr.push(item)
          } else if (item.fileType === '音频') {
            audioArr.push(item)
          } else {
            videoArr.push(item)
          }
        })
        setImageList(imageArr)
        setAudioList(audioArr)
        setVideoList(videoArr)
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 查询评论
  const commentRecord = id => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/queryComment', {
      id: id
    })
      .then(res => {
        const data = res.data.results
        const otherArr = []
        const selfArr = []
        data.forEach(item => {
          if (item.user_id === userInfo.user_id) {
            selfArr.push(item)
          } else {
            otherArr.push(item)
          }
        })
        setSelfRecord(selfArr)
        setOtherRecord(otherArr)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const handleOk = () => {
    var voice = document.getElementById('voice')
    if (voice) {
      voice.pause()
    }
    setAudioVisible(false)
    setVideoVisible(false)
  }

  const handleCancel = () => {
    var voice = document.getElementById('voice')
    if (voice) {
      voice.pause()
    }
    setAudioVisible(false)
    setVideoVisible(false)
  }
  const playVideo = path => {
    setFilePath(path)
    setPlayVisible(true)
  }
  const handleVideoOK = () => {
    var video = document.getElementById('movie')
    if (video) {
      video.pause()
    }
    setPlayVisible(false)
  }
  const handleVideoCancel = () => {
    var video = document.getElementById('movie')
    if (video) {
      video.pause()
    }

    setPlayVisible(false)
  }
  const fileChange = event => {
    console.log(event.target.files)
    const fileType = event.target.files[0].type.split('/')
    let type_file = ''
    if (fileType[0] === 'image') {
      type_file = 'image'
      if (event.target.files[0].size / 1024 / 1024 > 5) {
        message.warning('图片大小不能超过5MB')
        return
      }
    } else if (fileType[0] === 'video') {
      type_file = 'video'
      if (event.target.files[0].size / 1024 / 1024 > 50) {
        message.warning('视频大小不能超过50MB')
        return
      }
    } else if (fileType[0] === 'audio') {
      type_file = 'audio'
      if (event.target.files[0].size / 1024 / 1024 > 20) {
        message.warning('音频大小不能超过20MB')
        return
      }
    } else {
      message.warning('文件类型错误')
      return
    }
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    formData.append('name', event.target.files[0].name)
    post(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/event/attachmentUploading', formData)
      .then(res => {
        console.log(res)
        if (res.data.results !== null) {
          attachArray.push(res.data.results.uuid)
          setAttachArray(attachArray)
          if (type_file === 'image') {
          temImgArray.push({
                file_path: res.data.results.filePath,
                uuid: res.data.results.uuid
            })
            setImageList(temImgArray)
          } else if (type_file === 'video') {
              temVideoArray.push({
                  file_path: res.data.results.filePath,
                  uuid: res.data.results.uuid
              })
              setVideoList(temVideoArray)
          } else if (type_file === 'audio') {
              temAudioArray.push({
                  file_path: res.data.results.filePath,
                  uuid: res.data.results.uuid
              })
            setAudioList(temAudioArray)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 删除附件
  const deleteAttach = (arr, attachId, index) => {
    const uuids = [] // 附件
    uuids.push(attachId)
    reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/event/attachmentDelete', {
      attachmentIds: capsuleId ? [capsuleId] : [],
      uuid: uuids
    })
      .then(res => {
        arr.splice(index, 1)
        if(res.data.msg === '删除完成') {
            message.success('删除成功')
        }
        handleSmallCancel()
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 弹出小窗口进行图片 删除
  const handleSmallVisible = e => {
    e.stopPropagation()
    setSmallImageVisible(true)
  }
  const uploadFile = () => {
    fileChange()
  }
  const handleSmallOk = () => {
    setSmallImageVisible(false)
  }
  const handleSmallCancel = () => {
    setSmallImageVisible(false)
  }
  const accept =
    'image/JPEG,image/TIFF,image/RAW,image/BMP,image/GIF,image/PNG,' +
    'audio/WAV,audio/MP3,audio/WMA,audio/MIDI,video/MP4,' +
    'video/AVI,video/MOV,video/3GP,video/RM,video/RMVB'
  useEffect(() => {
    capsuleId && enclosureDetail(capsuleId)
    capsuleId && commentRecord(capsuleId)
  }, [capsuleId])
  return (
    <div className="enclosure">
      <div className="enclosure-header" style={{ position: 'relative' }}>
        <img src={enclosureImg} />
        <span>附件</span>
        <input
          type="file"
          onChange={fileChange}
          name="files"
          style={{ opacity: 0, position: 'absolute', left: 115, top: 0 }}
          accept={accept}
        />
        <span onClick={uploadFile}>
          <img src={addImg} style={{ marginLeft: 64 }} />
          <span style={{ fontSize: 14, color: '#357CF7', marginLeft: 8 }}>添加</span>
        </span>
      </div>
      <div className="enclosure-content">
        <div
          className="enclosure-content-type"
          onClick={() => setVisible(true)}
          style={{ display: imageList.length > 0 ? 'block' : 'none' }}
        >
          <img
            src={
              imageList.length > 0
                ? process.env.REACT_APP_SERVER_PATH +
                  `api/v1/event/capsule/downloadAttachment?file_path=${imageList[0].file_path}`
                : ''
            }
            width="128"
            height="128"
            alt=""
          />
          <div className="number">+{imageList.length}</div>
          <div className="small-del" onClick={handleSmallVisible}>
            <img src={delImg} alt="" />
          </div>
        </div>
        <div
          className="enclosure-content-type"
          onClick={() => setAudioVisible(true)}
          style={{ display: audioList.length > 0 ? 'block' : 'none' }}
        >
          <div className="type-img">
            <img src={audioImg} />
          </div>
          <div style={{ color: '#8EC1BE' }}>音频</div>
          <div style={{ color: '#238D7A' }}>+{audioList.length}</div>
        </div>
        <div
          className="enclosure-content-type"
          onClick={() => setVideoVisible(true)}
          style={{ display: videoList.length > 0 ? 'block' : 'none' }}
        >
          <div className="type-img">
            <img src={videoImg} />
          </div>
          <div style={{ color: '#8EC1BE' }}>视频</div>
          <div style={{ color: '#238D7A' }}>+{videoList.length}</div>
        </div>
      </div>
      <div
        className="enclosure-line"
        style={{ display: enclosureList.length > 0 ? 'block' : 'none' }}
      ></div>
      <PhotoSlider
        images={imageList.map(item => ({
          src:
            process.env.REACT_APP_SERVER_PATH +
            `api/v1/event/capsule/downloadAttachment?file_path=${item.file_path}`
        }))}
        visible={visible}
        onClose={() => setVisible(false)}
        index={photoIndex}
        onIndexChange={setPhotoIndex}
      />
      <div className="enclosure-chat">
        <img src={chatImg} />
        <span className="chat">评论</span>
        <span className="num">{otherRecord.length + selfRecord.length}条</span>
      </div>
      <div className="enclosure-chatRecord">
        <ul className="enclosure-chatRecord-other">
          {otherRecord &&
            otherRecord.map((item, index) => {
              return (
                <li key={index}>
                  <div className="date">
                    <span className="date-name"></span>
                    <span className="date-time">{item.create_time}</span>
                  </div>
                  <div className="text">{item.comment}</div>
                </li>
              )
            })}
        </ul>
        <ul className="enclosure-chatRecord-self">
          {selfRecord &&
            selfRecord.map((item, index) => {
              return (
                <li key={index + 'a'}>
                  <div className="self-date">{item.create_time}</div>
                  <div style={{ clear: 'both' }}></div>
                  <div className="self-text">{item.comment}</div>
                </li>
              )
            })}
        </ul>
      </div>
      <div className="enclosure-send">
        <Input
          value={comment}
          placeholder="发表评论"
          style={{ width: 224, height: 24 }}
          onChange={e => changeComment(e.target.value)}
        />
        <img src={sendImg} alt="" onClick={submitText} />
      </div>
      <div style={{ clear: 'both' }}></div>
      <Modal title="音频" visible={audioVisible} onOk={handleOk} onCancel={handleCancel}>
        {audioList &&
          audioList.map((item, index) => {
            return (
              <div key={index + '100'}>
                <audio
                  id="voice"
                  src={
                    process.env.REACT_APP_SERVER_PATH +
                    `api/v1/event/capsule/downloadAttachment?file_path=${item.file_path}`
                  }
                  width="300"
                  controls="controls"
                />
                <CloseCircleOutlined onClick={() => deleteAttach(audioList, item.uuid, index)} />
              </div>
            )
          })}
      </Modal>
      <Modal title="视频" visible={videoVisible} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ display: 'flex' }}>
          {videoList &&
            videoList.map((item, index) => {
              return (
                <div key={'video' + index} style={{ marginRight: 8, position: 'relative' }}>
                  <img
                    src={videoBgImg}
                    width="168"
                    height="168"
                    style={{ display: 'inline-block', borderRadius: 10 }}
                  />
                  <div
                    style={{ position: 'absolute', top: 60, left: 62 }}
                    onClick={() => playVideo(item.file_path)}
                  >
                    <img src={videoPlayImg} width="50" height="50" />
                  </div>
                  <CloseCircleOutlined
                    onClick={() => deleteAttach(audioList, item.uuid, index)}
                    style={{ position: 'absolute', top: -10, right: 3 }}
                  />
                </div>
              )
            })}
        </div>
      </Modal>
      <Modal visible={playVisible} onOk={handleVideoOK} onCancel={handleVideoCancel}>
        <div>
          <video
            id="movie"
            style={{ objectFit: 'fill', width: 400, height: 320 }}
            src={
              process.env.REACT_APP_SERVER_PATH +
              `api/v1/event/capsule/downloadAttachment?file_path=${filePath}`
            }
            controls="controls"
          />
        </div>
      </Modal>
      <Modal
        visible={smallImageVisible}
        onOk={handleSmallOk}
        onCancel={handleSmallCancel}
        width={800}
      >
        <ul style={{ display: 'flex', paddingLeft: 0 }}>
          {imageList &&
            imageList.map((item, index) => {
              return (
                <li
                  key={index + 'img'}
                  style={{ listStyle: 'none', position: 'relative', marginRight: 4 }}
                >
                  <img
                    src={
                      process.env.REACT_APP_SERVER_PATH +
                      `api/v1/event/capsule/downloadAttachment?file_path=${item.file_path}`
                    }
                    width="160"
                    height="140"
                  />
                  <div
                    style={{ position: 'absolute', top: -10, right: -3 }}
                    onClick={() => deleteAttach(imageList, item.uuid, index)}
                  >
                    <CloseCircleOutlined />
                  </div>
                </li>
              )
            })}
        </ul>
      </Modal>
    </div>
  )
}
export default Enclosure
