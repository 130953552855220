import React, { useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import { withRouter } from 'react-router';
import TopBreadcrumb from 'components/TopBreadCrumb';
import Tabs from 'components/TabsV2';
import DiagramDisplay from 'containers/DiagramDisplay';
import { get } from 'common/utils';

const Page = (props) => {

    const [tabs, setTabs] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);

    const [info, setInfo] = useState(null);
    const [layoutKey, setLayoutKey] = useState(null);
    useEffect(() => {
        setLayoutKey(new URLSearchParams(props?.location?.search ?? null).get('layout_key') ?? null);
    }, [props]);
    useEffect(() => {
        if (typeof layoutKey !== 'string' || layoutKey === "") {
            return;
        };
        get(process.env.REACT_APP_SERVER_PATH + "api/page_layouts", {
            page: 1,
            page_size: 999,
            key: layoutKey,
        })
            .then((retData) => {
                let info = null;
                try {
                    const content = retData?.data?.results?.[0]?.content ?? null;
                    info = JSON.parse(content);
                    info = Object.assign({}, info, {
                        diagram_list: info.diagram_list.map((item, index) => Object.assign({}, item, { key: index + "" })),
                    });
                } catch (e) { };
                // console.log(info);
                // setSelectedTab(null);
                setTabs(info?.diagram_list ?? null);
                setInfo(info);
                let initTab = info?.diagram_list?.[0] ?? null;
                if (!!initTab) {
                    initTab = Object.assign({}, initTab);
                };
                setSelectedTab(initTab);
            });
    }, [layoutKey]);

    const breadcrumbList = useMemo(() => {
        return [{ name: '监控' }]
            .concat(
                !!info?.diagram_list ? (
                    [{ name: info?.name ?? "--" }]
                        .concat(
                            info?.diagram_list.length > 1 ? [{ name: selectedTab?.name ?? "--" }] : []
                        )
                ) : []
            );
    }, [info, selectedTab]);

    const tabOnChange = (v) => {
        setSelectedTab(Object.assign({}, v));
    };

    return (
        <div className={style['wrapper']}>
            <div className="header">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
            </div>
            {
                !!tabs && (
                    <div className="body">
                        {
                            tabs.length > 1 && (
                                <div className="top">
                                    <Tabs tabs={tabs} selectedTab={selectedTab} onChange={tabOnChange} />
                                </div>
                            )
                        }
                        <div className="bottom">
                            {
                                !!selectedTab && (
                                    <DiagramDisplay deviceId={selectedTab.uid} key={selectedTab.uid} />
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Page);
