import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import {
    CommonObj,
} from '../utils';
import { group_A, group_B } from './constans';
import Card1 from '../components/Card_1';
import Card2 from '../components/Card_2';
import { post } from 'common/utils';

const Comp = (props) => {
    const { refreshTick } = props;
    const currScene = useRef(props.sceneInfo);
    const onLoadOver = useRef(props.onLoadOver);
    const [gpAIndex, setgpAIndex] = useState(null);
    const [gpBIndex, setgpBIndex] = useState(null);
    const [tag2Value, setTag2Value] = useState({});

    const queryLock = useRef(null);
    useEffect(() => {
        if (typeof refreshTick === 'symbol') {
            // refresh signal
            const tag_name_list = []
                .concat(
                    ...(
                        group_A.children.map((child) => {
                            const { basic_tag, tag_1, tag_2, tag_3, tag_4, tag_5, tag_6 } = child;
                            return [tag_1, tag_2, tag_3, tag_4, tag_5, tag_6].map((tag) => basic_tag + tag);
                        })
                    ),
                    ...(
                        group_B.children.map((child) => {
                            const { basic_tag, tag_2, tag_3, tag_4 } = child;
                            return [tag_2, tag_3, tag_4].map((tag) => basic_tag + tag);
                        })
                    ),
                );
            const trimed_tag_name_list = [...new Set(tag_name_list)];
            const currLock = Symbol();
            queryLock.current = currLock;
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                tag_name_list: trimed_tag_name_list,
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (retData.data && retData.data.errcode === 0) {
                        setTag2Value(retData.data.results);
                    };
                });
        };
        return () => {
            queryLock.current = Symbol();
        };
    }, [refreshTick]);

    useEffect(() => {
        onLoadOver.current = props.onLoadOver;
    }, [props]);
    useEffect(() => {
        if (!currScene.current) return;
        currScene.current.displayFunc(
            {//Action-2
                num: group_A.children.length,
                cb: (index) => {
                    // console.log(`now A display no.${index}`);
                    setgpAIndex(index);
                },
            },
            {//Action-3
                cb: () => {
                    // console.log("A消失");
                    setgpAIndex(null);
                },
            },
            {//Action-4
                num: group_B.children.length,
                cb: (index) => {
                    // console.log(`now B display no.${index}`);
                    setgpBIndex(index);
                },
            },
            {//Action-5
                cb: () => {
                    // console.log("B消失");
                    setgpBIndex(null);
                },
            },
            {//over
                cb: () => {
                    // console.log("over");
                    if (typeof onLoadOver.current === 'function') {
                        onLoadOver.current();
                    };
                },
            },
        );
        return () => {
            clearTimeout(CommonObj.mySetTimeout);
        };
    }, []);
    return (
        <div className={style['wrapper']}>
            {
                typeof gpAIndex === 'number' && (
                    <div className="gpA_wrapper">
                        <div className="left">
                            {
                                group_A.children
                                    .filter((item, index) => index >= 4)
                                    .map((item, index, arr) => {
                                        const { basic_tag, tag_1, tag_2, tag_3, tag_4, tag_5, tag_6 } = item;
                                        // const values = [9.55, 66.85, 286.5, 2.54, 1.58, 3.22];
                                        const values = [tag_1, tag_2, tag_3, tag_4, tag_5, tag_6].map((tag) => tag2Value[basic_tag + tag]);
                                        return (
                                            <div style={{ marginLeft: index < arr.length - 1 ? 8 : 0 }} key={index} className={index + 4 > gpAIndex ? "card_hide" : ""}>
                                                <Card1 name={item.name} unit={item.unit} values={values} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                        <div className="right">
                            <div className="title">{group_A.name}</div>
                            {
                                group_A.children
                                    .filter((item, index) => index < 4)
                                    .map((item, index) => {
                                        const { basic_tag, tag_1, tag_2, tag_3, tag_4, tag_5, tag_6 } = item;
                                        const values = [tag_1, tag_2, tag_3, tag_4, tag_5, tag_6].map((tag) => tag2Value[basic_tag + tag]);
                                        return (
                                            <div style={{ marginTop: 8 }} key={index} className={index > gpAIndex ? "card_hide" : ""}>
                                                <Card1 name={item.name} unit={item.unit} values={values} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                )
            }
            {
                typeof gpBIndex === 'number' && (
                    <div className="gpB_wrapper">
                        <div className="title">{group_B.name}</div>
                        <div className="content">
                            {
                                group_B.children
                                    .map((item, index) => {
                                        const { basic_tag, tag_2, tag_3, tag_4, precision_1, precision_2, precision_3 } = item;
                                        const values = [null, tag_2, tag_3, tag_4].map((tag) => typeof tag === 'string' ? tag2Value[basic_tag + tag] : null);
                                        const precisions = [null, precision_1, precision_2, precision_3].map((v) => typeof v === 'number' ? v : null);
                                        return (
                                            <div style={{ marginLeft: index > 0 ? 8 : 0 }} key={index} className={index > gpBIndex ? "card_hide" : ""}>
                                                <Card2 name={item.name} unit={item.unit} standard={item.standard} values={values} precisions={precisions} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Comp
