import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { Provider } from 'react-redux';
import store from './common/redux/store.js';
// import 'antd/dist/antd.dark.css';//less?
import 'antd/dist/antd.css';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>, document.getElementById('root'));
