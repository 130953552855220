import React, {useEffect, useState, useRef} from 'react'
import {Empty, Avatar} from 'antd'
import moment from 'moment'
import {get, getCachedUserInfo} from 'common/utils'
import {useClassifyTagList, useMessage} from '../../../hooks'
import {
    TIME_RANGE_HOURS,
    debounceTime,
    urgencyAndColor,
    formatDateTimeToText,
    getCurrentTime
} from '../../../utils'
import FullScreenLoading from 'components/FullScreenLoading'
import MiniCapsuleDetail from '../Dialogs/MiniCapsuleDetail'
import MyAvatar from '../MyAvatar'
import NewEventsCounter from '../NewEventCounter'
import style from './index.module.scss'

const baseUrl = process.env.REACT_APP_SERVER_PATH

const getCurrentUserId = () => {
    const userInfo = getCachedUserInfo()
    if (!userInfo || typeof userInfo.user_id !== 'number') {
        // 此处不提示，因为可能与其他地方重复提示
        return null
    }
    return userInfo.user_id
} // 写函数不写外部变量的原因在于，重新登陆后后者不会更新

// 事件窗口
const Events = props => {
    const {endTime, refreshNum, setEndTime, setInnerRangeEvents, setInnerViewTimeRange} = props
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState(null) // 事件
    const currUserId = useRef(getCurrentUserId())
    const [templateClassification] = useClassifyTagList([])
    const [activeCapsule, setActiveCapsule] = useState(null)
    const queryLock = useRef(null)
    const wrapperRef = useRef()
    const [messageService, messageInfo] = useMessage() // 消息服务
    const [newEvents, setNewEvents] = useState([]) // 新事件列表
    const [timePoint, setTimePoint] = useState(endTime) // 在此时间点后生成的事件才进行计数

    /** 时间轴结束时间变更后重新获取胶囊面板数据 */
    useEffect(() => {
        if (endTime && moment(endTime).isSameOrAfter(timePoint)) {
            setTimePoint(endTime)
        }
        getEvents()
        return () => {
            queryLock.current = Symbol()
        }
    }, [refreshNum, endTime])

    /** 接收新消息 */
    useEffect(() => {
        messageInfo && onMessage(messageInfo)
    }, [messageInfo])

    /** 获取事件和最新评论 */
    const getEvents = () => {
        setLoading(true)
        const currLock = (queryLock.current = Symbol())
        get(`${baseUrl}api/v1/event/capsule/capsulesCommentQueries`, {
            startDate: moment(endTime).subtract(TIME_RANGE_HOURS, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            stopDate: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
            useId: 1 // 筛掉没有userId的
        }).then(resultData => {
            if (currLock !== queryLock.current) return
            setLoading(false)
            handleEvents(resultData.data)
        })
    }

    /** 处理事件查询结果 */
    const handleEvents = resultData => {
        if (!!resultData && resultData.error === 0) {
            if (!!resultData.results) {
                const tempList = resultData.results.map(item => {
                    return handleSingleEvent(item)
                })
                setEvents(tempList)
            } else {
                setEvents(null)
            }
            scrollToBottom()
            calculateEventTimeRange(resultData.results || [])
        }
    }

    /** 处理单个事件信息 */
    const handleSingleEvent = item => {
        const {json_content, occur_time} = item
        // urgency 紧急程度 目前传回了0，还未定义其他紧急情况时的值，后续再添加
        let timeText = null
        let titleText = ''
        let classifyId = null
        try {
            const jsonText = JSON.parse(json_content)
            if (!!jsonText) {
                if (typeof jsonText.title === 'string') {
                    titleText = jsonText.title
                }
                if (typeof jsonText.classify === 'number') {
                    classifyId = jsonText.classify
                }
            }
        } catch (error) {
        }

        try {
            timeText = moment(occur_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss')
        } catch (err) {
        }
        return Object.assign({}, item, {
            timeText,
            titleText,
            classifyId
        })
    }

    /** 由于时间是正序的 所以需要将滚动条滚动到底部 */
    const scrollToBottom = () => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
        }
    }

    /** 计算可视范围内的事件发生时间起始时间 */
    const calculateEventTimeRange = sourceData => {
        if (wrapperRef.current) {
            const scrollRef = wrapperRef.current
            const scrollHeight = scrollRef.clientHeight
            const scrollTop = scrollRef.scrollTop
            const eventDoms = wrapperRef.current.getElementsByClassName('event-item') || []
            const eventRange = []
            let start = null
            let end = null

            for (var i = 0; i < eventDoms.length; i++) {
                const singleHeight = eventDoms[i].clientHeight
                const offsetTop = eventDoms[i].offsetTop
                if (offsetTop + singleHeight - scrollTop > 0 && offsetTop - scrollTop < scrollHeight) {
                    const id = sourceData[i].id
                    if (newEvents.some(item => item.id === id)) {
                        setNewEvents(newEvents.filter(newItem => newItem.id !== id))
                    }
                    eventRange.push(sourceData[i])
                }
            }
            if (eventRange.length > 0) {
                // 事件时间是正序
                start = eventRange[0].occur_time
                end = eventRange[eventRange.length - 1].occur_time
            }
            if (typeof setInnerViewTimeRange === 'function') setInnerViewTimeRange({start, end})
            if (typeof setInnerRangeEvents === 'function') setInnerRangeEvents(sourceData)
        }
    }

    /** 点击胶囊弹出胶囊详情缩小版 */
    const eventOnClick = (id, index, isOwn) => {
        // 带有new标记的胶囊事件，点击后即消失
        if (events[index].new === 1) {
            const tempList = events.concat()
            tempList[index].new = 0
            setEvents(tempList)
        }

        handleAtRightClass('add', isOwn ? 'move-left' : 'move-right')
        setActiveCapsule({id, isOwn})
    }

    /** 关闭缩小版胶囊 */
    const closeMiniCapsulePanel = () => {
        setTimeout(() => {
            setActiveCapsule(null)
            handleAtRightClass('add', 'move-right')
        }, 0)
    }

    /** 点击放大胶囊,同时关闭缩小胶囊 */
    const enlargeClick = id => {
        closeMiniCapsulePanel()
        if (typeof props.editEventOnClick === 'function') props.editEventOnClick(id, false)
    }

    /** 编辑胶囊,弹出放大版编辑状态,同时关闭缩小胶囊 */
    const editClick = id => {
        closeMiniCapsulePanel()
        if (typeof props.editEventOnClick === 'function') props.editEventOnClick(id, true)
    }

    //   const scroll = debounceTime(e => {
    //     calculateEventTimeRange(events || [])
    //   }, 500)
    const scroll = () => {
        calculateEventTimeRange(events || [])
    }

    /** 处理胶囊左移右移样式
     * @param type add/remove
     * @param className move-left/move-right
     */
    const handleAtRightClass = (type, className) => {
        if (wrapperRef.current) {
            const rightDoms = wrapperRef.current.getElementsByClassName('at_right')
            for (let i = 0; i < rightDoms.length; i++) {
                const dom = rightDoms[i]
                if (type === 'add') dom.classList.add(className)
                if (type === 'remove') dom.classList.remove(className)
            }
            if (type === 'add' && className === 'move-right') {
                setTimeout(() => {
                    handleAtRightClass('remove', 'move-left')
                    handleAtRightClass('remove', 'move-right')
                }, 500)
            }
        }
    }

    /** 截取字符串 */
    const substringText = (str, len) => {
        if (!str) return ''
        return str.substring(0, len)
    }

    /** 给消息分类 */
    const onMessage = data => {
        console.log('处理新事件消息：', data)
        const {occurTime: occur_time, userId: user_id, jsonContent: json_content} = data
        // 如果是当前用户创建的事件 则跳到指定时间点
        if (user_id === currUserId.current) {
            setEndTime(moment(occur_time))
        } else if (
            occur_time &&
            moment(occur_time).isSameOrAfter(moment(endTime).subtract(TIME_RANGE_HOURS, 'hours')) &&
            moment(occur_time).isSameOrBefore(endTime)
        ) {
            const isBottom =
                wrapperRef.current &&
                wrapperRef.current.clientHeight >=
                wrapperRef.current.scrollHeight - wrapperRef.current.scrollTop
            // 在当前时间轴范围内的 直接插入到events中
            const tempList = events.concat()
            const index = tempList.findIndex(event =>
                moment(occur_time).isSameOrBefore(moment(event.occur_time))
            )
            const item = handleSingleEvent({
                ...messageInfo,
                user_id,
                occur_time: moment(occur_time).format('YYYY-MM-DD HH:mm:ss'),
                json_content,
                new: 1
            })
            if (index === -1) {
                tempList.push(item)
            } else {
                tempList.splice(index, 0, item)
            }
            setEvents(tempList)
            // 如果本来就在窗口最底部 在底部插入之后则仍然滚动到最底部
            isBottom && index === -1 && scrollToBottom()
            calculateEventTimeRange(tempList)
        } else if (moment(occur_time).isAfter(timePoint)) {
            // 不在当前时间轴范围内，且在时间标志之后的 计入新事件
            const tempEvents = newEvents.concat()
            tempEvents.push(messageInfo)
            setNewEvents(tempEvents)
        }
    }

    return (
        <div className={style['EventWrapper']} onScrollCapture={scroll}>
            {newEvents.length > 0 && (
                <NewEventsCounter
                    count={newEvents.length}
                    clickFun={() => setEndTime(moment(getCurrentTime()))}
                />
            )}
            <div ref={wrapperRef} className="events">
                {!!events &&
                events.map((eventItem, index) => {
                    const {
                        user_id,
                        username,
                        timeText,
                        titleText,
                        id,
                        orgName,
                        classifyId,
                        urgency,
                        new: isNew
                    } = eventItem
                    const isOwn = user_id === currUserId.current // 登录者本人发布的事件
                    return (
                        <div key={index} className={`event-item ${isOwn ? 'at_right' : 'at_left'}`}>
                            <div className="creator-info">
                                <MyAvatar size={52} iconText={substringText(username, 1)}/>
                                <span className="creator">{substringText(username, 5)}</span>
                                {orgName && <span className="org-name">{substringText(orgName, 6)}</span>}
                            </div>
                            <div className="event-info">
                                <div className="time">{timeText}</div>
                                <div
                                    className="event-content pop-card"
                                    //   style={{ backgroundColor: urgencyAndColor[urgency] }}
                                >
                                    <div className="event-title" onClick={() => eventOnClick(id, index, isOwn)}>
                      <span
                          className={`classify-and-new ${!classifyId && isNew !== 1 ? 'hidden' : ''}`}
                      >
                        {classifyId ? templateClassification[classifyId] + ' | ' : ''}
                          {isNew === 1 && <i className="new-icon">New</i>}
                      </span>
                                        <div style={{padding: `10px 10px 10px 0px`, background: '#fff'}}>
                                            <span className="multi-ellipsis--l2">{titleText}</span>
                                        </div>
                                        {activeCapsule && activeCapsule.id === id && (
                                            <>
                                                <MiniCapsuleDetail
                                                    capsuleId={id}
                                                    classifyText={templateClassification[classifyId] || ''}
                                                    detailInfo={eventItem}
                                                    editClick={editClick}
                                                    enlargeClick={enlargeClick}
                                                    closeClick={closeMiniCapsulePanel}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <i
                                        className={`pop-arrow ${isOwn ? 'arrow-right' : 'arrow-left'}`}
                                        // style={{ borderColor: urgencyAndColor[urgency] }}
                                    />
                                </div>
                                {eventItem.comment && eventItem.comment.length > 0 && (
                                    <div className="comment-item">
                                        <div className="time">{eventItem.comment[0].create_time}</div>
                                        <div className="comment-content">
                                            {!isOwn && (
                                                <span style={{marginRight: 10}}>
                            {substringText(eventItem.comment[0].username, 5)}
                          </span>
                                            )}
                                            <div className="pop-card" style={{padding: 10}}>
                                                <i className={`pop-arrow ${isOwn ? 'arrow-right' : 'arrow-left'}`}/>
                                                <span className="ellipsis--l1">{eventItem.comment[0].comment}</span>
                                            </div>
                                            {isOwn && (
                                                <span style={{marginLeft: 10}}>
                            {substringText(eventItem.comment[0].username, 5)}
                          </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
                {!loading && (!events || events.length === 0) && <Empty/>}
            </div>

            {loading && <FullScreenLoading/>}
        </div>
    )
}

export default Events
