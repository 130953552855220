import React from 'react';

import './ExcelEditor.scss';
// import 'antd/dist/antd.less';

export default class ExcelEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file_key: props.file_key,
            iframe_id: 'iframe-' + parseInt(new Date().getTime()/1000) + '_' + parseInt(Math.random()*10000),
            title: props.title,
            doc_is_saving: false,
            save_doc_resolver: null,
            doc_saving_num: 0,
            doc_saved_num: 0,
        };
    }

    componentDidMount() {
        this.init_doc();
        this.props.getSaveFunc(this.save_doc);
    }

    componentWillUnmount() {
        if(this.docEditor){
            this.docEditor.destroyEditor();
        }
    }

    onAppReady = (e) => {
        console.log('onAppReady', e);
        this.state.doc_saving_num = 0;
        this.state.doc_saved_num = 0;
    }

    onDocumentReady = (e) => {
        console.log('onDocumentReady', e);
        let self = this;
        setTimeout(()=>{
            self.state.doc_saving_num = 0;
            self.state.doc_saved_num = 0;
            console.log('num cleared');
        }, 0);
    }

    onDocumentStateChange = (e)=>{
        if (e.data) {
            this.state.doc_saving_num += 1;
            console.log('['+this.state.iframe_id+'] Doc saving. ('+this.state.doc_saving_num+')');
            this.state.doc_is_saving = true;
        } else {
            this.state.doc_saved_num += 1;
            console.log('['+this.state.iframe_id+'] Doc saved. ('+this.state.doc_saved_num+')');
            this.state.doc_is_saving = false;
            if(this.state.save_doc_resolver !== null){
                this.state.save_doc_resolver({});
            }
        }
    }

    save_doc = ()=>{
        let self = this;
        return new Promise((resolve, reject)=>{
            let iframe_el = self.editor_node.getElementsByTagName("iframe")[0].contentWindow;
            iframe_el.postMessage(JSON.stringify({action: "save-xlsx"}), "*");
            setTimeout(()=>{
                if(self.doc_is_saving === true){
                    self.state.save_doc_resolver = resolve;
                }else{
                    resolve({});
                }
            }, 500);
        })
    }

    init_doc = ()=>{
        console.log('init_doc');
        let config = {
            "width": this.props.width+'px',
            "height": this.props.height+'px',
            "events": {
                "onAppReady": this.onAppReady,
                "onDocumentReady": this.onDocumentReady,
                "onDocumentStateChange": this.onDocumentStateChange,
            },
            "document": {
                "fileType": "xlsx",
                "key": this.state.file_key,
                "title": this.state.title,
                "url": process.env.REACT_APP_SERVER_INNER + "/api/office/open_tmp_file?file_key="+this.state.file_key,
                "permissions": {
                    "comment": false,
                    "copy": true,
                    "download": false,
                    "edit": (this.props.readonly === true?false:true),
                    "fillForms": true,
                    "modifyContentControl": false,
                    "modifyFilter": false,
                    "print": true,
                    "review": false
                },
            },
            "documentType": "cell",
            "editorConfig": {
                "mode": (this.props.readonly === true?'view':'edit'),
                "lang": "zh-CN",//"en-US",
                "canCoAuthoring": false,
                "callbackUrl":  process.env.REACT_APP_SERVER_INNER + "/api/office/callback",
                "customization": {
                    "about": false,
                    // "loaderName": "PEIMS", //不可修改，会报没有授权的错误
                    "chat": false,
                    "autosave": false,
                    "commentAuthorOnly": false,
                    "comments": false,
                    "toolbar": false,
                    "toolbarHideFileName": true,
                    "compactHeader": true,  //隐藏标题栏
                    "compactToolbar": true, //隐藏工具栏
                    "compatibleFeatures": false, //
                    // "customer": {
                    //     "address": "My City, 123a-45",
                    //     "info": "Some additional information",
                    //     "logo": "https://example.com/logo-big.png",
                    //     "mail": "john@example.com",
                    //     "name": "John Smith and Co.",
                    //     "www": "example.com"
                    // },
                    "feedback": {
                        "url": "https://example.com",
                        "visible": false
                    },
                    "forcesave": true,
                    "goback": false,
                    "help": false,
                    "hideRightMenu": true,
                    "leftMenu": false,
                    "rightMenu": false,
                    // "logo": {
                    //     "image": "https://example.com/logo.png",
                    //     "imageEmbedded": "https://example.com/logo_em.png",
                    //     "url": "https://www.onlyoffice.com"
                    // },
                    "macros": false,
                    "macrosMode": "disable",
                    "mentionShare": false,
                    "plugins": false,
                    "reviewDisplay": "original",
                    "showReviewChanges": false,
                    "spellcheck": false,
                    "toolbarNoTabs": true,
                    "unit": "cm",
                    "zoom": 100
                },
            },
        };
        // let _DocEditor = window.DocsAPI;
        this.docEditor = new window.DocsAPI.DocEditor(this.state.iframe_id, config);
    }

    render() {
        let self = this;
        // const { jsonList } = this.state;

        return (
            <div className="ExcelEditor" ref={(node) => { if (node) { self.editor_node = node } }}>
                <div id={this.state.iframe_id}></div>
            </div>
        );
    }
}
