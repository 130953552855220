const getTemplateContentText = (templateId, jsonContent) => {
  if (templateId === 1) {
    const fanInfo = (jsonContent.fanList || [])
      .filter(item => item.value || item.value === 0)
      .map(item => item.label + '(' + item.value + ')')
    return ` ${fanInfo.join(' ')} ${jsonContent.info || ''}`
  } else if (templateId === 2) {
    const threshold_1 =
      jsonContent.threshold_1 === 2
        ? ' 阀1开启(' + jsonContent.number_1 + ')cm'
        : jsonContent.threshold_1 === 3
        ? ' 阀1关闭(' + jsonContent.number_1 + ')cm'
        : ''
    const threshold_2 =
      jsonContent.threshold_2 === 2
        ? ' 阀2开启(' + jsonContent.number_2 + ')cm'
        : jsonContent.threshold_2 === 3
        ? ' 阀2关闭(' + jsonContent.number_2 + ')cm'
        : ''
    return ` ${threshold_1}${threshold_2} ${jsonContent.info || ''}`
  } else if (templateId === 3) {
    return ` 头部${jsonContent.headLabel === 1 ? '抬高(' : '降低('}${
      jsonContent.headNumber
    }cm) 整体${jsonContent.complateLabel === 1 ? '移入(' : '移出('}${
      jsonContent.complateNumber
    }cm) ${jsonContent.info || ''}`
  } else if (templateId === 4) {
    return ` ${jsonContent.info || ''}`
  } else if (templateId === 5) {
    let libraryInfo = (jsonContent.libraryList || [])
      .filter(item => item.value || item.value === 0)
      .map(item => '库' + item.name + '：(库空' + item.value + ')')
      .join(' ')
    libraryInfo +=
      ' 入库 ' +
      (jsonContent.libraryList || [])
        .filter(item => item.checkStatus)
        .map(item => item.name)
        .join('/')
    return ` ${libraryInfo} ${jsonContent.info || ''}`
  } else if (templateId === 6) {
    return ` (${jsonContent.elec_num || 0}万kWh) ${jsonContent.info || ''}`
  } else {
    return '暂无'
  }
}

export { getTemplateContentText }
