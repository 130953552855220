import React, { useEffect, useMemo, useState } from 'react'
import { Button, Drawer, Table, Radio, Input, message, Checkbox } from 'antd'
import style from './index.module.scss'
import { get, post, significantNum, roundNum } from 'common/utils';
import { withRouter } from 'react-router';

const calcId = (props) => {
    // 使用props里直传的id, 不使用url解析id
    let parsedId = null;
    // let id = props?.match?.params?.id ?? null;
    // id = parseFloat(id);
    const id = props?.ingredCalcId ?? null;
    if (typeof id === 'number' && !isNaN(id)) {
        parsedId = id;
    };
    return parsedId;
};

const parseData = (conf) => {
    let nextInfo = null;
    const opt_conf = conf ?? null;

    if (!!opt_conf) {

        let parsed_json = opt_conf;
        let { matl_comp_constr, matl_factor_constr, matl_wt_limit } = parsed_json;
        matl_comp_constr = matl_comp_constr
            .map((item, index) => Object.assign({}, item, { key: index + '' }));
        matl_factor_constr = matl_factor_constr
            .map((item, index) => Object.assign({}, item, { key: index + '' }));
        matl_wt_limit = matl_wt_limit
            .map((item, index) => Object.assign({}, item, { key: index + '' }));

        parsed_json = Object.assign({}, parsed_json, { matl_comp_constr, matl_factor_constr, matl_wt_limit });

        nextInfo = {
            opt_conf: parsed_json,
        };
    };
    return nextInfo;
};

const Comp = (props) => {
    const [ingredCalcId, setIngredCalcId] = useState(() => {
        return calcId(props);
    });
    useEffect(() => {
        setIngredCalcId(calcId(props));
    }, [props]);

    const { isShow, closeDrawer } = props;

    const [refresh, setRefresh] = useState(() => {
        return Symbol();
    });
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(null);
    const [updateWtLoading, setUpdateWtLoading] = useState(false);
    useEffect(() => {
        if (typeof ingredCalcId !== 'number') {
            setInfo(null);
            return;
        };
        if (isShow === true) {
            let delay = false;
            setLoading(true);
            get(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/get_opt_conf/${ingredCalcId}`, {})
                .then(retData => {
                    if (delay) return;
                    setLoading(false);
                    // console.log(retData);
                    setInfo(parseData(retData?.data?.result ?? null));
                });
            return () => {
                delay = true;
            };
        } else {
            setInfo(null);
        };
    }, [ingredCalcId, isShow, refresh]);

    const inputOnChange = (key, rowIndex, columnKey) => {
        return (e) => {
            const value = e.target.value;
            setInfo((prevInfo) => {
                const obj = prevInfo.opt_conf[key];
                const nextObj = obj
                    .map((item, index) => {
                        if (index === rowIndex) {
                            return Object.assign(
                                {},
                                item,
                                {
                                    [columnKey]: value,
                                },
                            );
                        } else {
                            return item;
                        };
                    });
                const nextOptConf = Object.assign({}, prevInfo.opt_conf, {
                    [key]: nextObj,
                });
                return Object.assign({}, prevInfo, { opt_conf: nextOptConf });
            });
        };
    };

    const rawMaterialColumns = [
        {
            dataIndex: 'is_use',
            align: 'center',
            render: (text) => <Checkbox checked={text === 1} disabled />,
            width: 35,
        },
        {
            title: '矿种',
            dataIndex: 'type',
            align: 'center',
            width: 60,
        },
        {
            title: '矿名',
            dataIndex: 'matl_name',
            align: 'center',
            width: 90,
        },
        {
            title: () => {
                return (
                    <div style={{ textAlign: 'center' }}>原料</div>
                );
            },
            dataIndex: 'full_name',
            align: 'left',
            // width: 150,
        },
        {
            title: '优化初值',
            dataIndex: 'init_value',
            align: 'center',
            render: (text, record, rowIndex) => <Input value={text} size="small" onChange={inputOnChange("matl_wt_limit", rowIndex, "init_value")} />,
            width: 90,
        },
        {
            title: '最小料比',
            dataIndex: 'wt_lower_limit',
            align: 'center',
            render: (text, record, rowIndex) => <Input value={text} size="small" onChange={inputOnChange("matl_wt_limit", rowIndex, "wt_lower_limit")} />,
            width: 90,
        },
        {
            title: '最大料比',
            dataIndex: 'wt_upper_limit',
            align: 'center',
            render: (text, record, rowIndex) => <Input value={text} size="small" onChange={inputOnChange("matl_wt_limit", rowIndex, "wt_upper_limit")} />,
            width: 90,
        },
        {
            title: '当前料比',
            dataIndex: 'wt_init',
            align: 'center',
            render: (text) => (typeof text === 'number' && !isNaN(text)) ? significantNum(text) : "--",
            width: 80,
        },
        {
            title: '优化料比',
            dataIndex: 'wt_opt',
            align: 'center',
            render: (text) => (typeof text === 'number' && !isNaN(text)) ? significantNum(text) : "--",
            width: 80,
        },
        {
            title: '占比变化',
            key: 'delta',
            align: 'right',
            width: 80,
            render: (text, record) => {
                if(!record.is_use){
                    return '--';
                }
                let { wt_init, wt_opt } = record;
                let datas = info?.opt_conf?.matl_wt_limit ?? [];
                datas = datas.filter(item=>item.is_use);
                let init_datas = datas.map(item=>item.wt_init).filter(item=>(typeof item === 'number' && !isNaN(item)));
                let opt_datas = datas.map(item=>item.wt_opt).filter(item=>(typeof item === 'number' && !isNaN(item)));
                let init_sum = init_datas.reduce((prev,cur)=>{
                                    return prev + cur;
                                },0);
                let opt_sum = opt_datas.reduce((prev,cur)=>{
                                    return prev + cur;
                                },0);
                if(!(typeof wt_init === 'number' && !isNaN(wt_init)) || !(typeof wt_opt === 'number' && !isNaN(wt_opt)) || init_sum === 0 || opt_sum === 0){
                    return ''
                }
                let pct_chg = (wt_opt/opt_sum - wt_init/init_sum)/(wt_init/init_sum)*100;
                return roundNum(pct_chg, 1) + ' %';
            },
        },
    ];

    const radioOnChange = (key, rowIndex) => {
        return inputOnChange(key, rowIndex, "value_range");
    };

    const getMainIndexColumns = (key) => {
        return [
            {
                title: '成分',
                dataIndex: 'comp',
                align: 'center',
                width: 100
            },
            {
                title: '取值方式',
                dataIndex: 'value_range',
                align: 'center',
                render: (text, record, rowIndex) => {
                    return (
                        <Radio.Group onChange={radioOnChange(key, rowIndex)} value={text}>
                            <Radio value="value">目标值</Radio>
                            <Radio value="range">范围</Radio>
                        </Radio.Group>
                    );
                },
                width: 200
            },
            {
                title: '目标值',
                dataIndex: 'value',
                align: 'center',
                render: (text, record, rowIndex) => <Input value={text} style={{ width: 80 }} size="small" onChange={inputOnChange(key, rowIndex, "value")} disabled={(record?.value_range ?? null) !== 'value'} />,
            },
            {
                title: '最小值',
                dataIndex: 'LL',
                align: 'center',
                render: (text, record, rowIndex) => <Input value={text} style={{ width: 80 }} size="small" onChange={inputOnChange(key, rowIndex, "LL")} disabled={(record?.value_range ?? null) !== 'range'} />,
            },
            {
                title: '最大值',
                dataIndex: 'HH',
                align: 'center',
                render: (text, record, rowIndex) => <Input value={text} style={{ width: 80 }} size="small" onChange={inputOnChange(key, rowIndex, "HH")} disabled={(record?.value_range ?? null) !== 'range'} />,
            },
            {
                title: '当前值',
                dataIndex: 'v_init',
                align: 'center',
                render: (text) => (typeof text === 'number' && !isNaN(text)) ? significantNum(text) : "--",
            },
            {
                title: '优化值',
                dataIndex: 'v_opt',
                align: 'center',
                render: (text) => (typeof text === 'number' && !isNaN(text)) ? significantNum(text) : "--",
            },
            {
                title: '偏差率',
                dataIndex: 'dev_pct',
                align: 'center',
                render: (text, record) => {
                    const { is_use } = record;
                    if (is_use === 1) {
                        const { value_range, v_opt, v_init } = record;
                        if (value_range === 'value') {
                            if (
                                (typeof v_opt === 'number' && !isNaN(v_opt))
                                && (typeof v_init === 'number' && !isNaN(v_init))
                                && v_init !== 0
                            ) {
                                const v = (v_opt - v_init) / v_init * 100;
                                const warning = v > 1;
                                return (
                                    <div style={warning ? {
                                        color: 'red',
                                    } : {}}>{significantNum(v, 3, 2)}%</div>
                                );
                            };
                        } else if (value_range === 'range') {
                            if (
                                (typeof v_opt === 'number' && !isNaN(v_opt))
                            ) {
                                let val = null;
                                const { LL, HH } = record;
                                const parseLL = parseFloat(LL);
                                const parseHH = parseFloat(HH);
                                if (
                                    (typeof parseLL === 'number' && !isNaN(parseLL)) ||
                                    (typeof parseHH === 'number' && !isNaN(parseHH))
                                ) {
                                    // 至少有一个是数字
                                    if (
                                        (typeof parseLL === 'number' && !isNaN(parseLL)) &&
                                        (typeof parseHH === 'number' && !isNaN(parseHH))
                                    ) {
                                        // 全是数字
                                        if (parseLL <= parseHH) {
                                            // 最大最小值合理
                                            if (v_opt < parseLL) {
                                                if (parseLL !== 0) {
                                                    val = (v_opt - parseLL) / parseLL * 100;
                                                };
                                            } else if (v_opt > parseHH) {
                                                if (parseHH !== 0) {
                                                    val = (v_opt - parseHH) / parseHH * 100;
                                                };
                                            } else {
                                                val = 0;
                                            };
                                        } else {
                                            // 最大最小值不合理
                                            // 不认为可算，放弃
                                        };
                                    } else {
                                        //  有一个数字
                                        if ((typeof parseLL === 'number' && !isNaN(parseLL))) {
                                            // parseLL 是数字
                                            if (v_opt < parseLL) {
                                                // 小于唯一的最小值
                                                // 判断分母不为0
                                                if (parseLL !== 0) {
                                                    val = (v_opt - parseLL) / parseLL * 100;
                                                };
                                            } else {
                                                // 不小于唯一的最小值
                                                val = 0;
                                            };
                                        } else {
                                            // parseHH 是数字
                                            if (v_opt > parseHH) {
                                                // 大于唯一的最大值
                                                // 判断分母不为0
                                                if (parseHH !== 0) {
                                                    val = (v_opt - parseHH) / parseHH * 100;
                                                };
                                            } else {
                                                // 不大于唯一的最大值
                                                val = 0;
                                            };
                                        };
                                    };
                                } else {
                                    // 都不是数字
                                };
                                if (typeof val === 'number' && !isNaN(val)) {
                                    // 存在范围偏差，按照条件显示
                                    const warning = (val !== 0);
                                    return (
                                        <div style={warning ? {
                                            color: 'red',
                                        } : {}}>{significantNum(val, 3, 2)}%</div>
                                    );
                                };
                            };
                        } else { };
                    };
                    return "--%";
                },
            },
        ];
    };

    const selectedRowOnChange = (key) => {
        return (selectedRowKeys) => {
            setInfo((prevInfo) => {
                // 一定存在对应的key对象，不需要校验
                const obj = prevInfo.opt_conf[key];
                const nextObj = obj
                    .map((item) => {
                        return Object.assign(
                            {},
                            item,
                            {
                                is_use: selectedRowKeys.includes(item.key) ? 1 : 0,
                            },
                        );
                    });
                const nextOptConf = Object.assign({}, prevInfo.opt_conf, {
                    [key]: nextObj,
                });
                return Object.assign({}, prevInfo, { opt_conf: nextOptConf });
            });
        };
    };

    const getSaveData = () => {
        let preSaveData = info?.opt_conf ?? null;

        if (!!preSaveData) {
            // 处理，有内容，不校验，必然是存在的
            const formatRight =
                [
                    ["matl_wt_limit", "init_value", "优化初值"],
                    ["matl_wt_limit", "wt_lower_limit", "最小料比"],
                    ["matl_wt_limit", "wt_upper_limit", "最大料比"],
                    ["matl_comp_constr", "value", "目标值"],
                    ["matl_comp_constr", "LL", "最小值"],
                    ["matl_comp_constr", "HH", "最大值"],
                    ["matl_factor_constr", "value", "目标值"],
                    ["matl_factor_constr", "LL", "最小值"],
                    ["matl_factor_constr", "HH", "最大值"],
                ]
                    .some(([key, valueKey, text = ""]) => {
                        // console.log(key, valueKey);
                        const obj = preSaveData[key];
                        let ret = false;
                        const nextObj = obj
                            .map((item) => {
                                let v = item[valueKey];
                                if (v === null || v === "" || v === undefined) {
                                    return Object.assign(
                                        {},
                                        item,
                                        {
                                            [valueKey]: null,
                                        },
                                    );
                                };
                                v = parseFloat(v);
                                if (typeof v === 'number' && !isNaN(v)) {
                                    return Object.assign(
                                        {},
                                        item,
                                        {
                                            [valueKey]: v,
                                        },
                                    );
                                } else {
                                    if (ret === false) {
                                        // 第一次的时候提示
                                        message.warning(`${text}数字格式有误，请正确填写后重试！`);
                                    };
                                    ret = true;
                                    return item;
                                };
                            });
                        preSaveData = Object.assign(
                            {},
                            preSaveData,
                            {
                                [key]: nextObj,
                            },
                        );
                        return ret;
                    });
            if (formatRight === true) {
                preSaveData = null;
            } else {
                // remove key
                ["matl_wt_limit", "matl_comp_constr", "matl_factor_constr"]
                    .some((key) => {
                        const obj = preSaveData[key];
                        const nextObj = obj
                            .map((item) => {
                                const { key, ...rest } = item;
                                return rest;
                            });
                        preSaveData = Object.assign(
                            {},
                            preSaveData,
                            {
                                [key]: nextObj,
                            },
                        );
                        return false;
                    });
            };
        };
        return preSaveData;
    };

    const saveData = () => {

        const preSaveData = getSaveData();
        // console.log("saveData", info?.opt_conf ?? null, preSaveData);

        if (!!preSaveData) {
            // save
            // console.log(preSaveData);
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/patch_one/${ingredCalcId}`, {
                opt_conf: preSaveData,
            })
                .then((retData) => {
                    setLoading(false);
                    // console.log(retData);
                    if ((retData?.data?.errcode ?? null) === 0) {
                        message.success("保存成功!");
                        // 刷新内容
                        setRefresh(Symbol());
                        // 直接退出
                        // closeDrawer(true);
                    } else {
                        message.warning("保存失败!");
                    };
                });
        } else { };
    };

    const optOnClick = () => {
        const preSaveData = getSaveData();
        if (!!preSaveData) {
            const mergedData = Object.assign(
                {},
                {
                    cost_ori: null,
                    cost_opt: null,
                    cost_reduction: null,
                },
                preSaveData,
            );
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/calc_opt/${ingredCalcId}`, {
                opt_conf: mergedData,
            }).then((retData) => {
                setLoading(false);
                // console.log(retData);
                if ((retData?.data?.errcode ?? null) === 0) {
                    // 计算成功
                    message.success("优化计算成功!");
                    setInfo(parseData(retData?.data?.result ?? null));
                } else {
                    // 计算失败
                    message.warning("优化计算失败!");
                };
            });
        };
    };

    const updateWeight = () => {
        setUpdateWtLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/update_weight_from_opt/${ingredCalcId}`, {
        }).then((retData) => {
            setUpdateWtLoading(false);
            if ((retData?.data?.errcode ?? null) === 0) {
                message.success("更新成功");
                closeDrawer(true);
            } else {
                message.warning("更新失败");
            };
        });
    };

    const [text1, text2, text3, text4] = useMemo(() => {
        const costOri = info?.opt_conf?.cost_ori ?? null;
        const costOpt = info?.opt_conf?.cost_opt ?? null;
        const costReduction = info?.opt_conf?.cost_reduction ?? null;
        const parsed_v1 = significantNum(costOri, 5, 3, "--");
        const parsed_v2 = significantNum(costOpt, 5, 3, "--");
        let parsed_v3 = null;
        let parsed_v4 = null;
        if (typeof costReduction === 'number' && !isNaN(costReduction)) {
            if (costReduction > 0) {
                parsed_v3 = "节省";
                parsed_v4 = significantNum(costReduction, 3, 3);
            } else if (costReduction < 0) {
                parsed_v3 = "上升";
                parsed_v4 = significantNum(Math.abs(costReduction), 3, 3);
            } else {
                // ===0
                parsed_v3 = "持平";
            };
        } else {
            // 数字无效,无法判断,这句话不显示,都是null
        };
        return [parsed_v1, parsed_v2, parsed_v3, parsed_v4];
    }, [info]);

    return (
        <Drawer title="优化配料"
            placement="right"
            closable={true}
            width={1000} visible={isShow}
            onClose={closeDrawer}
            footer={[<div style={{ display: "flex", justifyContent: "space-between" }} key="1">
                <div>
                    <Button type="primary" disabled={loading} onClick={optOnClick}>优化计算</Button>
                    <Button type="primary" ghost style={{ marginLeft: '12px' }} loading={updateWtLoading} onClick={updateWeight}>更新料比</Button>
                    <span style={{ marginLeft: 16 }}>当前成本: {text1}元/t; 优化成本: {text2}元/t{typeof text3 === 'string' ? `, 较当前成本${text3}` : ""}</span>
                    {
                        typeof text4 === 'number' && (
                            <span style={{ color: "rgba(134,193,42,1)", fontWeight: 'bold' }}>{text4}%</span>
                        )
                    }
                </div>
                <div>
                    <Button type="primary" ghost onClick={() => closeDrawer(false)}>关闭</Button>
                    <Button type="primary" style={{ marginLeft: 12 }} onClick={saveData} disabled={loading}>保存</Button>
                </div>

            </div>]}>
            <div className={style['wrapper']}>
                <div className="container">
                    <div className="title">入炉原料寻优范围</div>
                    <Table
                        loading={loading}
                        // rowSelection={{
                        //     selectedRowKeys: (info?.opt_conf?.matl_wt_limit ?? [])
                        //         .filter(({ is_use }) => is_use === 1)
                        //         .map(({ key }) => key),
                        //     onChange: selectedRowOnChange("matl_wt_limit"),
                        // }}
                        columns={rawMaterialColumns}
                        dataSource={info?.opt_conf?.matl_wt_limit ?? []}
                        pagination={false} size="small"
                    />
                    <div className="title" style={{ marginTop: 32 }}>
                        <span>约束条件：</span>
                        <span>实际入炉原料成分</span>
                    </div>
                    <Table
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys: (info?.opt_conf?.matl_comp_constr ?? [])
                                .filter(({ is_use }) => is_use === 1)
                                .map(({ key }) => key),
                            onChange: selectedRowOnChange("matl_comp_constr"),
                        }}
                        columns={getMainIndexColumns("matl_comp_constr")}
                        dataSource={info?.opt_conf?.matl_comp_constr ?? []}
                        pagination={false} size="small"
                    />
                    <div className="title" style={{ marginTop: 32 }}>
                        <span>约束条件：</span>
                        <span>实际入炉原料指标</span>
                    </div>
                    <Table
                        loading={loading}
                        rowSelection={{
                            selectedRowKeys: (info?.opt_conf?.matl_factor_constr ?? [])
                                .filter(({ is_use }) => is_use === 1)
                                .map(({ key }) => key),
                            onChange: selectedRowOnChange("matl_factor_constr"),
                        }}
                        columns={getMainIndexColumns("matl_factor_constr")}
                        dataSource={info?.opt_conf?.matl_factor_constr ?? []}
                        pagination={false} size="small"
                    />
                </div>
            </div>
        </Drawer>

    )
}

export default withRouter(Comp);
