import {
  TIME_RANGE_HOURS,
  calcTimeStamps,
  calcCursorPosion,
  calculateGroupByMinutePosition
} from './timeline-helper'
import debounceTime from './debounceTime'
import exportFile from './exportFile'
import getTemplateContentText from './getTemplateContentText'
import { formatDateTimeToText, defaultTime, getCurrentTime } from './dete-format'

/** 紧急程度从低到高对应的颜色 */
const urgencyAndColor = { 0: '#CFEBFF', 1: '#AFDAD8', 2: '#6BBBDD', 3: '#6B95DD', 4: '#FEBC05' }

export {
  urgencyAndColor,
  debounceTime,
  exportFile,
  TIME_RANGE_HOURS,
  calcTimeStamps,
  calcCursorPosion,
  getTemplateContentText,
  formatDateTimeToText,
  calculateGroupByMinutePosition,
  defaultTime,
  getCurrentTime
}
