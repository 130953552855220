import React from 'react';

const Comp = (props) => {
    const { shared, onChange } = props;
    let tabIndex = null;
    if (typeof shared === "boolean") {
        tabIndex = shared ? 1 : 0;
    };
    const tabOnClick = (index) => {
        if (typeof onChange === "function") {
            onChange(index === 0 ? false : true);
        };
    };
    return (
        <div style={{ height: '100%', paddingLeft: 4, display: 'flex' }}>
            {
                ['我的报表', '共享报表']
                    .map((text, index) => {
                        const textStyle = { fontSize: 16, height: 16, lineHeight: '16px', color: tabIndex === index ? '#44F3DA' : '#9FA0BA' };
                        return (
                            <div key={index} style={{ padding: '0 16px', userSelect: 'none', cursor: 'pointer' }} onClick={() => tabOnClick(index)}>
                                <div style={{ position: 'relative', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={textStyle}>{text}</div>
                                    {
                                        tabIndex === index && (
                                            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, backgroundColor: '#44F3DA' }} />
                                        )
                                    }
                                </div>
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default Comp;
