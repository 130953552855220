import { useEffect, useState } from 'react';
import { post } from 'common/utils';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [statusInfo, setStatusInfo] = useState(null);

    useEffect(() => {
        const { day = null, shift_id = null, furnace_no = null } = conf ?? {};
        if (typeof day !== 'string' || typeof shift_id !== 'number' || typeof furnace_no !== 'number') {
            setResult(null);
            setLoading(false);
            setStatusInfo(null);
            return;
        };
        let delay = true;
        setLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + 'api/proj_puyuan/shift_ingred/get_one', {
            day,
            shift_id,
            furnace_no,
            create_on_empty: true
        })
            .then((retData) => {
                if (delay) {
                    setLoading(false);
                    // console.log('hook', retData);
                    const errcode = retData?.data?.errcode ?? null;
                    if (errcode === 0) {
                        let nextResult = retData.data;
                        let parsed_result = nextResult.result;
                        if (!!parsed_result) {
                            let parsed_content = null;
                            try {
                                parsed_content = JSON.parse(parsed_result.content);
                            } catch (e) { };
                            parsed_result = Object.assign({}, parsed_result, {
                                parsed_content,
                            });
                        };
                        nextResult = Object.assign({}, nextResult, {
                            parsed_result,
                        });
                        // console.log(nextResult);
                        setResult(nextResult);
                    } else if (errcode === 404) {
                        setStatusInfo({ code: 404 });
                        setResult(null);
                    } else {
                        // 处理错误
                        setResult(null);
                        setStatusInfo(null);
                    };
                };
            });
        return () => {
            delay = false;
        };
    }, [conf]);
    return [result, setConf, loading, statusInfo];
};

export default Hook;
