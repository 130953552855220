import RightBlock from './RightBlock.js';
import { connect } from 'react-redux';
import { post } from 'common/utils';
import { getStatus } from './utils.js';

const statusOnchange = (hour, status) => {
    return (dispatch, getState) => {
        const { sampleMaintenance = {} } = getState();
        const { sys_id, date } = sampleMaintenance;
        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/opt/set_participate_status', {
            sys_id,
            day: date.format('YYYY-MM-DD'),
            type: 1,
            hour,
            status,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                // self.updateStatus();
                return true;
            } else {
                return false;
            };
        });
    }
}

const mapStateToProps = state => {
    const { sampleMaintenance = {} } = state;
    const { sys_id, layoutResults, hisTag2Value, contentItemWidth, layoutCusConfig, status, events, date } = sampleMaintenance;
    let staticTrendMetas = [];
    let cusMetas = [];
    const result = layoutResults
        .filter(({ sys_id: sysId }) => sysId === sys_id)[0];
    if (!!result) {
        staticTrendMetas = result.trend_metas;
        const cusConfig = layoutCusConfig
            .filter(({ layout_id }) => layout_id === result._id)[0];
        if (!!cusConfig) {
            cusMetas = cusConfig.cus_trend_metas;
        };
    };
    return {
        staticTrendMetas,
        cusMetas,
        hisTag2Value,
        contentItemWidth,
        status,
        events,
        date,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        statusOnchange: (hour, status) => dispatch(statusOnchange(hour, status)),
        getStatus: () => dispatch(getStatus),
    };
}

const WrapperedRightBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RightBlock);

export default WrapperedRightBlock;