import React from 'react';
import { useState, useEffect } from 'react';
import {
    CloseOutlined
} from '@ant-design/icons';

import QualityItem from './QualityItem.js';
import QualityItemLarge from './QualityItemLarge.js';

import ZHILIANGGAIKUANG_ICON from './res/zhilianggaikuang.png';

import './QualityModal.scss';
const moment = require('moment');

const QualitytItems = [
    {
        name: '普通生料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
];

const LargeDynamicBlock = (props) => {
    const { onClick, item, finalWidth } = props;
    const [width, setWidth] = useState(182);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        // console.log("useEffect", barContentWidthPercent);
        if (!isMounted) {
            setTimeout(() => { setIsMounted(true) }, 20);
        } else {
            if (width < finalWidth) {
                let newWidth = width + 5;
                if (newWidth > finalWidth) {
                    newWidth = finalWidth;
                };
                setTimeout(() => {
                    setWidth(newWidth);
                }, 10);
            };
        };
    });
    return (
        <div className="item_large_position_wrapper" onClick={onClick} style={{ width }}>
            <QualityItemLarge item={item} />
        </div>
    );
};

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unfoldIndexs: [],
            qualitytItems: [],
        };
    }

    componentDidMount() {
        const unfoldIndexs = [];
        const qualitytItems = QualitytItems
            .map((item, index) => {
                const id = `quality_item_${index}`;
                if (Math.random() > 0.95) {
                    // unfoldIndexs.push(id);
                };
                return {
                    ...item,
                    id,
                };
            });
        this.setState({
            qualitytItems,
            unfoldIndexs,
        });
    }

    unfoldItem = index => {
        // console.log(index);
        const { qualitytItems, unfoldIndexs } = this.state;
        this.setState({
            unfoldIndexs: [
                ...new Set(
                    unfoldIndexs
                        .concat([qualitytItems[index].id])
                ),
            ],
        });
    }

    foldItem = index => {
        // console.log(index);
        const { qualitytItems, unfoldIndexs } = this.state;
        this.setState({
            unfoldIndexs: unfoldIndexs
                .filter(id => id + '' !== qualitytItems[index].id),
        });
    }

    onCancel = () => {
        const { onClose = () => { } } = this.props;
        onClose();
    }

    render() {
        const { unfoldIndexs, qualitytItems } = this.state;

        return (
            <div className="main_page_bottom_panels_quality_modal_wrapper">
                <div className="mask" onClick={this.onCancel} />
                <div className="cus_modal_wrapper">
                    <div className="modal_header">
                        <img alt="" src={ZHILIANGGAIKUANG_ICON} className="title_icon" />
                        <div className="title">质量概况</div>
                        <div className="time">【 {moment().format('YYYY年MM月DD日')} 】 </div>
                        {/* <div className="time">【 2020年12月20日 】 </div> */}
                        <CloseOutlined className="cus_close_icon" onClick={this.onCancel} />
                        <div className="mid_tab_wrapper">
                            <div className="tab">产品</div>
                            <div className="tab tab_selected">报警</div>
                        </div>
                    </div>
                    <div className="modal_body scroll_bar_restyle">
                        <div className="modal_body_content_wrapper">
                            {
                                qualitytItems
                                    .map((item, index) => {
                                        const { id } = item;
                                        if (unfoldIndexs.includes(id)) {
                                            return (
                                                <LargeDynamicBlock key={`large_${id}`} onClick={() => this.foldItem(index)} item={item} finalWidth={376} />
                                                // <div className="item_large_position_wrapper" key={`large_${id}`} onClick={() => this.foldItem(index)}>
                                                //     <QualityItemLarge item={item} />
                                                // </div>
                                            );
                                        } else {
                                            return (
                                                <div className="item_position_wrapper" key={`small_${id}`} onClick={() => this.unfoldItem(index)}>
                                                    <QualityItem item={item} />
                                                </div>
                                            );
                                        };
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
