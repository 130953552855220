import React, { useMemo } from 'react';
import style from './index.module.scss';
import PIC from './res/pic.svg';

const Comp = (props) => {
    const { data } = props;
    const valueText = useMemo(() => {
        const value = data?.["Hz"] ?? null;
        if (typeof value === 'number') {
            return Math.floor(value * 100) / 100;
        } else {
            return '--';
        };
    }, [data]);
    return (
        <div className={style['wrapper']}>
            <div className="title">频率</div>
            <div className="value_wrapper">
                <div className="value">{valueText}</div>
                <div className="unit">Hz</div>
            </div>
            <img alt="" src={PIC} className="img" />
        </div>
    );
};

export default Comp;
