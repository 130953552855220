import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import ScaleWrapper from 'components/ScaleWrapper';
import TimeSelector from './TimeSelectorV2';
import ElecAnalysis from './ElecAnalysis';

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeInfo: null,
        };
    }

    componentDidMount() {
    }

    setTimeInfo = (timeInfo) => {
        this.setState({ timeInfo }, this.updateData);
    }

    updateData = () => {
        // console.log("updateData");
    };

    render() {
        const { timeInfo } = this.state;

        return (
            <div className={style["wrapper"]}>
                <ScaleWrapper width={1672} height={968} verticalCentered={false}>
                    <div className={style["content"]}>
                        <div className="top">
                            <div className="title">电能统计 / 电能统计</div>
                            <div className="right">
                                <TimeSelector setTimeInfo={this.setTimeInfo} timeInfo={timeInfo} />
                            </div>
                        </div>
                        <div className="bottom">
                            <ElecAnalysis timeInfo={timeInfo} />
                        </div>
                    </div>
                </ScaleWrapper>
            </div>
        );
    }
}

export default withRouter(Comp);
