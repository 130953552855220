import React from 'react'
import Content from './Content'
import style from './ExamRules.module.scss'
import ExamRuleConfig from '../ExamRuleConfig/ExamRuleConfig'

export default class ExamRules extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      examRuleConfigInfo: null
    }
  }

  gotoExamRuleConfig = id => {
    this.setState({ examRuleConfigInfo: { id } })
  }

  goBackToList = () => {
    this.setState({ examRuleConfigInfo: null })
  }

  render() {
    const { examRuleConfigInfo } = this.state
    const { customStyle } = this.props

    return (
      <div className={style['exam_rules_wrapper']}>
        <div
          className="inner_wrapper"
          style={{ opacity: !!examRuleConfigInfo ? 0 : '', ...customStyle }}
        >
          <Content gotoExamRuleConfig={this.gotoExamRuleConfig} style={{ borderRadius: 0 }} />
        </div>
        {!!examRuleConfigInfo && (
          <div className="secondary_wrapper">
            <ExamRuleConfig
              {...examRuleConfigInfo}
              goBackToList={this.goBackToList}
              customStyle={customStyle}
            />
          </div>
        )}
      </div>
    )
  }
}
