import React, { Component } from 'react'
import { Modal, Input, Button, message, Form, TimePicker, Tabs, Select, Radio } from 'antd'
import { get, post, patch, reqDelete } from 'common/utils.js'
import moment from 'moment'
import Style from './index.module.scss'
import timeStyle from '../SchedulingTimeSetting/index.module.scss'
import DELETE_ICON from '../../assets/delete.png'
import ADD_ICON from '../../assets/add.png'
import FullScreenLoading from '../FullScreenLoading'
import { time } from 'echarts~5'

const { Option } = Select
const { TabPane } = Tabs
const defaultArr = {
  userId: null,
  userName: '',
  day: 0,
  beginTime: '',
  endTime: ''
} // 排班时间设置默认行结构
class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmLoading: false,
      timePrompt: false, // 显示值班人员时间编辑
      showPrompt: false, // 排班编辑
      membersList: null, // 成员
      detailInfo: {}, // 详情
      shiftConfigList: [], // 排班时间列表
      shiftGroupList: [], // 班组列表
      postList: [], // 岗位列表
      userTimeList: [], // 多人排班时间编辑
      selectIndex:0
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  // 提交排班表单
  handleOk = e => {
    // console.log('handleOk',this.state.detailInfo)
    let params =[]
    this.state.detailInfo.data.map((info,infoIndex)=>{
      params.push({hisId :info.hisId,data:[]})
      info.posts.map(postInfo=>{
        postInfo.users.map(userInfo=>{
          params[infoIndex].data.push({
              day:userInfo.day?userInfo.day:1,
              postId:postInfo.postId,
              userId:userInfo.userId,
              beginTime:userInfo.beginTime,
              endTime:userInfo.endTime
          })
        })
      })
    })
    console.log(params);
    this.setState({ confirmLoading: true })
    post(
      process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/cycleEditor',
      params
    ).then(retData => {
      this.setState({ confirmLoading: false })
      if (!!retData.data && retData.data.error === 0) {
        message.success('添加成功')
        this.setState({ showPrompt: false })
        this.props.getResults('success')
      } else {
        message.error(retData.data.msg)
      }
    })
  }

  /** 点击取消/X 关闭排班时间编辑弹窗*/
  handleCancel = () => {
    //点击取消按钮触发的事件
    console.log('Clicked cancel button')
    this.setState({ showPrompt: false })
  }

  // 获取所有成员
  getMembers() {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/userQuery', {}).then(retData => {
      this.setState({
        membersList: retData.data.results
      })
    })
  }

  /** 打开编辑排班弹窗及数据处理 */
  onShow(itemData, shiftConfigList, postList) {
    console.log('onShow');
    this.getMembers()
    console.log(itemData, shiftConfigList, postList)
    const shiftGroupList = itemData.data.map(item => {
      item.posts.forEach(postItem => {
        postItem.type = (postItem.users || []).length > 1 ? 1 : 0
        postItem.openOptions = false
      })
      return item.shiftGroupName
    })
    console.log('itemSata',itemData)
    this.setState({
      showPrompt: true,
      detailInfo: itemData,
      shiftConfigList,
      shiftGroupList,
      postList
    })
  }

  /** 选择单人/多人值班 */
  changeRadio = (targetValue, shiftIndex, postIndex) => {
    const { detailInfo, shiftConfigList } = this.state
    detailInfo.data[shiftIndex].posts[postIndex].type = targetValue
    const { users } = detailInfo.data[shiftIndex].posts[postIndex]
    if (targetValue === 0 && users.length > 1) {
      users.splice(1, users.length)
      const shiftTime = shiftConfigList[shiftIndex]
      const { begin_time: beginTime, end_time: endTime, day } = shiftTime
      users[0] = { ...users[0], beginTime, endTime, day }
    }
    this.setState({ detailInfo })
  }

  /** 点击下拉框时根据单人值班和多人值班
   * 判断是弹出下拉框还是弹出时间设置弹框
   */
  clickSelect = (e, type, shiftIndex, postIndex) => {
    const { detailInfo } = this.state
    if (type) {
      const { users } = detailInfo.data[shiftIndex].posts[postIndex]
      this.state.selectIndex = shiftIndex
      this.setState({ timePrompt: true, userTimeList: users || [] ,selectIndex:this.state.selectIndex})
    } else {
      detailInfo.data[shiftIndex].posts[postIndex].openOptions = e
      this.setState({ detailInfo })
    }
  }

  /** 修改单人排班的值班人员 */
  changeSelect = (e, shiftIndex, postIndex) => {
    const { detailInfo, shiftConfigList } = this.state
    const shiftTime = shiftConfigList[shiftIndex]
    const { begin_time: beginTime, end_time: endTime, day } = shiftTime
    const selectUser = { userId: e.value, userName: e.label, beginTime, endTime, day }
    detailInfo.data[shiftIndex].posts[postIndex].users = [selectUser]
    this.setState({ detailInfo })
  }

  /** 多人值班设置 》开始 */

  /** 添加值班人员 */
  addUser = () => {
    const { userTimeList } = this.state
    userTimeList.push({ ...defaultArr })
    this.setState({ userTimeList })
  }

  /** 移除值班人员 */
  deleteUser(index) {
    const { userTimeList } = this.state
    if (userTimeList.length > 1) {
      userTimeList.splice(index, 1)
      this.setState({ userTimeList })
    } else {
      message.info('排班时间至少保留一条')
    }
  }
  /** 多人值班设置->修改值班人员 */
  changeUserSelect = (e, rowIndex) => {
    this.state.userTimeList[rowIndex].userId = e.value
    this.state.userTimeList[rowIndex].userName = e.label
    this.setState({
      userTimeList:this.state.userTimeList
    })
  }

  /** 处理开始时间变更 */
  handleSelectBeginTime(timeString, index) {
    const { userTimeList } = this.state
    if(timeString.split(" ")[0]==="昨日"){
      userTimeList[index].beginTime = timeString.split(" ")[1]
    }else{
      userTimeList[index].beginTime = timeString
    }
    this.setState({ userTimeList })
  }

  /** 处理结束时间变更 */
  handleSelectEndTime(timeString, index) {
    const { userTimeList } = this.state
    userTimeList[index].endTime = timeString
    this.setState({ userTimeList })
  }

  /** 切换今日和昨日 */
  selectDay(value, index) {
    const { userTimeList } = this.state
    userTimeList[index].day = Number(value)
    this.setState({ userTimeList })
  }

  /** 校验多人排班表单
   * @returns true 校验通过 false 校验不通过
   */
  checkMultipleUserValidate = () => {
    const { userTimeList,shiftConfigList,selectIndex } = this.state
    const validStatus = !(
      !!userTimeList &&
      userTimeList.some((item, index) => {
        // 校验必填
        if (!item.userId || !item.beginTime || !item.endTime) {
          message.error('请完整填写信息')
          return true
        }
        const beginTime =
          item.day === 0
            ? moment(item.beginTime, 'HH:mm').subtract(1, 'day')
            : moment(item.beginTime, 'HH:mm')
        const endTime = moment(item.endTime, 'HH:mm')
        // 校验同一班次起始时间的大小关系
        if (!beginTime.isBefore(endTime)) {
          message.error(`第${index + 1}行的开始时间应小于结束时间`)
          return true
        }

        // 校验时间重叠-排班开始时间不能小于前一个排班的结束时间
        if (index > 0 && beginTime.isBefore(moment(userTimeList[index - 1].endTime, 'HH:mm'))) {
          message.error('各排班之间时间不能重复')
          return true
        }
        const configBeginTime = moment(shiftConfigList[selectIndex].begin_time,'HH:mm')
        const configEndTime = moment(shiftConfigList[selectIndex].end_time,'HH:mm')
        if(!beginTime.isSameOrAfter(configBeginTime)){
          message.error(`第${index + 1}行的开始时间应大于当前班次开始时间（${shiftConfigList[selectIndex].begin_time}）`)
          return true
        }
        if(!endTime.isSameOrBefore(configEndTime)){
          message.error(`第${index + 1}行的结束时间应小于当前班次结束时间（${shiftConfigList[selectIndex].end_time}）`)
          return true
        }
      })
    )
    console.log('validStatus', validStatus)
    return validStatus
  }

  /** 修改值班人员及值班时间段 */
  handleTimeOk = e => {
    if (!this.checkMultipleUserValidate()) return
    this.setState({
      timePrompt:false
    })
  }

  /** 多人排班时间设置 》 结束 */

  render() {
    const {
      showPrompt,
      confirmLoading,
      timePrompt,
      membersList,
      shiftConfigList,
      shiftGroupList,
      postList,
      detailInfo,
      userTimeList
    } = this.state
    return (
      <Modal
        title="排班编辑"
        visible={showPrompt}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className={Style['setting-modal']}
      >
        <Form className="form">
          {/* 排班时间 */}
          <div className="form-top">
            <div className="top-item" style={{ width: 100, maxWidth: 100 }} />
            {shiftConfigList &&
              shiftConfigList.map(item => {
                return (
                  <div key={item.id} className="top-item">
                    <p>{item.name}</p>
                    <p>{`${item.day === 0 ? '昨日' : ''}${item.begin_time}-${item.end_time}`}</p>
                  </div>
                )
              })}
          </div>
          {/* 班组信息 */}
          <div className="class-group">
            <div style={{ width: 100, maxWidth: 100 }} />
            {shiftGroupList &&
              shiftGroupList.map((shiftGroupItem,index) => {
                return (
                  <div key={index}>
                    <span>{shiftGroupItem}</span>
                  </div>
                )
              })}
          </div>
          {/* 值班人员信息 */}
          <div className="class-member">
            <div style={{ width: 100, maxWidth: 100 }}>
              {postList &&
                postList.map(postItem => {
                  return <div key={postItem.id}>{postItem.name}</div>
                })}
            </div>
            {detailInfo.data &&
              detailInfo.data.map((shiftItem, shiftIndex) => {
                return (
                  <div className="info" key={shiftIndex}>
                    {shiftItem.posts &&
                      shiftItem.posts.map((postItem, postIndex) => {
                        const { users } = postItem
                        const userIds = (users || []).map(user => user.userId)
                        const userNames = (users || []).map(user => user.userName)

                        return (
                          <div key={shiftIndex + postIndex} className="info-setting">
                            <Select
                              labelInValue
                              value={{
                                value: users.length > 0 ? users[0].userId : '',
                                label: userNames.join('、')
                              }}
                              allowClear={false}
                              open={postItem.openOptions}
                              onChange={e => this.changeSelect(e, shiftIndex, postIndex)}
                              style={{ width: 168 }}
                              onDropdownVisibleChange={e =>
                                this.clickSelect(e, postItem.type, shiftIndex, postIndex)
                              }
                            >
                              {membersList &&
                                membersList.map(member => {
                                  return (
                                    <Option value={member.id} key={member.id}>
                                      {member.name}
                                    </Option>
                                  )
                                })}
                            </Select>
                            <Radio.Group
                              value={postItem.type}
                              className="radio-group"
                              onChange={e =>
                                this.changeRadio(e.target.value, shiftIndex, postIndex)
                              }
                            >
                              <Radio value={0}>单人值班</Radio>
                              <Radio value={1}>多人值班</Radio>
                            </Radio.Group>
                          </div>
                        )
                      })}
                  </div>
                )
              })}
          </div>
        </Form>
        {confirmLoading && <FullScreenLoading loadingTip="数据提交中，请耐心等待..." />}
        <Modal
          closable={false}
          visible={timePrompt}
          onCancel={() => this.setState({ timePrompt: false })}
          onOk={this.handleTimeOk}
          className={timeStyle['setting-modal']}
        >
          <Form className={timeStyle['form']} style={{ marginTop: '24px' }}>
            {userTimeList &&
              userTimeList.map((userItem, rowIndex) => (
                <div className={timeStyle['setting-info']} key={rowIndex}>
                  <Select
                    className={timeStyle['class-setting']}
                    labelInValue
                    value={{
                      value: userItem.userId,
                      label: userItem.userName
                    }}
                    onChange={e => this.changeUserSelect(e, rowIndex)}
                  >
                    {membersList &&
                      membersList.map(member => (
                        <Option value={member.id} key={member.id}>
                          {member.name}
                        </Option>
                      ))}
                  </Select>
                  <TimePicker
                    showNow={false}
                    placeholder="开始时间"
                    value={userItem.beginTime != '' ? moment(userItem.beginTime, 'HH:mm') : ''}
                    format={userItem.day == 0 ? '昨日 HH:mm' : 'HH:mm'}
                    onChange={(time, timeString) =>
                      this.handleSelectBeginTime(timeString, rowIndex)
                    }
                    renderExtraFooter={() => (
                      <Tabs
                        defaultActiveKey={userItem.day}
                        onChange={key => this.selectDay(key, rowIndex)}
                      >
                        <TabPane tab="昨日" key="0"></TabPane>
                        <TabPane tab="今日" key="1"></TabPane>
                      </Tabs>
                    )}
                  />
                  <span>-</span>
                  <TimePicker
                    showNow={false}
                    placeholder="结束时间"
                    value={userItem.endTime != '' ? moment(userItem.endTime, 'HH:mm') : ''}
                    format={'HH:mm'}
                    onChange={(time, timeString) => this.handleSelectEndTime(timeString, rowIndex)}
                  />
                  <img
                    src={DELETE_ICON}
                    className={timeStyle['class-info-delete']}
                    onClick={e => this.deleteUser(rowIndex)}
                  ></img>
                </div>
              ))}
            <img
              onClick={this.addUser}
              src={ADD_ICON}
              className={timeStyle['class-info-delete']}
              style={{ float: 'right', marginTop: '16px' }}
            ></img>
          </Form>
        </Modal>
      </Modal>
    )
  }
}

export default Edit
