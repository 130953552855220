import React from 'react';

import ProductElectricalTrend from './ProductElectricalTrend.js';
import ProductElectricalAnalysisComp from './ProductElectricalAnalysisComp.js';
import './ProductElectricalAnalysis.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="product_electrical_analysis_wrapper scroll_bar_restyle">
                <div className="content_wrapper">
                    <div className="content_root_title">产品电单耗分析</div>

                    {/* <div style={{ marginTop: 16 }}>
                        <ProductElectricalCountComp />
                    </div> */}

                    <div style={{ marginTop: 16 }}>
                        <ProductElectricalAnalysisComp />
                    </div>

                    <div style={{ marginTop: 16 }}>
                        <ProductElectricalTrend />
                    </div>
                </div>
            </div>
        );
    }
}
