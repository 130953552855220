import React, { useEffect, useState } from 'react'
import moment from 'moment'
import style from './index.module.scss'
// TODO: 引入3.0的报表组件
// import ReportModal from '../../../../CapsuleBoard/Report/ReportModal'
import ReportModal from '../components/Dialogs/ReportModal'

import { get } from 'common/utils'
import { getCurrentTime } from '../../utils'

const ReportPanel = props => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [todoList, setTodoList] = useState([]) // 待填记录
  const [finishedList, setFinishedList] = useState([]) // 已填记录
  const [recordSelect, setRecordSelect] = useState({}) // 选中的

  const groupList = [
    { title: '当班待填记录', className: 'todoList' },
    { title: '已填记录', className: 'doneList' }
  ]

  useEffect(() => {
    getReportRecords()
  }, [])

  /** 获取报表 */
  const getReportRecords = () => {
    get(`${process.env.REACT_APP_SERVER_PATH}api/v1/event/report/reportList`, {
      date: getCurrentTime()
    }).then(res => {
      const list = res.data && res.data.results ? res.data.results : []
      const tempTodoList = list.filter(item => item.cellCount > 0)
      const tempFinishedList = list.filter(item => item.cellCount === 0)
      setTodoList(tempTodoList)
      setFinishedList(tempFinishedList)
    })
  }

  /** 打开弹窗 */
  const openModal = recordItem => {
    setRecordSelect(recordItem)
    setIsModalVisible(true)
  }

  /** 关闭弹窗 */
  const closeModal = isRefresh => {
    setRecordSelect({})
    setIsModalVisible(false)
    isRefresh && getReportRecords()
  }

  return (
    <div className={style['ReportPanel']}>
      <div className="title">报表</div>
      {groupList.map(item => {
        const reportRecords = item.title === '当班待填记录' ? todoList : finishedList
        return (
          <div key={item.title} className={item.className}>
            <p className="group-title">{item.title}</p>
            <ul className="report-records">
              {reportRecords.map((recordItem, index) => {
                return (
                  <li key={index}>
                    <div
                      className={`report-card ${recordSelect.id === recordItem.id ? 'active' : ''}`}
                      onClick={() => openModal(recordItem)}
                    >
                      <div className="report-name">{recordItem.report_name}</div>
                      <div className="cell-count">{recordItem.cellCount}</div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}

      {isModalVisible && (
        <ReportModal
          recordSelect={recordSelect}
          onCancel={closeModal}
          onOk={() => closeModal(true)}
        />
      )}
    </div>
  )
}

export default ReportPanel
