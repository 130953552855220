import Page from '../Header';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { demoSystemV2 } = state;
    return {
        ...demoSystemV2,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
}

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;