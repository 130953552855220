import React, { useEffect, useState } from 'react'
import { Checkbox, Input } from 'antd'
import moment from 'moment'
import style from './index.module.scss'
import { post } from 'common/utils'
import SingleLineChart from './SingleLineChart'
import TimeLineChart from './TimeLineChart'
const tabs = [
  { key: 1, label: '分开观察' },
  { key: 2, label: '合并观察' }
]

const Com = props => {
  const { isDisabled, points, occur_time } = props
  const [activeTabIndex, setActiveTabIndex] = useState(1) // 1: 分开观察 2:合并观察
  const [showFilter, setShowFilter] = useState(false) // 标记筛选是否展开
  const [filterOptions, setFilterOptions] = useState([])  
  
  const [checkedPoints, setCheckedPoints] = useState([]) // 经筛选后进行观察的点位集合
  const [singleLineChartData, setSingleLineChartData] = useState([]) // 分开观察数据
  const [chartData, setChartData] = useState([]) // 合并观察数据
  const [timeData, setTimeData] = useState([]) // 时间点数据

  
  useEffect(() => {
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('click', clickListener)
    }
  }, [])

  useEffect(() => {
    const checkedList = []
    const options = points.map(pointItem => {
      const _item = { label: pointItem.tag_name, value: pointItem.id, tag_name: pointItem.tag_name }
      if (
        checkedPoints.some(checkedItem => checkedItem.value === pointItem.id) ||
        !filterOptions.some(optionItem => optionItem.value === pointItem.id)
      ) {
        checkedList.push(_item)
      }
      return _item
    })
    setFilterOptions(options)
    setCheckedPoints(checkedList)
  }, [points])

  useEffect(() => {
    getPointChartData(checkedPoints)
  }, [checkedPoints])

  /** 更新筛选点位数据源 
   * @param initCheckedPoint 是否初始化筛选选中的点位
  */
  const updateFilterOptions = (initCheckedPoint = false) => {
    const options = points.map(pointItem => {
      return { label: pointItem.tag_name, value: pointItem.id, tag_name: pointItem.tag_name }
    })
    setFilterOptions(options)
    initCheckedPoint && setCheckedPoints(options)
  }

  /** 获取点位折线图 */
  const getPointChartData = arr => {
    const tag_list = arr.map(item => {
      return {
        tag_name: item.tag_name,
        time_begin: moment(occur_time).subtract(5, 'minute').format('YYYY-MM-DD HH:mm:00'),
        time_end: moment(occur_time).add(5, 'minute').format('YYYY-MM-DD HH:mm:00')
      }
    })
    if (tag_list.length === 0) {
      setSingleLineChartData([])
      setChartData([])
      setTimeData([])
      return
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
      tag_list: tag_list
    })
      .then(res => {
        if (res.data.results.length > 0) {
          const data = res.data.results
          const singleLineData = []
          const timeLineData = []
          data.forEach(item => {
            const yData = []
            const xData = []
            item.data_list.forEach(_item => {
              xData.push(_item[1])
              yData.push(_item[0])
            })
            singleLineData.push({
              name: item.tag_name,
              xData: xData,
              yData: yData,
              max: xData.length > 0 ? Math.max.apply(null, xData) : '',
              min: xData.length > 0 ? Math.min.apply(null, xData) : ''
            })
            timeLineData.push({
              name: item.tag_name,
              type: 'line',
              data: xData
            })
          })
          setSingleLineChartData(singleLineData)
          setChartData(timeLineData)
          setTimeData(singleLineData[0].yData ? singleLineData[0].yData : [])
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  /** 变更显示点位选项 */
  const onChangePointFilter = checkedValue => {
    const tempList = filterOptions.filter(optionItem => checkedValue.includes(optionItem.value))
    setCheckedPoints(tempList)
  }

  /** 点击窗口外关闭筛选面板 */
  const clickListener = event => {
    const filterOptions = document.querySelector('.filter-options')
    if (
      filterOptions &&
      !filterOptions.contains(event.target) &&
      event.target.className.indexOf('filter-icon') < 0
    ) {
      setShowFilter(false)
    }
  }

  // 分开观察input值
  const changeChartYaxis = (value, targetIndex, type) => {
    const tempData = singleLineChartData.concat()
    if (!tempData) return
    const num = value.replace(/[^\d]+/g, '')
    if (type === 'max') {
      tempData[targetIndex].max = num ? Number(num) : ''
    } else {
      tempData[targetIndex].min = num ? Number(num) : ''
    }
    return setSingleLineChartData(tempData)
  }

  return (
    <div className={style['pointLineWrapper']}>
      <div className="operation">
        <div className="switch-group">
          {tabs.map(tabItem => {
            return (
              <div
                key={tabItem.key}
                className={activeTabIndex === tabItem.key ? 'active' : ''}
                onClick={() => setActiveTabIndex(tabItem.key)}
              >
                {tabItem.label}
              </div>
            )
          })}
        </div>

        <div className="filter-switch">
          <div className="filter-icon" onClick={() => setShowFilter(!showFilter)}>
            筛选
          </div>
          {showFilter && (
            <Checkbox.Group
              options={filterOptions}
              className="filter-options"
              value={checkedPoints.map(item => item.value)}
              onChange={onChangePointFilter}
            />
          )}
        </div>
      </div>
      <div className="chart-wrapper">
        {activeTabIndex === 1 &&
          singleLineChartData.map((item, chartIndex) => {
            return (
              <div key={chartIndex} className="chart-item">
                <div className="chart-left">
                  <Input
                    value={item.max}
                    style={{ width: 80, height: 24 }}
                    onChange={e => changeChartYaxis(e.target.value, chartIndex, 'max')}
                  />

                  <div className="title">{item.name}</div>
                  <Input
                    value={item.min}
                    style={{ width: 80, height: 24 }}
                    onChange={e => changeChartYaxis(e.target.value, chartIndex, 'min')}
                  />
                </div>
                <SingleLineChart
                  lineData={item.xData}
                  timeData={item.yData}
                  yMin={item.min}
                  yMax={item.max}
                />
              </div>
            )
          })}
        {activeTabIndex === 2 && <TimeLineChart timeLineData={chartData} timeData={timeData} />}
      </div>
    </div>
  )
}
export default Com
