import React from 'react'
import { Input, Divider, DatePicker, Button, Select, Modal, message, Table } from 'antd'
import { get, patch } from '../../../common/utils.js'
import { getMyPermissions } from '../../../common/AuthPermission.js'
import AbnormalModal from './AbnormalModal.js'
import style from './AbnormalPage.module.scss'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { tmp_users_exchange } from '../utils'

const Option = Select.Option
// 1：异常工况
// 2：系统停运
const abnormalType = [
  {
    id: 1,
    name: '异常工况'
  },
  {
    id: 2,
    name: '系统停运'
  }
]
const abnormalStatus = [
  {
    id: 1,
    name: '正常'
  },
  {
    id: -1,
    name: '已删除'
  }
]

export default class AbnormalPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myPermissions: getMyPermissions(),
      filterStatus: 'all',
      filterSysId: 'all',
      filterAbnormalType: 'all',
      filterAbnormalStatus: '1',
      filterKeyword: null,
      createDayBegin: null,
      createDayEnd: null,
      systems: [],
      dataList: [],
      current: 1,
      pageSize: 10,
      total: 0,
      tableLoading: false,

      isDisplayDetails: false,
      currentOrderId: null,

      addAbnormalModal: false,
      selectedAbnormal: null
    }
  }

  componentDidMount() {
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/cmt_system/list').then(retData => {
      console.log(retData)
      if (retData.data && retData.data.errcode === 0) {
        this.setState({ systems: retData.data.results })
      }
    })
    this.getDataList(1)
  }

  getDataList = current => {
    let self = this
    const { filterAbnormalStatus } = this.state
    self.setState({ tableLoading: true })
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/abnormal_periods', {
      page: current,
      page_size: self.state.pageSize,
      // status: (self.state.filterStatus == 'all' ? undefined : self.state.filterStatus),
      type_id: self.state.filterAbnormalType == 'all' ? undefined : self.state.filterAbnormalType,
      sys_id: self.state.filterSysId == 'all' ? undefined : self.state.filterSysId,
      time_begin: self.state.createDayBegin
        ? self.state.createDayBegin.format('YYYY-MM-DD HH:mm:00')
        : undefined,
      time_end: self.state.createDayEnd
        ? self.state.createDayEnd.format('YYYY-MM-DD HH:mm:00')
        : undefined,
      keyword: self.state.filterKeyword == '' ? undefined : self.state.filterKeyword,
      status: filterAbnormalStatus == -1 ? -1 : undefined
    }).then(res => {
      self.setState({ tableLoading: false })
      console.log(res)
      if (res.data) {
        let data = res.data
        self.setState({ dataList: data.results, total: data.count, current: current })
      }
    })
  }

  delEvent = record => {
    const execDel = () => {
      patch(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/abnormal_periods/' + record.id, {
        status: -1
      }).then(retData => {
        if (retData.data) {
          message.success('删除成功')
          this.getDataList(1)
        } else {
          message.error('删除失败')
        }
      })
    }

    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      // content: '确认删除吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => execDel()
    })
  }

  getSysNameById = sysId => {
    let l = this.state.systems.filter(item => item.id == sysId)
    if (l.length > 0) {
      return l[0].name
    } else {
      return ''
    }
  }

  displayDetails = record => {
    this.setState({
      isDisplayDetails: true,
      modalData: record
    })
  }

  render() {
    let self = this
    const { customStyle } = this.props
    const { systems, addAbnormalModal, selectedAbnormal, myPermissions } = this.state

    return (
      <div className={style['AbnormalPageWrapper']} style={customStyle}>
        <div className="AbnormalPage">
          <div className="optLine" style={{ display: 'flex' }}>
            <div className="leftGroup">
              <span>剔除类型</span>
              <Select
                defaultValue="all"
                value={this.state.filterAbnormalType}
                style={{ width: 130, marginLeft: 10 }}
                onChange={v => this.setState({ filterAbnormalType: v })}
              >
                <Option value="all">全部</Option>
                {abnormalType.map(item => (
                  <Option value={item.id + ''} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <span style={{ marginLeft: 20 }}>所属系统</span>
              <Select
                defaultValue="all"
                value={this.state.filterSysId}
                style={{ width: 130, marginLeft: 10 }}
                onChange={v => this.setState({ filterSysId: v })}
              >
                <Option value="all">全部</Option>
                {this.state.systems.map(item => (
                  <Option value={item.id + ''} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <span style={{ marginLeft: 20 }}>时间</span>
              <DatePicker.RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                value={[this.state.createDayBegin, this.state.createDayEnd]}
                onChange={dateRange =>
                  this.setState(
                    {
                      createDayBegin: dateRange == null ? null : dateRange[0],
                      createDayEnd: dateRange == null ? null : dateRange[1],
                      dateRangeOption: null
                    },
                    () => {}
                  )
                }
                style={{ width: 320, marginLeft: 10 }}
              />
              <span style={{ marginLeft: 20 }}>描述</span>
              <Input
                style={{ width: 160, marginLeft: 10 }}
                value={this.state.filterKeyword}
                onChange={e => this.setState({ filterKeyword: e.target.value })}
                // placeholder="请输入关键字"
              />
              <span style={{ marginLeft: 20 }}>状态</span>
              <Select
                value={this.state.filterAbnormalStatus}
                style={{ width: 100, marginLeft: 10 }}
                onChange={v => this.setState({ filterAbnormalStatus: v })}
              >
                {abnormalStatus.map(item => (
                  <Option value={item.id + ''} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={() => self.getDataList(1)}
                style={{
                  marginLeft: 26,
                  backgroundColor: '#357CF7',
                  borderColor: '#357CF7'
                }}
              >
                查询
              </Button>
              {/* <Button type="default" style={{ marginLeft: 16 }} onClick={() => window.location.reload()}>重置</Button> */}
            </div>
            <div className="rightGroup">
              {myPermissions.includes('exclude_period/add_btn') && (
                <Button
                  type="primary"
                  onClick={() => this.setState({ addAbnormalModal: true, selectedAbnormal: null })}
                  style={{
                    marginLeft: 40,
                    backgroundColor: '#357CF7',
                    borderColor: '#357CF7'
                  }}
                >
                  添加
                </Button>
              )}
            </div>
          </div>

          <Table
            rowKey="id"
            id="events-table-wrapper"
            bordered
            style={{ marginTop: 20 }}
            loading={this.state.tableLoading}
            dataSource={this.state.dataList}
            pagination={{
              defaultCurrent: 1,
              current: this.state.current,
              pageSize: this.state.pageSize,
              total: this.state.total,
              showSizeChanger: true,
              showQuickJumper: true
            }}
            onChange={pagination => {
              this.setState({ pageSize: pagination.pageSize }, () =>
                self.getDataList(pagination.current)
              )
            }}
          >
            <Table.Column
              title="剔除类型"
              key="sys_name"
              render={(text, { type_id }) =>
                (abnormalType.filter(({ id }) => id === type_id)[0] || {}).name
              }
            />
            <Table.Column
              title="所属系统"
              key="name"
              render={(text, { sys_id }) =>
                (systems.filter(({ id }) => id === sys_id)[0] || {}).name
              }
            />
            <Table.Column title="起始时间" dataIndex="time_begin" />
            <Table.Column title="结束时间" dataIndex="time_end" />
            <Table.Column
              title="描述"
              width={450}
              className="desp_table_td"
              key="desp"
              render={(text, { desp }) => <div className="desp_text_wrapper">{desp}</div>}
            />
            <Table.Column
              title="负责人"
              dataIndex="user_name"
              render={text => {
                let tmp_name = ''
                if (!!tmp_users_exchange && typeof tmp_users_exchange[text] === 'string') {
                  tmp_name = tmp_users_exchange[text]
                }
                return tmp_name
              }}
            />
            <Table.Column title="编辑时间" dataIndex="create_time" />
            <Table.Column
              title="操作"
              key="operate"
              width={150}
              render={(text, record) => (
                <span>
                  {myPermissions.includes('exclude_period/view_item') && (
                    <a
                      onClick={() =>
                        this.setState({ selectedAbnormal: record, addAbnormalModal: true })
                      }
                      style={{ color: '#357CF7' }}
                    >
                      {' '}
                      查看
                    </a>
                  )}
                  {myPermissions.includes('exclude_period/view_item') &&
                    myPermissions.includes('exclude_period/del_item') && (
                      <Divider type="vertical" />
                    )}
                  {myPermissions.includes('exclude_period/del_item') && (
                    <a onClick={() => this.delEvent(record)} style={{ color: '#357CF7' }}>
                      删除
                    </a>
                  )}
                </span>
              )}
            />
          </Table>
          {addAbnormalModal && (
            <AbnormalModal
              selectedAbnormal={selectedAbnormal}
              systems={systems}
              abnormalType={abnormalType}
              onClose={update =>
                this.setState({ addAbnormalModal: false }, () => update && this.getDataList(1))
              }
            />
          )}
        </div>
      </div>
    )
  }
}
