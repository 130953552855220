import React from 'react';
import { Input, Button, Modal, message, Select, Spin, Radio } from 'antd';
import { post, patch } from '../../../common/utils.js';
import debounce from 'lodash/debounce';

const Option = Select.Option;

let ALARM_LEVELS = [
    {
        level: 1,
        name: '一级告警',
    }, {
        level: 2,
        name: '二级告警',
    }, {
        level: 3,
        name: '三级告警',
    }
]

const STATUS_LIST = [
    {
        name: '启用',
        value: 1,
    },
    {
        name: '停用',
        value: 0,
    },
];

export default class Comp extends React.Component {

    constructor(props) {
        super(props);
        const { data } = props;
        let extraState = {};
        if (!!data) {
            const { id, sys_id, status = 0, level = ALARM_LEVELS[0].level, upper_limit, lower_limit, time_limit, tag_name, lower_msg, upper_msg, remark, meta_data } = data;
            extraState = {
                id, sys_id, status, level,
                upper_limit, lower_limit, time_limit, lower_msg, upper_msg,
                remark,
                meta_data_tag_name: tag_name,
                data_metas: [meta_data],
            };
        };
        this.state = Object.assign({
            id: null,
            sys_id: null,
            status: 0,
            level: ALARM_LEVELS[0].level,
            upper_limit: null,
            lower_limit: null,
            time_limit: 0,
            meta_data_tag_name: null,

            lower_msg: "",
            upper_msg: "",

            remark: "",

            data_metas: [],
            mode: 'edit',//(d.id?'read':'edit'),
            systems: props.systems,
            loading: false,
            visible: true,

            value: [],
            fetching: false,
        }, extraState);

        this.lastFetchId = Symbol();
        this.fetchMeta = debounce(this.fetchMeta, 300);
    }

    componentDidMount() {
        const { data } = this.state;
        if (!data) {
            // 创建模式
            this.fetchMeta();
        };
    }

    fetchMeta = value => {
        let self = this;
        this.lastFetchId = Symbol();
        let fetchId = this.lastFetchId;

        this.setState({ data: [], fetching: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', Object.assign({
            page: 1,
            page_size: 10,
        }, (
            typeof value === "string" ? {
                keyword: value,
            } : {}
        ))).then(retData => {
            // console.log(retData);
            if (fetchId !== self.lastFetchId) {
                return;
            }
            if (!!retData.data && retData.data.errcode === 0) {
                self.setState({ data_metas: retData.data.results });
            }
            this.setState({ fetching: false });
        });
    };

    handleChange = v => {
        console.log(v);
        this.setState({
            meta_data_tag_name: v,
            fetching: false,
        }, () => {
            ['lower', 'upper'].some((type) => {
                this.resetMsg(type);
                return false;
            });
        });
    };

    verifyData = d => {
        const {
            lower_limit, upper_limit, time_limit,
            tag_name, sys_id,
        } = d;
        if (!sys_id) {
            message.error('请选择【系统】');
            return false;
        } else if (!tag_name) {
            message.error('请选择【点位】');
            return false;
        } else if (lower_limit === null && upper_limit === null) {
            message.error('请正确填写【上限】或【下限】');
            return false;
        } else if (time_limit === null) {
            message.error('请正确填写【越限超时】');
            return false;
        };
        return true;
    }

    formatData = (data) => {
        let { lower_limit, upper_limit, time_limit } = data;
        [lower_limit, upper_limit, time_limit] = [lower_limit, upper_limit, time_limit].map((v) => {
            const parsed_v = parseFloat(v);
            if (typeof parsed_v === 'number' && !isNaN(parsed_v)) {
                return parsed_v;
            };
            return null;
        });
        return Object.assign({}, data, { lower_limit, upper_limit, time_limit });
    }

    saveData = _ => {
        let s = this.state;
        let data = Object.assign({
            sys_id: s.sys_id,
            status: s.status,
            level: s.level,
            upper_limit: s.upper_limit,
            lower_limit: s.lower_limit,
            lower_msg: s.lower_msg,
            upper_msg: s.upper_msg,
            time_limit: s.time_limit,
            tag_name: s.meta_data_tag_name,
            remark: s.remark,
        }, (
            !!s.id ? {
                id: s.id,
            } : {}
        ));

        data = this.formatData(data);

        if (!this.verifyData(data)) {
            return;
        };

        let self = this;
        self.setState({ loading: true });
        if (!data.id) {
            post(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_metas', data).then((retData) => {
                if (retData.error === true) {
                    message.error('保存失败。');
                } else if (retData && retData.data && retData.data.id) {
                    message.success('保存成功');
                    self.props.onClose(true);
                    return;
                } else {
                    message.error('保存失败');
                }
                self.setState({ loading: false });
            })
        } else {
            patch(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_metas/' + data.id, data).then((retData) => {
                if (retData.error === true) {
                    message.error('保存失败。');
                } else if (retData && retData.data && retData.data.id) {
                    message.success('保存成功');
                    self.props.onClose(true);
                } else {
                    message.error('保存失败');
                }
                self.setState({ loading: false });
            })
        }
    }

    resetMsg = (type) => {
        if (!['lower', 'upper'].includes(type)) {
            return;
        };
        const { meta_data_tag_name } = this.state;
        if (!meta_data_tag_name) {
            message.error('请先选择点位!');
            return;
        };
        const { data_metas } = this.state;
        let meta_name = data_metas.filter(({ tag_name }) => tag_name === meta_data_tag_name)[0]?.name ?? (meta_data_tag_name ?? "");
        if (type === 'lower') {
            this.setState({ lower_msg: `${meta_name} 超下限告警` });
        } else if (type === 'upper') {
            this.setState({ upper_msg: `${meta_name} 超上限告警` });
        };
    }

    render() {
        let self = this;
        const { meta_data_tag_name, lower_msg, upper_msg } = this.state;
        const rowWrapperStyle = { display: 'flex', alignItems: 'flex-start' };
        const titleStyle = { width: 80, display: 'inline-block' };

        return (
            <Modal
                title="告警策略配置"
                centered
                visible={this.state.visible}
                onOk={() => this.saveData()}
                onCancel={() => this.setState({ visible: false }, () => this.props.onClose(false))}
                width={624}
            >
                <div>
                    <div style={rowWrapperStyle}>
                        <div style={titleStyle}>系统名称</div>
                        <Select style={{ width: 200 }} value={this.state.sys_id} onSelect={value => self.setState({ sys_id: value })}>
                            {
                                this.state.systems.map(item => (
                                    <Option key={'sys-' + item.id} value={item.id}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                    <div style={{ height: 30 }} />
                    <div style={rowWrapperStyle}>
                        <div style={titleStyle}>点位名称</div>
                        <Select
                            showSearch
                            // labelInValue
                            value={meta_data_tag_name}
                            placeholder="输入数据点"
                            notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={this.fetchMeta}
                            onChange={this.handleChange}
                            style={{ width: 390 }}
                        >
                            {this.state.data_metas.map((item, index) => {
                                const { name, tag_name } = item;
                                return (
                                    <Option key={index} value={tag_name}>{name + '　　' + tag_name}</Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div style={{ height: 30 }} />
                    <div style={rowWrapperStyle}>
                        <div style={titleStyle}>越限告警</div>
                        <div style={{ display: 'inline-block', border: '1px solid #434343', borderRadius: 2, padding: 12, width: 490 }}>
                            <div>
                                <span>越限超时</span>
                                <Input
                                    style={{ width: 80, marginLeft: 12 }}
                                    value={this.state.time_limit}
                                    onChange={e => this.setState({ time_limit: e.target.value })}
                                />
                                <span style={{ marginLeft: 12 }}>分钟</span>
                            </div>
                            <div style={{ marginTop: 12 }}>
                                <span>上限</span>
                                <Input
                                    style={{ width: 80, marginLeft: 12 }}
                                    value={this.state.upper_limit}
                                    onChange={e => this.setState({ upper_limit: e.target.value })}
                                />
                                <span style={{ marginLeft: 12 }}>告警名称</span>
                                <Input
                                    style={{ width: 200, marginLeft: 12 }}
                                    value={upper_msg}
                                    onChange={e => this.setState({ upper_msg: e.target.value })}
                                />
                                <Button type="primary" size="small" style={{ marginLeft: 12 }} onClick={() => this.resetMsg('upper')}>重置</Button>
                            </div>
                            <div style={{ marginTop: 12 }}>
                                <span>下限</span>
                                <Input
                                    style={{ width: 80, marginLeft: 12 }}
                                    value={this.state.lower_limit}
                                    onChange={e => this.setState({ lower_limit: e.target.value })}
                                />
                                <span style={{ marginLeft: 12 }}>告警名称</span>
                                <Input
                                    style={{ width: 200, marginLeft: 12 }}
                                    value={lower_msg}
                                    onChange={e => this.setState({ lower_msg: e.target.value })}
                                />
                                <Button type="primary" size="small" style={{ marginLeft: 12 }} onClick={() => this.resetMsg('lower')}>重置</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 30 }} />
                    <div style={rowWrapperStyle}>
                        <div style={titleStyle}>告警级别</div>
                        <Select defaultValue="1" style={{ width: 200 }} value={this.state.level} onChange={v => self.setState({ level: v })}>
                            {
                                ALARM_LEVELS.map((item, index) =>
                                    <Option value={item.level} key={index}>{item.name}</Option>
                                )
                            }
                        </Select>
                    </div>
                    <div style={{ height: 30 }} />
                    <div style={rowWrapperStyle}>
                        <div style={titleStyle}>备注</div>
                        <Input.TextArea value={this.state.remark} onChange={e => this.setState({ remark: e.target.value })} style={{ width: 490 }} />
                    </div>
                    <div style={{ height: 30 }} />
                    <div style={rowWrapperStyle}>
                        <div style={titleStyle}>使用状态</div>
                        <Radio.Group onChange={e => this.state.mode === 'edit' && this.setState({ status: e.target.value })} value={this.state.status}>
                            {
                                STATUS_LIST
                                    .map((item, index) => {
                                        const { name, value } = item;
                                        return (
                                            <Radio key={index} value={value}>{name}</Radio>
                                        );
                                    })
                            }
                        </Radio.Group>
                    </div>
                </div>
            </Modal>
        );
    }
}
