import moment from 'moment';
import * as constants from './constants.js'

const defaultState = {
    currentDate: moment().format('YYYY-MM-DD'),
    isShowDatePicker: false,
    mainData: {}
}
const reducer = (state = defaultState, action) => {
    if (action.type === constants.PREV_DATE) {
        return {
            currentDate: moment(state.currentDate).subtract(1, 'day').format('YYYY-MM-DD')
        }
    }
    if (action.type === constants.NEXT_DATE) {
        return {
            currentDate: moment(state.currentDate).add(1, 'day').format('YYYY-MM-DD')
        }
    }
    if (action.type === constants.CHANGE_DATE) {
        console.log(action)
        return {
            currentDate: action.data
        }
    }
    if (action.type === constants.SHOW_DATEPICKER) {
        return {
            isShowDatePicker: !state.isShowDatePicker
        }
    }
    if (action.type === constants.CHANGE_MAINDATA) {
        return {
            mainData: action.data
        }
    }
    return state
};
export default reducer;
