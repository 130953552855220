import React from 'react';

import AnalysisBlock from './AnalysisBlockContainer.js';
import ShiftCompareBlock from './ShiftCompareBlockContainer.js';

const MultiFactorsDetail = (props) => {
    const { selectedRF } = props;
    return (
        <div>
            <AnalysisBlock selectedRF={selectedRF} />
            <div style={{ height: 12 }} />
            <ShiftCompareBlock selectedRF={selectedRF} />
        </div>
    );
};

export default MultiFactorsDetail;
