import React, {useEffect, useState} from 'react'
import style from './index.module.scss'
import { Button, Drawer, Radio, Table, Space, message, InputNumber } from "antd";
import { get, significantNum, post } from 'common/utils';

const MixedBalance = (props) => {
    const [prodCompTarget, setProdCompTarget] = useState(null) // 优化配料 合金
    const [elemBalance, setElemBalance] = useState(null) // 硅锰合金
    const [recSlagModifyLab, setRecSlagModifyLab] = useState([]) // 再循环原料成分调整 回炉渣 化验值
    const [recSlagModifyManual, setRecSlagModifyManual] = useState([]) // 再循环原料成分调整 回炉渣 调整值
    const [recSlagModifyValue, setRecSlagModifyValue] = useState('lab') // 再循环原料成分调整 回炉渣 radio
    const [recSlagModifyName, setRecSlagModifyName] = useState(null) // 再循环原料成分调整 name

    const [recPelletModifyValue, setRecPelletModifyValue] = useState('lab') // 再循环原料成分调整 球团锰矿 radio
    const [recPelletModifyLab, setRecPelletModifyLab] = useState([]) // 再循环原料成分调整 球团锰矿 化验值
    const [recPelletModifyManual, setRecPelletModifyManual] = useState([]) // 再循环原料成分调整 球团锰矿 调整值
    const [recPelletModifyName, setRecPelletModifyName] = useState(null) // 再循环原料成分调整 name

    const [auxSiModifyValue, setAuxSiModifyValue] = useState('lab') // 辅料成分调整 硅石 radio
    const [auxSiModifyLab, setAuxSiModifyLab] = useState([]) // 辅料成分调整 硅石 化验值
    const [auxSiModifyManual, setAuxSiModifyManual] = useState([]) // 辅料成分调整 硅石 调整值
    const [auxSiModifyName, setAuxSiModifyName] = useState(null) // 辅料成分调整 name

    const [recDolomiteModifyValue, setRecDolomiteModifyValue] = useState('lab') // 辅料成分调整  白云石 radio
    const [recDolomiteModifyLab, setRecDolomiteModifyLab] = useState([]) // 辅料成分调整 白云石 化验值
    const [recDolomiteModifyManual, setRecDolomiteModifyManual] = useState([]) // 辅料成分调整 白云石 调整值
    const [recDolomiteModifyName, setRecDolomiteModifyName] = useState(null) // 辅料成分调整 name

    const [cokeModifyValue, setCokeModifyValue] = useState('lab') // 辅料成分调整 焦炭 radio
    const [cokeModifyLab, setCokeModifyLab] = useState([]) // 辅料成分调整 焦炭 化验值
    const [cokeModifyManual, setCokeModifyManual] = useState([]) // 辅料成分调整 焦炭 调整值
    const [cokeModifyName, setCokeModifyName] = useState(null) // 辅料成分调整 name

    const [cokeExcess, setCokeExcess] = useState([]) // 平衡系数
    const [slagR3, setSlagR3] = useState([]) // 炉渣灰
    const [loading, setLoading] = useState(false) // loading状态

    const [maxWeight, setMaxWeight] = useState(null)
    const [useMaxWeight, setUseMaxWeight] = useState(false)

    const onRecSlagModifyChange = (e) => {
        setRecSlagModifyValue(e.target.value)
    }
    const onRecPelletModifyChange = (e) => {
        setRecPelletModifyValue(e.target.value)
    }
    const onAuxSiModifyChange = (e) => {
        setAuxSiModifyValue(e.target.value)
    }
    const onRecDolomiteModifyChange = (e) => {
        setRecDolomiteModifyValue(e.target.value)
    }
    const onCokeModifyChange = (e) => {
        setCokeModifyValue(e.target.value)
    }
    const alloyColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: () => <Space>
                <span>合金成分(%)</span>
            </Space>
        },
        {
            title: 'Mn',
            dataIndex: 'Mn',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.Mn} style={{ width: 60 }} size="small" onChange={inputOnChange(rowIndex, 'Mn', 'prod_comp_target')} />
            </Space>
        },
        {
            title: 'Fe',
            dataIndex: 'Fe',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.Fe} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Fe', 'prod_comp_target')}/>
            </Space>
        },
        {
            title: 'P',
            dataIndex: 'P',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.P} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'P', 'prod_comp_target')}/>
            </Space>
        },
        {
            title: 'Si',
            dataIndex: 'Si',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.Si} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Si', 'prod_comp_target')}/>
            </Space>
        },
        {
            title: 'C',
            dataIndex: 'C',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.C} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'C', 'prod_comp_target')}/>
            </Space>
        }
    ]
    const siMnColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            align: 'center'
        },
        {
            title: 'Mn',
            dataIndex: 'Mn',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.Mn} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Mn', 'elem_balance')}/>
            </Space>
        },
        {
            title: 'Fe',
            dataIndex: 'Fe',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.Fe} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Fe', 'elem_balance')}/>
            </Space>
        },
        {
            title: 'P',
            dataIndex: 'P',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.P} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'P', 'elem_balance')}/>
            </Space>
        },
        {
            title: 'SiO2',
            dataIndex: 'SiO2',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.SiO2} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'SiO2', 'elem_balance')}/>
            </Space>
        },
        {
            title: 'CaO',
            dataIndex: 'CaO',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.CaO} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'CaO', 'elem_balance')}/>
            </Space>
        },
        {
            title: 'MgO',
            dataIndex: 'MgO',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.MgO} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'MgO', 'elem_balance')}/>
            </Space>
        },
        {
            title: 'Al2O3',
            dataIndex: 'Al2O3',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.Al2O3} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Al2O3', 'elem_balance')}/>
            </Space>
        }
    ]
    const recyclingColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            align: 'center',
            render: (text, record) => <Space>
                <span>成分 (%)</span>
            </Space>
        },
        {
            title: 'H2O',
            dataIndex: 'H2O',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.H2O}</span>:
                        <InputNumber value={record.H2O} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'H2O', 'rec_slag_modify')}/>
                }
            </Space>
        },
        {
            title: 'Mn',
            dataIndex: 'Mn',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.Mn}</span>:
                        <InputNumber value={record.Mn} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Mn', 'rec_slag_modify')}/>
                }
            </Space>
        },
        {
            title: 'Fe',
            dataIndex: 'Fe',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.Fe}</span>:
                        <InputNumber value={record.Fe} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Fe', 'rec_slag_modify')}/>
                }
            </Space>
        },
        {
            title: 'SiO2',
            dataIndex: 'SiO2',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.SiO2}</span>:
                        <InputNumber value={record.SiO2} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'SiO2', 'rec_slag_modify')}/>
                }
            </Space>
        },
        {
            title: 'CaO',
            dataIndex: 'CaO',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.CaO}</span>:
                        <InputNumber value={record.CaO} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'CaO', 'rec_slag_modify')}/>
                }
            </Space>
        },
        {
            title: 'MgO',
            dataIndex: 'MgO',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.MgO}</span>:
                        <InputNumber value={record.MgO} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'MgO', 'rec_slag_modify')}/>
                }
            </Space>
        },
        {
            title: 'Al2O3',
            dataIndex: 'Al2O3',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recSlagModifyValue === 'lab'? <span>{record.Al2O3}</span>:
                        <InputNumber value={record.Al2O3} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Al2O3', 'rec_slag_modify')}/>
                }
            </Space>
        }
    ]
    const recPollectColumns = [
        {
            title: '名称',
            dataIndex: 'name',
            align: 'center',
            render: (text, record) => <Space>
                <span>成分 (%)</span>
            </Space>
        },
        {
            title: 'H2O',
            dataIndex: 'H2O',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.H2O}</span>:
                        <InputNumber value={record.H2O} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'H2O', 'rec_pellet_modify')}/>
                }
            </Space>
        },
        {
            title: 'Mn',
            dataIndex: 'Mn',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.Mn}</span>:
                        <InputNumber value={record.Mn} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Mn', 'rec_pellet_modify')}/>
                }
            </Space>
        },
        {
            title: 'Fe',
            dataIndex: 'Fe',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.Fe}</span>:
                        <InputNumber value={record.Fe} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Fe', 'rec_pellet_modify')}/>
                }
            </Space>
        },
        {
            title: 'SiO2',
            dataIndex: 'SiO2',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.SiO2}</span>:
                        <InputNumber value={record.SiO2} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'SiO2', 'rec_pellet_modify')}/>
                }
            </Space>
        },
        {
            title: 'CaO',
            dataIndex: 'CaO',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.CaO}</span>:
                        <InputNumber value={record.CaO} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'CaO', 'rec_pellet_modify')}/>
                }
            </Space>
        },
        {
            title: 'MgO',
            dataIndex: 'MgO',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.MgO}</span>:
                        <InputNumber value={record.MgO} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'MgO', 'rec_pellet_modify')}/>
                }
            </Space>
        },
        {
            title: 'Al2O3',
            dataIndex: 'Al2O3',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    recPelletModifyValue === 'lab'? <span>{record.Al2O3}</span>:
                        <InputNumber value={record.Al2O3} style={{ width: 60 }} size="small" onChange={inputOnChange(rowIndex, 'Al2O3', 'rec_pellet_modify')} />
                }
            </Space>
        }
    ]
    const silicaColumns = [
        {
            title: '名称',
            dataIndex: 'matl_name',
            align: 'center',
            render: (text, record) => <Space>
                <span>{text}</span>
            </Space>
        },
        {
            title: '水分',
            dataIndex: 'H2O',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    auxSiModifyValue === 'lab'? <span>{record.H2O}</span>:
                        <InputNumber value={record.H2O} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'H2O', 'aux_si_modify')}/>
                }
            </Space>
        },
        {
            title: 'SiO2',
            dataIndex: 'SiO2',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    auxSiModifyValue === 'lab'? <span>{record.SiO2}</span>:
                        <InputNumber value={record.SiO2} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'SiO2', 'aux_si_modify')}/>
                }
            </Space>
        }
    ]

    const dolomiteColumns = [
        {
            title: '名称',
            dataIndex: 'matl_name',
            align: 'center'
        },
        {
            title: '水分',
            dataIndex: 'H2O',
            align: 'center',
            width: 120,
            render: (text,record,rowIndex) => <Space>
                {
                    recDolomiteModifyValue === 'lab' ? <span>{record.H2O}</span> :
                        <InputNumber value={record.H2O} style={{width: 80}} size="small" onChange={inputOnChange(rowIndex, 'H2O', 'rec_dolomite_modify')}/>
                }

            </Space>
        },
        {
            title: 'CaO+MgO',
            dataIndex: 'CaMgO',
            align: 'center',
            width: 120,
            render: (text,record,rowIndex) => <Space>
                {
                    recDolomiteModifyValue === 'lab' ? <span>{record.CaMgO}</span> :
                        <InputNumber value={record.CaMgO} style={{width: 80}} size="small" onChange={inputOnChange(rowIndex, 'CaMgO', 'rec_dolomite_modify')}/>
                }

            </Space>
        }
    ]
    const cokeColumns = [
        {
            title: '名称',
            dataIndex: 'matl_name',
            align: 'center'
        },
        {
            title: '水分',
            dataIndex: 'H2O',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    cokeModifyValue === 'lab' ? <span>{record.H2O}</span> :
                        <InputNumber value={record.H2O} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'H2O', 'coke_modify')}/>
                }
            </Space>
        },
        {
            title: '固定碳',
            dataIndex: 'C',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    cokeModifyValue === 'lab' ? <span>{record.C}</span> :
                        <InputNumber value={record.C} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'C', 'coke_modify')}/>
                }
            </Space>
        },
        {
            title: '灰分',
            dataIndex: 'Ad',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                {
                    cokeModifyValue === 'lab' ? <span>{record.Ad}</span> :
                        <InputNumber value={record.Ad} style={{width: 60}} size="small" onChange={inputOnChange(rowIndex, 'Ad', 'coke_modify')}/>
                }
            </Space>
        }
    ]

    const balanceColumns = [
        {
            title: '名称',
            dataIndex: 'matl_name',
            align: 'center',
        },
        {
            title: '灰分含SiO2',
            dataIndex: 'SiO2',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.comp.SiO2} style={{width: 100}} size="small" onChange={inputOnChange(rowIndex, 'SiO2', 'coke_excess')}/>
            </Space>
        },
        {
            title: '过量系数',
            dataIndex: 'excess_coeff',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.excess_coeff} style={{width: 100}} size="small" onChange={inputOnChange(rowIndex, 'excess_coeff', 'coke_excess')}/>
            </Space>
        }
    ]
    const slagColumns = [
        {
            title: '名称',
            dataIndex: 'matl_name',
            align: 'center',
            width: 100
        },
        {
            title: '碱度R3',
            dataIndex: 'R3',
            align: 'center',
            render: (text,record,rowIndex) => <Space>
                <InputNumber value={record.R3} size="small" style={{width: 120}} onChange={inputOnChange(rowIndex, 'R3', 'slag_R3')}/>
            </Space>
        },
    ]
    const { isShow, closeDrawer,ingredCalcId} = props
    const updateBalance = () => {

        const conf = {
            balance_conf: {
                elem_balance: elemBalance,
                aux_si_modify: {
                    lab: auxSiModifyLab[0],
                    manual: auxSiModifyManual[0],
                    manual_lab: auxSiModifyValue,
                    matl_name: auxSiModifyName,
                },
                coke_excess: cokeExcess[0],
                coke_modify: {
                    lab: cokeModifyLab[0],
                    manual: cokeModifyManual[0],
                    manual_lab: cokeModifyValue,
                    matl_name: cokeModifyName,
                },
                prod_comp_target: prodCompTarget[0],
                rec_dolomite_modify: {
                    lab: recDolomiteModifyLab[0],
                    manual: recDolomiteModifyManual[0],
                    manual_lab: recDolomiteModifyValue,
                    matl_name: recDolomiteModifyName,
                },
                rec_pellet_modify: {
                    lab: recPelletModifyLab[0],
                    manual: recPelletModifyManual[0],
                    manual_lab: recPelletModifyValue,
                    matl_name: recPelletModifyName,
                },
                rec_slag_modify: {
                    lab: recSlagModifyLab[0],
                    manual: recSlagModifyManual[0],
                    manual_lab: recSlagModifyValue,
                    matl_name: recSlagModifyName,
                },
                slag_R3: slagR3[0],
                max_weight: parseFloat(maxWeight),
                use_max_weight: (useMaxWeight ? true:false)
            },
        };

        // console.log(conf);
        // return;

        setLoading(true)
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/patch_one/${ingredCalcId}`, conf)
            .then(res => {
                // console.log(res);
                setLoading(false);
                if (res?.data?.errcode === 0) {
                    // 保存成功，并关闭弹窗
                    message.success("保存成功");
                    if (typeof closeDrawer === 'function') {
                        closeDrawer();
                    };
                } else {
                    // 保存失败
                    message.warning("保存失败");
                };
            });
    }
    const inputOnChange = (rowIndex,columnKey,key) => {
        return (v) => {
            let newVal = v
            if(key === 'prod_comp_target') {
                const newArr = prodCompTarget.concat()
                newArr[0][columnKey] = newVal
                setProdCompTarget(newArr)
            } else if (key === 'elem_balance') {
                const newArr = elemBalance.concat()
                newArr.map((item,index) => {
                    if (rowIndex === index) {
                        item[columnKey] = newVal
                    }
                })
                setElemBalance(newArr)
            } else if (key === 'rec_slag_modify') {
                const newArr = recSlagModifyManual.concat()
                newArr[0][columnKey] = newVal
                setRecSlagModifyManual(newArr)
            } else if (key === 'rec_pellet_modify') {
                const newArr = recPelletModifyManual.concat()
                newArr[0][columnKey] = newVal
                setRecPelletModifyManual(newArr)
            } else if (key === 'aux_si_modify') {
                const newArr = auxSiModifyManual.concat()
                newArr[0][columnKey] = newVal
                setAuxSiModifyManual(newArr)
            } else if (key === 'rec_dolomite_modify') {
                const newArr = recDolomiteModifyManual.concat()
                newArr[0][columnKey] = newVal
                setRecDolomiteModifyManual(newArr)
            } else if (key === 'coke_modify') {
                const newArr = cokeModifyManual.concat()
                newArr[0][columnKey] = newVal
                setCokeModifyManual(newArr)
            } else if (key === 'coke_excess') {
                const newArr = cokeExcess.concat()
                newArr[0][columnKey] = newVal
                setCokeExcess(newArr)
            } else if (key === 'slag_R3') {
                const newArr = slagR3.concat()
                newArr[0][columnKey] = newVal
                setSlagR3(newArr)
            }
        }

    };
    useEffect(() => {
        if (isShow === true) {
            setLoading(true)
            get(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/get_balance_conf/${ingredCalcId}`).then(res => {
                setLoading(false)
                // console.log(res);
                if (res?.data?.errcode === 0) {
                    let balance_conf_json = res.data.result ? res.data.result : null
                    const prod_comp_target = []
                    prod_comp_target.push(balance_conf_json.prod_comp_target)
                    setProdCompTarget(prod_comp_target)
                    const elem_balance = balance_conf_json.elem_balance
                    setElemBalance(elem_balance)
                    const rec_slag_modify_lab = []
                    rec_slag_modify_lab.push(balance_conf_json.rec_slag_modify.lab)
                    const rec_slag_modify_manual = []
                    rec_slag_modify_manual.push(balance_conf_json.rec_slag_modify.manual)
                    let rec_slag_modify_val = balance_conf_json.rec_slag_modify.manual_lab
                    setRecSlagModifyValue(rec_slag_modify_val)
                    setRecSlagModifyName(balance_conf_json.rec_slag_modify.matl_name);
                    setRecSlagModifyLab(rec_slag_modify_lab)
                    setRecSlagModifyManual(rec_slag_modify_manual)

                    const rec_pellet_modify_lab = []
                    const rec_pellet_modify_manual = []
                    let rec_pellet_modify_val = balance_conf_json.rec_pellet_modify.manual_lab
                    setRecPelletModifyValue(rec_pellet_modify_val)
                    setRecPelletModifyName(balance_conf_json.rec_pellet_modify.matl_name);
                    rec_pellet_modify_lab.push(balance_conf_json.rec_pellet_modify.lab)
                    rec_pellet_modify_manual.push(balance_conf_json.rec_pellet_modify.manual)
                    setRecPelletModifyLab(rec_pellet_modify_lab)
                    setRecPelletModifyManual(rec_pellet_modify_manual)

                    const aux_si_modify_lab = []
                    const aux_si_modify_manual = []
                    let aux_si_modify_val = balance_conf_json.aux_si_modify.manual_lab
                    setAuxSiModifyValue(aux_si_modify_val)
                    setAuxSiModifyName(balance_conf_json.aux_si_modify.matl_name);
                    aux_si_modify_lab.push(balance_conf_json.aux_si_modify.lab)
                    aux_si_modify_manual.push(balance_conf_json.aux_si_modify.manual)
                    setAuxSiModifyLab(aux_si_modify_lab)
                    setAuxSiModifyManual(aux_si_modify_manual)

                    const rec_dolomite_modify_lab = []
                    const rec_dolomite_modify_manual = []
                    let rec_dolomite_modify_val = balance_conf_json.rec_dolomite_modify.manual_lab
                    setRecDolomiteModifyValue(rec_dolomite_modify_val)
                    setRecDolomiteModifyName(balance_conf_json.rec_dolomite_modify.matl_name);
                    rec_dolomite_modify_lab.push(balance_conf_json.rec_dolomite_modify.lab)
                    rec_dolomite_modify_manual.push(balance_conf_json.rec_dolomite_modify.manual)
                    setRecDolomiteModifyLab(rec_dolomite_modify_lab)
                    setRecDolomiteModifyManual(rec_dolomite_modify_manual)

                    const coke_modify_lab = []
                    const coke_modify_manual = []
                    let coke_modify_val = balance_conf_json.coke_modify.manual_lab
                    setCokeModifyValue(coke_modify_val)
                    setCokeModifyName(balance_conf_json.coke_modify.matl_name);
                    coke_modify_lab.push(balance_conf_json.coke_modify.lab)
                    coke_modify_manual.push(balance_conf_json.coke_modify.manual)
                    setCokeModifyLab(coke_modify_lab)
                    setCokeModifyManual(coke_modify_manual)

                    const coke_excess = []
                    const slag_R3 = []
                    coke_excess.push(balance_conf_json.coke_excess)
                    setCokeExcess(coke_excess)
                    slag_R3.push(balance_conf_json.slag_R3)
                    setSlagR3(slag_R3)

                    setMaxWeight(balance_conf_json.max_weight)
                    setUseMaxWeight(balance_conf_json.use_max_weight?true:false)
                };
            })
        } else {
            setLoading(false)
            return
        }
    }, [isShow,ingredCalcId]);
    return (
        <Drawer title="物料平衡" placement="right" closable={true} onClose={closeDrawer} visible={isShow} width={730} key="2"
            footer={(
                <div style={{ float: 'right' }}>
                    <Button onClick={props.closeDrawer}>取消</Button>
                    <Button type="primary" style={{ marginLeft: 12 }} onClick={updateBalance}>保存</Button>
                </div>
            )}
        >
            <div className={style['material-balance-wrapper']}>
                <div className='wrapper'>
                    <div className="wrapper-title">
                        <div className="wrapper-title-left">合金目标成分</div>
                    </div>
                    <div className="wrapper-table">
                        <Table dataSource={prodCompTarget} columns={alloyColumns} pagination={false} size="small" loading={loading}/>
                    </div>
                    {/********/}
                    <div className="wrapper-title">
                        <div className="wrapper-title-left">硅锰合金冶炼原料元素分配</div>
                    </div>
                    <div className="wrapper-table">
                        <Table dataSource={elemBalance} columns={siMnColumns} pagination={false} size="small" loading={loading}/>
                    </div>
                    {/********/}
                    <div className="wrapper-title" style={{marginTop: '20px', fontWeight: 'bold'}}>再循环原料成分调整</div>
                    <div className="wrapper-title">
                        <div className="wrapper-title-left">
                            <span style={{marginRight: 18}}>回炉渣</span>
                            <Radio.Group onChange={onRecSlagModifyChange} value={recSlagModifyValue}>
                                <Radio value="manual">调整值</Radio>
                                <Radio value="lab">化验值</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="wrapper-table">
                        {
                            recSlagModifyValue === 'lab' ? <Table dataSource={recSlagModifyLab} columns={recyclingColumns} pagination={false} size="small" loading={loading}/> :
                                <Table dataSource={recSlagModifyManual} columns={recyclingColumns} pagination={false} size="small" loading={loading}/>
                        }
                    </div>
                    {/********/}
                    <div className="wrapper-title">
                        <div className="wrapper-title-left">
                            <span style={{marginRight: 18}}>球团锰矿</span>
                            <Radio.Group onChange={onRecPelletModifyChange} value={recPelletModifyValue}>
                                <Radio value="manual">调整值</Radio>
                                <Radio value="lab">化验值</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="wrapper-table">
                        {
                            recPelletModifyValue === 'lab' ? <Table dataSource={recPelletModifyLab} columns={recPollectColumns} pagination={false} size="small" loading={loading}/> :
                                <Table dataSource={recPelletModifyManual} columns={recPollectColumns} pagination={false} size="small" loading={loading}/>
                        }
                    </div>
                    {/********/}
                    <div className="wrapper-title" style={{marginTop: '20px', fontWeight: 'bold'}}>辅料成分调整</div>
                    <div className="wrapper-smallTable">
                        <div className="wrapper-smallTable-table">
                            <div className="title">
                                <div>
                                    <span style={{marginRight: 18}}>硅石综合</span>
                                    <Radio.Group onChange={onAuxSiModifyChange} value={auxSiModifyValue}>
                                        <Radio value="manual">调整值</Radio>
                                        <Radio value="lab">化验值</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {
                                auxSiModifyValue === 'lab' ? <Table dataSource={auxSiModifyLab} columns={silicaColumns} pagination={false} size="small" loading={loading}/>:
                                    <Table dataSource={auxSiModifyManual} columns={silicaColumns} pagination={false} size="small" loading={loading}/>
                            }

                        </div>
                        {/********/}
                        <div className="wrapper-smallTable-table">
                            <div className="title">
                                <div>
                                    <span style={{marginRight: 18}}>白云石</span>
                                    <Radio.Group onChange={onRecDolomiteModifyChange} value={recDolomiteModifyValue}>
                                        <Radio value="manual">调整值</Radio>
                                        <Radio value="lab">化验值</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {
                                recDolomiteModifyValue === 'lab' ? <Table dataSource={recDolomiteModifyLab} columns={dolomiteColumns} pagination={false} size="small" loading={loading}/>:
                                    <Table dataSource={recDolomiteModifyManual} columns={dolomiteColumns} pagination={false} size="small" loading={loading}/>
                            }
                        </div>
                    </div>
                    {/********/}
                    <div className="wrapper-title" style={{marginTop: '20px', fontWeight: 'bold'}}>焦炭成分调整</div>
                    <div className="wrapper-smallTable">
                        <div className="wrapper-smallTable-table">
                            <div className="title">
                                <div>
                                    <span style={{marginRight: 18}}>焦炭综合</span>
                                    <Radio.Group onChange={onCokeModifyChange} value={cokeModifyValue}>
                                        <Radio value="manual">调整值</Radio>
                                        <Radio value="lab">化验值</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {
                                cokeModifyValue === 'lab' ? <Table dataSource={cokeModifyLab} columns={cokeColumns} pagination={false} size="small" loading={loading}/>:
                                    <Table dataSource={cokeModifyManual} columns={cokeColumns} pagination={false} size="small" loading={loading}/>
                            }

                        </div>
                        {/********/}
                        <div className="wrapper-smallTable-table">
                            <div className="title">
                                <div>
                                    <span style={{marginRight: 18}}>平衡系数</span>
                                </div>
                            </div>

                            <Table dataSource={cokeExcess} columns={balanceColumns} pagination={false} size="small" loading={loading}/>
                        </div>

                    </div>
                    {/********/}
                    <div className="wrapper-smallTable" style={{marginTop: '20px'}}>
                        <div className="wrapper-smallTable-table">
                            <div className="title">
                                <div>
                                    <span style={{fontWeight: 'bold'}}>炉渣成分指标</span>
                                </div>
                            </div>
                            <Table dataSource={slagR3} columns={slagColumns} pagination={false} size="small" loading={loading}/>
                        </div>
                        <div className="wrapper-smallTable-table">
                            <div className="title">
                                <div>
                                    <span style={{fontWeight: 'bold'}}>其他参数</span>
                                </div>
                            </div>
                            <div className="form-input" style={{marginTop: '20px'}}>
                                <div>是&nbsp;&nbsp;否&nbsp;&nbsp;修&nbsp;&nbsp;正&nbsp;：</div>
                                <Radio.Group onChange={e=>setUseMaxWeight(e.target.value)} value={useMaxWeight}>
                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>否</Radio>
                                </Radio.Group>
                            </div>
                            <div className="form-input" style={{marginTop: '14px'}}>
                                <div>最大投料限制(锰矿湿吨)：</div>
                                <InputNumber value={maxWeight} onChange={setMaxWeight} style={{width: 140}} disabled={!useMaxWeight} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default MixedBalance
