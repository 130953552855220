import style from './index.module.scss';
import { useData } from './hooks';
import { message, Button, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormRender, { useForm } from 'form-render';
import { post, patch } from 'common/utils';
import CombinedLabel from '../widgets/CombinedLabel/index.js';
import SearchSelect from '../widgets/SearchSelect/index.js';
import RecordsTable from '../widgets/RecordsTable';
import Upload from '../widgets/Upload';
import { withRouter } from 'react-router';

const default_func = () => { };
const needVerify = ['jinglei', 'tengfei', 'wlcb'].includes(process.env.REACT_APP_PROJECT_TAG);

const Comp = (props) => {
    const { code, id, catId, readonly } = props;
    const form = useForm();

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(default_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : default_func;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current({ code, id });
    }, [code, id])

    useEffect(() => {
        const parsed_content = data?.parsed_result?.parsed_content ?? null;
        // console.log(parsed_content);
        if (!!parsed_content) {
            form.setValues(parsed_content);
        } else {
            form.resetFields();
        };
    }, [data]);

    const tplJson = useMemo(() => {
        const tpl_json = data?.parsed_result?.parsed_tpl_json ?? {};
        return Object.assign({}, tpl_json);
    }, [data]);

    const [saveLoading, setSaveLoading] = useState(false);
    const submit = () => {
        // console.log(form.formData);
        const saveFunc = () => {
            setSaveLoading(true);
            if (typeof id === 'string') {
                // 修改
                patch(process.env.REACT_APP_SERVER_PATH + `api/form/forms/${id}`, {
                    content: JSON.stringify(form.formData),
                }).then((retData) => {
                    setSaveLoading(false);
                    window._hmt && window._hmt.push(['_trackEvent', 'Form', 'save', code]);
                    console.log(retData);
                    if (retData?.data?.status === 1) {
                        message.success('保存成功');
                        // 这里可以不做处理
                        if (typeof props.saveDone === 'function') {
                            props.saveDone();
                        };
                    } else if (retData?.data?.msg) {
                        message.error(retData?.data?.msg);
                    } else {
                        // 处理错误
                        message.error('保存失败');
                    };
                });
            } else {
                // 新建
                post(process.env.REACT_APP_SERVER_PATH + 'api/form/forms', {
                    tpl_code: code,
                    content: JSON.stringify(form.formData),
                    cat_id: props.catId,
                }).then((retData) => {
                    setSaveLoading(false);
                    window._hmt && window._hmt.push(['_trackEvent', 'Form', 'save', code]);
                    // console.log(retData, props);
                    if (retData?.data?.status === 1) {
                        const id = retData.data.id;
                        message.success('保存成功');
                        // 这里需要通知上级，更新id
                        if (typeof props.createDone === 'function') {
                            props.createDone(id);
                        };
                    } else if (retData?.data?.msg) {
                        message.error(retData?.data?.msg);
                    } else {
                        // 处理错误
                        message.error('保存失败');
                    };
                });
            };
        };
        // verify
        if (needVerify) {
            post(process.env.REACT_APP_SERVER_PATH + "api/validation", Object.assign(
                {
                    tpl_code: code,
                    content: JSON.stringify(form.formData),
                    cat_id: catId,
                },
                typeof id === 'string' ? {
                    id: parseInt(id),
                } : {},
            ))
                .then(retData => {
                    console.log(retData);
                    if (retData?.data?.status === 1) {
                        saveFunc();
                    } else {
                        if (typeof retData?.data?.msg === 'string') {
                            message.error(retData?.data?.msg);
                        } else {
                            message.error('校验失败');
                        };
                    };
                });
        } else {
            saveFunc();
        };
    };

    const cancel = () => {
        if (typeof props.onCancel === 'function') {
            props.onCancel();
        };
    };

    const watch = useMemo(() => {
        const tpl_json = data?.parsed_result?.parsed_tpl_json ?? {};
        // console.log("tpl_json", tpl_json);
        // console.log("code", code);
        let _watch = {};
        if (code == "HUAYAN_MENGKUANG_RUCHANGPINGJUN") {
            _watch['sample_name'] = (val) => {
                form.setValueByPath('avg_table', val);
            }
            _watch['date'] = (val) => {
                form.setValueByPath('avg_table', val);
            }
            _watch['avg_table'] = (val) => {
                try {
                    console.log("val", val);
                    let val_json = JSON.parse(val);
                    let { Mn, Fe, H2O } = val_json;
                    form.setValueByPath('Mn', Mn);
                    form.setValueByPath('Fe', Fe);
                    form.setValueByPath('H2O', H2O);
                } catch (e) { }
            }
        }
        const properties = tpl_json?.properties ?? {};
        const watch_combile_label = Object.keys(properties)
            .reduce((pV, cKey) => {
                if ((properties?.[cKey]?.widget ?? null) === "combined_label") {
                    const combine_elements = properties?.[cKey]?.props?.combine_elements ?? [];
                    const addonWatchs = combine_elements
                        .reduce((pO, cItem) => {
                            if (cItem?.type === "key") {
                                if (typeof (cItem?.value ?? null) === 'string') {
                                    pO = Object.assign({}, pO, {
                                        [cItem.value]: val => {
                                            form.setValueByPath(cKey, val);
                                        },
                                    });
                                };
                            };
                            return pO;
                        }, {});
                    pV = Object.assign({}, pV, addonWatchs);
                };
                return pV;
            }, {});

        _watch = Object.assign({}, _watch, watch_combile_label);

        return Object.assign({}, _watch);
    }, [data]);

    // const watch = {
    //     // # 为全局
    //     '#': (val, x) => {
    //         console.log('表单的时时数据为：', val, x);
    //         // form.setValueByPath('sample_name', val);
    //     },
    //     sample_name: val => {
    //         form.setValueByPath('avg_table', val);
    //     },
    //     avg_table: val => {
    //         // let data = JSON.parse(val);
    //         // let {water_pct, mn_pct, fe_pct} = data;
    //         // form.setValues({
    //         //     'water_pct': water_pct,
    //         //     'mn_pct': mn_pct,
    //         //     'fe_pct': fe_pct,
    //         // });
    //     }
    // };

    const onFinish = useCallback((data, errors) => {
        if (!errors || errors.length === 0) {
            // console.log('onFinish', data, errors)
            submit()
        } else {
            message.error('保存失败，请按照提示正确填写信息!');
            console.log('onFinish errors', data, errors)
        }
    }, [submit])

    return (
        <div className={style["wrapper"]}>
            {/* <div
                style={{
                    height: 44,
                    backgroundColor: 'rgba(53, 91, 153, 1)',
                    color: 'rgba(255, 255, 255, 1)',
                    fontSize: 19,
                    lineHeight: '19px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >{data?.parsed_result?.name}</div> */}
            <div style={{
                padding: "8px 16px",
                height: 0,
                flexGrow: 1,
                overflowY: 'auto',
                position: 'relative',
            }}>
                <FormRender
                    form={form}
                    schema={tplJson}
                    watch={watch}
                    widgets={{
                        combined_label: CombinedLabel,
                        search_select: SearchSelect,
                        records_table: RecordsTable,
                        file_uploader: Upload,
                    }}
                    onFinish={onFinish}
                />
                {/* <Button type="primary" onClick={form.submit}>
                    提交
      </Button> */}
                {
                    saveLoading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
            {
                (props?.readOnly ?? null) !== true && (
                    <div
                        style={{
                            height: 49,
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            fontSize: 14,
                            lineHeight: '14px',
                            borderTop: "1px solid rgba(230, 230, 230, 1)",
                        }}
                    >
                        <Button
                            shape="round"
                            onClick={cancel}
                            disabled={saveLoading}
                        >关闭</Button>
                        {
                            !readonly && (
                                <Button
                                    type="primary"
                                    shape="round"
                                    style={{ marginLeft: 48 }}
                                    // onClick={submit}
                                    onClick={form.submit}
                                    loading={saveLoading}
                                >保存</Button>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Comp);
