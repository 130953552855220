const ZONE_COLOR = {
    1: 'rgba(246, 38, 76, 1)',
    2: 'rgba(255, 187, 150, 1)',
    3: 'rgba(53, 207, 201, 1)',
};
const ZONE_NAME = {
    1: '红区',
    2: '黄区',
    3: '绿区',
};

export {
    ZONE_COLOR,
    ZONE_NAME,
};