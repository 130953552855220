import React, { useState } from 'react';
import moment from 'moment';
import './index.scss';

const Comp = (props) => {
    const { tableResult, sequence, timeType } = props;
    const [topHeadScroll, setTopHeadScroll] = useState(false);
    const [leftHeadScroll, setLeftHeadScroll] = useState(false);
    const tableOnScroll = (e) => {
        const { scrollLeft, scrollTop } = e.target;
        // console.log(scrollLeft, scrollTop);
        const nextTopHeadScroll = scrollTop !== 0;
        if (nextTopHeadScroll !== topHeadScroll) {
            setTopHeadScroll(nextTopHeadScroll);
        };
        const nextLeftHeadScroll = scrollLeft !== 0;
        if (nextLeftHeadScroll !== leftHeadScroll) {
            setLeftHeadScroll(nextLeftHeadScroll);
        };
    };
    let ths = [];
    let trs = [];
    if (!!tableResult) {
        let { form_header, content_list } = tableResult;
        content_list = content_list.map((item) => {
            const { data_time } = item;
            let timeFormat = "YYYY-MM-DD";
            if (timeType === 1) {
                //hour
                timeFormat = "YYYY-MM-DD HH:mm:ss";
            };
            return Object.assign({}, item, {
                name: moment.unix(data_time).format(timeFormat),
            });
        });
        if (sequence === 0) {
            // 0 列 th time
            ths = [].concat(content_list).map((item) => {
                const { name } = item;
                return {
                    name,
                    id: name,
                };
            });
            trs = [].concat(form_header).map((item) => {
                const { id, name } = item;
                return content_list.reduce((pV, cItem) => {
                    if (cItem[id] !== undefined) {
                        return Object.assign({}, pV, {
                            [cItem.name]: cItem[id],
                        });
                    };
                    return pV;
                }, {
                    name,
                });
            });
        } else {
            // 1 行 th tag
            ths = [].concat(form_header);
            trs = [].concat(content_list);
        };
    };
    return (
        <div style={{ height: '100%' }} className="table_wrapper_gPxgP scroll_bar_restyle" onScroll={tableOnScroll}>
            {
                !!tableResult && (
                    <table>
                        <thead>
                            <tr className={(topHeadScroll ? "head_scroll" : "") + (leftHeadScroll ? " left_head_scroll" : "")}>
                                <th>
                                    <div style={{ minWidth: 146 }}>时间</div>
                                </th>
                                {
                                    ths
                                        .map((item, index) => {
                                            const { name } = item;
                                            return (
                                                <th key={index}>{name}</th>
                                            );
                                        })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                trs
                                    .map((item, index) => {
                                        const { name } = item;
                                        return (index > 0 ? [(
                                            <tr key={`space_${index}`}>
                                                <td style={{ height: 16 }} className={leftHeadScroll ? "head_scroll" : ""} />
                                            </tr>
                                        )] : [])
                                            .concat([(
                                                <tr key={index} className="data_tr">
                                                    <td className={leftHeadScroll ? "head_scroll" : ""}>{name}</td>
                                                    {
                                                        ths
                                                            .map((tdItem, index) => {
                                                                const { id } = tdItem;
                                                                let text = '-';
                                                                if (typeof item[id] === "number") {
                                                                    text = Math.floor(item[id] * 100) / 100;
                                                                };
                                                                return (
                                                                    <td key={index}>{text}</td>
                                                                );
                                                            })
                                                    }
                                                </tr>
                                            )]);
                                    })
                            }
                        </tbody>
                    </table>
                )
            }
        </div>
    );
};

export default Comp;
