import React, { useCallback } from 'react';
import style from './index.module.scss';
import ScaleWrapper from 'components/ScaleWrapper';
import ScreenTrend from './ScreenTrend';
import ScreenMain from './ScreenMain';
import ScreenCarbon from './ScreenCarbon';

const Page = (props) => {

    const gotoFunc = useCallback((path) => {
        return () => {
            const pushFunc = props?.history?.push ?? null;
            if (typeof pushFunc === 'function') {
                pushFunc(path);
            };
        };
    }, [props]);

    return (
        <div className={style['wrapper']}>
            <ScaleWrapper width={9216} height={1728}>
                <div className={style['content']}>
                    <div className="item" style={{ flexGrow: 2688 }} onClick={gotoFunc("/ScreenTrend")}>
                        <ScreenTrend />
                    </div>
                    <div className="item" style={{ flexGrow: 3840 }} onClick={gotoFunc("/ScreenMain")}>
                        <ScreenMain />
                    </div>
                    <div className="item" style={{ flexGrow: 2688 }} onClick={gotoFunc("/ScreenCarbon")}>
                        <ScreenCarbon />
                    </div>
                </div>
            </ScaleWrapper>
        </div>
    );
};

export default Page;
