import React, { useCallback, useState } from 'react';
import style from './index.module.scss';
import TopBreadcrumb from 'components/TopBreadCrumb';
import { fncs } from '../utils.js';
import Tabs from 'components/TabsV2';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const breadcrumbList = [{ name: '指标看板' }, { name: '出炉分析' }];

const Comp = (props) => {
    const { sysInfo, setSysInfo, range, setRange = (() => { }) } = props;

    const [key, setKey] = useState(0)
    const handleClick = useCallback(key => {
        return () => {
            setKey(key)
            const curr = moment()
            if (key === 0) {
                setRange([moment(curr).subtract(1, 'months'), moment(curr)])
            } else if (key === 1) {
                setRange([moment(curr).subtract(1, 'weeks'), moment(curr)])
            }
        }
    }, [setRange])
    return (
        <div className={style['wrapper']}>
            <div className="bread_wrapper">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
            </div>
            <div className="tabs_wrapper">
                <Tabs tabs={fncs} selectedTab={sysInfo} onChange={setSysInfo} />
                <div className='right'>
                    <div className='text'>{moment(range[0]).format("YYYY年MM月DD日")} - {moment(range[1]).format("YYYY年MM月DD日")}</div>
                    <div className={`item ${key === 0 ? 'selected' : ''}`} onClick={handleClick(0)}>近一月</div>
                    <div className={`item ${key === 1 ? 'selected' : ''}`} onClick={handleClick(1)}>近一周</div>
                    <div className={`item custom ${key === 2 ? 'selected' : ''}`} onClick={handleClick(2)}>选择时间<RangePicker value={range} onChange={setRange} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, opacity: 0 }} /></div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
