import React from 'react';
import style from './index.module.scss';
import Chart from './Chart';

const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className="content_wrapper scroll_bar_restyle">
                <div className="content">
                    {
                        new Array(5)
                            .fill(null)
                            .map((item, index) => {
                                return (
                                    <div className="item" key={index}>
                                        <div className="top">
                                            <div className="title">熟料</div>
                                            <div className="item">
                                                <div className="bar" style={{ backgroundColor: "rgba(251, 152, 49, 1)" }}></div>
                                                <div className="name">产量</div>
                                                <div className="value" style={{ color: "rgba(251, 152, 49, 1)" }}>99999</div>
                                                <div className="unit">t</div>
                                            </div>
                                            <div className="item">
                                                <div className="bar" style={{ backgroundColor: "rgba(39, 229, 240, 1)" }}></div>
                                                <div className="name">台时</div>
                                                <div className="value" style={{ color: "rgba(39, 229, 240, 1)" }}>99999</div>
                                                <div className="unit">t/h</div>
                                            </div>
                                        </div>
                                        <div className="bottom">
                                            <div className="left">近10天趋势</div>
                                            <div className="right">
                                                <Chart />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        </div>
    );
};

export default Page;
