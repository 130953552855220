import React, { useEffect, useState, useRef } from 'react'
import style from './index.module.scss'
import { SettingOutlined } from '@ant-design/icons';
import {Button,Drawer,Table,Radio,DatePicker,Select,Space,Menu, Dropdown, message, Modal } from 'antd'
import { CaretUpOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { post, roundNum } from 'common/utils'
import { useHistory } from "react-router-dom";
import moment from "moment";
const { RangePicker } = DatePicker;
const { Option } = Select;

const roundStrNum = (v, i) => {
    if(isNaN(parseFloat(v))){
        return null;
    }else{
        return roundNum(v, i);
    }
}

export default class Mixed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            filter_is_published: "ALL",
            filter_day_range: null,
            filter_furnace_no: 2,
            loading: false,
            page: 1,
            pageSize: 10,
            total: 0,
        };
    }

    columns = [
        {
            title: '编号',
            width: 120,
            dataIndex: 'sn',
            // align: 'center'
        },
        {
            title: '创建时间',
            width: 180,
            // dataIndex: 'create_time',
            render: (r)=>{
                return moment(r.create_time).format("YYYY年M月D日 HH:mm");
            }
        },
        {
            title: '发布时间',
            width: 160,
            // dataIndex: 'publish_time',
            render: (r)=>{
                if(r.publish_time){
                    return moment(r.publish_time).format("YYYY年M月D日 HH:mm");
                }else{
                    return '';
                }
            }
        },
        {
            title: '干吨入炉锰(%)',
            dataIndex: 'MnPct_Mn_ad',
            width: 120,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '干吨锰硅(%)',
            dataIndex: 'MnPct_MnSi_ad',
            width: 120,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'Si干吨锰硅(%)',
            dataIndex: 'SiPct_MnSi_ad',
            width: 120,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'C综合料比',
            dataIndex: 'C_MnSi_R',
            width: 100,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '锰铁比',
            dataIndex: 'MnFe_R',
            width: 100,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '硅锰比',
            dataIndex: 'SiMn_R',
            width: 100,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '碳锰比',
            dataIndex: 'CMn_R',
            width: 100,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '自然碱度',
            dataIndex: 'R3_Feed',
            width: 100,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '炉渣R2',
            dataIndex: 'R2_SLAG',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '炉渣R3',
            dataIndex: 'R3_SLAG',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'Fe(%)',
            dataIndex: 'Fe',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'SiO2(%)',
            dataIndex: 'SiO2',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'CaO(%)',
            dataIndex: 'CaO',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'MgO(%)',
            dataIndex: 'MgO',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'Al2O3(%)',
            dataIndex: 'Al2O3',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: 'C(%)',
            dataIndex: 'C',
            width: 96,
            align: 'center',
            render: v=>roundStrNum(v, 2)
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 120,
            align: 'left',
        },
        {
            title: '成本对标',
            dataIndex: 'cost',
            fixed: 'right',
            width: 100,
            // sorter: {
            //     compare: (a, b) => moment(a.occurTime) - moment(b.occurTime)
            // },
            render: () => <Space>
                <CaretUpOutlined style={{color: 'hotpink'}} />
                <span style={{color: "hotpink"}}>3.56%</span>
                <span style={{display: 'inline-block', width: 100,height: 10,backgroundColor: 'hotpink'}}></span>
            </Space>
        },
        {
            title: '入炉矿单价',
            dataIndex: 'price',
            fixed: 'right',
            width: 100,
            // sorter: {
            //     compare: (a, b) => moment(a.occurTime) - moment(b.occurTime)
            // }
        },
        {
            title: '创建人',
            dataIndex: 'user_name',
            fixed: 'right',
            width: 110,
        },
        {
            title: '发布状态',
            fixed: 'right',
            width: 100,
            render: (r) => <Space>
                <span style={{display: 'inline-block',width: 8,height: 8,borderRadius: 4,backgroundColor: (r.publish_time?"#04dc04":"red")}}></span>
                <span>{r.publish_time?"已发布":"未发布"}</span>
            </Space>
        },
        {
            title: '操作',
            fixed: 'right',
            width: 120,
            render: (r) => <div>
                <a style={{marginRight: "14px"}} onClick={_=>this.props.history.push(`/PEIMSV2/Mixed/MixDetail/${r.id}`)}>查看</a>
                <Dropdown key="dropdown" overlay={(
                    <Menu onClick={({ key }) => {
                        if (key == 'WITHDRAW'){
                            this.updatePubStatus(r.id, false);
                        }else if (key == 'PUBLISH'){
                            this.updatePubStatus(r.id, true);
                        }else if (key == 'DELETE') {
                            this.delItem(r);
                        }else if (key == 'DUPLICATE') {
                            this.duplicateItem(r);
                        }
                    }}>
                        {<Menu.Item key="DUPLICATE">复制</Menu.Item>}
                        {r.publish_time && <Menu.Item key="WITHDRAW">撤回</Menu.Item>}
                        {!r.publish_time && <Menu.Item key="PUBLISH">发布</Menu.Item>}
                        <Menu.Item key="DELETE">删除</Menu.Item>
                    </Menu>
                )}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: '#357CF7' }}>
                        更多 <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        },
    ]

    updatePubStatus = (id, pubStatus) => {
        let self = this;
        self.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/update_pub_status/${id}`, {
            publish_status: pubStatus,
        }).then(res => {
            self.setState({loading: false});
            if (!!res.data && !!res.data.results) {
                message.success('操作成功');
            }
            self.getDataList(1);
        })
    }

    duplicateItem = (record) => {
        let self = this;
        Modal.confirm({
            icon: <InfoCircleOutlined style={{color: '#22B14C'}}/>,
            content: `确认复制物料记录 ${record.sn} 吗?`,
            onOk: () => {
                self.setState({loading: true});
                post(process.env.REACT_APP_SERVER_PATH + 'api/proj_puyuan/ingred_calc/duplicate_one/' + record.id, {
                }).then((retData) => {
                    self.setState({loading: false});
                    if (retData.data) {
                        message.success("操作成功");
                        self.getDataList(1);
                    } else {
                        message.error("操作失败");
                    }
                });
            },
        });
    }

    delItem = (record)=>{
        let self = this;
        Modal.confirm({
            content: `确认删除物料记录 ${record.sn} 吗?`,
            onOk: () => {
                self.setState({loading: true});
                post(process.env.REACT_APP_SERVER_PATH + 'api/proj_puyuan/ingred_calc/delete_one/' + record.id, {
                    "status": -1,
                }).then((retData) => {
                    self.setState({loading: false});
                    if (retData.data) {
                        message.success("删除成功");
                        self.getDataList(1);
                    } else {
                        message.error("删除失败");
                    }
                });
            },
        });
    }

    getDataList = (page) => {
        const { pageSize } = this.state;
        let day_begin = null;
        let day_end = null;
        let self = this;
        let {filter_day_range, filter_furnace_no, filter_is_published } = this.state;
        console.log('getDataList ', filter_day_range);
        this.setState({loading: true});
        if(filter_day_range !== null){
            day_begin = moment(filter_day_range[0]).format("YYYY-MM-DD");
            day_end = moment(filter_day_range[1]).format("YYYY-MM-DD");
        }
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/get_list`, {
            page: page,
            page_size: pageSize,
            furnace_no: filter_furnace_no,
            day_begin: day_begin,
            day_end: day_end,
            is_published: (filter_is_published === 'ALL' ? null:filter_is_published),
        }).then(res => {
            self.setState({
                loading: false,
            });
            if (!!res.data && !!res.data.results) {
                self.setState({
                    dataList: res.data.results,
                    page,
                    total: res.data.total,
                });
            }
        })
    }

    createItem = ()=>{
        let self = this;
        this.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/create_one`, {
            furnace_no: self.state.filter_furnace_no,
        }).then(res => {
            self.setState({loading: false});
            if (!!res.data && res.data.errcode === 0) {
                self.props.history.push(`/PEIMSV2/Mixed/MixDetail/${res.data.result}`)
            }
        })
    }

    componentDidMount() {
        this.getDataList(1);
    }

    pageOnChange = (page, pageSize) => {
        this.getDataList(page);
    }

    render(){
        let self = this;
        let { filter_day_range, filter_furnace_no, filter_is_published, loading, page, pageSize, total } = this.state;
        return (
            <div className={style['mixed']}>
                <div className="mixed-header">
                    <div className="mixed-header-left">
                        <div>
                            <span style={{color: 'rgba(0,0,0,.45)'}}>轻环+ /</span>
                            <span>&nbsp;&nbsp;配料优化 </span>
                        </div>
                        <div style={{marginLeft: 35}}>
                            <Radio.Group
                                defaultValue={2}
                                value={filter_furnace_no}
                                buttonStyle="solid"
                                size="middle"
                                onChange={e=>this.setState({filter_furnace_no: e.target.value}, ()=>this.getDataList(1))}
                            >
                                <Radio.Button value={1}>1#炉</Radio.Button>
                                <Radio.Button value={2}>2#炉</Radio.Button>
                                <Radio.Button value={3}>3#炉</Radio.Button>
                                <Radio.Button value={4}>4#炉</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="mixed-header-right"></div>
                </div>
                <div className="mixed-container">
                    <div className="mixed-container-content">
                        <div className="header">
                            <div className="header-left">
                                <div style={{marginRight: 8}}>选择日期：</div>
                                <RangePicker
                                    // showTime={{ format: 'HH:mm' }}
                                    defaultValue={null}
                                    // format="YYYY-MM-DD"
                                    onChange={v=>{
                                        console.log('1. ', v);
                                        if(v !== null){
                                            self.setState({
                                                filter_is_published: 'TRUE',
                                                filter_day_range: v,
                                            }, ()=>{
                                                self.getDataList(1);
                                            })
                                        }else{
                                            self.setState({
                                                filter_day_range: v,
                                            }, ()=>{
                                                self.getDataList(1);
                                            })
                                        }
                                    }}
                                    value={filter_day_range}
                                />
                                <div style={{marginRight: 8,marginLeft: 12}}>发布状态：</div>
                                <Select
                                    defaultValue="ALL"
                                    style={{ width: 100 }}
                                    // onChange={handleChange}
                                    onChange={v=>{
                                        if(v == 'FALSE' && filter_day_range !== null){
                                            self.setState({
                                                filter_day_range: null,
                                                filter_is_published: v,
                                            },()=>self.getDataList(1))
                                        }else{
                                            self.setState({
                                                filter_is_published: v,
                                            },()=>self.getDataList(1))
                                        }
                                    }}
                                    value={filter_is_published}

                                >
                                    <Option value="ALL">全部</Option>
                                    <Option value="TRUE">已发布</Option>
                                    <Option value="FALSE">未发布</Option>
                                </Select>
                                <div style={{marginRight: 8,marginLeft: 12}}>对标基准：</div>
                                <Select defaultValue="本月最优" style={{ width: 120 }} onChange={()=>{}}>
                                    <Option value="本月最优">本月最优</Option>
                                </Select>
                            </div>
                            <div className="header-right">
                                <Button type="primary" style={{marginRight: 12}} onClick={_=>this.createItem()}>新建物料</Button>
                                <Button icon={<SettingOutlined />} >显示设置</Button>
                            </div>
                        </div>

                        <div style={{marginTop: 32}} className="list">
                            <Table
                                className="calc-list"
                                loading={loading}
                                columns={this.columns}
                                dataSource={this.state.dataList}
                                scroll={{ x: 2500 }}
                                // sticky
                                pagination={{
                                    pageSize: pageSize,
                                    current: page,
                                    total,
                                    onChange: this.pageOnChange,
                                }}
                                size="default"
                                rowKey="sn"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
