import React, { useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import KeyFactors from '../KeyFactors';
import DomResize from 'components/DomResize';

const DesignWidth = 481;

const Page = (props) => {
    const [timeInfo, setTimeInfo] = useState(null);

    const [widthHeight, setWidthHeight] = useState(null);
    const onResize = (conf) => {
        setWidthHeight(conf);
    };

    return (
        <div className={style['wrapper']}>
            <Header timeInfo={timeInfo} setTimeInfo={setTimeInfo} />
            <div className="content_wrapper">
                <div className="resize">
                    <DomResize onResize={onResize} mountedCall={true} />
                </div>
                {
                    !!widthHeight && (
                        <div
                            className="content"
                            style={{
                                width: DesignWidth,
                                transform: `scale(${widthHeight.width / DesignWidth})`,
                                height: widthHeight.height / (widthHeight.width / DesignWidth),
                            }}
                        >
                            <KeyFactors timeInfo={timeInfo} link={false} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Page;
