import React, { Component } from 'react'
import { Modal, Form, Icon, Input, Button, Checkbox, Select, message } from 'antd'
import Style from './index.module.scss'
import { get, post, patch, reqDelete } from 'common/utils.js'
import DELETE_ICON from '../../assets/delete.png'
import ADD_ICON from '../../assets/add.png'
const { Option } = Select

class Groups extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmLoading: false,
      showPrompt: false,
      lists: [
        {
          id: null,
          isDelete: 0,
          name: '',
          post: [
            {
              postId: 0,
              userId: 0
            }
          ]
        }
      ],
      membersList: null, // 成员列表
      stationsList: null, // 岗位列表
      selectedMembers: []
      // selectedUsers
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  onShow() {
    this.init()
    this.state.stationsList = this.props.stationList
    if (this.props.groupsList.length > 0) {
      this.state.lists = this.props.groupsList
    }
    this.setState({
      showPrompt: true,
      stationsList: this.state.stationsList,
      lists: this.state.lists
    })
    console.log(this.state.stationsList)
  }

  init() {
    this.getMembers()
  }

  // 获取所有成员
  getMembers() {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/userQuery', {}).then(retData => {
      this.setState({
        membersList: retData.data.results
      })
    })
  }

  add = () => {
    const postArr = []
    this.state.stationsList.map(post => {
      postArr.push({ postId: post.id, userId: null })
    })
    this.state.lists.push({
      id: null,
      isDelete: 0,
      name: '',
      post: postArr
    })
    this.setState({ lists: this.state.lists })
    console.log(this.state.lists)
  }

  delete(index) {
    var lists = this.state.lists
    if (lists.length > 1) {
      lists.map((item, itemIndex) => {
        if (itemIndex === index) {
          item.isDelete = 1
        }
      })
      this.setState({
        lists: lists
      })
    } else {
      message.info('请至少设置一条班组信息')
    }
  }

  // 设置班组名
  changeInput = (item, index, e) => {
    const { lists } = this.state
    lists[index].name = e.target.value
    let newArr = lists
    this.setState({
      lists: newArr
    })
  }

  // 设置人员
  selectChange = (e, id, postId) => {
    this.state.lists.map(info => {
      if (info.id === id) {
        info.post.map(item => {
          if (item.postId === postId) {
            item.userId = e.value
            item.userName = e.label
            this.state.selectedMembers.push({ userId: e.value, groupId: id })
          }
        })
      }
    })
    this.setState({
      lists: this.state.lists,
      selectedMembers: this.state.selectedMembers
    })
  }

  // 提交表单
  handleOk = e => {
    post(
      process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/shiftAdd',
      this.state.lists.filter(n => n.name != '')
    ).then(retData => {
      this.setState({ confirmLoading: false })
      if (retData && retData.data) {
        if (retData.data.errcode === 501) {
          message.error(retData.data.msg)
          return
        }
        message.success('添加成功')
        this.setState({
          showPrompt: false
        })
        this.props.getResults('success')
      } else {
        message.error('修改失败')
      }
    })
  }

  handleCancel = () => {
    //点击取消按钮触发的事件
    console.log('Clicked cancel button')
    this.setState({
      showPrompt: false
    })
  }

  render() {
    const { showPrompt, confirmLoading, membersList, stationsList, selectedMembers } = this.state
    return (
      <Modal
        title="班组设置"
        visible={showPrompt}
        onOk={this.handleOk}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
        className={Style['setting-modal']}
      >
        <Form className={Style['form']}>
          {this.state.lists.map((item, index) =>
            item.isDelete != 1 ? (
              <div className={Style['setting-info']} key={item.id}>
                <Input
                  className={Style['class-setting']}
                  placeholder="请输入班组名称"
                  value={item.name}
                  onChange={this.changeInput.bind(this, item, index)}
                />
                <div style={{ margin: ' 0 16px' }}>
                  {stationsList &&
                    stationsList.map((stationItem, stationIndex) => (
                      <div key={stationItem.id} className={Style['user-setting']}>
                        <span>{stationItem.name}</span>
                        <Select
                          labelInValue
                          value={
                            item.post[stationIndex] &&
                            item.post[stationIndex].postId &&
                            item.post[stationIndex].postId === stationItem.id
                              ? {
                                  value: item.post[stationIndex].userId,
                                  label: item.post[stationIndex].userName
                                }
                              : { value: null, label: null }
                          }
                          className={Style['user-selected']}
                          onChange={e => this.selectChange(e, item.id, stationItem.id)}
                        >
                          {membersList &&
                            membersList.map(member => {
                              if (
                                selectedMembers.some(
                                  selectItem =>
                                    selectItem.groupId !== item.id &&
                                    member.id === selectItem.userId
                                )
                              ) {
                                return ''
                              }
                              return (
                                <>
                                  <Option value={member.id} key={member.id}>
                                    {member.name}
                                  </Option>
                                </>
                              )
                            })}
                        </Select>
                      </div>
                    ))}
                </div>
                <img
                  src={DELETE_ICON}
                  className={Style['class-info-delete']}
                  onClick={e => this.delete(index)}
                ></img>
              </div>
            ) : (
              ''
            )
          )}
          <img
            onClick={this.add}
            src={ADD_ICON}
            className={Style['class-info-delete']}
            style={{ float: 'right', marginTop: '16px' }}
          ></img>
        </Form>
      </Modal>
    )
  }
}

export default Groups
