import React from 'react';
import { post } from '../../../common/utils';
import moment from 'moment';
import Header from './Header';
import Content from './Content';

import './index.scss';

export default class AlarmPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_finished: null,
            unfinishedNum: null,
            dataSource: [],
            page: 1,
            total: 0,
            pageSize: 10,
            filterSysId: 'all',
            filterLevel: 'all',
            dayBegin: null,
            dayEnd: null,
        }
    }

    componentDidMount() {
        this.getUnfinishedAlarms();
        this.getAlarmList();
    }

    getAlarmList = () => {
        const { is_finished } = this.state;
        const { page, pageSize, filterSysId, filterLevel, dayBegin, dayEnd } = this.state;
        post(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_events/list', {
            page,
            pageSize,
            is_finished,
            sys_id: (filterSysId + '' === 'all' ? undefined : filterSysId),
            level: (filterLevel + '' === 'all' ? undefined : filterLevel),
            time_begin: (dayBegin ? dayBegin.format('YYYY-MM-DD 00:00:00') : undefined),
            time_end: (dayEnd ? dayEnd.format('YYYY-MM-DD 23:59:59') : undefined),
        }).then(retData => {
            // console.log(retData);
            if (!!retData.data && retData.data.errcode === 0) {
                const dataSource = retData.data.results
                    .map((item) => {
                        const { id } = item;
                        return Object.assign({}, item, {
                            key: id,
                            duration: this.alarmDur(item),
                        });
                    });
                this.setState({
                    dataSource,
                    total: retData.data.total,
                });
            }
        });
    }

    getUnfinishedAlarms = () => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_events/list', {
            page: 1,
            page_size: 0,
            is_finished: false,
        }).then(retData => {
            // console.log(retData);
            if (!!retData.data && retData.data.errcode === 0) {
                this.setState({ unfinishedNum: retData.data.total });
            }
        })
    }

    statusOnChange = (is_finished = null) => {
        this.getUnfinishedAlarms();
        this.setState({ is_finished },
            this.getAlarmList
        )
        // console.log(is_finished);
    }

    alarmDur = ({ time_begin, time_end }) => {
        if (time_begin && time_end) {
            time_begin = moment(time_begin, 'YYYY-MM-DD HH:mm:ss');
            time_end = moment(time_end, 'YYYY-MM-DD HH:mm:ss');
            const duration = moment.duration(time_end.diff(time_begin));
            // console.log(time_end.diff(time_begin, 'days'), duration.hours(), duration.minutes(), duration.seconds());
            const diffDays = time_end.diff(time_begin, 'days');
            return (diffDays === 0 ? '' : `${diffDays}天 `) + [duration.hours(), duration.minutes(), duration.seconds()].map(v => v < 10 ? `0${v}` : v).join(':');
        }
        return '';
    }

    render() {
        const { unfinishedNum, dataSource, filterSysId, filterLevel, dayBegin, dayEnd, total } = this.state;
        return (
            <div className="alarm_page_wrapper" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Header statusOnChange={this.statusOnChange} unfinishedNum={unfinishedNum} />
                {/* <div style={{ height: 1, backgroundColor: 'rgb(61, 205, 185)' }}></div> */}
                <div style={{ height: 0, flexGrow: 1 }}>
                    <Content
                        dataSource={dataSource}
                        filterSysId={filterSysId}
                        filterLevel={filterLevel}
                        dayBegin={dayBegin}
                        dayEnd={dayEnd}
                        total={total}
                        sysIdOnchange={filterSysId => this.setState({ filterSysId })}
                        levelOnchange={filterLevel => this.setState({ filterLevel })}
                        rangeOnchange={(dateRange) => this.setState({
                            dayBegin: (dateRange == null ? null : dateRange[0]),
                            dayEnd: (dateRange == null ? null : dateRange[1]),
                        })}
                        getAlarmList={this.getAlarmList}
                        pageOnchange={page => this.setState({ page }, this.getAlarmList)}
                    />
                </div>
            </div>
        );
    }
}
