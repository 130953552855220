import React, { useEffect, useRef, useState, useMemo, useContext } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';
import { elec_items, get_radar_option, get_line_option, chart_sufficList } from './constants';
import DetailModal from './DetailModal';
import CommonContext from '../../context';
import { Spin } from 'antd';
import { post } from 'common/utils';
import { getSuffixIndexByTime } from '../../utils';
import moment from 'moment';

const getTimeRange = (timeType, timeStr) => {
    if (timeType === 'date') {
        return [moment(timeStr).add(-13, 'days').format("YYYY-MM-DD 00:00:00"), moment(timeStr).format("YYYY-MM-DD 00:05:00")];
    } else if (timeType === 'week') {
        return [moment(timeStr).add(-11, 'weeks').startOf('week').format("YYYY-MM-DD 00:00:00"), moment(timeStr).endOf('week').format("YYYY-MM-DD 00:05:00")];
    } else if (timeType === 'month') {
        return [moment(timeStr).add(-11, 'months').startOf('month').format("YYYY-MM-DD 00:00:00"), moment(timeStr).endOf('month').format("YYYY-MM-DD 00:05:00")];
    };
    return null;
};

const Comp = (props) => {
    // const { } = props;
    const [selectedIndex, setSelectedIndex] = useState(!!elec_items && elec_items.length > 0 ? 0 : null);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableIndex, setTableIndex] = useState(0);
    const [tag2Val, setTag2Val] = useState({});
    const [hisTag2Val, setHisTag2Val] = useState({});
    const [abnormalList, setAbnormalList] = useState(null);
    const chartNode = useRef(null);
    const lineChartRef = useRef(null);
    const commonInfo = useContext(CommonContext);
    useEffect(() => {
        if (!!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!selectedDevice || !time) return;
            const basic_tag = selectedDevice.tag_name;
            const [timeTab, timeStr] = time;
            const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
            const tags = []
                .concat(
                    elec_items
                        .map((item) => {
                            const { extdTag, suffixList } = item;
                            const tag = basic_tag + extdTag + suffixList[suffixIndex];
                            return tag;
                        }),
                    ...(
                        elec_items
                            .map((item) => {
                                const { tables } = item;
                                const datas = [].concat(...tables.map((table) => table.dataSource(basic_tag)));
                                const tbTags = datas
                                    .map((data) => {
                                        const { suffixList } = data;
                                        const rowTags = ['c1', 'c2', 'c3', 'c4']
                                            .reduce((pV, cKey) => {
                                                if (!!data[cKey]) {
                                                    const { extdTag } = data[cKey];
                                                    return pV.concat([basic_tag + extdTag + suffixList[suffixIndex]])
                                                };
                                                return pV;
                                            }, []);
                                        return rowTags;
                                    });
                                return [].concat(...tbTags);
                            })
                    ),
                );
            const hisTags = []
                .concat(
                    elec_items
                        .map((item) => {
                            const { chartExtdTag } = item;
                            return basic_tag + chartExtdTag + chart_sufficList[suffixIndex];
                        })
                );
            const trimedTags = [...new Set(tags)];
            const trimedHisTags = [...new Set(hisTags)];
            // console.log(tags, trimedTags);
            const [his_time_begin, his_time_end] = getTimeRange(timeTab.tag, timeStr) || [];

            // 历史create_time取周期末的23:30:00
            // 当前周期的create_time取当前时间前1小时10分
            let create_time = moment(timeStr).endOf(timeTab.momentUnit).format("YYYY-MM-DD 23:30:00");
            if (moment(timeStr).isSame(moment(), timeTab.isAfterKey)) {
                // 当前周期内
                create_time = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:10:00");
            };

            setLoading(true);
            Promise.all([
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                    tag_name_list: trimedTags,
                    create_time,
                }),
                post(process.env.REACT_APP_SERVER_PATH + 'api/meter_node/get_meter_alarm_events', {
                    "meter_id": 1,
                    "day_begin": moment(timeStr).format("YYYY-MM-DD"),
                    "time_type": "DAY"
                }),
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                    tag_list: trimedHisTags
                        .map((tag_name) => {
                            return {
                                tag_name,
                                time_begin: his_time_begin,
                                time_end: his_time_end,
                            };
                        }),
                }),
            ])
                .then(([retData1, retData2, retData3]) => {
                    setLoading(false);
                    // console.log(retData1);
                    if (!!retData1.data && retData1.data.errcode === 0) {
                        const nextTag2Val = retData1.data.results;
                        // console.log(nextTag2Val);
                        setTag2Val(nextTag2Val);
                    };
                    // console.log(retData2);
                    if (!!retData2.data && retData2.data.errcode === 0) {
                        setAbnormalList(retData2.data.results);
                    };
                    // console.log(retData3);
                    if (!!retData3.data && retData3.data.errcode === 0) {
                        if (retData3.data.results.length > 0) {
                            const nextHisTag2Val = retData3.data.results
                                .reduce((pV, cItem) => {
                                    const { tag_name, data_list } = cItem;
                                    if (!!data_list && data_list.length > 0) {
                                        return Object.assign({}, pV, { [tag_name]: data_list })
                                    };
                                    return pV;
                                }, {});
                            setHisTag2Val(nextHisTag2Val);
                        };
                    };
                });
        };
    }, [commonInfo]);
    useEffect(() => {
        if (!!chartNode.current && !!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!selectedDevice || !time) {
                return;
            };
            const [timeTab, timeStr] = time;
            const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
            const datas = elec_items.map((item) => {
                const { extdTag, suffixList } = item;
                const tag = selectedDevice.tag_name + extdTag + suffixList[suffixIndex];
                return tag2Val[tag] * 100 || null;
            })
                .map((v) => typeof v !== 'number' ? 0 : v);
            // render chart
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption(get_radar_option(datas));
        };
    }, [tag2Val, commonInfo]);
    useEffect(() => {
        if (!!lineChartRef.current && !!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!selectedDevice || !time) {
                return;
            };
            const [timeTab, timeStr] = time;
            const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
            const extdTag = elec_items[selectedIndex].chartExtdTag;
            const tag = selectedDevice.tag_name + extdTag + chart_sufficList[suffixIndex];
            const data = hisTag2Val[tag] || [];
            // render chart
            let instance = echarts.getInstanceByDom(lineChartRef.current);
            if (!instance) {
                instance = echarts.init(lineChartRef.current);
            };
            instance.setOption(get_line_option(data));
        };
    }, [selectedIndex, hisTag2Val, commonInfo]);
    const moreOnClick = () => {
        setVisible(true);
    };
    const selectedItem = useMemo(() => {
        if (selectedIndex !== null && !!elec_items[selectedIndex]) {
            return elec_items[selectedIndex];
        };
        return null;
    }, [selectedIndex]);
    const all_BasicTag = useMemo(() => {
        if (!!commonInfo) {
            const { state: { selectedDevice } } = commonInfo;
            if (!!selectedDevice) {
                return selectedDevice.tag_name;
            };
        };
        return null;
    }, [commonInfo]);
    const itemOnClick = (index) => {
        setTableIndex(0);
        setSelectedIndex(index);
    };
    return (
        <div className={style['wrapper']}>
            <div className="title_row">
                <div className="title">电能健康</div>
            </div>
            <div className="content_row">
                <div className="left">
                    <div className="chart_wrapper">
                        <div className="chart_content_wrapper">
                            <div className="radar_block">
                                <div style={{ width: '100%', height: '100%' }} ref={chartNode} />
                                {
                                    elec_items
                                        .map((item, index) => {
                                            const { state: { selectedDevice, time } } = commonInfo;
                                            if (!selectedDevice || !time) return null;
                                            const [timeTab, timeStr] = time;
                                            const { style, name, extdTag, suffixList } = item;
                                            const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
                                            const tag = selectedDevice.tag_name + extdTag + suffixList[suffixIndex];
                                            // console.log(tag);
                                            let valueText = '--';
                                            if (typeof tag2Val[tag] === 'number') {
                                                valueText = Math.floor(tag2Val[tag] * 1000) / 10;
                                            };
                                            return (
                                                <div key={index} style={style} className="item_wrapper" onClick={() => itemOnClick(index)}>
                                                    <div className="value">{valueText}</div>
                                                    <div className={"name" + (selectedIndex === index ? " selected_name" : "")}>{name}</div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="chart_row" ref={lineChartRef}></div>
                    <div className="table_row">
                        {
                            selectedItem !== null && (
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                selectedItem.tables[tableIndex].columns
                                                    .map((item, index) => {
                                                        const { title } = item;
                                                        return (
                                                            <th key={index} className={index === 0 ? "th_topic" : ""}>
                                                                <div
                                                                    {
                                                                    ...(
                                                                        index === 0 && selectedItem.tables.length > 1 ? {
                                                                            className: "clickable",
                                                                            onClick: () => { setTableIndex(1 - tableIndex); },
                                                                        } : {}
                                                                    )
                                                                    }
                                                                >
                                                                    {title}
                                                                </div>
                                                            </th>
                                                        );
                                                    })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="5" style={{ paddingBottom: 8 }}>
                                                <div style={{ height: 1, backgroundColor: '#c9c9c9' }} />
                                            </td>
                                        </tr>
                                        {
                                            selectedItem.tables[tableIndex].dataSource(all_BasicTag)
                                                .map((item, index) => {
                                                    const { state: { selectedDevice, time } } = commonInfo;
                                                    if (!selectedDevice || !time) return null;
                                                    const [timeTab, timeStr] = time;
                                                    const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
                                                    const { name, unit, precision } = item;
                                                    let currPrecision = 0;
                                                    if (typeof precision === 'number' && precision >= 0) {
                                                        currPrecision = precision
                                                    };
                                                    const multiple = Math.pow(10, currPrecision);
                                                    return (
                                                        <tr key={index} className="tr_content">
                                                            <td>
                                                                <div style={{ maxWidth: 72 }}>{name}</div>
                                                            </td>
                                                            {
                                                                selectedItem.tables[tableIndex].columns
                                                                    .filter((column, cIndex) => cIndex > 0)
                                                                    .map((column, cIndex) => {
                                                                        const cKey = `c${cIndex + 1}`;
                                                                        const cItem = item[cKey];
                                                                        const { extdTag, suffixList } = cItem;
                                                                        const suffix = suffixList[suffixIndex];
                                                                        const tag = selectedDevice.tag_name + extdTag + suffix;
                                                                        let valueText = '--';
                                                                        if (typeof tag2Val[tag] === 'number') {
                                                                            valueText = Math.floor(tag2Val[tag] * multiple) / multiple;
                                                                        };
                                                                        return (
                                                                            <td key={cIndex}>
                                                                                <div style={{ maxWidth: 72 }}>{valueText}{typeof unit === 'string' ? unit : ""}</div>
                                                                            </td>
                                                                        );
                                                                    })
                                                            }
                                                        </tr>
                                                    );
                                                })
                                        }
                                        <tr>
                                            <td colSpan="5" style={{ paddingTop: 8 }}>
                                                <div style={{ height: 1, backgroundColor: '#c9c9c9' }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                    <div className="list_row">
                        {
                            !!abnormalList && (
                                <div className="content_wrapper">
                                    {

                                        abnormalList
                                            .filter((item, index) => index < 4)
                                            .map((item, index) => {
                                                const { create_time, content } = item;
                                                return (
                                                    <div className="item_wrapper" key={index}>{moment(create_time).format("HH:mm:ss")} {content}</div>
                                                );
                                            })
                                    }
                                    {
                                        abnormalList.length > 4 && (
                                            <div>
                                                <div className="more_btn" onClick={moreOnClick}>更多...</div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
            {
                visible && (
                    <DetailModal onCancel={() => setVisible(false)} />
                )
            }
        </div>
    );
};

export default Comp;
