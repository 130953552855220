import React from 'react';
import { post, get, patch } from '../../../common/utils';
import { Button, Input, Table, Select, message, Modal } from 'antd';
import AlarmMetaModal from './AlarmMetaModal.js';
import './Content.scss';

const { Option } = Select;

let ALARM_LEVELS = [
    {
        level: 1,
        name: '一级告警',
    }, {
        level: 2,
        name: '二级告警',
    }, {
        level: 3,
        name: '三级告警',
    }
]

export default class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            dataSource: [],
            systems: [],
            modalData: null,
            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,

            filterLevel: 'all',
            filterSysId: 'all',
            filterKeyword: null,
        }
    }

    componentDidMount() {
        const self = this;
        this.refreshList();
        get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_prod_system_list').then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                self.setState({ systems: retData.data.results });
            }
        });
    }

    refreshList = () => {
        const self = this;
        const { page, pageSize } = this.state;
        this.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_metas/list', {
            page,
            page_size: pageSize,
            sys_id: (self.state.filterSysId + '' === 'all' ? undefined : self.state.filterSysId),
            level: (self.state.filterLevel + '' === 'all' ? undefined : self.state.filterLevel),
            keyword: (self.state.filterKeyword + '' === 'all' ? undefined : self.state.filterKeyword),
        }).then(retData => {
            // console.log(retData);
            this.setState({loading: false});
            if (retData.data && retData.data.errcode === 0) {
                const dataSource = retData.data.results;
                dataSource.forEach(item => {
                    item.key = item.id;
                    item.createTime = item.create_time.split(' ')[0];
                    item.alarmLevel = item.level === 1 ? '三级报警' : `级别${item.level}`
                });
                self.setState({ dataSource, total: retData.data.total })
            }
        });
    }

    delEvent = (record) => {
        let self = this;
        let modal = Modal.confirm({
            content: `确认删除告警策略【${record.meta_data.name}】吗?`,
            onOk: ()=> new Promise((resolve, reject)=>{
                patch(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_metas/' + record.id, {
                    "status": -1,
                }).then((retData) => {
                    if (retData.data) {
                        resolve();
                        message.success("删除成功");
                        self.refreshList(1);
                    } else {
                        message.error("删除失败");
                        reject();
                    }
                });
            }),
        });
    }

    displayDetails = (record) => {
        this.setState({
            modalVisible: true,
            modalData: !!record ? record : null,
        });
    }

    render() {
        let self = this;
        const { filterSysId, filterLevel, filterKeyword, dataSource, systems, pageSize, page, total, loading } = this.state;
        return (
            <div className="alarm_strategy_content_wrapper">
                <div className="content_wrapper_">
                    <div className="header">
                        <Select
                            defaultValue="all"
                            value={filterSysId}
                            style={{ width: 180 }}
                            onChange={v => this.setState({ filterSysId: v })}
                        >
                            <Option value='all'>全部系统</Option>
                            {
                                systems.map((item, index) =>
                                    <Option value={item.id} key={index}>{item.name}</Option>)
                            }
                        </Select>
                        <Select
                            style={{ width: 144, marginLeft: 24 }}
                            defaultValue="all"
                            value={filterLevel}
                            onChange={v => self.setState({ filterLevel: v })}
                        >
                            <Option value='all'>全部告警</Option>
                            {
                                ALARM_LEVELS.map((item, index) =>
                                    <Option value={item.level} key={index}>{item.name}</Option>
                                )
                            }
                        </Select>
                        <Input
                            style={{ width: 160, marginLeft: 20 }}
                            value={filterKeyword}
                            onChange={(e) => this.setState({ filterKeyword: e.target.value })}
                            placeholder="请输入关键字"
                        />
                        <Button type="primary" style={{ marginLeft: 24 }} onClick={() => self.setState({ page: 1 }, self.refreshList)}>查询</Button>
                        {/* <Button type="primary" ghost style={{ marginLeft: 'auto' }} onClick={() => this.props.history.push('/System/AlarmList')}>返回</Button> */}
                        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={() => this.displayDetails()}>添加</Button>
                    </div>

                    <Table
                        dataSource={dataSource}
                        loading={loading}
                        columns={[
                            {
                                title: '系统名称',
                                key: 'title',
                                render: (text, { sys_name }, index) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{sys_name}</span>
                                    </div>
                                )
                            },
                            {
                                title: '点位名称',
                                key: 'data_meta_id',
                                render: (text, record) => {
                                    if (record.meta_data && record.meta_data.name) {
                                        return record.meta_data.name;
                                    } else {
                                        return '';
                                    }
                                }
                            },
                            {
                                title: '下限',
                                dataIndex: 'lower_limit',
                            },
                            {
                                title: '上限',
                                dataIndex: 'upper_limit',
                            },
                            {
                                title: '超限超时',
                                key: 'time_limit',
                                render: (text, { time_limit }) => {
                                    return time_limit === null ? '' : (time_limit + '分钟');
                                }
                            },
                            {
                                title: '警告级别',
                                key: 'level',
                                render: (text, record) => {
                                    return ALARM_LEVELS.filter(item => item.level + '' === record.level + '')[0].name
                                }
                            },
                            {
                                title: '状态',
                                key: 'status',
                                render: (text, record) => {
                                    if (parseInt(record.status) === 1) {
                                        return '已启用';
                                    } else if (parseInt(record.status) === 0) {
                                        return '已停用';
                                    } else {
                                        return null;
                                    }
                                }
                            },
                            {
                                title: '备注',
                                dataIndex: 'remark',
                            },
                            {
                                title: '创建人',
                                dataIndex: 'user_name',
                            },
                            {
                                title: '创建日期',
                                dataIndex: 'createTime',
                            },
                            {
                                title: '操作',
                                key: 'action',
                                render: (text, record, index) => (
                                    <div>
                                        <Button type="link" onClick={() => self.displayDetails(record)}>查看</Button>
                                        <Button type="link" onClick={() => this.delEvent(record)}>删除</Button>
                                    </div>
                                )
                            },
                        ]}
                        pagination={{
                            current: page,
                            pageSize,
                            total,
                            onChange: (page, pageSize) => {
                                self.setState({ page }, self.refreshList);
                            }
                        }}
                    />
                    {
                        this.state.modalVisible &&
                        <AlarmMetaModal
                            data={this.state.modalData}
                            systems={this.state.systems}
                            onClose={(update) => this.setState({ modalVisible: false }, () => update && self.setState({ page: 1 }, self.refreshList))}
                        />
                    }
                </div>
            </div>
        );
    }
}
