

const getCheckInfo = (rolePermissions, pageList) => {
    const checkedList = [];
    const halfCheckedList = [];
    if ((rolePermissions ?? []).length > 0 && !!pageList) {
        // 判断二级和一级
        // 先判断二级，根据自身的permissions判断
        []
            .concat(
                ...(pageList ?? [])
                    .map(({ children }) => children ?? []),
            )
            .some((item) => {
                const { permissions, id } = item;
                let checkStatus = 2;
                // 0 未选， 1半选， 2全选
                // 没有permissions认为是2
                if ((permissions ?? []).length > 0) {
                    const selectedNum = permissions
                        .filter(({ id }) => (rolePermissions ?? []).includes(id))
                        .length;
                    if (selectedNum < permissions.length) {
                        if (selectedNum === 0) {
                            checkStatus = 0;
                        } else {
                            checkStatus = 1;
                        };
                    };
                };
                if (checkStatus === 1) {
                    halfCheckedList.push(id);
                } else if (checkStatus === 2) {
                    checkedList.push(id);
                };
                return false;
            });
        // 再判断一级
        // 根据children和permissions
        (pageList ?? [])
            .some((item) => {
                const { children, permissions, id } = item;
                let checkStatus = 2;
                // 0 未选， 1半选， 2全选
                // 没有permissions和children认为是2
                if ((permissions ?? []).length > 0 || (children ?? []).length > 0) {
                    const permissionsSelectedNum = (permissions ?? [])
                        .filter(({ id }) => (rolePermissions ?? []).includes(id))
                        .length;
                    const childrenSelectedNum = (children ?? [])
                        .filter(({ id }) => checkedList.includes(id) || halfCheckedList.includes(id))
                        .length;
                    const selectedNum = permissionsSelectedNum + childrenSelectedNum;
                    const totalNum = (permissions ?? []).length + (children ?? []).length;
                    if (selectedNum < totalNum) {
                        if (selectedNum === 0) {
                            checkStatus = 0;
                        } else {
                            checkStatus = 1;
                        };
                    };
                };
                if (checkStatus === 1) {
                    halfCheckedList.push(id);
                } else if (checkStatus === 2) {
                    checkedList.push(id);
                };
                return false;
            });
    };
    return [checkedList, halfCheckedList];
};

export {
    getCheckInfo,
};