import React from 'react';
import style from './ThreeComp.module.scss';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';


export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            degAngle: null,
        };
    }

    componentDidMount() {
        this.render3D();
    }

    componentWillUnmount() {
        if (!!this.mySetTimeout) {
            clearTimeout(this.mySetTimeout);
        };
        if (!!this.onDocumentMouseMove) {
            document.removeEventListener('mousemove', this.onDocumentMouseMove);
        };
    }

    calcSpaceDistance = (pos1, pos2) => {
        const [x1, y1, z1] = pos1;
        const [x2, y2, z2] = pos2;
        // const squireDis = x1 * x1 + y1 * y1 + z1 * z1 + x2 * x2 + y2 * y2 + z2 * z2;
        const squireDis = (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2) + (z1 - z2) * (z1 - z2);
        return Math.floor(Math.sqrt(squireDis) * 100) / 100;
    }

    render3D = () => {
        if (!this.refNode) {
            return;
        };

        const startXAxis = 570000;
        const startYAxis = 1000000;
        const startZAxis = -240000;
        const startLookAtPostion = [startXAxis, 50000, startZAxis];
        const endLookAtPosition = [400000, 30000, -400000];
        const startCameraPosition = [startXAxis - 10000, startYAxis, startZAxis - 10000];
        const endCameraPosition = [530000, 250000, -470000];
        // const startLookAtPostion = endLookAtPosition;
        // const startCameraPosition = endCameraPosition;

        const self = this;
        // console.log(this.refNode.offsetWidth);
        const nodeWidth = this.refNode.offsetWidth;
        const nodeHeight = this.refNode.offsetHeight;
        // console.log(THREE);

        const refNode = this.refNode;
        const deltaCameraPosition = startCameraPosition
            .map((startAxis, index) => endCameraPosition[index] - startAxis);
        const deltaLookAtPosition = startLookAtPostion
            .map((startAxis, index) => endLookAtPosition[index] - startAxis);

        const renderStepNum = 100;
        var container;
        // var stats;

        var camera, scene, raycaster, renderer;
        var mouse = new THREE.Vector2(), INTERSECTED;

        // var mouseX = 0, mouseY = 0;

        // var windowHalfX = nodeWidth / 2;
        // var windowHalfY = nodeHeight / 2;


        init();
        animate();
        this.camera = camera;

        let currentStep = 0;
        const renderNextScene = () => {
            if (currentStep < renderStepNum) {
                currentStep++;
            } else {
                return;
            };
            const currentCameraPosition = startCameraPosition
                .map((startAxis, index) => startAxis + (deltaCameraPosition[index] * currentStep / renderStepNum));
            const currentLookAtPosition = startLookAtPostion
                .map((startAxis, index) => startAxis + (deltaLookAtPosition[index] * currentStep / renderStepNum));
            const [x, y, z] = currentCameraPosition;
            // console.log(currentCameraPosition);
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            // camera.lookAt(0, 0, -13000);
            camera.lookAt(...currentLookAtPosition);
            // render();
            this.mySetTimeout = setTimeout(renderNextScene, 30);
        };
        this.mySetTimeout = setTimeout(renderNextScene, 100);
        // renderNextScene();




        function init() {

            // container = document.createElement('div');
            // document.body.appendChild(container);

            container = refNode;

            camera = new THREE.PerspectiveCamera(45, nodeWidth / nodeHeight, 100, 13000000);
            const [x, y, z] = startCameraPosition;
            // const [x, y, z] = endCameraPosition;
            // console.log(startCameraPosition);
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            // camera.position.x = -150000;
            // camera.position.y = 100000;
            // camera.position.z = 45000;
            // camera.lookAt(0, 0, -13000);
            camera.lookAt(...startLookAtPostion);

            raycaster = new THREE.Raycaster();

            // scene

            scene = new THREE.Scene();

            var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
            scene.add(ambientLight);

            var pointLight = new THREE.PointLight(0xffffff, 0.8);
            camera.add(pointLight);
            scene.add(camera);

            // add axios
            // const materialX = new THREE.LineBasicMaterial({ color: 0xffffff });
            const geometryX = new THREE.Geometry();
            geometryX.vertices.push(new THREE.Vector3(0, 0, 0));
            geometryX.vertices.push(new THREE.Vector3(300000, 0, 0));
            // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
            // const lineX = new THREE.Line(geometryX, materialX);

            // const materialY = new THREE.LineBasicMaterial({ color: 0xff55ff });
            const geometryY = new THREE.Geometry();
            geometryY.vertices.push(new THREE.Vector3(0, 0, 0));
            geometryY.vertices.push(new THREE.Vector3(0, 300000, 0));
            // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
            // const lineY = new THREE.Line(geometryY, materialY);

            // const materialZ = new THREE.LineBasicMaterial({ color: 0xff0000 });
            const geometryZ = new THREE.Geometry();
            geometryZ.vertices.push(new THREE.Vector3(0, 0, 0));
            geometryZ.vertices.push(new THREE.Vector3(0, 0, 300000));
            // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
            // const lineZ = new THREE.Line(geometryZ, materialZ);

            // add model mid
            const sphereGeometry = new THREE.SphereGeometry(10000, 20, 20);
            const sphereMaterial = new THREE.MeshBasicMaterial({
                color: 0x7777ff,
                wireframe: true
            });
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
            sphere.position.set(...endLookAtPosition);


            // model

            var onProgress = function (xhr) {
                if (xhr.lengthComputable) {
                    var percentComplete = xhr.loaded / xhr.total * 100;
                    console.log(Math.round(percentComplete, 2) + '% downloaded');
                }
            };

            var onError = function (xhr) { };


            var mtlLoader = new MTLLoader();
            mtlLoader.setPath('obj/');
            mtlLoader.load('factory-1207.mtl', function (materials) {

                materials.preload();

                var objLoader = new OBJLoader();
                objLoader.setMaterials(materials);
                objLoader.setPath('obj/');
                objLoader.load('factory-1207.obj', function (object) {
                    // console.log(object);
                    scene.add(object);
                    console.log(scene);

                }, onProgress, onError);

            });

            renderer = new THREE.WebGLRenderer();
            // renderer = new THREE.WebGLRenderer({ alpha: true });
            // renderer.setClearColor(0xffffff, 0);
            renderer.setPixelRatio(window.devicePixelRatio);
            // renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setSize(nodeWidth, nodeHeight);
            container.appendChild(renderer.domElement);

            var controls = new OrbitControls(camera, renderer.domElement);
            // controls.addEventListener('change', render); // use only if there is no animation loop
            controls.addEventListener('change', (event) => {
                const { target } = event;
                const azimuthalAngle = target.getAzimuthalAngle();
                const degAngle = Math.floor(azimuthalAngle / Math.PI * 180);
                const { degAngle: prevDegAngle } = self.state;
                if (prevDegAngle !== degAngle && degAngle > 70) {
                    self.setState({ degAngle });
                };
                // console.log(degAngle);
            });
            // controls.minDistance = 10;
            // controls.maxDistance = 100;
            // controls.enableZoom = false;
            controls.enableKeys = false;//禁用键盘
            controls.target = new THREE.Vector3(...endLookAtPosition);

            // 上下旋转范围
            // controls.minPolarAngle = Math.PI * (50 / 180);
            // controls.maxPolarAngle = Math.PI * (50 / 180);
            controls.minPolarAngle = Math.PI * (30 / 180);
            controls.maxPolarAngle = Math.PI * (80 / 180);
            // 左右旋转范围
            // controls.minAzimuthAngle = -Math.PI * (180 / 180);
            // controls.maxAzimuthAngle = Math.PI * (180 / 180);
            controls.minAzimuthAngle = Math.PI * (60 / 180);
            controls.maxAzimuthAngle = Math.PI * (150 / 180);

            controls.update();

            document.addEventListener('mousemove', onDocumentMouseMove, false);

            //

            // window.addEventListener('resize', onWindowResize, false);

        }

        function onDocumentMouseMove(event) {
            event.preventDefault();

            mouse.x = ((event.clientX - self.nod.offsetLeft) / self.refNode.offsetWidth) * 2 - 1;
            mouse.y = - ((event.clientY - self.nod.offsetTop - 60) / self.refNode.offsetHeight) * 2 + 1;

        }

        this.onDocumentMouseMove = onDocumentMouseMove;

        function animate() {

            requestAnimationFrame(animate);
            render();

        }

        function render() {

            raycaster.setFromCamera(mouse, camera);
            if (scene.children[6]) {
                var intersects = raycaster.intersectObjects(scene.children[2].children);

                if (intersects.length > 0 && intersects[0].object.id !== 16) {

                    if (INTERSECTED !== intersects[0].object) {

                        if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

                        INTERSECTED = intersects[0].object;
                        INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
                        INTERSECTED.material = INTERSECTED.material.clone();
                        INTERSECTED.material.emissive.setHex(0xff0000);

                    }

                } else {

                    if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

                    INTERSECTED = null;

                };
            };


            renderer.render(scene, camera);

        }
    }

    refNodeFunc = refNode => {
        this.refNode = refNode;
    }

    render() {

        return (
            <div className={style["wrapper"]} ref={nod => this.nod = nod}>
                <div className="three_chart_block" ref={this.refNodeFunc} />
            </div>
        );
    }
}
