import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router';
import { Input, Modal, Space, Typography, message, Spin, Select, DatePicker } from 'antd';
import { post, patch } from '../../common/utils';
import { cusOpts } from 'components/TimeSelectorV3/utils.js'
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select
const { RangePicker } = DatePicker;

const Comp = (props) => {
    const { info } = props
    const [name, setName] = useState(() => info?.name ?? '');
    const [dTimeInfo, setDTimeInfo] = useState(() => {
        return info?.parseConfig?.defaultTimeInfo ?? null
    })

    const [loading, setLoading] = useState(false);
    const onCancel = useCallback((refresh = false) => {
        const { onCancel: propOnCancel } = props;
        if (loading) {
            message.info("创建中,请稍后！");
        } else {
            if (typeof propOnCancel === 'function') {
                propOnCancel(refresh);
            };
        };
    }, [props, loading]);
    const onOk = useCallback(() => {
        if (info) {
            const newConfig = Object.assign(
                info?.parseConfig ?? {},
                {
                    defaultTimeInfo: dTimeInfo
                }
            )

            if (typeof name === 'string' && name !== "") {
                setLoading(true);
                patch(process.env.REACT_APP_SERVER_PATH + `api/grid/screens/${info.id}`, {
                    name,
                    config: JSON.stringify(newConfig),
                })
                    .then((retData) => {
                        setLoading(false);
                        // console.log(retData);
                        if (!!retData.data && typeof retData.data.id === 'number') {
                            message.success("保存成功!");
                            onCancel(true)
                        } else {
                            message.error("保存布局信息失败,请重试!");
                        };
                    });
            } else {
                message.error("请输入名称!");
            };
        } else {
            if (typeof name === 'string' && name !== "") {
                setLoading(true);
                post(process.env.REACT_APP_SERVER_PATH + "api/grid/screens", {
                    name,
                })
                    .then((retData) => {
                        setLoading(false);
                        if (!!retData.data && typeof retData.data.id === 'number') {
                            //创建成功，id
                            props.history.push(`/PEIMSV2/DnDPage/${retData.data.id}`);
                        } else {
                            message.error("创建失败!");
                        };
                    });
            } else {
                message.error("请输入名称!");
            };
        }
    }, [props, name, info, dTimeInfo, onCancel]);

    const handleChange = useCallback(v => {
        // const selOpt = cusOpts.find(({ time_type }) => time_type === v) ?? null
        // console.log(selOpt)
        let nDTimeInfo = null
        if (v) {
            nDTimeInfo = { type: v }
            if (v === 'CUSTOM') {
                const curr = moment()
                nDTimeInfo = Object.assign(nDTimeInfo, {
                    timeBegin: moment(curr).format("YYYY-MM-DD 00:00:00"),
                    timeEnd: moment(curr).format('YYYY-MM-DD 23:59:59')
                })
            }
        }
        setDTimeInfo(nDTimeInfo)
    }, [])
    const rangeOnChange = useCallback(range => {
        setDTimeInfo(pDTimeInfo => {
            const [mTimeBegin, mTimeEnd] = range
            return Object.assign({}, pDTimeInfo, {
                timeBegin: moment(mTimeBegin).format("YYYY-MM-DD HH:mm:00"),
                timeEnd: moment(mTimeEnd).format("YYYY-MM-DD HH:mm:59")
            })
        })
    }, [])

    return (
        <Modal
            title={info ? "配置布局" : "创建新布局"}
            visible={true}
            onOk={onOk} onCancel={onCancel}
            {...loading ? { okButtonProps: { disabled: true }, cancelButtonProps: { disabled: true } } : {}}
            width={700}
        >
            <Spin spinning={loading}>
                <Space direction='vertical'>
                    <Space>
                        <Text>布局名称</Text>
                        <Input
                            style={{ width: 200 }}
                            placeholder="请输入名称" value={name} onChange={e => setName(e.target.value)}
                        />
                    </Space>
                    <Space>
                        <Text>默认时间</Text>
                        <Select
                            style={{ width: 200 }}
                            value={dTimeInfo?.type ?? null}
                            onChange={handleChange}
                            allowClear
                        >
                            {
                                (cusOpts ?? [])
                                    .map((opt, optIndex) => {
                                        const { name, time_type } = opt
                                        return <Option value={time_type} key={optIndex}>{name}</Option>
                                    })
                            }
                        </Select>
                        {
                            dTimeInfo?.type === 'CUSTOM' && (
                                <RangePicker
                                    style={{ width: 350 }}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    value={[dTimeInfo.timeBegin, dTimeInfo.timeEnd].map(v => moment(v))}
                                    onChange={rangeOnChange}
                                />
                            )
                        }
                    </Space>
                </Space>
            </Spin>
        </Modal>
    );
};

export default withRouter(Comp);
