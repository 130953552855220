import React, { useRef, useEffect, useMemo } from 'react';
import style from './index.module.scss';
import Wrapper from '../Wrapper';
import * as echarts from 'echarts~5';
import { useData } from './hooks';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';
import moment from 'moment';
import { significantNum } from 'common/utils';

const colors = ["#5CA9C8", "#8F6DC6", "#5E7BC4", "#5BAE8D", "#7489AE", "#FFBE0D", "#FB6060"];

const renderChart = (refNode, datas) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!datas) {
            instance.clear();
            return;
        };
        const xData = datas
            .map((item) => {
                let name = item?.name;
                if (typeof name === 'string') {
                    if (name.length < 3) return name;
                    return name.slice(0, name.length - 2) + '\n' + name.slice(name.length - 2);
                };
                return "--";
            });
        // const realData = [120, 200, 150, 80, 70, 110, 130];
        const realData = datas
            .map((item) => item.value);
        // const standardData = [125, 180, 145, 90, 80, 100, 120];
        const standardData = datas
            .map((item) => item.plan_value);
        const [parsedRealData, parsedStandardData] = [realData, standardData]
            .map((arr, cat_index) => {
                return arr
                    .map((v, index) => {
                        let color = "transparent";
                        if (!!colors && colors.length > 0) {
                            const ind = index % colors.length;
                            color = colors[ind];
                        };
                        if (cat_index === 1) {
                            color = "#979797";
                        };
                        return {
                            value: v,
                            itemStyle: {
                                color,
                            },
                        };
                    });
            })
        instance.setOption({
            grid: {
                containLabel: true,
                left: 0,
                top: 10,
                right: 10,
                bottom: 10,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [{ axisValueLabel, data: { value: value_1 }, marker: marker_1 }, { data: { value: value_2 }, marker: marker_2 }] = params;
                    const parsed_values = [value_1, value_2]
                        .map((v) => typeof v === 'number' ? significantNum(v, 4) : "--");
                    return `${axisValueLabel.replace("\n", "")}<br />${marker_2}${parsed_values[1]}<br />${marker_1}${parsed_values[0]}`;
                },
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    fontSize: 11,
                    color: '#AAAAAA',
                    interval: 0,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#AAAAAA',
                },
                max: (value) => {
                    return Math.ceil(value.max * 1.1);
                },
            },
            series: [
                {
                    data: parsedRealData,
                    type: 'bar',
                    barWidth: 12,
                    itemStyle: {
                        borderRadius: 2,
                    },
                    label: {
                        show: true,
                        position: 'top',
                        formatter: (params) => {
                            const { dataIndex } = params;
                            if (typeof standardData[dataIndex] !== 'number' || typeof realData[dataIndex] !== 'number' || standardData[dataIndex] === 0) return "";
                            if (standardData[dataIndex] > realData[dataIndex]) return "";
                            let v = realData[dataIndex] / standardData[dataIndex] * 100;
                            v = Math.floor(v);
                            return v + "%";
                        },
                        color: 'inherit',
                    },
                },
                {
                    type: 'line',
                    data: parsedStandardData,
                    symbol: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAECAYAAAD4Za0dAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAA9SURBVChTY/wPBLdv32b4+PEjw2ADjIyMDIKCggxKSkoMjLdu3fq/d+9eqNTgBF5eXgxMUPagB0Mk6pUYAEq0GqCIb41nAAAAAElFTkSuQmCC",
                    symbolSize: [19, 3],
                    lineStyle: {
                        color: 'transparent',
                    },
                    label: {
                        show: true,
                        position: 'top',
                        formatter: (params) => {
                            const { dataIndex } = params;
                            if (typeof standardData[dataIndex] !== 'number' || typeof realData[dataIndex] !== 'number' || standardData[dataIndex] === 0) return "";
                            if (standardData[dataIndex] <= realData[dataIndex]) return "";
                            let v = realData[dataIndex] / standardData[dataIndex] * 100;
                            v = Math.floor(v);
                            return v + "%";
                        },
                        color: 'inherit',
                    },
                },
            ],
        });
    };
};

const mergeTimeInfo = {
    time_type: "MONTH",
    range: 'month',
};

const Comp = (props) => {
    const { title, icon, timeInfo } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);

    const parsedTimeInfo = useMemo(() => {
        if (!!timeInfo) {
            return Object.assign({}, timeInfo, mergeTimeInfo);
        };
        return null;
    }, [timeInfo]);

    useEffect(() => {
        if (!!parsedTimeInfo) {
            setDataFunc.current(parsedTimeInfo);
        };
    }, [parsedTimeInfo]);

    const refNode = useRef(null);
    useEffect(() => {
        const datas = data?.prod_plan_status;
        // 需要把datas传进去，处理不存在的情况，在这里不便重置图像
        if (!!refNode.current) {
            renderChart(refNode.current, datas);
        };
    }, [data]);

    const onResize = () => {
        if (!!refNode.current) {
            const instance = echarts.getInstanceByDom(refNode.current);
            if (!!instance) instance.resize();
        };
    };

    const parsed_month_text = useMemo(() => {
        if (!!parsedTimeInfo) {
            const { time } = parsedTimeInfo;
            return moment(time).format("YYYY年MM月");
        };
        return "";
    }, [parsedTimeInfo]);

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={icon}
                title={title}
                watchDetail={false}
                subtitle={(
                    <div className={style["subtitle"]}>{parsed_month_text}</div>
                )}
            >
                <div className={style['content']}>
                    <div className="content">
                        <div className="title">生产计划完成情况</div>
                        <div className="chart_wrapper">
                            <div className="back">
                                <DomResize onResize={onResize} debounce_wait={20} />
                            </div>
                            <div className="font">
                                <div className="chart" ref={refNode}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
