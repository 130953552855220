import React from 'react';
import { Table } from 'antd';
import style from './ElecDetailBlock.module.scss';

export default class ElecDetailBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortBig2Small: true,
        };
    }

    render() {
        const { sortBig2Small } = this.state;
        const { tag2Value, ckw_results } = this.props;
        const dataSourceAll = ckw_results
            .map((item, index) => {
                let benchmarks_value = item.benchmarks_value;
                if (typeof benchmarks_value === 'number') {
                    benchmarks_value = Math.round(benchmarks_value);
                };
                return {
                    ...item,
                    benchmarks_value,
                    key: `${item.cur_value_tag}_${index}`,
                    curValue: '-',
                    diffValue: '-',
                    ...(tag2Value[item.cur_value_tag] !== undefined && typeof benchmarks_value === 'number' ? {
                        curValue: Math.round(tag2Value[item.cur_value_tag]),
                        diffValue: Math.round(tag2Value[item.cur_value_tag]) - benchmarks_value,
                    } : {}),
                };
            });
        const dataSourceHasValue = dataSourceAll
            .filter(({ diffValue }) => typeof diffValue === 'number')
            .sort(({ diffValue: diffValue_1 }, { diffValue: diffValue_2 }) => (sortBig2Small ? 1 : -1) * (diffValue_2 - diffValue_1));
        const dataSourceHasNoValue = dataSourceAll
            .filter(({ diffValue }) => typeof diffValue !== 'number');
        const dataSource = sortBig2Small ? dataSourceHasValue.concat(dataSourceHasNoValue) : dataSourceHasNoValue.concat(dataSourceHasValue);
        return (
            <div className={style["main_page_v2_elec_detail_block_wrapper"]}>
                <Table
                    columns={[
                        {
                            title: () => (
                                <div className="table_title">电耗参数</div>
                            ),
                            dataIndex: 'name',
                            align: 'center',
                            width: 180,
                        },
                        {
                            title: () => (
                                <div className="table_title">当前值</div>
                            ),
                            dataIndex: 'curValue',
                            align: 'center',
                            width: 60,
                        },
                        {
                            title: () => (
                                <div className="table_title">寻优值</div>
                            ),
                            dataIndex: 'benchmarks_value',
                            align: 'center',
                            width: 60,
                        },
                        {
                            title: () => (
                                <div className={'arrow ' + (sortBig2Small ? 'arrow_down' : 'arrow_up')} onClick={() => this.setState({ sortBig2Small: !sortBig2Small })} />
                            ),
                            dataIndex: 'diffValue',
                            align: 'center',
                        },
                    ]}
                    dataSource={dataSource}
                    size="small"
                    pagination={false}
                    rowClassName={({ diffValue }) => typeof diffValue === 'number' && diffValue > 0 ? 'row_red' : ''}
                    // loading={loading}
                    scroll={{ y: 700 }}
                />
            </div>
        );
    }
}
