const InitialState = {
    sys_id: null,
    systems: [],
    contentItemWidth: 0,
    layoutResults: [],
    layoutCusConfig: [],
    date: null,
    hisTag2Value: {},
    dataMetaList: [],
    status: [],
    events: [],
};

export {
    InitialState,
}