import React from 'react'
import './index.scss'
const ProductionTotal = (props) => {
    const {
        gasProductWeek,
        gasProductMonth,
        gasProductYear,
        ethaneProductWeek,
        ethaneProductMonth,
        ethaneProductYear,
        propaneProductWeek,
        propaneProductMonth,
        propaneProductYear,
        butaneProductWeek,
        butaneProductMonth,
        butaneProductYear,
        olefinProductWeek,
        olefinProductMonth,
        olefinProductYear,
    } = props;
    return (
        <div className="productionTotal">
            <div className="productionTotal-title">生产汇总</div>
            <ul className="productionTotal-list">
                <li>
                    <span className="name thead">产品</span>
                    <span className="thead">周累</span>
                    <span className="thead">月累</span>
                    <span className="thead">年累</span>
                </li>
                <li>
                    <span className="name">
                        <span className="energy">天然气</span>
                        <span className="type">万Nm³</span>
                    </span>
                    <span>{gasProductWeek}</span>
                    <span>{gasProductMonth}</span>
                    <span>{gasProductYear}</span>
                </li>
                <li>
                    <span className="name">
                        <span className="energy">乙烷</span>
                        <span className="type" style={{marginLeft:20}}>万Nm³</span>
                    </span>
                    <span>{ethaneProductWeek}</span>
                    <span>{ethaneProductMonth}</span>
                    <span>{ethaneProductYear}</span>
                </li>
                <li>
                    <span className="name">
                        <span className="energy">丙烷</span>
                        <span className="type" style={{marginLeft:22}}>t</span>
                    </span>
                    <span>{propaneProductWeek}</span>
                    <span>{propaneProductMonth}</span>
                    <span>{propaneProductYear}</span>
                </li>
                <li>
                    <span className="name">
                        <span className="energy">丁烷</span>
                        <span className="type" style={{marginLeft:22}}>t</span>
                    </span>
                    <span>{butaneProductWeek}</span>
                    <span>{butaneProductMonth}</span>
                    <span>{butaneProductYear}</span>
                </li>
                <li>
                    <span className="name">
                       <span className="energy">烯烃</span>
                       <span className="type" style={{marginLeft:22}}>t</span>
                    </span>
                    <span>{olefinProductWeek}</span>
                    <span>{olefinProductMonth}</span>
                    <span>{olefinProductYear}</span>
                </li>
            </ul>
        </div>
    )
}
export default ProductionTotal
