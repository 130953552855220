import React, { useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import List from './List';
import Trend from './Trend';
import Cost from './Cost';
import Compare from './Compare';

const Page = (props) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [timeInfo, setTimeInfo] = useState(null);
    return (
        <div className={style['wrapper']}>
            <Header timeInfo={timeInfo} setTimeInfo={setTimeInfo} />
            <div className="content_wrapper">
                <div className="inner_wrapper scroll_bar_restyle">
                    <div className="content">
                        <div className="left">
                            <List timeInfo={timeInfo} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
                        </div>
                        <div className="right">
                            <div className="top">
                                <Cost timeInfo={timeInfo} selectedProduct={selectedProduct} />
                            </div>
                            <div className="mid">
                                <Trend timeInfo={timeInfo} selectedProduct={selectedProduct} />
                            </div>
                            <div className="bottom">
                                <Compare timeInfo={timeInfo} selectedProduct={selectedProduct} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
