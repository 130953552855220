import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { Checkbox, Input, Select, Modal, message } from 'antd'
import {
  AudioOutlined,
  FileDoneOutlined,
  FieldTimeOutlined,
  SelectOutlined
} from '@ant-design/icons'
import './index.scss'
import { post, get, reqDelete } from '../../../common/utils'
import FirstTpl from '../../../components/CapsuleBoard/CapsuleTemplate/FirstTemplate'
import SecondTpl from '../../../components/CapsuleBoard/CapsuleTemplate/SecondTemplate'
import ThirdTpl from '../../../components/CapsuleBoard/CapsuleTemplate/ThirdTemplate'
import FourTpl from '../../../components/CapsuleBoard/CapsuleTemplate/FourTemplate'
import FiveTpl from '../../../components/CapsuleBoard/CapsuleTemplate/FiveTemplate'
import SixTpl from '../../../components/CapsuleBoard/CapsuleTemplate/SixTemplate'
import QrCodeModal from './QrCodeModal'
import ChartTpl from './ChartTemplate'
import Enclosure from './Enclosure'
import moment from 'moment'

const { Option } = Select
const baseUrl = process.env.REACT_APP_SERVER_PATH

const ForwardEditInfo = forwardRef((props, ref) => {
  const { templateId } = props
  return (
    <>
      {templateId === 1 && <FirstTpl {...props} refInstance={ref} />}
      {templateId === 2 && <SecondTpl {...props} refInstance={ref} />}
      {templateId === 3 && <ThirdTpl {...props} refInstance={ref} />}
      {templateId === 4 && <FourTpl {...props} refInstance={ref} />}
      {templateId === 5 && <FiveTpl {...props} refInstance={ref} />}
      {templateId === 6 && <SixTpl {...props} refInstance={ref} />}
    </>
  )
})

const ForwardAttach = forwardRef((props, ref) => {
  return <Enclosure {...props} refInstance={ref} />
})

const AddTemplate = props => {
  const { capsuleId, tpl_id, tpl_name, isEdit } = props
  const [templateId, setTemplateId] = useState(null) // 模版分类id
  const [tplId, setTplId] = useState(null) // 胶囊模版id
  const [isDisabled, setIsDisabled] = useState(false) // true 详情 false 新建/编辑
  const [capsuleDetailInfo, setCapsuleDetailInfo] = useState({}) // 详情对象
  const [mainTabIndex, setMainTabIndex] = useState(0)
  const [viceTabIndex, setViceTabIndex] = useState(0)
  const [templateList, setTemplateList] = useState([])
  const [tplItem, setTplItem] = useState([])

  const [pointList, setPointList] = useState([]) // 点位
  const [searchTplList, setSearchTplList] = useState([])
  const templateRef = useRef() // 模版
  const [isCodeVisible, changeCodeVisible] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [selectPointList, setSelectPointList] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [checkIndex, setCheckIndex] = useState(0)
  const [chartData, setChartData] = useState([]) // 胶囊详情折线图数据集合
  const [moreChartData, setMoreChartData] = useState([])
  const [moreTimeData, setMoreTimeData] = useState([])
  const [isShowScreen, setIsShowScreen] = useState(false)
  const [screenList, setScreenList] = useState([])
  const [actionName, setActionName] = useState('')
  // {id:jsonContent}
  const [cacheTamplateData, setCacheTamplateData] = useState({}) // 模版缓存信息
  const mainTabs = [
    { name: '最常用', component: <FileDoneOutlined /> },
    { name: '最近', component: <FieldTimeOutlined /> },
    { name: '分类选择', component: <SelectOutlined /> }
  ]
  const attachRef = useRef()
  useEffect(() => {
    console.log('attachRef:', attachRef)
    if (!capsuleId) {
      setTemplateId(4)
      getCommonlyTemplateSearch(true)
      typeof tpl_id !== 'number' && getDefaultTemplateId()
    }
    // 提前把模版分类准备好
    getTemplateList()
  }, [])

  useEffect(() => {
    capsuleId && getCapsuleDetail()
    // capsuleId && getPointChartData()
  }, [tplId])
  /** 获取胶囊详情 */
  const getCapsuleDetail = () => {
    setIsDisabled(isEdit ? false : true)
    const requestUrl = `${baseUrl}api/v1/event/capsule/singleCapsuleSearch`
    get(requestUrl, { capsuleId }).then(res => {
      if (res.data && res.data.error === 0) {
        const capsuleDetail = res.data.results || {}
        const name = `${capsuleDetail.orgName || ''} ${capsuleDetail.name || ''}`
        setActionName(name)
        const json_content = JSON.parse(capsuleDetail.json_content) || {}
        capsuleDetail.jsonContent = json_content
        setCapsuleDetailInfo(capsuleDetail || {})

        setTemplateId(json_content && json_content.templateId ? json_content.templateId : 4)
        setSelectPointList(
          json_content && json_content.selectPointList ? json_content.selectPointList : []
        )
        if (
          json_content &&
          json_content.selectPointList &&
          json_content.selectPointList.length > 0
        ) {
          const tplData = []
          json_content.selectPointList.forEach(item => {
            tplData.push({
              tag_name: item.tag_name,
              checked: true
            })
          })
          setScreenList(tplData)
        }

        getPointChartData(json_content.selectPointList || [], capsuleDetail.occur_time)
      } else {
        message.error('获取胶囊详情信息失败')
      }
    })
  }

  // 查找默认模板的id
  const getDefaultTemplateId = () => {
    const apiUrl = baseUrl + 'api/v1/event/capsule/defaultTemplateIdSearch'
    get(apiUrl).then(res => {
      if (res.data && res.data.error === 0) {
        setTplId(res.data.results)
      }
    })
  }

  /** 切换 最常用/最近/分类 */
  const changeMainTab = index => {
    setMainTabIndex(index)
    setTplItem([])
    setViceTabIndex(0)
    if (index === 0) {
      getCommonlyTemplateSearch(false)
    } else if (index === 1) {
      getLatelyTemplateSearch()
    } else if (index === 2) {
      getTemplateList(true)
    }
  }
  const changeViceTab = (index, tplId) => {
    setViceTabIndex(index)
    getItemTemplateSearch(tplId)
  }

  /** 获取最常用模版 */
  const getCommonlyTemplateSearch = isChangeClassify => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/commonlyTemplateSearch', {})
      .then(res => {
        if (res.data.error === 0) {
          setTplItem(res.data.results || [])
          if (isChangeClassify && tpl_id) {
            const tempList = (res.data.results || []).filter(tplItem => tplItem.id === tpl_id)
            if (tempList && tempList.length > 0) {
              handleSetTemplate(tpl_id, tpl_name, tempList[0].sn)
            }
          }
        } else if (isChangeClassify && tpl_id && tpl_name) {
          handleSetTemplate(tpl_id, tpl_name, '')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 获取最近使用的模版
  const getLatelyTemplateSearch = () => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/latelyTemplateSearch', {
      id: 1
    })
      .then(res => {
        setTplItem(res.data.results || [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  /** 获取模版分类 */
  const getTemplateList = (isRequestChildren = false) => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/templateClassificationSearch')
      .then(res => {
        setTemplateList(res.data.results)
        if (!capsuleId && isRequestChildren && res.data.results.length > 0) {
          getItemTemplateSearch(res.data.results[0].id)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 根据模版分类查询分类模版下的模版
  const getItemTemplateSearch = id => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/templatesByLabelSearch', {
      label: id
    })
      .then(res => {
        setTplItem(res.data.results)
      })
      .catch(err => {
        console.log(err)
      })
  }

  /** 切换分类标签对应模版 */
  const handleSetTemplate = (newTplId, name, sn) => {
    setTplId(newTplId)
    let newTemplateId = 4
    if (name === '筒体风机操作') {
      newTemplateId = 1
    } else if (name === '三次风闸板阀操作') {
      newTemplateId = 2
    } else if (name === '煤管操作') {
      newTemplateId = 3
    } else if (name === '熟料库空') {
      newTemplateId = 5
    } else if (name === '发电量') {
      newTemplateId = 6
    } else if (
      name === '超上限' ||
      name === '超下限' ||
      name === '设备检修' ||
      name === '设备更换' ||
      name === 'XXX异常' ||
      name === '游离钙异常' ||
      name === '立升重异常' ||
      name === '入窑细度异常' ||
      name === '生料秤调整' ||
      name === '头煤秤调整' ||
      name === '尾煤秤调整' ||
      name === '止料异常' ||
      name === '台时异常'
    ) {
      newTemplateId = 4
    }
    const templateInfo = templateRef.current ? templateRef.current.getTemplatInfo() : null
    cacheTamplateData[tplId] = templateInfo
    setCacheTamplateData(cacheTamplateData)

    setTemplateId(newTemplateId)
    setTimeout(() => {
      if (templateRef.current) {
        const tamplateTitle =
          cacheTamplateData[newTplId] && cacheTamplateData[newTplId].json_content.title
            ? cacheTamplateData[newTplId].json_content.title
            : name
        templateRef.current.changeTitle(tamplateTitle)
        templateRef.current.changeClassify(sn ? Number(sn) : null)
      }
    }, 0)
  }

  const resetClick = () => {
    console.log('重置')
    // 重置点位开始
    setSearchValue(null)
    setPointList([])
    setSelectPointList([])
    // 重置点位结束
  }

  //模糊查询点位
  const handleSearch = value => {
    post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
      keyword: value,
      page: 1,
      page_size: 10
    })
      .then(res => {
        setPointList(res.data.results)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const handleTemplateSearch = value => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/templateBlurrySearch', {
      title: value
    })
      .then(res => {
        setSearchTplList(res.data.results)
      })
      .catch(err => {
        console.log(err)
      })
  }

  /** 点击提交 */
  const handleOk = () => {
    if (templateRef.current.checkValidate()) {
      let templateInfo = templateRef.current.getTemplatInfo()
      templateInfo.json_content['selectPointList'] = selectPointList
      setConfirmLoading(true)
      if (capsuleId) {
        editTemplateInfo(templateInfo.json_content, templateInfo.date)
      } else {
        addTemplate(templateInfo.json_content, templateInfo.date)
      }
    }
  }

  /** 点击取消 */
  const handleCancel = e => {
    if (e && e.currentTarget.className === 'ant-btn' && capsuleId && !isEdit && !isDisabled) {
      // 查看详情时编辑并点击取消
      getCapsuleDetail()
    } else {
      const { onCancel: propOnCancel } = props
      if (typeof propOnCancel === 'function') {
        propOnCancel()
      }
    }
  }

  // 新建模版
  const addTemplate = (jsonContent, date) => {
    const params = {
      tplId,
      jsonContent,
      urgency: 0,
      date,
      uuid: attachRef.current.attachArray ? attachRef.current.attachArray : []
    }
    post(baseUrl + 'api/v1/event/capsule/saveCapsule', params)
      .then(res => {
        if (res.data && res.data.error === 0) {
          message.success('新建胶囊成功')
          const { onOk: propOnOk } = props
          if (typeof propOnOk === 'function') {
            propOnOk({ id: res.data.results, date, type: 'add' })
          }
        } else {
          message.error(res.data.msg)
          setConfirmLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        setConfirmLoading(false)
      })
  }

  /** 编辑胶囊 */
  const editTemplateInfo = (jsonContent, occurTime) => {
    const params = {
      id: capsuleId,
      jsonContent,
      occurTime
    }
    post(baseUrl + 'api/v1/event/capsule/modifyCapsule', params)
      .then(res => {
        if (res.data && res.data.error === 0) {
          message.success('编辑胶囊成功')
          const { onOk: propOnOk } = props
          if (typeof propOnOk === 'function') {
            propOnOk({ id: capsuleId, type: 'edit' })
          }
        } else {
          message.error(res.data.msg)
          setConfirmLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        setConfirmLoading(false)
      })
  }
  const showCodeModal = () => {
    changeCodeVisible(true)
  }

  const handleCancelCodeModal = () => {
    changeCodeVisible(false)
  }
  const editTemplate = () => {
    setIsDisabled(false)
  }
  /** 增加关联点位 */
  const selectPoint = pointId => {
    if (selectPointList.some(point => point.id === pointId)) return
    const selectPoint = pointList.filter(pointItem => pointItem.id === pointId)
    if (selectPoint && selectPoint.length > 0) {
      const tempList = selectPointList.concat(selectPoint)
      setSelectPointList(tempList)
    }
  }
  /** 移除选中点位 */
  const removePoint = pointIndex => {
    selectPointList.splice(pointIndex, 1)
    const tempList = selectPointList.concat()
    setSelectPointList(tempList)
  }
  const changeTemplateSearch = index => {
    handleSetTemplate(searchTplList[index].id, searchTplList[index].title, searchTplList[index].sn)
  }
  // 分开观察 合并观察
  const changeCheckChart = index => {
    setCheckIndex(index)
  }
  // 获取点位折线图
  const getPointChartData = (arr, time) => {
    if (arr.length < 1) {
      return
    }
    const tag_list = []
    arr.forEach(item => {
      tag_list.push({
        tag_name: item.tag_name,
        time_begin: moment(time).subtract(5, 'minute').format('YYYY-MM-DD HH:mm:00'),
        time_end: moment(time).add(5, 'minute').format('YYYY-MM-DD HH:mm:00')
      })
    })
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
      tag_list: tag_list
    })
      .then(res => {
        if (res.data.results.length > 0) {
          const data = res.data.results
          const lineData = []
          const moreData = []
          data.forEach(item => {
            const yData = []
            const xData = []
            item.data_list.forEach(_item => {
              xData.push(_item[1])
              yData.push(_item[0])
            })
            lineData.push({
              name: item.tag_name,
              xData: xData,
              yData: yData,
              max: xData.length > 0 ? Math.max.apply(null, xData) : '',
              min: xData.length > 0 ? Math.min.apply(null, xData) : ''
            })
            moreData.push({
              name: item.tag_name,
              type: 'line',
              data: xData
            })
          })
          setChartData(lineData)
          setMoreChartData(moreData)
          setMoreTimeData(lineData[0].yData ? lineData[0].yData : [])
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 分开观察input值
  const changeChartYaxis = (value, targetIndex, type) => {
    const tempData = chartData.concat()
    if (!chartData) return
    if (type === 'max') {
      tempData[targetIndex].max = Number(value)
    } else {
      tempData[targetIndex].min = Number(value)
    }
    return setChartData(tempData)
  }
  // 筛选按钮
  const changeScreen = () => {
    setIsShowScreen(!isShowScreen)
  }
  // change筛选
  const changeScreenList = targetIndex => {
    console.log('目标index' + targetIndex)
    const tempData = screenList.concat()
    const enterRefArray = []
    tempData[targetIndex].checked = !tempData[targetIndex].checked
    tempData.forEach(item => {
      if (item.checked === true) {
        enterRefArray.push(item)
      }
    })
    getPointChartData(enterRefArray)
    return setScreenList(tempData)
  }
  // 删除
  const deleteCapsule = () => {
    reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/capsuleDeleteByIdSearch', {
      capsuleId: [capsuleId]
    })
      .then(res => {
        console.log(res)
        if (res.data.msg === '删除成功') {
          message.success('删除成功')
          // 刷新事件看板
          if (typeof props.refreshEvents === 'function') props.refreshEvents()
          handleCancel()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  return (
    <>
      <Modal
        title={capsuleId ? (isDisabled ? '胶囊详情' : '编辑胶囊') : '新建胶囊'}
        maskClosable={isDisabled}
        okText="提交"
        wrapClassName={`capsuleModal ${isDisabled ? 'detail-modal' : ''}`}
        visible={true}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        width="976px"
      >
        <div
          className="del"
          onClick={deleteCapsule}
          style={{ display: capsuleId && isDisabled && templateId ? 'block' : 'none' }}
        ></div>
        <div
          className="qrcode"
          onClick={showCodeModal}
          style={{
            display: capsuleId && isDisabled && templateId ? 'block' : 'none',
            cursor: 'pointer'
          }}
        />
        <div
          className="edit"
          onClick={editTemplate}
          style={{
            display: capsuleId && isDisabled && templateId ? 'block' : 'none',
            cursor: 'pointer'
          }}
        />
        <div className="addCapsule" style={{ display: capsuleId ? 'none' : 'block' }}>
          <div className="addCapsule-tabs">
            <Select
              showSearch
              className="search-select"
              onSearch={handleTemplateSearch}
              style={{ width: 264 }}
              placeholder="输入文字搜索胶囊模版"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onChange={changeTemplateSearch}
            >
              {searchTplList &&
                searchTplList.map((item, index) => {
                  return (
                    <Option value={index} key={item.id}>
                      {item.title}
                    </Option>
                  )
                })}
            </Select>
            <div className="addCapsule-tabs-type">
              {mainTabs.map((item, index) => {
                return (
                  <div
                    className="first-tabs"
                    key={item.name}
                    onClick={() => changeMainTab(index)}
                    style={{ backgroundColor: mainTabIndex === index ? '#357CF7' : '#8EC1BE' }}
                  >
                    {item.component} {item.name}
                  </div>
                )
              })}
            </div>
            <div className="addCapsule-tabs-list">
              <ul className="viceTab" style={{ display: mainTabIndex === 2 ? 'flex' : 'none' }}>
                {templateList &&
                  templateList.map((item, index) => {
                    return (
                      <li key={item.id} onClick={() => changeViceTab(index, item.id)}>
                        <div className="viceTab-name">{item.title}</div>
                        <div
                          className="viceTab-line"
                          style={{
                            backgroundColor: viceTabIndex === index ? '#357CF7' : '#B4B4B4'
                          }}
                        ></div>
                      </li>
                    )
                  })}
              </ul>
            </div>
            <div className="addCapsule-tabs-list">
              {tplItem &&
                tplItem.map((item, index) => {
                  return (
                    item && (
                      <div
                        className="addCapsule-tabs-list-item"
                        key={item.id}
                        onClick={() => handleSetTemplate(item.id, item.title, item.sn)}
                      >
                        {item.title || '暂无'}
                      </div>
                    )
                  )
                })}
            </div>
          </div>
        </div>
        <ForwardEditInfo
          ref={templateRef}
          tplId={tplId}
          cacheTamplateData={cacheTamplateData}
          templateId={templateId}
          capsuleDetailInfo={capsuleDetailInfo}
          templateList={templateList}
          isDisabled={isDisabled}
          resetClick={resetClick}
          actionName={actionName}
        />
        {templateId && (
          <div className="tpl-point">
            <div className="point" style={{ whiteSpace: 'nowrap' }}>
              点位
            </div>
            <div>
              <div
                className="select-point"
                style={{ marginBottom: selectPointList.length > 0 ? '10px' : 0 }}
              >
                {selectPointList &&
                  selectPointList.map((item, pointIndex) => {
                    return (
                      <div
                        key={pointIndex}
                        style={{
                          fontSize: 14,
                          color: '#357CF7',
                          textDecorationLine: 'underline',
                          fontWeight: 'bold'
                        }}
                      >
                        {typeof item === 'object' ? `${item.tag_name} ${item.name}` : item}
                        {!isDisabled && (
                          <i className="remove-btn" onClick={() => removePoint(pointIndex)}></i>
                        )}
                      </div>
                    )
                  })}
              </div>
              {!isDisabled && (
                <div style={{ position: 'relative', width: 264 }}>
                  <Select
                    showSearch
                    className="search-select"
                    value={searchValue}
                    onSearch={handleSearch}
                    style={{ width: 264 }}
                    placeholder="搜索增加点位"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onChange={selectPoint}
                  >
                    {pointList &&
                      pointList.map((item, index) => {
                        return (
                          <Option value={item.id} key={item.id}>
                            {`${item.tag_name} ${item.name}`}
                          </Option>
                        )
                      })}
                  </Select>
                  <i className="search-icon" />
                </div>
              )}
            </div>
            {isDisabled && (!selectPointList || selectPointList.length === 0) && (
              <div style={{ lineHeight: '32px' }}>暂无点位</div>
            )}
          </div>
        )}
        {capsuleId && selectPointList.length > 0 && (
          <ChartTpl
            changeCheckChart={changeCheckChart}
            checkIndex={checkIndex}
            data={chartData}
            moreChartData={moreChartData}
            moreTimeData={moreTimeData}
            changeChartYaxis={changeChartYaxis}
            changeScreen={changeScreen}
            isShow={isShowScreen}
            screenList={screenList}
            changeScreenList={changeScreenList}
          />
        )}
        {/*{capsuleId && <Enclosure capsuleId={capsuleId} />}*/}
        {/* <Enclosure capsuleId={capsuleId} /> */}
        <ForwardAttach ref={attachRef} capsuleId={capsuleId} />
        <QrCodeModal
          isCodeVisible={isCodeVisible}
          templateId={templateId}
          capsuleDetailInfo={capsuleDetailInfo}
          handleCancelCodeModal={handleCancelCodeModal}
        />
      </Modal>
    </>
  )
}
export default AddTemplate
