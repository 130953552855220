import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import FormRender, { useForm } from 'form-render'
import { Button } from 'antd'
import TopBreadcrumb from 'components/TopBreadCrumb'
import FullScreenLoading from 'components/FullScreenLoading'
import style from './index.module.scss'
import { post, get, getCachedUserInfo } from 'common/utils'
const breadcrumbList = [{ name: '录入' }, { name: '录入' }]

const Com = props => {
  const { match } = props
  const { params } = match
  const { openType, id } = params
  const [loading, setLoading] = useState(false)
  const [detailInfo, setDetailInfo] = useState(null)
  const [schema, setSchema] = useState({})
  const [displayType, setDisplayType] = useState('column')
  const customForm = useForm() // form
  const userInfo = getCachedUserInfo() // 当前用户信息

  useEffect(() => {
    if (openType === 'add') {
      // 新建
      // TODO: setTestData 为测试代码
      setTestData()
    } else if (openType === 'edit' || openType === 'view') {
      // 编辑/详情
      getEventInfoById()
    }
  }, [])

  const getEventInfoById = () => {
    // TODO: setTestData 为测试代码
    setTestData()
  }

  /** 根据模版id获取schema配置 */
  const getSchemaByTplId = () => {}

  const resetForm = () => {
    if (openType === 'add') {
      customForm.resetFields()
    } else if (openType === 'edit') {
      getSchemaByTplId()
    }
  }

  /** 表单校验 */
  const checkValidate = () => {
    return true
  }

  /** 提交 */
  const onSubmitClick = async () => {
    if (checkValidate()) {
      // TODO:
      openType === 'add' ? addEvent() : editEvent()
    }
  }

  const addEvent = () => {}

  const editEvent = () => {}

  const setTestData = () => {
    setSchema({
      type: 'object',
      displayType: 'row',
      showDescIcon: true,
      properties: {
        date_Jukzkn: {
          title: '日期',
          required: true,
          disabled: false,
          readOnly: openType === 'view',
          hidden: false,
          format: 'date',
          type: 'string'
        },
        'number_vlunE4qUYej-': {
          title: '单价（元/吨）',
          description: '本月平均',
          required: true,
          min: 0,
          max: null,
          disabled: false,
          readOnly: openType === 'view',
          hidden: false,
          labelWidth: 150,
          type: 'number'
        }
      }
    })
  }

  return (
    <div className={style['eventForm']}>
      <TopBreadcrumb breadcrumbList={breadcrumbList} />
      <div className="content-wrap">
        <div className="title">录入</div>
        <div className="scroll-wrap">
          <div className="form-wrap">
            <div className="creator-info">
              <span>
                {detailInfo?.name || userInfo.name}{' '}
                <span>{detailInfo?.orgName ? `（${detailInfo.orgName}）` : ''}</span>
              </span>

              {openType !== 'view' && (
                <div className="reset-icon" onClick={resetForm}>
                  重置
                </div>
              )}
            </div>
            <FormRender form={customForm} schema={schema} displayType={displayType} />
          </div>
          {openType !== 'view' && (
            <div className="footer">
              <Button type="primary" loading={customForm.isSubmitting} onClick={onSubmitClick}>
                提交
              </Button>
            </div>
          )}
        </div>
        {loading && <FullScreenLoading loadingTip="数据加载中..." />}
      </div>
    </div>
  )
}

export default withRouter(Com)
