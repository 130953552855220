import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input, Select, Checkbox, message, DatePicker } from 'antd'
import moment from 'moment'
import { getCurrentTime } from '../../../../containers/DemoSystemV2/EventCapsules/utils'
const { TextArea } = Input
const { Search } = Input
const { Option } = Select
const resetImg = require('../../../../assets/reset.png')

const FiveTpl = props => {
  const {
    tplId,
    cacheTamplateData,
    capsuleDetailInfo,
    isDisabled,
    templateList,
    actionName
  } = props
  const [title, setTitle] = useState('') // 标题
  const [classify, setClassify] = useState(null) // 分类
  const [libraryList, setLibraryList] = useState([
    { name: '1', value: '', checkStatus: false },
    { name: '2', value: '', checkStatus: false },
    { name: '3', value: '', checkStatus: false }
  ])
  const [info, setInfo] = useState('')
  const [dateTime, setDateTime] = useState(moment(getCurrentTime()))

  useEffect(() => {
    // 新建时会产生缓存数据
    const cacheInfo = tplId && cacheTamplateData[tplId] ? cacheTamplateData[tplId] : null
    if (cacheInfo) {
      const jsonContent = cacheInfo.json_content || {}
      cacheInfo.date && setDateTime(moment(cacheInfo.date))
      if (Object.keys(jsonContent).length > 0) {
        jsonContent.classify ? setClassify(jsonContent.classify) : initDefaultClassify()
        setLibraryList(jsonContent.libraryList)
        setInfo(jsonContent.info)
      }
    }
  }, [])

  useEffect(() => {
    const jsonContent = capsuleDetailInfo.jsonContent || {}
    if (Object.keys(jsonContent).length > 0) {
      setDateTime(capsuleDetailInfo.occur_time ? moment(capsuleDetailInfo.occur_time) : '')
      setTitle(jsonContent.title)
      jsonContent.classify
        ? setClassify(jsonContent.classify)
        : capsuleDetailInfo.sn
        ? setClassify(Number(capsuleDetailInfo.sn))
        : initDefaultClassify()
      setLibraryList(jsonContent.libraryList)
      setInfo(jsonContent.info)
    } else {
      initDefaultClassify()
    }
  }, [templateList, isDisabled])
  useImperativeHandle(props.refInstance, () => ({
    checkValidate: checkValidate,
    getTemplatInfo: getTemplatInfo,
    changeTitle: changeTitle,
    changeClassify
  }))

  /** 设置初始值 */
  const initDefaultClassify = () => {
    if (templateList.length > 0) {
      setClassify(templateList[0].id)
    }
  }

  /** 修改标题 */
  const changeTitle = title => {
    setTitle(title)
  }

  /** 修改分类标签 */
  const changeClassify = id => {
    id && setClassify(id)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (!dateTime) {
      message.error('请选择事件发生时间')
      return false
    }
    if (!(title || '').trim()) {
      message.error('请输入标题')
      return false
    }
    return true
  }

  /** 切换分类 */
  const classifyChange = e => {
    setClassify(e)
  }

  const libraryInputChange = (newValue, libraryIndex) => {
    const tempList = libraryList.concat()
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (newValue || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return
    }
    tempList[libraryIndex].value = formatValue
    setLibraryList(tempList)
  }
  /** 字符串转数字 */
  const formatNumber = (newValue, libraryIndex) => {
    if (!newValue) return
    const tempList = libraryList.concat()
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    tempList[libraryIndex].value = formatValue
    setLibraryList(tempList)
  }

  const libraryCheckboxChange = (checkStatus, libraryIndex) => {
    const tempList = libraryList.concat()
    tempList[libraryIndex].checkStatus = checkStatus
    setLibraryList(tempList)
  }

  /** 重置 */
  const resetForm = () => {
    setDateTime('')
    setTitle('')
    initDefaultClassify()
    setLibraryList([
      { name: '1', value: '', checkStatus: false },
      { name: '2', value: '', checkStatus: false },
      { name: '3', value: '', checkStatus: false }
    ])
    setInfo('')
    const { resetClick: propReset } = props
    propReset()
  }

  const onSearch = value => console.log(value)

  /** 获取模版信息 */
  const getTemplatInfo = () => {
    return {
      date: dateTime.format('YYYY-MM-DD HH:mm:00'),
      json_content: {
        title,
        classify,
        libraryList,
        info,
        templateId: 5
      }
    }
  }
  const onChangeTime = (value, dateString) => {
    // setDateTime(moment(dateString).format('YYYY-MM-DD HH:mm:00'))
    setDateTime(value)
  }
  const onOkTime = value => {
    // setDateTime(moment(value).format('YYYY-MM-DD HH:mm:00'))
    setDateTime(value)
  }
  return (
    <div className="tpl">
      <div className="tpl-operator">
        <div className="tpl-operator-left">
          <span className="center">
            {isDisabled ? actionName : ''}
            {/*{JSON.parse(window.sessionStorage.getItem('USER_INFO')).name}*/}
          </span>
          <DatePicker
            showTime
            allowClear={false}
            value={dateTime}
            disabled={isDisabled}
            format="YYYY-MM-DD HH:mm"
            onChange={onChangeTime}
            onOk={onOkTime}
          />
        </div>
        <div className="tpl-operator-right">
          <Select value={classify} disabled={isDisabled} onChange={e => classifyChange(e)}>
            {templateList.map(item => {
              return (
                <Option key={item.title} value={item.id}>
                  {item.title}
                </Option>
              )
            })}
          </Select>
          {!isDisabled && (
            <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={resetForm}>
              <i className="reset-icon" />
              {/* <img src={resetImg} width="22" height="16" /> */}
              <span className="reset">重置</span>
            </div>
          )}
        </div>
      </div>
      <div className="tpl-input">
        <Input
          placeholder="请输入"
          maxLength={20}
          style={{ width: 312 }}
          disabled={isDisabled}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <ul className="fiveUl">
        <li>
          <div className="top" style={{ textAlign: 'left' }}>
            库
          </div>
          <div style={{ marginBottom: 16 }}>库空</div>
          <div>入库</div>
        </li>
        {libraryList.map((item, libraryIndex) => {
          return (
            <li key={libraryIndex}>
              <div className="top">{item.name}</div>
              <Input
                className="float-input"
                style={{ marginBottom: 16 }}
                disabled={isDisabled}
                value={item.value}
                onChange={e => libraryInputChange(e.target.value, libraryIndex)}
                onBlur={e => formatNumber(e.target.value, libraryIndex)}
              />
              <Checkbox
                style={{ marginLeft: 15 }}
                disabled={isDisabled}
                checked={item.checkStatus}
                onChange={e => libraryCheckboxChange(e.target.checked, libraryIndex)}
              />
            </li>
          )
        })}
      </ul>
      <div className="tpl-line"></div>
      <div className="tpl-info">说明</div>
      <div className="tpl-textarea">
        <TextArea
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={200}
          style={{ width: 880 }}
          disabled={isDisabled}
          value={info}
          onChange={e => setInfo(e.target.value)}
        />
      </div>
      <div className="tpl-line"></div>
      {/* <div className="tpl-point">
        <div className="point">点位</div>
        {!isDisabled && (
          <Search placeholder="搜索增加点位" onSearch={onSearch} style={{ width: 264 }} />
        )}
        {isDisabled && <></>}
      </div> */}
    </div>
  )
}
FiveTpl.defaultProps = {
  isDisabled: false
}
export default FiveTpl
