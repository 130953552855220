import React from 'react';
import { Checkbox } from 'antd';
import echarts from 'echarts';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/tooltip';

import style from './ScatterChart.module.scss';

export default class ScatterChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: props.record,
            optResult: props.optResult,
            dataList: props.dataList,
            topTimeList: props.optResult.result.kpi_result['top_state_datetime'].map(item => { return { value: item[0], checked: true } }),
            // topTimeList: props.optResult.kpi_result['top_state_datetime'],
        }
        this.option = {
            tooltip: {
                trigger: 'axis',
                // axisPointer: {
                //     type: 'shadow'
                // }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '3%',
                containLabel: true
            },
            xAxis: {
                scale: true,
                min: undefined,
                max: undefined,
            },
            yAxis: { scale: true },
            series: [{
                symbolSize: 5,
                data: [
                    // [0, 0],
                    // [0, 0],
                ],
                type: 'scatter',
                markArea: {
                    data: [
                        [{
                            name: '推荐区间',
                            xAxis: 0
                        }, {
                            xAxis: 0
                        }]
                    ],
                    itemStyle: {
                        // normal:{
                        color: ['rgba(238, 246, 225, 1)', 'rgba(238, 246, 225, 1)'],
                        borderType: 'dashed',
                        borderColor: '#008080',
                        borderWidth: 1,
                        opacity: 0.5
                        // }
                    }
                }
            }]
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.dataList.length == 0 && nextProps.dataList.length != 0) {
            this.setState({
                dataList: nextProps.dataList
            }, () => this.updateChart())
        }
    }

    updateChart = () => {
        let self = this;
        this.sChart.clear();
        let record = self.props.record
        let xkey = record.scatter_plotxy[0];
        let ykey = record.scatter_plotxy[1];
        this.option.series[0].data = this.state.dataList.map(item => {
            return [item[xkey], item[ykey]]
        })
        let areaItem = this.option.series[0].markArea.data[0];
        areaItem[0].xAxis = record.bound_low;
        areaItem[1].xAxis = record.bound_high;
        // this.option.xAxis.min = (value)=>{
        //     return (value.min < record.bound_low ? value.min : record.bound_low) - (record.bound_high - record.bound_low)*0.2;
        // }
        // this.option.xAxis.max = (value)=>{
        //     return (value.max > record.bound_high ? value.max : record.bound_high) + (record.bound_high - record.bound_low)*0.2;
        // }
        self.sChart && self.sChart.setOption(self.option);
    }

    componentDidMount = () => {
        let self = this;
        setTimeout(function () {
            // self.sChart = echarts.init(self.chartNode);
            // // self.fetchData();
            // // setTimeout(()=>{
            // self.sChart && self.sChart.setOption(self.option);
            // // }, 100);

            let props = self.props;
            if (!self.sChart) {
                self.sChart = echarts.init(self.chartNode);
            } else {
                self.sChart.clear();
            }
            // self.option.yAxis.data = props.dataList.map(item=>self.getNameByUserId(item.user_id));
            // self.option.series[0].data = props.dataList.map(item=>item.value);
            // console.log('option', props.name, self.option);
            self.sChart && self.sChart.setOption(self.option);
        }, 0);
    }

    onChangeTime = (checked, item) => {
        item.checked = checked;
        this.forceUpdate();
    }

    render() {
        let self = this;
        let r = this.state.record;

        return (
            <div
                className={style["PA_ScatterChart"]}
            >
                <div className="left-content">
                    <div className="info-bar">
                        <div>参数名称：<span style={{ fontWeight: 'bold' }}>{r.name}</span></div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div><img src={null} /> 当前值：<span>{r.curValue}</span></div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div><img src={null} /> 推荐区间：<span>{r.bound_low} ~ {r.bound_high}</span></div>
                    </div>
                    <div className="scatter-node" ref={(node) => { if (node) { self.chartNode = node } }}></div>
                </div>
                <div className="time-options">
                    {
                        this.state.topTimeList.map(item =>
                            <Checkbox
                                style={{ marginLeft: "0px" }}
                                checked={item.checked}
                                onChange={e => self.onChangeTime(e.target.checked, item)}
                            >{item.value}</Checkbox>
                        )
                    }
                </div>
            </div>
        );
    }
}
