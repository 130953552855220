import React, { Component } from 'react';
import style from './index.module.scss';
import { withRouter } from 'react-router';

class Comp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      path: null,
    };
  }

  componentDidMount() {
    this.updatePath();
  }

  componentDidUpdate() {
    this.updatePath();
  }

  updatePath = () => {
    const { path: prevPath } = this.state;
    const path = new URLSearchParams(this.props.location.search).get('path');
    if (path !== prevPath) {
      if (typeof path === 'string' && path !== "") {
        this.setState({ path });
      } else {
        this.setState({ path: null });
      };
    };
  };

  render() {
    const { path } = this.state;
    return (
      <div className={style['wrapper']}>
        {
          (typeof path === 'string' && path !== "") && (
            <iframe key={path} src={path} className="iframe" />
          )
        }
      </div>
    )
  }
}

export default withRouter(Comp);
