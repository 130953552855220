import { useEffect, useState } from 'react'
import { get } from 'common/utils'

const useShiftConfig = () => {
  const [shiftConfig, setShiftConfig] = useState(null)
  useEffect(() => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/report/shiftConfig', {}).then(retData => {
      // console.log('hook', retData);
      if (!!retData.data && retData.data.error === 0) {
        if (!!retData.data.results) {
          const nextShiftConfig = retData.data.results
          setShiftConfig(nextShiftConfig)
        }
      }
    })
  }, [])
  return [shiftConfig]
}

export default useShiftConfig
