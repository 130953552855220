import { Route, Switch } from 'react-router-dom'

import PowerPlan from 'containers/PowerPlan/index.js'
import DailyResult from 'containers/PowerPlan/DailyResult'
// import ElectricityStatistics from 'containers/PowerPlan/ElectricityStatistics.js'
import System from 'containers/System/System.js'
import MainPage from 'components/System/MainPage/index.js'
import ProduceTrend from 'components/System/ProduceTrend/ProduceTrend.js'
import ImproveChance from 'components/System/ImproveChance/ImproveChance.js'
import ProductElectricalAnalysis from 'components/System/ProductElectricalAnalysis/ProductElectricalAnalysis.js'
import MainElectricalParams from 'components/System/MainElectricalParams/MainElectricalParams.js'
import FactorSummary from 'components/System/FactorSummaryV2/index.js'
import ReferenceFactors from 'components/System/ReferenceFactors/index.js'
import FactorStatistics from 'components/System/FactorStatistics/FactorStatistics.js'
import ProduceAnalysis from 'components/System/ProduceAnalysis/ProduceAnalysis.js'
import SystemElectricalAnalysis from 'components/System/SystemElectricalAnalysis/SystemElectricalAnalysis.js'
import AlarmSetting from 'components/System/AlarmSetting/AlarmSetting.js'
import AlarmList from 'components/System/AlarmList'
import HistoryData from 'components/System/HistoryData/HistoryData'
import StatisticalReport from 'components/System/StatisticalReport/StatisticalReport.js'
import EditReport from 'components/System/StatisticalReport/EditReport'
import FactorList from 'components/Tools/FactorManager/FactorList.js'
// import Login from 'containers/Login/Login.js'
import PersonMng from 'components/System/PersonMng'
import RoleMngSetting from 'components/System/RoleMngSetting'
import PersonalInfo from 'components/System/PersonalInfo'
import DnDPage from 'containers/DnD'
import ScreenList from 'containers/ScreenList'
import BoardEdit from 'containers/BoardEdit'
import SchedulingManagement from 'containers/SchedulingManagement'

import AuthPage from 'containers/Auth/index.js'
import AppIdPage from 'containers/Auth/AppId.js'

// diagram related
import DiagramListMobile from 'containers/DiagramListMobile'
import DiagramList from 'containers/DiagramList/DiagramList.js'
import DiagramDisplay from 'containers/DiagramDisplay/index.js'
import DiagramDisplayMobile from 'containers/DiagramDisplayMobile'
import DiagramGen from 'containers/DiagramGen/DiagramGen.js'
import DiagramChart from 'containers/DiagramChart'
import DiagramChartMobile from 'containers/DiagramChartMobile'
import LnOilfield from 'containers/LnOilfield/index'
import NewScreen from 'containers/NewScreenWrapper'

// elec using analysis
import ElecAnalysis from 'containers/ElecAnalysis'

import Monitor from 'containers/Monitor'
import CapsuleBoard from 'containers/CapsuleBoard'
import CapsuleSummary from 'containers/CapsuleSummary'
import CapsuleTemplate from 'components/CapsuleBoard/CapsuleTemplate'
import ManagementSystem from 'containers/ManagementSystem'
import BigManagementSystem from 'containers/BigManagementSystem'
import SystemSetting from 'containers/SystemSetting'

import ExcelDemo from 'containers/ExcelDemo/ExcelDemo.js'
import OnlineDisk from 'containers/OnlineDisk/OnlineDisk.js'

// PEIMS system for yxsn merged in demo system
// demo system
import DemoSystem from 'containers/DemoSystem'
import Cockpit from 'containers/Cockpit'
import AutoLogin from 'containers/Auth/AutoLogin';
import Exam from 'containers/Exam'
import OnlineDiskWrapper from 'containers/OnlineDiskWrapper'
import ElecManagement from 'containers/DemoSystem/ElecManagement'
import AnalysisBoard from 'containers/DemoSystem/AnalysisBoard'
import Screen from 'containers/DemoSystem/Screen'

import Overview from 'containers/FactorRelated/Overview'
import Ingred from 'containers/DemoSystemV2/Ingred'
import FactorAnalysis from 'containers/DemoSystemV2/Analysis'
import ProductCost from 'containers/DemoSystemV2/ProductCost'
import FactorStatisticsV2 from 'containers/DemoSystemV2/FactorStatistics'
import FactorPredict from 'containers/DemoSystemV2/FactorPredict'
import User from 'containers/PersonMng'
import ProcessPage from 'containers/DemoSystemV2/Process'
import DeviceStatus from 'containers/DemoSystemV2/DeviceStatus'
import OperatingBoard from 'containers/FactorRelated/OperatingBoard'
import RuleList from 'containers/ExamV2/ExamRule/RuleList.js'
import ScreenShow from 'containers/DemoSystemV2/ScreenShow';

import BlankLayout from 'containers/BlankLayout';

import CommonLayout from 'containers/CommonLayout';

// 可缩放页面系统
import DemoSystemV3 from 'containers/DemoSystemV3';

// 辽河大屏
import BigDayPanel from 'containers/BigPage/DayPanel/index'
import BigElecAnalysis from 'containers/BigPage/ElecAnalysis'
import BigMonitor from 'containers/BigPage/Monitor'
import BigScreenPanel from 'containers/BigPage/ScreenPanel'

// mobile
import OverviewMobile from 'containers/FactorRelated/Overview/Mobile';

import PeimsRoutes from '../peims';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/AutoLogin" component={AutoLogin} />
            <Route exact path="/elestat/:factoryId" component={PowerPlan} />
            <Route exact path="/eleplan/:factoryId" component={PowerPlan} />
            <Route exact path="/PEIMSV2/eleresult/:factoryId" component={DailyResult} />
            <Route exact path="/auth" component={AuthPage} />
            <Route exact path="/AppAuth" component={AppIdPage} />
            <Route exact path="/FactorList" component={FactorList} />
            <Route exact path="/DnDPage/:id" component={DnDPage} />
            <Route exact path="/BoardEdit/:id" component={BoardEdit} />
            <Route exact path="/ScreenList" component={ScreenList} />
            <Route exact path="/DiagramGen/:id" component={DiagramGen} />
            <Route exact path="/DiagramDisplay/:id" component={DiagramDisplay} />
            <Route exact path="/DiagramDisplayMobile/:id" component={DiagramDisplayMobile} />
            <Route exact path="/DiagramChart/:id" component={DiagramChart} />
            <Route exact path="/DiagramChartMobile/:id" component={DiagramChartMobile} />
            <Route exact path="/DiagramList" component={DiagramList} />
            <Route exact path="/DiagramListMobile" component={DiagramListMobile} />
            <System path="/System" component={System}>
                <Switch>
                    <Route exact path="/System/MainPage" component={MainPage} />
                    <Route exact path="/System/ProduceTrend" component={ProduceTrend} />
                    <Route exact path="/System/ImproveChance" component={ImproveChance} />
                    <Route exact path="/System/ProductElectricalAnalysis" component={ProductElectricalAnalysis} />
                    <Route exact path="/System/MainElectricalParams" component={MainElectricalParams} />
                    <Route exact path="/System/FactorSummary" component={FactorSummary} />
                    <Route exact path="/System/ReferenceFactors/:id" component={ReferenceFactors} />
                    <Route exact path="/System/FactorStatistics" component={FactorStatistics} />
                    <Route exact path="/System/ProduceAnalysis" component={ProduceAnalysis} />
                    <Route exact path="/System/SystemElectricalAnalysis" component={SystemElectricalAnalysis} />
                    <Route exact path="/System/HistoryData" component={HistoryData} />
                    <Route exact path="/System/AlarmSetting" component={AlarmSetting} />
                    <Route exact path="/System/AlarmList" component={AlarmList} />
                    <Route exact path="/System/StatisticalReport" component={StatisticalReport} />
                    <Route exact path="/System/EditReport/:id" component={EditReport} />
                    <Route exact path="/System/PersonMng" component={PersonMng} />
                    <Route exact path="/System/RoleMngSetting" component={RoleMngSetting} />
                    <Route exact path="/System/SchedulingManagement" component={SchedulingManagement} />
                    <Route exact path="/System/PersonalInfo" component={PersonalInfo} />
                </Switch>
            </System>
            <ManagementSystem path="/LH" component={ManagementSystem}>
                <Switch>
                    <Route exact path="/LH/Monitor" component={Monitor} />
                    <Route exact path="/LH/ElecAnalysis" component={ElecAnalysis} />
                    <Route exact path="/LH/LnOilfield" component={LnOilfield} />
                    <Route exact path="/LH/DayPanel" component={BigDayPanel} />
                </Switch>
            </ManagementSystem>
            <BigManagementSystem path="/Big" component={BigManagementSystem}>
                <Switch>
                    <Route exact path="/Big/DayPanel" component={BigDayPanel} />
                    <Route exact path="/Big/ElecAnalysis" component={BigElecAnalysis} />
                    <Route exact path="/Big/Monitor" component={BigMonitor} />
                </Switch>
            </BigManagementSystem>
            <DemoSystem path="/PEIMS" component={DemoSystem}>
                <Switch>
                    <Route exact path="/PEIMS/Cockpit" component={Cockpit} />
                    <Route exact path="/PEIMS/SystemSetting" component={SystemSetting} />
                    <Route exact path="/PEIMS/CapsuleBoard" component={CapsuleBoard} />
                    <Route exact path="/PEIMS/CapsuleSummary" component={CapsuleSummary} />
                    <Route exact path="/PEIMS/Exam" component={Exam} />
                    <Route exact path="/PEIMS/OnlineDisk" component={OnlineDiskWrapper} />
                    <Route exact path="/PEIMS/ElecManagement" component={ElecManagement} />
                    <Route exact path="/PEIMS/AnalysisBoard" component={AnalysisBoard} />
                    <Route exact path="/PEIMS/Screen" component={Screen} />
                </Switch>
            </DemoSystem>
            <DemoSystemV3 path="/PEIMSV3" component={DemoSystemV3}>
                <Switch>
                    <Route exact path="/PEIMSV3/user" component={User} />
                </Switch>
            </DemoSystemV3>
            <BlankLayout path="/BlankLayout" component={BlankLayout}>
                <Switch>
                    <Route exact path="/BlankLayout/Overview" component={Overview} />
                    <Route exact path="/BlankLayout/OperatingBoard" component={OperatingBoard} />
                    <Route exact path="/BlankLayout/ScreenShow" component={ScreenShow} />
                    <Route exact path="/BlankLayout/ProcessPage" component={ProcessPage} />
                    <Route exact path="/BlankLayout/DeviceStatus" component={DeviceStatus} />
                    <Route exact path="/BlankLayout/FactorStatistics" component={FactorStatisticsV2} />
                    <Route exact path="/BlankLayout/FactorPredict" component={FactorPredict} />
                    <Route exact path="/BlankLayout/Analysis" component={FactorAnalysis} />
                    <Route exact path="/BlankLayout/Exam/RuleList" component={RuleList} />
                    <Route exact path="/BlankLayout/ProductCost" component={ProductCost} />
                </Switch>
            </BlankLayout>
            <CommonLayout path="/CommonLayout" component={BlankLayout}></CommonLayout>
            <Route exact path="/Ingred" component={Ingred} />
            <Route exact path="/NewScreen" component={NewScreen} />
            <Route exact path="/OverviewMobile" component={OverviewMobile} />
            <Route exact path="/BigScreenPanel" component={BigScreenPanel} />
            {/* // TODO 临时调试 胶囊模版路由 */}
            <Route exact path="/CapsuleTemplate" component={CapsuleTemplate} />
            <Route exact path="/ExcelDemo" component={ExcelDemo} />
            <Route exact path="/OnlineDisk" component={OnlineDisk} />
            <PeimsRoutes />
        </Switch>
    );
};

export default Routes;