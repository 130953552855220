import React from 'react'
import TopBreadcrumb from 'components/TopBreadCrumb'
import OnlineDiskWrapper from 'containers/OnlineDiskWrapper'

const AutoReport = () => {
  const breadcrumbList = [{ name: '报表' }, { name: '自动报表' }]
  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
    >
      <TopBreadcrumb breadcrumbList={breadcrumbList} />
      <OnlineDiskWrapper customStyle={{ padding: 0 }} />
    </div>
  )
}

export default AutoReport
