import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import echarts from 'echarts';
import Wrapper from '../../Common/Wrapper.js';

import ICON_2 from '../res/single_2.png';
import ICON_ROW_ICON from '../res/icon_1.png';
import { parseTimeRange } from '../utils.js';

const SecondBlock = (props) => {
    const { factorId, timeRange } = props;
    const titleStyle = { color: '#9FA0BA', fontSize: 12, lineHeight: '12px', height: 12 };
    const [failedNum, setFailedNum] = useState('-');
    const [avgValue, setAvgValue] = useState('-');
    const [refValue, setRefValue] = useState('-');
    const [loading, setLoading] = useState(false);
    const refNode = useRef(null);
    const fetDataFunc = (id) => {
        const { getHistoryDetails } = props;
        setLoading(true);
        getHistoryDetails(id)
            .then(({ isSuccess, result }) => {
                setLoading(false);
                if (isSuccess || true) {
                    // const { failed_num, avg_value, ref_value, freq_list } = result;
                    // hard code
                    const failed_num = 10;
                    const avg_value = 190;
                    const ref_value = 265;
                    const freq_list = new Array(10).fill(null).map((v, index) => {
                        return {
                            left: 180 + 10 * index,
                            right: 190 + 10 * index,
                            num: 25 - Math.pow(index - 5, 2),
                        };
                    });
                    setFailedNum(failed_num);
                    setAvgValue(Math.floor(avg_value * 10) / 10);
                    setRefValue(Math.floor(ref_value * 10) / 10);
                    // console.log(refNode);
                    if (!!refNode.current) {
                        // render chart
                        let instance = echarts.getInstanceByDom(refNode.current);
                        if (!instance) {
                            instance = echarts.init(refNode.current);
                        };
                        instance.clear();

                        const xAxisData = freq_list
                            .map(({ left }, index) => left);
                        const lineData = freq_list
                            .map(({ num }) => num);
                        let maxY = Math.ceil(Math.max(...lineData));
                        maxY *= 1.1;
                        if (maxY === 0) {
                            maxY = 10;
                        };
                        maxY = Math.ceil(maxY);

                        let ref_index = freq_list
                            .reduce((pV, cItem, cIndex) => {
                                const { left, right } = cItem;
                                if (pV !== null) {
                                    return pV;
                                };
                                if (left <= ref_value && right >= ref_value) {
                                    return cIndex;
                                };
                                return null;
                            }, null);

                        instance.setOption({
                            grid: {
                                // left: 32,
                                left: 42,
                                top: 23,
                                right: 16,
                                bottom: 45,
                            },
                            xAxis: {
                                type: 'category',
                                // show: false,
                                axisLine: {
                                    // show: false,
                                    lineStyle: {
                                        color: '#3A3F46',
                                    },
                                },
                                axisTick: {
                                    show: false,
                                },
                                axisLabel: {
                                    // show: false,
                                    fontSize: 11,
                                    color: '#9FA0BA',
                                },
                                data: xAxisData,
                                // boundaryGap: false,
                                boundaryGap: true,
                            },
                            yAxis: {
                                type: 'value',
                                // show: false,
                                axisLine: {
                                    show: false,
                                },
                                splitLine: {
                                    lineStyle: {
                                        color: '#3A3F46',
                                    },
                                },
                                axisTick: {
                                    show: false,
                                },
                                axisLabel: {
                                    // show: false,
                                    fontSize: 11,
                                    color: '#9FA0BA',
                                },
                                // min: yMin,
                                max: maxY,
                            },
                            series: [
                                {
                                    data: lineData,
                                    type: 'line',
                                    symbol: 'none',
                                    itemStyle: {
                                        color: 'rgba(74,155,135,1)',
                                    },
                                    areaStyle: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: 'rgba(74,155,135,1)' },
                                                { offset: 1, color: 'transparent' },
                                            ],
                                        ),
                                    },
                                    smooth: true,
                                },
                            ]
                                .concat(
                                    ref_index === null ? [] : [
                                        {
                                            data: lineData.map((v, index) => index === ref_index ? maxY : '-'),
                                            type: 'bar',
                                            itemStyle: {
                                                // color: '#FF6170',
                                                color: new echarts.graphic.LinearGradient(
                                                    0, 0, 0, 1,
                                                    []
                                                        .concat(
                                                            ...(
                                                                new Array(20)
                                                                    .fill(0)
                                                                    .map((_, index) => {
                                                                        const delta = 1 / 20;
                                                                        const color = index % 2 === 0 ? "#FF6170" : "transparent";
                                                                        return [
                                                                            { offset: index * delta, color },
                                                                            { offset: (index + 1) * delta, color },
                                                                        ];
                                                                    })
                                                            )
                                                        ),
                                                ),
                                            },
                                            barWidth: 2,
                                        },
                                    ]
                                ),
                        });
                    };
                };
            });
    };
    useEffect(() => {
        // console.log("in useEffect");
        fetDataFunc(factorId);
        // eslint-disable-next-line
    }, [factorId]);
    let timeRangeText = "-年-月-日- -年-月-日";
    if (timeRange !== null) {
        timeRangeText = parseTimeRange(timeRange)
            .map(time => time.format('YYYY年MM月DD日'))
            .join('- ');
    };
    return (
        <Wrapper icon={ICON_2} title="指标分析">
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="" src={ICON_ROW_ICON} width="2" height="16" />
                    <div style={{ marginLeft: 8, fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>统计时段</div>
                    <div style={{ marginLeft: 4, fontSize: 12, height: 12, lineHeight: '12px', color: '#9FA0BA' }}>【 {timeRangeText} 】</div>
                </div>
                <div style={{ marginTop: 17, padding: '0 8px 0 10px', display: 'flex', alignItems: 'center' }}>
                    <div style={titleStyle}>改善机会</div>
                    <div style={{ marginLeft: 8, color: '#44F3DA', fontSize: 14, height: 14, lineHeight: '14px' }}>{failedNum}</div>
                    <div style={{ marginLeft: 2, fontSize: 12, height: 12, lineHeight: '12px', color: '#9FA0BA' }}>次</div>
                    <div style={{ marginLeft: 'auto', width: 14, height: 2, backgroundColor: '#4A9B87' }} />
                    <div style={Object.assign({ marginLeft: 4 }, titleStyle)}>指标均值</div>
                    <div style={{ marginLeft: 4, color: '#FFFFFF', fontSize: 14, height: 14, lineHeight: '14px' }}>{avgValue}</div>
                    <div style={{ marginLeft: 'auto', width: 17, height: 2, backgroundImage: 'linear-gradient(to right, rgba(255,97,112,1) 0%, rgba(255,97,112,1) 70%, transparent 70%)', backgroundRepeat: 'repeat-x', backgroundSize: '7px 2px' }} />
                    <div style={Object.assign({ marginLeft: 4 }, titleStyle)}>基准值</div>
                    <div style={{ marginLeft: 4, color: '#FFFFFF', fontSize: 14, height: 14, lineHeight: '14px' }}>{refValue}</div>
                </div>
                <div style={{ marginTop: 13, backgroundColor: '#191F27' }}>
                    <div ref={refNode} style={{ height: 189 }} />
                </div>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
        </Wrapper>
    );
};

export default SecondBlock;
