import React from 'react';
import { Input, Modal, message } from 'antd';
import { post } from 'common/utils.js';
const { TextArea } = Input;
export default class RuleModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            // mode: 'read',
            name: '',
            remark: '',
            _id: null,
        }
    }

    componentWillMount() {
    }
    componentWillReceiveProps({ visible, rule }) {
        if (visible) {
            const { _id = null, name = "", remark = "" } = rule;
            this.setState({ _id, name, remark });
        }
    }
    saveData = () => {
        const { name, remark, _id } = this.state;
        const params = {
            name,
            remark,
        };
        if (_id) params._id = _id;
        post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/' + `${_id ? 'update' : 'create'}`, params).then(retData => {
            console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                const { onClose = () => { } } = this.props;
                onClose(true);
            } else {
                message.error('保存失败');
            }
        });
    }
    modalOnOk = () => {
        // if (this.state.mode == 'read') {
        //     this.setState({ mode: 'edit' });
        // } else {
        //     this.saveData();
        // }
        this.saveData();
    }
    modalOnCancel = () => {
        const { onClose = () => { } } = this.props;
        // this.setState({ mode: 'read' });
        onClose();
    }

    render() {
        let self = this;
        const { visible = false } = this.props;
        const { name, remark, _id } = this.state;
        return (
            <Modal className='EventModal'
                visible={visible}
                width={600}
                title={`${_id ? '修改' : '添加'}规则`}
                confirmLoading={this.state.loading}
                okText={'保存'}
                onOk={this.modalOnOk}
                onCancel={this.modalOnCancel}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexShrink: 0, width: 100, textAlign: 'right' }}>规则名称：</div>
                    <Input value={name} onChange={e => this.setState({ name: e.target.value })} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                    <div style={{ flexShrink: 0, width: 100, textAlign: 'right' }}>备注：</div>
                    <TextArea rows={4} value={remark} onChange={e => this.setState({ remark: e.target.value })} />
                </div>

            </Modal>
        );
    }
}
