import Page from './MainPage.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/DemoSystemV2/actions';

const setDarkMode = (value) => {
    return (dispatch, getState) => {
        dispatch(setValues({ useDarkMode: value }));
    };
};

const mapStateToProps = state => {
    const { mainPage } = state;
    return {
        ...mainPage,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addDarkMode: () => dispatch(setDarkMode(true)),
        rmDarkMode: () => dispatch(setDarkMode(false)),
    };
}

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;