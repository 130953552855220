import React from 'react';
import { message, Spin, Button, Upload, Modal, Popconfirm } from 'antd';
import { RollbackOutlined, CodeOutlined, CloseOutlined, ExclamationCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import ReactModal from 'react-modal-resizable-draggable';

import { get, post, patch, getQueryString } from '../../common/utils.js';
import ExcelEditor from '../../components/ExcelEditor/ExcelEditor.js';
import ScriptList from './ScriptList.js';
import FileItem from './FileItem.js';
import './ExcelWindow.scss';
// import 'antd/dist/antd.less';

export default class ExcelWindow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileId: parseInt(getQueryString('file_id')),
            fileKey: null,
            folderId: null,
            loading: true,
            modalVisible: false,
            fileReadOnly: false,
            showConvertModal: false,
            convertModalLoading: false,
        };
    }

    componentDidMount() {
        this.editExcel();
        // this.fetchScriptDetails();
    }

    saveExcel = (callback)=>{
        let self = this;
        if(this.excel_inst_save){
            this.setState({loading: true});
            this.excel_inst_save().then(()=>{
                console.log('excel saved');
                post(process.env.REACT_APP_SERVER_PATH + 'api/pan/save_doc', {
                    file_key: self.state.fileKey,
                    file_id: self.state.fileId,
                    keep_tmp: true,
                }).then(retData => {
                    self.setState({loading: false});
                    if (retData.data && retData.data.errcode == 0) {
                        message.success('已保存', 0.5);
                        callback && callback(true);
                    } else {
                        message.error('保存失败');
                        callback && callback(false);
                    };
                });
            });
        }
    }

    editExcel = ()=>{
        let self = this;
        this.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pan/copy_doc_to_tmp', {
            file_id: this.state.fileId
        }).then(retData => {
            this.setState({loading: false});
            let d = retData.data;
            if (d && d.errcode === 0) {
                console.log(d.filename.substr(d.filename.length - 5));
                self.setState({
                    fileKey: d.file_key,
                    fileName: d.filename,
                    fileReadOnly: (d.filename.substr(d.filename.length - 5) === '.xlsx' ? false:true),
                    folderId: d.folder_id,
                })
            } else {
            };
        });
    }

    convertExcel = ()=>{
        let self = this;
        self.setState({convertModalLoading: true, loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pan/convert_file', {
            file_id: this.state.fileId,
            src_type: 'xls',
            dst_type: 'xlsx',
        }).then(retData => {
            self.setState({convertModalLoading: false, loading: false});
            let d = retData.data;
            if (d && d.errcode === 0) {
                message.success('转换成功');
                self.setState({
                    showConvertModal: false,
                    fileKey: null
                },()=>self.editExcel());
            }else if (d && d.errcode && d.errcode > 500 && d.errcode < 600) {
                message.error(d.msg);
            } else {
                message.error('转换失败');
            };
        });
    }

    render() {
        let self = this;
        const { fileName, fileId, fileKey, loading, modalVisible, scriptContent, executing, folderId, fileReadOnly, showConvertModal, convertModalLoading} = this.state;

        return (
            <div className="ExcelWindow">
                <div className="toolBar">
                    <div className="leftTools">
                        <Button
                            style={{color: "#666"}}
                            icon={<RollbackOutlined />}
                            className="backBtn"
                            onClick={()=>{
                            window.close();
                        }}>返回</Button>
                        {
                            fileKey && fileReadOnly &&
                            <Popconfirm
                                title={<div>编辑Excel需要先从 <span>xls</span> 格式文件<br/>转换为 <span>xlsx</span> 格式，确认转换吗？</div>}
                                onConfirm={()=>this.convertExcel()}
                                // onCancel={cancel}
                                okText="转换"
                                cancelText="不转换"
                            >
                                <div className="readonlyHintWrapper">
                                    <div className='readonlyHint'>只读模式</div>
                                    <QuestionCircleOutlined className="qMarkIcon" />
                                </div>
                            </Popconfirm>
                        }
                    </div>
                    <div className="rightTools">
                        {
                            fileKey &&
                            <Button
                                className="editScriptBtn"
                                type="default"
                                icon={<CodeOutlined />}
                                onClick={()=>this.setState({modalVisible: true})}
                                style={{color: 'green', borderColor: 'green', marginRight: '10px'}}
                            >编辑脚本</Button>
                        }
                        {
                            fileKey && !fileReadOnly &&
                            <Button className="saveBtn" type="primary" onClick={_=>this.saveExcel()} loading={loading}>保存</Button>
                        }
                        {
                            fileKey && fileReadOnly &&
                            <Button className="covertBtn" type="primary" onClick={_=>this.setState({showConvertModal: true})} loading={loading}>编辑Excel</Button>
                        }
                    </div>
                </div>
                <div className="downContent">
                    <Spin className="downSpin" spinning={loading}>
                    {
                        fileKey &&
                        <ExcelEditor
                            key={fileKey}
                            file_key={fileKey}
                            title={fileName}
                            width={'100%'}
                            height={'100%'}
                            readonly={fileReadOnly}
                            getSaveFunc={_func=>this.excel_inst_save=_func}
                        />
                    }
                    </Spin>
                </div>
                <ReactModal
                    initWidth={800} initHeight={600} 
                    onFocus={() => console.log("Modal is clicked")}
                    className="ScriptModal"
                    disableKeystroke={true}
                    onRequestClose={_=>{
                        self.setState({modalVisible: false})
                    }}
                    isOpen={modalVisible}
                >
                    <div className="scriptModalContent">
                        <div className="modalTitle">脚本编辑器</div>
                        <div className='closeBtn' onClick={_=>self.setState({modalVisible: false})}><CloseOutlined /></div>
                        {folderId &&
                            <ScriptList
                                key={"scripts-"+folderId}
                                folder_id={folderId}
                                target_file_id={fileId}
                                beforeExec={_=>new Promise((resolve, reject)=>{
                                    Modal.confirm({
                                        title: '运行前需要先保存Excel文档，现在保存吗？',
                                        icon: <ExclamationCircleOutlined />,
                                        okText: '保存',
                                        cancelText: '取消',
                                        onOk: () => self.saveExcel(saveStatus=>{
                                            if(saveStatus === true){
                                                resolve();
                                            }else{
                                                reject();
                                            }
                                        }),
                                        onCancel: ()=>reject()
                                    });
                                })}
                                onExecFinish={_=>{
                                    self.setState({ //执行成功后重新加载Excel
                                        fileKey: null
                                    },()=>self.editExcel());
                                }}
                            />
                        }
                    </div>
                </ReactModal>
                <Modal
                    key="convert-modal"
                    className="convertModal"
                    visible={showConvertModal}
                    onOk={_=>this.convertExcel()}
                    onCancel={_=>this.setState({showConvertModal: false})}
                    confirmLoading={convertModalLoading}
                >
                    需要先将此 <span>xls</span> 格式文件转换为 <span>xlsx</span> 格式，确认转换吗？
                </Modal>
            </div>
        );
    }
}

                    // <Button
                    //     className="runScriptBtn"
                    //     style={{color: 'green', marginRight: '10px'}}
                    //     type="default"
                    //     icon={<PlayCircleOutlined />}
                    //     loading={executing}
                    //     onClick={_=>this.execScript()}
                    // >运行</Button>
                    // <Button className="saveScriptBtn" onClick={()=>this.saveScript()} loading={loading} type="primary">保存</Button>
                    // <div className="scriptModalContent">
                    //     <ScriptList
                    //         key={"scripts-"+selectedFolderId}
                    //         folder_id={selectedFolderId}
                    //     />
                    //     <Editor
                    //         className="scriptEditor"
                    //         value={scriptContent}
                    //         onValueChange={(code) => this.setState({scriptContent: code})}
                    //         highlight={(code) => highlight(code, languages.js)}
                    //         padding={10}
                    //         style={{
                    //             fontFamily: '"Fira code", "Fira Mono", monospace',
                    //             fontSize: 12,
                    //             overflowY: 'auto'
                    //         }}
                    //     />
                    // </div>