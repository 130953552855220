import * as echarts from 'echarts~5';

const renderLineChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (!data) {
            instance.clear();
            return;
        };

        const xData = data.map((item, index) => index);

        instance.setOption({
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                containLabel: true,
                left: 24,
                top: 12,
                right: 26,
                bottom: 20,
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            series: [
                Object.assign(
                    {},
                    {
                        data: data,
                        type: 'bar',
                        barWidth: 12,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: "rgba(251, 152, 49, 1)" },
                                    { offset: 1, color: 'rgba(251, 152, 49, 0.01)' },
                                ],
                            ),
                            borderRadius: 16,
                        },
                    },
                ),
                Object.assign(
                    {},
                    {
                        data: data,
                        type: 'bar',
                        barWidth: 12,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: "rgba(15, 229, 240, 1)" },
                                    { offset: 1, color: 'rgba(15, 229, 240, 0.01)' },
                                ],
                            ),
                            borderRadius: [16, 16, 0, 0],
                        },
                    },
                ),
            ]
        });
    };
};

const mockList = [
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
];

export {
    renderLineChart,
    mockList,
};
