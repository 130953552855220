import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import style from './index.module.scss'
import Header from './Header'
import Menu from 'components/Menu'

const menuList = [
  {
    menuName: '驾驶面板',
    menuPath: '/Cockpit'
  },
  {
    menuName: '指标分析',
    menuPath: '/AnalysisBoard'
  },
  {
    menuName: '事件胶囊',
    menuPath: '/CapsuleBoard'
  },
  {
    menuName: '事件汇总',
    menuPath: '/CapsuleSummary'
  },
  {
    menuName: '自由报表',
    menuPath: '/OnlineDisk',
    supportMinMode: true
  },
  {
    menuName: '运行考核',
    menuPath: '/Exam'
  },
  {
    menuName: '用电管理',
    menuPath: '/ElecManagement'
  },
  {
    menuName: '大屏展示',
    // inheritPath: false, // 默认继承父级path
    menuPath: '/Screen'
  },
  {
    menuName: '系统设置',
    menuPath: '/SystemSetting'
  }
]

const envPathStr = process.env.REACT_APP_ROUTER_PATH
let envPathList = null
if (typeof envPathStr === 'string' && envPathStr !== '') {
  envPathList = envPathStr.split(',')
}

const minStyle = {
  minHeight: 600,
  minWidth: 1200
}

const Page = props => {
  const [conditionedMenus, setConditionedMenus] = useState(null)
  useEffect(() => {
    let nextMenus = null
    if (!!envPathList) {
      // 支持外部排序 && 筛选
      nextMenus = envPathList
        .map(path => menuList.filter(({ menuPath }) => menuPath === path)[0] || null)
        .filter(item => !!item)
    } else {
      nextMenus = menuList
    }
    let nextConditionedMenus = null
    if (!!nextMenus && typeof props.path === 'string') {
      nextConditionedMenus = nextMenus.map(item => {
        return Object.assign({}, item, {
          menuPath: item.inheritPath === false ? '' : props.path + item.menuPath
        })
      })
    }
    setConditionedMenus(nextConditionedMenus)
  }, [props.path])

  const minModePage = useMemo(() => {
    if (!!conditionedMenus) {
      return conditionedMenus.filter(({ supportMinMode }) => supportMinMode === true)
    }
    return null
  }, [conditionedMenus])
  const supportMinMode = useMemo(() => {
    if (!!props && !!minModePage) {
      if (!!props.location) {
        if (typeof props.location.pathname === 'string') {
          if (props.location.pathname !== '') {
            return (
              minModePage.map(({ menuPath }) => menuPath).indexOf(props.location.pathname) !== -1
            )
          }
        }
      }
    }
    return false
  }, [props, minModePage])

  return (
    <div className={style['wrapper']}>
      <div className="scroll_wrapper">
        <div className="content" style={supportMinMode ? minStyle : {}}>
          <Header />
          <div className="content">{props.children}</div>
        </div>
      </div>
      {!!conditionedMenus && (
        <Menu match={props.match} history={props.history} menuList={conditionedMenus} />
      )}
    </div>
  )
}

export default withRouter(Page)
