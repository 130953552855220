import React from 'react'
import { useEffect, useRef } from 'react'
import echarts from 'echarts'

const LineChart = props => {
  const { lineData, timeData, yMax, yMin } = props
  useEffect(() => {
    renderChart()
  }, [lineData, timeData, yMax, yMin])
  const chartNode = useRef(null)
  const renderChart = () => {
    if (!!chartNode.current) {
      let instance = echarts.getInstanceByDom(chartNode.current)
      if (!instance) {
        instance = echarts.init(chartNode.current)
      }
      instance.setOption({
        animation: true,
        tooltip: {
          trigger: 'axis',
          symbol: 'none'
        },
        grid: {
          left: 0,
          top: 5,
          right: 5,
          bottom: 5
        },
        xAxis: {
          show: false,
          splitLine: {
            show: false
          },
          type: 'category',
          data: timeData
        },
        yAxis: {
          show: false,
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            //y轴刻度线
            show: false
          },
          axisLine: {
            //y轴
            show: false
          },
          min: yMin,
          max: yMax
        },
        series: [
          {
            data: lineData,
            type: 'line',
            lineStyle: {
              color: '#357CF7',
              width: 1
            },
            symbol: 'none'
          }
        ]
      })
    }
  }
  return (
    <div>
      <div ref={chartNode} style={{ width: 478, height: 88 }} />
    </div>
  )
}
export default LineChart
