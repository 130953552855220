import React from 'react'
import style from './RuleBar.module.scss';

export default class RuleBar extends React.Component {
    constructor(props) {
        super(props);
        let d = props.data;
        this.state = {
            // score_1: 100,
            // score_2: 50,
            // score_3: -100,
            // color_1: d.color_1,
            // color_2: d.color_2,
            // color_3: d.color_3,
            // limit_1: (d.limit_1 === '' || d.limit_1 === null)?'-∞':d.limit_1,
            // limit_2: (d.limit_2 === '' || d.limit_2 === null)?'':d.limit_2,
            // limit_3: (d.limit_3 === '' || d.limit_3 === null)?'':d.limit_3,
            // limit_4: (d.limit_4 === '' || d.limit_4 === null)?'+∞':d.limit_4,
        }
    }

    componentDidMount() {
        // this.get_datas();
    }

    render() {
        let { score_1, score_2, score_3,
              color_1, color_2, color_3,
              limit_1, limit_2, limit_3, limit_4 } = this.props.data;
        limit_1 = (limit_1 === '' || limit_1 === null)?'-∞':limit_1;
        limit_2 = (limit_2 === '' || limit_2 === null)?'':limit_2;
        limit_3 = (limit_3 === '' || limit_3 === null)?'':limit_3;
        limit_4 = (limit_4 === '' || limit_4 === null)?'+∞':limit_4;
        if(score_1 === undefined || score_1 === null || score_1 === ''){
            score_1 = '';
        }
        if(score_2 === undefined || score_2 === null || score_2 === ''){
            score_2 = '';
        }
        if(score_3 === undefined || score_3 === null || score_3 === ''){
            score_3 = '';
        }

        return (
            <div
                className={style["wrapper"]}
                style={this.props.style?this.props.style:{}}
            >
                <div className="color_line">
                    <div className='color_1' style={{backgroundColor: color_1}}>{score_1>0?'+':''}{score_1}{score_1==''?'':'分'}</div>
                    <div className='color_2' style={{backgroundColor: color_2}}>{score_2>0?'+':''}{score_2}{score_2==''?'':'分'}</div>
                    <div className='color_3' style={{backgroundColor: color_3}}>{score_3>0?'+':''}{score_3}{score_3==''?'':'分'}</div>
                </div>
                <div className="interval_line">
                    <div className="left_limit">{limit_1}</div>
                    <div className="center_limits">
                        <div>{limit_2}</div>
                        <div>{limit_3}</div>
                    </div>
                    <div className="right_limit">{limit_4}</div>
                </div>
            </div>
        )
    }
}
