import { useEffect, useState, useMemo, useRef } from 'react';
import { pageSizeOptions } from '../utils';

// 这里保证存在
const defaultPageSize = pageSizeOptions[0];

const CompList = [
    {
        status: 0,
        name: '已达标',
    },
    {
        status: 1,
        name: '未达标',
    },
];

const AlarmList = [
    {
        status: 0,
        name: '正常',
    },
    {
        status: 1,
        name: '告警',
    },
];

const getNextInfo = (list, prevSelected, key) => {
    let nextInfo = null;
    if (!!list) {
        const parsed_list = list
            .map((item, index) => Object.assign({}, item, { key: index + "" }));
        nextInfo = Object.assign({}, nextInfo ?? {}, {
            selected: null,
            list: parsed_list,
        });
        if (!!prevSelected) {
            // 上次存在选中的系统，判断上次选中系统的id在目前的系统列表中是否存在一致的，如果有，继承???
            const nextSelected = parsed_list.filter(({ [key]: id }) => id === prevSelected[key])[0] ?? null;
            nextInfo = Object.assign({}, nextInfo, { selected: nextSelected });
        };
    };
    return nextInfo;
};

const Hook = (props) => {
    const { data, defaultPageSize: cusDefPAgeSize } = props;
    const final_defaultPageSize = useRef(defaultPageSize);
    useEffect(() => {
        final_defaultPageSize.current = pageSizeOptions.includes(cusDefPAgeSize) ? cusDefPAgeSize : defaultPageSize;
    }, [cusDefPAgeSize]);
    const sortBlankValue = useRef(props.sortBlankValue);
    const defaultCategory = useRef(props.defaultCategory);
    const defaultSysId = useRef(props.defaultSysId);

    const dataMounted = useRef(false);

    const [dataSourceInfo, setDataSourceInfo] = useState(null);
    useEffect(() => {
        let status = !!data && !dataMounted.current;//data mounted

        if (!!data) { dataMounted.current = true };

        setDataSourceInfo((prevDataSourceInfo) => {
            // 由于return的内容为obj，所以prevDataSourceInfo===null时代表第一次fetch data

            // get nextDataSource
            const nextDataSource = (data?.parsed_results ?? []);

            // get nextSysInfo
            // 这里注意需要继承之前的选择（如果有的话）
            let sysPrevSelected = prevDataSourceInfo?.sysInfo?.selected ?? null;
            if (status) {
                // 首次加载，加载default
                if (typeof defaultSysId.current === 'number') {
                    sysPrevSelected = { id: defaultSysId.current };
                };
            };
            const nextSysInfo = getNextInfo(data?.sys_list, sysPrevSelected, 'id');

            // get nextProdInfo
            // 继承同上
            const nextProdInfo = getNextInfo(data?.product_list, prevDataSourceInfo?.prodInfo?.selected ?? null, 'id');

            // get nextCatInfo
            // 继承同上
            let catPrevSelected = prevDataSourceInfo?.catInfo?.selected ?? null;
            if (status) {
                // 首次加载，加载default
                if (typeof defaultCategory.current === 'string') {
                    catPrevSelected = { category: defaultCategory.current };
                };
            };
            const nextCatInfo = getNextInfo(data?.category_list, catPrevSelected, 'category');

            // get nextCompInfo
            // 继承同上
            const nextCompInfo = getNextInfo(CompList, prevDataSourceInfo?.compInfo?.selected ?? null, 'status');

            // get nextAlarmInfo
            // 继承同上
            const nextAlarmInfo = getNextInfo(AlarmList, prevDataSourceInfo?.alarmInfo?.selected ?? null, 'status');

            // sort sort sort
            // 现在不支持多条件sort
            const nextSortInfo = prevDataSourceInfo?.sortInfo ?? null;

            return Object.assign({}, prevDataSourceInfo ?? {}, {
                dataSource: nextDataSource,
                sysInfo: nextSysInfo,
                prodInfo: nextProdInfo,
                catInfo: nextCatInfo,
                compInfo: nextCompInfo,
                alarmInfo: nextAlarmInfo,
                sortInfo: nextSortInfo,
            });
        });
    }, [data]);

    // 这里根据前序信息获取筛选后信息和分页信息
    const [filteredDataSourceInfo, setFilteredDataSourceInfo] = useState(null);
    useEffect(() => {
        const selected_sys_id = dataSourceInfo?.sysInfo?.selected?.id;
        const selected_cat_category = dataSourceInfo?.catInfo?.selected?.category;
        const selected_prod_id = dataSourceInfo?.prodInfo?.selected?.id;
        const selected_comp_status = dataSourceInfo?.compInfo?.selected?.status;
        const selected_alarm_status = dataSourceInfo?.alarmInfo?.selected?.status;
        let nextFilterDataSource = (dataSourceInfo?.dataSource ?? [])
            .filter(({ sys_id }) => sys_id === (selected_sys_id ?? sys_id))// 这里是因为null的时候全选
            .filter(({ product_id }) => product_id === (selected_prod_id ?? product_id))
            .filter(({ category }) => category === (selected_cat_category ?? category))
            .filter(({ comp_status }) => comp_status === (selected_comp_status ?? comp_status))
            .filter(({ alarm_status }) => alarm_status === (selected_alarm_status ?? alarm_status));

        // sort
        // sortBlankValue 这个可能需要放在所有排序最后
        // 这里其他项sort优先级高于sortBlankValue
        const sortInfo = dataSourceInfo?.sortInfo;
        if (!!sortInfo) {
            const { key, is_desc } = sortInfo;
            nextFilterDataSource = []
                .concat(nextFilterDataSource)
                .sort(({ [key]: v1 }, { [key]: v2 }) => {
                    const v_1 = is_desc ? v1 : v2;
                    const v_2 = is_desc ? v2 : v1;
                    if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                        return v_2 - v_1;
                    };
                    if (typeof v1 === 'number') return -1;
                    if (typeof v2 === 'number') return 1;
                    return 0;
                    // 1是交换
                });
        } else {
        };
        if (sortBlankValue.current === true) {
            // 不论其他排序如何，空value总是置底
            nextFilterDataSource = []
                .concat(nextFilterDataSource)
                .sort(({ value: v_1 }, { value: v_2 }) => {
                    if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                        return 0;
                    };
                    if (typeof v_1 === 'number') return -1;
                    if (typeof v_2 === 'number') return 1;
                    return 0;
                    // 1是交换
                });
        };

        // 这里只需要考虑继承pageSize和pageSizeOptions，其他信息重置
        setFilteredDataSourceInfo((prevFilteredDataSourceInfo) => {
            const prevPagination = prevFilteredDataSourceInfo?.paginationInfo ?? null;
            const nextPaginationInfo = Object.assign({}, prevPagination ?? {
                pageSizeOptions,
                pageSize: final_defaultPageSize.current,
            }, {
                current: 1,
                total: nextFilterDataSource.length,
            });
            return Object.assign({}, prevFilteredDataSourceInfo ?? {}, {
                filteredDataSource: nextFilterDataSource,
                paginationInfo: nextPaginationInfo,
            });
        });
    }, [dataSourceInfo]);

    const display_list = useMemo(() => {
        if (!!filteredDataSourceInfo) {
            const current = filteredDataSourceInfo.paginationInfo.current;
            const pageSize = filteredDataSourceInfo.paginationInfo.pageSize;
            const index_start = (current - 1) * pageSize;
            const index_end = current * pageSize - 1;
            return filteredDataSourceInfo
                .filteredDataSource
                .filter((item, index) => {
                    return index_start <= index && index <= index_end;
                });
        };
        return [];
    }, [filteredDataSourceInfo]);

    const [sysInfo, catInfo, prodInfo, compInfo, alarmInfo, sortInfo] = useMemo(() => {
        return [dataSourceInfo?.sysInfo, dataSourceInfo?.catInfo, dataSourceInfo?.prodInfo, dataSourceInfo?.compInfo, dataSourceInfo?.alarmInfo, dataSourceInfo?.sortInfo];
    }, [dataSourceInfo]);

    const [paginationInfo] = useMemo(() => {
        return [filteredDataSourceInfo?.paginationInfo];
    }, [filteredDataSourceInfo]);

    const onChange = (page, pageSize) => {
        setFilteredDataSourceInfo((prevFilteredDataSourceInfo) => {
            const prevPagination = prevFilteredDataSourceInfo?.paginationInfo;
            // 方法严谨
            if (!prevPagination) return prevFilteredDataSourceInfo;
            return Object.assign({}, prevFilteredDataSourceInfo, {
                paginationInfo: Object.assign({}, prevPagination, {
                    current: page,
                    pageSize,
                }),
            });
        });
    };
    const filterOnChange = (filterInfoKey, v) => {
        setDataSourceInfo((prevDataSourceInfo) => {
            const prevInfo = prevDataSourceInfo?.[filterInfoKey];
            // 方法严谨
            if (!prevInfo) return prevDataSourceInfo;
            const nextSelected = prevInfo.list.filter(({ key }) => key === v)[0] ?? null;//有可能 all 然后就是null
            return Object.assign({}, prevDataSourceInfo, {
                [filterInfoKey]: Object.assign({}, prevInfo, {
                    selected: nextSelected,
                }),
            });
        });
    };
    const sysOnChange = (v) => {
        filterOnChange("sysInfo", v);
    };
    const catOnChange = (v) => {
        filterOnChange("catInfo", v);
    };
    const prodOnChange = (v) => {
        filterOnChange("prodInfo", v);
    };
    const compOnChange = (v) => {
        filterOnChange("compInfo", v);
    };
    const alarmOnChange = (v) => {
        filterOnChange("alarmInfo", v);
    };

    const setSortInfo = (key) => {
        setDataSourceInfo((prevDataSourceInfo) => {
            // 方法严谨
            if (!prevDataSourceInfo) return prevDataSourceInfo;
            let nextSortInfo = null;
            const prevInfo = prevDataSourceInfo?.sortInfo ?? null;
            if (typeof key === 'string') {
                let nextIsDesc = false;
                if (!!prevInfo) {
                    if (prevInfo.key === key) {
                        nextIsDesc = !prevInfo.is_desc;
                    };
                };
                nextSortInfo = { key, is_desc: nextIsDesc };
            };
            return Object.assign({}, prevDataSourceInfo, {
                sortInfo: nextSortInfo,
            });
        });
    };

    return {
        display_list,
        paginationInfo, onChange,
        sysInfo, sysOnChange, prodInfo, prodOnChange, catInfo, catOnChange, compInfo, compOnChange, alarmInfo, alarmOnChange,
        sortInfo, setSortInfo,
    };
};

export default Hook;
