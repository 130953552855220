import React from 'react';
import style from './LeftViewer.module.scss';
import { CURR_SYSTEM_ICON } from './constants.js';
import { significantNum } from '../../../common/utils';

export default class LeftViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            offsetWidth: 0,
            data: null,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.calcOffset);
        this.updateData();
    }

    componentDidUpdate() {
        this.updateData();
    }

    updateData = () => {
        const { data } = this.props;
        const { data: prevData } = this.state;
        if (data !== prevData) {
            // update 
            this.setState({ data }, this.calcOffset);
        };
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcOffset);
    }

    calcOffset = () => {
        // console.log(this.outRefNode);
        if (!!this.outRefNode) {
            // console.log(this.outRefNode.offsetWidth, this.outRefNode.scrollWidth, this.outRefNode.clientWidth);
            let offsetWidth = this.outRefNode.offsetWidth - this.outRefNode.scrollWidth;
            if (offsetWidth < 0) {
                offsetWidth = 0;
            };
            const { offsetWidth: prevOffsetWidth } = this.state;
            if (prevOffsetWidth !== offsetWidth) {
                this.setState({ offsetWidth });
            };
        };
    }

    onSelect = (sysInfo, itemInfo) => {
        return () => {
            if (!!sysInfo && !!itemInfo) {
                if (typeof this.props?.onSelect === 'function') {
                    this.props.onSelect(Object.assign({}, sysInfo, itemInfo));
                };
            };
        };
    };

    render() {
        const { offsetWidth, data } = this.state;
        const { selectedItem } = this.props;

        return (
            <div className={style["exam_analysis_left_viewer_wrapper"]}>
                <div
                    className="common_row_block viewerTitle"
                    style={{ paddingRight: offsetWidth }}
                >
                    <div className="left_block" />
                    <div className="right_block right_row_block">
                        <div className="right_block_item right_block_item_rank">当前名次</div>
                        <div className="right_block_item">班次</div>
                        <div className="right_block_item">当前得分</div>
                    </div>
                </div>
                <div className='leftTables' ref={refNode => this.outRefNode = refNode}>
                    {
                        (data ?? [])
                            .map((item, index) => {
                                const { sys_id, sys_name, shift_list } = item;

                                // 计算当前系统图标，需要显示图片
                                const sysIconSrc = CURR_SYSTEM_ICON[sys_id] || null;
                                return (
                                    <div
                                        className="sys_block_wrapper common_row_block"
                                        key={index}
                                    >
                                        <div className="left_block">
                                            <div className="sys_icon_name_wrapper">
                                                <img src={sysIconSrc} alt="" className="img" />
                                                <div
                                                    className="sys_name"
                                                    cusattribute={sys_name}
                                                >{sys_name}</div>
                                            </div>
                                        </div>
                                        <div className="right_table_block">
                                            {
                                                (shift_list ?? [])
                                                    .map((item2, index2) => {
                                                        const isSelected = (selectedItem?.sys_id === item?.sys_id && selectedItem?.shift_id === item2?.shift_id);
                                                        const { score, shift_name } = item2;
                                                        const parsed_score = (typeof score === 'number' && !isNaN(score)) ? significantNum(score) : "--";
                                                        return (
                                                            <div
                                                                className={"right_row_block" + (isSelected ? " selected_right_row_block" : "")}
                                                                key={index2}
                                                                {
                                                                ...isSelected ?
                                                                    {} : {
                                                                        onClick: this.onSelect(item, item2),
                                                                    }
                                                                }
                                                            >
                                                                <div className="right_block_item">{index2 + 1}</div>
                                                                <div className="right_block_item right_block_item_username">
                                                                    <div>{shift_name}</div>
                                                                </div>
                                                                <div className="right_block_item">{parsed_score}</div>
                                                            </div>
                                                        );
                                                    })
                                            }
                                        </div>
                                    </div>
                                )
                            })

                    }

                </div>
            </div>
        );
    }
}
