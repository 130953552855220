import React from 'react';
import Wrapper from '../../Common/Wrapper.js';
import { DisconnectOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import ICON_1 from '../res/single_1.png';
import ICON_ADD from '../res/icon_add.png';
import ICON_ROW_ICON from '../res/icon_1.png';

const FirstBlock = (props) => {
    const { selectedFactor, systems, themes, customFactors, getCustomKPIList } = props;
    const { name, theme_id, unit, sys_id, product_name, rf_count } = selectedFactor;
    let themeText = '-';
    const theme = themes.filter(({ id }) => id === theme_id)[0];
    if (!!theme) {
        themeText = `${theme.name}主题`;
    };
    let sysText = '-';
    const sys = systems.filter(({ id }) => id === sys_id)[0];
    if (!!sys) {
        sysText = `${sys.name}系统`;
    };
    let unitText = '-';
    if (typeof unit === 'string') {
        unitText = unit;
    };
    let product_name_text = '-';
    if (typeof product_name === 'string') {
        product_name_text = product_name;
    };
    let rf_count_text = '-';
    if (typeof rf_count === 'number') {
        rf_count_text = `${rf_count}个`;
    };
    const addToCusSelected = (id) => {
        const { add2FavouriteKPI } = props;
        add2FavouriteKPI(id)
            .then(isSuccess => {
                // console.log(isSuccess);
                if (isSuccess) {
                    getCustomKPIList();
                };
            });
    };
    const deleteFromCusSelected = (id) => {
        const { name } = selectedFactor;
        Modal.confirm({
            content: `确认取消自选指标【${name}】吗?`,
            onOk: () => {
                const { deleteFromFavouriteKPI } = props;
                deleteFromFavouriteKPI(id)
                    .then(isSuccess => {
                        // console.log(isSuccess);
                        if (isSuccess) {
                            getCustomKPIList();
                        };
                    });
            },
        });
    };
    return (
        <Wrapper icon={ICON_1} title="指标详情">
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="" src={ICON_ROW_ICON} width="2" height="16" />
                    <div style={{ marginLeft: 8, fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>指标名称</div>
                    <div style={{ marginLeft: 12, borderRadius: 9, backgroundColor: '#44F3DA', height: 14, width: 28, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#151922', fontSize: 12 }}>KPI</div>
                    <div style={{ marginLeft: 8, color: '#FFFFFF', fontSize: 14, lineHeight: '14px', height: 14 }}>{name}</div>
                    {
                        !!customFactors && (
                            customFactors.filter(({ id }) => id === selectedFactor.id).length > 0 ?
                                (
                                    <div
                                        style={{ marginLeft: 'auto', marginRight: 4, display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}
                                        onClick={() => deleteFromCusSelected(selectedFactor.id)}
                                    >
                                        <DisconnectOutlined style={{ fontSize: 16, color: '#FF6170' }} />
                                        <div style={{ marginLeft: 2, color: '#FF6170', fontSize: 14, height: 14, lineHeight: '14px' }}>取消自选</div>
                                    </div>
                                ) : (
                                    <div
                                        style={{ marginLeft: 'auto', marginRight: 4, display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}
                                        onClick={() => addToCusSelected(selectedFactor.id)}
                                    >
                                        <img alt="" src={ICON_ADD} width="16" height="16" />
                                        <div style={{ marginLeft: 2, color: '#3DCDB9', fontSize: 14, height: 14, lineHeight: '14px' }}>加入自选</div>
                                    </div>
                                )
                        )
                    }
                </div>
                <div style={{ marginTop: 16, display: 'grid', gridTemplateColumns: 'repeat(2,50%)', gridRowGap: 24 }}>
                    {
                        [
                            ['指标主题', themeText],
                            ['指标单位', unitText],
                            ['所属系统', sysText],
                            ['产品类型', product_name_text],
                            ['影响因子', rf_count_text],
                        ]
                            .map((item, index) => {
                                const [title, value] = item;
                                return (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: 14, lineHeight: '14px' }}>
                                        <div style={{ color: '#9FA0BA', height: 14, width: 68 }}>{title}</div>
                                        <div style={{ color: '#FFFFFF', height: 14 }}>{value}</div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        </Wrapper>
    );
};

export default FirstBlock;
