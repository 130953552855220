import React, { } from 'react';
import './index.scss';

const Comp = (props) => {
    const { header, leftHeader, leftContent, rightHeader, rightContent } = props;
    return (
        <div className="statistical_report_FtWJc" style={{ position: 'relative' }}>
            <div className="statistical_report_header_wrapper">
                <div style={{ height: 67, paddingTop: 1, backgroundColor: '#212830' }}>
                    {header}
                </div>
                <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
            </div>
            <div className="statistical_report_content_wrapper">
                <div style={{ height: '100%', display: 'flex' }}>
                    <div style={{ width: 360, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ height: 46, border: '1px solid rgba(68,242,217,0.2)', backgroundColor: '#15191E' }}>
                            {leftHeader}
                        </div>
                        <div style={{ height: 0, flexGrow: 1, backgroundColor: '#212830' }}>
                            {leftContent}
                        </div>
                    </div>
                    <div style={{ width: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ height: 47, border: '1px solid rgba(68,242,217,0.2)', backgroundColor: '#15191E' }}>
                            {rightHeader}
                        </div>
                        <div style={{ height: 0, flexGrow: 1, backgroundColor: 'rgba(21,25,30,1)', borderStyle: 'solid', borderColor: 'rgba(68,243,218,0.4)', borderWidth: '0 1px 1px 0' }}>
                            {rightContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
