import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import Wrapper from '../../Common/Wrapper.js';
import AdviceBlockModal from './AdviceBlockModalContainer.js';
import { getAdviceRankBckColor } from '../utils.js';

import ICON_3 from '../res/single_3.png';

import './AdviceBlock.scss';

const AdviceBlock = (props) => {
    const { selectedIds } = props;
    const [visible, setVisible] = useState(false);
    const [improvements, setImprovements] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchData = (ids) => {
        // console.log("selectedIds changed");
        const { getImprovements } = props;
        setLoading(true);
        getImprovements(ids)
            .then((retData) => {
                // console.log(retData);
                setLoading(false);
                const { isSuccess, results } = retData;
                if (isSuccess) {
                    setImprovements(results);
                };
            });
    };
    useEffect(() => {
        fetchData(selectedIds);
        // eslint-disable-next-line
    }, [selectedIds]);
    return (
        <Wrapper icon={ICON_3} title="改进建议" titleOnClick={() => { setVisible(true) }}>
            <div className="factor_summary_v2_single_factor_detail_advice_block_wrapper" style={{ position: 'relative', minHeight: 60, overflow: 'hidden' }}>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
                <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 16px', marginTop: -16, marginBottom: -16 }}>
                    <thead>
                        <tr>
                            <th style={{ width: 24 }} />
                            <th>影响因子名称</th>
                            <th style={{ width: 56 }}>改善机会</th>
                            <th style={{ width: 160 }}>改善建议</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!improvements &&
                            improvements
                                .filter((_, index) => index < 9)
                                .map((item, index) => {
                                    const { factor_name, improve_num, lower_limit, upper_limit, avg_value, unit } = item;
                                    const textStyle = { fontSize: 12, lineHeight: '14px', color: '#FFFFFF' };
                                    let advice = (<span>-</span>);
                                    if (typeof avg_value === "number" && ([typeof lower_limit, typeof upper_limit].includes("number"))) {
                                        if (typeof lower_limit === "number" && typeof upper_limit === "number") {
                                            advice = (<div>保持参数在<span style={{ color: '#B376FF' }}>{Math.floor(lower_limit * 10) / 10}{unit}-{Math.floor(upper_limit * 10) / 10}{unit}</span>之间</div>);
                                        } else if (typeof lower_limit === "number") {
                                            advice = (<div><span style={{ color: '#B376FF' }}>提高</span>参数，保持在<span style={{ color: '#B376FF' }}>{Math.floor(lower_limit * 10) / 10}{unit}</span>以上</div>);
                                        } else if (typeof upper_limit === "number") {
                                            advice = (<div><span style={{ color: '#B376FF' }}>降低</span>参数，保持在<span style={{ color: '#B376FF' }}>{Math.floor(upper_limit * 10) / 10}{unit}</span>以下</div>);
                                        };
                                    };
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div style={{
                                                    width: 16, height: 16, borderRadius: "50%",
                                                    backgroundColor: getAdviceRankBckColor(index + 1),
                                                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                    fontSize: 12, color: '#FFFFFF',
                                                }}>{index + 1}</div>
                                            </td>
                                            <td style={textStyle}>{factor_name}</td>
                                            <td style={textStyle}>{improve_num} 次</td>
                                            <td style={textStyle}>{advice}</td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
            {
                visible && !!improvements && (
                    <AdviceBlockModal onCancel={() => { setVisible(false) }} improvements={improvements} />
                )
            }
        </Wrapper>
    );
};

export default AdviceBlock;
