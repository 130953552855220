const colors = [
    '#5CA9C8',
    '#8F6DC6',
    '#5E7BC4',
    '#7489AE',
    '#FB6060',
    '#FFBE0D',
]
    .concat(
        new Array(128)
            .fill(null)
            .map((item, index) => `rgba(${50 + index},${50 + index},${50 + index},1)`),
    );

export {
    colors,
};