import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import style from './index.module.scss';
import CommonContext from '../context';
import { post } from 'common/utils';
import { Spin, Table } from 'antd';
import { tabs } from './utils.js'
import DomResize from 'components/DomResize'

const Comp = (props) => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(() => 0);
    const [switchTag, setSwitchTag] = useState(() => 'all');// all, self
    const switchStyle = useMemo(() => {
        switch (switchTag) {
            case 'all':
                return { backgroundColor: '#E6E6E6', justifyContent: 'flex-end' };
            case 'self':
                return { backgroundColor: '#AFDAD8', justifyContent: 'flex-start' };
            default:
                return {};
        };
    }, [switchTag]);

    const [loading, setLoading] = useState(false);

    const commonInfo = useContext(CommonContext);
    const conf = useMemo(() => {
        const partConf = commonInfo?.state?.conf ?? null
        const sys_name = commonInfo?.state?.selectSys?.sys_name ?? null
        const sel_all = switchTag === 'all' ? 'yes' : 'no'
        if (partConf && typeof sys_name === 'string' && typeof sel_all === 'string') {
            return Object.assign({}, partConf, { sys_name, sel_all })
        }
        return null
    }, [commonInfo, switchTag])

    const [data, setData] = useState(null)
    useEffect(() => {
        // console.log(conf)
        let config = conf
        if (config) { // mock
            // console.log(conf)
            // config = {
            //     "dtype": "data_weekly",
            //     "date": "2021-12-11",
            //     "basic_tag": "PL0_AC_M1",
            //     "sys_name": "公用机组辅助",
            //     "sel_all": "no"
            // } // mock
            setLoading(true)
            let delay = true
            post(process.env.REACT_APP_SERVER_PATH + 'puyuan_api/ele_statistic', config)
                .then(retData => {
                    if (delay) {
                        setLoading(false)
                        // retData = mockRet //mock
                        // console.log(retData)
                        if (retData?.data?.errcode === 0) {
                            const dataSource = (retData?.data?.result ?? [])
                                .map((item, index) => Object.assign({}, item, { key: `${index}` }))
                            // console.log(dataSource)
                            setData(dataSource)
                        }
                    }
                })
            return () => {
                delay = false
            }
        }
    }, [conf])

    const [height, setHeight] = useState(() => 0)
    const onResize = useCallback(conf => {
        setHeight(conf.height - 50)
    }, [])

    return (
        <div className={style['wrapper']}>
            <div className="tab_row">
                {
                    tabs
                        .map((item, index) => {
                            const { name } = item;
                            return (
                                <div className={"tab_item" + (selectedTabIndex === index ? " selected_tab_item" : "")} key={index} onClick={() => setSelectedTabIndex(index)}>
                                    <div className="text">{name}</div>
                                </div>
                            );
                        })
                }
                <div style={{ marginLeft: 'auto' }} />
                <div className="switch_btn_wrapper" style={switchStyle} onClick={() => setSwitchTag(switchTag === 'all' ? 'self' : 'all')}>
                    {
                        switchTag === 'all' ? ([
                            <div className="text" key="1" style={{ marginRight: 6 }}>所有归属表</div>,
                            <div className="ball" key="2"></div>
                        ]) : (switchTag === 'self' ? ([
                            <div className="ball" key="2"></div>,
                            <div className="text" key="1" style={{ marginLeft: 6 }}>只看本表</div>
                        ]) : (null))
                    }
                </div>
            </div>
            <div className="content_wrapper">
                <div className='resize-wrapper'>
                    <DomResize mountedCall={true} onResize={onResize} />
                </div>
                <div className='content'>
                    <Table
                        size='small'
                        dataSource={data}
                        columns={tabs[selectedTabIndex].columns}
                        pagination={false}
                        scroll={{
                            y: height
                        }}
                    />
                </div>
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
