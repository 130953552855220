import React, { useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import { renderChart } from './utils.js';
// import { Spin } from 'antd';
import List from './List/index.js'
import moment from 'moment'
import { get, post } from 'common/utils.js'
import LeftChart from './LeftChart/index.jsx'
import Tabs from 'components/TabsV2';

const Page = (props) => {
    const [fncs, setFncs] = useState(() => [])
    const [sysInfo, setSysInfo] = useState(() => null);
    const [ranges, setRanges] = useState(() => [])
    const [selR, setSelR] = useState(() => null)
    const [api, setApi] = useState(() => null)

    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + 'api/page_layouts', {
            page: 1, page_size: 999, key: 'COLLAPSE_MONITOR_CONF'
        })
            .then(retData => {
                // console.log(retData)
                let nList = []
                let nR = []
                let nApi = null
                try {
                    const obj = JSON.parse(retData.data.results[0].content)
                    // console.log(obj)
                    nList = obj.tabs.map((item, index) => Object.assign(item, { key: `${index}` }))
                    nR = (obj.ranges ?? []).map((item, index) => Object.assign(item, { key: `${index}` }))
                    nApi = obj.api ?? null
                } catch (e) {
                    console.log(e)
                }
                setFncs(nList)
                setRanges(nR)
                setApi(nApi)
                setSelR(nR?.[0] ?? null)
                setSysInfo(nList?.[0] ?? null)
            })
    }, [])

    const [refreshInfo, setRefreshInfo] = useState(() => null)
    useEffect(() => {
        let nInfo = null
        if (sysInfo) {
            nInfo = {
                fnc_id: sysInfo.id,
                timeStamp: moment().format('YYYY-MM-DD HH:mm:ss')
            }
        }
        setRefreshInfo(nInfo)
    }, [sysInfo])
    const [statusInfo, setStatusInfo] = useState(() => null)
    const pStatusInfo = useMemo(() => {
        let info = null
        if (statusInfo && sysInfo) {
            // console.log(sysInfo, statusInfo)
            try {
                info = sysInfo.status.map(item => Object.assign({}, item, { value: statusInfo[item.key] }))
            } catch (e) { }
        }
        return info
    }, [statusInfo, sysInfo])
    const [sChartInfo, setSChartInfo] = useState(() => null)
    const pSChartInfo = useMemo(() => {
        let info = null
        if (sChartInfo && sysInfo) {
            try {
                info = sysInfo.status.map(item => Object.assign({}, item, { dBottom: sChartInfo[item.key2], dTop: sChartInfo[item.key3], times: sChartInfo.datetime }))
            } catch (e) { }
        }
        // console.log(info)
        return info
    }, [sChartInfo, sysInfo])
    const [tNum, setTNum] = useState(() => null)
    useEffect(() => {
        let myTimeout = null
        if (tNum === 0) {
            // console.log(tNum)
            myTimeout = setTimeout(() => {
                setRefreshInfo(p => {
                    return Object.assign({}, p, { timeStamp: moment().format('YYYY-MM-DD HH:mm:ss') })
                })
            }, 10000)
        }
        return () => {
            clearTimeout(myTimeout)
        }
    }, [tNum])
    useEffect(() => {
        // console.log(refreshInfo)
        let delay = true
        if (refreshInfo && api !== null) {
            setTNum(p => p === null ? 1 : p + 1)
            post(process.env.REACT_APP_SERVER_PATH + `${api}/get_slip_monitor`, refreshInfo)
                .then(retData => {
                    setTNum(p => p - 1)
                    if (delay) {
                        // console.log(retData)
                        let nInfo = null
                        if (retData?.data?.errcode === 0) {
                            try {
                                nInfo = retData.data.results ?? null
                            } catch (e) { }
                        }
                        // console.log(nInfo)
                        setStatusInfo(nInfo)
                    }
                })
            setTNum(p => p === null ? 1 : p + 1)
            post(process.env.REACT_APP_SERVER_PATH + `${api}/fnc_slip_stat`, refreshInfo)
                .then(retData => {
                    setTNum(p => p - 1)
                    if (delay) {
                        // console.log(retData)
                        let nInfo = null
                        if (retData?.data?.errcode === 0) {
                            try {
                                nInfo = retData.data.results ?? null
                            } catch (e) { }
                        }
                        // console.log(nInfo)
                        setSChartInfo(nInfo)
                    }
                })
        } else {
            setStatusInfo(null)
            setSChartInfo(null)
        }
        return () => {
            delay = false
        }
    }, [refreshInfo, api])

    const [cList, setCList] = useState(() => [])
    useEffect(() => {
        // console.log(refreshInfo)
        let delay = true
        if (refreshInfo && selR && api !== null) {
            const { fnc_id, timeStamp } = refreshInfo
            const { value, unit } = selR
            const end_time = timeStamp
            const start_time = moment(end_time).subtract(value, unit).format("YYYY-MM-DD HH:mm:ss")
            setTNum(p => p === null ? 1 : p + 1)
            post(process.env.REACT_APP_SERVER_PATH + `${api}/abntag_curve_data`, {
                fnc_id, start_time, end_time
            })
                .then(retData => {
                    setTNum(p => p - 1)
                    if (delay) {
                        // console.log(retData)
                        let nL = []
                        if (retData?.data?.errcode === 0) {
                            try {
                                nL = retData.data.results ?? []
                            } catch (e) { }
                        }
                        // console.log(nL)
                        setCList(nL)
                    }
                })
        } else {
            setCList([])
        }
        return () => {
            delay = false
        }
    }, [refreshInfo, api, selR])

    return (
        <div className={style['wrapper']}>
            <Header sysInfo={sysInfo} setSysInfo={setSysInfo} fncs={fncs} />
            <div className="content_wrapper">
                <div className='left'>
                    <div className={`top ${style.block}`}>
                        <div className='title'>塌料状态监控</div>
                        {
                            pStatusInfo && (
                                <div className='bottom'>
                                    {
                                        pStatusInfo.map((item, index) => {
                                            const colorClass = item.value === 0 ? 'green'
                                                : (item.value === 1 ? 'yellow' :
                                                    (item.value === 2 ? 'red' : 'grey'))
                                            return (
                                                <div className='item' key={index}>
                                                    <div className={`top ${colorClass}`}></div>
                                                    <div className='bottom'>{item.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className={`bottom ${style.block}`}>
                        <div className='title'>塌料状态统计（过去24小时）</div>
                        {
                            pSChartInfo && (
                                <div className='bottom scroll_bar_restyle'>
                                    {
                                        pSChartInfo.map((item, index) => {
                                            return (
                                                <div className='item' key={index}>
                                                    <div className='top'>{item.name}</div>
                                                    <div className='bottom'>
                                                        <LeftChart info={item} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={`right ${style.block}`}>
                    <div className='title'>关键参数趋势</div>
                    <div className='top'>
                        <div className='wrapper'>
                            <Tabs tabs={ranges} selectedTab={selR} onChange={setSelR} />
                        </div>
                    </div>
                    <div className='bottom scroll_bar_restyle'>
                        <List list={cList} renderFunc={renderChart} />
                    </div>
                </div>
            </div>
            {/* {
                loading === true && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin />
                    </div>
                )
            } */}
        </div>
    );
};

export default Page;
