import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    const { tabs } = props;
    const tabsRef = useRef(tabs);
    useEffect(() => {
        tabsRef.current = tabs;
    }, [tabs]);

    const [selectedTab, setSelectedTab] = useState(() => {
        let initValue = [null, null];
        if (!!tabs && !!tabs[0]) {
            let initSelectedTab = [tabs[0], null];
            if (!!tabs[0].children && !!tabs[0].children[0]) {
                initSelectedTab = [].concat(initSelectedTab);
                initSelectedTab[1] = tabs[0].children[0];
            };
            initValue = initSelectedTab;
        };
        return initValue;
    }); // 用来保持原子性

    // 随着选择变化
    const [latestSelectedChildren, setLatestSelectedChildren] = useState(() => {
        return tabs.map(({ children }) => !!children ? children[0] : null);
    }); //Lazy initial state

    useEffect(() => {
        if (!!selectedTab[1]) {
            const selectedItemIndex = tabsRef.current.indexOf(selectedTab[0]);
            if (selectedItemIndex !== -1) {
                setLatestSelectedChildren((latestSelectedChildren) => {
                    let nextLatestSelectedChildren = [].concat(latestSelectedChildren);
                    nextLatestSelectedChildren[selectedItemIndex] = selectedTab[1];
                    return nextLatestSelectedChildren;
                });
            };
        };
    }, [selectedTab]);

    // selectedTab变化后回调父节点函数 deps wraning
    const { onChange } = props;
    const onChangeRef = useRef(onChange);
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);
    useEffect(() => {
        if (typeof onChangeRef.current === 'function') {
            onChangeRef.current(selectedTab[1] || selectedTab[0]);
        };
    }, [selectedTab]);

    const childOnClick = (child, item) => {
        const [prevItem, prevChild] = selectedTab;
        if (prevItem === item && prevChild === child) return;
        setSelectedTab([item, child]);
    };

    const itemOnClick = (index) => {
        console.log(selectedTab,tabs,latestSelectedChildren,index);
        if (selectedTab[0] === tabs[index]) return;
        setSelectedTab([tabs[index], latestSelectedChildren[index]]);
    };

    return (
        <div className={style['wrapper']}>
            {
                tabs
                    .map((item, index) => {
                        const { name, children } = item;
                        return (
                            <div onClick={() => itemOnClick(index)}
                                key={index} className={"item" + (selectedTab[0] === item ? " selected" : "")}
                            >
                                {
                                    !!children && (
                                    <div className="arrow_wrapper">{'<'}</div>
                                    )
                                }
                                <div className="text">{name}</div>
                                {
                                    !!children && (
                                        [
                                            // (
                                            //     <div className="arrow_2_wrapper" key="arrow"></div>
                                            // ),
                                            // (
                                            //     <div className="child_name" key="child_name">{latestSelectedChildren[index].name}</div>
                                            // ),
                                            (
                                                // <div className="hover_menu_wrapper" key="hover_menu">
                                                    <div className="content_wrapper">
                                                        {
                                                            children
                                                                .map((child, child_index) => {
                                                                    const { name } = child;
                                                                    return (
                                                                        <div
                                                                            key={child_index} className={"item" + (latestSelectedChildren[index] === child ? " selected" : "")}
                                                                            onClick={(e) => {
                                                                                childOnClick(child, item);
                                                                            }}
                                                                        >
                                                                            {name}
                                                                        </div>
                                                                    );
                                                                })
                                                        }
                                                    </div>
                                                // </div>
                                            )
                                        ]
                                    )
                                }
                                {/* <div
                                    className="click_area"
                                    onClick={() => itemOnClick(index)}
                                ></div> */}
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default Comp;
