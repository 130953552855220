import React from 'react';
import { withRouter } from 'react-router';
import { post, get } from 'common/utils';
import { Button, Table, Select, Modal } from 'antd';
import WeightSettingModal from './WeightSettingModal.js';
import DetailModal from './DetailModal/DetailModal.js';
import { SCORE_METHODS, RANK_METHODS, RULE_DETAIL_STATUS } from './config.js';
import { getMyPermissions } from 'common/AuthPermission.js';
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import style from './Content.module.scss';

const { Option } = Select;

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            myPermissions: getMyPermissions(),
            modalVisible: false,
            detailModalVisible: false,
            systems: [],
            // page: 1,
            // total: 0,
            // pageSize: 10,
            ruleStatus: 'all',
            systemSelected: 'all',
            scoreMethodSelected: 'all',
            selectedFactor: {},
        };
    }

    componentDidMount() {
        this.getSystemList();
    }

    getSystemList = () => {
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/cmt_system/list').then(retData => {
            // console.log('system', retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ systems: retData.data.results });
            };
        });
    }

    deleteFactor = _id => {
        const execDel = () => {
            const { id: rule_id } = this.props;
            post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/factors/delete', {
                rule_id, _id
            }).then(retData => {
                console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    this.props.getRuleDetail();
                }
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            // content: '确认删除吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    render() {
        let self = this;
        const { modalVisible, systems, systemSelected, scoreMethodSelected, detailModalVisible, ruleStatus, myPermissions } = this.state;
        const { factors: originFactors } = this.props;
        const factors = originFactors.filter(({ sys_id, status, score_method }) => {
            if (systemSelected !== 'all' && systemSelected != sys_id) return false;
            if (ruleStatus !== 'all' && ruleStatus != status) return false;
            if (scoreMethodSelected !== 'all' && scoreMethodSelected != score_method) return false;
            return true;
        })
        return (
            <div className={style["exam_rule_config_content_wrapper"]}>
                <div className="content_wrapper">
                    <div className="header">
                        <Select
                            style={{ width: 144, marginRight: 24 }}
                            defaultValue="all"
                            value={systemSelected}
                            onChange={v => self.setState({ systemSelected: v })}
                        >
                            <Option value='all'>全部系统</Option>
                            {
                                systems.map(({ id, name }) =>
                                    <Option key={id}>{name}</Option>
                                )
                            }
                        </Select>
                        <Select
                            style={{ width: 144, marginRight: 24 }}
                            defaultValue="all"
                            value={ruleStatus}
                            onChange={v => self.setState({ ruleStatus: v })}
                        >
                            <Option value='all'>全部状态</Option>
                            {
                                RULE_DETAIL_STATUS.map(({ status, name }) =>
                                    <Option key={status}>{name}</Option>
                                )
                            }
                        </Select>
                        <Select
                            style={{ width: 144, marginRight: 24 }}
                            defaultValue="all"
                            value={scoreMethodSelected}
                            onChange={v => self.setState({ scoreMethodSelected: v })}
                        >
                            <Option value='all'>统计方法</Option>
                            {
                                SCORE_METHODS.map(({ id, name }) =>
                                    <Option key={id}>{name}</Option>
                                )
                            }
                        </Select>
                        {
                            myPermissions.includes('rule_setting/edit_weight') && (
                                <Button
                                    type="primary" ghost disabled={factors.length === 0} onClick={() => this.setState({ modalVisible: true })}
                                    style={{
                                        color: '#357CF7',
                                        borderColor: '#357CF7',
                                    }}
                                >权重设置</Button>
                            )
                        }
                        <Button
                            type="primary" ghost onClick={() => this.props.goBackToList()}
                            style={{
                                marginLeft: 'auto',
                                color: '#357CF7',
                                borderColor: '#357CF7',
                            }}
                        >返回</Button>
                        {
                            myPermissions.includes('rule_setting/add_factor') && (
                                <Button
                                    type="primary" onClick={() => this.setState({ detailModalVisible: true, selectedFactor: {} })}
                                    style={{
                                        marginLeft: 12,
                                        backgroundColor: '#357CF7',
                                        borderColor: '#357CF7',
                                    }}
                                >添加</Button>
                            )
                        }
                    </div>

                    <div className="cus_table_wrapper">
                        <Table
                            dataSource={factors}
                            pagination={false}
                            columns={[
                                {
                                    title: '指标名称',
                                    dataIndex: 'meta_name',
                                },
                                {
                                    title: '所属系统',
                                    key: 'system',
                                    render: (text, { sys_id }, index) =>
                                        (systems.filter(({ id }) => id == sys_id)[0] || {}).name || ''
                                },
                                {
                                    title: '统计方法',
                                    key: 'score_method',
                                    render: (text, { score_method }, index) =>
                                        (SCORE_METHODS.filter(({ id }) => id == score_method)[0] || {}).name || '累计/平均'
                                },
                                {
                                    title: '排名方法',
                                    key: 'rank_method',
                                    render: (text, { rank_method, score_method }, index) =>
                                        score_method == '2' ?
                                            ((RANK_METHODS.filter(({ id }) => id == rank_method)[0] || {}).name || '') : ('')
                                },
                                {
                                    title: '权重',
                                    key: 'weight',
                                    render: (text, { weight }, index) =>
                                        weight + '%'
                                },
                                {
                                    title: '备注',
                                    dataIndex: 'remark',
                                },
                                {
                                    title: '状态',
                                    key: 'status',
                                    render: (text, { status }, index) =>
                                        RULE_DETAIL_STATUS.filter(item => item.status == status)[0].name
                                },
                                {
                                    title: '操作',
                                    key: 'action',
                                    render: (text, record, index) => ([
                                        myPermissions.includes('rule_setting/edit_factor') && (
                                            <Button key='1' type="link" onClick={() => this.setState({ detailModalVisible: true, selectedFactor: record })} style={{ color: '#357CF7' }}>编辑</Button>
                                        )
                                        ,
                                        myPermissions.includes('rule_setting/del_factor') && (
                                            <Button key='2' type="link" onClick={() => this.deleteFactor(record._id)} style={{ color: '#357CF7' }}>删除</Button>
                                        )
                                    ])
                                },
                            ]}
                            scroll={{ y: true }}
                        />
                    </div>
                    {
                        modalVisible && (
                            <WeightSettingModal
                                factors={factors}
                                systems={systems}
                                onClose={refresh => {
                                    this.setState({ modalVisible: false });
                                    refresh && this.props.getRuleDetail();
                                }}
                            />
                        )
                    }
                    {
                        detailModalVisible && (
                            <DetailModal
                                factor={this.state.selectedFactor}
                                systems={systems}
                                onClose={refresh => {
                                    this.setState({ detailModalVisible: false });
                                    refresh && this.props.getRuleDetail();
                                }}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Content);
