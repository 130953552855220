import React, { useMemo, useState } from 'react';
import style from './index.module.scss';
import TopBreadcrumb from 'components/TopBreadCrumb';
import { withRouter } from 'react-router';

const Comp = (props) => {
    const [furnaceNo] = useState(() => {
        let fNo = null;
        try {
            let id = new URLSearchParams(props.location.search).get('furnaceNo') ?? 1;
            id = parseInt(id);
            if (!isNaN(id)) {
                fNo = id;
            };
        } catch (e) { };
        if (typeof fNo !== 'number') {
            fNo = 1;
        };
        return fNo;
    });

    const breadcrumbList = useMemo(() => {
        return [{ name: '配料' }, { name: `${furnaceNo}#炉` }];
    }, [furnaceNo]);

    return (
        <div className={style['wrapper']}>
            <div className="left">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
            </div>
        </div>
    );
};

export default withRouter(Comp);
