import moment from 'moment';

const limitTimeStr = "01:00:00";

let items = [
    {
        name: '日',
        cn_text: '天',
        time_type: 'DAY',
        picker: 'date',
        range: 'day',//startOf
        calc_unit: 'days',
        period: [6, 'days'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('day')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'day')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('date').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年M月D日");
        },
    },
    {
        name: '周',
        cn_text: '周',
        time_type: 'WEEK',
        picker: 'week',
        range: 'week',//startOf
        calc_unit: 'weeks',
        period: [6, 'weeks'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('week')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'week')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('week').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年M月D日");
        },
    },
    {
        name: '月',
        cn_text: '月',
        time_type: 'MONTH',
        picker: 'month',
        range: 'month',//startOf
        calc_unit: 'months',// add, subtract
        period: [6, 'months'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('month')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'month')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('month').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年M月");
        },
    },
]
    .map((item, index) => {
        return Object.assign({}, item, { key: index + "" });
    });
// 一定要写在最后
items = items
    .map((item) => Object.assign({}, item, { all_infos: items }));

const getLatestTime = (timeInfo) => {
    const { range, calc_unit } = timeInfo;
    let currMoment = moment();
    if (moment(currMoment).isBefore(moment(moment(currMoment).startOf(range).format(`YYYY-MM-DD ${limitTimeStr}`)))) {
        currMoment = moment(currMoment).subtract(1, calc_unit).endOf(range);
    };
    return moment(currMoment).format("YYYY-MM-DD 00:00:00");
};

export {
    items,
    getLatestTime,
};