import React, { useMemo } from 'react';
import style from './index.module.scss'; // has problem
import { Space } from 'antd';
import {
    scenesInfo,
} from '../utils';
import {
    PlayCircleOutlined, PauseCircleOutlined, CloseCircleOutlined,
    FullscreenExitOutlined, FullscreenOutlined,
} from '@ant-design/icons';//<PlayCircleOutlined /><PauseCircleOutlined /><CloseCircleOutlined />

const Comp = (props) => {
    const { currScene, status, isFullScreen } = props;
    const currSceneIndex = useMemo(() => {
        if (!!currScene) return scenesInfo.indexOf(currScene);
        return null;
    }, [currScene]);

    const quitPlay = () => {
        const { quitPlay } = props;
        if (typeof quitPlay === 'function') {
            quitPlay();
        };
    };

    const jump2Scene = (scene) => {
        const { jump2Scene } = props;
        if (typeof jump2Scene === 'function') {
            jump2Scene(scene);
        };
    };

    const pauseOnClick = () => {
        const { pauseOnClick } = props;
        if (typeof pauseOnClick === 'function') {
            pauseOnClick();
        };
    };

    const continueOnClick = () => {
        const { continueOnClick } = props;
        if (typeof continueOnClick === 'function') {
            continueOnClick();
        };
    };

    const replayOnClick = () => {
        const { replayOnClick } = props;
        if (typeof replayOnClick === 'function') {
            replayOnClick();
        };
    };

    const exitFullScreen = () => {
        const { exitFullScreen } = props;
        if (typeof exitFullScreen === 'function') {
            exitFullScreen();
        };
    };

    const enterFullScreen = () => {
        const { enterFullScreen } = props;
        if (typeof enterFullScreen === 'function') {
            enterFullScreen();
        };
    };

    return (
        <div className={style['wrapper']}>
            <Space>
                {
                    status === 1 ? ([
                        <PauseCircleOutlined key="1" onClick={pauseOnClick} />,
                        <CloseCircleOutlined onClick={quitPlay} key="2" />
                    ]) : status === 2 ? ([
                        <PlayCircleOutlined key="1" onClick={continueOnClick} />,
                        <CloseCircleOutlined onClick={quitPlay} key="2" />
                    ]) : status === 3 ? (
                        <PlayCircleOutlined onClick={replayOnClick} />
                    ) : null
                }
            </Space>
            <div className="scene_select_wrapper">
                <Space>
                    {
                        scenesInfo
                            .map((item, index) => {
                                return (
                                    <div className={"scene_tag" + (currSceneIndex === index ? " curr_scene" : "")} onClick={() => jump2Scene(item)} key={index}>
                                        <div className="text">{index + 1}</div>
                                    </div>
                                );
                            })
                    }
                </Space>
            </div>
            <div className="screen_related_tool">
                {
                    isFullScreen === true ?
                        (
                            <FullscreenExitOutlined onClick={exitFullScreen} />
                        ) : (
                            <FullscreenOutlined onClick={enterFullScreen} />
                        )
                }
            </div>
        </div>
    );
};

export default Comp;
