import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, renderGaugeChart, mockList } from './utils';

const Page = (props) => {

    const refBottom = useRef(null);
    const refTopLeft = useRef(null);
    const refTopRight = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, mockList);
        renderGaugeChart(refTopLeft.current, mockList);
        renderGaugeChart(refTopRight.current, mockList);
    }, []);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="item">
                    <div className="left">
                        <div className="title">当月累计</div>
                        <div className="v_wrapper">
                            <div className="value">53686</div>
                        </div>
                        <div className="unit">tCO₂</div>
                    </div>
                    <div className="right">
                        <div className="chart" ref={refTopLeft}></div>
                    </div>
                </div>
                <div className="item">
                    <div className="left">
                        <div className="title">当年累计</div>
                        <div className="v_wrapper">
                            <div className="value">152903</div>
                        </div>
                        <div className="unit">tCO₂</div>
                    </div>
                    <div className="right">
                        <div className="chart" ref={refTopRight}></div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="title">当年月趋势</div>
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
