import React from 'react';
import { get } from '../../../common/utils';
import moment from 'moment';
import { Button, DatePicker, Table, Select } from 'antd';

import './Content.scss';
import DetailModal from './DetailModal.js';
const { Option } = Select;

let ALARM_LEVELS = [
    {
        level: 1,
        name: '一级告警',
    }, {
        level: 2,
        name: '二级告警',
    }, {
        level: 3,
        name: '三级告警',
    }
]

export default class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            systems: [],
            selectAlarm: null,
            volumeOff: true,
        };
    }

    componentDidMount() {
        this.getSystems();
        // setTimeout(() => {
        //     const utterThis = new window.SpeechSynthesisUtterance('2 3 4 4 4，分解炉中段温度报警');
        //     utterThis.pitch = 2;
        //     utterThis.onstart = () => {
        //         console.log('onstart');
        //     };
        //     this.speeking = window.speechSynthesis.speak(utterThis);
        // }, 2000);
    }

    getSystems = () => {
        get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_prod_system_list').then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ systems: retData.data.results });
            };
        });
    }

    // volumeControlOnclick = () => {
    //     const { volumeOff } = this.state;
    //     this.setState({ volumeOff: !volumeOff });
    //     if (volumeOff) {
    //         const utterThis = new window.SpeechSynthesisUtterance('2 3 4 4 4，分解炉中段温度报警');
    //         utterThis.pitch = 2;
    //         this.speeking = window.speechSynthesis.speak(utterThis);
    //     } else {
    //     };
    // }

    render() {
        const { modalVisible, selectAlarm } = this.state;
        const { dataSource, filterSysId, filterLevel, dayBegin, dayEnd, total } = this.props;
        const { sysIdOnchange = () => { }, levelOnchange = () => { }, rangeOnchange = () => { }, getAlarmList = () => { }, pageOnchange = () => { } } = this.props;

        return (
            <div className="alarm_page_content_wrapper">
                <div className="content_wrapper">
                    <div className="header">
                        <Select
                            defaultValue="all"
                            value={filterSysId}
                            style={{ width: 180 }}
                            onChange={sysIdOnchange}
                        >
                            <Option value='all'>全部系统</Option>
                            {
                                this.state.systems.map(({ id, name }) =>
                                    <Option value={id + ''} key={id}>{name}</Option>)
                            }
                        </Select>
                        <Select
                            style={{ width: 144, marginLeft: 24 }}
                            defaultValue="all"
                            value={filterLevel}
                            onChange={levelOnchange}
                        >
                            <Option value='all'>全部告警</Option>
                            {
                                ALARM_LEVELS.map(({ level, name }) =>
                                    <Option value={level} key={level}>{name}</Option>
                                )
                            }
                        </Select>
                        <span style={{ marginLeft: 20 }}>告警时间：</span>
                        <DatePicker.RangePicker
                            value={[dayBegin, dayEnd]}
                            onChange={rangeOnchange}
                            disabledDate={current => current > moment().endOf('day')}
                            style={{ width: 240 }}
                        />
                        <Button type="primary" style={{ marginLeft: 24 }} onClick={getAlarmList}>查询</Button>
                        {/* <div className="alarm_volume_wrapper" onClick={this.volumeControlOnclick}>
                            <img alt="" src={volumeOff ? VOLUME_OFF_ICON : VOLUME_UP_ICON} className="icon" />
                            <div className="volume_text_hint">语音报警已{volumeOff ? '关闭' : '开启'}</div>
                        </div> */}
                    </div>

                    <Table
                        dataSource={dataSource}
                        pagination={{
                            defaultCurrent: 1,
                            pageSize: 10,
                            total: total,
                            onChange: pageOnchange
                        }}
                        rowClassName={({ time_end }, index) => time_end ? '' : 'not_end_alarm'}
                        columns={[
                            {
                                title: '点位名称',
                                key: 'title',
                                render: (text, item, index) => {
                                    const { alarm_meta: { data_meta: { name } = {} }, time_end } = item;
                                    return (
                                        <div className={time_end ? '' : 'not_end_title'}>
                                            <span>{name}</span>
                                        </div>
                                    );
                                }
                            },
                            {
                                title: '所属系统',
                                key: 'system',
                                render: (text, { alarm_meta: { sys_name } }) => sys_name
                            },
                            {
                                title: '告警类型',
                                dataIndex: 'type',
                                render: (text) => {
                                    if (parseInt(text) === 1) {
                                        return '超上限告警';
                                    } else if (parseInt(text) === 2) {
                                        return '超下限告警';
                                    } else if (parseInt(text) === 3) {
                                        return '波动告警';
                                    }
                                },
                                align: 'center',
                            },
                            {
                                title: '告警起止时间',
                                key: 'alarmTime',
                                render: (text, { time_begin, time_end }, index) => (
                                    <span>{time_begin}<br />{time_end || '—— ——'}</span>
                                ),
                                align: 'center',
                            },
                            {
                                title: '持续时长',
                                dataIndex: 'duration',
                                align: 'center',
                            },
                            {
                                title: '警告级别',
                                key: 'level',
                                render: (text, record) => {
                                    return ALARM_LEVELS.filter(item => item.level === record.alarm_meta.level)[0].name
                                },
                                align: 'center',
                            },
                            {
                                title: '操作',
                                key: 'action',
                                render: (text, record, index) => (
                                    <Button type="link" onClick={() => this.setState({ selectAlarm: record, modalVisible: true })}>查看</Button>
                                ),
                                align: 'center',
                            },
                        ]}
                    />
                    {
                        modalVisible && (
                            <DetailModal
                                data={selectAlarm}
                                onCancel={() => this.setState({ selectAlarm: null, modalVisible: false })}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}
