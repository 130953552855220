import React from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';

const IndexBar= (props) => {
    const { barChart } = props
    useEffect(() => {
        renderChart();
    }, [barChart]);
    const chartNode = useRef(null);
    const renderChart = () => {
        if (!!chartNode.current) {
            // render chart
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption({
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: '<span>{c}</span>'
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    show: false,
                    splitLine:{
                        show: false
                    },
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisTick:{ //y轴刻度线
                        show:false
                    },
                    axisLine:{ //y轴
                        show:false
                    },
                    max: Math.max.apply(null, barChart) + ((Math.max.apply(null, barChart) - (Math.max.apply(null, barChart)) * 0.1)),
                    min: Math.min.apply(null, barChart) - ((Math.max.apply(null, barChart) - (Math.max.apply(null, barChart)) * 0.1))
                },
                series: [
                    {
                        data: barChart,
                        type: 'bar',
                        itemStyle: {
                            color: '#33C2A4'
                        },
                        barWidth: 8
                    },
                ],
            });
        };
    };
    return (
        <div>
            <div ref={chartNode} style={{ width: 248,height: 65 }} />
        </div>
    );
};

export default IndexBar;
