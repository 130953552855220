import { Pagination, Select, Radio, Spin } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize';
import { useData } from './hooks';
import moment from 'moment';
import CusPageSize from '../../components/PageSize';
import { useTableData } from '../../hooks';

const { Option } = Select;

const margin_width = 24;

const get_current_time = () => {
    const curr_time = moment();
    const parsed_current_time = moment(curr_time).format("YYYY-MM-DD HH:00:00");
    return parsed_current_time;
};
const radio_conf = [
    {
        name: "月计划",
        plan_type: "MONTH",
        get_current_time,
    },
    {
        name: "年计划",
        plan_type: "YEAR",
        get_current_time,
    },
]
    .map((item, index) => Object.assign({}, item, { key: index }));

const Page = (props) => {

    const [selected_radio, set_selected_radio] = useState(null);
    useEffect(() => {
        set_selected_radio(radio_conf[0]);
    }, []);
    const radioOnChange = (e) => {
        const next_selected_radio = radio_conf.filter(({ key }) => key === e.target.value)[0];
        set_selected_radio(next_selected_radio);
    };

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current(Object.assign({}, selected_radio ?? {}, !!selected_radio ? {
            current_time: selected_radio.get_current_time(),
        } : {}));
    }, [selected_radio]);

    const [width, setWidth] = useState(null);
    const onResize = (conf) => {
        setWidth(conf.width);
    };

    const {
        display_list,
        paginationInfo, onChange,
        sysInfo, sysOnChange, prodInfo, prodOnChange, catInfo, catOnChange, alarmInfo, alarmOnChange,
    } = useTableData({ data });

    return (
        <div className={style['wrapper']}>
            <div className="top" style={{ paddingLeft: margin_width }}>
                <div className="item">
                    <div className="title">选择系统</div>
                    <div className="value">
                        <Select
                            value={sysInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => sysOnChange(v)}
                        >
                            <Option value="all">全部系统</Option>
                            {
                                (sysInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="title">选择产品</div>
                    <div className="value">
                        <Select
                            value={prodInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => prodOnChange(v)}
                        >
                            <Option value="all">全部产品</Option>
                            {
                                (prodInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="title">指标类型</div>
                    <div className="value">
                        <Select
                            value={catInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => catOnChange(v)}
                        >
                            <Option value="all">全部类型</Option>
                            {
                                (catInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="title">预测结果</div>
                    <div className="value">
                        <Select
                            value={alarmInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => alarmOnChange(v)}
                        >
                            <Option value="all">全部</Option>
                            {
                                (alarmInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="right_wrapper" style={{ paddingRight: margin_width }}>
                    <Radio.Group value={selected_radio?.key ?? null} onChange={radioOnChange}>
                        {
                            radio_conf
                                .map((item, index) => {
                                    return (
                                        <Radio value={item.key} key={index}>{item.name}</Radio>
                                    );
                                })
                        }
                    </Radio.Group>
                </div>
            </div>
            <div style={{ height: 0 }}>
                <DomResize onResize={onResize} mountedCall={true} />
            </div>
            {
                typeof width === 'number' && (
                    <div className="bottom">
                        <div className="scroll_wrapper scroll_bar_restyle">
                            <div className="content" style={{ marginLeft: margin_width, width: width - 2 * margin_width }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="name">指标名称</th>
                                            <th>所属系统</th>
                                            <th>产品类型</th>
                                            <th>指标类型</th>
                                            <th>单位</th>
                                            <th>累计生产小时</th>
                                            <th>指标统计值</th>
                                            <th>剩余计划小时</th>
                                            <th>指标预测值</th>
                                            <th>指标标准值</th>
                                            <th>指标范围</th>
                                            <th>预测结果</th>
                                            <th className="action">操作</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (display_list ?? [])
                                                .map((item, index) => {
                                                    const {
                                                        name, sys_name, product_name, category_name, unit,
                                                        parsed_hours, parsed_rest_hours, parsed_value, parsed_pred_value,
                                                        parsed_standard, parsed_range, alarm_status,
                                                    } = item;
                                                    return (
                                                        <tr key={index}>
                                                            <td className="name">{name}</td>
                                                            <td>{sys_name}</td>
                                                            <td>{product_name}</td>
                                                            <td>{category_name}</td>
                                                            <td>{unit}</td>
                                                            <td>{parsed_hours}</td>
                                                            <td>{parsed_value}</td>
                                                            <td>{parsed_rest_hours}</td>
                                                            <td className="pred_value">{parsed_pred_value}</td>
                                                            <td>{parsed_standard}</td>
                                                            <td>{parsed_range}</td>
                                                            <td className={"result" + (alarm_status === 0 ? " normal" : (alarm_status === 1 ? " alarm" : ""))}>
                                                                {alarm_status === 0 ? "正常" : (alarm_status === 1 ? "告警" : "--")}
                                                            </td>
                                                            <td className="action">
                                                                <div className="action_wrapper">
                                                                    <div className="btn">调整计划</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="footer_wrapper" style={{ paddingRight: margin_width }}>
                {
                    !!paginationInfo && paginationInfo?.total !== 0 && [
                        (
                            <Pagination
                                current={paginationInfo.current}
                                total={paginationInfo.total}
                                // showSizeChanger={true}
                                // 这个属性和缩放冲突...先false略去
                                showSizeChanger={false}
                                pageSizeOptions={paginationInfo.pageSizeOptions}
                                pageSize={paginationInfo.pageSize}
                                onChange={onChange}
                                key="1"
                            />
                        ),
                        (
                            <div className="page_size_selector" key="2">
                                <CusPageSize pageSizeOptions={paginationInfo.pageSizeOptions} pageSize={paginationInfo.pageSize} onChange={(pageSize) => onChange(1, pageSize)} />
                            </div>
                        ),
                    ]
                }
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Page;
