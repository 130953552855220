import React from 'react';
import { Select } from 'antd';
import echarts from 'echarts';

import TREND_ICON from './res/trend_icon.png';
import TITLE_ICON from './res/title_left_icon.png';

import './ProductElectricalTrend.scss';

const { Option } = Select;

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        if (!!this.refNode) {
            // render chart
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            const xAxisData = new Array(124)
                .fill(0)
                .map((_, index) => index);
            const barData = new Array(124)
                .fill(0)
                .map(() => 50 + Math.ceil(Math.random() * 100));
            let maxY = Math.ceil(Math.max(...barData));
            if (maxY === 0) {
                maxY = 10;
            };
            if (maxY % 2 === 1) {
                maxY++;
            };
            instance.setOption({
                grid: {
                    left: 64,
                    top: 10,
                    right: 40,
                    bottom: 18,
                },
                xAxis: {
                    type: 'category',
                    // show: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(58, 63, 70, 1)',
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    // boundaryGap: false,
                    data: xAxisData,
                },
                yAxis: {
                    type: 'value',
                    // show: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(58, 63, 70, 1)',
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    // min: yMin,
                    // max: yMax,
                },
                series: [
                    {
                        data: barData,
                        type: 'bar',
                        itemStyle: {
                            color: 'rgba(74, 155, 135, 1)',
                            barBorderRadius: 2,
                        },
                        barWidth: 4,
                        // markLine: {
                        //     silent: true,
                        //     animation: false,
                        //     symbol: ['none', 'none'],
                        //     data: [0, maxY / 2, maxY]
                        //         .map(v => ({
                        //             yAxis: v,
                        //             label: {
                        //                 show: false,
                        //             },
                        //             lineStyle: {
                        //                 color: 'rgba(58, 63, 70, 1)',
                        //                 type: 'solid',
                        //             },
                        //         }))
                        //         .concat(
                        //             [0, maxY / 2, maxY]
                        //                 .map(v => ({
                        //                     yAxis: v,
                        //                     label: {
                        //                         show: true,
                        //                         position: 'start'
                        //                     },
                        //                     lineStyle: {
                        //                         color: 'rgba(159, 160, 186, 1)',
                        //                         width: 0,
                        //                     },
                        //                 }))
                        //         ),
                        // },
                    },
                ],
            });
        };
    }

    render() {

        return (

            <div className="product_electrical_trend_wrapper">
                <div className="item_header_wrapper">
                    <img alt="" src={TREND_ICON} className="item_header_icon" />
                    <div className="title">产品电单耗趋势</div>
                    <div className="sub_title">【 2020年12月20日 】</div>
                </div>
                <div className="item_content_wrapper">
                    <div className="inner_content_wrapper">
                        <div className="title_wrapper">
                            <img alt="" src={TITLE_ICON} className="icon" />
                            <div className="text">选择指标</div>
                            <Select defaultValue="lucy" style={{ width: 160, marginLeft: 8 }}>
                                <Option value="jack">一个月</Option>
                                <Option value="lucy">三个月</Option>
                                <Option value="Yiminghe">六个月</Option>
                            </Select>
                        </div>
                        <div className="bottom_content_wrapper">
                            <div className="left">
                                <div className="block_wrapper">
                                    <div className="value_unit_wrapper">
                                        <div className="value">30.33</div>
                                        <div className="unit">kwh/t</div>
                                    </div>
                                    <div className="name">指标平均值</div>
                                </div>
                                <div className="block_wrapper">
                                    <div className="value_unit_wrapper">
                                        <div className="value">23.33</div>
                                        <div className="unit">kwh/t</div>
                                    </div>
                                    <div className="name">指标标准值</div>
                                </div>
                                <div className="block_wrapper">
                                    <div className="value_unit_wrapper">
                                        <div className="value">83.45</div>
                                        <div className="unit">%</div>
                                    </div>
                                    <div className="name">指标PHI</div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="line_sign_wrapper">
                                    <div className="line_1" style={{ backgroundColor: 'rgba(68, 243, 218, 1)' }} />
                                    <div className="line_1_text">小时均值</div>
                                    <div className="line_2" style={{ borderTopColor: 'rgba(255, 97, 112, 1)' }} />
                                    <div className="line_2_text">基准值</div>
                                </div>
                                <div className="chart_wrapper">
                                    <div className="chart_self" ref={refNode => { this.refNode = refNode }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
