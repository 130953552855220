import React from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';

const AbnormalLineChart = (props) => {
    const { abnormalChart, abnormalTime, basicTop } = props;
    useEffect(() => {
        renderChart();
    }, [abnormalChart, abnormalTime, basicTop]);
    const chartNode = useRef(null);
    const renderChart = () => {
        if(!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption({
                animation: true,
                tooltip: {
                    trigger: 'axis',
                    symbol: 'none',
                },
                grid: {
                    left: 40,
                    top: 10,
                    right: 30,
                    bottom: 10,
                },
                xAxis: {
                    show: false,
                    splitLine:{
                        show: false
                    },
                    type: 'category',
                    data: abnormalTime
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisTick:{ //y轴刻度线
                        show:false
                    },
                    axisLine:{ //y轴
                        show:false
                    },
                    max: Math.max.apply(null, abnormalChart) + (Math.max.apply(null, abnormalChart) * 0.1),
                    min: Math.min.apply(null, abnormalChart) - (Math.min.apply(null, abnormalChart) * 0.1)
                },
                series: [{
                    data: abnormalChart,
                    type: 'line',
                    lineStyle:{
                        color:'#FF6161',
                        width: 1,
                    },
                    symbol: 'none',
                    markLine: {
                        symbol: 'none',
                        data: [{
                            silent: false,
                            lineStyle:{
                                type:"dashed",
                                color:"#355B99",
                            },
                            label:{
                                position:'start',
                                formatter: `${basicTop}`,
                                color: "#727272"
                            },
                            yAxis: basicTop
                        }]
                    }
                }]
            })
        }
    };
    return (
        <div style={{background: "#F5F5F5",position: "relative"}}>
            <div ref={chartNode} style={{ width: 976, height: 56 }} />
        </div>
    );
}
export default AbnormalLineChart
