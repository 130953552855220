const ZONE_COLOR = {
    1: 'rgba(251, 96, 96, 1)',
    2: 'rgba(255, 218, 119, 1)',
    3: 'rgba(91, 174, 141, 1)',
};
const ZONE_NAME = {
    1: '红区',
    2: '黄区',
    3: '绿区',
};

export {
    ZONE_COLOR,
    ZONE_NAME,
};