import React from 'react';
import style from './index.module.scss';
import OverView from 'containers/FactorRelated/Overview';

const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className="content">
                <OverView />
            </div>
        </div>
    );
};

export default Page;
