import React, { useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import KeyFactors from './KeyFactors';
import Factors from './Factors';
import TableBlock from './Table';
import TreeMapBlock from './TreeMap';
import BarChartBlock from './BarChart';
import BarRankBlock from './BarRank';
import ICON_1 from './res/icon_1.svg';
import ICON_6 from './res/icon_6.svg';
import ICON_7 from './res/icon_7.svg';
import { factors_datas, table_datas } from './utils';

const Page = (props) => {
    const [timeInfo, setTimeInfo] = useState(null);

    return (
        <div className={style['wrapper']}>
            <Header timeInfo={timeInfo} setTimeInfo={setTimeInfo} />
            <div className="content_wrapper">
                <div className="inner_wrapper scroll_bar_restyle scroll_bar_restyle_2">
                    <div className="content">
                        <div className="left">
                            <KeyFactors timeInfo={timeInfo} />
                        </div>
                        <div className="right">
                            <div className="item_wrapper">
                                <div className="item">
                                    <BarChartBlock title="计划" icon={ICON_1} timeInfo={timeInfo} />
                                </div>
                            </div>
                            {
                                !!factors_datas && factors_datas
                                    .map((item, index) => {
                                        return (
                                            <div className="item_wrapper" key={index}>
                                                <div className="item">
                                                    <Factors {...item} timeInfo={timeInfo} />
                                                </div>
                                            </div>
                                        );
                                    })
                            }
                            <div className="item_wrapper" style={{ marginBottom: 0 }}>
                                <div className="item">
                                    <BarRankBlock title="报警统计" icon={ICON_7} timeInfo={timeInfo} />
                                </div>
                            </div>
                            {
                                !!table_datas && table_datas
                                    .filter((item, index) => index < 1)
                                    .map((item, index) => {
                                        return (
                                            <div className="item_wrapper" style={{ marginBottom: 0 }} key={index}>
                                                <div className="item">
                                                    <TableBlock {...item} timeInfo={timeInfo} />
                                                </div>
                                            </div>
                                        );
                                    })
                            }
                            <div className="item_wrapper">
                                <div className="item">
                                    <TreeMapBlock title="成本" icon={ICON_6} timeInfo={timeInfo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
