import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import * as echarts from 'echarts~5';
import { useData } from './hooks';
import { Spin } from 'antd';
import moment from 'moment';

const renderChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!data) {
            instance.clear();
            return;
        };

        const xData = data.map(({ time }) => moment(time).format("MM/DD"));
        const yData_1 = data.map(({ cost: value }) => ({ value }));
        const yData_2 = data.map(({ pot_cost: value }) => ({ value }));

        instance.setOption({
            grid: {
                containLabel: true,
                left: 10,
                top: 36,
                right: 26,
                bottom: 10,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [{ axisValueLabel, marker: marker_1, value: value_1 }, { marker: marker_2, value: value_2 }] = params;
                    const parsed_values = [value_1, value_2]
                        .map((v) => typeof v === 'number' ? Math.floor(v * 1000) / 1000 : "--");
                    return `${axisValueLabel}<br />${marker_1}${parsed_values[0]}<br />${marker_2}${parsed_values[1]}`;
                },
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    color: '#AAAAAA',
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#AAAAAA',
                },
            },
            series: [
                {
                    data: yData_1,
                    type: 'bar',
                    barWidth: 10,
                    stack: '1',
                    itemStyle: {
                        color: '#355B99',
                        borderRadius: 2,
                    },
                },
                {
                    data: yData_2,
                    type: 'bar',
                    barWidth: 10,
                    stack: '1',
                    itemStyle: {
                        color: '#5BAE8D',
                        borderRadius: 2,
                    },
                },
            ]
        });
    };
};

const Comp = (props) => {
    const { selectedProduct } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        // 需要把空的selectedProduct id传进去，支持未来空的product选择
        setDataFunc.current(Object.assign({}, selectedProduct ?? {}, selectedProduct?.timeInfo ?? {}));
    }, [selectedProduct]);

    const refNode = useRef(null);
    useEffect(() => {
        const history_datas = data?.history_datas;
        if (!!refNode.current) {
            renderChart(refNode.current, history_datas);
        };
    }, [data]);

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="成本趋势"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <div className="top">
                        <div className="hint_wrapper">
                            <div className="colored_block" style={{ backgroundColor: '#355B99' }}></div>
                            <div className="text">当前时段</div>
                            <div className="colored_block" style={{ backgroundColor: '#5BAE8D', marginLeft: 20 }}></div>
                            <div className="text">优化潜力</div>
                            <div className="date">{data?.parsed_basic_info?.date_range ?? ""}</div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="left">
                            <div className="chart" ref={refNode}></div>
                        </div>
                        <div className="right">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="title">
                                            <div className="value_wrapper">
                                                {
                                                    "日期"
                                                        .split("")
                                                        .map((v, index) => {
                                                            return (
                                                                <div className="value" key={index}>{v}</div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </td>
                                        <td className="dots">：</td>
                                        <td className="value">{data?.parsed_basic_info?.date ?? ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">
                                            <div className="value_wrapper">
                                                {
                                                    "产品名称"
                                                        .split("")
                                                        .map((v, index) => {
                                                            return (
                                                                <div className="value" key={index}>{v}</div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </td>
                                        <td className="dots">：</td>
                                        <td className="value">{data?.parsed_basic_info?.product_name ?? ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">
                                            <div className="value_wrapper">
                                                {
                                                    "实际成本"
                                                        .split("")
                                                        .map((v, index) => {
                                                            return (
                                                                <div className="value" key={index}>{v}</div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </td>
                                        <td className="dots">：</td>
                                        <td className="value">{data?.parsed_basic_info?.cost_text ?? ""}{data?.parsed_basic_info?.unit ?? ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">
                                            <div className="value_wrapper">
                                                {
                                                    "优化潜力"
                                                        .split("")
                                                        .map((v, index) => {
                                                            return (
                                                                <div className="value" key={index}>{v}</div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </td>
                                        <td className="dots">：</td>
                                        <td className="value">{data?.parsed_basic_info?.pot_cost_text ?? ""}{data?.parsed_basic_info?.unit ?? ""}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
