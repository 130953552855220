import React, { useEffect, useMemo, useRef, useState } from 'react'
import style from './index.module.scss'
import { useData } from './hooks';
import { Button, message, Space, Table, Modal } from 'antd';
import { reqDelete } from 'common/utils';
import moment from 'moment';

const blank_func = () => { };

const default_columns = [
  {
    title: '表单编号',
    dataIndex: "id",
  },
  {
    title: '状态',
    dataIndex: "status",
  },
  {
    title: '模板编码',
    dataIndex: "tpl_code",
  },
];

/** 事件模版树 */
const Com = props => {
  const { tplCode, refresh, tableJson, keyword, readonly } = props;

  const [innerRefresh, setInnerRefresh] = useState(() => {
    return Symbol();
  });

  const [page, setPage] = useState(1);

  const setConfFunc = useRef(blank_func);
  const [data, setConf, loading] = useData();
  useEffect(() => {
    setConfFunc.current = typeof setConf === 'function' ? setConf : blank_func;
  }, [setConf]);

  useEffect(() => {
    setConfFunc.current(
      typeof tplCode === 'string'
        ? {
          tplCode,
          page,
          keyword,
        }
        : null
    );
  }, [tplCode, page, refresh, innerRefresh, keyword]);

  useEffect(() => {
    setPage(1);
  }, [tplCode, refresh]);

  const editForm = (id) => {
    if (typeof props.editForm === 'function') {
      props.editForm(id);
    };
  };

  const deleteForm = (id) => {
    Modal.confirm({
      content: `确认删除该表单吗?`,
      onOk: () => {
        reqDelete(process.env.REACT_APP_SERVER_PATH + `api/form/forms/${id}`)
          .then((retData) => {
            // console.log(retData);
            if (retData?.data?.errcode === 0) {
              message.success("删除成功!");
              // 刷新列表
              setInnerRefresh(Symbol());
            } else {
              message.warning("删除失败!");
            };
          });
      },
    });
  };

  const columns = useMemo(() => {
    const cus_columns = (tableJson?.columns ?? default_columns)
      .map(column => {
        const trans_list = column?.trans_list ?? null
        if (!!trans_list) {
          column.render = t => (trans_list ?? []).find(([key]) => key === t)?.[1] ?? ''
        }
        return column
      })
    const final_columns = []
      .concat(
        cus_columns,
        [
          {
            title: '创建时间',
            dataIndex: "create_time",
            render: t => moment(t).format("YYMMDD HH:mm"),
            width: 130,
          },
          {
            title: '操作',
            key: 'action',
            render: (text, record, rowIndex) => {
              const { id } = record;
              return (
                <Space>
                  <Button size="small" type="primary" ghost onClick={() => editForm(id)}>查</Button>
                  {
                    !readonly && (
                      <Button size="small" danger onClick={() => deleteForm(id)}>删</Button>
                    )
                  }
                </Space>
              );
            },
            width: 110,
          },
        ],
      )
    return final_columns;
  }, [tableJson, readonly]);

  return (
    <div className={style['eventTable']}>
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data?.parsed_results ?? []}
        pagination={{
          current: page,
          pageSize: 20,
          total: data?.count ?? 0,
          onChange: (page) => setPage(page),
          showSizeChanger: false,
          showQuickJumper: true
        }}
      ></Table>
    </div>
  )
}

export default Com
