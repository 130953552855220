import React from "react";
import './survive.scss'
import SurvivePieChart from './SurvivePieChart'
const SurviveTable = (props) => {
    const {
        naturalGas_single,
        naturalGas_comparison,
        naturalGas_dayTotal,
        naturalGas_MonthTotal,
        naturalGas_YearTotal,
        electric_single,
        electric_comparison,
        electric_dayTotal,
        electric_MonthTotal,
        electric_YearTotal,
        nitrogen_single,
        nitrogen_comparison,
        nitrogen_dayTotal,
        nitrogen_MonthTotal,
        nitrogen_YearTotal,
        gasNumber,
        electricNumber,
        nitrogenNumber,
    } = props
    return (
        <div className="surviveTable">
            <div className="surviveTable-title">能耗情况</div>
            <div className="surviveTable-content">
                <div className="surviveTable-table">
                    <div className="surviveTable-table-th" style={{backgroundColor: "#F5F5F5"}}>
                        <div>名称</div>
                        <div>单耗</div>
                        <div>环比</div>
                        <div>日累</div>
                        <div>月累</div>
                        <div style={{paddingRight: 16}}>年累</div>
                        <div></div>
                    </div>
                    <div className="surviveTable-table-tr">
                        <div className="left" style={{paddingLeft: 16}}>燃料气</div>
                        <div>
                            <span className="bold">{naturalGas_single}</span>
                            <span className="light">Nm³/万Nm³</span>
                        </div>
                        <div style={{fontSize: 24}}>{`${naturalGas_comparison}%`}</div>
                        <div className="center">{naturalGas_dayTotal}</div>
                        <div className="center">{naturalGas_MonthTotal}</div>
                        <div className="center">{naturalGas_YearTotal}</div>
                        <div className="center" style={{fontSize: 20}}>万Nm³</div>
                    </div>
                    <div className="surviveTable-table-tr">
                        <div className="left" style={{paddingLeft: 16}}>电</div>
                        <div>
                            <span className="bold">{electric_single}</span>
                            <span className="light">kWh/万Nm³</span>
                        </div>
                        <div style={{fontSize: 24}}>{`${electric_comparison}%`}</div>
                        <div className="center">{electric_dayTotal}</div>
                        <div className="center">{electric_MonthTotal}</div>
                        <div className="center">{electric_YearTotal}</div>
                        <div className="center" style={{fontSize: 20}}>kWh</div>
                    </div>
                    {/*<div className="surviveTable-table-tr">*/}
                    {/*    <div className="left" style={{paddingLeft: 16}}>氮气</div>*/}
                    {/*    <div>*/}
                    {/*        <span className="bold">{nitrogen_single}</span>*/}
                    {/*        <span className="light">Nm³/万Nm³</span>*/}
                    {/*    </div>*/}
                    {/*    <div style={{fontSize: 24}}>{`${nitrogen_comparison}%`}</div>*/}
                    {/*    <div className="center">{nitrogen_dayTotal}</div>*/}
                    {/*    <div className="center">{nitrogen_MonthTotal}</div>*/}
                    {/*    <div className="center">{nitrogen_YearTotal}</div>*/}
                    {/*    <div className="center" style={{fontSize: 20}}>万Nm³</div>*/}
                    {/*</div>*/}
                </div>
                <div className="surviveTable-content-right">
                    <div className="title">能耗成本占比</div>
                    <div style={{display: "flex"}}>
                        <SurvivePieChart
                            gasNumber={gasNumber}
                            electricNumber={electricNumber}
                        />
                        <div className="legend_big">
                            <div>
                                <span className="blue"></span>
                                <span className="font24">燃料气</span>
                            </div>
                            <div style={{marginTop: 36}}>
                                <span className="green"></span>
                                <span className="font24">电</span>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>
    )
}
export default SurviveTable
