import React from 'react'
import './index.scss'
import IndexBar from "../YieldTarget/BarChart";
const ProductionTarget = (props) => {
    const {
        feedGasChart,
        naturalGasChart,
        ethaneChart,
        propaneChart,
        butaneChart,
        olefinChart,
        feedGasNumber,
        feedGasPercent,
        feedGasComparison,
        naturalGasNumber,
        naturalGasPercent,
        naturalGasComparison,
        ethaneNumber,
        ethanePercent,
        ethaneComparison,
        propaneNumber,
        propanePercent,
        propaneComparison,
        butaneNumber,
        butanePercent,
        butaneComparison,
        olefinNumber,
        olefinPercent,
        olefinComparison,
    } = props;
    return (
        <div className="productionTarget">
            <div className="productionTarget-header">
                <div className="productionTarget-header-left">生产指标</div>
                <div className="productionTarget-header-right">小时平均:近10日</div>
            </div>
            <div className="productionTarget-item">
                <div className="productionTarget-item-left">
                    <div className="left-top">
                        <span className="left-top-name">原料气</span>
                        <span className="left-top-type">Nm³/h</span>
                    </div>
                    <div className="left-bottom">
                        {/*<span className="left-bottom-number">{Math.round(feedGasNumber)}</span>*/}
                        <span className="left-bottom-number">{feedGasNumber}</span>
                        <span className="left-bottom-percent">
                            <span className={`triangle ${feedGasPercent > 0? 'up': 'down'}`}></span>
                            <span>{feedGasPercent}%</span>
                        </span>
                        <span className="left-bottom-comparison">环比值{feedGasComparison}</span>
                    </div>
                </div>
                <IndexBar barChart={feedGasChart}/>
            </div>

            <div className="productionTarget-item">
                <div className="productionTarget-item-left">
                    <div className="left-top">
                        <span className="left-top-name">天然气</span>
                        <span className="left-top-type">Nm³/h</span>
                    </div>
                    <div className="left-bottom">
                        {/*<span className="left-bottom-number">{Math.round(naturalGasNumber)}</span>*/}
                        <span className="left-bottom-number">{naturalGasNumber}</span>
                        <span className="left-bottom-percent">
                            <span className={`triangle ${naturalGasPercent > 0? 'up': 'down'}`}></span>
                            <span>{naturalGasPercent}%</span>
                        </span>
                        <span className="left-bottom-comparison">环比值{naturalGasComparison}</span>
                    </div>
                </div>
                <IndexBar barChart={naturalGasChart}/>
            </div>

            <div className="productionTarget-item">
                <div className="productionTarget-item-left">
                    <div className="left-top">
                        <span className="left-top-name">乙烷</span>
                        <span className="left-top-type">Nm³/h</span>
                    </div>
                    <div className="left-bottom">
                        {/*<span className="left-bottom-number">{Math.round(ethaneNumber)}</span>*/}
                        <span className="left-bottom-number">{ethaneNumber}</span>
                        <span className="left-bottom-percent">
                            <span className={`triangle ${ethanePercent > 0? 'up': 'down'}`}></span>
                            <span>{ethanePercent}%</span>
                        </span>
                        <span className="left-bottom-comparison">环比值{ethaneComparison}</span>
                    </div>
                </div>
                <IndexBar barChart={ethaneChart}/>
            </div>
            <div className="productionTarget-item">
                <div className="productionTarget-item-left">
                    <div className="left-top">
                        <span className="left-top-name">丙烷</span>
                        <span className="left-top-type">kg/h</span>
                    </div>
                    <div className="left-bottom">
                        {/*<span className="left-bottom-number">{Math.round(propaneNumber)}</span>*/}
                        <span className="left-bottom-number">{propaneNumber}</span>
                        <span className="left-bottom-percent">
                            <span className={`triangle ${propanePercent > 0? 'up': 'down'}`}></span>
                            <span>{propanePercent}%</span>
                        </span>
                        <span className="left-bottom-comparison">环比值{propaneComparison}</span>
                    </div>
                </div>
                <IndexBar barChart={propaneChart}/>
            </div>

            <div className="productionTarget-item">
                <div className="productionTarget-item-left">
                    <div className="left-top">
                        <span className="left-top-name">丁烷</span>
                        <span className="left-top-type">kg/h</span>
                    </div>
                    <div className="left-bottom">
                        {/*<span className="left-bottom-number">{Math.round(butaneNumber)}</span>*/}
                        <span className="left-bottom-number">{butaneNumber}</span>
                        <span className="left-bottom-percent">
                            <span className={`triangle ${butanePercent > 0? 'up': 'down'}`}></span>
                            <span>{butanePercent}%</span>
                        </span>
                        <span className="left-bottom-comparison">环比值{butaneComparison}</span>
                    </div>
                </div>
                <IndexBar barChart={butaneChart}/>
            </div>

            <div className="productionTarget-item" style={{marginBottom: 0}}>
                <div className="productionTarget-item-left">
                    <div className="left-top">
                        <span className="left-top-name">烯烃</span>
                        <span className="left-top-type">kg/h</span>
                    </div>
                    <div className="left-bottom">
                        {/*<span className="left-bottom-number">{Math.round(olefinNumber)}</span>*/}
                        <span className="left-bottom-number">{olefinNumber}</span>
                        <span className="left-bottom-percent">
                            <span className={`triangle ${olefinPercent > 0? 'up': 'down'}`}></span>
                            <span>{olefinPercent}%</span>
                        </span>
                        <span className="left-bottom-comparison">环比值{olefinComparison}</span>
                    </div>
                </div>
                <IndexBar barChart={olefinChart}/>
            </div>

        </div>
    )
}
export default ProductionTarget
