import React, { useContext, useMemo, useEffect } from 'react';
import style from './index.module.scss';
import Tabs from 'components/BigTabs';
import CardGroup from '../CardGroup';
import CommonContext from '../context';

const tabs = [
    {
        name: (
            <div className={style['tab_item']}>
                <div className="name">生产流程</div>
                <div className="desc">过程参数及关键设备</div>
            </div>
        ),
        comp: null,
        diagramId: '005',
    },
    // {
    //     name: (
    //         <div className={style['tab_item']}>
    //             <div className="name">关键设备</div>
    //             <div className="desc">原料气压缩机</div>
    //         </div>
    //     ),
    //     comp: null,
    //     diagramId: '006',
    // },
    {
        name: (
            <div className={style['tab_item']}>
                <div className="name">电表</div>
                <div className="desc">各电表电器参数</div>
            </div>
        ),
        comp: CardGroup,
    },
];

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const selectedTab = useMemo(() => {
        const { state: { selectedTab } } = commonInfo;
        return selectedTab;
    }, [commonInfo]);
    const selectedTabIndex = useMemo(() => {
        let index = tabs.indexOf(selectedTab);
        if (index === -1) return null;
        return index;
    }, [selectedTab]);

    useEffect(() => {
        // 此处逻辑扩展为了当没有被选中时，会选中第一个
        const { state: { selectedTab }, dispatch } = commonInfo;
        if (!selectedTab && tabs.length > 0) {
            dispatch({ type: 'tabSelect', selectedTab: tabs[0] });
        };
    }, [commonInfo]);

    const onChange = (index) => {
        const { dispatch } = commonInfo;
        dispatch({ type: 'tabSelect', selectedTab: tabs[index] });
    };
    return (
        <div className={style['wrapper']}>
            <Tabs tabs={tabs} selectedIndex={selectedTabIndex} onChange={(index) => onChange(index)} itemWidth={200} />
        </div>
    );
};

export default Comp;
