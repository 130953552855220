import React, { useMemo, useState } from 'react';
import style from './index.module.scss';
import PIC_1 from './res/pic.svg';
import Card from '../SingleCard';
import ICON_SWITCH from './res/switch.svg';
import { significantNum } from 'common/utils';

const Comp = (props) => {
    const { name, left, right, data } = props;
    const [leftIndex, setLeftIndex] = useState(0);

    const nameText = useMemo(() => typeof name === 'string' ? name : "--", [name]);
    const rightValueText = useMemo(() => {
        const v = data?.[right?.key ?? null] ?? null;
        const p_v = typeof v === 'number' ? significantNum(v) : "--";
        return p_v;
    }, [right, data]);

    return (
        <div className={style['wrapper']}>
            <div className="head">
                <div className="text">{nameText}</div>
            </div>
            <div className="content">
                <div className="left">
                    {
                        !!left && left[leftIndex] && left[0] && (
                            <div className="content">
                                <div className="title_wrapper">
                                    {
                                        left.length > 1 && (
                                            <img alt="" src={ICON_SWITCH} width="16" height="16" style={{ cursor: 'pointer', marginRight: 8 }} onClick={() => { setLeftIndex(1 - leftIndex); }} />
                                        )
                                    }
                                    <div className="text">{left[leftIndex].name}</div>
                                </div>
                                <div className="cards_wrapper">
                                    {
                                        left[leftIndex].cards
                                            .map((item, index) => {
                                                const { key } = item;
                                                const v = data?.[key ?? null] ?? null;
                                                return (
                                                    <Card key={index} {...item} value={v} />
                                                );
                                            })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="line"></div>
                <div className="right">
                    <div className="title">{right.name}</div>
                    <div className="content">
                        <div className="value_wrapper">
                            <div className="value">{rightValueText}</div>
                            <div className="unit">{right.unit}</div>
                        </div>
                        <div className="pic_wrapper" style={{ backgroundImage: `url(${PIC_1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
