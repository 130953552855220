import React from 'react';
import Content from './Content';

import './index.scss';

export default class PersonalInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="personal_info_wrapper">
                <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
                <div className="root_content_wrapper">
                    <Content />
                </div>
            </div>
        );
    }
}
