import React from 'react';
import { Button, Calendar, Checkbox } from 'antd';
import { get } from 'common/utils.js';
import HourlyFactorsDiagram from './HourlyFactorsDiagram/HourlyFactorsDiagram.js';
import style from './ExamResult.module.scss';
import {
    CalendarOutlined,
} from '@ant-design/icons';
import { tmp_users_exchange } from '../../utils';

const SERVER_PATH = process.env.REACT_APP_CEMENT_SERVER_PATH;

export default class ExamResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSys: null,
            resultType: 1,
            results: [],
            details: [],
            rule_data: null,
            name: '',
            rule_name: '',

            _id: props.id,

            table_id_is_set: false,

            cusTimeRange: null,
            cusTimeWrapperVisible: false,
        }
    }

    componentDidMount() {
        this.getInitialData();
    }

    setExport = () => {
        //table
        // let tableNode = window.document.getElementById('events-table');
        // tableNode.id = 'events-table';
        this.setState({ table_id_is_set: true });
    }

    getInitialData = () => {
        const { _id: _id } = this.state;
        if (_id !== '-1') {
            get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/get_one', {
                _id
            }).then(retData => {
                console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    let r = retData.data.result;
                    const { results, details, rule_data, name, rule_name } = r;
                    this.setState({
                        results: results,
                        details: details,
                        rule_data,
                        name,
                        rule_name,
                        selectedSys: (r.results[0] || {}).sys_id || null,
                    }, this.setExport);
                };
            });
        }
    }
    tabSelected = (selectedSys) => {
        this.setState({ selectedSys });
    }

    openTimeWrapper = () => {
        this.setState({ cusTimeWrapperVisible: true });
    }

    render() {
        const { selectedSys, resultType, results, details, rule_data, name, rule_name, cusTimeWrapperVisible } = this.state;
        const curSysResult = results.filter(({ sys_id }) => sys_id === selectedSys)[0];
        const curDetail = details.filter(({ sys_id }) => sys_id === selectedSys)[0] || {};
        const factors = ((rule_data || {}).factors || []).filter(({ sys_id }) => sys_id === selectedSys);

        return (
            <div className={style["exam_result_root_wrapper"]}>
                <div className="header">
                    <div className="item"><span>竞赛名称：</span>{name}</div>
                    <div className="item"><span>使用规则：</span>{rule_name}</div>
                    <Button
                        type="primary" className="btn" onClick={() => this.props.backtoList()}
                        style={{
                            backgroundColor: '#357CF7',
                            borderColor: '#357CF7',
                        }}
                    >返回竞赛结果</Button>
                </div>
                <div className="ExamResultWrapper">
                    <div className="top_bar">
                        <div className="exam_system_wrapper">
                            {
                                results.map(({ sys_id }, index) => (
                                    <div key={index} className={'tab_title' + (selectedSys === sys_id ? ' tab_title_selected' : '')} onClick={() => this.tabSelected(sys_id)}>
                                        {
                                            (details
                                                .filter(({ sys_id: sysId }) => sysId === sys_id)[0] || {}).name || sys_id
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className="rightBtns">
                            {
                                // this.state.table_id_is_set == true && resultType == 1 &&
                                // <ReactHTMLTableToExcel
                                //     id="test-table-xls-button"
                                //     className="download-table-xls-button export_btn"
                                //     table="exam-result-table"
                                //     filename="tablexls"
                                //     sheet="tablexls"
                                //     buttonText="导出Excel"
                                // />
                            }
                            {
                                this.state.table_id_is_set == true && resultType == 1 &&
                                <Button
                                    type="primary" className="export_btn"
                                    onClick={() => window.location = SERVER_PATH + 'api/exams/download_result_excel?exam_id=' + this.state._id}
                                    style={{
                                        backgroundColor: '#357CF7',
                                        borderColor: '#357CF7',
                                    }}
                                >导出Excel</Button>
                            }
                            <div className="cus_switch">
                                <div className={"item" + (resultType === 1 ? " active_item" : "")} onClick={() => this.setState({ resultType: 1 })}>统计报表</div>
                                <div className={"item" + (resultType === 2 ? " active_item" : "")} onClick={() => this.setState({ resultType: 2 })}>结果分析</div>
                            </div>
                        </div>
                    </div>
                    {
                        curSysResult && resultType == 2 &&
                        <HourlyFactorsDiagram examId={this.state._id} />
                    }
                    {
                        curSysResult && resultType == 1 && (
                            <div className="bottom_content_wrapper">
                                <div className="bottom_header_block">
                                    <div className="display_item display_time_range_wrapper">
                                        <div className="title">竞赛起止时间：</div>
                                        <div className="value">{curDetail.time_begin || '-'} 至 {curDetail.time_end || '-'}</div>
                                    </div>
                                    <div
                                        className="display_item display_item_select_time"
                                        style={{
                                            display: 'none',
                                        }}
                                    >
                                        <div className="title">计分区间：</div>
                                        <div className="text">截止当前</div>
                                        <Button type="primary" size="small" className="more_btn" onClick={this.openTimeWrapper}>
                                            <span>更多</span>
                                            <CalendarOutlined />
                                        </Button>
                                        <div
                                            className="time_picker_wrapper_custom"
                                            style={
                                                cusTimeWrapperVisible ?
                                                    {} : {
                                                        display: 'none',
                                                    }
                                            }
                                        >
                                            <div className="time_content_wrapper">
                                                <div className="left">
                                                    <Calendar
                                                        fullscreen={false}
                                                        headerRender={({ value, type, onChange, onTypeChange }) => {
                                                            return (
                                                                <div className="custom_header" />
                                                            );
                                                            // return date ?
                                                            //     (
                                                            //         <div className="custom_header">
                                                            //             <DoubleLeftOutlined onClick={() => this.calendarOnchange(-1, 'years')} style={{ marginRight: 12 }} />
                                                            //             <LeftOutlined onClick={() => this.calendarOnchange(-1, 'months')} />
                                                            //             <div className="text">{displayDate.format('YYYY年 M月')}</div>
                                                            //             <RightOutlined onClick={() => this.calendarOnchange(1, 'months')} />
                                                            //             <DoubleRightOutlined onClick={() => this.calendarOnchange(1, 'years')} style={{ marginLeft: 12 }} />
                                                            //         </div>
                                                            //     ) : (
                                                            //         <div className="custom_header" />
                                                            //     )
                                                        }}
                                                    />
                                                </div>
                                                <div className="mid_line" />
                                                <div className="right">
                                                    <div className="all_shifts_wrapper">
                                                        <Checkbox onChange={() => { }}>所有班组</Checkbox>
                                                    </div>
                                                    {
                                                        process.env.REACT_APP_PROJECT_TAG === 'changzhi' ? [
                                                            <div className="single_shift_wrapper" key="first">
                                                                <Checkbox onChange={() => { }}>早班</Checkbox>
                                                                <div className="hint">08:00————20:00</div>
                                                            </div>
                                                            ,
                                                            <div className="single_shift_wrapper" key="second">
                                                                <Checkbox onChange={() => { }}>晚班</Checkbox>
                                                                <div className="hint">20:00————次日08:00</div>
                                                            </div>
                                                        ] : [
                                                            <div className="single_shift_wrapper" key="zero">
                                                                <Checkbox onChange={() => { }}>夜班</Checkbox>
                                                                <div className="hint">昨日23:30————07:30</div>
                                                            </div>
                                                            ,
                                                            <div className="single_shift_wrapper" key="first">
                                                                <Checkbox onChange={() => { }}>早班</Checkbox>
                                                                <div className="hint">07:30————15:30</div>
                                                            </div>
                                                            ,
                                                            <div className="single_shift_wrapper" key="second">
                                                                <Checkbox onChange={() => { }}>中班</Checkbox>
                                                                <div className="hint">15:30————23:30</div>
                                                            </div>
                                                        ]
                                                    }
                                                    <Button type="primary" className="btn_ok">确认</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_wrapper">
                                    <table
                                        id="exam-result-table"
                                    >
                                        <thead>
                                            <tr>
                                                <th colSpan="2" className="bck_th">姓名</th>
                                                {
                                                    curSysResult.user_values.map(({ user_id }, index) => {
                                                        const user_name = ((curDetail.users || [])
                                                            .filter(({ user_id: userId }) => userId === user_id)[0] || {}).user_name || `用户${user_id}`;
                                                        let tmp_name = "";
                                                        if (!!tmp_users_exchange && typeof tmp_users_exchange[user_name] === 'string') {
                                                            tmp_name = tmp_users_exchange[user_name];
                                                        };
                                                        return (
                                                            <th key={index} className="bck_th">
                                                                {tmp_name}
                                                            </th>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" className="bck_th">名次</td>
                                                {
                                                    curSysResult.user_values.map(({ final_rank }, index) => (
                                                        <td key={index} >{parseInt(final_rank) + 1}</td>
                                                    ))
                                                }
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="bck_th">最终得分</td>
                                                {
                                                    curSysResult.user_values.map(({ final_score }, index) => (
                                                        <td key={index} >{final_score}</td>
                                                    ))
                                                }
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="bck_th">有效时长（小时）</td>
                                                {
                                                    curSysResult.user_values.map(({ minutes_total }, index) => (
                                                        <td key={index} >{Math.floor(minutes_total * 1000 / 60) / 1000}</td>
                                                    ))
                                                }
                                            </tr>
                                            {
                                                factors.map(({ meta_name, _id, weight, score_method }, index) => ([
                                                    (
                                                        <tr key={`1_${index}`}>
                                                            <td rowSpan={
                                                                [3, 4].indexOf(score_method) > -1 ? "4" : "3"
                                                            }><span style={{ fontWeight: 'bold' }}>{meta_name}</span><br />权重{weight}%</td>
                                                            {/* 缺个单位unit */}
                                                            <td>平均值</td>
                                                            {
                                                                curSysResult.user_values.map(({ score_datas }, index) => {
                                                                    const data = score_datas.filter(({ factor_id }) => factor_id === _id)[0] || {};
                                                                    return (
                                                                        <td key={index} >
                                                                            {
                                                                                Math.floor(1000 * (data.raw_avg || 0)) / 1000
                                                                            }
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    ), [3, 4].indexOf(score_method) > -1 ? (
                                                        <tr key={`2_${index}`}>
                                                            <td>原始得分</td>
                                                            {
                                                                curSysResult.user_values.map(({ score_datas }, index) => (
                                                                    <td key={index} >
                                                                        {
                                                                            Math.floor(1000 * (score_datas.filter(({ factor_id }) => factor_id === _id)[0] || {}).score_value) / 1000
                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ) : null, (
                                                        <tr key={`3_${index}`}>
                                                            <td>排名</td>
                                                            {
                                                                curSysResult.user_values.map(({ score_datas }, index) => {
                                                                    const rank = (score_datas.filter(({ factor_id }) => factor_id === _id)[0] || {}).rank;
                                                                    return (
                                                                        <td key={index} >{rank === undefined ? '' : (rank + 1)}</td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    ), (
                                                        <tr key={`4_${index}`}>
                                                            <td>排名得分</td>
                                                            {
                                                                curSysResult.user_values.map(({ score_datas }, index) => (
                                                                    <td key={index} >
                                                                        {
                                                                            (score_datas.filter(({ factor_id }) => factor_id === _id)[0] || {}).rank_score
                                                                        }
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    )
                                                ]))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}
