import React, { useEffect, useRef, useState } from 'react';
import { get, patch } from '../../common/utils';
import { Button, message, Space, Divider } from 'antd';
import style from './index.module.scss';
// import JSONEditModal from './JSONEditModal.js';
import { GridModules } from 'components/GridModules/config';

const blank_promise_func = () => {
    return Promise.resolve(null);
};
const uid2TemplateEdit = GridModules
    .reduce((pV, cV) => {
        return Object.assign({}, pV, {
            [cV.key]: cV.editComp,
        });
    }, {});

//BOARD_TEST_01
const Page = (props) => {
    const id = useRef(props.match.params.id);
    const [screenId, setScreenId] = useState(null);
    const [data, setData] = useState(null);
    const [compUid, setCompUid] = useState(null);

    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + `api/grid/boards`, {
            ids: id.current + "",
        })
            .then((retData) => {
                // console.log(retData);
                const result = retData?.data?.results?.[0] ?? null;
                if (!!result) {
                    let parsed_result = result;
                    let parsed_config = null;
                    try {
                        const config = parsed_result?.config ?? null;
                        parsed_config = JSON.parse(config);
                    } catch (e) { };
                    parsed_result = Object.assign({}, result, { config: parsed_config });
                    // console.log(parsed_result);
                    if (!!parsed_result?.template?.uid) {
                        setCompUid(parsed_result?.template?.uid ?? null);
                        setData(parsed_result)
                        setScreenId(parsed_result.screen_id);
                    };
                } else {
                    message.error("获取信息失败,请刷新重试!");
                };
            });
    }, []);// 如果外部传进来的id发生了变化, 不理会

    const getDataFunc = useRef(blank_promise_func);
    const registerDataFunc = (func) => {
        getDataFunc.current = typeof func === 'function' ? func : blank_promise_func;
    };
    const saveEdit = () => {
        getDataFunc
            .current()
            .then((retConf) => {
                // console.log(retConf);
                if (!!retConf) {
                    const newConfig = JSON.stringify(retConf);
                    patch(process.env.REACT_APP_SERVER_PATH + `api/grid/boards/${id.current}`, {
                        config: newConfig,
                    })
                        .then((retData) => {
                            // console.log(retData);
                            if (!!retData.data && typeof retData.data.id === 'number') {
                                message.success("保存成功！");
                                if (typeof screenId === 'number') {
                                    props.history.push(`/PEIMSV2/DnDPage/${screenId}`);
                                };
                            } else {
                                message.error("保存设置失败,请重试!");
                            };
                        });
                } else {
                    // 处理错误
                };
            });
    };
    const Comp = uid2TemplateEdit[compUid] ?? null;
    return (
        <div className={style['wrapper']}>
            {
                (!!data && !!Comp) && (
                    <Comp registerDataFunc={registerDataFunc} data={data} />
                )
            }
            <Divider />
            <Space>
                <Button type="primary" onClick={saveEdit}>保存修改</Button>
                {/* <Button type="default" onClick={() => setVisible(true)}>编辑JSON</Button> */}
            </Space>
            {/* {
                visible && (
                    <JSONEditModal onCancel={() => setVisible(false)} onOk={jsonEditOnOk} config={config} />
                )
            } */}
        </div>
    );
};

export default Page;
