import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Select, Space, Divider, Checkbox, Input, Popover } from 'antd';
import style from './index.module.scss';
import LineChartWrapper from '../Comp/wrapper';
import { SwatchesPicker } from 'react-color';
// import { CheckIsColor } from '../Comp/utils';
import { withRouter } from 'react-router';
// console.log(style);
const { Text } = Typography;
const { Option } = Select;
const default_LineC = "#40A1E1";
const tag_name_list = [
    {
        id: 1,
        tag_name: "FY5423A",
        name: "生料台时A",
    },
    {
        id: 2,
        tag_name: "FY5423B",
        name: "生料台时B",
    },
];
//BOARD_TEST_01
const Page = (props) => {
    const { data, registerDataFunc } = props;

    const [point, setPoint] = useState(true);
    const [title, setTitle] = useState("");
    const [selectedTagName, setSelectedTagName] = useState(null);
    const [lineColor, setLineColor] = useState(default_LineC);
    const config = useMemo(() => {
        return {
            point, color: lineColor, title, tag_name: selectedTagName,
        };
    }, [lineColor, point, title, selectedTagName]);

    const getConf = useCallback(() => {
        return Promise.resolve(config);
    }, [config]);

    useEffect(() => {
        if (typeof registerDataFunc === 'function') {
            registerDataFunc(getConf);
        };
    }, [getConf, registerDataFunc]);

    useEffect(() => {
        setPoint(data?.config?.point ?? true);
        setTitle(data?.config?.title ?? "");
        setSelectedTagName(data?.config?.tag_name ?? null);
        setLineColor(data?.config?.color ?? default_LineC);
    }, [data]);

    const handleChangeComplete = (color) => {
        setLineColor(color.hex);
    };
    const tagNameOnChange = (v) => {
        setSelectedTagName(v);
    };
    return (
        <div className={style['wrapper']}>
            <div className="display_block">
                <LineChartWrapper config={config} />
            </div>
            <div className="edit_block">
                <Divider />
                <Space direction="vertical">
                    <Space>
                        <Text>标题</Text>
                        <Input placeholder="请输入标题名" value={title} onChange={e => setTitle(e.target.value)} style={{ width: 200 }} />
                    </Space>
                    <Space>
                        <Text>选择数据点</Text>
                        <Select value={selectedTagName} onChange={tagNameOnChange} style={{ width: 200 }}>
                            {
                                tag_name_list
                                    .map((item, index) => {
                                        const { name, tag_name, id } = item;
                                        return (
                                            <Option key={id} value={tag_name}>{name} {tag_name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </Space>
                    <Space>
                        <Text>显示数据标记图形</Text>
                        <Checkbox checked={point} onChange={e => setPoint(e.target.checked)} />
                    </Space>
                    <Space>
                        <Text>线条颜色</Text>
                        <Popover
                            title="选择颜色"
                            // trigger="click"
                            placement="right"
                            content={(
                                <Space direction="vertical">
                                    <SwatchesPicker
                                        color={lineColor}
                                        onChangeComplete={handleChangeComplete}
                                    />
                                </Space>
                            )}
                        >
                            <div style={{ width: 100, height: 30, backgroundColor: lineColor, borderRadius: 5, cursor: 'pointer' }} />
                        </Popover>
                    </Space>
                </Space>
            </div>
        </div>
    );
};

export default withRouter(Page);
