import React from 'react';
import style from './index.module.scss';
import TimeSelector from 'components/TimeSelectorFactor';

const Comp = (props) => {
    const { timeInfo, setTimeInfo } = props;
    return (
        <div className={style['wrapper']}>
            <div className="right">
                <TimeSelector setTimeInfo={setTimeInfo} timeInfo={timeInfo} />
            </div>
        </div>
    );
};

export default Comp;
