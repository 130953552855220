import React from 'react';

import TITLE_ICON from './res/title_icon.png';

import './QualityItem.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstRotateDeg: 0,
            secondRotateDeg: 0,
        };
    }

    componentDidMount() {
        this.setRate(0);
    }

    setRate = rate => {
        const { item = {} } = this.props;
        const { rate: finalRate } = item;
        if (rate > finalRate) {
            let firstRotateDeg = 0;
            let secondRotateDeg = 0;
            if (finalRate > 50) {
                firstRotateDeg = 180;
                secondRotateDeg = 180 * (finalRate - 50) / 50;
            } else {
                firstRotateDeg = 180 * finalRate / 50;
            };
            this.setState({ firstRotateDeg, secondRotateDeg });
        } else {
            let firstRotateDeg = 0;
            let secondRotateDeg = 0;
            if (rate > 50) {
                firstRotateDeg = 180;
                secondRotateDeg = 180 * (rate - 50) / 50;
            } else {
                firstRotateDeg = 180 * rate / 50;
            };
            this.setState({ firstRotateDeg, secondRotateDeg }, () => {
                setTimeout(() => {
                    this.setRate(rate + 5);
                }, 50);
            });
        };
    }

    render() {
        const { item = {} } = this.props;
        const { name, rate, warning } = item;
        const { firstRotateDeg, secondRotateDeg } = this.state;

        let rateText = '-';
        if (typeof rate === "number") {
            rateText = Math.floor(rate * 10) / 10;
        };

        return (
            <div className="quality_item_wrapper">
                <div className="left">
                    <div className="item_title">
                        <div className="text">{name}</div>
                        <img alt="" src={TITLE_ICON} className="title_icon" />
                    </div>
                    <div className="rows_wrapper">
                        <div className="row_wrapper">
                            <div className="row_title">KPR</div>
                            <div className="row_value">{rateText}%</div>
                        </div>
                        <div className="row_wrapper">
                            <div className="row_title">改善机会</div>
                            <div className={"row_value" + (warning > 0 ? " warning_row" : "")}>{warning}次</div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="spin_block">
                        <div className="rotate_block_1">
                            <div className="colored_block" style={{
                                transform: `rotate(${firstRotateDeg}deg)`
                            }}></div>
                        </div>
                        <div className="rotate_block_2">
                            <div className="colored_block" style={{
                                transform: `rotate(${secondRotateDeg}deg)`
                            }}></div>
                        </div>
                        <div className="inner_hover_circle"></div>
                    </div>
                </div>
            </div>
        );
    }
}
