import React from 'react';
import echarts from 'echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/tooltip';
import style from './BarFactorChart.module.scss';

export default class BarFactorChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_finished: null,
            unfinishedNum: null,
            dataSource: [],
            page: 1,
            total: 0,
            pageSize: 10,
            filterSysId: 'all',
            filterLevel: 'all',
            dayBegin: null,
            dayEnd: null,
        }
    }

    componentDidMount() {
        this.initDiagram(this.props.axisData, this.props.boxData);
    }

    initDiagram = (axisData, boxData) => {
        let self = this;

        let minValue = null;
        let maxValue = null;
        boxData.forEach(item => {
            if (minValue === null || item.min_value < minValue) {
                minValue = item.min_value;
            }
            if (maxValue === null || item.max_value > maxValue) {
                maxValue = item.max_value;
            }
        })
        // if(minValue !== null && maxValue !== null){
        // }
        // console.log(self.props.fSetting.meta_name, minValue, maxValue);

        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '10%',
                top: '20%',
                right: '10%',
                bottom: '15%'
            },
            xAxis: {
                type: 'category',
                data: axisData,
                boundaryGap: true,
                nameGap: 30,
                splitArea: {
                    show: true
                },
                axisLabel: {
                    formatter: (value) => {
                        return value.replace(' ', '\n');
                    }
                },
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                type: 'value',
                name: self.props.fSetting.meta_name,
                min: function (value) {
                    return Math.floor(value.min);
                },
                max: function (value) {
                    return Math.ceil(value.max);
                },
                splitArea: {
                    show: false
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    // start: 0,
                    // end: 20
                },
                {
                    show: true,
                    height: 20,
                    type: 'slider',
                    top: '90%',
                    xAxisIndex: [0],
                    start: 0,
                    end: 20
                }
            ],
            series: [
                {
                    // name: 'category0',
                    type: 'bar',
                    data: boxData.map(({ avg_value }) => avg_value),
                    // tooltip: {formatter: formatter}
                },
            ]
        };

        console.log(boxData.map(({ avg_value }) => avg_value));

        self.sChart = echarts.init(self.chartNode);
        self.sChart && self.sChart.setOption(option);
    }

    render() {
        let self = this;

        return (
            <div className={style["BarFactorChart"]}>
                <div className="chartNode" ref={(node) => { if (node) { self.chartNode = node } }}></div>
            </div>
        );
    }
}
