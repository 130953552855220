import React from 'react';
import { Checkbox } from 'antd';
import { roundNum } from 'common/utils.js';
import moment from 'moment';
import echarts from 'echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/tooltip';
import store from 'common/redux/store.js';

import style from './TrendChart.module.scss';
export default class TrendChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: props.record,
            tag2Value: store.getState().mainPageV2.tag2Value,
            optResult: props.optResult,
            dataList: (props.curr_time_begin ? this.resampleDataTime(props.curr_time_begin.unix(), props.dataList) : []),
            topTimeList: props.optResult.result.kpi_result['top_state_datetime'].map(item => { return { value: item[0], checked: true } }),
            // topTimeList: props.optResult.kpi_result['top_state_datetime'],
        }
        this.option = {
            tooltip: {
                trigger: 'axis',
                // axisPointer: {
                //     type: 'shadow'
                // }
                formatter: (params) => {
                    // console.log(params);
                    params.forEach(({ value, color }) => {
                        // console.log(value, color);
                    })
                    let result = moment(params[0] && params[0].axisValue).format('HH:mm:ss');
                    result += '<div style="height:4px;"></div>';
                    params.forEach(function (item) {
                        result += ('<div style="margin-top: 1px;line-height:22px;height:22px;">' +
                            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + item.color + '"></span>');
                        // result += item.seriesName + '：'
                        result += item.data[1] + '</div>'
                    });

                    return result;
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '3%',
                containLabel: true
            },
            xAxis: {
                // scale: true
                type: 'time',
            },
            yAxis: {
                type: 'value',
                scale: true,
                min: undefined,
                max: undefined,
                axisLabel: {
                    formatter: (value) => {
                        return roundNum(value, 2);
                    }
                }
            },
            series: [
                // {
                //     data: [
                //         [0, 0],
                //         [10, 1],
                //         [20, 2],
                //     ],
                //     type: 'line'
                // },{
                //     data: [
                //         [0, 0],
                //         [10, 3],
                //         [20, 18],
                //     ],
                //     type: 'line'
                // }
            ]
        };
    }

    componentWillReceiveProps(nextProps) {
        // console.log('this length: '+this.state.dataList.length+', next length: '+nextProps.dataList.length);
        if (this.state.dataList.length == 0 && nextProps.dataList.length != 0) {
            // console.log('nextProps.curr_time_begin: ', nextProps.curr_time_begin);
            let dataList = this.resampleDataTime(nextProps.curr_time_begin.unix(), nextProps.dataList);
            // console.log('resampled dataList: ', dataList);
            // dataList.forEach(item=>{
            //     item.checked = true
            // })
            this.setState({
                dataList: dataList,
                record: nextProps.record,
            }, () => this.updateChart(true))
        }
    }

    resampleDataTime = (beginSec, dataList) => {
        let offsetSec = null
        return dataList.map((data, setIndex) => {
            data.checked = true;
            let data_list = [];
            if (setIndex == 0) {
                data_list = data.data_list.map((item, index) => {
                    return [item[0] * 1000, item[1]];
                })
            } else {
                data_list = data.data_list.map((item, index) => {
                    if (index == 0) {
                        offsetSec = beginSec - item[0];
                    }
                    return [(item[0] + offsetSec) * 1000, item[1]];
                })
            }
            return {
                ...data,
                data_list
            }
        })
    }
    // return dataList.map((data, setIndex)=>{
    //     data.checked = true;
    //     let data_list = [];
    //     if(setIndex == 0){
    //         dataList = data.data_list;
    //     }else{
    //         dataList = data.data_list.map((item, index)=>{
    //             if(index == 0){
    //                 offsetSec = beginSec - item[0];
    //             }
    //             return [item[0] + offsetSec, item[1]];
    //         })
    //     }
    //     return {
    //         ...data,
    //         data_list
    //     }
    // })
    updateChart = (animation) => {
        let self = this;
        this.sChart.clear();
        let xkey = self.props.record.scatter_plotxy[0];
        let ykey = self.props.record.scatter_plotxy[1];
        this.option.animation = animation;
        // console.log('this.state.dataList[0].checked: ' + this.state.dataList[0].checked);
        this.option.series = this.state.dataList.filter(item => item.checked).map(item => {
            return {
                type: 'line',
                smooth: true,
                symbol: 'none',
                color: (item.time_begin == self.props.curr_time_begin.format('YYYY-MM-DD HH:mm:ss') ? '#ff0000' : '#357cf7'),
                data: item.data_list,
            }
        })
        let { minValue, maxValue } = this.getMinMax(this.state.dataList, self.props.zone_cut_point[0], self.props.zone_cut_point[self.props.zone_cut_point.length - 1]);
        this.option.yAxis.min = minValue;
        this.option.yAxis.max = maxValue;
        if (self.sChart) {
            self.sChart.hideLoading();
            self.sChart.setOption(self.option);
        }
    }

    getMinMax = (datas, _min, _max) => {
        datas.forEach(item => {
            item.data_list.forEach(d => {
                if (d[1] > _max) {
                    _max = d[1];
                }
                if (d[1] < _min) {
                    _min = d[1];
                }
            })
        })
        return { minValue: _min, maxValue: _max };
    }

    componentDidMount = () => {
        let self = this;
        this.storeUnsubscribe = store.subscribe(this.onChange);

        setTimeout(function () {
            // self.sChart = echarts.init(self.chartNode);
            // // self.fetchData();
            // // setTimeout(()=>{
            // self.sChart && self.sChart.setOption(self.option);
            // // }, 100);

            let props = self.props;
            if (!self.sChart) {
                self.sChart = echarts.init(self.chartNode);
                self.sChart.showLoading({ text: '加载中...' });
            } else {
                self.sChart.clear();
            }
            // self.option.yAxis.data = props.dataList.map(item=>self.getNameByUserId(item.user_id));
            // self.option.series[0].data = props.dataList.map(item=>item.value);
            // console.log('option', props.name, self.option);
            self.sChart && self.sChart.setOption(self.option);

            if (self.state.dataList && self.state.dataList.length > 0) {
                self.updateChart(true);
            }
        }, 0);
    }

    componentWillUnmount() {
        this.storeUnsubscribe && this.storeUnsubscribe();
    }

    onChange = () => {
        const { oneResult, tag2Value } = store.getState().mainPageV2;
        this.setState({ tag2Value });
    }

    onChangeTime = (checked, item) => {
        item.checked = checked;
        this.updateChart(false);
        this.forceUpdate();
    }

    render() {
        let self = this;
        let r = this.state.record;

        return (
            <div
                style={{ ...this.props.style }}
                className={style["PA_TrendChart"]}
            >
                <div className="left-content">
                    <div className="info-bar">
                        <div>参数名称：<span style={{ fontWeight: 'bold' }}>{r.name}</span></div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div><img src={null} /> 当前值：<span>{this.state.tag2Value[r.cur_value_tag]}</span></div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div><img src={null} /> 推荐区间：<span>{r.bound_low} ~ {r.bound_high}</span></div>
                    </div>
                    <div className="chart-node" ref={(node) => { if (node) { self.chartNode = node } }}></div>
                </div>
                <div className="time-options">
                    {
                        this.state.dataList.map((item, index) =>
                            <Checkbox
                                key={index}
                                style={{ marginLeft: "0px" }}
                                checked={item.checked}
                                onChange={e => self.onChangeTime(e.target.checked, item)}
                            >{(item.time_begin == self.props.curr_time_begin.format('YYYY-MM-DD HH:mm:ss') ? '近期趋势' : item.time_begin)}</Checkbox>
                        )
                    }
                </div>
            </div>
        );
    }
}

