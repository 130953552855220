import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import moment from 'moment';
import { DatePicker } from 'antd';
import Tabs from 'components/TabsV2';
import { withRouter } from 'react-router';
const { RangePicker } = DatePicker;

const limitTimeStr = "01:00:00";

let tabs2 = [
    {
        name: '日',
        dtype: 'data_daily',
        cn_text: '天',
        time_type: 'DAY',
        picker: 'date',
        range: 'day',//startOf
        calc_unit: 'days',
        period: [6, 'days'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('day')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'day')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('date').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月DD日");
        },
        sample_interval: '15m'
    },
    {
        name: '周',
        dtype: 'data_weekly',
        cn_text: '周',
        time_type: 'WEEK',
        picker: 'week',
        range: 'week',//startOf
        calc_unit: 'weeks',
        period: [6, 'weeks'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('week')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'week')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('week').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月DD日");
        },
        sample_interval: '1h'
    },
    {
        name: '月',
        dtype: 'data_monthly',
        cn_text: '月',
        time_type: 'MONTH',
        picker: 'month',
        range: 'month',//startOf
        calc_unit: 'months',// add, subtract
        period: [6, 'months'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('month')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'month')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('month').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月");
        },
        sample_interval: '1d'
    },
    {
        name: '自定义',
        cn_text: '自定义',
        time_type: 'range',
        picker: 'date',
        range: 'range',//startOf
        calc_unit: 'day',// add, subtract
        period: [6, 'months'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('month')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'month')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('month').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月DD日");
        },
        sample_interval: '1h'
    },
]
    .map((item, index) => {
        return Object.assign({}, item, { key: index + "" });
    });
// 一定要写在最后


const getLatestTime = (timeInfo) => {
    const { range, calc_unit } = timeInfo;
    let currMoment = moment();
    if (moment(currMoment).isBefore(moment(moment(currMoment).startOf(range).format(`YYYY-MM-DD ${limitTimeStr}`)))) {
        currMoment = moment(currMoment).subtract(1, calc_unit).endOf(range);
    };
    return moment(currMoment).format("YYYY-MM-DD 00:00:00");
};

const Comp = (props) => {
    const { timeInfo, hideDay = false, onRangeChange = null } = props;
    const [range, setRange] = useState()
    const [tabs, setTabs] = useState(tabs2)


    const [default_time_obj] = useState(() => {
        let tempTabs = tabs2.filter(t => !hideDay ? true : t.name !== '日').filter(t => onRangeChange ? true : t.name !== '自定义')
            .map((item) => Object.assign({}, item, { all_infos: tempTabs }))
        setTabs(tempTabs)

        const time = null //new URLSearchParams(props.location.search).get('time');
        const timeKey = null //new URLSearchParams(props.location.search).get('timeKey');
        const env_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
        const default_time = (typeof time === 'string' && time !== "")
            ? time
            : (props?.defaultTime ?? (
                (typeof env_time === 'string' && env_time !== "")
                    ? env_time
                    : moment('2022-02-15').format("YYYY-MM-DD 00:00:00"))
            );
        let default_time_info = (tempTabs ?? [])[0] ?? null;
        if (typeof timeKey === 'string' && timeKey !== "") {
            const cus_time_info = (tempTabs ?? []).filter((item) => item.range === timeKey)[0] ?? null;
            // console.log(cus_time_info)
            if (!!cus_time_info) {
                default_time_info = cus_time_info;
            };
        };
        if (!!default_time_info) {
            default_time_info = Object.assign({}, default_time_info, {
                time: default_time,
            });
        };
        return default_time_info;
    }, [hideDay]);
    const default_time_info = useRef(default_time_obj);

    const setTimeInfo = useRef(null);
    useEffect(() => {
        if (typeof props.setTimeInfo === 'function') {
            setTimeInfo.current = props.setTimeInfo;
            return;
        };
        setTimeInfo.current = () => { };
    }, [props]);

    useEffect(() => {
        if (!!default_time_info.current) {
            // let t = sessionStorage.getItem('time') ? JSON.parse(sessionStorage.getItem('time')) : null
            // let rg = sessionStorage.getItem('picker') ? sessionStorage.getItem('picker') : false
            const latestTime = getLatestTime(default_time_info.current);
            const defaultTime = default_time_info.current.time;
            const nextTime = moment(latestTime).isBefore(moment(defaultTime)) ? latestTime : defaultTime;
            const init_time_info = Object.assign({}, default_time_info.current, {
                time: nextTime,
            });
            setTimeInfo.current(init_time_info);
        };
    }, []);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (timeInfo) {
            sessionStorage.setItem("time", timeInfo.time)
            sessionStorage.setItem("picker", timeInfo.picker)
        }
    }, [timeInfo])

    useEffect(() => {
        if (visible) {
            const cb = () => {
                setVisible(false);
            };
            window.addEventListener('click', cb);
            return () => {
                window.removeEventListener('click', cb);
            };
        };
    }, [visible]);

    const datePickerOnChange = (v) => {
        if (timeInfo.name === '自定义') {
            const nextTimeInfo = Object.assign({}, timeInfo ?? {}, { time: moment(v[0]).format("YYYY-MM-DD HH:mm:ss") });
            setTimeInfo.current(nextTimeInfo);
            setRange(v);
            onRangeChange(v)
        } else {
            const nextTimeInfo = Object.assign({}, timeInfo ?? {}, { time: moment(v).format("YYYY-MM-DD HH:mm:ss") });
            setTimeInfo.current(nextTimeInfo);
        }

        setVisible(false);
    };

    const getRange = () => {
        // console.log(range)
        return range ? `${timeInfo.formatter(range[0])} - ${timeInfo.formatter(range[1])}` :
            `${timeInfo.formatter(timeInfo.time)} - ${timeInfo.formatter(timeInfo.time)}`
    }

    const getWeek = () => {
        let d = moment(new Date(timeInfo.time));
        let monday = d.clone().weekday(0);
        let sunday = monday.clone().add(7, "days")
        return `${timeInfo.formatter(monday)}-${timeInfo.formatter(sunday)}`
    }

    const getMonth = () => {
        let d = moment(new Date(timeInfo.time));
        return timeInfo.formatter(d)
    }

    const setNewPicker = (item) => {
        // tab切换也会影响到time，在一个周期内可以，在另一个周期内就不一定了
        const prevTime = timeInfo?.time ?? moment().format("YYYY-MM-DD 00:00:00");
        const currLatestTime = getLatestTime(item);
        const nextTime = moment(currLatestTime).isBefore(moment(prevTime)) ? currLatestTime : prevTime;
        const nextTimeInfo = Object.assign({}, item, {
            time: nextTime,
        });
        // console.log(nextTimeInfo)
        setTimeInfo.current(nextTimeInfo);
    };

    return (
        <div className={style['wrapper']} style={{ marginTop: 8 }}>
            {
                visible && !!timeInfo && (
                    <div className="picker_wrapper">
                        <div
                            onClick={(e) => {
                                // 当用range选择器时，需要在此处阻止冒泡避免弹窗消失
                                // 后续的挂载在内部的意义似乎只剩下隐藏ant-picker-range-arrow
                                e.stopPropagation();//此处不明白，日历组件默认挂载body下，但是这里的stopPropagation缺起效了，放在外部反而不起效
                            }}
                        >
                            {
                                timeInfo.name === '自定义' ?
                                    <RangePicker
                                        style={{ width: "100%" }}
                                        value={moment(timeInfo.time)}
                                        open={true}
                                        defaultPickerValue={range || [moment(timeInfo.time), moment(timeInfo.time).add(1, "days")]}
                                        picker={timeInfo.picker}
                                        onChange={datePickerOnChange}
                                        disabledDate={timeInfo.disabledDate}
                                    />
                                    :
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        value={moment(timeInfo.time)}
                                        open={true}
                                        picker={timeInfo.picker}
                                        onChange={datePickerOnChange}
                                        disabledDate={timeInfo.disabledDate}
                                    />
                            }
                        </div>
                        {/* 遮挡内部点击事件 */}
                        {/* 因为高度可能还是会超出的 */}
                        <div className="hover_block"></div>
                    </div>
                )
            }
            <div className="content">
                {
                    !!timeInfo && (
                        <div className="date" onClick={(e) => { setVisible(!visible); e.stopPropagation(); }} >【 {
                            timeInfo.range === 'day' ? timeInfo.formatter(timeInfo.time) :
                                timeInfo.range === 'week' ? getWeek(timeInfo) :
                                    timeInfo.range === 'range' ? getRange() :
                                        getMonth(timeInfo)} 】</div>
                    )
                }
                {
                    !!tabs && tabs.length > 0 && (
                        <Tabs tabs={tabs} selectedTab={timeInfo} onChange={setNewPicker} />
                    )
                }
            </div>
        </div>
    );
};

export default withRouter(Comp);
