import * as echarts from 'echarts';

const renderLineChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (!data) {
            instance.clear();
            return;
        };

        const xData = data.map((item, index) => index);

        instance.setOption({
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                containLabel: true,
                left: 30,
                top: 48,
                right: 30,
                bottom: 24,
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    interval: 0,
                    fontSize: 18,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    fontSize: 18,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            series: [
                Object.assign(
                    {},
                    {
                        data: data,
                        type: 'bar',
                        barWidth: 40,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: 'rgba(123, 225, 110, 1)' },
                                    { offset: 1, color: 'rgba(0, 120, 255, 1)' },
                                ],
                            ),
                            borderRadius: 2,
                        },
                    },
                ),
            ]
        });
    };
};

const renderPieChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (!data) {
            instance.clear();
            return;
        };

        instance.setOption({
            tooltip: {
                trigger: 'item',
                // formatter: (params) => {
                //     const { marker, value, name } = params;
                //     const parsed_value = (typeof value === 'number' && !isNaN(value)) ? significantNum(value * 100) : "--";
                //     return `${marker}${name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${parsed_value}%`;
                // },
            },
            series: [
                {
                    type: 'pie',
                    radius: ['60%', '90%'],
                    labelLine: {
                        show: false
                    },
                    label: {
                        show: false,
                    },
                    clockwise: false,
                    data: data
                        .map((item, index) => {
                            const { name, value } = item;
                            const color = colors[index % colors.length];
                            return {
                                name,
                                value,
                                itemStyle: {
                                    color,
                                },
                            };
                        }),
                }
            ]
        });
    };
};

const colors = ["rgba(244, 90, 102, 1)", "rgba(55, 195, 215, 1)", "rgba(251, 152, 49, 1)", "rgba(64, 160, 119, 1)"];
const mockList = [
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
];

export {
    renderLineChart,
    renderPieChart,
    colors,
    mockList,
};
