const SCORE_METHODS = [
    {
        id: 2,
        name: '平均',
        // }, {
        //     id: 2,
        //     name: '累积',
    }, {
        id: 3,
        name: '区间',
    }, {
        id: 4,
        name: '限值',
    }
];
const RANK_METHODS = [
    {
        id: 1,
        name: '参数低-得分高',
    }, {
        id: 2,
        name: '参数高-得分高',
    }
];
const RULE_DETAIL_STATUS = [
    {
        status: 0,
        name: '未使用',
    }, {
        status: 1,
        name: '使用中',
    }
];

export {
    SCORE_METHODS,
    RANK_METHODS,
    RULE_DETAIL_STATUS,
}