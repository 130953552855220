import {
    SET_SYSTEM_TITLE,
    SET_VALUES,
    SET_INITIAL_VALUES,
} from './actions.js';
import {
    InitialState,
} from './constants.js';

const R = require('ramda');

const reducer = (state = R.clone(InitialState), action) => {
    switch (action.type) {
        case SET_SYSTEM_TITLE:
            return { ...state, title: action.title }
        case SET_VALUES:
            return {
                ...state,
                ...action.values,
            }
        case SET_INITIAL_VALUES:
            return R.clone(InitialState)
        default:
            return state
    }
};

export default reducer;
