const RANGE_HOURS = [1, 2, 3, 4];
const DEFAULT_SELECTED_RANGE_HOUR_INDEX = 0;

const InitialState = {
    layout: null,
    oneResult: null,
    sys_id: null,
    alarms: [],
    events: [],
    tag2Value: {},
    hisTag2Value: {},
    //cur_value_tag,index
    dataSource: [],
    contentItemWidth: 0,
    draggingIndex: null,
    onDragOverIndex_table: null,
    onDragOverIndex_charts: null,
    customCharts: [],//mock
    // customCharts: [0, 1, 2, 3, 4],//mock
    RANGE_HOURS,
    selectedRangeHourIndex: DEFAULT_SELECTED_RANGE_HOUR_INDEX,
    chartsModal: [],
};

export {
    InitialState,
};