import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import { Button, Select, Spin } from 'antd';
import DomResize from 'components/DomResize';
import { useData } from './hooks';
import { useSys } from '../../hooks';
import CusModal from './Modal';
import LineChart from './LineChart';

const { Option } = Select;
const margin_width = 24;
const defaultFunc = () => { };

const Comp = (props) => {
    const { timeInfo, selectedProduct } = props;

    const [selectedSys, setSelectedSys] = useState(null);
    const [systems, sys_loading] = useSys();
    // console.log(systems, sys_loading);
    const sysOnChange = (v) => {
        const nextSelectedSys = (systems ?? []).filter(({ key }) => key === v)[0] ?? null;
        setSelectedSys(nextSelectedSys);
    };

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        if (!!timeInfo) {
            setDataFunc.current(Object.assign({}, timeInfo, { sys_id: selectedSys?.id ?? null }));
        };
    }, [timeInfo, selectedSys]);

    const [width, setWidth] = useState(null);
    const onResize = (conf) => {
        setWidth(conf.width);
    };

    const [modal, setModal] = useState(null);

    const productOnChange = useRef(defaultFunc);
    useEffect(() => {
        if (typeof props.setSelectedProduct === 'function') {
            productOnChange.current = props.setSelectedProduct;
        } else {
            productOnChange.current = defaultFunc;
        };
    }, [props]);
    useEffect(() => {
        let nextSelectedProduct = null;
        // 如果新的data内包含源有选中的id，仍然选中原来的
        // 上面说的这个特性可能后续在考虑是否确实使用
        if (!!data) {
            const prev_selected_prod_id = selectedProduct?.product_id;
            const tmp_product = data.filter(({ product_id }) => product_id === prev_selected_prod_id)[0] ?? null;
            if (!!tmp_product) {
                nextSelectedProduct = tmp_product;
            } else {
                if (data.length > 0) {
                    nextSelectedProduct = data[0];
                };
            };
        } else {
            // null
        };
        productOnChange.current(nextSelectedProduct);
    }, [data]);
    const itemOnClick = (item) => {
        if (item.key !== selectedProduct?.key) {
            productOnChange.current(item);
        };
    };

    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="top">
                    <div className="title">选择系统</div>
                    <div className="selector">
                        <Select value={selectedSys?.key ?? "all"} style={{ width: 120 }} onChange={sysOnChange}>
                            <Option value="all">全部系统</Option>
                            {
                                (systems ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="bottom">
                    <div style={{ height: 0 }}>
                        <DomResize onResize={onResize} mountedCall={true} />
                    </div>
                    {
                        typeof width === 'number' && (
                            <div className="scroll_wrapper scroll_bar_restyle">
                                <div className="content" style={{ width: width - 2 * margin_width, marginLeft: margin_width }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>产品名称</th>
                                                <th>所属系统</th>
                                                <th>单位</th>
                                                <th>产品成本</th>
                                                <th>过去7{timeInfo?.cn_text ?? "周期"}趋势</th>
                                                <th>优化成本</th>
                                                <th colSpan="2">优化潜力</th>
                                                <th className="action">操作</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (data ?? [])
                                                    .map((item, index) => {
                                                        const {
                                                            product_name, sys_name, unit, data_list,
                                                            parsed_cost, parsed_opt_cost, parsed_pot_cost,
                                                            barWidth,
                                                        } = item;
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className={selectedProduct?.key === item.key ? "selected" : ""}
                                                                onClick={() => itemOnClick(item)}
                                                            >
                                                                <td>{product_name}</td>
                                                                <td>{sys_name}</td>
                                                                <td>{unit}</td>
                                                                <td>{parsed_cost}</td>
                                                                <td>
                                                                    <LineChart history_values={data_list} />
                                                                </td>
                                                                <td>{parsed_opt_cost}</td>
                                                                <td className="pot_cost">
                                                                    <div className="value">
                                                                        {parsed_pot_cost}{unit}
                                                                    </div>
                                                                </td>
                                                                <td className="pot_bar">
                                                                    {
                                                                        typeof barWidth === 'string' && (
                                                                            <div className="bar">
                                                                                <div className="value" style={{ width: barWidth }}></div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Button type="primary" onClick={() => setModal(item)}>成本分析</Button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ height: 12 }}></div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                !!modal && (
                    <CusModal
                        info={modal}
                        timeInfo={timeInfo}
                        onCancel={() => setModal(null)}
                    ></CusModal>
                )
            }
            {
                // 这里sys_loading其实是不影响总体的
                sys_loading && loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
