import React from 'react';
import style from './index.module.scss';
import Layout from '../Layout';
import Block1 from './Block1';

const Page = (props) => {

    return (
        <Layout
            title="关键指标趋势"
            width={2688}
            height={1728}
        >
            <div className={style['wrapper']}>
                <Block1 />
            </div>
        </Layout>
    );
};

export default Page;
