import { useEffect, useState } from 'react';
import { post } from 'common/utils';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { range, tag_name } = conf;
            if (!!range && typeof tag_name === 'string') {
                let delay = true;
                setLoading(true);
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                    "tag_list": [
                        {
                            "tag_name": tag_name,
                            "time_begin": range[0],
                            "time_end": range[1],
                        }
                    ]
                })
                    .then((retData) => {
                        if (delay) {
                            setLoading(false);
                            // console.log('hook', retData);
                            if (!!retData.data && retData.data.errcode === 0) {
                                let nextResult = retData.data;
                                let parsed_data_list = nextResult?.results?.[0]?.data_list ?? null;

                                if (process.env.REACT_APP_PROJECT_TAG === 'jinglei' && parsed_data_list && tag_name === 'JL.BSY.YZS') {
                                    parsed_data_list = parsed_data_list.map(([t, v]) => [t, typeof v === 'number' ? v * 2.2 / 50 : v])
                                }

                                nextResult = Object.assign({}, nextResult, {
                                    parsed_data_list,
                                });
                                setResult(nextResult);
                            } else {
                                // 处理错误
                                setResult(null);
                            };
                        };
                    });
                return () => {
                    delay = false;
                };
            };
        };
        setLoading(false);
        setResult(null);
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
