import React, { useMemo } from 'react'
import style from './index.module.scss'

const getValueText = (v, precision = 2) => {
    if (typeof v === 'number') {
        const multiNum = Math.pow(10, precision);
        return Math.floor(v * multiNum) / multiNum;
    };
    return '--';
};

const Comp = (props) => {
    const { name, values } = props;
    const valueTextList = useMemo(() => {
        const actualValues = !!values ? values : [];
        return new Array(3).fill(null).map((v, index) => getValueText(actualValues[index], index === 0 ? 2 : 0))
    }, [values]);
    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="title">{name}</div>
                <div className="row">
                    <div className="title">P</div>
                    <div className="value">{valueTextList[0]}</div>
                    <div className="unit">MPa</div>
                </div>
                <div className="row">
                    <div className="title">T</div>
                    <div className="value">{valueTextList[1]}</div>
                    <div className="unit">℃</div>
                </div>
            </div>
        </div>
    )
}

export default Comp
