import React from 'react';
import { withRouter } from 'react-router'
import { get, post, getToken, getQueryString } from '../../common/utils';

const isNewMainPage = ['liaohe'].includes(process.env.REACT_APP_PROJECT_TAG);
const MainPagePath = isNewMainPage ? "/LH/LnOilfield" : "/System/MainPage";

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.checkAndRedirect();
    }

    checkAndRedirect = () => {
        let code = getQueryString('code');
        // 如果是第二次进入Auth页面，则会带上code，用该code可换取自己服务器的token
        if(code !== null && code !== ''){
            this.loginByCode(code);
            return;
        }

        let token = getToken();
        if(token == null){
            this.loginSupos();
        }else{
            this.props.history.push(MainPagePath);
        }
    }

    loginByCode = (code)=>{
        let self = this;
        post(process.env.REACT_APP_SERVER_PATH + 'api/supos/login_by_code', {
            code: code
        }).then(retData => {
            console.log(retData);
            let d = retData.data;
            if (!!d && d.errcode === 0) {
                window.localStorage.setItem('USER_INFO', JSON.stringify({
                    user_id: d.user_id,
                    token: d.token,
                    time: new Date().getTime(),
                    name: (d.name == '' || d.name == null) ? d.username : d.name,
                    expire_time: d.token_expire_time,
                }));
                window.localStorage.setItem('CACHED_PERMISSION_LIST', JSON.stringify({
                    data: retData.data.permissions,
                    time: new Date().getTime(),
                    // expire_time: retData.data.token_expire_time,
                }));
                self.props.history.push(MainPagePath);
            }
            // 处理登陆失败
        });
    }

    loginSupos = () =>{
        get(process.env.REACT_APP_SERVER_PATH + 'api/supos/get_app_info', {})
        .then(retData => {
            console.log(retData);
            let d = retData.data;
            if (!!d && d.errcode === 0) {
                // let preFix = window.location.href;
                // console.log(window.location.origin, document.referrer);
                // if (window.parent !== window) {
                //     preFix = document.referrer;
                // };
                this.jumpToAuth(d.base_url, d.app_id);
                return;
            }
            // 处理登陆失败
        });
    }

    jumpToAuth = (baseUrl, appId) => {
        let backUrl = (window.location.href+'').split('#')[0]+'#/AppAuth';
        window.location.href = `${baseUrl}/oauth2/code/v1/authorize?appid=${appId}&responseType=code&state=0&redirectUri=${window.encodeURIComponent(backUrl)}`;
    }

    render() {

        return (
            null
            // <div>
            // </div>
        );
    }
}
export default withRouter(Comp);
