import moment from 'moment';

const parseTimeRange = (timeRange) => {
    const [startTime, endTime] = timeRange;
    return [moment(startTime), moment(endTime).add(1, 'days')]
        .map(t => t.format("YYYY-MM-DD 00:00:00"));
};

export {
    parseTimeRange,
};
