import React from 'react';

import './ImproveChance.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div className="page_improve_chance_wrapper">
                <div className="content_wrapper">
                    <div className="content_root_title">改善机会</div>
                    <div className="system_all content_item">
                        <div className="item_header_wrapper">
                            <img alt="" src={null} className="item_header_icon" />
                            <div className="title">全部系统</div>
                        </div>
                    </div>
                    {/* {
                        datas
                            .map((item, index) => {
                                return (
                                    <div className="item_position_wrapper" key={index}>
                                        <ProduceItem item={item} />
                                    </div>
                                );
                            })
                    } */}
                </div>
            </div>
        );
    }
}
