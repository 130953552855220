import React, { useReducer, useEffect, useRef } from 'react';
import style from './index.module.scss';
import CommonContext from './context';
import { time_key, timeRange_key } from './constants';
import PageTabs from './PageTabs';
import DevicesCard from './DevicesCard';
import DiagramDisplay from 'containers/DiagramDisplay';

const iniState = {
    [time_key]: null, //['date', '2020-09-20']
    [timeRange_key]: null, //['date', '2020-09-14', '2020-09-20'],
    selectedTab: null,
    selectedDevice: null,
    refreshTick: null, // Symbol
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return Object.assign({}, state, iniState);
        case 'time':
            return Object.assign({}, state, { [time_key]: action.time });
        case 'tabSelect':
            return Object.assign({}, state, { selectedTab: action.selectedTab });
        case 'deviceSelect':
            return Object.assign({}, state, { selectedDevice: action.selectedDevice });
        case 'refreshTick':
            return Object.assign({}, state, { refreshTick: action.refreshTick });
        default:
            return state;
    };
};

const PagePanel = (props) => {
    const [state, dispatch] = useReducer(reducer, iniState);
    const { selectedTab, selectedDevice } = state;

    const controlled = useRef(props.controlled);// 是否受控, true表示受控,只检查mounted
    const { value } = props;
    useEffect(() => {
        if (controlled.current === true) {
            let nextSelectedTab = null;
            if (!!value) {
                nextSelectedTab = value;
            };
            dispatch({ type: 'tabSelect', selectedTab: nextSelectedTab });
        };
    }, [value])


    return (
        <CommonContext.Provider value={{ state, dispatch }}>
            <div className={style['wrapper']}>
                {
                    controlled.current !== true && (
                        <PageTabs />
                    )
                }
                <div className="content">
                    {
                        !!selectedTab && (
                            typeof selectedTab.diagramId === 'string' ? (
                                <div className="inner_content_wrapper" style={{ padding: "24px 72px" }} key={selectedTab.diagramId}>
                                    <DiagramDisplay deviceId={selectedTab.diagramId} />
                                </div>
                            ) : (
                                    <div className="inner_content_wrapper">
                                        <div className="left">
                                            <div className="content">
                                                <DevicesCard />
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="content_wrapper">
                                                {
                                                    !!selectedTab && !!selectedDevice && !!selectedTab.comp && (
                                                        <selectedTab.comp />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                        )
                    }
                </div>
            </div>
        </CommonContext.Provider>
    );
};

export default PagePanel;
