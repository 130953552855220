import React from 'react'
import './index.scss'
import { Spin } from 'antd'
const FullScreenLoading = props => {
  const { loadingTip } = props

  return (
    <div className="loading-tip">
      <Spin tip={loadingTip} />
    </div>
  )
}

export default FullScreenLoading
