import React, { useEffect, useState } from 'react'
import style from './index.module.scss'
import Report from './Report'
import AddTemplate from './AddCapsule'
import ToolBar from './ToolBar'
import DialogPanel from './DialogPanel'
import CommonContext from './context'

const Page = props => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [templpateId, setTemplateId] = useState(null)
  const [capsuleId, setCapsuleId] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [name, setName] = useState(null)
  const [refresh, setRefresh] = useState(null) // Symbol
  const [event, setEvent] = useState(null) // {type:"new"/"edit", id:(number),...}

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeCapsuleModal = (event, isRefreshEventList) => {
    setName(null)
    setTemplateId(null)
    setCapsuleId(null)
    setIsEdit(false)
    setIsModalVisible(false)
    if (isRefreshEventList) {
      // TODO: 刷新事件列表
      // setRefresh(Symbol())
    }
    if (!!event) {
      setEvent(event)
    }
  }

  useEffect(() => {
    console.log('page')
  }, [])

  const addCapsuleOnClick = (id, title) => {
    setName(title)
    setTemplateId(id)
    showModal()
  }

  const editEventOnClick = (id, isEdit) => {
    if (isEdit === true) {
      setIsEdit(true)
    }
    setCapsuleId(id)
    setIsModalVisible(true)
  }
  return (
    <div className={style['wrapper']}>
      <div className="left">
        {/* <Header /> */}
        <div className="title">胶囊看板</div>
        <div className="body">
          <Report />
          {isModalVisible && (
            <AddTemplate
              capsuleId={capsuleId}
              isEdit={isEdit}
              tpl_id={templpateId}
              tpl_name={name}
              onOk={e => closeCapsuleModal(e, true)}
              onCancel={closeCapsuleModal}
            />
          )}
        </div>
      </div>
      <CommonContext.Provider value={{ refresh, event }}>
        <div className="right">
          <div>
            <ToolBar addCapsuleOnClick={addCapsuleOnClick} />
          </div>
          <div className="dialog_panel_wrapper">
            <DialogPanel editEventOnClick={editEventOnClick} />
          </div>
        </div>
      </CommonContext.Provider>
    </div>
  )
}

export default Page
