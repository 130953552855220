import React, { useMemo } from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    const { tabs, onChange, selectedIndex, itemWidth ,height } = props;
    const itemStyle = useMemo(() => {
        if (typeof itemWidth === 'number' && itemWidth > 0) {
            return { width: itemWidth };
        } else {
            return {};
        };
    }, [itemWidth]);
    return (
        <div className={style['wrapper']} style={{height}}>
            {
                tabs
                    .map((item, index) => {
                        const { name } = item;
                        return (
                            <div key={index} className={"item" + (selectedIndex === index ? " selected" : "")} onClick={() => onChange(index, item)} style={itemStyle}>
                                <div className="text">{name}</div>
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default Comp;
