import moment from 'moment'
const Yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD') // 昨天
const Today = moment().format('YYYY-MM-DD') // 今天

/** 格式化日期 今天/昨天/具体日期 */
const formatDateTimeToText = time => {
  if (!time) return time
  const tempYMD = moment(time).format('YYYY-MM-DD')
  const tempHm = moment(time).format('HH:mm')
  if (tempYMD === Yesterday) return '昨天 ' + tempHm
  if (tempYMD === Today) return '今天 ' + tempHm
  return `${tempYMD} ${tempHm}`
}

export { formatDateTimeToText }
