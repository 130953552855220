import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { plan_type, current_time } = conf;
            if (typeof plan_type !== 'string' || typeof current_time !== 'string') {
                setResult(null);
                return;
            };
            let requesting = true;
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_factor_prediction_list', {
                plan_type,
                current_time,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data;
                            let parsed_results = nextResult?.results;
                            if (!!parsed_results) {
                                parsed_results = parsed_results
                                    .map((item) => {
                                        const {
                                            hours, rest_hours, value, pred_value,
                                            lower_limit, upper_limit, qt_lower, qt_upper,
                                        } = item;
                                        const parsed_hours = typeof hours === 'number' ? significantNum(hours, 4) : "--";
                                        const parsed_value = typeof value === 'number' ? significantNum(value, 4) : "--";
                                        const parsed_rest_hours = typeof rest_hours === 'number' ? significantNum(rest_hours, 4) : "--";
                                        const parsed_pred_value = typeof pred_value === 'number' ? significantNum(pred_value, 4) : "--";
                                        let parsed_standard = "--";
                                        if (typeof lower_limit === 'number' && typeof upper_limit === 'number') {
                                            parsed_standard = significantNum(lower_limit, 4) + "~" + significantNum(upper_limit, 4);
                                        } else {
                                            if (typeof lower_limit === 'number') {
                                                parsed_standard = significantNum(lower_limit, 4);
                                            } else if (typeof upper_limit === 'number') {
                                                parsed_standard = significantNum(upper_limit, 4);
                                            };
                                        };
                                        let parsed_range = "--";
                                        if (typeof qt_lower === 'number' && typeof qt_upper === 'number') {
                                            parsed_range = significantNum(qt_lower, 4) + "~" + significantNum(qt_upper, 4);
                                        } else {
                                            if (typeof qt_lower === 'number') {
                                                parsed_range = significantNum(qt_lower, 4);
                                            } else if (typeof qt_upper === 'number') {
                                                parsed_range = significantNum(qt_upper, 4);
                                            };
                                        };

                                        return Object.assign({}, item, {
                                            parsed_hours,
                                            parsed_value,
                                            parsed_rest_hours,
                                            parsed_pred_value,
                                            parsed_standard,
                                            parsed_range,
                                        });
                                    });
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_results,
                            });
                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
