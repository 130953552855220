import ElecDetailBlock from './ElecDetailBlock.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { mainPageV2 } = state;
    const { oneResult, tag2Value } = mainPageV2;
    let ckw_results = [];
    if (oneResult) {
        ckw_results = oneResult.result.ckw_results || [];
    };
    return {
        ckw_results,
        tag2Value,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

const WrapperedElecDetailBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ElecDetailBlock);

export default WrapperedElecDetailBlock;