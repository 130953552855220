import { getSuffixIndexByTime } from '../utils';

const commonSuffixList = ['_HD', '_D', '_HW', '_W', '_HM', '_M'];
const compareSuffixList = ['_HDQOQ', '_HDQOQ', '_HWQOQ', '_HWQOQ', '_HMQOQ', '_HMQOQ'];
const sumMonthSuffixList = ['_HM', '_DM', '_HM', '_DM'];
const sumYearSuffixList = ['_DY', '_DY', '_DY', '_DY', '_DY', '_DY'];
const timeSuffixList = ['tbd', 'tbd', 'tbd', 'tbd', 'tbd', 'tbd'];

const timeType2Text = { date: '日', week: '周', month: '月' };

const getPowerConfig = (timeType, timeStr) => {
    const suffixIndex = getSuffixIndexByTime(timeType, timeStr);
    const timeText = timeType2Text[timeType] || '';
    const hideMonth = timeType === 'month';
    return [
        {
            name: '有功',
            extdTag: 'kWhZ',
            children: [
                {
                    name: `${timeText}电量`,
                    unit: '万kWh',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
            ]
                .concat(
                    hideMonth ? [] : [
                        {
                            name: `月累`,
                            unit: null,
                            suffix: sumMonthSuffixList[suffixIndex],
                        },
                    ],
                    [
                        {
                            name: `年累`,
                            unit: null,
                            suffix: sumYearSuffixList[suffixIndex],
                        },
                    ],
                ),
        },
        {
            name: '无功',
            extdTag: 'kWRh',
            children: [
                {
                    name: `${timeText}电量`,
                    unit: '万kVarh',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
            ],
        },
        {
            name: '视在',
            extdTag: 'kVAhZ',
            children: [
                {
                    name: `${timeText}电量`,
                    unit: '万kVAh',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
            ],
        },
        {
            name: '最大需量',
            children: [
                {
                    extdTag: 'kVAmx',
                    name: '功率',
                    unit: '万kVAh',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
                {
                    extdTag: 'LDRT',
                    name: '负载率',
                    unit: '%',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
                {
                    extdTag: 'tbd',
                    name: '时间',
                    isTime: true,
                    unit: null,
                    suffix: timeSuffixList[suffixIndex],
                },
            ],
        }
    ];
};

const getElecFeeConfig = (timeType, timeStr) => {
    const suffixIndex = getSuffixIndexByTime(timeType, timeStr);
    const hideMonth = timeType === 'month';
    return [
        {
            name: '高峰',
            children: [
                {
                    name: `有功电量`,
                    extdTag: 'kWh2',
                    unit: '万kWh',
                    suffix: commonSuffixList[suffixIndex],
                },
                {
                    name: `电费`,
                    extdTag: 'elchrg2',
                    unit: '万元',
                    suffix: commonSuffixList[suffixIndex],
                },
            ],
        },
        {
            name: '平段',
            children: [
                {
                    name: `有功电量`,
                    extdTag: 'kWh1',
                    unit: '万kWh',
                    suffix: commonSuffixList[suffixIndex],
                },
                {
                    name: `电费`,
                    extdTag: 'elchrg1',
                    unit: '万元',
                    suffix: commonSuffixList[suffixIndex],
                },
            ],
        },
        {
            name: '低谷',
            children: [
                {
                    name: `有功电量`,
                    extdTag: 'kWh0',
                    unit: '万kWh',
                    suffix: commonSuffixList[suffixIndex],
                },
                {
                    name: `电费`,
                    extdTag: 'elchrg0',
                    unit: '万元',
                    suffix: commonSuffixList[suffixIndex],
                },
            ],
        },
        {
            name: '合计',
            children: [
                {
                    extdTag: 'kWRZ',
                    name: '有功电量',
                    unit: '万kWh',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
                {
                    extdTag: 'elprc',
                    name: '电价',
                    unit: '元/kWh',
                    suffix: commonSuffixList[suffixIndex],
                    compare: {
                        name: '比较',
                        suffix: compareSuffixList[suffixIndex],
                    },
                },
            ]
                .concat(
                    hideMonth ? [] : [
                        {
                            extdTag: 'elprc',
                            name: `月累`,
                            unit: null,
                            suffix: sumMonthSuffixList[suffixIndex],
                        },
                    ],
                    [
                        {
                            extdTag: 'elprc',
                            name: `年累`,
                            unit: null,
                            suffix: sumYearSuffixList[suffixIndex],
                        },
                    ],
                ),
        }
    ];
};

const countChildrenNum = (children) => {
    // 认为children总是数组且length>0, 否则不负责准确性
    return children
        .reduce((pV, cChild) => {
            const delta = 1 + (!!cChild.compare ? 1 : 0);
            return pV + delta;
        }, 0);
};

const getAllTagsInfo = (conf) => {
    // 认为conf是由上面getPowerConfig, getElecFeeConfig生成的，否则不负责准确性
    return conf
        .reduce((pV, cItem) => {
            const { children } = cItem;
            const tags = children
                .reduce((pChildV, cChildItem) => {
                    const { compare, suffix, isTime } = cChildItem;
                    const extdTag = cChildItem.extdTag || cItem.extdTag || "";
                    let currentTags = [{
                        tag: extdTag + suffix,
                        ...(
                            isTime === true ? {
                                isTime: true,
                            } : {}
                        )
                    }];
                    if (!!compare) currentTags.push({ tag: extdTag + compare.suffix, isCompare: true });
                    return pChildV.concat(currentTags);
                }, []);
            return pV.concat(tags);
        }, []);
};

const getAllTags = (conf) => {
    // 认为conf是由上面getPowerConfig, getElecFeeConfig生成的，否则不负责准确性
    return getAllTagsInfo(conf).map(({ tag }) => tag);
};

export {
    getPowerConfig, getElecFeeConfig, countChildrenNum, getAllTags, getAllTagsInfo,
};
