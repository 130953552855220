import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Modal, Form, Input, message } from 'antd'
import style from  './index.module.scss'
import FullScreenLoading from 'components/FullScreenLoading'
import { get, post } from 'common/utils'
const baseUrl = process.env.REACT_APP_SERVER_PATH

const Com = props => {
  const [form] = Form.useForm()
  const { recordSelect } = props
  const [loading, setLoading] = useState(false) // 准备数据的loading
  const [submitLoading, setSubmitLoading] = useState(false) // 提交的loading
  const [loadingTip, setLoadingTip] = useState('数据加载中...')
  const [detailData, setDetailData] = useState({})
  const [reportDate, setReportDate] = useState('') // 报表日期
  const [headData, setHeadtData] = useState([]) // 报表头信息
  const [rowData, setRowData] = useState([])
  const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD') // 昨天
  const today = moment().format('YYYY-MM-DD') // 今天
  const currentHour = moment().startOf('hour').format('HH:00') // 当前整点信息
  const [highlightIndex, setHighlightIndex] = useState(null) // 当前整点对应的rowIndex

  useEffect(() => {
    getDetailInfo()
  }, [])

  /** 获取详情 */
  const getDetailInfo = () => {
    setLoadingTip('数据加载中，请耐心等待...')
    setLoading(true)
    get(`${baseUrl}api/v1/event/report/reportDetails`, {
      id: recordSelect.id
    })
      .then(res => {
        if (res.data && res.data.error === 0) {
          handleResultData(res.data.results || {})
        } else {
          setLoading(false)
          message.error('报表详情获取失败')
        }
      })
      .catch(err => {
        console.log('报表详情获取失败', err)
        setLoading(false)
        message.error('报表详情获取失败')
      })
  }

  /** 切换班次
   * around 0前一个 1后一个
   */
  const changeShift = around => {
    setLoadingTip('数据加载中，请耐心等待...')
    setLoading(true)
    const params = { rpId: detailData.id, around }
    const apiUrl = `${baseUrl}api/v1/event/report/lastNextShift`
    get(apiUrl, params)
      .then(res => {
        if (res.data && res.data.error === 0) {
          if (!res.data.results) {
            setLoading(false)
            message.error(`${around === 0 ? '暂无上一班次' : '暂无下一班次'}`)
            return
          }
          handleResultData(res.data.results || {})
        } else {
          setTimeout(() => {
            setLoading(false)
            message.error(`${around === 0 ? '暂无上一班次' : '暂无下一班次'}`)
          }, 200)
        }
      })
      .catch(err => {
        console.log('切换班次失败', err)
        setTimeout(() => {
          setLoading(false)
          message.error('切换班次失败')
        }, 200)
      })
  }

  /** 处理详情数据 */
  const handleResultData = resultData => {
    setDetailData(resultData)
    calculateReportDate(resultData.beginTime)
    setHeadtData(resultData.headerList || []) // 表头
    setRowData(resultData.rows || []) // 表行
    calculateHighlightIndex(resultData.beginTime, resultData.rows || [])

    setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  /** 计算高亮行索引 */
  const calculateHighlightIndex = (beginTime, rows) => {
    if (rows.length === 0) return setHighlightIndex(-1)
    const reportDate = beginTime ? moment(beginTime).format('YYYY-MM-DD') : ''
    if (reportDate < today) return setHighlightIndex(-1)
    if (reportDate > today) return setHighlightIndex()
    const hourList = rows.map(rowItem => rowItem.label)
    if (currentHour < hourList[0]) return setHighlightIndex()
    const activeIndex = hourList.findIndex(hour => hour === currentHour)
    setHighlightIndex(activeIndex)
  }

  /** 计算班次日期 */
  const calculateReportDate = beginTime => {
    const reportDate = beginTime ? moment(beginTime).format('YYYY-MM-DD') : ''
    if (reportDate === yesterday) return setReportDate('昨天')
    if (reportDate === today) return setReportDate('今天')
    setReportDate(reportDate)
  }

  /** 校验是否是否是今天的报表 */
  const isToday = momentDate => {
    if (!momentDate) return false
    return moment(new Date(momentDate)).isSame(moment(), 'd')
  }

  /** 更新输入框对应数值 */
  const updateValue = (newValue, rowIndex, columnLabel) => {
    const tempData = rowData.concat()
    const regex = /^\-?(0|[1-9]\d{0,4})?(\.\d{0,2})?$/
    const formatValue = (newValue || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入5位整数和2位小数', formatValue)
      return
    }
    tempData[rowIndex][columnLabel] = formatValue
    setRowData(tempData)
  }
  /** 字符串转数字 */
  const formatNumber = (newValue, rowIndex, columnLabel) => {
    if (!newValue) return
    const tempData = rowData.concat()
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    tempData[rowIndex][columnLabel] = formatValue
    setRowData(tempData)
  }

  /** 校验输入框的值 */
  const checkValidate = (value, rowIndex, columnLabel) => {
    const regex = /^(0|[1-9]\d{0,5})?(\.\d{0,2})?$/
    const errRef = document.getElementsByClassName(`error-msg-${rowIndex}-${columnLabel}`)
    if (value && !regex.test(value)) {
      errRef[0].classList.add('is-error')
    } else {
      errRef[0].classList.remove('is-error')
    }
  }

  /** 点击提交 */
  const handleOk = () => {
    if (loading) {
      message.error('数据正在加载中，无法提交数据！')
      return
    }
    // const errRefs = document.getElementsByClassName('is-error')
    // if (errRefs && errRefs.length > 0) {
    //   message.error('请先处理不合法的数据！')
    //   return
    // }

    saveReport()
  }

  /** 保存数据 */
  const saveReport = () => {
    setLoadingTip('提交数据中，请耐心等待...')
    setSubmitLoading(true)
    const params = {
      rows: rowData,
      shiftRpId: detailData.id
    }
    post(`${baseUrl}api/v1/event/report/dataSave`, params)
      .then(res => {
        if (res.data && res.data.error === 0) {
          setTimeout(() => {
            message.success('提交成功')
            const { onOk: propOnOk } = props
            propOnOk()
          }, 300)
        } else {
          setSubmitLoading(false)
          message.error('数据提交失败')
        }
      })
      .catch(err => {
        setSubmitLoading(false)
        message.error('数据提交失败')
        console.log('数据提交失败', err)
      })
  }
  /** 点击取消 */
  const handleCancel = () => {
    const { onCancel: propOnCancel } = props
    if (typeof propOnCancel === 'function') {
      propOnCancel()
    }
  }

  /** 禁止输入空格 */
  const onKeyDown = e => {
    if (e.keyCode === 32) e.preventDefault()
  }

  const getInputStyle=(rowIndex,hourLabel,value)=>{
      const activeTime = `${today} ${currentHour}`
 
      if(`${today} ${currentHour}`>`${reportDate} ${hourLabel}`){         
          return {backgroundColor:!value&&value!==0?'#D0E7FF':'#F5F5F5'}
      } else if(`${today} ${currentHour}`===`${reportDate} ${hourLabel}`){
          return {backgroundColor:!value&&value!==0?'#D0E7FF':'#FFF'}
      }else if(`${today} ${currentHour}`<`${reportDate} ${hourLabel}`){
          return {backgroundColor:'#fff',borderRadius: 2,
          border: '1px solid #E8E8E8'}
      }
  }

  return (
    <>
      <Modal
        width="670px"
        style={{ marginLeft: 508, top: 215 }}
        mask={false}
        maskClosable={false}
        okText="提交"    
        wrapClassName={`common-modal ${style['reportWrapper']}`}
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="toolbar">
          <div className="date-info">{reportDate}</div>
          <div className="shift-btn" style={{ visibility: detailData.name ? 'visible' : 'hidden' }}>
            <i className="left-arrow" onClick={() => changeShift(0)} />
            <span>{detailData.name}</span>
            <i
              className="right-arrow"
              style={{ pointerEvents: detailData.lastShift === 1 ? 'none' : 'auto' }}
              onClick={() => changeShift(1)}
            />
          </div>
        </div>

        {/* <span className="full-screen-btn">全屏</span> */}
        <div className="title">{recordSelect.report_name}</div>
        <Form form={form} name="reportForm" className="reportForm" scrollToFirstError>
          <div className="table-head">
            {headData.map((headItem, columnIndex) => {
              return <div key={columnIndex}>{headItem}</div>
            })}
          </div>
          <div className="table-row">
            {rowData.map((rowItem, rowIndex) => {
              return (
                <div
                  key={rowIndex}
                  className={`row-item ${highlightIndex === rowIndex ? 'highlight-row' : ''}`}
                >
                  {Object.keys(rowItem).map(columnLabel => {
                    if (columnLabel === 'label') {
                      return <div key={columnLabel}>{rowItem[columnLabel]}</div>
                    } else {
                      return (
                        <Form.Item key={columnLabel}>
                          <Input
                            value={rowItem[columnLabel]}
                            autoComplete="off"
                            className={`${(highlightIndex!==-1&&highlightIndex<rowIndex)?'ignore':!rowItem[columnLabel] &&
                                rowItem[columnLabel] !== 0?'empty':'valid'}`}
                            // style={{
                            //     backgroundColor:
                            //       (highlightIndex === -1 || highlightIndex >= rowIndex) &&
                            //       !rowItem[columnLabel] &&
                            //       rowItem[columnLabel] !== 0
                            //         ? 'rgba(208, 231, 255, 1)'
                            //         : today<=reportDate &&highlightIndex!==-1&& highlightIndex<rowIndex?'#FFF':'#F5F5F5'
                            //   }}
                            onChange={e => updateValue(e.target.value, rowIndex, columnLabel)}
                            onBlur={e => formatNumber(e.target.value, rowIndex, columnLabel)}
                            onKeyDown={e => onKeyDown(e)}
                            onPaste={e => e.preventDefault()}
                          />
                          {/* <div className={`error-msg error-msg-${rowIndex}-${columnLabel}`}>
                            最多5位整数 2位小数
                          </div> */}
                        </Form.Item>
                      )
                    }
                  })}
                </div>
              )
            })}
          </div>
          {loading && <FullScreenLoading loadingTip={loadingTip} />}
        </Form>
      </Modal>
      {submitLoading && <FullScreenLoading loadingTip={loadingTip} />}
    </>
  )
}

export default Com
