import React from "react";
import './tabs.scss'
const Tabs = (props) => {
    const { tabList, activeIndex, changeTab } = props
    return (
        <div className="tabs">
            <ul>
                {
                    tabList && tabList.map((item,index)=> {
                        return (
                            <li className="tabs-title"
                                style={{borderBottom: activeIndex === index?`3px solid #357CF7`: 'none'}}
                                key={item.id}
                                onClick={() => changeTab(item.name, index)}>
                                {item.name}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
export default Tabs
