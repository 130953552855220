import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, range, base_id, comp_type } = conf;
            if (typeof time !== 'string' || typeof base_id !== 'number' || typeof comp_type !== 'string') {
                setResult(null);
                setLoading(false);
                return;
            };
            let requesting = true;
            setLoading(true);
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/compare_factor_history', {
                base_id,
                selected_time_type: time_type,
                selected_time: time_start,
                comp_type,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data;

                            let parsed_comp_list = nextResult?.comp_list;
                            if (!!parsed_comp_list) {
                                parsed_comp_list = parsed_comp_list
                                    .map((item) => {
                                        const { value, comp_pct } = item;
                                        const parsed_value = typeof value === 'number' ? significantNum(value, 4) : "--";
                                        const parsed_comp_pct = (typeof comp_pct === 'number' ? significantNum(Math.abs(comp_pct) * 100) : "--") + "%";
                                        return Object.assign({}, item, {
                                            parsed_value,
                                            parsed_comp_pct,
                                        });
                                    });
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_comp_list,
                            });
                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
