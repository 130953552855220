import React, { useReducer, useEffect, useRef } from 'react';
import style from './index.module.scss';
import DevicesCard from './DevicesCard';
import ReportTabs from './ReportTabs';
import TimeSelector from './TimeSelector';
import CommonContext from './context';
import { time_key, timeRange_key } from './constants';

const iniState = {
    [time_key]: null, //['date', '2020-09-20']
    [timeRange_key]: null, //['date', '2020-09-14', '2020-09-20'],
    selectedTab: null,
    selectedDevice: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'time':
            return Object.assign({}, state, { [time_key]: action.time });
        case 'timeRange':
            return Object.assign({}, state, { [timeRange_key]: action.timeRange });
        case 'tabSelect':
            return Object.assign({}, state, { selectedTab: action.selectedTab });
        case 'deviceSelect':
            return Object.assign({}, state, { selectedDevice: action.selectedDevice });
        default:
            return state;
    };
};

const Page = (props) => {
    const [state, dispatch] = useReducer(reducer, iniState);
    const { selectedTab, selectedDevice } = state;

    const controlled = useRef(props.controlled);// 是否受控, true表示受控,只检查mounted
    const { value } = props;
    useEffect(() => {
        if (controlled.current === true) {
            let nextSelectedTab = null;
            if (!!value) {
                nextSelectedTab = value;
            };
            dispatch({ type: 'tabSelect', selectedTab: nextSelectedTab });
        };
    }, [value])

    return (
        <CommonContext.Provider value={{ state, dispatch }}>
            <div className={style['wrapper']}>
                <div className="left">
                    {/* {
                        controlled.current !== true && (
                            <div className="title"></div>
                        )
                    } */}
                    <div className="content">
                        <DevicesCard />
                    </div>
                </div>
                <div className="right">
                    {
                        controlled.current !== true && (
                            <div className="tab_row">
                                <ReportTabs />
                                <div style={{ marginLeft: 'auto' }} />
                                {
                                    !!selectedTab && (
                                        <TimeSelector />
                                    )
                                }
                            </div>
                        )
                    }
                    <div className="content_wrapper">
                        {
                            !!selectedTab && state[selectedTab.timeType] !== null && !!selectedDevice && (
                                <selectedTab.comp />
                            )
                        }
                    </div>
                    {
                        controlled.current === true && (
                            <div className="tab_row_absolute">
                                {
                                    !!selectedTab && (
                                        <TimeSelector />
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </CommonContext.Provider>
    );
};

export default Page;
