import React from "react";
import ReactDOM from "react-dom";
import building from "./building.jpg";

export default class RuleDetails extends React.Component {
    constructor(props){
      super(props);
      this.state={
      }
    }

    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', wdith: '100%', height: '100%', justifyContent: 'center', backgroundColor: '#FFF'}}>
                <img style={{}} src={building} />
            </div>
        );
    }
}
