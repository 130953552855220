import FullScreenWrapper from 'components/FullScreenWrapper';
import { useRef, useState } from 'react';

const Comp = (props) => {
    const [id] = useState(() => {
        return props?.match?.params?.id ?? null;
    });
    const refNode = useRef(null);
    const onLoad = () => {
        if (!!refNode.current) {
            const cb = () => {
                // diagramChartGoBack
                // console.log("diagramChartGoBack");
                const goBack = props?.history?.goBack ?? null;
                if (typeof goBack === 'function') {
                    goBack();
                };
            };
            const iframeWindow = refNode.current.contentWindow;
            iframeWindow.addEventListener('diagramChartGoBack', cb);
            return () => {
                iframeWindow.removeEventListener('diagramChartGoBack', cb);
            };
        };
    };
    return (
        <FullScreenWrapper>
            {
                typeof id === 'string' && (
                    <iframe
                        src={`./#/DiagramChart/${id}`}
                        style={{ height: "100%", width: "100%", borderWidth: 0 }}
                        ref={refNode}
                        onLoad={onLoad}
                    />
                )
            }
        </FullScreenWrapper>
    );
};

export default Comp;
