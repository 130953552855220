import React from 'react';
import { message, Spin, Input, Button, Badge, Modal } from 'antd';
import { RollbackOutlined, PlayCircleOutlined } from '@ant-design/icons';
import Editor from "react-simple-code-editor";
//高亮代码
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-python";
import "prismjs/themes/prism.css";

import { get, post, patch } from '../../common/utils.js';
import './ScriptEditor.scss';
// import 'antd/dist/antd.less';

export default class ScriptEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loaded: false,
            name: '',
            content: '',
            script_id: props.script_id,

            resultVisible: false,
            execResult: '',
            execStatus: null,
            executing: false,

            contentModified: false,
        };
    }

    componentDidMount() {
        if(this.state.script_id !== -1){
            this.fetchScriptDetails(this.state.script_id);
        }else{
            this.setState({loading: false, loaded: true});
        }
        console.log('this.props', this.props);
    }

    save = (onSuccess)=>{
        let self = this;
        let data = {name: this.state.name, content: this.state.content, folder_id: this.props.folder_id};
        this.setState({loading: true});
        if(this.state.script_id !== -1){
            patch(process.env.REACT_APP_SERVER_PATH + 'api/pan/scripts/' + this.state.script_id, data).then((retData) => {
                self.setState({loading: false});
                if (retData.data) {
                    self.setState({contentModified: false});
                    !onSuccess && message.success("保存成功", 0.5);
                    onSuccess && onSuccess();
                } else {
                    message.error("保存失败");
                };
            });
        }else{
            post(process.env.REACT_APP_SERVER_PATH + 'api/pan/scripts', data).then((retData) => {
                self.setState({loading: false});
                if (retData.data) {
                    self.setState({script_id: retData.data.id, contentModified: false})
                    !onSuccess && message.success("保存成功", 0.5);
                    onSuccess && onSuccess();
                } else {
                    message.error("保存失败");
                };
            });
        }
    }

    fetchScriptDetails = (script_id)=>{
        let self = this;
        self.setState({loading: true, loaded: false});
        get(process.env.REACT_APP_SERVER_PATH+'api/pan/scripts/' + script_id)
        .then(retData => {
            self.setState({loading: false, loaded: true});
            let d = retData.data
            if (d && d.id) {
                self.setState({
                    name: d.name,
                    content: (d.content === null ? '' : d.content)
                });
            } else {
            };
        });
    }

    execScript = ()=>{
        let self = this;
        let _exec = ()=>{
            self.setState({executing: true});
            post(process.env.REACT_APP_SERVER_PATH+'api/pan/exec_script', {
                script_id: self.state.script_id,
                file_id: (self.props.target_file_id ? self.props.target_file_id:null)
            }).then(retData => {
                self.setState({executing: false});
                let d = retData.data
                if (d && (d.errcode === 0 || d.errcode === 500)) {
                    self.setState({
                        execStatus: d.errcode,
                        execResult: d.result,
                        resultVisible: true,
                    });
                } else {
                    message.error('运行失败');
                };
            });
        }

        let __exec = ()=>{
            if(this.props.beforeExec){
                this.props.beforeExec().then(_=>_exec());
            }else{
                _exec();
            }
        }

        if(this.state.script_id == -1 || this.state.contentModified){
            this.save(__exec);
        }else{
            __exec();
        }
    }

    render() {
        let self = this;
        const { name, loading, loaded, resultVisible, executing, execStatus, execResult} = this.state;

        return (
            <div className="ScriptEditor">
                <Spin spinning={!loaded}></Spin>
                {
                    loaded &&
                    [
                        <div className="toolBar" key="s_e_tools">
                            <div className="leftTools">
                                <Button
                                    className="saveBtn"
                                    icon={<RollbackOutlined style={{color: '#919191'}} />}
                                    onClick={()=>this.props.onClose(true)}
                                    type="default"
                                ></Button>
                                <Input
                                    className="nameInput"
                                    style={{width:"300px"}}
                                    value={ name }
                                    placeholder="请输入脚本名称"
                                    onChange={e=>this.setState({name:e.target.value, contentModified: true})}
                                />
                            </div>
                            <div className="rightTools">
                                <Button
                                    style={{color: 'green', marginRight: '10px'}}
                                    type="default"
                                    icon={<PlayCircleOutlined />}
                                    loading={executing}
                                    onClick={_=>this.execScript()}
                                >运行</Button>
                                <Button className="saveBtn" onClick={()=>this.save()} loading={loading} type="primary">保存</Button>
                            </div>
                        </div>
                        ,
                        <div className="downContent" key="s_e_content">
                            <Editor
                                className="scriptEditor"
                                value={this.state.content}
                                onValueChange={(code) => this.setState({content: code, contentModified: true})}
                                highlight={(code) => highlight(code, languages.js)}
                                padding={10}
                                style={{
                                    overflowY: 'auto',
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 12,
                                }}
                            />
                        </div>
                    ]
                }
                <Modal
                    key={'task-result'}
                    visible={resultVisible}
                    width={700}
                    onCancel={()=>this.setState({resultVisible: false},_=>self.props.onExecFinish && self.props.onExecFinish())}
                    onOk={()=>this.setState({resultVisible: false},_=>self.props.onExecFinish && self.props.onExecFinish())}
                >
                    <div>
                        <Badge status={execStatus === 0 ? 'success':'error'} />
                        {execStatus === 0 ? '运行成功':'运行失败'}
                    </div>
                    <pre style={{whiteSpace: 'pre-wrap', marginTop: '10px'}}>{execResult}</pre>
                </Modal>
            </div>
        );
    }
}
