import moment from 'moment';

const getTimeRange = (timeType, timeStr) => {
    if (timeType === 'date') {
        return [moment(timeStr).format("YYYY-MM-DD 00:00:00"), moment(timeStr).add(1, 'days').format("YYYY-MM-DD 00:05:00")];
    } else if (timeType === 'week') {
        return [moment(timeStr).add(-1, 'weeks').startOf('week').format("YYYY-MM-DD 00:00:00"), moment(timeStr).endOf('week').add(1, 'days').format("YYYY-MM-DD 00:05:00")];
    } else if (timeType === 'month') {
        return [moment(timeStr).add(-1, 'months').startOf('month').format("YYYY-MM-DD 00:00:00"), moment(timeStr).endOf('month').add(1, 'days').format("YYYY-MM-DD 00:05:00")];
    };
    return null;
};

const state_2_color = {
    0: "#5BA3ED",
    1: "#FFC63C",
    2: "#FE8569",
};

const elec_config = [{
    "hm_begin": "00:00",
    "hm_end": "08:00",
    period: 8,
    "elec_price": 0.3936,
    "elec_state": 0
}, {
    "hm_begin": "08:00",
    "hm_end": "09:00",
    period: 1,
    "elec_price": 0.6405,
    "elec_state": 1
}, {
    "hm_begin": "09:00",
    "hm_end": "12:00",
    period: 3,
    "elec_price": 0.8874,
    "elec_state": 2
}, {
    "hm_begin": "12:00",
    "hm_end": "17:00",
    period: 5,
    "elec_price": 0.6405,
    "elec_state": 1
}, {
    "hm_begin": "17:00",
    "hm_end": "22:00",
    period: 5,
    "elec_price": 0.8874,
    "elec_state": 2
}, {
    "hm_begin": "22:00",
    "hm_end": "23:00",
    period: 1,
    "elec_price": 0.6405,
    "elec_state": 1
}, {
    "hm_begin": "23:00",
    "hm_end": "00:00",
    period: 1,
    "elec_price": 0.3936,
    "elec_state": 0
}]
    .map((item) => {
        let color = "transparent";
        if (typeof state_2_color[item.elec_state] === 'string') {
            color = state_2_color[item.elec_state];
        };
        return Object.assign({}, item, {
            color,
        });
    });

export {
    getTimeRange,
    elec_config,
};
