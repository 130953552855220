import React from 'react';
import echarts from 'echarts';

import TITLE_ICON from './res/title_icon.png';

import './QualityItemLarge.scss';
const moment = require('moment');

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.renderChart();
    }

    refNodeFunc = refNode => {
        this.refNode = refNode;
        this.renderChart();
    }

    renderChart = () => {
        if (!!this.refNode) {
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            const data = new Array(24).fill(0).map(() => Math.ceil(Math.random() * 100));
            const aveNum = Math.ceil(Math.random() * 10 + 80);
            const largerData = data.map(v => v > aveNum ? v : '-');
            const smallerData = data.map(v => v > aveNum ? '-' : v);
            // const [minV, maxV] = [Math.min(...data), Math.max(...data)];
            // console.log(data, minV, maxV);
            // return;
            instance.setOption({
                grid: {
                    left: 14,
                    top: 0,
                    right: 40,
                    bottom: 0,
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    boundaryGap: false,
                    // data: xData,
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    // min: yMin,
                    // max: yMax,
                },
                series: [
                    {
                        data: largerData,
                        type: 'bar',
                        stack: 'data',
                        itemStyle: {
                            color: 'rgba(150, 75, 82, 1)',
                            barBorderRadius: 2,
                        },
                        barWidth: 4,
                    },
                    {
                        data: smallerData,
                        type: 'bar',
                        stack: 'data',
                        itemStyle: {
                            color: 'rgba(74, 155, 135, 1)',
                            barBorderRadius: 2,
                        },
                        barWidth: 4,
                        markLine: {
                            silent: true,
                            animation: false,
                            symbol: ['none', 'none'],
                            data: [aveNum]
                                .map(v => ({
                                    yAxis: v,
                                    label: {
                                        show: false,
                                    },
                                    lineStyle: {
                                        color: 'rgba(255, 97, 112, 1)',
                                        type: 'dashed',
                                    },
                                })),
                        },
                    },
                ],
            });
        };
    }

    render() {
        const { item = {} } = this.props;
        const { name, rate, warning } = item;

        return (
            <div className="quality_item_large_wrapper">
                <div className="left">
                    <div className="item_title">
                        <div className="text">{name}</div>
                        <img alt="" src={TITLE_ICON} className="title_icon" />
                    </div>
                    <div className="rows_wrapper">
                        <div className="row_wrapper">
                            <div className="row_title">合格率</div>
                            <div className="row_value">{rate}%</div>
                        </div>
                        <div className="row_wrapper">
                            <div className="row_title">质量告警</div>
                            <div className={"row_value" + (warning > 0 ? " warning_row" : "")}>{warning}次</div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="chart_wrapper">
                        <div className="time">{moment().format('YYYY年MM月DD日')}</div>
                        {/* <div className="time">2020年12月20日</div> */}
                        <div className="chart_header_bar">
                            <div className="ave_color_icon" style={{ backgroundColor: 'rgba(68, 243, 218, 1)' }} />
                            <div className="ave_color_text">小时均值</div>
                            <div className="standard_color_icon" style={{ borderColor: `rgba(255, 97, 112, 1) transparent transparent transparent` }} />
                            <div className="standard_color_text">基准值</div>
                            <div className="value_text">KPR-</div>
                            <div className="value_number">74.3%</div>
                        </div>
                        <div className="chart_self_wrapper" ref={this.refNodeFunc} />
                    </div>
                </div>
            </div>
        );
    }
}
