import { useEffect, useMemo, useState } from 'react';
import Chart from './index.js';
import moment from 'moment';
import { post } from 'common/utils.js';

const Comp = (props) => {
    const { config, range, id } = props;
    const [lineData, setLineData] = useState(null);
    const { meta_list } = useMemo(() => {
        const meta_list = config?.meta_list ?? null;
        return {
            meta_list,
        };
    }, [config]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // console.log(tag_name, range);
        const tags = (meta_list ?? [])
            .map(({ tag_name }) => tag_name);
        if (tags.length > 0 && !!range) {
            const time_end = moment(range[1]);
            const time_begin = moment(range[0]);
            setLoading(true);
            let delay = true;
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                tag_list: tags
                    .map((tag_name) => {
                        return {
                            tag_name,
                            time_begin: moment(time_begin).format('YYYY-MM-DD HH:mm:ss'),
                            time_end: moment(time_end).format('YYYY-MM-DD HH:mm:ss'),
                            // interval: 60,
                        };
                    }),
            })
                .then(retData => {
                    if (!delay) {
                        return;
                    };
                    setLoading(false);
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        const nextLineData = (retData.data.results ?? [])
                            .reduce((pV, cV) => {
                                let list = cV.data_list
                                if (id === 1) {
                                    list = (list ?? []).map(([t, v]) => {
                                        let pV = v
                                        if (typeof pV === 'number') {
                                            pV = Math.floor(pV)
                                        }
                                        return [t, pV]
                                    })
                                }
                                return Object.assign({}, pV, { [cV.tag_name]: list });
                            }, {});
                        setLineData(nextLineData);
                    };
                });
            return () => {
                delay = false;
            };
        } else {
            setLineData(null);
        };
    }, [meta_list, range, id]);
    return (
        <Chart
            config={config}
            lineData={lineData}
            loading={loading}
        />
    );
};

export default Comp;
