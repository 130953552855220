import React, { useCallback } from 'react';
import style from './index.module.scss';
import ICON_CLOSE from './res/close.svg';
import { useEvents } from '../hooks';
import { Pagination, Typography } from 'antd';
import ICON_EDIT from './res/edit.svg';
import ICON_DELETE from './res/delete.svg';
import { PAGE_SIZE } from '../utils';

const { Text } = Typography;

const Comp = (props) => {
    const { onCancel } = props;
    const cancel = useCallback(() => {
        if (typeof onCancel === 'function') onCancel();
    }, [onCancel]);
    const [events, forceRefreshEvents, page, setPage, total] = useEvents();

    const editEventOnClick = (id, title) => {
        if (typeof props.editEventOnClick === "function") props.editEventOnClick(id, title);
        cancel();
    };

    const deleteEventOnClick = (id) => {
        if (typeof props.deleteEventOnClick === "function") props.deleteEventOnClick(id);
        forceRefreshEvents();
    };

    const paginationOnChange = (page, pageSize) => {
        setPage(page);
    };

    return (
        <div className={style['wrapper']}>
            <div className="header">
                <div className="close_btn_wrapper" onClick={cancel} >
                    {/* 扩大点击面积 */}
                    <img alt="" src={ICON_CLOSE} className="icon" />
                </div>
            </div>
            <div className="table_wrapper">
                {
                    !!events && (
                        <table>
                            <tbody>
                                {
                                    events
                                        .map((item, index) => {
                                            const { titleText, id, createTimeText, title } = item;
                                            return (
                                                <tr key={index}>
                                                    <td style={{ width: 40 }}>
                                                        <div className="key">
                                                            <div className="text">{index + 1}</div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: 136 }}>
                                                        <div className="time">{createTimeText}</div>
                                                    </td>
                                                    <td>
                                                        <div className="content_wrapper">
                                                            <div className="text_wrapper">
                                                                <Text
                                                                    ellipsis={{
                                                                        tooltip: titleText,
                                                                    }}
                                                                    className="text"
                                                                >{titleText}</Text>
                                                            </div>
                                                            <div className="tool_wrapper">
                                                                <img alt="" src={ICON_EDIT} className="icon" onClick={() => editEventOnClick(id, title)} />
                                                                <img alt="" src={ICON_DELETE} className="icon" onClick={() => deleteEventOnClick(id)} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                }
                            </tbody>
                        </table>
                    )
                }
            </div>
            <div className="footer">
                <div className="cus_pagination_wrapper">
                    <Pagination current={page} total={total} pageSize={PAGE_SIZE} showSizeChanger={false} size="small" showQuickJumper onChange={paginationOnChange} />
                </div>
            </div>
        </div>
    );
};

export default Comp;
