import React from 'react';
import style from './index.module.scss';
import IMG_PAGE from './res/page.png';

const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="img" style={{ backgroundImage: `url(${IMG_PAGE})` }}></div>
            </div>
        </div>
    );
};

export default Page;
