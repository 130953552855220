import './index.scss'
import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { message } from 'antd'
import { get } from '../../../common/utils'
import { getTemplateContentText } from '../AddCapsule/utils'
import QrCodeModal from '../AddCapsule/QrCodeModal'
import FullScreenLoading from '../../../components/FullScreenLoading'
const baseUrl = process.env.REACT_APP_SERVER_PATH

const MiniCapsuleDetail = props => {
  const { capsuleId, detailInfo, classifyText } = props
  const [loading, setLoading] = useState(true)
  const [templateId, setTemplateId] = useState(4) // 模版分类id
  const [capsuleDetailInfo, setCapsuleDetailInfo] = useState(null) // 详情对象
  const [isCodeVisible, changeCodeVisible] = useState(false)
  const detailRef = useRef()

  useEffect(() => {
    getCapsuleDetail()
    document.addEventListener('click', clickListener)

    return () => {
      document.removeEventListener('click', clickListener)
    }
  }, [])

  /** 获取胶囊详情 */
  const getCapsuleDetail = () => {
    const requestUrl = `${baseUrl}api/v1/event/capsule/singleCapsuleSearch`
    get(requestUrl, { capsuleId }).then(res => {
      if (res.data && res.data.error === 0) {
        const capsuleDetail = res.data.results || {}
        const json_content = JSON.parse(capsuleDetail.json_content) || {}
        capsuleDetail.jsonContent = json_content
        setCapsuleDetailInfo(capsuleDetail || {})
        setTemplateId(json_content && json_content.templateId ? json_content.templateId : 4)
      } else {
        message.error('获取胶囊详情信息失败')
      }
      // 接口返回很快的时候会感觉有闪动 loading加延迟关闭体验更好
      setTimeout(() => {
        setLoading(false)
      }, 100)
    })
  }

  /** 点击窗口外关闭面板 */
  const clickListener = event => {
    if (
      detailRef.current &&
      !detailRef.current.contains(event.target) &&
      event.target.className.indexOf('QrCodeModal') < 0
    ) {
      console.log('clickListener')
      closeDetailPanel()
    }
  }

  /** 显示二维码 */
  const showCodeModal = () => {
    changeCodeVisible(true)
  }

  /** 关闭二维码 */
  const handleCancelCodeModal = () => {
    changeCodeVisible(false)
  }

  const editClick = () => {
    if (typeof props.editClick === 'function') props.editClick(capsuleId)
  }

  /** 点击放大窗口 */
  const enlargeClick = () => {
    if (typeof props.enlargeClick === 'function') props.enlargeClick(capsuleId)
  }

  /** 关闭详情窗口 */
  const closeDetailPanel = () => {
    if (typeof props.closeClick === 'function') props.closeClick()
  }

  return (
    <div ref={detailRef} className="miniCapsuleDetail">
      {!!capsuleDetailInfo && (
        <>
          <div className="header">
            <div className="title">胶囊详情</div>
            <div className="header-right">
              <div className="edit-icon" onClick={editClick} />
              <div className="enlarge-icon" onClick={enlargeClick} />
              <div className="qrcode-icon" onClick={showCodeModal} />
              <div className="close-icon" onClick={closeDetailPanel} />
            </div>
          </div>
          <div className="content">
            <div style={{ color: '#727272', fontSize: 16, lineHeight: '24px' }}>
              <span style={{ fontWeight: 'bold', marginRight: 32 }}>
                {capsuleDetailInfo.orgName || ''} {capsuleDetailInfo.name || ''}
              </span>
              <span>{capsuleDetailInfo.occur_time}</span>
              <span className="classify-text">{classifyText}</span>
            </div>
            <div className="capsule-title">{capsuleDetailInfo.jsonContent.title}</div>
            <div className="capsule-info">
              {getTemplateContentText(templateId, capsuleDetailInfo.jsonContent)}
            </div>
          </div>
          <QrCodeModal
            isCodeVisible={isCodeVisible}
            templateId={templateId}
            capsuleDetailInfo={capsuleDetailInfo}
            handleCancelCodeModal={handleCancelCodeModal}
          />
        </>
      )}

      {loading && <FullScreenLoading loadingTip="数据加载中..." />}
    </div>
  )
}

export default MiniCapsuleDetail
