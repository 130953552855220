import React, { useCallback, useState } from 'react';
import style from './index.module.scss';
import { significantNum } from 'common/utils';
import { Modal } from 'antd';
import DiagramChart from 'containers/DiagramChart';

const Comp = (props) => {
    const [chartInfo, setChartInfo] = useState(null);
    const watchVal = useCallback((item) => {
        return () => {
            setChartInfo(item);
        };
    }, []);
    return (
        <div className={style['wrapper'] + " scroll_bar_restyle"}>
            <div className="content">
                {
                    (props?.info ?? [])
                        .map((item, index) => {
                            const {
                                name, unit,
                                tagName,
                            } = item;
                            const value = props?.tag2Val?.[tagName] ?? null;
                            const parsed_v = (typeof value === 'number' && !isNaN(value)) ? significantNum(value) : "--";
                            return (
                                <div className="item" key={index}>
                                    <div className="top">{name}</div>
                                    <div className="bottom">
                                        <div className="value" onClick={watchVal(item)}>{parsed_v}</div>
                                        <div className="unit">{unit}</div>
                                    </div>
                                </div>
                            );
                        })
                }
            </div>
            {
                chartInfo && (
                    <Modal
                        visible={true}
                        okButtonProps={{ style: { display: "none" } }}
                        onCancel={() => {
                            setChartInfo(null);
                        }}
                        width={1200}
                        title="点位曲线图"
                        cancelText="关闭"
                    >
                        <div style={{ height: 600 }}>
                            <DiagramChart tag_alias={chartInfo.tagName} />
                        </div>
                    </Modal>
                )
            }
        </div>
    );
};

export default Comp;
