import { useEffect, useState } from 'react';
import { get } from 'common/utils';

const Hook = () => {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        let delay = true;
        setLoading(true);
        get(process.env.REACT_APP_SERVER_PATH + 'api/form/get_catetory_tree', {
            has_form_tpl: true,
        })
            .then((retData) => {
                if (delay) {
                    setLoading(false);
                    // console.log('hook', retData);
                    if (retData?.data?.errcode === 0) {
                        let nextResult = retData.data;
                        let parsed_results = nextResult.results ?? null;
                        if (!!parsed_results) {
                            parsed_results = parsed_results
                                .map((item) => {
                                    const sub_children = (item.children ?? [])
                                        .filter(({ isLeaf }) => !isLeaf);
                                    const direct_templates = (item.children ?? [])
                                        .filter(({ isLeaf }) => isLeaf);
                                    return Object.assign({}, item, {
                                        sub_children,
                                        direct_templates,
                                    });
                                });
                        };
                        nextResult = Object.assign({}, nextResult, {
                            parsed_results,
                        });
                        // console.log(nextResult);
                        setResult(nextResult);
                    } else {
                        // 处理错误
                        setResult(null);
                    };
                };
            });
        return () => {
            delay = false;
        };
    }, []);
    return [result, loading];
};

export default Hook;
