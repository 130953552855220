const bckColors = [
    {
        begin_color: '#264775',
        end_color: 'rgba(26,57,99)',
        mid_offset: 0.7,
    },
    {
        begin_color: '#3A5881',
        end_color: 'rgba(40,69,108)',
        mid_offset: 0.6,
    },
    {
        begin_color: '#506B90',
        end_color: 'rgba(64,90,127)',
        mid_offset: 0.75,
    },
    {
        begin_color: '#7B8FAA',
        end_color: 'rgba(98,119,148)',
        mid_offset: 0.8,
    },
    {
        begin_color: '#657C9C',
        end_color: 'rgba(90,114,146)',
        mid_offset: 0.9,
    },
    {
        begin_color: '#8FA0B7',
        end_color: 'rgba(128,146,170)',
        mid_offset: 0.85,
    },
    {
        begin_color: '#A4B1C4',
        end_color: 'rgba(147,161,182)',
        mid_offset: 0.71,
    },
    {
        begin_color: '#B9C3D1',
        end_color: 'rgba(166,178,193)',
        mid_offset: 0.55,
    },
    {
        begin_color: '#CFD5DE',
        end_color: 'rgba(185,194,205)',
        mid_offset: 0.9,
    },
]; // sorted

export {
    bckColors,
};
