import React from 'react';
import moment from 'moment';
import { Table, Button } from 'antd';
import { post, roundNum } from '../../../../common/utils';
import style from './index.module.scss';

export default class RecordsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            dataList: [],
        };
    }

    componentDidMount(){
        this.getDataList(this.props);
        console.log("props.addons", this.props.addons);
        console.log("props", this.props);
    }

    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps');
        this.getDataList(nextProps);
    }

    updateValue = (props)=>{
        // props.onChange(value);
        console.log('updateValue');
        this.getDataList(props);
    }

    calcAvgValues = ()=>{
        let Fe = 0;
        let Mn = 0;
        let H2O = 0;
        let Fe_num = 0;
        let Mn_num = 0;
        let H2O_num = 0;
        this.state.dataList.map(item=>{
            if(item['Fe']){
                Fe += item['Fe'];
                Fe_num += 1;
            }
            if(item['Mn']){
                Mn += item['Mn'];
                Mn_num += 1;
            }
            if(item['H2O']){
                H2O += item['H2O'];
                H2O_num += 1;
            }
        })
        let Fe_avg = roundNum(Fe * 1.0 / Fe_num, 2);
        let Mn_avg = roundNum(Mn * 1.0 / Mn_num, 2);
        let H2O_avg = roundNum(H2O * 1.0 / H2O_num, 2);
        let val_str = JSON.stringify({
            Fe: Fe_avg,
            Mn: Mn_avg,
            H2O: H2O_avg,
        })
        this.props.onChange(val_str);
    }

    getDataList = (props)=>{
        let sample_name = props.addons.getValue("sample_name");
        if(!sample_name){
            return;
        }
        let date = props.addons.getValue("date");
        if(!date){
            return;
        }

        post(process.env.REACT_APP_SERVER_PATH + 'api/form/get_list', {
            tpl_code: ["HUAYAN_MENGKUANG_RUCHANG", "HUAYAN_MENGKUANG_RUCHANG_SIMPLE"],
            page: 1,
            page_size: 999,
            sample_name: sample_name
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                let dataList = retData.data.results.filter(item=>{
                    let c_json = JSON.parse(item.content);
                    let _date = c_json['date'];
                    if(_date !== date){
                        return false;
                    }
                    if(c_json['SiO2'] !== undefined && c_json['SiO2'] !== null){
                        return false;
                    }
                    if(c_json['CaO'] !== undefined && c_json['CaO'] !== null){
                        return false;
                    }
                    if(c_json['MgO'] !== undefined && c_json['MgO'] !== null){
                        return false;
                    }
                    if(c_json['Al2O3'] !== undefined && c_json['Al2O3'] !== null){
                        return false;
                    }
                    return true;
                }).map(item=>{
                    let c_json = JSON.parse(item.content);
                    return {
                        'id': item.id,
                        'date': c_json['date'],
                        'Mn': c_json['Mn'],
                        'Fe': c_json['Fe'],
                        'H2O': c_json['H2O'],
                        'remark': c_json['remark'],
                    }
                })
                this.setState({ dataList: dataList });
            };
        });
    }

    render() {
        const { value, dataList } = this.state;
        return (
            <div className={style['wrapper']}>
                <Table
                    dataSource={dataList}
                    pagination={false}
                    size="small"
                    rowKey="id"
                    columns={[
                        // {
                        //     title: '序号',
                        //     key: 'index',
                        //     render: (text, item, index) => {
                        //         return index
                        //     }
                        // },
                        {
                            title: '时间',
                            key: 'date',
                            render: (text, item, index) => {
                                return item.date
                            }
                        },{
                            title: '水分（%）',
                            key: 'H2O',
                            dataIndex: 'H2O',
                        },{
                            title: 'Mn（%）',
                            key: 'Mn',
                            dataIndex: 'Mn',
                        },{
                            title: 'Fe（%）',
                            key: 'Fe',
                            dataIndex: 'Fe',
                        },{
                            title: '备注',
                            key: 'remark',
                            dataIndex: 'remark',
                        }
                    ]}
                />
                <Button
                    style={{marginTop: "20px"}}
                    type="primary"
                    ghost
                    onClick={()=>this.calcAvgValues()}
                >计算均值</Button>
            </div>
        )
    }
}
