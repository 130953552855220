import React from 'react'
import { Divider, Layout, Breadcrumb, Table, Button, Input, Spin, message, Modal } from 'antd';
import { post } from 'common/utils.js';
import './MetaDataList.scss';

const { Column } = Table;


export default class MetaDataList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pageSize: 10,
            total: 0,
            dataList: [],
            current: 1,
            selectRuleId: 0
        }
    }

    getDataList = (page) => {
        let self = this;
        self.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            page_size: this.state.pageSize,
            page: page,
        }).then((retData) => {
            if (retData.data && retData.data.results) {
                self.setState({
                    dataList: retData.data.results,
                    loading: false,
                    current: page,
                    total: retData.data.count
                });
            } else {
                message.error("加载失败");
            }
        });
    }
    componentWillMount() {
        this.getDataList(1);
    }


    render() {
        let self = this;
        let { pageSize, page, total, dataList, loading } = this.state;
        return (
            <Layout className="AlertPushRuleList layout-container">
                <div className="pg-header">
                    <div className="pg-header-left">
                        <div>
                            <span style={{ color: 'rgba(0,0,0,.45)' }}>工艺大数据 /</span>
                            <span> 数据点位管理</span>
                        </div>
                    </div>
                    <div className="pg-header-right"></div>
                </div>
                <Layout className="layout-first">
                    <Layout className="layout-second">
                        <div className="headerOpt">
                            <Button type="primary" className="header-opt-build" onClick={() => self.props.history.push("/PEIMSV2/AlarmPushRuleDetails/-1")} style={{ float: 'right' }} >新建</Button>
                        </div>
                        <Table
                            rowKey='id'
                            dataSource={dataList}
                            pagination={{
                                defaultCurrent: 1,
                                current: page,
                                pageSize: pageSize,
                                total: total,
                                showSizeChanger: false,
                                showQuickJumper: true,
                            }}
                            loading={loading}
                            onChange={pagination => {
                                self.setState({ pageSize: pagination.pageSize }, () => self.getPushRuleList(pagination.current))
                            }
                            }
                        >
                            <Column
                                title='源数据点位地址'
                                dataIndex='src_tag'
                                key='src_tag'
                            />
                            <Column
                                title='源数据点位名称'
                                dataIndex='src_name'
                                key='src_name'
                            />
                            <Column
                                title='更新数据点位地址'
                                dataIndex='tag_name'
                                key='tag_name'
                            />
                            <Column
                                title='更新数据点位名称'
                                dataIndex='name'
                                key='name'
                            />
                            <Column
                                title='数据类型'
                                dataIndex='value_type'
                                key='value_type'
                            />
                            <Column
                                title='单位'
                                dataIndex='unit'
                                key='unit'
                            />
                            <Column
                                title='显示精度'
                                dataIndex='precision'
                                key='precision'
                            />
                            <Column
                                title='所属系统'
                                dataIndex='sys_name'
                                key='sys_name'
                            />
                            <Column
                                title='所属工序'
                                dataIndex='procedure'
                                key='procedure'
                            />
                            <Column
                                title='所属设备'
                                dataIndex='machine_name'
                                key='machine_name'
                            />
                            <Column
                                title='数据源'
                                dataIndex='src_type'
                                key='src_type'
                            />
                            <Column
                                title='启用状态'
                                dataIndex='status'
                                key='status'
                            />
                            <Column
                                title='备注'
                                dataIndex='remark'
                                key='remark'
                            />
                            <Column
                                title='启用状态'
                                dataIndex='status'
                                key='status'
                            />
                            <Column
                                title="操作"
                                key="operate"
                                width={120}
                                render={(text, record) => (
                                    <span>
                                        <a onClick={() => self.props.history.push("/PEIMSV2/AlarmPushRuleDetails/" + record.id)}>编辑</a>
                                        <Divider type="vertical" />
                                        <a onClick={() => self.setState({ deleteAlertPushRuleVisible: true, selectRuleId: record.id })}>查看趋势</a>
                                    </span>
                                )}
                            />
                        </Table>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
