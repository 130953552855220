import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import Header from './HeaderWrapper';
import LeftMenu from './Menu'
// import ScaleWrapper from 'components/ScaleWrapper';
const Page = (props) => {

    // 通过url参数获得iframe路由
    const [page, setPage] = useState(null);
    useEffect(() => {
        let page = new URLSearchParams(props.location.search).get('page') ?? null;
        if (!!page) {
            page = window.decodeURIComponent(page);
        };
        setPage(page);
        console.log(page, props);
    }, [props]);

    return (
        <div className={style['wrapper']}>
            <div className='scroll_wrapper'>
                <div className='content'>
                    <Header />
                    <div className="bottom_wrapper">
                        <div className="left">
                            <LeftMenu />
                        </div>
                        <div className="right">
                            <div className="content">
                                {
                                    !!page && (
                                        <iframe src={page} style={{ width: "100%", height: "100%", borderWidth: 0 }}></iframe>
                                    )
                                }
                                {/* {props.children} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Page);
