import React, { useMemo } from 'react';

const Comp = (props) => {
    const [width, backgroundColor] = useMemo(() => {
        const degree = props?.degree;
        if (typeof degree === 'number' && degree >= 0 && degree <= 1) {
            return [`${degree * 100}%`, degree < 1 ? "#355B99" : "#FB6060"];//mock逻辑小于1为蓝，1为红，后续更新
        };
        return [null, null];
    }, [props]);
    return typeof width === "string" ?
        (
            <div>
                <div style={{ width, backgroundColor, height: 8 }}></div>
            </div>
        )
        :
        null;
};

export default Comp;
