import React from 'react'
import './index.scss'
import FirstTpl from "./FirstTemplate";
import SecondTpl from "./SecondTemplate";
import ThirdTpl from './ThirdTemplate'
import FourTpl from "./FourTemplate";
import FiveTpl from "./FiveTemplate";
import SixTpl from './SixTemplate'
const CapsuleTemplate = () => {
    return (
        <div>
            {/*<FirstTpl />*/}
            {/*<SecondTpl />*/}
            <ThirdTpl />
            {/*<FourTpl />*/}
            {/*<FiveTpl />*/}
            {/*<SixTpl />*/}
        </div>
    )
}
export default CapsuleTemplate
