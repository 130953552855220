import React from 'react';
import { Table } from 'antd';
import style from './RightViewer.module.scss';
import { rightTableHeaderSortableThs, BOTTOM_CHART_HEIGHT } from './constants.js';
import { getFactorDetailChartOption } from './utils.js';
import ICON_FOLD from './res/fold.svg';
import { significantNum, post } from '../../../common/utils';
import moment from 'moment';

const echarts = require('echarts');
export default class RightViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortBy: null,//['weight', true]:[key, sortBigFirst]
            data: null,
            showDetailList: [],
        };
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate() {
        this.updateData();
    }

    updateData = () => {
        const { data } = this.props;
        const { data: prevData } = this.state;
        if (data !== prevData) {
            // console.log(data);
            this.setState({
                data,
                sortBy: null,
                showDetailList: [],
            });
        };
    }

    chartRefFunc = (item) => {
        return (refNode) => {
            const { tag_name, key } = item;
            const refNodeName = `chartRefNode-${key}`;
            this[refNodeName] = refNode;
            if (!!refNode) {
                // return;
                // 先出来时让刷新
                let instance = echarts.getInstanceByDom(refNode);
                const { timeInfo } = this.props;
                if (!instance && !!timeInfo) {
                    const { time, range } = timeInfo;
                    const time_begin = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
                    const time_end = moment(time).endOf(range).format("YYYY-MM-DD 23:59:59");
                    instance = echarts.init(refNode);
                    instance.showLoading();
                    //因为有刷新功能，所以获取过数据的可以直接展示出来
                    const lockName = `${refNodeName}-lock`;
                    if (this[lockName] === true) {
                        //上次请求未完成，不重新请求
                        return;
                    };
                    this[lockName] = true;
                    post(process.env.REACT_APP_SERVER_PATH + "api/data/get_history_data", {
                        // time_type: 'seconds',
                        tag_list: [
                            {
                                tag_name,
                                time_begin,
                                time_end,
                            },
                        ],
                    })
                        .then(retData => {
                            // console.log(retData);
                            if (retData?.data?.errcode === 0) {
                                const hisData = retData?.data?.results?.[0]?.data_list ?? null;
                                this.renderBottomChart(refNodeName, hisData);
                            } else {
                                const refNode = this[refNodeName];
                                if (!!refNode) {
                                    let instance = echarts.getInstanceByDom(refNode);
                                    if (!instance) {
                                        instance = echarts.init(refNode);
                                    };
                                    instance.showLoading(
                                        'default',
                                        {
                                            text: '暂无数据',
                                            color: '#c23531',
                                            textColor: '#000',
                                            maskColor: 'rgba(255, 255, 255, 0.8)',
                                            zlevel: 0,

                                            // 字体大小。从 `v4.8.0` 开始支持。
                                            fontSize: 12,
                                            // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
                                            showSpinner: false,
                                            // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
                                            // spinnerRadius: 10,
                                            // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
                                            // lineWidth: 5
                                        },
                                    );
                                };
                            };
                            this[lockName] = false;
                        });
                };
            };
        };
    }

    renderBottomChart = (refNodeName, hisData) => {
        if (!hisData) {
            return;
        };
        const refNode = this[refNodeName];
        if (!!refNode) {
            let instance = echarts.getInstanceByDom(refNode);
            if (!instance) {
                instance = echarts.init(refNode);
            };
            instance.hideLoading();
            instance.setOption(getFactorDetailChartOption(hisData));
        };
    }

    sortOnClick = key => {
        const { sortBy } = this.state;
        const newSortBy = [key, true];
        if (!!sortBy && sortBy[0] === key) {
            newSortBy[1] = !sortBy[1];
        };
        this.setState({ sortBy: newSortBy });
    }

    factorShowDetailOnClick = (item) => {
        this.setState({ showDetailList: [item?.key] });
    }

    closeChart = () => {
        this.setState({ showDetailList: [] });
    };

    render() {
        const { data, showDetailList, sortBy } = this.state;
        let sortedData = data;
        if (!!sortBy && !!data) {
            const [key, sortBigFirst] = sortBy;
            sortedData = []
                .concat(data)
                .sort((data1, data2) => {
                    if (sortBigFirst) {
                        return data1[key] - data2[key];
                    } else {
                        return data2[key] - data1[key];
                    };
                });
        };
        const getSortableTh = ({ name, key }) => {
            return (
                <div className="th_block_wrapper">
                    <div
                        className="th_sort_wrapper"
                        onClick={() => this.sortOnClick(key)}
                    >
                        <div className="title">{name}</div>
                        <div className="sort_icons_wrapper">
                            <div className={"icon icon_up" + (!!sortBy && sortBy[0] === key && sortBy[1] ? " icon_up_selected" : "")} />
                            <div className={"icon icon_down" + (!!sortBy && sortBy[0] === key && !sortBy[1] ? " icon_down_selected" : "")} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={style["exam_analysis_right_viewer_wrapper"]}>
                {/* 右侧视图 */}
                <div className='title1'>竞赛操作分析</div>
                <div className="cus_table_wrapper">
                    <Table
                        className="cus_table_wrapper_in_board_table"
                        dataSource={sortedData ?? []}
                        columns={[
                            {
                                title: '指标名称',
                                dataIndex: 'rule_name',
                                className: 'name_cell',
                                width: 180,
                                onCell: (item, rowIndex) => {
                                    const { key } = item;
                                    return {
                                        onClick: () => {
                                            if (!showDetailList.includes(key)) {
                                                this.factorShowDetailOnClick(item);
                                            };
                                        },
                                    }
                                },
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[0]),
                                dataIndex: 'rule_weight',
                                render: (text) => {
                                    return text === 1.5 ? "重要" : (text === 1 ? "一般" : "--");
                                },
                                align: 'center',
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[1]),
                                dataIndex: 'score',
                                render: (text) => {
                                    const parsed_text = (typeof text === 'number' && !isNaN(text)) ? significantNum(text) : "--";
                                    return parsed_text;
                                },
                                align: 'center',
                            },
                        ]}
                        pagination={false}
                        scroll={{ y: true }}
                        rowClassName={({ key }, index) => {
                            return (index % 2 === 1 ? "tr_dark_bck" : "") + (showDetailList.includes(key) ? " expanded_factor_tr" : "");
                        }}
                        expandable={{
                            expandedRowRender: (r) => {
                                // console.log("console log new node", tag_name);
                                return (
                                    <div className="chart_wrapper">
                                        <img
                                            alt=""
                                            src={ICON_FOLD}
                                            className="fold_icon"
                                            onClick={this.closeChart}
                                        />
                                        <div
                                            className="chart"
                                            ref={this.chartRefFunc(r)}
                                            style={{
                                                // width: tableExpandChartWidth,
                                                height: BOTTOM_CHART_HEIGHT,
                                            }}
                                        />
                                    </div>
                                )
                            },
                            rowExpandable: ({ key }) => showDetailList.includes(key),//
                            expandedRowKeys: showDetailList,
                            expandIconColumnIndex: -1,//antd4.5 support antd4.3 not support
                            // expandIcon: () => (<span />),
                        }}
                    />
                </div>
            </div>
        );
    }
}
