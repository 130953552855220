import React, { Component } from 'react'
import { Modal, message, Form, Tabs, Select, Empty } from 'antd'
import Style from './index.module.scss'
import moment from 'moment'
import { get, post, reqDelete } from '../../common/utils'
import FullScreenLoading from '../../components/FullScreenLoading'
import AddShiftCycleModal from './AddShiftCycleModal'
const { TabPane } = Tabs
const { Option } = Select
const { confirm } = Modal
const baseUrl = process.env.REACT_APP_SERVER_PATH
class Period extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true, // 数据加载的loading
      confirmLoading: false, // 数据提交的loading
      showPrompt: false,
      activeKey: '', // 当前在用的排班周期id
      shiftCycleList: [], // 排班周期列表
      shiftList: [], // 排班时间列表
      shiftGroupList: [], // 可选班组列表
      singleShiftCycleDetail: {},
      dataImageStr: '[]', // 排班快照，用于对比表单数据是否有变更
      showAddModal: false
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  /** 打开弹窗 */
  onShow() {
    // 前期数据准备
    this.getShiftCycleList()
    this.getShiftList()
    this.getShiftGroupList()
    this.setState({ showPrompt: true, loading: true, confirmLoading: false })
  }

  /** 获取排班周期列表 */
  getShiftCycleList = () => {
    get(`${baseUrl}api/v1/event/shift/shiftCycleQuery`).then(resData => {
      if (!!resData.data && resData.data.error === 0) {
        let key = ''
        if (!!resData.data.results && resData.data.results.length > 0) {
          resData.data.results.some(item => {
            if (item.default === 1) {
              key = item.id
              return
            }
          })
          if (!key) {
            key = resData.data.results[0].id
          }
          this.getSingleShiftCycleDetail(key)
        }
        this.setState({ shiftCycleList: resData.data.results || [] })
      }
      this.setState({ loading: false })
    })
  }

  /** 根据周期id获取详情 */
  getSingleShiftCycleDetail = id => {
    get(`${baseUrl}api/v1/event/shift/cycleQuery`, { id }).then(resData => {
      if (!!resData.data && resData.data.error === 0) {
        this.setState({
          activeKey: String(id),
          singleShiftCycleDetail: resData.data.results || {},
          dataImageStr: JSON.stringify((resData.data.results || {}).data || [])
        })
      } else {
        message.error('排班周期详情获取失败！')
      }
    })
  }

  /** 获取班组列表 */
  getShiftGroupList = () => {
    get(`${baseUrl}api/v1/event/shift/shiftGroupQueries`).then(resData => {
      if (!!resData.data && resData.data.error === 0) {
        this.setState({ shiftGroupList: resData.data.results || [] })
      }
    })
  }

  /** 获取排班时间列表作为动态列 */
  getShiftList = () => {
    get(baseUrl + 'api/v1/event/report/shiftConfig').then(shiftData => {
      if (!!shiftData.data && shiftData.data.error === 0) {
        this.setState({
          shiftList: shiftData.data.results || []
        })
      }
    })
  }

  /** 切换排班周期 */
  changeShiftCycleTab = tabKey => {
    this.checkIsChangeOrAddShiftCycle(() => {
      this.getSingleShiftCycleDetail(tabKey)
    })
  }

  /** 切换班组 */
  changeSelected = (targetValue, rowIndex, columnIndex) => {
    const { singleShiftCycleDetail } = this.state
    singleShiftCycleDetail.data[rowIndex].shiftGroupIds[columnIndex] = targetValue
    this.setState({ singleShiftCycleDetail })
  }

  /** 点击添加排班周期，弹出弹框 */
  addLocalShiftCycleClick = () => {
    this.checkIsChangeOrAddShiftCycle(() => {
      this.setState({ showAddModal: true })
    })
  }

  /** 关闭添加排班周期弹窗 */
  closeAddModal = () => {
    this.setState({ showAddModal: false })
  }

  /** 校验是否可以切换排班周期或添加排班周期 */
  checkIsChangeOrAddShiftCycle = callback => {
    const { shiftCycleList } = this.state
    const localShiftCycle = shiftCycleList.some(item => item.id === '')
    if (localShiftCycle) {
      message.warning('请先保存当前排班周期！')
      return false
    }
    if (this.checkFormDataChanged()) {
      confirm({
        title: `当前排班周期数据未保存，是否继续?`,
        content: '继续执行，当前未保存的数据将无法恢复！',
        onOk() {
          callback && callback()
        },
        onCancel() {}
      })
    } else {
      callback && callback()
    }
  }

  /** 检查form表单数据是否有变更 */
  checkFormDataChanged = () => {
    const { singleShiftCycleDetail, dataImageStr } = this.state
    const newJson = JSON.stringify(singleShiftCycleDetail.data || [])
    if (dataImageStr !== newJson) {
      return true
    }
    return false
  }

  /** 添加排班周期 */
  addLocalShiftCycle = event => {
    this.closeAddModal()
    const { name, dayNum } = event
    const { shiftCycleList, shiftList } = this.state
    const shiftCycleInfo = {
      id: '',
      name,
      data: [],
      days: dayNum
    }

    for (let i = 0; i < dayNum; i++) {
      shiftCycleInfo.data.push({
        shiftGroupIds: new Array(shiftList.length).fill(null),
        isDelete: 0
      })
    }
    shiftCycleList.push(shiftCycleInfo)

    this.setState({
      activeKey: '',
      shiftCycleList,
      singleShiftCycleDetail: shiftCycleInfo,
      dataImageStr: JSON.stringify(shiftCycleInfo.data)
    })
  }

  /** 添加排班 */
  addRow = (rowIndex, position) => {
    const { shiftList, singleShiftCycleDetail } = this.state
    const addRow = { shiftGroupIds: new Array(shiftList.length).fill(null) }
    if (position === 'before') {
      singleShiftCycleDetail.data.splice(rowIndex, 0, addRow)
    } else {
      singleShiftCycleDetail.data.splice(rowIndex + 1, 0, addRow)
    }
    singleShiftCycleDetail.days = singleShiftCycleDetail.data.length
    this.setState(singleShiftCycleDetail)
  }

  /** 删除排班 */
  deleteRow = rowIndex => {
    const { singleShiftCycleDetail } = this.state
    singleShiftCycleDetail.data.splice(rowIndex, 1)
    singleShiftCycleDetail.days = singleShiftCycleDetail.data.length
    this.setState(singleShiftCycleDetail)
  }

  // 提交表单
  handleOk = e => {
    if (this.checkDataValidate()) {
      this.editShiftCycle()
      console.log(e)
    }
  }

  /** 取消 */
  handleCancel = () => {
    this.setState({
      showPrompt: false,
      activeKey: '',
      singleShiftCycleDetail: {},
      dataImageStr: '[]',
      shiftCycleList: [],
      shiftGroupList: [],
      shiftList: []
    })
  }

  /** 删除排班周期 */
  onDeleteClick = tabKey => {
    const { shiftCycleList } = this.state
    const shiftCycleItem = shiftCycleList.filter(item => String(item.id) === String(tabKey))
    const self = this
    if (shiftCycleItem && shiftCycleItem.length > 0) {
      confirm({
        title: `确认删除<${shiftCycleItem[0].name}>?`,
        content: '',
        onOk() {
          console.log('删除')

          self.deleteShiftCycle(tabKey)
        },
        onCancel() {}
      })
    }
  }

  /** 删除排班周期 */
  deleteShiftCycle = shiftCycleId => {
    if (shiftCycleId === '') {
      this.resetActiveShiftCycle(shiftCycleId)
    } else {
      reqDelete(`${baseUrl}api/v1/event/shift/shiftCycleDel?id=${shiftCycleId}`).then(
        resultData => {
          if (!!resultData.data && resultData.data.error === 0) {
            message.success('删除成功')
            this.resetActiveShiftCycle(shiftCycleId)
          } else {
            message.error(resultData.data.msg)
          }
        }
      )
    }
  }

  /** 重置高亮排班周期 */
  resetActiveShiftCycle = removeKey => {
    const { shiftCycleList } = this.state
    const tempList = shiftCycleList.filter(item => String(item.id) !== String(removeKey))
    let newActiveKey = ''
    const defaultUseShiftCycle = tempList.filter(item => item.default === 1)
    if (defaultUseShiftCycle && defaultUseShiftCycle.length > 0) {
      newActiveKey = defaultUseShiftCycle[0].id
    } else if (tempList.length > 0) {
      newActiveKey = tempList[0].id
    }
    newActiveKey && this.getSingleShiftCycleDetail(newActiveKey)
    this.setState({ shiftCycleList: tempList })
  }

  /** 提交排班周期 */
  editShiftCycle = () => {
    this.setState({ confirmLoading: true })
    const { singleShiftCycleDetail } = this.state
    const { data } = singleShiftCycleDetail
    // 处理index
    !!data &&
      data.forEach((item, index) => {
        item['index'] = index + 1
      })
    delete singleShiftCycleDetail['createTime']
    singleShiftCycleDetail.id = singleShiftCycleDetail.id || null
    post(`${baseUrl}api/v1/event/shift/shiftCycleAdd`, { ...singleShiftCycleDetail }).then(
      resData => {
        if (!!resData.data && resData.data.error === 0) {
          message.success('提交成功')
          if (typeof this.props.refreshShiftCycleList === 'function')
            this.props.refreshShiftCycleList()
          this.handleCancel()
        } else {
          message.warning(resData.data.msg)
        }
        this.setState({ confirmLoading: false })
      }
    )
  }

  /** 校验表单数据是否完整 */
  checkDataValidate = () => {
    const { singleShiftCycleDetail } = this.state
    if (
      singleShiftCycleDetail &&
      singleShiftCycleDetail.data &&
      singleShiftCycleDetail.data.some(item => item.shiftGroupIds.includes(null))
    ) {
      message.warning('请完整填写信息！')
      return false
    }
    return true
  }

  render() {
    const {
      showPrompt,
      loading,
      confirmLoading,
      shiftCycleList,
      shiftList,
      singleShiftCycleDetail,
      shiftGroupList,
      activeKey,
      showAddModal
    } = this.state
    return (
      <Modal
        visible={showPrompt}
        title="排班周期设置"
        width="728px"
        className={`${Style['setting-modal']} ${
          !shiftCycleList || shiftCycleList.length === 0 ? Style['setting-modal-empty'] : ''
        }`}
        confirmLoading={confirmLoading}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <div className="add-btn" onClick={this.addLocalShiftCycleClick}>
          添加排班周期
        </div>
        <Tabs
          hideAdd
          tabPosition="left"
          activeKey={activeKey}
          onChange={e => this.changeShiftCycleTab(e)}
          type="editable-card"
          onEdit={e => this.onDeleteClick(e)}
        >
          {shiftCycleList.map(tabItem => {
            return (
              <>
                <TabPane tab={tabItem.name} key={tabItem.id}>
                  <Form className="form">
                    <div className="total-days">共{singleShiftCycleDetail.days}天</div>
                    <ul className="setting-info">
                      <li className="setting-item">
                        <div className="order">序号</div>
                        {shiftList.map(shiftItem => {
                          return (
                            <div key={shiftItem.id} title={shiftItem.name}>
                              {shiftItem.name}
                            </div>
                          )
                        })}
                        <div className="operation">操作</div>
                      </li>
                      {!!singleShiftCycleDetail.data &&
                        singleShiftCycleDetail.data.map((rowItem, rowIndex) => {
                          return (
                            <li className="setting-item">
                              <div className="order">{rowIndex + 1}</div>
                              {!!rowItem.shiftGroupIds &&
                                rowItem.shiftGroupIds.map((columnItem, columnIndex) => {
                                  return (
                                    <div key={`${rowIndex}_${columnIndex}`}>
                                      <Select
                                        value={columnItem}
                                        size="small"
                                        style={{ width: 88 }}
                                        onChange={targetValue =>
                                          this.changeSelected(targetValue, rowIndex, columnIndex)
                                        }
                                      >
                                        <Option value={null}>请选择</Option>
                                        {shiftGroupList.map(shiftGroupItem => {
                                          return (
                                            <Option
                                              key={shiftGroupItem.id}
                                              value={shiftGroupItem.id}
                                            >
                                              {shiftGroupItem.name}
                                            </Option>
                                          )
                                        })}
                                      </Select>
                                    </div>
                                  )
                                })}
                              <div className="operation">
                                {singleShiftCycleDetail.data.length > 1 && (
                                  <i
                                    className="btn-item delete-btn"
                                    onClick={() => this.deleteRow(rowIndex)}
                                  />
                                )}
                                <i
                                  className="btn-item add-before-btn"
                                  onClick={() => this.addRow(rowIndex, 'before')}
                                />
                                <i
                                  className="btn-item add-after-btn"
                                  onClick={() => this.addRow(rowIndex, 'after')}
                                />
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  </Form>
                </TabPane>
              </>
            )
          })}
        </Tabs>
        {(loading || confirmLoading) && (
          <FullScreenLoading loadingTip={loading ? '数据加载中...' : '数据提交中...'} />
        )}
        {!loading && (!shiftCycleList || shiftCycleList.length === 0) && (
          <Empty description="暂无排班周期" />
        )}
        {showAddModal && (
          <AddShiftCycleModal
            addShiftClcle={this.addLocalShiftCycle}
            closeAddModal={this.closeAddModal}
          />
        )}
      </Modal>
    )
  }
}

export default Period
