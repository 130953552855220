import React, { useEffect, useState } from 'react'
import style from './index.module.scss'
import { useData } from './hooks';
import { Tree } from 'antd';

/** 事件模版树 */
const Com = (props) => {
    const [data, loading] = useData();

    const [selectedKeys, setSelectedKeys] = useState(null);

    const onSelect = (selectedKeys, info) => {
        setSelectedKeys(selectedKeys);
        let table_json = null;
        try {
            if (typeof info?.node?.data?.table_json === 'string') {
                table_json = JSON.parse(info?.node?.data?.table_json);
            };
        } catch (e) { };
        if (typeof props.setTableJson === 'function') {
            props.setTableJson(table_json);
        };
        const catId = info?.node?.data?.id ?? null;
        if (typeof props.setCatId === 'function') {
            props.setCatId(catId);
        };
        const catName = info?.node?.name ?? null;
        if (typeof props.setCatName === 'function') {
            props.setCatName(catName);
        };
        const code = info?.node?.data?.code ?? null;
        if (typeof props.setTplCode === 'function') {
            props.setTplCode(code);
        };
    };

    useEffect(() => {
        // 每次有新的table，更改选择模板，默认第一个存在的模板
        // 递归
        const parsed_results = data?.parsed_results ?? [];
        const findLeaf = (arr) => {
            let i;
            for (i = 0; i < arr.length; i++) {
                if (arr[i]?.isLeaf === true) {
                    return arr[i];
                } else {
                    if (!!arr[i].children) {
                        const leaf = findLeaf(arr[i].children);
                        if (!!leaf) return leaf;
                    };
                };
            };
            return null;
        };
        const first_leaf = findLeaf(parsed_results);
        const key = first_leaf?.key ?? null;
        if (typeof key === 'string') {
            setSelectedKeys([key]);
            let table_json = null;
            try {
                if (typeof first_leaf?.data?.table_json === 'string') {
                    table_json = JSON.parse(first_leaf?.data?.table_json);
                };
            } catch (e) { };
            if (typeof props.setTableJson === 'function') {
                props.setTableJson(table_json);
            };
            const catId = first_leaf?.data?.id ?? null;
            if (typeof props.setCatId === 'function') {
                props.setCatId(catId);
            };
            const catName = first_leaf?.name ?? null;
            if (typeof props.setCatName === 'function') {
                props.setCatName(catName);
            };
            const code = first_leaf?.data?.code ?? null;
            if (typeof props.setTplCode === 'function') {
                props.setTplCode(code);
            };
        };

    }, [data]);

    return <div className={style['tplTree']}>
        <div className="search-warp"></div>
        <div className="tree-wrap scroll_bar_restyle">
            {
                !!data?.results && (
                    <Tree
                        selectedKeys={selectedKeys}
                        defaultExpandAll={true}
                        onSelect={onSelect}
                        treeData={data?.results ?? []}
                    />
                )
            }
        </div>
    </div>
}

export default Com