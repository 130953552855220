import { Input, Space, TreeSelect, InputNumber } from 'antd';
import React, { useMemo } from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    const { data, orgs } = props;
    const [name, parent_value, description, sort_index] = useMemo(() => {
        if (!!data) {
            const { name, parent_id, sort_index, description } = data;
            let next_parent_value = null;
            if (typeof parent_id === 'number') {
                next_parent_value = parent_id + "";
            };
            let next_sort_index = null;
            if (typeof sort_index === 'number') {
                next_sort_index = sort_index;
            };
            return [name, next_parent_value, description, next_sort_index];
        };
        return ["", null, "", ""];
    }, [data]);

    const updateData = (addon) => {
        const { onChange } = props;
        if (typeof onChange === 'function') {
            const nextData = Object.assign({}, !!data ? data : {}, !!addon ? addon : {});
            onChange(nextData);
        };
    };

    const orgOnChange = (value, label, extra) => {
        let next_parent_id = null;
        if (typeof value === 'string') {
            const tmp_next_parent_id = parseInt(value);
            if (typeof tmp_next_parent_id === 'number') {
                next_parent_id = tmp_next_parent_id;
            };
        };
        updateData({ parent_id: next_parent_id })
    };

    const sortOnChange = (value) => {
        let next_sort_index = null;
        if (typeof value === 'number') {
            next_sort_index = value;
        };
        updateData({ sort_index: next_sort_index });
    };

    return (
        <div className={style["wrapper"]}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <div className="row">
                    <div className="title">
                        <div className="required">*</div>
                        <div className="text">部门名称:</div>
                    </div>
                    <div className="content">
                        <Input value={name} onChange={(e) => updateData({ name: e.target.value })} />
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        <div className="required">*</div>
                        <div className="text">父级部门:</div>
                    </div>
                    <div className="content">
                        <TreeSelect
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            treeData={!!orgs ? orgs : []}
                            placeholder="请选择部门"
                            treeDefaultExpandAll
                            treeNodeFilterProp="title"
                            value={parent_value}
                            onChange={orgOnChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        <div className="text">部门描述:</div>
                    </div>
                    <div className="content">
                        <Input.TextArea rows={4} value={description} onChange={(e) => updateData({ description: e.target.value })} />
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        <div className="required">*</div>
                        <div className="text">排序:</div>
                    </div>
                    <div className="content">
                        <InputNumber value={sort_index} min={1} onChange={sortOnChange} precision={0} style={{ width: "100%" }} />
                        <div className="hint_wrapper">
                            <div className="text">数字越小越靠前</div>
                        </div>
                    </div>
                </div>
            </Space>
        </div>
    );
};

export default Comp;
