import React from 'react';
import CLOSE_ICON from './res/close.svg';
import style from './BoardCharts.module.scss';
import { message } from 'antd';

const echarts = require('echarts');
const moment = require('moment');
export default class BoardCharts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rightContentWidth: 0,
        };
    }

    componentDidMount() {
        // setTimeout(this.renderCharts, 1000);
        this.renderCharts();
    }

    componentDidUpdate({
        contentItemWidth: prevContentItemWidth,
        panel_1: prevPanel_1,
        hisTag2Value: prevHisTag2Value,
        customCharts: prevCustomCharts,
    }) {
        const { contentItemWidth, panel_1, hisTag2Value, customCharts } = this.props;
        const { getHisTagListData } = this.props;
        // console.log('this.rightContentNode', prevContentItemWidth, contentItemWidth);
        if (prevContentItemWidth !== contentItemWidth) {
            //说明外部宽度可能发生了变化，此处重新计算内部宽度
            this.setContentWidth();
        };
        if ((panel_1 !== prevPanel_1) || (hisTag2Value !== prevHisTag2Value)) {
            this.renderCharts();
        };
        if (customCharts !== prevCustomCharts) {
            // console.log('customCharts changed', customCharts);
            getHisTagListData();
        };
    }

    renderCharts = () => {
        const { customCharts, panel_1, hisTag2Value } = this.props;
        const echartInstances = panel_1
            .map(({ tag_1 }, index) => ({
                nodeName: `topChartNode_${index}`,
                tag_name: tag_1,
            }))
            .concat(customCharts
                .map(({ tag_name }, index) => ({
                    nodeName: `chartNode_${index}`,
                    tag_name,
                })))
            .map(({ nodeName, tag_name }) => {
                if (this[nodeName]) {
                    // console.log(hisTag2Value[tag_name]);
                    const values = (hisTag2Value[tag_name] || [])
                        .map(([time, value]) => [time * 1000, value]);
                    let instance = echarts.getInstanceByDom(this[nodeName]);
                    if (!instance) {
                        instance = echarts.init(this[nodeName]);
                    };
                    // const xData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                    // const ySplitNum = 4;
                    // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                    //     .map(() => Math.random() * 100);
                    const data = values
                        .map(([_, value]) => value);
                    //这里可能需要检验不同charts之间values time的同步问题
                    let [dataMin, dataMax] = [Math.min(...data), Math.max(...data)];
                    if (dataMin === dataMax) {
                        dataMin--;
                        dataMax++;
                    };
                    const yMin = 2 * dataMin - dataMax;
                    const yMax = 2 * dataMax - dataMin;
                    const yMid = (dataMin + dataMax) / 2;
                    const yMidUp = yMax - (yMax - yMin) * 11 / 100;
                    const yMidDown = yMin + (yMax - yMin) * 11 / 100;
                    const gridLeft = 48;
                    instance.setOption({
                        grid: {
                            left: gridLeft,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        },
                        xAxis: {
                            type: 'time',
                            show: false,
                            boundaryGap: false,
                            // data: xData,
                        },
                        yAxis: {
                            show: false,
                            min: yMin,
                            max: yMax,
                        },
                        series: [
                            {
                                data: values,
                                type: 'line',
                                symbol: 'none',
                                smooth: true,
                                itemStyle: {
                                    color: '#40A9FF',
                                },
                                lineStyle: {
                                    width: 2,
                                },
                                markLine: {
                                    silent: true,
                                    animation: false,
                                    symbol: ['none', 'none'],
                                    data: [yMidUp, yMid, yMidDown]
                                        .map(v => ({
                                            yAxis: v,
                                            label: {
                                                show: false,
                                            },
                                            lineStyle: {
                                                color: '#E0E0E0',
                                                type: 'solid',
                                            },
                                        }))
                                        .concat(
                                            [yMidUp, yMid, yMidDown]
                                                .map(v => ({
                                                    yAxis: v,
                                                    label: {
                                                        position: 'start',
                                                        formatter: () => Math.floor(v),
                                                    },
                                                    lineStyle: {
                                                        color: '#9E9EA8',
                                                        width: 0,
                                                    },
                                                })),
                                        ),
                                },
                            },
                        ],
                        tooltip: {
                            trigger: 'axis',
                            formatter: (params) => {
                                // console.log(params);
                                return params.map(({ marker, data: [axisValue, value] }, index) => (
                                    `${index === 0 ? moment.unix(axisValue / 1000).format('YYYY-MM-DD HH:mm:ss') : ''}<br/>${marker}${Math.floor(value * 1000) / 1000}`
                                )).join('');
                            },
                            axisPointer: {
                                lineStyle: {
                                    width: 2,
                                    color: '#40A9FF',
                                },
                            },
                            position: ([posX, posY], [{ dataIndex = 0 } = {}], dom, rect, { viewSize: [viewWidth, viewHeight] }) => {
                                // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
                                const obj = {};
                                if (posY > viewHeight / 2) {
                                    obj.bottom = viewHeight - posY;
                                } else {
                                    obj.top = posY;
                                };
                                const rightWidth = (viewWidth - gridLeft) * (1 - dataIndex / (data.length - 1));
                                if (dataIndex < data.length / 2) {
                                    obj.left = viewWidth - rightWidth + 15;
                                } else {
                                    obj.right = rightWidth + 15;
                                };
                                return obj;
                            },
                        }
                    });
                    return instance;
                };
                return null;
            })
            .filter(echartInstance => !!echartInstance);
        if (echartInstances.length > 0) {
            echarts.connect(echartInstances);
        };
    }

    rightContentRef = node => {
        if (node) {
            this.rightContentNode = node;
            this.setContentWidth();
        };
    }

    setContentWidth = () => {
        if (this.rightContentNode) {
            // console.log('this.rightContentNode')
            const { rightContentWidth } = this.state;
            const newRightContentWidth = this.rightContentNode.offsetWidth - 48;
            if (newRightContentWidth !== rightContentWidth) {
                this.setState({ rightContentWidth: newRightContentWidth }, this.chartsResize);
            };
        };
    }

    chartsResize = () => {
        const { customCharts } = this.props;
        const echartInstances = ['topChartNode_0', 'topChartNode_1']
            .concat(customCharts
                .map((_, index) => `chartNode_${index}`))
            .map(nodeName => this[nodeName])
            .filter(refNode => !!refNode)
            .map(dom => echarts.getInstanceByDom(dom))
            .filter(echartInstance => !!echartInstance);
        echartInstances
            .forEach(echartInstance => echartInstance.resize());
    }

    rangeHoursOnChange = (index) => {
        const { rangeHoursOnChange, getHisTagListData } = this.props;
        rangeHoursOnChange(index);
        getHisTagListData();
    }

    onDrop = (index) => {
        const { onDropCharts: onDrop } = this.props;
        onDrop(index)
            .then(retData => {
                const { isSuccess, info } = retData;
                if (isSuccess === false) {
                    message.error(info);
                } else if (isSuccess === true) {
                    message.success(info);
                };
            });
    }

    deleteCustomChart = index => {
        const { deleteCustomChart } = this.props;
        deleteCustomChart(index)
            .then(isSuccess => {
                if (isSuccess) {
                    message.success('删除成功！');
                } else {
                    message.error('删除失败！');
                };
            });
    }

    render() {
        const { rightContentWidth } = this.state;
        const { onDragOverIndex_charts: onDragOverIndex, customCharts, selectedRangeHourIndex, RANGE_HOURS, panel_1 } = this.props;
        const { onDragOverCharts: onDragOver } = this.props;
        // console.log(customCharts);
        return (
            <div
                className={style["board_charts_wrapper"]}
                ref={this.rightContentRef}
            >
                <div className="right_header">
                    <div className="title">数据趋势</div>
                    <div className="info_bar">
                        {
                            RANGE_HOURS
                                .map((hour, index, arr) => (
                                    <div
                                        className={"hour_item" + (selectedRangeHourIndex === index ? ' selected_hour_item' : '')}
                                        key={index}
                                        style={
                                            index === 0 ?
                                                {
                                                    borderRadius: '12px 0 0 12px',
                                                    marginLeft: 'auto',
                                                } : index === arr.length - 1 ?
                                                    {
                                                        borderRadius: '0 12px 12px 0',
                                                        borderRightWidth: 1,
                                                    } : {}
                                        }
                                        onClick={() => this.rangeHoursOnChange(index)}
                                    >{hour}h</div>
                                ))
                        }
                    </div>
                </div>
                <div className="right_content scroll_bar_restyle">
                    <div style={{ width: rightContentWidth }}>
                        {
                            panel_1
                                .map(({ name_1 }, index) => (
                                    <div className="chart_wrapper" key={index}>
                                        <div className="top_elements_wrapper">
                                            <div className="text_wrapper">
                                                <div className="title">{name_1}</div>
                                            </div>
                                        </div>
                                        <div className="chart" ref={node => { if (node) this[`topChartNode_${index}`] = node }} />
                                    </div>
                                ))
                        }
                        {
                            customCharts
                                .map(({ name }, index) => (
                                    <div
                                        key={index}
                                        className={"chart_wrapper" + (onDragOverIndex === index ? ' chart_wrapper_dragover' : '')}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            // console.log("onDragOver", index);
                                            onDragOver(index);
                                        }}
                                        onDrop={() => this.onDrop(index)}
                                    >
                                        <div className="top_elements_wrapper">
                                            <div className="text_wrapper">
                                                <div className="title">{name}</div>
                                                <div
                                                    className="close_icon_wrapper"
                                                    onClick={() => this.deleteCustomChart(index)}
                                                >
                                                    <img alt="" src={CLOSE_ICON} className="close_icon" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chart" ref={node => { if (node) this[`chartNode_${index}`] = node }} />
                                    </div>
                                ))
                        }
                        <div
                            className={"chart_wrapper chart_wrapper_bottom_block" + (onDragOverIndex === customCharts.length ? ' chart_wrapper_dragover' : '')}
                            onDragOver={(e) => {
                                e.preventDefault();
                                // console.log("onDragOver", index);
                                onDragOver(customCharts.length);
                            }}
                            onDrop={() => this.onDrop(customCharts.length)}
                        >
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
