import { useEffect, useRef, useState } from 'react'
import { get } from 'common/utils'
import moment from 'moment'

const Hook = pageSize => {
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [refreshFlag, setRefreshFlag] = useState(Symbol())
  /** 刷新自动生成事件列表 */
  const refreshAutoEventsList = () => setRefreshFlag(Symbol())

  const initEvens = () => {
    setEvents([])
    setPageNo(1)
  }

  const queryLock = useRef(null)
  useEffect(() => {
    if (typeof refreshFlag !== 'symbol') return
    const fetchData = () => {
      setLoading(true)
      const currLock = (queryLock.current = Symbol())
      get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/capsuleByUserIdSearch', {
        useId: 0,
        pageNo: pageNo,
        rowNo: pageSize
      }).then(retData => {
        if (currLock !== queryLock.current) return
        if (!!retData.data && retData.data.error === 0) {
          if (!!retData.data.results) {
            const { total, list } = retData.data.results
            setTotal(total)
            const nextEvents = list.map(item => {
              const { create_time, json_content } = item
              let titleText = ''
              try {
                const jsonText = JSON.parse(json_content)
                if (!!jsonText) {
                  if (typeof jsonText.title === 'string') {
                    titleText = jsonText.title
                  }
                }
              } catch (error) {}
              let createTimeText = ''
              try {
                const tmp_time = moment(create_time)
                if (tmp_time.isValid()) {
                  createTimeText = tmp_time.format('YYYY/MM/DD HH:mm:ss')
                }
              } catch (err) {}
              return Object.assign({}, item, { titleText, createTimeText })
            })
            setEvents(nextEvents)
          }
        }
        setLoading(false)
      })
    }
    fetchData()
  }, [refreshFlag, pageNo])

  return [events, pageNo, total, setPageNo, refreshAutoEventsList, loading, initEvens]
}

export default Hook
