import React from 'react';
import { Table } from 'antd';
import { get } from 'common/utils';

// const display_uuids = ["Kw_xRR7ISd-lXd-bzgnvTw"];
const display_uuids = ["cifUGS-IR0OpJvvuzBXdfw", "6Qosov9LQZGUsNCpK-w7cw", "J_eb3mt8T6qJyFfq-Djaaw"];

export default class DiagramList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            page_size: 10,
            dataSource: [],
        };
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        const { page, page_size } = this.state;
        get(process.env.REACT_APP_SERVER_PATH + 'api/diagrams', {
            page,
            page_size,
        }).then(retData => {
            console.log(retData);
            if (!!retData.data && !!retData.data.results) {
                this.setState({
                    dataSource: retData.data.results
                        .filter(({ uuid }) => display_uuids.includes(uuid))
                        .map((item, key) => Object.assign({}, item, { key }))
                });
            }
        })
    }

    render() {
        const { dataSource } = this.state;

        return (
            <div className="diagram_list_wrapper" style={{ padding: 12 }}>
                <Table
                    dataSource={dataSource}
                    // pagination={{
                    //     defaultCurrent: 1,
                    //     pageSize: 10,
                    //     total: this.state.total,
                    //     onChange: (page, pageSize) => self.getAlarmList(page, pageSize)
                    // }}
                    columns={[
                        {
                            title: '编号',
                            dataIndex: 'id',
                            width: 60,
                        },
                        {
                            title: '名称',
                            dataIndex: 'name',
                        },
                    ]}
                    onRow={(record) => {
                        const { uuid } = record;
                        return {
                            onClick: () => this.props.history.push(`/DiagramDisplayMobile/${uuid}`),
                        };
                    }}
                    pagination={false}
                />
            </div>
        );
    }
}
