import {
    setValues,
} from 'common/redux/MainPageV2/actions.js';
import { post, get, getCachedUserInfo } from 'common/utils';
const moment = require('moment');

const mergeDataSource = (originDataSource, lockedItems) => {
    //前提：应该保证originDataSource或lockedItems中没有重复的cur_value_tag
    //可能的问题是存在lockedItems中有而originDataSource没有的情况
    lockedItems = lockedItems
        .map(({ tag_name, index }) => ({
            cur_value_tag: tag_name,
            index,
        }));
    const tag2Items = {};
    originDataSource
        .forEach(item => {
            tag2Items[item.cur_value_tag] = item;
        });
    const sortedLockedItems = [...lockedItems]
        .filter(({ cur_value_tag }) => tag2Items[cur_value_tag] !== undefined)
        .sort(({ index: indexA }, { index: indexB }) => indexA - indexB);
    let mergedDataSource = [];
    sortedLockedItems
        .forEach(({ cur_value_tag, index }) => {
            while (index < 0 || mergedDataSource[index] !== undefined) index++;//防止有相同index或<0出现
            mergedDataSource[index] = {
                ...tag2Items[cur_value_tag],
                locked: true,
            };
        });
    const lockedTags = sortedLockedItems
        .map(({ cur_value_tag }) => cur_value_tag);
    const unlockedItems_origin = originDataSource
        .filter(({ cur_value_tag }) => !lockedTags.includes(cur_value_tag));
    let unlockedIndex = 0;
    unlockedItems_origin
        .forEach(item => {
            while (mergedDataSource[unlockedIndex] !== undefined) unlockedIndex++;
            mergedDataSource[unlockedIndex] = {
                ...item,
                locked: false,
            };
            unlockedIndex++;
        });
    return mergedDataSource
        .filter(item => item !== undefined);
};

const onDragOver = (onDragOverKey, index) => {
    return (dispatch, getState) => {
        const { mainPageV2 } = getState();
        if (mainPageV2[onDragOverKey] !== index) {
            dispatch(setValues({
                ...(
                    onDragOverKey === 'onDragOverIndex_charts' ?
                        {
                            onDragOverIndex_charts: index,
                            onDragOverIndex_table: null,
                        } : (
                            onDragOverKey === 'onDragOverIndex_table' ?
                                {
                                    onDragOverIndex_charts: null,
                                    onDragOverIndex_table: index,
                                } : {}
                        )
                )
            }));
        };
    };
};

const onDrop = (onDropKey, index) => {
    return (dispatch, getState) => {
        if (onDropKey === 'table') {
            const { mainPageV2: { draggingIndex, dataSource } } = getState();
            let newDataSource = null;
            if (draggingIndex !== index && draggingIndex !== index - 1) {
                newDataSource = [].concat(dataSource);
                const unlockedItemIndexs = dataSource
                    .map(({ locked }, index) => ({
                        locked,
                        index,
                    }))
                    .filter(({ locked }) => locked !== true)
                    .map(({ index }) => index);
                const draggingItem = newDataSource[draggingIndex];
                if (draggingIndex > index) {
                    const movingIndexs = unlockedItemIndexs
                        .filter((unlockedIndex) => unlockedIndex <= draggingIndex && unlockedIndex >= index);
                    for (let i = movingIndexs.length - 1; i > 0; i--) {
                        newDataSource[movingIndexs[i]] = newDataSource[movingIndexs[i - 1]];
                    };
                    newDataSource[index] = draggingItem;
                } else {
                    const movingIndexs = unlockedItemIndexs
                        .filter((unlockedIndex) => unlockedIndex >= draggingIndex && unlockedIndex <= index);
                    for (let i = 0; i < movingIndexs.length - 1; i++) {
                        newDataSource[movingIndexs[i]] = newDataSource[movingIndexs[i + 1]];
                    };
                    newDataSource[index] = draggingItem;
                };
            };
            dispatch(setValues({
                ...(newDataSource === null ?
                    {} : {
                        dataSource: newDataSource,
                    }),
                draggingIndex: null,
                onDragOverIndex_table: null,
                onDragOverIndex_charts: null,
            }));
            return {
                isSuccess: true,
                info: '移动成功',
            };
        } else if (onDropKey === 'charts') {
            const { mainPageV2: { draggingIndex, dataSource, customCharts, layout, sys_id } } = getState();
            // console.log(dataSource[draggingIndex]);
            if (
                customCharts
                    .map(({ tag_name }) => tag_name)
                    .indexOf(dataSource[draggingIndex].cur_value_tag)
                !== -1
            ) {
                // 不能重复加
                return Promise.resolve({
                    isSuccess: false,
                    info: '已存在该参数，请勿重复添加',
                });
            };
            const newCustomCharts = []
                .concat(customCharts);
            newCustomCharts.splice(index, 0, {
                tag_name: dataSource[draggingIndex].cur_value_tag,
                name: dataSource[draggingIndex].name,
            });
            const layout_id = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0]._id;
            return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/layout_conf/custom/save', {
                layout_id,
                cus_history_list: newCustomCharts,
            }).then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    const newLayout = layout;// 等待更好的做法
                    newLayout.cus_config.filter(({ layout_id: layoutId }) => layoutId === layout_id)[0].cus_history_list = newCustomCharts;
                    dispatch(setValues({
                        layout: newLayout,
                        customCharts: newCustomCharts,
                        draggingIndex: null,
                        onDragOverIndex_table: null,
                        onDragOverIndex_charts: null,
                    }));
                    return {
                        isSuccess: true,
                        info: '添加成功！',
                    };
                } else {
                    return {
                        isSuccess: false,
                        info: '添加失败！',
                    };
                };
            });
        };
    };
};

const valueFormat = (value, decimal = 2) => {
    const newValue = parseFloat(value);
    if (isNaN(newValue)) return '-';
    return parseFloat(newValue.toFixed(decimal));
};

const getHisTagListData = ownProps => {
    let time_end = null;
    // let time_end = new URLSearchParams(ownProps.location.search).get('time');//?time=2020-03-28 12:12:12
    if (!time_end) {
        time_end = moment();//2020-02-21 00:00:00
        // time_end = moment('2020-02-21 00:00:00');//2020-02-21 00:00:00
    } else {
        time_end = moment(time_end, "YYYY-MM-DD HH:mm:ss");
    };
    return (dispatch, getState) => {
        const { mainPageV2: { sys_id, customCharts, layout, RANGE_HOURS, selectedRangeHourIndex, chartsModal } } = getState();
        if (!layout) return Promise.resolve(false);
        const deltaHours = RANGE_HOURS[selectedRangeHourIndex];
        const tag_list = [
            ...new Set(//去重
                layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0].panel_1
                    .map(({ tag_1 }) => tag_1)
                    .concat(
                        customCharts
                            .map(({ tag_name }) => tag_name),
                    )
                    .concat(
                        chartsModal
                            .map(({ record: { cur_value_tag } }) => cur_value_tag)
                    )
                    .filter(tag_name => !!tag_name)//过滤
            ),
        ]
            .map(tag_name => ({
                tag_name,
                time_begin: moment(time_end).subtract(deltaHours, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                time_end: time_end.format('YYYY-MM-DD HH:mm:ss'),
            }));
        // console.log(tag_list);
        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/get_history_data_sep_time', {
            time_type: 'seconds',
            tag_list,
        })
            .then(retData => {
                // console.log('getHisTagListData', retData);
                if (retData.data && retData.data.errcode === 0) {
                    const hisTag2Value = {};
                    retData.data.results.forEach(({ tag_name, data_list }) => hisTag2Value[tag_name] = data_list);
                    // console.log(hisTag2Value);
                    dispatch(setValues({ hisTag2Value }))
                    return true;
                } else {
                    return false;
                };
            });
    };
};

export {
    mergeDataSource,
    onDragOver,
    onDrop,
    valueFormat,
    getHisTagListData,
};