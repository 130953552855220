import React from 'react';
import moment from 'moment';
import style from './index.module.scss';

export default class CombinedLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
    }

    componentDidMount() {
        this.updateValue(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateValue(nextProps);
    }

    updateValue = (props) => {
        const combine_elements = props?.combine_elements ?? [];
        const value = combine_elements
            .reduce((pV, cV) => {
                const { type, value } = cV;
                if (type === "static") return pV.concat(value);
                if (type === "key") {
                    let concatValue = props.addons.getValue(value) || '';
                    if (value === "day") {
                        concatValue = concatValue.replaceAll('-', '');
                    };
                    return pV.concat(concatValue);
                };
            }, [])
            .join("");
        this.setState({ value: value })
        props.onChange(value);
    }

    render() {
        const { value } = this.state;
        return (
            <div className={style['wrapper']}>
                {value}
            </div>
        )
    }
}
