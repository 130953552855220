import { Table } from 'antd'
import { useEffect } from 'react'
import styles from './index.module.scss'
import { renderMonthlySummaryCell, renderDate, renderMonthlySummaryCellPercent } from '../utils'
import { useState } from 'react'
import * as echarts from 'echarts~5';
import moment from 'moment'

const useResizeEchart = dom => {
  useEffect(() => {
    if (dom) {
      const resizeObserver = new ResizeObserver(() => {
        let instance = echarts.getInstanceByDom(dom)
        if (instance) {
          instance.resize()
        }
      })
      resizeObserver.observe(dom)
      return () => {
        resizeObserver.unobserve(dom)
      }
    }
  }, [dom])
  return []
}

const useChart = (monthDataSource, dayDataSource) => {
  const [chartDom, setChartDom] = useState(null)
  useResizeEchart(chartDom)
  const refFunc = d => {
    setChartDom(d)
  }
  useEffect(() => {
    // console.log(monthDataSource, dayDataSource)
    if (chartDom) {
      let instance = echarts.getInstanceByDom(chartDom)
      if (!instance) {
        instance = echarts.init(chartDom, null, {
          locale: 'ZH'
        })
      } else {
        instance.clear()
      }
      const lastDatetime = monthDataSource?.[0]?.datetime ?? null
      if (typeof lastDatetime === 'string' && lastDatetime !== '' && dayDataSource) {
        // console.log(lastDatetime)
        instance.setOption({
          legend: {
            show: true,
            top: 24,
            textStyle: {
              fontSize: 18
            }
          },
          tooltip: {
            show: true,
            trigger: 'axis',
            formatter: params => {
              // console.log(params)
              const axisValueLabel = params?.[0]?.axisValueLabel ?? '--'
              return `${axisValueLabel}<br/>${(params ?? []).map(({ marker, seriesName, data: { actualVal } }) => `${marker} ${seriesName} ${actualVal ?? '--'}`).join('<br/>')}`
            },
            textStyle: {
              fontSize: 18
            }
          },
          grid: {
            containLabel: true,
            top: 76,
            right: 24,
            bottom: 24,
            left: 24
          },
          xAxis: [{
            type: 'category',
            axisLabel: {
              fontSize: 18
            },
            data: (dayDataSource ?? []).map(({ datetime }) => moment(datetime).format("D日")),
            axisTick: {
              show: false
            }
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              fontSize: 18
            },
            interval: 50,
            max: ({ max }) => {
              if (max >= 500) {
                return 500
              } else {
                if (max > 0) {
                  const m = Math.ceil(max / 50) * 50
                  return m
                }
                return undefined
              }
            }
          }],
          series: [{
            name: '月累计电价',
            type: 'bar',
            barWidth: '50%',
            data: (dayDataSource ?? []).map(item => {
              const { datetime, daily_cum_actual_price } = item
              let value = null
              if (moment(datetime).isSameOrBefore(moment(lastDatetime))) {
                value = renderMonthlySummaryCell(daily_cum_actual_price)
              } else {
              }
              let pVal = value
              if (typeof pVal === 'number' && !isNaN(pVal)) {
                if (pVal < 0) {
                  pVal = 0
                } else if (pVal > 500) {
                  pVal = 500
                }
              }
              return {
                value: pVal,
                actualVal: value
              }
            }),
            itemStyle: {
              color: '#6887B9'
            }
          }, {
            name: '每日实际电价',
            type: 'line',
            data: (dayDataSource ?? []).map(item => {
              const { datetime, daily_mean_actual_price } = item
              let value = null
              if (moment(datetime).isSameOrBefore(moment(lastDatetime))) {
                value = renderMonthlySummaryCell(daily_mean_actual_price)
              } else {
              }
              let pVal = value
              if (typeof pVal === 'number' && !isNaN(pVal)) {
                if (pVal < 0) {
                  pVal = 0
                } else if (pVal > 500) {
                  pVal = 500
                }
              }
              return {
                value: pVal,
                actualVal: value,
                label: {
                  show: true,
                  fontSize: 18,
                  formatter: () => value
                }
              }
            }),
            itemStyle: {
              color: '#DF7F7F'
            },
            lineStyle: {
              width: 4
            },
            symbolSize: 8
          }, {
            name: '合约电价',
            type: 'line',
            data: (dayDataSource ?? []).map(item => {
              const { daily_mean_contract_price } = item
              const value = renderMonthlySummaryCell(daily_mean_contract_price)
              let pVal = value
              if (typeof pVal === 'number' && !isNaN(pVal)) {
                if (pVal < 0) {
                  pVal = 0
                } else if (pVal > 500) {
                  pVal = 500
                }
              }
              return {
                value: pVal,
                actualVal: value
              }
            }),
            itemStyle: {
              color: '#C0B2D0'
            },
            lineStyle: {
              type: 'dashed',
              width: 4
            },
            showSymbol: false
          }]
        })
      }
    }
  }, [monthDataSource, dayDataSource, chartDom])
  return [refFunc]
}

const Comp = props => {
  const { monthDataSource, dayDataSource } = props
  const [refFunc] = useChart(monthDataSource, dayDataSource)
  return (
    <div className={styles.wrapper}>
      <div className='top' ref={refFunc}></div>
      <div className='bottom'>
        <Table
          size='small'
          pagination={false}
          bordered
          dataSource={monthDataSource ?? []}
          columns={[{
            title: '月合约电量',
            dataIndex: 'em_total_contract_mwh',
            render: renderMonthlySummaryCell
          }, {
            title: '统计截止日',
            dataIndex: 'datetime',
            render: renderDate
          }, {
            title: '累计使用电量',
            dataIndex: 'daily_cum_actual_mwh',
            render: renderMonthlySummaryCell
          }, {
            title: '超额/缺额比例',
            dataIndex: 'daily_cum_mwh_bias_perc',
            render: renderMonthlySummaryCellPercent
          }, {
            title: '合约均价',
            dataIndex: 'em_mean_contract_price',
            render: renderMonthlySummaryCell
          }, {
            title: '月现货均价',
            dataIndex: 'daily_cum_spot_price',
            render: renderMonthlySummaryCell
          }, {
            title: '截止日电价',
            dataIndex: 'datetime',
            render: t => {
              return renderMonthlySummaryCell((dayDataSource ?? []).find(({ datetime }) => datetime === t)?.daily_mean_actual_price ?? null)
            }
          }, {
            title: '月累计电价',
            dataIndex: 'daily_cum_actual_price',
            render: renderMonthlySummaryCell
          }].map(item => ({ ...item, align: 'center' }))}
          onHeaderRow={() => ({
            className: styles.headerRow
          })}
        />
      </div>
    </div>
  )
}

export default Comp