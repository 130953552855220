import React from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';

const CardLineChart = (props) => {
    const { lineChart, timeDate } = props
    useEffect(() => {
        renderChart();
    }, [lineChart, timeDate]);
    const chartNode = useRef(null);
    const renderChart = () => {
        if(!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption({
                animation: true,
                tooltip: {
                    trigger: 'axis',
                    symbol: 'none',
                },
                grid: {
                    left: 0,
                    top: 5,
                    right: 5,
                    bottom: 5,
                },
                xAxis: {
                    show: false,
                    splitLine:{
                        show: false
                    },
                    type: 'category',
                    data: timeDate
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisTick:{ //y轴刻度线
                        show:false
                    },
                    axisLine:{ //y轴
                        show:false
                    },
                    max: Math.max.apply(null, lineChart),
                    min: Math.min.apply(null, lineChart)
                },
                series: [{
                    data: lineChart,
                    type: 'line',
                    lineStyle:{
                        color:'#00676B',
                        width: 1,
                    },
                    symbol: 'none',
                }]
            })
        }
    };
    return (
        <div>
            <div ref={chartNode} style={{ width: 294, height: 42 }} />
        </div>
    );
}
export default CardLineChart
