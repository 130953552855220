import React from 'react';

import ProduceItem from './ProduceItem.js';

import ICON_1 from './res/icon_1.png';
import ICON_2 from './res/icon_2.png';

import './ProduceTrend.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            datas: [
                {
                    icon: ICON_1,
                    name: '熟料烧成系统',
                    options: [
                        {
                            name: '普通熟料',
                        },
                        {
                            name: '中热熟料',
                        },
                        {
                            name: '特殊熟料',
                        },
                    ],
                    // selected: [],
                },
                {
                    icon: ICON_2,
                    name: '水泥产量',
                    options: [
                        {
                            name: 'M325水泥',
                        },
                        {
                            name: 'P425水泥',
                        },
                        {
                            name: '中热水泥',
                        },
                    ],
                },
                {
                    icon: ICON_2,
                    name: '生料产量',
                    options: [
                        {
                            name: '普通生料',
                        },
                        {
                            name: '中热生料',
                        },
                    ],
                },
                {
                    icon: ICON_1,
                    name: '熟料产量',
                    options: [
                        {
                            name: '普通生料',
                        },
                        {
                            name: '中热生料',
                        },
                    ],
                },
                {
                    icon: ICON_2,
                    name: '水泥磨系统',
                    options: [
                        {
                            name: '普通生料',
                        },
                        {
                            name: '中热生料',
                        },
                    ],
                },
            ],
        };
    }

    componentDidMount() {
    }

    render() {
        const { datas } = this.state;

        return (
            <div className="produce_trend_wrapper">
                <div className="content_wrapper">
                    <div className="content_root_title">生产趋势</div>
                    {
                        datas
                            .map((item, index) => {
                                return (
                                    <div className="item_position_wrapper" key={index}>
                                        <ProduceItem item={item} />
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
