import LeftViewer from './LeftViewer.js';
import { connect } from 'react-redux';
import {
    setValues,
} from '../../../common/redux/ExamAnalysis/actions.js';
import { post, get, getCachedUserInfo } from '../../../common/utils';
const moment = require('moment');

const selectUserValue = (resultsIndex, userValuesIndex) => {
    return (dispatch, getState) => {
        dispatch(setValues({
            selectedUserValue: [resultsIndex, userValuesIndex],
            showFactorDetail: [],
        }));
    };
}

const mapStateToProps = state => {
    const { examAnalysis } = state;
    const { examDetail, selectedUserValue, examDetailUniqueId, attendUsers, systems } = examAnalysis;
    return {
        examDetail,
        selectedUserValue,
        examDetailUniqueId,
        attendUsers,
        systems,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectUserValue: (resultsIndex, userValuesIndex) => dispatch(selectUserValue(resultsIndex, userValuesIndex)),
    };
}

const WrapperedLeftViewer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LeftViewer);

export default WrapperedLeftViewer;