import React from 'react'
import style from './index.module.scss'
import IndexBar from './BarChart'
const YieldTarget = props => {
  const {
    ethaneEfficiency,
    propaneEfficiency,
    butaneEfficiency,
    ethaneEfficiencyChart,
    propaneEfficiencyChart,
    butaneEfficiencyChart
  } = props
  return (
    <div className={style['bigYieldTarget']}>
      <div className="bigYieldTarget-header">
        <div className="bigYieldTarget-header-left">收率指标</div>
        <div className="bigYieldTarget-header-right">近10日日平均</div>
      </div>
      <div className="bigYieldTarget-item">
        <div className="bigYieldTarget-item-left">
          <div className="item-name">乙烷收率</div>
          <div className="item-progress">
            <div className="item-progress-proNum" style={{ width: `${ethaneEfficiency}%` }}></div>
            <div className="item-progress-percent">{ethaneEfficiency}%</div>
          </div>
        </div>
        <IndexBar barChart={ethaneEfficiencyChart} />
      </div>
      <div className="bigYieldTarget-item">
        <div className="bigYieldTarget-item-left">
          <div className="item-name">丙烷收率</div>
          <div className="item-progress">
            <div className="item-progress-proNum" style={{ width: `${propaneEfficiency}%` }}></div>
            <div className="item-progress-percent">{propaneEfficiency}%</div>
          </div>
        </div>
        <IndexBar barChart={propaneEfficiencyChart} />
      </div>
      <div className="bigYieldTarget-item">
        <div className="bigYieldTarget-item-left">
          <div className="item-name">丁烷收率</div>
          <div className="item-progress">
            <div className="item-progress-proNum" style={{ width: `${butaneEfficiency}%` }}></div>
            <div className="item-progress-percent">{butaneEfficiency}%</div>
          </div>
        </div>
        <IndexBar barChart={butaneEfficiencyChart} />
      </div>
    </div>
  )
}
export default YieldTarget
