import React from 'react';

import ICON_ALL from './res/icon_all.png';
import ICON_SLSC from './res/icon_SLSC.png';
import ICON_MFZB from './res/icon_MFZB.png';
import ICON_SNFM from './res/icon_SNFM.png';
import ICON_YLFM from './res/icon_YLFM.png';

import './MainElectricalParams.scss';

const sys2Color = {
    all: 'rgba(68, 243, 218, 1)',
    SLSC: 'rgba(89, 94, 168, 1)',
    MFZB: 'rgba(177, 93, 101, 1)',
    SNFM: 'rgba(74, 155, 135, 1)',
    YLFM: 'rgba(83, 146, 188, 1)',
};

const sys2MaskColor = {
    all: 'transparent',
    SLSC: 'linear-gradient(90deg, #595EA8 0%, rgba(89, 94, 168, 0) 100%)',
    MFZB: 'linear-gradient(90deg, #B15D65 0%, rgba(255, 174, 182, 0) 100%)',
    SNFM: 'linear-gradient(90deg, #4A9B87 0%, rgba(74, 155, 135, 0) 100%)',
    YLFM: 'linear-gradient(90deg, #5392BC 0%, rgba(83, 146, 188, 0) 100%)',
};

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [
                {
                    tag: 'all',
                    name: '全场用电参数',
                    icon: ICON_ALL,
                    items: [
                        {
                            value: 230.33,
                            unit: 'kw',
                            name: '最大有功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVAR',
                            name: '最大无功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVA',
                            name: '最大视在功率',
                        },
                        {
                            value: 88.5,
                            unit: '%',
                            name: '最大负载率',
                        },
                        {
                            value: 0.9165,
                            unit: '',
                            name: '功率因数',
                        },
                    ].map((item, index) => {
                        return {
                            ...item,
                            value: index < 3 ? Math.floor(item.value * 9 * 100) / 100 : item.value,
                        };
                    }),
                },
                {
                    tag: 'SLSC',
                    name: '熟料烧成参数',
                    icon: ICON_SLSC,
                    items: [
                        {
                            value: 230.33,
                            unit: 'kw',
                            name: '最大有功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVAR',
                            name: '最大无功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVA',
                            name: '最大视在功率',
                        },
                        {
                            value: 87.3,
                            unit: '%',
                            name: '最大负载率',
                        },
                        {
                            value: 0.8612,
                            unit: '',
                            name: '功率因数',
                        },
                    ].map((item, index) => {
                        return {
                            ...item,
                            value: index < 3 ? Math.floor(item.value * 2 * 100) / 100 : item.value,
                        };
                    }),
                },
                {
                    tag: 'MFZB',
                    name: '粉煤制备系统',
                    icon: ICON_MFZB,
                    items: [
                        {
                            value: 230.33,
                            unit: 'kw',
                            name: '最大有功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVAR',
                            name: '最大无功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVA',
                            name: '最大视在功率',
                        },
                        {
                            value: 88.5,
                            unit: '%',
                            name: '最大负载率',
                        },
                        {
                            value: 0.9052,
                            unit: '',
                            name: '功率因数',
                        },
                    ].map((item, index) => {
                        return {
                            ...item,
                            value: index < 3 ? Math.floor(item.value * 1.8 * 100) / 100 : item.value,
                        };
                    }),
                },
                {
                    tag: 'SNFM',
                    name: '水泥粉磨系统',
                    icon: ICON_SNFM,
                    items: [
                        {
                            value: 230.33,
                            unit: 'kw',
                            name: '最大有功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVAR',
                            name: '最大无功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVA',
                            name: '最大视在功率',
                        },
                        {
                            value: 83.5,
                            unit: '%',
                            name: '最大负载率',
                        },
                        {
                            value: 0.8533,
                            unit: '',
                            name: '功率因数',
                        },
                    ].map((item, index) => {
                        return {
                            ...item,
                            value: index < 3 ? Math.floor(item.value * 1.5 * 100) / 100 : item.value,
                        };
                    }),
                },
                {
                    tag: 'YLFM',
                    name: '原料粉磨系统',
                    icon: ICON_YLFM,
                    items: [
                        {
                            value: 230.33,
                            unit: 'kw',
                            name: '最大有功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVAR',
                            name: '最大无功功率',
                        },
                        {
                            value: 22344,
                            unit: 'KVA',
                            name: '最大视在功率',
                        },
                        {
                            value: 88.5,
                            unit: '%',
                            name: '最大负载率',
                        },
                        {
                            value: 0.9223,
                            unit: '',
                            name: '功率因数',
                        },
                    ],
                },
            ],
        };
    }

    componentDidMount() {
    }

    render() {
        const { dataSource } = this.state;

        return (
            <div className="main_electrical_params_wrapper scroll_bar_restyle">
                <div className="content_wrapper">
                    <div className="content_root_title">主要用电参数</div>
                    {
                        dataSource
                            .map((item, index) => {
                                const { icon, name, tag, items } = item;
                                const color = sys2Color[tag];
                                const maskColor = sys2MaskColor[tag];
                                return (
                                    <div key={index} style={{ marginTop: 16 }}>
                                        <div className="item_wrapper">
                                            <div className="item_header_wrapper">
                                                <img alt="" src={icon} className="item_header_icon" />
                                                <div className="title">{name}</div>
                                            </div>
                                            <div className="item_content_wrapper">
                                                {
                                                    items
                                                        .map((childItem, childIndex) => {
                                                            const { value, unit, name: childName } = childItem;
                                                            return (
                                                                <div key={childIndex} className="child_item_wrapper">
                                                                    <div className="mask" style={{ background: maskColor }} />
                                                                    <div className="head_color_bar" style={{ backgroundColor: color }} />
                                                                    <div className="inner_content_wrapper">
                                                                        <div className="value_unit_wrapper">
                                                                            <div className="value">{value}</div>
                                                                            <div className="unit">{unit}</div>
                                                                        </div>
                                                                        <div className="name">{childName}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
