import Block1 from './Block1';
import BlockAlarm from './BlockAlarm';
import BlockQlty from './BlockQlty';
import BlockElec from './BlockElec';
import ICON_PROD from './res/prod.svg';
import ICON_COST from './res/cost.svg';
import ICON_ELEC from './res/elec.svg';
import ICON_QLTY from './res/qlty.svg';
import ICON_ALARM from './res/alarm.svg';
import ContentLayout from './ContentLayout';

const getBlocks = () => {
    return [
        {
            name: "prod",
            info: {
                icon: ICON_PROD,
                title: "生产概况",
                Comp: Block1,
            },
        },
        {
            name: "cost",
            info: {
                icon: ICON_COST,
                title: "能耗概况",
                Comp: Block1,
            },
        },
        {
            name: "elec",
            info: {
                icon: ICON_ELEC,
                title: "用电统计",
                Comp: BlockElec,
            },
        },
        {
            name: "qlty",
            info: {
                icon: ICON_QLTY,
                title: "质量概况",
                Comp: BlockQlty,
            },
        },
        {
            name: "alarm",
            info: {
                icon: ICON_ALARM,
                title: "报警统计",
                Comp: BlockAlarm,
            },
        },
    ]
        .map(item => {
            const { info: { icon, title, Comp } } = item;
            return Object.assign({}, item, {
                Comp: (
                    <ContentLayout icon={icon} title={title}>
                        {
                            !!Comp && (
                                <Comp />
                            )
                        }
                    </ContentLayout>
                ),
            });
        })
        .reduce((pV, cV) => {
            return Object.assign(pV, {
                [cV.name]: cV.Comp,
            });
        }, {});
};

export {
    getBlocks,
};
