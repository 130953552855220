import Page from './SecondBlock.js';
import { connect } from 'react-redux';
import { post } from '../../../../common/utils';
import { queryTimeRangeFormat } from '../utils.js';

const getHistoryDetails = (kpi_factor_id) => {
    return (dispatch, getState) => {
        const { factorSummaryV2 } = getState();
        const { timeRange } = factorSummaryV2;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        return post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_history_data_with_details', {
            time_begin,
            time_end,
            kpi_factor_id,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                // dispatch(setValues({ kpiList: retData.data.results }));
                return {
                    isSuccess: true,
                    result: retData.data,
                };
            };
            return {
                isSuccess: false,
            };
        });
    };
};

const mapStateToProps = state => {
    const { factorSummaryV2 } = state;
    const { timeRange } = factorSummaryV2;
    return {
        timeRange,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getHistoryDetails: (kpi_factor_id) => dispatch(getHistoryDetails(kpi_factor_id)),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;