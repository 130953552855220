import React from 'react';
import { ZONE_COLOR } from '../constants.js';
import style from './index.module.scss';

export default class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    render() {
        const { value, zone_cut_point, zone_color } = this.props;
        // if (value === undefined || isNaN(value)) {
        //     return <div className="new_main_page_range_block_wrapper" />
        // };
        const [v0, v1, v2, v3] = zone_cut_point;
        const [c0, c1, c2] = zone_color;

        const left = 42, mid = 65, right = 42;
        const allWidth = left + mid + right;
        // const minValue = rangeLeft - (left / mid * (rangeRight - rangeLeft));
        // const maxValue = rangeRight + (right / mid * (rangeRight - rangeLeft));
        // const allValue = maxValue - minValue;
        let signLeft = -1;
        if (value !== undefined && !isNaN(value)) {
            if (value < v0) {
                signLeft = 0;
            } else if (value <= v1) {
                signLeft = (value - v0) / (v1 - v0) * left / allWidth * 100;
            } else if (value <= v2) {
                signLeft = (value - v1) / (v2 - v1) * mid / allWidth * 100 + (left / allWidth * 100);
            } else if (value <= v3) {
                signLeft = (value - v2) / (v3 - v2) * right / allWidth * 100 + ((left + mid) / allWidth * 100);
            } else {
                signLeft = 100;
            };
        };
        return (
            <div className={style["new_main_page_range_block_wrapper"]}>
                <div className="left" style={{ backgroundColor: ZONE_COLOR[c0] || 'transparent' }}></div>
                <div className="mid" style={{ backgroundColor: ZONE_COLOR[c1] || 'transparent' }}></div>
                <div className="right" style={{ backgroundColor: ZONE_COLOR[c2] || 'transparent' }}></div>
                {
                    (signLeft !== -1) && [
                        (
                            <div key="top" className="sign_block top_sign" style={{ left: signLeft + '%' }}></div>
                        ), (
                            <div key="bottom" className="sign_block bottom_sign" style={{ left: signLeft + '%' }}></div>
                        )
                    ]
                }
            </div>
        );
    }
}
