import React from 'react';
import { Table, Input, Select, Button, Modal, message, Upload, Divider, Spin} from 'antd';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Editor from "react-simple-code-editor";
//高亮代码
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-python";
import "prismjs/themes/prism.css";

import { get, post, patch } from '../../common/utils.js';
import ExcelEditor from '../../components/ExcelEditor/ExcelEditor.js';

import './ExcelDemo.scss';
// import 'antd/dist/antd.less';

export default class ExcelDemo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            modalVisible: false,
            modalType: 'create',
            modalTitle: '',
            modalScript: '',
            editType: null, //"new" or "modify"
            modalLoading: false,
            tableLoading: false,
            tplId: null,
        };
    }

    componentDidMount() {
        this.fetch_excel_list();
    }

    fetch_excel_list = ()=>{
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/office/rp_excel_tpls')
        .then(retData => {
            if (retData.data && retData.data.results) {
                self.setState({dataList: retData.data.results});
            } else {
            };
        });
    }

    displayNewExcelModal = ()=>{
        this.setState({
            modalKey: 'modal-' + parseInt(new Date().getTime()),
            modalScript: '',
            modalVisible: true,
            modalType: 'create',
            editType: 'new',
            modalTitle: '',
        })
    }

    onClickModalOK = ()=>{
        if(this.modalLoading === true){
            return;
        }

        if(this.state.modalType == 'edit'){
            this.saveExcel();
        }
    }

    editExcel = (record)=>{
        let self = this;
        this.setState({tableLoading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/office/copy_excel_tpl_to_tmp', {
            id: record.id
        }).then(retData => {
            this.setState({tableLoading: false});
            let d = retData.data;
            if (d && d.errcode === 0) {
                self.setState({
                    modalKey: 'modal-' + parseInt(new Date().getTime()),
                    modalVisible: true,
                    fileKey: d.file_key,
                    modalScript: (record.script === null ? '':record.script),
                    modalType: 'edit',
                    editType: 'modify',
                    modalTitle: record.name,
                    tplId: record.id
                })
            } else {
            };
        });
    }

    delExcel = (record)=>{
        const execDel = () => {
            patch(process.env.REACT_APP_SERVER_PATH + 'api/office/rp_excel_tpls/' + record.id, {
                status: -1,
            }).then((retData) => {
                if (retData.data) {
                    message.success("删除成功");
                    this.fetch_excel_list(1);
                } else {
                    message.error("删除失败");
                };
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    saveExcel = ()=>{
        let self = this;
        if(this.excel_inst_save){
            this.setState({modalLoading: true});
            this.excel_inst_save().then(()=>{
                console.log('excel saved');
                if(self.state.editType == 'new'){
                    post(process.env.REACT_APP_SERVER_PATH + 'api/office/rp_excel_tpls', {
                        file_key: self.state.fileKey,
                        name: self.state.modalTitle,
                        script: self.state.modalScript,
                    }).then(retData => {
                        self.setState({modalLoading: false});
                        if (retData.data && retData.data.id) {
                            message.success('模板已添加');
                            self.setState({modalVisible: false}, ()=>self.fetch_excel_list());
                        } else {
                        };
                    });
                }else if(self.state.editType == 'modify'){
                    patch(process.env.REACT_APP_SERVER_PATH + 'api/office/rp_excel_tpls/'+self.state.tplId, {
                        file_key: self.state.fileKey,
                        name: self.state.modalTitle,
                        script: self.state.modalScript,
                    }).then(retData => {
                        self.setState({modalLoading: false});
                        if (retData.data && retData.data.id) {
                            message.success('模板已更新');
                            self.setState({modalVisible: false}, ()=>self.fetch_excel_list());
                        } else {
                        };
                    });
                }
            });
        }
    }

    render() {
        let self = this;
        const {dataList} = this.state;

        return (
            <div className="ExcelDemo">
                <div className="toolBar">
                    <Button style={{marginLeft: 0}} onClick={()=>this.displayNewExcelModal()} type="primary">添加报表模板</Button>
                </div>
                <div className="downContent">
                    <Table
                        rowKey='id'
                        bordered
                        pagination={{
                            defaultCurrent: 1,
                            current: 1,
                            pageSize: 99999,
                            total: dataList.length,
                        }}
                        loading={this.state.tableLoading}
                        dataSource={dataList}
                    >
                        <Table.Column
                            title="名称"
                            key="name"
                            dataIndex="name"
                            // width={150}
                        />
                        <Table.Column
                            title="创建时间"
                            key="create_time"
                            dataIndex="create_time"
                            width={200}
                        />
                        <Table.Column
                            title="操作"
                            key="operate"
                            width={150}
                            render={(text,record)=>(
                                <span>
                                    <a onClick={_=>self.editExcel(record)}>编辑</a>
                                    <Divider type="vertical" />
                                    <a onClick={_=>self.delExcel(record)}>删除</a>
                                </span>
                            )}
                        />
                    </Table>
                </div>
                <Modal
                    key={this.state.modalKey}
                    className="excel_modal"
                    title={
                        this.state.modalType == "create" ?
                        "EXCEL 编辑器"
                        :
                        [
                        "EXCEL 编辑器",
                        <Input
                            placeholder="请输入模板名称"
                            style={{width: 250, marginLeft: 20}}
                            value={this.state.modalTitle}
                            onChange={e=>this.setState({modalTitle: e.target.value})}
                        />
                        ]
                    }
                    visible={this.state.modalVisible}
                    width={1000}
                    style={{
                        minWidth: 1200,
                        height: 550,
                        top: this.state.modalType == "create" ? 80:10
                    }}
                    onOk={()=>this.onClickModalOK()}
                    onCancel={()=>this.setState({modalVisible: false})}
                    okText={this.state.modalType=='edit'?'保存':<React.Fragment />}
                >
                    <Spin tip="请稍等..." spinning={this.state.modalLoading}>
                    {
                        this.state.modalType == 'create' &&
                        <Upload.Dragger
                            name='file'
                            action={process.env.REACT_APP_SERVER_PATH + "api/office/upload_tmp_file"}
                            // headers={{
                            //     authorization: '***',
                            // }}
                            onChange = {(info)=>{
                                const { status } = info.file;
                                if (status !== 'uploading') {
                                    console.log(info.file, info.fileList);
                                }
                                if (status === 'done') {
                                    // message.success("file uploaded successfully.");
                                    console.log(info.file.response);
                                    self.setState({modalType: "edit", fileKey: info.file.response.file_key, modalScript: ''});
                                } else if (status === 'error') {
                                    message.error("上传失败。");
                                }
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">上传文件：点击此处，或将文件拖到这里。</p>
                            <p></p>
                        </Upload.Dragger>
                    }
                    {
                        this.state.modalType == 'edit' &&
                        <div className="modalEditContent">
                            <ExcelEditor
                                key={this.state.fileKey}
                                file_key={this.state.fileKey}
                                title="XXX Excel"
                                width={800}
                                height={550}
                                getSaveFunc={_func=>this.excel_inst_save=_func}
                            />
                            <Editor
                                className="scriptEditor"
                                value={this.state.modalScript}
                                onValueChange={(code) => this.setState({modalScript: code})}
                                highlight={(code) => highlight(code, languages.js)}
                                padding={10}
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 12,
                                }}
                            />
                        </div>
                    }
                    </Spin>
                </Modal>
            </div>
        );
    }
}
