import BoardCharts from './BoardCharts.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/MainPageV2/actions.js';
import { post } from 'common/utils';
import {
    onDragOver,
    onDrop,
    getHisTagListData,
} from './utils.js';

const deleteCustomChart = index => {
    return (dispatch, getState) => {
        const { mainPageV2: { customCharts, layout, sys_id } } = getState();
        const newCustomCharts = customCharts
            .filter((_, ind) => ind !== index);
        const layout_id = layout.results.filter(({ sys_id: sysId }) => sysId === sys_id)[0]._id;
        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/layout_conf/custom/save', {
            layout_id,
            cus_history_list: newCustomCharts,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                const newLayout = layout;// 等待更好的做法
                newLayout.cus_config.filter(({ layout_id: layoutId }) => layoutId === layout_id)[0].cus_history_list = newCustomCharts;
                dispatch(setValues({
                    layout: newLayout,
                    customCharts: newCustomCharts,
                }));
                return true;
            } else {
                return false;
            };
        });
    };
}

const mapStateToProps = state => {
    const { mainPageV2 } = state;
    const { layout, sys_id } = mainPageV2;
    let panel_1 = [];
    if (!!layout) {
        panel_1 = layout.results.filter(({ sys_id: sysId }) => sysId === sys_id)[0].panel_1;
    };
    return {
        ...mainPageV2,
        panel_1,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onDragOverCharts: index => dispatch(onDragOver('onDragOverIndex_charts', index)),
        onDropCharts: index => dispatch(onDrop('charts', index)),
        deleteCustomChart: index => dispatch(deleteCustomChart(index)),
        rangeHoursOnChange: selectedRangeHourIndex => dispatch(setValues({ selectedRangeHourIndex })),
        getHisTagListData: () => dispatch(getHisTagListData(ownProps)),
    };
}

const WrapperedBoardCharts = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoardCharts);

export default WrapperedBoardCharts;