import React from 'react'
import style from './index.module.scss'
import TopBreadcrumb from '../../../../components/TopBreadCrumb'

const IronData = () => {
    const breadcrumbList = [{ name: '看板' }, { name: '趋势看板' }, { name: '看板' }]
    return (
        <div className={style['ironData']}>
            <div className="ironData-header">
                <div className="ironData-header-left">
                    <TopBreadcrumb breadcrumbList={breadcrumbList}/>
                </div>
                <div className="ironData-header-right">
                    22222
                </div>
            </div>
        </div>
    )
}
export default IronData
