import { useEffect, useRef, useState, useContext, useMemo } from 'react';
import { get } from 'common/utils';
import { PAGE_SIZE } from '../../utils';
import moment from 'moment';
import CommonContext from '../../../../context';

const REFRESH_PERIOD = 60 * 1000;//1 minute

const Hook = () => {
    const [events, setEvents] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [forceRefresh, setForceRefresh] = useState(Symbol());
    const forceRefreshFunc = useRef(() => setForceRefresh(Symbol()));
    const commonInfo = useContext(CommonContext);
    const commonRefresh = useMemo(() => {
        if (!!commonInfo) {
            return commonInfo.refresh;
        };
        return null;
    }, [commonInfo]);

    const queryLock = useRef(null);
    useEffect(() => {
        if (typeof forceRefresh !== 'symbol') return;
        const fetchData = () => {
            const currLock = (queryLock.current = Symbol());
            get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/capsuleByUserIdSearch', {
                useId: 0,
                pageNo: page,
                rowNo: PAGE_SIZE,
            })
                .then((retData) => {
                    if (currLock !== queryLock.current) return;
                    // console.log('useEvents', retData);
                    if (!!retData.data && retData.data.error === 0) {
                        if (!!retData.data.results) {
                            const { total, list } = retData.data.results;
                            setTotal(total);
                            const nextEvents =
                                list
                                    .map((item) => {
                                        const { create_time, json_content } = item;
                                        let titleText = "";
                                        try {
                                            const jsonText = JSON.parse(json_content);
                                            if (!!jsonText) {
                                                if (typeof jsonText.title === 'string') {
                                                    titleText = jsonText.title;
                                                };
                                            };
                                        } catch (error) { };
                                        let createTimeText = "";
                                        try {
                                            const tmp_time = moment(create_time);
                                            if (tmp_time.isValid()) {
                                                createTimeText = tmp_time.format("YYYY/MM/DD HH:mm:ss");
                                            };
                                        } catch (err) { };
                                        return Object.assign({}, item, { titleText, createTimeText });
                                    });
                            setEvents(
                                nextEvents
                            );
                        };
                    };

                });
        };
        fetchData();
        const myInterval = setInterval(fetchData, REFRESH_PERIOD)
        return () => {
            clearInterval(myInterval);
            queryLock.current = Symbol();
        };
    }, [forceRefresh, page, commonRefresh]);

    return [events, forceRefreshFunc.current, page, setPage, total];
};

export default Hook;
