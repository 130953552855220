import React from 'react';

import ReportViewer from './ReportViewer';
// import EditReport from './EditReport';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // mode: 'view',
            // editReportId: null,
        };
    }

    componentDidMount() {
    }

    editReportOnClick = (editReportId) => {
        let reportId = -1;
        if (typeof editReportId === 'number') {
            reportId = editReportId;
        };
        this.props.history.push(`/System/EditReport/${reportId}`)
        // console.log(editReportId);
        // let nextEditReportId = null;
        // if (!!editReportId) {
        //     nextEditReportId = editReportId;
        // };
        // this.setState({ mode: 'edit', editReportId: nextEditReportId });
    }

    render() {
        // const { mode, editReportId } = this.state;

        return (
            <div style={{ height: '100%', position: 'relative' }}>
                <div
                    style={Object.assign(
                        { height: '100%' },
                        // mode === 'view' ? {} : { opacity: 0 },
                    )}
                >
                    <ReportViewer editReportOnClick={this.editReportOnClick} />
                </div>
                {/* {
                    mode === 'edit' && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                            <EditReport reportId={editReportId} />
                        </div>
                    )
                } */}
            </div >
        );
    }
}
