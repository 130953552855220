import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const parse_func = (data) => {
    const { volatility, abnormal_avg, analysis_avg, compare_avg } = data;
    const parsed_volatility = (typeof volatility === 'number' ? Math.floor(volatility * 1000) / 10 : "--") + "%";
    const parsed_abnormal_avg = typeof abnormal_avg === 'number' ? significantNum(abnormal_avg, 4) : "--";
    const parsed_analysis_avg = typeof analysis_avg === 'number' ? significantNum(analysis_avg, 4) : "--";
    const parsed_compare_avg = typeof compare_avg === 'number' ? significantNum(compare_avg, 4) : "--";
    return Object.assign({}, data, {
        parsed_volatility,
        parsed_abnormal_avg,
        parsed_analysis_avg,
        parsed_compare_avg,
    });
};

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            let requesting = true;
            const { base_id, an_time_begin, an_time_end, cp_time_begin, cp_time_end, time_begin, time_end } = conf;
            if (typeof time_begin !== 'string' || typeof base_id !== 'number') {
                setLoading(false);
                setResult(null);
                return;
            };
            const ab_time_begin = moment(time_begin).format("YYYY-MM-DD HH:mm:00");
            let ab_time_end = (typeof time_end === 'string' ? moment(time_end) : moment()).format("YYYY-MM-DD HH:mm:00");
            if (moment(ab_time_end).isBefore(moment(ab_time_begin))) ab_time_end = ab_time_begin;
            // 这里约定好给到的time_begin不会晚于moment(),其实是有同步性要求的
            // 如果开始结束时间不满足要求，结束时间为开始时间
            setLoading(true);
            Promise.all([
                post(process.env.REACT_APP_SERVER_PATH + "api/kpi/get_related_factors", {
                    base_id,
                    ab_time_begin,
                    ab_time_end,
                    an_time_begin,
                    an_time_end,
                    cp_time_begin,
                    cp_time_end,
                }),
                post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_related_events', {
                    time_begin: ab_time_begin, time_end: ab_time_end,
                }),
            ])
                .then((retDatas) => {
                    if (requesting) {
                        const [retData_1, retData_2] = retDatas;
                        // console.log('hook', retData_1, retData_2);
                        let nextResult = {};
                        if (retData_1?.data?.errcode === 0) {
                            let related_factor_info = retData_1?.data ?? null;
                            if (!!related_factor_info) {
                                let parsed_data = null;
                                let parsed_kpi_info = related_factor_info?.kpi_info;
                                if (!!parsed_kpi_info) {
                                    parsed_kpi_info = parse_func(parsed_kpi_info);
                                    const effect_factors = related_factor_info?.effect_factors;
                                    if (!!effect_factors) {
                                        const parsed_effect_factors = effect_factors.map((data) => parse_func(data));
                                        parsed_kpi_info = Object.assign({}, parsed_kpi_info, { children: parsed_effect_factors });
                                    };
                                    parsed_data = [parsed_kpi_info];
                                };
                                related_factor_info = Object.assign({}, related_factor_info, { parsed_data });
                            };
                            nextResult = Object.assign({}, nextResult, { related_factor_info });
                        };
                        if (retData_2?.data?.errcode === 0) { //abnormal_history
                            let events = retData_2?.data?.results ?? null;
                            if (!!events) {
                                events = events
                                    .map((item) => {
                                        const { occur_time } = item;
                                        const parsed_time = moment(occur_time).format("YYYY/MM/DD HH:mm");
                                        return Object.assign({}, item, {
                                            parsed_time,
                                        });
                                    });
                            };
                            nextResult = Object.assign({}, nextResult, { events });
                        };

                        // console.log(nextResult);
                        setResult(nextResult);
                        setLoading(false);
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
