import React from 'react';
import style from './HorizLine.module.scss';
import { elec_config } from './utils';

export default class HoriLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className={style['HorizLine']}>
                <div className="LeftPad"></div>
                {
                    elec_config.map((item, index) =>
                        <div key={index + "-cell"} className='LineCell' style={{ flexGrow: item.period }}>
                            <div className='ColorBlock' style={{ background: item.color }}></div>
                            <div className='TextBlock' style={{ color: item.color }}>{item.elec_price}元/kWh</div>
                        </div>
                    )
                }
                <div className="RightPad"></div>
            </div>
        );
    }
}