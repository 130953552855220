import React from 'react';
import { Select, DatePicker } from 'antd';
import moment from 'moment';

import ICON_STAR from './res/star.png';
import SEARCH_ICON from './res/search.png';
import ICON_CHECKED from './res/check_yes.png';
import ICON_NOT_CHECKED from './res/check_no.png';

import './ReferenceFactors.scss';

import ColoredBar from '../Common/ColoredBar.js';
import TrendChartWrapper from './TrendChartWrapper.js';
import CustomFactorBtn from '../Common/CustomFactorBtnContainer.js';
import CustomFactorPanel from '../Common/CustomFactorPanelContainer.js';
import RFDetail from './RFDetail/index.js';

const { Option } = Select;

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rowSelectedIndex: null,
            cusFactorPanelVisible: false,
            trendCheckedIndexs: [],
            topBarTexts: [
                {
                    text: '日',
                    picker: 'date',
                }, {
                    text: '周',
                    picker: 'week',
                }, {
                    text: '月',
                    picker: 'month',
                },
            ],
            selectedTopBarTextIndex: null,
            edittingTopBarTextIndex: null,
            selectedPeriodTime: null,
        };
    }

    componentDidMount() {
        const { getSysList, getThemeList } = this.props;
        getSysList()
            .then(isSuccess => {
                // console.log(isSuccess);
            });
        getThemeList()
            .then(isSuccess => {
                // console.log(isSuccess);
            });
        const startTime = new URLSearchParams(this.props.location.search).get('startTime');
        const endTime = new URLSearchParams(this.props.location.search).get('endTime');
        // console.log(startTime, endTime);
        if (!!startTime && !!endTime) {
            const { updateTimeRange } = this.props;
            updateTimeRange(moment(startTime), moment(endTime));
        } else {
            this.setState({ selectedTopBarTextIndex: 2 });
        };
        setTimeout(() => {
            this.initData();
        }, 0);
    }

    initData = () => {
        const currFactorId = this.props.match.params.id;
        // console.log(currFactorId, typeof currFactorId);
        const { getKPIDetail, timeRange } = this.props;
        const [startTime, endTime] = timeRange;
        getKPIDetail(startTime.format("YYYY-MM-DD 00:00:00"), endTime.format("YYYY-MM-DD 23:59:59"), parseInt(currFactorId))
            .then(isSuccess => {
                // console.log(isSuccess);
                this.setState({ rowSelectedIndex: null }, () => {
                    const { kpiDetail } = this.props;
                    if (!!kpiDetail && kpiDetail.ref_factors.length > 0) {
                        this.rowOnclick(1);
                    };
                });
            });
    }

    rowOnclick = (nextRowSelectedIndex) => {
        if (nextRowSelectedIndex !== 0) {
            this.setState({ rowSelectedIndex: nextRowSelectedIndex }, () => {
                const { getShiftDetails, kpiDetail: { ref_factors }, timeRange } = this.props;
                const [startTime, endTime] = timeRange;
                getShiftDetails(startTime.format("YYYY-MM-DD 00:00:00"), endTime.format("YYYY-MM-DD 23:59:59"), [ref_factors[nextRowSelectedIndex - 1].id])
                    .then(isSuccess => {
                        // console.log(isSuccess);
                    });
            });
        };
    }

    customeFactorBtnOnClick = () => {
        this.setState({ cusFactorPanelVisible: true });
    }

    trendIndexOnClick = (index) => {
        const { trendCheckedIndexs } = this.state;
        const nextTrendCheckedIndexsSet = new Set(trendCheckedIndexs);
        if (nextTrendCheckedIndexsSet.has(index)) {
            nextTrendCheckedIndexsSet.delete(index);
        } else {
            nextTrendCheckedIndexsSet.add(index);
        };
        this.setState({ trendCheckedIndexs: [...nextTrendCheckedIndexsSet] });
    }

    periodRangeOnChange = date => {
        const { edittingTopBarTextIndex } = this.state;
        const { updateTimeRange } = this.props;
        // console.log(date);
        this.setState({
            selectedPeriodTime: date,
            edittingTopBarTextIndex: null,
            selectedTopBarTextIndex: edittingTopBarTextIndex,
        });
        let time_begin = moment(date);
        let time_end = moment(date);
        if (edittingTopBarTextIndex === 1) {
            //week 1~7 1~0
            time_begin = moment(date).startOf('week');
            time_end = moment(date).endOf('week');
        } else if (edittingTopBarTextIndex === 2) {
            // month
            time_begin = moment(date).startOf('month');
            time_end = moment(date).endOf('month');
        };
        console.log(time_begin.format("YYYY-MM-DD"), time_end.format("YYYY-MM-DD"));
        updateTimeRange(time_begin, time_end);
        setTimeout(() => {
            this.initData();
        }, 0);
    }

    cancelPeriodSelect = () => {
        this.setState({ edittingTopBarTextIndex: null });
    }

    render() {
        const { systems, themes, kpiDetail, timeRange } = this.props;
        const [startTime, endTime] = timeRange;
        const {
            rowSelectedIndex, cusFactorPanelVisible, trendCheckedIndexs,
            selectedTopBarTextIndex, topBarTexts, edittingTopBarTextIndex,
        } = this.state;
        const selectStyle = { height: 32, width: 160, backgroundColor: '#343D47' };
        let timeRangeText = "-年-月-日- -年-月-日";
        if (timeRange !== null) {
            timeRangeText = timeRange
                .map(time => time.format('YYYY年MM月DD日'))
                .join('- ');
        };

        return (
            <div className="reference_factors_wrapper">
                <div className="reference_factors_header_wrapper">
                    <div style={{ height: 67, paddingTop: 1, display: 'flex', alignItems: 'center', backgroundColor: '#212830' }}>
                        <Select disabled defaultValue="all" style={Object.assign({ marginLeft: 24 }, selectStyle)} bordered={false}>
                            <Option value="all">全部生产系统</Option>
                            {
                                systems
                                    .map((item, index) => {
                                        const { name, id } = item;
                                        return (
                                            <Option value={id} key={index}>{name}</Option>
                                        );
                                    })
                            }
                        </Select>
                        <Select disabled defaultValue="all" style={Object.assign({ marginLeft: 8 }, selectStyle)} bordered={false}>
                            <Option value="all">全部指标主题</Option>
                            {
                                themes
                                    .map((item, index) => {
                                        const { name, id } = item;
                                        return (
                                            <Option value={id} key={index}>{name}主题</Option>
                                        );
                                    })
                            }
                        </Select>
                        {/* <Select defaultValue="1" style={Object.assign({ marginLeft: 8 }, selectStyle)} bordered={false}>
                            <Option value="1">改进计划</Option>
                            <Option value="2">全部</Option>
                            <Option value="3">已加入</Option>
                            <Option value="4">未加入</Option>
                        </Select> */}
                        <div style={{ marginLeft: 16, position: 'relative' }}>
                            <Select
                                disabled
                                placeholder="请输入指标名称"
                                style={{ width: 240, height: 32, backgroundColor: '#343D47' }}
                                bordered={false}
                                showSearch
                                suffixIcon={null}
                            ></Select>
                            <img alt="" src={SEARCH_ICON} width="22" height="22" style={{ cursor: 'pointer', position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }} />
                        </div>
                        <div style={{ marginLeft: 'auto', position: 'relative' }}>
                            <div
                                style={Object.assign({ color: '#FFFFFF', fontSize: 20, lineHeight: '20px', height: 20, cursor: 'pointer' }, {})}
                            >【{timeRangeText}】</div>
                        </div>
                        <div className="time_range_select_block" style={{ position: 'relative' }}>
                            {
                                topBarTexts
                                    .map((item, index, arr) => {
                                        const { text } = item;
                                        let className = "block_wrapper";
                                        if (edittingTopBarTextIndex !== null) {
                                            if (index === edittingTopBarTextIndex) {
                                                className += " selected_block_wrapper";
                                            };
                                        } else {
                                            if (index === selectedTopBarTextIndex) {
                                                className += " selected_block_wrapper";
                                            };
                                        };
                                        let style = index < arr.length - 1 ? { marginRight: 4 } : {};
                                        if (edittingTopBarTextIndex !== null) {
                                            style = Object.assign(
                                                {
                                                    position: 'relative',
                                                    zIndex: 10001,
                                                },
                                                style,
                                            )
                                        };
                                        return (
                                            <div
                                                className={className}
                                                key={index}
                                                onClick={() => {
                                                    this.setState({ edittingTopBarTextIndex: index });
                                                }}
                                                style={style}
                                            >
                                                <div className="text">{text}</div>
                                            </div>
                                        );
                                    })
                            }
                            {
                                edittingTopBarTextIndex !== null && (
                                    <div
                                        style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000 }}
                                        onClick={this.cancelPeriodSelect}
                                    />
                                )
                            }
                            <div
                                style={{ position: 'absolute', zIndex: 10001, width: '100%', bottom: 0, left: '50%', transform: 'translate(-50%, 100%)' }}
                                ref={refNode => {
                                    if (!!refNode) {
                                        this.refNode = refNode;
                                    };
                                }}
                            >
                                {
                                    edittingTopBarTextIndex !== null && (
                                        <div
                                            key={`${edittingTopBarTextIndex}-picker`}
                                            style={{ width: '100%', backgroundColor: 'rgb(33, 40, 48)' }}
                                        >
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                // bordered={false}
                                                defaultValue={startTime}
                                                open={true}
                                                picker={topBarTexts[edittingTopBarTextIndex].picker}
                                                onChange={this.periodRangeOnChange}
                                                getPopupContainer={(trigger) => {
                                                    // console.log(trigger);
                                                    // console.log(this.refNode)
                                                    return this.refNode;
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
                </div>
                <div className="reference_factors_content_wrapper">

                    <div className="left_block_wrapper">
                        <div className="item_content_wrapper">
                            <div className="table_wrapper scroll_bar_restyle">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 12, height: 52 }}></th>
                                            <th style={{ width: 96 }}>产品类型</th>
                                            <th colSpan="4">指标名称</th>
                                            <th>平均值</th>
                                            <th>基准值</th>
                                            <th style={{ textAlign: 'center' }}>红绿灯</th>
                                            <th>平均KPR</th>
                                            <th>目标KPR</th>
                                            <th>比较</th>
                                            <th style={{ width: 400 }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>{startTime.format("YYYY-MM-DD")}</div>
                                                    <div>{endTime.format("YYYY-MM-DD")}</div>
                                                </div>
                                            </th>
                                            <th style={{ width: 96 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // dataSource
                                            !!kpiDetail &&
                                            [kpiDetail].concat(kpiDetail.ref_factors)
                                                .map((item, index) => {
                                                    const {
                                                        product_name, name, weighted_avg, lower_limit, upper_limit, rate, exp_cpl_rate,
                                                        times, id,
                                                    } = item;

                                                    let redGreen = null;
                                                    let weighted_avg_format = '-';
                                                    if (typeof weighted_avg === "number") {
                                                        weighted_avg_format = Math.floor(weighted_avg * 10) / 10;
                                                    };
                                                    let standardV = '-';
                                                    if (typeof lower_limit === "number") {
                                                        if (typeof weighted_avg === "number") {
                                                            redGreen = lower_limit > weighted_avg;
                                                        };
                                                        standardV = lower_limit;
                                                    } else if (typeof upper_limit === "number") {
                                                        if (typeof weighted_avg === "number") {
                                                            redGreen = upper_limit < weighted_avg;
                                                        };
                                                        standardV = upper_limit;
                                                    };
                                                    if (typeof standardV === "number") {
                                                        standardV = Math.floor(standardV * 10) / 10;
                                                    };

                                                    let phiWarning = false;
                                                    let rateText = '-';
                                                    if (typeof rate === "number") {
                                                        rateText = Math.floor(rate * 1000) / 10;
                                                    };
                                                    let expCplRate = '-';
                                                    if (typeof exp_cpl_rate === "number") {
                                                        expCplRate = Math.floor(exp_cpl_rate * 1000) / 10;
                                                    };
                                                    let rateDelta = '-';
                                                    if (typeof rate === "number" && typeof exp_cpl_rate === "number") {
                                                        rateDelta = Math.floor((rate - exp_cpl_rate) * 1000) / 10;
                                                        phiWarning = rate < exp_cpl_rate;
                                                    };

                                                    const signStyle = { width: 12, height: 12, borderRadius: '50%', backgroundColor: redGreen === null ? "transparent" : (redGreen ? "#FF6170" : "#44F3DA"), margin: '0 auto' };

                                                    // const redGreen = Math.random() > 0.9;//red true,green false
                                                    const rowBckType = (rowSelectedIndex === index) ? ((trendCheckedIndexs.includes(index) && index !== 0) ? "tr_selected_top" : "tr_selected") : "";
                                                    let rowSpan = kpiDetail.ref_factors.length + trendCheckedIndexs.length;
                                                    if (trendCheckedIndexs.includes(kpiDetail.ref_factors.length)) {
                                                        rowSpan--;
                                                    };
                                                    if (trendCheckedIndexs.includes(0)) {
                                                        rowSpan--;
                                                    };
                                                    return [
                                                        (
                                                            <tr key={id} className={rowBckType + (trendCheckedIndexs.includes(0) ? " tr_zero_expand" : "")} onClick={() => this.rowOnclick(index)}>
                                                                <td style={{ height: 48 }}>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        index === 0 && (
                                                                            <div className="td_3_product_category" style={{ marginLeft: 2 }}>{product_name}</div>
                                                                        )
                                                                    }
                                                                </td>
                                                                {
                                                                    index === 1 && (
                                                                        <td rowSpan={rowSpan} style={{ width: 1, position: 'relative' }}>
                                                                            <div style={{ position: 'absolute', top: trendCheckedIndexs.includes(0) ? -85 : -5, left: 0, bottom: 24, width: 1 }}>
                                                                                <div style={{ height: '100%', backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 50%, transparent 50%)', backgroundRepeat: 'repeat-y', backgroundSize: '1px 5px' }} />
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    index !== 0 && ([
                                                                        (
                                                                            <td key={`${index}-span-2`} style={{ width: 27 }}>
                                                                                <div style={{ height: 1, backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 50%, transparent 50%)', backgroundRepeat: 'repeat-x', backgroundSize: '5px 1px' }} />
                                                                            </td>
                                                                        ),
                                                                        (
                                                                            <td key={`${index}-span-3`} style={{ width: 7 }}></td>
                                                                        ),
                                                                    ])
                                                                }
                                                                <td
                                                                    {
                                                                    ...(
                                                                        index === 0 ? { colSpan: '4' } : {}
                                                                    )
                                                                    }
                                                                >
                                                                    <div className="factor_name_wrapper">
                                                                        {
                                                                            index === 0 ?
                                                                                (
                                                                                    <img alt="" src={ICON_STAR} width="20" height="20" />
                                                                                ) : (
                                                                                    <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#B376FF' }} />
                                                                                )
                                                                        }
                                                                        <div style={{ marginLeft: index === 0 ? 4 : 8, height: 14 }}>{name}</div>
                                                                    </div>
                                                                </td>
                                                                <td style={redGreen === true ? { color: '#FF6170' } : {}}>{weighted_avg_format}</td>
                                                                <td>{standardV}</td>
                                                                <td>
                                                                    <div style={signStyle} />
                                                                </td>
                                                                <td style={phiWarning === true ? { color: '#FF6170' } : {}}>{rateText}%</td>
                                                                <td>{expCplRate}%</td>
                                                                <td style={phiWarning === true ? { color: '#FF6170' } : {}}>{rateDelta}%</td>
                                                                <td>
                                                                    <ColoredBar timeBegin={startTime.format("YYYY-MM-DD 00:00:00")} timeEnd={endTime.format("YYYY-MM-DD 23:59:59")} coloredLines={times} />
                                                                </td>
                                                                <td onClick={(e) => { e.stopPropagation(); this.trendIndexOnClick(index) }}>
                                                                    <div style={{ display: 'inline-block', marginLeft: 31 }}>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <img alt="" src={trendCheckedIndexs.includes(index) ? ICON_CHECKED : ICON_NOT_CHECKED} width="16" height="16" />
                                                                            <div style={{ marginLeft: 4, fontSize: 14, lineHeight: '14px', height: 14, color: '#9FA0BA' }}>趋势</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    ]
                                                        .concat(
                                                            trendCheckedIndexs.includes(index) && [(
                                                                <tr key={`${id}-trend`} className={(rowSelectedIndex === index && index !== 0 ? "tr_selected_bottom" : "") + (trendCheckedIndexs.includes(0) ? " tr_zero_expand" : "")}>
                                                                    <td colSpan={2}></td>
                                                                    <td />
                                                                    {
                                                                        kpiDetail.ref_factors.length === index && (
                                                                            <td />
                                                                        )
                                                                    }
                                                                    <td colSpan={index === 0 && kpiDetail.ref_factors.length > 0 ? 9 : 8} />
                                                                    <td style={{ height: 80, position: 'relative' }}>
                                                                        <div style={{ position: 'absolute', left: 0, right: 0, height: 80, top: -6, backgroundColor: 'rgba(13, 17, 21, 1)', border: '1px solid rgba(68, 243, 218, 0.2)' }}>
                                                                            <TrendChartWrapper timeRange={timeRange} id={id} upper_limit={upper_limit} lower_limit={lower_limit} />
                                                                        </div>
                                                                    </td>
                                                                    <td />
                                                                </tr>
                                                            )]
                                                        );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="right_block_wrapper">
                        <div style={{ height: "100%", overflowY: 'auto' }} className="scroll_bar_restyle">
                            {
                                rowSelectedIndex !== null && (
                                    <RFDetail selectedRF={kpiDetail.ref_factors[rowSelectedIndex - 1]} />
                                )
                            }
                        </div>
                    </div>
                    {//hide
                        false && (
                            <div style={{ position: 'absolute', right: 16, top: 372 }} onClick={this.customeFactorBtnOnClick}>
                                <CustomFactorBtn />
                            </div>
                        )
                    }
                    {
                        cusFactorPanelVisible && (
                            <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, width: 360, zIndex: 1000 }}>
                                <CustomFactorPanel
                                    onCancel={() => this.setState({ cusFactorPanelVisible: false })}
                                    watchFavouriteKPIs={this.watchFavouriteKPIs}
                                />
                            </div>
                        )
                    }

                </div>
            </div >
        );
    }
}

export default Page;