import React from 'react';
import { Table, Input, Select, Button, Modal, message} from 'antd';
import { get } from '../../../common/utils.js';

import './FactorList.scss';
// import 'antd/dist/antd.less';

export default class FactorList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            factors: [],
            factor: {},
            jsonList: [],
            validTagList: [],
            selectedValidTag: 'ALLDATA',
            filterText: '',
            jsonRawValue: ''
        };
    }

    componentDidMount() {
        this.fetch_factor_list();
    }

    get_name = (tag_name)=>{
        let name = null;
        this.state.factors.forEach(item=>{
            if(item.tag_name === tag_name){
                name = item.name;
            }
        })
        return name;
    }

    get_upper_limit = (factor_tag)=>{
        let limit_value = null;
        this.state.jsonList.forEach(item=>{
            if(item.factor_tag === factor_tag){
                limit_value = item.upper_limit;
                console.log(limit_value);
            }
        })
        return limit_value;
    }

    get_lower_limit = (factor_tag)=>{
        let limit_value = null;
        this.state.jsonList.forEach(item=>{
            if(item.factor_tag === factor_tag){
                limit_value = item.lower_limit;
            }
        })
        return limit_value;
    }

    set_upper_limit = (factor_tag, value)=>{
        console.log(factor_tag, value);
        this.state.jsonList.forEach(item=>{
            if(item.factor_tag === factor_tag){
                if(parseFloat(value)+''===value){
                    item.upper_limit = parseFloat(value);
                }else if(value === ''){
                    item.upper_limit = null;
                }else{
                    item.upper_limit = value;
                }
            }
        })
        this.forceUpdate();
    }

    set_lower_limit = (factor_tag, value)=>{
        console.log(factor_tag, value);
        this.state.jsonList.forEach(item=>{
            if(item.factor_tag === factor_tag){
                if(parseFloat(value)+''===value){
                    item.lower_limit = parseFloat(value);
                }else if(value === ''){
                    item.lower_limit = null;
                }else{
                    item.lower_limit = value;
                }
            }
        })
        this.forceUpdate();
    }

    fetch_factor_list = ()=>{
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/factor/list')
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    self.setState({factors: retData.data.results});
                } else {
                };
            });
    }

    editFactor = (item)=>{
        this.setState({
            factor: item
        });
    }

    editJson = ()=>{
        this.setState({
            modalVisible: true,
            jsonRawValue: JSON.stringify(this.state.jsonList)
        });
    }

    confirmJson = ()=>{
        let jsonString = this.state.jsonRawValue.replace(/\bNaN\b/g, "null");
        try{
            let jsonData = JSON.parse(jsonString);
            let validTagList = [];
            jsonData.forEach(item=>{
                if(validTagList.indexOf(item.valid_tag) === -1){
                    validTagList.push(item.valid_tag);
                }
            })
            this.setState({
                jsonList: jsonData,
                modalVisible: false,
                validTagList: validTagList
            })
            console.log(jsonData);
        }catch(err){
            message.error('JSON格式错误');
        }
    }

    addFactor = ()=>{
        let self = this;
        if(!this.state.factor.factor_tag){
            message.info('请先选择左侧指标');
            return;
        }
        let factor_tag = prompt("请输入完整的点位名", "");
        let current_num = this.state.factor.factors.filter(item=>item.tag_name===factor_tag).length;
        if(current_num > 0){
            message.error('此影响因子在当前指标下已存在，无法重复添加。');
            return;
        }

        let factor_item = null;
        this.state.jsonList.forEach(item=>{
            if(item.factor_tag === factor_tag){
                factor_item = item;
            }
        })
        if(factor_item == null){
            message.error('未找到影响因子：'+factor_tag);
        }else{
            this.state.factor.factors.push({
                tag_name: factor_tag,
            });
            setTimeout(()=>self.forceUpdate(), 100);
        }
    }

    delFactor = (tag_name)=>{
        let self = this;
        let execDelFunc = ()=>{
            let factors = self.state.factor.factors;
            let i = 0;
            while (i < factors.length) {
                if (factors[i].tag_name === tag_name) {
                    factors.splice(i, 1);
                } else {
                    ++i;
                }
            }
            self.forceUpdate();
        }

        Modal.confirm({
            title: '确认删除因子 '+tag_name+' 吗？',
            onOk() {
                execDelFunc();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {
        let self = this;
        const { jsonList, factor, selectedValidTag, filterText} = this.state;
        // console.log('filterText: '+filterText);
        let dataList = jsonList.filter(item=>{
            let name = self.get_name(item.factor_tag);
            if(item.is_kpi===0){
                return false;
            }else if(selectedValidTag !== 'ALLDATA' && selectedValidTag !== item.valid_tag){
                return false;
            }else if(filterText !== '' && 
                !((item.valid_tag && item.valid_tag.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
                (item.factor_tag && item.factor_tag.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) || 
                (name && name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1))){
                return false;
            }else{
                return true;
            }
        });

        let factorsLen = (factor && factor.factors && factor.factors.length) || 0;

        return (
            <div className="FactorList">
                <div className="toolBar">
                    <Select
                        defaultValue={selectedValidTag}
                        value={selectedValidTag}
                        style={{ width: 180 }}
                        onChange={value=>{
                            self.setState({selectedValidTag: value});
                        }}
                    >
                        <Select.Option value="ALLDATA">全部</Select.Option>
                        {
                            this.state.validTagList.map(item=>
                                <Select.Option value={item}>{item}</Select.Option>
                            )
                        }
                    </Select>
                    <Input
                        className="filterBox"
                        onChange={(e)=>this.setState({filterText: e.target.value})}
                        value={filterText}
                        placeholder="请输入筛选关键字"
                    />
                    <Button style={{marginLeft: 20}} onClick={()=>this.editJson()} type="primary">编辑JSON</Button>
                </div>
                <div className="downContent">
                    <Table
                        className="leftTable"
                        rowKey='factor_tag'
                        // id="events-table-wrapper"
                        bordered
                        // style={{ marginTop: 20 }}
                        // loading={this.state.tableLoading}
                        // height={400}
                        size="small"
                        pagination={{
                            defaultCurrent: 1,
                            current: 1,
                            pageSize: 99999,
                            total: dataList.length,
                        }}
                        scroll={{
                            y: 580
                        }}
                        dataSource={dataList}
                    >
                        <Table.Column
                            title="valid_tag"
                            key="valid_tag"
                            dataIndex="valid_tag"
                            width={150}
                        />
                        <Table.Column
                            title="位号（factor_tag）"
                            key="factor_tag"
                            dataIndex="factor_tag"
                            width={280}
                            render={(text, record)=>
                                <div className='factor_tag' onClick={()=>self.editFactor(record)}>{text}</div>
                            }
                        />
                        <Table.Column
                            title="描述"
                            key="name"
                            render={(text, record)=>this.get_name(record.factor_tag)}
                        />
                    </Table>
                    <div className="rightContent">
                        <div className="nameRow">位号：{factor.factor_tag}</div>
                        <div className="tagRow">名称：{this.get_name(factor.factor_tag)}</div>
                        <div className="limitRow">
                            <span>上限<br/>upper_limit：</span>
                            <Input
                                className="inputBox"
                                onChange={(e)=>this.set_upper_limit(factor.factor_tag, e.target.value)}
                                value={this.get_upper_limit(factor.factor_tag)}
                            />
                            <span style={{marginLeft: '30px'}}>下限<br/>lower_limit：</span>
                            <Input
                                className="inputBox"
                                onChange={(e)=>this.set_lower_limit(factor.factor_tag, e.target.value)}
                                value={this.get_lower_limit(factor.factor_tag)}
                            />
                            <Button style={{marginLeft: 20}} onClick={()=>this.addFactor()} type="primary">添加影响因子</Button>
                        </div>
                        <Table
                            key={'factors-'+factorsLen}
                            className="factorTable"
                            rowKey='tag_name'
                            // id="events-table-wrapper"
                            bordered
                            // style={{ marginTop: 20 }}
                            // loading={this.state.tableLoading}
                            // height={400}
                            size="small"
                            pagination={{
                                defaultCurrent: 1,
                                current: 1,
                                pageSize: 99999,
                                total: factorsLen,
                            }}
                            scroll={{
                                y: 580
                            }}
                            dataSource={factor.factors}
                        >
                            <Table.Column
                                title="位号（tag_name）"
                                key="tag_name"
                                dataIndex="tag_name"
                                width={280}
                                render={(text, record)=>
                                    <div className="factor_tag_block">
                                        <div className='tag_name'>{text}</div>
                                        <div className="delBtn" onClick={()=>this.delFactor(text)}>删除</div>
                                    </div>
                                }
                            />
                            <Table.Column
                                title="描述"
                                key="name"
                                render={(text, r)=>this.get_name(r.tag_name)}
                            />
                            <Table.Column
                                title="上限"
                                key="upper_limit"
                                render={(text, r)=>
                                    <Input
                                        onChange={(e)=>this.set_upper_limit(r.tag_name, e.target.value)}
                                        value={this.get_upper_limit(r.tag_name)}
                                    />
                                }
                            />
                            <Table.Column
                                title="下限"
                                key="lower_limit"
                                render={(text, r)=>
                                    <Input
                                        onChange={(e)=>this.set_lower_limit(r.tag_name, e.target.value)}
                                        value={this.get_lower_limit(r.tag_name)}
                                    />
                                }
                            />
                        </Table>
                    </div>
                </div>

                <Modal
                    title="水泥磨寻优时间选择"
                    visible={this.state.modalVisible}
                    onOk={()=>this.confirmJson()}
                    onCancel={()=>this.setState({modalVisible: false})}
                >
                    <Input.TextArea
                        className="jsonTextArea"
                        style={{width: '100%', height:300}}
                        value={this.state.jsonRawValue}
                        onChange={e=>this.setState({
                            jsonRawValue: e.target.value
                        })}
                    />
                </Modal>
            </div>
        );
    }
}
