import React from 'react'
import { Input } from 'antd'
import './chartTemplate.scss'
import LineImg from '../../../assets/chart.png'
import screenImg from '../../../assets/shaixuan.png'
import LineChart from './LineChart'
import MoreLineChart from './MoreLineChart'
const tabs = [{name: '分开观察',id: 1}, {name: '合并观察', id: 2}]
const ChartTpl = (props) => {
    const {
        checkIndex,
        changeCheckChart,
        data,
        moreChartData,
        moreTimeData,
        changeChartYaxis,
        changeScreen,
        isShow,
        screenList,
        changeScreenList
    } = props;
    return (
        <div className="chartTpl">
            <div className="chartTpl-content">
                <div className="chartTpl-content-header">
                    <img src={LineImg} width={16} height={16} style={{marginRight: 12,marginTop: 8}}/>
                    {
                        tabs.map((item,index) => {
                            return (
                                <div key={item.id} style={{marginRight: 8,cursor: "pointer"}} onClick={() => changeCheckChart(index)}>
                                    <div style={{width:64,textAlign: "center",fontSize: 14,color: "#3A3A3A",fontWeight: "bold"}}>{item.name}</div>
                                    <div style={{width:64,height: 2,backgroundColor: checkIndex === index?"#357CF7": '#B4B4B4'}}></div>
                                </div>
                            )
                        })
                    }
                    <img src={screenImg} width={16} height={16} style={{marginLeft: 36,marginTop: 8,cursor:"pointer"}} onClick={changeScreen}/>
                    <div className="screen" style={{display: isShow === true? 'block': 'none'}}>
                        <div className="screen-content">
                            {
                                screenList && screenList.map((item,index) => {
                                    return (
                                        <div className="screen-content-item" key={index + 'a'}>
                                            <div className="select" onClick={() => changeScreenList(index)}>
                                                <div className="checked" style={{display: item.checked === true? 'block': 'none'}}></div>
                                            </div>
                                            <div className="name">{item.tag_name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="chartTpl-content-list" style={{display: checkIndex === 0? 'block': 'none'}}>
                    {
                        data && data.map((item,index) => {
                            return (
                            <div className="list-item" key={index}>
                                <div className="list-item-left">
                                    <div className="title">{item.name}</div>
                                    <Input value={item.max} style={{width: 80,height: 24,marginBottom: 16}} onChange={(e) => changeChartYaxis(e.target.value,index,'max')}/>
                                    <Input value={item.min} style={{width: 80,height: 24}} onChange={(e) => changeChartYaxis(e.target.value,index,'min')}/>
                                </div>
                                <LineChart lineData={item.xData}
                                           timeData={item.yData}
                                           yMin={item.min}
                                           yMax={item.max}
                                />
                            </div>
                            )
                        })
                    }
                </div>
                <div style={{display: checkIndex === 1? 'block': 'none'}}>
                    <MoreLineChart moreChartData={moreChartData} moreTimeData={moreTimeData}/>
                </div>
            </div>
        </div>
    )
}
export default ChartTpl
