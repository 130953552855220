import RightViewer from './RightViewer.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/ExamAnalysis/actions.js';
import { post } from 'common/utils';
const moment = require('moment');

const getHisTagListData = (tag_name) => {
    let time_end = moment();
    return (dispatch, getState) => {
        const { examAnalysis: { examDetail, selectedUserValue: [resultsIndex, userValuesIndex] } } = getState();
        const result = examDetail.results[resultsIndex];

        // 计算当前用户 在当前竞赛下的在班时间段
        let times = [];
        if (!!result.user_times) {
            const { user_id } = result.user_values[userValuesIndex];
            const filterUserTimes = result.user_times
                .filter(({ user_id: userId }) => userId === user_id);
            if (filterUserTimes.length > 0) {
                times = filterUserTimes[0].times;
            };
        };
        // console.log(times);//认为按时间顺序排列
        if (times.length === 0) {
            //该人员无在班时间，显示“暂无数据”？
            return Promise.resolve({
                isSuccess: false,
                status: 3,
            });
        };

        return Promise.all(
            times
                .map(([time_begin, time_end]) => {
                    return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/get_history_data_sep_time', {
                        time_type: 'seconds',
                        tag_list: [
                            {
                                tag_name,
                                time_begin,
                                time_end,
                            },
                        ],
                    })
                        .then(retData => {
                            if (retData.data && retData.data.errcode === 0) {
                                let data_list = [];
                                const filterResults = retData.data.results
                                    .filter(({ tag_name: tagName }) => tagName === tag_name);
                                if (filterResults.length > 0) {
                                    data_list = filterResults[0].data_list;
                                };
                                return data_list;
                            } else {
                                return null;
                            };
                        });
                }),
        )
            .then(retDatas => {
                // console.log(retDatas);
                const filterRetDatas = retDatas
                    .filter(retData => retData !== null);
                if (retDatas.length === filterRetDatas.length) {
                    const { examAnalysis: { hisTag2Values } } = getState();
                    dispatch(setValues({
                        hisTag2Values: {
                            ...hisTag2Values,
                            [tag_name]: [].concat(...filterRetDatas),
                        },
                    }));
                    return {
                        isSuccess: true,
                    };
                } else {
                    return {
                        isSuccess: false,
                    };
                };
            });

        // return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/get_history_data_sep_time', {
        //     time_type: 'seconds',
        //     tag_list: [
        //         {
        //             tag_name,
        //             time_begin: moment(time_end).subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        //             time_end: time_end.format('YYYY-MM-DD HH:mm:ss'),
        //         },
        //     ],
        // })
        //     .then(retData => {
        //         console.log('getHisTagListData', retData);
        //         if (retData.data && retData.data.errcode === 0) {
        //             const newHisTag2Values = {};
        //             retData.data.results.forEach(({ tag_name, data_list }) => newHisTag2Values[tag_name] = data_list);
        //             const { examAnalysis: { hisTag2Values } } = getState();
        //             dispatch(setValues({
        //                 hisTag2Values: {
        //                     ...hisTag2Values,
        //                     ...newHisTag2Values,
        //                 },
        //             }));
        //             return {
        //                 isSuccess: true,
        //             };
        //         } else {
        //             return {
        //                 isSuccess: false,
        //             };
        //         };
        //     });
    };
};

const mapStateToProps = state => {
    const { examAnalysis } = state;
    const { rightContentWidth, selectedUserValue: [resultsIndex, userValuesIndex], examDetail, hisTag2Values, showFactorDetail, systems } = examAnalysis;
    // 由于页面的展示逻辑，此处认为必然选中，且必然有数据，若有展示异常，应从数据格式检查
    const factors = examDetail.rule_data.factors;

    // allUserValuesFactors用来得到排名第一的分数
    const allUserValues = examDetail.results[resultsIndex].user_values;
    const allUserValuesFactors = [].concat(...allUserValues.map(({ score_datas }) => score_datas));
    // console.log(allUserValuesFactors);

    const dataSource = examDetail.results[resultsIndex].user_values[userValuesIndex].score_datas
        .map(item => {
            const { factor_id } = item;
            const factor = factors
                .filter(({ _id }) => _id === factor_id)[0];
            const rank1Factor = allUserValuesFactors
                .filter(({ factor_id: factorId, rank }) => rank === 0 && factorId === factor_id)[0];
            let rank1TotalScore = null;
            let factorName = '';
            let weight = null;
            let scoreMethod = null;
            let total_score = null;
            let tag_name = null;
            if (!!factor) {
                factorName = factor.meta_name;
                weight = factor.weight;
                scoreMethod = factor.score_method;
                total_score = weight * item.rank_score / 100;
                tag_name = factor.tag_name;
                if (!!rank1Factor) {
                    rank1TotalScore = weight * rank1Factor.rank_score / 100;
                };
            };
            return {
                ...item,
                factorName,
                weight,
                scoreMethod,
                total_score,
                tag_name,
                rank1TotalScore,
                factorInfo: factor,
            };
        });

    return {
        rightContentWidth,
        dataSource,
        hisTag2Values,
        showFactorDetail,
        userValueIndexPath: `${resultsIndex}_${userValuesIndex}`,
        systems,
    };
}

const factorShowDetailOnClick = (factor_id) => {
    return (dispatch, getState) => {
        dispatch(setValues({
            showFactorDetail: factor_id === undefined ? [] : [factor_id],
        }))
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getHisTagListData: (tag_name) => dispatch(getHisTagListData(tag_name)),
        factorShowDetailOnClick: (factor_id) => dispatch(factorShowDetailOnClick(factor_id)),
    };
}

const WrapperedRightViewer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RightViewer);

export default WrapperedRightViewer;