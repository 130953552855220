import BoardState from './BoardState.js';
import { connect } from 'react-redux';

const itemsPerBoard = 4;//这里先写死每版显示4个，后期最好该称动态的
const mapStateToProps = state => {
    const { mainPageV2: { tag2Value, oneResult } } = state;
    let state_results = [];
    if (oneResult) {
        state_results = oneResult.result.state_results;
    };
    const groupedResults = state_results
        .reduce((results, item) => {
            if (results.length === 0 || results.slice(-1)[0].length === itemsPerBoard) {
                results.push([item]);
            } else {
                results.slice(-1)[0].push(item);
            }
            return results;
        }, []);
    // console.log(state_results, groupedResults);
    return {
        state_results,
        groupedResults,
        tag2Value,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

const WrapperedBoardState = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoardState);

export default WrapperedBoardState;