import React from 'react';
import { queryTimeRangeFormat } from './utils.js';
import { post } from '../../../common/utils.js';

import TITLE_ICON from './res/title_icon.png';

import './EnergyConsumptionComp.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            energyList: null,
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            this.refreshData();
        };
    }

    refreshData = () => {
        const { timeRange } = this.props;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        post(process.env.REACT_APP_SERVER_PATH + 'api/board/index_page/energy_block', {
            cpl_rule_id: 1,
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ energyList: retData.data.results });
            };
        });
    }

    render() {
        const { energyList } = this.state;

        return (
            <div className="main_page_bottom_panels_energy_consumption_comp_wrapper scroll_bar_restyle">
                <div className="comp_content_wrapper">
                    {
                        energyList &&
                        energyList
                            .map((item, index) => {
                                const { avg_value, factor_id, factor_name, max_value, unit, value_diff_pct } = item;
                                let valueText = '-';
                                if (typeof avg_value === "number") {
                                    valueText = Math.floor(avg_value * 1000) / 1000;
                                };
                                let rateText = '-';
                                let up = null;
                                if (typeof value_diff_pct === "number") {
                                    rateText = Math.floor(Math.abs(value_diff_pct) * 1000) / 10;
                                    up = value_diff_pct < 0 ? false : true;
                                };
                                let maxValueText = '-';
                                if (typeof max_value === "number") {
                                    maxValueText = Math.floor(max_value * 1000) / 1000;
                                };
                                return (
                                    <div key={factor_id} className="item_wrapper">
                                        <div className="item_title">
                                            <div className="text">{factor_name}</div>
                                            <img alt="" src={TITLE_ICON} className="title_icon" />
                                        </div>
                                        <div className="rate_text">比目标值</div>
                                        <div className="values_wrapper">
                                            <div className="value">{valueText}</div>
                                            <div className="unit">{unit}</div>
                                            <div className={"arrow" + (typeof up === 'boolean' ? (up ? " arrow_top" : " arrow_down") : "")} />
                                            <div className="rate">{rateText}%</div>
                                        </div>
                                        <div className="item_aLine" />
                                        <div className="ave_wrapper">
                                            <div className="ave_title">平均{factor_name}</div>
                                            <div className="ave_value_wrapper">{maxValueText}{unit}</div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
