import moment from 'moment';

const getYAxisMinMax = (min, max) => {
    let yAxisMin = Math.floor(min - 0.2 * (max - min));
    let yAxisMax = Math.ceil(max + 0.2 * (max - min));
    if (yAxisMin === yAxisMax) {
        yAxisMin--;
        yAxisMax++;
    };
    return [yAxisMin, yAxisMax];
}

const getFactorDetailChartOption = (hisData) => {

    const allXData = hisData
        .map(([v]) => v);
    const allYData = hisData
        .map(([_, v]) => v);

    const GridTop = 20;
    const GridBottom = 60;

    let lineColor = 'rgba(255, 188, 52, 1)';
    let lineShadowColor = 'rgba(255, 188, 52, 0.4)';

    return {
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                // console.log(params);
                return params.map(({ axisValue, marker, data }, index) => (
                    `${index === 0 ? `${moment(axisValue).format('MM-DD HH:mm:ss')}` : ''}<br/>${marker} ${Math.floor(data * 1000) / 1000}`
                )).join('');
            },
        },
        grid: {
            left: 50,
            right: 20,
            bottom: GridBottom,
            top: GridTop,
        },
        xAxis: [
            {
                // type: 'value',
                type: 'category',
                data: allXData,
                boundaryGap: false,
                // type: 'time',
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(235, 235, 235, 1)',
                    },
                },
                axisLabel: {
                    color: 'rgba(0, 0, 0, 0.44)',
                    fontSize: 12,
                    formatter: (value, index) => {

                        return moment(value).format("HH:mm:ss");
                    },
                },
                splitLine: {
                    show: false,
                },
                // min: xMin,
                // max: xMax,
            },
        ],
        yAxis: [
            {
                type: 'value',
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(235, 235, 235, 1)',
                    },
                },
                axisLabel: {
                    color: 'rgba(0, 0, 0, 0.45)',
                    fontSize: 12,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, 0.04)',
                    },
                },
                min: (value) => {
                    // console.log(value);
                    const { min, max } = value;
                    return getYAxisMinMax(min, max)[0];
                },
                max: (value) => {
                    // console.log(value);
                    const { min, max } = value;
                    return getYAxisMinMax(min, max)[1];
                },
                // min: yAxisMin,
                // max: yAxisMax,
            },
        ],
        dataZoom: [
            {
                type: 'inside',
                // start: 0,
                // end: 20
            },
            {
                show: true,
                // height: 20,
                type: 'slider',
                // top: '90%',
                xAxisIndex: 0,
                // start: 0,
                // end: 20
                labelFormatter: (index) => {
                    const value = allXData[index];
                    // console.log(value);
                    const time = moment(value);
                    const topStr = time.format('MM-DD');
                    const bottomStr = time.format('HH:mm:ss');
                    return `${topStr}\n${bottomStr}`;
                },
            }
        ],
        series: [
            {
                // name: '标准分',
                type: 'line',
                // data: seriesData,
                data: allYData,
                symbol: 'none',
                smooth: true,
                itemStyle: {
                    color: lineColor,
                },
                lineStyle: {
                    width: 1,
                    // color: 'rgba(255, 188, 52, 1)',
                    shadowColor: lineShadowColor,
                    shadowBlur: 3,
                },
            },
        ],
    };
};

export {
    getFactorDetailChartOption,
};