import * as echarts from 'echarts';

const renderLineChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        if (!data) {
            instance.clear();
            return;
        };

        const data1 = new Array(50).fill(null).map(() => Math.floor(100 * Math.random()));
        const data2 = new Array(50).fill(null).map(() => Math.floor(75 + 50 * Math.random()));

        const xData = data1.map((item, index) => index);

        instance.setOption({
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                containLabel: true,
                left: 60,
                top: 48,
                right: 60,
                bottom: 64,
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    // interval: 0,
                    fontSize: 18,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    fontSize: 18,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(5, 78, 138, 1)',
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
            },
            series: [
                Object.assign(
                    {},
                    {
                        data: data1,
                        type: 'bar',
                        barWidth: 16,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: '#0FE5F0' },
                                    { offset: 0.25, color: 'rgba(15, 229, 240, 0.92)' },
                                    { offset: 0.5, color: 'rgba(15, 229, 240, 0.71)' },
                                    { offset: 0.75, color: 'rgba(15, 229, 240, 0.38)' },
                                    { offset: 1, color: 'rgba(15, 229, 240, 0)' },
                                ],
                            ),
                            borderRadius: 2,
                        },
                    },
                ),
                Object.assign(
                    {},
                    {
                        data: data2,
                        type: 'line',
                        symbol: "none",
                        itemStyle: {
                            color: "rgba(251, 152, 49, 1)",
                        },
                    },
                ),
            ]
        });
    };
};

const colors = ["rgba(244, 90, 102, 1)", "rgba(55, 195, 215, 1)", "rgba(251, 152, 49, 1)", "rgba(64, 160, 119, 1)"];
const mockList = [
    {
        name: "燃料燃烧排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "过程排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "购入电力排放量",
        value: 9999,
        unit: "tCO₂",
    },
    {
        name: "余热发电排放量",
        value: 9999,
        unit: "tCO₂",
    },
];

export {
    renderLineChart,
    colors,
    mockList,
};
