import moment from 'moment'

/** 表头
 * @param current 当前页码
 * @param pageSize 每页条数
 */
const initTableColumns = (tags, current, pageSize) => {
  return [
    {
      title: '序号',
      dataIndex: 'rowIndex',
      align: 'center',
      width: '100px',
      render: (text, record, index) => {
        return (current - 1) * pageSize + index + 1
      }
    },
    {
      title: '时间',
      dataIndex: 'occurTime',
      align: 'center',
      width: '200px',
      sorter: {
        compare: (a, b) => moment(a.occurTime) - moment(b.occurTime)
      }
    },
    {
      title: '标签',
      dataIndex: 'classify',
      align: 'center',
      width: '100px',
      sorter: {
        compare: (a, b) => a.classify - b.classify
      },
      render: (text, record, index) => {
        return tags[text] || '--'
      }
    },
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
      width: '260px',
      sorter: {
        compare: (a, b) => (a.title > b.title ? 1 : -1)
      }
    },
    {
      title: '内容',
      dataIndex: 'contentText',
      align: 'center',
      render: (text, record, index) => {
        return text || '--'
      }
    },
    {
      title: '填写人',
      dataIndex: 'creator',
      align: 'center',
      width: '150px',
      sorter: {
        compare: (a, b) => (a.creator > b.creator ? 1 : -1)
      }
    },
    {
      title: '填写时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '200px',
      sorter: {
        compare: (a, b) => moment(a.createTime) - moment(b.createTime)
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      width: '150px',
      className: 'operation'
    }
  ]
}

export { initTableColumns }
