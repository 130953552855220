import React from 'react';
import echarts from 'echarts';

import TITLE_ICON from './res/title_icon.png';

import './EnergyConsumptionItem.scss';

const chartLeftWidth = 32;

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.renderChart();
    }

    refNodeFunc = refNode => {
        this.refNode = refNode;
    }

    renderChart = () => {
        if (!!this.refNode) {
            // render chart
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            const xAxisData = new Array(24)
                .fill(0)
                .map((_, index) => index);
            const barData = new Array(24)
                .fill(0)
                .map(() => 50 + Math.ceil(Math.random() * 100));
            const lineData = new Array(24)
                .fill(0)
                .map(() => 100 + Math.ceil(Math.random() * 100));
            let maxY = Math.ceil(Math.max(...barData, ...lineData));
            if (maxY === 0) {
                maxY = 10;
            };
            if (maxY % 2 === 1) {
                maxY++;
            };
            instance.setOption({
                grid: {
                    left: chartLeftWidth,
                    top: 10,
                    right: 0,
                    bottom: 18,
                },
                xAxis: {
                    type: 'category',
                    // show: false,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        color: 'rgba(159, 160, 186, 1)',
                    },
                    // boundaryGap: false,
                    data: xAxisData,
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    // min: yMin,
                    // max: yMax,
                },
                series: [
                    {
                        data: barData,
                        type: 'bar',
                        itemStyle: {
                            color: 'rgba(74, 155, 135, 1)',
                            barBorderRadius: 2,
                        },
                        barWidth: 4,
                        markLine: {
                            silent: true,
                            animation: false,
                            symbol: ['none', 'none'],
                            data: [0, maxY / 2, maxY]
                                .map(v => ({
                                    yAxis: v,
                                    label: {
                                        show: false,
                                    },
                                    lineStyle: {
                                        color: 'rgba(58, 63, 70, 1)',
                                        type: 'solid',
                                    },
                                }))
                                .concat(
                                    [0, maxY / 2, maxY]
                                        .map(v => ({
                                            yAxis: v,
                                            label: {
                                                show: true,
                                                position: 'start'
                                            },
                                            lineStyle: {
                                                color: 'rgba(159, 160, 186, 1)',
                                                width: 0,
                                            },
                                        }))
                                ),
                        },
                    },
                    {
                        data: lineData,
                        type: 'line',
                        itemStyle: {
                            color: 'rgba(255, 217, 78, 1)',
                        },
                        lineStyle: {
                            width: 1,
                        },
                    },
                ],
            });
        };
    }

    onCancel = () => {
        const { onClose = () => { } } = this.props;
        onClose();
    }

    render() {
        const { } = this.state;

        return (
            <div className="energy_consumption_item_wrapper">
                <div className="title_header">
                    <div className="text">耗电量</div>
                    <img alt="" src={TITLE_ICON} className="title_icon" />
                </div>
                <div className="item_body_wrapper">
                    <div className="left">
                        <div className="info_item_wrapper">
                            <div className="name_wrapper">
                                <div className="text">日累计</div>
                                <div className="icon" />
                            </div>
                            <div className="value_wrapper">
                                <div className="value">46678</div>
                                <div className="unit">吨</div>
                            </div>
                        </div>
                        <div className="info_item_wrapper">
                            <div className="name_wrapper">
                                <div className="text">小时均值</div>
                                <div className="icon" />
                            </div>
                            <div className="value_wrapper">
                                <div className="value">34.54</div>
                                <div className="unit">t/h</div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="chart_color_desc_wrapper">
                            <div className="ave_color_icon" style={{ backgroundColor: 'rgba(74, 155, 135, 1)' }} />
                            <div className="ave_color_text">电量</div>
                            <div className="standard_color_icon" style={{ backgroundColor: 'rgba(255, 217, 78, 1)' }} />
                            <div className="standard_color_text">标准值</div>
                        </div>
                        <div className="chart_wrapper" ref={this.refNodeFunc} />
                        <div className="hourly_sign_wrapper" style={{ paddingLeft: chartLeftWidth }}>
                            <div className="hourly_items">
                                {
                                    new Array(24)
                                        .fill(0)
                                        .map((_, index) => {
                                            const warning = Math.random() > 0.7;
                                            return (
                                                <div key={index} className={"sign_item" + (warning ? " sign_item_warning" : "")} />
                                            );
                                        })
                                }
                            </div>
                            <div className="value_wrapper">
                                <div className="name">PHI</div>
                                <div className="value">74.3%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
