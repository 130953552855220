import React, { useCallback, useMemo } from 'react';
import style from './index.module.scss';
import moment from 'moment';
import { DatePicker, Button } from 'antd';
import Tabs from './TabsV2';
import { withRouter } from 'react-router';
import { tabs2, parseTime, typeInfo, changePicker } from './utils.js'
import { ReloadOutlined } from '@ant-design/icons'
import { post } from 'common/utils';

const { RangePicker } = DatePicker;

const Comp = (props) => {
    const { timeInfo } = props;
    const timeText = useMemo(() => {
        return parseTime(timeInfo)
    }, [timeInfo])

    const rangeOnChange = useCallback((v) => {
        const { timeInfo, setTimeInfo = (() => { }) } = props
        const timeBegin = moment(v[0]).format('YYYY-MM-DD HH:mm:00')
        const timeEnd = moment(v[1]).format('YYYY-MM-DD HH:mm:00')
        const nextTimeInfo = Object.assign({}, timeInfo, {
            timeBegin, timeEnd
        });
        setTimeInfo(nextTimeInfo);
    }, [props]);

    const timeOnChange = useCallback((v, timeInfo) => {
        const { setTimeInfo = (() => { }) } = props
        if (typeof timeInfo !== 'object') {
            timeInfo = props.timeInfo
        }
        let datetime = moment(v).format('YYYY-MM-DD 12:00:00')
        if (timeInfo.type === 'WEEK') {
            datetime = moment(v).startOf('week').add(3, 'days').format('YYYY-MM-DD 12:00:00')
        } else if (timeInfo.type === 'MONTH') {
            datetime = moment(v).format('YYYY-MM-15 12:00:00')
        }
        post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
            .then(retData => {
                if (retData?.data?.errcode === 0) {
                    const range = retData?.data?.results?.[timeInfo.type === 'DAY' ? 'day_range' : (timeInfo.type === 'WEEK' ? 'week_range' : 'month_range')] ?? null
                    if (range) {
                        const nTimeInfo = Object.assign({}, timeInfo, {
                            timeBegin: range[0],
                            timeEnd: range[1]
                        })
                        setTimeInfo(nTimeInfo)
                    }
                }
            })
    }, [props])

    const setNewPicker = useCallback(item => {
        const { timeInfo, setTimeInfo = (() => { }) } = props
        if (timeInfo && ['DAY', 'WEEK', 'MONTH'].includes(item?.time_type)) {
            const timeTypeInfo = typeInfo?.[item?.time_type]
            timeTypeInfo.getRangeAsync(timeInfo.timeBegin)
                .then(retData => {
                    // console.log(retData)
                    const nTimeInfo = Object.assign({}, retData, { type: item.time_type })
                    setTimeInfo(nTimeInfo);
                })
        } else {
            const timeTypeInfo = typeInfo?.[item?.time_type]
            if (timeTypeInfo?.type === 'async') {
                timeTypeInfo.getRangeAsync()
                    .then(retData => {
                        // console.log(retData)
                        const nTimeInfo = Object.assign({}, retData, { type: item.time_type })
                        setTimeInfo(nTimeInfo);
                    })
            } else {
                const nTimeInfo = changePicker(timeInfo, item)
                setTimeInfo(nTimeInfo);
            }
        }
    }, [props]);

    const pickerInfo = useMemo(() => {
        if (timeInfo) {
            if (typeInfo[timeInfo.type]?.usePicker) {
                const { timeBegin, timeEnd } = timeInfo
                let val = [moment(timeBegin), moment(timeEnd)]
                if (typeInfo[timeInfo.type].pickerType === 'single') {
                    val = moment(timeBegin)
                }
                return {
                    props: typeInfo[timeInfo.type].info,
                    pickerType: typeInfo[timeInfo.type].pickerType,
                    val
                }
            }
        } else {
            return null
        }
    }, [timeInfo])

    const handleRefresh = useCallback(() => {
        const { timeInfo, setTimeInfo = (() => { }) } = props
        if (timeInfo) {
            setTimeInfo(Object.assign({}, timeInfo))
        }
    }, [props])

    return (
        <div className={style['wrapper']}>
            <Button
                type="link"
                size='small'
                icon={<ReloadOutlined />}
                onClick={handleRefresh}
            />
            {
                timeInfo && (
                    <div className="date">
                        <div className='text'>{timeText}</div>
                        {
                            pickerInfo && (
                                pickerInfo.pickerType === 'range' ? (
                                    <RangePicker
                                        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, opacity: 0 }}
                                        value={pickerInfo.val}
                                        {
                                        ...pickerInfo.props
                                        }
                                        onChange={rangeOnChange}
                                    />
                                ) : (
                                    <DatePicker
                                        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, opacity: 0 }}
                                        value={pickerInfo.val}
                                        {
                                        ...pickerInfo.props
                                        }
                                        onChange={timeOnChange}
                                    />
                                )
                            )
                        }
                    </div>
                )
            }
            {
                !!tabs2 && tabs2.length > 0 && (
                    <Tabs tabs={tabs2} selectedTab={timeInfo} onChange={setNewPicker} />
                )
            }
        </div>
    );
};

export default withRouter(Comp);
