import React from 'react';
import {
    CheckCircleFilled,
} from '@ant-design/icons';
import SETTING_ICON from './res/setting.svg';
import META_INPUT_ICON from './res/meta_input_icon.svg';
import COAL_MOUNT_ICON from './res/coal_mount_icon.svg';
import KW_ICON from './res/kw_icon.svg';
import START_ICON from './res/start_icon.svg';
import moment from 'moment';
import {
    valueFormat,
} from './utils.js';
import OptimizationConfig from './OptimizationConfig.js';
import { message, Button } from 'antd';
import { post } from 'common/utils';
import style from './BoardSecond.module.scss';

const ICONS_NAME2ICON = {
    icon_shengliao: META_INPUT_ICON,
    icon_toumei: COAL_MOUNT_ICON,
    icon_total_power: KW_ICON,
};
export default class BoardSecond extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createTimeText: '',
            drawerVisible: false,
            loading: null,
        };
    }

    componentDidMount() {
        this.updateCreateTimeText(this.props.createTime);
    }

    componentDidUpdate({ createTime: prevCreateTime }) {
        const { createTime } = this.props;
        if (prevCreateTime !== createTime) {
            this.updateCreateTimeText(createTime);
        };
    }

    componentWillUnmount() {
        this.calcInterval && clearInterval(this.calcInterval);
    }

    updateCreateTimeText = createTime => {
        this.calcInterval && clearInterval(this.calcInterval);
        if (!!createTime) {
            this.calcTimeText(createTime);
            this.calcInterval = setInterval(() => {
                this.calcTimeText(createTime);
            }, 10000);
        } else {
            this.setState({ createTime: '' });
        };
    }

    calcTimeText = createTime => {
        let diffTimeRange = moment().diff(moment(createTime), 'minutes');
        if (diffTimeRange <= 0) {
            this.setState({ createTimeText: '0分钟前' });
            return;
        };
        // console.log(createTime, diffTimeRange);
        const diffM = diffTimeRange % 60;
        diffTimeRange = (diffTimeRange - diffM) / 60;
        const diffH = diffTimeRange % 24;
        const diffD = (diffTimeRange - diffH) / 24;
        this.setState({
            createTimeText: [
                diffD === 0 ? '' : diffD + '天',
                diffH === 0 ? '' : diffH + '小时',
                diffM === 0 ? '' : diffM + '分钟',
                '前',
            ]
                .join(''),
        });
    }

    startOptimization = () => {
        this.setState({ loading: true, clickTime: moment() });
        const { sys_id } = this.props;
        if (sys_id === undefined) return;
        message.success("已开始寻优");
        // return;
        post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/optimization/start', {
            async_task: false,
            sys_id,
        }).then(retData => {
            console.log('startOptimization', retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ loading: false });
            } else {
                //netWorkError ???
                this.setState({ loading: false });
            };
        });
    }

    render() {
        const { panel_2, tag2Value, execStatusText, sys_id } = this.props;
        const { createTimeText, drawerVisible, loading } = this.state;
        return (
            <div
                className={style["board_second_wrapper"]}
            >
                <div className="board_top">
                    {
                        panel_2
                            .map(({ icon, name, tag_name, unit }, index) => (
                                <div key={index} className="board_block_wrapper">
                                    <div className="img_wrapper">
                                        <img alt="" src={ICONS_NAME2ICON[icon]} style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <div className="text_wrapper">
                                        <div className="title">{name}</div>
                                        <div className="value_wrapper">
                                            <div className="value">{valueFormat(tag2Value[tag_name], 1)}</div>
                                            <div className="unit">{unit}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                    }
                    <div className="board_block_wrapper">
                        <div className="img_wrapper">
                            <img alt="" src={START_ICON} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            onClick={this.startOptimization}
                            style={{
                                marginLeft: 10,
                                backgroundColor: '#357CF7',
                                borderColor: '#357CF7',
                            }}
                            loading={!!loading}
                        >开始寻优</Button>
                        {/* <div
                            className="start_opt_wrapper"
                            onClick={this.startOptimization}
                        >开始寻优</div> */}
                    </div>
                </div>
                <div className="board_bottom">
                    <CheckCircleFilled className="icon" />
                    <div className="result_text">{execStatusText}</div>
                    <div className="time_text">{createTimeText}</div>
                    {/* 3小时22分钟前 */}
                    <div
                        className="setting_wrapper"
                        onClick={() => this.setState({ drawerVisible: true })}
                    >
                        <img alt="" src={SETTING_ICON} className="setting_icon" />
                        <div className="setting_text">设置</div>
                    </div>
                </div>
                {
                    drawerVisible && (
                        <OptimizationConfig
                            sys_id={sys_id}
                            onClose={() => this.setState({ drawerVisible: false })}
                        />
                    )
                }
            </div>
        );
    }
}
