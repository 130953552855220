import moment from 'moment';

const staticTypes = ['date', 'week', 'month'];
const rangeType = 'range';

const parseTimeRange = (timeRange) => {
    const [type, time] = timeRange;
    let startTime = null;
    let endTime = null;
    if (type === rangeType) {
        [startTime, endTime] = time;
    } else if (staticTypes.includes(type)) {
        startTime = moment(time).startOf(type);
        endTime = moment(time).endOf(type);
    } else { };
    return [startTime, endTime];
};

const queryTimeRangeFormat = (timeRange) => {
    const [startTime, endTime] = parseTimeRange(timeRange);
    return [moment(startTime), moment(endTime).add(1, 'days')]
        .map(t => t.format("YYYY-MM-DD 00:00:00"));
};

export {
    parseTimeRange,
    queryTimeRangeFormat,
};
