import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input } from 'antd'
import style from './custom.module.scss'
const defaultFanList = [
  { label: '1', value: '' },
  { label: '2', value: '' },
  { label: '3', value: '' },
  { label: '4', value: '' },
  { label: '5', value: '' },
  { label: '6', value: '' },
  { label: '7', value: '' },
  { label: '8', value: '' },
  { label: '9', value: '' },
  { label: '10', value: '' },
  { label: '离', value: '' },
  { label: '离', value: '' }
]

/** UI模版 templateId=1 */
const Com = props => {
  const { capsuleDetailInfo, cacheData, resetAction } = props
  const [fanList, setFanList] = useState(JSON.parse(JSON.stringify(defaultFanList)))

  useImperativeHandle(props.refInstance, () => ({
    checkValidate,
    getCustomData: () => {
      return { fanList }
    }
  }))

  useEffect(() => {
    if (capsuleDetailInfo && Object.keys(capsuleDetailInfo).length > 0) {
      // 编辑
      const { jsonContent } = capsuleDetailInfo
      setFanList(jsonContent.fanList)
    } else {
      // 新建时会产生缓存数据
      const cacheInfo = cacheData ? cacheData : null
      if (cacheInfo) {
        const jsonContent = cacheInfo.json_content || {}
        if (Object.keys(jsonContent).length > 0) {
          setFanList(jsonContent.fanList)
        }
      }
    }
  }, [])

  /** 重置 */
  useEffect(() => {
    resetAction && setFanList(JSON.parse(JSON.stringify(defaultFanList)))
  }, [resetAction])

  const changeFanInfo = (newValue, fanIndex) => {
    const tempList = fanList.concat()
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (newValue || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return
    }

    tempList[fanIndex].value = formatValue
    setFanList(tempList)
  }
  /** 字符串转数字 */
  const formatNumber = (newValue, fanIndex) => {
    if (!newValue) return
    const tempList = fanList.concat()
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    tempList[fanIndex].value = formatValue
    setFanList(tempList)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    return true
  }

  return (
    <ul className={style['custom_1']}>
      <li className="column_1">
        <div>风机</div>
        <div>位置</div>
        <div>风机</div>
        <div>位置</div>
      </li>
      <li className="column_2">
        {fanList.map((item, index) => {
          return (
            <div key={index}>
              <div>{item.label}</div>
              <Input
                value={item.value}
                style={{ width: 80, borderColor: 'rgba(0, 0, 0, 0.15)', borderRadius: 4 }}
                onChange={e => changeFanInfo(e.target.value, index)}
                onBlur={e => formatNumber(e.target.value, index)}
              />
            </div>
          )
        })}
      </li>
    </ul>
  )
}

export default Com
