import LineChartEdit from 'components/GridModules/LineChart/Edit';
import LineChartWrapper from 'components/GridModules/LineChart/Comp/wrapper';
import LineBarChartEdit from 'components/GridModules/LineBarChartV2/Edit';
import LineBarChartWrapper from 'components/GridModules/LineBarChartV2/Comp/wrapper';
import ScatterChartEdit from './ScatterChart/Edit';
import ScatterChartWrapper from './ScatterChart/Comp/wrapper';
import ThreeNumsEdit from './ThreeNums/Edit/index.js'
import ThreeNumsWrapper from './ThreeNums/Comp/wrapper.js'
import LineBarChartV3Edit from 'components/GridModules/LineBarChartV3/Edit';
import LineBarChartV3Wrapper from 'components/GridModules/LineBarChartV3/Comp/wrapper';

const GridModules = [
    {
        key: "BOARD_TEST_01",
        editComp: LineChartEdit,
        comp: LineChartWrapper,
        name: '折线图',
        hide: true
    },
    {
        key: "LINEBAR_CHART_V1",
        editComp: LineBarChartEdit,
        comp: LineBarChartWrapper,
        name: '柱状图+折线图',
        hide: true
    },
    {
        key: "LINEBAR_CHART_V3",
        editComp: LineBarChartV3Edit,
        comp: LineBarChartV3Wrapper,
        name: '柱状图+折线图',
    },
    {
        key: "BOARD_TEST_02",
        editComp: ThreeNumsEdit,
        comp: ThreeNumsWrapper,
        name: '三数图'
    },
    {
        key: "SCATTER_CHART_V1",
        editComp: ScatterChartEdit,
        comp: ScatterChartWrapper,
        name: '散点(气泡)图',
        hide: true
    },
];

export {
    GridModules,
};