import React from 'react'
import './tabPanel.scss'
import DeviceTabLineChart from './DeviceTabLineChart'
import { CaretUpFilled,CaretDownFilled } from '@ant-design/icons'

const SecondTabPanel = (props) => {
    const {
        typeName,
        expanderTotal,
        expanderComparison,
        expanderCompressorRateTotal,
        expanderCompressorRateComparison,
        recoveryRateTotal,
        recoveryRateComparison,
        deviceTabChart,
        deviceTabTime
    } = props;
    return (
        <div>
            <div style={{display: typeName=== '膨胀机效率'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{expanderTotal}</span>
                        <span style={{fontSize: 12}}>%</span>
                    </div>
                    <div className="tabPanel-data-percent">{expanderComparison}%</div>
                    <div className="tabPanel-data-progress">
                        <div className="tabPanel-data-progress-num" style={{width: `${expanderTotal}`}}></div>
                        <div className="tabPanel-data-progress-text">标准</div>
                        <div className="tabPanel-data-progress-line"></div>
                        <div className="tabPanel-data-progress-number">79%</div>
                    </div>
                </div>
                {/*<div className="tabPanel-text"></div>*/}
            </div>
            <div style={{display: typeName=== '膨胀压缩机效率'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{expanderCompressorRateTotal}</span>
                        <span style={{fontSize: 12}}>%</span>
                    </div>
                    {/*<div><CaretDownFilled/></div>*/}
                    <div className="tabPanel-data-percent">{expanderCompressorRateComparison}%</div>
                    <div className="tabPanel-data-progress">
                        <div className="tabPanel-data-progress-num" style={{width: `${expanderCompressorRateTotal}`}}></div>
                        <div className="tabPanel-data-progress-text">标准</div>
                        <div className="tabPanel-data-progress-line"></div>
                        <div className="tabPanel-data-progress-number">79%</div>
                    </div>
                </div>
                {/*<div className="tabPanel-text"></div>*/}
            </div>
            <div style={{display: typeName=== '回收功率'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{recoveryRateTotal}</span>
                        <span style={{fontSize: 12}}>kW</span>
                    </div>
                    {/*<div><CaretDownFilled/></div>*/}
                    <div className="tabPanel-data-percent">{recoveryRateComparison}%</div>
                    <DeviceTabLineChart deviceTabChart={deviceTabChart} deviceTabTime={deviceTabTime}/>
                </div>
                {/*<div className="tabPanel-text"></div>*/}
            </div>
        </div>

    )
}
export default SecondTabPanel
