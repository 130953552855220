import React from 'react';

import QualityItem from './QualityItem.js';

import './QualityComp.scss';

const qualitytItems = [
    {
        name: '普通生料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
];

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="main_page_bottom_panels_quality_comp_wrapper scroll_bar_restyle">
                <div className="comp_content_wrapper">
                    {
                        qualitytItems
                            .map((item, index) => {
                                return (
                                    <div key={index} className="item_position_wrapper">
                                        <QualityItem item={item} />
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
