import ChartBlock from './ChartBlock.js';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    // console.log('ownProps', ownProps);
    const { trendMeta: { tag_name } } = ownProps;
    const { sampleMaintenance = {} } = state;
    const { hisTag2Value, contentItemWidth } = sampleMaintenance;
    return {
        hisTag2Value,
        contentItemWidth,
        chartValues: hisTag2Value[tag_name],
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
}

const WrapperedChartBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChartBlock);

export default WrapperedChartBlock;