import React from 'react'
import { Checkbox, Tree, Button, Modal, Input, message, Switch, Spin } from 'antd';
import { get, post, patch, reqDelete } from 'common/utils.js';
import { PlusOutlined } from '@ant-design/icons';
import DELETE_ICON from './res/delete.svg';
import style from './index.module.scss';
import { getCheckInfo } from './utils';

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleList: [],
            pageList: [],
            flatPageList: [],
            isAddingRole: false,
            modifyRoleStatus: false,
            edittingName: '',
            selectedRole: null,
            selectedKeys: [],
            authChangeLock: {},
            modalVisible: false,
            currentUserId: null,
            initSelectedKeys: [],
            loading: false,
        };
    }
    componentDidMount() {
        this.getInfoAndConfig();
    }
    getInfoAndConfig = () => {
        this.setState({ loading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/list_info')
            .then(retData => {
                // console.log(retData);
                this.setState({ loading: false });
                if (retData.data && retData.data.errcode === 0) {
                    const { pages = [], roles: roleList = [] } = retData.data.results || {};
                    // const flatPageList = [];
                    // pages.forEach(page => {
                    //     flatPageList.push(...(page.children || []), page);
                    // });
                    const flatPageList = (pages ?? [])
                        .concat(
                            ...(pages ?? [])
                                .map(({ children }) => children ?? []),
                        )
                    const sortedFlatPageList = flatPageList
                        .sort(({ children: children_1 = [] }, { children: children_2 = [] }) => children_1.length - children_2.length);
                    // initSelectedKeys应该是第一个叶子节点？
                    const firstChild = []
                        .concat(
                            ...(pages ?? [])
                                .map(({ children }) => children ?? []),
                        )[0] ?? null;
                    this.setState({
                        pageList: pages
                            .map(page => ({
                                ...page,
                                title: page.name,
                                key: page.id,
                                children: page.children
                                    .map(child => ({
                                        ...child,
                                        title: child.name,
                                        key: child.id,
                                    }))
                            })),
                        flatPageList: sortedFlatPageList,
                        initSelectedKeys: !!firstChild ? [firstChild.id] : [],
                        roleList,
                    });
                }
            })
    }
    updatePermission = (operation, role_id, permission_id) => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/permissions/update_one', {
            role_id, permission_id, operation,
        })
            .then(retData => {
                console.log(retData);
                if (retData.data && retData.data.errcode === 0) return true;
                return false;
            })
    }
    addRoleBtn = () => {
        this.setState({ isAddingRole: true, edittingName: '', selectedRole: null }, () => {
            this.addRoleInput && this.addRoleInput.focus && this.addRoleInput.focus();
        });
    }
    createRole = name => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/user/roles', {
            name,
            permission_ids: [],
        }).then((retData) => {
            if (retData && retData.data) {
                if (retData.data.id > 0) {
                    message.success("角色创建成功");
                    return {
                        id: retData.data.id,
                        status: retData.data.status,
                    };
                } else {
                    message.error("角色创建失败");
                    return null;
                }
            } else {
                message.error("角色创建失败,请检测网络等问题");
                return null;
            }
        });
    }
    updateRole = (roleId, permission_ids) => {
        return patch(process.env.REACT_APP_SERVER_PATH + 'api/user/roles/' + roleId, {
            permission_ids,
        }).then((retData) => {
            if (retData && retData.data) {
                if (retData.data.id > 0) {
                    message.success("权限修改成功");
                    return true;
                } else {
                    message.error("权限修改失败");
                    return false;
                }
            } else {
                message.error("权限修改失败,请检测网络等问题");
                return false;
            }
        });
    }
    addNewRoleEnter = () => {
        const { edittingName, roleList } = this.state;
        this.setState({ isAddingRole: false });
        if (edittingName) {
            this.createRole(edittingName)
                .then((ret) => {
                    if (ret !== null) {
                        const { id, status } = ret;
                        roleList.push({
                            id,
                            name: edittingName,
                            status,
                            permissions: [],
                        });
                        this.setState({ roleList });
                    }
                })
        };
    }
    deleteRoleBtn = (id, e) => {
        e.stopPropagation();
        this.setState({
            currentUserId: id,
            modalVisible: true,
        });
    }
    deleteRole = () => {
        const { currentUserId, roleList, selectedRole } = this.state;
        reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/user/roles/' + currentUserId, {})
            .then((retData) => {
                this.setState({
                    modalVisible: false,
                })
                if (retData.status === 204) {
                    message.info("角色删除成功");
                    this.setState({
                        roleList: roleList.filter(({ id: _id }) => _id !== currentUserId),
                        currentUserId: null,
                        ...(selectedRole === currentUserId ?
                            {
                                selectedRole: null,
                            } : {}),
                    })
                } else {
                    message.info("角色删除失败");
                }
            });
    }

    changeRoleStatus = (roleItem, status) => {
        let self = this;
        patch(process.env.REACT_APP_SERVER_PATH + 'api/user/roles/' + roleItem.id, {
            status: status === true ? 1 : 0,
        }).then((retData) => {
            if (retData && retData.data) {
                if (retData.data.id > 0) {
                    roleItem.status = (status === true ? 1 : 0);
                    self.forceUpdate();
                    message.success("权限修改成功", 1);
                    return true;
                } else {
                    message.error("权限修改失败", 1);
                    return false;
                }
            } else {
                message.error("权限修改失败，网络或服务器异常");
                return false;
            }
        });
    }

    selectRoleOnClick = id => {
        const { selectedRole, isAddingRole, initSelectedKeys } = this.state;
        if (isAddingRole || selectedRole === id) return;
        this.setState({
            selectedRole: id,
            selectedKeys: initSelectedKeys,
        }, () => {
            this.treeDataContent && (this.treeDataContent.scrollTop !== undefined) && (this.treeDataContent.scrollTop = 0);
        });
    }
    authCheckOnChange = (checked, id) => {
        const { roleList, selectedRole, authChangeLock } = this.state;
        if (selectedRole === null) {
            message.error("尚未选择角色");
            return;
        }
        if (authChangeLock[`${selectedRole}`] === true) {
            message.error("权限尚未修改完成，请稍后再次尝试");
            return;
        }
        authChangeLock[`${selectedRole}`] = true;
        const { permissions = [] } = roleList.filter(({ id: roleId }) => roleId === selectedRole)[0] || {};
        permissions.splice(permissions.indexOf(id), ...checked ? [0, id] : [1]);
        this.updatePermission(checked ? 'ADD' : 'DELETE', selectedRole, id)
            .then(isSuccess => {
                if (isSuccess) {
                    message.success("权限修改成功");
                    authChangeLock[`${selectedRole}`] = false;
                } else {
                    message.error("权限修改失败");
                    permissions.splice(permissions.indexOf(id), ...!checked ? [0, id] : [1]);
                    this.setState({ roleList }, () => {
                        authChangeLock[`${selectedRole}`] = false;
                    });
                };
            });
        this.setState({ roleList });
    }
    checkedKeysOnChange = (checkedList) => {
        return (checkedKeys, e) => {
            const node = e?.node ?? null;
            if (!!node) {
                // console.log(checkedList, node);
                // 判断node next status
                // 全选或全不选
                const { selectedRole, authChangeLock, roleList, pageList } = this.state;
                if (authChangeLock[`${selectedRole}`] === true) {
                    message.error("权限尚未修改完成，请稍后再次尝试");
                    return;
                };

                const curRolePermissions = (roleList ?? []).filter(({ id: roleId }) => roleId === selectedRole)[0]?.permissions ?? [];

                let allNodePermissionIds = (node?.permissions ?? []).map(({ id }) => id);
                if ((pageList ?? []).map(({ id }) => id).includes(node.id)) {
                    // 说明是一级节点，需要加上children的permissions
                    allNodePermissionIds = allNodePermissionIds
                        .concat(
                            ...(node?.children ?? [])
                                .map(({ permissions }) => (permissions ?? []).map(({ id }) => id)),
                        );
                };

                if (allNodePermissionIds.length > 0) {
                    let newIds = [];
                    if (checkedList.includes(node.id)) {
                        // node下的全不选
                        // 删allNodePermissionIds
                        newIds = curRolePermissions
                            .filter((id) => !allNodePermissionIds.includes(id));
                    } else {
                        // node下的全选
                        // 增allNodePermissionIds
                        newIds = curRolePermissions.concat(allNodePermissionIds);
                    };
                    newIds = [...new Set(newIds)];
                    // console.log(curRolePermissions, allNodePermissionIds, newIds);
                    // return;
                    authChangeLock[`${selectedRole}`] = true;
                    this.updateRole(selectedRole, newIds)
                        .then(isSuccess => {
                            if (isSuccess) {
                                authChangeLock[`${selectedRole}`] = false;
                            } else {
                                this.setState({
                                    roleList,
                                }, () => {
                                    authChangeLock[`${selectedRole}`] = false;
                                });
                            }
                        });
                    const successRuleList = roleList
                        .map((item) => {
                            if (item?.id === selectedRole) {
                                return Object.assign({}, item, { permissions: newIds });
                            };
                            return item;
                        });
                    this.setState({
                        roleList: successRuleList,
                    });
                } else {
                    message.warning("该页面尚未配置具体权限，无法修改!");
                };

            };
        };
    }
    render() {
        const { roleList, pageList, isAddingRole, edittingName, selectedRole, selectedKeys, flatPageList, modalVisible, loading } = this.state;
        let authList = [];
        if (selectedRole && selectedKeys.length > 0) {
            authList =
                (flatPageList
                    .filter(page => page.id === selectedKeys[0])[0] || {})
                    .permissions || [];
        };

        const [checkedList, halfCheckedList] = getCheckInfo((roleList ?? []).filter(({ id }) => id === selectedRole)[0]?.permissions ?? null, pageList);
        // console.log(checkedList, halfCheckedList);

        return (
            <div className={style["RoleMngSetting"]}>
                <div className="bottom_content_wrapper">
                    <div>
                        <div className="content_wrapper_header">
                            <Button type="primary" style={{ marginLeft: 'auto', backgroundColor: '#357CF7' }} onClick={this.addRoleBtn}><PlusOutlined />添加</Button>
                        </div>
                        <div className="content_wrapper_content">
                            <div className="block_wrapper">
                                <div className="block_header">角色名称</div>
                                <div className="block_content">
                                    {
                                        roleList.map(({ id, name, status }, index) => (
                                            <div
                                                key={index}
                                                className={"role_list_row" + (selectedRole === id ? ' role_list_row_selected' : '')}
                                                onClick={() => this.selectRoleOnClick(id)}
                                            >
                                                <div className="name" style={{ color: (status === 1 ? '#000000a6' : '#A5A5A5a6') }}>{name}</div>
                                                {
                                                    //Hard code
                                                    [1, 2].indexOf(id) === -1 &&
                                                    <div className="opt_box">
                                                        <Switch
                                                            className="on_off_switch"
                                                            size="default"
                                                            checkedChildren="已启用"
                                                            unCheckedChildren="已禁用"
                                                            defaultChecked={status === 1}
                                                            checked={status === 1}
                                                            onChange={(_status) => this.changeRoleStatus(roleList[index], _status)}
                                                        />
                                                        {!isAddingRole && (
                                                            <div
                                                                className="delete"
                                                                onClick={e => this.deleteRoleBtn(id, e)}
                                                            >
                                                                <img
                                                                    src={DELETE_ICON}
                                                                    alt=""
                                                                    width="15"
                                                                    height="17"
                                                                    style={{ display: 'block', marginRight: 2 }}
                                                                />
                                                                删除
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        isAddingRole && (
                                            <div className="role_list_row role_list_row_selected role_list_row_selected_input">
                                                <Input
                                                    placeholder="请输入角色名称"
                                                    style={{ width: '40%', borderWidth: 0 }}
                                                    value={edittingName}
                                                    onChange={e => this.setState({ edittingName: e.target.value })}
                                                    onPressEnter={this.addNewRoleEnter}
                                                    ref={e => this.addRoleInput = e}
                                                />
                                                <div className="save" onClick={this.addNewRoleEnter}>保存</div>
                                                <div className="delete_2" onClick={() => this.setState({ isAddingRole: false })}><img src={DELETE_ICON} alt="" width="15" height="17" style={{ display: 'block', marginRight: 2 }} />删除</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="middle_block_item" />
                            <div className="block_wrapper">
                                <div className="block_header">页面名称</div>
                                <div className="block_content" ref={e => this.treeDataContent = e}>
                                    {
                                        typeof selectedRole === 'number' && !isNaN(selectedRole) && pageList.length > 0 && (
                                            <Tree
                                                checkable
                                                defaultExpandAll
                                                blockNode
                                                checkedKeys={{ checked: checkedList, halfChecked: halfCheckedList }}
                                                checkStrictly={true}
                                                onCheck={this.checkedKeysOnChange(checkedList, halfCheckedList)}
                                                selectedKeys={selectedKeys}
                                                onSelect={selectedKeys => this.setState({ selectedKeys })}
                                                treeData={pageList}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="middle_block_item" />
                            <div className="block_wrapper">
                                <div className="block_header">操作权限</div>
                                <div className="block_content">
                                    {
                                        authList
                                            .map(({ id, name }, index) => (
                                                <div key={index} className="auth_list_row">
                                                    <Checkbox
                                                        onChange={e => this.authCheckOnChange(e.target.checked, id)}
                                                        checked={((roleList.filter(({ id: roleId }) => roleId === selectedRole)[0] || {}).permissions || []).indexOf(id) > -1}
                                                    />
                                                    <div className="name">{name}</div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={modalVisible}
                    title="角色删除"
                    onOk={this.deleteRole}
                    onCancel={() =>
                        this.setState({
                            modalVisible: false
                        })
                    }>
                    <span>你确定要删除该角色吗？</span>
                </Modal>
                {
                    loading === true && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
        )
    }
}
