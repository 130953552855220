import React from 'react';

const ColoredBar = (props) => {
    const { style = {} } = props;
    return (
        <th style={Object.assign({
            position: 'sticky', top: 0, zIndex: 100, backgroundColor: '#191F27',
            // paddingTop: 8,
            fontWeight: 'normal',
        }, style)}>
            {props.children}
        </th>
    );
};

export default ColoredBar;
