import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { get } from 'common/utils'
import {
  useClassifyTagList,
  useCommonlyTemplateSearch,
  useLatelyTemplateSearch
} from '../../../../../hooks'
import style from './index.module.scss'
const { Option } = Select
const tabList = [
  { key: '1', title: '最常用', icon: 'commonly' },
  { key: '2', title: '最近使用', icon: 'lately' },
  { key: '3', title: '分类选择', icon: 'classify' }
]
/** 模版选择器 */
const Com = props => {
  const { tplParams, classifyTags, handleSetTemplate } = props
  const { tabIndex } = tplParams
  const [searchTplList, setSearchTplList] = useState([])
  const [firstLevelTabIndex, setFirstLevelTabIndex] = useState(0)
  const [frequentlyUsedList, refreshFrequentlyUsedList] = useCommonlyTemplateSearch([]) // 最常用的
  const [recentlyUsedList, refreshRecentlyUsedList] = useLatelyTemplateSearch([]) // 最近使用的
  const [tplList, setTplList] = useState([])
  const [secondLevelTabIndex, setSecondLevelTabIndex] = useState(0)

  useEffect(() => {
    handleTemplateSearch('')
    tabIndex >= 0 && setFirstLevelTabIndex(tabIndex)
  }, [])

  useEffect(() => {
    tabIndex === 2 &&
      classifyTags &&
      Object.keys(classifyTags).length > 0 &&
      getTplListByClassifyId(Object.keys(classifyTags)[0], 0, true)
  }, [classifyTags])

  useEffect(() => {
    setTplList(frequentlyUsedList)
  }, [frequentlyUsedList])

  /** 搜索模版 */
  const handleTemplateSearch = value => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/templateBlurrySearch', {
      title: value
    })
      .then(res => {
        !!res.data && setSearchTplList(res.data.results || [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  /** 获取模版分类 */
  const getTplListByClassifyId = (id, index, ignoreSameIndex = false) => {
    if (!ignoreSameIndex && secondLevelTabIndex === index) return
    setSecondLevelTabIndex(index)
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/templatesByLabelSearch', {
      label: id
    })
      .then(res => {
        !!res.data && setTplList(res.data.results || [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  /** 变更选中模版 */
  const changeTemplateSearch = index => {
    typeof handleSetTemplate === 'function' &&
      handleSetTemplate(
        searchTplList[index].id,
        searchTplList[index].title,
        searchTplList[index].sn
      )
  }

  /** 切换 最常用/最近/分类 */
  const changeTab = index => {
    setFirstLevelTabIndex(index)
    if (index === 0) {
      setTplList(frequentlyUsedList)
    } else if (index === 1) {
      setTplList(recentlyUsedList)
    } else if (index === 2) {
      // TODO: 获取第一个分类模版列表
      classifyTags &&
        Object.keys(classifyTags).length > 0 &&
        getTplListByClassifyId(Object.keys(classifyTags)[0], 0, true)
    }
  }

  return (
    <div className={style['tplSelectorWrapper']}>
      <div className="search-container">
        <Select
          showSearch
          className="search-select"
          onSearch={handleTemplateSearch}
          style={{ width: 386 }}
          placeholder="输入文字搜索胶囊模版"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onChange={changeTemplateSearch}
        >
          {searchTplList &&
            searchTplList.map((item, index) => {
              return (
                <Option value={index} key={item.id}>
                  {item.title}
                </Option>
              )
            })}
        </Select>
        <i className="search-icon" />
      </div>
      <ul className="tabs-container">
        {tabList.map(({ key, title, icon }, index) => {
          return (
            <li
              key={key}
              className={`${firstLevelTabIndex === index ? 'active' : ''}`}
              onClick={() => changeTab(index)}
            >
              <i className={icon} />
              {title}
            </li>
          )
        })}
      </ul>
      <div className="tab-panel">
        {firstLevelTabIndex === 2 && (
          <ul className="classify-tag-list">
            {classifyTags &&
              Object.keys(classifyTags).map((classifyKey, tagIndex) => {
                return (
                  <li
                    key={classifyKey}
                    className={`${secondLevelTabIndex === tagIndex ? 'active' : ''}`}
                    onClick={() => getTplListByClassifyId(classifyKey, tagIndex)}
                  >
                    {classifyTags[classifyKey]}
                  </li>
                )
              })}
          </ul>
        )}
        <ul className="tpl-wrapper">
          {tplList.map((tplItem, index) => {
            if (firstLevelTabIndex !== 2 && index > 5) return <></>
            return (
              <li
                key={tplItem.id}
                className="tpl-item"
                onClick={() =>
                  typeof handleSetTemplate === 'function' &&
                  handleSetTemplate(tplItem.id, tplItem.title, tplItem.sn)
                }
              >
                {tplItem.title || '暂无'}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Com
