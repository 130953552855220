import React from 'react';
import { Select } from 'antd';
import './DetailHeaderBlock.scss';
import { SCORE_METHODS } from '../config.js';
const Option = Select.Option;

export default class DetailHeaderBlock extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            systems = [], systemSelected, systemOnChange = () => { },
            dataMetaList = [], dataMeta, dataMetaOnChange = () => { }, dataMetaOnSearch = () => { },
            scoreMethodSelected, scoreMethodOnChange = () => { }
        } = this.props;
        return (
            <div className="detail_header_block_wrapper">
                <div className="title">所属系统：</div>
                <Select
                    style={{ width: 144, marginRight: 24 }}
                    value={systemSelected}
                    onChange={systemOnChange}
                >
                    {
                        systems.map(({ id, name }) =>
                            <Option key={id}>{name}</Option>
                        )
                    }
                </Select>
                <div className="title" style={{ marginLeft: 'auto' }}>点位名称：</div>
                <Select
                    style={{ width: 344, marginRight: 24 }}
                    value={dataMeta}
                    onChange={dataMetaOnChange}
                    showSearch
                    onSearch={dataMetaOnSearch}
                    filterOption={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                >
                    {
                        dataMetaList.map(({ id, name, tag_name }) =>
                            <Option key={id}>{name} {tag_name}</Option>
                        )
                    }
                </Select>
                <div className="title" style={{ marginLeft: 'auto' }}>统计方法：</div>
                <Select
                    style={{ width: 144, marginRight: 24 }}
                    value={scoreMethodSelected}
                    onChange={scoreMethodOnChange}
                >
                    {
                        SCORE_METHODS.map(({ id, name }) =>
                            <Option key={id}>{name}</Option>
                        )
                    }
                </Select>
            </div>
        );
    }
}