const parseOrgsData = (orgs) => {
    const nextOrgs = orgs.map((org, index) => {
        const { id, children } = org;
        const key = typeof id === "number" ? `id-${id}` : `index-${index}`;
        return Object.assign({}, org, {
            key,
            children: !!children ? parseOrgsData(children) : [],
        });
    });
    return nextOrgs;
};

export {
    parseOrgsData,
};
