import ICON_V from './InfoCard/res/v.png';
import ICON_ELECT from './InfoCard/res/elect.png';
const infoCard_1 = {
    name: '电压',
    img:ICON_V,
    left: [
        {
            name: '相电压',
            cards: [
                {
                    name: '平均',
                    // isAvg: true,
                    extd_tag: '_Vz',
                    unit: 'v',
                    abnormal: false,
                    highlight: true,
                },
                {
                    name: 'Ua',
                    extd_tag: '_Va',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Ub',
                    extd_tag: '_Vb',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Uc',
                    extd_tag: '_Vc',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
            ],
        },
        {
            name: '线电压',
            cards: [
                {
                    name: '平均',
                    isAvg: true,
                    unit: 'v',
                    abnormal: false,
                    highlight: true,
                },
                {
                    name: 'Uab',
                    extd_tag: '_Vab',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Ubc',
                    extd_tag: '_Vbc',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Uac',
                    extd_tag: '_Vca',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
            ],
        },
    ],
    right: {
        name: '三相不平衡度',
        extd_tag: '_Vzzub',
        unit: '%',
    },
};

const infoCard_1_2 = {
    name: '电压',
    img: ICON_V,
    left: [
        {
            name: '线电压',
            cards: [
                {
                    name: '平均',
                    // isAvg: true,
                    extd_tag: '_Vzz',
                    unit: 'v',
                    abnormal: false,
                    highlight: true,
                },
                {
                    name: 'Uab',
                    extd_tag: '_Vab',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Ubc',
                    extd_tag: '_Vbc',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Uac',
                    extd_tag: '_Vca',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
            ],
        },
        {
            name: '相电压',
            cards: [
                {
                    name: '平均',
                    isAvg: true,
                    unit: 'v',
                    abnormal: false,
                    highlight: true,
                },
                {
                    name: 'Ua',
                    extd_tag: '_Va',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Ub',
                    extd_tag: '_Vb',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Uc',
                    extd_tag: '_Vc',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                },
            ],
        },
    ],
    right: {
        name: '三相不平衡度',
        extd_tag: '_Vzzub',
        unit: '%',
    },
};

const infoCard_2 = {
    name: '电流',
    img:ICON_ELECT,
    left: [
        {
            name: '电流',
            cards: [
                {
                    name: '平均',
                    isAvg: true,
                    unit: 'A',
                    abnormal: false,
                    highlight: true,
                },
                {
                    name: 'La',
                    extd_tag: '_Ia',
                    unit: 'A',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Lb',
                    extd_tag: '_Ib',
                    unit: 'A',
                    abnormal: false,
                    highlight: false,
                },
                {
                    name: 'Lc',
                    extd_tag: '_Ic',
                    unit: 'A',
                    abnormal: false,
                    highlight: false,
                },
            ],
        },
    ],
    right: {
        name: '三相不平衡度',
        extd_tag: '_Iub',
        unit: '%',
    },
};

export {
    infoCard_1, infoCard_2,
    infoCard_1_2,
};
