import React from 'react';
import { Select } from 'antd';
import echarts from 'echarts';

import ANALYSIS_ICON from './res/analysis_icon.png';
import TITLE_ICON from './res/title_left_icon.png';

import './ProductElectricalAnalysisComp.scss';

const { Option } = Select;

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rowDatas: new Array(10).fill(0)
                .map(() => {
                    const nameYellow = Math.random() > 0.8;
                    const rateUp = Math.random() > 0.8;
                    const redBar = Math.random() > 0.8;
                    const rate = Math.ceil(70 + Math.random() * 30);
                    return {
                        nameYellow,
                        rateUp,
                        redBar,
                        rate,
                        name: '普通生料',
                    };
                }),
            selectedRowIndex: null,
        };
    }

    componentDidMount() {
        this.trOnclick(0);
    }

    trOnclick = (index) => {
        this.setState({ selectedRowIndex: index }, () => {
            console.log(index);
            this.renderChart();
        });
    }

    renderChart = () => {
        if (!!this.refNode) {
            // render chart
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            const xAxisData = new Array(34)
                .fill(0)
                .map((_, index) => index);
            const lineData = new Array(34)
                .fill(0)
                .map(() => 50 + Math.ceil(Math.random() * 100));
            let maxY = Math.ceil(Math.max(...lineData));
            if (maxY === 0) {
                maxY = 10;
            };
            if (maxY % 2 === 1) {
                maxY++;
            };
            instance.setOption({
                grid: {
                    left: 32,
                    top: 10,
                    right: 24,
                    bottom: 18,
                },
                xAxis: {
                    type: 'category',
                    // show: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(58, 63, 70, 1)',
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    // boundaryGap: false,
                    data: xAxisData,
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    // show: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(58, 63, 70, 1)',
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    // min: yMin,
                    // max: yMax,
                },
                series: [
                    {
                        data: lineData,
                        type: 'line',
                        itemStyle: {
                            color: 'rgba(64, 161, 225, 1)',
                            // barBorderRadius: 2,
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: 'rgba(64, 161, 225, 1)' },
                                    { offset: 1, color: 'transparent' },
                                ],
                            ),
                        },
                        smooth: true,
                        // barWidth: 4,
                        // markLine: {
                        //     silent: true,
                        //     animation: false,
                        //     symbol: ['none', 'none'],
                        //     data: [0, maxY / 2, maxY]
                        //         .map(v => ({
                        //             yAxis: v,
                        //             label: {
                        //                 show: false,
                        //             },
                        //             lineStyle: {
                        //                 color: 'rgba(58, 63, 70, 1)',
                        //                 type: 'solid',
                        //             },
                        //         }))
                        //         .concat(
                        //             [0, maxY / 2, maxY]
                        //                 .map(v => ({
                        //                     yAxis: v,
                        //                     label: {
                        //                         show: true,
                        //                         position: 'start'
                        //                     },
                        //                     lineStyle: {
                        //                         color: 'rgba(159, 160, 186, 1)',
                        //                         width: 0,
                        //                     },
                        //                 }))
                        //         ),
                        // },
                    },
                ],
            });
        };
    }

    render() {
        const { rowDatas, selectedRowIndex } = this.state;
        const { title = "产量分析" } = this.props;

        return (

            <div className="product_electrical_analysis_comp_wrapper">
                <div className="item_header_wrapper">
                    <img alt="" src={ANALYSIS_ICON} className="item_header_icon" />
                    <div className="title">{title}</div>
                    <div className="sub_title">【 2020年12月20日 】</div>
                </div>
                <div className="item_content_wrapper">
                    <div className="inner_content_wrapper_analysis">
                        <div className={"left" + (typeof selectedRowIndex === 'number' ? " has_selected_left" : "")}>
                            <div className="title_wrapper">
                                <img alt="" src={TITLE_ICON} className="icon" />
                                <div className="text">基准时段：过去</div>
                                <Select defaultValue="lucy" style={{ width: 160, marginLeft: 8 }}>
                                    <Option value="jack">一个月</Option>
                                    <Option value="lucy">一周</Option>
                                    <Option value="Yiminghe">六个月</Option>
                                </Select>
                            </div>
                            <div className="table_wrapper">
                                <table className="">
                                    <thead>
                                        <tr>
                                            <th>产品类型</th>
                                            <th>当前时段(kwh/t)</th>
                                            <th>基准时段(kwh/t)</th>
                                            <th>均值环比(%)</th>
                                            <th>均值排名(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rowDatas
                                                .map((item, index) => {
                                                    const { nameYellow, rateUp, redBar, rate } = item;
                                                    const nameList = ['普通生料', '特种生料', 'M325水泥', 'PO425水泥', '特种水泥', '普通熟料', '特种熟料', '煤粉制备'];
                                                    const currList = [50410, 40398, 25226, 37740, 37750];
                                                    const standardList = [52333, 39812, 24870, 38280, 39141];
                                                    const rate1 = Math.floor((Math.random() / 10 + 0.95) * 100) / 100;
                                                    return (
                                                        <tr key={index} onClick={() => { this.trOnclick(index) }} className={selectedRowIndex === index ? " has_selected_tr" : ""}>
                                                            <td>
                                                                <div className={"name_block" + (nameYellow ? " name_block_yellow" : "")}>{nameList[index % nameList.length]}</div>
                                                            </td>
                                                            <td>{currList[index % currList.length]}</td>
                                                            <td>{standardList[index % standardList.length]}</td>
                                                            <td>
                                                                <div className={"rate_block" + (rateUp ? " rateUp" : " rateDown")}>
                                                                    <div className="sign" />
                                                                    <div className="text">{rate1}%</div>
                                                                </div>
                                                            </td>
                                                            <td className="bar_td">
                                                                <div className={"bar_block" + (redBar ? " redBar" : " greenBar")}>
                                                                    <div className="bar_content">
                                                                        <div className="bar" style={{ width: `${rate}%` }} />
                                                                    </div>
                                                                    <div className="text">{rate}%</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            typeof selectedRowIndex === 'number' && (
                                <div
                                    className="right"
                                // key={selectedRowIndex}
                                >
                                    <div className="title_wrapper">
                                        <div className="title_name">产能分布：</div>
                                        <div className={"title_value" + (rowDatas[selectedRowIndex].nameYellow ? " title_value_yellow" : "")}>{rowDatas[selectedRowIndex].name}</div>
                                    </div>
                                    <div className="line_sign_wrapper">
                                        <div className="line_1" style={{ borderTopColor: 'rgba(64, 161, 225, 1)' }} />
                                        <div className="line_1_text">基准期电单耗</div>
                                        <div className="line_2" style={{ borderTopColor: 'rgba(255, 217, 78, 1)' }} />
                                        <div className="line_2_text">当前电单耗均值</div>
                                    </div>
                                    <div className="chart_wrapper" ref={refNode => { this.refNode = refNode }} />
                                    <div className="bottom_title">产能分析:</div>
                                    <div className="bottom_text_wrapper">
                                        <div className={"name_text" + (rowDatas[selectedRowIndex].nameYellow ? " name_value_yellow" : "")}>{rowDatas[selectedRowIndex].name}</div>
                                        <div className="content_text">
                                            <span>在统计期间的平均用电单耗为 </span>
                                            <span className="highlight">220kwh/t</span>
                                            <span>，高于基准期 </span>
                                            <span className="highlight">67%</span>
                                            <span> 的工况</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        );
    }
}
