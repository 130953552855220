import React, { useEffect, useMemo, useRef } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts~5';
import { significantNum } from 'common/utils';
import moment from 'moment';

const renderChart = (refNode, data_list, lower_limit, upper_limit) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        let parsed_standard = "--";
        if (typeof lower_limit === 'number' && typeof upper_limit === 'number') {
            parsed_standard = significantNum(lower_limit, 4) + "~" + significantNum(upper_limit, 4);
        } else {
            if (typeof lower_limit === 'number') {
                parsed_standard = significantNum(lower_limit, 4);
            } else if (typeof upper_limit === 'number') {
                parsed_standard = significantNum(upper_limit, 4);
            };
        };
        const yData = data_list
            .map((v) => {
                const [, value] = v;
                let isGood = null;
                if (typeof value === 'number') {
                    let lower_good = null;
                    if (typeof lower_limit === 'number') {
                        if (lower_limit <= value) {
                            lower_good = true;
                        } else {
                            lower_good = false;
                        };
                    };
                    let upper_good = null;
                    if (typeof upper_limit === 'number') {
                        if (upper_limit >= value) {
                            upper_good = true;
                        } else {
                            upper_good = false;
                        };
                    };
                    if (lower_good === false || upper_good === false) {
                        isGood = false;
                    } else {
                        if (lower_good === true || upper_good === true) {
                            isGood = true;
                        };
                    };
                };
                return Object.assign({
                    value,
                    ...(
                        typeof isGood === 'boolean' ? {
                            itemStyle: {
                                color: isGood ? '#3AA372' : '#FB6060',
                            },
                        } : {}
                    ),
                });
            });
        const xData = data_list
            .map((v) => v[0]);

        const allValues = yData
            .map(({ value }) => value)
            .concat([upper_limit, lower_limit])
            .filter((v) => typeof v === 'number');
        let y_min = 0;
        let y_max = 1;
        if (allValues.length > 0) {
            const min = Math.min(...allValues);
            const max = Math.max(...allValues);
            const delta = max - min + 1;
            // 使delta>0
            y_min = min - delta / 10;
            y_max = max + delta / 10;
            if (min >= 0 && y_min < 0) {
                y_min = 0;
            };
            if (max <= 0 && y_max > 0) {
                y_max = 0;
            };
        };

        instance.setOption({
            grid: {
                left: 3,
                top: 3,
                right: 3,
                bottom: 3,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [{ axisValueLabel, data: { value }, marker }] = params;
                    const parsed_value = typeof value === 'number' ? Math.floor(value * 1000) / 1000 : "--";
                    return `${moment(axisValueLabel).format("YYYY-MM-DD")}<br />标准值: ${parsed_standard}<br />${marker}${parsed_value}`;
                },
                position: (point) => {
                    const [x] = point;
                    const obj = {
                        top: 0,
                        right: refNode.offsetWidth - x + 30,
                    };
                    return obj;
                },
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                // scale: true,// true时不一定显示0
                show: false,
                min: y_min,
                max: y_max,
            },
            series: [
                {
                    data: yData,
                    type: 'bar',
                    itemStyle: {
                        color: '#355B99',
                        barBorderRadius: 3,
                    },
                    barWidth: '61.5%',
                    markLine: {
                        silent: true,
                        symbol: 'none',
                        animation: false,
                        precision: 4,
                        lineStyle: {
                            type: "dashed",
                            color: "#355B99",
                        },
                        label: {
                            show: false,
                        },
                        data: []
                            .concat(
                                typeof lower_limit === 'number' ? [
                                    {
                                        yAxis: lower_limit,
                                    }
                                ] : [],
                                typeof upper_limit === 'number' ? [
                                    {
                                        yAxis: upper_limit,
                                    }
                                ] : [],
                            ),
                    },
                },
            ],
        });
    };
};

const Comp = (props) => {
    const { name, cpl_rate, is_good, std_diff_arrow_up, data_list, std_diff_text, avg_value_text, unit_text, is_good_cpl, lower_limit, upper_limit } = props;

    const refNode = useRef(null);
    useEffect(() => {
        if (!!refNode.current) {
            renderChart(refNode.current, data_list ?? [], lower_limit, upper_limit);
        };
    }, [data_list]);

    const name_text = useMemo(() => {
        if (typeof name === 'string') return name;
        return null;
    }, [name]);
    const [rate_num, rate_text] = useMemo(() => {
        if (typeof cpl_rate === 'number') {
            const rate = cpl_rate * 100;
            if (rate >= 0 && rate <= 100) {
                return [rate, Math.floor(rate * 10) / 10 + ""];
            };
        };
        return [0, '--'];
    }, [cpl_rate]);

    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="left">
                    <div className="row">
                        <div className="block_1">
                            {
                                typeof name_text === 'string' && (
                                    <div className="text_1">{name_text}</div>
                                )
                            }
                        </div>
                        <div className="block_2">
                            <div className="text">{avg_value_text} {unit_text}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="block_1">
                            <div className="title">指标合格率</div>
                            <div className="bar">
                                <div className="bar_value" style={{ width: `${rate_num}%` }}></div>
                            </div>
                            <div className={"value" + (is_good_cpl === false ? " red" : "")}>{rate_text}%</div>
                        </div>
                        <div className={"block_2" + (is_good === true ? " green" : (is_good === false ? " red" : ""))}>
                            {
                                typeof std_diff_arrow_up === 'boolean' ? (
                                    <div className={"arrow" + (std_diff_arrow_up ? " up" : " down")}></div>
                                ) : null
                            }
                            <div className="text_2">{std_diff_text}</div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="content">
                        <div className="chart_wrapper">
                            <div className="chart" ref={refNode}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
