let SERVER_PATH = process.env.REACT_APP_SERVER_PATH; //'//localhost:8000/';//
if(window.location.hostname.indexOf('192.168') >= 0 || window.location.hostname.indexOf('172.16') >= 0){
    SERVER_PATH = '/';
}

// let URLS = process.env.REACT_APP_URLS;

// if(URLS){
//     let url_list = URLS.split(";");
//     let curr_f_url = window.location.href+"";
//     for(let i=0;i<url_list.length;i++){
//         let f_url = url_list[i].split(',')[0]
//         let api_url = url_list[i].split(',')[1]
//         if(curr_f_url.indexOf(f_url) >= 0){
//             SERVER_PATH = api_url;
//         }
//     }
// }

// if(window.location.hostname.indexOf('192.168') >= 0 || window.location.hostname.indexOf('172.16') >= 0){
//     SERVER_PATH = '/';
// }
//


let PIC_SERVER_PATH = process.env.REACT_APP_PIC_SERVER_PATH;

// 数据交互配置
const TIMEOUT = 120000; //请求超时时间

const base = {
	header: {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	},
};

/*
 *
 * 接口配置部分
 *
 */


const Config = {
	authApi: {
		// API_GET_ROLE_LIST: SERVER_PATH + "api/pc/user/roles",
	},
	mgrApi: {
		// API_USERS: SERVER_PATH + "api/pc/user/users",//users
	},
	api: {
		// API_GET_ORDER_LIST: SERVER_PATH + "api/pc/order/list",
    }
};

export {
	base,
	TIMEOUT,
	SERVER_PATH,
	PIC_SERVER_PATH,
	Config
}

export default Config;
