import React from 'react';
import { Button, DatePicker, message } from 'antd';
import { post, get, postDownloadFile, getToken } from 'common/utils';
import moment from 'moment';

const { RangePicker } = DatePicker;

export default class LogCenter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeRange: null
        };
    }

    componentDidMount() {
    }

    downloadExcel = (id) => {
        if(!this.state.timeRange){
            message.error("请选择开始和结束日期");
            return;
        }
        postDownloadFile(process.env.REACT_APP_SERVER_PATH + 'api/auth/download_log', {
            "day_begin": moment(this.state.timeRange[0]).format("YYYY-MM-DD"),
            "day_end": moment(this.state.timeRange[1]).format("YYYY-MM-DD"),
        })
    }

    nameOnChange = (e) => {
        if (typeof e.target.value === 'string') {
            const { selectedItem } = this.state;
            this.setState({
                selectedItem: Object.assign({}, selectedItem, {
                    name: e.target.value,
                })
            });
        } else {
            console.log("not string", e.target.value);
        };
    }

    render() {
        let self = this;

        return (
            <div className="LogCenter" style={{ padding: 24 }}>
                <RangePicker
                    className="picker"
                    style={{ marginRight: 'auto' }}
                    showTime={false}
                    format="YYYY-MM-DD"
                    placeholder={['开始时间', '结束时间']}
                    value={this.state.timeRange}
                    onChange={timeRange => this.setState({ timeRange })}
                />
                <Button
                    onClick={()=>this.downloadExcel()}
                    type="primary"
                    style={{marginLeft: "15px"}}
                >下载日志</Button>
            </div>
        );
    }
}
