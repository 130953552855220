import React from 'react';
import { Select, Spin, message } from 'antd';

import ICON_STAR from './res/star.png';
import ICON_XLXS from './res/xlxs_icon.png';
import SEARCH_ICON from './res/search.png';
import ICON_CHECKED from './res/check_yes.png';
import ICON_NOT_CHECKED from './res/check_no.png';

import './FactorSummary.scss';

import ColoredBar from '../Common/ColoredBar.js';
import CusTh from '../Common/CusTh.js';
import SingleFactorDetail from './SingleFactorDetail/index.js';
import MultiFactorsDetail from './MultiFactorsDetail/index.js';
import CustomFactorBtn from '../Common/CustomFactorBtnContainer.js';
import CustomFactorPanel from '../Common/CustomFactorPanelContainer.js';
import TimeRangePicker from '../Common/TimeRangePicker.js';
import { parseTimeRange } from './utils.js';

const { Option } = Select;

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayMultiFactors: false,
            rowSelectedId: null,
            cusFactorPanelVisible: false,
            multiSelectedIds: [],
            sysSelected: 'all',
            themeSelected: 'all',
            searchValue: null,
            loading: false,
        };
        this.searchStr = null;
    }

    componentDidMount() {
        const { getSysList, getThemeList, getKPIList } = this.props;
        getSysList()
            .then(isSuccess => {
                // console.log(isSuccess);
            });
        getThemeList()
            .then(isSuccess => {
                // console.log(isSuccess);
            });
        this.setState({ loading: true });
        getKPIList()
            .then(isSuccess => {
                // 首次拉取数据需要定义无条件状态下的列表
                // console.log(isSuccess);
                this.setState({ loading: false });
                if (isSuccess) {
                    const { originAllKPIList, setDisplayKPIList } = this.props;
                    setDisplayKPIList([].concat(originAllKPIList));
                    setTimeout(() => {
                        const list = this.getFilteredSearchedFactors();
                        if (list.length > 0) {
                            this.rowOnclick(list[0].id);
                        };
                    }, 0);
                } else {
                    message.error('数据获取失败！请检查网络或刷新重试！');
                };
            });
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (prevTimeRange !== timeRange) {
            console.log("time changed");
        };
    }

    optionOnClick = (nextDisplayMultiFactors) => {
        this.setState({ displayMultiFactors: nextDisplayMultiFactors });
    }

    rowOnclick = (nextRowSelectedId) => {
        this.setState({ rowSelectedId: nextRowSelectedId });
    }

    customeFactorBtnOnClick = () => {
        this.setState({ cusFactorPanelVisible: true });
    }

    multiIndexOnClick = (id) => {
        const { multiSelectedIds } = this.state;
        const nextMultiSelectedIdsSet = new Set(multiSelectedIds);
        if (nextMultiSelectedIdsSet.has(id)) {
            nextMultiSelectedIdsSet.delete(id);
        } else {
            nextMultiSelectedIdsSet.add(id);
        };
        const nextMultiSelectedIds = [...nextMultiSelectedIdsSet];
        this.setState({ multiSelectedIds: nextMultiSelectedIds });
    }

    KPIAnalysisOnClick = (id) => {
        const { timeRange } = this.props;
        const [startTime, endTime] = parseTimeRange(timeRange);
        // this.props.history.push('/System/ReferenceFactors/3');
        window.open(window.location.origin + `/#/System/ReferenceFactors/${id}?startTime=${startTime.format("YYYY-MM-DD 00:00:00")}&endTime=${endTime.format("YYYY-MM-DD 23:59:59")}`);
    }

    sysOnchange = nextSysSelected => {
        // console.log(nextSysSelected, typeof nextSysSelected);
        this.setState({
            sysSelected: nextSysSelected,
            rowSelectedId: null,
            multiSelectedIds: [],
            searchValue: null,
        }, () => {
            // this.rowOnclick(0);
        });
    }

    themeOnchange = nextThemeSelected => {
        // console.log(nextSysSelected, typeof nextSysSelected);
        this.setState({
            themeSelected: nextThemeSelected,
            rowSelectedId: null,
            multiSelectedIds: [],
            searchValue: null,
        }, () => { });
    }

    getFilteredFactors = () => {
        const { sysSelected, themeSelected } = this.state;
        const { kpiList } = this.props;
        let retList = [];
        if (!!kpiList) {
            retList =
                kpiList
                    .filter(({ sys_id, theme_id }) => {
                        let ret = true;
                        if (typeof sysSelected === "number") {
                            ret = ret && (sys_id === sysSelected);
                        };
                        if (typeof themeSelected === "number") {
                            ret = ret && (theme_id === themeSelected);
                        };
                        return ret;
                    });
        };
        return retList;
    }

    getFilteredSearchedFactors = () => {
        const filteredList = this.getFilteredFactors();
        const { searchValue } = this.state;
        if (typeof searchValue === "number") {
            return filteredList.filter(({ id }) => id === searchValue);
        } else if (typeof searchValue === "string") {
            return filteredList.filter(({ name }) => name.indexOf(searchValue) !== -1);
        };
        return filteredList;
    }

    searchOnChange = (value) => {
        console.log(value, typeof value);
        this.setState({ searchValue: value });
    }

    searchOnClick = () => {
        // console.log(this.searchStr, typeof this.searchStr);
        let nextForceSearchStr = null;
        if (typeof this.searchStr === "string" && this.searchStr.trim() !== "") {
            nextForceSearchStr = this.searchStr;
        };
        this.setState({ searchValue: nextForceSearchStr });
    }

    watchFavouriteKPIs = () => {
        this.setState({ rowSelectedId: null }, () => {
            const { setDisplayKPIList, customFactors, originAllKPIList } = this.props;
            const displayIds = (customFactors || []).map(({ id }) => id);
            const nextKpiList = originAllKPIList.filter(({ id }) => displayIds.includes(id));
            setDisplayKPIList(nextKpiList);
            setTimeout(() => {
                const list = this.getFilteredSearchedFactors();
                if (list.length > 0) {
                    this.rowOnclick(list[0].id);
                };
            }, 0);
        });
    }

    timeRangeOnChange = (timeRange) => {
        const { getKPIList } = this.props;
        this.setState({ loading: true });
        getKPIList(timeRange)
            .then(isSuccess => {
                // 刷新数据需要更新对应id
                // console.log(isSuccess);
                this.setState({ loading: false });
                if (isSuccess) {
                    this.setState({ rowSelectedId: null, multiSelectedIds: [] });
                    const { updateTimeRange } = this.props;
                    updateTimeRange(timeRange);

                    const { originAllKPIList, kpiList, setDisplayKPIList } = this.props;
                    const displayIds = kpiList.map(({ id }) => id);
                    const nextKpiList = originAllKPIList.filter(({ id }) => displayIds.includes(id));
                    setDisplayKPIList(nextKpiList);
                    setTimeout(() => {
                        const list = this.getFilteredSearchedFactors();
                        if (list.length > 0) {
                            this.rowOnclick(list[0].id);
                        };
                    }, 0);
                } else {
                    message.error('数据获取失败！请检查网络或刷新重试！');
                };
            });
    }

    render() {
        const { kpiList, systems, themes, timeRange } = this.props;
        const [startTime, endTime] = parseTimeRange(timeRange);
        const {
            sysSelected, themeSelected, searchValue,
            displayMultiFactors, rowSelectedId,
            cusFactorPanelVisible, multiSelectedIds,
            loading,
        } = this.state;
        const selectStyle = { height: 32, width: 160, backgroundColor: '#343D47' };
        let timeRangeText = "-年-月-日- -年-月-日";
        if (timeRange !== null) {
            timeRangeText = parseTimeRange(timeRange)
                .map(time => time.format('YYYY年MM月DD日'))
                .join('- ');
        };

        return (
            <div className="factor_summary_v2_wrapper" style={{ position: 'relative' }}>
                <div className="factor_summary_v2_header_wrapper">
                    <div style={{ height: 67, paddingTop: 1, display: 'flex', alignItems: 'center', backgroundColor: '#212830' }}>
                        <Select value={sysSelected} style={Object.assign({ marginLeft: 24 }, selectStyle)} bordered={false} onChange={this.sysOnchange}>
                            <Option value="all">全部生产系统</Option>
                            {
                                systems
                                    .map((item, index) => {
                                        const { name, id } = item;
                                        return (
                                            <Option value={id} key={index}>{name}</Option>
                                        );
                                    })
                            }
                        </Select>
                        <Select value={themeSelected} style={Object.assign({ marginLeft: 8 }, selectStyle)} bordered={false} onChange={this.themeOnchange}>
                            <Option value="all">全部指标主题</Option>
                            {
                                themes
                                    .map((item, index) => {
                                        const { name, id } = item;
                                        return (
                                            <Option value={id} key={index}>{name}主题</Option>
                                        );
                                    })
                            }
                        </Select>
                        {/* <Select defaultValue="1" style={Object.assign({ marginLeft: 8 }, selectStyle)} bordered={false}>
                            <Option value="1">改进计划</Option>
                            <Option value="2">全部</Option>
                            <Option value="3">已加入</Option>
                            <Option value="4">未加入</Option>
                        </Select> */}
                        <div style={{ marginLeft: 16, position: 'relative' }}>
                            <Select
                                placeholder="请输入指标名称"
                                style={{ width: 240, height: 32, backgroundColor: '#343D47' }}
                                bordered={false}
                                showSearch
                                suffixIcon={null}
                                value={searchValue}
                                onChange={this.searchOnChange}
                                filterOption={(inputValue, option) => {
                                    this.searchStr = inputValue;
                                    const { cus_attr } = option;
                                    return cus_attr.indexOf(inputValue) !== -1;
                                }}
                            >
                                {
                                    this.getFilteredFactors()
                                        .map((item, index) => {
                                            const { id, name } = item;
                                            return (
                                                <Option value={id} key={id} cus_attr={name}>{name}</Option>
                                            );
                                        })
                                }
                            </Select>
                            <img
                                alt="" src={SEARCH_ICON}
                                width="22" height="22"
                                style={{ cursor: 'pointer', position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }}
                                onClick={this.searchOnClick}
                            />
                        </div>
                        <div style={{ marginLeft: 'auto', position: 'relative' }}>
                            <div
                                style={Object.assign({ color: '#FFFFFF', fontSize: 20, lineHeight: '20px', height: 20, cursor: 'pointer' }, {})}
                            >【{timeRangeText}】</div>
                        </div>
                        <div style={{ marginLeft: 8 }}>
                            <TimeRangePicker timeRange={timeRange} onChange={this.timeRangeOnChange} />
                        </div>
                        <div style={{ marginLeft: 12, marginRight: 24, display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}>
                            <img alt="" src={ICON_XLXS} width="24" height="24" />
                            <div style={{ marginLeft: 4, color: '#3DCDB9', fontSize: 14, height: 20, lineHeight: '20px' }}>导出指标</div>
                        </div>
                    </div>
                    <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
                </div>
                <div className="factor_summary_v2_content_wrapper">

                    <div className="left_block_wrapper">
                        <div className="item_header_wrapper">
                            <div className={"option" + (displayMultiFactors ? "" : " selected_option")} onClick={() => this.optionOnClick(false)}>单指标分析</div>
                            <div className={"option" + (displayMultiFactors ? " selected_option" : "")} onClick={() => this.optionOnClick(true)}>多指标联合</div>
                        </div>
                        <div className="item_content_wrapper">
                            <div className="table_wrapper scroll_bar_restyle">
                                <table>
                                    <thead>
                                        <tr>
                                            <CusTh style={{ width: displayMultiFactors ? 60 : 12, height: 52 }}></CusTh>
                                            <CusTh style={{ width: 96 }}>产品类型</CusTh>
                                            <CusTh>指标名称</CusTh>
                                            <CusTh>平均值</CusTh>
                                            <CusTh>基准值</CusTh>
                                            <CusTh style={{ textAlign: 'center' }}>红绿灯</CusTh>
                                            <CusTh>平均KPR</CusTh>
                                            <CusTh>目标KPR</CusTh>
                                            <CusTh>比较</CusTh>
                                            <CusTh style={{ width: 400 }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>{startTime.format("YYYY-MM-DD")}</div>
                                                    <div>{endTime.format("YYYY-MM-DD")}</div>
                                                </div>
                                            </CusTh>
                                            <CusTh style={{ width: displayMultiFactors ? 24 : 72 }}></CusTh>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.getFilteredSearchedFactors()
                                                .map((item, index) => {
                                                    const {
                                                        product_name, name, weighted_avg, lower_limit, upper_limit, rate, exp_cpl_rate,
                                                        times, id,
                                                    } = item;

                                                    const rowBckType = (rowSelectedId === id && !displayMultiFactors) ? "tr_selected" : "";

                                                    let redGreen = null;
                                                    let weighted_avg_format = '-';
                                                    if (typeof weighted_avg === "number") {
                                                        weighted_avg_format = Math.floor(weighted_avg * 10) / 10;
                                                    };
                                                    let standardV = '-';
                                                    if (typeof lower_limit === "number") {
                                                        if (typeof weighted_avg === "number") {
                                                            redGreen = lower_limit > weighted_avg;
                                                        };
                                                        standardV = lower_limit;
                                                    } else if (typeof upper_limit === "number") {
                                                        if (typeof weighted_avg === "number") {
                                                            redGreen = upper_limit < weighted_avg;
                                                        };
                                                        standardV = upper_limit;
                                                    };
                                                    if (typeof standardV === "number") {
                                                        standardV = Math.floor(standardV * 10) / 10;
                                                    };

                                                    let phiWarning = false;
                                                    let rateText = '-';
                                                    if (typeof rate === "number") {
                                                        rateText = Math.floor(rate * 1000) / 10;
                                                    };
                                                    let expCplRate = '-';
                                                    if (typeof exp_cpl_rate === "number") {
                                                        expCplRate = Math.floor(exp_cpl_rate * 1000) / 10;
                                                    };
                                                    let rateDelta = '-';
                                                    if (typeof rate === "number" && typeof exp_cpl_rate === "number") {
                                                        rateDelta = Math.floor((rate - exp_cpl_rate) * 1000) / 10;
                                                        phiWarning = rate < exp_cpl_rate;
                                                    };

                                                    const signStyle = { width: 12, height: 12, borderRadius: '50%', backgroundColor: redGreen === null ? "transparent" : (redGreen ? "#FF6170" : "#44F3DA"), margin: '0 auto' };

                                                    return (
                                                        <tr
                                                            key={`${id}-content`}
                                                            className={rowBckType}
                                                            onClick={displayMultiFactors ? () => { } : () => this.rowOnclick(id)}
                                                        >
                                                            <td style={{ height: 48 }}>
                                                                {
                                                                    displayMultiFactors && (
                                                                        <img alt="" src={multiSelectedIds.includes(id) ? ICON_CHECKED : ICON_NOT_CHECKED} width="16" height="16" style={{ marginLeft: 14 }} onClick={() => this.multiIndexOnClick(id)} />
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="td_3_product_category" style={displayMultiFactors ? {} : { marginLeft: 2 }}>{product_name}</div>
                                                            </td>
                                                            <td>
                                                                <div className="factor_name_wrapper">
                                                                    <img alt="" src={ICON_STAR} width="20" height="20" />
                                                                    <div className="text">{name}</div>
                                                                </div>
                                                            </td>
                                                            <td style={redGreen === true ? { color: '#FF6170' } : {}}>{weighted_avg_format}</td>
                                                            <td>{standardV}</td>
                                                            <td>
                                                                <div style={signStyle} />
                                                            </td>
                                                            <td style={phiWarning === true ? { color: '#FF6170' } : {}}>{rateText}%</td>
                                                            <td>{expCplRate}%</td>
                                                            <td style={phiWarning === true ? { color: '#FF6170' } : {}}>{rateDelta}%</td>
                                                            <td>
                                                                <ColoredBar timeBegin={startTime.format("YYYY-MM-DD 00:00:00")} timeEnd={endTime.format("YYYY-MM-DD 23:59:59")} coloredLines={times} />
                                                            </td>
                                                            <td className="td_analysis_btn">
                                                                {
                                                                    !displayMultiFactors && (
                                                                        <div style={{ marginLeft: 28, color: '#44F3DA', display: 'inline-block', cursor: 'pointer', userSelect: 'none' }} onClick={(e) => { e.stopPropagation(); this.KPIAnalysisOnClick(id) }}>分析</div>
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="right_block_wrapper">
                        <div style={{ height: "100%", overflowY: 'auto' }} className="scroll_bar_restyle">
                            {
                                displayMultiFactors ?
                                    (
                                        multiSelectedIds.length > 0 && (
                                            <MultiFactorsDetail multiSelectedIds={multiSelectedIds} />
                                        )
                                    ) : (
                                        rowSelectedId !== null && (
                                            <SingleFactorDetail selectedFactor={kpiList.filter(({ id }) => id === rowSelectedId)[0]} />
                                        )
                                    )
                            }
                        </div>
                    </div>

                    <div style={{ position: 'absolute', right: 16, top: 372 }} onClick={this.customeFactorBtnOnClick}>
                        <CustomFactorBtn />
                    </div>

                    {
                        cusFactorPanelVisible && (
                            <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, width: 360, zIndex: 1000 }}>
                                <CustomFactorPanel
                                    onCancel={() => this.setState({ cusFactorPanelVisible: false })}
                                    watchFavouriteKPIs={this.watchFavouriteKPIs}
                                />
                            </div>
                        )
                    }

                </div>

                {
                    loading && (
                        <div style={{ position: 'absolute', zIndex: 1000, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div >
        );
    }
}

export default Page;