import React from 'react'
import { Layout, Form, Checkbox, Table, Button, Input, Spin, message, Modal, Switch } from 'antd';
import { get, post, patch, reqDelete } from '../../../common/utils.js';
import md5 from 'md5';
import { getMyPermissions } from '../../../common/AuthPermission.js';
import { PlusOutlined } from '@ant-design/icons';
import style from './index.module.scss';

const { Column } = Table;

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spinLoading: false,
            userList: [],
            currentUserId: 0,
            current: 1,
            // selectRoleName:[],
            modal_user_name: '',
            modal_role_ids: [],
            roles: [],
            total: 0,
            pageSize: 20,
            userEditorVisible: false,
            modalVisible: false,
            optName: '',
            modifyPasswordVisible: false,
            newPassword: '',
            againPassword: '',
            // role:'',
            myPermissions: getMyPermissions(),

            isShowRowSelectd: this.props.isShowRowSelectd,
            selectedRowKeys: this.props.selectUserRowsIds,

            checkedAuthValues: [],
            roleSelectBlockVisible: false,
            userStatus: 1,
            filterKeyword: '',
        }
    }

    componentDidMount() {
        this.getUserList(1)
        this.getRoles();
    }

    getRoles = () => {
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/list_info', {
            'page': 1,
            'page_size': 999,
        }).then((retData) => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                self.setState({
                    roles: retData.data.results.roles,
                });
            } else {
            }
        });
    }

    getUserList = (page) => {
        const { pageSize, filterKeyword, checkedAuthValues } = this.state;
        this.setState({ spinLoading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/user/users', {
            page: page,
            page_size: pageSize,
            status: "0,1",
            ...(!filterKeyword ? {} : {
                keyword: filterKeyword,
            }),
            ...(checkedAuthValues.length === 0 ? {} : {
                role_ids: checkedAuthValues.join(','),
            })
        }).then((retData) => {
            this.setState({ spinLoading: false });
            if (retData.data && retData.data.results) {
                this.setState({
                    userList: retData.data.results,
                    current: page,
                    total: retData.data.count
                });
            } else {
            }
        });
    }

    updateUserInfo = () => {
        let self = this;
        // let roleIdList = [];
        const { newPassword, againPassword, userStatus, modal_user_name, modal_role_ids } = this.state;
        if (self.state.modal_user_name === '') {
            message.info("请输入完整信息");
        } else {
            this.setState({ confirmLoading: true });
            if (parseInt(self.state.currentUserId) === -1) {
                //新建
                if (!newPassword) {
                    message.info("请设置新密码");
                    return;
                } else if (newPassword !== againPassword) {
                    message.info("两次密码输入不一致");
                    return;
                };
                post(process.env.REACT_APP_SERVER_PATH + 'api/user/users', {
                    name: modal_user_name,
                    md5_pwd: md5(newPassword),
                    status: userStatus,
                    role_ids: modal_role_ids,
                }).then((retData) => {
                    this.setState({ confirmLoading: false });
                    // console.log(retData);
                    if (retData && retData.data) {
                        if (retData.data.errcode === 501) {
                            message.error(retData.data.msg);
                            return;
                        }
                        message.success("添加成功");
                        self.getUserList(1);
                        self.setState({
                            userEditorVisible: false
                        });
                    } else {
                        message.error("用户新建失败");
                    }
                });
            } else {
                //修改
                if (!!newPassword && newPassword !== againPassword) {
                    message.info("两次密码输入不一致");
                    return;
                }
                patch(process.env.REACT_APP_SERVER_PATH + 'api/user/users/' + self.state.currentUserId, {
                    name: modal_user_name,
                    role_ids: modal_role_ids,
                    status: userStatus,
                    ...(!newPassword ? {} : {
                        new_pwd: md5(newPassword),
                    })
                }).then((retData) => {
                    this.setState({ confirmLoading: false });
                    if (retData && retData.data) {
                        if (retData.data.errcode === 0) {
                            message.success("用户修改成功");
                            self.getUserList(self.state.current);
                            self.setState({
                                userEditorVisible: false
                            });
                        } else if (retData.data.errcode === 501) {
                            message.error('该姓名用户已存在');
                        } else {
                            message.error(retData.data.msg);
                        };
                    } else {
                        message.error("用户修改失败");
                    }
                });
            }
        }
    }
    handleDeleteUser = () => {
        const { currentUserId, current } = this.state;
        if (currentUserId === -1) return;
        reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/user/users/' + currentUserId, {})
            .then((retData) => {
                if (retData.data && retData.data.errcode === 0) {
                    message.success("用户删除成功");
                    this.getUserList(current);
                    this.setState({
                        userEditorVisible: false,
                    })
                } else {
                    message.error("用户删除失败");
                }
            });
        this.setState({
            modalVisible: false,
        });
    }
    deleteUser = () => {
        const { currentUserId } = this.state;
        if (currentUserId === -1) {
            return;
        }
        this.setState({
            modalVisible: true
        });
    }
    createPerson = () => {
        this.setState({
            optName: '添加用户',
            userEditorVisible: true,
            // selectRoleName:[],
            modal_user_name: '',
            currentUserId: -1,
            modal_role_ids: [],
            newPassword: '',
            againPassword: '',
            userStatus: 1,
        });
    }
    editUser(user) {
        let self = this;
        const { status = 1 } = user;
        self.setState({
            optName: '编辑',
            userEditorVisible: true,
            modal_user_name: user.name,
            modal_role_ids: user.roles.map(item => item.id),
            currentUserId: user.id,
            newPassword: '',
            againPassword: '',
            userStatus: status,
        });
    }
    delRole = (id, e) => {
        if (e) e.stopPropagation();
        const { modal_role_ids } = this.state;
        this.setState({
            modal_role_ids: modal_role_ids.filter(item => item !== id),
        })
    }

    roleValueOnClick = id => {
        const { modal_role_ids } = this.state;
        if (modal_role_ids.indexOf(id) > -1) {
            this.delRole(id);
        } else {
            modal_role_ids.push(id);
            this.setState({ modal_role_ids });
        }
    }

    authOnChange = checkedAuthValues => {
        this.setState({ checkedAuthValues }, () => {
            this.getUserList(1);
        });
    }

    render() {
        let self = this;
        const { roles, myPermissions, checkedAuthValues, currentUserId, roleSelectBlockVisible, newPassword, againPassword, userStatus } = this.state;

        return (
            <Layout className={style['PersonMng']} onClick={() => this.setState({ roleSelectBlockVisible: false })}>
                <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
                <Spin spinning={this.state.spinLoading}>
                    <Layout
                        className="layout-first"
                    // style={{ backgroundColor: 'rgba(240, 242, 245, 1)' }}
                    >
                        <Layout className="layout-second">
                            <div className="headerOpt">
                                <Input
                                    style={{ width: 180 }}
                                    value={this.state.filterKeyword}
                                    onChange={(e) => this.setState({ filterKeyword: e.target.value })}
                                    placeholder="请输入姓名"
                                />
                                <Button
                                    type="primary"
                                    className="header-opt-build"
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        self.getUserList(1);
                                    }}
                                >搜索</Button>
                                <Checkbox.Group
                                    options={
                                        roles
                                            .map(({ id: value, name: label }) => ({ value, label }))
                                    }
                                    value={checkedAuthValues}
                                    onChange={this.authOnChange}
                                    style={{ marginLeft: 20 }}
                                />
                                {
                                    !this.state.isShowRowSelectd &&
                                    myPermissions.includes('users/add_user') &&
                                    <Button style={{ marginLeft: 'auto' }} type="primary" onClick={this.createPerson}><PlusOutlined />添加</Button>
                                }
                            </div>
                            <div className="table_wrapper">
                                <Table
                                    rowKey='id'
                                    dataSource={this.state.userList}
                                    pagination={{
                                        defaultCurrent: 1,
                                        total: this.state.total,
                                        pageSize: this.state.pageSize,
                                        showSizeChanger: true,
                                        showQuickJumper: false,
                                        current: this.state.current,
                                    }}
                                    onChange={pagination => {
                                        this.setState({ pageSize: pagination.pageSize }, () => self.getUserList(pagination.current))
                                    }
                                    }
                                    rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                                >
                                    <Column
                                        title={() => (
                                            <span style={{ color: '#357CF7' }}>序号</span>
                                        )}
                                        key='no'
                                        // width={100}
                                        render={(text, record, index) => (index + 1 + "")}
                                    />
                                    <Column
                                        title='姓名'
                                        dataIndex='name'
                                    // width={200}
                                    // key='name'
                                    />
                                    {
                                        <Column
                                            title='角色'
                                            dataIndex='roles'
                                            key='roles'
                                            render={(text, record, index) => text.map(item => item.name).join('，')}
                                        />
                                    }
                                    {
                                        !this.state.isShowRowSelectd &&
                                        myPermissions.includes('users/edit_user') &&
                                        <Column
                                            title="操作"
                                            key="operate"
                                            width={200}
                                            render={(text, record) => (
                                                <span onClick={() => this.setState({ currentUserId: record.id })}>
                                                    <Button type="link" size="small" onClick={(e) => this.editUser(record)}>编辑</Button>
                                                </span>
                                            )}
                                        />
                                    }
                                </Table>
                            </div>
                            <Modal
                                visible={this.state.modalVisible}
                                title="删除"
                                onOk={this.handleDeleteUser}
                                onCancel={() => self.setState({ modalVisible: false })}
                            >
                                <span>确定删除该用户吗？</span>
                            </Modal>

                            {
                                this.state.userEditorVisible &&
                                <Modal
                                    visible={this.state.userEditorVisible}
                                    title={this.state.optName}
                                    onOk={this.updateUserInfo}
                                    confirmLoading={this.state.confirmLoading}
                                    onCancel={() => self.setState({ userEditorVisible: false })}
                                    footer={[
                                        currentUserId !== -1 ? (
                                            <Button
                                                key="back"
                                                onClick={this.deleteUser}
                                            >
                                                删除用户
                                            </Button>
                                        ) : null,
                                        <Button key="submit" type="primary" onClick={this.updateUserInfo}>
                                            提交
                                          </Button>,
                                    ]}
                                >
                                    <div>
                                        <Form className={style["ant-advanced-search-form"]}>
                                            <div className="row_item">
                                                <div className="item_title">姓名</div>
                                                <Input value={this.state.modal_user_name} onChange={(e) => this.setState({ modal_user_name: e.target.value })} style={{ width: 200 }} />
                                            </div>
                                            <div className="row_item">
                                                <div className="item_title">角色</div>
                                                <div className="select_root_wrapper">
                                                    <div className="role_select_wrapper" onClick={e => { this.setState({ roleSelectBlockVisible: true }); e.stopPropagation(); }}>
                                                        {
                                                            this.state.modal_role_ids.map((id, index) => (
                                                                <div key={index} className="role_item">{
                                                                    (this.state.roles.filter(({ id: _id }) => _id === id)[0] || {}).name
                                                                }<span onClick={e => this.delRole(id, e)}>×</span></div>
                                                            ))
                                                        }
                                                    </div>
                                                    {
                                                        roleSelectBlockVisible && (
                                                            <div className="role_select_values_wrapper" onClick={e => e.stopPropagation()}>
                                                                <div className="role_select_wrapper">
                                                                    {
                                                                        this.state.modal_role_ids.map((id, index) => (
                                                                            <div key={index} className="role_item">{
                                                                                (this.state.roles.filter(({ id: _id }) => _id === id)[0] || {}).name
                                                                            }<span onClick={e => this.delRole(id, e)}>×</span></div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="select_values_content_wrapper">
                                                                    {
                                                                        this.state.roles.map(({ id, name }, index) => (
                                                                            <div
                                                                                className={"role_value_item" + (this.state.modal_role_ids.indexOf(id) > -1 ? ' role_value_item_selected' : '')}
                                                                                key={index}
                                                                                onClick={() => this.roleValueOnClick(id)}
                                                                            >{name}</div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="item_title">停用该用户</div>
                                                <Switch
                                                    checked={userStatus === 0}
                                                    onChange={checked => this.setState({ userStatus: checked ? 0 : 1 })}
                                                />
                                            </div>
                                            <div className="row_item">
                                                <div className="item_title">密码</div>
                                                <Input
                                                    value={newPassword}
                                                    onChange={e => this.setState({ newPassword: e.target.value })}
                                                    style={{ width: 200 }}
                                                    placeholder="请输入新密码"
                                                />
                                            </div>
                                            <div className="row_item">
                                                <div className="item_title"></div>
                                                <Input
                                                    value={againPassword}
                                                    onChange={e => this.setState({ againPassword: e.target.value })}
                                                    style={{ width: 200 }}
                                                    placeholder="请再次输入"
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                </Modal>
                            }
                        </Layout>
                    </Layout>
                </Spin>
            </Layout>
        )
    }
}
