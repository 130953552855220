import { Modal, Space, Button, Spin, DatePicker } from 'antd';
import { post } from 'common/utils';
import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';
import IngredTable from './IngredTable/index.js';
import List from '../List/index.js'
import { renderChart } from './utils.js'
import moment from 'moment';

const { RangePicker } = DatePicker;

const Comp = (props) => {
    const [range, setRange] = useState(() => {
        const sT = moment(props.info.end_time).subtract(6, 'hours')
        const eT = moment(props.info.end_time).add(4, 'hours')
        return [sT, eT]
    })

    const handleCancel = useCallback(() => {
        if (typeof props.onCancel === 'function') {
            props.onCancel()
        }
    }, [props])

    const [key, setKey] = useState(() => props.info?.index ?? 1)

    const [tags, setTags] = useState(null)
    useEffect(() => {
        // console.log(key)
        const id = props.info?.id ?? null
        if (typeof id === 'number') {
            let delay = true
            post(process.env.REACT_APP_SERVER_PATH + 'tengfei_api/get_fncop_tags', { fnc_id: id })
                .then(retData => {
                    if (delay) {
                        // console.log(retData)
                        let tags = null
                        if (retData?.data?.errcode === 0) {
                            tags = retData.data.results ?? []
                            setTags(tags)
                        }
                    }
                })
            return () => {
                delay = false
            }
        } else {
            setTags(null)
        }
    }, [props])

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState(null)
    useEffect(() => {
        // console.log(tags)
        // fetch data
        if ((tags ?? []).length > 0) {
            let delay = true
            setLoading(true)
            const time_begin = moment(range[0]).format("YYYY-MM-DD HH:mm:00")
            const time_end = moment(range[1]).format("YYYY-MM-DD HH:mm:00")
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_his_aggr_data', {
                tag_name_list: tags,
                time_begin,
                time_end,
                aggr: "last",
                sample_interval: "all",
                time_range: "custom"
            })
                .then(retData => {
                    if (delay) {
                        setLoading(false)
                        let list = null
                        // console.log(retData)
                        if (retData?.data?.errcode === 0) {
                            post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
                                "tag_name_list": tags,
                                "page": 1,
                                "page_size": tags.length + 1
                            })
                                .then(retD => {
                                    console.log(retD)
                                    let rets = []
                                    if (retD?.data?.errcode === 0) {
                                        rets = retD.data.results ?? []
                                    }
                                    list = (retData.data.results ?? []).map(item => {
                                        const pInfo = rets.find(({ tag_name }) => tag_name === item.tag_name) ?? {}
                                        return Object.assign(pInfo, item)
                                    })
                                    setList(list)
                                })
                        }
                        // console.log(list)
                    }
                })
            return () => {
                delay = false
            }
        } else {
            setList(null)
        }
    }, [tags, range])

    const handleView = useCallback((key) => {
        return () => {
            setKey(key)
        }
    }, [])

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={handleCancel}
            width={1360}
        >
            <div className='top'>
                <Space>
                    <Button type='primary' disabled={key === 1} onClick={handleView(1)}>查看配料</Button>
                    <Button type='primary' disabled={key === 2} onClick={handleView(2)}>查看炉况参数</Button>
                </Space>
            </div>
            <div className={style['content']} style={key === 2 ? { display: 'none' } : {}}>
                <IngredTable info={props.info} />
            </div>
            <div className={style['content'] + ' list'} style={key === 1 ? { display: 'none' } : {}}>
                <div className='top'>
                    <div className='text'>
                        时间选择：{moment(range[0]).format("YYYY-MM-DD HH:mm")} - {moment(range[1]).format("YYYY-MM-DD HH:mm")}
                        <RangePicker value={range} onChange={setRange} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, opacity: 0 }} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
                    </div>
                </div>
                <div className='bottom scroll_bar_restyle'>
                    <List list={list} renderFunc={renderChart(props.info)} />
                </div>
                {
                    loading === true && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        </Modal>
    );
};

export default Comp;
