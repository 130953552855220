import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import style from './index.module.scss';
import { tabs } from './constants';
import { Tree } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import CommonContext from '../context';
import { post } from 'common/utils';
import { parseNode, findNode, needUpdate } from './utils';
import moment from 'moment';
import ICON from '../CardGroup/InfoCard/res/card.png'

const { DirectoryTree } = Tree;

const Comp = (props) => {
    // const { } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(!!tabs && tabs.length > 0 ? 0 : null);
    const commonInfo = useContext(CommonContext);
    const selectedDevice = useMemo(() => {
        const { state: { selectedDevice } } = commonInfo;
        return selectedDevice;
    }, [commonInfo]);
    const selectedKeys = useMemo(() => {
        if (!!selectedDevice) return [selectedDevice.key];
        return [];
    }, [selectedDevice]);

    // prev commonInfo
    const prevCommonInfo = useRef(null);

    const [treeData, setTreeData] = useState(null);
    const treeDataExisted = useRef(false);
    const [abnormalList, setAbnormalList] = useState(null);
    const abnormalKey2Item = useMemo(() => {
        if (!!abnormalList) {
            return Object.assign({}, ...(
                abnormalList.map((item) => ({ [item.tag_name]: item }))
            ));
        };
        return null;
    }, [abnormalList]);

    // get data
    const currCommonInfo = useRef(commonInfo);
    useEffect(() => {
        currCommonInfo.current = commonInfo;
    }, [commonInfo]);
    useEffect(() => {
        // console.log(commonInfo, prevCommonInfo.current);
        const nextNeedUpdate = needUpdate(currCommonInfo.current, prevCommonInfo.current) || true;
        // console.log(nextNeedUpdate);
        if (nextNeedUpdate) {
            const config = {
                time_type: 'DAY',
                day_begin: moment().format("YYYY-MM-DD"),
            };
            // console.log(config);
            if (!!config) {
                post(process.env.REACT_APP_SERVER_PATH + 'api/meter_node/get_meters_tree', config)
                    .then((retData) => {
                        // console.log(retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            // console.log(retData.data.results);
                            const { flat_list, results } = retData.data;
                            setAbnormalList(flat_list);
                            if (!treeDataExisted.current) {
                                const parsed_results = results.map((node) => parseNode(node));
                                setTreeData(parsed_results);
                                treeDataExisted.current = true;
                                if (parsed_results.length > 0) {
                                    const { dispatch } = currCommonInfo.current;
                                    dispatch({ type: 'deviceSelect', selectedDevice: parsed_results[0] });
                                };
                            };
                        };
                    });
            };

        };
    }, []);

    useEffect(() => {
        prevCommonInfo.current = commonInfo;
    }, [commonInfo]);

    const onSelect = (selectedKeys) => {
        const selectedKey = !!selectedKeys ? selectedKeys[0] : null;
        if (selectedKey !== null) {
            const selectedNode = findNode(treeData, selectedKey);
            if (!!selectedNode) {
                const { dispatch } = commonInfo;
                dispatch({ type: 'deviceSelect', selectedDevice: selectedNode });
            };
        };
    };

    return (
        <div className={style['wrapper']}>
            <div className="tab_row">
                {
                    tabs
                        .map((item, index) => {
                            const { name } = item;
                            return (
                                <div className={"tab_item" + (selectedTabIndex === index ? " selected_tab_item" : "")} key={index} onClick={() => setSelectedTabIndex(index)}>
                                    <div className="text">{name}</div>
                                </div>
                            );
                        })
                }
            </div>
            <div className="content_wrapper">
                <div className="tree_wrapper scroll_bar_restyle" style={selectedTabIndex === 1 ? { transform: 'translateX(100%)' } : {}}>
                    {
                        !!treeData && (
                            <DirectoryTree
                                showIcon={false}
                                defaultExpandAll={true}
                                selectedKeys={selectedKeys}
                                onSelect={onSelect}
                                treeData={treeData}
                                expandAction={false}
                                switcherIcon={(
                                    <div style={{ height: '100%' }}>
                                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <CaretDownOutlined style={{ color: '#00676B' }} />
                                        </div>
                                    </div>
                                )}
                                titleRender={(nodeData) => {
                                    const { name, tag_name } = nodeData;
                                    const abnormalItem = !!abnormalList && abnormalKey2Item[tag_name];
                                    return (
                                        <div>
                                            <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                                <div className="title_text">{name}</div>
                                                {
                                                    (!!abnormalItem) && (
                                                        <div style={{ flexShrink: 0, alignSelf: 'stretch', marginLeft: 6 }}>
                                                            <div className="dot_wrapper">
                                                                {
                                                                    (typeof abnormalItem.abnormal_num === 'number' && abnormalItem.abnormal_num > 0) && (
                                                                        <div className="red_dot">
                                                                            <div className="dot_text">{abnormalItem.abnormal_num}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    (typeof abnormalItem.sub_abnormal_num === 'number' && abnormalItem.sub_abnormal_num > 0) && (
                                                                        <div className="red_dot">
                                                                            <div className="dot_text">+{abnormalItem.sub_abnormal_num}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        )
                    }
                </div>
                {
                    selectedTabIndex === 1 && (
                        <div className="abnormal_wrapper scroll_bar_restyle">
                            {
                                !!abnormalList &&
                                abnormalList
                                    .map((item, index) => {
                                        const { abnormal_num } = item;
                                        if (typeof abnormal_num === 'number' && abnormal_num > 0) {
                                            const { name, tag_name } = item;
                                            return (
                                                <div
                                                    key={index}
                                                    className={"row" + ((!!selectedDevice && selectedDevice.tag_name === tag_name) ? " selected" : "")}
                                                    onClick={() => onSelect([tag_name])}
                                                >
                                                    <div className="title_text">{name}</div>
                                                    <div style={{ flexShrink: 0, alignSelf: 'stretch', marginLeft: 6 }}>
                                                        <div className="dot_wrapper">
                                                            <div className="red_dot">
                                                                <div className="dot_text">{abnormal_num}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        };
                                    })
                            }
                        </div>
                    )
                }
            </div>
        </div >
    );
};

export default Comp;
