import React from 'react'
import style from './inddex.module.scss'
const EnergyTotal = (props) => {
    const {
        feedGasTotalWeek,
        feedGasTotalMonth,
        feedGasTotalYear,
        electricTotalWeek,
        electricTotalMonth,
        electricTotalYear,
        waterTotalWeek,
        waterTotalMonth,
        waterTotalYear,
        nitrogenTotalWeek,
        nitrogenTotalMonth,
        nitrogenTotalYear
    } = props
    return (
        <div className={style['bigEnergyTotal']}>
            <div className="bigEnergyTotal-list">
                <div className="bigEnergyTotal-list-item">
                    <div className="item-top">
                        <span className="item-top-name">自耗气累计消耗</span>
                        <span className="item-top-type">万Nm³</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{feedGasTotalWeek}</span>
                    </div>
                    <div className="item-bottom">
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>月</span>
                        <span className="item-bottom-number" style={{ marginRight: 16 }}>{feedGasTotalMonth}</span>
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>年</span>
                        <span className="item-bottom-number">{feedGasTotalYear}</span>
                    </div>
                </div>
                <div className="bigEnergyTotal-list-item">
                    <div className="item-top">
                        <span className="item-top-name">电累计消耗</span>
                        <span className="item-top-type">万kWh</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{electricTotalWeek}</span>
                    </div>
                    <div className="item-bottom">
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>月</span>
                        <span className="item-bottom-number" style={{ marginRight: 16 }}>{electricTotalMonth}</span>
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>年</span>
                        <span className="item-bottom-number">{electricTotalYear}</span>
                    </div>
                </div>
            </div>


            <div className="bigEnergyTotal-list">
                <div className="bigEnergyTotal-list-item" >
                    <div className="item-top">
                        <span className="item-top-name">综合用水累计消耗</span>
                        <span className="item-top-type">t</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{waterTotalWeek}</span>
                    </div>
                    <div className="item-bottom" style={{paddingLeft: 20}}>
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>月</span>
                        <span className="item-bottom-number" style={{ marginRight: 16 }}>{waterTotalMonth}</span>
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>年</span>
                        <span className="item-bottom-number">{waterTotalYear}</span>
                    </div>
                </div>
                <div className="bigEnergyTotal-list-item">
                    <div className="item-top">
                        <span className="item-top-name">氮气累计消耗</span>
                        <span className="item-top-type">万Nm³</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{nitrogenTotalWeek}</span>
                    </div>
                    <div className="item-bottom">
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>月</span>
                        <span className="item-bottom-number" style={{ marginRight: 16 }}>{nitrogenTotalMonth}</span>
                        <span className="item-bottom-time" style={{ marginRight: 4 }}>年</span>
                        <span className="item-bottom-number">{nitrogenTotalYear}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EnergyTotal
