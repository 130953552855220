import React from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    const { tabs, selectedTab } = props;
    const onChange = (item) => {
        if (typeof props.onChange === 'function') {
            props.onChange(item);
        };
    };
    return (
        <div className={style['wrapper']}>
            <div className="tabs">
                {
                    (tabs ?? [])
                        .map((item, index, arr) => {
                            const { name } = item;
                            return [
                                (
                                    <div className="item" key={index} onClick={() => onChange(item)}>
                                        <div className={"text_wrapper" + (selectedTab?.key === item.key ? " selected" : "")}>
                                            <div className="text">{name}</div>
                                        </div>
                                    </div>
                                )
                            ];
                        })
                }
            </div>
        </div>
    );
};

export default Comp;
