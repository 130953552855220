import { getToken } from 'common/utils'

/** 导出文件到本地
 * @param fileUrl 下载地址
 * @param fileName 导出的文件名
 * @param type 请求方式 GET/POST
 */
function exportFile(fileUrl, fileName, type = 'GET') {
  const url = fileUrl
  const xhr = new XMLHttpRequest()
  xhr.open(type, url, true) // 也可以使用POST方式，根据接口
  xhr.responseType = 'blob' // 返回类型blob
  xhr.setRequestHeader('Authorization', getToken())
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
  // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
  xhr.onload = function () {
    // 请求完成
    if (this.status === 200) {
      // 返回200
      const blob = this.response
      const reader = new FileReader()
      reader.readAsDataURL(blob) // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.download = fileName
        a.href = e.target.result
        a.click()
        setTimeout(() => {
          document.body.removeChild(a)
        }, 0)
      }
    }
  }
  // 发送ajax请求
  xhr.send()
}

export default exportFile
