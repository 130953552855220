import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts~5';
import moment from 'moment';
import { Select } from 'antd';
import { useData } from './hooks';
import { significantNum } from 'common/utils';
import Modal from 'components/Modal';

const { Option } = Select;

const left = 128;
const right = 16;

const renderChart = (refNode, data, lower_limit, upper_limit) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!data) {
            instance.clear();
            return;
        };

        const xData = data.map(({ name }) => name);
        const yData = data.map(({ value }) => {
            let isGood = null;
            if (typeof value === 'number') {
                let lower_good = null;
                if (typeof lower_limit === 'number') {
                    if (lower_limit <= value) {
                        lower_good = true;
                    } else {
                        lower_good = false;
                    };
                };
                let upper_good = null;
                if (typeof upper_limit === 'number') {
                    if (upper_limit >= value) {
                        upper_good = true;
                    } else {
                        upper_good = false;
                    };
                };
                if (lower_good === false || upper_good === false) {
                    isGood = false;
                } else {
                    if (lower_good === true || upper_good === true) {
                        isGood = true;
                    };
                };
            };
            return {
                value,
                ...(
                    typeof isGood === 'boolean' ? {
                        itemStyle: {
                            color: isGood ? '#3AA372' : '#FB6060',
                        },
                    } : {}
                ),
            };
        });

        const allValues = yData
            .map(({ value }) => value)
            .concat([upper_limit, lower_limit])
            .filter((v) => typeof v === 'number');
        let y_min = 0;
        let y_max = 1;
        if (allValues.length > 0) {
            const min = Math.min(...allValues);
            const max = Math.max(...allValues);
            const delta = max - min + 1;
            // 使delta>0
            y_min = significantNum(min - delta / 10, 4, 2);
            y_max = significantNum(max + delta / 10, 4, 2);
            if (min >= 0 && y_min < 0) {
                y_min = 0;
            };
            if (max <= 0 && y_max > 0) {
                y_max = 0;
            };
        };

        instance.setOption({
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [{ axisValueLabel, data: { value }, marker }] = params;
                    const parsed_value = typeof value === 'number' ? Math.floor(value * 1000) / 1000 : "--";
                    return `${axisValueLabel}<br />${marker}${parsed_value}`;
                },
            },
            grid: {
                left,
                top: 34,
                right,
                bottom: 45,
            },
            xAxis: {
                type: 'category',
                data: xData,
                // axisLabel: {
                //     show: false,
                // },
                axisTick: {
                    show: false,
                },
                // axisLine: {
                //     lineStyle: {
                //         color: '#EFEFEF',
                //     },
                // },
            },
            yAxis: {
                type: 'value',
                // show: false,
                // scale: true,// true时不一定显示0
                min: y_min,
                max: y_max,
            },
            series: [
                {
                    data: yData,
                    type: 'bar',
                    barWidth: 24,
                    itemStyle: {
                        color: '#355B99',
                        borderRadius: 2,
                    },
                    markLine: {
                        silent: true,
                        symbol: 'none',
                        precision: 4,
                        lineStyle: {
                            type: "dashed",
                            color: "#FB6060",
                        },
                        label: {
                            show: false,
                        },
                        data: []
                            .concat(
                                typeof lower_limit === 'number' ? [
                                    {
                                        yAxis: lower_limit,
                                    }
                                ] : [],
                                typeof upper_limit === 'number' ? [
                                    {
                                        yAxis: upper_limit,
                                    }
                                ] : [],
                            ),
                    },
                },
            ]
        });
    };
};

let options = [
    {
        name: "本期同比",
        comp_type: "THIS_PERIOD",
    },
    {
        name: "上期同比",
        comp_type: "LAST_PERIOD",
    },
];

options = options.map((item, index) => Object.assign({}, item, { key: index + "" }));

const Comp = (props) => {
    const { info, timeInfo } = props;

    const [selected, setSelected] = useState(() => {
        return options?.[0] ?? null;
    });
    const compTypeOnChange = (v) => {
        const nextSelected = (options ?? []).filter(({ key }) => key === v)[0] ?? null;
        setSelected(nextSelected);
    };

    const handleCancel = () => {
        if (typeof props.onCancel === 'function') {
            props.onCancel();
        };
    };

    const [data, setData, loading] = useData();
    useEffect(() => {
        setData(Object.assign({}, timeInfo ?? {}, {
            base_id: info?.id,
            comp_type: selected?.comp_type,
        }));
    }, [info, timeInfo, selected]);

    const range_text = useMemo(() => {
        if (!!timeInfo) {
            const { time, range } = timeInfo;
            return moment(time).startOf(range).format("YYYY/MM/DD 00:00") + " - " + moment(time).endOf(range).format("YYYY/MM/DD 23:59");
        };
        return "--";
    }, [timeInfo]);

    const refNode = useRef(null);
    useEffect(() => {
        const { lower_limit, upper_limit } = info;
        renderChart(refNode.current, data?.comp_list, lower_limit, upper_limit);
    }, [data, info]);

    return (
        <Modal
            title="历史对标"
            visible={true}
            footer={null}
            onCancel={handleCancel}
            width={760}
            centered={true}
        >
            <div className={style["wrapper"]}>
                <div className="top">
                    <table>
                        <tbody>
                            <tr>
                                <td className="title">指标名称</td>
                                <td className="value">{data?.basic_info?.name ?? ""}</td>
                                <td className="title">指标类型</td>
                                <td className="value">{data?.basic_info?.category_name ?? ""}</td>
                                <td className="title">产品类型</td>
                                <td className="value">{data?.basic_info?.product_name ?? ""}</td>
                            </tr>
                            <tr>
                                <td className="title">当前时段</td>
                                <td className="value">{range_text}</td>
                                <td className="title">对标时段</td>
                                <td className="value">
                                    <Select value={selected?.key} onChange={compTypeOnChange} style={{ width: 112 }}>
                                        {
                                            (options ?? [])
                                                .map((item, index) => {
                                                    return (
                                                        <Option value={item.key} key={index}>{item.name}</Option>
                                                    );
                                                })
                                        }
                                    </Select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mid">
                    <div className="chart" ref={refNode}></div>
                </div>
                <div className="bottom" style={{ paddingRight: right }}>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan="2" style={{ width: left }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="title">指标均值</td>
                                <td>{data?.basic_info?.unit ?? ""}</td>
                                {
                                    (data?.parsed_comp_list ?? [])
                                        .map((item, index) => {
                                            return (
                                                <td key={index}>{item.parsed_value}</td>
                                            );
                                        })
                                }
                            </tr>
                            <tr>
                                <td className="title">对标比较</td>
                                <td>%</td>
                                {
                                    (data?.parsed_comp_list ?? [])
                                        .map((item, index) => {
                                            const { comp_pct, status } = item;
                                            return (
                                                <td key={index} className={"comp" + (status === 0 ? " green" : (status === 1 ? " red" : ""))}>
                                                    <div className="content">
                                                        {
                                                            (typeof comp_pct === 'number' && comp_pct !== 0) ? (
                                                                <div className={"arrow" + (comp_pct > 0 ? " up" : " down")}></div>
                                                            ) : null
                                                        }
                                                        <div>{item.parsed_comp_pct}</div>
                                                    </div>
                                                </td>
                                            );
                                        })
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal >
    );
};

export default Comp;
