import React from 'react';
import { } from 'antd';
import Content from './Content.js';

import './AlarmSetting.scss';

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="alarm_setting_QZmFx">
                <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
                <div className="root_content_wrapper">
                    <Content history={this.props.history} />
                </div>
            </div>
        );
    }
}
