import moment from 'moment';

const defaultTimeTypeList = [
    {
        type: 'date',
        basic_index: 0,
    },
    {
        type: 'week',
        basic_index: 2,
    },
    {
        type: 'month',
        basic_index: 4,
    }
];

const getSuffixIndexByTime = (timeType, time, timeTypeList = defaultTimeTypeList) => {
    return timeTypeList.reduce((pV, cItem) => {
        if (pV !== null) {
            return pV;
        };
        const { type, basic_index } = cItem;
        if (timeType === type) {
            // time 是否是moment偏移量，暂无检查
            return basic_index + (moment(time).isSame(moment(), type) ? 0 : 1);
        };
        return pV;
    }, null);
};

const getSuffix = (position, timeType, time, suffixList, timeTypeList) => {
    const index = getSuffixIndexByTime(timeType, time, timeTypeList);
    if (index === null) { return '' };
    let suffix = '';
    try {
        suffix = suffixList[position][index]
    } catch (err) { };
    return suffix;
};

const getSuffixFunc = (suffixList, timeTypeList) => {
    return (position, timeType, time) => {
        return getSuffix(position, timeType, time, suffixList, timeTypeList);
    };
}

export {
    getSuffix,
    getSuffixFunc,
    getSuffixIndexByTime,
};
