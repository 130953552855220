import React from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    return (
        <div className={style['wrapper']}>
            <div className="top">{props?.title ?? null}</div>
            <div className="bottom">{props?.children ?? null}</div>
        </div>
    );
};

export default Comp;
