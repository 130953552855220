import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time, id: sys_id } = conf;
            if (typeof time !== 'string' || typeof sys_id !== 'number') {
                setResults(null);
                setLoading(false);
                return;
            };
            let requesting = true;
            setLoading(true);
            const formated_time = moment(time).format("YYYY-MM-DD");
            post(process.env.REACT_APP_SERVER_PATH + 'api/factors/get_factors_on_shifts', {
                day: formated_time,
                sys_id,
            })
                .then((retData) => {
                    if (requesting) {
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResults = retData.data;
                            let parsed_results = nextResults.results;
                            if (!!parsed_results) {
                                parsed_results = parsed_results
                                    .map((item, index) => {
                                        const {
                                            avg_value, lower_limit, upper_limit, cpl_rate,
                                            avg_0, avg_1, avg_2,
                                            cpl_0, cpl_1, cpl_2,
                                            cpl_results,
                                        } = item;

                                        // avg_value
                                        const [parsed_avg_value, parsed_avg_0, parsed_avg_1, parsed_avg_2] = [avg_value, avg_0, avg_1, avg_2]
                                            .map((v) => typeof v === 'number' ? significantNum(v, 4) : "--");

                                        let parsed_standard = "--";
                                        if (typeof lower_limit === 'number' && typeof upper_limit === 'number') {
                                            parsed_standard = significantNum(lower_limit, 4) + "~" + significantNum(upper_limit, 4);
                                        } else {
                                            if (typeof lower_limit === 'number') {
                                                parsed_standard = significantNum(lower_limit, 4);
                                            } else if (typeof upper_limit === 'number') {
                                                parsed_standard = significantNum(upper_limit, 4);
                                            };
                                        };

                                        // cpl_rate
                                        const [parsed_cpl_rate, parsed_cpl_0, parsed_cpl_1, parsed_cpl_2] = [cpl_rate, cpl_0, cpl_1, cpl_2]
                                            .map((v) => (typeof v === 'number' ? significantNum(v * 100, 3, 2) : "--") + "%");

                                        // is good cpl
                                        const [is_good_cpl, is_good_cpl_0, is_good_cpl_1, is_good_cpl_2] = [cpl_rate, cpl_0, cpl_1, cpl_2]
                                            .map((v) => typeof v === 'number' ? (v < 0.75 ? false : true) : null);

                                        let parsed_cpl_results = null;
                                        if (!!cpl_results && cpl_results.length === 3) {
                                            const hm_start_str = ['00:00', '08:00', '16:00'];
                                            const shift_hours = 8;
                                            parsed_cpl_results = cpl_results
                                                .map((item, index) => {
                                                    let parsed_item = [];
                                                    if (!!item) {
                                                        const hm_str = hm_start_str[index];
                                                        parsed_item = item
                                                            .reduce((pV, cV) => {
                                                                let conf = null;

                                                                const { status, time_begin, time_end } = cV;
                                                                const color = status === 1 ? "rgba(64, 161, 225, 1)" : (status === 0 ? "rgba(255, 97, 112, 1)" : "rgba(128, 128, 128, 0.2)");
                                                                const range_begin_unix = moment(moment(time_begin).format(`YYYY-MM-DD ${hm_str}`)).unix();
                                                                const range_end_unix = moment(moment(time_begin).format(`YYYY-MM-DD ${hm_str}`)).add(shift_hours, 'hours').unix();

                                                                const time_begin_unix = moment(time_begin).unix();
                                                                const time_end_unix = moment(time_end).unix();

                                                                const left = (time_begin_unix - range_begin_unix) / (range_end_unix - range_begin_unix) * 100 + "%";
                                                                const width = (time_end_unix - time_begin_unix) / (range_end_unix - range_begin_unix) * 100 + "%";

                                                                conf = {
                                                                    color,
                                                                    left,
                                                                    width,
                                                                }

                                                                return pV
                                                                    .concat(!!conf ? [conf] : []);
                                                            }, []);
                                                    };
                                                    return parsed_item;
                                                });
                                        };

                                        return Object.assign({}, item, {
                                            key: index + "",// 用来做唯一标识，不信任的产物
                                            parsed_avg_value,
                                            parsed_avg_0,
                                            parsed_avg_1,
                                            parsed_avg_2,
                                            parsed_standard,
                                            parsed_cpl_rate,
                                            parsed_cpl_0,
                                            parsed_cpl_1,
                                            parsed_cpl_2,
                                            is_good_cpl,
                                            is_good_cpl_0,
                                            is_good_cpl_1,
                                            is_good_cpl_2,
                                            parsed_cpl_results,
                                        });
                                    });
                            };

                            nextResults = Object.assign({}, nextResults, {
                                parsed_results,
                            });
                            // console.log(nextResults);
                            setResults(nextResults);
                        } else {
                            // 处理失败
                            setResults(null);
                        };
                        // final
                        setLoading(false);
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [results, setConf, loading];
};

export default Hook;
