
const group_E_tags = {
    tag_1: '_HDQOQ',
    tag_2: '_HD',
    tag_3: '_HW',
    tag_4: '_HM',
};

const group_E = {
    name: '自耗气分解',
    children: [
        {
            name: '导热油锅炉耗气',
            basic_tag: 'AUX_OBLR_FUEL_CUM',
            unit: '万kWh',
            ...group_E_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: '高低压火炬耗气',
            basic_tag: 'AUX_FLARE_FUEL_CUM',
            unit: '万kWh',
            ...group_E_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: 'DGS放空耗气',
            basic_tag: 'COMP_DGS_SEALG_CUM',
            unit: '万kWh',
            ...group_E_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: '其它耗气',
            basic_tag: 'PLANT_OTHER_FUEL_CUM',
            unit: '万kWh',
            ...group_E_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
    ],
};

const group_F_tags = {
    tag_1: '_HDQOQ',
    tag_2: '_HD',
    tag_3: '_HW',
    tag_4: '_HM',
};

const group_F = {
    name: '耗电分解',
    children: [
        {
            name: '原料气压缩机耗电',
            basic_tag: 'COMP_MOTOR_ELEC_CUM',
            unit: '万kWh',
            ...group_F_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 1,
        },
        {
            name: '丙烷制冷机耗电',
            basic_tag: 'C3CHL_C3CHL_ELEC_CUM',
            unit: '万kWh',
            ...group_F_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 1,
        },
        {
            name: '工艺设备耗电',
            basic_tag: 'PLANT_PID_ELEC_CUM',
            unit: '万kWh',
            ...group_F_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 1,
        },
        {
            name: '压缩机MCC',
            basic_tag: 'PLANT_OTHER_ELEC_CUM',
            unit: '万kWh',
            ...group_F_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 1,
        },
    ],
};

export {
    group_E,
    group_F,
};
