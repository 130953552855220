const Suffix_List = (() => {
    const list = {
        a: ['D', 'D', 'W', 'W', 'M', 'M'],
        b: ['HDQOQ', 'HDQOQ', 'HWQOQ', 'HWQOQ', 'HMQOQ', 'HMQOQ'],
        chart: ['D', 'D', 'D', 'D', 'D', 'D'],
    };
    return Object.assign({}, ...(Object.keys(list).map((key) => ({ [key]: list[key].map((tag) => `_${tag}`) }))));
})();

const Extd_Tag = '_kWhZ';

export {
    Extd_Tag,
    Suffix_List,
};
