import MFZB from './res/MFZB.svg'
import SLSC from './res/SLSC.svg'
import SNFM from './res/SNFM.svg'
import YLFM from './res/YLFM.svg'

const rightTableHeaderSortableThs = [
    {
        name: '权重占比',
        key: 'weight',
    },
    {
        name: '统计方法',
        key: 'scoreMethod',
    },
    {
        name: '排名',
        key: 'rank',
    },
    {
        name: '排名得分',
        key: 'rank_score',
    },
    {
        name: '总得分',
        key: 'total_score',
    },
];
const SCORE_METHODS = [
    {
        id: 2,
        name: '平均',
        // }, {
        //     id: 2,
        //     name: '累积',
    }, {
        id: 3,
        name: '区间',
    }, {
        id: 4,
        name: '限值',
    }
];

const YXSN_SYSTEM_ICON = {
    1: SLSC,
    2: SNFM,
    3: YLFM,
    4: MFZB,
};
const SF_SYSTEM_ICON = {
    1: MFZB,
    2: SLSC,
    3: SNFM,
    4: SNFM,
    5: YLFM,
    6: YLFM,
};
const MXSN_SYSTEM_ICON = {
    1: MFZB,
    2: SLSC,
    3: SNFM,
    4: YLFM,
};
const DANFENG_SYSTEM_ICON = {
    1: MFZB,
    2: SLSC,
    3: SNFM,
    4: SNFM,
    5: YLFM,
};
const FUPING_SYSTEM_ICON = {
    1: SLSC,
    2: SNFM,
    3: SNFM,
    4: YLFM,
    5: MFZB,
};
const CHANGZHI_SYSTEM_ICON = {
    1: MFZB,
    2: SLSC,
    3: SNFM,
    4: YLFM,
};
const DEFAULT_SYSTEM_ICON = YXSN_SYSTEM_ICON; // for demo
const SYS_2_ICON_CONFIG = {
    'yxsn': YXSN_SYSTEM_ICON,
    'shifeng': SF_SYSTEM_ICON,
    'mxsn': MXSN_SYSTEM_ICON,
    'danfeng': DANFENG_SYSTEM_ICON,
    'fuping': FUPING_SYSTEM_ICON,
    'changzhi': CHANGZHI_SYSTEM_ICON,
    'xinchen': YXSN_SYSTEM_ICON,
}
const CURR_SYSTEM_ICON = SYS_2_ICON_CONFIG[process.env.REACT_APP_PROJECT_TAG] || DEFAULT_SYSTEM_ICON;

const BOTTOM_CHART_HEIGHT = 225;

export {
    rightTableHeaderSortableThs,
    SCORE_METHODS,
    BOTTOM_CHART_HEIGHT,
    CURR_SYSTEM_ICON,
};