import React from 'react';

import ProduceStatistics from './ProduceStatistics.js';
import ProductElectricalAnalysisComp from '../ProductElectricalAnalysis/ProductElectricalAnalysisComp.js';

import './ProduceAnalysis.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null,
        };
    }

    componentDidMount() {
    }

    itemOnClick = (index) => {
        this.setState({ selectedIndex: index });
    }

    render() {

        return (
            <div className="produce_analysis_wrapper scroll_bar_restyle">
                <div className="content_wrapper">
                    <div className="content_root_title">生产分析</div>

                    <div
                        style={{ marginTop: 16 }}
                    >
                        <ProduceStatistics />
                    </div>

                    <div
                        style={{ marginTop: 16 }}
                    >
                        <ProductElectricalAnalysisComp title={'产能分析'} />
                    </div>

                </div>
            </div>
        );
    }
};

export default Page;