import React from 'react';
import { Button, Select, DatePicker, Radio, Table, message } from 'antd';
import { get, post } from '../../../common/utils.js';
import moment from 'moment';
import echarts from 'echarts';
import './HistoryData.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const SELECTED_DATAMETA_LIST_LOCAL = 'HISTORY_DATA_SELECTED_DATAMETA_LIST';
const INTERVAL_LIST = [{
    interval: 10,
    name: '10秒',
}, {
    interval: 30,
    name: '30秒',
}, {
    interval: 60,
    name: '1分钟',
}, {
    interval: 300,
    name: '5分钟',
}, {
    interval: 600,
    name: '10分钟',
}];
export default class HistoryData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataMetaList: [],
            selectedDataMeta: null,
            selectedDataMetaList: [],
            selectedRowKeys: [],
            interval: (INTERVAL_LIST[0] || {}).interval || null,
            timeRange: null,
            chartDatas: [],
            chartColumnNum: 1,
            systems: [],
            sys_id: '',
        };
    }

    componentDidMount() {
        this.getSystems();
    }
    getSystems = () => {
        get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_factor_theme_list')
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    const systems = retData.data.results || [];
                    let sys_id = '';
                    if (systems.length > 0) {
                        sys_id = systems[0].id;
                    };
                    this.setState({
                        systems,
                        sys_id,
                    }, () => {
                        this.getDataMetas();
                        this.initLocalData();
                    });
                } else {
                };
            });
    }
    initLocalData = () => {
        const { sys_id } = this.state;
        if (!sys_id) return;
        try {
            const selectedDataMetaList = JSON.parse(window.localStorage.getItem(SELECTED_DATAMETA_LIST_LOCAL + '_' + sys_id));
            if (selectedDataMetaList) this.setState({ selectedDataMetaList });
        } catch (e) { }
    }
    getDataMetas = (keyword) => {
        const { sys_id } = this.state;
        if (!sys_id) return;
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            ...!keyword ? {} : { keyword },
            page: 1,
            page_size: 10,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                const { selectedDataMeta } = this.state;
                const dataMetaList = retData.data.results;
                if (selectedDataMeta && dataMetaList.filter(({ _id }) => _id === selectedDataMeta._id).length === 0) {
                    dataMetaList.unshift(selectedDataMeta);
                }
                this.setState({ dataMetaList })
            }
        });
    }
    dataMetaOnChange = dataMetaId => {
        const { dataMetaList } = this.state;
        const selectedDataMeta = dataMetaList.filter(({ _id }) => (_id + '') === (dataMetaId + ''))[0] || null;
        if (selectedDataMeta) {
            this.setState({ selectedDataMeta });
        }
    }
    addDataMeta = () => {
        const { selectedDataMeta, selectedDataMetaList, sys_id } = this.state;
        if (!selectedDataMeta) {
            message.error('未选择点位');
            return;
        };
        if (selectedDataMetaList.filter(({ _id }) => _id === selectedDataMeta._id).length === 0) {
            selectedDataMetaList.unshift(selectedDataMeta);
            this.setState({ selectedDataMetaList }, () => {
                try {
                    window.localStorage.setItem(SELECTED_DATAMETA_LIST_LOCAL + '_' + sys_id, JSON.stringify(selectedDataMetaList));
                } catch (e) { }
            });
        }
        this.setState({ selectedDataMeta: null });
    }
    verify = () => {
        const { timeRange, interval, selectedRowKeys } = this.state;
        if (!interval) {
            message.error('未选择频率');
            return false;
        }
        if (!timeRange) {
            message.error('未选择时段');
            return false;
        }
        if (selectedRowKeys.length === 0) {
            message.error('未选择点位');
            return false;
        }
        const [time_begin, time_end] = timeRange;
        if (moment(time_begin).add(1, 'days').isBefore(moment(time_end))) {
            message.error('选择时段不得超过24小时');
            return false;
        }
        return true;
    }
    exportData = () => {
        if (!this.verify()) return;
        const { timeRange: [time_begin, time_end], interval, selectedRowKeys } = this.state;

        let url = 'https://yxsnali-api.ycim.tech';
        let loc = window.location.href + '';
        if (loc.indexOf('192.168.7.1') >= 0) {
            url = 'http://192.168.7.1:91';
        } else if (loc.indexOf('192.168.1.122') >= 0) {
            url = 'http://192.168.1.122:91';
        } else if (loc.indexOf('192.168.12.129') >= 0) {
            url = 'http://192.168.12.129:91';
        }

        window.open(
            `${url}/api/data/export_history_data?time_interval=${interval
            }s&time_begin=${moment(time_begin).format('YYYY-MM-DD HH:mm:00')
            }&time_end=${moment(time_end).format('YYYY-MM-DD HH:mm:00')
            }&data_meta_ids=${selectedRowKeys.join()
            }`,
            '_blank'
        );
    }
    watchCharts = () => {
        if (!this.verify()) return;
        const { timeRange: [time_begin, time_end], interval, selectedRowKeys, selectedDataMetaList } = this.state;
        post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data_sep_time', {
            time_type: 'seconds',
            tag_list: selectedRowKeys
                .map(key => selectedDataMetaList.filter(({ _id }) => _id === key)[0] || null)
                .filter(item => item !== null)
                .map(({ tag_name }) => ({
                    tag_name,
                    time_begin: moment(time_begin).format('YYYY-MM-DD HH:mm:00'),
                    time_end: moment(time_end).format('YYYY-MM-DD HH:mm:00'),
                    interval,
                }))
        })
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    this.setState({ chartDatas: retData.data.results }, this.drawCharts);
                }
            });
    }
    drawCharts = () => {
        const { chartColumnNum, chartDatas } = this.state;
        chartDatas.forEach(({ tag_name, data_list }, index) => {
            const dom = document.getElementById(tag_name);
            if (dom) {
                echarts.dispose(dom);
                var myChart = echarts.init(dom);
                myChart.setOption({
                    grid: {
                        left: 60,
                        top: 20,
                        right: 18,
                        bottom: 30,
                    },
                    xAxis: {
                        type: 'time',
                        // show: false,
                        axisLine: {
                            // show: false,
                            lineStyle: {
                                color: '#3A3F46',
                            },
                        },
                        axisLabel: {
                            // show: false,
                            fontSize: 11,
                            color: '#9FA0BA',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    yAxis: {
                        scale: true,
                        // show: false,
                        splitLine: {
                            lineStyle: {
                                color: '#3A3F46',
                            },
                        },
                        axisLabel: {
                            // show: false,
                            fontSize: 11,
                            color: '#9FA0BA',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    series: [{
                        data: data_list
                            .filter(([_, v]) => v !== null)
                            .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                        type: 'line',
                        symbol: 'none',
                        lineStyle: {
                            color: chartColumnNum > 1 ? ((index % 2 === 0) ? 'rgb(36,149,253)' : '#4A9B87') : "rgb(36,149,253)",
                            width: 2,
                        },
                    }]
                });
            }
        });
    }
    deleteDataMeta = id => {
        const { selectedDataMetaList, selectedRowKeys, sys_id } = this.state;
        this.setState({
            selectedRowKeys: selectedRowKeys.filter(key => key !== id),
            selectedDataMetaList: selectedDataMetaList.filter(({ _id: s_id }) => s_id !== id),
        }, () => {
            try {
                window.localStorage.setItem(SELECTED_DATAMETA_LIST_LOCAL + '_' + sys_id, JSON.stringify(this.state.selectedDataMetaList));
            } catch (e) { }
        });
    }
    render() {
        const {
            dataMetaList, selectedDataMeta,
            selectedDataMetaList, selectedRowKeys, interval,
            timeRange, chartDatas, chartColumnNum,
            systems, sys_id,
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: selectedRowKeys => this.setState({ selectedRowKeys }),
        };
        return (
            <div className="history_data_wrapper">
                <div className="history_data_header_wrapper">
                    <span style={{ marginLeft: 20 }}>所属系统</span>
                    <Select
                        value={sys_id + ''}
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={v => {
                            this.setState({ sys_id: parseInt(v) }, () => {
                                this.getDataMetas();
                                this.initLocalData();
                            });
                        }}
                    >
                        {
                            systems.map(item =>
                                <Option value={item.id + ''} key={item.id}>{item.name}系统</Option>)
                        }
                    </Select>
                    <div className="divide_line"></div>
                    <div className="opt_title">选中时段</div>
                    <RangePicker
                        showTime={{ format: 'HH:mm:ss' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder={['开始时间', '结束时间']}
                        value={timeRange}
                        onChange={timeRange => this.setState({ timeRange })}
                    />
                    <div className="opt_title">采样频率</div>
                    <Select style={{ width: 200, marginRight: 'auto' }} value={interval} onChange={interval => this.setState({ interval })}>
                        {
                            INTERVAL_LIST.map(({ interval, name }, index) => (
                                <Option value={interval} key={index}>{name}</Option>
                            ))
                        }
                    </Select>
                    <Button
                        type="primary"
                        ghost
                        // disabled={!timeRange || !interval || selectedRowKeys.length === 0}
                        onClick={this.watchCharts}
                    >查看曲线</Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={this.exportData}
                    >数据导出</Button>
                </div>
                <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
                <div className="history_data_content_wrapper">
                    <div className="content_wrapper">
                        <div className="content_top_header">
                            <div style={{ marginLeft: 'auto', marginRight: 24 }}>显示</div>
                            <Radio.Group
                                value={chartColumnNum}
                                onChange={e => this.setState({ chartColumnNum: e.target.value }, this.drawCharts)}
                            >
                                <Radio value={1}>一列</Radio>
                                <Radio value={2}>二列</Radio>
                            </Radio.Group>
                        </div>
                        <div className="content_bottom_wrapper">
                            <div className="left">
                                <div className="top_bar">
                                    <Select
                                        style={{ width: 400, marginRight: 24 }}
                                        value={selectedDataMeta && (selectedDataMeta._id + '')}
                                        onChange={this.dataMetaOnChange}
                                        showSearch
                                        onSearch={this.getDataMetas}
                                        filterOption={false}
                                        // showArrow={false}
                                        defaultActiveFirstOption={false}
                                    >
                                        {
                                            dataMetaList.map((item) => {
                                                const { _id, name, tag_name } = item;
                                                return (
                                                    <Option key={_id}>{name} {tag_name}</Option>
                                                );
                                            })
                                        }
                                    </Select>
                                    <Button
                                        type="primary"
                                        // disabled={!selectedDataMeta}
                                        onClick={this.addDataMeta}
                                    >添加</Button>
                                </div>
                                <Table
                                    dataSource={selectedDataMetaList.map(item => ({
                                        ...item,
                                        key: item._id,
                                    }))}
                                    rowSelection={rowSelection}
                                    pagination={false}
                                    columns={[{
                                        title: '点位名称',
                                        dataIndex: 'tag_name',
                                    }, {
                                        title: '点位描述',
                                        dataIndex: 'name',
                                    }, {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, { _id }) => (
                                            <Button type="link" onClick={() => this.deleteDataMeta(_id)}>删除</Button>
                                        )
                                    }]}
                                    scroll={{ y: true }}
                                />
                            </div>
                            <div className="right scroll_bar_restyle" style={chartColumnNum > 1 ? {} : { display: 'block' }}>
                                {
                                    chartDatas.map((item, index) => {
                                        const { tag_name } = item;
                                        return (
                                            <div
                                                className="chart_wrapper"
                                                key={index}
                                                style={{
                                                    ...chartColumnNum > 1 ? { width: `${Math.floor(98 / chartColumnNum)}%` } : {},
                                                    ...index >= chartColumnNum ? { marginTop: 12 } : {},
                                                }}
                                            >
                                                <div className="title">
                                                    指标名称
                                                    <span>{tag_name}</span>
                                                    <span>
                                                        {
                                                            (selectedDataMetaList
                                                                .filter(({ tag_name: tagName }) => tagName === tag_name)[0] || {})
                                                                .name || ''
                                                        }
                                                    </span>
                                                </div>
                                                <div id={tag_name} style={{ height: 200 }} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
