import ICON_2 from './res/icon_2.svg';
import ICON_3 from './res/icon_3.svg';
import ICON_4 from './res/icon_4.svg';
import ICON_5 from './res/icon_5.svg';
import ICON_8 from './res/icon_8.svg';
import ICON_9 from './res/icon_9.svg';

const factors_datas = [
    {
        title: '生产',
        category: 'PROD',
        icon: ICON_9,
    },
    {
        title: '能耗',
        category: 'ENRG',
        icon: ICON_2,
    },
    {
        title: '原料',
        category: 'MATL',
        icon: ICON_3,
    },
    {
        title: '运行',
        category: 'OPRN',
        icon: ICON_4,
    },
    {
        title: '质量',
        category: 'QLTY',
        icon: ICON_5,
    },
];

const table_datas = [
    {
        title: '系统状态',
        icon: ICON_8,
        path: 'api/kpi/brief/get_sys_cpl_status',
        columns: [
            {
                title: '系统名称',
                dataIndex: 'sys_name',
                align: 'center',
            },
            {
                title: '生产',
                dataIndex: 'prod_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
                category: "PROD",
            },
            {
                title: '能耗',
                dataIndex: 'enrg_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
                category: "ENRG",
            },
            {
                title: '原料',
                dataIndex: 'matl_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
                category: "MATL",
            },
            {
                title: '运行',
                dataIndex: 'oprn_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
                category: "OPRN",
            },
            {
                title: '质量',
                dataIndex: 'qlty_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
                category: "QLTY",
            },
        ],
    },
    {
        title: '运行状态',
        icon: ICON_9,
        path: 'api/kpi/brief/get_oprn_status',
        columns: [
            {
                title: '系统名称',
                dataIndex: 'sys_name',
                align: 'center',
            },
            {
                title: '运行时长',
                dataIndex: 'run_hours',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },
            {
                title: '计划内停机时长',
                dataIndex: 'stop_hours',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },
            {
                title: '异常时长',
                dataIndex: 'abnormal_hours',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },//??? 前三列颜色黑色，最后列大于0的格子显示红色。
            // {
            //     title: '低负荷运行时长',
            //     dataIndex: 'low_range',
            //     align: 'center',
            // },
        ],
    },
];

export {
    factors_datas,
    table_datas,
};
