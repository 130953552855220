import React from 'react';
import { queryTimeRangeFormat } from './utils.js';
import { post } from '../../../common/utils.js';

import QualityItem from './QualityItem.js';

import './QualityComp.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kpiList: null,
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            this.refreshData();
        };
    }

    refreshData = () => {
        const { timeRange } = this.props;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        post(process.env.REACT_APP_SERVER_PATH + 'api/board/index_page/kpi_block', {
            cpl_rule_id: 1,
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ kpiList: retData.data.results });
            };
        });
    }

    render() {
        const { kpiList } = this.state;

        return (
            <div className="main_page_bottom_panels_quality_comp_wrapper scroll_bar_restyle">
                <div className="comp_content_wrapper">
                    {
                        kpiList &&
                        kpiList
                            .map((item, index) => {
                                const { cpl_rate, factor_id, factor_name, failed_num } = item;
                                return (
                                    <div key={factor_id} className="item_position_wrapper">
                                        <QualityItem item={{ name: factor_name, rate: parseFloat(cpl_rate) * 100, warning: failed_num }} />
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
