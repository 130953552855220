import { useEffect, useState } from 'react';
import { post, get } from 'common/utils';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (typeof conf?.code !== 'string') {
            setLoading(false);
            setResult(null);
            return;
        };
        let delay = true;
        setLoading(true);
        if (typeof conf.id === 'string') {
            get(process.env.REACT_APP_SERVER_PATH + `api/form/get_one/${conf.id}`, {})
                .then((retData) => {
                    if (delay) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (retData?.data?.status === 1) {
                            let nextResult = retData.data;
                            let parsed_result = nextResult;
                            if (!!parsed_result) {
                                let parsed_tpl_json = null;
                                let parsed_content = null;
                                try {
                                    parsed_tpl_json = JSON.parse(parsed_result.tpl_json)?.schema ?? null;
                                    parsed_content = JSON.parse(parsed_result.content);
                                } catch { };
                                parsed_result = Object.assign({}, parsed_result, {
                                    parsed_tpl_json,
                                    parsed_content,
                                });
                            };
                            nextResult = Object.assign({}, nextResult, {
                                parsed_result,
                            });
                            setResult(nextResult);
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                    };
                });
        } else {
            post(process.env.REACT_APP_SERVER_PATH + 'api/form/get_one_form_tpl', {
                code: conf.code,
            })
                .then((retData) => {
                    if (delay) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (retData?.data?.errcode === 0) {
                            let nextResult = retData.data;
                            let parsed_result = nextResult.result;
                            if (!!parsed_result) {
                                let parsed_tpl_json = null;
                                try {
                                    parsed_tpl_json = JSON.parse(parsed_result.tpl_json)?.schema ?? null;
                                } catch { };
                                parsed_result = Object.assign({}, parsed_result, {
                                    parsed_tpl_json,
                                });
                            };
                            nextResult = Object.assign({}, nextResult, {
                                parsed_result,
                            });
                            setResult(nextResult);
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                    };
                });
        };
        return () => {
            delay = false;
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
