import React from 'react';

export default class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const { icon, title, subTitle, children } = this.props;

        return (
            <div
                style={{
                    border: '1px solid rgba(68, 242, 217, 0.2)'
                }}
            >
                <div
                    style={{
                        height: 47,
                        borderBottom: '1px solid rgba($color: #44F2D9, $alpha: 0.2)',
                        paddingLeft: 12,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#0D1115',
                    }}
                >
                    {
                        icon !== undefined && (
                            <img
                                alt=""
                                src={icon}
                                style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 5,
                                }}
                            />
                        )
                    }
                    {
                        title !== undefined && (
                            <div
                                style={{
                                    height: 18,
                                    fontSize: 18,
                                    color: '#FFFFFF',
                                    lineHeight: '18px',
                                }}
                            >{title}</div>
                        )
                    }
                    {
                        subTitle !== undefined && (
                            <div
                                style={{
                                    height: 16,
                                    fontSize: 16,
                                    color: '#9FA0BA',
                                    lineHeight: '16px',
                                    marginLeft: 12,
                                }}
                            >{subTitle}</div>
                        )
                    }
                </div>
                <div
                    style={{
                        padding: 16,
                        backgroundColor: 'rgba(33, 40, 48, 1)',
                    }}
                >
                    <div>{children}</div>
                </div>
            </div>
        );
    }
}
