import {
    setValues,
} from '../../../common/redux/System/actions.js';
import { post, get } from '../../../common/utils';

const getCustomKPIList = (dispatch, getState) => {
    return get(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_favorite_kpi_list', {})
        .then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ customFactors: retData.data.results }));
                return true;
            };
            return false;
        });
};

const updateFavouriteKPI = (factor_id, status) => {
    return (dispatch, getState) => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/set_favorite_kpi', {
            factor_id,
            status,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                return true;
            };
            return false;
        });
    };
};

export {
    getCustomKPIList,
    updateFavouriteKPI,
};