import React, { useMemo, useState } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize';

const StandardWidthHeight = {
    width: 1920,
    height: 1080,
};

const Comp = (props) => {
    const [widthHeight] = useState(() => {
        const props_width = props?.width ?? null;
        const props_height = props?.height ?? null;
        if (typeof props_width === 'number' && typeof props_height === 'number') {
            return {
                width: props_width,
                height: props_height,
            }
        };
        return StandardWidthHeight;
    });

    const [scaledStyle, setScaledStyle] = useState(null);
    const onResize = (conf) => {
        const { width: outerWidth, height: outerHeight } = conf;
        const { width, height } = widthHeight;
        let nextScaledStyle = null;
        if (![width, height, outerWidth, outerHeight].includes(0)) {
            if (width * outerHeight > outerWidth * height) {
                // 竖条内嵌图
                const scaleValue = outerWidth / width;
                nextScaledStyle = {
                    transformOrigin: "left top 0",
                    transform: `scale(${scaleValue}) translate(-50%,-50%)`,
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                };
            } else {
                // 横条内嵌图
                const scaleValue = outerHeight / height;
                nextScaledStyle = {
                    transformOrigin: "left top 0",
                    transform: `scale(${scaleValue}) translate(-50%,-50%)`,
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                };
            };
        };
        setScaledStyle(nextScaledStyle);
    };

    const addonStyle = useMemo(() => {
        if (!!props) {
            const { bckColor } = props;
            if (typeof bckColor === 'string') {
                return {
                    backgroundColor: bckColor,
                };
            };
        };
        return null;
    }, [props]);

    return (
        <div className={style["wrapper"]} style={!!addonStyle ? addonStyle : {}}>
            <div className="resize_wrapper">
                <DomResize mountedCall={true} onResize={onResize} />
            </div>
            {
                !!scaledStyle && (
                    <div className="scaled_wrapper" style={scaledStyle}>
                        <div style={{
                            height: widthHeight.height,
                            width: widthHeight.width,
                            overflow: 'hidden',
                        }}>
                            {props.children}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
