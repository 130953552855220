import React, { useEffect, useMemo, useState } from 'react'
import style from './index.module.scss'
import TopBreadcrumb from 'components/TopBreadCrumb'
import TplTree from './TplTree'
import EventTable from './EventTable'
import { Button, Drawer, Input, Space } from 'antd'
import { withRouter } from 'react-router'
import Schema from 'containers/Event/Schema';
import { getMyPermissions } from 'common/AuthPermission'

const usePermission = () => {
  const [permissions] = useState(() => {
    const pList = getMyPermissions()
    return pList ?? null
  })
  const { formReadonly } = useMemo(() => {
    const formReadonly = (permissions ?? []).indexOf("FORMS/READ_ONLY") !== -1
    return { formReadonly }
  }, [permissions])
  return {
    formReadonly
  }
}

const Com = props => {
  const { formReadonly } = usePermission()
  const { noTree } = props;
  const [breadcrumbList, setBreadcrumbList] = useState(() => {
    return [{ name: '录入' }];
  });
  const [catId, setCatId] = useState(null);
  const [catName, setCatName] = useState(null);
  const [tplCode, setTplCode] = useState(null) // 模版id
  const [tableJson, setTableJson] = useState(null) // 模版id
  const [id, setId] = useState(null);

  useEffect(() => {
    setBreadcrumbList(
      [{ name: '录入' }]
        .concat(
          typeof catName === 'string' ? [{ name: catName }] : []
        )
    )
  }, [catName]);

  useEffect(() => {
    const { noTree, info } = props;
    if (noTree === true && !!info) {
      const { cat_id, code, table_json, id, name } = info;
      setCatId(cat_id);
      setCatName(name);
      setTplCode(code);
      setTableJson(table_json);
      setId(id);
    };
  }, [props]);

  const [visible, setVisible] = useState(false);
  const onClose = () => {
    setVisible(false);
  };

  const [tableRefresh, setTableRefresh] = useState(() => {
    return Symbol();
  });
  const saveDone = () => {
    setTableRefresh(Symbol());
    setVisible(false);
    // setCatId(null);
    // setCatName(null);
    setId(null);
  };
  const createDone = (id) => {
    setTableRefresh(Symbol());
    setVisible(false);
    // setCatId(null);
    // setCatName(null);
    setId(null);
  };

  const editForm = (id) => {
    console.log(id);
    setId(id + "");
    setVisible(true);
  };

  const [keyword, setKeyword] = useState(null);
  const [searchedKeyword, setSearchedKeyword] = useState(null);
  const handleSearch = () => {
    setSearchedKeyword(keyword);
  };

  useEffect(() => {
    setKeyword(null);
    setSearchedKeyword(null);
  }, [tplCode, id]);

  // console.log(tplCode, id);

  return (
    <div className={style['eventView']}>
      <TopBreadcrumb breadcrumbList={breadcrumbList} />
      <div className="main-warpper">
        {
          noTree !== true && (
            <div className="main-warpper-left">
              <TplTree setTplCode={setTplCode} setTableJson={setTableJson} setCatId={setCatId} setKeyword={setKeyword} setCatName={setCatName} />
            </div>
          )
        }
        <div className="main-warpper-right">
          <div className="header-bar">
            <div className="left">
              <Space>
                <Input style={{ width: 240 }} placeholder="关键字搜索" value={keyword} onChange={(e) => setKeyword(e.target.value)} onPressEnter={handleSearch} />
                <Button type="primary" onClick={handleSearch}>搜索</Button>
              </Space>
            </div>
            {
              !formReadonly &&
              typeof tplCode === 'string' && (
                <Button type="primary" onClick={() => {
                  // props.history.push(`/PEIMSV2/EventSchema/${tplCode}`);
                  setId(null);
                  setVisible(true);
                }}>
                  录入
                </Button>
              )
            }
          </div>
          <div className="table-wrap">
            <EventTable tplCode={tplCode} tableJson={tableJson} refresh={tableRefresh} editForm={editForm} keyword={searchedKeyword} readonly={formReadonly} />
          </div>
        </div>
      </div>
      <Drawer
        title={catName}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={500}
      >
        {
          visible && (
            <div className={style["drawer"]}>
              <Schema code={tplCode} id={id} createDone={createDone} saveDone={saveDone} catId={catId} onCancel={onClose} readonly={formReadonly}/>
            </div>
          )
        }
      </Drawer>
    </div>
  );
}

export default withRouter(Com)
