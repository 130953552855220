import { post, get } from 'common/utils';
import {
    setValues,
} from 'common/redux/SampleMaintenance/actions.js';
const moment = require('moment');

const getDataMetas = keyword => {
    return (dispatch, getState) => {
        const { sampleMaintenance: { sys_id } } = getState();
        return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/data_metas', {
            ...!keyword ? {} : { keyword },
            status: 1,
            page: 1,
            page_size: 10,
            sys_id,
            module_name: 'SAMPLE_REF_FACTOR'
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                dispatch(setValues({ dataMetaList: retData.data.results }));
                return true;
            };
            return false;
        });
    };
};

const getHistoryData = (tagNames) => {
    return (dispatch, getState) => {
        if (!tagNames) {
            //如果没有穿tagNames数组进来，就默认从state里面拿配置信息
            const { sampleMaintenance: { sys_id, layoutResults, layoutCusConfig } } = getState();
            const result = layoutResults
                .filter(({ sys_id: sysId }) => sysId === sys_id)[0];
            if (!result) {
                return false;
            };
            let cusMetas = [];
            const cusConfig = layoutCusConfig
                .filter(({ layout_id }) => layout_id === result._id)[0];
            if (!!cusConfig) {
                cusMetas = cusConfig.cus_trend_metas;
            };
            tagNames = [
                ...new Set(
                    result
                        .trend_metas
                        .concat(cusMetas)
                        .map(({ tag_name }) => tag_name),
                ),
            ];
        };
        // console.log(tagNames);
        const { sampleMaintenance: { date } } = getState();
        // const time_day = moment().subtract(1, 'days');
        const time_begin = moment(date).format('YYYY-MM-DD 00:00:00');
        const time_end = moment(date).add(1, "days").format('YYYY-MM-DD 00:00:00');
        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/get_history_data_sep_time', {
            time_type: 'seconds',
            tag_list: tagNames
                .map(tag_name => ({
                    tag_name,
                    time_begin,
                    time_end,
                })),
        })
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    const hisTag2Value = Object.assign(
                        {},
                        ...retData.data.results
                            .map(({ tag_name, data_list }) => {
                                if (!!data_list[0]) {
                                    //判断头部时间
                                    if (moment(time_begin).isBefore(moment.unix(data_list[0][0]))) {
                                        // console.log('time_begin before');
                                        data_list.unshift([moment(time_begin).unix(), null]);
                                    };
                                };
                                if (!!data_list[data_list.length - 1]) {
                                    if (moment(time_end).isAfter(moment.unix(data_list[data_list.length - 1][0]))) {
                                        // console.log('time_end after');
                                        data_list.push([moment(time_end).unix(), null]);
                                    };
                                };
                                return {
                                    [tag_name]: data_list,
                                };
                            })
                    );
                    // 这里应该是覆盖原先的tag2value，但是原先存在的后来没有的不清空
                    // 可以对比tagNames和返回的数据比对？
                    // console.log(hisTag2Value);
                    dispatch(setValues({ hisTag2Value }));
                    return true;
                }
                return false;
            });
    };
};

const getStatus = (dispatch, getState) => {
    const { sampleMaintenance = {} } = getState();
    const { sys_id, date } = sampleMaintenance;
    return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/opt/get_participate_status', {
        sys_id,
        day: date.format('YYYY-MM-DD'),
        type: 1,
    })
        .then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ status: retData.data.results }));
                return true;
            };
            return false;
        });
};

const getEvents = (dispatch, getState) => {
    const { sampleMaintenance: { date, sys_id } } = getState();
    const time_begin = moment(date).format('YYYY-MM-DD 00:00:00');
    const time_end = moment(date).add(1, 'days').format('YYYY-MM-DD 00:00:00');
    return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/prod_events', {
        page: 1,
        page_size: 10,
        sys_id,
        time_begin,
        time_end,
        operate_visible: 1,
    }).then(retData => {
        // console.log(retData);
        if (retData.data && retData.data.results) {
            dispatch(setValues({ events: retData.data.results }));
            return true;
        };
        return false;
    });
}


export {
    getDataMetas,
    getHistoryData,
    getStatus,
    getEvents,
};