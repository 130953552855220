import React from 'react';
import {
    CloseOutlined
} from '@ant-design/icons';

import QualityItem from './QualityItem.js';
import QualityItemLarge from './QualityItemLarge.js';

import ZHILIANGGAIKUANG_ICON from './res/zhilianggaikuang.png';

import './QualityModal.scss';

const QualitytItems = [
    {
        name: '普通生料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'M325水泥',
        rate: 37.2,
        warning: 3,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
    {
        name: 'P425水泥',
        rate: 81.2,
        warning: 5,
    },
    {
        name: '普通熟料',
        rate: 91.2,
        warning: 0,
    },
];

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unfoldIndexs: [],
            qualitytItems: [],
        };
    }

    componentDidMount() {
        const unfoldIndexs = [];
        const qualitytItems = QualitytItems
            .map((item, index) => {
                const id = `quality_item_${index}`;
                if (Math.random() > 0.95) {
                    // unfoldIndexs.push(id);
                };
                return {
                    ...item,
                    id,
                };
            });
        this.setState({
            qualitytItems,
            unfoldIndexs,
        });
    }

    unfoldItem = index => {
        // console.log(index);
        const { qualitytItems, unfoldIndexs } = this.state;
        this.setState({
            unfoldIndexs: [
                ...new Set(
                    unfoldIndexs
                        .concat([qualitytItems[index].id])
                ),
            ],
        });
    }

    foldItem = index => {
        // console.log(index);
        const { qualitytItems, unfoldIndexs } = this.state;
        this.setState({
            unfoldIndexs: unfoldIndexs
                .filter(id => id != qualitytItems[index].id),
        });
    }

    onCancel = () => {
        const { onClose = () => { } } = this.props;
        onClose();
    }

    render() {
        const { unfoldIndexs, qualitytItems } = this.state;

        return (
            <div className="main_page_bottom_panels_quality_modal_wrapper">
                <div className="mask" onClick={this.onCancel} />
                <div className="cus_modal_wrapper">
                    <div className="modal_header">
                        <img alt="" src={ZHILIANGGAIKUANG_ICON} className="title_icon" />
                        <div className="title">质量概况</div>
                        <div className="time">【 2020年12月20日 】 </div>
                        <CloseOutlined className="cus_close_icon" onClick={this.onCancel} />
                        <div className="mid_tab_wrapper">
                            <div className="tab">产品</div>
                            <div className="tab tab_selected">报警</div>
                        </div>
                    </div>
                    <div className="modal_body scroll_bar_restyle">
                        <div className="modal_body_content_wrapper">
                            {
                                qualitytItems
                                    .map((item, index) => {
                                        const { id } = item;
                                        if (unfoldIndexs.includes(id)) {
                                            return (
                                                <div className="item_large_position_wrapper" key={`large_${id}`} onClick={() => this.foldItem(index)}>
                                                    <QualityItemLarge item={item} />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="item_position_wrapper" key={`small_${id}`} onClick={() => this.unfoldItem(index)}>
                                                    <QualityItem item={item} />
                                                </div>
                                            );
                                        };
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
