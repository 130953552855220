import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    useEffect(() => {
        if (!!conf) {
            let requesting = true;
            setLoading(true);
            const { time_type, time, range } = conf;
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/brief/get_kkpi_brief_list', {
                time_type, time: time_start,
            })
                .then((retData) => {
                    if (requesting) {
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            if (!!retData.data.results) {
                                let nextResults = []
                                    .concat(
                                        retData.data.results,
                                    )
                                    .map((item) => {
                                        const { std_diff, avg_value, unit, cpl_rate } = item;
                                        const std_diff_text = (typeof std_diff === 'number' ? Math.floor(Math.abs(std_diff) * 1000) / 10 : "--") + "%";
                                        const std_diff_arrow_up = (typeof std_diff === 'number' && std_diff !== 0) ? (std_diff > 0 ? true : false) : null;
                                        const avg_value_text = typeof avg_value === 'number' ? significantNum(avg_value, 4) : "--";
                                        const unit_text = typeof unit === 'string' ? unit : "";
                                        const is_good_cpl = typeof cpl_rate === 'number' ? (cpl_rate < 0.75 ? false : true) : null;
                                        return Object.assign({}, item, {
                                            std_diff_text,
                                            std_diff_arrow_up,
                                            avg_value_text,
                                            unit_text,
                                            is_good_cpl,
                                        });
                                    });
                                const tag_list = nextResults
                                    .map(({ trend_tag }) => {
                                        const { period, range } = conf;
                                        const time_end = time_start;
                                        const time_begin = moment(time_end).subtract(period[0], period[1]).startOf(range).format("YYYY-MM-DD 00:00:00");
                                        return {
                                            tag_name: trend_tag,
                                            time_type,
                                            time_begin,
                                            time_end,
                                        };
                                    });
                                if (tag_list.length > 0) {
                                    // fetch data
                                    post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/avg/get_avg_datas', { tag_list })
                                        .then((retData) => {
                                            if (requesting) {
                                                setLoading(false);
                                                // console.log(retData);
                                                if (!!retData.data && retData.data.errcode === 0) {
                                                    const tag2DataList = retData.data.results
                                                        .reduce((pV, cV) => {
                                                            return Object.assign({}, pV, { [cV.tag_name]: cV.data_list });
                                                        }, {});
                                                    nextResults = nextResults
                                                        .map((item) => {
                                                            const data_list = tag2DataList?.[item.trend_tag] ?? [];
                                                            return Object.assign({}, item, { data_list });
                                                        });
                                                };
                                                setResults(nextResults);
                                            };
                                        });
                                    return;
                                };
                                setLoading(false);
                                setResults(nextResults);
                                return;//其他都算请求失败了
                            };
                        };
                        // 如果第一个请求就失败了 以及 errcode为0但是没有results时,算失败,需要停止loading
                        // 是否还需要重置数据?
                        setResults(null);
                        setLoading(false);
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [results, setConf, loading];
};

export default Hook;
