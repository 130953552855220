import React from 'react';
import { Modal } from 'antd';
import md5 from 'md5';
import { withRouter } from 'react-router';
import { post } from '../../common/utils';

let PERMISSION_2_PAGE = {
    'read_order': '/navi/OrderList',
    // 'nbvw_overview': '',
}

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let self = this;
        post(process.env.REACT_APP_SERVER_PATH + 'api/auth/password_login', {
            username: process.env.REACT_APP_AUTO_LOGIN_USERNAME,
            password: md5(process.env.REACT_APP_AUTO_LOGIN_PWD),
        }).then((retData) => {
            console.log(JSON.stringify(retData));
            if (retData.error === true) {
                Modal.error({ title: '登陆失败', content: '服务异常或网络出现错误' });
            } else if (retData && retData.data && retData.data.errcode === 0) {
                let storage = null;
                if (this.state.remember === true) {
                    storage = window.localStorage;
                    window.localStorage.setItem('PERSIST_SESSION', 'true');
                } else {
                    storage = window.localStorage;
                    window.localStorage.setItem('PERSIST_SESSION', 'false');
                }
                let index_path = retData.data.index_path || null;
                if(retData.data.index_path){}
                storage.setItem('USER_INFO', JSON.stringify({
                    user_id: retData.data.user_id,
                    token: retData.data.token,
                    time: new Date().getTime(),
                    name: retData.data.name,
                    expire_time: retData.data.token_expire_time,
                    index_path: index_path,
                }));
                window.localStorage.setItem('CACHED_PERMISSION_LIST', JSON.stringify({
                    data: retData.data.permissions,
                    time: new Date().getTime(),
                    // expire_time: retData.data.token_expire_time,
                }));
                let indexUrl = null;
                if (typeof this.state.redirectHash === "string") {
                    indexUrl = this.state.redirectHash;
                };
                Object.keys(PERMISSION_2_PAGE).map(permission => {
                    if (retData.data.permissions.indexOf(permission) >= 0) {
                        indexUrl = PERMISSION_2_PAGE[permission];
                    };
                    return null;
                });
                if (indexUrl == null) {
                    indexUrl = "";
                }
                if (index_path){
                    indexUrl = retData.data.index_path;
                }
                self.props.history.replace(indexUrl);
            } else if (retData && retData.data && retData.data.errcode === 400) {
                Modal.error({ title: '登陆失败', content: "用户名或密码错误" });
            } else if (retData && retData.data && retData.data.errcode === 404) {
                Modal.error({ title: '登陆失败', content: "该账号不存在，请联系管理员" });
            } else if (retData && retData.data && retData.data.errcode === 405) {
                Modal.error({ title: '登陆失败', content: "用户账号已停用" });
            } else {
                Modal.error({ title: '登陆失败' });
            }
        })
    }

    render() {

        return (
            null
            // <div>
            // </div>
        );
    }
}
export default withRouter(Comp);
