import React from 'react';
import { useState, useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import moment from 'moment';
import ICON_XLXS from './res/xlxs_icon.png';

const { RangePicker } = DatePicker;

const timeType2Picker = {
    1: 'hour',
    2: 'date',
    3: 'week',
    4: 'month',
};

const Comp = (props) => {
    const [visible, setVisible] = useState(false);
    const { selectedReport, timeRange, timeOnChange, hideTimePicker } = props;
    let timeType = null;
    if (!!selectedReport) {
        timeType = selectedReport.timeType;
    };
    useEffect(() => {
        if (hideTimePicker === true) {
            // console.log("hideTimePicker === true");
            setVisible(false);
        };
    }, [hideTimePicker]);
    const timeRangeOnClick = () => {
        if (timeType !== null) {
            setVisible(true);
        };
    };
    let timeRangeText = '';
    if (timeRange !== null) {
        let timeFormat = "YYYY年MM月DD日";
        if (timeType === 1) {
            //hour
            timeFormat = "YYYY年MM月DD日 HH时";
        };
        timeRangeText = timeRange
            .map(t => moment(t).format(timeFormat))
            .join("- ");
    };
    const timeRangeOnChange = (t_range) => {
        // console.log(t_range);
        if (!t_range) {
            return;
        };
        const [startTime, endTime] = t_range;
        if (timeType === 1) {
            //hour
            timeOnChange([moment(startTime).format("YYYY-MM-DD HH:00:00"), moment(endTime).format("YYYY-MM-DD HH:00:00")]);
        } else if (timeType === 2) {
            //date
            timeOnChange([moment(startTime).format("YYYY-MM-DD 00:00:00"), moment(endTime).format("YYYY-MM-DD 23:00:00")]);
        } else if (timeType === 3) {
            //week
            timeOnChange([moment(startTime).startOf("week").format("YYYY-MM-DD 00:00:00"), moment(endTime).endOf("week").format("YYYY-MM-DD 23:00:00")]);
        } else if (timeType === 4) {
            //month
            timeOnChange([moment(startTime).startOf("month").format("YYYY-MM-DD 00:00:00"), moment(endTime).endOf("month").format("YYYY-MM-DD 23:00:00")]);
        };
        setVisible(false);
    }
    return (
        <div>
            <div style={{ height: 67, paddingTop: 1, display: 'flex', alignItems: 'center', backgroundColor: '#212830' }}>
                <div style={{ marginLeft: 'auto' }} />
                {
                    timeRange !== null && (
                        <div style={{ position: 'relative' }} onClick={e => e.stopPropagation()}>
                            <div
                                onClick={timeRangeOnClick}
                                style={Object.assign({ color: '#FFFFFF', fontSize: 20, lineHeight: '20px', height: 20, cursor: 'pointer', minWidth: 300 }, {})}
                            >【{timeRangeText}】</div>
                            {
                                visible && (
                                    <div style={{ position: 'absolute', top: -10, right: 0, bottom: -10, left: 0, backgroundColor: 'rgb(33, 40, 48)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {
                                            timeType === 1 ? (
                                                <RangePicker
                                                    open={true}
                                                    showTime={{ format: 'HH' }}
                                                    format="YYYY-MM-DD HH"
                                                    onChange={timeRangeOnChange}
                                                // defaultValue={timeRange.map(t => moment(t))}
                                                // renderExtraFooter={(mode) => {
                                                //     return (
                                                //         <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-end", padding: '10px 0' }}>
                                                //             <Button type="default" size="small" onClick={() => { setVisible(false) }}>取消</Button>
                                                //             {/* <div style={{ width: 12 }} />
                                                //             <Button type="primary" size="small">确认</Button> */}
                                                //         </div>
                                                //     );
                                                // }}
                                                />
                                            ) : (
                                                    <RangePicker
                                                        open={true}
                                                        picker={timeType2Picker[timeType]}
                                                        // defaultValue={timeRange.map(t => moment(t))}
                                                        renderExtraFooter={(mode) => {
                                                            return (
                                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-end", padding: '10px 0' }}>
                                                                    <Button type="default" size="small" onClick={() => { setVisible(false) }}>取消</Button>
                                                                    {/* <div style={{ width: 12 }} />
                                                                    <Button type="primary" size="small">确认</Button> */}
                                                                </div>
                                                            );
                                                        }}
                                                        onChange={timeRangeOnChange}
                                                    />
                                                )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                <div style={{ marginLeft: 12, marginRight: 24, display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}>
                    <img alt="" src={ICON_XLXS} width="24" height="24" />
                    <div style={{ marginLeft: 4, color: '#3DCDB9', fontSize: 14, height: 20, lineHeight: '20px' }}>导出指标</div>
                </div>
            </div>
            <div style={{ height: 1, backgroundColor: '#3DCDB9' }} />
        </div>
    );
};

export default Comp;
