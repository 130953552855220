const InitialState = {
    examDetail: undefined,
    examDetailUniqueId: null,
    rightContentWidth: 0,
    selectedUserValue: null,
    hisTag2Values: {},
    showFactorDetail: [],
    attendUsers: null,
    systems: [],
    fetchExamDetailLock: null,
    examList: [],
    loading: false,
};

export {
    InitialState,
};