import Scene_1 from './Scene_1';
import Scene_2 from './Scene_2';
import Scene_3 from './Scene_3';
import Scene_4 from './Scene_4';
import Scene_5 from './Scene_5';
import Scene_6 from './Scene_6';
import * as THREE from 'three';
// import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const lookAtPosition = [0, 0, 0];

const CommonObj = {
    customTimeOut: null,
    mySetTimeout: null,
    camera: null, // 相机
    scene: null, // 场景
    renderer: null, // 全局变量
    controls: null, // 控制鼠标旋转功能
};

const customSetTimeOut = (cb, delay) => {
    const start = new Date();
    let remain = null;
    const ret = setTimeout(() => { cb() }, delay);
    const customTimeOut = {
        ret,
        cancel: () => { clearTimeout(ret); },
        pause: () => {
            if (typeof remain === 'number') return;
            const curr = new Date();
            if (curr - start <= delay) {
                clearTimeout(ret);
                remain = delay - (curr - start);
            };
        },
        play: () => {
            if (remain === null) return;
            // const ret_2 =
            setTimeout(() => { cb() }, remain);
            remain = null;
        },
    };
    CommonObj.customTimeOut = customTimeOut;

    return ret;
};

const addAxis = (scene) => {
    // add axis
    const materialX = new THREE.LineBasicMaterial({ color: 0xffffff });
    const geometryX = new THREE.Geometry();
    geometryX.vertices.push(new THREE.Vector3(0, 0, 0));
    geometryX.vertices.push(new THREE.Vector3(1300000, 0, 0));
    // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
    const lineX = new THREE.Line(geometryX, materialX);

    const materialY = new THREE.LineBasicMaterial({ color: 0xff55ff });
    const geometryY = new THREE.Geometry();
    geometryY.vertices.push(new THREE.Vector3(0, 0, 0));
    geometryY.vertices.push(new THREE.Vector3(0, 1300000, 0));
    // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
    const lineY = new THREE.Line(geometryY, materialY);

    const materialZ = new THREE.LineBasicMaterial({ color: 0xff0000 });
    const geometryZ = new THREE.Geometry();
    geometryZ.vertices.push(new THREE.Vector3(0, 0, 0));
    geometryZ.vertices.push(new THREE.Vector3(0, 0, 1300000));
    // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
    const lineZ = new THREE.Line(geometryZ, materialZ);

    scene.add(lineX);
    scene.add(lineY);
    scene.add(lineZ);
};

const addLight = (scene) => {
    const ambientLight = new THREE.AmbientLight(0xFFFFE0, 0.4);
    scene.add(ambientLight);

    //平行光
    function DirectLight() {
        const light = new THREE.DirectionalLight('#FFFFE0', 1);
        light.castShadow = true;
        light.position.set(8000, 8000, 8000);
        light.decay = 2;
        light.penumbra = 0.2;
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;
        scene.add(light);
    }
    DirectLight();

    function PointLight() {
        const light = new THREE.PointLight('#EEB422', 1, 50, 2);
        light.castShadow = true;
        light.position.set(800, 500, 300);
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;
        //scene.add(light); 
    }
    PointLight()
};

const addOrbitControls = (camera, renderer, lookAtPosition) => {
    var controls = new OrbitControls(camera, renderer.domElement);
    // controls.addEventListener('change', render); // use only if there is no animation loop
    controls.addEventListener('change', (event) => {
        // const { target } = event;
        // const azimuthalAngle = target.getAzimuthalAngle();
        // const degAngle = Math.floor(azimuthalAngle / Math.PI * 180);

        // console.log(degAngle);
    });
    // controls.minDistance = 10;
    // controls.maxDistance = 100;
    // controls.enableZoom = false;
    controls.enableKeys = false;//禁用键盘
    controls.target = new THREE.Vector3(...lookAtPosition);

    // 上下旋转范围
    // controls.minPolarAngle = Math.PI * (30 / 180);
    // controls.maxPolarAngle = Math.PI * (80 / 180);
    // 左右旋转范围
    // controls.minAzimuthAngle = Math.PI * (60 / 180);
    // controls.maxAzimuthAngle = Math.PI * (150 / 180);
    controls.enabled = false;
    controls.update();
    CommonObj.controls = controls;
};

const render3D = (refNode, initCameraPosition, onProgress, onError, onLoad) => {
    if (!refNode) {
        return;
    };

    const cameraPosition = initCameraPosition || [600, 600, -600];

    const nodeWidth = refNode.offsetWidth;
    const nodeHeight = refNode.offsetHeight;

    init();
    animate();

    function init() {
        CommonObj.camera = new THREE.PerspectiveCamera(45, nodeWidth / nodeHeight, 100, 1300000000);
        const camera = CommonObj.camera;
        const [x, y, z] = cameraPosition;

        camera.position.x = x;
        camera.position.y = y;
        camera.position.z = z;

        camera.lookAt(...lookAtPosition);

        CommonObj.scene = new THREE.Scene();
        const scene = CommonObj.scene;

        var pointLight = new THREE.PointLight(0xffffff, 0.8);
        camera.add(pointLight);
        scene.add(camera);

        // addAxis(scene);
        addLight(scene);

        // model
        // THREE.Loader.Handlers.add(/\.dds$/i, new DDSLoader());

        var mtlLoader = new MTLLoader();
        mtlLoader.setPath('obj/');
        mtlLoader.load('objtest.mtl', function (materials) {

            materials.preload();

            var objLoader = new OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.setPath('obj/');
            objLoader.load('objtest.obj', function (object) {
                scene.add(object);
                if (typeof onLoad === 'function') onLoad();
            }, typeof onProgress === 'function' ? onProgress : () => { }, typeof onError === 'function' ? onError : () => { });

        });

        CommonObj.renderer = new THREE.WebGLRenderer();
        const renderer = CommonObj.renderer;
        // renderer = new THREE.WebGLRenderer({ alpha: true });
        // renderer.setClearColor(0xffffff, 0);
        renderer.setPixelRatio(window.devicePixelRatio);
        // renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setSize(nodeWidth, nodeHeight);
        refNode.appendChild(renderer.domElement);

        addOrbitControls(camera, renderer, lookAtPosition);// disable mouse handle
    }

    function animate() {
        requestAnimationFrame(animate);
        render();
    }

    function render() {
        CommonObj.renderer.render(CommonObj.scene, CommonObj.camera);
    }
};

const getProjectCoord = () => {
    const vec3s = [
        [-380, 15, 90],
        [160, 15, -40],
        [0, 15, 220],
        [230, 200, 130],
        [360, 170, 130],
    ]
        .map((item) => new THREE.Vector3(...item));

    if (!!CommonObj.camera) {
        return vec3s.map((vec3) => vec3.project(CommonObj.camera));
    };

    return null;
};

const scenesInfo = [
    {
        id: 1,
        name: '1',
        position: [600, 600, -600],
        displayFunc: (act2, act3, act4, act5, actOver) => {
            CommonObj.mySetTimeout = customSetTimeOut(() => {
                if (!act2) return;
                const { num, cb } = act2;
                if (typeof num !== 'number' || num < 0) return;
                const cbFunc = typeof cb === 'function' ? cb : () => { };
                const timeoutFunc = (index) => {
                    if (index < num) {
                        cbFunc(index);
                        CommonObj.mySetTimeout = customSetTimeOut(() => {
                            timeoutFunc(index + 1);
                        }, 3000);
                    } else {
                        if (!act3) return;
                        const { cb } = act3;
                        const cbFunc = typeof cb === 'function' ? cb : () => { };
                        cbFunc();
                        CommonObj.mySetTimeout = customSetTimeOut(() => {
                            if (!act4) return;
                            const { num, cb } = act4;
                            if (typeof num !== 'number' || num < 0) return;
                            const cbFunc = typeof cb === 'function' ? cb : () => { };
                            const timeoutFunc = (index) => {
                                if (index < num) {
                                    cbFunc(index);
                                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                                        timeoutFunc(index + 1);
                                    }, 3000);
                                } else {
                                    if (!act5) return;
                                    const { cb } = act5;
                                    const cbFunc = typeof cb === 'function' ? cb : () => { };
                                    cbFunc();
                                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                                        if (!actOver) return;
                                        const { cb } = actOver;
                                        const cbFunc = typeof cb === 'function' ? cb : () => { };
                                        cbFunc();// action over
                                    }, 2000);// action 5
                                };
                            };
                            timeoutFunc(0);// action 4
                        }, 2000);// action 3
                    };
                };
                timeoutFunc(0); // action 2
            }, 3000); // action 1
        },
        comp: Scene_1,
        switchDur: 2000,
    },
    {
        id: 2,
        name: '2',
        position: [0, 600, -600 * Math.sqrt(2)],
        displayFunc: (act2, act3, act4, actOver) => {
            if (!act2) return;
            const { num, cb } = act2;
            if (typeof num !== 'number' || num < 0) return;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            const timeoutFunc = (index) => {
                if (index < num) {
                    cbFunc(index);
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        timeoutFunc(index + 1);
                    }, 3000);
                } else {
                    if (!act3) return;
                    const { num, cb } = act3;
                    if (typeof num !== 'number' || num < 0) return;
                    const cbFunc = typeof cb === 'function' ? cb : () => { };
                    const timeoutFunc = (index) => {
                        if (index < num) {
                            cbFunc(index);
                            CommonObj.mySetTimeout = customSetTimeOut(() => {
                                timeoutFunc(index + 1);
                            }, 3000);
                        } else {
                            if (!act4) return;
                            const { cb } = act4;
                            const cbFunc = typeof cb === 'function' ? cb : () => { };
                            cbFunc();
                            CommonObj.mySetTimeout = customSetTimeOut(() => {
                                if (!actOver) return;
                                const { cb } = actOver;
                                const cbFunc = typeof cb === 'function' ? cb : () => { };
                                cbFunc();// action over
                            }, 2000);// action 4
                        };
                    };
                    timeoutFunc(0);// action 3
                };
            };
            timeoutFunc(0); // action 2
        },
        comp: Scene_2,
        switchDur: 2000,
    },
    {
        id: 3,
        name: '3',
        position: [-600, 600, -600],
        displayFunc: (act2, act3, act4, actOver) => {
            if (!act2) return;
            const { num, cb } = act2;
            if (typeof num !== 'number' || num < 0) return;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            const timeoutFunc = (index) => {
                if (index < num) {
                    cbFunc(index);
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        timeoutFunc(index + 1);
                    }, 2500);
                } else {
                    if (!act3) return;
                    const { num, cb } = act3;
                    if (typeof num !== 'number' || num < 0) return;
                    const cbFunc = typeof cb === 'function' ? cb : () => { };
                    const timeoutFunc = (index) => {
                        if (index < num) {
                            cbFunc(index);
                            CommonObj.mySetTimeout = customSetTimeOut(() => {
                                timeoutFunc(index + 1);
                            }, 2500);
                        } else {
                            if (!act4) return;
                            const { cb } = act4;
                            const cbFunc = typeof cb === 'function' ? cb : () => { };
                            cbFunc();
                            CommonObj.mySetTimeout = customSetTimeOut(() => {
                                if (!actOver) return;
                                const { cb } = actOver;
                                const cbFunc = typeof cb === 'function' ? cb : () => { };
                                cbFunc();// action over
                            }, 3000);// action 4
                        };
                    };
                    timeoutFunc(0);// action 3
                };
            };
            timeoutFunc(0); // action 2
        },
        comp: Scene_3,
        switchDur: 2000,
    },
    {
        id: 4,
        name: '4',
        position: [-600, 600, 600],
        displayFunc: (act2, act3, act4, actOver) => {
            if (!act2) return;
            const { num, cb } = act2;
            if (typeof num !== 'number' || num < 0) return;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            const timeoutFunc = (index) => {
                if (index < num) {
                    cbFunc(index);
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        timeoutFunc(index + 1);
                    }, 2600);
                } else {
                    if (!act3) return;
                    const { num, cb } = act3;
                    if (typeof num !== 'number' || num < 0) return;
                    const cbFunc = typeof cb === 'function' ? cb : () => { };
                    const timeoutFunc = (index) => {
                        if (index < num) {
                            cbFunc(index);
                            CommonObj.mySetTimeout = customSetTimeOut(() => {
                                timeoutFunc(index + 1);
                            }, 2000);
                        } else {
                            if (!act4) return;
                            const { cb } = act4;
                            const cbFunc = typeof cb === 'function' ? cb : () => { };
                            cbFunc();
                            CommonObj.mySetTimeout = customSetTimeOut(() => {
                                if (!actOver) return;
                                const { cb } = actOver;
                                const cbFunc = typeof cb === 'function' ? cb : () => { };
                                cbFunc();// action over
                            }, 3000);// action 4
                        };
                    };
                    timeoutFunc(0);// action 3
                };
            };
            timeoutFunc(0); // action 2
        },
        comp: Scene_4,
        switchDur: 2000,
    },
    {
        id: 5,
        name: '5',
        position: [600, 600, 600],
        displayFunc: (act2, act3, actOver) => {
            if (!act2) return;
            const { num, cb } = act2;
            if (typeof num !== 'number' || num < 0) return;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            const timeoutFunc = (index) => {
                if (index < num) {
                    cbFunc(index);
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        timeoutFunc(index + 1);
                    }, 1500);
                } else {
                    if (!act3) return;
                    const { cb } = act3;
                    const cbFunc = typeof cb === 'function' ? cb : () => { };
                    cbFunc();
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        if (!actOver) return;
                        const { cb } = actOver;
                        const cbFunc = typeof cb === 'function' ? cb : () => { };
                        cbFunc();// action over
                    }, 3000);// action 3
                };
            };
            timeoutFunc(0); // action 2
        },
        comp: Scene_5,
        switchDur: 4000,
    },
    {
        id: 6,
        name: '6',
        position: [-600, 600, -600],
        displayFunc: (act2, act3, actOver) => {
            if (!act2) return;
            const { num, cb } = act2;
            if (typeof num !== 'number' || num < 0) return;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            const timeoutFunc = (index) => {
                if (index < num) {
                    cbFunc(index);
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        timeoutFunc(index + 1);
                    }, 2500);
                } else {
                    if (!act3) return;
                    const { cb } = act3;
                    const cbFunc = typeof cb === 'function' ? cb : () => { };
                    cbFunc();
                    CommonObj.mySetTimeout = customSetTimeOut(() => {
                        if (!actOver) return;
                        const { cb } = actOver;
                        const cbFunc = typeof cb === 'function' ? cb : () => { };
                        cbFunc();// action over
                    }, 3000);// action 3
                };
            };
            timeoutFunc(0); // action 2
        },
        comp: Scene_6,
        switchDur: 3000,
    },
];

const sceneSwitch = (camera, from, to, switchEnd, totalPeriod = DefaultSwitchPeriod) => {
    const [x1, y1, z1] = from;
    const [x2, y2, z2] = to;
    const timeoutFunc = (currPeriod) => {
        if (currPeriod < totalPeriod) {
            const [x, z] = getCircleAxis([x1, z1], [x2, z2], currPeriod, totalPeriod);
            camera.position.x = x;
            camera.position.y = getLinearValue(y1, y2, currPeriod, totalPeriod);
            camera.position.z = z;
            camera.lookAt(...lookAtPosition);
            CommonObj.mySetTimeout = customSetTimeOut(() => {
                timeoutFunc(currPeriod + DefaultDeltaPeriod);
            }, DefaultDeltaPeriod);
        } else {
            // to end position
            camera.position.x = x2;
            camera.position.y = y2;
            camera.position.z = z2;
            camera.lookAt(...lookAtPosition);
            if (!switchEnd) return;
            const { cb } = switchEnd;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            cbFunc();
        };
    };
    timeoutFunc(0);
};

const switch2Scene = (camera, to, switchEnd, totalPeriod = DefaultSwitchPeriod) => {
    const { x: x1, y: y1, z: z1 } = camera.position;
    const [x2, y2, z2] = to;
    const timeoutFunc = (currPeriod) => {
        if (currPeriod < totalPeriod) {
            const [x, z] = getCircleAxis([x1, z1], [x2, z2], currPeriod, totalPeriod);
            camera.position.x = x;
            camera.position.y = getLinearValue(y1, y2, currPeriod, totalPeriod);
            camera.position.z = z;
            camera.lookAt(...lookAtPosition);
            CommonObj.mySetTimeout = customSetTimeOut(() => {
                timeoutFunc(currPeriod + DefaultDeltaPeriod);
            }, DefaultDeltaPeriod);
        } else {
            // to end position
            camera.position.x = x2;
            camera.position.y = y2;
            camera.position.z = z2;
            camera.lookAt(...lookAtPosition);
            if (!switchEnd) return;
            const { cb } = switchEnd;
            const cbFunc = typeof cb === 'function' ? cb : () => { };
            cbFunc();
        };
    };
    timeoutFunc(0);
};

const DefaultSwitchPeriod = 1000; // 5000 ms
const DefaultDeltaPeriod = 20; // 50ms

const getLinearValue = (v1, v2, currPeriod, totalPeriod = DefaultSwitchPeriod) => {
    if (currPeriod <= 0) return v1;
    if (currPeriod >= totalPeriod) return v2;
    return v1 + (v2 - v1) * currPeriod / totalPeriod;
};

const getCircleAxis = (p1, p2, currPeriod, totalPeriod = DefaultSwitchPeriod) => {
    if (currPeriod <= 0) return p1;
    if (currPeriod >= totalPeriod) return p2;
    const [x1, y1] = p1;
    const [x2, y2] = p2;
    const startRadiu = Math.sqrt(Math.pow(x1, 2) + Math.pow(y1, 2));
    const endRadiu = Math.sqrt(Math.pow(x2, 2) + Math.pow(y2, 2));
    const currRadius = getLinearValue(startRadiu, endRadiu, currPeriod, totalPeriod);

    const startAngle = Math.atan2(y1, x1);
    const endAngle = Math.atan2(y2, x2);

    let actualEndAngle = endAngle;
    if (Math.abs(actualEndAngle + 2 * Math.PI - startAngle) < Math.abs(actualEndAngle - startAngle)) {
        actualEndAngle = actualEndAngle + 2 * Math.PI;
    };
    if (Math.abs(actualEndAngle - 2 * Math.PI - startAngle) < Math.abs(actualEndAngle - startAngle)) {
        actualEndAngle = actualEndAngle - 2 * Math.PI;
    };

    // console.log(startAngle, endAngle, endAngle - startAngle, actualEndAngle, actualEndAngle - startAngle);
    // 此处加上一个特性，实际转动角度 <=Math.PI
    const currAngle = getLinearValue(startAngle, actualEndAngle, currPeriod, totalPeriod);

    const currX = currRadius * Math.cos(currAngle);
    const currY = currRadius * Math.sin(currAngle);

    return [currX, currY];
};

export {
    CommonObj,
    render3D,
    scenesInfo,
    sceneSwitch,
    switch2Scene,
    THREE,
    lookAtPosition,
    addAxis,
    addLight,
    addOrbitControls,
    DefaultSwitchPeriod,
    DefaultDeltaPeriod,
    getLinearValue,
    getCircleAxis,
    getProjectCoord,
};
