const factorDetail = {
    improveProject: true,//改善计划
    productCategory: '普通生料',
    factorName: '生料投入量',
    related: 1.000,
    factorAve: 120.4,
    standardValue: 120.4,
    improveChance: 20,
    currPHI: 94.5,
    improvePHI: 94.5,
    redGreen: Math.random() > 0.9,
    coloredBar: [
        {
            startTime: '2020-12-20 08:30:00',
            endTime: '2020-12-20 08:45:00',
            status: 1,//red?alarm?
        },
        {
            startTime: '2020-12-20 08:50:00',
            endTime: '2020-12-20 09:00:00',
            status: 2,//yellow?warning?
        },
        {
            startTime: '2020-12-20 09:45:00',
            endTime: '2020-12-20 10:40:00',
            status: 2,//yellow?warning?
        },
        {
            startTime: '2020-12-21 08:30:00',
            endTime: '2020-12-21 08:45:00',
            status: 1,//red?alarm?
        },
        {
            startTime: '2020-12-22 08:30:00',
            endTime: '2020-12-22 12:45:00',
            status: 1,//red?alarm?
        },
    ],
    children: [
        {
            improveProject: true,
            factorName: '二次风温度',
            related: 0.864,
            factorAve: 120.4,
            standardValue: 120.4,
            improveChance: 20,
            currPHI: 94.5,
            improvePHI: 94.5,
            redGreen: Math.random() > 0.9,
            coloredBar: [
                {
                    startTime: '2020-12-20 08:30:00',
                    endTime: '2020-12-20 08:45:00',
                    status: 1,//red?alarm?
                },
                {
                    startTime: '2020-12-20 08:50:00',
                    endTime: '2020-12-20 09:00:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-20 09:45:00',
                    endTime: '2020-12-20 10:40:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-21 08:30:00',
                    endTime: '2020-12-21 08:45:00',
                    status: 1,//red?alarm?
                },
            ],
        },
        {
            improveProject: true,
            factorName: '二次风温度',
            related: 0.864,
            factorAve: 120.4,
            standardValue: 120.4,
            improveChance: 20,
            currPHI: 94.5,
            improvePHI: 94.5,
            redGreen: Math.random() > 0.9,
            coloredBar: [
                {
                    startTime: '2020-12-20 08:30:00',
                    endTime: '2020-12-20 08:45:00',
                    status: 1,//red?alarm?
                },
                {
                    startTime: '2020-12-20 08:50:00',
                    endTime: '2020-12-20 09:00:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-20 09:45:00',
                    endTime: '2020-12-20 10:40:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-21 08:30:00',
                    endTime: '2020-12-21 08:45:00',
                    status: 1,//red?alarm?
                },
            ],
        },
        {
            improveProject: false,
            factorName: '二次风温度',
            related: 0.864,
            factorAve: 120.4,
            standardValue: 120.4,
            improveChance: 20,
            currPHI: 94.5,
            improvePHI: 94.5,
            redGreen: Math.random() > 0.9,
            coloredBar: [
                {
                    startTime: '2020-12-20 08:30:00',
                    endTime: '2020-12-20 08:45:00',
                    status: 1,//red?alarm?
                },
                {
                    startTime: '2020-12-20 08:50:00',
                    endTime: '2020-12-20 09:00:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-20 09:45:00',
                    endTime: '2020-12-20 10:40:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-21 08:30:00',
                    endTime: '2020-12-21 08:45:00',
                    status: 1,//red?alarm?
                },
            ],
        },
        {
            improveProject: false,
            factorName: '二次风温度',
            related: 0.864,
            factorAve: 120.4,
            standardValue: 120.4,
            improveChance: 20,
            currPHI: 94.5,
            improvePHI: 94.5,
            redGreen: Math.random() > 0.9,
            coloredBar: [
                {
                    startTime: '2020-12-20 08:30:00',
                    endTime: '2020-12-20 08:45:00',
                    status: 1,//red?alarm?
                },
                {
                    startTime: '2020-12-20 08:50:00',
                    endTime: '2020-12-20 09:00:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-20 09:45:00',
                    endTime: '2020-12-20 10:40:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-21 08:30:00',
                    endTime: '2020-12-21 08:45:00',
                    status: 1,//red?alarm?
                },
            ],
        },
        {
            improveProject: false,
            factorName: '二次风温度',
            related: 0.864,
            factorAve: 120.4,
            standardValue: 120.4,
            improveChance: 20,
            currPHI: 94.5,
            improvePHI: 94.5,
            redGreen: Math.random() > 0.9,
            coloredBar: [
                {
                    startTime: '2020-12-20 08:30:00',
                    endTime: '2020-12-20 08:45:00',
                    status: 1,//red?alarm?
                },
                {
                    startTime: '2020-12-20 08:50:00',
                    endTime: '2020-12-20 09:00:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-20 09:45:00',
                    endTime: '2020-12-20 10:40:00',
                    status: 2,//yellow?warning?
                },
                {
                    startTime: '2020-12-21 08:30:00',
                    endTime: '2020-12-21 08:45:00',
                    status: 1,//red?alarm?
                },
            ],
        },
    ],
};

const symbol = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAXdQTFRFAAAA/0JM/1Zk/11r/1xq/19u/11r/15t/2Bv/15s/11r/19u/1xq/1xq/1dl/0JM/0xY/1po/11s/2Fw/11r/1ln/0pW/1Zj/11s/2Fv/11r/1Vh/1Zj/15s/15s/1Rg/1Fe/1Fe/1lm/2Bv/19u/1dk/0ZS/1tp/0ZS/0xZ/15t/11s/0xZ/05b/19u/15t/05b/1po/1dk/1tp/1Zj/1xq/1Zj/1tp/0xY/2Fw/2Fw/2Fw/2Fw/2Fw/2Fw/2Fw/2Fw/2Fw/2Fw/1ln/2Fw/2Fw/2Fw/2Fw/2Fw/1tp/2Fw/2Fw/2Fw/2Fw/2Fw/1Zk/2Fw/1tq/2Fw/1hm/2Fw/15s/2Fw/1pp/2Fw/11r/2Bu/2Fw/2Fw/1xq/2Fw/1tp/2Fw/15t/2Fw/1hl/2Fw/1tp/2Fw/1dl/2Fw/2Bu/0JM/1po/1hm/0pW/1po/1Vh/1xq/1Rg/1po/15t/1Fe/1Zj/1ln/1xq/0ZS/0xZ/05b/1tp/1ppEioETQAAAH10Uk5TAAsqP05UYGVmZF9TTD4mBxI6VmZTNw4rVGZRJipbVyMVDjZkYy8HRwUPWVMKE15aDUQoShxWHUsMa6za8v798Nalaiif8f/sk01pyLxothyNK+pBlUvHXetkZvzpZMJbkEriQIUqqRpnZQVKJglHGU8YQ2EJIjtKAwcIXVtemY+1AAAClUlEQVR4nO3X6TsbURTH8YtgrMGNpSiiQVpa+04QNWjtW4mlVFWVqtLS7Y9v8vBoM/ObzDl38qq939fnziczk7mTCKHT6XQ6ne4/LyMzy5edk2sYefkFhUXF/vQLJaVlhkzKCJRXpJWorHogQUZ1TdqI2odIuK2uPi1EQ8CZSOQLejcaH6U2pAw1eSSafW5EopawF+PxE4ohZWubuvH0Gc2Qsr1D+TzIRlxRPJdm4rW6LaB2X0j3/E+dKkYjz5Cyi280uD4f1kL8p9LlOUd1c41aviFlDxNJsSc618szKlUMKftYSJUa0s8xSuA7yj1jgIGUOhxkcGh4ZDQyNj4RdRiYZCBl+BDPp8y7pmfwxCzdyDDQAV68NP9qbh5eL/pvmEz4KZMM01yAQ4tkJAteK9PSEppaJiNo/x2csiIr6O6vkpFssHrIapjmGhhbJyM5YPWwHdkAY6/ISC5YPWJHNsHYFhlB3+BROxIBYzFPSMSObHtC8sDqMTuyA8Z2yUg+WD1uR/bA2GsyUgBWT9iRfTB2QEYKwerotNV4g3avQzJSBFbLGSvyFk0dkZFitFzOJRvv4NAxGfHDrX5+Icl4j2ZOPpARpx9dSyv39wNeKylP6YYox4eQ0bWNzcj2zt4+fGPFO2MgFfB6uWd8ZCCiWg055xiiRg35xEJEnYpxwTNEvQrymYlw/2cluuQaIhjiGldf2Iho4iJf+YYQLTzjWsUQ4VaOcfNNCRFt7XQj8F3NEKKDrAR+qBrxcyH+B75RPo9E4U6Kca14P+7rcn1erpS+u8kFu1MblwrPIKin15m4YO9XjvX1w7eYcc7c210amJy1OCenZ6z3IC3/4vLq+s+tWGz318Hh0THjd4lOp9PpdLp/s9/U+jjEMkcr6wAAAABJRU5ErkJggg==";

export {
    factorDetail,
    symbol,
};
