import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { colors, renderChartBar, mockData, renderChartPie } from './utils';

const Page = (props) => {

    const refCircle = useRef(null);
    const refBar = useRef(null);
    useEffect(() => {
        renderChartBar(refBar.current, mockData);
        renderChartPie(refCircle.current, [10, 20, 20, 40]);
    }, []);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                {
                    ["熟料烧成", "原料粉磨1", "原料粉磨2", "煤粉制备"]
                        .map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="bar" style={{ backgroundColor: colors[index % colors.length] }}></div>
                                    <div className="name">{item}</div>
                                </div>
                            );
                        })
                }
            </div>
            <div className="bottom">
                <div className="left">
                    <div className="top">用电量占比</div>
                    <div className="bottom" ref={refCircle}></div>
                </div>
                <div className="right">
                    <div className="top">用电量小时趋势</div>
                    <div className="bottom" ref={refBar}></div>
                </div>
            </div>
        </div>
    );
};

export default Page;
