import React from 'react';
import { Input } from 'antd';
import './NumInput.scss';


export default class NumInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            isFocus: false,
            justEdit: false
        };
        this.dataSet = {
            min: props.min || 3,
            max: props.max || 30,
            mouseInBtn: false
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value });
        this.dataSet.min = nextProps.min || 3;
        this.dataSet.max = nextProps.max || 30;
    }
    inputOnchange = e => {
        this.setState({
            value: e.target.value
        })
    }
    onBtnClick = sign => {
        let self = this, funcList = [[self.onOkValue, self.onAddValue], [self.onCancelValue, self.onMinusValue]][sign];
        return _ => {
            (self.state.justEdit ? funcList[0] : funcList[1])();
            self.setState({ justEdit: false });
        }
    }
    onOkValue = _ => {
        console.log("on ok")
        let newValue = parseFloat(this.state.value);
        if (isNaN(newValue)) {
            this.onCancelValue();
            // const modal = Modal.error({
            //     title: '提示',
            //     content: '格式错误，已撤销修改'
            // });
            // setTimeout(() => modal.destroy(), 3000);
        }
        else {
            if (newValue > this.dataSet.max) newValue = this.dataSet.max;
            if (newValue < this.dataSet.min) newValue = this.dataSet.min;
            this.setState({ value: this.props.value }, _ => this.props.onChange(newValue));
        }
    }
    onCancelValue = _ => {
        console.log("on cancel");
        this.setState({ value: this.props.value });
    }
    onAddValue = _ => {
        console.log("on onAddValue")
        let newValue = this.state.value + 1;
        if (newValue > this.dataSet.max) newValue = this.dataSet.max;
        this.props.onChange(newValue);
    }
    onMinusValue = _ => {
        console.log("on onMinusValue")
        let newValue = this.state.value - 1;
        if (newValue < this.dataSet.min) newValue = this.dataSet.min;
        this.props.onChange(newValue);
    }
    inputOnBlur = _ => {
        this.setState({ isFocus: false });
        if (this.dataSet.mouseInBtn) {
            this.setState({ justEdit: true });
        } else {
            this.onCancelValue();
        }
    }
    render() {
        let self = this, { isFocus, justEdit } = self.state, btnProps = {
            className: "btn",
            onMouseEnter: _ => self.dataSet.mouseInBtn = true,
            onMouseLeave: _ => self.dataSet.mouseInBtn = false
        };
        return (
            <div className={"NumInputWrapper " + (self.props.className || "")}>
                <Input
                    placeholder="number"
                    value={self.state.value}
                    onChange={self.inputOnchange}
                    onFocus={_ => self.setState({ isFocus: true })}
                    onBlur={self.inputOnBlur}
                />
                <div
                    onClick={self.onBtnClick(0)}
                    {...btnProps}
                >{isFocus || justEdit ? "√" : "↑"}</div>
                <div
                    onClick={self.onBtnClick(1)}
                    {...btnProps}
                >{isFocus || justEdit ? "×" : "↓"}</div>
            </div>
        );
    }
}
