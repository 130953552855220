import moment from 'moment';
import { getCurrentTime } from '../../../common/utils.js';

const convertRange2Keys = (range) => {
    let v = range.replace('d', '').replace('w', '').replace('M', '').replace('Y', '');
    const unit = range.replace(v, '');
    v = parseInt(v);
    const key2Unit = {
        'd': 'days',
        'w': 'weeks',
        'M': 'months',
        'Y': 'years',
    };
    const u = key2Unit[unit];
    if (typeof v !== 'number' || typeof u !== 'string') {
        return null;
    };
    return [v, u];
};

const getDefaultTimeRange = (timeType, range) => {
    const currTime = getCurrentTime();
    if (timeType === 1) {
        //hour
        const endTime = moment(currTime).format("YYYY-MM-DD HH:00:00");
        const v_u = convertRange2Keys(range);
        const [v, u] = !!v_u ? v_u : [7, 'days'];
        const startTime = moment(currTime).subtract(v, u).format("YYYY-MM-DD HH:00:00");
        return [startTime, endTime];
    } else if (timeType === 2) {
        //day
        const endTime = moment(currTime).format("YYYY-MM-DD 00:00:00");
        const v_u = convertRange2Keys(range);
        const [v, u] = !!v_u ? v_u : [30, 'days'];
        const startTime = moment(currTime).subtract(v, u).format("YYYY-MM-DD 00:00:00");
        return [startTime, endTime];
    } else if (timeType === 3) {
        //week
        const endTime = moment(currTime).endOf('week').format("YYYY-MM-DD 00:00:00");
        const v_u = convertRange2Keys(range);
        const [v, u] = !!v_u ? v_u : [12, 'weeks'];
        const startTime = moment(currTime).subtract(v, u).startOf('week').format("YYYY-MM-DD 00:00:00");
        return [startTime, endTime];
    } else if (timeType === 4) {
        //month
        const endTime = moment(currTime).endOf('month').format("YYYY-MM-DD 00:00:00");
        const v_u = convertRange2Keys(range);
        const [v, u] = !!v_u ? v_u : [12, 'months'];
        const startTime = moment(currTime).subtract(v, u).startOf('month').format("YYYY-MM-DD 00:00:00");
        return [startTime, endTime];
    } else {
        //other
        const endTime = moment().format("YYYY-MM-DD 00:00:00");
        const v_u = convertRange2Keys(range);
        const [v, u] = !!v_u ? v_u : [2, 'days'];
        const startTime = moment(endTime).subtract(v, u).format("YYYY-MM-DD 00:00:00");
        return [startTime, endTime];
    }
};

const getTimestamps = (timeType, range) => {
    const [startTime, endTime] = getDefaultTimeRange(timeType, range);
    const type2Unit = {
        1: [1, 'hours'],
        2: [1, 'days'],
        3: [1, 'weeks'],
        4: [1, 'months'],
    };
    const [v, u] = type2Unit[timeType] || [1, 'days'];
    const retTimestamps = [];
    const stamp = moment(startTime);
    const eT = moment(endTime);
    while (stamp.isSameOrBefore(eT)) {
        retTimestamps.push(stamp.unix());
        stamp.add(v, u);
    };
    return retTimestamps;
};

export {
    getDefaultTimeRange,
    getTimestamps,
};