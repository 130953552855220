import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time, range, base_id } = conf;
            if (typeof time !== 'string' || typeof base_id !== 'number') {
                setResult(null);
                setLoading(false);
                return;
            };
            let requesting = true;
            setLoading(true);
            const day_begin = moment(time).startOf(range).format("YYYY-MM-DD");
            const day_end = moment(time).endOf(range).format("YYYY-MM-DD");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_history_statistics', {
                day_begin, day_end, base_id,
            })
                .then((retData) => {
                    if (requesting) {
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData.data;
                            let parsed_data_history = nextResult?.data_history;
                            if (!!parsed_data_history) {
                                parsed_data_history = parsed_data_history
                                    .map((item) => {
                                        return [moment(item[0]).unix() * 1000, item[1]];
                                    });
                            };
                            let parsed_status_history = nextResult?.status_history;
                            if (!!parsed_status_history) {
                                const begin_unix = moment(`${day_begin} 00:00:00`).unix();
                                const end_unix = moment(`${day_end} 23:59:00`).unix();//这里是00秒，不是59秒，实践得出
                                const unix_width = end_unix - begin_unix;
                                parsed_status_history = parsed_status_history
                                    .map((item, index) => {
                                        const { time_begin, time_end, status } = item;
                                        let width = moment(time_end).unix() - moment(time_begin).unix();
                                        if (width < 0) width = 0;
                                        const width_rate = `${width / unix_width * 100}%`;
                                        let left = moment(time_begin).unix() - begin_unix;
                                        if (left < 0) left = unix_width;
                                        const left_rate = `${left / unix_width * 100}%`;
                                        const color = nextResult?.status_summary.filter(({ value }) => value === status)[0]?.color ?? "transparent";
                                        return Object.assign({}, item, {
                                            color,
                                            width_rate,
                                            left_rate,
                                        });
                                    });
                            };
                            let parsed_status_summary = nextResult?.status_summary;
                            if (!!parsed_status_summary) {
                                parsed_status_summary = parsed_status_summary
                                    .map((item) => {
                                        const { hours } = item;
                                        const parsed_hours = typeof hours === 'number' ? significantNum(hours, 5) : "--";
                                        return Object.assign({}, item, {
                                            parsed_hours,
                                        })
                                    });
                            };
                            nextResult = Object.assign({}, nextResult, {
                                parsed_data_history,
                                parsed_status_history,
                                parsed_status_summary,
                            });
                            // console.log(nextResult);
                            setResult(nextResult);
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                        // final
                        setLoading(false);
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
