import {
    Pagination, Select, Spin,
    // Radio,
} from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize';
import { useData } from './hooks';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import LineChart from './LineChart';
import { withRouter } from 'react-router';
import CusPageSize from '../../components/PageSize';
import { useTableData } from '../../hooks';
import HisModal from './HistoryModal';

const { Option } = Select;

const margin_width = 24;

const Page = (props) => {
    const { timeInfo } = props;

    const [defaultCategory] = useState(() => {
        const category = new URLSearchParams(props.location.search).get('category');
        if (typeof category === 'string' && category !== "") {
            return category;
        };
        return null;
    });

    const [defaultSysId] = useState(() => {
        const sys_id = new URLSearchParams(props.location.search).get('sysId');
        if (typeof sys_id === 'string' && sys_id !== "") {
            const parsed_sys_id = parseInt(sys_id);
            if (typeof parsed_sys_id === 'number' && !isNaN(parsed_sys_id)) {
                return parsed_sys_id;
            };
        };
        return null;
    });

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current(timeInfo ?? {});
    }, [timeInfo]);

    const [width, setWidth] = useState(null);
    const onResize = (conf) => {
        setWidth(conf.width);
    };

    const {
        display_list,
        paginationInfo, onChange,
        sysInfo, sysOnChange, prodInfo, prodOnChange, catInfo, catOnChange, compInfo, compOnChange,
        sortInfo, setSortInfo,
    } = useTableData(
        Object.assign(
            {},
            {
                data,
                defaultPageSize: 30,
                sortBlankValue: true,
            },
            typeof defaultCategory === 'string' ? { defaultCategory } : {},
            typeof defaultSysId === 'number' ? { defaultSysId } : {},
        )
    );

    const anBtnOnClick = (item) => {
        let suffix = `id=${item.id}`;
        if (!!timeInfo) {
            suffix += `&time=${timeInfo.time}&timeKey=${timeInfo.key}`;
        };
        const pathname = props?.history?.location?.pathname ?? null;
        if (typeof pathname === 'string' && pathname !== "") {
            const layoutName = pathname.split("/")[1] ?? null;
            if (typeof layoutName === 'string' && layoutName !== "") {
                props.history.push(`/${layoutName}/Analysis?${suffix}`);
                return;
            };
        };
        props.history.push(`/PEIMSV2/Analysis?${suffix}`);
    };

    const [modalInfo, setModalInfo] = useState(null);
    const hisCompBtnOnClick = (item) => {
        setModalInfo(item);
    };

    return (
        <div className={style['wrapper']}>
            <div className="top" style={{ paddingLeft: margin_width }}>
                <div className="item">
                    <div className="title">选择系统</div>
                    <div className="value">
                        <Select
                            value={sysInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => sysOnChange(v)}
                        >
                            <Option value="all">全部系统</Option>
                            {
                                (sysInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="title">选择产品</div>
                    <div className="value">
                        <Select
                            value={prodInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => prodOnChange(v)}
                        >
                            <Option value="all">全部产品</Option>
                            {
                                (prodInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="title">指标类型</div>
                    <div className="value">
                        <Select
                            value={catInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => catOnChange(v)}
                        >
                            <Option value="all">全部类型</Option>
                            {
                                (catInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="title">对标结果</div>
                    <div className="value">
                        <Select
                            value={compInfo?.selected?.key ?? "all"}
                            style={{ width: 118 }}
                            onChange={(v) => compOnChange(v)}
                        >
                            <Option value="all">全部</Option>
                            {
                                (compInfo?.list ?? [])
                                    .map((item, index) => {
                                        return (
                                            <Option value={item.key} key={index}>{item.name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <div style={{ height: 0 }}>
                <DomResize onResize={onResize} mountedCall={true} />
            </div>
            {
                typeof width === 'number' && (
                    <div className="bottom">
                        <div className="scroll_wrapper scroll_bar_restyle">
                            <div className="content" style={{ marginLeft: margin_width, width: width - 2 * margin_width }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="name">指标名称</th>
                                            <th>所属系统</th>
                                            <th>产品类型</th>
                                            <th>指标类型</th>
                                            <th>单位</th>
                                            <th>指标统计值</th>
                                            <th>指标标准值</th>
                                            <th>对标比较</th>
                                            <th>对标结果</th>
                                            <th>过去7{timeInfo?.cn_text ?? "周期"}趋势</th>
                                            <th className="sort">
                                                <div className="wrapper" onClick={() => setSortInfo("cpl_rate")}>
                                                    <div className="content">
                                                        <div className="text">指标合格率</div>
                                                        <div className={"sort_icons" + ((sortInfo?.key === "cpl_rate") ? (sortInfo?.is_desc ? " desc" : " asc") : "")}></div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th colSpan="2" className="sort">
                                                <div className="wrapper" onClick={() => setSortInfo("bias_pct")}>
                                                    <div className="content">
                                                        <div className="text">改进潜力</div>
                                                        <div className={"sort_icons" + ((sortInfo?.key === "bias_pct") ? (sortInfo?.is_desc ? " desc" : " asc") : "")}></div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="action">操作</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (display_list ?? [])
                                                .map((item, index) => {
                                                    const {
                                                        name, sys_name, product_name, category_name, unit, parsed_value, parsed_standard,
                                                        parsed_comp_pct, comp_pct, comp_status, history_values, lower_limit, upper_limit,
                                                        parsed_cpl_rate, parsed_bias_pct, bias_pct,
                                                        bias_bar_width,
                                                        is_good_cpl,
                                                        // casual_rel,
                                                        // casual_rel true表示可供根因分析，false时”指标分析“按钮置灰
                                                    } = item;
                                                    return (
                                                        <tr key={index}>
                                                            <td className="name">{name}</td>
                                                            <td>{sys_name}</td>
                                                            <td>{product_name}</td>
                                                            <td>{category_name}</td>
                                                            <td>{unit}</td>
                                                            <td>{parsed_value}</td>
                                                            <td>{parsed_standard}</td>
                                                            <td className={"comp" + (comp_status === 0 ? " green" : (comp_status === 1 ? " red" : ""))}>
                                                                <div className="content">
                                                                    {
                                                                        (typeof comp_pct === 'number' && comp_pct !== 0) ? (
                                                                            <div className={"arrow" + (comp_pct > 0 ? " up" : " down")}></div>
                                                                        ) : null
                                                                    }
                                                                    <div>{parsed_comp_pct}</div>
                                                                </div>
                                                            </td>
                                                            <td className={"comp_result"}>
                                                                {
                                                                    comp_status === 0 ? (
                                                                        <div className="content green">
                                                                            <CheckCircleFilled className="cus_icon" />
                                                                            <div>已达标</div>
                                                                        </div>
                                                                    ) : (
                                                                        comp_status === 1 ? (
                                                                            <div className="content red">
                                                                                <CloseCircleFilled className="cus_icon" />
                                                                                <div>未达标</div>
                                                                            </div>
                                                                        ) : "--"
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <LineChart history_values={history_values} lower_limit={lower_limit} upper_limit={upper_limit} />
                                                            </td>
                                                            <td className={"cpl_rate" + (is_good_cpl === false ? " red" : "")}>{parsed_cpl_rate}</td>
                                                            <td className="bias">{parsed_bias_pct}</td>
                                                            <td className={"bias_bar" + (typeof bias_pct === 'number' ? " has_value" : "")}>
                                                                <div className="bar_wrapper">
                                                                    <div className="bar" style={{ width: bias_bar_width }}></div>
                                                                </div>
                                                            </td>
                                                            <td className="action">
                                                                <div className="action_wrapper">
                                                                    <div className="btn" onClick={() => hisCompBtnOnClick(item)}>历史对标</div>
                                                                    <div
                                                                        className={
                                                                            "btn"
                                                                            // + (casual_rel !== true ? " disable" : "")
                                                                        }
                                                                        onClick={() => anBtnOnClick(item)}
                                                                    // {
                                                                    // ...(
                                                                    //     casual_rel === true ? {
                                                                    //         onClick: () => anBtnOnClick(item),
                                                                    //     } : {}
                                                                    // )
                                                                    // }
                                                                    >指标分析</div>
                                                                    <div className="btn">更多</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="footer_wrapper" style={{ paddingRight: margin_width }}>
                {
                    !!paginationInfo && paginationInfo?.total !== 0 && [
                        (
                            <Pagination
                                current={paginationInfo.current}
                                total={paginationInfo.total}
                                // showSizeChanger={true}
                                // 这个属性和缩放冲突...先false略去
                                showSizeChanger={false}
                                pageSizeOptions={paginationInfo.pageSizeOptions}
                                pageSize={paginationInfo.pageSize}
                                onChange={onChange}
                                key="1"
                            />
                        ),
                        (
                            <div className="page_size_selector" key="2">
                                <CusPageSize pageSizeOptions={paginationInfo.pageSizeOptions} pageSize={paginationInfo.pageSize} onChange={(pageSize) => onChange(1, pageSize)} />
                            </div>
                        ),
                    ]
                }
            </div>
            {
                !!modalInfo && (
                    <HisModal
                        info={modalInfo}
                        timeInfo={timeInfo}
                        onCancel={() => setModalInfo(null)}
                    />
                )
            }
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Page);
