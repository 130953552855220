import moment from 'moment'
const TIME_RANGE_HOURS = 8

/** 计算时间轴整点点位 */
const calcTimeStamps = (endTime, shiftConfig) => {
  if (moment.isMoment(endTime)) {
    if (typeof TIME_RANGE_HOURS === 'number' && TIME_RANGE_HOURS > 0) {
      // 认为TIME_RANGE_HOURS是整数
      const today = moment().startOf('day')
      const yesterday = moment(today).subtract(1, 'days')
      // 把结束时间向前推 TIME_RANGE_HOURS 作为开始时间
      const startTime = moment(endTime).subtract(TIME_RANGE_HOURS, 'hours')
      const allSeconds = TIME_RANGE_HOURS * 60 * 60 // all seconds
      const unixEndTime = moment(endTime).unix()

      let handOverTimes = []
      if (!!shiftConfig) {
        handOverTimes = [].concat(
          ...shiftConfig.map(({ beginTime, endTime }) => [beginTime, endTime])
        )
        handOverTimes = ['23:00', '7:00', '16:30']
        handOverTimes = [...new Set(handOverTimes)]
          .map(timeStamp =>
            [
              {
                time: moment(endTime).format('YYYY-MM-DD ') + timeStamp
              }
            ].concat(
              moment(endTime).isSame(moment(startTime), 'day')
                ? []
                : [
                    {
                      time: moment(startTime).format('YYYY-MM-DD ') + timeStamp
                    }
                  ]
            )
          )
          .flat(1)
          .map(({ time }) => ({
            time: moment(time),
            isHandOverTime: true
          }))
      }

      let ret = new Array(TIME_RANGE_HOURS + 1) // 这里需要加一，因为有小概率是HH:00:00, 后续考虑抹平毫秒为00
        .fill(null)
        .map((item, index) => moment(endTime).subtract(index, 'hours').startOf('hour'))
        .map(time => ({
          time,
          isHandOverTime: false,
          isSameShiftTime: (function () {
            if (handOverTimes.some(overTime => overTime.time.isSame(time))) {
              handOverTimes = handOverTimes.filter(overTime => !overTime.time.isSame(time))
              return true
            } else {
              return false
            }
          })()
        }))
        .concat(handOverTimes)
        .filter(({ time }) => time.isSameOrAfter(startTime) && time.isSameOrBefore(endTime))
        .map(item => {
          const { time } = item
          const Hms = moment(time).format('HH:mm')
          let YMD = moment(time).format('YYYY/MM/DD')
          if (moment(time).isSameOrAfter(today)) {
            YMD = '今天'
          } else if (moment(time).isSameOrAfter(yesterday)) {
            YMD = '昨天'
          }

          const deltaSeconds = unixEndTime - moment(time).unix()
          const isLeft = moment(time).hour() % 2 === 1
          return Object.assign({}, item, {
            bottom: `${(deltaSeconds / allSeconds) * 100}%`,
            text: `${YMD} ${Hms}`,
            isLeft // demo 3.0 刻度都在右侧了 这个属性暂时用不到
          })
        })
      // +sort, +check HandOverTime
      return ret
    }
  }
  return null
}

/** 计算时间轴游标位置 */
const calcCursorPosion = (endTime, timeRange) => {
  if (moment.isMoment(endTime)) {
    const allSeconds = TIME_RANGE_HOURS * 60 * 60 // all seconds
    const unixEndTime = moment(endTime).unix()
    const { start, end } = timeRange
    const startSeconds = unixEndTime - moment(start).unix()
    const endSeconds = unixEndTime - moment(end).unix()

    const position = {
      bottom: `${(endSeconds / allSeconds) * 100}%`,
      height: `${
        start === end ? 0 : (Math.abs(endSeconds - startSeconds) / allSeconds) * 100 + '%'
      }`
    }
    return position
  }
}

/** 计算按时间分组后在时间轴上的位置 */
const calculateGroupByMinutePosition = (endTime, time, unit = 10) => {
  if (moment.isMoment(endTime)) {
    const allSeconds = TIME_RANGE_HOURS * 60 * 60 // all seconds
    const unixEndTime = moment(endTime).unix()
    const minute = moment(time).format('mm')
    let newMinute = Math.floor(minute / unit) * unit + unit / 2
    newMinute = newMinute < 10 ? '0' + newMinute : newMinute
    const newTime = moment(time).format('YYYY-MM-DD HH:') + newMinute + ':00'
    const diffSeconds = unixEndTime - moment(newTime).unix()
    const bottom = `${(diffSeconds / allSeconds) * 100}%`
    return { newTime, bottom }
  }
}

export { TIME_RANGE_HOURS, calcTimeStamps, calcCursorPosion, calculateGroupByMinutePosition }
