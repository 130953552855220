import React from 'react';
import { Menu, Icon, Button, Modal, Radio, Slider, DatePicker, Checkbox, InputNumber, Drawer, Input, Transfer, Table } from 'antd';

// import './Content.css';
const moment = require('moment');
const { RangePicker } = DatePicker;

export default class RangePickerModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeRange: null,
            selectedPeriodIndex: props.selectedPeriodIndex,
            periods: props.periods,
        }
    }

    componentDidMount() {
        const { periods, selectedPeriodIndex } = this.state;
        if (selectedPeriodIndex !== null) {
            this.setState({
                timeRange: periods[selectedPeriodIndex].map(time => moment(time, 'YYYY-MM-DD HH:mm:ss'))
            })
        }
    }

    timeRangeModalOnOk = () => {
        const { timeRange, selectedPeriodIndex } = this.state;
        const { onOk = () => { } } = this.props;
        onOk(timeRange, selectedPeriodIndex);
        this.setState({ timeRange: null });
    }

    timeRangeOnChange = timeRange => {
        this.setState({ timeRange });
    }

    render() {
        const { timeRange } = this.state;
        const { onCancel = () => { } } = this.props;

        return (
            <Modal
                title="选择时间"
                centered
                visible={true}
                onOk={this.timeRangeModalOnOk}
                onCancel={onCancel}
                width={600}
                okButtonProps={{ disabled: !timeRange }}
            >
                <div>
                    <RangePicker
                        showTime={{ format: 'HH:mm:ss' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder={['开始时间', '结束时间']}
                        value={timeRange}
                        onChange={this.timeRangeOnChange}
                    />
                </div>
            </Modal>
        );
    }
}
