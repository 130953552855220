import React from 'react';
import {
    CloseOutlined
} from '@ant-design/icons';

import GAISHANJIHUI_ICON from './res/gaishanjihui.png';
import TITLE_ICON_2 from './res/title_icon_2.png';

import './ImproveChanceModal.scss';
const moment = require('moment');

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    onCancel = () => {
        const { onClose = () => { } } = this.props;
        onClose();
    }

    render() {

        return (
            <div className="main_page_bottom_panels_improve_chance_modal_wrapper">
                <div className="mask" onClick={this.onCancel} />
                <div className="cus_modal_wrapper">
                    <div className="modal_header">
                        <img alt="" src={GAISHANJIHUI_ICON} className="title_icon" />
                        <div className="title">改善机会</div>
                        <div className="time">【 {moment().format('YYYY年MM月DD日')} 】 </div>
                        {/* <div className="time">【 2020年12月20日 】 </div> */}
                        <CloseOutlined className="cus_close_icon" onClick={this.onCancel} />
                    </div>
                    <div className="modal_body">
                        <div className="left">
                            <div className="header_block">
                                <div className="left_block" />
                                <div className="ave_title">平均值</div>
                                <div className="standard_title">基准值</div>
                            </div>
                            <div className="left_content_block scroll_bar_restyle">
                                <div className="union_wrapper">
                                    <div className="left_line" />
                                    <div className="items_wrapper">
                                        {
                                            new Array(20)
                                                .fill(0)
                                                .map((_, index) => {
                                                    const diff = Math.random() < 0.8;
                                                    const color = diff ? 'rgba(68, 243, 218, 1)' : 'rgba(179, 118, 255, 1)';
                                                    const tipText = diff ? 'KPI' : 'RF';
                                                    return (
                                                        <div className="item_row_wrapper" key={index}>
                                                            <div className="circle_block">
                                                                <div className="circle_wrapper" style={{ borderColor: color }}>
                                                                    <div className="circle_2" style={{ backgroundColor: color }} />
                                                                </div>
                                                            </div>
                                                            <div className="time_wrapper">{moment().format('YYYY.MM.DD')}  12:30:23</div>
                                                            {/* <div className="time_wrapper">2020.08.23  12:30:23</div> */}
                                                            <div className="tip_wrapper">
                                                                <div className="text" style={{ backgroundColor: color }}>{tipText}</div>
                                                            </div>
                                                            <div className="name_wrapper">熟料煤单耗</div>
                                                            <div className="ave_value">129.0</div>
                                                            <div className="standard_value">120.4</div>
                                                            <div className="hint_wrapper">超上限</div>
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal_body_devide_line" />
                        <div className="right">
                            <div className="title_wrapper">
                                <img alt="" src={TITLE_ICON_2} className="title_icon" />
                                <div className="title">TOP10 改善机会</div>
                            </div>
                            <div className="table_content scroll_bar_restyle">
                                <div className="items_wrapper">
                                    {
                                        new Array(10)
                                            .fill(0)
                                            .map((_, index) => {
                                                return (
                                                    <div className="item_row_wrapper" key={index}>
                                                        <div className="rank_wrapper">
                                                            <div className="text">{index + 1}</div>
                                                        </div>
                                                        <div className="tip_wrapper">
                                                            <div className="text">KPI</div>
                                                        </div>
                                                        <div className="name_wrapper">熟料煤单耗</div>
                                                        <div className="hint_wrapper">超上限</div>
                                                        <div className="value_wrapper">120.4</div>
                                                    </div>
                                                );
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
