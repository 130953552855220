import React, { useEffect, useState, useMemo } from 'react'
import style from './index.module.scss'
import ICON_ARROW_UP from './res/arrow_up.svg'
import ICON_ARROW_DOWN from './res/arrow_down.svg'
import ICON_AVATAR from './res/avatar.svg'
import { message } from 'antd'
import { useShiftConfig } from './hooks'
import { calcTimeStamps, calcCursorPosion, calculateGroupByMinutePosition } from './utils'
import { urgencyAndColor } from '../utils'
import { Badge } from 'antd'
import moment from 'moment'

const Comp = props => {
  const { isCurrent, endTime, timeRange, capsules, newEventCount } = props
  const [shiftConfig] = useShiftConfig()
  const [cursorPosition, changeCursorPosition] = useState({ top: 0, height: 0 })
  const [groupByTimeMarks, setGroupByTimeMarks] = useState({})
  const [showNewNum, setShowNewNum] = useState(false)

  const timeStamps = useMemo(() => {
    return calcTimeStamps(isCurrent, endTime, shiftConfig)
  }, [isCurrent, endTime, shiftConfig])

  useEffect(() => {
    if (!!timeRange && typeof timeRange === 'object') {
      const { start, end } = timeRange
      if (!start || !end) {
        changeCursorPosition({ top: 0, height: 0 })
      } else {
        const position = calcCursorPosion(endTime, timeRange)
        changeCursorPosition(position)
      }
      if (end === moment().format('YYYY-MM-DD HH:mm:00')) {
        setShowNewNum(false)
      } else {
        setShowNewNum(true)
      }
    }
  }, [timeRange])

  /** 将胶囊汇总到时间轴
   * 1.按事件发生时间每10分钟汇总
   * 2.按直径从大到小
   * 3.直径相同的按照紧急程度从大到小
   */
  useEffect(() => {
    const groupByTimeMap = {}
    capsules.forEach(capsuleItem => {
      const { occur_time } = capsuleItem
      const { newTime, top } = calculateGroupByMinutePosition(endTime, occur_time)
      if (!groupByTimeMap[newTime]) {
        groupByTimeMap[newTime] = {}
        groupByTimeMap[newTime]['top'] = top
        groupByTimeMap[newTime]['capsules'] = [{ ...capsuleItem }]
      } else {
        groupByTimeMap[newTime].top = top
        groupByTimeMap[newTime].capsules.push({ ...capsuleItem })
      }
    })
    Object.keys(groupByTimeMap).forEach(key => {
      Object.keys(urgencyAndColor).forEach(urgency_code => {
        const urgency = Number(urgency_code)
        const groupByUrgencyList = groupByTimeMap[key]['capsules'].filter(
          capsuleItem => capsuleItem.urgency === urgency
        )
        if (groupByUrgencyList.length === 0) return
        const capsuleMarks = groupByTimeMap[key]['capsuleMarks'] || []
        capsuleMarks.push({
          urgency: urgency,
          color: urgencyAndColor[urgency_code],
          capsules: groupByUrgencyList,
          radius: 2 + groupByUrgencyList.length
        })
        groupByTimeMap[key]['capsuleMarks'] = capsuleMarks
      })
      // 排序
      groupByTimeMap[key]['capsuleMarks'].sort((groupA, groupB) => {
        // 按直径从大到小
        if (groupA.radius - groupB.radius > 0) {
          return -1
        }
        // 直径相同的按照紧急程度从大到小
        if (groupA.radius === groupB.radius) {
          if (groupA.urgency - groupB.urgency > 0) {
            return -1
          }
        }
        return 1
      })
    })

    setGroupByTimeMarks(groupByTimeMap)
  }, [capsules])

  /**
   * 向前/向后推8小时
   * @param addon 1:向前 -1:向后
   */
  const onClickBeforeOrAfter = addon => {
    if (addon === 1) {
      const localCurrentTime = moment().format('YYYY-MM-DD HH:mm')
      const tempEndTime = moment(endTime).format('YYYY-MM-DD HH:mm')
      if (moment(tempEndTime).isSame(moment(localCurrentTime))) {
        if (!isCurrent) {
          setToCurrent()
        } else {
          message.info('已是最新时间！')
          return
        }
      }
    }
    const { timeChangeOnClick } = props
    if (typeof timeChangeOnClick === 'function') {
      timeChangeOnClick(addon)
    }
  }

  /** 点击当前按钮 */
  const setToCurrent = () => {
    if (typeof props.setToCurrent === 'function') props.setToCurrent()
  }

  /** 跳转至指定时刻 */
  const toggleTime = time => {
    if (typeof props.toggleTime === 'function') props.toggleTime(time)
  }

  return (
    <div className={style['wrapper']}>
      <div className="bck_block_wrapper">
        <img
          alt=""
          src={ICON_ARROW_UP}
          className={'icon_arrow icon_arrow_up' + (isCurrent === true ? ' current' : '')}
          onClick={() => onClickBeforeOrAfter(1)}
        />
        <img
          alt=""
          src={ICON_ARROW_DOWN}
          className="icon_arrow icon_arrow_down"
          onClick={() => onClickBeforeOrAfter(-1)}
        />
        <div className="current_tag" onClick={setToCurrent}>
          <div className="text">当前</div>
          {showNewNum && <Badge className="new-event-count" count={newEventCount} />}
        </div>

        {!!timeStamps && (
          <div className="time_line_range">
            {timeStamps.map((item, index) => {
              const { top, isLeft, text, isHandOverTime } = item
              const contentStyle = isLeft
                ? {
                    left: 0,
                    marginLeft: 8,
                    transform: 'translate(-100%,-50%)'
                  }
                : {
                    right: 0,
                    marginRight: 8,
                    transform: 'translate(100%,-50%)'
                  }
              const content = (
                <div className="text" key="text" onClick={() => toggleTime(item.time)}>
                  {text}
                </div>
              )
              const line = <div className="line" key="line"></div>
              const space = <div className="space" key="space"></div>

              const space2 = <div className="space2" key="space2"></div>
              const avatar = <img alt="" src={ICON_AVATAR} className="avatar" key="avatar" />

              const avatarDom = isHandOverTime ? [avatar, space2] : []
              return (
                <div
                  key={index}
                  style={{ top }}
                  className={'single_time_stamp' + (isHandOverTime ? ' hand_over_time' : '')}
                >
                  <div className="content_wrapper" style={contentStyle}>
                    {isLeft
                      ? [...avatarDom, content, space, line]
                      : [line, space, content, ...avatarDom.reverse()]}
                  </div>
                </div>
              )
            })}
            {timeRange && capsules && capsules.length > 0 && (
              <div
                className="cursor-range"
                style={{ top: cursorPosition.top, height: cursorPosition.height }}
              />
            )}
            {Object.keys(groupByTimeMarks).map(key => {
              const item = groupByTimeMarks[key]
              return (
                <div
                  key={key}
                  className="capsuleMarkGroup"
                  style={{ top: item.top }}
                  onClick={() => toggleTime(item.capsules[0].occur_time)}
                >
                  {item.capsuleMarks.map((items, index) => {
                    return (
                      <div
                        key={items.urgency}
                        className="capsuleMark"
                        style={{
                          width: items.radius * 2,
                          height: items.radius * 2,
                          backgroundColor: items.color,
                          marginLeft: index > 0 ? -items.radius : 0
                        }}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Comp
