import React from 'react';
import { withRouter } from 'react-router';
import { get } from 'common/utils';
import { Select, Button } from 'antd';
import style from './HourlyFactorsDiagram.module.scss';
import BarFactorChart from './BarFactorChart.js';
import BoxplotFactorChart from './BoxplotFactorChart.js';
const Option = Select.Option;

class HourlyFactorsDiagram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // loading: true
            dataList: [],
            selectedFactor: null,
            factors: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    convertDataList = (f_settings, hourlyFactors) => {
        let results = [];
        let axisData = hourlyFactors[0].hourly_datas.map(item => item.time_begin);
        let getHourlyDatas = (factor_id) => {
            let r = hourlyFactors.filter(item => item.factor_id == factor_id)[0];
            if (r) {
                return r.hourly_datas;
            } else {
                return [];
            }
        }
        f_settings.forEach(f_setting => {
            let datas = getHourlyDatas(f_setting._id);
            results.push({
                'f_setting': f_setting,
                'axisData': axisData,
                'boxData': datas,
            });
        })

        return results;
    }

    getData = () => {
        let self = this;
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/get_one', {
            _id: self.props.examId
        }).then(retData => {
            if (retData.data && retData.data.errcode === 0) {
                console.log(retData.data);
                let r = retData.data.result;
                let dataList = self.convertDataList(r.rule_data.factors, r.hourly_factors);
                self.setState({
                    dataList: dataList,
                    factors: r.rule_data.factors,
                    selectedFactor: (r.rule_data.factors[0] || {})._id || null,
                });
                console.log(dataList);
            }
        });
    }

    render() {
        const { selectedFactor, factors } = this.state;
        return (
            <div className={style["HourlyFactorsDiagram"]}>
                {
                    // <Header statusOnChange={this.statusOnChange} />
                }
                {/* <div className="line_bottom"></div> */}
                <div className="filter_wrapper">
                    <div>选择指标</div>
                    <Select
                        defaultValue={selectedFactor}
                        value={selectedFactor}
                        style={{ width: 200 }}
                        onChange={v => this.setState({ selectedFactor: v })}
                    >
                        {
                            factors.map(({ _id, meta_name }) =>
                                <Option value={_id} key={_id}>{meta_name}</Option>)
                        }
                    </Select>
                    {/* <Button
                        type="primary" onClick={() => this.props.history.push('/System/ShiftList')}
                        style={{
                            marginLeft: 'auto',
                            backgroundColor: '#357CF7',
                            borderColor: '#357CF7',
                        }}
                    >选择班组对比分析</Button> */}
                    {/* 应该没有班组对比 */}
                </div>
                {
                    this.state.dataList
                        .filter(({ f_setting: { _id } }) => selectedFactor === _id)
                        .map((item, index) => {
                            if (item['f_setting']['score_method'] == 1 || item['f_setting']['score_method'] == 2) {
                                return (<BarFactorChart
                                    key={`${item.f_setting._id}_${index}`}
                                    fSetting={item['f_setting']}
                                    axisData={item['axisData']}
                                    boxData={item['boxData']}
                                />)
                            } else {
                                return (<BoxplotFactorChart
                                    key={`${item.f_setting._id}_${index}`}
                                    fSetting={item['f_setting']}
                                    axisData={item['axisData']}
                                    boxData={item['boxData']}
                                />)
                            }
                        })
                }
            </div >
        );
    }
}

export default withRouter(HourlyFactorsDiagram);