import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import Header from './HeaderWrapper';
import LeftMenu from './Menu'
import ScaleWrapper from 'components/ScaleWrapperV2';
const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className='scroll_wrapper'>
                <div className='content'>
                    <Header />
                    <div className="bottom_wrapper">
                        <div className="left">
                            <LeftMenu />
                        </div>
                        <div className="right">
                            <ScaleWrapper width={1840} height={1016}>
                                <div className={style["inner_content"]}>
                                    {props.children}
                                </div>
                            </ScaleWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Page);
