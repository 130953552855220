import React from 'react';
import { useState, useEffect } from 'react';
import {
    CloseOutlined
} from '@ant-design/icons';

import FactorItem from './FactorItem.js';

import SHENCHANGAIKUANG_ICON from './res/shengchangaikuang.png';
import TITLE_ICON_2 from './res/title_icon_2.png';

import './ProductionModal.scss';
const moment = require('moment');

const SingleBar = (props) => {
    const { rate: finalRate, warning } = props;
    const [rate, setRate] = useState(0);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        // console.log("useEffect", barContentWidthPercent);
        if (!isMounted) {
            setTimeout(() => { setIsMounted(true) }, 0);
        } else {
            if (rate < finalRate) {
                let newRate = rate + 3;
                if (newRate > finalRate) {
                    newRate = finalRate;
                };
                setTimeout(() => {
                    setRate(newRate);
                }, 20);
            };
        };
    });
    return (
        <div className={"top_bar" + (warning ? " top_bar_warning" : "")} style={{ width: `${rate}%` }} />
    );
}

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            factorList: [],
        };
    }

    componentDidMount() {
        this.setState({
            factorList: new Array(10)
                .fill(0)
                .map((_, index) => {
                    return {
                        name: '生料台时' + index,
                        warning: Math.random() > 0.5,
                    };
                }),
        });
    }

    onCancel = () => {
        const { onClose = () => { } } = this.props;
        onClose();
    }

    render() {
        const { factorList } = this.state;

        return (
            <div className="main_page_bottom_panels_production_modal_wrapper">
                <div className="mask" onClick={this.onCancel} />
                <div className="cus_modal_wrapper">
                    <div className="modal_header">
                        <img alt="" src={SHENCHANGAIKUANG_ICON} className="title_icon" />
                        <div className="title">生产概况</div>
                        <div className="time">【 {moment().format('YYYY年MM月DD日')} 】 </div>
                        {/* <div className="time">【 2020年12月20日 】 </div> */}
                        <CloseOutlined className="cus_close_icon" onClick={this.onCancel} />
                    </div>
                    <div className="modal_body">
                        <div className="left">
                            <div className="left_header">
                                <div className="title_wrapper">
                                    <img alt="" src={TITLE_ICON_2} className="title_icon" />
                                    <div className="title">生产计划</div>
                                </div>
                                <div className="subtitle">{moment().format('YYYY年MM月')}生产计划</div>
                                {/* <div className="subtitle">2020年12月生产计划</div> */}
                            </div>
                            <div className="table_header row_devide">
                                <div className="name">名称</div>
                                <div className="chart">柱状图</div>
                                <div className="value">实绩</div>
                                <div className="project">计划</div>
                                <div className="rate">完成度</div>
                            </div>
                            <div className="table_content scroll_bar_restyle">
                                <div className="items_wrapper">
                                    {
                                        new Array(18)
                                            .fill(0)
                                            .map((_, index) => {
                                                const nameBordered = Math.random() > 0.5;
                                                const projectNum = Math.ceil(Math.random() * 300) + 1400;
                                                const completedNum = projectNum - Math.ceil(Math.random() * 300);
                                                const rate = Math.floor(completedNum / projectNum * 100);
                                                const warning = Math.random() > 0.7;
                                                return (
                                                    <div className="item_wrapper row_devide" key={index}>
                                                        <div className="name">
                                                            <div className={"name_text" + (nameBordered ? " name_text_bordered" : "")}>普通生料</div>
                                                        </div>
                                                        <div className="chart">
                                                            <SingleBar warning={warning} rate={rate} />
                                                            {/* <div className={"top_bar" + (warning ? " top_bar_warning" : "")} style={{ width: `${rate}%` }} /> */}
                                                            <div className="bottom_bar" />
                                                        </div>
                                                        <div className={"value" + (warning ? " text_warning" : "")}>{completedNum}</div>
                                                        <div className="project">{projectNum}</div>
                                                        <div className={"rate" + (warning ? " text_warning" : "")}>{rate}%</div>
                                                    </div>
                                                );
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal_body_devide_line" />
                        <div className="right">
                            <div className="title_wrapper">
                                <img alt="" src={TITLE_ICON_2} className="title_icon" />
                                <div className="title">生产趋势</div>
                            </div>
                            <div className="table_content scroll_bar_restyle">
                                <div className="items_wrapper">
                                    {
                                        factorList
                                            .map((item, index) => {
                                                const retNodes = [];
                                                if (index > 0) {
                                                    retNodes.push(
                                                        <div key={`aLine_${index}`} className="item_top_line" />
                                                    );
                                                };
                                                retNodes.push(
                                                    <div key={`item_${index}`} className="item_self_wrapper">
                                                        <FactorItem factor={item} />
                                                    </div>
                                                );
                                                return retNodes;
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
