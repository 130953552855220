import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Select } from 'antd'
import { post } from 'common/utils'
import style from './index.module.scss'
import Empty from '../../../Empty'
import PointLine from './PointLine'
const { Option } = Select

/** 点位选择及展示 */
const Com = props => {
  const { isDisabled, points, clearAction, occur_time } = props
  const [pointList, setPointList] = useState([]) // 点位
  const [selectPointList, setSelectPointList] = useState([])

  /** 暴露给父组件的方法和属性 */
  useImperativeHandle(props.refInstance, () => ({
    selectPointList
  }))

  //模糊查询点位
  useEffect(() => {
    !isDisabled && handleSearch()
    points && setSelectPointList(points)
  }, [isDisabled, points])

  useEffect(() => {
    // 清空
    if (clearAction) {
      setPointList([])
      setSelectPointList([])
    }
  }, [clearAction])

  /** 模糊查询点位 */
  const handleSearch = value => {
    post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
      keyword: value,
      page: 1,
      page_size: 10
    })
      .then(res => {
        res.data && setPointList(res.data.results || [])
      })
      .catch(err => {
        console.log(err)
      })
  }
  /** 增加关联点位 */
  const selectPoint = pointId => {
    if (selectPointList.some(point => point.id === pointId)) return
    const selectPoint = pointList.filter(pointItem => pointItem.id === pointId)
    if (selectPoint && selectPoint.length > 0) {
      const tempList = selectPointList.concat(selectPoint)
      setSelectPointList(tempList)
    }
  }
  /** 移除选中点位 */
  const removePoint = pointIndex => {
    selectPointList.splice(pointIndex, 1)
    const tempList = selectPointList.concat()
    setSelectPointList(tempList)
  }
  return (
    <div className={`${style['pointSelectorWrapper']} ${isDisabled ? 'detail-view' : ''}`}>
      <div className="point-selector">
          {!isDisabled && (
            <div className="search-container">
              <Select
                showSearch
                className="search-select"
                //   value={searchValue}
                onSearch={handleSearch}
                style={{ width: 386 }}
                placeholder="搜索增加点位"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onChange={selectPoint}
              >
                {pointList.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {`${item.tag_name} ${item.name}`}
                    </Option>
                  )
                })}
              </Select>
              <i className="search-icon" />
            </div>
          )}
          <div className="selected-points" style={{ marginTop: selectPointList.length > 0 ? 8 : 0 }}>
            {selectPointList.map((item, pointIndex) => {
              return (
                <div key={pointIndex} className="point-item">
                  {typeof item === 'object' ? `${item.tag_name} ${item.name}` : item}
                  {!isDisabled && (
                    <div className="remove-btn" onClick={() => removePoint(pointIndex)} />
                  )}
                </div>
              )
            })}
          </div>
          {!isDisabled && selectPointList.length === 0 && <div style={{paddingTop:16}}><Empty tipMsg="暂无关联数据点" /></div>}
      </div>
      {selectPointList && selectPointList.length > 0 && <PointLine isDisabled={isDisabled} points={selectPointList || []} occur_time={occur_time}/>}
      
      {isDisabled && selectPointList.length === 0 && <div style={{paddingTop:24}}><Empty tipMsg="暂无关联数据点" /></div>}      
      
    </div>
  )
}

export default Com
