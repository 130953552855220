import React from 'react';
import { Modal, Table } from 'antd';

const Comp = (props) => {
    const handleCancel = () => {
        const { onCancel } = props;
        if (typeof onCancel === 'function') {
            onCancel();
        };
    };
    return (
        <Modal
            title="电能健康异常列表" visible={true} centered
            onCancel={handleCancel}
            footer={null} width={800}
        >
            <div>
                <Table
                    columns={[
                        {
                            title: '时间',
                            dataIndex: 'time',
                            sorter: (a, b) => (a.time > b.time) ? 1 : -1,
                            width: 160,
                        },
                        {
                            title: '详情',
                            dataIndex: 'desc',
                        },
                    ]}
                    dataSource={[
                        {
                            time: '2021/1/15 19:21:35',
                            desc: 'Uab:350V，偏差-20V，10%。',
                        },
                        {
                            time: '2021/1/15 19:21:36',
                            desc: 'Uab:350V，偏差-20V，10%。',
                        },
                        {
                            time: '2021/1/15 19:21:38',
                            desc: 'Uab:350V，偏差-20V，10%。',
                        },
                        {
                            time: '2021/1/15 19:21:37',
                            desc: 'Uab:350V，偏差-20V，10%。',
                        },
                    ].map((item, index) => Object.assign({}, item, { key: index }))}
                    pagination={{
                        showQuickJumper: true,
                        total: 100,
                    }}
                />
            </div>
        </Modal>
    );
};

export default Comp;
