import ICON_CSGJ from './res/canshugaijin.svg';
import ICON_BZDB from './res/banzuduibi.svg';

const table_datas = [
    {
        title: '参数改进',
        icon: ICON_CSGJ,
        path: 'api/kpi/brief/get_sys_cpl_status',
        columns: [
            {
                title: '名称',
                dataIndex: 'sys_name',
                align: 'center',
                width: 150,
                render: () => "二次风温度二次风温度",
            },
            {
                title: '单位',
                dataIndex: 'prod_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
            },
            {
                title: '均值',
                dataIndex: 'enrg_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
            },
            {
                title: '得分',
                dataIndex: 'matl_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
            },
            {
                title: '',
                dataIndex: 'oprn_pct',
                align: 'center',
                render: (text) => (typeof text === 'number' ? Math.floor(text * 1000) / 10 : "--") + "%",
            },
        ],
    },
    {
        title: '班组对比',
        icon: ICON_BZDB,
        path: 'api/kpi/brief/get_oprn_status',
        columns: [
            {
                title: '班组',
                dataIndex: 'sys_name',
                align: 'center',
                render: () => "早班",
            },
            {
                title: '时段',
                dataIndex: 'run_hours',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },
            {
                title: '未达标指标',
                dataIndex: 'stop_hours',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },
            {
                title: '指标合格率',
                dataIndex: 'abnormal_hours',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },//??? 前三列颜色黑色，最后列大于0的格子显示红色。
            // {
            //     title: '低负荷运行时长',
            //     dataIndex: 'low_range',
            //     align: 'center',
            // },
            {
                title: '竞赛得分',
                dataIndex: 'score',
                align: 'center',
                render: (text) => typeof text === 'number' ? Math.floor(text * 10) / 10 : "--",
            },
        ],
    },
];

export {
    table_datas,
};
