import TimeSelector from 'components/TimeSelector';
import moment from 'moment';

let sysDefaultFactorTime = null;
let env_time = process.env.REACT_APP_DEFAULT_FACTOR_TIME;
if (typeof env_time === 'string' && env_time !== "") {
    const time = moment(env_time);
    if (time.isValid()) {
        sysDefaultFactorTime = time.format("YYYY-MM-DD 00:00:00");
    };
};

const Comp = (props) => {

    return (
        <TimeSelector {
            ...(
                Object.assign(
                    {},
                    typeof sysDefaultFactorTime === 'string' ? {
                        defaultTime: sysDefaultFactorTime,
                    } : {},
                    props ?? {},
                )
            )
        } />
    );
};

export default Comp;
