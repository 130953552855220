const getColumns = () => {
    return [
        {
            title: "指标名称",
            dataIndex: "name",
        },
        {
            title: "单位",
            dataIndex: "unit",
        },
        {
            title: "标准值",
            dataIndex: "std",
        },
        {
            title: "指标均值",
            dataIndex: "avg",
        },
        {
            title: "合格率",
            dataIndex: "rate",
        },
        {
            title: () => {
                return (
                    <div>（早班）过程</div>
                );
            },
            dataIndex: "process_1",
        },
        {
            title: "均值",
            dataIndex: "avg_1",
        },
        {
            title: "合格率",
            dataIndex: "rate_1",
        },
        {
            title: () => {
                return (
                    <div>（中班）过程</div>
                );
            },
            dataIndex: "process_2",
        },
        {
            title: "均值",
            dataIndex: "avg_2",
        },
        {
            title: "合格率",
            dataIndex: "rate_2",
        },
        {
            title: () => {
                return (
                    <div>（夜班）过程</div>
                );
            },
            dataIndex: "process_3",
        },
        {
            title: "均值",
            dataIndex: "avg_3",
        },
        {
            title: "合格率",
            dataIndex: "rate_3",
        },
    ];
};

export {
    getColumns,
};
