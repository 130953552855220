import moment from 'moment';

const blank_func = () => { };

const default_silo_num = 16;
let SILO_NUM = default_silo_num;
if (['wlcb'].includes(process.env.REACT_APP_PROJECT_TAG)) {
    SILO_NUM = 14;
}

const shifts = [
    {
        shift_id: 1,
        name: "白班",
        disabledHours: () => {
            return [20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
        },
        renderTime: (time, HHmm) => {
            // (moment,moment)
            return moment(moment(time).format("YYYY-MM-DD ") + moment(HHmm).format("HH:mm:00"));
        },
    },
    {
        shift_id: 2,
        name: "夜班",
        disabledHours: () => {
            return [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
        },
        renderTime: (time, HHmm) => {
            // (moment,moment)
            const h = parseInt(HHmm.format("H"));
            if (h < 8) {
                time = moment(time).add(1, 'days');
            };
            return moment(moment(time).format("YYYY-MM-DD ") + moment(HHmm).format("HH:mm:00"));
        },
    },
]
    .map((item, index) => {
        const nextItem = Object.assign({}, item, {
            key: index + "",
        });
        return nextItem;
    });

const silosList = new Array(SILO_NUM)
    .fill(null)
    .map((item, index) => {
        return {
            silo_id: index + 1,
            key: index + "",
        };
    });

const genSilos = (data) => {
    const silos = (data ?? [])
        .reduce((pV, cV) => {
            if (!!cV.silo) {
                pV = pV.concat(cV.silo);
            };
            return pV;
        }, []);
    return silos;
};

const actions = [
    {
        name: "调整",
        value: "ADJUST",
        pathKey: "opt_adjust",
    },
    {
        name: "换料",
        value: "CHANGE",
        pathKey: "opt_change",
    },
    {
        name: "附加",
        value: "EXTRA",
        pathKey: "opt_extra",
    },
]
    .map((item, index) => {
        const nextItem = Object.assign({}, item, {
            key: index + "",
        });
        return nextItem;
    });

export {
    blank_func,
    shifts,
    silosList,
    genSilos,
    actions,
};
