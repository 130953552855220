import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Layout from '../Layout';
import { getBlocks } from './utils';
import BlockThree from './BlockThree/ThreeComp';

const Page = (props) => {

    const [blocks, setBlocks] = useState(() => {
        return getBlocks();
    });

    useEffect(() => {
        setBlocks(getBlocks());
    }, []);

    return (
        <Layout
            title="指标概览【2020年12月20日】"
            width={3840}
            height={1728}
        >
            <div className={style['wrapper']}>
                <div className="top">
                    <div className="item">
                        {blocks?.prod ?? null}
                    </div>
                    <div className="item_mid">
                        <BlockThree />
                    </div>
                    <div className="item">
                        {blocks?.cost ?? null}
                    </div>
                </div>
                <div className="bottom">
                    <div className="item">
                        {blocks?.elec ?? null}
                    </div>
                    <div className="item">
                        {blocks?.qlty ?? null}
                    </div>
                    <div className="item">
                        {blocks?.alarm ?? null}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Page;
