// 生产情况tag非当日
export const proTagNameList = [
    'PLANT_CNG_PRDT_CUM_D',
    'PLANT_CNG_PRDT_CUM_HDQOQ',
    'PLANT_CNG_PRDT_CUM_DM',
    'PLANT_CNG_PRDT_CUM_DY',
    'PLANT_CNG_PRDT_RATE_D',

    'PLANT_C2H6_PRDT_CUM_D',
    'PLANT_C2H6_PRDT_CUM_HDQOQ',
    'PLANT_C2H6_PRDT_CUM_DM',
    'PLANT_C2H6_PRDT_CUM_DY',
    'PLANT_C2H6_PRDT_RATE_D',

    'PLANT_C3H8_PRDT_CUM_D',
    'PLANT_C3H8_PRDT_CUM_HDQOQ',
    'PLANT_C3H8_PRDT_CUM_DM',
    'PLANT_C3H8_PRDT_CUM_DY',
    'PLANT_C3H8_PRDT_RATE_D',

    'PLANT_C4H10_PRDT_CUM_D',
    'PLANT_C4H10_PRDT_CUM_HDQOQ',
    'PLANT_C4H10_PRDT_CUM_DM',
    'PLANT_C4H10_PRDT_CUM_DY',
    'PLANT_C4H10_PRDT_RATE_D',

    'PLANT_C5P_PRDT_CUM_D',
    'PLANT_C5P_PRDT_CUM_HDQOQ',
    'PLANT_C5P_PRDT_CUM_DM',
    'PLANT_C5P_PRDT_CUM_DY',
    'PLANT_C5P_PRDT_RATE_D',

    'PLANT_RAWG_RAW_CUM_D',
    'PLANT_RAWG_RAW_CUM_HDQOQ',
    'PLANT_RAWG_RAW_CUM_DM',
    'PLANT_RAWG_RAW_CUM_DY',
    'PLANT_RAWG_RAW_RATE_D']
export const surviveTagList = [
    'PLANT_FUEL_FG_CONSUM_D',
    'PLANT_FUEL_FG_CONSUM_HDQOQ',
    'PLANT_FUEL_FG_CUM_D',
    'PLANT_FUEL_FG_CUM_DM',
    'PLANT_FUEL_FG_CUM_DY',
    'AUX_OBLR_FUEL_CUM_D',
    'AUX_FLARE_FUEL_CUM_D',
    'PLANT_OTHER_FUEL_CUM_D',
    'COMP_DGS_SEALG_CUMD_D',
    'PLANT_ALL_ELEC_CONSUM_D',
    'PLANT_ALL_ELEC_CONSUM_HDQOQ',
    'PLANT_ALL_ELEC_CUM_D',
    'PLANT_ALL_ELEC_CUM_DM',
    'PLANT_ALL_ELEC_CUM_DY',
    'COMP_MOTOR_ELEC_CUM_D',
    'C3CHL_C3CHL_ELEC_CUM_D',
    'PLANT_PID_ELEC_CUM_D',
    'PLANT_OTHER_ELEC_CUM_D',
    'PLANT_COOL_H2O_CONSUM_D',
    'PLANT_COOL_H2O_CONSUM_HDQOQ',
    'PLANT_COOL_H2O_CUM_D',
    'PLANT_COOL_H2O_CUM_DM',
    'PLANT_COOL_H2O_CUM_DY',
    // 'PLANT_N2GEN_N2_CUM_D',
    // 'PLANT_N2GEN_N2_CUM_HDQOQ',
    // 'PLANT_N2GEN_N2_CONSUM_D',
    // 'PLANT_N2GEN_N2_CONSUM_M',
    // 'PLANT_N2GEN_N2_CONSUM_DY',
    'PLANT_N2GEN_N2_CONSUM_D',
    'PLANT_N2GEN_N2_CONSUM_HDQOQ',
    'PLANT_N2GEN_N2_CUM_D',
    'PLANT_N2GEN_N2_CUM_DM',
    'PLANT_N2GEN_N2_CUM_DY',
    'PLANT_FUEL_FG_CONCOST_D',
    'PLANT_ALL_ELEC_CONCOST_D',
    'PLANT_COOL_H2O_CONCOST_D',
    'PLANT_N2GEN_N2_CONCOST_D'
]
export const energyTagList = [
    'PLANT_RAWG_RAW_RATE_D',
    'PLANT_RAWG_RAW_RATE_HDQOQ',
    'PLANT_C2H6_PRDT_RCRATE_D',
    'PLANT_C2H6_PRDT_RCRATE_HDQOQ',
    'PLANT_C3H8_PRDT_RCRATE_D',
    'PLANT_C3H8_PRDT_RCRATE_HDQOQ',
    'PLANT_C4H10_PRDT_RCRATE_D',
    'PLANT_C4H10_PRDT_RCRATE_HDQOQ',
    'PLANT_ALL_ELEC_CONSUM_D',
    'PLANT_ALL_ELEC_CONSUM_HDQOQ'
]
export const abnormalTagList = [
    'PLANT_C2H6_PRDT_RCRATE_D',
    'PLANT_C2H6_PRDT_RCFAILT',
    'PLANT_C2H6_PRDT_RCFAILS_DW',
    'PLANT_C2H6_PRDT_RCFAILS_DM',
    'PLANT_C2H6_PRDT_RCFAILS_DY',
    'PLANT_C3H8_PRDT_RCRATE_D',
    'PLANT_C3H8_PRDT_RCFAILT',
    'PLANT_C3H8_PRDT_RCFAILS_DW',
    'PLANT_C3H8_PRDT_RCFAILS_DM',
    'PLANT_C3H8_PRDT_RCFAILS_DY',
    'PLANT_C4H10_PRDT_RCRATE_D',
    'PLANT_C4H10_PRDT_RCFAILT',
    'PLANT_C4H10_PRDT_RCFAILS_DW',
    'PLANT_C4H10_PRDT_RCFAILS_DM',
    'PLANT_C4H10_PRDT_RCFAILS_DY'
]
export const totalEfficiencyTagList = [
    'COMP_TOT_EFF_RATE_D',
    'COMP_TOT_EFF_RATE_HDQOQ',
    'COMP_CASE1_EFF_RATE_D',
    'COMP_CASE2_EFF_RATE_D',
    'COMP_CASE34_EFF_RATE_D'
]
export const totalPowerTagList = [
    'COMP_TOT_SHAFT_RATE_D',
    'COMP_TOT_SHAFT_RATE_HDQOQ',
    'REG_D301AB_SHAFT_RATE_D',
    'COMP_GEAR_PLOSS_RATE_D',
    'COMP_MOTOR_ELEC_RATE_D'
]
export const powerConsumptionTagList = [
    'COMP_MOTOR_ELEC_CONSUM_D',
    'COMP_MOTOR_ELEC_CONSUM_HDQOQ',
    'COMP_MOTOR_ELEC_CONSUM_DW',
    'COMP_MOTOR_ELEC_CONSUM_DM',
    'COMP_MOTOR_ELEC_CONSUM_DY'
]
export const electricTotalTagList = [
    'COMP_MOTOR_ELEC_CUM_D',
    'COMP_MOTOR_ELEC_CUM_HDQOQ',
    'COMP_MOTOR_ELEC_CUM_DW',
    'COMP_MOTOR_ELEC_CUM_DM',
    'COMP_MOTOR_ELEC_CUM_DY'
]
export const dryGasSealTagList = [
    'COMP_DGS_SEALG_RATE_D',
    'COMP_DGS_SEALG_RATE_HDQOQ',
]
export const expanderRateTagList = [
    'EXPC_EXP_EFF_RATE_D',
    'EXPC_EXP_EFF_RATE_HDQOQ'
]
export const expanderCompressorRateTagList = [
    'EXPC_EC_EFF_RATE_D',
    'EXPC_EC_EFF_RATE_HDQOQ'
]
export const recoveryRateTagList = [
    'EXPC_EXP_SHAFT_RATE_D',
    'EXPC_EXP_SHAFT_RATE_HDQOQ'
]

