import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input, Select, message, DatePicker } from 'antd'
import moment from 'moment'
import { getCurrentTime } from '../../../../containers/DemoSystemV2/EventCapsules/utils'
const { TextArea } = Input
const { Search } = Input
const { Option } = Select
const resetImg = require('../../../../assets/reset.png')

const SecondTpl = props => {
  const {
    tplId,
    cacheTamplateData,
    capsuleDetailInfo,
    templateList,
    isDisabled,
    actionName
  } = props
  const [info, setInfo] = useState('')
  const [classify, setClassify] = useState(null) // 分类
  const [title, setTitle] = useState('') // 标题
  const [threshold_1, setThreshold_1] = useState(1) // 阀1
  const [number_1, setNumber_1] = useState(null)
  const [threshold_2, setThreshold_2] = useState(1) // 阀2
  const [number_2, setNumber_2] = useState(null)
  const [dateTime, setDateTime] = useState(moment(getCurrentTime()))
  const actionOptions = [
    { label: '未操作', value: 1 },
    { label: '开启', value: 2 },
    { label: '关闭', value: 3 }
  ]

  useImperativeHandle(props.refInstance, () => ({
    getTemplatInfo: getTemplatInfo,
    checkValidate: checkValidate,
    changeTitle: changeTitle,
    changeClassify
  }))

  useEffect(() => {
    // 新建时会产生缓存数据
    const cacheInfo = tplId && cacheTamplateData[tplId] ? cacheTamplateData[tplId] : null
    if (cacheInfo) {
      const jsonContent = cacheInfo.json_content || {}
      cacheInfo.date && setDateTime(moment(cacheInfo.date))
      if (Object.keys(jsonContent).length > 0) {
        jsonContent.classify ? setClassify(jsonContent.classify) : initDefaultClassify()
        setThreshold_1(jsonContent.threshold_1)
        setNumber_1(jsonContent.number_1)
        setThreshold_2(jsonContent.threshold_2)
        setNumber_2(jsonContent.number_2)
        setInfo(jsonContent.info)
      }
    }
  }, [])

  useEffect(() => {
    const jsonContent = capsuleDetailInfo.jsonContent || {}
    if (Object.keys(jsonContent).length > 0) {
      setDateTime(capsuleDetailInfo.occur_time ? moment(capsuleDetailInfo.occur_time) : '')
      setTitle(jsonContent.title)
      jsonContent.classify
        ? setClassify(jsonContent.classify)
        : capsuleDetailInfo.sn
        ? setClassify(Number(capsuleDetailInfo.sn))
        : initDefaultClassify()
      setThreshold_1(jsonContent.threshold_1)
      setNumber_1(jsonContent.number_1)
      setThreshold_2(jsonContent.threshold_2)
      setNumber_2(jsonContent.number_2)
      setInfo(jsonContent.info)
    } else {
      initDefaultClassify()
    }
  }, [templateList, isDisabled])
  const onSearch = value => console.log(value)
  const handleChangeOne = value => {
    setThreshold_1(value)
    setNumber_1(null)
  }
  const handleChangeTwo = value => {
    setThreshold_2(value)
    setNumber_2(null)
  }
  const changeNumber_1 = newValue => {
    const formatValue = checkNumberValue(newValue)
    if (formatValue !== 'error') {
      setNumber_1(formatValue)
    }
  }
  const changeNumber_2 = newValue => {
    const formatValue = checkNumberValue(newValue)
    if (formatValue !== 'error') {
      setNumber_2(formatValue)
    }
  }

  const checkNumberValue = value => {
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (value || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return 'error'
    }
    return formatValue
  }

  /** 字符串转数字 */
  const formatNumber = (newValue, index) => {
    if (!newValue) return
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    index === 1 ? setNumber_1(formatValue) : setNumber_2(formatValue)
  }

  /** 设置初始值 */
  const initDefaultClassify = () => {
    if (templateList.length > 0) {
      setClassify(templateList[0].id)
    }
  }

  /** 修改标题 */
  const changeTitle = title => {
    setTitle(title)
  }

  /** 修改分类标签 */
  const changeClassify = id => {
    id && setClassify(id)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (!dateTime) {
      message.error('请选择事件发生时间')
      return false
    }
    if (!(title || '').trim()) {
      message.error('请输入标题')
      return false
    }
    if (threshold_1 !== 1 && !number_1 && number_1 !== 0) {
      message.error('请输入阀值1')
      return false
    }
    if (threshold_2 !== 1 && !number_2 && number_2 !== 0) {
      message.error('请输入阀值2')
      return false
    }
    return true
  }

  /** 切换分类 */
  const classifyChange = e => {
    setClassify(e)
  }

  /** 重置 */
  const resetForm = () => {
    setDateTime('')
    setTitle('')
    initDefaultClassify()
    setThreshold_1(null)
    setNumber_1(null)
    setThreshold_2(null)
    setNumber_2(null)
    setInfo('')
    const { resetClick: propReset } = props
    propReset()
  }

  /** 获取模版信息 */
  const getTemplatInfo = () => {
    return {
      date: dateTime.format('YYYY-MM-DD HH:mm:00'),
      json_content: {
        title,
        classify,
        threshold_1,
        number_1,
        threshold_2,
        number_2,
        info,
        templateId: 2
      }
    }
  }
  const onChangeTime = (value, dateString) => {
    // setDateTime(moment(dateString).format('YYYY-MM-DD HH:mm:00'))
    setDateTime(value)
  }
  const onOkTime = value => {
    // setDateTime(moment(value).format('YYYY-MM-DD HH:mm:00'))
    setDateTime(value)
  }
  return (
    <div className="tpl">
      <div className="tpl-operator">
        <div className="tpl-operator-left">
          <span className="center">
            {isDisabled ? actionName : ''}
            {/*{JSON.parse(window.sessionStorage.getItem('USER_INFO')).name}*/}
          </span>
          <DatePicker
            showTime
            allowClear={false}
            value={dateTime}
            disabled={isDisabled}
            format="YYYY-MM-DD HH:mm"
            onChange={onChangeTime}
            onOk={onOkTime}
          />
        </div>
        <div className="tpl-operator-right">
          <Select value={classify} disabled={isDisabled} onChange={e => classifyChange(e)}>
            {templateList.map(item => {
              return (
                <Option key={item.title} value={item.id}>
                  {item.title}
                </Option>
              )
            })}
          </Select>
          {!isDisabled && (
            <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={resetForm}>
              <i className="reset-icon" />
              {/* <img src={resetImg} width="22" height="16" /> */}
              <span className="reset">重置</span>
            </div>
          )}
        </div>
      </div>
      <div className="tpl-input">
        <Input
          placeholder="请输入"
          maxLength={20}
          style={{ width: 312 }}
          disabled={isDisabled}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div className="tpl-fa" style={{ marginBottom: 16 }}>
        <div className="tpl-fa-name">阀1</div>
        <Select
          value={threshold_1}
          style={{ width: 86, marginRight: 16 }}
          disabled={isDisabled}
          onChange={e => handleChangeOne(e)}
        >
          {actionOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
        <Input
          className="float-input"
          style={{ height: 32 }}
          disabled={isDisabled || !threshold_1 || threshold_1 === 1}
          value={number_1}
          onChange={e => changeNumber_1(e.target.value)}
          onBlur={e => formatNumber(e.target.value, 1)}
        />
        <div className="tpl-fa-unit">cm</div>
      </div>
      <div className="tpl-fa">
        <div className="tpl-fa-name">阀2</div>
        <Select
          value={threshold_2}
          style={{ width: 86, marginRight: 16 }}
          disabled={isDisabled}
          onChange={e => handleChangeTwo(e)}
        >
          {actionOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
        <Input
          className="float-input"
          style={{ height: 32 }}
          disabled={isDisabled || !threshold_2 || threshold_2 === 1}
          value={number_2}
          onChange={e => changeNumber_2(e.target.value)}
          onBlur={e => formatNumber(e.target.value, 2)}
        />
        <div className="tpl-fa-unit">cm</div>
      </div>
      <div className="tpl-line"></div>
      <div className="tpl-info">说明</div>
      <div className="tpl-textarea">
        <TextArea
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={200}
          style={{ width: 880 }}
          disabled={isDisabled}
          value={info}
          onChange={e => setInfo(e.target.value)}
        />
      </div>
      <div className="tpl-line"></div>
      {/* <div className="tpl-point">
        <div className="point">点位</div>
        {!isDisabled && (
          <Search placeholder="搜索增加点位" onSearch={onSearch} style={{ width: 264 }} />
        )}
        {isDisabled && <></>}
      </div> */}
    </div>
  )
}
SecondTpl.defaultProps = {
  isDisabled: false
}
export default SecondTpl
