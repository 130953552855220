import React from 'react';
import {
    ZONE_COLOR,
    ZONE_NAME,
} from '../constants.js';
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import { valueFormat } from '../utils.js';
import style from './index.module.scss';
import CLOSE_ICON from './res/close.svg';

export default class Comp extends React.Component {

    constructor(props) {
        super(props);
        const { initialPosition: [clientX = 100, clientY = 100] = [] } = props;
        this.state = {
            x: clientX,
            y: clientY,
            isMoving: false,
            onMouseDownX: 0,
            onMouseDownY: 0,
            onMouseMoveX: 0,
            onMouseMoveY: 0,
        }
    }

    onMouseDown = e => {
        const { clientX, clientY } = e;
        // console.log(clientX, clientY);
        this.setState({
            isMoving: true,
            onMouseDownX: clientX,
            onMouseDownY: clientY,
            onMouseMoveX: clientX,
            onMouseMoveY: clientY,
        });
    }

    onMouseUp = e => {
        const { clientX, clientY } = e;
        // console.log(clientX, clientY);
        const { x, y, onMouseDownX, onMouseDownY } = this.state;
        this.setState({
            isMoving: false,
            x: x + clientX - onMouseDownX,
            y: y + clientY - onMouseDownY,
        });
    }

    onMouseMove = e => {
        const { clientX, clientY } = e;
        // console.log(clientX, clientY);
        this.setState({
            onMouseMoveX: clientX,
            onMouseMoveY: clientY,
        });
    }

    onMouseLeave = e => {
        // console.log("onMouseLeave");
        const { x, y, onMouseDownX, onMouseDownY, onMouseMoveX, onMouseMoveY, isMoving } = this.state;
        if (isMoving) {
            this.setState({
                isMoving: false,
                x: x + onMouseMoveX - onMouseDownX,
                y: y + onMouseMoveY - onMouseDownY,
            });
        };
    }

    render() {
        const { info: { name, zone_cut_point, zone_color, cur_value_tag, unit } = {}, tag2Value = {} } = this.props;
        const { onCancel = () => { } } = this.props;
        const { isMoving, onMouseDownX, onMouseDownY, onMouseMoveX, onMouseMoveY } = this.state;
        let { x, y } = this.state;
        if (isMoving) {
            x += (onMouseMoveX - onMouseDownX);
            y += (onMouseMoveY - onMouseDownY);
        };
        // const greenZoneIndex = [].concat(zone_color).indexOf(3);
        const value = tag2Value[cur_value_tag];
        // const rows = [0, 1, 2]
        //     .map(index => ({
        //         zoneIndex: (zone_color ?? [])[index],
        //         zoneColor: ZONE_COLOR[zone_color[index]] || 'transparent',
        //         zoneName: ZONE_NAME[zone_color[index]] || '-区',
        //         zoneValues: [zone_cut_point[index], zone_cut_point[index + 1]],
        //     }))
        //     .map((item, index) => {
        //         const { zoneIndex, zoneValues: [vl, vh] } = item;
        //         let showValue = false;
        //         if (value !== undefined && !isNaN(value)) {
        //             if (vl < value && value <= vh) {
        //                 showValue = true;
        //             };
        //             if (index === 0 && value === vl) {
        //                 showValue = true;
        //             };
        //         };
        //         return {
        //             ...item,
        //             greenStatus: zoneIndex === 3 ?
        //                 (
        //                     <CheckOutlined style={{ fontSize: 10 }} />
        //                 ) : greenZoneIndex === -1 ?
        //                     (
        //                         null
        //                     ) : (
        //                         index < greenZoneIndex ?
        //                             (
        //                                 <ArrowDownOutlined style={{ fontSize: 10 }} />
        //                             ) : (
        //                                 <ArrowUpOutlined style={{ fontSize: 10 }} />
        //                             )
        //                     ),
        //             showValue,
        //         };
        //     });
        return (
            <div
                className={style["new_main_page_record_modal_wrapper"]}
                style={{
                    left: x,
                    top: y,
                }}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="title_wrapper">
                    <div className="title">{name}</div>
                    <img alt="" src={CLOSE_ICON} width="12" height="12" onClick={onCancel} />
                </div>
                <table>
                    <tbody>
                        {
                            [1, 2, 3]
                                .map(({ zoneColor, zoneName, zoneValues: [vl, vh] = [], showValue, greenStatus }, index) => (
                                    <tr key={index} className="zone_row">
                                        <td>
                                            <div className="point" style={{ backgroundColor: zoneColor }} />
                                        </td>
                                        <td>
                                            <div className="title">{zoneName}</div>
                                        </td>
                                        <td>
                                            <div className="zone_value">{valueFormat(vl, 2)}-{valueFormat(vh, 2)}</div>
                                        </td>
                                        <td>
                                            {
                                                showValue && (
                                                    <div
                                                        className="vlaue_block"
                                                        style={{ backgroundColor: zoneColor }}
                                                    >
                                                        {greenStatus}
                                                        <div className="value">{valueFormat(value, 2)}</div>
                                                        <div className="unit">{unit}</div>
                                                    </div>
                                                )
                                            }
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}
