import React from 'react';
import SystemElectricalStatistic from './SystemElectricalStatistic.js';

import './SystemElectricalAnalysis.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="system_electrical_analysis_wrapper scroll_bar_restyle">
                <div className="content_wrapper">
                    <div className="content_root_title">系统用电分析</div>
                    <div
                        style={{
                            marginTop: 16,
                        }}
                    >
                        <SystemElectricalStatistic />
                    </div>
                    <div
                        style={{
                            marginTop: 16,
                        }}
                    >
                        <SystemElectricalStatistic />
                    </div>
                    <div
                        style={{
                            marginTop: 16,
                        }}
                    >
                        <SystemElectricalStatistic />
                    </div>
                </div>
            </div>
        );
    }
}
