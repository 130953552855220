import React from 'react'
import { Modal } from 'antd'
import style from './index.module.scss'

const Com = props => {
  const { showExportTextView, exportEvents, changeShowExportTextView } = props
  return (
    <Modal
      title="事件汇总文本"
      visible={showExportTextView}
      width={560}
      wrapClassName={`${style['viewEventsContent']} common-modal`}
      onCancel={() => typeof changeShowExportTextView === 'function' && changeShowExportTextView()}
    >
      <ul>
        {exportEvents &&
          exportEvents.map((eventItem, index) => {
            return (
              <li>
                <div>{`${index + 1}、${eventItem.occurTime}`}</div>
                <div>{`${eventItem.title}  ${eventItem.contentText}`}</div>
              </li>
            )
          })}
      </ul>
    </Modal>
  )
}

export default Com
