import React from 'react';
import { useState, useEffect } from 'react';
import { queryTimeRangeFormat } from './utils.js';
import { post } from '../../../common/utils.js';

import './ProductionComp.scss';

const SingleBar = (props) => {
    const { barContentWidthPercent, barContentColor } = props;
    const [percent, setPercent] = useState(0);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        // console.log("useEffect", barContentWidthPercent);
        if (!isMounted) {
            setTimeout(() => { setIsMounted(true) }, 500);
        } else {
            if (percent < barContentWidthPercent) {
                let newPercent = percent + 3;
                if (newPercent > barContentColor) {
                    newPercent = barContentColor;
                };
                setTimeout(() => {
                    setPercent(newPercent);
                }, 20);
            };
        };
    });
    return (
        <div className="bar_content" style={{ width: `${percent}%`, backgroundColor: barContentColor }} />
    );
};

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            prodList: null,
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            this.refreshData();
        };
    }

    refreshData = () => {
        const { timeRange } = this.props;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        post(process.env.REACT_APP_SERVER_PATH + 'api/board/index_page/prod_block', {
            cpl_rule_id: 1,
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ prodList: retData.data.results });
            };
        });
    }

    render() {
        const { prodList } = this.state;

        return (
            <div className="main_page_bottom_panels_production_comp_wrapper scroll_bar_restyle">
                <div className="production_content_wrapper">
                    {
                        prodList &&
                        prodList
                            .map((item, index) => {
                                const { avg_value, cpl_rate: origin_cpl_rate, factor_id, factor_name, unit } = item;
                                let barContentWidthPercent = 0;
                                let barContentColor = 'transparent';
                                // let warningTextColor = '#ffffff';
                                let toTopArrow = null;
                                let warning = false;
                                let rateText = '-';
                                const cpl_rate = parseFloat(origin_cpl_rate);
                                if (typeof cpl_rate === 'number' && cpl_rate >= 0 && cpl_rate <= 1) {
                                    barContentWidthPercent = cpl_rate * 100;
                                    if (cpl_rate <= 0.75) {
                                        warning = true;
                                    };
                                    rateText = Math.floor(cpl_rate * 1000) / 10;
                                };
                                if (warning) {
                                    barContentColor = 'rgba(150, 75, 82, 1)';
                                    // warningTextColor = 'rgba(255, 97, 112, 1)';
                                    toTopArrow = false;
                                } else {
                                    barContentColor = 'rgba(74, 155, 135, 1)';
                                    // warningTextColor = 'rgba(68, 243, 218, 1)';
                                    toTopArrow = true;
                                };
                                let prodText = '-';
                                if (typeof avg_value === "number") {
                                    prodText = Math.floor(avg_value * 100) / 100;
                                };
                                return (
                                    <div className="item_wrapper" key={factor_id}>
                                        <div className="left">
                                            <div className="title">{factor_name}</div>
                                            <div className="bar">
                                                <SingleBar barContentWidthPercent={barContentWidthPercent} barContentColor={barContentColor} />
                                                {/* <div className="bar_content" style={{ width: `${barContentWidthPercent}%`, backgroundColor: barContentColor }} /> */}
                                            </div>
                                        </div>
                                        <div className="mid">
                                            <div className="title">KPR</div>
                                            <div className="value_wrapper" style={{ color: barContentColor }}>{rateText}%</div>
                                        </div>
                                        <div className="right">
                                            <div className="title">平均产量</div>
                                            <div className="value_wrapper">
                                                <div className="text" style={{ color: barContentColor }}>{prodText}{unit}</div>
                                                <div className={typeof toTopArrow === 'boolean' ? (toTopArrow ? 'arrow_top' : 'arrow_down') : "arrow_null"} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
