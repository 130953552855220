import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import style from './index.module.scss'
import ICON_AVATAR from './res/avatar.svg'
import { Menu, Dropdown, Modal } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import ZiGuang from './res/ziguang.png';
import CompanyName from './res/companyname.png';
import ColoredIcon from 'components/Icon';

const Comp = props => {
  const { history, location, useDarkMode } = props
  const [name, setName] = useState(null)

  useEffect(() => {
    const userInfoStr =
      window.sessionStorage.getItem('USER_INFO') || window.localStorage.getItem('USER_INFO')
    let userInfo = null
    if (typeof userInfoStr === 'string' && userInfoStr !== '') {
      try {
        userInfo = JSON.parse(userInfoStr)
      } catch (err) { }
    }
    if (!!userInfo) {
      const { name, expire_time } = userInfo
      try {
        if (moment.unix(expire_time).isAfter(moment())) {
          if (typeof name === 'string') setName(name)
        }
      } catch (err) { }
    }
  }, [])

  const nameText = useMemo(() => {
    if (typeof name === 'string') {
      return name
    }
    return '(未登录)'
  }, [name])

  const handleMenuClick = ({ key }) => {
    if (key === '1' && location.pathname !== '/PEIMSV2/PersonalCenter') {
      history.push('/PEIMSV2/PersonalCenter')
    } else if (key === '2') {
      Modal.confirm({
        title: '退出登录?',
        content: '',
        onOk() {
          localStorage.clear()
          sessionStorage.clear()
          history.push('/Login')
        }
      })
    }
  }

  /** 下拉菜单 */
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        className={`${location.pathname === '/PEIMSV2/PersonalCenter' ? 'active' : ''}`}
      >
        <i className="icon personal-icon" />
        个人中心
      </Menu.Item>
      <Menu.Item key="2">
        <i className="icon logout-icon" />
        退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <div className={style['wrapper']} style={useDarkMode === true ? { backgroundColor: "#212830" } : {}}>
      <div className="left">
        <ColoredIcon width={152} height={22} src={ZiGuang} color={[255, 255, 255]} alpha={220} />
        <div className="divide_line" key="line"></div>
        <ColoredIcon width={135} height={27} src={CompanyName} color={[255, 255, 255]} alpha={220} />
        <div className="line" />
        <div className="text">水泥企业 生产能源一体化系统</div>
      </div>
      <div className="right">
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
          overlayClassName={style['headerDorpDown']}
          arrow
        >
          <div>
            <img src={ICON_AVATAR} alt="" className="avatar" />
            <span className="user_name">{nameText}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default withRouter(Comp)
