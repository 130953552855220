import React from 'react';
import { Upload, Button, Spin, message } from 'antd';
import { PhotoSwipe } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import style from './index.module.scss';
import { getToken } from 'common/utils.js';
import {
    UploadOutlined,
    FileOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import PopupWrapper from 'components/PopupWrapper.js';

class Comp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
            imageList: [],
            accept: "",
            isPhotoSwipeOpen: false,
            activeImgIndex: 0,
        };
    }

    componentDidMount() {
        // console.log(this.props);
        const value = this.props?.value ?? [];
        const accept = this.props?.accept ?? "";
        this.setState({
            fileList: value,
            accept,
        });
    }

    refreshFlieList = (file) => {
        const filename = file?.response?.filename ?? null;
        if (typeof filename === 'string' && filename !== "") {
            const { fileList } = this.state;
            this.setState({
                fileList: [...new Set(fileList.concat([filename]))],
            }, () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(this.state.fileList);
                };
            });
        };
    };

    deleteFile = (filename) => {
        return () => {
            const { fileList } = this.state;
            this.setState({
                fileList: fileList.filter((existedFileName) => existedFileName !== filename),
            }, () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(this.state.fileList);
                };
            });
        };
    };

    downLoadFile = (filename) => {
        window.open(process.env.REACT_APP_SERVER_PATH + `api/form/get_file?filename=${filename}`);
    }

    isImage = (filename) => {
        let _f = filename.toLowerCase();
        if (_f.indexOf('.png') >= 0 || _f.indexOf('.jpg') >= 0 || _f.indexOf('.jpeg') >= 0 || _f.indexOf('.bmp') >= 0 || _f.indexOf('.gif') >= 0) {
            return true;
        } else {
            return false;
        }
    }

    openFile = (filename, index) => {
        if (this.isImage(filename)) {
            this.setState({ imageList: this.state.fileList.filter(_name => this.isImage(_name)), isPhotoSwipeOpen: true, activeImgIndex: index })
        } else {
            this.downLoadFile(filename);
        }
    }

    render() {
        const { loading, fileList, imageList, accept, isPhotoSwipeOpen, activeImgIndex } = this.state;
        let self = this;
        return (
            <div className={style["wrapper"]}>
                <Upload
                    accept={accept}
                    action={process.env.REACT_APP_SERVER_PATH + "api/form/upload_file"}
                    headers={{
                        authorization: getToken(),
                    }}
                    onChange={(info) => {
                        const { file } = info;
                        const { status } = file;
                        if (status === "uploading") {
                            this.setState({ loading: true });
                        } else {
                            this.setState({ loading: false });
                        };
                        if (status === 'done') {
                            message.success("文件已上传");
                            // console.log(info.file.response);
                            this.refreshFlieList(file);
                        } else if (status === 'error') {
                            message.error("上传失败");
                        }
                    }}
                    itemRender={() => null}
                >
                    <Button icon={<UploadOutlined />}>上传</Button>
                </Upload>
                <div style={{ marginTop: 8 }}>
                    {
                        fileList
                            .map((filename, index) => {
                                if (typeof filename === 'string' && filename !== "") {
                                    return (
                                        <div key={index} className={style["file_wrapper"]}>
                                            <FileOutlined />
                                            <div className="title" onClick={() => this.openFile(filename, index)}>{filename}</div>
                                            <DeleteOutlined style={{ marginLeft: 'auto' }} className="delete" onClick={this.deleteFile(filename)} />
                                        </div>
                                    );
                                };
                                return null;
                            })
                    }
                </div>
                <PopupWrapper
                    display={isPhotoSwipeOpen}
                    callBack={_ => self.setState({ isPhotoSwipeOpen: false })}
                ></PopupWrapper>
                <PhotoSwipe
                    isOpen={isPhotoSwipeOpen}
                    options={{ shareEl: false, index: activeImgIndex, clickToCloseNonZoomable: false, closeOnScroll: false }}
                    imageLoadComplete={(instance, index, item) => {
                        console.log('item.w/h: ', item.w, item.h);
                        if (item.w < 1 || item.h < 1) { // unknown size
                            let img = new Image();
                            img.onload = function () { // will get size after load
                                console.log('img.onload');
                                item.w = this.naturalWidth; // set image width
                                item.h = this.naturalHeight; // set image height
                                if (instance) {
                                    console.log('instance.invalidateCurrItems');
                                    // instance.invalidateCurrItems(); // reinit Items
                                    instance.updateSize(true); // reinit Items
                                }
                            }
                            img.src = item.src; // let's download image
                        } else {
                            // instance.invalidateCurrItems();
                            // instance.updateSize(true);
                        }
                    }}
                    items={imageList.map((filename, index) => {
                        return {
                            w: 0,
                            h: 0,
                            src: process.env.REACT_APP_SERVER_PATH + `api/form/get_file?filename=${filename}`,
                            title: filename
                        }
                    })}
                    onClose={() => window.history.back()}
                />
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Comp;
