import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import TopBreadcrumb from 'components/TopBreadCrumb';
import Tabs from './Tabs';
import moment from 'moment';

const breadcrumbList = [{ name: '看板' }, { name: '中控员看板' }];

const Comp = (props) => {
    const { sysInfo, setSysInfo } = props;
    const [time, setTime] = useState(null);
    useEffect(() => {
        let mySetTimeout = null;
        const calcNewTime = () => {
            const currMoment = moment();
            setTime(currMoment.format("【 YYYY年MM月DD日 HH:mm:ss】"));
            const wait_period = 1000 - (currMoment.valueOf() % 1000);
            mySetTimeout = setTimeout(calcNewTime, wait_period);
        };
        calcNewTime();
        return () => {
            clearTimeout(mySetTimeout);
        };
    }, []);

    return (
        <div className={style['wrapper']}>
            <TopBreadcrumb breadcrumbList={breadcrumbList} />
            <div className="sys_wrapper">
                <Tabs sysInfo={sysInfo} setSysInfo={setSysInfo} />
            </div>
            <div className="time_wrapper">{time ?? ""}</div>
        </div>
    );
};

export default Comp;
