import { useEffect, useState } from 'react';
import { post } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);

    useEffect(() => {
        if (!!conf) {
            let requesting = true;
            setLoading(true);
            const { time_type, time, path, range } = conf;
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + path, {
                time_type, time: time_start,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', path, retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            const nextResults = (retData?.data?.results ?? []).map((item, index) => Object.assign({}, item, { key: index }));
                            setResults(nextResults);
                            return;//其他都算请求失败了
                        } else {
                            // 处理失败
                            setResults(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [results, setConf, loading];
};

export default Hook;
