import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import { Spin } from 'antd';
import ScaleWrapper from 'components/ScaleWrapper';
import TimeSelector from './TimeSelector';
import Monitor from './Comp';
import moment from 'moment';

const limitTimeStr = "00:00:00";
const tInfo = {
    disabledDate: (current) => {
        if (!!current) {
            if (current > moment().endOf('day')) {
                return true;
            } else {
                const currMoment = moment();
                if (moment(current).isSame(moment(currMoment), 'day')) {
                    if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('date').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                        return true;
                    };
                };
            };
        };
        return false;
    },
};

class Comp extends React.Component {

    constructor(props) {
        super(props);
        const timeInfo = Object.assign({}, tInfo, { time: moment('2022-02-15').format("YYYY-MM-DD HH:mm:ss") });
        this.state = {
            loading: false,
            timeInfo,
        };
    }

    setTimeInfo = (timeInfo) => {
        this.setState({ timeInfo });
    }

    render() {
        const { loading, timeInfo } = this.state;

        return (
            <div className={style["wrapper"]}>
                <ScaleWrapper width={1672} height={800} verticalCentered={false}>
                    <div className={style["content"]}>
                        <div className="top">
                            <div className="title">电能统计 / 电能监控</div>
                            <div className="right">
                                <TimeSelector setTimeInfo={this.setTimeInfo} timeInfo={timeInfo} />
                            </div>
                        </div>
                        <div className="bottom">
                            <Monitor timeInfo={timeInfo} />
                        </div>
                    </div>
                </ScaleWrapper>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withRouter(Comp);
