import React, { useEffect, useState } from 'react'
import { Popover, Empty, Pagination, message, Modal } from 'antd'
import style from './index.module.scss'
import { useAutoEvents } from '../../../../hooks'
import { reqDelete } from 'common/utils'
import FullScreenLoading from 'components/FullScreenLoading'
const PAGE_SIZE = 10
const { confirm } = Modal
const Com = props => {
  const { moreCount, visible, setVisible } = props
  const [events, pageNo, total, setPageNo, refreshEvents, loading, initEvens] = useAutoEvents(
    PAGE_SIZE
  ) // 自动生成事件列表

  const initData = e => {
    if (e) {
      if (pageNo > 1) {
        initEvens()
      } else refreshEvents()
    }
  }

  /** 切换页码 */
  const paginationOnChange = page => {
    setPageNo(page)
  }

  /** 编辑事件 */
  const editEventClick = id => {
    if (typeof props.editEventOnClick === 'function') {
      setVisible(false)
      props.editEventOnClick(id, true)
    }
  }

  /** 删除事件 */
  const deleteEventClick = id => {
    confirm({
      title: `确认删除?`,
      content: '',
      onOk() {
        handleDeleteEvent(id)
      }
    })
  }

  const handleDeleteEvent = id => {
    reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/capsuleDeleteByIdSearch', {
      capsuleId: [id]
    }).then(retData => {
      if (!!retData.data && retData.data.error === 0) {
        message.success('已删除')
        // 刷新自动生成事件>更多
        refreshEvents()
        // 刷新自动生成的6个事件
        if (typeof props.refreshEvents === 'function') props.refreshEvents()
      } else {
        message.error('删除失败')
      }
    })
  }

  const content = (
    <>
      <div className="pop-top">
        <span>更多</span>
        <i className="fold-btn" onClick={() => setVisible(false)} />
      </div>
      <ul className="table-wrapper">
        {events &&
          events.map((eventItem, index) => {
            return (
              <li key={eventItem.id}>
                <div className="order">{(pageNo - 1) * PAGE_SIZE + index + 1}</div>
                <div className="time">{eventItem.createTimeText}</div>
                <div className="title">{eventItem.titleText}</div>
                <div className="operation">
                  <i className="btn edit-btn" onClick={() => editEventClick(eventItem.id)} />
                  <i className="btn delete-btn" onClick={() => deleteEventClick(eventItem.id)} />
                </div>
              </li>
            )
          })}
        {!loading && (!events || events.length === 0) && <Empty description="暂无自动生成事件" />}
        {loading && <FullScreenLoading />}
      </ul>

      {total > 0 && (
        <Pagination
          className="pagination"
          current={pageNo}
          total={total}
          pageSize={PAGE_SIZE}
          showSizeChanger={false}
          size="small"
          showQuickJumper
          onChange={paginationOnChange}
        />
      )}
    </>
  )

  return (
    <Popover
      visible={visible}
      content={content}
      trigger="click"
      placement="topRight"
      overlayClassName={style['wrapper']}
      onVisibleChange={e => initData(e)}
    >
      <span className="more-btn" onClick={() => setVisible(!visible)}>{`更多${moreCount}+`}</span>
    </Popover>
  )
}

export default Com
