import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { message } from 'antd';
import { get } from '../../common/utils';
import { template_uid_2_comp } from './constants.js';
const Comp = (props) => {
    const { layoutChange, range, id } = props;
    const [type, setType] = useState(null);
    const [config, setConfig] = useState(null);
    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + `api/grid/boards`, {
            ids: id + "",
        })
            .then((retData) => {
                // console.log(retData);
                if (!!retData.data && !!retData.data.results && retData.data.results.length === 1) {
                    // setScreen(retData.data);
                    const [{ template: { uid }, config }] = retData.data.results;
                    let nextConfig = null;
                    try {
                        if (!!config) {
                            nextConfig = JSON.parse(config);
                        };
                    } catch (e) { };
                    setConfig(nextConfig);
                    setType(uid);
                } else {
                    message.error("获取信息失败,请刷新重试!");
                };
            });
    }, [id]);// 外部id变化内部修改相应配置, 但其实如果有这样的需求的话可能需要在外部调节，而不是把id传到内部？
    const BlockComp = template_uid_2_comp[type] || null;
    // console.log(type, config);
    return !!BlockComp && (
        <BlockComp layoutChange={layoutChange} config={config} range={range} />
    );
};

export default withRouter(Comp);
