import React from 'react';
import { useState, useEffect } from 'react';

import SystemPanel from './SystemPanel.js';

import ICON_TITLE from './res/icon_sys.png';

import './FactorStatistics.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            topPanels: [
                {
                    rate: 99.8,
                    name: '原料粉磨系统',
                },
                {
                    rate: 61.2,
                    name: '熟料烧成系统',
                },
                {
                    rate: 99.8,
                    name: '水泥粉磨系统',
                },
                {
                    rate: 99.8,
                    name: '煤粉制备系统',
                },
                {
                    rate: 99.8,
                    name: '水泥包装系统',
                },
                {
                    rate: 99.8,
                    name: '矿山系统',
                },
            ],
        };
    }

    render() {
        const { topPanels } = this.state;

        return (
            <div className="factor_statistics_wrapper scroll_bar_restyle">
                <div className="content_wrapper">
                    <div className="content_root_title">指标统计</div>

                    <div className="item_wrapper">
                        <div className="item_header_wrapper">
                            <img alt="" src={ICON_TITLE} className="item_header_icon" />
                            <div className="title">系统生产综合KPI指标PHI</div>
                        </div>
                        <div className="item_content_wrapper">
                            <div
                                style={{
                                    height: 168,
                                    display: 'flex',
                                }}
                            >
                                {
                                    topPanels
                                        .map((item, index) => {
                                            const { name, rate } = item;
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        width: 0,
                                                        flexGrow: 1,
                                                        ...index > 0 ? {
                                                            marginLeft: 13,
                                                        } : {},
                                                        backgroundColor: 'rgba(25, 31, 39, 1)',
                                                        borderRadius: 2,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <HalfCirclePanel name={name} rate={rate} />
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>

                    <div
                        style={{ marginTop: 16 }}
                    >
                        <SystemPanel />
                    </div>

                </div>
            </div>
        );
    }
};

const HalfCirclePanel = (props) => {
    const { rate, name } = props;
    const [rotateDeg, setRotateDeg] = useState(0);
    const [outlineCircleColor, setOutlineCircleColor] = useState('transparent');
    const [textColor, setTextColor] = useState('transparent');
    useEffect(() => {
        // console.log("useEffect", barContentWidthPercent);
        let newRotateDeg = 0;
        if (rate >= 0 && rate <= 100) {
            newRotateDeg = 180 * rate / 100;
        };
        let newOutLineCircleColor = 'rgba(68, 243, 218, 1)';
        let newTextColor = 'rgba(255, 255, 255, 1)';
        if (rate < 90) {
            newOutLineCircleColor = 'rgba(255, 97, 112, 1)';
            newTextColor = 'rgba(255, 97, 112, 1)';
        };
        setOutlineCircleColor(newOutLineCircleColor);
        setRotateDeg(newRotateDeg);
        setTextColor(newTextColor);
        // setTimeout(() => { setIsMounted(true) }, 500);
    }, [rate]);
    return (
        <div className="chart_info_wrapper">
            <div
                //top_block_wrapper
                style={{
                    height: 63,
                    position: 'relative',
                }}
            >
                <div
                    //circle_outline_wrapper
                    style={{
                        width: 136,
                        height: 68,
                        overflow: 'hidden',
                        position: 'absolute',
                        top: -12,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    <div
                        //circle_wrapper
                        style={{
                            position: 'relative',
                            width: 136,
                            height: 136,
                            borderRadius: '50%',
                            backgroundColor: 'rgba(63, 69, 95, 1)',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            //rotate_block_1
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: '50%',
                                left: 0,
                                overflow: 'hidden',
                            }}
                        >
                            <div
                                //colored_block
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    right: 0,
                                    height: '100%',
                                    transformOrigin: '50% 0',
                                    transform: `rotate(${rotateDeg}deg)`,
                                    backgroundColor: outlineCircleColor,
                                }}
                            ></div>
                        </div>
                        <div
                            //inner_hover_circle
                            style={{
                                position: 'absolute',
                                top: 7,
                                right: 7,
                                bottom: 7,
                                left: 7,
                                borderRadius: '50%',
                                backgroundColor: 'rgba(25, 31, 39, 1)',
                                //backgroundColor还不够灵活
                            }}
                        />
                    </div>
                </div>
                <div
                    //back_circle_wrapper
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: 112,
                        overflow: 'hidden',
                        top: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    <div
                        //back_circle
                        style={{
                            height: 112,
                            width: 112,
                            borderRadius: '50%',
                            boxShadow: `0px 10px 8px 0px ${outlineCircleColor} inset`,
                            opacity: 0.34,
                        }}
                    />
                </div>
                <div
                    //value_text
                    style={{
                        color: textColor,
                        position: 'absolute',
                        height: 46,
                        fontSize: 36,
                        fontWeight: 'bold',
                        lineHeight: '46px',
                        left: '50%',
                        top: 15,
                        transform: 'translateX(-50%)',
                    }}
                >{rate}</div>
            </div>
            <div
                //name
                style={{
                    marginTop: 12,
                    height: 18,
                    fontSize: 13,
                    color: '#9FA0BA',
                    lineHeight: '18px',
                    textAlign: 'center',
                }}
            >{name}</div>
        </div>
    );
};

export default Page;