import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, range, base_id } = conf;
            if (typeof time !== 'string' || typeof base_id !== 'number') {
                setResult(null);
                return;
            };
            let requesting = true;
            setLoading(true);
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            const day_begin = moment(time).startOf(range).format("YYYY-MM-DD");
            const day_end = moment(time).endOf(range).format("YYYY-MM-DD");
            Promise.all([
                post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_factor_details', {
                    time_type, time: time_start, base_id,
                }),
                post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_factor_abnormal_events', {
                    day_begin, day_end, base_id,
                }),
            ])
                .then((retDatas) => {
                    if (requesting) {
                        setLoading(false);
                        const [retData_1, retData_2] = retDatas;
                        // console.log('hook', retData_1, retData_2);
                        let nextResult = {};
                        if (retData_1?.data?.errcode === 0) {
                            let detail = retData_1?.data?.result ?? null;
                            if (!!detail) {
                                const { avg_value, cpl_rate } = detail;
                                const avg_value_text = typeof avg_value === 'number' ? significantNum(avg_value, 4) : "--";

                                let standard_text = "";
                                let upper_limit = detail?.upper_limit;
                                let lower_limit = detail?.lower_limit;
                                if (typeof upper_limit === "number") upper_limit = significantNum(upper_limit, 4);
                                if (typeof lower_limit === "number") lower_limit = significantNum(lower_limit, 4);
                                if (typeof upper_limit === "number" && typeof lower_limit === "number") {
                                    standard_text = `${lower_limit} - ${upper_limit}`;
                                } else {
                                    if (typeof upper_limit === "number") standard_text = upper_limit;
                                    if (typeof lower_limit === "number") standard_text = lower_limit;
                                };
                                const parsed_cpl_rate = (typeof cpl_rate === 'number' ? significantNum(cpl_rate * 100, 3, 2) : "--") + "%";

                                detail = Object.assign({}, detail, {
                                    avg_value_text,
                                    standard_text,
                                    period_text: `${day_begin} 00:00 - ${day_end} 23:59`,
                                    parsed_cpl_rate,
                                });
                            };
                            nextResult = Object.assign({}, nextResult, { detail });
                        };
                        if (retData_2?.data?.errcode === 0) { //abnormal_history
                            let abnormal_history = retData_2?.data?.results ?? null;
                            if (!!abnormal_history) {
                                abnormal_history = abnormal_history
                                    .map((item) => {
                                        const { avg_value, time_begin, time_end, minutes } = item;
                                        const avg_value_text = typeof avg_value === "number" ? significantNum(avg_value, 4) : "--";
                                        const minutes_text = (typeof minutes === "number" ? significantNum(minutes / 60, 4) : "--");
                                        let time_range_text = "";
                                        if (typeof time_begin === 'string') {
                                            time_range_text += moment(time_begin).format("M/D HH:mm - ");
                                            if (typeof time_end === 'string') {
                                                let end_text = moment(time_end).format("M/D HH:mm");
                                                if (moment(time_begin).isSame(moment(time_end), 'day')) {
                                                    end_text = moment(time_end).format("HH:mm")
                                                };
                                                time_range_text += end_text;
                                            } else {
                                                time_range_text += "(未结束)";
                                            };
                                        };
                                        let unixs = {};
                                        if (typeof time_begin === 'string') {// ms
                                            unixs = Object.assign({}, unixs, { time_begin_unix: moment(time_begin).unix() * 1000 });
                                            if (typeof time_end === 'string') {
                                                unixs = Object.assign({}, unixs, { time_end_unix: moment(time_end).unix() * 1000 });
                                            };
                                        };
                                        // 分析时段 取前15m
                                        let an_time_begin = null;
                                        let an_time_end = null;
                                        let an_range_text = "";
                                        if (typeof time_begin === 'string') {
                                            const time_end_tmp = moment(time_begin).format("YYYY/MM/DD HH:mm");
                                            const time_begin_tmp = moment(time_begin).subtract(15, 'minutes').format("YYYY/MM/DD HH:mm");
                                            an_time_end = moment(time_end_tmp).format("YYYY-MM-DD HH:mm:00");
                                            an_time_begin = moment(time_begin_tmp).format("YYYY-MM-DD HH:mm:00");
                                            an_range_text = time_begin_tmp + " - " + time_end_tmp;
                                        };
                                        // 对标时段 取前2h
                                        let cp_time_begin = null;
                                        let cp_time_end = null;
                                        let cp_range_text = "";
                                        if (typeof time_begin === 'string') {
                                            const time_end_tmp = moment(time_begin).format("YYYY/MM/DD HH:mm");
                                            const time_begin_tmp = moment(time_begin).subtract(2, 'hours').format("YYYY/MM/DD HH:mm");
                                            cp_time_end = moment(time_end_tmp).format("YYYY-MM-DD HH:mm:00");
                                            cp_time_begin = moment(time_begin_tmp).format("YYYY-MM-DD HH:mm:00");
                                            cp_range_text = time_begin_tmp + " - " + time_end_tmp;
                                        };
                                        return Object.assign({}, item, {
                                            avg_value_text,
                                            minutes_text,
                                            time_range_text,
                                            ...unixs,
                                            an_range_text,
                                            an_time_begin,
                                            an_time_end,
                                            cp_range_text,
                                            cp_time_begin,
                                            cp_time_end,
                                        });
                                    });
                            };
                            nextResult = Object.assign({}, nextResult, { abnormal_history });
                        };
                        // console.log(nextResult);
                        setResult(nextResult);
                        return;//其他都算请求失败了
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
