import React from 'react'
import { SwatchesPicker } from 'react-color';
import style from './index.module.scss'
import TopBreadcrumb from '../../../../components/TopBreadCrumb'
import {Table, Input, DatePicker,Tabs,Radio,Button} from 'antd';
import BlendChart from '../components/blendChart'
const { Search } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const IronSettings = () => {
    const breadcrumbList = [{ name: '看板' }, { name: '趋势看板' }, { name: '设置' }]
    const callback = (key) => {
        console.log(key);
    }
    const pointSource = [
        {
            key: '1',
            name: 'number',
            point: 'A.DRERWE',
            pointDec: '综合产量'
        },
        {
            key: '2',
            name: 'number',
            point: 'A.DRERWE',
            pointDec: '综合产量'
        },
        {
            key: '3',
            name: 'number',
            point: 'A.DRERWE',
            pointDec: '综合产量'
        }
    ]
    const pointColumns = [
        {
            title: '序号',
            dataIndex: 'number',
            key: 'number'
        },
        {
            title: '点位位置',
            dataIndex: 'point',
            key: 'point'
        },
        {
            title: '点位描述',
            dataIndex: 'pointDec',
            key: 'pointDec'
        }
    ]
    return (
        <div className={style['ironSettings']}>
            <TopBreadcrumb breadcrumbList={breadcrumbList}/>
            <div className="iron-chart">
                <BlendChart />
            </div>
            <div className="iron-settings">
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="基础配置" key="1">
                        <div>
                            <label>图表名称</label>
                            <Input style={{width: 180}}/>
                        </div>
                        <div>
                            <label>Y轴左</label>
                            <span>上限</span>
                            <Input style={{width: 120}}/>
                            <span>下限</span>
                            <Input style={{width: 120}}/>
                        </div>
                        <div>
                            <label>Y轴右</label>
                            <span>上限</span>
                            <Input style={{width: 120}}/>
                            <span>下限</span>
                            <Input style={{width: 120}}/>
                        </div>
                    </TabPane>
                    <TabPane tab="数据配置" key="2">
                        <div className="data-settings">
                            <div className="data-settings-left">
                                <Input style={{width: 200}} placeholder="请输入添加点位"/>
                                <Table dataSource={pointSource} columns={pointColumns} pagination={false}/>
                            </div>
                            <div className="data-settings-right">
                                <div>
                                    <label>图表类型</label>
                                    <Radio>折线图</Radio>
                                    <Radio>柱状图</Radio>
                                </div>
                                <div>
                                    <label>显示描述</label>
                                    <Input style={{width: 200}}/>
                                </div>
                                <div>
                                    <label>单位</label>
                                    <Input style={{width: 200}}/>
                                </div>
                                <div>
                                    <label>对应Y轴</label>
                                    <Radio>左侧</Radio>
                                    <Radio>右侧</Radio>
                                </div>
                                <SwatchesPicker
                                    width="500px" height="150px"
                                />
                                <div>
                                    <Button type="primary">删除</Button>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}
export default IronSettings
