import React from 'react';

import './RightTableV2.scss';
import TITLE_ICON from './res/rightTableTitleIcon.png';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kkpiList: null,
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            this.getData();
        };
    }

    getData = () => {
        const { getKKPIList } = this.props;
        getKKPIList()
            .then((retData) => {
                const { isSuccess, results } = retData;
                if (isSuccess) {
                    this.setState({ kkpiList: results });
                };
            });
    }

    render() {
        const { kkpiList } = this.state;

        return (
            <div className="main_page_right_table_v2_wrapper">
                <div className="title_wrapper">
                    <img alt="" src={TITLE_ICON} className="title_icon" />
                    <div className="text">综合指标</div>
                </div>
                <div className="table_aLine" />
                <div className="table_content_wrapper">
                    <div className="content_left_block scroll_bar_restyle">
                        <div style={{ padding: '0 2px 2px 2px' }}>
                            {
                                !!kkpiList &&
                                kkpiList
                                    .map((item, index) => {
                                        const style = {};
                                        if (index > 0) {
                                            style.marginTop = 4;
                                        };
                                        return (
                                            <div key={`item_${index}`} style={style}>
                                                <RowItem factor={item} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


const RowItem = (props) => {
    const { factor } = props;
    // const { name, warning, num1, percent } = factor;
    const { name, product_name, is_kpi, weighted_avg, rate } = factor;
    let warning = false;
    let rateText = "-";
    if (typeof rate === "number") {
        rateText = Math.floor(rate * 1000) / 10;
        if (rate < 0.75) {
            warning = true;
        };
    };
    let weighted_avg_format = '-';
    if (typeof weighted_avg === 'number') {
        weighted_avg_format = Math.floor(weighted_avg * 10) / 10;
    };
    return (
        <div
            style={
                Object.assign({
                    height: 40,
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                }, (
                    warning ? {
                        backgroundColor: 'rgba(255, 97, 112, 0.1)',
                    } : {}
                ))
            }
        >
            <div style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                width: 2,
                borderRadius: 2,
                backgroundColor: warning ? "#FF6170" : "#747794",
            }} />
            <div style={{
                marginLeft: 16,
                border: "1px solid #FFD94E",
                height: 20,
                width: 57,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 12,
                color: '#FFD94E',
            }}>{product_name}</div>
            <div style={{
                marginLeft: 6,
                width: 28,
                height: 14,
                borderRadius: 9,
                backgroundColor: '#44F3DA',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 12,
                color: '#151922',
            }}>{is_kpi ? "KPI" : ""}</div>
            <div style={{
                marginLeft: 8,
                width: 160,
                color: '#FFFFFF',
                fontSize: 12,
                lineHeight: '14px',
                height: 14,
            }}>{name}</div>
            <div style={{
                marginLeft: 5,
                height: 14,
                fontSize: 12,
                lineHeight: '14px',
                color: warning ? "#FF6170" : "#FFFFFF",
            }}>{weighted_avg_format}</div>
            <div style={{
                marginLeft: 'auto',
                marginRight: 9,
                height: 14,
                fontSize: 12,
                lineHeight: '14px',
                color: warning ? "#FF6170" : "#FFFFFF",
            }}>{rateText}%</div>
        </div>
    );
};
