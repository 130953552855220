import {
    SET_VALUES,
} from './actions.js';
import {
    InitialState,
} from './constants.js';

const R = require('ramda');

const reducer = (state = R.clone(InitialState), action) => {
    switch (action.type) {
        case SET_VALUES:
            return {
                ...state,
                ...action.values,
            }
        default:
            return state;
    }
};

export default reducer;
