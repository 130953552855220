import React from 'react';
import echarts from 'echarts';
import { queryTimeRangeFormat } from './utils.js';
import { post } from '../../../common/utils.js';

import './ImproveChanceComp.scss';

const colors = [
    'rgba(89, 94, 168, 1)',
    'rgba(74, 155, 135, 1)',
    'rgba(177, 93, 101, 1)',
    'rgba(83, 146, 188, 1)',
    'rgba(196, 158, 112, 1)',
    'rgba(49, 98, 164, 1)',
];

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chanceList: null,
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            this.refreshData();
        };
    }

    refreshData = () => {
        const { timeRange } = this.props;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        post(process.env.REACT_APP_SERVER_PATH + 'api/board/index_page/cpl_block', {
            cpl_rule_id: 1,
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ chanceList: retData.data.results }, () => {
                    this.renderChart();
                });
            };
        });
    }

    refFunc = refNode => {
        this.refNode = refNode;
    }

    renderChart = () => {
        if (!!this.refNode) {
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            // console.log('renderChart');
            const { chanceList } = this.state;
            instance.setOption({
                series: [
                    {
                        name: '',
                        type: 'pie',
                        hoverAnimation: false,
                        radius: ['85%', '100%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: chanceList.map(({ failed_num }, index) => {
                            const failed_num_parse = parseFloat(failed_num);
                            let value = 0;
                            if (typeof failed_num_parse === "number") {
                                value = failed_num_parse;
                            };
                            const color = colors[index % colors.length];
                            return {
                                value,
                                name: '',
                                itemStyle: {
                                    color,
                                },
                            };
                        }),
                    },
                ],
            });
        };
    }

    render() {
        const { chanceList } = this.state;
        let total_num = '-';
        if (!!chanceList) {
            total_num = chanceList
                .reduce((pV, cItem) => {
                    const { failed_num } = cItem;
                    const failed_num_parse = parseFloat(failed_num);
                    if (typeof failed_num_parse === "number") {
                        pV += failed_num_parse;
                    };
                    return pV;
                }, 0);
        };

        return (
            <div className="main_page_bottom_panels_improve_chance_comp_wrapper">
                <div className="left">
                    <div className="position_wrapper">
                        <div className="chart_wrapper" ref={this.refFunc} />
                        <div className="circle">
                            <div className="circle_content">
                                <div className="title">当日次数</div>
                                <div className="value">{total_num}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right scroll_bar_restyle">
                    <div className="comp_content_wrapper">
                        {
                            chanceList &&
                            chanceList
                                .map((item, index) => {
                                    const { failed_num, percent, sys_id, sys_name } = item;
                                    let rateText = '-';
                                    const pct = parseFloat(percent);
                                    if (typeof pct === "number") {
                                        rateText = Math.floor(pct * 1000) / 10;
                                    };
                                    const color = colors[index % colors.length];
                                    return (
                                        <div className="row_wrapper" key={sys_id}>
                                            <div className="icon" style={{ backgroundColor: color }} />
                                            <div className="title">{sys_name}</div>
                                            <div className="rate">{rateText}%</div>
                                            <div className="value">{failed_num}</div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
