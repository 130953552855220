import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, renderPieChart, colors, mockList } from './utils';

const Page = (props) => {

    const refBottom = useRef(null);
    const refTop = useRef(null);
    useEffect(() => {
        renderLineChart(refBottom.current, mockList);
        renderPieChart(refTop.current, mockList);
    }, []);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <div className="left">
                    <div className="title">当日总排放量</div>
                    <div className="v_wrapper">
                        <div className="value">999999</div>
                        <div className="unit">tCO₂</div>
                    </div>
                    <div className="subtitle">#5线</div>
                </div>
                <div className="right">
                    <div className="left">
                        <div className="chart" ref={refTop}></div>
                    </div>
                    <div className="right">
                        {
                            (mockList ?? [])
                                .map((item, index) => {
                                    const color = colors[index % colors.length];
                                    const { name, value, unit } = item;
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="icon" style={{ backgroundColor: color }}></div>
                                            <div className="title">{name}</div>
                                            <div className="value">{value}</div>
                                            <div className="unit">{unit}</div>
                                        </React.Fragment>
                                    );
                                })
                        }
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="title">近7日趋势</div>
                <div className="chart" ref={refBottom}></div>
            </div>
        </div>
    );
};

export default Page;
