import React from 'react'
import style from './index.module.scss'
import TopBreadcrumb from '../../../../components/TopBreadCrumb'
import ReportPanel from './ReportPanel'
import EventPanel from './EventPanel'

const EventBoard = () => {
  const breadcrumbList = [{ name: '事件' }, { name: '看板' }]
  return (
    <div className={style['EventBoard']}>
      <TopBreadcrumb breadcrumbList={breadcrumbList} />
      <div className="main-warpper">
        <div className="left-item">
          <ReportPanel />
        </div>
        <div className="right-item">
          <EventPanel />
        </div>
      </div>
    </div>
  )
}

export default EventBoard
