import React from 'react';
import style from './index.module.scss';
import Header from './HeaderWrapper';
import LeftMenu from './Menu'
// import ScaleWrapper from 'components/ScaleWrapper';
const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className='scroll_wrapper'>
                <div className='content'>
                    <Header />
                    <div className="bottom_wrapper">
                        <div className="left">
                            <LeftMenu />
                        </div>
                        <div className="right">
                            <div className="content">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
