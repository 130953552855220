import React from 'react'
import DataTable from './DataTable'
import Report from './Report'
 
const Com =()=> {
    const wrapperStyle = {
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    }
    return <div style={wrapperStyle}>
        <DataTable/>
        <div style={{width:0,flexGrow:1,marginLeft:58}}><Report /></div>
    </div>
  
}

export default Com