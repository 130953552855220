import React, { useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import { Tabs } from 'antd';
import History from './History';
import Realtime from './Realtime';
import { withRouter } from 'react-router';
import { post } from 'common/utils';

const { TabPane } = Tabs;

const tabs = [
    {
        name: '实时',
        comp: Realtime,
    },
    {
        name: '历史',
        comp: History,
    },
]
    .map((item, index) => Object.assign({}, item, { key: index + "" }));

const Comp = (props) => {
    const [tag] = useState(() => {
        // props.tag_alias优先级最高
        return props?.tag_alias ?? (props?.match?.params?.id ?? null);
    });
    const [selectedTab, setSelectedTab] = useState(() => {
        return tabs?.[0] ?? null;
    });

    const [tagInfo, setTagInfo] = useState(null);
    useEffect(() => {
        // 首次
        // 获取点位信息
        if (typeof tag === 'string' && tag !== "") {
            post(process.env.REACT_APP_SERVER_PATH + "api/meta_data/list", {
                tag_name_list: [tag],
            })
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        setTagInfo(retData?.data?.results?.[0] ?? null);
                    } else {
                        setTagInfo(null);
                    };
                });
        } else {
            setTagInfo(null);
        };
    }, [tag]);

    const callback = (key) => {
        const nextSelectedTab = tabs.filter(({ key: key_1 }) => key_1 === key)[0];
        setSelectedTab(nextSelectedTab);
    };

    const returnOnClick = () => {
        // 如果判断在iframe内，仅向iframe外部通知
        if (window.self !== window.top) {
            // console.log('在iframe中');
            const event = document.createEvent("event");
            // 初始化testEvent事件
            event.initEvent("diagramChartGoBack", false, true);
            event.data = { "goBack": true };
            // 触发自定义事件
            window.dispatchEvent(event);
        } else {
            const goBack = props?.history?.goBack ?? null;
            if (typeof goBack === 'function') {
                goBack();
            };
        };
    };

    const DisplayComp = useMemo(() => {
        return selectedTab?.comp ?? null;
    }, [selectedTab]);

    return (
        <div className={style['wrapper']}>
            <Tabs
                activeKey={selectedTab?.key ?? null}
                onChange={callback}
            // tabBarExtraContent={(
            //     <Button onClick={returnOnClick}>返回</Button>
            // )}
            >
                {
                    tabs
                        .map((item) => {
                            return (
                                <TabPane tab={item.name} key={item.key}></TabPane>
                            );
                        })
                }
            </Tabs>
            <div className="bottom">
                {
                    !!DisplayComp && (
                        <DisplayComp tag={tag} tagInfo={tagInfo} />
                    )
                }
            </div>
        </div>
    );
};

export default withRouter(Comp);
