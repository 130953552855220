import React from 'react'
import './index.scss'
// import allImg from '../../../assets/3D.png'
import Card3D from './ThreeDCard';
const Overview = () => {
    return (
        <div className="overview">
            {/* <img src={allImg} width="896" height="624"/> */}
            <div style={{ height: '100%' }}>
                <Card3D />
            </div>
            <div className="name">全厂概览</div>
        </div>
    )
}

export default Overview
