import React from 'react'
import { withRouter } from 'react-router'
import { post, get } from 'common/utils'
import { Button, Table, message, Modal, Input, DatePicker, Select, Checkbox } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getMyPermissions } from 'common/AuthPermission.js'
import RaceSetting from './RaceSetting/RaceSetting'
import ExamResult from './ExamResult/ExamResult'
import style from './RaceList.module.scss'
import { tmp_users_exchange } from '../utils'

const { RangePicker } = DatePicker
const { Option } = Select
const ExamStatus = [
  {
    id: 2,
    name: '正在进行'
  },
  {
    id: 3,
    name: '已完成'
  }
]

const DisplayExamStatus = [
  {
    id: 1,
    name: '初始化中' //正在计算？
  },
  {
    id: 2,
    name: '正在进行'
  },
  {
    id: 3,
    name: '已完成'
  },
  {
    id: 4,
    name: '计算失败'
  },
  {
    id: 5,
    name: '已中止'
  },
  {
    id: -1,
    name: '已删除'
  }
]

class RaceList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myPermissions: getMyPermissions(),
      name: '',
      selectedExamStatus: 'all',
      time: null,

      dataSource: [],
      page: 1,
      page_size: 10,
      total: 0,
      loading: false,

      modalVisible: false,
      ruleList: [],
      rule_id: undefined,
      timeRange: null,
      systems: [],
      checkAllSystem: true,
      raceSettingInfo: null,
      examResultInfo: null
    }
  }

  componentDidMount() {
    this.getRuleList()
    this.getSystems()
    this.getList(this.state.page)
    this.myInterval = setInterval(() => {
      if (!this.state.loading) {
        this.getList(this.state.page)
      }
    }, 10000)
  }
  componentWillUnmount() {
    this.myInterval && clearInterval(this.myInterval)
  }
  componentDidUpdate(prevProps) {
    // const prevSearch = new URLSearchParams(prevProps.location.search);
    // const currSearch = new URLSearchParams(this.props.location.search);
    // const currPageKey = currSearch.get("pageKey");
    // if (currPageKey === "RaceSetting") {
    //     // RaceSetting
    //     if (!this.state.isRaceSetting) {
    //         this.setState({ isRaceSetting: true });
    //     };
    // };
    // console.log(currPageKey);
  }

  getList = page => {
    const { page_size, name, time, selectedExamStatus, loading } = this.state
    if (loading) {
      message.info('刷新中，请稍后尝试')
      return
    }
    this.setState({ loading: true })
    post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/list', {
      ...(time
        ? {
            search_time: time.format('YYYY-MM-DD HH:mm:00')
          }
        : {}),
      name: name ? name.slice(0, 12) : undefined,
      status: selectedExamStatus === 'all' ? undefined : selectedExamStatus,
      page,
      page_size
    }).then(retData => {
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0) {
        this.setState({
          dataSource: retData.data.results.map(item => {
            item.key = item._id
            return item
          }),
          page,
          total: retData.data.total
        })
      }
      this.setState({ loading: false })
    })
  }

  delExam = _id => {
    let self = this
    let execDel = () => {
      self.setState({ loading: true })
      post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/delete', {
        _id: _id
      }).then(retData => {
        self.setState({ loading: false })
        if (retData.data && retData.data.errcode === 0) {
          message.success('已删除')
          self.getList(self.state.page)
        } else {
          message.error('删除失败')
        }
      })
    }

    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      // content: '确认删除吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => execDel()
    })
  }

  getRuleList = () => {
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/list', {}).then(retData => {
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0) {
        this.setState({
          ruleList: retData.data.results
          // rule_id: retData.data.results
          //     .filter(({ status }) => status === 1)[0]._id || null,
        })
      }
    })
  }

  getSystems = () => {
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/cmt_system/list').then(retData => {
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0) {
        this.setState({
          systems: retData.data.results.map(result => ({
            ...result,
            checked: true,
            timeRange: null
          }))
        })
      }
    })
  }

  modalOnOk = () => {
    console.log('modalOnOk')
    const { name, rule_id, timeRange } = this.state
    this.setState({ loading: true })
    // post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/create', {
    //     rule_id,
    //     details: systems.filter(({ time_begin, time_end, selected }) => selected && time_end && time_begin).map(({ id: sys_id, time_begin, time_end, users = [] }) => {
    //         return {
    //             sys_id, time_begin, time_end, users: users.map(({ user_id, user_name: user_name }) => ({ user_id, user_name: user_name }))
    //         }
    //     }),
    //     name: raceName,
    // }).then(retData => {
    //     console.log(retData);
    //     self.setState({ loading: false });
    //     if (retData.data && retData.data.errcode === 0) {
    //         this.props.history.push('/System/RaceList');
    //     } else if (retData.data && retData.data.msg) {
    //         Modal.warning({ title: '创建失败', content: retData.data.msg });
    //     } else {
    //         message.error('创建失败')
    //     }
    // });
  }

  systemValueOnchange = (index, key, value) => {
    const { systems } = this.state
    this.setState({
      systems: systems.map((system, sysIndex) => ({
        ...system,
        ...(sysIndex === index
          ? {
              [key]: value
            }
          : {})
      }))
    })
  }

  systemCheckedOnchange = (index, checked) => {
    this.systemValueOnchange(index, 'checked', checked)
  }

  systemTimeRangeOnchange = (index, timeRange) => {
    this.systemValueOnchange(index, 'timeRange', timeRange)
  }

  allSystemCheckedOnchange = checked => {
    this.setState({ checkAllSystem: checked })
    if (!checked) {
      const { timeRange, systems } = this.state
      this.setState({
        systems: systems.map(system => ({
          ...system,
          timeRange
        }))
      })
    }
  }

  stopExam = _id => {
    let self = this
    let execStop = () => {
      self.setState({ loading: true })
      post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/terminate_one', {
        _id
      }).then(retData => {
        self.setState({ loading: false })
        if (retData.data && retData.data.errcode === 0) {
          message.success('已中止')
          self.getList(self.state.page)
        } else {
          message.error('中止失败')
        }
      })
    }

    Modal.confirm({
      title: '当前正在进行的竞赛将被中止，是否继续？',
      icon: <ExclamationCircleOutlined />,
      // content: '确认删除吗？',
      okText: '继续',
      cancelText: '取消',
      onOk: () => execStop()
    })
  }

  watchOnClick = id => {
    // const pathname = this.props.location.pathname;
    // this.props.history.push(`${pathname}?pageKey=RaceSetting&id=${id}`);
    this.setState({ raceSettingInfo: { id } })
  }

  gotoExamResult = id => {
    this.setState({
      raceSettingInfo: null,
      examResultInfo: { id }
    })
  }

  backtoList = () => {
    this.setState({
      raceSettingInfo: null,
      examResultInfo: null
    })
  }

  render() {
    let self = this
    const {
      checkAllSystem,
      systems,
      modalVisible,
      ruleList,
      rule_id,
      timeRange,
      myPermissions,
      dataSource,
      page_size: pageSize,
      page: current,
      total,
      loading,
      name,
      selectedExamStatus,
      time,
      raceSettingInfo,
      examResultInfo
    } = this.state
    const { customStyle } = this.props

    return (
      <div className={style['race_list_wrapper']} style={customStyle}>
        <Modal
          centered
          visible={modalVisible}
          onOk={this.modalOnOk}
          onCancel={() => this.setState({ modalVisible: false })}
          width={700}
        >
          <div className={style['race_list_header_wrapper_modal']}>
            <div className="wrapper_row">
              <div>竞赛名称：</div>
              <Input
                style={{ width: 412 }}
                value={name}
                onChange={e => this.setState({ name: e.target.value })}
                placeholder="请输入名称"
              />
            </div>
            <div className="wrapper_row">
              <div>选择规则：</div>
              <Select
                style={{ width: 412 }}
                value={rule_id}
                onChange={rule_id => this.setState({ rule_id })}
                placeholder="请选择规则"
                // optionFilterProp="children"
              >
                {ruleList.map(({ _id, name }) => (
                  <Option value={_id} key={_id}>
                    {name} {_id}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="wrapper_row">
              <div>起止时间：</div>
            </div>
            <div className="time_range_wrapper">
              <div className="row row_first">
                <div className="cus_checkbox_wrapper">
                  <Checkbox
                    checked={checkAllSystem}
                    onChange={e => this.allSystemCheckedOnchange(e.target.checked)}
                  />
                  <div className="title">全部系统</div>
                </div>
                <RangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder={['开始时间', '结束时间']}
                  value={timeRange}
                  onChange={timeRange => this.setState({ timeRange })}
                />
              </div>
              {!checkAllSystem &&
                systems.map(({ name, checked, timeRange }, index) => (
                  <div className="row" key={index}>
                    <div className="cus_checkbox_wrapper">
                      <Checkbox
                        checked={checked}
                        onChange={e => this.systemCheckedOnchange(index, e.target.checked)}
                      />
                      <div className="title">{name}</div>
                    </div>
                    <RangePicker
                      showTime={{ format: 'HH:mm:ss' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder={['开始时间', '结束时间']}
                      value={timeRange}
                      onChange={timeRange => this.systemTimeRangeOnchange(index, timeRange)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </Modal>
        <div
          className="inner_wrapper"
          style={!!raceSettingInfo || !!examResultInfo ? { opacity: 0 } : {}}
        >
          <div className="race_list_header_wrapper">
            <div>竞赛名称：</div>
            <Input
              style={{ width: 200 }}
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
              placeholder="请输入名称"
            />
            <div style={{ marginLeft: 20 }}>起止时间：</div>
            <DatePicker
              placeholder="请选择时间"
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={time}
              onChange={time => this.setState({ time })}
            />
            <div style={{ marginLeft: 20 }}>状态：</div>
            <Select
              style={{ width: 100 }}
              optionFilterProp="children"
              onChange={selectedExamStatus => this.setState({ selectedExamStatus })}
              value={selectedExamStatus}
            >
              <Option value={'all'}>全部</Option>
              {ExamStatus.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              onClick={() => this.getList(1)}
              style={{
                marginLeft: 20,
                backgroundColor: '#357CF7',
                borderColor: '#357CF7'
              }}
            >
              查询
            </Button>
            <Button onClick={() => window.location.reload()} style={{ marginLeft: 'auto' }}>
              刷新
            </Button>
            {myPermissions.includes('exam_results/add_btn') && (
              <Button
                type="primary"
                className="btn_setting"
                onClick={() => this.props.history.push('/System/RaceSetting/-1')}
                style={{
                  backgroundColor: '#357CF7',
                  borderColor: '#357CF7'
                }}
              >
                新建竞赛
              </Button>
              // <Button type="primary" className="btn_setting" onClick={() => this.setState({ modalVisible: true })}>新建竞赛</Button>
            )}
          </div>
          <div className="line_bottom"></div>
          <div className="race_list_content_wrapper">
            <div className="content_wrapper">
              {/* <div className="header">一级菜单 / 二级菜单 / 三级菜单</div> */}

              <Table
                dataSource={dataSource}
                loading={loading}
                pagination={{
                  current,
                  pageSize,
                  total,
                  onChange: this.getList,
                  disabled: loading
                }}
                scroll={{ y: true }}
                rowClassName={({ status }) => {
                  return status === 2 ? 'ongoing_record' : ''
                }}
                columns={[
                  {
                    title: '竞赛名称',
                    dataIndex: 'name',
                    align: 'center'
                  },
                  {
                    title: '起始时间',
                    dataIndex: 'first_time_begin',
                    align: 'center'
                  },
                  {
                    title: '结束时间',
                    dataIndex: 'last_time_end',
                    align: 'center'
                  },
                  {
                    title: '使用规则',
                    dataIndex: 'rule_name',
                    align: 'center'
                  },
                  {
                    title: '负责人',
                    dataIndex: 'user_name',
                    render: text => {
                      let tmp_name = ''
                      if (!!tmp_users_exchange && typeof tmp_users_exchange[text] === 'string') {
                        tmp_name = tmp_users_exchange[text]
                      }
                      return tmp_name
                    },
                    align: 'center'
                  },
                  {
                    title: '状态',
                    key: 'status',
                    render: (text, { status }, index) =>
                      (DisplayExamStatus.filter(({ id }) => id === status)[0] || {}).name,
                    align: 'center'
                  },
                  {
                    title: '操作',
                    key: 'action',
                    width: 190,
                    render: (text, { _id, status }, index) => (
                      <React.Fragment>
                        {myPermissions.includes('exam_results/view_btn') && (
                          <Button
                            type="link"
                            onClick={() => this.watchOnClick(_id)}
                            style={{ color: '#357CF7' }}
                          >
                            查看
                          </Button>
                        )}
                        {status === 2 || status === 1
                          ? myPermissions.includes('exam_results/stop_btn') && (
                              <Button
                                type="link"
                                onClick={() => this.stopExam(_id)}
                                style={{ color: '#357CF7' }}
                              >
                                中止
                              </Button>
                            )
                          : myPermissions.includes('exam_results/del_btn') && (
                              <Button
                                type="link"
                                onClick={() => self.delExam(_id)}
                                style={{ color: '#357CF7' }}
                              >
                                删除
                              </Button>
                            )}
                      </React.Fragment>
                    ),
                    align: 'center'
                  }
                ]}
              />
            </div>
          </div>
        </div>
        {!!raceSettingInfo && (
          <div className="secondary_wrapper">
            <RaceSetting
              {...raceSettingInfo}
              gotoExamResult={this.gotoExamResult}
              customStyle={customStyle}
            />
          </div>
        )}
        {!!examResultInfo && (
          <div className="secondary_wrapper">
            <ExamResult {...examResultInfo} backtoList={this.backtoList} />
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(RaceList)
