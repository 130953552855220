import React, { useContext, useEffect, useState, useMemo } from 'react';
import style from './index.module.scss';
import ICON_DOWNLOAD from './res/download.svg';
import CommonContext from '../context';
import { post, postDownloadFile } from 'common/utils';
import { getPowerConfig, getElecFeeConfig, countChildrenNum, getAllTags, getAllTagsInfo } from './utils';
import moment from 'moment';
import { Spin } from 'antd';

const tabs = [
    {
        name: '电量',
        confFunc: getPowerConfig,
    },
    {
        name: '电费',
        confFunc: getElecFeeConfig,
    },
];

const Comp = (props) => {
    // const { } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(null);
    const [switchTag, setSwitchTag] = useState('all');// all, self
    const [dataSource, setDataSource] = useState(null);
    const [loading, setLoading] = useState(false);
    const [topHeadScroll, setTopHeadScroll] = useState(false);
    const tableOnScroll = (e) => {
        const { scrollTop } = e.target;
        const nextTopHeadScroll = scrollTop !== 0;
        if (nextTopHeadScroll !== topHeadScroll) {
            setTopHeadScroll(nextTopHeadScroll);
        };
    };
    const commonInfo = useContext(CommonContext);
    const { state: { selectedDevice } } = commonInfo;
    const timeRangeConf = useMemo(() => {
        const { state: { timeRange } } = commonInfo;
        return timeRange;
    }, [commonInfo]);
    const tableConfig = useMemo(() => {
        if (!!timeRangeConf && typeof selectedTabIndex === 'number') {
            const [timeTab, dayBegin] = timeRangeConf;
            const getConfFunc = tabs[selectedTabIndex].confFunc;
            const conf = getConfFunc(timeTab.tag, dayBegin);
            // console.log(conf);
            return conf;
        };
        return null;
    }, [timeRangeConf, selectedTabIndex]);
    const allQuaryTagsInfo = useMemo(() => {
        return !!tableConfig ? getAllTagsInfo(tableConfig) : null;
    }, [tableConfig]);
    const allQueryTags = useMemo(() => {
        return !!tableConfig ? getAllTags(tableConfig) : null;
    }, [tableConfig]);
    useEffect(() => { setSelectedTabIndex(0); }, []);
    useEffect(() => {
        if (!!allQueryTags && !!selectedDevice && !!timeRangeConf) {
            const [{ queryStr, momentUnit }, start_time, end_time] = timeRangeConf;
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/meter_node/get_meter_table_report_2', {
                "meter_id": selectedDevice.id,
                "day_begin": moment(start_time).startOf(momentUnit).format("YYYY-MM-DD"),
                "day_end": moment(end_time).endOf(momentUnit).format("YYYY-MM-DD"),
                "time_type": queryStr,
                "has_sub_meters": switchTag === 'all',
                "meter_tag_type_list": allQueryTags,
            })
                .then((retData) => {
                    setLoading(false);
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        setDataSource(retData.data.results);
                    };
                });
        };
    }, [allQueryTags, switchTag, selectedDevice, timeRangeConf]);
    const switchStyle = useMemo(() => {
        switch (switchTag) {
            case 'all':
                return { backgroundColor: '#E6E6E6', justifyContent: 'flex-end'};
            case 'self':
                return { backgroundColor: '#355B99', justifyContent: 'flex-start' };
            default:
                return {};
        };
    }, [switchTag]);
    // console.log(dataSource, allQuaryTagsInfo);
    const downloadOnClick = () => {
        if (!selectedDevice) return;
        if (!timeRangeConf) return;
        const [{ queryStr, momentUnit }, start_time, end_time] = timeRangeConf;
        postDownloadFile("https://xinchen-api.quantile.tech/api/meter_node/download_meter_table_report_2", {
            "meter_id": selectedDevice.id,
            "day_begin": moment(start_time).startOf(momentUnit).format("YYYY-MM-DD"),
            "day_end": moment(end_time).endOf(momentUnit).format("YYYY-MM-DD"),
            "time_type": queryStr,
            "has_sub_meters": switchTag === 'all',
            "meter_tag_type_list": allQueryTags,
        });
    };
    return (
        <div className={style['wrapper']}>
            <div className="tab_row">
                {
                    tabs
                        .map((item, index) => {
                            const { name } = item;
                            return (
                                <div className={"tab_item" + (selectedTabIndex === index ? " selected_tab_item" : "")} key={index} onClick={() => setSelectedTabIndex(index)}>
                                    <div className="text">{name}</div>
                                </div>
                            );
                        })
                }
                <div style={{ marginLeft: 'auto' }} />
                <div className="switch_btn_wrapper" style={switchStyle} onClick={() => setSwitchTag(switchTag === 'all' ? 'self' : 'all')}>
                    {
                        switchTag === 'all' ? ([
                            <div className="text" key="1" style={{ marginRight: 6,color: '#3A3A3A'}}>所有归属表</div>,
                            <div className="ball" key="2"></div>
                        ]) : (switchTag === 'self' ? ([
                            <div className="ball" key="2"></div>,
                            <div className="text" key="1" style={{ marginLeft: 6 ,color:'#fff'}}>只看本表</div>
                        ]) : (null))
                    }
                </div>
                <img alt="" src={ICON_DOWNLOAD} width="24" height="24" style={{ marginLeft: 16, userSelect: 'none', cursor: 'pointer' }} onClick={downloadOnClick} />
            </div>
            <div className="content_wrapper" onScroll={tableOnScroll}>
                {
                    !!tableConfig && (
                        <table>
                            <thead>
                                <tr>
                                    <th rowSpan="2">电表</th>
                                    <th rowSpan="2">日期</th>
                                    {
                                        tableConfig
                                            .map((item, index) => {
                                                if (!item.children) return null;
                                                const countChildren = countChildrenNum(item.children);
                                                const { name } = item;
                                                return (
                                                    <th key={index} colSpan={countChildren}>{name}</th>
                                                );
                                            })
                                    }
                                </tr>
                                <tr>
                                    {
                                        tableConfig
                                            .map((item, index) => {
                                                const { children } = item;
                                                if (!children) return null;
                                                return children
                                                    .map((child, childIndex, childArr) => {
                                                        const { name, unit, compare } = child;
                                                        let nodes = [(
                                                            <th key={`${index}-${childIndex}-1`} style={(childIndex < childArr.length - 1 || !!compare) ? { borderRightWidth: 0 } : {}}>
                                                                <div className={"th_content" + (childIndex > 0 ? " need_left_line" : "")}>
                                                                    <div className="name">{name}</div>
                                                                    <div className="unit">{unit}</div>
                                                                </div>
                                                            </th>
                                                        )];
                                                        if (!!compare) {
                                                            const { name } = compare;
                                                            nodes = nodes.concat([(
                                                                <th key={`${index}-${childIndex}-2`} style={childIndex < childArr.length - 1 ? { borderRightWidth: 0 } : {}}>
                                                                    <div className="compare_content">
                                                                        <div className="text">{name}</div>
                                                                    </div>
                                                                </th>
                                                            )]);
                                                        };
                                                        return nodes;
                                                    });
                                            })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !!dataSource &&
                                    dataSource
                                        .map((item, index) => {
                                            const { meter_name, data_time } = item;
                                            const timeText = moment(data_time).format("YYYY/M/D");
                                            return (
                                                <tr key={index}>
                                                    <td>{meter_name || '--'}</td>
                                                    <td>{timeText}</td>
                                                    {
                                                        allQuaryTagsInfo
                                                            .map((tagInfo, tagInfoIndex) => {
                                                                const { tag, isCompare, isTime } = tagInfo;
                                                                const isBold = !isCompare && !isTime;
                                                                let vText = item[tag];
                                                                const mulitple = isCompare ? 10 : 1000;
                                                                if (typeof vText === 'number') {
                                                                    vText = Math.floor(vText * mulitple) / mulitple;
                                                                } else {
                                                                    vText = '--';
                                                                };
                                                                return (
                                                                    <td key={tagInfoIndex} className={(isCompare ? " compare" : "") + (isTime ? " time" : "") + (isBold ? " bold" : "")}>
                                                                        {vText || '--'}{isCompare ? "%" : ""}
                                                                    </td>
                                                                );
                                                            })
                                                    }
                                                </tr>
                                            );
                                        })
                                }
                            </tbody>
                        </table>
                    )
                }
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
