import React, { useRef, useEffect, useContext, useMemo } from 'react';
import echarts from 'echarts';
import style from './DailyChart.module.scss';
import CommonContext from '../../context';
import { getTimeRange } from './utils';
import moment from 'moment';

const initChart = (refNode, datas, time) => {
    const [timeTab, timeStr] = time;
    // console.log(datas, time, getTimeRange(timeTab.tag, timeStr));
    const timeRange = getTimeRange(timeTab.tag, timeStr);
    let xDatas = [];
    let lineData_1 = [], lineData_2 = [], barData_1 = [], barData_2 = [], barData_3 = [];
    if (!!timeRange) {
        const [timeStart, timeEnd] = timeRange;
        const endMoment = moment(timeEnd);
        let startMoment = moment(timeStart);
        while (startMoment.isSameOrBefore(endMoment)) {
            xDatas.push(startMoment.format("YYYY-MM-DD"));
            startMoment = startMoment.add(1, 'days');
        };
        [, lineData_1, lineData_2, barData_1, barData_2, barData_3] = datas
            .map((data) => {
                if (!data) return [];
                const ret = xDatas.map(() => null);
                return data.reduce((pV, cItem) => {
                    const [key, value] = cItem;
                    const arrIndex = xDatas.indexOf(moment(key).format("YYYY-MM-DD"));
                    if (arrIndex !== -1) {
                        let nextPV = [].concat(pV);
                        nextPV[arrIndex] = value;
                        return nextPV;
                    };
                    return pV;
                }, ret);
            });
    };
    // console.log(xDatas, barData, lineData_1, lineData_2);
    if (!!timeRange) { };
    if (!!refNode) {
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        instance.setOption({
            grid: {
                left: 101,
                right: 75,
                top: 10,
                bottom: 30,
            },
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0,0,0,0.4)',
            },
            xAxis: [
                {
                    type: 'category',
                    data: xDatas,
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    show: false,
                },
                {
                    type: 'value',
                    show: false,
                    min: (value) => {
                        return 0.7 * value.min;
                    },
                    max: (value) => {
                        const { min, max } = value;
                        return max + (max - min) / 10;
                    },
                },
                {
                    type: 'value',
                    show: false,
                }
            ],
            series: [
                {
                    name: '电量（最大）',
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: '#FE8569'
                    },
                    data: barData_1,
                }, {
                    name: '电量（平段）',
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: '#FFC63C'
                    },
                    data: barData_2,
                }, {
                    name: '电量（低谷）',
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: '#5BA3ED'
                    },
                    data: barData_3,
                }, {
                    name: '电费',
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        color: '#727272'
                    },
                    data: lineData_1,
                }, {
                    name: '电价指数',
                    type: 'line',
                    yAxisIndex: 2,
                    itemStyle: {
                        color: 'rgba(254,139,139,1)',
                    },
                    data: lineData_2,
                }
            ]
        });
    }
};

const Comp = (props) => {
    const { hisDatas } = props;
    const chartNode = useRef(null);
    const commonInfo = useContext(CommonContext);
    const time = useMemo(() => {
        if (!!commonInfo) {
            const { state: { time } } = commonInfo;
            return time;
        };
        return null;
    }, [commonInfo]);

    useEffect(() => {
        initChart(chartNode.current, hisDatas, time);
    }, [hisDatas, time]);
    return (
        <div className={style['DailyChart']}>
            <div className='chartNode' ref={chartNode}></div>
        </div>
    );
};

export default Comp;
