import React from 'react';
import CalendarCard from './CalendarCard';
import HealthCard from './HealthCard';
import SepTimeBoard from './SepTimeBoard/SepTimeBoard';
import SimpleCardsGroup from './SimpleCardsGroup';
import style from './index.module.scss';

const Comp = (props) => {
    return (
        <div className={style['wrapper']}>
            <div className="card_group_row">
                <div className="left" style={{ width: 344 }}>
                    <CalendarCard />
                </div>
                <div className="mid" style={{ width: 416 }}>
                    <SimpleCardsGroup />
                </div>
                <div className="right" style={{ width: 664 }}>
                    <HealthCard />
                </div>
            </div>
            <div className="card_wrapper">
                <SepTimeBoard />
            </div>
        </div>
    );
};

export default Comp;
