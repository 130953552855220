import React from 'react';
import { Input, Modal, message, Select, DatePicker } from 'antd';
import { post, patch } from '../../../common/utils.js';
import { getMyPermissions } from '../../../common/AuthPermission.js';
import moment from 'moment';
import style from './AbnormalModal.module.scss';

const Option = Select.Option;
const { TextArea } = Input;

export default class AbnormalModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAbnormal: props.selectedAbnormal,
            sys_id: null,
            time_begin: null,
            time_end: null,
            systems: props.systems,
            loading: false,
            visible: true,
            myPermissions: getMyPermissions(),

            selectedAbnormalType: null,
            desp: '',
        }
    }

    componentDidMount() {
        const { selectedAbnormal } = this.state;
        if (selectedAbnormal) {
            const { time_begin, time_end, desp, sys_id, type_id } = selectedAbnormal;
            this.setState({
                time_begin: time_begin ? moment(time_begin) : null,
                time_end: time_end ? moment(time_end) : null,
                desp,
                sys_id,
                selectedAbnormalType: type_id
            });
        }
    }

    verifyData = () => {
        const { selectedAbnormalType, sys_id, time_begin, time_end, selectedAbnormal } = this.state;
        if (selectedAbnormal) return true;
        if (!selectedAbnormalType) {
            message.error('请选择【剔除类型】');
            return false;
        } else if (!sys_id) {
            message.error('请选择【所属系统】');
            return false;
        } else if (!time_begin) {
            message.error('请填写【开始时间】');
            return false;
            // } else if (!time_end) {
            //     message.error('请填写【结束时间】');
            //     return false;
        }
        return true;
    }

    saveData = _ => {
        let s = this.state;

        let result = this.verifyData();
        if (!result) {
            return;
        }

        const { selectedAbnormal, selectedAbnormalType, sys_id, time_begin, time_end, desp } = this.state;

        let data = {
            sys_id: parseInt(sys_id),
            type_id: parseInt(selectedAbnormalType),
            desp,
            time_begin: time_begin ? time_begin.format('YYYY-MM-DD HH:mm:ss') : null,
            time_end: time_end ? time_end.format('YYYY-MM-DD HH:mm:ss') : null,
        };

        let self = this;
        self.setState({ loading: true });
        if (!selectedAbnormal) {
            post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/abnormal_periods', data).then((retData) => {
                self.setState({ loading: false });
                if (retData.error == true) {
                    message.error('保存失败。');
                } else if (retData && retData.data && retData.data.id) {
                    message.success('保存成功');
                    self.props.onClose(true);
                } else {
                    message.error('保存失败');
                }
            })
        } else {
            patch(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/abnormal_periods/' + selectedAbnormal.id, data)
                .then((retData) => {
                    self.setState({ loading: false });
                    if (retData.error == true) {
                        message.error('保存失败。');
                    } else if (retData && retData.data && retData.data.id) {
                        message.success('保存成功');
                        self.props.onClose(true);
                    } else {
                        message.error('保存失败');
                    }
                })
        }
    }

    beginTimeOnchange = v => {
        const { time_end } = this.state;
        if (v && v.isSameOrAfter(time_end)) {
            message.error('开始时间应早于结束时间，请重新选择')
            this.setState({ time_begin: null });
        } else {
            this.setState({ time_begin: v });
        }
    }

    endTimeOnchange = v => {
        const { time_begin } = this.state;
        if (v && v.isSameOrBefore(time_begin)) {
            message.error('结束时间应晚于结束时间，请重新选择')
            this.setState({ time_end: null });
        } else {
            this.setState({ time_end: v });
        }
    }

    render() {
        const range = (start, end) => {
            const result = [];
            for (let i = start; i <= end; i++) {
                result.push(i);
            }
            return result;
        };
        const { abnormalType } = this.props;

        return (
            <Modal className={style["EventModal"]}
                visible={true}
                width={600}
                // title='事件详情'
                confirmLoading={this.state.loading}
                // footer={this.state.myPermissions.includes('edit_prod_event') ? undefined : null}
                okText='保存'
                onOk={this.saveData}
                onCancel={() => this.props.onClose(false)}
                okButtonProps={{
                    style: {
                        backgroundColor: '#357CF7',
                        borderColor: '#357CF7',
                    },
                }}
            >
                <div className="info-line">
                    <div className="info-hint">剔除类型</div>
                    <div className="info-content">
                        <Select
                            defaultValue={this.state.selectedAbnormalType}
                            value={this.state.selectedAbnormalType}
                            style={{ width: 200 }}
                            onChange={v => this.setState({ selectedAbnormalType: v })}
                        >
                            {
                                abnormalType.map(item =>
                                    <Option value={item.id} key={item.id}>{item.name}</Option>)
                            }
                        </Select>
                    </div>
                </div>
                <div className="info-line">
                    <div className="info-hint">所属系统</div>
                    <div className="info-content">
                        <Select
                            defaultValue={this.state.sys_id}
                            value={this.state.sys_id}
                            style={{ width: 200 }}
                            onChange={v => this.setState({ sys_id: v })}
                        >
                            {
                                this.state.systems.map(item =>
                                    <Option value={item.id} key={item.id}>{item.name}</Option>)
                            }
                        </Select>
                    </div>
                </div>
                <div className="info-line">
                    <div className="info-hint">开始时间</div>
                    <div className="info-content">
                        <DatePicker
                            style={{ width: 200 }}
                            format="YYYY-MM-DD HH:mm:ss"
                            value={this.state.time_begin}
                            onChange={this.beginTimeOnchange}
                            showTime={{ defaultValue: this.state.time_begin }}
                            disabledDate={current => {
                                const { time_end } = this.state;
                                if (time_end && current) {
                                    if (current.isAfter(time_end, 'day')) return true;
                                    if (current.isSame(time_end, 'day') && time_end.isSame(moment(time_end).startOf('day'))) return true;
                                }
                                return false;
                            }}
                            disabledTime={current => {
                                let hours = [];
                                const { time_end } = this.state;
                                if (time_end && current && current.isSame(time_end, 'day')) {
                                    const end_hour = time_end.hour();
                                    hours = range(end_hour + 1, 23);
                                    if (time_end.minute() === 0 && time_end.second() === 0) {
                                        hours.push(end_hour);
                                    }
                                }
                                return {
                                    disabledHours: () => hours,
                                    disabledMinutes: (selectedHour) => {
                                        let minutes = [];
                                        if (time_end && current && current.isSame(time_end, 'day')) {
                                            if (time_end.hour() === selectedHour) {
                                                const end_minute = time_end.minute();
                                                minutes = range(end_minute + 1, 59);
                                                if (time_end.second() === 0) {
                                                    minutes.push(end_minute);
                                                }
                                            } else if (time_end.hour() < selectedHour) {
                                                minutes = range(0, 59);
                                            }
                                        }
                                        return minutes;
                                    },
                                    disabledSeconds: (selectedHour, selectedMinute) => {
                                        let seconds = [];
                                        if (time_end && current && current.isSame(time_end, 'day')) {
                                            if (time_end.hour() === selectedHour) {
                                                if (time_end.minute() === selectedMinute) {
                                                    seconds = range(time_end.second(), 59);
                                                } else if (time_end.minute() < selectedMinute) {
                                                    seconds = range(0, 59);
                                                }
                                            } else if (time_end.hour() < selectedHour) {
                                                seconds = range(0, 59);
                                            }
                                        }
                                        return seconds;
                                    },
                                };
                            }}
                        />
                    </div>
                </div>
                <div className="info-line">
                    <div className="info-hint">结束时间</div>
                    <div className="info-content">
                        <DatePicker
                            style={{ width: 200 }}
                            format="YYYY-MM-DD HH:mm:ss"
                            value={this.state.time_end}
                            onChange={this.endTimeOnchange}
                            showTime={{ defaultValue: this.state.time_end }}
                            disabledDate={current => {
                                const { time_begin } = this.state;
                                if (time_begin && current) {
                                    if (current.isBefore(time_begin, 'day')) return true;
                                    if (current.isSame(time_begin, 'day') && time_begin.isSame(moment(time_begin).endOf('day'))) return true;
                                }
                                return false;
                            }}
                            disabledTime={current => {
                                let hours = [];
                                const { time_begin } = this.state;
                                if (time_begin && current && current.isSame(time_begin, 'day')) {
                                    const begin_hour = time_begin.hour();
                                    hours = range(0, begin_hour - 1);
                                    if (time_begin.minute() === 59 && time_begin.second() === 59) {
                                        hours.push(begin_hour);
                                    }
                                }
                                return {
                                    disabledHours: () => hours,
                                    disabledMinutes: (selectedHour) => {
                                        let minutes = [];
                                        if (time_begin && current && current.isSame(time_begin, 'day')) {
                                            if (time_begin.hour() === selectedHour) {
                                                const begin_minute = time_begin.minute();
                                                minutes = range(0, begin_minute - 1);
                                                if (time_begin.second() === 59) {
                                                    minutes.push(begin_minute);
                                                }
                                            } else if (time_begin.hour() > selectedHour) {
                                                minutes = range(0, 59);
                                            }
                                        }
                                        return minutes;
                                    },
                                    disabledSeconds: (selectedHour, selectedMinute) => {
                                        let seconds = [];
                                        if (time_begin && current && current.isSame(time_begin, 'day')) {
                                            if (time_begin.hour() === selectedHour) {
                                                if (time_begin.minute() === selectedMinute) {
                                                    seconds = range(0, time_begin.second());
                                                } else if (time_begin.minute() > selectedMinute) {
                                                    seconds = range(0, 59);
                                                }
                                            } else if (time_begin.hour() > selectedHour) {
                                                seconds = range(0, 59);
                                            }
                                        }
                                        return seconds;
                                    },
                                };
                            }}
                        />
                    </div>
                </div>
                <div className="info-line">
                    <div className="info-hint">描述</div>
                    <div className="info-content desp_info-content">
                        <TextArea
                            rows={4}
                            value={this.state.desp}
                            onChange={(e) => this.setState({ desp: e.target.value })}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}
