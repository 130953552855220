import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import { Button, Space, Spin, Modal, DatePicker, message } from 'antd';
import { useData } from '../hooks';
import * as echarts from 'echarts~5';
import DomResize from 'components/DomResize';
import { get_range, renderChart } from '../utils';
import moment from 'moment';

const tabs = [
    // {
    //     name: '近10分钟',
    //     period: 1,
    //     period_unit: 'minutes',
    // },
    {
        name: '近30分钟',
        period: 30,
        period_unit: 'minutes',
    },
    // {
    //     name: '近1小时',
    //     period: 1,
    //     period_unit: 'hours',
    // },
    {
        name: '近2小时',
        period: 2,
        period_unit: 'hours',
    },
    {
        name: '近4小时',
        period: 4,
        period_unit: 'hours',
    },
    {
        name: '近6小时',
        period: 6,
        period_unit: 'hours',
    },
    {
        name: '近12小时',
        period: 12,
        period_unit: 'hours',
    },
]
    .map((item, index) => Object.assign({}, item, { key: index + "" }));

const Comp = (props) => {
    const { tag } = props;

    const [selectedTab, setSelectedTab] = useState(() => {
        let initSelectedTab = tabs?.[0] ?? null;
        if (!!initSelectedTab) {
            const { period, period_unit } = initSelectedTab;
            initSelectedTab = Object.assign({}, initSelectedTab, { range: get_range(period, period_unit) });
        };
        return initSelectedTab;
    });
    const btnOnClick = (item) => {
        const { period, period_unit } = item;
        setSelectedTab(Object.assign({}, item, { range: get_range(period, period_unit) }));
    };

    const refNode = useRef(null);

    const [data, setData, loading] = useData(null);

    useEffect(() => {
        // console.log(selectedTab, tag);
        let conf = null;
        if (!!selectedTab) {
            conf = Object.assign({}, conf ?? {}, { range: selectedTab.range });
        };
        if (typeof tag === 'string') {
            conf = Object.assign({}, conf ?? {}, { tag_name: tag });
        };
        setData(conf);
    }, [selectedTab, tag]);

    useEffect(() => {
        renderChart(refNode.current, data);
    }, [data]);

    const [clientWidth, setClientWidth] = useState(0);
    const onResize = (conf) => {
        if (!!refNode.current) {
            const instance = echarts.getInstanceByDom(refNode.current);
            if (!!instance) {
                instance.resize();
            };
        };
        setClientWidth(conf.width > 520 ? 520 : conf.width);
        // widthRef.current = conf.width > 200 ? 200 : conf.width;
    };

    const [visible, setVisible] = useState(false);
    const cusBtnOnClick = () => {
        setVisible(true);
    };

    const [cusTimeBegin, setCusTimeBegin] = useState(null);
    const beginOnChange = (time) => {
        setCusTimeBegin(time);
    };

    const [cusTimeEnd, setCusTimeEnd] = useState(null);
    const endOnChange = (time) => {
        setCusTimeEnd(time);
    };

    const handleOk = () => {
        // check
        if (!!cusTimeBegin && !!cusTimeEnd) {
            if (moment(cusTimeBegin).isBefore(moment(cusTimeEnd))) {
                // correct
                setSelectedTab({
                    key: 'cus',
                    range: [cusTimeBegin, cusTimeEnd].map((t) => t.format("YYYY-MM-DD HH:mm:ss")),
                });
                setVisible(false);
            } else {
                message.warning("开始时间应该早于结束时间!");
            };
        } else {
            message.warning("请完整填写开始和结束时间!");
        };
    };

    const rangeText = useMemo(() => {
        let text = "-- 至 --";
        if (!!selectedTab) {
            const { range } = selectedTab;
            text = range.map((time) => moment(time).format("YYYY-MM-DD HH:mm")).join(" 至 ");
        };
        return text;
    }, [selectedTab]);

    return (
        <div className={style['wrapper']}>
            <div className="btns">
                <Space wrap={true}>
                    {
                        tabs
                            .map((item) => {
                                return (
                                    <Button
                                        key={item.key}
                                        {
                                        ...(
                                            selectedTab.key === item.key ? {
                                                type: "primary",
                                            } : {}
                                        )
                                        }
                                        onClick={() => btnOnClick(item)}
                                    >{item.name}</Button>
                                );
                            })
                    }
                    <Button
                        onClick={cusBtnOnClick}
                        {
                        ...(
                            selectedTab.key === 'cus' ? {
                                type: "primary",
                            } : {}
                        )
                        }
                    >自定义</Button>
                </Space>
            </div>
            <div className="title">历史查询</div>
            <div className="range">{rangeText}</div>
            <div className="bottom">
                <div className="resize">
                    <DomResize onResize={onResize} mountedCall={true} />
                </div>
                <div className="chart" ref={refNode}></div>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
            {
                visible && (
                    <Modal
                        title="自定义时间"
                        visible={true}
                        onOk={handleOk}
                        onCancel={() => setVisible(false)}
                        width={clientWidth}
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>开始</div>
                            <DatePicker
                                value={cusTimeBegin}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={beginOnChange}
                                style={{ width: "100%" }}
                            />
                            <div>结束</div>
                            <DatePicker
                                value={cusTimeEnd}
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                onChange={endOnChange}
                                style={{ width: "100%" }}
                            />
                        </Space>
                    </Modal>
                )
            }
        </div>
    );
};

export default Comp;
