import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import * as echarts from 'echarts~5';
import { Select, Spin } from 'antd';
import { useData } from './hooks';
import moment from 'moment';

const { Option } = Select;

const get_time_func = (time, period_times, period_unit) => {
    return moment(time).subtract(period_times, period_unit).format("YYYY-MM-DD 00:00:00");
};

// 先不用timeInfo里传来的，未来改造
const compare_period_options = [
    {
        name: '上一天',
        time_type: 'DAY',
        range: 'day',
        period_unit: 'days',
        get_time: get_time_func,
    },
    {
        name: '上一周',
        time_type: 'WEEK',
        range: 'week',
        period_unit: 'weeks',
        get_time: get_time_func,
    },
    {
        name: '上一月',
        time_type: 'MONTH',
        range: 'month',
        period_unit: 'months',
        get_time: get_time_func,
    },
]
    .map((item, index) => Object.assign({}, item, { key: index }));

const renderChart = (refNode, curr_data, compare_data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        // console.log(curr_data, compare_data);
        if (!curr_data) {
            instance.clear();
            return;
        };
        // 后面做了两次默认，没有值置为0
        // 未来可能改成 没有值不显示总和?
        let data = curr_data
            .map((item) => {
                const { cost, pot_cost } = item;
                return Object.assign({}, item, {
                    cost: typeof cost === 'number' ? cost : 0,
                    pot_cost: typeof pot_cost === 'number' ? pot_cost : 0,
                })
            });
        data = data
            .map((item) => {
                const { factor_src_id } = item;
                const compare_cost = (compare_data ?? []).filter(({ factor_src_id: compare_factor_src_id }) => compare_factor_src_id === factor_src_id)[0]?.cost ?? 0;
                return Object.assign({}, item, { compare_cost });
            });
        const all_info = data
            .reduce((pV, cV) => {
                const { cost, pot_cost, compare_cost } = cV;
                return Object.assign({}, pV, {
                    cost: pV.cost + cost,
                    pot_cost: pV.pot_cost + pot_cost,
                    compare_cost: pV.compare_cost + compare_cost,
                });
            }, {
                cost: 0,
                pot_cost: 0,
                compare_cost: 0,
                cost_name: '总成本',
                blank: 0,
            });
        let remain_total_cost = all_info.cost + all_info.pot_cost;
        data = data
            .map((item) => {
                const { cost, pot_cost } = item;
                remain_total_cost -= (cost + pot_cost);
                return Object.assign({}, item, { blank: remain_total_cost });
            });
        const addDatas = [all_info]
            .concat(
                data
            );

        instance.setOption({
            grid: {
                containLabel: true,
                left: 10,
                top: 36,
                right: 26,
                bottom: 23,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [, { axisValueLabel, marker: marker_1, value: value_1 }, { marker: marker_2, value: value_2 }, , { marker: marker_3, value: value_3 }] = params;
                    const parsed_values = [value_1, value_2, value_3]
                        .map((v) => typeof v === 'number' ? Math.floor(v * 1000) / 1000 : "--");
                    return `${axisValueLabel}<br />${marker_1}${parsed_values[0]}<br />${marker_2}${parsed_values[1]}<br />${marker_3}${parsed_values[2]}`;
                },
            },
            xAxis: {
                type: 'category',
                data: addDatas.map(({ cost_name: name }) => name),
                axisLabel: {
                    color: '#AAAAAA',
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#AAAAAA',
                },
                min: 0, //强制为0，为了解决小数数据相加减时产生小于0的极小数
            },
            series: [
                {
                    data: addDatas.map(({ blank }) => blank),
                    type: 'bar',
                    barWidth: 10,
                    stack: '1',
                    itemStyle: {
                        color: 'transparent',
                        borderRadius: 2,
                    },
                },
                {
                    data: addDatas.map(({ cost }) => cost),
                    type: 'bar',
                    barWidth: 10,
                    stack: '1',
                    itemStyle: {
                        color: '#355B99',
                        borderRadius: 2,
                    },
                },
                {
                    data: addDatas.map(({ pot_cost }) => pot_cost),
                    type: 'bar',
                    barWidth: 10,
                    stack: '1',
                    itemStyle: {
                        color: '#5BAE8D',
                        borderRadius: 2,
                    },
                },
                {
                    data: addDatas.map(({ blank }) => blank),
                    type: 'bar',
                    barWidth: 10,
                    stack: '2',
                    itemStyle: {
                        color: 'transparent',
                        borderRadius: 2,
                    },
                },
                {
                    data: addDatas.map(({ compare_cost }) => compare_cost),
                    type: 'bar',
                    barWidth: 10,
                    stack: '2',
                    itemStyle: {
                        color: '#C9C9C9',
                        borderRadius: 2,
                    },
                },
            ]
        });
    };
};

const default_func = () => { };

const Comp = (props) => {
    const { selectedProduct } = props;

    const [data, setData, loading] = useData();
    const [data_compare, setData_compare, loading_compare] = useData();
    const setDataFunc = useRef(default_func);
    const setDataFunc_compare = useRef(default_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : default_func;
    }, [setData]);
    useEffect(() => {
        setDataFunc_compare.current = typeof setData_compare === 'function' ? setData_compare : default_func;
    }, [setData_compare]);
    useEffect(() => {
        // 需要把空的selectedProduct id传进去，支持未来空的product选择
        setDataFunc.current(Object.assign({}, selectedProduct ?? {}, selectedProduct?.timeInfo ?? {}));
    }, [selectedProduct]);

    const refNode = useRef(null);
    useEffect(() => {
        const parsed_results_data = data?.parsed_results;
        const parsed_results_compare_data = data_compare?.parsed_results;
        if (!!refNode.current) {
            renderChart(refNode.current, parsed_results_data, parsed_results_compare_data);
        };
    }, [data, data_compare]);

    const [compareOption, setCompareOption] = useState(compare_period_options?.[0] ?? null);
    const optionOnChange = (v) => {
        const nextCompareOption = compare_period_options.filter(({ key }) => key === v)[0] ?? null;
        setCompareOption(nextCompareOption);
    };
    const [compareProdInfo, setCompareProdInfo] = useState(null);
    useEffect(() => {
        // compareTimeInfo 随着 timeInfo 和 compareOption 变化改变
        // 取timeInfo代表时间所在周期开始那一天为基准
        let nextCompareProdInfo = null;
        const timeInfo = selectedProduct?.timeInfo;
        if (!!timeInfo && !!compareOption) {
            // 两者都存在时有值
            const { time, range } = timeInfo;
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            const { get_time, period_unit } = compareOption;
            const time_compare = get_time(time_start, 1, period_unit);
            nextCompareProdInfo = Object.assign(
                {},
                selectedProduct,
                {
                    timeInfo: Object.assign({}, compareOption, {
                        time: time_compare,
                    })
                },
            );
        } else {
            // 不满足情况时
        };
        setCompareProdInfo(nextCompareProdInfo);
    }, [selectedProduct, compareOption]);
    useEffect(() => {
        // 需要把空的selectedProduct id传进去，支持未来空的product选择
        setDataFunc_compare.current(Object.assign({}, compareProdInfo ?? {}, compareProdInfo?.timeInfo ?? {}));
    }, [compareProdInfo]);

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="成本对标"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <div className="content">
                        <div className="left">
                            <div className="top">
                                {
                                    [
                                        {
                                            color: '#355B99',
                                            name: '当前时段',
                                        },
                                        {
                                            color: '#5BAE8D',
                                            name: '优化潜力',
                                        },
                                        {
                                            color: '#C9C9C9',
                                            name: '对标时段',
                                        },
                                    ]
                                        .map((item, index) => {
                                            const { color, name } = item;
                                            return (
                                                <div className="item_wrapper" key={index}>
                                                    <div className="color" style={{ backgroundColor: color }}></div>
                                                    <div className="name">{name}</div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                            <div className="bottom">
                                <div className="chart" ref={refNode}></div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="title">产品名称：</div>
                                <div className="value">{data?.parsed_basic_info?.product_name ?? ""}</div>
                            </div>
                            <div className="row">
                                <div className="title">统计时段：</div>
                                <div className="value">{data?.parsed_basic_info?.time_range_text ?? ""}</div>
                            </div>
                            <div className="row">
                                <div className="title">对标时段：</div>
                                <div className="value selector">
                                    <Select value={compareOption?.key ?? null} style={{ width: 101 }} onChange={optionOnChange}>
                                        {
                                            compare_period_options
                                                .map((item, index) => {
                                                    return (
                                                        <Option value={item.key} key={index}>{item.name}</Option>
                                                    );
                                                })
                                        }
                                    </Select>
                                    <div className="text">{data_compare?.parsed_basic_info?.time_range_text ?? ""}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && loading_compare && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
