import React from 'react';
import style from './index.module.scss';
import Header from './Header';
import Table from './Table';

const Page = (props) => {
    return (
        <div className={style['wrapper']}>
            <Header key={props.location.search + ""} />
            <div className="content_wrapper">
                <div className="inner_wrapper">
                    <Table key={props.location.search + ""} />
                </div>
            </div>
        </div>
    );
};

export default Page;
