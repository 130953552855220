import React from 'react'
import { Modal } from 'antd'
import './index.scss'
import { getTemplateContentText } from './utils'
const QRcode = require('qrcode.react')

const QrCodeModel = props => {
  const { isCodeVisible, templateId, capsuleDetailInfo, handleCancelCodeModal } = props
  const jsonContent = capsuleDetailInfo.jsonContent || {}
  const getQrcodeValue = () => {
    return ` ${templateId === 6 ? '发电量' : jsonContent.title || ''} ${getTemplateContentText(
      templateId,
      jsonContent
    )}`
  }
  const qrcodeValue = getQrcodeValue()
  return (
    <>
      <Modal
        visible={isCodeVisible}
        wrapClassName="QrCodeModal"
        width={228}
        maskClosable={true}
        onCancel={handleCancelCodeModal}
      >
        <QRcode
          value={`胶囊简述：${capsuleDetailInfo.occur_time || ''}${qrcodeValue}`}
          size={180}
        />
      </Modal>
    </>
  )
}
export default QrCodeModel
