import React, { useMemo } from 'react';
import style from './index.module.scss';
import ICON_ARROW from './res/arrow.svg';

const Comp = (props) => {
    const { icon, title, watchDetail, subtitle, watchDetailOnClick } = props;

    const iconUrl = useMemo(() => {
        if (typeof icon === 'string') {
            return icon;
        };
        return null;
    }, [icon]);

    return (
        <div className={style['wrapper']}>
            <div className="header">
                <div className="icon" style={typeof iconUrl === 'string' ? { backgroundImage: `url(${iconUrl})` } : {}}></div>
                {
                    typeof title === 'string' && (
                        <div className="title">{title}</div>
                    )
                }
                {
                    !!subtitle && (
                        <div className="subtitle_wrapper">{subtitle}</div>
                    )
                }
                {
                    watchDetail === true && (
                        <div
                            className="watch_detail"
                            {
                            ...(
                                typeof watchDetailOnClick === 'function' ? {
                                    onClick: watchDetailOnClick
                                } : {}
                            )
                            }
                        >
                            <div className="text">查看详情</div>
                            <img alt="" src={ICON_ARROW} className="icon" />
                        </div>
                    )
                }
            </div>
            <div className="line"></div>
            <div className="content">
                {props.children}
            </div>
        </div>
    );
};

export default Comp;
