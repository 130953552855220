import React, { useContext, useEffect, useMemo } from 'react';
import Tabs from 'components/Tabs';
import CommonContext from '../context';
import DataTable from '../DataTable';
import Report from '../Report';
import ChartTable from '../ChartTable';
import ElecTable from '../ElecTable'
import { time_key, timeRange_key } from '../constants';

const tabs = [
    {
        name: '图表',
        comp: ChartTable,
        timeType: time_key,
    },
    // {
    //     name: '数据表',
    //     comp: DataTable,
    //     timeType: time_key,
    // },
    // {
    //     name: '报表',
    //     comp: Report,
    //     timeType: timeRange_key,
    // },
    { name: '数据报表', comp: ElecTable, timeType: timeRange_key }
];

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const selectedTab = useMemo(() => {
        const { state: { selectedTab } } = commonInfo;
        return selectedTab;
    }, [commonInfo]);
    const selectedTabIndex = useMemo(() => {
        let index = tabs.indexOf(selectedTab);
        if (index === -1) return null;
        return index;
    }, [selectedTab]);
    useEffect(() => {
        // 此处逻辑扩展为了当没有被选中时，会选中第一个
        const { state: { selectedTab }, dispatch } = commonInfo;
        if (!selectedTab && tabs.length > 0) {
            dispatch({ type: 'tabSelect', selectedTab: tabs[0] });
        };
    }, [commonInfo]);
    const onChange = (index) => {
        const { dispatch } = commonInfo;
        dispatch({ type: 'tabSelect', selectedTab: tabs[index] });
    };

    return (
        <Tabs tabs={tabs} selectedIndex={selectedTabIndex} onChange={(index) => onChange(index)} />
    );
};

export default Comp;
