import Page from './MainPage.js';
import { connect } from 'react-redux';
import {
    setValues,
} from '../../../common/redux/MainPage/actions.js';

const updateTimeRange = (timeRange) => {
    return (dispatch, getState) => {
        dispatch(setValues({ timeRange }));
    };
};

const mapStateToProps = state => {
    const { mainPage } = state;
    const { timeRange } = mainPage;
    return {
        timeRange,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateTimeRange: (timeRange) => dispatch(updateTimeRange(timeRange)),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;