import moment from 'moment';
import { Suffix_List } from './constants';
import { significantNum } from 'common/utils';

const timeTypeList = [
    {
        type: 'date',
        basic_index: 0,
    },
    {
        type: 'week',
        basic_index: 2,
    },
    {
        type: 'month',
        basic_index: 4,
    }
];

const getSuffixIndexByTime = (timeType, time) => {
    return timeTypeList.reduce((pV, cItem) => {
        if (pV !== null) {
            return pV;
        };
        const { type, basic_index } = cItem;
        if (timeType === type) {
            // time 是否是moment偏移量，暂无检查
            return basic_index + (moment(time).isSame(moment(), type) ? 0 : 1);
        };
        return pV;
    }, null);
};

const getSuffix = (position, timeType, time) => {
    const index = getSuffixIndexByTime(timeType, time);
    if (index === null) { return '' };
    let suffix = '';
    try {
        suffix = Suffix_List[position][index]
    } catch (err) { };
    return suffix;
};

const mockRet = {
    data: {
        "errcode": 0,
        "result": {
            "LDRT": 0.9121109343482661,
            "OPHR": 67.76666666666677,
            "elchrg": 0.538416284274,
            "elprice": 0.6456119646433098,
            "kWRhz": 0.3878791,
            "kWz": 123.07432868522949,
            "x_lable": [
                "Mon, 06 Dec 2021 00:00:00 GMT"
            ],
            "y_LDRT": [
                0.9121109343482661
            ],
            "y_OPHR": [
                67.76666666666677
            ],
            "y_elchrg": [
                0.538416284274
            ],
            "y_elprice": [
                0.6456119646433098
            ],
            "y_kWRhz": [
                0.3878791
            ],
            "y_kWz": [
                123.07432868522949
            ]
        }
    }
}

const getCards = (obj) => {
    return [
        {
            name: '负荷率',
            unit: '',
            tag: 'LDRT',
            yKey: 'y_LDRT'
        }
        ,
        {
            name: '电费',
            unit: '万元',
            tag: 'elchrg',
            yKey: 'y_elchrg'
        }
        ,
        {
            name: '平均电价',
            unit: '元/kWh',
            tag: 'elprice',
            yKey: 'y_elprice'
        }
        ,
        {
            name: '运行时间',
            unit: 'hrs',
            tag: 'OPHR',
            yKey: 'y_OPHR'
        }
        ,
        {
            name: '最大需量',
            unit: 'mxkVA',
            tag: 'mxkVA',
            yKey: 'y_mxkVA'
        }
        ,
        {
            name: '有功功率',
            unit: 'kW',
            tag: 'kWz',
            yKey: 'y_kWz'
        }
    ]
        .map(item => {
            const { tag, yKey } = item
            const value = obj?.[tag] ?? null
            const pValue = typeof value === 'number' ? significantNum(value) : '--'
            const xs = obj?.x_lable ?? null
            const ys = obj?.[yKey] ?? null
            return Object.assign(item, { value, pValue, xs, ys })
        })
}

export {
    getSuffix,
    mockRet,
    getCards
};
