import React, { useCallback, useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import { fncs, renderChart } from './utils.js';
import useList from './hooks/useList/index.js'
import { Button, Modal, Spin } from 'antd';
import ModalView from './ModalView/index.js'
import List from './List/index.js'
import moment from 'moment'

const initFunc = () => (fncs?.[0] ?? null)
const initInfoFunc = () => null

const Page = (props) => {
    const [sysInfo, setSysInfo] = useState(initFunc);
    const [range, setRange] = useState(() => {
        const curr = moment()
        return [moment(curr).subtract(1, 'months'), moment(curr)]
    })

    const [list, loading] = useList(sysInfo, range)

    const [info, setInfo] = useState(initInfoFunc)
    const handleClick = useCallback(info => {
        console.log(info)
        setInfo(info)
    }, [])

    const handleCancel = useCallback(() => {
        setInfo(null)
    }, [])

    const [mInfo, setMInfo] = useState(initInfoFunc)

    const handleView = useCallback((index) => {
        return () => {
            console.log('handleView')
            if (sysInfo && info) {
                const mInfo = Object.assign({}, sysInfo, info, { index })
                setMInfo(mInfo)
                setInfo(null)
            }
        }
    }, [sysInfo, info])

    const handleMCancel = useCallback(() => {
        setMInfo(null)
    }, [])

    return (
        <div className={style['wrapper']}>
            <Header sysInfo={sysInfo} setSysInfo={setSysInfo} range={range} setRange={setRange} />
            <div className="content_wrapper scroll_bar_restyle">
                <div className='content'>
                    <List click={handleClick} list={list} renderFunc={renderChart} />
                </div>
            </div>
            {
                info && (
                    <Modal
                        visible={true}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <div>第{info?.count ?? '--'}炉</div>
                        <div>上次堵炉：{info?.start_time ?? "--"}</div>
                        <div>本次堵炉：{info?.end_time ?? "--"}</div>
                        <div style={{ marginTop: 12, display: 'flex', justifyContent: 'space-around' }}>
                            <Button type='primary' onClick={handleView(1)}>查看配料</Button>
                            <Button type='primary' onClick={handleView(2)}>查看炉况参数</Button>
                        </div>
                    </Modal>
                )
            }
            {
                mInfo && (
                    <ModalView
                        onCancel={handleMCancel}
                        info={mInfo}
                    />
                )
            }
            {
                loading === true && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin />
                    </div>
                )
            }
        </div>
    );
};

export default Page;
