import React from 'react';
import style from './index.module.scss';
import TopBreadcrumb from 'components/TopBreadCrumb';

const breadcrumbList = [{ name: '指标' }, { name: '指标预测' }];

const Comp = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className="left">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                {/* <div className="text">指标预测</div> */}
            </div>
            <div className="right">
            </div>
        </div>
    );
};

export default Comp;
