import React from 'react';
import { Modal, Table, Select } from 'antd';
import { get } from 'common/utils';
import store from 'common/redux/store.js';

// import './Content.css';
const { Option } = Select;
export default class EventsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            prevPropsVisible: props.visible,
            eventsSelected: [],
            dataSource: [],
            page_size: 10,
            page: 1,
            count: 0,
            loading: false,
        }
    }

    componentDidUpdate({ visible: prevPropsVisible }) {
        const { visible, initSelectedEvents: eventsSelected } = this.props;
        if (!prevPropsVisible && visible) {
            // console.log('componentDidUpdate', prevPropsVisible, visible);
            this.setState({
                page: 1,
                count: 0,
                eventsSelected,
                dataSource: [],
            }, this.getEvents);
        }
    }

    getEvents = () => {
        const { page, page_size } = this.state;
        const { selectedSysId: sys_id } = store.getState().mainPageV2;
        this.setState({ loading: true });
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/prod_events', {
            page,
            page_size,
            sys_id,
            // time_begin: '2020-02-20 00:00:00',
            // time_end: '2020-02-21 00:00:00',
        }).then(retData => {
            // console.log(retData);
            this.setState({ loading: false });
            if (retData.data && retData.data.results) {
                const { results, count } = retData.data;
                this.setState({
                    dataSource: results.map(item => ({ ...item, key: item.id })),
                    count,
                })
            }
        });
    }

    eventModalOnOk = () => {
        const { eventsSelected } = this.state;
        const { onOk = () => { } } = this.props;
        onOk(eventsSelected);
    }

    onSelectChange = selectedRowKeys => {
        const { eventsSelected, dataSource } = this.state;
        const allEvents = eventsSelected.concat(dataSource);
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ eventsSelected: selectedRowKeys.map(s_key => allEvents.filter(({ id }) => s_key === id)[0]) });
    }

    pageChange = (page, pageSize) => {
        // console.log(page, pageSize);
        this.setState({ page }, this.getEvents)
    }

    render() {
        const { eventsSelected, dataSource, page, page_size, count, loading } = this.state;
        const { visible, onCancel = () => { } } = this.props;
        const rowSelection = {
            selectedRowKeys: eventsSelected.map(({ id }) => id),
            onChange: this.onSelectChange,
        };
        return (
            <Modal
                title="事件添加"
                centered
                visible={visible}
                onOk={this.eventModalOnOk}
                onCancel={onCancel}
                width={1600}
            >
                <div className="issue_modal_wrapper">
                    <div className="header">
                        <Select defaultValue="1" style={{ width: 200 }}>
                            <Option value="1">全部状态</Option>
                        </Select>
                    </div>
                    <Table
                        rowSelection={rowSelection}
                        dataSource={dataSource}
                        columns={[
                            {
                                title: '事件名称',
                                dataIndex: 'name',
                            },
                            {
                                title: '事件描述与处理建议',
                                dataIndex: 'desp',
                                width: 700,
                            },
                            {
                                title: '记录人',
                                dataIndex: 'user_name',
                            },
                            {
                                title: '事件状态',
                                dataIndex: 'status',
                            },
                            {
                                title: '发生/结束时间',
                                key: 'sort',
                                render: (text, { time_begin, time_end }, index) => (
                                    <div>
                                        <div>{time_begin}</div>
                                        <div>{time_end}</div>
                                    </div>
                                )
                            },
                        ]}
                        pagination={{
                            current: page,
                            pageSize: page_size,
                            total: count,
                            onChange: this.pageChange
                        }}
                        size="small"
                        loading={loading}
                    />
                </div>
            </Modal>
        );
    }
}
