import React from 'react';
import style from './ChartModal.module.scss';
import CLOSE_ICON from './res/close.svg';

const echarts = require('echarts');
const moment = require('moment');

export default class ChartModal extends React.Component {

    constructor(props) {
        super(props);
        const { initialPosition: [clientX = 100, clientY = 100] = [] } = props;
        this.state = {
            x: clientX,
            y: clientY,
            isMoving: false,
            onMouseDownX: 0,
            onMouseDownY: 0,
            onMouseMoveX: 0,
            onMouseMoveY: 0,
        }
    }

    componentDidMount() {
        this.renderCharts();
    }

    componentDidUpdate({ hisTag2Value: prevHisTag2Value }) {
        const { hisTag2Value } = this.props;
        if (hisTag2Value !== prevHisTag2Value) {
            this.renderCharts();
        };
    }


    renderCharts = () => {
        const { record: { cur_value_tag: tag_name }, hisTag2Value } = this.props;
        if (this.refNode) {
            // console.log(hisTag2Value[tag_name]);
            const values = (hisTag2Value[tag_name] || [])
                .map(([time, value]) => [time * 1000, value]);
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            // const xData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            // const ySplitNum = 4;
            // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            //     .map(() => Math.random() * 100);
            const data = values
                .map(([_, value]) => value);
            //这里可能需要检验不同charts之间values time的同步问题
            let [dataMin, dataMax] = [Math.min(...data), Math.max(...data)];
            if (dataMin === dataMax) {
                dataMin--;
                dataMax++;
            };
            if (data.length === 0) {
                dataMax = 1;
                dataMin = -1;
            };
            const yMin = 2 * dataMin - dataMax;
            const yMax = 2 * dataMax - dataMin;
            const yMid = (dataMin + dataMax) / 2;
            const yMidUp = yMax - (yMax - yMin) * 11 / 100;
            const yMidDown = yMin + (yMax - yMin) * 11 / 100;
            const gridLeft = 48;
            instance.setOption({
                grid: {
                    left: gridLeft,
                    top: 0,
                    right: 0,
                    bottom: 0,
                },
                xAxis: {
                    type: 'time',
                    show: false,
                    boundaryGap: false,
                    // data: xData,
                },
                yAxis: {
                    show: false,
                    min: yMin,
                    max: yMax,
                },
                series: [
                    {
                        data: values,
                        type: 'line',
                        symbol: 'none',
                        smooth: true,
                        itemStyle: {
                            color: '#40A9FF',
                        },
                        lineStyle: {
                            width: 2,
                        },
                        markLine: {
                            silent: true,
                            animation: false,
                            symbol: ['none', 'none'],
                            data: [yMidUp, yMid, yMidDown]
                                .map(v => ({
                                    yAxis: v,
                                    label: {
                                        show: false,
                                    },
                                    lineStyle: {
                                        color: 'rgba(224,224,224,1)',
                                        type: 'solid',
                                    },
                                }))
                                .concat(
                                    [yMidUp, yMid, yMidDown]
                                        .map(v => ({
                                            yAxis: v,
                                            label: {
                                                position: 'start',
                                                formatter: () => Math.floor(v),
                                            },
                                            lineStyle: {
                                                color: 'rgba(158,158,168,1)',
                                                width: 0,
                                            },
                                        })),
                                ),
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        // console.log(params);
                        return params.map(({ marker, data: [axisValue, value] }, index) => (
                            `${index === 0 ? moment.unix(axisValue / 1000).format('YYYY-MM-DD HH:mm:ss') : ''}<br/>${marker}${Math.floor(value * 1000) / 1000}`
                        )).join('');
                    },
                    axisPointer: {
                        lineStyle: {
                            width: 2,
                            color: 'green',
                        },
                    },
                    position: ([posX, posY], [{ dataIndex = 0 } = {}], dom, rect, { viewSize: [viewWidth, viewHeight] }) => {
                        // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
                        const obj = {};
                        if (posY > viewHeight / 2) {
                            obj.bottom = viewHeight - posY;
                        } else {
                            obj.top = posY;
                        };
                        const rightWidth = (viewWidth - gridLeft) * (1 - dataIndex / (data.length - 1));
                        if (dataIndex < data.length / 2) {
                            obj.left = viewWidth - rightWidth + 15;
                        } else {
                            obj.right = rightWidth + 15;
                        };
                        return obj;
                    },
                },
            });
        };
    }

    onMouseDown = e => {
        const { clientX, clientY } = e;
        // console.log(clientX, clientY);
        this.setState({
            isMoving: true,
            onMouseDownX: clientX,
            onMouseDownY: clientY,
            onMouseMoveX: clientX,
            onMouseMoveY: clientY,
        });
    }

    onMouseUp = e => {
        const { clientX, clientY } = e;
        // console.log(clientX, clientY);
        const { x, y, onMouseDownX, onMouseDownY } = this.state;
        this.setState({
            isMoving: false,
            x: x + clientX - onMouseDownX,
            y: y + clientY - onMouseDownY,
        });
    }

    onMouseMove = e => {
        const { clientX, clientY } = e;
        // console.log(clientX, clientY);
        this.setState({
            onMouseMoveX: clientX,
            onMouseMoveY: clientY,
        });
    }

    onMouseLeave = e => {
        // console.log("onMouseLeave");
        const { x, y, onMouseDownX, onMouseDownY, onMouseMoveX, onMouseMoveY, isMoving } = this.state;
        if (isMoving) {
            this.setState({
                isMoving: false,
                x: x + onMouseMoveX - onMouseDownX,
                y: y + onMouseMoveY - onMouseDownY,
            });
        };
    }

    refFunc = refNode => {
        if (!!refNode) {
            this.refNode = refNode;
        };
    }

    render() {
        const { record: { name, zone_cut_point, zone_color, cur_value_tag, unit }, tag2Value } = this.props;
        const { onCancel = () => { } } = this.props;
        const { isMoving, onMouseDownX, onMouseDownY, onMouseMoveX, onMouseMoveY } = this.state;
        let { x, y } = this.state;
        if (isMoving) {
            x += (onMouseMoveX - onMouseDownX);
            y += (onMouseMoveY - onMouseDownY);
        };
        return (
            <div
                className={style["new_main_page_chart_modal_wrapper"]}
                style={{
                    left: x,
                    top: y,
                }}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="title_wrapper">
                    <div className="title">{name}</div>
                    <img alt="" src={CLOSE_ICON} width="12" height="12" onClick={onCancel} />
                </div>
                <div className="chart_wrapper" ref={this.refFunc}></div>
            </div>
        );
    }
}
