import React from 'react';
import { withRouter } from 'react-router';
import { post, get, getCachedUserInfo } from '../../../common/utils';
import { Button, Input, message } from 'antd';
import {
    DoubleRightOutlined,
    DoubleLeftOutlined,
    ExclamationCircleFilled,
    EyeTwoTone,
    EyeInvisibleOutlined,
} from '@ant-design/icons';
import { getMyPermissions } from '../../../common/AuthPermission.js';
import md5 from 'md5';

import './Content.scss';

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            myPermissions: getMyPermissions(),
            changePWDVisible: false,
            originPWD: '',
            newPWD: '',
            reNewPWD: '',
            errorHint: null,
            name: '',
            hasLogin: false,
            roles: [],
        };
    }

    componentDidMount() {
        const userInfo = getCachedUserInfo();
        if (!userInfo) {
            message.error('用户尚未登陆！');
            return;
        };
        const { name, user_id } = userInfo;
        this.setState({ name, hasLogin: true });
        this.getRoles(user_id);
        // console.log(userInfo);
    }

    getRoles = (user_id) => {
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/user/users', {
            page: 1,
            page_size: 20,
            ids: user_id + '',
        }).then((retData) => {
            if (retData.data && retData.data.results) {
                self.setState({
                    roles: (retData.data.results
                        .filter(({ id }) => id === user_id)[0] || {})
                        .roles || [],
                });
            };
        });
    }

    cancelAction = () => {
        this.setState({
            changePWDVisible: false,
            originPWD: '',
            newPWD: '',
            reNewPWD: '',
            errorHint: null,
        });
    }

    okBtn = () => {
        const { newPWD, reNewPWD } = this.state;
        if (!newPWD) {
            this.setState({ errorHint: 2 });
            return;
        } else if (newPWD.length < 6 || newPWD.length > 10) {
            this.setState({ errorHint: 2 });
            return;
        } else if (newPWD !== reNewPWD) {
            this.setState({ errorHint: 3 });
            return;
        };
        this.saveNewPWD();
    }

    saveNewPWD = () => {
        const { originPWD, newPWD } = this.state;
        post(process.env.REACT_APP_SERVER_PATH + 'api/auth/user/change_self_password', {
            orig_pwd: md5(originPWD),
            new_pwd: md5(newPWD),
        })
            .then(retData => {
                // console.log(retData);
                if (retData.data) {
                    if (retData.data.errcode === 0) {
                        message.success('密码修改成功，请重新登陆');
                        setTimeout(this.logout, 1000);
                    } else {
                        const { msg, errcode } = retData.data;
                        message.error(msg);
                        if (errcode === 5002) {
                            this.setState({ errorHint: 1 });
                        };
                    };
                } else {
                    message.error('修改失败！');
                };
            });
    }

    logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push('/Login');
    }

    render() {
        const { name, changePWDVisible, originPWD, newPWD, reNewPWD, errorHint, myPermissions, hasLogin, roles } = this.state;
        if (hasLogin === false) {
            return null;
        };
        return (
            <div className="personal_info_content_wrapper">
                <div className="block_wrapper">
                    {/* <div className="header">一级菜单 / 二级菜单 / 三级菜单</div> */}
                    <div className="content">
                        <div className="left">
                            {/* <div className="menu">基本设置</div> */}
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="row_title">用户名</div>
                                <Input
                                    value={name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                    style={{ width: 240 }}
                                    disabled
                                />
                            </div>
                            <div className="row">
                                <div className="row_title">角色</div>
                                <div>
                                    {
                                        roles
                                            .map(({ name }) => name)
                                            .join('，')
                                    }
                                </div>
                            </div>
                            {
                                myPermissions.includes('personal_conf/modify_pwd') && (
                                    <div className="row">
                                        <div className="row_title row_title_action">操作</div>
                                        <div className="arrow_down" onClick={() => this.setState({ changePWDVisible: !changePWDVisible })}>
                                            {
                                                changePWDVisible ?
                                                    (
                                                        <DoubleLeftOutlined />
                                                    ) : (
                                                        <DoubleRightOutlined />
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            {
                                changePWDVisible && (
                                    <div className="change_pwd_wrapper">
                                        <div className="row">
                                            <div className="row_title">原密码</div>
                                            <div className="row_right">
                                                <Input.Password
                                                    placeholder="请输入原密码"
                                                    value={originPWD}
                                                    onChange={e => this.setState({ originPWD: e.target.value })}
                                                    style={{ width: 240 }}
                                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                                {
                                                    errorHint === 1 && (
                                                        <div className="hint_wrapper"><ExclamationCircleFilled style={{ fontSize: 10 }} /><span>原密码输入错误</span></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row_title">新密码</div>
                                            <div className="row_right">
                                                <Input.Password
                                                    placeholder="请输入新密码"
                                                    value={newPWD}
                                                    onChange={e => this.setState({ newPWD: e.target.value })}
                                                    style={{ width: 240 }}
                                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                                {
                                                    errorHint === 2 && (
                                                        <div className="hint_wrapper"><ExclamationCircleFilled style={{ fontSize: 10 }} /><span>密码必须为6-10个字符</span></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row_title">重复新密码</div>
                                            <div className="row_right">
                                                <Input.Password
                                                    placeholder="请重复输入新密码"
                                                    value={reNewPWD}
                                                    onChange={e => this.setState({ reNewPWD: e.target.value })}
                                                    style={{ width: 240 }}
                                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                />
                                                {
                                                    errorHint === 3 && (
                                                        <div className="hint_wrapper"><ExclamationCircleFilled style={{ fontSize: 10 }} /><span>两次输入密码不一致</span></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <Button style={{ marginLeft: 'auto' }} onClick={this.cancelAction}>取消</Button>
                                            <Button style={{ marginLeft: 12 }} onClick={this.okBtn}>确认</Button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Content);