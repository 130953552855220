import React from 'react';
import { Calendar as AntdCalendar } from 'antd';
import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import style from './Calendar.module.scss';

const moment = require('moment');

export default class Calendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customDisplay: false,
            customDate: null,
        };
    }

    componentDidMount() {
    }

    onSelect = date => {
        // console.log('onSelect', date.format('YYYY-MM-DD'));
        this.setState({
            customDisplay: false,
            customDate: null,
        });
        const { onSelect, getHistoryData, getStatus, getEvents } = this.props;
        onSelect(date);
        getHistoryData();
        getStatus();
        getEvents();
    }

    calendarOnchange = (delta, key) => {
        let { customDate } = this.state;
        const { date } = this.props;
        if (!customDate) {
            customDate = moment(date);
        }
        this.setState({
            customDisplay: true,
            customDate: customDate.add(delta, key),
        });
    }

    render() {
        const { customDisplay, customDate } = this.state;
        const { date } = this.props;
        const { } = this.props;
        const displayDate = customDisplay ? customDate : date;
        return (
            <div className={style["sample_maintenance_calendar_wrapper"]}>
                <AntdCalendar
                    value={displayDate}
                    fullscreen={false}
                    headerRender={({ value, type, onChange, onTypeChange }) => {
                        return date ?
                            (
                                <div className="custom_header">
                                    <DoubleLeftOutlined onClick={() => this.calendarOnchange(-1, 'years')} style={{ marginRight: 12 }} />
                                    <LeftOutlined onClick={() => this.calendarOnchange(-1, 'months')} />
                                    <div className="text">{displayDate.format('YYYY年 M月')}</div>
                                    <RightOutlined onClick={() => this.calendarOnchange(1, 'months')} />
                                    <DoubleRightOutlined onClick={() => this.calendarOnchange(1, 'years')} style={{ marginLeft: 12 }} />
                                </div>
                            ) : (
                                <div className="custom_header" />
                            )
                    }}
                    onSelect={this.onSelect}
                    dateFullCellRender={d => (
                        <div className={"cus_date_wrapper" + (date && date.isSame(d, 'day') ? ' cus_date_wrapper_selected' : '')}>
                            <div className="text">{d.format('DD')}</div>
                        </div>
                    )}
                />
                <div className="bottom_wrapper" onClick={() => this.onSelect(moment())}>今天</div>
            </div>
        );
    }
}
