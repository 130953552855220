import React from 'react';
import { Select, InputNumber } from 'antd';
import style from './DetailRankBlock.module.scss';
import { RANK_METHODS } from '../config.js';
import {
    PlusCircleOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

const Option = Select.Option;
const MAX_RANK_NUMBER = 8;
const MIN_RANK_NUMBER = 4;

export default class DetailRankBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rankNumber: MIN_RANK_NUMBER,
        };
    }

    rankValueOnChange = (v, index) => {
        const { rankList, rankListOnChange = () => { } } = this.props;
        rankListOnChange(Object.assign(Array(MAX_RANK_NUMBER).fill(0), rankList, { [index]: v }));
    }

    addRankNumber = renderRankNumber => {
        this.setState({
            rankNumber: renderRankNumber + 1,
        });
    }

    deleteRank = (index, renderRankNumber) => {
        const { rankList, rankListOnChange = () => { } } = this.props;
        const newRankList = Object.assign(Array(MAX_RANK_NUMBER).fill(0), rankList);
        newRankList.splice(index, 1);
        newRankList.push(0);
        rankListOnChange(newRankList);
        this.setState({
            rankNumber: Math.max(renderRankNumber - 1, MIN_RANK_NUMBER),
        });
    }

    render() {
        const { rankMethodSelected, rankMethodOnChange = () => { }, rankList = [], showRankMethod = false } = this.props;
        const { rankNumber } = this.state;
        const propRankNumber = rankList
            .reduce((lastNotZero, value, index) => (
                !!value ? index : lastNotZero
            ), -1) + 1;
        const renderRankNumber = Math.max(propRankNumber, rankNumber);
        return (
            <div className={style["detail_rank_block_wrapper"]}>
                {
                    showRankMethod && (
                        <div className="row">
                            <div className="row_left">排名方法：</div>
                            <div
                                className="row_right"
                                style={{
                                    width: 180
                                }}
                            >
                                <Select
                                    style={{ width: 140 }}
                                    value={rankMethodSelected}
                                    onChange={rankMethodOnChange}
                                >
                                    {
                                        RANK_METHODS.map(({ id, name }) =>
                                            <Option key={id}>{name}</Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                    )
                }
                <div className="row">
                    <div className="row_left">排名计分规则：</div>
                </div>
                {
                    Array(renderRankNumber).fill(0).map((v, index) => (
                        <div className="row" key={index}>
                            <div className="row_left">第{index + 1}名：</div>
                            <div className="row_right">
                                <InputNumber min={0} max={100} value={rankList[index] || 0} onChange={v => this.rankValueOnChange(v, index)} style={{ width: 80 }} />
                                <DeleteOutlined
                                    style={{ fontSize: 20 }}
                                    onClick={() => this.deleteRank(index, renderRankNumber)}
                                />
                            </div>
                        </div>
                    ))
                }
                {
                    renderRankNumber < MAX_RANK_NUMBER && (
                        <div className="add_icon_wrapper">
                            <PlusCircleOutlined
                                style={{ fontSize: 20, color: 'blue' }}
                                onClick={() => this.addRankNumber(renderRankNumber)}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}