import { useEffect, useState } from 'react';
import { post } from 'common/utils';
import { colors } from './utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, product_id, range } = conf;
            if (typeof time !== 'string' || typeof product_id !== 'number') {
                setResult(null);
                return;
            };
            let requesting = true;
            setLoading(true);
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_product_cost_structure', {
                time_type, time: time_start, product_id,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data;

                            let total_cost = null;
                            let parsed_results = nextResult?.results;
                            if (!!parsed_results) {
                                // sort
                                // cost text
                                // add color
                                parsed_results = []
                                    .concat(parsed_results)
                                    .sort(({ cost: cost_1 }, { cost: cost_2 }) => cost_2 - cost_1)
                                    .map((item) => Object.assign({}, item, { cost_text: typeof item.cost === 'number' ? Math.floor(item.cost * 10) / 10 : "--" }))
                                    .map((item, index) => Object.assign({}, item, { color: colors[index % colors.length] }));
                                // all cost
                                const all_costs = parsed_results.map(({ cost }) => cost).filter((v) => typeof v === 'number');
                                if (all_costs.length === parsed_results.length) {
                                    total_cost = all_costs.reduce((pV, cV) => pV + cV, 0);
                                };
                                // cost rate text
                                parsed_results = parsed_results
                                    .map((item) => {
                                        let rate_text = '--';
                                        if (typeof total_cost === 'number') {
                                            rate_text = (Math.floor(item.cost / total_cost * 1000) / 10);
                                        };
                                        return Object.assign({}, item, { rate_text });
                                    });
                            };

                            const total_cost_text = typeof total_cost === 'number' ? Math.floor(total_cost * 10) / 10 : "--";

                            let grouped_parsed_results = null;
                            if (!!parsed_results) {
                                const datas_1 = parsed_results.filter((v, index, arr) => index < Math.ceil(arr.length / 2));
                                const datas_2 = parsed_results.filter((v, index, arr) => index >= Math.ceil(arr.length / 2));
                                grouped_parsed_results = [datas_1, datas_2];
                            };

                            let parsed_basic_info = nextResult?.basic_info;
                            if (!!parsed_basic_info) {
                                parsed_basic_info = Object.assign({}, parsed_basic_info, {
                                    time: moment(time).format("YYYY/MM/DD"),
                                });
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_results,
                                total_cost,
                                grouped_parsed_results,
                                total_cost_text,
                                parsed_basic_info,
                            });
                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
