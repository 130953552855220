import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import ICON_4 from '../res/single_4.png';
import ICON_ROW_ICON from '../res/icon_1.png';
import ICON_STAR from '../res/star.png';
import Wrapper from '../../Common/Wrapper.js';

import './ShiftCompareModal.scss';

const Comp = (props) => {
    const { onCancel = () => { }, selectedIds } = props;
    const [shiftDetails, setShiftDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchData = (ids) => {
        setLoading(true);
        const { getShiftDetails } = props;
        getShiftDetails(ids)
            .then(retData => {
                setLoading(false);
                const { isSuccess, results } = retData;
                if (isSuccess) {
                    setShiftDetails(results);
                };
            });
    };
    useEffect(() => {
        fetchData(selectedIds);
        // eslint-disable-next-line
    }, [selectedIds]);
    return (
        <div style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, zIndex: 1000 }} className="lnJhIaRwzm">
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 1)', opacity: 0.7 }} onClick={onCancel} />
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: 946 }}>
                <Wrapper icon={ICON_4} title="班组对比" onCancel={onCancel}>
                    <div style={{ position: 'relative', minHeight: 60 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt="" src={ICON_ROW_ICON} width="2" height="16" />
                            <div style={{ marginLeft: 8, fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>统计时段</div>
                            <div style={{ marginLeft: 4, fontSize: 12, height: 12, lineHeight: '12px', color: '#9FA0BA' }}>【 2020年12月20日-2020年12月26日 】</div>
                            <div style={{ marginLeft: 40, fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>统计方法</div>
                            <div style={{ marginLeft: 8, width: 52, height: 24, border: '1px solid #3DCDB9', borderRadius: 4, backgroundColor: '#343D47', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                <div style={{ fontSize: 14, lineHeight: '14px', height: 14, color: '#44F3DA' }}>班组</div>
                            </div>
                        </div>
                        <div className="wrapper_pos_block">
                            <div style={{ marginTop: 12, backgroundColor: '#191F27' }} className="table_wrapper scroll_bar_restyle">
                                <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: "0 0" }}>
                                    <thead>
                                        <tr style={{ color: '#9FA0BA' }}>
                                            <th style={{ padding: "8px 0 8px 12px" }}>产品类型</th>
                                            <th>指标名称</th>
                                            <th style={{ paddingLeft: 40 }}>基准值</th>
                                            <th>当前值(晚班)</th>
                                            <th>当前值(中班)</th>
                                            <th>当前值(早班)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !!shiftDetails &&
                                            shiftDetails
                                                .map((item, index) => {
                                                    const {
                                                        factor_name, ref_value, exp_cpl_rate,
                                                        shift_1_avg, shift_1_cpl_rate,
                                                        shift_2_avg, shift_2_cpl_rate,
                                                        shift_3_avg, shift_3_cpl_rate,
                                                    } = item;
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ paddingLeft: 12 }}>
                                                                <div style={{ width: 57, height: 20, border: '1px solid #FFD94E', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#FFD94E', fontSize: 12, lineHeight: '12px' }}>普通生料</div>
                                                            </td>
                                                            <td>
                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <img alt="" src={ICON_STAR} width="20" height="20" />
                                                                    <div style={{ marginLeft: 4, height: 14, color: '#FFFFFF', fontSize: 14, lineHeight: '14px' }}>{factor_name}</div>
                                                                </div>
                                                            </td>
                                                            <td style={{ padding: "8px 0" }}>
                                                                <div>
                                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                                        <div style={{ width: 88, color: '#9FA0BA' }}>数值</div>
                                                                        <div>{ref_value && Math.floor(ref_value * 10) / 10}</div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                                        <div style={{ width: 88, color: '#9FA0BA' }}>KPR</div>
                                                                        <div>{exp_cpl_rate && Math.floor(exp_cpl_rate * 1000) / 10}%</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {/* <div>21t/h</div> */}
                                                                    <div>{shift_1_avg || '-'}</div>
                                                                    <div>{shift_1_cpl_rate && Math.floor(parseFloat(shift_1_cpl_rate) * 1000) / 10}%</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>{shift_2_avg || '-'}</div>
                                                                    <div>{shift_2_cpl_rate && Math.floor(parseFloat(shift_2_cpl_rate) * 1000) / 10}%</div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>{shift_3_avg || '-'}</div>
                                                                    <div>{shift_3_cpl_rate && Math.floor(parseFloat(shift_3_cpl_rate) * 1000) / 10}%</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {
                            loading && (
                                <div style={{ position: 'absolute', zIndex: 1000, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                                    <Spin tip="加载中..." />
                                </div>
                            )
                        }
                    </div>
                </Wrapper>
            </div>
        </div>
    );
};

export default Comp;
