import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss'; // has problem
import { Spin } from 'antd';
import {
    CommonObj, scenesInfo, sceneSwitch, render3D, switch2Scene, getProjectCoord,
} from './utils';
import ToolBar from './ToolBar';

const getNextCardPositions = (refNode) => {
    if (!refNode) return null;
    const halfWidth = refNode.offsetWidth / 2;
    const halfHeight = refNode.offsetHeight / 2;
    const vec3s = getProjectCoord();
    if (!vec3s) return null;
    return vec3s.map((vec3) => {
        const { x, y } = vec3;
        return {
            x: x * halfWidth + halfWidth,
            y: y * halfHeight * (-1) + halfHeight,
        }
    });
};

const Comp = () => {
    const chartRef = useRef(null);
    const wrapperRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [percentComplete, setPercentComplete] = useState(0);
    const [currScene, setCurrScene] = useState(null);
    const [refreshTick, setRefreshTick] = useState(null);
    const [status, setStatus] = useState(null); // 1:AutoPlay, 2:pause, 3:CustomControl
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [cardPositions, setCardPositions] = useState(null);

    // refresh data sign every 10 seconds
    useEffect(() => {
        setRefreshTick(Symbol());
        const mySetInterval = setInterval(() => {
            setRefreshTick(Symbol());
        }, 10000);
        return () => {
            clearInterval(mySetInterval);
        };
    }, []);

    useEffect(() => {
        const onProgress = (xhr) => {
            if (xhr.lengthComputable) {
                const nextPercentComplete = xhr.loaded / xhr.total * 100;
                console.log(nextPercentComplete + '% downloaded');
                setPercentComplete(nextPercentComplete);
            };
        };
        const onLoad = () => {
            setLoading(false);
            setCurrScene(scenesInfo[0]);
            setStatus(1);
        };
        setLoading(true);
        if (!!chartRef.current) {
            render3D(chartRef.current, scenesInfo[0].position, onProgress, null, onLoad);
        };
        return () => {
            clearTimeout(CommonObj.mySetTimeout);
        };
    }, []);

    const onLoadOver = () => {
        const index = scenesInfo.indexOf(currScene);
        setCurrScene(null); // 因为旋转过程中不显示上次的内容
        if (index > -1 && index < scenesInfo.length - 1) {
            sceneSwitch(CommonObj.camera, scenesInfo[index].position, scenesInfo[index + 1].position, {// switch over
                cb: () => {
                    console.log("switch over");
                    setCurrScene(scenesInfo[index + 1]);
                },
            }, scenesInfo[index].switchDur);
        } else if (index === scenesInfo.length - 1) {
            sceneSwitch(CommonObj.camera, scenesInfo[index].position, scenesInfo[0].position, {// switch over
                cb: () => {
                    console.log("switch over");
                    setCurrScene(scenesInfo[0]);
                },
            }, scenesInfo[index].switchDur);
        };
    };

    const quitPlay = () => {
        clearTimeout(CommonObj.mySetTimeout);
        setCurrScene(null);
        CommonObj.controls.enabled = true;
        setStatus(3);
    };

    const replayOnClick = () => {
        CommonObj.controls.enabled = false;
        setStatus(1);
        setTimeout(() => {
            switch2Scene(CommonObj.camera, scenesInfo[0].position, {// switch over
                cb: () => {
                    // console.log("switch over");
                    setCurrScene(scenesInfo[0]);
                },
            });
        }, 0);
    };

    const pauseOnClick = () => {
        setStatus(2);
        if (!!CommonObj.customTimeOut) {
            CommonObj.customTimeOut.pause();
        };
    };

    const continueOnClick = () => {
        setStatus(1);
        if (!!CommonObj.customTimeOut) {
            CommonObj.customTimeOut.play();
        };
    };

    const jump2Scene = (scene) => {
        if (!scene) return;
        CommonObj.controls.enabled = false;
        clearTimeout(CommonObj.mySetTimeout);
        setCurrScene(null);
        setStatus(1);
        setTimeout(() => {
            switch2Scene(CommonObj.camera, scene.position, {// switch over
                cb: () => {
                    // console.log("switch over");
                    setCurrScene(scene);
                },
            });
        }, 0);
    };

    const exitFullScreen = () => {
        setIsFullScreen(false);
        if (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement) {
            // can use exitFullscreen
            if (!!document.exitFullscreen) {
                document.exitFullscreen();
            } else if (!!document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (!!document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (!!document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            };
        };
    };
    const enterFullScreen = () => {
        setIsFullScreen(true);
        const dom = wrapperRef.current;
        if (!!dom) {
            if (!!dom.requestFullscreen) {
                dom.requestFullscreen();
            } else if (!!dom.msRequestFullscreen) {
                dom.msRequestFullscreen();
            } else if (!!dom.mozRequestFullScreen) {
                dom.mozRequestFullScreen();
            } else if (!!dom.webkitRequestFullScreen) {
                dom.webkitRequestFullScreen();
            };
        };
    };

    const iframeRef = useRef(null);
    useEffect(() => {
        if (!!iframeRef.current) {
            const iframeWindow = iframeRef.current.contentWindow;
            iframeWindow.addEventListener('resize', () => {
                if (!!chartRef.current) {
                    CommonObj.renderer.setSize(chartRef.current.offsetWidth, chartRef.current.offsetHeight);
                    CommonObj.camera.aspect = chartRef.current.offsetWidth / chartRef.current.offsetHeight;
                    CommonObj.camera.updateProjectionMatrix();
                    setCardPositions(getNextCardPositions(chartRef.current));
                };
                if (document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement) {
                    setIsFullScreen(true);
                } else {
                    setIsFullScreen(false);
                };
            });
        };
    }, []);

    const onLoad = () => {
        setCardPositions(getNextCardPositions(chartRef.current));
    };

    const percentCompleteText = useMemo(() => {
        if (typeof percentComplete === 'number') {
            return `${Math.round(percentComplete, 2)}%`;
        };
        return '加载中...';
    }, [percentComplete]);
    return (
        <div className={style['wrapper']} ref={wrapperRef}>
            <div className="iframe_wrapper">
                <iframe ref={iframeRef} title="dom resize"></iframe>
            </div>
            <div className="three_chart_block" ref={chartRef} />
            {
                !!currScene && !!currScene.comp && (
                    <div className="outer_wrapper">
                        <currScene.comp sceneInfo={currScene} onLoadOver={onLoadOver} refreshTick={refreshTick} cardPositions={cardPositions} onLoad={onLoad} />
                    </div>
                )
            }
            {
                !loading && typeof status === 'number' && (
                    <div className="control_bar_wrapper">
                        <ToolBar
                            quitPlay={quitPlay} jump2Scene={jump2Scene} currScene={currScene} status={status}
                            pauseOnClick={pauseOnClick} continueOnClick={continueOnClick} replayOnClick={replayOnClick}
                            isFullScreen={isFullScreen}
                            exitFullScreen={exitFullScreen}
                            enterFullScreen={enterFullScreen}
                        />
                    </div>
                )
            }
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                        <Spin tip={percentCompleteText} />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
