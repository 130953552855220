import Page from './FirstBlock.js';
import { connect } from 'react-redux';
import { getCustomKPIList, updateFavouriteKPI } from '../../Common/utils.js';

const mapStateToProps = state => {
    const { factorSummaryV2, system } = state;
    const { systems, themes } = factorSummaryV2;
    const { customFactors } = system;
    return {
        systems,
        themes,
        customFactors,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        add2FavouriteKPI: (id) => dispatch(updateFavouriteKPI(id, 1)),
        deleteFromFavouriteKPI: (id) => dispatch(updateFavouriteKPI(id, 0)),
        getCustomKPIList: () => dispatch(getCustomKPIList),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;