import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';
import { CheckIsColor } from './utils.js';
import moment from 'moment';

const default_lineC = 'rgba(64,161,225,1)';
const default_symbolShowCircle = true;
const default_lineWidth = 2;
const defaultLineData = new Array(10)
    .fill(0)
    .map((v, index) => {
        return [moment().add(index, "minutes"), Math.floor(Math.random() * 100)];
    });

const Comp = (props) => {
    const { lineColor, point, lineWidth, layoutChange, title, lineData } = props;
    const lineC = useMemo(() => {
        return CheckIsColor(lineColor) ? lineColor : default_lineC;// (CheckIsColor(lineColor) ? lineColor : default_lineC).replace(/\s*/g, "");
    }, [lineColor]);
    const [winResize, setWinResize] = useState(null);
    const symbolShowCircle = useMemo(() => {
        return (typeof point === 'boolean') ? point : default_symbolShowCircle;
    }, [point]);
    const lineW = useMemo(() => {
        return (typeof lineWidth === 'number') ? lineWidth : default_lineWidth;
    }, [lineWidth]);
    useEffect(() => {
        const func = () => {
            try {
                setWinResize(Symbol());
            } catch (e) { };
        };
        window.addEventListener("resize", func);
        return () => { window.removeEventListener("resize", func) };
    }, []);
    useEffect(() => {
        // 外部echart size变化;
        if (!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!!instance) {
                instance.resize();
            };
        };
    }, [layoutChange, title, winResize]);
    const chartNode = useRef(null);
    const renderChart = useCallback(() => {
        if (!!chartNode.current) {
            // render chart
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            } else {
                instance.clear();
            };

            instance.setOption({
                animation: false,
                grid: {
                    left: 40,
                    top: 10,
                    right: 30,
                    bottom: 35,
                },
                xAxis: {
                    type: 'time',
                    // data: xData,
                    axisLine: {
                        // show: false,
                        lineStyle: {
                            color: 'rgba(0,0,0,0.2)',
                        },
                    },
                    axisLabel: {
                        // show: false,
                        fontSize: 11,
                        color: 'rgba(0,0,0,0.8)',
                    },
                },
                yAxis: {
                    type: 'value',
                    scale: true,// true时不一定显示0
                    axisLine: {
                        // show: false,
                        lineStyle: {
                            color: 'rgba(0,0,0,0.2)',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,0.2)',
                        },
                    },
                    axisLabel: {
                        // show: false,
                        fontSize: 11,
                        color: 'rgba(0,0,0,0.8)',
                    },
                },
                series: [
                    {
                        data: (lineData || defaultLineData)
                            .filter(([_, v]) => v !== null)
                            .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                        // data: yData,
                        type: 'line',
                        itemStyle: {
                            color: lineC,
                        },
                        symbol: symbolShowCircle ? 'emptyCircle' : 'none',
                        lineStyle: {
                            width: lineW,
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: lineC },
                                    { offset: 1, color: 'transparent' },
                                ],
                            ),
                        },
                    },
                ],
            });
        };
    }, [lineC, symbolShowCircle, lineData, lineW]);
    useEffect(() => {
        renderChart();
    }, [renderChart]);
    // const greaterOnScroll = (e) => {
    //     console.log('greaterOnScroll', e.target.scrollTop, e.target.scrollLeft);
    // };
    const scrollGreaterRef = useRef(null);
    // const smallerOnScroll = (e) => {
    //     console.log('smallerOnScroll', e.target.scrollTop, e.target.scrollLeft);
    // };
    const scrollSmallerRef = useRef(null);
    useEffect(() => {
        if (!!scrollGreaterRef.current) {
            scrollGreaterRef.current.scrollTop = 10000;
            scrollGreaterRef.current.scrollLeft = 10000;
        };
        if (!!scrollSmallerRef.current) {
            scrollSmallerRef.current.scrollTop = 10000;
            scrollSmallerRef.current.scrollLeft = 10000;
        };
    }, []);
    return (
        <div className={style['wrapper']}>
            <div style={{ height: 0, flexGrow: 1 }}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {
                        !!title && (
                            <div style={{ height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{title}</div>
                        )
                    }
                    <div style={{ height: 0, flexGrow: 1 }}>
                        <div ref={chartNode} style={{ height: '100%', overflow: 'hidden' }} />
                    </div>
                </div>
            </div>
            {/* <div
                style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, overflow: 'scroll' }}
                onScroll={greaterOnScroll}
                ref={scrollGreaterRef}
            >
                <div style={{ height: 3000, width: 3000, backgroundColor: 'blue' }} />
            </div> */}
            {/* <div
                style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, overflow: 'scroll' }}
                onScroll={smallerOnScroll}
                ref={scrollSmallerRef}
            >
                <div style={{ height: '200%', width: '200%', backgroundColor: 'blue' }} />
            </div> */}
        </div>
    );
};

export default Comp;
