import React, { useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import Table from './Table';

const Page = (props) => {
    const [timeInfo, setTimeInfo] = useState(null);
    return (
        <div className={style['wrapper']}>
            <div className="header_wrapper">
                <Header timeInfo={timeInfo} setTimeInfo={setTimeInfo} />
            </div>
            <div className="content_wrapper">
                <div className="inner_wrapper">
                    <Table timeInfo={timeInfo} />
                </div>
            </div>
        </div>
    );
};

export default Page;
