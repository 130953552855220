import BoardSecond from './BoardSecond.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { mainPageV2: { sys_id, layout, tag2Value, oneResult } } = state;
    let panel_2 = [];
    if (!!layout) {
        panel_2 = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0].panel_2;
    };
    let createTime = null;
    let execStatusText = '';
    if (oneResult) {
        const { create_time, result: { kpi_result: { opt_result } } } = oneResult;
        createTime = create_time;
        if (opt_result == 1) {
            execStatusText = '寻优成功';
        } else if (opt_result == 0) {
            execStatusText = '寻优失败';
        };
    };
    return {
        sys_id,
        panel_2,
        tag2Value,
        createTime,
        execStatusText,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

const WrapperedBoardSecond = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoardSecond);

export default WrapperedBoardSecond;