const defaultItemConf = {
    type: 'line',
    color: '#40A1E1',
};

const defaultYAxis = {
    upper_limit: null,
    lower_limit: null,
    "unit": null
};

const intervals = [
    {
        name: '所有',
        value: 'all'
    },
    {
        name: '1分钟',
        value: '1m'
    },
    {
        name: '15分钟',
        value: '15m'
    },
    {
        name: '1小时',
        value: '1h'
    },
    {
        name: '1天',
        value: '1d'
    }
]

const rules = [
    {
        name: '最大值',
        value: 'max'
    },
    {
        name: '最小值',
        value: 'min'
    },
    {
        name: '平均值',
        value: 'mean'
    },
    {
        name: '极差值',
        value: 'spread'
    },
    {
        name: '末位值',
        value: 'last'
    },
    {
        name: '首位值',
        value: 'first'
    },
    {
        name: '总和值',
        value: 'sum'
    },
    {
        name: '积分值',
        value: 'cumsum'
    },
    {
        name: '增量值',
        value: 'increase'
    },
    {
        name: '差分值',
        value: 'diff'
    }
]

export {
    defaultItemConf,
    defaultYAxis,
    intervals, rules
};
