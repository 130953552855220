
const group_A_tags = {
    tag_1: '_HD',
    tag_2: '_HW',
    tag_3: '_HM',
    tag_4: '_HDQOQ',
    tag_5: '_HWQOQ',
    tag_6: '_HWQOQ',
};

const group_A = {
    name: '产量完成指标',
    children: [
        {
            name: '原料气处理总量',
            unit: '万Nm³',
            basic_tag: 'PLANT_RAWG_RAW_CUM',
            ...group_A_tags,
        },
        {
            name: '天然气产量',
            unit: '万Nm³',
            basic_tag: 'PLANT_CNG_PRDT_CUM',
            ...group_A_tags,
        },
        {
            name: '乙烷产量',
            unit: '万Nm³',
            basic_tag: 'PLANT_C2H6_PRDT_CUM',
            ...group_A_tags,
        },
        {
            name: '丙烷产量',
            unit: 'ton',
            basic_tag: 'PLANT_C3H8_PRDT_CUM',
            ...group_A_tags,
        },
        {
            name: '丁烷产量',
            unit: 'ton',
            basic_tag: 'PLANT_C4H10_PRDT_CUM',
            ...group_A_tags,
        },
        {
            name: '烯烃产量',
            unit: 'ton',
            basic_tag: 'PLANT_C5P_PRDT_CUM',
            ...group_A_tags,
        },
    ],
};

const group_B_tags = {
    tag_2: '_HD',
    tag_3: '_HW',
    tag_4: '_HM',
};

const group_B = {
    name: '收率指标',
    children: [
        {
            name: '乙烷收率',
            basic_tag: 'PLANT_C2H6_PRDT_RCRATE',
            standard: 80,
            ...group_B_tags,
            precision_1: 2,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: '丙烷收率',
            basic_tag: 'PLANT_C3H8_PRDT_RCRATE',
            standard: 95,
            ...group_B_tags,
            precision_1: 2,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: '乙烷收率',
            basic_tag: 'PLANT_C4H10_PRDT_RCRATE',
            standard: 95,
            ...group_B_tags,
            precision_1: 2,
            precision_2: 2,
            precision_3: 2,
        },
    ],
};

export {
    group_A,
    group_B,
};
