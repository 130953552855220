import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import style from './index.module.scss'
import ICON_AVATAR from './res/avatar.svg'
import ICON_LOGO from './res/logo.png'
import ICON_MESSAGE from './res/message.png'
import { Input, Menu, Dropdown, Modal } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'

const Comp = props => {
  const { history, location, useDarkMode } = props
  const [name, setName] = useState(null)
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)

  useEffect(() => {
    const userInfoStr =
      window.localStorage.getItem('USER_INFO') || window.localStorage.getItem('USER_INFO')
    let userInfo = null
    if (typeof userInfoStr === 'string' && userInfoStr !== '') {
      try {
        userInfo = JSON.parse(userInfoStr)
      } catch (err) {}
    }
    if (!!userInfo) {
      const { name, expire_time } = userInfo
      try {
        if (moment.unix(expire_time).isAfter(moment())) {
          if (typeof name === 'string') setName(name)
        }
      } catch (err) {}
    }
  }, [])

  const nameText = useMemo(() => {
    if (typeof name === 'string') {
      return name
    }
    return '(未登录)'
  }, [name])

  const handleMenuClick = ({ key }) => {
    if (key === '1' && location.pathname !== '/PEIMSV2/PersonalCenter') {
      setShowDropDownMenu(false)
      history.push('/PEIMSV2/PersonalCenter')
    } else if (key === '2') {
      Modal.confirm({
        title: '退出登录?',
        content: '',
        onOk() {
          localStorage.clear()
          sessionStorage.clear()
          history.push('/Login')
        }
      })
    }
  }

  /** 下拉菜单 */
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        className={`${location.pathname === '/PEIMSV2/PersonalCenter' ? 'active' : ''}`}
      >
        <i className="icon personal-icon" />
        个人中心
      </Menu.Item>
      <Menu.Item key="2">
        <i className="icon logout-icon" />
        退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <div className={style['wrapper']} style={useDarkMode === true ? { backgroundColor: "#212830" } : {}}>
      <div className="left">
        <img alt="" src={ICON_LOGO} className="logo" />
        <div className="sys_name">轻环</div>
        <div className="line" />
        <div className="text">过程工业生产能源一体化系统</div>
      </div>
      <div className="right">
        <Input
          placeholder="请输入关键词"
          style={{ width: 327, height: 32 }}
          className="keywordInput"
        />
        <img src={ICON_MESSAGE} alt="" />
        <div className="user_name" style={{ marginRight: 20 }}>
          消息
        </div>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="topRight"
          overlayClassName={style['headerDorpDown']}
          onVisibleChange={setShowDropDownMenu}
          visible={showDropDownMenu}
          arrow
        >
          <div>
            <img src={ICON_AVATAR} alt="" className="avatar" />
            <span className="user_name">{nameText}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default withRouter(Comp)
