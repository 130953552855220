import React from 'react';
import { Table, message } from 'antd';
import RangeBlock from './RangeBlock';
import RecordModal from './RecordModal.js';
import ChartModal from './ChartModal.js';
import {
    SwapOutlined,
} from '@ant-design/icons';
import LOCK_ICON from './res/lock.svg';
import UNLOCK_ICON from './res/unlock.svg';
import AnalysisModal from '../ParamAnalysis/AnalysisModal.js';
import style from './BoardTable.module.scss';

export default class BoardTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recordModalList: [],
            corrSort: null,//null,up,down
            showAnalysisModal: false,
            modal_record: {},
        };
    }

    nameOnClick = (e, record) => {
        //单向的，只管显示
        // console.log(e.clientX, e.clientY);
        const { chartsModal, setChartsModal, getHisTagListData } = this.props;
        const existRecordIndex = chartsModal
            .map(({ record: { cur_value_tag } }) => cur_value_tag)
            .indexOf(record.cur_value_tag);
        const newRecordModal = {
            record,
            position: [e.clientX, e.clientY],
        };
        const newChartsModal = [].concat(chartsModal);
        if (existRecordIndex === -1) {
            newChartsModal.push(newRecordModal);
            setChartsModal(newChartsModal);
            getHisTagListData();
        } else {
            // newChartsModal[existRecordIndex] = newRecordModal;
        };
        // console.log(newChartsModal);
    }

    removeChartModal = index => {
        const { chartsModal, setChartsModal } = this.props;
        setChartsModal(
            chartsModal
                .filter((chart, chartIndex) => chartIndex !== index)
        );
    }

    rangeBlockOnClick = (e, record) => {
        //单向的，只管显示
        // console.log(e.clientX, e.clientY);
        const { recordModalList } = this.state;
        const existRecordIndex = recordModalList
            .map(({ record: { cur_value_tag } }) => cur_value_tag)
            .indexOf(record.cur_value_tag);
        const newRecordModal = {
            record,
            position: [e.clientX, e.clientY],
        };
        if (existRecordIndex === -1) {
            recordModalList.push(newRecordModal);
        } else {
            recordModalList[existRecordIndex] = newRecordModal;
        };
        this.setState({ recordModalList });
    }

    removeRecordModal = index => {
        const { recordModalList } = this.state;
        recordModalList.splice(index, 1);
        this.setState({ recordModalList });
    }

    lockOnClick = (index, locked) => {
        const { lockOnClick } = this.props;
        lockOnClick(index, locked)
            .then(isSuccess => {
                if (isSuccess) {
                    message.success(`${locked ? '锁定' : '解锁'}成功!`);
                } else {
                    message.error(`${locked ? '锁定' : '解锁'}失败!`);
                };
            });
    }

    corrSortOnClick = () => {
        const { corrSort } = this.state;
        let nextCorrSort = 'down';
        if (corrSort === null) {
            nextCorrSort = 'down';
        } else if (corrSort === 'up') {
            nextCorrSort = 'down';
        } else if (corrSort === 'down') {
            nextCorrSort = 'up';
        } else {
            return;
        };
        const { corrSortFunc } = this.props;
        corrSortFunc(nextCorrSort);
        this.setState({ corrSort: nextCorrSort });
    }

    render() {
        const { recordModalList, corrSort, modal_record } = this.state;
        const { dataSource, tag2Value, draggingIndex, onDragOverIndex_table: onDragOverIndex, chartsModal, hisTag2Value, oneResult } = this.props;
        const { onDragStart, onDragEnd, onDragOverTable: onDragOver, onDropTable: onDrop } = this.props;
        // console.log('chartsModal', chartsModal);
        return (
            <div
                className={style["board_table_wrapper"]}
            >
                <Table
                    className="cus_table_wrapper_in_board_table"
                    dataSource={
                        dataSource
                            .length === 0 ?
                            []
                            :
                            dataSource
                                .concat([{ key: 'last_one' }])
                    }
                    columns={[
                        {
                            title: '',
                            key: 'first_column',
                            className: 'first_column_td',
                            width: 24,
                        },
                        {
                            title: () => (
                                <div className="table_title"></div>
                            ),
                            key: 'lock',
                            render: (text, { locked = false }, index) => index === dataSource.length ?
                                (
                                    null
                                ) : (
                                    <div
                                        className="lock_wrapper"
                                        onClick={() => this.lockOnClick(index, !locked)}
                                    >
                                        {
                                            locked ?
                                                (
                                                    <img alt="" src={LOCK_ICON} />
                                                ) : (
                                                    <img alt="" src={UNLOCK_ICON} />
                                                )
                                        }
                                    </div>
                                ),
                            className: 'lock_td',
                            width: 42,
                            align: "center",
                        },
                        {
                            title: () => (
                                <div
                                    className="table_title table_title_corr"
                                    onClick={this.corrSortOnClick}
                                >
                                    <div className="title">相关性</div>
                                    <div className="sort_wrapper">
                                        <div
                                            className="top"
                                            style={corrSort === 'up' ? { borderColor: 'transparent transparent rgba(24,144,255,1) transparent' } : {}}
                                        />
                                        <div
                                            className="bottom"
                                            style={corrSort === 'down' ? { borderColor: 'rgba(24,144,255,1) transparent transparent transparent' } : {}}
                                        />
                                    </div>
                                </div>
                            ),
                            key: 'relationship',
                            render: (text, record, index) => {
                                const { corr } = record;
                                if (corr === undefined) {
                                    return null;
                                };
                                const coloredIndex = Math.ceil(corr * 5);
                                // console.log(corr, coloredIndex);
                                return (
                                    <div className="corr_wrapper">
                                        {
                                            [1, 2, 3, 4, 5]
                                                .map((corrIndex, index) => (
                                                    <div
                                                        className={"corr_block" + (corrIndex <= coloredIndex ? ' colored_corr_block' : '')}
                                                        key={index}
                                                    />
                                                ))
                                        }
                                    </div>
                                );
                            },
                            width: 115,
                        },
                        {
                            title: () => (
                                <div className="table_title">参数名称</div>
                            ),
                            key: 'name',
                            className: 'cus_table_name-cell',
                            render: (text, modal_record, index) => index === dataSource.length ?
                                (
                                    null
                                ) : (
                                    <div
                                        className="cus_table_name"
                                        // onClick={e => this.nameOnClick(e, modal_record)}
                                        onClick={() => this.setState({ showAnalysisModal: true, modal_record })}
                                    >{modal_record.name}</div>
                                ),
                            // width: 190,
                        },
                        {
                            title: () => (
                                <div className="table_title">实际值</div>
                            ),
                            key: 'curValue',
                            width: 100,
                            className: 'curr-value-cell',
                            render: (text, { cur_value_tag, unit = '' }, index) => {
                                let curValue = tag2Value[cur_value_tag];
                                if (curValue === undefined) {
                                    curValue = '-'
                                } else {
                                    curValue = Math.floor(curValue * 100) / 100;
                                };
                                return index === dataSource.length ?
                                    (
                                        null
                                    ) : (
                                        <div className="curr_value_wrapper">{curValue + unit}</div>
                                    );
                            },
                            align: "center",
                        },
                        {
                            title: () => (
                                <div className="table_title" style={{ fontSize: '12px' }}>推荐范围</div>
                            ),
                            width: 110,
                            key: 'valueRange',
                            className: 'rcmd_range-cell',
                            render: (text, { bound_low, bound_high }, index) => index === dataSource.length ?
                                (
                                    null
                                ) : (
                                    `${bound_low}~${bound_high}`
                                ),
                            align: "center",
                        },
                        {
                            title: () => (
                                <div className="table_title">参数对标</div>
                            ),
                            key: 'params',
                            render: (text, record, index) => {
                                const { cur_value_tag, zone_cut_point, zone_color } = record;
                                return index === dataSource.length ?
                                    (
                                        null
                                    ) : (
                                        <div
                                            className="mainpage_range_block_wrapper"
                                            onClick={e => this.rangeBlockOnClick(e, record)}
                                        >
                                            <RangeBlock value={tag2Value[cur_value_tag]} zone_cut_point={zone_cut_point} zone_color={zone_color} />
                                        </div>
                                    )
                            },
                            // width: 220,
                        },
                        {
                            title: '',
                            width: 24,
                            key: 'move',
                            render: (text, { locked = false }, index) => (index === dataSource.length) ?
                                (
                                    null
                                ) : (
                                    <div
                                        className="move_icon_wrapper"
                                        draggable
                                        onDragStart={() => onDragStart(index)}
                                        onDragEnd={(e) => {
                                            e.preventDefault();
                                            onDragEnd();
                                        }}
                                    >
                                        <SwapOutlined className="move_icon" style={{ color: 'rgba(24, 144, 255, 1)' }} />
                                    </div>
                                ),
                            className: 'move_td',
                            // align: "center",
                        },
                    ]}
                    pagination={false}
                    scroll={{ y: true }}
                    rowClassName={({ locked = false }, index) => (
                        (
                            index % 2 === 1 ? 'grey_row' : ''
                        ) + (
                            (dataSource[draggingIndex] && dataSource[draggingIndex].locked !== true) && locked !== true && draggingIndex !== null && onDragOverIndex === index && draggingIndex !== index ? ' dragover_row' : ''
                        )
                    )}
                    onRow={({ locked = false }, index) => (
                        (locked === true || (dataSource[draggingIndex] && dataSource[draggingIndex].locked === true)) ?
                            {} : {
                                onDragOver: (e) => {
                                    e.preventDefault();
                                    onDragOver(index);
                                },
                                onDrop: () => onDrop(index),
                            }
                    )}
                />
                {
                    this.state.showAnalysisModal &&
                    <AnalysisModal
                        record={modal_record} //当前行
                        optResult={oneResult} //get one result的完整数据
                        onClose={() => this.setState({ showAnalysisModal: false })}
                    />
                }
                {
                    recordModalList
                        .map(({ record, position }, index) => (
                            <RecordModal
                                record={record}
                                initialPosition={position}
                                key={record.cur_value_tag}
                                onCancel={() => this.removeRecordModal(index)}
                                tag2Value={tag2Value}
                            />
                        ))
                }
                {
                    chartsModal
                        .map(({ record, position }, index) => (
                            <ChartModal
                                record={record}
                                initialPosition={position}
                                key={record.cur_value_tag}
                                onCancel={() => this.removeChartModal(index)}
                                tag2Value={tag2Value}
                                hisTag2Value={hisTag2Value}
                            />
                        ))
                }
            </div>
        );
    }
}
