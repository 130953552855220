import BoardTable from './BoardTable.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/MainPageV2/actions.js';
import { post, get, getCachedUserInfo } from 'common/utils';
import {
    onDragOver,
    onDrop,
    // mergeDataSource,
    getHisTagListData,
} from './utils.js';

const lockOnClick = (index, locked) => {
    return (dispatch, getState) => {
        const { mainPageV2: { dataSource, sys_id, layout } } = getState();
        const layout_id = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0]._id;

        const newLockedItems = dataSource
            .map(({ cur_value_tag, locked: originLocked = false }, dataIndex) => ({
                tag_name: cur_value_tag,
                locked: dataIndex === index ? locked : originLocked,
                index: dataIndex,
            }))
            .filter(({ locked }) => locked === true)
            .map(({ tag_name, index }) => ({
                tag_name,
                index,
            }));
        // console.log(newLockedItems);

        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/layout_conf/custom/save', {
            layout_id,
            mid_list: {
                locked_items: newLockedItems,
            },
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                //这里看起来不需要更新layout的值，因为每次从dataSource中读取就好了
                const newLayout = layout;// 等待更好的做法
                const newCusConfig = newLayout.cus_config.filter(({ layout_id: layoutId }) => layoutId === layout_id)[0] || {};
                newCusConfig.mid_list = newCusConfig.mid_list || {};
                newCusConfig.mid_list.locked_items = newLockedItems;
                dispatch(setValues({
                    layout: newLayout,
                    dataSource: dataSource
                        .map((item, itemIndex) => ({
                            ...item,
                            ...(index === itemIndex ?
                                {
                                    locked,
                                } : {})
                        })),
                }));
                return true;
            } else {
                // message.success('保存失败!');
                return false;
            };
        });
    };
}

const corrSortFunc = sortType => {
    return (dispatch, getState) => {
        const { mainPageV2: { dataSource } } = getState();
        // console.log(sortType);
        const newDataSource = [].concat(dataSource);
        const unlockedItemIndexs = newDataSource
            .map(({ locked }, index) => ({
                locked,
                index,
            }))
            .filter(({ locked }) => locked !== true)
            .map(({ index }) => index);
        const unlockedItems = dataSource
            .filter(({ locked }) => locked !== true);
        const noCorrUnlockedItems = unlockedItems
            .filter(({ corr }) => typeof corr !== 'number');
        const hasCorrUnlockedItems = unlockedItems
            .filter(({ corr }) => typeof corr === 'number');
        hasCorrUnlockedItems
            .sort(({ corr: corrA }, { corr: corrB }) => sortType === 'up' ? (corrA - corrB) : (corrB - corrA));
        // console.log(noCorrUnlockedItems, hasCorrUnlockedItems);
        const sortedUnlockedItems = hasCorrUnlockedItems.concat(noCorrUnlockedItems);
        for (let i = 0; i < unlockedItemIndexs.length; i++) {
            newDataSource[unlockedItemIndexs[i]] = sortedUnlockedItems[i];
        };
        dispatch(setValues({ dataSource: newDataSource }));
    };
}

const mapStateToProps = state => {
    const { mainPageV2 } = state;
    return {
        ...mainPageV2,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        lockOnClick: (index, locked) => dispatch(lockOnClick(index, locked)),
        onDragStart: draggingIndex => {
            dispatch(setValues({ draggingIndex }));
        },
        onDragEnd: () => {
            dispatch(setValues({
                draggingIndex: null,
                onDragOverIndex_table: null,
                onDragOverIndex_charts: null,
            }));
        },
        onDragOverTable: index => dispatch(onDragOver('onDragOverIndex_table', index)),
        onDropTable: index => dispatch(onDrop('table', index)),
        setChartsModal: chartsModal => dispatch(setValues({ chartsModal })),
        getHisTagListData: () => dispatch(getHisTagListData(ownProps)),
        corrSortFunc: (sortType) => dispatch(corrSortFunc(sortType)),
    }
}

const WrapperedBoardTable = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoardTable);

export default WrapperedBoardTable;