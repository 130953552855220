import { Empty } from 'antd';
import React from 'react';
import style from './index.module.scss';

const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className="content">
                <Empty description="正在开发" />
            </div>
        </div>
    );
};

export default Page;
