import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import moment from 'moment';
import { DatePicker } from 'antd';
import { withRouter } from 'react-router';

const Comp = (props) => {
    const { timeInfo } = props;

    const setTimeInfo = useRef(null);
    useEffect(() => {
        if (typeof props.setTimeInfo === 'function') {
            setTimeInfo.current = props.setTimeInfo;
            return;
        };
        setTimeInfo.current = () => { };
    }, [props]);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible) {
            const cb = () => {
                setVisible(false);
            };
            window.addEventListener('click', cb);
            return () => {
                window.removeEventListener('click', cb);
            };
        };
    }, [visible]);

    const datePickerOnChange = (v) => {
        const nextTimeInfo = Object.assign({}, timeInfo ?? {}, { time: moment(v).format("YYYY-MM-DD HH:mm:ss") });
        setTimeInfo.current(nextTimeInfo);
        setVisible(false);
    };

    return (
        <div className={style['wrapper']}>
            {
                visible && !!timeInfo && (
                    <div className="picker_wrapper">
                        <div
                            onClick={(e) => {
                                // 当用range选择器时，需要在此处阻止冒泡避免弹窗消失
                                // 后续的挂载在内部的意义似乎只剩下隐藏ant-picker-range-arrow
                                e.stopPropagation();//此处不明白，日历组件默认挂载body下，但是这里的stopPropagation缺起效了，放在外部反而不起效
                            }}
                        >
                            <DatePicker
                                showTime
                                style={{ width: "100%" }}
                                value={moment(timeInfo.time)}
                                open={true}
                                onChange={datePickerOnChange}
                                disabledDate={timeInfo.disabledDate}
                            />
                        </div>
                        {/* 遮挡内部点击事件 */}
                        {/* 因为高度可能还是会超出的 */}
                        <div className="hover_block"></div>
                    </div>
                )
            }
            <div className="content">
                {
                    !!timeInfo && (
                        <div className="date" onClick={(e) => { setVisible(!visible); e.stopPropagation(); }} >
                            {moment(timeInfo.time).format("【YYYY年MM月DD日】HH:mm:ss")}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default withRouter(Comp);
