import React, { useEffect, useState, useRef } from 'react'
import style from './index.module.scss'
import ICON_ADD from './res/add.svg'
import { get, post, reqDelete, patch } from 'common/utils.js'
import { Button, Space, Tree, Modal, message } from 'antd'
import { parseOrgsData } from './utils.js'
import EditPanel from './EditPanel'

const Comp = props => {
  const [orgList, setOrgList] = useState(null)
  const queryLockOrgList = useRef(null)
  useEffect(() => {
    const currLock = (queryLockOrgList.current = Symbol())
    get(process.env.REACT_APP_SERVER_PATH + 'api/auth/orgs', {}).then(retData => {
      if (queryLockOrgList.current !== currLock) {
        return
      }
      console.log('list', retData)
      if (!!retData.data && !!retData.data.results) {
        setOrgList(retData.data.results)
      } else {
      }
    })
    return () => {
      queryLockOrgList.current = Symbol()
    }
  }, [])

  // get orgs tree data
  const [originOrgs, setOriginOrgs] = useState(null)
  const [orgs, setOrgs] = useState(null)
  const queryLock = useRef(null)
  useEffect(() => {
    const currLock = (queryLock.current = Symbol())
    get(process.env.REACT_APP_SERVER_PATH + 'api/auth/org/get_orgs_tree', {}).then(retData => {
      if (queryLock.current !== currLock) {
        return
      }
      console.log('tree', retData)
      if (!!retData.data && retData.data.errcode === 0) {
        setOriginOrgs(retData.data.results)
        setOrgs(parseOrgsData(retData.data.results))
      } else {
      }
    })
    return () => {
      queryLock.current = Symbol()
    }
  }, [])

  const onDrop = info => {
    console.log(info)
  }

  const [selectedNodes, setSelectedNodes] = useState(null)
  const [editNode, setEditNode] = useState(null)
  const [newNode, setNewNode] = useState(null)
  const [visible, setVisible] = useState(false)
  const onSelect = (selectedKeys, e) => {
    const { selectedNodes } = e
    setSelectedNodes(selectedNodes)
    let nextEditNode = null
    if (!!selectedNodes && selectedNodes.length > 0) {
      const { id } = selectedNodes[0]
      if (!!orgList) {
        const tmp_nextEditNode = orgList.filter(({ id: _id }) => _id === id)[0]
        if (!!tmp_nextEditNode) {
          nextEditNode = tmp_nextEditNode
        }
      }
    }
    setEditNode(nextEditNode)
  }

  const onClick = () => {
    setVisible(true)
  }

  const addPostLock = useRef(null)
  const deleteLock = useRef(null)
  const patchLock = useRef(null)
  useEffect(() => {
    return () => {
      addPostLock.current = Symbol()
      deleteLock.current = Symbol()
      patchLock.current = Symbol()
    }
  }, [])

  const refreshOrgs = () => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/auth/org/get_orgs_tree', {}).then(retData => {
      console.log('tree refresh', retData)
      if (!!retData.data && retData.data.errcode === 0) {
        setOriginOrgs(retData.data.results)
        setOrgs(parseOrgsData(retData.data.results))
      } else {
      }
    })
    get(process.env.REACT_APP_SERVER_PATH + 'api/auth/orgs', {}).then(retData => {
      console.log('list refresh', retData)
      if (!!retData.data && !!retData.data.results) {
        setOrgList(retData.data.results)
      } else {
      }
    })
    // 检查刷新后editNode是否还有效;
  }

  const handleOk = () => {
    // check
    if (!!newNode) {
      const { name, parent_id, description, sort_index } = newNode
      if (typeof name === 'string' && name !== '') {
        if (typeof sort_index === 'number') {
          // 先不检查parene_id，因为最外层无父节点
          const currLock = (addPostLock.current = Symbol())
          post(process.env.REACT_APP_SERVER_PATH + 'api/auth/orgs', {
            name,
            parent_id,
            sort_index,
            description
          }).then(retData => {
            if (addPostLock.current !== currLock) {
              return
            }
            console.log('add', retData)
            if (!!retData.data && typeof retData.data.id === 'number') {
              // 添加成功，刷新，关闭弹窗
              message.success('新建成功！')
              handleCancel()
              refreshOrgs()
            } else {
            }
          })
          return
        }
      }
    }
    message.warning('请填写完整信息！')
  }

  const handleCancel = () => {
    setNewNode(null)
    setVisible(false)
  }

  const deleteOnClick = () => {
    const { id } = editNode
    const currLock = (deleteLock.current = Symbol())
    reqDelete(process.env.REACT_APP_SERVER_PATH + `api/auth/orgs/${id}`, {}).then(retData => {
      if (deleteLock.current !== currLock) {
        return
      }
      console.log('delete', retData)
      if (retData.data === '') {
        // 删除成功，刷新
        message.success('删除成功')
        setEditNode(null)
        refreshOrgs()
      } else {
      }
    })
  }

  const saveOnClick = () => {
    // check
    const { name, parent_id, description, sort_index, id } = editNode
    if (typeof name === 'string' && name !== '') {
      if (typeof sort_index === 'number') {
        // 先不检查parene_id，因为最外层无父节点
        const currLock = (patchLock.current = Symbol())
        patch(process.env.REACT_APP_SERVER_PATH + `api/auth/orgs/${id}`, {
          name,
          parent_id,
          sort_index,
          description
        }).then(retData => {
          if (patchLock.current !== currLock) {
            return
          }
          console.log('update', retData)
          if (!!retData.data && typeof retData.data.id === 'number') {
            // 添加成功，刷新，关闭弹窗
            message.success('保存成功！')
            handleCancel()
            refreshOrgs()
          } else {
          }
        })
        return
      }
    }
    message.warning('请填写完整信息！')
  }

  return (
    <div className={style['wrapper']}>
      {visible && (
        <Modal
          title="新增部门"
          visible={true}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: {
              backgroundColor: '#357CF7',
              borderColor: '#357CF7'
            }
          }}
        >
          <EditPanel
            data={newNode}
            onChange={d => setNewNode(d)}
            orgs={originOrgs}
            orgList={orgList}
          />
        </Modal>
      )}
      <div className="content-top">
        部门管理
        <Button type="primary" style={{ display: 'flex', alignItems: 'center' }} onClick={onClick}>
          <img alt="" src={ICON_ADD} style={{ marginRight: 8 }} />
          新增
        </Button>
      </div>
      <div className="content-main">
        <div className="left">
          <div className="content_wrapper scroll_bar_restyle">
            {!!orgs && (
              <Tree
                // className="draggable-tree"
                defaultExpandAll={true}
                // draggable
                blockNode
                // onDragEnter={this.onDragEnter}
                onDrop={onDrop}
                treeData={orgs}
                onSelect={onSelect}
                selectedKeys={!!selectedNodes ? selectedNodes.map(({ key }) => key) : []}
              />
            )}
          </div>
        </div>
        <div className="right">
          {!!editNode && (
            <div className="content_wrapper">
              <EditPanel
                data={editNode}
                onChange={d => setEditNode(d)}
                orgs={originOrgs}
                orgList={orgList}
              />
              <div className="btn_wrapper">
                <Space>
                  <Button danger onClick={deleteOnClick}>
                    删除
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#357CF7',
                      borderColor: '#357CF7'
                    }}
                    onClick={saveOnClick}
                  >
                    保存
                  </Button>
                </Space>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="left" style={{ display: 'none' }}>
        <div className="content_wrapper scroll_bar_restyle">
          {!!orgs && (
            <Tree
              // className="draggable-tree"
              defaultExpandAll={true}
              // draggable
              blockNode
              // onDragEnter={this.onDragEnter}
              onDrop={onDrop}
              treeData={orgs}
              onSelect={onSelect}
              selectedKeys={!!selectedNodes ? selectedNodes.map(({ key }) => key) : []}
            />
          )}
        </div>
      </div>
      <div className="right" style={{ display: 'none' }}>
        <div className="top">
          <div className="add_btn_wrapper" onClick={onClick}>
            <img alt="" src={ICON_ADD} className="icon" />
            <div className="text">新增</div>
          </div>
        </div>
        <div className="bottom">
          {!!editNode && (
            <div className="content_wrapper">
              <EditPanel
                data={editNode}
                onChange={d => setEditNode(d)}
                orgs={originOrgs}
                orgList={orgList}
              />
              <div className="btn_wrapper">
                <Space>
                  <Button danger onClick={deleteOnClick}>
                    删除
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: '#357CF7',
                      borderColor: '#357CF7'
                    }}
                    onClick={saveOnClick}
                  >
                    保存
                  </Button>
                </Space>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Comp
