import { combineReducers } from 'redux';
import mainPage from './MainPage/reducer.js';
import factorSummaryV2 from './FactorSummaryV2/reducer.js';
import referenceFactors from './ReferenceFactors/reducer.js';
import system from './System/reducer.js';
import lnOilfield from './LnOilfield/reducer.js'
import mainPageV2 from './MainPageV2/reducer';
import sampleMaintenance from './SampleMaintenance/reducer.js';
import examAnalysis from './ExamAnalysis/reducer.js';
import demoSystemV2 from './DemoSystemV2/reducer';

const reducers = combineReducers({
    mainPage,
    factorSummaryV2,
    referenceFactors,
    system,
    lnOilfield,
    mainPageV2,
    sampleMaintenance,
    examAnalysis,
    demoSystemV2,
})

export default reducers;
