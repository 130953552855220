import React from 'react';
import { useState, useEffect } from 'react';
import Wrapper from '../../Common/Wrapper.js';
import ShiftCompareModal from './ShiftCompareModal.js';
import { post } from '../../../../common/utils.js';
import { parseTimeRange } from './utils.js';

import ICON_TITLE from '../res/icon_1.png';
import ICON_4 from '../res/single_4.png';
import './ShiftCompareBlock.scss';

const Comp = (props) => {
    const { shiftDetails, selectedRF, timeRange } = props;
    const { name, upper_limit, lower_limit, exp_cpl_rate } = selectedRF;
    const [visible, setVisible] = useState(false);
    const [shiftDatas, setShiftDatas] = useState(null);
    useEffect(() => {
        // console.log("in use effect");
        const [time_begin, time_end] = parseTimeRange(timeRange);
        post(process.env.REACT_APP_SERVER_PATH + "api/kpi/get_history_per_shift", {
            time_begin,
            time_end,
            cpl_rule_id: 1,
            kpi_factor_ids: [selectedRF.id],
        })
            .then(retData => {
                // console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    setShiftDatas(retData.data.results);
                };
            });
    }, [timeRange, selectedRF.id]);

    let standV = '-';
    if (typeof upper_limit === 'number') {
        standV = Math.floor(upper_limit * 10) / 10;
    } else if (typeof lower_limit === "number") {
        standV = Math.floor(lower_limit * 10) / 10;
    };
    let expCplRateText = '-';
    if (typeof exp_cpl_rate === "number") {
        expCplRateText = Math.floor(exp_cpl_rate * 1000) / 10;
    };
    return (
        <Wrapper icon={ICON_4} title="班组对比" titleOnClick={() => { setVisible(true) }}>
            <div className="factor_summary_v2_single_factor_detail_shift_compare_block_wrapper">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt="" src={ICON_TITLE} width="2" height="16" />
                    <div style={{ marginLeft: 8, fontSize: 14, lineHeight: '14px', height: 14, color: '#9FA0BA' }}>参数名称</div>
                    <div style={{ marginLeft: 12, width: 28, height: 14, borderRadius: 9, backgroundColor: '#B376FF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ color: '#151922', fontSize: 12, lineHeight: "12px", height: 12 }}>RF</div>
                    </div>
                    <div style={{ marginLeft: 8, fontSize: 14, lineHeight: '14px', height: 14, color: '#FFFFFF' }}>{name}</div>
                </div>
                <div style={{ marginTop: 13, display: 'grid', gridTemplateColumns: 'repeat(2,50%)' }}>
                    {
                        [['参数基准值', standV], ['目标PHI', `${expCplRateText}%`]]
                            .map((item, index) => {
                                const [title, value] = item;
                                return (
                                    <div key={index} style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
                                        <div style={{ color: '#9FA0BA', lineHeight: '14px', height: 14, fontSize: 14 }}>{title}</div>
                                        <div style={{ marginLeft: 8, color: '#FFFFFF', lineHeight: '14px', height: 14, fontSize: 14 }}>{value}</div>
                                    </div>
                                );
                            })
                    }
                    <div style={{ marginLeft: 10 }}></div>
                </div>
                <table style={{ width: '100%', marginTop: 16 }}>
                    <thead>
                        <tr>
                            <th style={{ width: 12, height: 38 }} />
                            <th>班组名称</th>
                            <th>不及格指标</th>
                            <th>平均KPR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!shiftDatas &&
                            shiftDatas
                                .map((item, index) => {
                                    const { shift_name, failed_num, avg_cpl_rate } = item;
                                    let rateText = '-';
                                    const v_parse = parseFloat(avg_cpl_rate);
                                    if (typeof v_parse === "number") {
                                        rateText = Math.floor(v_parse * 10000) / 100;
                                    };
                                    let warning = false;
                                    const n_parse = parseInt(failed_num);
                                    if (typeof n_parse === "number") {
                                        if (n_parse > 0) {
                                            warning = true;
                                        };
                                    };
                                    return (
                                        <tr key={index}>
                                            <td />
                                            <td>{shift_name}</td>
                                            <td className={warning ? "td_red" : ""}>{failed_num}</td>
                                            <td>{rateText}%</td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
            {
                visible && (
                    <ShiftCompareModal onCancel={() => { setVisible(false) }} shiftDetails={shiftDetails} />
                )
            }
        </Wrapper>
    );
};

export default Comp;
