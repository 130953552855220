import React from "react";
import './tabs.scss'

const SecondTabs = (props) => {
    const { tabList, activeIndex_, changeSecondTab } = props
    return (
        <div className="tabs">
            <ul>
                {
                    tabList && tabList.map((item,index) => {
                        return (
                            <li className="tabs-title"
                                style={{borderBottom: activeIndex_ === index?`3px solid #355B99`: 'none'}}
                                key={item.id}
                                onClick={() => changeSecondTab(item.name, index)}>
                                {item.name}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
export default SecondTabs
