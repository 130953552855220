import React from 'react';
import { useEffect } from 'react';

import AdviceBlock from '../Common/AdviceBlockContainer.js';
import ShiftCompareBlock from '../Common/ShiftCompareBlockContainer.js';

const MultiFactorsDetail = (props) => {
    const { multiSelectedIds } = props;
    useEffect(() => {
    });
    return (
        <div>
            <AdviceBlock selectedIds={multiSelectedIds} />
            <div style={{ height: 12 }} />
            <ShiftCompareBlock selectedIds={multiSelectedIds} />
        </div>
    );
};

export default MultiFactorsDetail;
