import React, { useState } from 'react'
import FilterSetting from './FilterSetting'
import CapsuleTable from './CapsuleTable'
import { useTemplateClassification } from '../CapsuleBoard/DialogPanel/Dialog/hooks'
import style from './index.module.scss'

/** 事件汇总 */
const CapsuleSummary = props => {
  const [tags] = useTemplateClassification([])
  const [filterParams, changeFilterParams] = useState(null) // 查询条件
  const [exportIds, changeExportIds] = useState([]) // 勾选的胶囊id集合

  return (
    <div className={style['capsuleSummary']}>
      <FilterSetting tags={tags} exportIds={exportIds} changeFilterParams={changeFilterParams} />
      <CapsuleTable filterParams={filterParams} tags={tags} changeExportIds={changeExportIds} />
    </div>
  )
}
export default CapsuleSummary
