import LeftTable from './LeftTable.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/SampleMaintenance/actions.js';
import { post } from 'common/utils';
import { getDataMetas, getHistoryData } from './utils.js';

const layoutConfigSave = (cus_trend_metas) => {
    return (dispatch, getState) => {
        const { sampleMaintenance: { sys_id, layoutResults } } = getState();
        const result = layoutResults
            .filter(({ sys_id: sysId }) => sysId === sys_id)[0];
        if (!result) {
            return false;
        };
        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/layout_conf/custom/save', {
            layout_id: result._id,
            cus_trend_metas,
        })
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    const { sampleMaintenance: { layoutCusConfig } } = getState();
                    const cusConfig = layoutCusConfig
                        .filter(({ layout_id }) => layout_id === result._id)[0];
                    if (!!cusConfig) {
                        cusConfig.cus_trend_metas = cus_trend_metas;
                        dispatch(setValues({ layoutCusConfig }));//layoutCusConfig写法有待商榷
                    };
                    return true;
                };
                return false;
            });
    };
}

const mapStateToProps = state => {
    const { sampleMaintenance = {} } = state;
    const { sys_id, layoutResults, layoutCusConfig, dataMetaList } = sampleMaintenance;
    let cusMetas = [];
    const result = layoutResults
        .filter(({ sys_id: sysId }) => sysId === sys_id)[0];
    if (!!result) {
        const cusConfig = layoutCusConfig
            .filter(({ layout_id }) => layout_id === result._id)[0];
        if (!!cusConfig) {
            cusMetas = cusConfig.cus_trend_metas;
        };
    };
    const allSelectedMetaIds = cusMetas
        .map(({ id }) => id);
    return {
        dataMetaList: dataMetaList
            .filter(
                ({ id }) => !allSelectedMetaIds
                    .includes(id)
            ),
        cusMetas,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDataMetas: keyword => dispatch(getDataMetas(keyword)),
        layoutConfigSave: newCusMetas => dispatch(layoutConfigSave(newCusMetas)),
        getHistoryData: tagNames => dispatch(getHistoryData(tagNames)),
    };
}

const WrapperedLeftTable = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LeftTable);

export default WrapperedLeftTable;