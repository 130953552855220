import React from 'react';
import style from './index.module.scss';
import PIC_1 from './res/pic.svg';
import Card from '../SingleCard';

const cards = [
    {
        name: '有功功率',
        unit: 'kW',
        abnormal: false,
        highlight: false,
        valueColor: '#00676B',
        extd_tag: '_kWz',
        key: "kWz",
    },
    {
        name: '无功功率',
        unit: 'kVar',
        abnormal: false,
        highlight: false,
        valueColor: '#00676B',
        extd_tag: '_kWRz',
        key: "kWRz",
    },
    {
        name: '视在功率',
        unit: 'kVA',
        abnormal: false,
        highlight: false,
        valueColor: '#00676B',
        extd_tag: '_kVAz',
        key: "kVAz",
    },
];

const barInfos = [
    {
        name: '总',
        color: '#00676B',
        extd_tag: '_PFz',
        key: "PFz",
    },
    {
        name: 'A相',
        extd_tag: '_PFa',
        key: "PFz",
    },
    {
        name: 'B相',
        extd_tag: '_PFb',
        key: "PFb",
    },
    {
        name: 'C相',
        extd_tag: '_PFc',
        key: "PFc",
    },
];

const barStandardPercent = 90;
const titleWidth = 32;
const valueWidth = 50;

const Comp = (props) => {
    const { data } = props;
    return (
        <div className={style['wrapper']}>
            <div className="head">
                <div className="text">功率</div>
            </div>
            <div className="content">
                <div className="left">
                    <div className="content">
                        <div className="title_wrapper">
                            <div className="text">功率</div>
                        </div>
                        <div className="cards_wrapper">
                            {
                                cards
                                    .map((item, index) => {
                                        const { key } = item;
                                        const value = data?.[key ?? null] ?? null;
                                        return (
                                            <Card key={index} {...item} value={value} precision={1} />
                                        );
                                    })
                            }
                            <img alt="" src={PIC_1} width="152" height="88" />
                        </div>
                    </div>
                </div>
                <div className="line"></div>
                <div className="right">
                    <div className="title">功率因数</div>
                    <div className="content">
                        {
                            barInfos
                                .map((item, index) => {
                                    const { name, color, key } = item;
                                    let value = data?.[key ?? null] ?? null;
                                    let valueText = '--';
                                    let barWidth = 0;
                                    let colorRed = false;
                                    if (typeof value === 'number') {
                                        value = Math.abs(value);
                                        valueText = Math.floor(value * 100) / 100;
                                        barWidth = value * 100;
                                        colorRed = value * 100 < barStandardPercent;
                                    };
                                    return (
                                        <div className="row_wrapper" key={index}>
                                            <div className="title" style={{ width: titleWidth }}>{name}</div>
                                            <div className="bar_wrapper">
                                                <div className="colored_bar" style={Object.assign({ width: `${barWidth}%` }, typeof color === 'string' ? { backgroundColor: color } : {})}></div>
                                            </div>
                                            <div className={"value" + (colorRed ? " value_red" : "")} style={{ width: valueWidth }}>{valueText}</div>
                                        </div>
                                    );
                                })
                        }
                        <div style={{ position: 'absolute', left: titleWidth, right: valueWidth, top: -8, bottom: -8 }}>
                            <div style={{ position: 'absolute', left: `${barStandardPercent}%`, width: 0, height: '100%', top: 0, borderRight: '1px dashed #B4B4B4' }}>
                                <div className="standard_value_text" style={{ position: 'absolute', bottom: -10, left: '50%', transform: 'translate(-50%,100%)' }}>{barStandardPercent / 100}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
