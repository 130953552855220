import React from 'react';

import style from './BoardState.module.scss';

export default class BoardState extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
        };
    }

    pageOnClick = page => {
        this.setState({ page });
    }

    render() {
        const { tag2Value, groupedResults } = this.props;
        const { page } = this.state;
        const currentPage = page < groupedResults.length ? page : groupedResults.length - 1;
        return (
            <div
                className={style["board_state_wrapper"]}
                style={
                    (groupedResults.length > 0 &&
                        groupedResults[currentPage].length < 4) ? {
                            justifyContent: 'flex-start',
                        } : {}
                }
            >
                {
                    groupedResults.length > 0 &&
                    groupedResults[currentPage]
                        .map(({ name, cur_value_tag, unit, bound_value, bound_margin }, index) => {
                            let value = tag2Value[cur_value_tag];
                            if (typeof value === 'number') {
                                value = Math.floor(value * 100) / 100;
                            } else {
                                value = '-';
                            };
                            return (
                                <div
                                    key={index}
                                    className="item_wrapper"
                                    style={(groupedResults[currentPage].length < 4 && index > 0) ? {
                                        marginLeft: 20,
                                    } : {}}
                                >
                                    <div className="title" title={name}>{name}</div>
                                    <div className="value_wrapper">
                                        <div className="value">{value}</div>
                                        <div className="unit">{unit}</div>
                                    </div>
                                    <div className="range">有效范围: {bound_value}±{bound_margin}</div>
                                </div>
                            );
                        })
                }
                <div className="page_switch_wrapper">
                    {
                        groupedResults
                            .map((_, index) => (
                                <div
                                    key={index}
                                    className={"dot" + (currentPage === index ? ' current_dot' : '')}
                                    onClick={() => this.pageOnClick(index)}
                                />
                            ))
                    }
                </div>
            </div>
        );
    }
}
