import * as echarts from 'echarts~5';

const fncs = [
    {
        id: 1,
        name: '1号炉'
    },
    {
        id: 2,
        name: '2号炉'
    },
    {
        id: 3,
        name: '3号炉'
    }
].map((item, index) => Object.assign(item, { key: `${index}` }))


const renderChart = (chartNode, data, nodeObj, cb) => {
    if (!!chartNode) {
        // render chart
        let instance = echarts.getInstanceByDom(chartNode);
        if (!instance) {
            instance = echarts.init(chartNode);
        };
        if (data) {
            const { data_list } = data
            const yVals = data_list.map(({ value }) => value)
            const xVals = data_list.map(({ count, end_time }) => `${end_time} 出炉：${count}`)
            const pYVals = yVals.filter(v => typeof v === 'number')
            let max = 1
            let min = 0
            // 该页面目前仅有tengfei使用
            if (pYVals.length > 0) {
                const yMax = Math.max(...pYVals)
                const yMin = Math.min(...pYVals)
                const delta = yMax - yMin
                if (delta === 0) {
                    max = Math.ceil(yMax) + 1
                    min = Math.floor(yMin)
                    if (min > 0) min--
                } else {
                    max = Math.ceil((yMax + delta / 10) * 100) / 100
                    min = Math.floor((yMin - delta / 10) * 100) / 100
                    if (min < 0 && yMin >= 0) min = 0
                    if (max > 0 && yMax <= 0) max = 0
                }
            }

            instance.setOption({
                grid: {
                    left: 80,
                    top: 24,
                    right: 12,
                    bottom: 12,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(64, 161, 225, 1)',
                            type: [10, 0]
                        }
                    },
                    formatter: (param) => {
                        const [{ axisValueLabel, marker, value }] = param
                        return `${axisValueLabel}<br/>${marker}${value}`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xVals,
                    boundaryGap: false,
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,// true时不一定显示0
                    min,
                    max
                },
                series: [
                    {
                        data: yVals,
                        type: 'line',
                        itemStyle: {
                            color: 'rgba(64, 161, 225, 1)',
                        },
                        symbol: 'none'
                    },
                    {
                        data: yVals.map(() => max),
                        type: 'bar',
                        barWidth: '100%',
                        itemStyle: {
                            color: 'transparent',
                        },
                    },
                ],
            });
            instance.off('click')
            instance.on('click', function (param) {
                const index = param?.dataIndex
                const info = data?.data_list?.[index] ?? null
                if (info) {
                    if (typeof cb === 'function') {
                        cb(info)
                    }
                }
            });
        } else {
            instance.clear()
        }
        const ins = Object.keys(nodeObj).map(key => nodeObj[key]).filter(n => n).map(n => echarts.getInstanceByDom(n)).filter(ins => ins)
        echarts.connect(ins)

    };
};

export {
    fncs, renderChart
}