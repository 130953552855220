import React, { useMemo, useState, useContext, useEffect, useRef } from 'react';
import style from './index.module.scss';
import PIC_1 from './res/pic.svg';
import Card from '../SingleCard';
import ICON_SWITCH from './res/switch.png';
import CommonContext from '../../context';
import { post } from 'common/utils';

const getAllExtdTags = (left, right) => {
    const allTags = []
        .concat(
            left
                .reduce((pV, cItem) => {
                    return pV.concat(cItem.cards.map(({ extd_tag }) => extd_tag).filter((tag) => typeof tag === 'string'));
                }, []),
            [right.extd_tag],
        );
    return [...new Set(allTags)];
};

const getValue = (selectedDevice, tag2Value, extd_tag) => {
    let value = null;
    if (!!selectedDevice && typeof extd_tag === 'string') {
        const v = tag2Value[selectedDevice.tag_name + extd_tag];
        if (typeof v === 'number') {
            value = v;
        };
    };
    return value;
};

const getAvgValue = (selectedDevice, tag2Value, cards, currIndex) => {
    let value = null;
    const otherValues = cards.filter((card, index) => index !== currIndex).map(({ extd_tag }) => getValue(selectedDevice, tag2Value, extd_tag));
    if (otherValues.length > 0 && otherValues.filter((v) => typeof v === 'number').length === otherValues.length) {
        return otherValues.reduce((pV, cV) => pV + cV, 0) / otherValues.length;
    };
    return value;
};

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const { name, left, right,img } = props;
    const [leftIndex, setLeftIndex] = useState(0);
    const [tag2Value, setTag2Value] = useState({});

    const allExtdTagsRef = useRef(getAllExtdTags(left, right));
    useEffect(() => {
        // 表示不随着left, right变化而渲染，仅仅作为数据源作用
        // 第二个作用是 如果真的在运行时改变，会随之改变
        allExtdTagsRef.current = getAllExtdTags(left, right);
    }, [left, right]);

    const [selectedDevice, refreshTick] = useMemo(() => {
        if (!!commonInfo) {
            return [commonInfo.state.selectedDevice, commonInfo.state.refreshTick];
        };
        return [null, null];
    }, [commonInfo]);
    const queryLock = useRef(null);
    useEffect(() => {
        // console.log(selectedDevice, refreshTick);
        if (!!selectedDevice) {
            const currLock = (queryLock.current = Symbol());
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                tag_name_list: allExtdTagsRef.current.map((extd_tag) => selectedDevice.tag_name + extd_tag),
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        setTag2Value(retData.data.results);
                    };
                });
            return () => {
                queryLock.current = Symbol();
            };
        };
    }, [selectedDevice, refreshTick]);

    const nameText = useMemo(() => typeof name === 'string' ? name : "--", [name]);
    const rightValueText = useMemo(() => {
        if (!!selectedDevice) {
            const value = tag2Value[selectedDevice.tag_name + right.extd_tag];
            if (typeof value === 'number') {
                return Math.floor(value * 100) / 100;
            };
        };
        return '--';
    }, [right, tag2Value, selectedDevice]);

    return (
        <div className={style['wrapper']}>
            <div className="head">
                <img src={img} />
                <div className="text">{nameText}</div>
            </div>
            <div className="content">
                <div className="left">
                    {
                        !!left && left[leftIndex] && left[0] && (
                            <div className="content">
                                <div className="title_wrapper">
                                    {
                                        left.length > 1 && (
                                            <img alt="" src={ICON_SWITCH} width="16" height="16" style={{ cursor: 'pointer', marginRight: 8 }} onClick={() => { setLeftIndex(1 - leftIndex); }} />
                                        )
                                    }
                                    <div className="text">{left[leftIndex].name}</div>
                                </div>
                                <div className="cards_wrapper">
                                    {
                                        left[leftIndex].cards
                                            .map((item, index, arr) => {
                                                const { extd_tag, isAvg } = item;
                                                const value = isAvg === true ? getAvgValue(selectedDevice, tag2Value, arr, index) : getValue(selectedDevice, tag2Value, extd_tag);
                                                return (
                                                    <Card key={index} {...item} value={value} />
                                                );
                                            })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="line"></div>
                <div className="right">
                    <div className="title">{right.name}</div>
                    <div className="content">
                        <div className="value_wrapper">
                            <div className="value">{rightValueText}</div>
                            <div className="unit">{right.unit}</div>
                        </div>
                        <div className="pic_wrapper" style={{ backgroundImage: `url(${PIC_1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
