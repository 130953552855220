import React, { Component } from 'react'
import { HashRouter, Switch, Redirect, Route } from 'react-router-dom'
import LoginV2 from 'containers/LoginV2'
import LoginV2YXSN from 'containers/LoginV2YXSN'
import UserDefinedRoutes from 'routes';
import ExcelWindow from 'containers/OnlineDisk/ExcelWindow.js';

import { getCachedUserInfo, sendStatusInfo } from 'common/utils.js';

import 'moment/locale/zh-cn'

import './App.scss'

const needSuposAuth = process.env.REACT_APP_SUPOS_AUTH === 'true'
const isYXSN = ['yxsn'].includes(process.env.REACT_APP_PROJECT_TAG)
const isDemo = ['xinchen', 'demo', 'demo-yxsn'].includes(process.env.REACT_APP_PROJECT_TAG)
const isWUHU = ['wuhu'].includes(process.env.REACT_APP_PROJECT_TAG)
let defaultIndexPage = process.env.REACT_APP_DEFAULT_HOME_PAGE || '/PEIMSV2/Overview';
let userInfo = getCachedUserInfo();
if (userInfo && userInfo.index_path) {
    defaultIndexPage = userInfo.index_path;
}

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onHashChange = () => {
        sendStatusInfo()
    }

    componentDidMount() {
        sendStatusInfo()
        window.addEventListener('hashchange', this.onHashChange)
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.onHashChange)
    }

    render() {
        return (
            <div
                style={Object.assign(
                    { height: '100%' },
                )}
            >
                <HashRouter>
                    <Switch>
                        <Redirect
                            exact
                            from="/"
                            to={
                                needSuposAuth
                                    ? '/AppAuth'
                                    : isYXSN
                                        ? '/PEIMSV2/SPEC?path=%2Fyxsn-pc%2F%23%2FControllerBoard'
                                        : isDemo
                                            ? '/PEIMSV2/Overview'
                                            : isWUHU ? '/System/MainPage'
                                                : defaultIndexPage
                            }
                        />
                        <Route exact path="/Login" component={isYXSN ? LoginV2YXSN : LoginV2} />
                        <Route exact path="/ExcelWindow" component={ExcelWindow} />
                        <UserDefinedRoutes />
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default App
