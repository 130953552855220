import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import Detail from './Detail';
import Trend from './Trend';
import AbnormalAnalysis from './AbnormalAnalysis';
// import Improve from './Improve';
// import Event from './Event';

const Page = (props) => {
    const [selectedAbnormal, setSelectedAbnormal] = useState(null);
    const [detail, setDetail] = useState(null);
    const [timeInfo, setTimeInfo] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        const id = new URLSearchParams(props.location.search).get('id');
        let nextId = null;
        if (typeof id === 'string' && id !== "") {
            const parsed_id = parseInt(id);
            if (!isNaN(parsed_id)) {
                nextId = parsed_id;
            };
        };
        // 这里赋予默认值，为了demo，如果是直接从menu进来的，而不是跳转来的，会没有id
        if (nextId === null) nextId = 10;

        setId(nextId);
    }, []);

    return (
        <div className={style['wrapper']}>
            <Header timeInfo={timeInfo} setTimeInfo={setTimeInfo} />
            <div className="content_wrapper">
                <div className="inner_wrapper scroll_bar_restyle">
                    <div className="content">
                        <div className="left">
                            <Detail selectedAbnormal={selectedAbnormal} onChange={(v) => setSelectedAbnormal(v)} setDetail={setDetail} timeInfo={timeInfo} id={id} />
                        </div>
                        <div className="mid">
                            <div className="top">
                                <Trend selectedAbnormal={selectedAbnormal} detail={detail} timeInfo={timeInfo} id={id} />
                            </div>
                            <div className="bottom">
                                <AbnormalAnalysis selectedAbnormal={selectedAbnormal} id={id} detail={detail} />
                            </div>
                        </div>
                        {/* <div className="right">
                            <div className="top">
                                <Improve />
                            </div>
                            <div className="bottom">
                                <Event />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
