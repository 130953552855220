import { parseNode } from './utils';

const tabs = [
    {
        name: '全部',
    },
    // {
    //     name: '异常',
    // },
];

const devices_list = [
    {
        name: '中心变高压主进线',
        tag: 'H01',
        children: [
            {
                name: '中心变T1进线',
                tag: '1A_01',
                abnormal: 3,
                children: [
                    {
                        name: 'T1电容主柜',
                        tag: '1A02_1',
                    },
                    {
                        name: 'T1电容辅柜',
                        tag: '1A03_1',
                    },
                    {
                        name: 'C2/OP180',
                        tag: '1A10_1',
                    },
                    {
                        name: '制冷站水泵控制箱B',
                        tag: '1A11_9',
                    },
                    {
                        name: '螺杆冷水机组B',
                        tag: '1A11_10',
                    },
                ],
            },
            {
                name: '中心变T2进线',
                tag: '2A01',
                children: [
                    {
                        name: 'T2电容主柜',
                        tag: '2A02_1',
                    },
                    {
                        name: 'T2电容辅柜',
                        tag: '2A03_1',
                    },
                    {
                        name: '螺杆冷水机组C',
                        tag: '2A11_10',
                    },
                    {
                        name: '制冷站水泵控制箱C',
                        tag: '2A11_9',
                    },
                ],
            },
            {
                name: '1#分变-北变低压进线',
                tag: 'A009',
                children: [
                    {
                        name: '办公楼AP-01',
                        tag: 'AP_01',
                    },
                    {
                        name: '后厨DX-01',
                        tag: 'DX01',
                    },
                    {
                        name: '一车间北组合送风机MM1',
                        tag: 'A001_10',
                    },
                ],
            },
            {
                name: '2#分变-南变1低压进线',
                tag: 'A101',
                children: [
                    {
                        name: '机加区照明MXC3',
                        tag: 'A105_3',
                    },
                    {
                        name: '一车间南组合送风机MM2',
                        tag: 'A105_10',
                    },
                    {
                        name: '2#4#空压机 冷干机',
                        tag: 'A106_10',
                    },
                    {
                        name: '5#空压机 冷干机',
                        tag: 'A108_1',
                    },
                ],
            },
            {
                name: '2#分变-南变2低压进线',
                tag: 'A220',
                children: [
                    {
                        name: '1#3#空压机 冷干机',
                        tag: 'A214_11',
                    },
                    {
                        name: '螺杆冷水机组A',
                        tag: 'A217_2',
                    },
                    {
                        name: '制冷站水泵控制箱A',
                        tag: 'A217_4',
                    },
                ],
            },
            {
                name: '3#分变-二车间低压进线',
                tag: 'LI11',
                children: [
                    {
                        name: 'AP024小空调、换热站',
                        tag: 'LV14_10',
                    },
                    {
                        name: '冷区西&物流东照明',
                        tag: 'LV19_7',
                    },
                    {
                        name: '冷区东&物流西照明',
                        tag: 'LV19_4',
                    },
                    {
                        name: '组装&物流东应急照明',
                        tag: 'LV18_6',
                    },
                    {
                        name: '组装&办公西照明物流',
                        tag: 'LV18_7',
                    },
                    {
                        name: '组装区东侧照明',
                        tag: 'LV14_5',
                    },
                    {
                        name: '二车间南侧风冷机组',
                        tag: 'LV18_8',
                    },
                    {
                        name: '二车间北侧风冷机组',
                        tag: 'LV19_8',
                    },
                ],
            },
        ],
    }
];

const parsed_devices_list = devices_list.map((node) => parseNode(node));

const DefaultSelectedDevice = parsed_devices_list.length > 0 ? parsed_devices_list[0] : null;

export {
    tabs, devices_list, parsed_devices_list, DefaultSelectedDevice
};
