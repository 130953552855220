import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import Wrapper from '../../Common/Wrapper.js';
import ShiftCompareModal from './ShiftCompareModalContainer.js';
import { post } from '../../../../common/utils';
import { queryTimeRangeFormat } from '../utils.js';

import ICON_4 from '../res/single_4.png';
import './ShiftCompareBlock.scss';

const Comp = (props) => {
    const { selectedIds, timeRange } = props;
    const [visible, setVisible] = useState(false);
    const [shiftDatas, setShiftDatas] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchData = (kpi_factor_ids) => {
        // console.log('in useEffect');
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        setLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + "api/kpi/get_history_per_shift", {
            time_begin,
            time_end,
            cpl_rule_id: 1,
            kpi_factor_ids,
        })
            .then(retData => {
                // console.log(retData);
                setLoading(false);
                if (!!retData.data && retData.data.errcode === 0) {
                    setShiftDatas(retData.data.results);
                };
            });
    };
    useEffect(() => {
        fetchData(selectedIds);
        // eslint-disable-next-line
    }, [selectedIds]);
    return (
        <Wrapper icon={ICON_4} title="班组对比" titleOnClick={() => { setVisible(true) }}>
            <div className="factor_summary_v2_single_factor_detail_shift_compare_block_wrapper" style={{ position: 'relative', minHeight: 60 }}>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 12, height: 38 }} />
                            <th>班组名称</th>
                            <th>不及格指标</th>
                            <th>平均KPR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !!shiftDatas &&
                            shiftDatas
                                .map((item, index) => {
                                    const { shift_name, failed_num, avg_cpl_rate } = item;
                                    let rateText = '-';
                                    const v_parse = parseFloat(avg_cpl_rate);
                                    if (typeof v_parse === "number") {
                                        rateText = Math.floor(v_parse * 10000) / 100;
                                    };
                                    let warning = false;
                                    const n_parse = parseInt(failed_num);
                                    if (typeof n_parse === "number") {
                                        if (n_parse > 0) {
                                            warning = true;
                                        };
                                    };
                                    return (
                                        <tr key={index}>
                                            <td />
                                            <td>{shift_name}</td>
                                            <td className={warning ? "td_red" : ""}>{failed_num}</td>
                                            <td>{rateText}%</td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
            {
                visible && (
                    <ShiftCompareModal onCancel={() => { setVisible(false) }} selectedIds={selectedIds} />
                )
            }
        </Wrapper>
    );
};

export default Comp;
