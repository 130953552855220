import React from 'react';
import { withRouter } from 'react-router'
import { get } from '../../common/utils';

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const code = new URLSearchParams(this.props.location.search).get('code');
        const state = new URLSearchParams(this.props.location.search).get('state');
        console.log(code, state);

        if (typeof code === 'string' && code !== '') {
            get(process.env.REACT_APP_SERVER_PATH + `suposAccessToken/getTokenByCode/${code}`, {})
                .then(retData => {
                    console.log(retData);
                    if (!!retData.data && retData.data.errorCode === 0) {
                        const token = retData.data.result;
                        if (typeof token === 'string' && token !== '') {
                            localStorage.setItem("APP_TOKEN", token);
                            this.props.history.push('/System/MainPage');
                        };
                    };
                });
        };
    }

    render() {

        return (
            null
            // <div>
            // </div>
        );
    }
}
export default withRouter(Comp);
