import React, { useEffect, useMemo, useRef, useState } from 'react'
import style from './index.module.scss'
import { Button, Table, Space, Input, Modal, Select, message, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import OptimizationDrawer from "../MixOptimization";
import { post, get, significantNum } from 'common/utils'
import MixedBalance from "../MixedBlance";
import { withRouter } from 'react-router';
import {
    econIndexColumns, mainIndexColumns, materialColumns,
    getMaterialComColumns,
    calcId, getStyles,
} from './utils';
import { getMyPermissions } from '../../../../common/AuthPermission.js';
const { Option } = Select;

const MixDetail = (props) => {
    const [remarkText, setRemarkText] = useState(null)
    const [remarkTextTmp, setRemarkTextTmp] = useState(null)
    const [displayRemarkModal, setDisplayRemarkModal] = useState(false)
    const [materialDrawer, setMaterialDrawer] = useState(false)
    const [showOptDrawer, setShowOptDrawer] = useState(false)
    const [manganeseMine, setManganeseMine] = useState(null) // 锰矿
    const [accessories, setAccessories] = useState([]) // 辅料
    const [coke, setCoke] = useState([]) // 焦炭
    const [createTime, setCreateTime] = useState('--') // 创建时间
    const [createUser, setCreateUser] = useState('--') // 创建人
    const [snValue, setSnValue] = useState('--'); // 编号
    const [publishLoading, setPublishLoading] = useState(false) // 发布按钮的loading
    const [publishTime, setPublishTime] = useState(undefined) // 发布时间
    const [matlOutPut, setMatlOutPut] = useState([]) // output
    const [mainFactor, setMainFactor] = useState([]) // factor
    const [econIndic, setEconIndic] = useState([]) // econIndic
    const [showEditItem, setShowEditItem] = useState(false)
    const [parentData, setParentData] = useState([]) // 一级数据
    const [childrenData, setChildrenData] = useState([]) // 二级数据
    const [typeName, setTypeName] = useState('') // 矿种类型
    const [materialName, setMaterialName] = useState('') // 矿名名称
    const [fullName, setFullName] = useState('') // 原料名称
    const [loading, setLoading] = useState(false) // loading
    const [refCoke, setRefCoke] = useState('--')
    const [refSiO2, setRefSiO2] = useState('--')
    const [refDolomite, setRefDolomite] = useState('--')
    const [totalCoke, setTotalCoke] = useState('--')
    const [totalSiO2, setTotalSiO2] = useState('--')
    const [totalDolomite, setTotalDolomite] = useState('--')
    // 用于modal内可能存在的loading进行控制
    const [modalLoading, setModalLoading] = useState(false);
    // 支持修改模式
    const [editInfo, setEditInfo] = useState(null);
    const [myPermissions, _] = useState(()=>{
        return getMyPermissions();
    });

    const [result, setResult] = useState(null);

    const [ingredCalcId, setIngredCalcId] = useState(() => {
        return calcId(props);
    });
    const onClose = () => {
        setMaterialDrawer(false)
    }
    const openMaterialDrawer = () => {
        setMaterialDrawer(true)
    }
    const materialChange = (index, isUse, id) => {
        console.log(index, isUse, id)
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/matl_ingred/update_item/${ingredCalcId}`, {
            is_use: isUse === 1 ? 0 : 1,
            uuid: id
        }).then(res => {
            if (res.data.msg === 'success') {
                getMaterialData()
            } else {
                message.error('操作失败')
            }
        })
    }
    const getMaterialData = () => {
        setLoading(true)
        get(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/get_one/${ingredCalcId}`).then(res => {
            if (res.data && res.data.result) {
                const data = JSON.parse(res.data.result.matl_ingred)
                setResult(res?.data?.result ?? null);
                setRemarkText(res.data.result.remark)
                setCreateTime(res.data.result.create_time ? res.data.result.create_time : '--')
                setCreateUser(res.data.result.user_name ? res.data.result.user_name : '--')
                setSnValue(res.data.result.sn ?? '--');
                // console.log(res.data.result.publish_time)
                setPublishTime(res.data.result.publish_time)
                let material_ref = JSON.parse(res.data.result.material_ref)
                setRefCoke(material_ref.ref_Coke)
                setRefSiO2(material_ref.ref_SiO2)
                setRefDolomite(material_ref.ref_dolomite)
                setTotalCoke(material_ref.total_Coke)
                setTotalSiO2(material_ref.total_SiO2)
                setTotalDolomite(material_ref.total_dolomite)
                const matl_output = JSON.parse(res.data.result.matl_output)
                setMatlOutPut(matl_output)
                const main_factor = []
                main_factor.push(JSON.parse(res.data.result.main_factor))
                setMainFactor(main_factor)
                const econ_indic = []
                econ_indic.push(JSON.parse(res.data.result.econ_indic))
                setEconIndic(econ_indic)
                const data_1 = []
                const data_2 = []
                const data_3 = []
                data.map(item => {
                    if (item.type === '锰矿') {
                        data_1.push(item)
                    } else if (item.type === '辅料') {
                        data_2.push(item)
                    } else {
                        data_3.push(item)
                    }
                })
                setManganeseMine(data_1)
                setAccessories(data_2)
                setCoke(data_3)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const editItem = (obj) => {
        setEditInfo(obj);
        const { type, matl_name, full_name } = obj;
        // console.log(obj);
        setShowEditItem(true);
        setMaterialName(matl_name);
        setTypeName(type);
        setFullName(full_name);
        getParentData(type);
    }
    const handleOk = () => {
        // 这里查询价格，查询成功保存信息，失败提示且不保存，不关闭弹窗

        let obj = {
            type: typeName,
            matl_name: materialName,
            full_name: fullName,
            weight: ''
        }
        setModalLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + `/api/proj_puyuan/ingred_calc/query_ingred/${ingredCalcId}`, obj)
            .then(res => {
                // console.log(res);
                setModalLoading(false);
                if ((res?.data?.errcode ?? null) === 0) {
                    let data = res.data.result
                    let params = Object.assign(data, obj)
                    // 这里区分edit模式
                    if (!!editInfo) {
                        params = Object.assign(
                            {},
                            {
                                is_use: editInfo.is_use,
                                uuid: editInfo.uuid,
                            },
                            params,
                        );
                        addItem(params, true);
                        setEditInfo(null);
                    } else {
                        addItem(params);
                    };
                } else {
                    message.error('价格查询失败！')
                };
            });
    }
    const handleCancel = () => {
        setShowEditItem(false)
    }
    const getParentData = (typeName) => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/form/get_dict_items', {
            "sn": "MATL_CAT_TREE"
        })
            .then(res => {
                if ((res?.data?.errcode ?? null) === 0) {
                    const data = res.data.results;
                    let list = [];
                    data.some(item => {
                        if (item.key === typeName) {
                            list = item.children;
                            return true;
                        };
                        return false;
                    });
                    setMaterialName((prevMatlName) => {
                        if (list.map(({ value }) => value).includes(prevMatlName)) {
                            getChildrenData(typeName, prevMatlName, '');
                            return prevMatlName;
                        };
                        return '';
                    });
                    setParentData(list);
                } else {
                    // 获取失败
                    message.warning("获取数据失败，请重试");
                };
            });
    }
    const handleChildrenChange = (value) => {
        setFullName(value)
        // 原来是在这里查询价格，现在改成点击确定时查询
        // getItemData(value)
    }
    const handleParentChange = (par) => {
        setMaterialName(par)
        setFullName('')
        getChildrenData(typeName, par, '')
    }
    const getChildrenData = (parentName, childrenName, keyword) => {
        // console.log(materialName, fullName)
        post(process.env.REACT_APP_SERVER_PATH + "api/proj_puyuan/get_material_list", {
            "type": parentName || '',
            "matl_name": childrenName || '',
            "keyword": keyword || ''
        })
            .then(res => {
                if ((res?.data?.errcode ?? null) === 0) {
                    const data = res.data.results ?? [];
                    setFullName((prevFullName) => {
                        if (data.map(({ full_name }) => full_name).includes(prevFullName)) {
                            return prevFullName;
                        };
                        return '';
                    });
                    setChildrenData(data);
                } else {
                    // 失败
                    message.warning("获取信息失败，请重试！");
                    setFullName('');
                    setChildrenData([]);
                };
            });
    }
    // 添加一行
    const addItem = (obj, edit = false) => {
        if (fullName === '') {
            message.error('原料名不能为空')
            return
        }
        obj = Object.assign(
            {},
            {
                is_use: 1,
            },
            obj,
        );
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/matl_ingred/${edit ? "update_item" : 'add_item'}/${ingredCalcId}`, obj)
            .then(res => {
                if ((res?.data?.errcode ?? null) === 0) {
                    setShowEditItem(false);
                    getMaterialData();
                } else {
                    message.warning('保存失败！')
                };
            });
    }
    // 添加类型
    const addType = (type) => {
        setTypeName(type)
        setShowEditItem(true)
        setMaterialName('')
        setFullName('')
        getParentData(type)
    }
    // 删除
    const deleteItem = (delId) => {
        Modal.confirm({
            content: `确认删除吗?`,
            onOk: () => {
                setLoading(true)
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/matl_ingred/delete_item/${ingredCalcId}`, {
                    uuid: delId
                }).then(res => {
                    setLoading(false);
                    if (res.data.msg === 'success') {
                        message.success("删除成功!");
                        getMaterialData()
                    } else {
                        message.error("删除失败!");
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
        });
    }
    // 上移 下移
    const moveItem = (moveId, direction) => {
        setLoading(true)
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/matl_ingred/move_item/${ingredCalcId}`, {
            uuid: moveId,
            direction: direction
        }).then(res => {
            setLoading(false)
            if (res.data.msg === 'success') {
                getMaterialData()
            } else {
                message.error("移动失败")
            }
        })
    }
    // 发布状态
    const updatePubStatus = (status) => {
        setPublishLoading(true)
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/update_pub_status/${ingredCalcId}`, {
            publish_status: status
        }).then(res => {
            setPublishLoading(false)
            setPublishTime(undefined)
            if (res.data.msg === 'success') {
                getMaterialData()
                message.success('操作成功')
            } else {
                message.error('操作失败')
            }
        })
    }
    const keySearch = (val) => {
        getChildrenData(typeName, materialName, val)
    }
    const balanceCalculation = () => {
        setLoading(true)
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/calc_balance/${ingredCalcId}`)
            .then(res => {
                setLoading(false)
                if ((res?.data?.errcode ?? null) === 0) {
                    message.success('计算成功');
                    // 计算成功刷新数据
                    getMaterialData();
                } else {
                    message.warning('请求计算失败');
                };
            });
    }

    // 通用Modal，修改数字
    const [modifyInfo, setModifyInfo] = useState(null);
    const modifyRef = useRef(null);
    const handleModifyOk = () => {
        let value = modifyInfo?.value ?? null;
        if (value === "") value = null;
        if (value !== null) {
            value = parseFloat(value);
            if (typeof value !== 'number' || isNaN(value)) {
                message.warning("格式有误，请确认后重试");
                return;
            };
        };
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/matl_ingred/update_item/${ingredCalcId}`, {
            [modifyInfo.key]: value,
            uuid: modifyInfo.uuid,
        }).then(res => {
            if (res?.data?.errcode === 0) {
                message.success('保存成功');
                getMaterialData();
                setModifyInfo(null);
            } else {
                message.error('保存失败')
            }
        })
    };
    const handleModifyCancel = () => {
        setModifyInfo(null);
    };
    const changeModifyValue = (e) => {
        const value = e.target.value;
        setModifyInfo(prevModifyInfo => {
            if (!!prevModifyInfo) {
                return Object.assign(
                    {},
                    prevModifyInfo,
                    {
                        value,
                    },
                );
            };
            return prevModifyInfo;
        });
    };
    const valueModify = (key, record, title) => {
        const value = record?.[key] ?? null;
        const uuid = record?.uuid ?? null;
        return () => {
            setModifyInfo({
                key,
                uuid,
                title: title ?? key,
                value,
            });
            setTimeout(() => {
                if (!!(modifyRef?.current ?? null)) {
                    modifyRef.current.focus({ cursor: "all" });
                };
            }, 0);
        };
    };

    const saveRemark = ()=>{
        setLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/ingred_calc/patch_one/${ingredCalcId}`, {
            remark: remarkTextTmp
        }).then(res => {
            setLoading(false);
            if (res?.data?.errcode === 0) {
                message.success("操作成功");
                getMaterialData();
                setDisplayRemarkModal(false);
            } else {
                message.warning("操作失败");
            };
        });
    };

    useEffect(() => {
        setIngredCalcId(calcId(props));
    }, [props]);
    useEffect(() => {
        getMaterialData()
    }, [])

    const [style1, style2, style3] = useMemo(() => {
        return getStyles(totalSiO2, refSiO2, totalDolomite, refDolomite, totalCoke, refCoke);
    }, [totalSiO2, refSiO2, totalDolomite, refDolomite, totalCoke, refCoke]);

    const materialComColumns = getMaterialComColumns({
        materialChange,
        editItem,
        moveItem,
        deleteItem,
        valueModify,
    });

    const [watchInfo, setWatchInfo] = useState(null);
    const watchBtn = () => {
        setWatchInfo({});
    };

    return (
        <div className={style['detailWrapper']}>
            <div className="detailWrapper-header">
                <div className="detailWrapper-header-left">
                    <Space>
                        <span className="parent-bread" style={{ color: 'rgba(0,0,0,.45)' }}>轻环+ /</span>
                        <span
                            className="parent-bread clickable-bread"
                            onClick={() => {
                                props.history.push("/PEIMSV2/Mixed");
                            }}
                        > 配料优化</span><span>/&nbsp;&nbsp;配料详情</span>
                    </Space>
                </div>
                <div className="detailWrapper-header-right"></div>
            </div>
            <div className="detailWrapper-container">
                <div className="wrapper">
                    <div className="wrapper-header">
                        <div className="wrapper-header-left">
                            <span className="page-mixed-title">入炉原料成分计算</span>
                            <Button type="primary" ghost style={{ marginLeft: 16 }} onClick={openMaterialDrawer}>物料平衡</Button>
                            <Button type="primary" style={{ marginLeft: 14 }} onClick={balanceCalculation}>平衡计算</Button>
                            <div className="vertical-divider"></div>
                            <Button type="primary" ghost style={{ marginLeft: 18 }} onClick={() => setShowOptDrawer(true)}>优化配料</Button>
                            <div className='remark-box'>
                                {remarkText && typeof(remarkText) === 'string' && remarkText.trim() != '' && <span>{remarkText}</span>}
                                <span className="remark-btn" onClick={()=>{setRemarkTextTmp(remarkText); setDisplayRemarkModal(true);}}>备注</span>
                            </div>
                        </div>
                        <div className="wrapper-header-right">
                            {publishTime === null && <Button icon={<UploadOutlined />} loading={publishLoading} type="link" style={{ marginLeft: 12, marginRight: 12 }} onClick={_ => updatePubStatus(true)}>发布配料</Button>}
                            {publishTime && <Button loading={publishLoading} type="default" style={{ marginLeft: 12, marginRight: 12 }} onClick={_ => updatePubStatus(false)}>撤销发布</Button>}
                            <span style={{ marginRight: 18 }}>编号：{snValue}</span>
                            <span style={{ marginRight: 18 }}>创建人：{createUser}</span>
                            <span>创建时间：{createTime}</span>
                            <Button type="primary" ghost style={{ marginLeft: 18 }} onClick={watchBtn}>查看料比</Button>
                        </div>
                    </div>
                    <div className="wrapper-table" style={{paddingBottom: "20px"}}>
                        <Table locale={{ emptyText: <div style={{ padding: "24px 0" }}>请添加锰矿</div> }} rowKey="uuid" dataSource={manganeseMine} columns={materialComColumns} pagination={false} size="small" key="1" loading={loading} />
                        <Space style={{ marginTop: 8, marginBottom: 8 }}><Button type="link" onClick={() => addType('锰矿')}>+添加锰矿</Button></Space>

                        <Table style={{ borderTop: "1px solid #eee", marginTop: 24 }} locale={{ emptyText: '请添加辅料' }} rowKey="uuid" dataSource={accessories} columns={materialComColumns} pagination={false} size="small" showHeader={false} key="2" loading={loading} />
                        <Space style={{ marginTop: 8, marginBottom: 8 }}><Button type="link" onClick={() => addType('辅料')}>+添加辅料</Button></Space>

                        <Table style={{ borderTop: "1px solid #eee", marginTop: 24 }} locale={{ emptyText: '请添加焦炭' }} rowKey="uuid" dataSource={coke} columns={materialComColumns} pagination={false} size="small" showHeader={false} key="3" loading={loading} />
                        <Space style={{ marginTop: 8, marginBottom: 8 }}><Button type="link" onClick={() => addType('焦炭')}>+添加焦炭</Button></Space>

                        <div className="wrapper-table-total">
                            <Space>
                                <span>当前硅石总量：<span style={style1}>{typeof totalSiO2 === 'number' ? significantNum(totalSiO2) : '--'}</span></span>
                                <span>推荐硅石总量：<span style={style1}>{typeof refSiO2 === 'number' ? significantNum(refSiO2) : '--'}</span></span>
                                <span>当前白云石总量：<span style={style2}>{typeof totalDolomite === 'number' ? significantNum(totalDolomite) : '--'}</span></span>
                                <span>推荐白云石总量：<span style={style2}>{typeof refDolomite === 'number' ? significantNum(refDolomite) : '--'}</span></span>
                                <span>当前焦炭总量：<span style={style3}>{typeof totalCoke === 'number' ? significantNum(totalCoke) : '--'}</span></span>
                                <span>推荐总量：<span style={style3}>{typeof refCoke === 'number' ? significantNum(refCoke) : '--'}</span></span>
                            </Space>
                        </div>
                        <div className="wrapper-table-title">入炉原料合计</div>
                        <Table
                            rowKey="name"
                            dataSource={matlOutPut}
                            columns={materialColumns.filter(item=>{
                                if(item['dataIndex'] == 'unit_price'){
                                    if(myPermissions.includes('IngredCalc/SHOW_MATL_PRICE')){
                                        return true;
                                    }else{
                                        return false;
                                    }
                                }else{
                                    return true;
                                }
                            })}
                            pagination={false}
                            size="small"
                            style={{ width: `80%` }}
                        />
                        <div className="wrapper-table-title">入炉原料主要指标</div>
                        <Table dataSource={(mainFactor ?? []).map((item, index) => Object.assign({}, item, { key: index + "" }))} columns={mainIndexColumns} pagination={false} size="small" style={{ width: `60%` }} />
                        <div className="wrapper-table-title">入炉原料主要经济指标</div>
                        <Table dataSource={(econIndic ?? []).map((item, index) => Object.assign({}, item, { key: index + "" }))} columns={econIndexColumns} pagination={false} size="small" style={{ width: `40%` }} />
                    </div>
                </div>
            </div>
            <OptimizationDrawer
                isShow={showOptDrawer}
                closeDrawer={(updatePage) => {
                    setShowOptDrawer(false);
                    if (updatePage === true) {
                        getMaterialData();
                    }
                }}
                ingredCalcId={ingredCalcId}
            />
            <MixedBalance
                isShow={materialDrawer}
                closeDrawer={onClose}
                ingredCalcId={ingredCalcId}
            />
            <Modal
                title="选择原料"
                visible={showEditItem}
                onOk={handleOk}
                {
                ...(
                    modalLoading ? {} : {
                        onCancel: handleCancel
                    }
                )
                }
                cancelButtonProps={{ disabled: modalLoading }}
                okButtonProps={{ disabled: modalLoading }}
                width={750}
            >
                <Spin spinning={modalLoading}>
                    <Space>
                        <span>{typeName}</span>
                        <Select style={{ width: 150 }} onChange={handleParentChange} value={materialName}>
                            {
                                parentData && parentData.map(item => {
                                    return (
                                        <Option value={item.value} key={item.key}>{item.title}</Option>
                                    )
                                })
                            }
                        </Select>
                        <Select
                            showSearch
                            filterOption={false}
                            style={{ width: 450 }}
                            value={fullName}
                            onChange={handleChildrenChange}
                            onSearch={(val) => keySearch(val)}
                        >
                            {
                                childrenData && childrenData.map((item, index) => {
                                    return (
                                        <Option value={item.full_name} key={index}>{item.full_name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Space>
                </Spin>
            </Modal>
            {
                !!modifyInfo && (
                    <Modal title={`修改${modifyInfo.title ?? ""}`} visible={true} onOk={handleModifyOk} onCancel={handleModifyCancel} width={340}>
                        <Space>
                            <span>{modifyInfo.title ?? "--"}：</span>
                            <Input value={modifyInfo.value ?? null} style={{ width: 200 }} onChange={changeModifyValue} ref={modifyRef} onPressEnter={handleModifyOk} />
                        </Space>
                    </Modal>
                )
            }
            {
                !!displayRemarkModal && (
                    <Modal title="修改备注" visible={true} onOk={saveRemark} onCancel={()=>setDisplayRemarkModal(false)} width={340} confirmLoading={loading}>
                        <Space>
                            <Input value={remarkTextTmp} style={{ width: "300px" }} onChange={e=>setRemarkTextTmp(e.target.value)} onPressEnter={saveRemark} />
                        </Space>
                    </Modal>
                )
            }
            {
                !!watchInfo && (
                    <Modal
                        title="配料明细"
                        visible={true}
                        footer={null}
                        onCancel={() => setWatchInfo(null)}
                        width={600}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 12,
                        }}>
                            <Space>
                                <span>系统名称：</span>
                                <span>{result?.furnace_no ?? "--"}#炉</span>
                            </Space>
                            <Space>
                                <span>发布时间：</span>
                                <span>{createTime}</span>
                            </Space>
                            <Space>
                                <span>发布人：</span>
                                <span>{createUser}</span>
                            </Space>
                        </div>
                        <Table
                            columns={[
                                {
                                    title: "矿名",
                                    dataIndex: "matl_name",
                                    align: "center",
                                    width: 100,
                                },
                                {
                                    title: ()=>(
                                        <div style={{ textAlign: 'center' }}>原料</div>
                                    ),
                                    dataIndex: "full_name",
                                },
                                {
                                    title: "实际料比",
                                    dataIndex: "weight",
                                    align: "center",
                                    width: 90,
                                }
                            ]}
                            dataSource={
                                []
                                    .concat(
                                        manganeseMine ?? [], accessories ?? [], coke ?? [],
                                    )
                                    .map((item, index) => Object.assign({}, item, { key: index + "" }))
                            }
                            pagination={false}
                            size="small"
                        />
                    </Modal>
                )
            }
        </div>
    )
}

export default withRouter(MixDetail);
