import React, { useRef, useEffect, useState, useMemo } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import { useData } from './hooks';
import { Button, Select, Spin } from 'antd';
import ICON from './res/icon.svg';
import ICON_CANCEL from './res/cancel.svg';
import DomResize from 'components/DomResize';
import SortIcons from 'components/SortIcons';

const { Option } = Select;

const margin_width = 16;

const tabs = [
    {
        name: '图表',
        id: 1,
    },
    {
        name: '表格',
        id: 2,
    },
]
    .map((item, index) => Object.assign({}, item, { key: index + "" }));

const default_func = () => { };

const getNextInfo = (list, prevSelected, key) => {
    let nextInfo = null;
    if (!!list) {
        const parsed_list = list
            .map((item, index) => Object.assign({}, item, { key: index + "" }));
        nextInfo = Object.assign({}, nextInfo ?? {}, {
            selected: null,
            list: parsed_list,
        });
        if (!!prevSelected) {
            // 上次存在选中的系统，判断上次选中系统的id在目前的系统列表中是否存在一致的，如果有，继承???
            const nextSelected = parsed_list.filter(({ [key]: id }) => id === prevSelected[key])[0] ?? null;
            nextInfo = Object.assign({}, nextInfo, { selected: nextSelected });
        };
    };
    return nextInfo;
};

const Comp = (props) => {
    const { timeInfo, sysInfo } = props;

    const [sortInfo, setSortInfo] = useState(null);
    const sortOnClick = (key) => {
        setSortInfo((prevSortInfo) => {
            let nextSortInfo = null;
            if (typeof key === 'string') {
                let nextIsDesc = false;
                if (!!prevSortInfo) {
                    if (prevSortInfo.key === key) {
                        nextIsDesc = !prevSortInfo.is_desc;
                    };
                };
                nextSortInfo = { key, is_desc: nextIsDesc };
            };
            return nextSortInfo;
        });
    };

    const setSelectedFactor = useRef(default_func);
    useEffect(() => {
        setSelectedFactor.current = typeof props.setSelectedFactor === 'function' ? props.setSelectedFactor : default_func;
    }, [props]);

    const [selectedTab, setSelectedTab] = useState(null);
    useEffect(() => {
        setSelectedTab((prevSelectedTab) => {
            if (!prevSelectedTab) return tabs[0];
            return prevSelectedTab;
        })
    }, []);

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(default_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : default_func;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current(Object.assign({}, timeInfo ?? {}, sysInfo ?? {}));
    }, [timeInfo, sysInfo]);

    const [filteredData, setFilteredData] = useState(null);
    useEffect(() => {
        setFilteredData((prevFilteredData) => {
            if (!data) {
                return null;
            };
            const nextCatInfo = getNextInfo(data?.category_list, prevFilteredData?.catInfo?.selected ?? null, 'category');
            return Object.assign({}, data, {
                catInfo: nextCatInfo,
            });
        });
    }, [data]);
    const catInfo = useMemo(() => {
        return filteredData?.catInfo ?? null;
    }, [filteredData]);

    const [dataInfo, setDataInfo] = useState(null);
    useEffect(() => {
        let nextList = filteredData?.parsed_results ?? [];
        const selected_cat_category = filteredData?.catInfo?.selected?.category ?? null;
        nextList = nextList
            .filter(({ category }) => category === (selected_cat_category ?? category));
        if (!!sortInfo) {
            const { key, is_desc } = sortInfo;
            nextList = []
                .concat(nextList)
                .sort(({ [key]: v1 }, { [key]: v2 }) => {
                    const v_1 = is_desc ? v1 : v2;
                    const v_2 = is_desc ? v2 : v1;
                    if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                        return v_2 - v_1;
                    };
                    if (typeof v1 === 'number') return -1;
                    if (typeof v2 === 'number') return 1;
                    return 0;
                    // 1是交换
                });
        };
        // 不论其他排序如何，空avg_value总是置底
        nextList = []
            .concat(nextList)
            .sort(({ avg_value: v_1 }, { avg_value: v_2 }) => {
                if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                    return 0;
                };
                if (typeof v_1 === 'number') return -1;
                if (typeof v_2 === 'number') return 1;
                return 0;
                // 1是交换
            });
        setDataInfo((prevDataInfo) => {
            const prevSelected = prevDataInfo?.selected ?? null;
            // 继承上一次的selected,继承指标id
            // 这里加一个特性，非个人取消的情况下，默认select第一个
            const currSelected = nextList.filter(({ id }) => id === (prevSelected?.id ?? null))[0] ?? (nextList[0] ?? null);
            return Object.assign({}, {
                selected: currSelected,
                list: nextList,
            });
        });
    }, [filteredData, sortInfo]);

    const itemOnClick = (item) => {
        setSelectedTab(item);
    };

    const [width, setWidth] = useState(null);
    const onResize = (conf) => {
        setWidth(conf.width);
        // 内或外宽度变了之后需要判断横向scroll 位置
    };

    const trItemOnClick = (item) => {
        setDataInfo((prevDataInfo) => {
            if (!prevDataInfo) return prevDataInfo;
            return Object.assign({}, prevDataInfo ?? {}, { selected: item });
        });
    };

    useEffect(() => {
        // 单纯通知外部，不受外部影响
        let selected = dataInfo?.selected;
        if (!!selected) {
            selected = Object.assign(selected, { timeInfo });
        };
        setSelectedFactor.current(selected ?? null);
    }, [dataInfo]);


    const filterOnChange = (filterInfoKey, v) => {
        setFilteredData((prevFilteredData) => {
            const prevInfo = prevFilteredData?.[filterInfoKey];
            // 方法严谨
            if (!prevInfo) return prevFilteredData;
            const nextSelected = prevInfo.list.filter(({ key }) => key === v)[0] ?? null;//有可能 all 然后就是null
            return Object.assign({}, prevFilteredData, {
                [filterInfoKey]: Object.assign({}, prevInfo, {
                    selected: nextSelected,
                }),
            });
        });
    };
    const catOnChange = (v) => {
        filterOnChange("catInfo", v);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="系统指标"
                watchDetail={false}
                subtitle={(
                    <div className={style["subtitle"]}>
                        <div className="left">
                            <div className="text_item green">
                                <div className="title">已完成指标：</div>
                                <div className="value">25</div>
                                <div className="unit">项</div>
                            </div>
                            <div className="space"></div>
                            <div className="text_item red">
                                <div className="title">未完成指标：</div>
                                <div className="value">0</div>
                                <div className="unit">项</div>
                            </div>
                            <div className="space"></div>
                            <div className="select_item">
                                <div className="title">指标类型：</div>
                                <div className="selector">
                                    <Select value={catInfo?.selected?.key ?? "all"} style={{ width: 120 }} onChange={catOnChange}>
                                        <Option value="all">全部类型</Option>
                                        {
                                            (catInfo?.list ?? [])
                                                .map((item, index) => {
                                                    const { key, name } = item;
                                                    return (
                                                        <Option value={key} key={index}>{name}</Option>
                                                    );
                                                })
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <Button type="primary" className="btn">导出</Button>
                            <div className="tabs">
                                {
                                    tabs
                                        .map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={"item" + (item.key === selectedTab?.key ? " selected" : "")}
                                                    onClick={() => itemOnClick(item)}
                                                >
                                                    <div className="text">{item.name}</div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                )}
            >
                <div className={style['content']}>
                    <div className="resize">
                        <DomResize onResize={onResize} mountedCall={true} />
                    </div>
                    {
                        typeof width === 'number' && (
                            <div className="inner_wrapper">
                                <div className="mid scroll_bar_restyle">
                                    <table style={{ width: width - margin_width }} className={style["table"]}>
                                        <thead>
                                            <tr>
                                                <th className="name">指标名称</th>
                                                <th>单位</th>
                                                <th>标准值</th>
                                                <th>指标均值</th>
                                                <th className="sort">
                                                    <div className="wrapper" onClick={() => sortOnClick("cpl_rate")}>
                                                        <div className="content">
                                                            <div className="text">合格率</div>
                                                            <SortIcons is_desc={sortInfo?.key === "cpl_rate" ? sortInfo?.is_desc : null} />
                                                        </div>
                                                    </div>
                                                </th>
                                                {
                                                    selectedTab?.id === 1 ? [
                                                        (
                                                            <th className="bar" key="1">
                                                                <div className="wrapper">
                                                                    {
                                                                        ['00:00', '早班', '08:00', '中班', '16:00', '晚班', '24:00']
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <div className={"item" + (index % 2 === 1 ? " bold" : "")} key={index}>
                                                                                        <div className="text">{item}</div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            </th>
                                                        ),
                                                        (
                                                            <th className="space" key="2"></th>
                                                        ),
                                                    ] : (
                                                        selectedTab?.id === 2 ? (
                                                            ['早班', '中班', '晚班']
                                                                .map((item, index, arr) => {
                                                                    return (
                                                                        <th key={index} className={"shift" + (index === arr.length - 1 ? " last" : "")} colSpan="2">
                                                                            <div className="wrapper">
                                                                                <div className="name">{item}</div>
                                                                                <div className="subtitle">（指标均值与合格率）</div>
                                                                            </div>
                                                                        </th>
                                                                    );
                                                                })
                                                        ) : null
                                                    )
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (dataInfo?.list ?? [])
                                                    .map((item, index) => {
                                                        const {
                                                            name, unit, parsed_avg_value, parsed_standard,
                                                            parsed_cpl_rate, is_good_cpl,
                                                            parsed_cpl_results,
                                                        } = item;
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className={(dataInfo?.selected?.key ?? null) === item.key ? " selected" : ""}
                                                                onClick={() => trItemOnClick(item)}
                                                            >
                                                                <td className="name">{name}</td>
                                                                <td>{unit}</td>
                                                                <td>{parsed_standard}</td>
                                                                <td>{parsed_avg_value}</td>
                                                                <td className={"cpl_rate" + (is_good_cpl === false ? " red" : "")}>{parsed_cpl_rate}</td>
                                                                {
                                                                    selectedTab?.id === 1 ? [
                                                                        (
                                                                            <td className="bar" key="1">
                                                                                {
                                                                                    !!parsed_cpl_results && (
                                                                                        <div className="wrapper">
                                                                                            {
                                                                                                parsed_cpl_results
                                                                                                    .map((item, index) => {
                                                                                                        return (
                                                                                                            <div className="item" key={index}>
                                                                                                                {
                                                                                                                    item
                                                                                                                        .map((color_item, color_item_index) => {
                                                                                                                            const { color, left, width } = color_item;
                                                                                                                            return (
                                                                                                                                <div
                                                                                                                                    className="color_item"
                                                                                                                                    style={{
                                                                                                                                        backgroundColor: color,
                                                                                                                                        left,
                                                                                                                                        width,
                                                                                                                                    }}
                                                                                                                                    key={color_item_index}
                                                                                                                                ></div>
                                                                                                                            );
                                                                                                                        })
                                                                                                                }
                                                                                                            </div>
                                                                                                        );
                                                                                                    })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        ),
                                                                        (
                                                                            <td key="2"></td>
                                                                        ),
                                                                    ] : (
                                                                        selectedTab?.id === 2 ? (
                                                                            ['早班', '中班', '晚班']
                                                                                .map((_, index, arr) => {
                                                                                    return [
                                                                                        (
                                                                                            <td key={`${index}-1`} className="avg">{item[`parsed_avg_${index}`]}</td>
                                                                                        ),
                                                                                        (
                                                                                            <td key={`${index}-2`} className={"cpl_rate rate" + (index === arr.length - 1 ? " last" : "") + (item[`is_good_cpl_${index}`] === false ? " red" : "")}>{item[`parsed_cpl_${index}`]}</td>
                                                                                        ),
                                                                                    ];
                                                                                })
                                                                        ) : null
                                                                    )
                                                                }
                                                                {/* <td className="action">
                                                                    {
                                                                        (dataInfo?.selected?.key ?? null) === item.key ? (
                                                                            <img alt="" src={ICON_CANCEL} className="cancel" onClick={() => trItemOnClick(null)} />
                                                                        ) : (
                                                                            <div className="btn" onClick={() => trItemOnClick(item)}>查看趋势</div>
                                                                        )
                                                                    }
                                                                </td> */}
                                                            </tr>
                                                        );
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="bottom"></div>
                            </div>
                        )
                    }
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div >
    );
};

export default Comp;
