import React from 'react'
import style from './index.module.scss'
import TopBreadcrumb from 'components/TopBreadCrumb'
import PureDnd from 'containers/PureDnD/index.js'
import TimeSelector from './TimeSelector/index.js'
import moment from 'moment'
import { post } from 'common/utils'

export default class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeInfo: null,
      range: null
    }
  }

  componentDidMount() { }

  setTimeInfo = (nTInfo) => {
    this.setState({ timeInfo: nTInfo }, this.updateRange)
  }

  updateRange = () => {
    const { timeInfo } = this.state
    let nRange = null
    if (timeInfo) {
      const datetime = moment(timeInfo.time).format("YYYY-MM-15 00:00:00")
      post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
        .then(retData => {
          // console.log(retData)
          if (retData?.data?.errcode === 0) {
            const monthRange = retData?.data?.results?.month_range ?? null
            if (monthRange) {
              nRange = monthRange.filter((item, index) => index <= 1)
            }
          }
          this.setState({ range: nRange })
        })
    }
  }

  render() {
    const breadcrumbList = [{ name: '电能统计' }, { name: '电能计划' }]
    const { timeInfo, range } = this.state

    return (
      <div className={style['wrapper']}>
        <div className="tabs_wrapper">
          <TopBreadcrumb breadcrumbList={breadcrumbList} />
          <div className='right'>
            <TimeSelector timeInfo={timeInfo} setTimeInfo={this.setTimeInfo} />
          </div>
        </div>
        <div className="content_wrapper">
          <PureDnd id={12} range={range} />
        </div>
      </div>
    )
  }
}
