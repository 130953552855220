import { useEffect, useState } from "react"
import { post } from 'common/utils';
import moment from "moment";

const initListFunc = () => null
const Func = (sysInfo, range) => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState(initListFunc)
    useEffect(() => {
        const fnc_id = sysInfo?.id ?? null
        if (typeof fnc_id === 'number') {
            let delay = true
            const start_time = moment(range[0]).format("YYYY-MM-DD 00:00:00")
            const end_time = moment(range[1]).format("YYYY-MM-DD 23:59:59")
            setLoading(true)
            post(process.env.REACT_APP_SERVER_PATH + 'tengfei_api/get_fnckpi_trend', {
                fnc_id,
                start_time,
                end_time
            })
                .then(retData => {
                    if (delay) {
                        setLoading(false)
                        // console.log(retData)
                        if (retData?.data?.errcode === 0) {
                            const { data, fnc_kpi } = (retData?.data?.results ?? {});
                            if (data && fnc_kpi && data.length === fnc_kpi.length + 3) {
                                const checkFailed = data.some(item => {
                                    if ((item?.data_list ?? []).length !== (data?.[0]?.data_list ?? []).length) {
                                        return true
                                    }
                                    return false
                                })
                                if (!checkFailed) {
                                    const countList = data.find(({ tag_name }) => tag_name === 'fnc_count')?.data_list ?? null
                                    const startTimeList = data.find(({ tag_name }) => tag_name === 'start_time')?.data_list ?? null
                                    const endTimeList = data.find(({ tag_name }) => tag_name === 'end_time')?.data_list ?? null
                                    const list = fnc_kpi
                                        .map(item => {
                                            const tagName = Object.keys(item)?.[0] ?? null
                                            const name = item?.[tagName] ?? null
                                            const data_list = (data.find(({ tag_name }) => tag_name === tagName)?.data_list ?? [])
                                                .map((val, index) => {
                                                    let start_time = moment(startTimeList?.[index])
                                                    if (start_time.isValid()) {
                                                        start_time = start_time.format("YYYY-MM-DD HH:mm")
                                                    }
                                                    let end_time = moment(endTimeList?.[index])
                                                    if (end_time.isValid()) {
                                                        end_time = end_time.format("YYYY-MM-DD HH:mm")
                                                    }
                                                    return {
                                                        value: val,
                                                        count: countList?.[index] ?? null,
                                                        start_time,
                                                        end_time
                                                    }
                                                })
                                            return { tag_name: tagName, name, data_list }
                                        })
                                    setList(list)
                                    return
                                }
                            }
                        }
                        setList(null)
                    }
                })
            return () => {
                delay = false
            }
        } else {
            setList(null)
        }
    }, [sysInfo, range])
    return [list, loading]
}
export default Func