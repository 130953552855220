import { Route, Switch } from 'react-router-dom'

import AlarmPushRuleList from 'containers/Alarm/AlarmPushRule/RuleList.js'
import AlarmPushRuleDetails from 'containers/Alarm/AlarmPushRule/RuleDetails.js'
import DnDPage from 'containers/DnD'
import ScreenList from 'containers/ScreenList'
import BoardEdit from 'containers/BoardEdit'
import SchedulingManagement from 'containers/SchedulingManagement'

import DiagramList from 'containers/DiagramList/DiagramList.js'
import OnlineDiskWrapper from 'containers/OnlineDiskWrapper'
import ElecManagement from 'containers/DemoSystem/ElecManagement'

// demo system ver.2
import DemoSystemV2 from 'containers/DemoSystemV2'
import Overview from 'containers/FactorRelated/Overview'
import Ingred from 'containers/DemoSystemV2/Ingred'
import FactorAnalysis from 'containers/DemoSystemV2/Analysis'
import ProductCost from 'containers/DemoSystemV2/ProductCost'
import FactorStatisticsV2 from 'containers/DemoSystemV2/FactorStatistics'
import FactorPredict from 'containers/DemoSystemV2/FactorPredict'
import EventBoard from 'containers/DemoSystemV2/EventCapsules/EventBoard'
import EventSummary from 'containers/DemoSystemV2/EventCapsules/EventSummary'
import AutoReport from 'containers/DemoSystemV2/AutoReport'
import AlarmPage from 'containers/Cockpit/AlarmPage/AlarmPage.js'
import User from 'containers/PersonMng'
import Role from 'containers/RoleMngSetting'
import Department from 'containers/SystemSetting/OrgMng'
import { EliminationPeriod, ExamRules, RaceList } from 'containers/DemoSystemV2/Assess'
import {
    Energy,
    ExamAnalysis,
    SampleMaintenance
} from 'containers/DemoSystemV2/OperateBoards'
import HistoryDataV2 from 'containers/DemoSystemV2/HistoryData/HistoryData'
import ProcessPage from 'containers/DemoSystemV2/Process'
import DeviceStatus from 'containers/DemoSystemV2/DeviceStatus'
import AlarmSettings from 'containers/DemoSystemV2/AlarmSetting/Content'
import PersonalCenter from 'containers/DemoSystemV2/PersonalCenter'
import OperatingBoard from 'containers/FactorRelated/OperatingBoard'
import RuleList from 'containers/ExamV2/ExamRule/RuleList.js'
import ExamDailyScore from 'containers/Exam/ExamDailyScore/ExamDailyScore.js'
import ScreenShow from 'containers/DemoSystemV2/ScreenShow';
import ControllerBoard from 'containers/DemoSystemV2/ControllerBoard';
import EventView from 'containers/DemoSystemV2/EventEnter/EventView'
import EventViewNoTree from 'containers/DemoSystemV2/EventEnter/NoTreeWrapper';
import EventForm from 'containers/DemoSystemV2/EventEnter/EnterForm'
import FormTplList from 'containers/FormTemplate/FormTplList.js'
import EventSchema from 'containers/Event/SchemaWrapper';
import DCSBoard from 'containers/DCSBoard';
import DCSBoardV2 from 'containers/DCSBoardV2/index.js';
import MetaDataList from 'containers/MetaData/MetaDataList.js';
import LogCenter from 'containers/System/LogCenter/LogCenter.js'
import SPEC from 'containers/DemoSystemV2/SPEC';

// Iron
import IronSettings from "containers/DemoSystemV2/IronPanel/IrnSettings";
import IronData from "containers/DemoSystemV2/IronPanel/IronData";

// Mixed
import MixedPanel from "containers/DemoSystemV2/MixedIngredients";
import MixDetail from "containers/DemoSystemV2/MixedIngredients/MixedDetail"

import UnderConstruction from 'containers/UnderConstruction';
import ProcessBoard from 'containers/ProcessBoard';

import MetaDataMng from 'containers/MetaDataMng/MetaDataList.js'

// 海螺大屏
import ScreenCarbon from 'containers/ThreeScreen/ScreenCarbon';
import ScreenTrend from 'containers/ThreeScreen/ScreenTrend';
import ScreenMain from 'containers/ThreeScreen/ScreenMain';
import ThreeScreen from 'containers/ThreeScreen';

import ElecMonitor from 'containers/ElecMonitorV2/index.js';
import ElecStatistics from 'containers/ElecStatistics/index.js';
import ElecCharts from 'containers/ElecCharts/index.jsx'

import FncAnalysis from 'containers/FncAnalysis/index.jsx'
import CollapseMonitor from 'containers/CollapseMonitor/index.jsx'

const Routes = () => {
    return (
        <Switch>
            <Route path="/PEIMSV2">
                <DemoSystemV2>
                    <Switch>
                        <Route exact path="/PEIMSV2/UnderConstruction" component={UnderConstruction} />
                        <Route exact path="/PEIMSV2/Overview" component={Overview} />
                        <Route exact path="/PEIMSV2/OperatingBoard" component={OperatingBoard} />
                        <Route exact path="/PEIMSV2/Analysis" component={FactorAnalysis} />
                        <Route exact path="/PEIMSV2/ProductCost" component={ProductCost} />
                        <Route exact path="/PEIMSV2/FactorStatistics" component={FactorStatisticsV2} />
                        <Route exact path="/PEIMSV2/FactorPredict" component={FactorPredict} />
                        <Route exact path="/PEIMSV2/EventBoard" component={EventBoard} />
                        <Route exact path="/PEIMSV2/EventSummary" component={EventSummary} />
                        <Route exact path="/PEIMSV2/AutoReport" component={AutoReport} />
                        <Route exact path="/PEIMSV2/EliminationPeriod" component={EliminationPeriod} />
                        <Route exact path="/PEIMSV2/ExamRules" component={ExamRules} />
                        <Route exact path="/PEIMSV2/ExamDailyScore" component={ExamDailyScore} />
                        <Route exact path="/PEIMSV2/RaceList" component={RaceList} />
                        <Route exact path="/PEIMSV2/OnlineDisk" component={OnlineDiskWrapper} />
                        <Route exact path="/PEIMSV2/SchedulingManagement" component={SchedulingManagement} />
                        <Route exact path="/PEIMSV2/ProcessPage" component={ProcessPage} />
                        <Route exact path="/PEIMSV2/DeviceStatus" component={DeviceStatus} />
                        <Route exact path="/PEIMSV2/AlarmPage" component={AlarmPage} />
                        <Route exact path="/PEIMSV2/ElecManagement" component={ElecManagement} />
                        <Route exact path="/PEIMSV2/AlarmSettings" component={AlarmSettings} />
                        <Route exact path="/PEIMSV2/AlarmPushRuleList" component={AlarmPushRuleList} />
                        <Route exact path="/PEIMSV2/AlarmPushRuleDetails/:id" component={AlarmPushRuleDetails} />
                        <Route exact path="/PEIMSV2/Board/Energy" component={Energy} />
                        <Route exact path="/PEIMSV2/Board/ExamAnalysis" component={ExamAnalysis} />
                        <Route exact path="/PEIMSV2/Exam/RuleList" component={RuleList} />

                        <Route exact path="/PEIMSV2/Board/HistoryData" component={HistoryDataV2} />
                        <Route exact path="/PEIMSV2/Board/SampleMaintenance" component={SampleMaintenance} />
                        <Route exact path="/PEIMSV2/user" component={User} />
                        <Route exact path="/PEIMSV2/role" component={Role} />
                        <Route exact path="/PEIMSV2/department" component={Department} />
                        <Route exact path="/PEIMSV2/PersonalCenter" component={PersonalCenter} />
                        <Route exact path="/PEIMSV2/ScreenShow" component={ScreenShow} />
                        <Route exact path="/PEIMSV2/ControllerBoard" component={ControllerBoard} />
                        <Route exact path="/PEIMSV2/EventEnter/EventView" component={EventView} />
                        <Route exact path="/PEIMSV2/EventEnter/EventForm/:openType/:id" component={EventForm} />
                        <Route exact path="/PEIMSV2/FormTplList" component={FormTplList} />
                        <Route exact path="/PEIMSV2/EventSchema/:id" component={EventSchema} />
                        <Route exact path="/PEIMSV2/Ingred" component={Ingred} />
                        <Route exact path="/PEIMSV2/IronPanel/IronSettings" component={IronSettings} />
                        <Route exact path="/PEIMSV2/IronPanel/IronData" component={IronData} />
                        <Route exact path="/PEIMSV2/Mixed" component={MixedPanel} />
                        <Route exact path="/PEIMSV2/Mixed/MixDetail/:id" component={MixDetail} />
                        <Route exact path="/PEIMSV2/DnDPage/:id" component={DnDPage} />
                        <Route exact path="/PEIMSV2/ScreenList" component={ScreenList} />
                        <Route exact path="/PEIMSV2/BoardEdit/:id" component={BoardEdit} />
                        <Route exact path="/PEIMSV2/DiagramGroup" component={['tengfei', 'wlcb', 'jinglei'].indexOf(process.env.REACT_APP_PROJECT_TAG) > -1 ? DCSBoardV2 : DCSBoard} />
                        <Route exact path="/PEIMSV2/FORMS" component={EventViewNoTree} />
                        <Route exact path="/PEIMSV2/MetaDataList" component={MetaDataList} />
                        <Route exact path="/PEIMSV2/LogCenter" component={LogCenter} />
                        <Route exact path="/PEIMSV2/DiagramList" component={DiagramList} />
                        <Route exact path="/PEIMSV2/SPEC" component={SPEC} />
                        <Route exact path="/PEIMSV2/ProcessBoard" component={ProcessBoard} />
                        <Route exact path="/PEIMSV2/MetaDataMng" component={MetaDataMng} />
                        <Route exact path="/PEIMSV2/ElecMonitor" component={ElecMonitor} />
                        <Route exact path="/PEIMSV2/ElecStatistics" component={ElecStatistics} />
                        <Route exact path="/PEIMSV2/ElecCharts" component={ElecCharts} />
                        <Route exact path="/PEIMSV2/FncAnalysis" component={FncAnalysis} />
                        <Route exact path="/PEIMSV2/CollapseMonitor" component={CollapseMonitor} />
                    </Switch>
                </DemoSystemV2>
            </Route>
            <Route exact path="/ScreenCarbon" component={ScreenCarbon} />
            <Route exact path="/ScreenTrend" component={ScreenTrend} />
            <Route exact path="/ScreenMain" component={ScreenMain} />
            <Route exact path="/ThreeScreen" component={ThreeScreen} />
        </Switch>
    );
};

export default Routes;