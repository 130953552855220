import React, { useState, useEffect, useMemo, useRef } from 'react';
import Layout from '../Layout';
import TableViewer from '../TableViewer';
import ICON_REFRESH from '../res/refresh.png';
import ICON_DEL from '../res/del.png';
import ICON_RADIO_BLANK from '../res/radio_blank.png';
import ICON_RADIO_SELECT from '../res/radio_select.png';
import ICON_CHECK_NO from '../res/check_no.png';
import ICON_CHECK_YES from '../res/check_yes.png';
// import ICON_SEARCH from '../res/search.png';
import { Input, message, Select, Tooltip, Spin, Modal } from 'antd';
import { post, get, patch } from '../../../../common/utils';
import { getTimestamps, getDefaultTimeRange } from '../utils.js';

import './index.scss';

const { Search } = Input;
const { Option } = Select;

const parseId = (id) => {
    let retId = null;
    id = parseInt(id);
    if (typeof id === 'number' && id !== -1) {
        retId = id;
    };
    return retId;
};

const Comp = (props) => {
    const paramId = useRef(parseId(props.match.params.id));
    const reportId = paramId.current;
    const [step, setStep] = useState(0);
    const [reportName, setReportName] = useState(null);
    const [range, setRange] = useState(null);
    const [sequence, setSequence] = useState(null);
    const [shared, setShared] = useState(null);
    const [timeType, setTimeType] = useState(null);
    const [timeTypeConf, setTimeTypeConf] = useState(null);
    const [timeType2Range, setTimeType2Range] = useState(null);
    const [keyword, setKeyWord] = useState("");
    const [metaDatas, setMetaDatas] = useState(null);
    const [selectedMetas, setSelectedMetas] = useState([]);
    const [tableResult, setTableResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const rangeList = useMemo(() => {
        if (!!timeType && !!timeType2Range && !!timeType2Range[timeType]) {
            return timeType2Range[timeType]
                .map((rangeKey) => {
                    return {
                        key: rangeKey,
                        text: rangeKey
                            .replace('d', '天')
                            .replace('w', '周')
                            .replace('M', '月')
                            .replace('Y', '年'),
                    };
                });
        };
        return null;
    }, [timeType, timeType2Range]);
    useEffect(() => {
        const fetchDataFunc = () => {
            get(process.env.REACT_APP_SERVER_PATH + 'report/get_int_2_time_type', {})
                .then((retData) => {
                    if (!!retData.data && retData.data.errcode === 0) {
                        setTimeTypeConf(retData.data.results);
                    };
                });
            get(process.env.REACT_APP_SERVER_PATH + 'report/get_time_type_2_range', {})
                .then((retData) => {
                    if (!!retData.data && retData.data.errcode === 0) {
                        setTimeType2Range(retData.data.results);
                    };
                });
            if (typeof paramId.current === 'number') {
                setLoading(true);
                post(process.env.REACT_APP_SERVER_PATH + 'report/queryReportInfo', {
                    report_id: paramId.current,
                })
                    .then((retData) => {
                        setLoading(false);
                        if (!!retData.data && retData.data.errcode === 0 && !!retData.data.results.dataMetas && !!retData.data.results.rpReportConf) {
                            const { dataMetas, rpReportConf: { name, range, sequence, shared, timeType } } = retData.data.results;
                            setReportName(name);
                            setRange(range || null);
                            setSequence(sequence);
                            setShared(shared);
                            setTimeType(timeType || null);
                            setSelectedMetas(dataMetas);
                            renderTableDatas(dataMetas, timeType || null, range || null, sequence);
                        } else {
                            message.error("获取数据失败，请刷新重试！");
                        };
                    });
            };
        };
        fetchDataFunc();
    }, []);
    const renderTableDatas = (dataMetas, timeType, range) => {
        let nextTableDatas = {
            content_list: [],
            form_header: dataMetas,
        };
        if (!!timeType && !!range) {
            const timestamps = getTimestamps(timeType, range);
            // console.log(timestamps);
            nextTableDatas = Object.assign({}, nextTableDatas, {
                content_list: timestamps
                    .map((stamp) => {
                        return {
                            data_time: stamp,
                        };
                    })
            });
        };
        setTableResult(nextTableDatas);
    };
    const nameOnChange = (e) => {
        setReportName(e.target.value);
    };
    const timeTypeOnChange = (v) => {
        setRange(null);
        setTimeType(v);
        const deletedMetas = selectedMetas.filter(({ timeType }) => timeType !== v);
        if (deletedMetas.length > 0) {
            const nameStr = deletedMetas.map(({ name }) => `【${name}】`).join(',');
            message.info(`由于维度变化，已自动删除【已选择指标】内不支持新维度的指标：${nameStr}`, 5)
        };
        const nextSelectedMetas = selectedMetas.filter(({ timeType }) => timeType === v);
        setSelectedMetas(nextSelectedMetas);
        renderTableDatas(nextSelectedMetas, v, null);
    };
    const rangeOnChange = (v) => {
        setRange(v);
        renderTableDatas(selectedMetas, timeType, v);
    };
    const selectSequence = (v) => {
        setSequence(v);
    };
    const selectShared = (v) => {
        setShared(v);
    };
    const keywordOnChange = (e) => {
        setKeyWord(e.target.value);
    };
    const nextStep = () => {
        setStep(1);
    };
    const prevStep = () => {
        setStep(0);
    };
    const onSearch = () => {
        // console.log(keyword, typeof keyword);
        queryMetas(keyword);
    };
    const metaSelect = (meta, selected) => {
        let nextSelectedMetas;
        if (!selected) {
            nextSelectedMetas = selectedMetas.concat([meta]);
        } else {
            nextSelectedMetas = selectedMetas
                .filter(({ id }) => id !== meta.id);
        };
        setSelectedMetas(nextSelectedMetas);
        renderTableDatas(nextSelectedMetas, timeType, range);
    };
    const unselectMeta = (meta) => {
        metaSelect(meta, true);
    };
    const queryMetas = (v) => {
        if (timeType === null) {
            message.error('请先选择【维度】!');
            return;
        };
        setLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + "report/query_data_metas_by_key", {
            search_key: v,
            time_type: timeType,
            page: 1,
            page_size: 10,
        })
            .then((retData) => {
                setLoading(false);
                if (!!retData.data && retData.data.errcode === 0) {
                    setMetaDatas(retData.data.results);
                } else {
                    message.error("获取数据失败，请重试！");
                };
            });
    };
    const saveOnClick = () => {
        // check values
        if (typeof reportName !== 'string' || reportName === "") {
            message.error("请正确填写【报表名称】!");
            return;
        };
        if (typeof timeType !== 'number') {
            message.error("请正确选择【维度】!");
            return;
        };
        if (typeof range !== 'string') {
            message.error("请正确选择【默认区间】!");
            return;
        };
        if (typeof sequence !== 'number') {
            message.error("请正确选择【时间序列】!");
            return;
        };
        if (typeof shared !== 'boolean') {
            message.error("请正确选择【是否共享】!");
            return;
        };
        if (selectedMetas.length === 0) {
            message.error("请选择【指标】!");
            return;
        };
        const dataMetaIds = selectedMetas.map(({ id }) => id);
        setLoading(true);
        if (typeof reportId === 'number') {
            // 更新
            patch(process.env.REACT_APP_SERVER_PATH + "report/update", {
                id: reportId,
                name: reportName,
                time_type: timeType,
                user_id: 1,
                is_shared: shared ? 1 : 0,
                range: range,
                sequence: sequence,
                data_meta_ids: dataMetaIds,
            })
                .then((retData) => {
                    if (!!retData.data && retData.data.errcode === 0) {
                        message.success("更新成功!");
                        props.history.push("/System/StatisticalReport");
                        // window.location.reload();
                    } else {
                        setLoading(false);
                        message.error("更新失败,请重试!");
                    };
                });
        } else {
            // 新建
            post(process.env.REACT_APP_SERVER_PATH + "report/add", {
                name: reportName,
                time_type: timeType,
                user_id: 1,
                is_shared: shared ? 1 : 0,
                range: range,
                sequence: sequence,
                data_meta_ids: dataMetaIds,
            })
                .then((retData) => {
                    if (!!retData.data && retData.data.errcode === 0) {
                        message.success("创建成功!");
                        props.history.push("/System/StatisticalReport");
                        // window.location.reload();
                    } else {
                        setLoading(false);
                        message.error("创建失败,请重试!");
                    };
                });
        };
    };
    const refreshData = () => {
        if (!!range && selectedMetas.length > 0) {
            setLoading(true);
            const [time_begin, time_end] = getDefaultTimeRange(timeType, range);
            post(process.env.REACT_APP_SERVER_PATH + 'report/query_report_content_by_data_meta_ids', {
                data_meta_ids: selectedMetas.map(({ id }) => id),
                time_begin,
                time_end,
            })
                .then((retData) => {
                    setLoading(false);
                    if (!!retData.data && retData.data.errcode === 0) {
                        setTableResult(retData.data.results);
                    } else {
                        message.error("获取数据失败，请重试！");
                    };
                });
        } else {
            message.error("未选择默认区间或未选择指标！");
        };
    };
    const delReport = () => {
        Modal.confirm({
            content: `确认删除该报表吗?`,
            onOk: () => {
                if (reportId === null) {
                    props.history.push("/System/StatisticalReport");
                } else {
                    setLoading(true);
                    post(process.env.REACT_APP_SERVER_PATH + 'report/delete_report', {
                        id: reportId,
                    }).then((retData) => {
                        if (!!retData.data && retData.data.errcode === 0) {
                            message.success('删除成功!');
                            props.history.push("/System/StatisticalReport");
                        } else {
                            setLoading(false);
                            message.error('删除失败！请重试！');
                        };
                    });
                };
            },
        });
    };

    let leftHeaderTitle = '新增报表';
    if (typeof reportId === 'number') {
        leftHeaderTitle = '编辑报表';
    };
    return (
        <div style={{ height: '100%', position: 'relative' }} className="edit_report_vaQ3t">
            <div style={{ height: '100%' }}>
                <Layout
                    header={(
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 24 }}>
                            <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}
                                onClick={refreshData}
                            >
                                <img alt="" src={ICON_REFRESH} width="24" height="24" />
                                <div style={{ marginLeft: 4, fontSize: 14, height: 14, lineHeight: '14px', color: '#3DCDB9' }}>刷新数据</div>
                            </div>
                            <div
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none', marginLeft: 24 }}
                                onClick={delReport}
                            >
                                <img alt="" src={ICON_DEL} width="24" height="24" />
                                <div style={{ marginLeft: 4, fontSize: 14, height: 14, lineHeight: '14px', color: '#3DCDB9' }}>删除</div>
                            </div>
                        </div>
                    )}
                    leftHeader={(
                        <div style={{ height: '100%', paddingLeft: 20, display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: 16, color: '#FFFFFF', height: 16, lineHeight: '16px' }}>{leftHeaderTitle}</div>
                        </div>
                    )}
                    leftContent={
                        step === 0 ?
                            (
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', borderRight: '1px solid rgba(68,243,218,0.4)' }}>
                                    <div style={{ height: 0, flexGrow: 1 }}>
                                        <div style={{ overflowY: 'auto', height: '100%' }}>
                                            <div style={{ padding: '24px 16px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: 70, marginRight: 12, display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>报表名称</div>
                                                    </div>
                                                    <div style={{ width: 0, flexGrow: 1 }}>
                                                        <Input style={{ width: '100%' }} value={reportName} onChange={nameOnChange} allowClear placeholder="未命名报表" />
                                                    </div>
                                                </div>
                                                <div style={{ height: 1, backgroundColor: '#343D47', marginTop: 24 }} />
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                                                    <div style={{ width: 70, marginRight: 12, display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>维度</div>
                                                    </div>
                                                    <div style={{ width: 0, flexGrow: 1 }}>
                                                        <Select style={{ width: '100%' }} value={timeType} onChange={timeTypeOnChange}>
                                                            {
                                                                !!timeTypeConf &&
                                                                Object.keys(timeTypeConf)
                                                                    .map((key, index) => {
                                                                        const text = timeTypeConf[key];
                                                                        return (
                                                                            <Option key={index} value={parseInt(key)}>{text}</Option>
                                                                        );
                                                                    })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                                                    <div style={{ width: 70, marginRight: 12, display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>默认区间</div>
                                                    </div>
                                                    <div style={{ width: 0, flexGrow: 1 }}>
                                                        <Select style={{ width: '100%' }} value={range} onChange={rangeOnChange}>
                                                            {
                                                                !!rangeList &&
                                                                rangeList
                                                                    .map((item, index) => {
                                                                        const { key, text } = item;
                                                                        return (
                                                                            <Option key={index} value={key}>{text}</Option>
                                                                        );
                                                                    })
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                                                    <div style={{ width: 70, marginRight: 12, display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>时间序列</div>
                                                    </div>
                                                    <div style={{ width: 0, flexGrow: 1 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', userSelect: 'none' }}>
                                                            <div
                                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                                onClick={() => selectSequence(0)}
                                                            >
                                                                <img alt="" src={sequence === 0 ? ICON_RADIO_SELECT : ICON_RADIO_BLANK} width="24" height="24" />
                                                                <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#FFFFFF', marginLeft: 6 }}>列</div>
                                                            </div>
                                                            <div
                                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: 32 }}
                                                                onClick={() => selectSequence(1)}
                                                            >
                                                                <img alt="" src={sequence === 1 ? ICON_RADIO_SELECT : ICON_RADIO_BLANK} width="24" height="24" />
                                                                <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#FFFFFF', marginLeft: 6 }}>行</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                                                    <div style={{ width: 70, marginRight: 12, display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>统计方式</div>
                                                    </div>
                                                    <div style={{ width: 0, flexGrow: 1 }}>
                                                        <Select style={{ width: '100%' }} value='ave'>
                                                            <Option value='ave'>平均</Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div style={{ height: 1, backgroundColor: '#343D47', marginTop: 24 }} />
                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
                                                    <div style={{ width: 70, marginRight: 12, display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA' }}>是否共享</div>
                                                    </div>
                                                    <div style={{ width: 0, flexGrow: 1 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', userSelect: 'none' }}>
                                                            <div
                                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                                onClick={() => selectShared(false)}
                                                            >
                                                                <img alt="" src={shared === false ? ICON_RADIO_SELECT : ICON_RADIO_BLANK} width="24" height="24" />
                                                                <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#FFFFFF', marginLeft: 6 }}>不共享</div>
                                                            </div>
                                                            <div
                                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: 32 }}
                                                                onClick={() => selectShared(true)}
                                                            >
                                                                <img alt="" src={shared === true ? ICON_RADIO_SELECT : ICON_RADIO_BLANK} width="24" height="24" />
                                                                <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#FFFFFF', marginLeft: 6 }}>共享</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{ height: 60, backgroundColor: '#41ECD4', display: 'flex', alignItems: 'center', justifyContent: 'center', userSelect: 'none', cursor: 'pointer' }}
                                        onClick={nextStep}
                                    >
                                        <div style={{ fontSize: 20, color: '#0D1115', lineHeight: '20px', height: 20 }}>下一步</div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', borderRight: '1px solid rgba(68,243,218,0.4)' }}>
                                    <div style={{ height: 0, flexGrow: 1 }}>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ padding: '12px 16px' }}>
                                                <Search
                                                    placeholder="请输入关键词"
                                                    allowClear
                                                    onSearch={onSearch}
                                                    style={{ width: '100%' }}
                                                    value={keyword}
                                                    onChange={keywordOnChange}
                                                />
                                            </div>
                                            <div style={{ margin: '0 16px 9px 16px', height: 1, backgroundColor: '#343D47' }} />
                                            <div style={{ maxHeight: '40%', overflow: 'auto', marginRight: 3 }} className="search_meta_wrapper">
                                                <div style={{ padding: "0 16px" }}>
                                                    {
                                                        !metaDatas ?
                                                            (
                                                                <div style={{ height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#9FA0BA' }}>暂无数据</div>
                                                            ) : (
                                                                metaDatas.map((item, index) => {
                                                                    const { id, name } = item;
                                                                    const selected = selectedMetas.filter(({ id: mId }) => mId === id).length > 0;
                                                                    return (
                                                                        <div key={index} style={{ height: 34, display: 'flex', alignItems: 'center' }}>
                                                                            <img
                                                                                alt="" src={selected ? ICON_CHECK_YES : ICON_CHECK_NO}
                                                                                width="16" height="16" style={{ cursor: 'pointer' }}
                                                                                onClick={() => { metaSelect(item, selected) }}
                                                                            />
                                                                            <Tooltip title={name} placement="bottomLeft">
                                                                                <div style={{ fontSize: 14, height: 14, lineHeight: '14px', color: '#9FA0BA', marginLeft: 6, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>
                                                                            </Tooltip>
                                                                        </div>
                                                                    );
                                                                })
                                                            )
                                                    }
                                                </div>
                                            </div>
                                            <div style={{ height: 12 }} />
                                            <div style={{ height: 0, flexGrow: 1, backgroundColor: '#343D47', display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ margin: '12px 16px' }}>
                                                    <div style={{ fontSize: 16, height: 16, lineHeight: '16px', color: '#FFFFFF' }}>已选择指标</div>
                                                </div>
                                                <div style={{ height: 0, flexGrow: 1, overflowY: 'auto', marginRight: 3 }} className="selected_meta_wrapper">
                                                    {
                                                        selectedMetas
                                                            .map((item, index) => {
                                                                const { name } = item;
                                                                return (
                                                                    <div key={index} style={{ margin: `${index === 0 ? 0 : 12}px 16px 12px 16px`, height: 32, borderRadius: 2, backgroundColor: '#15191E', display: 'flex', alignItems: 'center', padding: '9px 11px' }} className="selected_meta_item">
                                                                        <div style={{ width: 0, flexGrow: 1, fontSize: 14, height: 14, lineHeight: '14px', color: '#FFFFFF', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>
                                                                        <img
                                                                            alt="" src={ICON_DEL} width="24" height="24" style={{ marginLeft: 12, cursor: 'pointer' }}
                                                                            className="icon_del"
                                                                            onClick={() => { unselectMeta(item) }}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: 60, backgroundColor: '#41ECD4', display: 'flex', userSelect: 'none', cursor: 'pointer' }}>
                                        <div
                                            style={{ width: 0, flexGrow: 1, backgroundColor: 'rgba(58,197,179)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onClick={prevStep}
                                        >
                                            <div style={{ fontSize: 20, color: '#0D1115', lineHeight: '20px', height: 20 }}>上一步</div>
                                        </div>
                                        <div
                                            style={{ width: 0, flexGrow: 1, backgroundColor: '#41ECD4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onClick={saveOnClick}
                                        >
                                            <div style={{ fontSize: 20, color: '#0D1115', lineHeight: '20px', height: 20 }}>保存</div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    rightHeader={(
                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ color: '#FFFFFF', fontSize: 16, height: 16, lineHeight: '16px' }}>{reportName || ""}</div>
                        </div>
                    )}
                    rightContent={
                        !!tableResult ?
                            (
                                <div style={{ height: '100%' }}>
                                    <TableViewer tableResult={tableResult} sequence={sequence} timeType={timeType} />
                                </div>
                            ) : (
                                null
                            )
                    }
                />
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', zIndex: 2, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
