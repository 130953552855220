import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import {
    CommonObj,
} from '../utils';
import { groups } from './constans';
import Card4 from '../components/Card_4';
import { post } from 'common/utils';

const Comp = (props) => {
    const { refreshTick, cardPositions } = props;
    const currScene = useRef(props.sceneInfo);
    const onLoadOver = useRef(props.onLoadOver);
    const [step, setStep] = useState(null);
    const [tag2Value, setTag2Value] = useState({});

    const onLoad = useRef(() => {
        if (typeof props.onLoad === 'function') props.onLoad();
    });
    useEffect(() => {
        const myTO = setTimeout(() => {
            onLoad.current();
        }, 50);
        return () => {
            clearTimeout(myTO);
        };
    }, []);

    const queryLock = useRef(null);
    useEffect(() => {
        if (typeof refreshTick === 'symbol') {
            // refresh signal
            const tag_name_list = []
                .concat(
                    ...(
                        groups.children.map((child) => {
                            const { children } = child;
                            return []
                                .concat(
                                    ...(
                                        children
                                            .map((child) => {
                                                const { tag_1, tag_2 } = child;
                                                return [tag_1, tag_2];
                                            })
                                    )
                                );
                        })
                    ),
                );
            const trimed_tag_name_list = [...new Set(tag_name_list)];
            const currLock = Symbol();
            queryLock.current = currLock;
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                tag_name_list: trimed_tag_name_list,
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (retData.data && retData.data.errcode === 0) {
                        setTag2Value(retData.data.results);
                    };
                });
        };
        return () => {
            queryLock.current = Symbol();
        };
    }, [refreshTick]);

    useEffect(() => {
        onLoadOver.current = props.onLoadOver;
    }, [props]);
    useEffect(() => {
        if (!currScene.current) return;
        currScene.current.displayFunc(
            {//Action-2
                num: groups.children.length,
                cb: (index) => {
                    // console.log(`now ${index} displayed`);
                    setStep(index);
                },
            },
            {//Action-3
                cb: () => {
                    // console.log("all消失");
                    setStep(null);
                },
            },
            {//over
                cb: () => {
                    // console.log("over");
                    if (typeof onLoadOver.current === 'function') {
                        onLoadOver.current();
                    };
                },
            },
        );
        return () => {
            clearTimeout(CommonObj.mySetTimeout);
        };
    }, []);
    if (typeof step !== 'number' || !cardPositions) return null;
    return (
        <div className={style['wrapper']}>
            {
                step >= 0 && !!cardPositions[0] &&
                !!groups.children[0] && (
                    <div className="item_wrapper" style={{ top: cardPositions[0].y, left: cardPositions[0].x }}>
                        <div className="point">
                            <div className="line" style={{ width: 1, height: 32, top: '50%', left: '50%', transform: 'translateX(-50%)' }}>
                                <div className="line" style={{ width: 144, height: 1, bottom: 0, left: 0, transform: 'translateX(-50%)' }}>
                                    <div className="content_wrapper" style={{ top: 8, left: '50%', transform: 'translateX(-50%)' }}>
                                        {
                                            groups.children[0].children
                                                .map((item, index) => {
                                                    const { name } = item;
                                                    const { tag_1, tag_2 } = item;
                                                    const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                    return (
                                                        <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                            <Card4 name={name} values={values} />
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                step >= 1 && !!cardPositions[1] &&
                !!groups.children[1] && (
                    <div className="item_wrapper" style={{ top: cardPositions[1].y, left: cardPositions[1].x }}>
                        <div className="point">
                            <div className="line" style={{ width: 1, height: 76, top: '50%', left: '50%', transform: 'translateX(-50%)' }}>
                                <div className="line" style={{ width: 144, height: 1, bottom: 0, left: 0, transform: 'translateX(-50%)' }}>
                                    <div className="content_wrapper" style={{ top: 8, left: '50%', transform: 'translateX(-50%)' }}>
                                        {
                                            groups.children[1].children
                                                .map((item, index) => {
                                                    const { name } = item;
                                                    const { tag_1, tag_2 } = item;
                                                    const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                    return (
                                                        <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                            <Card4 name={name} values={values} />
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                step >= 2 && !!cardPositions[2] &&
                !!groups.children[2] && (
                    <div className="item_wrapper" style={{ top: cardPositions[2].y, left: cardPositions[2].x }}>
                        <div className="point">
                            <div className="line" style={{ width: 1, height: 64, top: '50%', left: '50%', transform: 'translateX(-50%)' }}>
                                <div className="line" style={{ width: 144, height: 1, bottom: 0, left: 0, transform: 'translateX(-50%)' }}>
                                    <div className="content_wrapper" style={{ top: 8, left: '50%', transform: 'translateX(-50%)' }}>
                                        {
                                            groups.children[2].children
                                                .map((item, index) => {
                                                    const { name } = item;
                                                    const { tag_1, tag_2 } = item;
                                                    const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                    return (
                                                        <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                            <Card4 name={name} values={values} />
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                step >= 3 && !!cardPositions[3] &&
                !!groups.children[3] && (
                    <div className="item_wrapper" style={{ top: cardPositions[3].y, left: cardPositions[3].x }}>
                        <div className="point">
                            <div className="line" style={{ width: 168, height: 1, top: '50%', left: '50%', transform: 'translateY(-50%)' }}>
                                <div className="line" style={{ width: 1, height: 200, top: -44, right: 0 }}>
                                    <div className="content_wrapper" style={{ left: 8, top: '50%', transform: 'translateY(-50%)' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                {
                                                    groups.children[3].children
                                                        .map((item, index) => {
                                                            const { name } = item;
                                                            const { tag_1, tag_2 } = item;
                                                            const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                            return (
                                                                <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                                    <Card4 name={name} values={values} />
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                            {
                                                step >= 4 &&
                                                !!groups.children[4] && (
                                                    <div style={{ marginLeft: 8 }}>
                                                        {
                                                            groups.children[4].children
                                                                .map((item, index) => {
                                                                    const { name } = item;
                                                                    const { tag_1, tag_2 } = item;
                                                                    const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                                    return (
                                                                        <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                                            <Card4 name={name} values={values} />
                                                                        </div>
                                                                    );
                                                                })
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                step >= 5 && !!cardPositions[4] &&
                !!groups.children[5] && (
                    <div className="item_wrapper" style={{ top: cardPositions[4].y, left: cardPositions[4].x }}>
                        <div className="point">
                            <div className="line" style={{ width: 16, height: 1, top: '50%', right: '50%', transform: 'translateY(-50%)' }}>
                                <div className="line" style={{ width: 1, height: 200, top: -34, left: 0 }}>
                                    <div className="content_wrapper" style={{ right: 8, top: '50%', transform: 'translateY(-50%)' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {
                                                step >= 6 &&
                                                !!groups.children[6] && (
                                                    <div style={{ marginRight: 8 }}>
                                                        {
                                                            groups.children[6].children
                                                                .map((item, index) => {
                                                                    const { name } = item;
                                                                    const { tag_1, tag_2 } = item;
                                                                    const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                                    return (
                                                                        <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                                            <Card4 name={name} values={values} />
                                                                        </div>
                                                                    );
                                                                })
                                                        }
                                                    </div>
                                                )
                                            }
                                            <div>
                                                {
                                                    groups.children[5].children
                                                        .map((item, index) => {
                                                            const { name } = item;
                                                            const { tag_1, tag_2 } = item;
                                                            const values = [tag_1, tag_2].map((tag) => tag2Value[tag]);
                                                            return (
                                                                <div key={index} style={{ marginTop: index > 0 ? 8 : 0 }}>
                                                                    <Card4 name={name} values={values} />
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Comp
