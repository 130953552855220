const findPathIndexs = (pathname, menus) => {
    if (typeof pathname !== "string") {
        return null;
    };
    const menuRouteSelectedIndex = menus
        .reduce((pV, cItem, cIndex) => {
            if (pV !== null) {
                return pV;
            };
            if (cItem.route === pathname) {
                return cIndex;
            };
            return pV;
        }, null);
    if (menuRouteSelectedIndex !== null) {
        return [menuRouteSelectedIndex];
    };
    const menuChildItemSelectedIndex = menus
        .reduce((pV, cMenu, menuIndex) => {
            if (pV !== null) {
                return pV;
            };
            if (!!cMenu.children) {
                const childSelectedIndex = cMenu.children
                    .reduce((pV_c, cChild, childIndex) => {
                        if (pV_c !== null) {
                            return pV_c;
                        };
                        if (cChild.route === pathname) {
                            return [childIndex];
                        } else if (!!cChild.children) {
                            // 第三级路由
                            const thirdIndex = cChild.children.reduce((pV, cItem, cIndex) => {
                                if (!!pV) return pV;
                                if (typeof cItem.route === 'string' && pathname.indexOf(cItem.route) > -1) {
                                    return [cIndex];
                                };
                                return pV;
                            }, null);
                            if (!!thirdIndex) return [childIndex].concat(thirdIndex);
                        };
                        return pV_c;
                    }, null);
                if (childSelectedIndex !== null) {
                    return [menuIndex].concat(childSelectedIndex);
                };
            };
            return pV;
        }, null);
    return menuChildItemSelectedIndex;
};

const findPathItems = (pathname, menus) => {
    const indexs = findPathIndexs(pathname, menus);
    if (indexs === null) return null;
    return indexs.reduce((pV, cI, cIndex) => {
        if (cIndex === 0) {
            return [menus[cI]];
        } else {
            const cParent = pV[pV.length - 1];
            return pV.concat([cParent.children[cI]]);
        };
    }, []);
};

const addDarkTheme = () => {
    let styleLink = document.getElementById('theme-style');
    let hrefSrc = './antd.dark.css';
    if (!!styleLink) {
        styleLink.href = hrefSrc;
    } else {
        styleLink = document.createElement('link');
        styleLink.type = 'text/css';
        styleLink.rel = 'stylesheet';
        styleLink.id = 'theme-style';
        styleLink.href = hrefSrc;
        document.body.append(styleLink);
    };
};

const rmDarkTheme = () => {
    let styleLink = document.getElementById('theme-style');
    if (!!styleLink) {
        styleLink.href = "";
    };
};

export {
    findPathIndexs,
    findPathItems,
    addDarkTheme,
    rmDarkTheme,
};
