import React, { useEffect, useRef } from 'react';
import echarts from 'echarts';
import moment from 'moment';

const renderChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        // const datas = ['2020-09-01', '2020-09-02', '2020-09-03', '2020-09-04', '2020-09-05', '2020-09-06', '2020-09-07', '2020-09-08', '2020-09-09', '2020-09-10']
        //     .map((time) => {
        //         return [
        //             time,
        //             Math.floor(Math.random() * 100),
        //         ];
        //     });
        const datas = data;
        const option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: 3,
                top: 11,
                right: 3,
                bottom: 2,
            },
            xAxis: {
                type: 'time',
                show: false,
            },
            yAxis: {
                type: 'value',
                scale: true,// true时不一定显示0
                show: false,
            },
            series: [
                {
                    data: (datas)
                        .filter(([_, v]) => v !== null)
                        .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                    type: 'line',
                    itemStyle: {
                        color: '#00676B',
                    },
                    symbol: 'none',
                    lineStyle: {
                        width: 1,
                    },
                    // animation: false,
                },
            ],
        };

        instance.setOption(option);
    };
};

const Comp = (props) => {
    const chartNode = useRef(null);

    const { data_list } = props;
    useEffect(() => {
        renderChart(chartNode.current, data_list || []);
    }, [data_list]);

    return (
        <div style={{ position: 'relative' }}>
            <div ref={chartNode} style={{ height: 24 }}></div>
        </div>
    );
};

export default Comp;
