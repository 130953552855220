import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import Tabs from 'components/TabsV2';
import { get } from 'common/utils';

const blankFunc = () => { };

const Comp = (props) => {
    const { sysInfo, setSysInfo } = props;
    const [tabs, setTabs] = useState(null);

    const setInfoFunc = useRef(blankFunc);
    useEffect(() => {
        setInfoFunc.current = typeof setSysInfo === 'function' ? setSysInfo : blankFunc;
    }, [setSysInfo]);

    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + "api/puyuan/queryProdSystem")
            // get("http://192.168.0.95:8073/api/puyuan/queryProdSystem")
            .then(retData => {
                // console.log(retData);
                if (retData?.data?.error === 0) {
                    const tabs = (retData?.data?.results ?? []).map((item, index) => Object.assign({}, item, { key: `${index}` }));
                    setTabs(tabs);
                };
            });
    }, []);

    useEffect(() => {
        if (!!tabs) {
            setInfoFunc.current((prevSysInfo) => {
                if (!prevSysInfo) {
                    return tabs[0] ?? prevSysInfo;
                };
                return prevSysInfo;
            });
        };
    }, [tabs]);

    const selectSys = (item) => {
        setInfoFunc.current(item);
    };
    return (
        <div className={style['wrapper']}>
            {
                !!tabs && (
                    <Tabs tabs={tabs} selectedTab={sysInfo} onChange={selectSys} />
                )
            }
        </div>
    );
};

export default Comp;
