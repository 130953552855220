import React from 'react'
import TopBreadcrumb from 'components/TopBreadCrumb'
import ExamRules from '../../Exam/ExamRules/ExamRules'

const Page = () => {
  const breadcrumbList = [{ name: '考核' }, { name: '规则设置' }]
  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
    >
      <TopBreadcrumb breadcrumbList={breadcrumbList} />
      <ExamRules customStyle={{ padding: 0 }} />
    </div>
  )
}

export default Page
