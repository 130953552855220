import React from 'react';
import { useEffect } from 'react';
import ICON from './res/cus_factor_btn.png';

import './CustomFactorBtn.scss';

const CustomFactorBtn = (props) => {
    const { customFactors } = props;
    // const [percent, setPercent] = useState(0);
    useEffect(() => {
        const { getCustomKPIList } = props;
        getCustomKPIList()
            .then(isSuccess => {
                // console.log(isSuccess);
            });
        // eslint-disable-next-line
    }, []);
    let numText = '-';
    if (!!customFactors) {
        numText = customFactors.length;
    };
    return (
        <div className="factor_summary_v2_single_factor_detail_custom_factor_btn_wrapper">
            <div
                className="inner_wrapper"
                style={{
                    width: 44, height: 44, borderRadius: '50%',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                }}
            >
                <img alt="" src={ICON} width="24" height="24" className="icon" />
                <div className="text">自选<br />指标</div>
                <div style={{
                    position: 'absolute', padding: '0 4px', minWidth: 16, height: 16, top: 14, right: 14, transform: 'translate(100%,-100%)', backgroundColor: '#FF6170', borderRadius: 8,
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    fontSize: 12, lineHeight: '12px', color: '#FFFFFF',
                    maxWidth: 28,
                }}>
                    <div style={{ overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>{numText}</div>
                </div>
            </div>
        </div>
    );
};

export default CustomFactorBtn;
