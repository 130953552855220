import React from 'react';
import { Spin } from 'antd';
import { } from '@ant-design/icons';
import moment from 'moment';
import { post } from '../../../common/utils';
import TrendChart from './TrendChart.js';

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartData: null,
            loading: true,
            max_bound: null,
            min_bound: null,
        };
    }

    componentDidMount() {
        this.getChartData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            // console.log(prevTimeRange, timeRange, prevTimeRange === timeRange, '时间段变了');
            this.getChartData();
        };
    }

    getChartData = () => {
        const { id, timeRange } = this.props;
        const [timeBegin, timeEnd] = timeRange;
        this.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/factor/get_history_data', {
            factor_id: id,
            cpl_rule_id: 1,
            time_begin: timeBegin.format('YYYY-MM-DD 00:00:00'),
            time_end: moment(timeEnd).add(1, 'days').format('YYYY-MM-DD 00:00:00'),
        })
            .then(retData => {
                // console.log(retData);
                this.setState({ loading: false });
                if (!!retData.data && retData.data.errcode === 0) {
                    const { results, max_bound, min_bound } = retData.data;
                    this.setState({
                        chartData: results,
                        max_bound,
                        min_bound,
                    });
                };
            });
    }

    render() {
        const { upper_limit, lower_limit } = this.props;
        const { chartData, loading, max_bound, min_bound } = this.state;

        return (
            <div style={{ height: '100%', position: 'relative' }}>
                {
                    chartData !== null && (
                        <TrendChart chartData={chartData} upper_limit={upper_limit} lower_limit={lower_limit} max_bound={max_bound} min_bound={min_bound} />
                    )
                }
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default Page;