import ExamAnalysis from './ExamAnalysis.js';
import { connect } from 'react-redux';
import {
    setValues,
    setInitialValues,
} from 'common/redux/ExamAnalysis/actions.js';
import { get, post, getCachedUserInfo } from 'common/utils';

const getSystemsInfo = () => {
    return (dispatch, getState) => {
        return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/cmt_system/list')
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    dispatch(setValues({ systems: retData.data.results }));
                    return true;
                } else {
                    return false;
                };
            });
    };
};

const getExamDetailData = () => {
    return (dispatch, getState) => {
        // //forceUpdate===true代表中止之前的获取请求，强制发出新的请求，中止通过Symbol唯一标识符来实现.
        // // 这里看刷新的规则，是强制重载还是等待未完成的请求完成
        // const { examAnalysis: { fetchExamDetailLock } } = getState();
        // if (fetchExamDetailLock !== null) {
        //     //表示尚有请求未完成
        //     return Promise.resolve({
        //         isSuccess: false,
        //         msg: '等待请求完成！'
        //     })
        // };
        // hard code for demo

        return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/list', {
            page: 1,
            page_size: 50,
        }).then(retData => {
            // console.log("exams/list", retData);
            if (retData?.data?.errcode === 0) {
                const _id = retData?.data?.results?.[0]?._id ?? null;
                if (typeof _id === 'string' && _id !== "") {

                    dispatch(setValues({
                        examList: retData?.data?.results ?? [],
                    }));

                    // const url = process.env.REACT_APP_CEMENT_SERVER_PATH + (process.env.REACT_APP_PROJECT_TAG === "shifeng" ? 'api/exams/get_ongoing_one' : 'api/exams/get_one?_id=5fd2456ce85992430c5e1359');
                    const url = process.env.REACT_APP_CEMENT_SERVER_PATH + (process.env.REACT_APP_PROJECT_TAG === "shifeng" ? 'api/exams/get_ongoing_one' : `api/exams/get_one?_id=${_id}`);
                    return get(url, {})
                        .then(retData => {
                            // console.log('getExamDetailData', retData);
                            const userInfo = getCachedUserInfo();
                            // 是否需要根据userInfo判断当前登陆状态，不登陆能看竞赛吗？
                            if (!!retData.data && retData.data.errcode === 0) {
                                if (!!retData.data.result) {
                                    const examDetail = retData.data.result;
                                    let selectedUserValue = null;
                                    // 默认选择当前用户第一个出现的数据
                                    // 如果没有当前用户，选择第一个系统第一个用户
                                    if (!!userInfo) {
                                        const { user_id } = userInfo;
                                        selectedUserValue = examDetail
                                            .results
                                            .reduce((pV, { user_values }, cIndex) => {
                                                if (pV !== null) return pV;
                                                const cUserIndex = user_values
                                                    .reduce((pUV, { user_id: userId }, cUIndex) => {
                                                        if (pUV !== null) return pUV;
                                                        if (userId === user_id) return cUIndex;
                                                        // console.log(cIndex, cUIndex);
                                                        return pUV;
                                                    }, null);
                                                if (cUserIndex !== null) return [cIndex, cUserIndex];
                                                return pV;
                                            }, null);
                                    };
                                    if (selectedUserValue === null) {
                                        //如果没有当前用户，选择第一个系统第一个用户
                                        if (examDetail.results.length > 0) {
                                            if (examDetail.results[0].user_values.length > 0) {
                                                selectedUserValue = [0, 0];
                                            };
                                        };
                                    };
                                    // console.log("selectUserValue", selectedUserValue);
                                    // 应当先重置页面信息...
                                    dispatch(setValues({
                                        examDetail,
                                        selectedUserValue,
                                        examDetailUniqueId: Symbol(),
                                    }));
                                    return true;
                                } else {
                                    //result === null,认为无竞赛
                                    dispatch(setValues({ examDetail: null }));
                                    return false;
                                };
                            } else {
                                return false;
                            };
                        });

                };
            };
            return false;
        });

    };
};

const getLatestShiftAttendedUsers = () => {
    return (dispatch, getState) => {
        return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/shift/get_latest_one', {})
            .then(retData => {
                // console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    if (!!retData.data.result) {
                        dispatch(setValues({ attendUsers: retData.data.result.attend_users }));
                        return true;
                    } else {
                        dispatch(setValues({ attendUsers: null }));
                        return false;
                    };
                } else {
                    return false;
                };
                // return false;
            });
    };
};

const mapStateToProps = state => {
    const { examAnalysis } = state;
    const { } = examAnalysis;
    return {
        ...examAnalysis,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetState: () => dispatch(setInitialValues()),
        getExamDetailData: () => dispatch(getExamDetailData()),
        updateContentWidth: rightContentWidth => {
            dispatch(setValues({ rightContentWidth }));
        },
        getLatestShiftAttendedUsers: () => dispatch(getLatestShiftAttendedUsers()),
        getSystemsInfo: () => dispatch(getSystemsInfo()),
    };
}

const WrapperedExamAnalysis = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExamAnalysis);

export default WrapperedExamAnalysis;