import React from 'react'
import './index.scss'
import IndexBar from '../YieldTarget/BarChart'
const EnergyTarget = props => {
  const {
    electricConsumeChart,
    naturalGasConsumeChart,
    waterConsumeChart,
    nitrogenConsumeChart,
    electricConsumeNumber,
    naturalGasConsumeNumber,
    waterConsumeNumber,
    nitrogenConsumeNumber
  } = props
  const base_rate = {
    electricConsumeNumber: {
      baseValue: electricConsumeNumber === '--' ? '--' : Math.round(electricConsumeNumber),
      reteValue:
        electricConsumeNumber === '--'
          ? '--'
          : ((((electricConsumeNumber || 0) - 2120) / 2120) * 100).toFixed(2)
    },
    naturalGasConsumeNumber: {
      baseValue: naturalGasConsumeNumber === '--' ? '--' : Math.round(naturalGasConsumeNumber),
      reteValue:
        naturalGasConsumeNumber === '--'
          ? '--'
          : ((((naturalGasConsumeNumber || 0) - 115) / 115) * 100).toFixed(2)
    },
    waterConsumeNumber: {
      baseValue: waterConsumeNumber === '--' ? '--' : Math.round(waterConsumeNumber),
      reteValue:
        waterConsumeNumber === '--'
          ? '--'
          : ((((waterConsumeNumber || 0) - 0.1) / 0.1) * 100).toFixed(2)
    },
    nitrogenConsumeNumber: {
      baseValue: nitrogenConsumeNumber === '--' ? '--' : Math.round(nitrogenConsumeNumber),
      reteValue:
        nitrogenConsumeNumber === '--'
          ? '--'
          : ((((nitrogenConsumeNumber || 0) - 40) / 40) * 100).toFixed(2)
    }
  }
  return (
    <div className="energyTarget">
      <div className="energyTarget-header">
        <div className="energyTarget-header-left">能耗指标</div>
        <div className="energyTarget-header-right">小时平均:近10日</div>
      </div>
      <div className="energyTarget-item">
        <div className="energyTarget-item-left">
          <div className="left-top">
            <span className="left-top-name">电单耗</span>
            <span className="left-top-type">kWh/Nm³</span>
          </div>
          <div className="left-bottom">
            <span className="left-bottom-number">
              {base_rate['electricConsumeNumber'].baseValue}
            </span>
            <span className="left-bottom-percent">
              <span className="triangle up"></span>
              {base_rate['electricConsumeNumber'].reteValue}%
            </span>
            <span className="left-bottom-comparison">基准值2120</span>
          </div>
        </div>
        <IndexBar barChart={electricConsumeChart} />
      </div>

      <div className="energyTarget-item">
        <div className="energyTarget-item-left">
          <div className="left-top">
            <span className="left-top-name">自耗气单耗</span>
            <span className="left-top-type">Nm³/万Nm³</span>
          </div>
          <div className="left-bottom">
            <span className="left-bottom-number">
              {base_rate['naturalGasConsumeNumber'].baseValue}
            </span>
            <span className="left-bottom-percent">
              <span className="triangle down"></span>
              {base_rate['naturalGasConsumeNumber'].reteValue}%
            </span>
            <span className="left-bottom-comparison">基准值115</span>
          </div>
        </div>
        <IndexBar barChart={naturalGasConsumeChart} />
      </div>

      <div className="energyTarget-item">
        <div className="energyTarget-item-left">
          <div className="left-top">
            <span className="left-top-name">水电耗</span>
            <span className="left-top-type">t/Nm³</span>
          </div>
          <div className="left-bottom">
            <span className="left-bottom-number">{base_rate['waterConsumeNumber'].baseValue}</span>
            <span className="left-bottom-percent">
              <span className="triangle up"></span>
              {base_rate['waterConsumeNumber'].reteValue}%
            </span>
            <span className="left-bottom-comparison">基准值0.1</span>
          </div>
        </div>
        <IndexBar barChart={waterConsumeChart} />
      </div>

      <div className="energyTarget-item" style={{ marginBottom: 0 }}>
        <div className="energyTarget-item-left">
          <div className="left-top">
            <span className="left-top-name">氮气电耗</span>
            <span className="left-top-type">Nm³/万Nm³</span>
          </div>
          <div className="left-bottom">
            <span className="left-bottom-number">
              {base_rate['nitrogenConsumeNumber'].baseValue}
            </span>
            <span className="left-bottom-percent">
              <span className="triangle up"></span>
              {base_rate['nitrogenConsumeNumber'].reteValue}%
            </span>
            <span className="left-bottom-comparison">基准值40</span>
          </div>
        </div>
        <IndexBar barChart={nitrogenConsumeChart} />
      </div>
    </div>
  )
}

export default EnergyTarget
