import React from 'react';
import { Input, Menu, Dropdown } from 'antd';
import { withRouter } from 'react-router';

import SEARCH_ICON from './res/search.svg';
import MESSAGE_ICON from './res/message.png';
import RING_ICON from './res/ring.png';
import MINE_ICON from './res/mine.png';
import LOGO_ICON from './res/logo.png';

import style from './Header.module.scss';

import { menus } from './constants.js';
import { findPathItems } from './utils.js';
import { getCachedUserInfo } from '../../common/utils.js';
import { CaretDownOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pathItems: null,
        };
        let userInfo = getCachedUserInfo();
        if (userInfo != null) {
            this.state.user_name = userInfo.name;
        }
    }

    componentDidMount() {
        const { location: { pathname } } = this.props;
        this.pathCheck(pathname);
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname: prevPathname } } = prevProps;
        const { location: { pathname } } = this.props;
        if (prevPathname !== pathname) {
            // console.log(prevPathname, pathname);
            this.pathCheck(pathname);
        };
    }

    pathCheck = (pathname) => {
        const pathItems = findPathItems(pathname, menus);
        this.setState({ pathItems });
    }

    pathOnClick = (route) => {
        if (typeof route === 'string' && route !== "") {
            this.props.history.push(route);
        };
    }
    logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push('/Login');
    }

    render() {
        const { pathItems, user_name } = this.state;
        const systemItem = {
            name: '管理系统',
            route: '/System/MainPage',
        };

        const menu = (
            <Menu>
                {/* <Menu.Item>
                    <div className="cus_menu_item_SbZQy" onClick={() => this.setState({ isDisplayCModal: true })}><CarryOutOutlined style={{ marginRight: 11 }} />考评签到</div>
                </Menu.Item> */}
                <Menu.Item>
                    <div className="cus_menu_item_SbZQy" onClick={() => this.props.history.push('/System/PersonalInfo')}><UserOutlined style={{ marginRight: 11 }} />个人中心</div>
                </Menu.Item>
                <Menu.Item>
                    <div className="cus_menu_item_SbZQy cus_menu_item_second_SbZQy" onClick={this.logout}><LoginOutlined style={{ marginRight: 11 }} />退出登录</div>
                </Menu.Item>
            </Menu>
        );

        return (
            <div className={style["header_wrapper"]}>
                <img alt="" src={LOGO_ICON} className="logo_img" />
                <div className="system_name">supOS生产能源一体化管理系统</div>
                <div className="content_index_wrapper">
                    {
                        !!pathItems &&
                        [systemItem].concat(pathItems)
                            .map((item, index, arr) => {
                                const { name, route } = item;
                                return (
                                    (index > 0 ? [(
                                        <div className="right_arrow" key={`arrow_${index}`} />
                                    )] : [])
                                        .concat([(
                                            <div
                                                className="title" key={`title_${index}`}
                                                {
                                                ...(
                                                    index < arr.length - 1 ? {
                                                        onClick: () => { this.pathOnClick(route) },
                                                    } : {}
                                                )
                                                }
                                            >{name}</div>
                                        )])
                                );
                            })
                    }
                </div>
                <Input
                    placeholder="请输入关键词"
                    style={{ width: 327, height: 32 }}
                    bordered={false}
                    className="custom_input_comp"
                    suffix={(
                        <img alt="" src={SEARCH_ICON} className="input_icon" />
                    )}
                />
                <img alt="" src={MESSAGE_ICON} className="message_img" />
                <img alt="" src={RING_ICON} className="ring_img" />
                <div className="divide_line" />
                <Dropdown overlay={menu}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img alt="" src={MINE_ICON} className="mine_img" />
                        {
                            typeof user_name === 'string' ?
                                (
                                    <div className="user_name">{user_name}</div>
                                ) : (
                                    <div style={{ width: 16 }} />
                                )
                        }
                        <CaretDownOutlined style={{ marginLeft: 6, color: '#3DCDB9' }} />
                    </div>
                </Dropdown>
            </div>
        );
    }
}

export default withRouter(Comp);