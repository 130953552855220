import React from 'react';
import RightTable from './RightTableV2Container.js';
import BottomPanels from './BottomPanels.js';
import ThreeComp from './ThreeCompContainer.js';
import TimeRangePicker from '../Common/TimeRangePicker.js';
import { parseTimeRange } from './utils.js';

import './MainPage.scss';

export default class MainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    timeRangeOnChange = (timeRange) => {
        const { updateTimeRange } = this.props;
        updateTimeRange(timeRange);
    }

    render() {
        const { timeRange } = this.props;
        let timeRangeText = "-年-月-日- -年-月-日";
        if (timeRange !== null) {
            timeRangeText = parseTimeRange(timeRange)
                .map(time => time.format('YYYY年MM月DD日'))
                .join('- ');
        };

        return (
            <div className="main_page_wrapper">
                <div className="system_root_content_top_wrapper">
                    <div className="header_out_wrapper">
                        <div className="title_text">全场总览</div>
                        {/* <div className="date_text">2020年09月06日</div> */}
                        <div className="date_text">{timeRangeText}</div>
                        <div style={{ marginRight: 10 }}>
                            <TimeRangePicker timeRange={timeRange} onChange={this.timeRangeOnChange} />
                        </div>
                    </div>
                </div>
                <div className="content_out_wrapper scroll_bar_restyle">
                    <div className="content_wrapper">
                        <div className="top_wrapper">
                            <div className="left_chart_wrapper">
                                <ThreeComp />
                            </div>
                            <div className="right_table_wrapper">
                                <RightTable />
                            </div>
                        </div>
                        <div className="bottom_wrapper">
                            <BottomPanels />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
