import React, { Component, useState } from 'react'
import {
  Layout,
  Form,
  Checkbox,
  Table,
  Button,
  Input,
  Spin,
  message,
  Modal,
  Switch,
  Radio,
  Select,
  DatePicker
} from 'antd'
import { get, post, patch, reqDelete } from 'common/utils.js'
import style from './index.module.scss'
import ICON_EDIT from '../../assets/edit.png'
import ICON_UP from '../../assets/arrow.png'
import ARROW_LEFT from '../../assets/arrow-left.png'
import ARROW_RIGHT from '../../assets/arrow-right.png'
import ICON_DOWN from '../../assets/arrow_down.png'
import Groups from '../../components/SchedulingGroupsSetting/index' // 班组设置
import Station from '../../components/SchedulingStationSetting/index' // 岗位设置
import Time from '../../components/SchedulingTimeSetting/index' // 排班时间设置
import Period from '../../components/SchedulingPeriodSetting/index' // 排班周期设置
import Edit from '../../components/SchedulingEdit/index' // 排班编辑
import TopBreadcrumb from '../../components/TopBreadCrumb'
import moment from 'moment'
const { Column } = Table
const { Option } = Select
const { RangePicker } = DatePicker
const baseUrl = process.env.REACT_APP_SERVER_PATH

const Results = {
  shiftConfigList: [], // 生成排班结果时的排班时间列表
  postList: [], // 生成排班结果时的岗位列表
  list: []
} // 排班结果假数据

export default class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      spinLoading: false,
      showLable: false,
      timeRange: [],
      stationList: [
        {
          name: '',
          id: null,
          isDelete: 0
        }
      ], // 岗位列表
      groupsList: [],
      gruopsMembers: [],
      timesList: [],
      shiftCycleList: [], // 排班周期列表
      defaultShiftCycle: {}, // 默认排班周期
      periodType: 0, // 按默认排班周期 1: 按起始序号
      startIndex: 1, // 起始序号
      shiftResult: {}, // 排班结果实例
      currentDate:moment().format('YYYY-MM') // 当前选择年月
    }
  }

  componentDidMount() {
    this.init()
  }

  // 查询所有岗位
  getAllStation = () => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/postQuery', {}).then(resData => {
      if (!!resData.data && resData.data.error === 0) {
        this.setState({
          stationList: resData.data.results,
        })
      }
    })
  }
  // 查询班组信息
  getAllGroups = () => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/shiftGroupQueries', {}).then(
      resData => {
        if (!!resData.data && resData.data.error === 0){
          this.state.groupsList = resData.data.results
          const membersList = []
          const newArr = []
          this.state.groupsList.map((item, index) => {
            newArr.push({ id: item.id, members: [] })
            item.post.map(info => {
              if (membersList.indexOf(info.userId) == -1) {
                membersList.push(info.userId)
                newArr[index].members.push(info.userName)
              }
            })
          })
          this.setState({
            groupsList: this.state.groupsList,
            gruopsMembers: newArr
          })
        }
        
      }
    )
  }
  // 查询班次信息
  getAllTime() {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/report/shiftConfig', {}).then(retData => {
      if (!!retData.data && retData.data.error === 0) {
        this.state.timesList = retData.data.results
        this.setState({
          timesList: this.state.timesList
        })
      }
    })
  }

  init() {
    this.getAllStation()
    this.getAllGroups()
    this.getAllTime()
    this.getShiftCycleList()
    this.getShiftResultData()
  }

  getResults(msg) {
    if (msg === 'success') {
      this.init()
    }
  }

  // 显示排班时间组件
  showPrompt(type) {
    console.log(type)
    switch (type) {
      case 'time':
        this.time.onShow()
        break
      case 'station':
        this.station.onShow()
        break
      case 'groups':
        this.groups.onShow()
        break
      case 'period':
        if (this.checkPeriodSettingStatus()) this.period.onShow()
        break
      default:
        console.log('默认')
        break
    }
  }
  //  显示排班时间组件
  showStation = e => {
    this.station.onShow()
  }
  /**
   * 展开收起切换
   * @memberof EleItem
   */
  handleToggleCondition = () => {
    const { showLable } = this.state
    this.setState({ showLable: !showLable })
  }

  /** 校验是否可以进行排班周期设置 */
  checkPeriodSettingStatus = () => {
    const { groupsList, timesList } = this.state
    if (!groupsList || groupsList.length === 0) {
      message.error('请先完成班组设置')
      return false
    }
    if (!timesList || timesList.length === 0) {
      message.error('请先完成排班时间设置')
      return false
    }
    return true
  }

  /** 获取排班周期列表 */
  getShiftCycleList = () => {
    get(`${baseUrl}api/v1/event/shift/shiftCycleQuery`).then(resData => {
      if (!!resData.data && resData.data.error === 0) {
        if (!!resData.data.results && resData.data.results.length > 0) {
          const defaultIndex = resData.data.results.findIndex(item => item.default === 1)
          this.setState({
            shiftCycleList: resData.data.results || [],
            defaultShiftCycle: defaultIndex !== -1 ? resData.data.results[defaultIndex] : {},
            startIndex: 1
          })
        }
      }
    })
  }
  /** 切换排班方式 */
  changePeriodType = targetValue => {
    this.setState({ startIndex: 1, periodType: targetValue })
  }
  /** 切换排班周期 */
  changeDefaultShiftCycleSelected = targetValue => {
    const { shiftCycleList } = this.state
    if (targetValue) {
      const defaultIndex = shiftCycleList.findIndex(item => String(item.id) === String(targetValue))
      this.setState({
        defaultShiftCycle: shiftCycleList[defaultIndex],
        periodType: 0,
        startIndex: 1
      })
    } else {
      this.setState({
        defaultShiftCycle: {}
      })
    }
  }

  // 切换日历
  changeCalender = (value, dateString) =>{
    this.state.currentDate = dateString
    this.setState({
      currentDate:this.state.currentDate
    })
    this.getShiftResultData()
  }

  //开始排班
  orderTable = () => {
    if (this.state.defaultShiftCycle.id) {
      get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/shiftCycleStart', {
        id: this.state.defaultShiftCycle.id,
        startDate: this.state.timeRange[0],
        stopDate: this.state.timeRange[1],
        index: this.state.startIndex
      }).then(retData => {
        if (retData.data && retData.data.results) {
          message.success('排版完成')
          this.setState({ shiftResult: retData.data.results || {} })
        } else {
          message.error('排班失败，请完善相关配置')
        }
      })
    } else {
      message.error('请选择排班周期')
    }
  }

  /** 根据月份获取排班实例
   * @param monthDateStr yyyy-mm
   */
  getShiftResultData(){
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/shift/cycleExampleQuery', {
      date: this.state.currentDate
    }).then(retData => {
      if (!!retData.data && retData.data.error === 0) {
        this.setState({ shiftResult: retData.data.results || {} })
      }
    })
  }

  /** 组装表头数据 */
  handleTableHeader = (resShiftConfigList, resPostList) => {
    // 组装表格数据开始
    const headerShift = (resShiftConfigList || []).map((shiftItem, shiftIndex) => {
      const headerPosts = (resPostList || []).map((postItem, postIndex) => {
        return {
          title: postItem.name,
          dataIndex: `post${shiftIndex}${postIndex}`,
          key: `post${shiftIndex}${postIndex}`,
          align: 'center',
          className: postIndex === resPostList.length - 1 ? 'border-right' : ''
        }
      })
      return {
        title: shiftItem.name,
        children: [
          {
            title: '班组',
            dataIndex: `shiftGroup${shiftIndex}`,
            key: `shiftGroup${shiftIndex}`,
            align: 'center'
          }
        ].concat(headerPosts)
      }
    })
    const tableHeader = [
      {
        title: '日期',
        dataIndex: 'occurTime',
        key: 'occurTime',
        width: 150,
        fixed: 'left',
        align: 'center'
      }
    ]
      .concat(headerShift)
      .concat([
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 150,
          fixed: 'right',
          align: 'center'
        }
      ])

    console.log('tableHeader', tableHeader)
    return tableHeader
  }

  /** 组装表格数据源 */
  handleTableData = dataList => {
    const tableData = (dataList || []).map((rowItem, rowIndex) => {
      const { occurTime, data } = rowItem
      const newRow = { occurTime: occurTime ? moment(occurTime).format('YYYY-MM-DD') : '', key:rowIndex }
      data.forEach((shiftItem, shiftIndex) => {
        newRow[`shiftGroup${shiftIndex}`] = shiftItem.shiftGroupName
        shiftItem.posts.forEach((postItem, postIndex) => {
          newRow[`post${shiftIndex}${postIndex}`] = postItem.users
            .map(user => user.userName)
            .join(',')
        })
      })
      newRow['operation'] = (
        <i title="编辑" className="edit-btn" onClick={() => this.openEditModal(rowItem)} />
      )
      return newRow.occurTime!=''?newRow:null
    })
    return tableData
  }

  /** 编辑排班 */
  openEditModal = data => {
    const { shiftConfigList: resShiftConfigList, postList: resPostList } = this.state.shiftResult
    this.edit.onShow(data, resShiftConfigList || [], resPostList || [])
  }
  clickCalender(type){
    let time = ''
    if(type==='left'){
      time = moment().month(moment(this.state.currentDate).month() - 1).startOf('month').format('YYYY-MM')
    }else{
      time = moment().month(moment(this.state.currentDate).month() + 1).startOf('month').format('YYYY-MM')
    }
    this.state.currentDate = time
    this.setState({
      currentDate:this.state.currentDate
    })
    this.getShiftResultData()
  }

  render() {
    const {
      showLable,
      stationList,
      timesList,
      groupsList,
      gruopsMembers,
      shiftCycleList,
      defaultShiftCycle,
      periodType,
      startIndex,
      shiftResult,
      currentDate
    } = this.state

    const {
      shiftConfigList: resShiftConfigList,
      postList: resPostList,
      list: dataList
    } = shiftResult

    // 准备表格数据
    const tableHeader = this.handleTableHeader(resShiftConfigList, resPostList) || []
    const tableData = this.handleTableData(dataList) || []
    const scrollX = 150 * ((resShiftConfigList || []).length * ((resPostList || []).length + 1) + 3)
    const breadcrumbList = [{ name: '设置' }, { name: '排班管理' }]
    return (
      <div className={style['Scheduling']}>
        <TopBreadcrumb breadcrumbList={breadcrumbList} />
        <Layout className={style['SchedulingManagement']}>
        <Spin spinning={this.state.spinLoading}>
          <Layout className="layout-first">
            <div className="layout-second">
              <h2 className="title" style={{borderBottom:'1px solid #C9C9C9',color:'#357CF7'}}>
                排班设置
                <img
                  style={{ marginLeft: '10px', marginBottom: '4px' }}
                  src={showLable ? ICON_DOWN : ICON_UP}
                  onClick={this.handleToggleCondition}
                ></img>
              </h2>
              <div className="setting" style={{ display: showLable ? 'none' : 'block' }}>
                <div className="setting-row">
                  <div className="setting-info">
                    <div>
                      <span className="second-title">岗位</span>
                      <img
                        alt=""
                        src={ICON_EDIT}
                        className="icon"
                        onClick={e => this.showPrompt('station')}
                      />
                    </div>
                    <div>
                      {stationList.map((item, index) => (
                        <span key={item.id}>{item.name}</span>
                      ))}
                    </div>
                    <Station
                      lists={stationList}
                      getResults={this.getResults.bind(this)}
                      onRef={ref => {
                        this.station = ref
                      }}
                    ></Station>
                  </div>
                  <div className="setting-info">
                    <div>
                      <span className="second-title">排班时间</span>
                      <img
                        alt=""
                        src={ICON_EDIT}
                        className="icon"
                        onClick={e => this.showPrompt('time')}
                      />
                    </div>
                    <div>
                      {timesList &&
                        timesList.map((times, index) => (
                          <div className="setting-time" key={times.id}>
                            <p>{times.name}</p>
                            <p>{times.day?'':'昨日'}{times.beginTime + '-' + times.endTime}</p>
                          </div>
                        ))}
                    </div>
                    <Time
                      lists={timesList}
                      getResults={this.getResults.bind(this)}
                      onRef={ref => {
                        this.time = ref
                      }}
                    ></Time>
                  </div>
                </div>
                <div className="setting-row">
                  <div className="setting-info">
                    <div>
                      <span className="second-title">班组</span>
                      <img
                        alt=""
                        src={ICON_EDIT}
                        className="icon"
                        onClick={e => this.showPrompt('groups')}
                      />
                    </div>
                    <div className="group-details">
                      {groupsList &&
                        groupsList.map((groups, index) =>index<3 && (
                          <div className="setting-group" key={groups.id}>
                            <p className="blue">{groups.name}</p>
                            {gruopsMembers.map(
                              item =>
                                item.id === groups.id && (
                                  <p key={item.id}>{item.members.join('、')}</p>
                                )
                            )}
                          </div>
                        ))}
                    </div>
                    <Groups
                      getResults={this.getResults.bind(this)}
                      onRef={ref => {
                        this.groups = ref
                      }}
                      groupsList={this.state.groupsList}
                      stationList={this.state.stationList}
                    ></Groups>
                  </div>
                  <div className="setting-info">
                    <div>
                      <span className="second-title">排班周期</span>
                      <img
                        alt=""
                        src={ICON_EDIT}
                        className="icon"
                        onClick={e => this.showPrompt('period')}
                      />
                    </div>
                    <div>
                      <Select
                        className="selects"
                        value={defaultShiftCycle.id}
                        style={{ width: 120 }}
                        onChange={this.changeDefaultShiftCycleSelected}
                      >
                        {shiftCycleList.map(shiftCycleItem => {
                          return (
                            <Option key={shiftCycleItem.id} value={shiftCycleItem.id}>
                              {shiftCycleItem.name}
                            </Option>
                          )
                        })}
                      </Select>

                      <span className="period">共{defaultShiftCycle.days || '--'}天</span>
                      <div>
                        <Radio.Group
                          value={periodType}
                          onChange={e => this.changePeriodType(e.target.value)}
                        >
                          <Radio value={0}>按默认排班周期</Radio>
                          <Radio value={1}>选择起始序号</Radio>
                        </Radio.Group>
                        <Select
                          className="selects"
                          value={startIndex}
                          defaultValue={1}
                          disabled={periodType !== 1}
                          onChange={targetValue => this.setState({ startIndex: targetValue })}
                        >
                          {new Array(defaultShiftCycle.days || 0)
                            .fill('')
                            .map((indexItem, index) => {
                              return (
                                <Option key={index} value={index + 1}>
                                  {index + 1}
                                </Option>
                              )
                            })}
                        </Select>
                      </div>
                    </div>
                    <Period
                      onCancel={this.handleCancel}
                      onRef={ref => {
                        this.period = ref
                      }}
                      refreshShiftCycleList={this.getShiftCycleList}
                    />
                  </div>
                </div>
                <div className="setting-footer">
                  <RangePicker
                    onChange={(date, dateString) => this.setState({ timeRange: dateString })}
                  />
                  <Button style={{marginLeft:'16px'}} type="primary" onClick={this.orderTable}>
                    开始排班
                  </Button>
                </div>
              </div>
               
              
            </div>
            <div className="layout-second">
              <div className="setting-results">
                <h2 className="title">排班结果</h2>
                <div style={{display:'flex',alignItems:'center'}}>
                  <span className="calender-arrow-left" onClick={e=>this.clickCalender('left')}>{'<'}</span>
                  <DatePicker style={{backgroundColor:'#F5F6F8',border:'none'}} value={moment(currentDate)} format="YYYY-MM" className="calender" allowClear={false} picker="month" onChange={this.changeCalender} />
                  <span className="calender-arrow-right" onClick={e=>this.clickCalender('right')}>{'>'}</span>
                </div>
              </div>
              <div className="table_wrapper">
                <Table
                  columns={tableHeader}
                  dataSource={tableData}
                  pagination={false}
                  size="middle"
                  scroll={{ x: scrollX, y: 500 }}
                />
                <Edit
                  getResults={this.getResults.bind(this)}
                  onRef={ref => {
                    this.edit = ref
                  }}
                  timesList={this.state.timesList}
                  groupsList={this.state.groupsList}
                  stationList={this.state.stationList}
                ></Edit>
              </div>
            </div>
          </Layout>
        </Spin>
      </Layout>
      </div>
    )
  }
}
