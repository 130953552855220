import React from 'react';
import style from './index.module.scss';
import InfoCard from './InfoCard';
import InfoCard2 from './InfoCard2';
import { infoCard_1, infoCard_2 } from './constants';
import PieCard from './PieCard';
import HzCard from './HzCard';

const Comp = (props) => {
    const { data } = props;

    return (
        <div className={style['wrapper']}>
            <div style={{ width: 656, height: 320 }}>
                <InfoCard {...infoCard_1} data={data} />
            </div>
            <div style={{ width: 672, height: 320, marginLeft: 16 }}>
                <InfoCard2 data={data} />
            </div>
            <div style={{ width: "100%", marginTop: 16 }}></div>
            <div style={{ width: 656, height: 320 }}>
                <InfoCard {...infoCard_2} data={data} />
            </div>
            <div style={{ width: 328, height: 320, marginLeft: 16 }}>
                <PieCard data={data} />
            </div>
            <div style={{ width: 328, height: 320, marginLeft: 16 }}>
                <HzCard data={data} />
            </div>
        </div>
    );
};

export default Comp;
