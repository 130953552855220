import React from 'react'
import './index.scss'
import RulerBar from './RulerBar'
import { Divider } from 'antd'

const DeviceStatus = props => {
  const {
    // MCL压缩机效率 1-4 从左向右
    COMP_TOT_EFF_RATE_H: MCL_eff_rate_1,
    COMP_CASE1_EFF_RATE_H: MCL_eff_rate_2,
    COMP_CASE2_EFF_RATE_H: MCL_eff_rate_3,
    COMP_CASE34_EFF_RATE_H: MCL_eff_rate_4,
    // MCL压缩机功率 1-4 从左向右
    COMP_TOT_SHAFT_RATE_H: MCL_power_1,
    COMP_CASE1_SHAFT_RATE_H: MCL_power_2,
    COMP_CASE2_SHAFT_RATE_H: MCL_power_3,
    COMP_CASE34_SHAFT_RATE_H: MCL_power_4,
    // MCL压缩机 运行统计
    COMP_TOT_HOUR_PER: MCL_tot_hour_per,
    COMP_TOT_HOUR_CUM: MCL_tot_hour_cum_DY,
    // 膨胀压缩机
    EXPC_EXP_EFF_RATE_H: EXPC_exp_eff_rate,
    EXPC_EC_EFF_RATE_H: EXPC_ec_eff_rate,
    EXPC_EXP_SHAFT_RATE_H: EXPC_exp_shaft_rate
  } = props.deviceStatusObj
  return (
    <div className="devicesStatus">
      <div className="model-title">设备状态</div>
      <div className="devicesStatus-main">
        <div className="devicesStatus-main-left">
          <div className="device-title" style={{ marginLeft: 16 }}>
            MCL压缩机
          </div>
          <div className="rulerBar-group">
            <RulerBar rate={MCL_eff_rate_1 * 100 || 0} />
            <RulerBar rate={MCL_eff_rate_2 * 100 || 0} />
            <RulerBar rate={MCL_eff_rate_3 * 100 || 0} />
            <RulerBar rate={MCL_eff_rate_4 * 100 || 0} />
          </div>
          <ul>
            <li className="tr-1">
              <div>效率%</div>
              <div>{MCL_eff_rate_1 ? parseFloat(MCL_eff_rate_1 * 100).toFixed(1) : '--'}</div>
              <div>{MCL_eff_rate_2 ? parseFloat(MCL_eff_rate_2 * 100).toFixed(1) : '--'}</div>
              <div>{MCL_eff_rate_3 ? parseFloat(MCL_eff_rate_3 * 100).toFixed(1) : '--'}</div>
              <div>{MCL_eff_rate_4 ? parseFloat(MCL_eff_rate_4 * 100).toFixed(1) : '--'}</div>
            </li>
            <li className="tr-2">
              <div>功率kW</div>
              <div>{MCL_power_1 ? Number(MCL_power_1).toFixed(0) : '--'}</div>
              <div>{MCL_power_2 ? Number(MCL_power_2).toFixed(0) : '--'}</div>
              <div>{MCL_power_3 ? Number(MCL_power_3).toFixed(0) : '--'}</div>
              <div>{MCL_power_4 ? Number(MCL_power_4).toFixed(0) : '--'}</div>
            </li>
            <li className="tr-3">
              <div></div>
              <div>合计</div>
              <div>1段缸</div>
              <div>2段缸</div>
              <div>3段缸</div>
            </li>
          </ul>
          {/*<div className="devicesStatus-main-left-bottom">*/}
          {/*  <span>运行统计</span>*/}
          {/*  <span>本次/年累</span>*/}
          {/*  <span>*/}
          {/*    {MCL_tot_hour_per ? parseInt(MCL_tot_hour_per) : '--'}/*/}
          {/*    {parseInt(MCL_tot_hour_cum_DY) || '--'}h*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>
        <Divider type="vertical" />
        <div className="devicesStatus-main-right">
          <div className="device-title">膨胀压缩机</div>
          <div className="rulerBar-group">
            <RulerBar rate={EXPC_exp_eff_rate * 100 || 0} />
            <RulerBar rate={EXPC_ec_eff_rate * 100 || 0} />
          </div>
          <ul>
            <li>
              <div className="text">
                <div>膨胀侧</div>
                <div>效率%</div>
              </div>
              <div className="text">
                <div>压缩侧</div>
                <div>效率%</div>
              </div>
            </li>
            <li>
              <div>{EXPC_ec_eff_rate ? parseFloat(EXPC_exp_eff_rate * 100).toFixed(1) : '--'}</div>
              <div>{EXPC_ec_eff_rate ? parseFloat(EXPC_ec_eff_rate * 100).toFixed(1) : '--'}</div>
            </li>
            <li>
              <div className="text">耗功kW</div>
              <div>{EXPC_exp_shaft_rate ? Number(EXPC_exp_shaft_rate).toFixed(0) : '--'}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default DeviceStatus
