import React from 'react';
import { withRouter } from 'react-router'
import SideMenu from './SideMenu';
import Header from './Header';
import AlarmEvents from './AlarmEvents.js';
import { addDarkTheme, rmDarkTheme } from './utils.js';

import './System.scss';

const hideHeader = ['lanzhuo'].includes(process.env.REACT_APP_PROJECT_TAG);
const hideMenu = ['lanzhuo'].includes(process.env.REACT_APP_PROJECT_TAG);

class Comp extends React.Component {

    constructor(props) {
        super(props);
        let zoom = null;
        const screenWidth = window.screen.availWidth;
        if (screenWidth < 1920) {
            zoom = Math.floor((screenWidth / 1920) * 1000) / 1000;
        };
        this.state = {
            zoom,
        };
    }

    componentDidMount() {
        addDarkTheme();
    }

    componentWillUnmount() {
        rmDarkTheme();
    }

    render() {
        const { zoom } = this.state;

        return (
            <div className="system_overflow_wrapper" style={!!zoom ? { zoom } : {}}>
                <AlarmEvents />
                <div className="system_wrapper">
                    {
                        !hideHeader && (
                            <div className="system_root_header_wrapper">
                                <Header />
                            </div>
                        )
                    }
                    <div className="system_root_sidemenu_content_wrapper">
                        {
                            !hideMenu && (
                                <div className="system_root_sidemenu_wrapper">
                                    <SideMenu />
                                </div>
                            )
                        }
                        <div className="system_root_content_wrapper">
                            <div className="system_root_content_bottom_wrapper">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Comp);
