import React, { useEffect, useState } from 'react'
import style from './index.module.scss'
import { useData } from './hooks';
import { Button, Tree } from 'antd';

/** 事件模版树 */
const Com = (props) => {
    const [data, loading] = useData();

    const [selectedKeys, setSelectedKeys] = useState(null);

    const onSelect = (selectedKeys, info) => {
        setSelectedKeys(selectedKeys);
        const id = info?.node?.id ?? null;
        if (typeof props.setId === 'function') {
            props.setId(id);
        };
    };

    useEffect(() => {
        // 每次有新的table，更改选择模板，默认第一个存在的模板
        console.log(data);
        // 递归
        const firstKey = data?.parsed_results?.[0]?.key ?? null;
        setSelectedKeys(typeof firstKey === 'string' ? [firstKey] : []);
        const id = data?.parsed_results?.[0]?.id ?? null;
        if (typeof props.setId === 'function') {
            props.setId(id);
        };
    }, [data]);

    return <div className={style['tplTree']}>
        {/* <div className="head-warp">
            <Button type="primary">新建</Button>
        </div> */}
        <div className="tree-wrap scroll_bar_restyle">
            {
                !!data?.results && (
                    <Tree
                        selectedKeys={selectedKeys}
                        defaultExpandAll={true}
                        onSelect={onSelect}
                        treeData={data?.results ?? []}
                    />
                )
            }
        </div>
    </div>
}

export default Com