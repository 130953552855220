import Page from './CustomFactorPanel.js';
import { connect } from 'react-redux';
import { getCustomKPIList, updateFavouriteKPI } from './utils.js';

const mapStateToProps = state => {
    const { system } = state;
    const { customFactors } = system;
    return {
        customFactors,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deleteFromFavouriteKPI: (id) => dispatch(updateFavouriteKPI(id, 0)),
        getCustomKPIList: () => dispatch(getCustomKPIList),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;