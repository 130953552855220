import React from 'react';
import { withRouter } from 'react-router';
import { Input, Button, Modal, message, Select, Table, DatePicker, Radio, InputNumber } from 'antd';
import { post, patch } from '../../../common/utils.js';
import { clone } from 'ramda'
import { SCORE_METHODS } from './config.js';
// import './WeightSettingModal.css';

const Option = Select.Option;
const MAX_WEIGHT = 1000;//js精度损失，目前可以保证保留两位小数不出现精度损失

class WeightSettingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mode: 'read',
            factors: [],
            loading: false,
            systems: [],
            selectedSystem: null,
        }
    }

    componentDidMount() {
        const { systems: prop_systems, factors } = this.props;
        let systems = prop_systems.filter(({ id }) => factors.filter(({ sys_id }) => sys_id === id).length > 0);
        let selectedSystem = (systems[0] || {}).id || null;
        if (typeof selectedSystem === 'number') selectedSystem += '';
        this.setState({ systems, selectedSystem });
    }

    verifyData = () => {
        const { systems, selectedSystem } = this.state;
        let sysIndex = 0;
        systems.forEach(({ id }, index) => {
            if (id == selectedSystem) sysIndex = index;
        })
        for (let i = 0; i < systems.length; i++) {
            const curSysIndex = (i + sysIndex) % systems.length;
            const totalWeight = this.state.factors
                .filter(({ sys_id }) => sys_id == systems[curSysIndex].id)
                .reduce((total, { weight }) => total + MAX_WEIGHT * (parseFloat(weight) || 0), 0) / MAX_WEIGHT;
            if (totalWeight !== 100) {
                message.error(`系统【${systems[curSysIndex].name}】分配未完成，请【自动分配】至100%`);
                this.setState({ selectedSystem: systems[curSysIndex].id + '' });
                return false;
            }
        }
        return true;
    }
    saveData = () => {
        const { ruleId: rule_id } = this.props.match.params;
        this.setState({ loading: true });
        post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/factors/weight/update', {
            rule_id,
            weights: this.state.factors.map(({ _id, weight = 0 }) => ({ _id, weight })),
        }).then(retData => {
            this.setState({ loading: false });
            if (retData.data && retData.data.errcode === 0) {
                this.modalOnCancel(true);
            }
        });
    }
    modalOnOk = () => {
        if (this.state.mode == 'read') {
            this.setState({ mode: 'edit', factors: clone(this.props.factors) });
        } else {
            if (!this.verifyData()) return;
            this.saveData();
        }
    }
    modalOnCancel = (refresh = false) => {
        if (this.state.loading) return;
        const { onClose = () => { } } = this.props;
        this.setState({ mode: 'read' });
        onClose(refresh);
    }

    autoSetting = totalWeight => {
        const { factors, selectedSystem } = this.state;
        if (totalWeight === 0) totalWeight = 100;
        factors
            .filter(({ sys_id }) => sys_id == selectedSystem)
            .reduce((total, item, index, arr) => {
                if (index !== arr.length - 1) {
                    item.weight = Math.floor((item.weight || 0) * 10000 / totalWeight) / 100;
                } else {
                    item.weight = (MAX_WEIGHT * 100 - total) / MAX_WEIGHT;
                }
                return total + MAX_WEIGHT * item.weight;
            }, 0);
        this.setState({ factors });
    }

    weightOnChange = (id, weight) => {
        const { factors } = this.state;
        (factors.filter(({ _id }) => _id === id)[0] || []).weight = weight || 0;
        // factors[index].weight = weight || 0;
        this.setState({ factors });
    }

    render() {
        const { factors: props_factors } = this.props;
        const { factors: local_factors, mode, loading, systems, selectedSystem } = this.state;
        const factors = mode === 'edit' ? local_factors : props_factors;
        const totalWeight = factors.filter(({ sys_id }) => sys_id == selectedSystem).reduce((total, { weight }) => total + MAX_WEIGHT * (parseFloat(weight) || 0), 0) / MAX_WEIGHT;
        return (
            <Modal className='EventModal'
                visible={true}
                width={600}
                title='考核指标权重分配'
                confirmLoading={this.state.loading}
                okText={mode == 'edit' ? '保存' : '编辑'}
                onOk={this.modalOnOk}
                onCancel={this.modalOnCancel}
                okButtonProps={{
                    loading,
                    style: {
                        backgroundColor: '#357CF7',
                        borderColor: '#357CF7',
                    },
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 12 }}>
                    <Select
                        style={{ width: 144, marginRight: 'auto' }}
                        // defaultValue="all"
                        value={selectedSystem}
                        onChange={v => this.setState({ selectedSystem: v })}
                    >
                        {
                            systems.map(({ id, name }) =>
                                <Option key={id}>{name}</Option>
                            )
                        }
                    </Select>
                    <Button disabled={mode === 'read' || loading} type="primary" ghost onClick={() => this.autoSetting(totalWeight)}>自动分配</Button>
                    <span style={{ marginLeft: 24 }}>合计： {totalWeight} %</span>
                </div>

                <Table
                    dataSource={factors.filter(({ sys_id }) => sys_id == selectedSystem)}
                    pagination={false}
                    columns={[
                        {
                            title: '指标名称',
                            dataIndex: 'meta_name',
                        },
                        {
                            title: '所属系统',
                            key: 'system',
                            render: (text, { sys_id }, index) =>
                                (systems.filter(({ id }) => id == sys_id)[0] || {})['name'] || ''
                        },
                        {
                            title: '统计方法',
                            key: 'score_method',
                            render: (text, { score_method }, index) =>
                                (SCORE_METHODS.filter(({ id }) => id == score_method)[0] || {}).name || '累计/平均'
                            // score_method
                        },
                        {
                            title: '排名方法',
                            key: 'rank_method',
                            render: (text, { rank_method }, index) =>
                                rank_method
                        },
                        {
                            title: '权重',
                            key: 'weight',
                            render: (text, { weight, _id }, index) => (
                                <div>
                                    <InputNumber disabled={mode === 'read' || loading} size="small" min={0} max={MAX_WEIGHT} value={weight} onChange={v => this.weightOnChange(_id, v)} style={{ width: 60, marginRight: 10 }} />
                                    <span>%</span>
                                </div>
                            )
                        },
                    ]}
                />
            </Modal>
        );
    }
}

export default withRouter(WeightSettingModal);