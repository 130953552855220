import React from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    const { is_desc } = props;

    return (
        <div className={style['wrapper']}>
            <div className={"sort_icons" + ((typeof is_desc === 'boolean') ? (is_desc ? " desc" : " asc") : "")}></div>
        </div>
    );
};

export default Comp;
