import React from 'react'
import style from './index.module.scss'
import OnlineDisk from 'containers/OnlineDisk/OnlineDisk'

const Page = props => {
  const { customStyle } = props
  return (
    <div className={style['wrapper']} style={customStyle}>
      <div className="content_wrapper">
        <div className="content">
          <OnlineDisk />
        </div>
      </div>
    </div>
  )
}

export default Page
