import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import Tabs from 'components/TabsV2';
import { useSys } from 'containers/DemoSystemV2/hooks';

const blankFunc = () => { };

const Comp = (props) => {
    const { sysInfo, setSysInfo } = props;
    const [tabs, setTabs] = useState(null);
    const [systems] = useSys();

    const setInfoFunc = useRef(blankFunc);
    useEffect(() => {
        setInfoFunc.current = typeof setSysInfo === 'function' ? setSysInfo : blankFunc;
    }, [setSysInfo]);

    useEffect(() => {
        setTabs((prevTabs) => prevTabs ?? systems ?? prevTabs);
    }, [systems]);

    useEffect(() => {
        if (!!tabs) {
            setInfoFunc.current((prevSysInfo) => {
                if (!prevSysInfo) {
                    return tabs[0] ?? prevSysInfo;
                };
                return prevSysInfo;
            });
        };
    }, [tabs]);

    const selectSys = (item) => {
        setInfoFunc.current(item);
    };
    return (
        <div className={style['wrapper']}>
            {
                !!tabs && (
                    <Tabs tabs={tabs} selectedTab={sysInfo} onChange={selectSys} />
                )
            }
        </div>
    );
};

export default Comp;
