import moment from 'moment';
import { post } from 'common/utils';

const typeInfo = {
    'DAY': {
        usePicker: true,
        pickerType: 'single',
        parseText: tf => {
            return moment(tf.timeBegin).format("【YYYY年MM月DD日】")
        },
        getRangeAsync: datetime => {
            return new Promise((resolve) => {
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
                    .then(retData => {
                        if (retData?.data?.errcode === 0) {
                            const range = retData?.data?.results?.day_range ?? null
                            if (range) {
                                // console.log(range)
                                resolve({
                                    timeBegin: range[0],
                                    timeEnd: range[1]
                                })
                            }
                        }
                    })
            })
        },
        parseVal: v => {
            const timeBegin = moment(v).startOf('day').format("YYYY-MM-DD HH:mm:00")
            const timeEnd = moment(v).endOf('day').format("YYYY-MM-DD HH:mm:59")
            return { timeBegin, timeEnd }
        },
        info: {
            picker: 'date'
        }
    },
    'WEEK': {
        usePicker: true,
        pickerType: 'single',
        getRangeAsync: datetime => {
            return new Promise((resolve) => {
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
                    .then(retData => {
                        if (retData?.data?.errcode === 0) {
                            const range = retData?.data?.results?.week_range ?? null
                            if (range) {
                                // console.log(range)
                                resolve({
                                    timeBegin: range[0],
                                    timeEnd: range[1]
                                })
                            }
                        }
                    })
            })
        },
        parseVal: v => {
            const timeBegin = moment(v).startOf('week').format("YYYY-MM-DD HH:mm:00")
            const timeEnd = moment(v).endOf('week').format("YYYY-MM-DD HH:mm:59")
            return { timeBegin, timeEnd }
        },
        info: {
            picker: 'week',
        }
    },
    'MONTH': {
        usePicker: true,
        pickerType: 'single',
        getRangeAsync: datetime => {
            return new Promise((resolve) => {
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
                    .then(retData => {
                        if (retData?.data?.errcode === 0) {
                            const range = retData?.data?.results?.month_range ?? null
                            if (range) {
                                // console.log(range)
                                resolve({
                                    timeBegin: range[0],
                                    timeEnd: range[1]
                                })
                            }
                        }
                    })
            })
        },
        parseVal: v => {
            const timeBegin = moment(v).startOf('month').format("YYYY-MM-DD HH:mm:00")
            const timeEnd = moment(v).endOf('month').format("YYYY-MM-DD HH:mm:59")
            return { timeBegin, timeEnd }
        },
        info: {
            picker: 'month',
        }
    },
    'CUSTOM': {
        usePicker: true,
        pickerType: 'range',
        parseText: tf => {
            return `【${moment(tf.timeBegin).format("YYYY年MM月DD日 HH:mm")} - ${moment(tf.timeEnd).format("YYYY年MM月DD日 HH:mm")}】`
        },
        info: {
            picker: 'date',
            showTime: { format: 'HH:mm' },
            format: "YYYY-MM-DD HH:mm"
        }
    },
    'TODAY': {
        isStatic: true,
        title: '当日',
        type: 'async',
        getRangeAsync: () => {
            return new Promise((resolve) => {
                // 当日
                const datetime = moment().format("YYYY-MM-DD HH:mm:ss")
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
                    .then(retData => {
                        if (retData?.data?.errcode === 0) {
                            const range = retData?.data?.results?.day_range ?? null
                            if (range) {
                                // console.log(range)
                                resolve({
                                    timeBegin: range[0],
                                    timeEnd: datetime
                                })
                            }
                        }
                    })
            })
        },
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).format('YYYY-MM-DD 00:00:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD 23:59:59')
            return { timeBegin, timeEnd }
        }
    },
    'CURRENT-WEEK': {
        isStatic: true,
        title: '本周',
        type: 'async',
        getRangeAsync: () => {
            return new Promise((resolve) => {
                // 当日
                const datetime = moment().format("YYYY-MM-DD HH:mm:ss")
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
                    .then(retData => {
                        if (retData?.data?.errcode === 0) {
                            const range = retData?.data?.results?.week_range ?? null
                            if (range) {
                                // console.log(range)
                                resolve({
                                    timeBegin: range[0],
                                    timeEnd: datetime
                                })
                            }
                        }
                    })
            })
        },
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).startOf('week').format('YYYY-MM-DD 00:00:00')
            const timeEnd = moment(curr).endOf('week').format('YYYY-MM-DD 23:59:59')
            return { timeBegin, timeEnd }
        }
    },
    'CURRENT-MONTH': {
        isStatic: true,
        title: '本月',
        type: 'async',
        getRangeAsync: () => {
            return new Promise((resolve) => {
                // 当日
                const datetime = moment().format("YYYY-MM-DD HH:mm:ss")
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_dwmy_range', { datetime })
                    .then(retData => {
                        if (retData?.data?.errcode === 0) {
                            const range = retData?.data?.results?.month_range ?? null
                            if (range) {
                                // console.log(range)
                                resolve({
                                    timeBegin: range[0],
                                    timeEnd: datetime
                                })
                            }
                        }
                    })
            })
        },
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).startOf('month').format('YYYY-MM-DD 00:00:00')
            const timeEnd = moment(curr).endOf('month').format('YYYY-MM-DD 23:59:59')
            return { timeBegin, timeEnd }
        }
    },
    'LATEST-30-MINUTES': {
        isStatic: true,
        title: '过去30分钟',
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD HH:mm:59')
            return { timeBegin, timeEnd }
        }
    },
    'LATEST-4-HOURS': {
        isStatic: true,
        title: '过去4小时',
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).subtract(4, 'hours').format('YYYY-MM-DD HH:mm:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD HH:mm:59')
            return { timeBegin, timeEnd }
        }
    },
    'LATEST-1-DAY': {
        isStatic: true,
        title: '过去1天',
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).subtract(1, 'days').format('YYYY-MM-DD HH:mm:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD HH:mm:59')
            return { timeBegin, timeEnd }
        }
    },
    'LATEST-10-DAYS': {
        isStatic: true,
        title: '过去10天',
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).subtract(10, 'days').format('YYYY-MM-DD HH:mm:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD HH:mm:59')
            return { timeBegin, timeEnd }
        }
    },
    'LATEST-2-MONTHS': {
        isStatic: true,
        title: '过去2个月',
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).subtract(2, 'months').format('YYYY-MM-DD HH:mm:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD HH:mm:59')
            return { timeBegin, timeEnd }
        }
    },
    'LATEST-1-YEAR': {
        isStatic: true,
        title: '过去1年',
        getRange: () => {
            const curr = moment()
            const timeBegin = moment(curr).subtract(1, 'years').format('YYYY-MM-DD HH:mm:00')
            const timeEnd = moment(curr).format('YYYY-MM-DD HH:mm:59')
            return { timeBegin, timeEnd }
        }
    }
}

const cusOpts = [
    {
        name: '任意时间',
        time_type: 'CUSTOM',
    },
    {
        name: '当日',
        time_type: 'TODAY',
    },
    {
        name: '本周',
        time_type: 'CURRENT-WEEK',
    },
    {
        name: '本月',
        time_type: 'CURRENT-MONTH',
    },
    {
        name: '过去30分钟',
        time_type: 'LATEST-30-MINUTES',
    },
    {
        name: '过去4小时',
        time_type: 'LATEST-4-HOURS',
    },
    {
        name: '过去1天',
        time_type: 'LATEST-1-DAY',
    },
    {
        name: '过去10天',
        time_type: 'LATEST-10-DAYS',
    },
    {
        name: '过去2个月',
        time_type: 'LATEST-2-MONTHS',
    },
    {
        name: '过去1年',
        time_type: 'LATEST-1-YEAR',
    },
]

const tabs2 = [
    {
        name: '日',
        time_type: 'DAY',
    },
    {
        name: '周',
        time_type: 'WEEK',
    },
    {
        name: '月',
        time_type: 'MONTH',
    },
    {
        name: '自定义',
        isOpts: true,
        options: cusOpts
    },
];

const parseTime = (timeInfo) => {
    if (timeInfo) {
        const { type } = timeInfo
        const info = typeInfo[type] ?? null
        if (info?.isStatic === true) {
            return `【${info?.title ?? '--'}】`
        } else {
            const parseText = info?.parseText ?? null
            if (parseText) {
                return parseText(timeInfo)
            }
            return `【${moment(timeInfo.timeBegin).format("YYYY年MM月DD日")} - ${moment(timeInfo.timeEnd).format("YYYY年MM月DD日")}】`
        }
    } else {
        return '【-- - --】'
    }
}

const changePicker = (timeInfo, nPicker) => {
    if (timeInfo) {
        const { time_type } = nPicker
        const info = typeInfo[time_type]
        const { getRange, parseVal } = info
        if (getRange) {
            return Object.assign({
                type: time_type
            }, getRange())
        } else if (parseVal) {
            return Object.assign({
                type: time_type
            }, parseVal(timeInfo.timeBegin))
        } else {
            return Object.assign({}, timeInfo, { type: time_type })
        }
    }
    return null
}

export {
    tabs2, parseTime, typeInfo, changePicker, cusOpts
}