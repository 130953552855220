import React from 'react';
import { Button, List, Dropdown, Menu } from 'antd';
import style from './MidWrapperPart.module.scss';
import { PlusCircleOutlined, DownOutlined } from '@ant-design/icons';
const PIC_SERVER_PATH = process.env.REACT_APP_SERVER_PATH + 'api/img/download/';

export default class MidWrapperPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    //点击midwrapper里的添加时选择类型，不提供更改，要么直接删除
    addItemOnClick(v) {
        let spd = this.props.data, listData = spd.listData, listItem = {
            dataPoint: null,
            details: [],
            type: v,
            detailItemNowSelected: null
        };
        if (v === 0 || v === 3 || v === 4) {//数字或可操作对象
            let detail = {
                top: 0,
                right: 0,
                size: 15,
                color: '#000000',
                text: '123',
                lineHeight: 100
            };
            if (v === 0) {
                detail = Object.assign({}, detail, { precision: 1 });
            };
            listItem.details.push(detail);
        } else if (v === 2) {//按钮
            listItem.details.push({
                top: 10,
                right: 10,
                size: 20
            });
            listItem.detailItemNowSelected = 0;
        } else if (v === 1) { }
        else return;
        listData.push(listItem);
        this.props.componentSetState({ listData });
    }
    exchangeTwoItem(obj, InA, InB) {
        let tmp = obj[InA];
        obj[InA] = obj[InB];
        obj[InB] = tmp;
    }
    itemManipulation = (sign, index) => {
        if (![0, 1, 2, 3].includes(sign)) return;
        console.log(sign, index);
        let spd = this.props.data, listData = spd.listData, newIndex = null;
        //加个复制功能
        if (sign === 3) {
            listData.push(JSON.parse(JSON.stringify(listData[index])));
            newIndex = listData.length - 1;
        } else if (sign === 2) {
            listData.splice(index, 1);
        } else {
            if (sign === 0) {
                newIndex = index > 0 ? index - 1 : 0;
            } else {//sign===1
                newIndex = index === listData.length - 1 ? index : index + 1;
            }
            this.exchangeTwoItem(listData, newIndex, index);
        }
        this.props.componentSetState({ listData, nowSelected: newIndex });
    }
    itemOnClick = (e, index) => {
        // console.log(e.ctrlKey);
        let self = this, { nowSelected, groupSelected } = self.props.data;
        if (e.ctrlKey) {
            let idx = groupSelected.indexOf(index);
            if (idx > -1) {
                groupSelected.splice(idx, 1);
            } else {
                groupSelected.push(index);
            }
            // if (groupSelected.length === 1) nowSelected = groupSelected[0]
            // else 
            nowSelected = null;
        } else {
            groupSelected = [index];
            nowSelected = index;
        }
        this.props.componentSetState({ nowSelected, groupSelected });
    }
    render() {
        let self = this, spd = self.props.data,
            typeDescription = {
                0: "数值", 1: "图片", 2: "跳转按钮", 3: "修改数值", 4: "字"
            },
            menu = (index) => (
                <Menu>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(0, index)}>向上</Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(1, index)}>向下</Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(3, index)}>复制</Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(2, index)}>删除</Button>
                    </Menu.Item>
                </Menu>
            ),
            itemContent = (item, index) => (
                <div className="itemWrapper">
                    <div className="displayWrapper">
                        <div className="itemIndex">{index + 1}、</div>
                        {
                            [0, 3].includes(item.type) ?
                                <div>（数值）</div>
                                :
                                [1, 2].includes(item.type) && item.dataPoint !== null &&
                                item.details.map((detail, ind) => {
                                    if (ind > 1) return null;
                                    return <img src={detail.img ? PIC_SERVER_PATH + detail.img : undefined} alt="" key={ind} />
                                })
                        }
                    </div>
                    <div className="manipulationWrapper">
                        <span>【{typeDescription[item.type]}】</span>
                        <div onClick={e => { e.stopPropagation() }}>
                            <Dropdown overlay={menu(index)}>
                                <span className="ant-dropdown-link" >操作 <DownOutlined /></span>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            );
        return (
            <div className={style["MidWrapperPart"]}>
                <List
                    itemLayout="horizontal"
                    dataSource={spd.listData}
                    renderItem={(item, index) => (
                        <List.Item className={"ListItem" + (spd.groupSelected.indexOf(index) > -1 ? " selected" : "")} onClick={e => self.itemOnClick(e, index)}>
                            {itemContent(item, index)}
                        </List.Item>
                    )}
                    footer={<div className="footerWrapper">
                        <div>
                            <Button className="detailBtn" type="primary" icon={<PlusCircleOutlined />} onClick={_ => self.addItemOnClick(0)}>数</Button>
                            <Button className="detailBtn" type="primary" icon={<PlusCircleOutlined />} onClick={_ => self.addItemOnClick(1)}>图</Button>
                            <Button className="detailBtn" type="primary" icon={<PlusCircleOutlined />} onClick={_ => self.addItemOnClick(2)}>按钮</Button>
                        </div>
                        <div>
                            <Button className="detailBtn" type="primary" icon={<PlusCircleOutlined />} onClick={_ => self.addItemOnClick(3)}>可修改对象</Button>
                            <Button className="detailBtn" type="primary" icon={<PlusCircleOutlined />} onClick={_ => self.addItemOnClick(4)}>字</Button>
                            {/* <Button className="detailBtn" type="primary" icon="plus-circle-o">其他</Button> */}
                        </div>
                        {/* <Button className="AddButton" type="primary" icon="plus-circle-o" onClick={() => self.addItemOnClick()}>添加</Button> */}
                    </div>}
                />
            </div>
        )
    }
}