import Page from '../Header';
import PageYXSN from '../HeaderYXSN';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { demoSystemV2 } = state;
    return {
        ...demoSystemV2,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
}

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(process.env.REACT_APP_PROJECT_TAG === "yxsn" ? PageYXSN : Page);

export default WrapperedPage;