import React, { useEffect, useRef, useState } from 'react';
import { get } from 'common/utils';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { message } from 'antd';
import style from './index.module.scss';
import ItemWrapper from './ItemWrapper.js';
import { GRID_ROW_HEIGHT, GRID_MIN_HEIGHT, GRID_MIN_WIDTH, GRID_COLS, GRID_BREAK_POINTS } from './constants.js';
import { withRouter } from 'react-router';
const ResponsiveGridLayout = WidthProvider(Responsive);
//BOARD_TEST_01
const Page = (props) => {
    const id = useRef(props.id);
    const [screen, setScreen] = useState(null);
    const [layoutChange, setLayoutChange] = useState(null);

    // layout 配置相关
    const [layout, setLayout] = useState(null);

    // 实例变量
    // mode
    const mode = useRef(new URLSearchParams(props.location.search).get('mode'));
    const isViewMode = useRef(mode.current === 'view');
    // grid_config
    const rowHeight = useRef(GRID_ROW_HEIGHT);
    const cols = useRef(GRID_COLS);
    const breakpoints = useRef(GRID_BREAK_POINTS);
    const minHeight = useRef(GRID_MIN_HEIGHT);
    const minWidth = useRef(GRID_MIN_WIDTH);

    const parseLayout = (layout) => {
        return layout
            .map((item) => {
                const { h, w } = item;
                return {
                    ...item,
                    minH: minHeight.current,
                    minW: minWidth.current,
                    ...isViewMode.current ? {
                        static: true,
                    } : {},
                    h: h,
                    w: (w > minWidth.current ? w : minWidth.current),
                };
            });
    };

    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + `api/grid/screens/${id.current}`, {})
            .then((retData) => {
                console.log(retData);
                if (!!retData.data && typeof retData.data.id === 'number') {
                    setScreen(retData.data);
                    try {
                        if (!!retData.data.config) {
                            const config = JSON.parse(retData.data.config);
                            let nextLayout = [];
                            if (!!config.layout) {
                                nextLayout = config.layout;
                                if (!!config.grid_config) {
                                    const gConf = config.grid_config;
                                    // row_height, cols, breakpoints, min_height, min_width
                                    if (!!gConf.row_height) {
                                        rowHeight.current = gConf.row_height;
                                    };
                                    if (!!gConf.cols) {
                                        cols.current = gConf.cols;
                                    };
                                    if (!!gConf.breakpoints) {
                                        breakpoints.current = gConf.breakpoints;
                                    };
                                    if (!!gConf.min_height) {
                                        minHeight.current = gConf.min_height;
                                    };
                                    if (!!gConf.min_width) {
                                        minWidth.current = gConf.min_width;
                                    };
                                };
                            } else if (typeof config.length === 'number') {
                                nextLayout = config;
                            };
                            nextLayout = parseLayout(nextLayout);
                            setLayout(nextLayout);
                        };
                    } catch (e) { };
                } else {
                    message.error("获取信息失败,请刷新重试!");
                };
            });
    }, []);
    const onResizeStop = () => {
        setLayoutChange(Symbol());
    };
    return screen !== null && (
        <div className={style['wrapper']}>
            {
                !!layout && (
                    <div className="grid_wrapper scroll_bar_restyle">
                        <ResponsiveGridLayout
                            className="layout"
                            rowHeight={rowHeight.current}
                            cols={cols.current}
                            breakpoints={breakpoints.current}
                            onResizeStop={onResizeStop}
                            verticalCompact={true}
                        >
                            {
                                layout
                                    .map((item, index) => {
                                        const { i, x, y, w, h, minW } = item;
                                        // console.log(item);
                                        return (
                                            <div key={i} data-grid={{ x, y, w, h, static: true, minW }}>
                                                <ItemWrapper layoutChange={layoutChange} id={parseInt(i)} range={props.range} />
                                            </div>
                                        );
                                    })
                            }
                        </ResponsiveGridLayout>
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Page);
