import { useEffect, useState } from 'react'
import { get } from 'common/utils'

const useClassifyTagList = () => {
  const [id2Title, setId2Title] = useState({})
  useEffect(() => {
    get(
      process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/templateClassificationSearch',
      {}
    ).then(retData => {
      // console.log('hook', retData);
      if (!!retData.data && retData.data.error === 0) {
        if (!!retData.data.results) {
          const nextId2Title = retData.data.results.reduce((pV, cItem) => {
            return Object.assign({}, pV, { [cItem.id]: cItem.title })
          }, {})
          setId2Title(nextId2Title)
        }
      }
    })
  }, [])
  return [id2Title]
}

export default useClassifyTagList
