import EventView from '../EventView';
import { withRouter } from 'react-router'
import { useEffect, useState } from 'react';
import { post } from 'common/utils';

const Comp = (props) => {
    const [tplCode, setTplCode] = useState(null);
    useEffect(() => {
        const tpl_code = new URLSearchParams(props?.location?.search ?? null).get('tpl_code') ?? null;
        setTplCode(tpl_code);
    }, [props]);

    const [tplInfo, setTplInfo] = useState(null);
    useEffect(() => {
        if (typeof tplCode !== 'string' || tplCode === "") {
            setTplInfo(null);
            return;
        };
        post(process.env.REACT_APP_SERVER_PATH + "api/form/get_one_form_tpl", {
            code: tplCode,
        })
            .then((retData) => {
                // console.log(retData);
                if (retData?.data?.errcode === 0) {
                    let info = retData.data.result ?? null;
                    if (!!info) {
                        let table_json = null;
                        try {
                            table_json = JSON.parse(info.table_json);
                            info = Object.assign({}, info, { table_json });
                        } catch (e) { };
                    };
                    setTplInfo(info);
                } else {
                    setTplInfo(null);
                };
            });
    }, [tplCode]);

    return !!tplInfo
        ? (
            <EventView noTree={true} info={tplInfo} />
        )
        : null;
};

export default withRouter(Comp);
