import React, { useEffect, useState } from 'react';
import moment from 'moment';
import style from './index.module.scss';

const Comp = (props) => {
    const [time, setTime] = useState(null);
    useEffect(() => {
        let mySetTimeout = null;
        const calcNewTime = () => {
            const currMoment = moment();
            setTime(currMoment.format("YYYY年M月D日 HH:mm:ss"));
            const wait_period = 1000 - (currMoment.valueOf() % 1000);
            mySetTimeout = setTimeout(calcNewTime, wait_period);
        };
        calcNewTime();
        return () => {
            clearTimeout(mySetTimeout);
        };
    }, []);

    return (
        <div className={style['wrapper']}>{time}</div>
    );
};

export default Comp;
