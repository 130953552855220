import moment from 'moment';
import { getCurrentTime } from '../../utils.js';
const currTime = getCurrentTime();
const startTime = moment(currTime).subtract(1, "months").add(1, "days");
const InitialState = {
    systems: [],
    themes: [],
    kpiDetail: null,
    shiftDetails: [],
    timeRange: [startTime, currTime],
};

export {
    InitialState,
};