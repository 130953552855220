import moment from 'moment';
import React from 'react';
import ICON_V from './res/icon.png';

const singleSuffixList = ['_D', '_D', '_W', '_W', '_M', '_M'];

const r1_suffixList = ['_DXL', '_DXL', '_WXL', '_WXL', '_MXL', '_MXL'];
const r2_suffixList = ['_DXS', '_DXS', '_WXS', '_WXS', '_MXS', '_MXS'];
const r3_suffixList = ['_D', '_D', '_W', '_W', '_M', '_M'];

const chart_sufficList = ['_D', '_D', '_W', '_W', '_M', '_M'];

const elec_items = [
    {
        style: { position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%,-100%)' },
        value: 92,
        name: '电压偏差',
        extdTag: '_RDmVL',
        chartExtdTag: '_Vzz',
        suffixList: singleSuffixList,
        tables: [
            {
                columns: [
                    {
                        title: (
                            <div className="title_wrapper">
                                <img alt="" src={ICON_V} width="16" height="16" />
                                <div className="name">线电压</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">平均</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">Ua</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">Ub</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">Uc</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_Vzz', c2: '_Vab', c3: '_Vbc', c4: '_Vca',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 0,
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1', 'c2', 'c3', 'c4'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
            {
                columns: [
                    {
                        title: (
                            <div className="title_wrapper">
                                <img alt="" src={ICON_V} width="16" height="16" />
                                <div className="name">相电压</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">平均</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">Uab</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">Ubc</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">Uac</div>
                                <div className="unit">V</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_Vz', c2: '_Va', c3: '_Vb', c4: '_Vc',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 0,
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1', 'c2', 'c3', 'c4'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
        ],
    },
    {
        style: { position: 'absolute', top: '25%', left: `${50 - 25 * Math.sqrt(3)}%`, transform: 'translate(-100%,-50%)' },
        value: 92,
        name: '功率因数',
        extdTag: '_RDmPF',
        chartExtdTag: '_PFz',
        suffixList: singleSuffixList,
        tables: [
            {
                columns: [
                    {
                        title: (
                            <div className="title_wrapper">
                                <div className="name">功率因数</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">平均</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">A相</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">B相</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">C相</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_PFz', c2: '_PFa', c3: '_PFb', c4: '_PFc',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 2,
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1', 'c2', 'c3', 'c4'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
        ],
    },
    {
        style: { position: 'absolute', bottom: '25%', left: `${50 - 25 * Math.sqrt(3)}%`, transform: 'translate(-100%,50%)' },
        value: 92,
        name: '三项不平衡',
        extdTag: '_RDmUb',
        chartExtdTag: '_Iub',
        suffixList: singleSuffixList,
        tables: [
            {
                columns: [
                    {
                        title: (
                            <div className="title_wrapper">
                                <div className="name">三相不平衡</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">电流</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">电压</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_Iub', c2: '_Vzub',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 2,
                            unit: "%",
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1', 'c2'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
        ],
    },
    {
        style: { position: 'absolute', bottom: 0, left: '50%', transform: 'translate(-50%,100%)' },
        value: 92,
        name: '谐波畸变率',
        extdTag: '_RDmTHD',
        chartExtdTag: '_thdVz',
        suffixList: singleSuffixList,
        tables: [
            {
                columns: [
                    {
                        title: (
                            <div className="title_wrapper">
                                <img alt="" src={ICON_V} width="16" height="16" />
                                <div className="name">电压谐波</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">总</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">A相</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">B相</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">C相</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_RDmTHD', c2: '_thdVa', c3: '_thdVb', c4: '_thdVc',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 2,
                            unit: "%",
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1', 'c2', 'c3', 'c4'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
            {
                columns: [
                    {
                        title: (
                            <div className="title_wrapper">
                                <img alt="" src={ICON_V} width="16" height="16" />
                                <div className="name">电流谐波</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">总</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">A相</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">B相</div>
                            </div>
                        ),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">C相</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_RDmTHD', c2: '_thdIa', c3: '_thdIb', c4: '_thdIc',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 2,
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1', 'c2', 'c3', 'c4'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
        ],
    },
    {
        style: { position: 'absolute', bottom: '25%', right: `${50 - 25 * Math.sqrt(3)}%`, transform: 'translate(100%,50%)' },
        value: 92,
        name: '负载波动性',
        extdTag: '_LDRTx',
        chartExtdTag: '_LDRTx',
        suffixList: singleSuffixList,
        tables: [
            {
                columns: [
                    {
                        title: (null),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">负载波动性</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_LDRTx',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 2,
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
        ],
    },
    {
        style: { position: 'absolute', top: '25%', right: `${50 - 25 * Math.sqrt(3)}%`, transform: 'translate(100%,-50%)' },
        value: 92,
        name: '频率偏差',
        extdTag: '_RDmHz',
        chartExtdTag: '_Hz',
        suffixList: singleSuffixList,
        tables: [
            {
                columns: [
                    {
                        title: (null),
                    },
                    {
                        title: (
                            <div className="col_title_wrapper">
                                <div className="name">频率</div>
                                <div className="unit">Hz</div>
                            </div>
                        ),
                    },
                ],
                dataSource: (basic_tag) => {
                    const c2extd = {
                        c1: '_Hz',
                    };
                    return [
                        { name: '最大', suffixList: r1_suffixList },
                        { name: '最小', suffixList: r2_suffixList },
                        { name: '平均', suffixList: r3_suffixList },
                    ].map((item) => {
                        const { suffixList } = item;
                        return Object.assign({}, item, {
                            precision: 2,
                            basic_tag,
                            ...(
                                Object.assign({}, ...(
                                    ['c1'].map((cTag) => {
                                        return {
                                            [cTag]: {
                                                extdTag: c2extd[cTag],
                                                suffixList,
                                            },
                                        };
                                    })
                                ))
                            ),
                        })
                    });
                },
            },
        ],
    },
];

const get_line_option = (data) => {
    const option = {
        tooltip: {
            trigger: 'axis',
        },
        grid: {
            left: 3,
            top: 11,
            right: 3,
            bottom: 0,
        },
        xAxis: {
            type: 'time',
            show: false,
        },
        yAxis: {
            type: 'value',
            scale: true,// true时不一定显示0
            show: false,
        },
        series: [
            {
                data: (
                    data
                )
                    .filter(([_, v]) => v !== null)
                    .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                type: 'line',
                itemStyle: {
                    color: '#FF6161',
                },
                symbol: 'none',
                lineStyle: {
                    width: 1,
                },
            },
        ],
    };
    return option;
};

const get_radar_option = (data) => {
    const option = {
        tooltip: {},
        radar: {
            name: {
                // show: false,
            },
            indicator: [
                { name: '', max: 100 }, // 电压偏差
                { name: '', max: 100 }, // 功率因数
                { name: '', max: 100 }, // 三项不平衡
                { name: '', max: 100 }, // 谐波畸变率
                { name: '', max: 100 }, // 负载波动性
                { name: '', max: 100 } // 频率偏差
            ],
            radius: '100%',
        },
        series: [{
            type: 'radar',
            symbol: 'none',
            itemStyle: {
                color: '#FB6060',
            },
            data: [
                {
                    value: data,
                    name: '电能健康'
                },
            ]
        }]
    };
    return option;
};

export {
    elec_items, chart_sufficList, get_line_option, get_radar_option
};
