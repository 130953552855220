import React, { useEffect, useState, useImperativeHandle } from 'react'
import { message, Input, Button } from 'antd'
import { get, post, getCachedUserInfo } from 'common/utils'
import style from './index.module.scss'
const baseUrl = process.env.REACT_APP_SERVER_PATH

/** 评论模块 */
const Com = props => {
  const { capsuleId, moveViewToBottom } = props
  const userInfo = getCachedUserInfo() // 当前用户信息
  const [commentList, setCommentList] = useState([]) // 评论列表
  const [commentText, setCommentText] = useState('') // 发表评论的输入内容
  const [dirty, setDirty] = useState(false)

  useImperativeHandle(props.refInstance, () => ({
    dirty
  }))
  useEffect(() => {
    getCommentListByCapsuleId()
  }, [])
  // 查询评论
  const getCommentListByCapsuleId = (isMoveViewToBottom = false) => {
    get(`${baseUrl}api/v1/event/capsule/queryComment`, {
      id: capsuleId
    })
      .then(res => {
        res.data && setCommentList(res.data.results || [])
        // TODO: 新建评论后应该将视图滚动到底部
        isMoveViewToBottom && typeof moveViewToBottom === 'function' && moveViewToBottom()
      })
      .catch(err => {
        console.log(err)
      })
  }
  /** 发送评论 */
  const submitComment = () => {
    if (!commentText || !commentText.trim()) {
      message.warning('评论内容不能为空')
      return
    }
    post(`${baseUrl}/api/v1/event/capsule/addComment`, {
      evtInsId: capsuleId,
      comment: commentText
    })
      .then(res => {
        !dirty && commentList.length === 0 && setDirty(true)
        setCommentText('')
        getCommentListByCapsuleId(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className={style['commentWrapper']}>
      <div className="title">
        评论<span>{`（${commentList.length}条）`}</span>
      </div>
      <ul className="comments">
        {commentList.map((commentItem, index) => {
          const { name, orgName, create_time, comment, user_id } = commentItem
          const isOwn = user_id === userInfo.user_id
          return (
            <li key={index} className={`comment-item ${isOwn ? 'right' : ''}`}>
              <div className="info">
                {!isOwn && (
                  <>
                    <span className="creator">{name || ''}</span>{' '}
                    <span className="org-name">{orgName ? `（${orgName}）` : ''}</span>
                  </>
                )}
                <span className="create-time">{create_time}</span>
              </div>
              <div className="text">
                <span>{comment || ''}</span>
              </div>
            </li>
          )
        })}
      </ul>
      <div className="footer">
        <Input
          placeholder="发表评论"
          style={{ width: 538, borderColor: '#375B98' }}
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
        />
        <Button type="primary" style={{ backgroundColor: '#375B98' }} onClick={submitComment}>
          发送
        </Button>
      </div>
    </div>
  )
}

export default Com
