import React from 'react';
import { Typography, Select, Space, Input, Tabs, Table, Button, message } from 'antd';
import style from './index.module.scss';
import LineChartWrapper from '../Comp/wrapper';
import { withRouter } from 'react-router';
import { post } from 'common/utils';
import { defaultItemConf } from './utils';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const range = (
    () => {
        const curr = moment();
        const lastTime = moment(curr).subtract(1, 'weeks');
        return [lastTime, curr];
    }
)();

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props?.data?.config?.title ?? null,
            deltaTitle: props?.data?.config?.deltaTitle ?? null,
            metaList: props?.data?.config?.meta_list ?? [],
            metaOptions: [],
            selectedOption: null,
            selectedMetaKey: null,
        };
    }

    componentDidMount() {
        let { registerDataFunc } = this.props;
        if (!!registerDataFunc) {
            registerDataFunc(() => {
                return Promise.resolve(this.getConf());
            });
        }
        this.handleSearch("");
    }

    handleSearch = value => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            page: 1,
            page_size: 10,
            keyword: value ?? ""
        })
            .then((retData) => {
                // console.log(retData);
                if (retData?.data?.errcode === 0) {
                    const metaOptions = retData?.data?.results ?? []
                    this.setState({ metaOptions })
                } else {
                    this.setState({ metaOptions: [] })
                }
            });
    };

    addMeta = meta => {
        const { metaList, metaOptions } = this.state;
        let nextMetaList = [].concat(metaList ?? []);
        if (nextMetaList.length >= 2) {
            message.warning("只能选择两个点位!");
        } else {
            const nextSelectedOption = (metaOptions ?? [])
                .filter(({ tag_name }) => tag_name === meta)?.[0] ?? null;
            this.setState({ selectedOption: nextSelectedOption });
            if (!!nextSelectedOption) {
                if (!nextMetaList.map(({ tag_name }) => tag_name).includes(nextSelectedOption?.tag_name ?? null)) {
                    // 未有
                    // 提示添加成功
                    const addonMeta = Object.assign({}, nextSelectedOption, defaultItemConf);
                    nextMetaList = nextMetaList.concat([addonMeta]);
                    this.setState({ metaList: nextMetaList });
                    message.success(`成功添加点位【${addonMeta?.name ?? "--"}】`);
                } else {
                    // 已有
                    // 提示已有
                    message.warning("已有该点位，请勿重复添加!");
                };
            };
            // 选择好以直接后添加到下面的table中去，不过需要判断一下是否重复添加，如果重复添加，需要提示并不加入
        }
    }

    getConf = () => {
        const { title, deltaTitle, metaList } = this.state;
        return {
            title,
            deltaTitle,
            meta_list: metaList,
        };
    };

    delete = (item) => {
        return () => {
            const { metaList } = this.state;
            const nextMetaList = metaList.filter(({ tag_name }) => tag_name !== (item?.tag_name ?? null));
            this.setState({ metaList: nextMetaList });
        };
    };

    rowOnClick = (item) => {
        return () => {
            // console.log(item);
            this.setState({ selectedMetaKey: item?.tag_name ?? null });
        };
    }

    onRow = (record, index) => {
        return {
            style: { cursor: "pointer" },
            onClick: this.rowOnClick(record),
        };
    }

    getSelectedMeta = () => {
        const { selectedMetaKey, metaList } = this.state;
        const selectedMeta = metaList
            .filter(({ tag_name }) => tag_name === selectedMetaKey)[0] ?? null;
        return selectedMeta;
    };

    render() {
        let self = this;
        let { title, metaOptions, metaList, selectedMetaKey, selectedOption, deltaTitle } = this.state;

        const selectedMeta = this.getSelectedMeta();

        return (
            <div className={style['wrapper']}>
                <div className="display_block">
                    <LineChartWrapper config={this.getConf()} range={range} />
                </div>
                <div className="edit_block">
                    <Tabs defaultActiveKey="1" tabPosition="left">
                        <TabPane tab="基本配置" key="1">
                            <Space direction="vertical">
                                <Space>
                                    <Text>标题</Text>
                                    <Input placeholder="请输入标题名" value={title} onChange={e => this.setState({ title: e.target.value })} style={{ width: 360 }} />
                                </Space>
                                <Space>
                                    <Text>差值标题</Text>
                                    <Input placeholder="差值标题" value={deltaTitle} onChange={e => this.setState({ deltaTitle: e.target.value })} style={{ width: 332 }} />
                                </Space>
                            </Space>
                        </TabPane>
                        <TabPane tab="数据项" key="2">
                            <Space>
                                <Space className="leftMetaList" direction="vertical">
                                    <Space className="meta-options-line">
                                        <Text>选择点位：</Text>
                                        <Select
                                            allowClear
                                            showSearch
                                            value={selectedOption?.tag_name ?? null}
                                            style={{ width: 450 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.handleSearch}
                                            onChange={this.addMeta}
                                            notFoundContent={null}
                                        >
                                            {metaOptions.map((item, index) => <Option value={item.tag_name} key={index}>{item.name} {item.tag_name}</Option>)}
                                        </Select>
                                    </Space>
                                    <Table
                                        dataSource={metaList}
                                        rowKey="tag_name"
                                        locale={{ emptyText: "请添加点位" }}
                                        columns={[
                                            {
                                                title: "序号",
                                                key: "no",
                                                render: (text, r, index) => {
                                                    return index + 1;
                                                },
                                                width: 45,
                                                align: "center",
                                            },
                                            {
                                                title: "名称",
                                                dataIndex: "name",
                                                // width: 250,
                                            },
                                            {
                                                title: "点位号",
                                                dataIndex: "tag_name",
                                                width: 250,
                                            },
                                        ]}
                                        size="small"
                                        scroll={{
                                            y: 200,
                                        }}
                                        pagination={false}
                                        rowSelection={{
                                            type: "radio",
                                            columnWidth: 0,
                                            renderCell: () => null,
                                            selectedRowKeys: typeof selectedMetaKey === 'string' ? [selectedMetaKey] : [],
                                        }}
                                        onRow={this.onRow}
                                    />
                                </Space>
                                <Space className="rightConf" direction="vertical">
                                    {!!selectedMeta && <>
                                        <Space>
                                            <Text>名称：</Text>
                                            <Input
                                                value={selectedMeta.name}
                                                onChange={e => { selectedMeta.name = e.target.value; self.forceUpdate(); }}
                                                style={{ width: 200 }}
                                            />
                                        </Space>
                                        <Button danger onClick={this.delete(selectedMeta)}>删除</Button>
                                    </>}
                                </Space>
                            </Space>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
};

export default withRouter(Comp);
