import React, { useEffect, useState } from 'react'
import {
  Tabs,
  DatePicker,
  Radio,
  Checkbox,
  Input,
  Select,
  Button,
  Dropdown,
  Menu,
  message
} from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get, post } from '../../../../../common/utils'
import style from './index.module.scss'
import ViewEventsContentModal from '../ViewEventsContentModal'
const { TabPane } = Tabs
const { Option } = Select
const baseUrl = process.env.REACT_APP_SERVER_PATH
const currMoment = !!process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME ? moment(process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME) : moment();
const defaultFilterParams = {
  time: 1,
  startDate: moment(currMoment).subtract(7, 'day').format('YYYY-MM-DD HH:mm:00'),
  stopDate: moment(currMoment).format('YYYY-MM-DD HH:mm:00'),
  tag: null,
  title: '',
  username: ''
} // 筛选默认值 默认时间为近一周

/** 胶囊汇总 */
const FilterSetting = props => {
  const { tags, exportEvents, setAddAction, changeFilterParams } = props
  const USER_INFO = JSON.parse(localStorage.getItem('USER_INFO')) || {} // 用户信息
  const [timeTabIndex, setTimeTabIndex] = useState('1') // 类型需要为string
  const [startDate, setStartDate] = useState(moment(currMoment).subtract(7, 'days')) // 时间发生时间-开始时间
  const [stopDate, setStopDate] = useState(moment(currMoment)) // 时间发生时间-结束时间
  const [timeRadioIndex, setTimeRadioIndex] = useState(1) // 最近添加>radio
  const [recentlyAdd, setRecentlyAdd] = useState(1) // 最近几小时内创建的
  const [shiftList, setShiftList] = useState([]) // 排班时间列表
  const [shiftData, changeShiftData] = useState({
    classGroup: 1,
    classGroupDate: moment(),
    shiftId: shiftList.length > 0 ? shiftList[0].id : ''
  }) // 班组

  const [tagId, setTagId] = useState([]) // 标签
  const [title, setTitle] = useState('') // 标题/内容
  const [username, setUsername] = useState('') // 填写人
  const [viewMyself, changeViewMyself] = useState(false) // 只看自己
  const [showExportTextView, changeShowExportTextView] = useState(false) // 导出文本弹窗 用户可自由复制

  useEffect(() => {
    getShiftConfig()
    // const params = JSON.parse(sessionStorage.getItem('filterParams'))

    // if (params) {
    //   setTimeTabIndex(String(params.time))
    //   setStartDate(params.startDate ? moment(params.startDate) : '')
    //   setStopDate(params.stopDate ? moment(params.stopDate) : '')
    //   setTagId(params.tag)
    //   setTitle(params.title)
    //   setUsername(params.username)
    //   if (String(params.time) === '2') {
    //     setTimeRadioIndex(params.timeRadioIndex)
    //     params.timeRadioIndex === 4 && setRecentlyAdd(params.recentlyAdd)
    //   }
    //   changeFilterParams(params)
    // } else {
    changeFilterParams(defaultFilterParams)
    // }
  }, [])

  /** 获取排班时间配置 */
  const getShiftConfig = () => {
    get(baseUrl + 'api/v1/event/report/shiftConfig').then(shiftData => {
      if (!!shiftData.data && shiftData.data.error === 0) {
        setShiftList(shiftData.data.results || [])
      }
    })
  }

  /** 点击查询 */
  const searchClick = () => {
    if (timeTabIndex === '1' && startDate && stopDate && startDate.isAfter(stopDate)) {
      message.error('截止时间不能大于开始时间！')
      return
    }

    if (timeTabIndex === '2' && timeRadioIndex === 4 && !recentlyAdd && recentlyAdd !== 0) {
      message.error('请输入"最近添加"的筛选时间！')
      return
    } else if (timeTabIndex === '3' && !shiftData.shiftId) {
      message.error('请选择班次')
      return
    }
    let params = {
      tag: tagId.join(',') || null,
      title,
      username
    }
    if (timeTabIndex === '1') {
      params = Object.assign({}, params, {
        startDate: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:00') : '',
        stopDate: stopDate ? moment(stopDate).format('YYYY-MM-DD HH:mm:00') : ''
      })
    } else if (timeTabIndex === '2') {
      params = Object.assign({}, params, { recentlyAdd })
    } else {
      params = Object.assign(
        {},
        params,
        shiftData.classGroup === 3
          ? {
              ...shiftData,
              classGroupDate: moment(shiftData.classGroupDate).format('YYYY-MM-DD')
            }
          : { classGroup: shiftData.classGroup }
      )
    }
    // TODO: 待定 点击查询后缓存查询条件
    // sessionStorage.setItem('filterParams', JSON.stringify(params))
    changeFilterParams(params)
  }

  /** 新增 */
  const addClick=() =>{
       typeof setAddAction === 'function' && setAddAction(Symbol())
  }

  /** 切换 起止时间/最近添加/班组 */
  const changeTimeTab = index => {
    if (index === '1') {
      setStartDate(moment().subtract(7, 'days'))
      setStopDate(moment())
    } else if (index === '2') {
      setTimeRadioIndex(1)
      setRecentlyAdd(1)
    } else if (index === '3') {
      changeShiftData({
        classGroup: 1,
        classGroupDate: moment(),
        shiftId: shiftList.length > 0 ? shiftList[0].id : ''
      })
    }
    setTimeTabIndex(index)
  }

  /** 最近添加->radio */
  const changeTimeRadioIndex = e => {
    const index = e.target.value
    setTimeRadioIndex(index)
    if (index !== 4) {
      setRecentlyAdd(index)
    }
  }

  /** 最近添加->input
   * 0~168小时，1位小数
   */
  const changeRecentlyAdd = newHour => {
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,1})?$/
    let formatValue = (newHour || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和1位小数', formatValue)
      return
    }
    formatValue = formatValue > 168 ? 168 : formatValue
    setRecentlyAdd(formatValue)
  }

  /** 字符串转数字 */
  const formatNumber = newHour => {
    if (!newHour) return
    const formatValue = isNaN(parseFloat(newHour)) ? '' : parseFloat(newHour)
    if (formatValue || formatValue === 0) {
      setRecentlyAdd(formatValue)
    } else {
      setRecentlyAdd(null)
    }
  }

  /** 修改班组
   * 1:本班 2:上个班  3: 选固定日期 的某个班次
   */
  const changeShiftRadioIndex = e => {
    const classGroup = e.target.value
    changeShiftData({
      classGroup,
      classGroupDate: moment(),
      shiftId: shiftList.length > 0 ? shiftList[0].id : ''
    })
  }

  /** 修改班组->自定义日期 */
  const changeClassGroupDate = e => {
    changeShiftData({ ...shiftData, classGroupDate: e })
  }

  /** 修改班组->班次 */
  const changeShift = shiftId => {
    changeShiftData({ ...shiftData, shiftId })
  }

  /** 变更<只看我的>复选框状态 */
  const onChangeUser = e => {
    changeViewMyself(e.target.checked)
    setUsername(e.target.checked ? USER_INFO.name : '')
  }

  /** 重置条件 */
  const resetFilterParams = () => {
    setTimeTabIndex('1')
    setStartDate(moment().subtract(7, 'days')) // 时间发生时间-开始时间
    setStopDate(moment()) // 时间发生时间-结束时间
    setTimeRadioIndex(1)
    setRecentlyAdd(1) // 最近几小时内创建的

    changeShiftData({
      classGroup: 1,
      classGroupDate: moment(),
      shiftId: shiftList.length > 0 ? shiftList[0].id : ''
    })

    setTagId([])
    setTitle('')
    setUsername('')
    changeViewMyself(false)
  }

  /** 起止时间->禁用的开始时间 */
  const disabledStartDate = e => {
    if (stopDate) {
      return moment(e.format('YYYY-MM-DD')).isAfter(stopDate.format('YYYY-MM-DD'))
    }
    return false
  }

  /** 起止时间->禁用的截止时间 */
  const disabledStopDate = e => {
    if (startDate) {
      return moment(e.format('YYYY-MM-DD')).isBefore(startDate.format('YYYY-MM-DD'))
    }
    return false
  }

  /** 班组->禁用的自定义日期 */
  const disabledClassGroupDate = e => {
    return moment(e.format('YYYY-MM-DD')).isAfter(moment().format('YYYY-MM-DD'))
  }

  /** 导出下拉框 */
  const exportMenu = (
    <Menu>
      <Menu.Item onClick={() => exportClick(1)}>
        <span>Excel</span>
      </Menu.Item>
      <Menu.Item onClick={() => exportClick(2)}>
        <span>文本</span>
      </Menu.Item>
    </Menu>
  )

  /** 点击导出
   * @param exportType 1:excel 2:txt
   */
  const exportClick = exportType => {
    if (exportType === 2) {
      changeShowExportTextView(true)
      return
    }
    const capsuleIds = exportEvents.map(item => item.id).join(',')
    const exportUrl = `${baseUrl}api/v1/event/event/attachmentDownload?exportType=${exportType}&capsuleIds=${capsuleIds}`
    console.log(exportUrl)
    post(exportUrl).then(resultData => {
      if (resultData.data && resultData.data.error === 0) {
        message.success('导出成功，请于1分钟后到【自由报表】版块查阅')
      } else {
        message.error('导出失败')
      }
    })
  }

  return (
    <div className={style['filter-container']}>
      <div className="pageTitle">
        <span>事件汇总</span>
        <i className="filter-icon" />
      </div>
      <div style={{ width: 448 }}>
        <Tabs activeKey={timeTabIndex} onChange={changeTimeTab}>
          <TabPane tab="起止时间" key="1">
            <div>
              <DatePicker
                showTime
                value={startDate}
                style={{ width: 180 }}
                format="YYYY/MM/DD HH:mm"
                disabledDate={disabledStartDate}
                onChange={setStartDate}
                onOk={setStartDate}
              />
              <span style={{ color: '#AFDAD8', margin: '0 8px' }}>-</span>
              <DatePicker
                showTime
                value={stopDate}
                style={{ width: 180 }}
                format="YYYY/MM/DD HH:mm"
                disabledDate={disabledStopDate}
                onChange={setStopDate}
                onOk={setStopDate}
              />
            </div>
          </TabPane>
          <TabPane tab="最近添加" key="2">
            <div>
              <label style={{ marginRight: 8 }}>最近</label>
              <Radio.Group name="radiogroup" value={timeRadioIndex} onChange={changeTimeRadioIndex}>
                <Radio value={1}>1小时</Radio>
                <Radio value={2}>2小时</Radio>
                <Radio value={3}>3小时</Radio>
                <Radio value={4}>
                  <Input
                    value={recentlyAdd}
                    style={{ width: 40, height: 32, marginRight: 8 }}
                    disabled={timeRadioIndex !== 4}
                    maxLength={5}
                    onChange={e => changeRecentlyAdd(e.target.value)}
                    onBlur={e => formatNumber(e.target.value)}
                  />
                  小时
                </Radio>
              </Radio.Group>
            </div>
          </TabPane>
          <TabPane tab="班组" key="3">
            <div>
              <Radio.Group
                name="radiogroup"
                value={shiftData.classGroup}
                onChange={changeShiftRadioIndex}
              >
                <Radio value={1}>本班</Radio>
                <Radio value={2}>上个班</Radio>

                <Radio value={3}>
                  <DatePicker
                    value={shiftData.classGroupDate}
                    style={{ width: 160 }}
                    format="YYYY/MM/DD"
                    allowClear={false}
                    disabledDate={disabledClassGroupDate}
                    disabled={shiftData.classGroup !== 3}
                    onChange={changeClassGroupDate}
                  />
                </Radio>
              </Radio.Group>
              {
                <Select
                  value={shiftData.shiftId}
                  style={{ width: 72, whiteSpace: 'nowrap' }}
                  disabled={shiftData.classGroup !== 3}
                  onChange={e => changeShift(e)}
                >
                  {/* <Option key={''} value={''}>
                        全部
                      </Option> */}
                  {shiftList &&
                    shiftList.map(shiftItem => {
                      return (
                        <Option key={shiftItem.id} value={shiftItem.id}>
                          {shiftItem.name}
                        </Option>
                      )
                    })}
                </Select>
              }
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div>
        <label>标签</label>
        <Select
          value={tagId}
          mode="multiple"
          placeholder="全部"
          style={{ width: 370 }}
          onChange={e => setTagId(e)}
        >
          {tags &&
            Object.keys(tags).map(tagItem => {
              return (
                <Option key={tagItem} value={tagItem}>
                  {tags[tagItem]}
                </Option>
              )
            })}
        </Select>
      </div>
      <div>
        <label>标题/内容</label>
        <Input
          value={title}
          style={{ width: 200, height: 32 }}
          maxLength={20}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div>
        <div>
          <label>填写人</label>
          <Checkbox
            style={{ float: 'right', marginBottom: 8 }}
            checked={viewMyself}
            onChange={onChangeUser}
          >
            只看我的
          </Checkbox>
        </div>
        <div>
          <Input
            value={username}
            disabled={viewMyself}
            style={{ width: 200, height: 32 }}
            maxLength={10}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
      </div>

      <div className="btn-group">
        <div className="reset-btn" onClick={resetFilterParams}>
          重置条件
        </div>
        <Button className="search-icon" type="primary" onClick={searchClick}>
          查询
        </Button>
      </div>
      <div className="btn-group">
        <Button className="add-icon" type="primary" onClick={addClick}>
          新增
        </Button>
        <Dropdown overlay={exportMenu} disabled={!exportEvents || exportEvents.length === 0}>
          <Button className="export-btn">
            导出 <MoreOutlined />
          </Button>
        </Dropdown>
      </div>
      {/* 导出文本格式 */}
      <ViewEventsContentModal
        showExportTextView={showExportTextView}
        exportEvents={exportEvents}
        changeShowExportTextView={changeShowExportTextView}
      />
    </div>
  )
}

FilterSetting.propTypes = {
  changeFilterParams: PropTypes.func.isRequired
}

export default FilterSetting
