import FullScreenWrapper from 'components/FullScreenWrapper';
import { useState, useRef } from 'react';
import { Button } from 'antd';

const Comp = (props) => {
    const [id] = useState(() => {
        return props?.match?.params?.id ?? null;
    });
    const refNode = useRef(null);
    const onLoad = () => {
        if (!!refNode.current) {
            const cb = (params) => {
                // diagramChartGoBack
                // console.log("diagramChartGoBack", params);
                const tag_alias = params?.data?.tag_alias ?? null;
                if (typeof tag_alias === 'string') {
                    const push = props?.history?.push ?? null;
                    if (typeof push === 'function') {
                        push(`/DiagramChartMobile/${tag_alias}`);
                    };
                };
            };
            const iframeWindow = refNode.current.contentWindow;
            iframeWindow.addEventListener('diagramDisplayNeedChartNumOnClick', cb);
            return () => {
                iframeWindow.removeEventListener('diagramDisplayNeedChartNumOnClick', cb);
            };
        };
    };
    const returnOnClick = () => {
        const goBack = props?.history?.goBack ?? null;
        if (typeof goBack === 'function') {
            goBack();
        };
    };
    return (
        <FullScreenWrapper>
            <div style={{ height: "100%", display: "flex", flexDirection: 'column' }}>
                <div style={{ padding: "7px 12px", display: 'flex', justifyContent: 'flex-end', borderBottom: "1px solid #f0f0f0" }}>
                    <Button size="small" onClick={returnOnClick}>返回</Button>
                </div>
                <div style={{ height: 0, flexGrow: 1 }}>
                    {
                        typeof id === 'string' && (
                            <iframe
                                src={`./#/DiagramDisplay/${id}`}
                                style={{ height: "100%", width: "100%", borderWidth: 0 }}
                                ref={refNode}
                                onLoad={onLoad}
                            />
                        )
                    }
                </div>
            </div>
        </FullScreenWrapper>
    );
};

export default Comp;
