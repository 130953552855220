import Page from './FactorSummary.js';
import { connect } from 'react-redux';
import {
    setValues,
} from '../../../common/redux/FactorSummaryV2/actions.js';
import { post, get } from '../../../common/utils';
import { queryTimeRangeFormat } from './utils.js';

const getSysList = (dispatch, getState) => {
    return get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_prod_system_list', {})
        .then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ systems: retData.data.results }));
                return true;
            };
            return false;
        });
};

const getThemeList = (dispatch, getState) => {
    return get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_factor_theme_list', {})
        .then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                dispatch(setValues({ themes: retData.data.results }));
                return true;
            };
            return false;
        });
};

const getKPIList = (cusTimeRange) => {
    return (dispatch, getState) => {
        let time_begin, time_end;
        if (!!cusTimeRange) {
            [time_begin, time_end] = queryTimeRangeFormat(cusTimeRange);
        } else {
            const { factorSummaryV2 } = getState();
            const { timeRange } = factorSummaryV2;
            [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        };
        return post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_kpi_main_list', {
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                const results = retData.data.results;
                dispatch(setValues({
                    originAllKPIList: [].concat(results),
                    // kpiList: retData.data.results,
                }));
                return true;
            };
            return false;
        });
    };
};

const setDisplayKPIList = (kpiList) => {
    return (dispatch, getState) => {
        dispatch(setValues({ kpiList: kpiList }));
    };
};

const updateTimeRange = (timeRange) => {
    return (dispatch, getState) => {
        dispatch(setValues({ timeRange }));
    };
};

const mapStateToProps = state => {
    const { factorSummaryV2, system } = state;
    const { kpiList, systems, themes, timeRange, originAllKPIList } = factorSummaryV2;
    const { customFactors } = system;
    return {
        kpiList,
        systems,
        themes,
        timeRange,
        originAllKPIList,
        customFactors,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getKPIList: (timeRange) => dispatch(getKPIList(timeRange)),
        getSysList: () => dispatch(getSysList),
        getThemeList: () => dispatch(getThemeList),
        updateTimeRange: (timeRange) => dispatch(updateTimeRange(timeRange)),
        setDisplayKPIList: (kpiList) => dispatch(setDisplayKPIList(kpiList)),
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;