import React from "react";
import style from './index.module.scss'
import CardLineChart from "./LineChart";

const CardList = (props) => {
    const {
        energyGasNumber,
        energyGasPercent,
        energyEthaneNumber,
        energyEthanePercent,
        energyPropaneNumber,
        energyPropanePercent,
        energyButaneNumber,
        energyButanePercent,
        energyElectricNumber,
        energyElectricPercent,
        gasChart,
        ethaneChart,
        propaneChart,
        butaneChart,
        electricChart,
        gasChartTime,
        ethaneChartTime,
        propaneChartTime,
        butaneChartTime,
        electricChartTime
    } = props
    return (
        <ul className={style['cardList']}>
            <li>
                <div className={style['cardItem-Top']}>
                    <span className={style['cardItem-top-title']}>原料气</span>
                    <span className={style['cardItem-top-text']}>流量</span>
                    <span className={style['cardItem-top-percent']}>
                        {energyGasPercent? Number(energyGasPercent).toFixed(2): '--'}%
                    </span>
                </div>
                <div className={style['cardItem-center']}>
                    <span className={style['number']}>
                        {energyGasNumber? Number(energyGasNumber).toFixed(4): '--'}
                    </span>
                    <span>万Nm³/h</span>
                </div>
                <CardLineChart lineChart={gasChart} timeDate={gasChartTime} />
            </li>
            <li>
                <div className={style['cardItem-Top']}>
                    <span className={style['cardItem-top-title']}>乙烷</span>
                    <span className={style['cardItem-top-text']}>收率</span>
                    <span className={style['cardItem-top-percent']}>
                        {energyEthanePercent? Number(energyEthanePercent).toFixed(2): '--'}%
                    </span>
                </div>
                <div className={style['cardItem-center']}>
                    <span className={style['number']}>
                        {energyEthaneNumber? Number(energyEthaneNumber * 100).toFixed(2) : '--'}
                    </span>
                    <span>%</span>
                </div>
                <CardLineChart lineChart={ethaneChart} timeDate={ethaneChartTime} />
            </li>
            <li>
                <div className={style['cardItem-Top']}>
                    <span className={style['cardItem-top-title']}>丙烷</span>
                    <span className={style['cardItem-top-text']}>收率</span>
                    <span className={style['cardItem-top-percent']}>
                        {energyPropanePercent? Number(energyPropanePercent).toFixed(2): '--'}%
                    </span>
                </div>
                <div className={style['cardItem-center']}>
                    <span className={style['number']}>
                        {energyPropaneNumber? Number(energyPropaneNumber * 100).toFixed(2): '--'}
                    </span>
                    <span>%</span>
                </div>
                <CardLineChart lineChart={propaneChart} timeDate={propaneChartTime} />
            </li>
            <li>
                <div className={style['cardItem-Top']}>
                    <span className={style['cardItem-top-title']}>丁烷</span>
                    <span className={style['cardItem-top-text']}>收率</span>
                    <span className={style['cardItem-top-percent']}>
                        {energyButanePercent? Number(energyButanePercent).toFixed(2): '--'}%
                    </span>
                </div>
                <div className={style['cardItem-center']}>
                    <span className={style['number']}>
                        {energyButaneNumber? Number(energyButaneNumber * 100).toFixed(2): '--'}
                    </span>
                    <span>%</span>
                </div>
                <CardLineChart lineChart={butaneChart} timeDate={butaneChartTime} />
            </li>
            <li className={style['noMarinLeft']}>
                <div className={style['cardItem-Top']}>
                    <span className={style['cardItem-top-title']}>电</span>
                    <span className={style['cardItem-top-text']}>单耗</span>
                    <span className={style['cardItem-top-percent']}>
                        {energyElectricPercent? Number(energyElectricPercent).toFixed(2): '--'}%
                    </span>
                </div>
                <div className={style['cardItem-center']}>
                    <span className={style['number']}>
                        {energyElectricNumber? `${Number(energyElectricNumber).toFixed(0)}`: '--'}
                    </span>
                    <span style={{fontSize: 14}}>kWh/万Nm³</span>
                </div>
                <CardLineChart lineChart={electricChart} timeDate={electricChartTime} />
            </li>
        </ul>
    )
}
export default CardList
