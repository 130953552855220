import React from 'react'
import { withRouter } from 'react-router'
import { get } from 'common/utils'
import Content from './Content'
import style from './ExamRuleConfig.module.scss'

class ExamRuleConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ruleDetail: {},
      factors: []
    }
  }

  componentDidMount() {
    this.getRuleDetail()
  }

  getRuleDetail = () => {
    const { id: _id } = this.props
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/details', {
      _id
    }).then(retData => {
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0) {
        this.setState({
          ruleDetail: retData.data.result || {},
          factors: (retData.data.result.factors || []).map(item => {
            item.key = item._id
            return item
          })
        })
      }
    })
  }
  render() {
    let self = this
    const { customStyle } = this.props

    return (
      <div className={style['exam_rule_config_wrapper']} style={customStyle}>
        <div className="content_root_wrapper">
          <Content
            ruleDetail={this.state.ruleDetail}
            factors={this.state.factors}
            getRuleDetail={this.getRuleDetail}
            id={this.props.id}
            goBackToList={this.props.goBackToList}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(ExamRuleConfig)
