import React, { useEffect, useState, useRef, useMemo, useContext } from 'react'
import { Modal, Tabs, DatePicker, Radio, Input, Select, Button, message } from 'antd'
import moment from 'moment'
import Dialog from '../Dialog'
import CommonContext from '../../context'
import { useTemplateClassification } from '../Dialog/hooks'
import { get } from '../../../../common/utils'
import './index.scss'
const { TabPane } = Tabs
const { Option } = Select
const baseUrl = process.env.REACT_APP_SERVER_PATH
const defaultFilterParams = {
  time: 1,
  startDate: moment().subtract(7, 'day').format('YYYY-MM-DD HH:mm:00'),
  stopDate: moment().format('YYYY-MM-DD HH:mm:00'),
  tag: '',
  title: '',
  username: ''
} // 筛选默认值 默认时间为近一周

const FilterCapsuleModal = props => {
  const { editEventOnClick, closeFilterModal, navigateToEvent, refreshNewNum } = props
  const [timeTabIndex, setTimeTabIndex] = useState('1') // 类型需要为string
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'))
  const [stopDate, setStopDate] = useState(moment())
  const [timeRadioIndex, setTimeRadioIndex] = useState(1)
  const [radioHour, setRadioHour] = useState(null)
  const [tags] = useTemplateClassification([])
  const [tagId, setTagId] = useState('')
  const [title, setTitle] = useState('')
  const [username, setUsername] = useState('')
  const [filterParams, changeFilterParams] = useState(null)

  const commonInfo = useContext(CommonContext)
  /** 点击取消 */
  const closeFilterModalClick = () => {
    if (typeof closeFilterModal === 'function') closeFilterModal()
  }
  useEffect(() => {
    const params = JSON.parse(localStorage.getItem('filterParams'))

    if (params) {
      setTimeTabIndex(String(params.time))
      setStartDate(params.startDate ? moment(params.startDate) : '')
      setStopDate(params.stopDate ? moment(params.stopDate) : '')
      setTagId(params.tag)
      setTitle(params.title)
      setUsername(params.username)
      if (String(params.time) === '2') {
        setTimeRadioIndex(params.timeRadioIndex)
        params.timeRadioIndex === 4 && setRadioHour(params.radioHour)
      }
      changeFilterParams(params)
    } else {
      changeFilterParams(defaultFilterParams)
    }
  }, [])

  useEffect(() => {
    commonInfo.event && searchClick()
  }, [commonInfo.event])

  /** 点击查询 */
  const searchClick = () => {
    if (startDate && stopDate && startDate.isAfter(stopDate)) {
      message.error('截止时间不能大于开始时间！')
      return
    }

    if (timeTabIndex === '2' && timeRadioIndex === 4 && !radioHour && radioHour !== 0) {
      message.error('请输入"最近添加"的筛选时间！')
      return
    }
    const params = {
      time: Number(timeTabIndex),
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:00') : '',
      stopDate: stopDate ? moment(stopDate).format('YYYY-MM-DD HH:mm:00') : '',
      tag: tagId,
      title,
      username
    }
    // 点击查询后缓存查询条件
    const cacheParams = Object.assign({}, params, { timeTabIndex, timeRadioIndex, radioHour })
    localStorage.setItem('filterParams', JSON.stringify(cacheParams))
    changeFilterParams(params)
  }

  const changeTimeTab = index => {
    if (index === '1') {
      setStartDate(moment().subtract(7, 'days'))
      setStopDate(moment())
    } else {
      setTimeRadioIndex(1)
      setRadioHour(null)
      setStartDate(moment().subtract(1, 'hours'))
      setStopDate(moment())
    }
    setTimeTabIndex(index)
  }

  /** 最近添加->radio */
  const changeTimeRadioIndex = e => {
    const index = e.target.value
    setTimeRadioIndex(index)
    if (index !== 4) {
      setStartDate(moment().subtract(index, 'hours'))
      setStopDate(moment())
    }
    setRadioHour(null)
  }

  /** 最近添加->input
   * 0~168小时，1位小数
   */
  const changeRadioHour = newHour => {
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,1})?$/
    let formatValue = (newHour || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和1位小数', formatValue)
      return
    }
    formatValue = formatValue > 168 ? 168 : formatValue
    setRadioHour(formatValue)
  }

  /** 字符串转数字 */
  const formatNumber = newHour => {
    if (!newHour) return
    const formatValue = isNaN(parseFloat(newHour)) ? '' : parseFloat(newHour)
    if (formatValue || formatValue === 0) {
      setRadioHour(formatValue)
      setStartDate(moment().subtract(formatValue, 'hours'))
      setStopDate(moment())
    } else {
      setRadioHour(null)
    }
  }

  const disabledStartDate = e => {
    if (stopDate) {
      return moment(e.format('YYYY-MM-DD')).isAfter(stopDate.format('YYYY-MM-DD'))
    }
    return false
  }

  const disabledStopDate = e => {
    if (startDate) {
      return moment(e.format('YYYY-MM-DD')).isBefore(startDate.format('YYYY-MM-DD'))
    }
    return false
  }

  return (
    <>
      <Modal
        wrapClassName="filterCapsuleModal"
        visible={true}
        onCancel={closeFilterModalClick}
        width="1264px"
      >
        <div className="title">筛选</div>
        <div className="filter-container">
          <div style={{ width: 408 }}>
            <Tabs activeKey={timeTabIndex} onChange={changeTimeTab}>
              <TabPane tab="起止时间" key="1">
                <div>
                  <DatePicker
                    showTime
                    value={startDate}
                    style={{ width: 160 }}
                    format="YYYY/MM/DD HH:mm"
                    disabledDate={disabledStartDate}
                    onChange={setStartDate}
                    onOk={setStartDate}
                  />
                  <span style={{ color: '#AFDAD8', margin: '0 8px' }}>-</span>
                  <DatePicker
                    showTime
                    value={stopDate}
                    style={{ width: 160 }}
                    format="YYYY/MM/DD HH:mm"
                    disabledDate={disabledStopDate}
                    onChange={setStopDate}
                    onOk={setStopDate}
                  />
                </div>
              </TabPane>
              <TabPane tab="最近添加" key="2">
                <div>
                  <label style={{ marginRight: 8 }}>最近</label>
                  <Radio.Group
                    name="radiogroup"
                    value={timeRadioIndex}
                    onChange={changeTimeRadioIndex}
                  >
                    <Radio value={1}>1小时</Radio>
                    <Radio value={2}>2小时</Radio>
                    <Radio value={3}>3小时</Radio>
                    <Radio value={4}>
                      <Input
                        value={radioHour}
                        style={{ width: 40, height: 32, marginRight: 8 }}
                        disabled={timeRadioIndex !== 4}
                        maxLength={5}
                        onChange={e => changeRadioHour(e.target.value)}
                        onBlur={e => formatNumber(e.target.value)}
                      />
                      小时
                    </Radio>
                  </Radio.Group>
                </div>
              </TabPane>
            </Tabs>
          </div>
          <div>
            <label>标签</label>
            <Select value={tagId} style={{ width: 72 }} onChange={e => setTagId(e)}>
              <Option key={''} value={''}>
                全部
              </Option>
              {tags &&
                Object.keys(tags).map(tagItem => {
                  return (
                    <Option key={tagItem} value={tagItem}>
                      {tags[tagItem]}
                    </Option>
                  )
                })}
            </Select>
          </div>
          <div>
            <label>标题/内容</label>
            <Input
              value={title}
              style={{ width: 280, height: 32 }}
              maxLength={20}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label>填写人</label>
            <div>
              <Input
                value={username}
                style={{ width: 270, height: 32 }}
                maxLength={10}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div style={{ padding: 0 }}>
            <Button className="search-icon" onClick={searchClick}>
              查询
            </Button>
          </div>
        </div>
        <div style={{ height: 'calc(100% - 150px)' }}>
          <Dialog
            isFilter={true}
            filterParams={filterParams}
            editEventOnClick={editEventOnClick}
            navigateToEvent={navigateToEvent}
            refreshNewNum={refreshNewNum}
          />
        </div>
      </Modal>
    </>
  )
}

export default FilterCapsuleModal
