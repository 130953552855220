import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import { renderLineChart, mockList } from './utils';

const Page = (props) => {

    const refNode = useRef(null);
    useEffect(() => {
        renderLineChart(refNode.current, mockList);
    }, []);

    return (
        <div className={style['wrapper']} ref={refNode}>
        </div>
    );
};

export default Page;
