
const group_G_tags = {
    tag_1: '_H',
    tag_2: '_HDQOQ',
};

const group_G = {
    name: '原料处理量实时监测',
    children: [
        {
            name: '原料气总流量',
            basic_tag: 'PLANT_RAWG_RAW_RATE',
            unit: '万Nm³/h',
            ...group_G_tags,
            precision_1: 3,
        },
        {
            name: '欢喜岭来气流量',
            basic_tag: 'PLANT_RAWGa_RAW_RATE',
            unit: '万Nm³/h',
            ...group_G_tags,
            tag_1: '',
            precision_1: 3,
        },
        {
            name: '兴隆台来气流量',
            basic_tag: 'PLANT_RAWGb_RAW_RATE',
            unit: '万Nm³/h',
            ...group_G_tags,
            tag_1: '',
            precision_1: 3,
        },
    ],
};

const group_H_tags = {
    tag_1: '_H',
    tag_2: '_HDQOQ',
};

const group_H = {
    name: '全厂产量实时监测',
    children: [
        {
            name: '天然气外输流量',
            basic_tag: 'PLANT_CNG_PRDT_RATE',
            unit: '万Nm³/h',
            ...group_H_tags,
            precision_1: 3,
        },
        {
            name: '乙烷回收流量',
            basic_tag: 'PLANT_C2H6_PRDT_RATE',
            unit: '万Nm³/h',
            ...group_H_tags,
            precision_1: 3,
        },
        {
            name: '丙烷回收流量',
            basic_tag: 'PLANT_C3H8_PRDT_RATE',
            unit: 't/h',
            ...group_H_tags,
        },
        {
            name: '丁烷回收流量',
            basic_tag: 'PLANT_C4H10_PRDT_RATE',
            unit: 't/h',
            ...group_H_tags,
        },
        {
            name: '烯烃回收流量',
            basic_tag: 'PLANT_C5P_PRDT_RATE',
            unit: 't/h',
            ...group_H_tags,
        },
    ],
};

export {
    group_G,
    group_H,
};
