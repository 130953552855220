import React, { useContext, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import SimpleCard from '../SimpleCard';
import CommonContext from '../../context';
import { post } from 'common/utils.js'
import { getCards } from './utils.js'

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const conf = useMemo(() => (commonInfo?.state?.conf ?? null), [commonInfo])

    const [cards, setCards] = useState(() => getCards())
    useEffect(() => {
        // console.log(commonInfo)
        let config = conf
        if (config) { // mock
            // console.log(conf)
            // config = {
            //     "dtype": "data_weekly",
            //     "date": "2021-12-11",
            //     "basic_tag": "PL0_AC_M1"
            // } // mock
            post(process.env.REACT_APP_SERVER_PATH + 'puyuan_api/ele_main_card', config)
                .then(retData => {
                    // retData = mockRet //mock
                    // console.log(retData)
                    if (retData?.data?.errcode === 0) {
                        const obj = retData?.data?.result ?? null
                        // console.log(obj)
                        setCards(getCards(obj))
                    }
                })
        }
    }, [conf])

    // console.log(cards)

    return (
        <div className={style['wrapper']}>
            {
                cards
                    .map((item, index) => {
                        return (
                            <div key={index} className="item_wrapper">
                                <SimpleCard info={item} />
                            </div>
                        );
                    })
            }
        </div>
    );
};

export default Comp;
