import DomResize from 'components/DomResize';
import { useMemo, useState } from 'react';

const Comp = (props) => {
    const [widthHeight, setWidthHeight] = useState(null);

    const onResize = (conf) => {
        // console.log(conf);
        const { width, height } = conf;
        const nextWidth = width > height ? width : height;
        const nextHeight = width > height ? height : width;
        const needRotate = width < height;
        setWidthHeight({ width: nextWidth, height: nextHeight, needRotate });
    };

    const style = useMemo(() => {
        if (!!widthHeight) {
            const { width, height, needRotate } = widthHeight;
            return Object.assign(
                {},
                { position: 'relative', height, width },
                needRotate === true ? {
                    // position: 'absolute',
                    // top: "50%",
                    // left: "50%",
                    left: (height - width) / 2,
                    top: (width - height) / 2,
                    transform: 'rotate(90deg)',
                    transformOrigin: 'center',
                } : {},
            );
        };
        return {};
    }, [widthHeight]);

    return (
        <div style={{ height: "100%", position: 'relative', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                <DomResize mountedCall={true} onResize={onResize} />
            </div>
            {
                !!widthHeight && (
                    <div style={style}>{props.children}</div>
                )
            }
        </div>
    );
};

export default Comp;
