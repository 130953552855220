import React, { useMemo } from 'react';
import style from './index.module.scss';

const defaultPrecision = 2;

const Comp = (props) => {
    const { name, unit, value, abnormal, highlight, valueColor, precision } = props;
    const multiNum = useMemo(() => {
        // 引入value，判断5位数，保留1位，6位数以上不保留小数位，源于设计稿保留2位小数，但是假数据5位整数+2位小数超出范围, 负数暂时不考虑
        let p = defaultPrecision;
        if (typeof precision === 'number') p = precision;

        if (typeof value === 'number') {
            if (value > 100000) {
                if (p > 0) p = 0;
            } else if (value > 10000) {
                if (p > 1) p = 1;
            };
        };

        return Math.pow(10, p);
    }, [precision, value]);

    const valueText = useMemo(() => typeof value === 'number' ? Math.floor(value * multiNum) / multiNum : "--", [value, multiNum]);
    const unitText = useMemo(() => typeof unit === 'string' ? unit : null, [unit]);
    const nameText = useMemo(() => typeof name === 'string' ? name : "--", [name]);

    const additionalWrapperStyle = useMemo(() => {
        if (highlight === true) {
            return {
                backgroundColor: '#AFDAD8',
            };
        } else {
            return {};
        };
    }, [highlight]);

    const additionalTextStyle = useMemo(() => {
        if (typeof valueColor === 'string') {
            return { color: valueColor };
        } else if (abnormal === true) {
            return { color: '#FB6060' };
        } else if (highlight === true) {
            return { color: '#00676B' };
        } else {
            return {};
        };
    }, [abnormal, highlight, valueColor]);

    return (
        <div className={style['wrapper']} style={additionalWrapperStyle}>
            <div className="value_wrapper" style={additionalTextStyle}>
                <div className="value">{valueText}</div>
                {
                    unitText !== null && (
                        <div className="unit">{unitText}</div>
                    )
                }
            </div>
            <div className="name">{nameText}</div>
        </div>
    );
};

export default Comp;
