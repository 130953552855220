import React from 'react';
import {
    CloseOutlined
} from '@ant-design/icons';

import EnergyConsumptionItem from './EnergyConsumptionItem.js';

import NENGHAOGAIKUANG_ICON from './res/nenghaogaikuang.png';

import './EnergyConsumptionModal.scss';
const moment = require('moment');

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
        };
    }

    componentDidMount() {
        this.setState({
            dataList: [
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
            ],
        });
    }

    onCancel = () => {
        const { onClose = () => { } } = this.props;
        onClose();
    }

    render() {
        const { dataList } = this.state;

        return (
            <div className="main_page_bottom_panels_energy_consumption_modal_wrapper">
                <div className="mask" onClick={this.onCancel} />
                <div className="cus_modal_wrapper">
                    <div className="modal_header">
                        <img alt="" src={NENGHAOGAIKUANG_ICON} className="title_icon" />
                        <div className="title">能源概况</div>
                        <div className="time">【 {moment().format('YYYY年MM月DD日')} 】 </div>
                        {/* <div className="time">【 2020年12月20日 】 </div> */}
                        <CloseOutlined className="cus_close_icon" onClick={this.onCancel} />
                    </div>
                    <div className="modal_body">
                        <div className="cus_content_wrapper scroll_bar_restyle">
                            {
                                dataList
                                    .map((item, index) => {
                                        return (
                                            <div className="item_self_wrapper" key={index}>
                                                <EnergyConsumptionItem info={item} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
