import NengHaoKanBan from './res/NengHaoKanBan.png';
import TongJiBaoBiao from './res/TongJiBaoBiao.png';
import JianKongBaoJing from './res/JianKongBaoJing.png';
// import GongKuangXunYou from './res/GongKuangXunYou.png';
// import XiaoZhiBiaoJingSai from './res/XiaoZhiBiaoJingSai.png';
import XiTongSheZhi from './res/XiTongSheZhi.png';
import LiShiQuXian from './res/GongKuangXunYou.png';

const category = [
    {
        name: '综合',
        icon: '',
    },
    {
        name: '生产',
        icon: '',
    },
    {
        name: '能源',
        icon: '',
    },
    {
        name: '质量',
        icon: '',
    },
    {
        name: '改善',
        icon: '',
    },
    {
        name: '自定义',
        icon: '',
    },
];

const menus = [
    {
        icon: NengHaoKanBan,
        name: '能耗看板',
        children: [
            {
                name: '全厂总览',
                route: '/System/MainPage',
            },
            {
                name: '指标分析',
                route: '/System/FactorSummary',
            },
            // {
            //     name: '能耗分析',
            // },
            // {
            //     name: '改善机会',
            // },
            // {
            //     name: '对标管理',
            // },
            // {
            //     name: '数据查询',
            // },
            // {
            //     name: '指标管理',
            // },
        ],
    },
    {
        icon: TongJiBaoBiao,
        name: '统计报表',
        children: [
            {
                name: '自定义报表',
                route: '/System/StatisticalReport',
                children: [
                    {
                        name: '编辑报表',
                        route: '/System/EditReport/',
                    },
                ],
            },
        ],
    },
    {
        icon: JianKongBaoJing,
        name: '监控报警',
        children: [
            {
                name: '报警结果',
                route: '/System/AlarmList',
            },
            {
                name: '报警配置',
                route: '/System/AlarmSetting',
            },
        ],
    },
    {
        icon: LiShiQuXian,
        name: '历史曲线',
        route: '/System/HistoryData',
    },
    {
        icon: XiTongSheZhi,
        name: '系统设置',
        children: [
            {
                name: '个人中心',
                route: '/System/PersonalInfo',
            },
            {
                name: '用户管理',
                route: '/System/PersonMng',
            },
            {
                name: '角色设置',
                route: '/System/RoleMngSetting',
            }
        ],
    },
    // {
    //     icon: GongKuangXunYou,
    //     name: '工况寻优',
    // },
    // {
    //     icon: XiaoZhiBiaoJingSai,
    //     name: '小指标竞赛',
    // },
    // {
    //     icon: XiTongSheZhi,
    //     name: '系统管理',
    // },
];

export {
    category,
    menus,
};