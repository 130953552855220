import React, { useMemo, useContext, useState, useEffect } from 'react';
import SepTimePie from './SepTimePie';
import DailyChart from './DailyChart';
import style from './SepTimeBoard.module.scss';
import CommonContext from '../../context';
import { post } from 'common/utils';
import { Spin } from 'antd';

const Comp = (props) => {
    const [loading, setLoading] = useState(false);
    const commonInfo = useContext(CommonContext);
    const conf = useMemo(() => (commonInfo?.state?.conf ?? null), [commonInfo])
    const [data, setData] = useState(null)
    useEffect(() => {
        // console.log(commonInfo)
        let config = conf
        if (config) { // mock
            // console.log(conf)
            // config = {
            //     "dtype": "data_daily",
            //     "date": "2021-12-12",
            //     "basic_tag": "PL0_AFC_F04"
            // }// mock
            setLoading(true)
            post(process.env.REACT_APP_SERVER_PATH + 'puyuan_api/ele_charge_chart', config)
                .then(retData => {
                    setLoading(false)
                    // retData = mockRet //mock
                    // console.log(retData)
                    if (retData?.data?.errcode === 0) {
                        const obj = retData?.data?.result ?? null
                        // console.log(obj)
                        setData(obj)
                    }
                })
        }
    }, [conf])

    const pieDatas = useMemo(() => {
        if (data?.chart) {
            const tag2Val = Object.assign({}, data.chart.charge, data.chart.power)
            return ['kWhz2', 'kWhz1', 'kWhz0', 'kWhz3', 'elchrg2', 'elchrg1', 'elchrg0', 'elchrg3'].map(tag => (tag2Val[tag] ?? null))
        }
        return null;
    }, [data]);

    const hisDatas = useMemo(() => {
        if (data?.bar) {
            return data.bar
        }
        return null;
    }, [data]);

    return (
        <div className={style['SepTimeBoard']}>
            <div className='BoardTitle'>峰谷平用电分析</div>
            <div className='BoardContent'>
                <div className="left">
                    <SepTimePie pieDatas={pieDatas} />
                </div>
                <div className="right">
                    <DailyChart hisDatas={hisDatas} />
                </div>
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
