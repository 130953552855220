import { useEffect, useState } from 'react';
import { post } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, product_id, range } = conf;
            if (typeof time !== 'string' || typeof product_id !== 'number') {
                setResult(null);
                return;
            };
            let requesting = true;
            setLoading(true);
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_product_cost_trend', {
                time_type, time: time_start, product_id,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data;

                            let parsed_basic_info = nextResult?.basic_info;
                            if (!!parsed_basic_info) {
                                let date_range = "";
                                let date = "";
                                let cost_text = "";
                                let pot_cost_text = "";
                                const history_datas = nextResult?.history_datas;
                                if (history_datas.length > 0) {
                                    const last_data = history_datas[history_datas.length - 1];
                                    date = moment(last_data.time).format("YYYY/MM/DD");
                                    cost_text = typeof last_data.cost === 'number' ? Math.floor(last_data.cost * 10) / 10 : "--";
                                    pot_cost_text = typeof last_data.pot_cost === 'number' ? Math.floor(last_data.pot_cost * 10) / 10 : "--";

                                    const first_data_date = moment(history_datas[0].time).format("YYYY/MM/DD");
                                    date_range = `【 ${first_data_date} - ${date} 】`;
                                };
                                parsed_basic_info = Object.assign({}, parsed_basic_info, {
                                    date,
                                    cost_text,
                                    pot_cost_text,
                                    date_range,
                                });
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_basic_info,
                            });
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
