import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './index.scss'
import ReportModal from './ReportModal'
import { get } from '../../../common/utils'

const Report = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [reportRecords, setReportRecords] = useState([])
  const [recordSelect, setRecordSelect] = useState({})

  useEffect(() => {
    getReportRecords()
  }, [])

  /** 获取报表 */
  const getReportRecords = () => {
    get(`${process.env.REACT_APP_SERVER_PATH}api/v1/event/report/reportList`, {
      date: moment().format('YYYY-MM-DD HH:mm:ss')
    }).then(res => {
      setReportRecords(res.data && res.data.results ? res.data.results : [])
    })
  }

  /** 打开弹窗 */
  const openModal = recordItem => {
    setRecordSelect(recordItem)
    setIsModalVisible(true)
  }

  /** 关闭弹窗 */
  const closeModal = isRefresh => {
    setRecordSelect({})
    setIsModalVisible(false)
    isRefresh && getReportRecords()
  }

  return (
    <div className={'report-container'}>
      {/* <i className="report-icon"></i> */}
      <div className="title">报表</div>
      <ul className="report-records">
        {reportRecords.map((recordItem, index) => {
          return (
            <li key={index}>
              <div className="fill-count">当班待填记录：{recordItem.cellCount}</div>
              <div
                className="card-bg"
                className={`card-bg ${recordSelect.id === recordItem.id ? 'active' : ''}`}
              >
                <div
                  className={`report-card ${recordItem.cellCount ? 'need-fill' : ''}`}
                  onClick={() => openModal(recordItem)}
                >
                  {recordItem.report_name}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {isModalVisible && (
        <ReportModal
          recordSelect={recordSelect}
          onCancel={closeModal}
          onOk={() => closeModal(true)}
        />
      )}
    </div>
  )
}

export default Report
