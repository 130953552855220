import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import * as echarts from 'echarts~5';
import { useData } from './hooks';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';
import { significantNum } from 'common/utils';

const left = 53;
const right = 23;
const top = 16;
const bottom = 43;

const renderChart = (refNode, data, lower_limit, upper_limit, abnormal_history, selectedAbnormal) => {
    const func = () => {
        if (!!refNode) {
            // render chart
            let instance = echarts.getInstanceByDom(refNode);
            if (!instance) {
                instance = echarts.init(refNode);
            };
            if (!data) {
                instance.clear();
                return;
            };
            instance.resize();

            // console.log(abnormal_history);

            let bckMarkArea = [];
            // and bck
            const delta_width = (refNode.offsetWidth - left - right) / 8;

            bckMarkArea = bckMarkArea
                .concat(
                    new Array(4)
                        .fill(null)
                        .map((item, index) => {
                            const begin_x = left + delta_width * (2 * index + 1);
                            const end_x = left + delta_width * (2 * index + 2);
                            return [
                                {
                                    x: begin_x,
                                    itemStyle: {
                                        color:
                                            new echarts.graphic.LinearGradient(
                                                0, 0, 0, 1,
                                                [
                                                    { offset: 0, color: 'rgba(32,73,144,0)' },
                                                    { offset: 1, color: 'rgba(32,73,144,0.05)' }
                                                ]
                                            ),
                                    },
                                },
                                {
                                    x: end_x,
                                },
                            ];
                        })
                );

            if (data.length > 1) {
                const min = data[0][0];
                const max = data[data.length - 1][0];
                const outerWidth = refNode.offsetWidth;
                const innerWidth = outerWidth - left - right;
                bckMarkArea = bckMarkArea
                    .concat(
                        (abnormal_history ?? [])
                            .map((item) => {
                                const { time_begin_unix, time_end_unix, id } = item;
                                let begin_unix = min;
                                let end_unix = max;
                                if (typeof time_begin_unix === 'number') {
                                    if (time_begin_unix > min) begin_unix = time_begin_unix;
                                    if (typeof time_end_unix === 'number') {
                                        if (time_end_unix < max) end_unix = time_end_unix;
                                    };
                                    if (begin_unix < end_unix) {
                                        const begin_x = (begin_unix - min) / (max - min) * innerWidth + left;
                                        const end_x = (end_unix - min) / (max - min) * innerWidth + left;
                                        return [
                                            {
                                                x: begin_x,
                                                itemStyle: {
                                                    color: `rgba(251,96,96,${(id === selectedAbnormal?.id) ? 0.7 : 0.2})`,
                                                },
                                            },
                                            {
                                                x: end_x,
                                            },
                                        ];
                                    };
                                };
                                return null;
                            })
                            .filter((item) => !!item),
                    );
            };

            const yData = data;

            const allValues = yData
                .map(([, value]) => value)
                .concat([upper_limit, lower_limit])
                .filter((v) => typeof v === 'number');
            let y_min = 0;
            let y_max = 1;
            if (allValues.length > 0) {
                const min = Math.min(...allValues);
                const max = Math.max(...allValues);
                let delta = max - min;
                if (delta === 0) {
                    delta += 1;
                };
                // 使delta>0
                const multi = Math.pow(10, 2);
                y_min = Math.floor((min - delta / 10) * multi) / multi;
                y_max = Math.ceil((max + delta / 10) * multi) / multi;
                if (min >= 0 && y_min < 0) {
                    y_min = 0;
                };
                if (max <= 0 && y_max > 0) {
                    y_max = 0;
                };
            };

            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        const [{ axisValueLabel, data: [, value], marker }] = params;
                        const parsed_value = typeof value === 'number' ? significantNum(value, 4) : "--";
                        return `${axisValueLabel}<br />${marker}${parsed_value}`;
                    },
                },
                grid: {
                    left,
                    top,
                    right,
                    bottom,
                },
                xAxis: {
                    type: 'time',
                    axisLine: {
                        lineStyle: {
                            color: '#EBEBEB',
                        },
                    },
                    axisTick: {
                        lineStyle: {
                            color: '#E3E7EE',
                        },
                    },
                    axisLabel: {
                        color: '#666666',
                        formatter: '{HH}:{mm}\n{MM}/{dd}',
                        showMinLabel: true,
                        showMaxLabel: true,
                    },
                },
                yAxis: {
                    type: 'value',
                    // scale: true,// true时不一定显示0
                    min: y_min,
                    max: y_max,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#EFEFEF',
                        },
                    },
                    axisLabel: {
                        color: '#898989',
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#EBEBEB',
                        },
                    },
                },
                series: [
                    {
                        data: yData,
                        type: 'line',
                        itemStyle: {
                            color: '#355B99',
                        },
                        symbol: 'none',
                        lineStyle: {
                            width: 2,
                        },
                        markLine: {
                            silent: true,
                            symbol: "none",
                            precision: 4,
                            lineStyle: {
                                type: "dashed",
                                color: "#FB6060",
                            },
                            label: {
                                show: false,
                            },
                            data: []
                                .concat(
                                    typeof upper_limit === 'number' ? [
                                        {
                                            yAxis: upper_limit,
                                        },
                                    ] : [],
                                    typeof lower_limit === 'number' ? [
                                        {
                                            yAxis: lower_limit,
                                        },
                                    ] : [],
                                ),
                        },
                        markArea: {
                            data: []
                                .concat(
                                    bckMarkArea,
                                ),
                        },
                    },
                ],
            };

            instance.setOption(option);
        };
    };
    func();
    return func;
};

const default_func = () => { };

const Comp = (props) => {
    const { selectedFactor } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(default_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : default_func;
    }, [setData]);
    useEffect(() => {
        const timeInfo = selectedFactor?.timeInfo ?? null;
        setDataFunc.current(Object.assign({}, timeInfo ?? {}, {
            base_id: selectedFactor?.id ?? null,
        }));
    }, [selectedFactor]);

    const renderFunc = useRef(null);

    const refNode = useRef(null);
    useEffect(() => {
        const parsed_data = data?.parsed_data_history;
        const parsed_abnormals = data?.parsed_abnormals;
        const upper_limit = selectedFactor?.upper_limit ?? null;
        const lower_limit = selectedFactor?.lower_limit ?? null;
        if (!!refNode.current) {
            renderFunc.current = renderChart(refNode.current, parsed_data, lower_limit, upper_limit, parsed_abnormals);
        };
    }, [data, selectedFactor]);

    const onResize = (conf) => {
        if (typeof renderFunc.current === 'function') {
            renderFunc.current();
        };
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="指标趋势"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <div className="top">
                        <div className="items_wrapper">
                            <div className="item">
                                <div className="title">指标名称：</div>
                                <div className="value">{selectedFactor?.name ?? ""}</div>
                            </div>
                            <div className="item">
                                <div className="title">指标均值：</div>
                                <div className="value">{selectedFactor?.parsed_avg_value ?? ""}{selectedFactor?.unit ?? ""}</div>
                            </div>
                            <div className="item">
                                <div className="title">指标标准：</div>
                                <div className="value">{selectedFactor?.parsed_standard ?? ""}{selectedFactor?.unit ?? ""}</div>
                            </div>
                            <div className="item">
                                <div className="title">合格率：</div>
                                <div className="value">{selectedFactor?.parsed_cpl_rate ?? ""}</div>
                            </div>
                        </div>
                    </div>
                    <div className="mid">
                        <div className="resize">
                            <DomResize onResize={onResize} debounce_wait={20} />
                        </div>
                        <div className="chart" ref={refNode}></div>
                    </div>
                    <div className="bottom" style={{ paddingLeft: left, paddingRight: right }}>
                        <div className="item">
                            <div className="title">早班</div>
                            <div className="content">
                                <div className="item">
                                    <div className="title">指标均值：</div>
                                    <div className="value">{selectedFactor?.parsed_avg_0 ?? ""}{selectedFactor?.unit ?? ""}</div>
                                </div>
                                <div className="item">
                                    <div className="title">合格率：</div>
                                    <div className="value">{selectedFactor?.parsed_cpl_0 ?? ""}</div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">中班</div>
                            <div className="content">
                                <div className="item">
                                    <div className="title">指标均值：</div>
                                    <div className="value">{selectedFactor?.parsed_avg_1 ?? ""}{selectedFactor?.unit ?? ""}</div>
                                </div>
                                <div className="item">
                                    <div className="title">合格率：</div>
                                    <div className="value">{selectedFactor?.parsed_cpl_1 ?? ""}</div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">晚班</div>
                            <div className="content">
                                <div className="item">
                                    <div className="title">指标均值：</div>
                                    <div className="value">{selectedFactor?.parsed_avg_2 ?? ""}{selectedFactor?.unit ?? ""}</div>
                                </div>
                                <div className="item">
                                    <div className="title">合格率：</div>
                                    <div className="value">{selectedFactor?.parsed_cpl_2 ?? ""}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
