import React, { useEffect, useRef, useCallback } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts~5'
import DomResize from 'components/DomResize/index.js'

const clearChart = d => {
    const instance = echarts.getInstanceByDom(d)
    if (instance) {
        instance.clear()
    }
}

const Comp = (props) => {
    const { info } = props
    const refNode = useRef(null)
    useEffect(() => {
        if (refNode.current) {
            if (info) {
                // console.log(info)
                const { dBottom, dTop, times } = info
                if (dBottom && dTop && times && dBottom.length === dTop.length && dBottom.length === times.length && times.length > 0) {
                    let instance = echarts.getInstanceByDom(refNode.current)
                    if (!instance) {
                        instance = echarts.init(refNode.current)
                    }
                    instance.setOption({
                        grid: {
                            containLabel: true,
                            left: 20,
                            top: 24,
                            right: 36,
                            bottom: 12,
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            type: 'category',
                            data: times,
                            // boundaryGap: false,
                            axisLine: {
                                lineStyle: {
                                    color: 'rgba(0,0,0,0.5)'
                                }
                            },
                            axisLabel: {
                                formatter: v => (v ?? '').split(' ').filter(s => s !== '').join('\n')
                            },
                        },
                        yAxis: {
                            type: 'value',
                            name: '分钟',
                            nameLocation: 'middle',
                            nameTextStyle: {
                                fontWeight: 'bold'
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: true
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: 'rgba(0,0,0,0.5)'
                                }
                            },
                        },
                        series: [
                            {
                                data: dBottom,
                                type: 'bar',
                                stack: '1',
                                itemStyle: {
                                    color: 'yellow',
                                }
                            },
                            {
                                data: dTop,
                                type: 'bar',
                                stack: '1',
                                itemStyle: {
                                    color: 'red',
                                }
                            },
                        ],
                    })
                } else {
                    clearChart(refNode.current)
                }
            } else {
                clearChart(refNode.current)
            }
        }
    }, [info])
    const handleResize = useCallback(() => {
        const refN = refNode.current
        if (refN) {
            const ins = echarts.getInstanceByDom(refN)
            if (ins) {
                ins.resize()
            }
        }
    }, [])
    return (
        <div className={style['wrapper']}>
            <div style={{ height: 0, opacity: 0 }}>
                <DomResize onResize={handleResize} />
            </div>
            <div style={{ height: '100%' }} ref={refNode}></div>
        </div>
    );
};

export default Comp;
