import React from 'react';
import echarts from 'echarts';
import CHART_TITLE_ICON from './res/icon_3.png';

import './ProduceItem.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        if (this.leftRefNode) {
            // console.log('leftRefNode');
            const refNode = this.leftRefNode;
            let instance = echarts.getInstanceByDom(refNode);
            if (!instance) {
                instance = echarts.init(refNode);
            };
            instance.setOption({
                grid: {
                    left: 50,
                    top: 20,
                    right: 0,
                    bottom: 30,
                },
                xAxis: {
                    type: 'category',
                    data: [20, 21, 22, 23, 24, 25, 26],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(58, 63, 70, 1)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(159, 160, 186, 1)',
                        fontSize: 11,
                    },
                },
                yAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(159, 160, 186, 1)',
                        fontSize: 11,
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['rgba(58, 63, 70, 1)'],
                        },
                    },
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: [3, 4, 5, 6, 7, 8, 3],
                    type: 'bar',
                    barWidth: 8,
                    itemStyle: {
                        color: 'rgba(74, 155, 135, 1)',
                    },
                }],
            });
        };
        if (this.rightRefNode) {
            // console.log('rightRefNode');
            const refNode = this.rightRefNode;
            let instance = echarts.getInstanceByDom(refNode);
            if (!instance) {
                instance = echarts.init(refNode);
            };
            instance.setOption({
                grid: {
                    left: 50,
                    top: 20,
                    right: 0,
                    bottom: 30,
                },
                xAxis: {
                    type: 'category',
                    data: [20, 21, 22, 23, 24, 25, 26, 20, 21, 22, 23, 24, 25, 26, 20, 21, 22, 23, 24, 25, 26, 20, 21, 22, 23, 24, 25, 26, 20, 21, 22, 23, 24, 25, 26],
                    show: false,
                },
                yAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(159, 160, 186, 1)',
                        fontSize: 11,
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['rgba(58, 63, 70, 1)'],
                        },
                    },
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: [3, 7, 8, 3, 3, 8, 3, 7, 8, 3, 3, 4, 3, 4, 4, 7, 6, 5, 6, 4, 5, 6, 5, 6, 7, 5, 6, 7, 4, 5, 3, 8, 8, 3, 3],
                    type: 'bar',
                    barWidth: 8,
                    itemStyle: {
                        color: 'rgba(74, 155, 135, 1)',
                        barBorderRadius: 4,
                    },
                }],
            });
        };
    }

    render() {
        const { item } = this.props;
        const { icon, name, options } = item;

        return (
            <div className="produce_trend_content_item_wrapper">
                <div className="item_header_wrapper">
                    <img alt="" src={icon} className="item_header_icon" />
                    <div className="title">{name}</div>
                    <div className="select_wrapper">
                        {
                            options
                                .map((item2, index2) => {
                                    const { name: name2 } = item2;
                                    return (
                                        <div className="single_select_wrapper" key={index2}>
                                            <div className="check_block"></div>
                                            <div className="check_name">{name2}</div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
                <div className="item_content_wrapper">
                    <div className="left_chart_wrapper">
                        <div className="chart_header">
                            <img alt="" src={CHART_TITLE_ICON} className="icon" />
                            <div className="chart_title">每日趋势</div>
                            <div className="subtitle">【 2020年12月20日-2020年12月26日 】</div>
                        </div>
                        <div className="chart_content">
                            <div className="chart_dom" ref={refNode => this.leftRefNode = refNode} />
                        </div>
                    </div>
                    <div className="right_chart_wrapper">
                        <div className="chart_header">
                            <img alt="" src={CHART_TITLE_ICON} className="icon" />
                            <div className="chart_title">小时趋势</div>
                        </div>
                        <div className="chart_content">
                            <div className="chart_dom" ref={refNode => this.rightRefNode = refNode} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
