import Page from './AdviceBlockModal.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    // const { factorSummaryV2 } = state;
    // const { } = factorSummaryV2;
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;