import React from 'react';
import SepTimeBoard from './SepTimeBoard/SepTimeBoard';
import SimpleCardsGroup from './SimpleCardsGroup';
import style from './index.module.scss';
import Report from '../Report';

const Comp = (props) => {
    return (
        <div className={style['wrapper']}>
            <div className="card_group_row">
                <div className="left">
                    <SimpleCardsGroup />
                </div>
                <div className="right">
                    <SepTimeBoard />
                </div>
            </div>
            <div className="bottom">
                <Report />
            </div>
        </div>
    );
};

export default Comp;
