import React from 'react';
import { message } from 'antd';
import { get, post } from '../../common/utils.js';
import style from './DiagramGen.module.scss';
import LeftSettingPart from './LeftSettingPart';
import PicEditPart from './PicEditPart';
import MidWrapperPart from './MidWrapperPart';
import RightWrapperPart from './RightWrapperPart';

export default class DiagramGen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            nowSelected: null,
            groupSelected: [],
            dataPoint: [],
            bckPic: null,
            bckSize: null,
            diagramConf: null,
            showAll: true,
            boardBckSize: null,
        };
    }
    componentDidMount() {
        this.setInitialDataPoint();
        this.setInitialState();
    }
    setInitialState() {
        let self = this, keyList = ['listData', 'bckPic', 'bckSize'];
        get(process.env.REACT_APP_SERVER_PATH + 'api/diagrams/' + this.props.match.params.id).then(retData => {
            // console.log(retData);
            if (retData && retData.data) {
                self.setState({ diagramConf: retData.data });
                try {
                    if (retData.data.content !== null) {
                        let nState = JSON.parse(retData.data.content);
                        if (typeof nState === 'object') {
                            // console.log(nState);
                            keyList.forEach(key => {
                                if (nState[key]) self.state[key] = nState[key];
                            })
                            const existedDataPoint = (nState?.listData ?? []).map(({ dataPoint }) => ({ value: dataPoint, name: '[merge]' + dataPoint }));
                            const existedValues = [...new Set(existedDataPoint.map(({ value }) => value))];//去重
                            const parsedExistedDataPoint = existedValues.map((value) => existedDataPoint.filter(({ value: v }) => v === value)[0]);
                            const nextDataPoint = this.mergeDataPoints(this.state.dataPoint, parsedExistedDataPoint);
                            this.state.dataPoint = nextDataPoint;
                            // this.setState({ dataPoint: nextDataPoint });
                            this.setState({ boardBckSize: nState.bckSize });
                        }
                    }
                } catch (e) {
                    alert("输入数据有误，请重新输入");
                }
                self.setState(self.state);
            }
        })
    }
    mergeDataPoints = (main, sub) => {
        const mainValues = main.map(({ value }) => value);
        const mergedDataPoint = []
            .concat(
                main,
                sub
                    .filter(({ value }) => !mainValues.includes(value)),
            );
        return mergedDataPoint;
    }
    setInitialDataPoint() {
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            // status: 1,
            page: 1,
            page_size: 5000,
            // keyword: '煤',
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                const reqDataPoint = retData.data.results.map(({ name, tag_alias }) => ({ name, value: tag_alias }));
                const nextDataPoint = this.mergeDataPoints(reqDataPoint, this.state.dataPoint);
                this.setState({ dataPoint: nextDataPoint });
            }
        })
    }
    componentSetState = (obj, cb) => {
        //定时保存
        localStorage.setItem("diagramGenLatestData", JSON.stringify(this.state));
        this.setState(obj, typeof cb === 'function' ? cb : () => { });
    }
    onFilesChange = refName => {
        let self = this;
        const file = document.getElementById(refName).files[0];

        var formData = new FormData();
        formData.append('file', file);
        formData.append('diagram_id', this.props.match.params.id);

        post(process.env.REACT_APP_SERVER_PATH + 'api/img/upload', formData)
            .then((retData) => {
                console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    message.success("图片处理完成");
                    const fileName = retData.data.img_name;
                    if (refName === "bckId") {
                        self.setState({ bckPic: fileName, bckSize: null });
                    } else {
                        let listData = self.state.listData, detailIndex = listData[self.state.nowSelected].detailItemNowSelected;
                        listData[self.state.nowSelected].details[detailIndex].img = fileName;
                        self.setState({ listData });
                    }
                } else {
                    message.error("图片处理失败");
                };
            });
    }
    render() {
        let self = this,
            propsConf = {
                data: self.state,
                componentSetState: self.componentSetState
            };
        return (
            <div className={style["DiagramGenWrapper"]}>
                <div className="left">
                    <LeftSettingPart {...propsConf} onFilesChange={self.onFilesChange} />
                    <div className="bottom">
                        <PicEditPart {...propsConf} />
                    </div>
                </div>
                <div className="mid">
                    <MidWrapperPart {...propsConf} />
                </div>
                <div className="right">
                    <RightWrapperPart {...propsConf} onFilesChange={self.onFilesChange} />
                </div>
            </div>
        );
    }
}
