import React from 'react';
import { withRouter } from 'react-router';
import { Input, Modal, message, InputNumber } from 'antd';
import { get, post } from 'common/utils.js';
import { clone } from 'ramda'
import style from './DetailModal.module.scss';
import ScoreInterval from './ScoreInterval.js';
import ScoreIntervalLimit from './ScoreIntervalLimit.js';
import DetailRankBlock from './DetailRankBlock.js';
import DetailHeaderBlock from './DetailHeaderBlock.js';
import RuleFactorImg from '../../Common/RuleFactorImg/RuleFactorImg.js';
import CementAnalysisBlock from './CementAnalysisBlock.js';
import { SCORE_METHODS, RANK_METHODS } from '../config.js';
import {
    ReloadOutlined,
} from '@ant-design/icons';

const { TextArea } = Input;

const MAX_INTERVAL_NUMBER = 6;
const MIN_INTERVAL_NUMBER = 4;
class DetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mode: 'edit',
            factor: clone(props.factor || {}),
            displayFactor: null,
            systemSelected: null,
            scoreMethodSelected: '2',
            dataMeta: null,
            dataMetaList: [],
            selectedLimitType: '1',
            //
            rankMethodSelected: '1',
            remark: '',
            rankList: [],
            scoreDetail: {},
            //
            selectedCat: null,
            detailsList: [],
            rcmdVisible: false,
        }
    }
    //rankList, rankMethod, remark, scoreDetail

    componentDidMount() {
        const { systems = [], factor = {} } = this.props;
        let { systemSelected, selectedLimitType, dataMeta, scoreMethodSelected, rankMethodSelected, rankList, remark, scoreDetail, selectedCat, detailsList } = this.state;
        if (systems.length > 0) systemSelected = systems[0].id + '';
        const { rank_method, sys_id, data_meta_id, rank_list = [], score_method, score_details = {}, remark: prop_remark = '', details_list } = factor;
        if (sys_id) {
            //存在sys_id, 表示编辑，并非创建
            if (systems.map(({ id }) => id).indexOf(sys_id) > -1) systemSelected = sys_id + '';
            // 判断是不是存在exam_cats
            const examCats = (systems
                .filter(({ id }) => id == systemSelected)[0] || {})
                .exam_cats;
            if (examCats !== undefined) {
                //若存在，赋初值为数组第一个
                selectedCat = 0;
                detailsList = details_list;
                const selectedDetail = details_list
                    .filter(({ cat_name }) => cat_name === examCats[selectedCat].cat_name)[0];
                if (selectedDetail !== undefined) {
                    scoreDetail = selectedDetail.score_details;
                    if (score_method === 4) {
                        if (selectedDetail.score_details.dev_intervals[0][0] === null) selectedLimitType = '2';
                    }
                };
            } else {
                //若不存在，常规取数据
                scoreDetail = score_details;
                if (score_method === 4) {
                    if (score_details.dev_intervals[0][0] === null) selectedLimitType = '2';
                }
            }
            if (RANK_METHODS.map(({ id }) => id).indexOf(rank_method) > -1) rankMethodSelected = rank_method + '';
            rankList = rank_list;
            remark = prop_remark;
            if (SCORE_METHODS.map(({ id }) => id).indexOf(score_method) > -1) {
                scoreMethodSelected = score_method + '';
            };
            dataMeta = (data_meta_id + '') || null;
        } else {
            const examCats = (systems
                .filter(({ id }) => id == systemSelected)[0] || {})
                .exam_cats;
            if (examCats !== undefined) {
                selectedCat = 0;
            }
        }
        this.setState({
            systemSelected,
            scoreMethodSelected,
            selectedLimitType,
            dataMeta,
            remark,
            rankMethodSelected,
            rankList,
            scoreDetail,
            selectedCat,
            detailsList,
        }, () => {
            this.getDataMetas();
            if (!!factor.sys_id) {
                this.setState({ displayFactor: this.getDisplayFactor(clone(factor)) });
            };
        });
    }

    getDataMetas = (keyword) => {
        const { systemSelected: sys_id } = this.state;
        if (!sys_id) return;
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/data_metas', {
            ...!keyword ? {} : { keyword },
            status: 1,
            page: 1,
            page_size: 10,
            sys_id,
            module_name: 'EXAM_RULE_FACTOR'
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                const { factor: { data_meta_id, meta_name, tag_name } } = this.state;
                const dataMetaList = retData.data.results;
                if (!keyword && data_meta_id && dataMetaList.filter(({ id }) => id == data_meta_id).length === 0) {
                    dataMetaList.unshift({
                        id: data_meta_id,
                        name: meta_name,
                        tag_name,
                    })
                }
                this.setState({ dataMetaList })
            }
        });
    }
    modalOnOk = () => {
        if (this.state.mode == 'read') {
            // this.setState({ mode: 'edit', factors: clone(this.props.factors) });
        } else {
            this.saveData();
        }
    }

    asyncData = (resetScoreDetail = false) => {
        const { systems = [] } = this.props;
        const { rankList: rank_list, rankMethodSelected: rank_method, remark, scoreDetail, selectedCat, systemSelected, detailsList } = this.state;
        const examCats = (systems
            .filter(({ id }) => id == systemSelected)[0] || {})
            .exam_cats;
        if (examCats !== undefined) {
            if (resetScoreDetail) {
                detailsList
                    .forEach(detail => {
                        Object.assign(detail, {
                            scoreDetail: {},
                        })
                    })
                return;
            }
            let selectedDetail = detailsList
                .filter(({ cat_name }) => cat_name === examCats[selectedCat].cat_name)[0];
            if (selectedDetail === undefined) {
                selectedDetail = { cat_name: examCats[selectedCat].cat_name };
                detailsList.push(selectedDetail);
            };
            Object.assign(selectedDetail, {
                // rank_list,
                // rank_method,
                remark,
                score_details: scoreDetail,
            })
        }
    }

    scoreStdValueOnChange = v => {
        const { scoreDetail } = this.state;
        this.setState({
            scoreDetail: {
                ...scoreDetail,
                std_value: v,
            }
        }, this.asyncData);
    }

    intervalValueOnChange = (v, rowIndex, index) => {
        const { scoreMethodSelected, selectedLimitType, scoreDetail } = this.state;
        const { dev_intervals = [] } = scoreDetail;
        dev_intervals[rowIndex] = dev_intervals[rowIndex] || [0, 0, 0];
        dev_intervals[rowIndex][index] = v;
        if (scoreMethodSelected == 4 && selectedLimitType == 1) {
            if (rowIndex === 0) dev_intervals[0][1] = null;
            if (rowIndex === 5) dev_intervals[5][0] = null;
        } else {
            if (rowIndex === 0) dev_intervals[0][0] = null;
            if (rowIndex === 5) dev_intervals[5][1] = null;
        }
        if ([0, 5].indexOf(rowIndex) === -1 && index !== 2) {
            if (dev_intervals[rowIndex][1 - index] === undefined) dev_intervals[rowIndex][1 - index] = 0;
        }
        this.setState({
            scoreDetail: {
                ...scoreDetail,
                dev_intervals,
            }
        }, this.asyncData);
    }

    deleteIntervalRow = rowIndex => {
        const { scoreDetail } = this.state;
        const { dev_intervals = [] } = scoreDetail;
        dev_intervals
            .splice(rowIndex, 1);
        dev_intervals
            .splice(MAX_INTERVAL_NUMBER - 2, 0, [0, 0, 0]);
        this.setState({
            scoreDetail: {
                ...scoreDetail,
                dev_intervals,
            }
        }, this.asyncData);
    }

    selectedLimitTypeOnChange = selectedLimitType => {
        const { scoreDetail } = this.state;
        this.setState({
            selectedLimitType,
            scoreDetail: {
                ...scoreDetail,
                dev_intervals: [],
            }
        }, this.asyncData);
    }

    scoreMethodSelectedOnChange = scoreMethodSelected => {
        this.setState({ scoreDetail: {}, scoreMethodSelected }, () => this.asyncData(true));
    }

    remarkOnChange = remark => {
        this.setState({ remark }, this.asyncData);
    }

    examCatOnchange = selectedCat => {
        const { systems } = this.props;
        const { detailsList, systemSelected } = this.state;
        const examCats = systems
            .filter(({ id }) => systemSelected == id)[0].exam_cats;
        let nextDetail = detailsList
            .filter(({ cat_name }) => cat_name === examCats[selectedCat].cat_name)[0];
        if (nextDetail === undefined) {
            nextDetail = {
                rank_method: '1',
                remark: '',
                rank_list: [],
                score_details: {},
            };
        };
        const { rank_method: rankMethodSelected, remark, rank_list: rankList, score_details: scoreDetail } = nextDetail;
        this.setState({
            selectedCat,
            // rankMethodSelected: rankMethodSelected + '',
            remark,
            // rankList,
            scoreDetail,
        });
    }

    systemOnchange = systemSelected => {
        const { systems } = this.props;
        let { selectedCat } = this.state;
        const examCats = (systems
            .filter(({ id }) => id == systemSelected)[0] || {})
            .exam_cats;
        if (examCats !== undefined) {
            selectedCat = 0;
        }
        this.setState({
            systemSelected,
            dataMeta: null,
            selectedCat,
            remark: '',
        }, this.getDataMetas);
    }

    intervalFormat = (intervals, score_method, selectedLimitType) => {
        return Object.assign(
            Array(6)
                .fill(0)
                .map(
                    (v, index) => index === 0 ?
                        (
                            score_method == 4 && selectedLimitType == 1 ? [0, null, 0] : [null, 0, 0]
                        )
                        : index === 5 ?
                            (
                                score_method == 4 && selectedLimitType == 1 ? [null, 0, 0] : [0, null, 0]
                            )
                            : [0, 0, 0]
                ),
            intervals || []);
    }

    getNewData = () => {
        const { systems } = this.props;
        const { ruleId: rule_id } = this.props.match.params;
        let { scoreDetail: score_details, detailsList, selectedLimitType, systemSelected: sys_id, dataMeta: data_meta_id, dataMetaList, rankMethodSelected: rank_method, remark, factor = {}, scoreMethodSelected: score_method, rankList } = this.state;
        const meta_name = (dataMetaList.filter(({ id }) => id == data_meta_id)[0] || {}).name || '';
        const tag_name = (dataMetaList.filter(({ id }) => id == data_meta_id)[0] || {}).tag_name || '';
        const { _id, weight = 0 } = factor;
        data_meta_id = parseInt(data_meta_id);
        if (!data_meta_id) {
            message.error('尚未选择点位');
            return null;
        };
        sys_id = parseInt(sys_id);
        if (!sys_id) {
            message.error('尚未选择系统');
            return null;
        };
        score_method = parseInt(score_method);
        //
        const examCats = (systems
            .filter(({ id }) => sys_id == id)[0] || {})
            .exam_cats;
        const params = {
            rule_id,
            status: 1,
            sys_id,
            data_meta_id,
            meta_name,
            tag_name,
            weight,
            score_method,
            //
            rank_method: (score_method == 3 || score_method == 4) ? 1 : parseInt(rank_method),
            rank_list: Object.assign(Array(8).fill(0), rankList),
            ...(examCats === undefined ?
                {
                    remark,
                    ...((score_method == 3 || score_method == 4) ?
                        {
                            score_details: {
                                ...score_details,
                                std_type: 1,
                                dev_intervals: this.intervalFormat(score_details.dev_intervals, score_method, selectedLimitType),
                                ...(score_method == 3 ?
                                    {
                                        std_value: parseFloat(score_details.std_value || 0) || 0,
                                    } : {})
                            }
                        } : {}),
                } : {
                    details_list: detailsList
                        .concat(
                            examCats
                                .filter(({ cat_name }) => {
                                    return !(detailsList
                                        .map(({ cat_name }) => cat_name)
                                        .includes(cat_name));
                                })
                        )
                        .map(({ remark = '', score_details = {}, cat_name }) => ({
                            cat_name,
                            remark,
                            ...((score_method == 3 || score_method == 4) ?
                                {
                                    score_details: {
                                        ...score_details,
                                        std_type: 1,
                                        dev_intervals: this.intervalFormat(score_details.dev_intervals, score_method, selectedLimitType),
                                        ...(score_method == 3 ?
                                            {
                                                std_value: parseFloat(score_details.std_value || 0) || 0,
                                            } : {})
                                    }
                                } : {
                                    score_details: {
                                        std_value: parseFloat(score_details.std_value || 0) || 0,
                                    }
                                }),
                        })),
                }),
        };
        if (_id) {
            params._id = _id;
        };
        return params;
    }

    saveData = () => {
        const params = this.getNewData();
        if (!params) {
            return;
        };
        // console.log(params);
        // return;
        post(process.env.REACT_APP_CEMENT_SERVER_PATH + `api/exam/rules/factors/${params._id ? 'update' : 'create'}`, params).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                // window.location.reload();
                const { onClose = () => { } } = this.props;
                onClose(true);
            } else if (retData.data && retData.data.errcode === 500 && retData.data && retData.data.msg) {
                message.error({ content: retData.data.msg, duration: 4 });
            } else {
                message.error('提交失败');
            }
        });
    }

    renderFactor = () => {
        const params = this.getNewData();
        if (!params) {
            return;
        };
        // console.log(params);
        this.setState({
            displayFactor: this.getDisplayFactor(clone(params)),
        });
        // console.log('renderFactor');
    }

    getDisplayFactor = factor => {
        const { systems } = this.props;
        const { systemSelected, selectedCat } = this.state;
        if (!factor.score_details && !!factor.details_list) {
            const examCats = systems
                .filter(({ id }) => systemSelected == id)[0].exam_cats;
            const selectedDetail = factor.details_list
                .filter(({ cat_name }) => cat_name === examCats[selectedCat].cat_name)[0];
            factor.score_details = selectedDetail.score_details;
        };
        return factor;
    }

    rcmdOnClick = () => {
        // console.log('rcmdOnClick');
        const { rcmdVisible } = this.state;
        this.setState({ rcmdVisible: !rcmdVisible });
    }

    render() {
        const { systems = [], onClose = () => { }, editable = true } = this.props;
        const { rcmdVisible, factor: { _id } } = this.state;
        const { displayFactor, selectedCat, systemSelected, scoreMethodSelected, dataMeta, dataMetaList, selectedLimitType, rankMethodSelected, remark, rankList, scoreDetail: score_details } = this.state;
        const selectedSystem = systems
            .filter(({ id }) => systemSelected == id)[0] || null;
        const isCreate = !_id;
        return (
            <Modal className='EventModal'
                visible={true}
                width={1000}
                title='编辑考核指标'
                confirmLoading={this.state.loading}
                okText='保存'
                onOk={this.modalOnOk}
                {
                ...editable === false ?
                    {
                        okButtonProps: {
                            disabled: true,
                        },
                    } : {}
                }
                onCancel={onClose}
                maskClosable={false}
                okButtonProps={{
                    style: {
                        backgroundColor: '#357CF7',
                        borderColor: '#357CF7',
                    },
                }}
            >
                <div className={style["factor_detail_modal_wrapper"]}>
                    <DetailHeaderBlock
                        systems={systems}
                        systemSelected={systemSelected}
                        systemOnChange={this.systemOnchange}
                        dataMetaList={dataMetaList}
                        dataMeta={dataMeta}
                        dataMetaOnChange={dataMeta => this.setState({ dataMeta })}
                        dataMetaOnSearch={this.getDataMetas}
                        scoreMethodSelected={scoreMethodSelected}
                        scoreMethodOnChange={this.scoreMethodSelectedOnChange}
                    />
                    <div className="divide_line" />
                    <div className="factor_content_wrapper">
                        <div
                            className="left"
                        >
                            <DetailRankBlock
                                showRankMethod={scoreMethodSelected == '2'}
                                rankMethodSelected={rankMethodSelected}
                                rankMethodOnChange={v => this.setState({ rankMethodSelected: v }, this.asyncData)}
                                rankList={rankList}
                                rankListOnChange={rankList => this.setState({ rankList }, this.asyncData)}
                            />
                        </div>
                        <div
                            className="right"
                            style={
                                selectedSystem && selectedSystem.exam_cats !== undefined ?
                                    {
                                        borderLeft: '1px solid grey'
                                    } : {}
                            }
                        >
                            {
                                selectedSystem && selectedSystem.exam_cats !== undefined && (
                                    <div className="cat_wrapper">
                                        {
                                            selectedSystem.exam_cats.map(({ name }, index) => (
                                                <div
                                                    className={"cat_item" + (selectedCat === index ? ' cat_item_selected' : '')}
                                                    key={index}
                                                    onClick={() => this.examCatOnchange(index)}
                                                >{name}</div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {
                                scoreMethodSelected == 4 ? (//限值
                                    <ScoreIntervalLimit
                                        selectedLimitType={selectedLimitType}
                                        selectedLimitTypeOnChange={this.selectedLimitTypeOnChange}
                                        score_details={score_details}
                                        intervalValueOnChange={this.intervalValueOnChange}
                                        deleteIntervalRow={this.deleteIntervalRow}
                                        remark={remark}
                                        remarkOnChange={this.remarkOnChange}
                                        isCreate={isCreate}
                                    />
                                ) : scoreMethodSelected == 3 ? (//区间
                                    <ScoreInterval
                                        score_details={score_details}
                                        intervalValueOnChange={this.intervalValueOnChange}
                                        scoreStdValueOnChange={this.scoreStdValueOnChange}
                                        deleteIntervalRow={this.deleteIntervalRow}
                                        remark={remark}
                                        remarkOnChange={this.remarkOnChange}
                                        isCreate={isCreate}
                                    />
                                ) : (scoreMethodSelected == 2 || scoreMethodSelected == 1) ? (//累积或平均
                                    <div className="score2_wrapper">
                                        {
                                            selectedSystem && selectedSystem.exam_cats !== undefined && (
                                                <div className="row_wrapper">
                                                    <div className="title">指标标准值：</div>
                                                    <InputNumber value={score_details.std_value || 0} onChange={this.scoreStdValueOnChange} style={{ width: 80 }} />
                                                </div>
                                            )
                                        }
                                        <div className="row_wrapper">
                                            <div style={{ flexShrink: 0 }}>备注：</div>
                                            <TextArea rows={4} value={remark} onChange={e => this.remarkOnChange(e.target.value)} />
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                        {
                            selectedSystem && selectedSystem.exam_cats !== undefined && (
                                <div className="rcmd_right">
                                    <div
                                        className="hint_text"
                                        onClick={this.rcmdOnClick}
                                    >{rcmdVisible ? '收起<<' : '查看推荐>>'}</div>
                                    {
                                        rcmdVisible && (
                                            <div className="analysis_wrapper">
                                                <CementAnalysisBlock examCats={selectedSystem.exam_cats} selectedCat={selectedCat} dataMeta={dataMeta} dataMetaList={dataMetaList} />
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className="rule_factor_img_display_wrapper">
                        <div className="top">
                            <div
                                className="reload_wrapper"
                                onClick={this.renderFactor}
                            >
                                <ReloadOutlined style={{ color: 'blue' }} />
                                <div className="reload_text">刷新示意图</div>
                            </div>
                        </div>
                        {
                            displayFactor && (
                                <div className="rule_colored_bar_wrapper">
                                    <RuleFactorImg factor={displayFactor} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(DetailModal);