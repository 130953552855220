import React from 'react';
import { Modal } from 'antd';
import { post } from 'common/utils.js';
import moment from 'moment';

import ScatterChart from './ScatterChart.js';
import TrendChart from './TrendChart.js';
import style from './AnalysisModal.module.scss';

export default class AnalysisModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            visible: true,
            scatterDataList: [],
            lineDataList: [],
            kpi_result: props.optResult.result.kpi_result,
            renderCounter: 0
        }
        // console.log("props.record", props.record);
        // console.log("props.optResult", props.optResult);
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        let self = this;
        // 散点图功能暂时停用
        // post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/optimization/get_analysis_data', {
        //     "opt_task_id": this.props.optResult._id,
        //     "scatter_plotxy": this.props.record.scatter_plotxy
        // }).then(retData => {
        //     self.setState({loading: false});
        //     if(retData.data && retData.data.errcode === 0){
        //         self.setState({scatterDataList: retData.data.results});
        //     }else{
        //         message.error('数据查询错误');
        //     }
        // })

        let curr_time_end = moment();
        // console.log('curr_time_end', curr_time_end);
        let curr_time_begin = moment(curr_time_end).subtract(self.state.kpi_result.stat_period, 'minutes').add(10, 'seconds');

        post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/get_history_data_sep_time', {
            time_type: 'seconds',
            tag_list: [
                {
                    tag_name: self.props.record.cur_value_tag,
                    time_begin: curr_time_begin.format('YYYY-MM-DD HH:mm:ss'),
                    time_end: curr_time_end.format('YYYY-MM-DD HH:mm:ss'),
                    time_type: 'seconds',
                },
                ...self.state.kpi_result.top_state_datetime.map((timeRange, index) => ({
                    tag_name: self.props.record.cur_value_tag,
                    time_begin: moment(timeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
                    time_end: moment(timeRange[1]).format('YYYY-MM-DD HH:mm:ss'),
                    time_type: 'seconds',
                }))]
        }).then(retData => {
            if (retData.data && retData.data.errcode === 0) {
                // console.log('lineDataList: ', retData.data.results);
                self.setState({
                    curr_time_begin: curr_time_begin,
                    lineDataList: retData.data.results,
                    renderCounter: ++self.state.renderCounter
                });
            }
        });
    }

    render() {
        let self = this;

        // console.log('lineDataList.length: '+this.state.lineDataList.length)

        return (
            <Modal
                className={style["AlarmMetaModal"]}
                title="运行参数对标分析"
                centered
                style={{}}
                width={1200}
                visible={this.state.visible}
                // onOk={()=>this.saveData()}
                footer={null}
                onCancel={() => this.setState({ visible: false }, () => this.props.onClose(false))}
            >
                <TrendChart
                    zone_cut_point={self.props.record.zone_cut_point}
                    curr_time_begin={self.state.curr_time_begin}
                    style={{ marginTop: 25 }}
                    counter={this.state.renderCounter}
                    record={this.props.record}
                    optResult={this.props.optResult}
                    dataList={this.state.lineDataList}
                />
                {
                    // <Tabs defaultActiveKey="1">
                    //     <Tabs.TabPane tab="参数分布" key="1">
                    //         <ScatterChart
                    //             record={this.props.record}
                    //             optResult={this.props.optResult}
                    //             dataList={this.state.scatterDataList}
                    //         />
                    //     </Tabs.TabPane>
                    //     <Tabs.TabPane tab="参数趋势" key="2">
                    //         <TrendChart
                    //             counter={this.state.renderCounter}
                    //             record={this.props.record}
                    //             optResult={this.props.optResult}
                    //             dataList={this.state.lineDataList}
                    //         />
                    //     </Tabs.TabPane>
                    // </Tabs>
                }
            </Modal>
        );
    }
}
