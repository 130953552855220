import React, { Component } from 'react'
import style from './index.module.scss'
import TimeSelect from '../../components/LnOilfield/TimeSelect'
import { post } from '../../common/utils'
import {
  proTagNameList,
  surviveTagList,
  energyTagList,
  totalEfficiencyTagList,
  totalPowerTagList,
  powerConsumptionTagList,
  electricTotalTagList,
  dryGasSealTagList,
  expanderRateTagList,
  expanderCompressorRateTagList,
  recoveryRateTagList
} from './requestData/requestData'
import {
  proTagNameList_today,
  surviveTagList_today,
  energyTagList_today,
  totalEfficiencyTagList_today,
  totalPowerTagList_today,
  powerConsumptionTagList_today,
  electricTotalTagList_today,
  dryGasSealTagList_today,
  expanderRateTagList_today,
  expanderCompressorRateTagList_today,
  recoveryRateTagList_today
} from './requestData/todayRequestData'
import moment from 'moment'
import CardList from '../../components/LnOilfield/CardList'
import Abnormal from '../../components/LnOilfield/Abnormal'
import {message, Table} from 'antd'
import Tabs from '../../components/LnOilfield/DeviceSystem/Tabs'
import TabPanel from '../../components/LnOilfield/DeviceSystem/TabPanel'
import SecondTabs from '../../components/LnOilfield/DeviceSystem/SecondTabs'
import SecondTabPanel from '../../components/LnOilfield/DeviceSystem/SecondTabPanel'
import SurviveTable from "../../components/LnOilfield/Survive/SurviveTable";
import {setIn} from "immutable";
const tabList_1 = [
  { name: '总效率', id: 1 },
  { name: '总功耗', id: 2 },
  { name: '电单耗', id: 3 },
  { name: '用电量', id: 4 },
  { name: '干气密封放空', id: 5 }
]
const tabList_2 = [
  { name: '膨胀机效率', id: 1 },
  { name: '膨胀压缩机效率', id: 2 },
  { name: '回收功率', id: 3 }
]
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      todayDate: moment().format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD 00:00:00'),
      isShowDatePicker: false,
      firstTabIndex: 0,
      secondTabIndex: 0,
      // 生产情况
      dataSource: [],
      // 能耗
      naturalGas_single: '--',
      naturalGas_comparison: '--',
      naturalGas_dayTotal: '--',
      naturalGas_MonthTotal: '--',
      naturalGas_YearTotal: '--',
      naturalGas_redNum: '--',
      naturalGas_yellowNum: '--',
      naturalGas_greenNum: '--',
      naturalGas_LightGreenNum: '--',
      electric_single: '--',
      electric_comparison: '--',
      electric_dayTotal: '--',
      electric_MonthTotal: '--',
      electric_YearTotal: '--',
      electric_redNum: '--',
      electric_yellowNum: '--',
      electric_greenNum: '--',
      electric_LightGreenNum: '--',
      water_single: '--',
      water_comparison: '--',
      water_dayTotal: '--',
      water_MonthTotal: '--',
      water_YearTotal: '--',
      nitrogen_single: '--',
      nitrogen_comparison: '--',
      nitrogen_dayTotal: '--',
      nitrogen_MonthTotal: '--',
      nitrogen_YearTotal: '--',
      gasNumber: '--',
      electricNumber: '--',
      waterNumber: '--',
      nitrogenNumber: '--',
      gasTotal: 0,
      electricTotal: 0,
      // 能源信息
      energyGasNumber: '--',
      energyGasPercent: '--',
      energyEthaneNumber: '--',
      energyEthanePercent: '--',
      energyPropaneNumber: '--',
      energyPropanePercent: '--',
      energyButaneNumber: '--',
      energyButanePercent: '--',
      energyElectricNumber: '--',
      energyElectricPercent: '--',
      // 能源chart
      gasChart: [],
      ethaneChart: [],
      propaneChart: [],
      butaneChart: [],
      electricChart: [],
      gasChartTime: [],
      ethaneChartTime: [],
      propaneChartTime: [],
      butaneChartTime: [],
      electricChartTime: [],
      // 异常指标
      abnormalData: [],
      // 关键设备及系统
      materialTabName: '总效率',
      expandTabName: '膨胀机效率',
      totalEfficiencyPercent: '--',
      totalEfficiencyComparison: '--',
      totalEfficiencyFirstRate: '--',
      totalEfficiencySecondRate: '--',
      totalEfficiencyThirdRate: '--',
      totalPowerPercent: '--',
      totalPowerComparison: '--',
      totalPowerFirst: '--',
      totalPowerSecond: '--',
      totalPowerThird: '--',
      electricSingle: '--',
      electricSingleComparison: '--',
      electricSingleWeek: '--',
      electricSingleMonth: '--',
      electricSingleYear: '--',
      useElectricTotal: '--',
      useElectricComparison: '--',
      useElectricWeek: '--',
      useElectricMonth: '--',
      useElectricYear: '--',
      dryGasTotal: '--',
      dryGasComparison: '--',
      expanderTotal: '--',
      expanderComparison: '--',
      expanderCompressorRateTotal: '--',
      expanderCompressorRateComparison: '--',
      recoveryRateTotal: '--',
      recoveryRateComparison: '--',
      // 设备系统chart
      deviceTabChart: [],
      deviceTabTime: []
    }
  }
  disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  toggleShowDatePicker() {
    this.setState({
      isShowDatePicker: !this.state.isShowDatePicker
    })
  }
  changeDate(dateString) {
    this.setState(
      {
        currentDate: moment(dateString).format('YYYY-MM-DD'),
        isShowDatePicker: false
      },
      () => this.getAll()
    )
  }
  prevDate() {
    this.setState(
      {
        currentDate: moment(this.state.currentDate).subtract(1, 'day').format('YYYY-MM-DD')
      },
      () => this.getAll()
    )
  }
  nextDate() {
    let limitDate =  moment(this.state.currentDate).add(1, 'day').format('YYYY-MM-DD')
    if (moment().isBefore(moment(limitDate))) {
          message.warning("无法晚于当日");
    } else {
      this.setState(
        {
            currentDate: moment(this.state.currentDate).add(1, 'day').format('YYYY-MM-DD')
        },
        () => this.getAll()
      )
    }

  }
  // 生产情况数据
  getProductData() {
    let currentTime = this.state.currentDate
    let tag_list = []
    let isToday = true
    if (this.state.todayDate === currentTime.substring(0, 10)) {
      tag_list = proTagNameList_today
      currentTime = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
    } else {
      tag_list = proTagNameList
      currentTime = this.state.currentDate + ' ' + '00:00:00'
      isToday = false
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
      create_time: currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        if (!isToday) {
          const dataSource = [
            {
              key: '1',
              name: '天然气 ',
              nameUnit: '万Nm³',
              dayTotal: data.PLANT_CNG_PRDT_CUM_D? Number(data.PLANT_CNG_PRDT_CUM_D).toFixed(3): '--',
              comparison: data.PLANT_CNG_PRDT_CUM_HDQOQ? `${Number(data.PLANT_CNG_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_CNG_PRDT_CUM_DM? Number(data.PLANT_CNG_PRDT_CUM_DM).toFixed(2): '--',
              yearTotal: data.PLANT_CNG_PRDT_CUM_DY? Number(data.PLANT_CNG_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_CNG_PRDT_RATE_D? Number(data.PLANT_CNG_PRDT_RATE_D).toFixed(3)  + ' '+'万Nm³/h': '--',
              // dayUnit: '万Nm³/h'
            },
            {
              key: '2',
              name: '乙烷',
              nameUnit: '万Nm³',
              dayTotal: data.PLANT_C2H6_PRDT_CUM_D? Number(data.PLANT_C2H6_PRDT_CUM_D).toFixed(3): '--',
              comparison: data.PLANT_C2H6_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C2H6_PRDT_CUM_HDQOQ).toFixed(2)}%` : '--',
              monthTotal: data.PLANT_C2H6_PRDT_CUM_DM? Number(data.PLANT_C2H6_PRDT_CUM_DM).toFixed(2): '--',
              yearTotal: data.PLANT_C2H6_PRDT_CUM_DY? Number(data.PLANT_C2H6_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C2H6_PRDT_RATE_D? Number(data.PLANT_C2H6_PRDT_RATE_D).toFixed(3)  + ' '+'万Nm³/h': '--',
              // dayUnit: '万Nm³/h'
            },
            {
              key: '3',
              name: '丙烷',
              nameUnit: 't',
              dayTotal: data.PLANT_C3H8_PRDT_CUM_D? Number(data.PLANT_C3H8_PRDT_CUM_D).toFixed(3): '--',
              comparison: data.PLANT_C3H8_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C3H8_PRDT_CUM_HDQOQ).toFixed(2)}` + '%': '--',
              monthTotal: data.PLANT_C3H8_PRDT_CUM_DM? Number(data.PLANT_C3H8_PRDT_CUM_DM).toFixed(2): '--',
              yearTotal: data.PLANT_C3H8_PRDT_CUM_DY? Number(data.PLANT_C3H8_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C3H8_PRDT_RATE_D? Number(data.PLANT_C3H8_PRDT_RATE_D).toFixed(3)  + ' '+'万Nm³/h': '--',
              // dayUnit: 't/h'
            },
            {
              key: '4',
              name: '丁烷',
              nameUnit: 't',
              dayTotal: data.PLANT_C4H10_PRDT_CUM_D? Number(data.PLANT_C4H10_PRDT_CUM_D).toFixed(3): '--',
              comparison: data.PLANT_C4H10_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C4H10_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_C4H10_PRDT_CUM_DM? Number(data.PLANT_C4H10_PRDT_CUM_DM).toFixed(2): '--',
              yearTotal: data.PLANT_C4H10_PRDT_CUM_DY? Number(data.PLANT_C4H10_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C4H10_PRDT_RATE_D? Number(data.PLANT_C4H10_PRDT_RATE_D).toFixed(3)  + ' '+'万Nm³/h': '--',
              // dayUnit: 't/h'
            },
            {
              key: '5',
              name: '烯烃',
              nameUnit: 't',
              dayTotal: data.PLANT_C5P_PRDT_CUM_D? Number(data.PLANT_C5P_PRDT_CUM_D).toFixed(3): '--',
              comparison: data.PLANT_C5P_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C5P_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_C5P_PRDT_CUM_DM? Number(data.PLANT_C5P_PRDT_CUM_DM).toFixed(2): '--',
              yearTotal: data.PLANT_C5P_PRDT_CUM_DY? Number(data.PLANT_C5P_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C5P_PRDT_RATE_D? Number(data.PLANT_C5P_PRDT_RATE_D).toFixed(3)  + ' '+'万Nm³/h': '--',
              // dayUnit: 't/h'
            },
            {
              key: '6',
              name: '原料气',
              nameUnit: '万Nm³',
              dayTotal: data.PLANT_RAWG_RAW_CUM_D? Number(data.PLANT_RAWG_RAW_CUM_D).toFixed(3): '--',
              comparison: data.PLANT_RAWG_RAW_CUM_HDQOQ? `${Number(data.PLANT_RAWG_RAW_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_RAWG_RAW_CUM_DM? Number(data.PLANT_RAWG_RAW_CUM_DM).toFixed(2): '--',
              yearTotal: data.PLANT_RAWG_RAW_CUM_DY? Number(data.PLANT_RAWG_RAW_CUM_DY).toFixed(1):'--',
              dayAverage: data.PLANT_RAWG_RAW_RATE_D? Number(data.PLANT_RAWG_RAW_RATE_D).toFixed(3)  + ' '+'万Nm³/h': '--',
              // dayUnit: '万Nm³/h'
            }
          ]
          this.setState({
            dataSource: dataSource
          })
        } else {
          const dataSource = [
            {
              key: '1',
              name: '天然气',
              nameUnit: '万Nm³',
              dayTotal: data.PLANT_CNG_PRDT_CUM_HD? Number(data.PLANT_CNG_PRDT_CUM_HD).toFixed(3): '--',
              comparison: data.PLANT_CNG_PRDT_CUM_HDQOQ? `${Number(data.PLANT_CNG_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_CNG_PRDT_CUM_HM? Number(data.PLANT_CNG_PRDT_CUM_HM).toFixed(2) : '--',
              yearTotal: data.PLANT_CNG_PRDT_CUM_DY? Number(data.PLANT_CNG_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_CNG_PRDT_RATE_HD? Number(data.PLANT_CNG_PRDT_RATE_HD).toFixed(3) + ' '+'万Nm³/h': '--',
            },
            {
              key: '2',
              name: '乙烷',
              nameUnit: '万Nm³',
              dayTotal: data.PLANT_C2H6_PRDT_CUM_HD? Number(data.PLANT_C2H6_PRDT_CUM_HD).toFixed(3): '--',
              comparison: data.PLANT_C2H6_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C2H6_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_C2H6_PRDT_CUM_HM? Number(data.PLANT_C2H6_PRDT_CUM_HM).toFixed(2): '--',
              yearTotal: data.PLANT_C2H6_PRDT_CUM_DY? Number(data.PLANT_C2H6_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C2H6_PRDT_RATE_HD? Number(data.PLANT_C2H6_PRDT_RATE_HD).toFixed(3) + ' ' + '万Nm³/h': '--',
            },
            {
              key: '3',
              name: '丙烷',
              nameUnit: 't',
              dayTotal: data.PLANT_C3H8_PRDT_CUM_HD? Number(data.PLANT_C3H8_PRDT_CUM_HD).toFixed(3): '--',
              comparison: data.PLANT_C3H8_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C3H8_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_C3H8_PRDT_CUM_HM? Number(data.PLANT_C3H8_PRDT_CUM_HM).toFixed(2): '--',
              yearTotal: data.PLANT_C3H8_PRDT_CUM_DY? Number(data.PLANT_C3H8_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C3H8_PRDT_RATE_HD? Number(data.PLANT_C3H8_PRDT_RATE_HD).toFixed(3) + ' '+'万Nm³/h': '--',
            },
            {
              key: '4',
              name: '丁烷',
              nameUnit: 't',
              dayTotal: data.PLANT_C4H10_PRDT_CUM_HD? Number(data.PLANT_C4H10_PRDT_CUM_HD).toFixed(3): '--',
              comparison: data.PLANT_C4H10_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C4H10_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_C4H10_PRDT_CUM_HM? Number(data.PLANT_C4H10_PRDT_CUM_HM).toFixed(2): '--',
              yearTotal: data.PLANT_C4H10_PRDT_CUM_DY? Number(data.PLANT_C4H10_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C4H10_PRDT_RATE_HD? Number(data.PLANT_C4H10_PRDT_RATE_HD).toFixed(3) + ' '+'万Nm³/h': '--',
            },
            {
              key: '5',
              name: '烯烃',
              nameUnit: 't',
              dayTotal: data.PLANT_C5P_PRDT_CUM_HD? Number(data.PLANT_C5P_PRDT_CUM_HD).toFixed(3): '--',
              comparison: data.PLANT_C5P_PRDT_CUM_HDQOQ? `${Number(data.PLANT_C5P_PRDT_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_C5P_PRDT_CUM_HM? Number(data.PLANT_C5P_PRDT_CUM_HM).toFixed(2): '--',
              yearTotal: data.PLANT_C5P_PRDT_CUM_DY? Number(data.PLANT_C5P_PRDT_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_C5P_PRDT_RATE_HD? Number(data.PLANT_C5P_PRDT_RATE_HD).toFixed(3) + ' '+'万Nm³/h': '--',
            },
            {
              key: '6',
              name: '原料气',
              nameUnit: '万Nm³',
              dayTotal: data.PLANT_RAWG_RAW_CUM_HD? Number(data.PLANT_RAWG_RAW_CUM_HD).toFixed(3): '--',
              comparison: data.PLANT_RAWG_RAW_CUM_HDQOQ? `${Number(data.PLANT_RAWG_RAW_CUM_HDQOQ).toFixed(2)}%`: '--',
              monthTotal: data.PLANT_RAWG_RAW_CUM_HM? Number(data.PLANT_RAWG_RAW_CUM_HM).toFixed(2): '--',
              yearTotal: data.PLANT_RAWG_RAW_CUM_DY? Number(data.PLANT_RAWG_RAW_CUM_DY).toFixed(1): '--',
              dayAverage: data.PLANT_RAWG_RAW_RATE_HD? Number(data.PLANT_RAWG_RAW_RATE_HD).toFixed(3) + ' '+'万Nm³/h': '--',
            }
          ]
          this.setState({
            dataSource: dataSource
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  getSurviveData() {
    let currentTime = this.state.currentDate
    let tag_list = []
    let isToday = true
    if (this.state.todayDate === currentTime.substring(0, 10)) {
      tag_list = surviveTagList_today
      currentTime = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
    } else {
      tag_list = surviveTagList
      currentTime = this.state.currentDate + ' ' + '00:00:00'
      isToday = false
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
      create_time: currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        if (!isToday) {
          let a = data.AUX_OBLR_FUEL_CUM_D ? data.AUX_OBLR_FUEL_CUM_D : 0
          let b = data.AUX_FLARE_FUEL_CUM_D ? data.AUX_FLARE_FUEL_CUM_D : 0
          let c = data.PLANT_OTHER_FUEL_CUM_D ? data.PLANT_OTHER_FUEL_CUM_D : 0
          let d = data.COMP_DGS_SEALG_CUMD_D ? data.COMP_DGS_SEALG_CUMD_D : 0
          let e = data.COMP_MOTOR_ELEC_CUM_D ? data.COMP_MOTOR_ELEC_CUM_D : 0,
            f = data.C3CHL_C3CHL_ELEC_CUM_D ? data.C3CHL_C3CHL_ELEC_CUM_D : 0,
            g = data.PLANT_PID_ELEC_CUM_D ? data.PLANT_PID_ELEC_CUM_D : 0,
            h = data.PLANT_OTHER_ELEC_CUM_D ? data.PLANT_OTHER_ELEC_CUM_D : 0

          let gas_total = parseFloat(a + b + c + d)
          let electric_total = parseFloat(e + f + g + h)
          this.setState({
            naturalGas_single: data.PLANT_FUEL_FG_CONSUM_D ? Number(data.PLANT_FUEL_FG_CONSUM_D).toFixed(1) : '--',
            naturalGas_comparison: data.PLANT_FUEL_FG_CONSUM_HDQOQ
              ? Number(data.PLANT_FUEL_FG_CONSUM_HDQOQ).toFixed(2)
              : '--',
            naturalGas_dayTotal: data.PLANT_FUEL_FG_CUM_D ? Number(data.PLANT_FUEL_FG_CUM_D).toFixed(3) : '--',
            naturalGas_MonthTotal: data.PLANT_FUEL_FG_CUM_DM ? Number(data.PLANT_FUEL_FG_CUM_DM).toFixed(2) : '--',
            naturalGas_YearTotal: data.PLANT_FUEL_FG_CUM_DY ? Number(data.PLANT_FUEL_FG_CUM_DY).toFixed(2) : '--',
            naturalGas_redNum: data.AUX_OBLR_FUEL_CUM_D ? Number(data.AUX_OBLR_FUEL_CUM_D).toFixed(2) : '--',
            naturalGas_yellowNum: data.AUX_FLARE_FUEL_CUM_D ? Number(data.AUX_FLARE_FUEL_CUM_D).toFixed(2) : '--',
            naturalGas_greenNum: data.PLANT_OTHER_FUEL_CUM_D ? Number(data.PLANT_OTHER_FUEL_CUM_D).toFixed(2) : '--',
            naturalGas_LightGreenNum: data.COMP_DGS_SEALG_CUMD_D
              ? Number(data.COMP_DGS_SEALG_CUMD_D).toFixed(2)
              : '--',
            electric_single: data.PLANT_ALL_ELEC_CONSUM_D ? Number(data.PLANT_ALL_ELEC_CONSUM_D).toFixed(0) : '--',
            electric_comparison: data.PLANT_ALL_ELEC_CONSUM_HDQOQ
              ? Number(data.PLANT_ALL_ELEC_CONSUM_HDQOQ).toFixed(2)
              : '--',
            electric_dayTotal: data.PLANT_ALL_ELEC_CUM_D ? Number(data.PLANT_ALL_ELEC_CUM_D).toFixed(3) : '--',
            electric_MonthTotal: data.PLANT_ALL_ELEC_CUM_DM ? Number(data.PLANT_ALL_ELEC_CUM_DM).toFixed(2) : '--',
            electric_YearTotal: data.PLANT_ALL_ELEC_CUM_DY ? Number(data.PLANT_ALL_ELEC_CUM_DY).toFixed(2) : '--',
            electric_redNum: data.COMP_MOTOR_ELEC_CUM_D ? Number(data.COMP_MOTOR_ELEC_CUM_D).toFixed(2) : '--',
            electric_yellowNum: data.C3CHL_C3CHL_ELEC_CUM_D ? Number(data.C3CHL_C3CHL_ELEC_CUM_D).toFixed(2) : '--',
            electric_greenNum: data.PLANT_PID_ELEC_CUM_D ? Number(data.PLANT_PID_ELEC_CUM_D).toFixed(2) : '--',
            electric_LightGreenNum: data.PLANT_OTHER_ELEC_CUM_D
              ? Number(data.PLANT_OTHER_ELEC_CUM_D).toFixed(2)
              : '--',
            water_single: data.PLANT_COOL_H2O_CONSUM_D ? Number(data.PLANT_COOL_H2O_CONSUM_D).toFixed(2) : '--',
            water_comparison: data.PLANT_COOL_H2O_CONSUM_HDQOQ
              ? Number(data.PLANT_COOL_H2O_CONSUM_HDQOQ).toFixed(2)
              : '--',
            water_dayTotal: data.PLANT_COOL_H2O_CUM_D ? Number(data.PLANT_COOL_H2O_CUM_D).toFixed(3) : '--',
            water_MonthTotal: data.PLANT_COOL_H2O_CUM_DM ? Number(data.PLANT_COOL_H2O_CUM_DM).toFixed(2) : '--',
            water_YearTotal: data.PLANT_COOL_H2O_CUM_DY ? Number(data.PLANT_COOL_H2O_CUM_DY).toFixed(2) : '--',
            nitrogen_single: data.PLANT_N2GEN_N2_CONSUM_D ? Number(data.PLANT_N2GEN_N2_CONSUM_D).toFixed(2) : '--',
            nitrogen_comparison: data.PLANT_N2GEN_N2_CONSUM_HDQOQ
              ? Number(data.PLANT_N2GEN_N2_CONSUM_HDQOQ).toFixed(2)
              : '--',
            nitrogen_dayTotal: data.PLANT_N2GEN_N2_CUM_D ? Number(data.PLANT_N2GEN_N2_CUM_D).toFixed(3) : '--',
            nitrogen_MonthTotal: data.PLANT_N2GEN_N2_CUM_DM ? Number(data.PLANT_N2GEN_N2_CUM_DM).toFixed(2) : '--',
            nitrogen_YearTotal: data.PLANT_N2GEN_N2_CUM_DY ? Number(data.PLANT_N2GEN_N2_CUM_DY).toFixed(2) : '--',
            gasNumber: data.PLANT_FUEL_FG_CONCOST_D ? Number(data.PLANT_FUEL_FG_CONCOST_D).toFixed(2) : '--',
            electricNumber: data.PLANT_ALL_ELEC_CONCOST_D ? Number(data.PLANT_ALL_ELEC_CONCOST_D).toFixed(2) : '--',
            waterNumber: data.PLANT_COOL_H2O_CONCOST_D ? Number(data.PLANT_COOL_H2O_CONCOST_D).toFixed(2) : '--',
            nitrogenNumber: data.PLANT_N2GEN_N2_CONCOST_D ? Number(data.PLANT_N2GEN_N2_CONCOST_D).toFixed(2) : '--',
            gasTotal: gas_total,
            electricTotal: electric_total
          })
        } else {
          let a = data.AUX_OBLR_FUEL_CUM_HD ? data.AUX_OBLR_FUEL_CUM_HD : 0
          let b = data.AUX_FLARE_FUEL_CUM_HD ? data.AUX_FLARE_FUEL_CUM_HD : 0
          let c = data.PLANT_OTHER_FUEL_CUM_HD ? data.PLANT_OTHER_FUEL_CUM_HD : 0
          let d = data.COMP_DGS_SEALG_CUMD_HD ? data.COMP_DGS_SEALG_CUMD_HD : 0
          let e = data.COMP_MOTOR_ELEC_CUM_HD ? data.COMP_MOTOR_ELEC_CUM_HD : 0,
            f = data.C3CHL_C3CHL_ELEC_CUM_HD ? data.C3CHL_C3CHL_ELEC_CUM_HD : 0,
            g = data.PLANT_PID_ELEC_CUM_HD ? data.PLANT_PID_ELEC_CUM_HD : 0,
            h = data.PLANT_OTHER_ELEC_CUM_HD ? data.PLANT_OTHER_ELEC_CUM_HD : 0

          let gas_total = parseFloat(a + b + c + d)
          let electric_total = parseFloat(e + f + g + h)
          this.setState({
            naturalGas_single: data.PLANT_FUEL_FG_CONSUM_HD ? Number(data.PLANT_FUEL_FG_CONSUM_HD).toFixed(1) : '--',
            naturalGas_comparison: data.PLANT_FUEL_FG_CONSUM_HDQOQ
              ? Number(data.PLANT_FUEL_FG_CONSUM_HDQOQ).toFixed(2)
              : '--',
            naturalGas_dayTotal: data.PLANT_FUEL_FG_CUM_HD ? Number(data.PLANT_FUEL_FG_CUM_HD).toFixed(3) : '--',
            naturalGas_MonthTotal: data.PLANT_FUEL_FG_CUM_HM ? Number(data.PLANT_FUEL_FG_CUM_HM).toFixed(2) : '--',
            naturalGas_YearTotal: data.PLANT_FUEL_FG_CUM_DY ? Number(data.PLANT_FUEL_FG_CUM_DY).toFixed(2) : '--',
            naturalGas_redNum: data.AUX_OBLR_FUEL_CUM_HD ? Number(data.AUX_OBLR_FUEL_CUM_HD).toFixed(2) : '--',
            naturalGas_yellowNum: data.AUX_FLARE_FUEL_CUM_HD ? Number(data.AUX_FLARE_FUEL_CUM_HD).toFixed(2): '--',
            naturalGas_greenNum: data.PLANT_OTHER_FUEL_CUM_HD ? Number(data.PLANT_OTHER_FUEL_CUM_HD).toFixed(2) : '--',
            naturalGas_LightGreenNum: data.COMP_DGS_SEALG_CUMD_HD
              ? Number(data.COMP_DGS_SEALG_CUMD_HD).toFixed(2)
              : '--',
            electric_single: data.PLANT_ALL_ELEC_CONSUM_HD ? `${Number(data.PLANT_ALL_ELEC_CONSUM_HD).toFixed(0)}` : '--',
            electric_comparison: data.PLANT_ALL_ELEC_CONSUM_HDQOQ
              ? Number(data.PLANT_ALL_ELEC_CONSUM_HDQOQ).toFixed(2)
              : '--',
            electric_dayTotal: data.PLANT_ALL_ELEC_CUM_HD ? Number(data.PLANT_ALL_ELEC_CUM_HD).toFixed(3) : '--',
            electric_MonthTotal: data.PLANT_ALL_ELEC_CUM_HM ? Number(data.PLANT_ALL_ELEC_CUM_HM).toFixed(2) : '--',
            electric_YearTotal: data.PLANT_ALL_ELEC_CUM_DY ? Number(data.PLANT_ALL_ELEC_CUM_DY).toFixed(2) : '--',
            electric_redNum: data.COMP_MOTOR_ELEC_CUM_HD ? Number(data.COMP_MOTOR_ELEC_CUM_HD).toFixed(2) : '--',
            electric_yellowNum: data.C3CHL_C3CHL_ELEC_CUM_HD ? Number(data.C3CHL_C3CHL_ELEC_CUM_HD).toFixed(2) : '--',
            electric_greenNum: data.PLANT_PID_ELEC_CUM_HD ? Number(data.PLANT_PID_ELEC_CUM_HD).toFixed(2) : '--',
            electric_LightGreenNum: data.PLANT_OTHER_ELEC_CUM_HD
              ? Number(data.PLANT_OTHER_ELEC_CUM_HD).toFixed(2)
              : '--',
            water_single: data.PLANT_COOL_H2O_CONSUM_HD ? Number(data.PLANT_COOL_H2O_CONSUM_HD).toFixed(3) : '--',
            water_comparison: data.PLANT_COOL_H2O_CONSUM_HDQOQ
              ? Number(data.PLANT_COOL_H2O_CONSUM_HDQOQ).toFixed(2)
              : '--',
            water_dayTotal: data.PLANT_COOL_H2O_CUM_HD ? Number(data.PLANT_COOL_H2O_CUM_HD).toFixed(3) : '--',
            water_MonthTotal: data.PLANT_COOL_H2O_CUM_HM ? Number(data.PLANT_COOL_H2O_CUM_HM).toFixed(2) : '--',
            water_YearTotal: data.PLANT_COOL_H2O_CUM_DY ? Number(data.PLANT_COOL_H2O_CUM_DY).toFixed(2) : '--',
            nitrogen_single: data.PLANT_N2GEN_N2_CONSUM_HD ? Number(data.PLANT_N2GEN_N2_CONSUM_HD).toFixed(2) : '--',
            nitrogen_comparison: data.PLANT_N2GEN_N2_CONSUM_HDQOQ
                  ? Number(data.PLANT_N2GEN_N2_CONSUM_HDQOQ).toFixed(2)
                  : '--',
            nitrogen_dayTotal: data.PLANT_N2GEN_N2_CUM_HD ? Number(data.PLANT_N2GEN_N2_CUM_HD).toFixed(3) : '--',
            nitrogen_MonthTotal: data.PLANT_N2GEN_N2_CUM_HM ? Number(data.PLANT_N2GEN_N2_CUM_HM).toFixed(2) : '--',
            nitrogen_YearTotal: data.PLANT_N2GEN_N2_CUM_DY ? Number(data.PLANT_N2GEN_N2_CUM_DY).toFixed(2) : '--',
            gasNumber: data.PLANT_FUEL_FG_CONCOST_HD ? Number(data.PLANT_FUEL_FG_CONCOST_HD).toFixed(2) : '--',
            electricNumber: data.PLANT_ALL_ELEC_CONCOST_HD ? Number(data.PLANT_ALL_ELEC_CONCOST_HD).toFixed(2) : '--',
            waterNumber: data.PLANT_COOL_H2O_CONCOST_HD ? Number(data.PLANT_COOL_H2O_CONCOST_HD).toFixed(2) : '--',
            nitrogenNumber: data.PLANT_N2GEN_N2_CONCOST_HD ? Number(data.PLANT_N2GEN_N2_CONCOST_HD).toFixed(2) : '--',
            gasTotal: gas_total,
            electricTotal: electric_total
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  getEnergyData() {
    let currentTime = this.state.currentDate
    let tag_list = []
    let isToday = true
    if (this.state.todayDate === currentTime.substring(0, 10)) {
      tag_list = energyTagList_today
      currentTime = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
    } else {
      tag_list = energyTagList
      currentTime = this.state.currentDate + ' ' + '23:30:00'
      isToday = false
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
      create_time: currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        if (!isToday) {
          this.setState({
            energyGasNumber: data.PLANT_RAWG_RAW_RATE_D,
            energyGasPercent: data.PLANT_RAWG_RAW_RATE_HDQOQ,
            energyEthaneNumber: data.PLANT_C2H6_PRDT_RCRATE_D,
            energyEthanePercent: data.PLANT_C2H6_PRDT_RCRATE_HDQOQ,
            energyPropaneNumber: data.PLANT_C3H8_PRDT_RCRATE_D,
            energyPropanePercent: data.PLANT_C3H8_PRDT_RCRATE_HDQOQ,
            energyButaneNumber: data.PLANT_C4H10_PRDT_RCRATE_D,
            energyButanePercent: data.PLANT_C4H10_PRDT_RCRATE_HDQOQ,
            energyElectricNumber: data.PLANT_ALL_ELEC_CONSUM_D,
            energyElectricPercent: data.PLANT_ALL_ELEC_CONSUM_HDQOQ
          })
        } else {
          this.setState({
            energyGasNumber: data.PLANT_RAWG_RAW_RATE_HD,
            energyGasPercent: data.PLANT_RAWG_RAW_RATE_HDQOQ,
            energyEthaneNumber: data.PLANT_C2H6_PRDT_RCRATE_HD,
            energyEthanePercent: data.PLANT_C2H6_PRDT_RCRATE_HDQOQ,
            energyPropaneNumber: data.PLANT_C3H8_PRDT_RCRATE_HD,
            energyPropanePercent: data.PLANT_C3H8_PRDT_RCRATE_HDQOQ,
            energyButaneNumber: data.PLANT_C4H10_PRDT_RCRATE_HD,
            energyButanePercent: data.PLANT_C4H10_PRDT_RCRATE_HDQOQ,
            energyElectricNumber: data.PLANT_ALL_ELEC_CONSUM_HD,
            energyElectricPercent: data.PLANT_ALL_ELEC_CONSUM_HDQOQ
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 异常指标
  getDayAbnormalData() {
    post(process.env.REACT_APP_SERVER_PATH + 'api/factor/get_abnormal_events', {
      day: moment(this.state.currentDate).format('YYYY-MM-DD')
    })
      .then(res => {
        const data = res.data ? res.data.results : []
        const tag_list = []
        data.forEach(item => {
          tag_list.push(item.tag_name)
        })
        this.setState({abnormalList: data})
        this.setState({
            abnormalData: data
        }, () =>
            console.log(this.state.abnormalData)
        )
      })
      .then(() => {
          let currentTime = this.state.currentDate
          let begin_time, end_time = ''
          if (this.state.todayDate === currentTime.substring(0, 10)) {
              begin_time = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
              end_time = moment(this.state.currentDate).subtract(6, 'day').format('YYYY-MM-DD 23:30:00')
          } else {
              begin_time = moment(this.state.currentDate).format('YYYY-MM-DD 23:00:00')
              end_time = moment(this.state.currentDate).subtract(6, 'day').format('YYYY-MM-DD 23:00:00')
          }
        post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
          tag_list: [
            {
              tag_name: 'PLANT_C2H6_PRDT_RCRATE_H',
              time_begin: end_time,
              time_end:  begin_time
            },
            {
              tag_name: 'PLANT_C3H8_PRDT_RCRATE_H',
                time_begin: end_time,
                time_end:  begin_time
            },
            {
              tag_name: 'PLANT_C4H10_PRDT_RCRATE_H',
                time_begin: end_time,
                time_end:  begin_time
            }
          ]
        }).then(res => {
          const _data = res.data ? res.data.results : []
          const tempData = this.state.abnormalData.concat()
          tempData.forEach(item => {
              item.time = []
              item.lineChart = []
          })
          tempData.forEach(item => {
              _data.forEach(_item => {
                  if (item.tag_name === _item.tag_name.substring(0,_item.tag_name.length - 2)) {
                    _item.data_list.forEach(v=> {
                        item.time.push(v[0])
                        item.lineChart.push(v[1])
                    })
                  }
              })
          })
          this.setState({
            abnormalData: tempData
          })
        })
      })
      .catch(err => {})
  }
  getFirstTabDeviceData(name) {
    let currentTime = this.state.currentDate
    let tag_list = []
    let isToday = true
    if (this.state.todayDate === currentTime.substring(0, 10)) {
      if (name === '总效率') {
        tag_list = totalEfficiencyTagList_today
      } else if (name === '总功耗') {
        tag_list = totalPowerTagList_today
      } else if (name === '电单耗') {
        tag_list = powerConsumptionTagList_today
      } else if (name === '用电量') {
        tag_list = electricTotalTagList_today
      } else if (name === '干气密封放空') {
        tag_list = dryGasSealTagList_today
      } else {
        tag_list = totalEfficiencyTagList_today
      }
      currentTime = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
    } else {
      if (name === '总效率') {
        tag_list = totalEfficiencyTagList
      } else if (name === '总功耗') {
        tag_list = totalPowerTagList
      } else if (name === '电单耗') {
        tag_list = powerConsumptionTagList
      } else if (name === '用电量') {
        tag_list = electricTotalTagList
      } else if (name === '干气密封放空') {
        tag_list = dryGasSealTagList
      } else {
        tag_list = totalEfficiencyTagList
      }
      currentTime = this.state.currentDate + ' ' + '23:30:00'
      isToday = false
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
      create_time: currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        if (!isToday) {
          if (this.state.materialTabName === '总效率') {
            this.setState({
              totalEfficiencyPercent: data.COMP_TOT_EFF_RATE_D ? Number(data.COMP_TOT_EFF_RATE_D * 100).toFixed(2) : '--',
              totalEfficiencyComparison: data.COMP_TOT_EFF_RATE_HDQOQ
                ? Number(data.COMP_TOT_EFF_RATE_HDQOQ).toFixed(2)
                : '--',
              totalEfficiencyFirstRate: data.COMP_CASE1_EFF_RATE_D
                ? Number(data.COMP_CASE1_EFF_RATE_D * 100).toFixed(2)
                : '--',
              totalEfficiencySecondRate: data.COMP_CASE2_EFF_RATE_D
                ? Number(data.COMP_CASE2_EFF_RATE_D * 100).toFixed(2)
                : '--',
              totalEfficiencyThirdRate: data.COMP_CASE34_EFF_RATE_D
                ? Number(data.COMP_CASE34_EFF_RATE_D * 100).toFixed(2)
                : '--'
            })
          } else if (this.state.materialTabName === '总功耗') {
            this.setState({
              totalPowerPercent: data.COMP_TOT_SHAFT_RATE_D ? `${Number(data.COMP_TOT_SHAFT_RATE_D).toFixed(0)}` : '--',
              totalPowerComparison: data.COMP_TOT_SHAFT_RATE_HDQOQ
                ? Number(data.COMP_TOT_SHAFT_RATE_HDQOQ).toFixed(2)
                : '--',
              totalPowerFirst: data.REG_D301AB_SHAFT_RATE_D ? Number(data.REG_D301AB_SHAFT_RATE_D).toFixed(0) : '--',
              totalPowerSecond: data.COMP_GEAR_PLOSS_RATE_D ? Number(data.COMP_GEAR_PLOSS_RATE_D).toFixed(0) : '--',
              totalPowerThird: data.COMP_MOTOR_ELEC_RATE_D ? Number(data.COMP_MOTOR_ELEC_RATE_D).toFixed(0) : '--'
            })
          } else if (this.state.materialTabName === '电单耗') {
            this.setState({
              electricSingle: data.COMP_MOTOR_ELEC_CONSUM_D ? Number(data.COMP_MOTOR_ELEC_CONSUM_D).toFixed(0) : '--',
              electricSingleComparison: data.COMP_MOTOR_ELEC_CONSUM_HDQOQ
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_HDQOQ).toFixed(2)
                : '--',
              electricSingleWeek: data.COMP_MOTOR_ELEC_CONSUM_DW
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_DW).toFixed(0)
                : '--',
              electricSingleMonth: data.COMP_MOTOR_ELEC_CONSUM_DM
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_DM).toFixed(0)
                : '--',
              electricSingleYear: data.COMP_MOTOR_ELEC_CONSUM_DY
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_DY).toFixed(0)
                : '--'
            })
          } else if (this.state.materialTabName === '用电量') {
            this.setState({
              useElectricTotal: data.COMP_MOTOR_ELEC_CUM_D ? Number(data.COMP_MOTOR_ELEC_CUM_D).toFixed(3) : '--',
              useElectricComparison: data.COMP_MOTOR_ELEC_CUM_HDQOQ
                ? Number(data.COMP_MOTOR_ELEC_CUM_HDQOQ).toFixed(2)
                : '--',
              useElectricWeek: data.COMP_MOTOR_ELEC_CUM_DW ? Number(data.COMP_MOTOR_ELEC_CUM_DW).toFixed(3) : '--',
              useElectricMonth: data.COMP_MOTOR_ELEC_CUM_DM ? Number(data.COMP_MOTOR_ELEC_CUM_DM).toFixed(2) : '--',
              useElectricYear: data.COMP_MOTOR_ELEC_CUM_DY ? Number(data.COMP_MOTOR_ELEC_CUM_DY).toFixed(1) : '--'
            })
          } else if (this.state.materialTabName === '干气密封放空') {
            this.setState({
              dryGasTotal: data.COMP_DGS_SEALG_RATE_D ? Number(data.COMP_DGS_SEALG_RATE_D).toFixed(2) : '--',
              dryGasComparison: data.COMP_DGS_SEALG_RATE_HDQOQ
                ? Number(data.COMP_DGS_SEALG_RATE_HDQOQ).toFixed(2)
                : '--'
            })
          }
        } else {
          if (this.state.materialTabName === '总效率') {
            this.setState({
              totalEfficiencyPercent: data.COMP_TOT_EFF_RATE_H ? Number(data.COMP_TOT_EFF_RATE_H * 100).toFixed(2) : '--',
              totalEfficiencyComparison: data.COMP_TOT_EFF_RATE_HDQOQ
                ? Number(data.COMP_TOT_EFF_RATE_HDQOQ).toFixed(2)
                : '--',
              totalEfficiencyFirstRate: data.COMP_CASE1_EFF_RATE ? Number(data.COMP_CASE1_EFF_RATE * 100).toFixed(2) : '--',
              totalEfficiencySecondRate: data.COMP_CASE2_EFF_RATE ? Number(data.COMP_CASE2_EFF_RATE * 100).toFixed(2) : '--',
              totalEfficiencyThirdRate: data.COMP_CASE34_EFF_RATE ? Number(data.COMP_CASE34_EFF_RATE * 100).toFixed(2) : '--'
            })
          } else if (this.state.materialTabName === '总功耗') {
            this.setState({
              totalPowerPercent: data.COMP_TOT_SHAFT_RATE_H ? `${Number(data.COMP_TOT_SHAFT_RATE_H).toFixed(0)}` : '--',
              totalPowerComparison: data.COMP_TOT_SHAFT_RATE_HDQOQ
                ? Number(data.COMP_TOT_SHAFT_RATE_HDQOQ).toFixed(2)
                : '--',
              totalPowerFirst: data.REG_D301AB_SHAFT_RATE ? Number(data.REG_D301AB_SHAFT_RATE).toFixed(0) : '--',
              totalPowerSecond: data.COMP_GEAR_PLOSS_RATE ? Number(data.COMP_GEAR_PLOSS_RATE).toFixed(0) : '--',
              totalPowerThird: data.COMP_MOTOR_ELEC_RATE ? Number(data.COMP_MOTOR_ELEC_RATE).toFixed(0) : '--'
            })
          } else if (this.state.materialTabName === '电单耗') {
            this.setState({
              electricSingle: data.COMP_MOTOR_ELEC_CONSUM_HD
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_HD).toFixed(0)
                : '--',
              electricSingleComparison: data.COMP_MOTOR_ELEC_CONSUM_HDQOQ
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_HDQOQ).toFixed(2)
                : '--',
              electricSingleWeek: data.COMP_MOTOR_ELEC_CONSUM_HW
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_HW).toFixed(0)
                : '--',
              electricSingleMonth: data.COMP_MOTOR_ELEC_CONSUM_HM
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_HM).toFixed(0)
                : '--',
              electricSingleYear: data.COMP_MOTOR_ELEC_CONSUM_DY
                ? Number(data.COMP_MOTOR_ELEC_CONSUM_DY).toFixed(0)
                : '--'
            })
          } else if (this.state.materialTabName === '用电量') {
            this.setState({
              useElectricTotal: data.COMP_MOTOR_ELEC_CUM_HD ? Number(data.COMP_MOTOR_ELEC_CUM_HD).toFixed(3) : '--',
              useElectricComparison: data.COMP_MOTOR_ELEC_CUM_HDQOQ
                ? Number(data.COMP_MOTOR_ELEC_CUM_HDQOQ).toFixed(2)
                : '--',
              useElectricWeek: data.COMP_MOTOR_ELEC_CUM_HW ? Number(data.COMP_MOTOR_ELEC_CUM_HW).toFixed(3) : '--',
              useElectricMonth: data.COMP_MOTOR_ELEC_CUM_HM ? Number(data.COMP_MOTOR_ELEC_CUM_HM).toFixed(2) : '--',
              useElectricYear: data.COMP_MOTOR_ELEC_CUM_DY ? Number(data.COMP_MOTOR_ELEC_CUM_DY).toFixed(1) : '--'
            })
          } else if (this.state.materialTabName === '干气密封放空') {
            this.setState({
              dryGasTotal: data.COMP_DGS_SEALG_RATE_H ? Number(data.COMP_DGS_SEALG_RATE_H).toFixed(2) : '--',
              dryGasComparison: data.COMP_DGS_SEALG_RATE_HDQOQ ? Number(data.COMP_DGS_SEALG_RATE_HDQOQ).toFixed(2) : '--'
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  getFistTabChart(name) {
    let tag_name = 'COMP_TOT_SHAFT_RATE_H'
    if (name === '总功耗') {
      tag_name = 'COMP_TOT_SHAFT_RATE_H'
    } else if (name === '电单耗') {
      tag_name = 'COMP_MOTOR_ELEC_CONSUM_H'
    } else if (name === '用电量') {
      tag_name = 'COMP_MOTOR_ELEC_CUM_H'
    } else if (name === '干气密封放空') {
      tag_name = 'COMP_DGS_SEALG_RATE_H'
    } else if (name === '回收功率') {
      tag_name = 'EXPC_EXP_SHAFT_RATE_H'
    }
      let currentTime = this.state.currentDate
      let begin_time, end_time = ''
      if (this.state.todayDate === currentTime.substring(0, 10)) {
          begin_time = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
          end_time = moment(this.state.currentDate).subtract(6, 'day').format('YYYY-MM-DD 23:30:00')
      } else {
          begin_time = moment(this.state.currentDate).format('YYYY-MM-DD 23:00:00')
          end_time = moment(this.state.currentDate).subtract(6, 'day').format('YYYY-MM-DD 23:00:00')
      }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
      tag_list: [
        {
          tag_name: tag_name,
          time_begin: end_time,
          time_end:  begin_time
        }
      ]
    })
      .then(res => {
        console.log(res)
        const data = res.data ? res.data.results : []
        const chart = []
        const chart_time = []
        data.forEach(item => {
          item.data_list.forEach(_item => {
            chart.push(_item[1])
            chart_time.push(_item[0])
          })
        })
        this.setState({
          deviceTabChart: chart,
          deviceTabTime: chart_time
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  getSecondTabDeviceData(name) {
    let currentTime = this.state.currentDate
    let tag_list = []
    let isToday = true
    if (this.state.todayDate === currentTime.substring(0, 10)) {
      if (name === '膨胀机效率') {
        tag_list = expanderRateTagList_today
      } else if (name === '膨胀压缩机效率') {
        tag_list = expanderCompressorRateTagList_today
      } else if (name === '回收功率') {
        tag_list = recoveryRateTagList_today
      } else {
        tag_list = expanderRateTagList_today
      }
    currentTime = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
    } else {
      if (name === '膨胀机效率') {
        tag_list = expanderRateTagList
      } else if (name === '膨胀压缩机效率') {
        tag_list = expanderCompressorRateTagList
      } else if (name === '回收功率') {
        tag_list = recoveryRateTagList
      } else {
        tag_list = expanderRateTagList
      }
      currentTime = this.state.currentDate + ' ' + '23:30:00'
      isToday = false
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
      create_time: currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        if (!isToday) {
          if (this.state.expandTabName === '膨胀机效率') {
            this.setState({
              expanderTotal: data.EXPC_EXP_EFF_RATE_D ? Number(data.EXPC_EXP_EFF_RATE_D * 100).toFixed(2) : '--',
              expanderComparison: data.EXPC_EXP_EFF_RATE_HDQOQ ? Number(data.EXPC_EXP_EFF_RATE_HDQOQ).toFixed(2) : '--'
            })
          } else if (this.state.expandTabName === '膨胀压缩机效率') {
            this.setState({
              expanderCompressorRateTotal: data.EXPC_EC_EFF_RATE_D ? Number(data.EXPC_EC_EFF_RATE_D * 100).toFixed(2) : '--',
              expanderCompressorRateComparison: data.EXPC_EC_EFF_RATE_HDQOQ
                ? Number(data.EXPC_EC_EFF_RATE_HDQOQ).toFixed(2)
                : '--'
            })
          } else if (this.state.expandTabName === '回收功率') {
            this.setState({
              recoveryRateTotal: data.EXPC_EXP_SHAFT_RATE_D ? `${Number(data.EXPC_EXP_SHAFT_RATE_D).toFixed(0)}.0` : '--',
              recoveryRateComparison: data.EXPC_EXP_SHAFT_RATE_HDQOQ
                ? Number(data.EXPC_EXP_SHAFT_RATE_HDQOQ).toFixed(2)
                : '--'
            })
          }
        } else {
          if (this.state.expandTabName === '膨胀机效率') {
            this.setState({
              expanderTotal: data.EXPC_EXP_EFF_RATE_H ? Number(data.EXPC_EXP_EFF_RATE_H * 100).toFixed(2) : '--',
              expanderComparison: data.EXPC_EXP_EFF_RATE_HDQOQ ?Number(data.EXPC_EXP_EFF_RATE_HDQOQ).toFixed(2) : '--'
            })
          } else if (this.state.expandTabName === '膨胀压缩机效率') {
            this.setState({
              expanderCompressorRateTotal: data.EXPC_EC_EFF_RATE_H ? Number(data.EXPC_EC_EFF_RATE_H * 100).toFixed(2) : '--',
              expanderCompressorRateComparison: data.EXPC_EC_EFF_RATE_HDQOQ
                ? Number(data.EXPC_EC_EFF_RATE_HDQOQ).toFixed(2)
                : '--'
            })
          } else if (this.state.expandTabName === '回收功率') {
            this.setState({
              recoveryRateTotal: data.EXPC_EXP_SHAFT_RATE_H ? `${Number(data.EXPC_EXP_SHAFT_RATE_H).toFixed(0)}.0` : '--',
              recoveryRateComparison: data.EXPC_EXP_SHAFT_RATE_HDQOQ
                ? Number(data.EXPC_EXP_SHAFT_RATE_HDQOQ).toFixed(2)
                : '--'
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
  getEnergyChartData() {
    let currentTime = this.state.currentDate
    let begin_time, end_time = ''
    if (this.state.todayDate === currentTime.substring(0, 10)) {
        begin_time = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:10:00')
        end_time = moment(this.state.currentDate).subtract(6, 'day').format('YYYY-MM-DD 23:30:00')
    } else {
        begin_time = moment(this.state.currentDate).format('YYYY-MM-DD 23:00:00')
        end_time = moment(this.state.currentDate).subtract(6, 'day').format('YYYY-MM-DD 23:00:00')
    }
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
      tag_list: [
        {
          tag_name: 'PLANT_RAWG_RAW_RATE_H',
          time_begin: end_time,
          time_end: begin_time
        },
        {
          tag_name: 'PLANT_C2H6_PRDT_RCRATE_H',
          time_begin: end_time,
          time_end: begin_time
        },
        {
          tag_name: 'PLANT_C3H8_PRDT_RCRATE_H',
          time_begin: end_time,
          time_end: begin_time
        },
        {
          tag_name: 'PLANT_C4H10_PRDT_RCRATE_H',
          time_begin: end_time,
          time_end: begin_time
        },
        {
          tag_name: 'PLANT_ALL_ELEC_CONSUM_H',
          time_begin: end_time,
          time_end: begin_time
        }
      ]
    })
      .then(res => {
        const data = res.data ? res.data.results : []
        const chart_1 = []
        const chart_2 = []
        const chart_3 = []
        const chart_4 = []
        const chart_5 = []
        const chart_1_time = []
        const chart_2_time = []
        const chart_3_time = []
        const chart_4_time = []
        const chart_5_time = []

        data.forEach(item => {
          if (item.tag_name === 'PLANT_RAWG_RAW_RATE_H') {
            item.data_list.forEach(_item => {
              chart_1.push(_item[1])
              chart_1_time.push(_item[0])
            })
          }
          if (item.tag_name === 'PLANT_C2H6_PRDT_RCRATE_H') {
            item.data_list.forEach(_item => {
              chart_2.push(_item[1])
              chart_2_time.push(_item[0])
            })
          }
          if (item.tag_name === 'PLANT_C3H8_PRDT_RCRATE_H') {
            item.data_list.forEach(_item => {
              chart_3.push(_item[1])
              chart_3_time.push(_item[0])
            })
          }
          if (item.tag_name === 'PLANT_C4H10_PRDT_RCRATE_H') {
            item.data_list.forEach(_item => {
              chart_4.push(_item[1])
              chart_4_time.push(_item[0])
            })
          }
          if (item.tag_name === 'PLANT_ALL_ELEC_CONSUM_H') {
            item.data_list.forEach(_item => {
              chart_5.push(_item[1])
              chart_5_time.push(_item[0])
            })
          }
        })
        this.setState({
          gasChart: chart_1,
          ethaneChart: chart_2,
          propaneChart: chart_3,
          butaneChart: chart_4,
          electricChart: chart_5,
          gasChartTime: chart_1_time,
          ethaneChartTime: chart_2_time,
          propaneChartTime: chart_3_time,
          butaneChartTime: chart_4_time,
          electricChartTime: chart_5_time
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  changeTab(name, index) {
    this.setState({
      firstTabIndex: index,
      materialTabName: name
    })
    if (name === '总功耗') {
      this.getFistTabChart(name)
    } else if (name === '电单耗') {
      this.getFistTabChart(name)
    } else if (name === '用电量') {
      this.getFistTabChart(name)
    } else if (name === '干气密封放空') {
      this.getFistTabChart(name)
    }
    this.getFirstTabDeviceData(name)
  }
  changeSecondTab(name, i) {
    this.setState({
      secondTabIndex: i,
      expandTabName: name
    })
    if (name === '回收功率') {
      this.getFistTabChart(name)
    }
    this.getSecondTabDeviceData(name)
  }
  getAll() {
    this.getEnergyData()
    this.getEnergyChartData()
    this.getProductData()
    this.getSurviveData()
    this.getFirstTabDeviceData()
    this.getSecondTabDeviceData()
    this.getDayAbnormalData()
  }
  componentDidMount() {
    this.getAll()
    this.timer = setInterval(() => {
        this.getEnergyChartData()
    }, 36000)
  }
  componentWillUnmount() {
    if (this.timer != null) {
        clearInterval(this.timer);
    }
  }

  render() {
    return (
      <div className={style['home']} id="screen">
        <TimeSelect
          currentDate={this.state.currentDate}
          isShowDatePicker={this.state.isShowDatePicker}
          toggleShowDatePicker={this.toggleShowDatePicker.bind(this)}
          changeDate={this.changeDate.bind(this)}
          prevDate={this.prevDate.bind(this)}
          nextDate={this.nextDate.bind(this)}
          disabledDate={this.disabledDate.bind(this)}
        />
        <div className={style['clearFix']}></div>
        <div className={style['basics']}>
          <div className={style['basics-left']}>
            <CardList
              energyGasNumber={this.state.energyGasNumber}
              energyGasPercent={this.state.energyGasPercent}
              energyEthaneNumber={this.state.energyEthaneNumber}
              energyEthanePercent={this.state.energyEthanePercent}
              energyPropaneNumber={this.state.energyPropaneNumber}
              energyPropanePercent={this.state.energyPropanePercent}
              energyButaneNumber={this.state.energyButaneNumber}
              energyButanePercent={this.state.energyButanePercent}
              energyElectricNumber={this.state.energyElectricNumber}
              energyElectricPercent={this.state.energyElectricPercent}
              gasChart={this.state.gasChart}
              ethaneChart={this.state.ethaneChart}
              propaneChart={this.state.propaneChart}
              butaneChart={this.state.butaneChart}
              electricChart={this.state.electricChart}
              gasChartTime={this.state.gasChartTime}
              ethaneChartTime={this.state.ethaneChartTime}
              propaneChartTime={this.state.propaneChartTime}
              butaneChartTime={this.state.butaneChartTime}
              electricChartTime={this.state.electricChartTime}
            />
            <Abnormal abnormalData={this.state.abnormalData}/>
            <SurviveTable
                naturalGas_single={this.state.naturalGas_single}
                naturalGas_comparison={this.state.naturalGas_comparison}
                naturalGas_dayTotal={this.state.naturalGas_dayTotal}
                naturalGas_MonthTotal={this.state.naturalGas_MonthTotal}
                naturalGas_YearTotal={this.state.naturalGas_YearTotal}
                naturalGas_redNum={this.state.naturalGas_redNum}
                naturalGas_yellowNum={this.state.naturalGas_yellowNum}
                naturalGas_greenNum={this.state.naturalGas_greenNum}
                naturalGas_LightGreenNum={this.state.naturalGas_LightGreenNum}
                electric_single={this.state.electric_single}
                electric_comparison={this.state.electric_comparison}
                electric_dayTotal={this.state.electric_dayTotal}
                electric_MonthTotal={this.state.electric_MonthTotal}
                electric_YearTotal={this.state.electric_YearTotal}
                electric_redNum={this.state.electric_redNum}
                electric_yellowNum={this.state.electric_yellowNum}
                electric_greenNum={this.state.electric_greenNum}
                electric_LightGreenNum={this.state.electric_LightGreenNum}
                water_single={this.state.water_single}
                water_comparison={this.state.water_comparison}
                water_dayTotal={this.state.water_dayTotal}
                water_MonthTotal={this.state.water_MonthTotal}
                water_YearTotal={this.state.water_YearTotal}
                nitrogen_single={this.state.nitrogen_single}
                nitrogen_comparison={this.state.nitrogen_comparison}
                nitrogen_dayTotal={this.state.nitrogen_dayTotal}
                nitrogen_MonthTotal={this.state.nitrogen_MonthTotal}
                nitrogen_YearTotal={this.state.nitrogen_YearTotal}
                gasNumber={this.state.gasNumber}
                electricNumber={this.state.electricNumber}
                waterNumber={this.state.waterNumber}
                nitrogenNumber={this.state.nitrogenNumber}
                gasTotal={this.state.gasTotal}
                electricTotal={this.state.electricTotal}
            />
          </div>
          <div className={style['basics-right']}>
            <div className={style['deviceSystem']}>
              <div className={style['deviceSystem-title']}>关键设备及系统</div>
              <div className={style['deviceSystem-tab']}>
                <div style={{fontWeight: "bold",fontSize: 16}}>原料气压缩机</div>
                <Tabs
                  tabList={tabList_1}
                  activeIndex={this.state.firstTabIndex}
                  changeTab={this.changeTab.bind(this)}
                />
              </div>
              <div className={style['deviceSystem-panel']}>
                <TabPanel
                  typeName={this.state.materialTabName}
                  totalEfficiencyPercent={this.state.totalEfficiencyPercent}
                  totalEfficiencyComparison={this.state.totalEfficiencyComparison}
                  totalEfficiencyFirstRate={this.state.totalEfficiencyFirstRate}
                  totalEfficiencySecondRate={this.state.totalEfficiencySecondRate}
                  totalEfficiencyThirdRate={this.state.totalEfficiencyThirdRate}
                  totalPowerPercent={this.state.totalPowerPercent}
                  totalPowerComparison={this.state.totalPowerComparison}
                  totalPowerFirst={this.state.totalPowerFirst}
                  totalPowerSecond={this.state.totalPowerSecond}
                  totalPowerThird={this.state.totalPowerThird}
                  electricSingle={this.state.electricSingle}
                  electricSingleComparison={this.state.electricSingleComparison}
                  electricSingleWeek={this.state.electricSingleWeek}
                  electricSingleMonth={this.state.electricSingleMonth}
                  electricSingleYear={this.state.electricSingleYear}
                  useElectricTotal={this.state.useElectricTotal}
                  useElectricComparison={this.state.useElectricComparison}
                  useElectricWeek={this.state.useElectricWeek}
                  useElectricMonth={this.state.useElectricMonth}
                  useElectricYear={this.state.useElectricYear}
                  dryGasTotal={this.state.dryGasTotal}
                  dryGasComparison={this.state.dryGasComparison}
                  deviceTabChart={this.state.deviceTabChart}
                  deviceTabTime={this.state.deviceTabTime}
                />
              </div>
              <div className={style['deviceSystem-tab']}>
                <div style={{fontWeight: "bold",fontSize: 16}}>膨胀压缩机</div>
                <SecondTabs
                  tabList={tabList_2}
                  activeIndex_={this.state.secondTabIndex}
                  changeSecondTab={this.changeSecondTab.bind(this)}
                />
              </div>
              <div className={style['deviceSystem-panel']}>
                <SecondTabPanel
                  typeName={this.state.expandTabName}
                  expanderTotal={this.state.expanderTotal}
                  expanderComparison={this.state.expanderComparison}
                  expanderCompressorRateTotal={this.state.expanderCompressorRateTotal}
                  expanderCompressorRateComparison={this.state.expanderCompressorRateComparison}
                  recoveryRateTotal={this.state.recoveryRateTotal}
                  recoveryRateComparison={this.state.recoveryRateComparison}
                  deviceTabChart={this.state.deviceTabChart}
                  deviceTabTime={this.state.deviceTabTime}
                />
              </div>
            </div>
            <div className={style['productionSituation']}>
              <div className={style['productionSituation-title']}>生产情况</div>
              <Table
                columns={[
                  {
                    title: '名称',
                    dataIndex: 'name',
                    className: 'column-pro media noBorder center',
                    width: 100,
                    key: 'name'
                  },
                  {
                    title: '',
                    dataIndex: 'nameUnit',
                    className: 'column-pro media noBorder center',
                    width: 80,
                    key: 'nameUnit'
                  },
                  {
                    title: '日累',
                    dataIndex: 'dayTotal',
                    className: 'column-pro media blod noBorder right center',
                    width: 100,
                    key: 'dayTotal'
                  },
                  {
                    title: '环比',
                    dataIndex: 'comparison',
                    className: 'column-pro media noBorder right th-right',
                    width: 100,
                    key: 'comparison'
                  },
                  {
                    title: '月累',
                    dataIndex: 'monthTotal',
                    className: 'column-pro media noBorder right th-right',
                    width: 120,
                    key: 'monthTotal'
                  },
                  {
                    title: '年累',
                    dataIndex: 'yearTotal',
                    className: 'column-pro media right th-right',
                    width: 100,
                    key: 'yearTotal'
                  },
                  {
                    title: '日均',
                    dataIndex: 'dayAverage',
                    className: 'column-pro media right center',
                    width: 180,
                    key: 'dayAverage'
                  }
                ]}
                dataSource={this.state.dataSource}
                pagination={false}
                size="middle"
                bordered={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Home
