import React from 'react';
import { Select } from 'antd';
import { get } from '../../../../common/utils';
import style from './index.module.scss';

const { Option } = Select;

export default class SearchSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            dataList: [],
            searchApi: props?.searchApi ?? null,
        };
    }

    componentDidMount(){
        this.updateValue(this.props);
        // 刚打开时加载，
        // 后续 componentWillReceiveProps 中不重复加载，避免输入并选定内容后，再次点击只显示一条数据
        let searchValue = this.props.value;
        if(!searchValue){
            searchValue = '';
        }
        this.handleSearch(searchValue);
    }

    componentWillReceiveProps(nextProps) {
        this.updateValue(nextProps);
    }

    updateValue = (props)=>{
        // let boat_name = props.addons.getValue("boat_name") || '';
        // let matl_name = props.addons.getValue("matl_name") || '';
        // let fe_pct = props.addons.getValue("fe_pct") || '';
        // let mn_pct = props.addons.getValue("mn_pct") || '';
        // let day = props.addons.getValue("day") || '';
        // let value = boat_name + ' ' + matl_name + ' Fe' + fe_pct + ' Mn' + mn_pct + ' ' + day.replaceAll('-', '');
        let value = props.value;
        this.setState({value: value});
        props.onChange(value);
    }

    handleSearch = value => {
        const { searchApi } = this.state;
        if (typeof searchApi !== 'string') {
            // 用户输入内容无任何反应
            return;
        };
        let self = this;
        let combinedQuery = searchApi;
        if (typeof value === 'string' && value !== "") {
            if (combinedQuery.indexOf("?") !== -1) {
                // 包含params的
                combinedQuery += "&";
            } else {
                // 不包含params
                combinedQuery += "?";
            };
            const keyWordParam = `keyword=${value}`;
            combinedQuery += keyWordParam;
        };
        get(process.env.REACT_APP_SERVER_PATH + combinedQuery, {})
            .then((retData) => {
                // console.log(retData);
                let results = retData?.data?.results ?? [];
                let dataList = [];
                results.map(item => {
                    if (dataList.indexOf(item.full_name) < 0) {
                        dataList.push(item.full_name);
                    }
                })
                self.setState({
                    dataList: dataList
                })
            });
    }

    handleChange = value => {
        this.setState({ value });
        this.props.onChange(value);
        if(value === undefined){ // 因为有allowClear，因此用户清空输入框后，需要加载最新的数据。
            this.handleSearch('');
        }
    }

    render() {
        const { value, dataList } = this.state;
        return (
            <Select
                showSearch
                className={style['wrapper']}
                value={this.state.value}
                placeholder={this.props.placeholder}
                // style={this.props.style}
                style={{
                    maxWidth: "calc(100vw - 142px)",
                    minWidth: "288px",
                    fontSize: "12px",
                }}
                allowClear={true}
                defaultActiveFirstOption={false}
                // showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
            {
                dataList.map(item=>
                    <Option key={item}>{item}</Option>
                )
            }
            </Select>
        )
    }
}
