import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import './FileItem.scss';
import excelIcon from './icons/excel.png'
import codeIcon from './icons/code.png'
import defaultIcon from './icons/default.png'

const FILE_ICONS = {
    'xlsx': excelIcon,
    'xls': excelIcon,
    'csv': excelIcon,
    'py': codeIcon,
    'DEFAULT': defaultIcon,
}

export default class FileItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ext: this.getExt(props.name)
        };
    }

    getExt = (name)=>{
        if(!name || name.indexOf('.')<0){
            return 'DEFAULT';
        }else{
            let ext = name.split('.').pop();
            if(Object.keys(FILE_ICONS).indexOf(ext) >= 0){
                return ext;
            }else{
                return 'DEFAULT';
            }
        }
    }

    componentDidMount() {

    }

    render() {
        let self = this;
        const { name, selected } = this.props;
        const { ext } = this.state;

        return (
            <div
                className={"FileItem "+(selected?'selected':'')}
                onClick={()=>{
                    console.log('onClick FileItem');
                    this.props.onClick();
                }}
            >
                <img src={FILE_ICONS[ext]} onDragStart={e=>e.preventDefault()} />
                <div className="fileName" onClick={(e)=>{e.stopPropagation();}}>{name}</div>
                <div className="checkRect" onClick={(e)=>{
                    console.log('onClick checkRect: ', !selected);
                    e.stopPropagation();
                    self.props.onSelect(!selected);
                }}>
                    <CheckOutlined
                        className="checkedIcon"
                        style={{fontSize: 16, color: '#666'}}
                        // onClick={(e)=>{
                        //     console.log('onClick CheckOutlined');
                        //     e.stopPropagation();
                        //     self.props.onSelect(!selected);
                        // }}
                    />
                </div>
            </div>
        );
    }
}
