import Page from './ShiftCompareBlock.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { referenceFactors } = state;
    const { shiftDetails, timeRange } = referenceFactors;
    return {
        shiftDetails,
        timeRange,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;