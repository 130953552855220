import React from 'react'
import { Table, Button, message, Select, DatePicker } from 'antd';
import TopBreadcrumb from 'components/TopBreadCrumb'
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { get, post, postDownloadFile, roundNum } from 'common/utils.js';
import moment from 'moment';
import style from './ExamDailyScore.module.scss';

const Column = Table.Column;
const Option = Select.Option;


export default class ExamDailyScore extends React.Component {
    constructor(props) {
        super(props);
        const env_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
        const default_time = (typeof env_time === 'string' && env_time !== "")
            ? (moment(env_time).format("YYYY-MM-DD ") + moment().format("HH:mm:ss"))
            : moment().format("YYYY-MM-DD HH:mm:ss");
        this.state = {
            sysList: [],
            dataList: [],
            loading: false,
            shiftList: [],
            month: moment(default_time),
            sys_id: 1,
        }
    }

    componentDidMount() {
        this.getDataList();
        this.getSysList();
        this.getShiftList();
    }

    getDataList = ()=>{
        let self = this;
        self.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/exam/get_shift_scores_per_day',{
            sys_id: this.state.sys_id,
            day_begin: moment(this.state.month).startOf('month').format("YYYY-MM-DD"),
            day_end: moment(this.state.month).endOf('month').format("YYYY-MM-DD"),
        }).then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.results) {
                self.setState({dataList: retData.data.results})
            };
        });
    }

    getSysList = ()=>{
        let self = this;
        self.setState({loading: true});
        get(process.env.REACT_APP_SERVER_PATH + 'api/meta/get_prod_system_list').then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.results) {
                self.setState({sysList: retData.data.results})
            };
        });
    }

    getShiftList = ()=>{
        let self = this;
        self.setState({loading: true});
        get(process.env.REACT_APP_SERVER_PATH + '/api/shift/get_config_info').then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.results) {
                self.setState({shiftList: retData.data.results})
            };
        });
    }

    onChangeMonth = (v, x)=>{
        this.setState({month: v}, ()=>this.getDataList());
        // console.log(v,x,moment(v).format("YYYY-MM-DD"));
    }

    downloadExcel = ()=>{
        postDownloadFile(process.env.REACT_APP_SERVER_PATH + 'api/exam/download_shift_scores_per_day', {
            sys_id: this.state.sys_id,
            day_begin: moment(this.state.month).startOf('month').format("YYYY-MM-DD"),
            day_end: moment(this.state.month).endOf('month').format("YYYY-MM-DD"),
        });
    }

    render() {
        let self = this;
        let { loading, shiftList, dataList, month, sysList } = this.state;
        console.log(this)
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', overflow: 'auto', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <TopBreadcrumb breadcrumbList={[{ name: '指标考核' }, { name: '每日得分' }]} />
                <div className="ExamDailyScore">
                    <div className="toolBar">
                        <div className="leftTools">
                            <Select defaultValue={1} style={{ width: 120 }} onChange={v=>this.setState({sys_id: parseInt(v)}, ()=>this.getDataList())}>
                                {
                                    sysList.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )
                                }
                            </Select>
                            <DatePicker value={month} style={{marginLeft: 12}} onChange={(v, x)=>this.onChangeMonth(v, x)} picker="month" />
                        </div>
                        <div className="rightTools">
                            <Button onClick={_=>this.downloadExcel()} type="primary">导出Excel</Button>
                        </div>
                    </div>
                    <div className="table_wrapper">
                        <Table
                            rowKey='id'
                            dataSource={dataList}
                            loading={loading}
                            pagination={false}
                            // onChange={pagination =>
                            //     this.setState({ pageSize: pagination.pageSize }, () => self.getUserList(pagination.current))
                            // }
                            rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                        >
                            <Column
                                title='日期'
                                key='day'
                                render={r=>{
                                    return moment(r.day).format("YYYY年M月D日");
                                }}
                            />
                            <Column
                                title='当日均分'
                                key='当日均分'
                                dataIndex='daily_score'
                                render={text=>{
                                    if(text === undefined || text === null){
                                        return '';
                                    }else{
                                        return roundNum(parseFloat(text), 3);
                                    }
                                }}
                            />
                            {
                                shiftList.map(item=>
                                    <Column
                                        title={item.name}
                                        key={"shift_"+item.id}
                                        dataIndex={"shift_"+item.id}
                                        render={text=>{
                                            if(text === undefined || text === null){
                                                return '';
                                            }else{
                                                return roundNum(parseFloat(text), 3);
                                            }
                                        }}
                                    />
                                )
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}
