import React, { useContext, useEffect, useMemo, useRef } from 'react';
import style from './index.module.scss';
import InfoCard from './InfoCard';
import InfoCard2 from './InfoCard2';
import { infoCard_1, infoCard_2, infoCard_1_2 } from './constants';
import PieCard from './PieCard';
import HzCard from './HzCard';
import GaugeCard from './GaugeCard';
import CommonContext from '../context';

const INTERVAL_DUR = 10000;

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const dispatchFunc = useRef(() => { });
    const selectedDevice = useMemo(() => {
        if (!!commonInfo) {
            return commonInfo.state.selectedDevice;
        };
        return null;
    }, [commonInfo]);
    useEffect(() => {
        if (!!commonInfo && typeof commonInfo.dispatch === 'function') {
            dispatchFunc.current = commonInfo.dispatch;
        };
    }, [commonInfo]);
    useEffect(() => {
        // selectedDevice 变化后重置计时
        const myInterval = setInterval(() => {
            dispatchFunc.current({ type: 'refreshTick', refreshTick: Symbol() });
        }, INTERVAL_DUR);
        return () => {
            clearInterval(myInterval);
        };
    }, [selectedDevice]);

    return (
        <div className={style['wrapper']}>
            <div style={{ width: 1170, height: 524 }}>
                <InfoCard {...infoCard_1} />
            </div>
            <div style={{ width: 1170, height: 524,marginLeft: 60 }}>
                <InfoCard {...infoCard_1_2} />
            </div>
            <div style={{ width: 940, height: 524,marginLeft: 60 }}>
                <InfoCard2 />
            </div>
            {/*<div style={{ width: "100%", marginTop: 12 }}></div>*/}
            <div style={{ width: 1170, height: 524 }}>
                <InfoCard {...infoCard_2} />
            </div>
            <div style={{ width: 1117, height: 524,marginLeft: 60 }}>
                <GaugeCard />
            </div>
            <div style={{ width: 470, height: 524,marginLeft: 60 }}>
                <PieCard />
            </div>
            <div style={{ width: 470, height: 524,marginLeft: 60 }}>
                <HzCard />
            </div>
        </div>
    );
};

export default Comp;
