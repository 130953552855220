import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';
import style from './DailyChart.module.scss';
import moment from 'moment';

const initChart = (refNode, datas) => {
    if (!!refNode) {
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!datas) {
            instance.clear()
            return
        }
        const { x_lable, y_curve, y_kWhz0, y_kWhz1, y_kWhz2, y_kWhz3 } = datas
        const xDatas = x_lable.map(t => {
            const mT = moment(t)
            if (mT.isValid()) {
                return mT.format("YYYY-MM-DD")
            }
            return '--'
        })

        instance.setOption({
            grid: {
                left: 101,
                right: 75,
                top: 10,
                bottom: 30,
            },
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(0,0,0,0.4)',
            },
            xAxis: [
                {
                    type: 'category',
                    data: xDatas,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,0.6)'
                        }
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    // show: false,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,0.6)'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    show: false,
                }
            ],
            series: [
                {
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: 'red'
                    },
                    data: y_kWhz3,
                }, {
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: '#FE8569'
                    },
                    data: y_kWhz2,
                }, {
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: '#FFC63C'
                    },
                    data: y_kWhz1,
                }, {
                    type: 'bar',
                    stack: '电量',
                    itemStyle: {
                        color: '#5BA3ED'
                    },
                    data: y_kWhz0,
                }, {
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        color: '#727272'
                    },
                    data: y_curve,
                }
            ]
        });
    }
};

const Comp = (props) => {
    const { hisDatas } = props;
    const chartNode = useRef(null);

    useEffect(() => {
        initChart(chartNode.current, hisDatas);
    }, [hisDatas]);
    return (
        <div className={style['DailyChart']}>
            <div className='chartNode' ref={chartNode}></div>
        </div>
    );
};

export default Comp;
