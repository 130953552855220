import { significantNum, roundNum } from 'common/utils';
import { Space, Checkbox } from 'antd';

const econIndexColumns = [
    {
        title: '相似配料数量',
        dataIndex: 'similar_feed',
        align: 'center',
        render: (text, record) => <span>{record.similar_feed ? record.similar_feed : '--'}</span>
    },
    {
        title: '本次排名',
        dataIndex: 'ranking',
        align: 'center',
        render: (text, record) => <span>{record.ranking ? record.ranking : '--'}</span>
    },
    {
        title: '最优成本配料编号',
        dataIndex: 'top_id":',
        align: 'center',
        render: (text, record) => <span>{record.top_id ? record.top_id : '--'}</span>
    },
    {
        title: '本次比最优',
        dataIndex: 'potential',
        align: 'center',
        render: (text, record) => <span>{record.potential ? record.potential : '--'}</span>
    }
];

const mainIndexColumns = [
    {
        title: '干吨入炉锰',
        dataIndex: 'MnPct_Mn_ad',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.MnPct_Mn_ad)}</span>
        </Space>,
    },
    {
        title: '实际入炉锰',
        dataIndex: 'MnPct_MnSi_ad',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.MnPct_MnSi_ad)}</span>
        </Space>,
    },
    {
        title: '干吨锰矿硅石入炉硅',
        dataIndex: 'SiPct_MnSi_ad',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.SiPct_MnSi_ad)}</span>
        </Space>,
    },
    {
        title: '焦炭/综合入炉料比',
        dataIndex: 'C_MnSi_R',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.C_MnSi_R)}</span>
        </Space>,
    },
    {
        title: '锰铁比',
        dataIndex: 'MnFe_R',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.MnFe_R)}</span>
        </Space>,
    },
    {
        title: '二氧化硅锰比',
        dataIndex: 'SiMn_R',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.SiMn_R)}</span>
        </Space>,
    },
    {
        title: '炭量与锰量比',
        dataIndex: 'CMn_R',
        align: 'center',
        render: (text, record) => <Space>
            <span>{typeof record.CMn_R === 'number' ? significantNum(record.CMn_R) : '--'}</span>
        </Space>
    },
    {
        title: '自然碱度',
        dataIndex: 'R3_Feed',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.R3_Feed)}</span>
        </Space>,
    },
    {
        title: '炉渣R2',
        dataIndex: 'R2_SLAG',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.R2_SLAG)}</span>
        </Space>,
    },
    {
        title: '炉渣R3',
        dataIndex: 'R3_SLAG',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.R3_SLAG)}</span>
        </Space>,
    }
];

const materialColumns = [
    {
        title: '名称',
        dataIndex: 'name',
        align: 'center',
        key: 'name'
    },
    {
        title: '实际料比',
        dataIndex: 'tot_weight',
        align: 'center',
        key: 'tot_weight'
    },
    {
        title: '干吨料比',
        dataIndex: 'tot_weight_dry',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.tot_weight_dry)}</span>
        </Space>,
    },
    {
        title: 'Mn',
        dataIndex: 'Mn',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.Mn)}</span>
        </Space>,
    },
    {
        title: 'Fe',
        dataIndex: 'Fe',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.Fe)}</span>
        </Space>,
    },
    {
        title: 'SiO2',
        dataIndex: 'SiO2',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.SiO2)}</span>
        </Space>,
    },
    {
        title: 'CaO',
        dataIndex: 'CaO',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.CaO)}</span>
        </Space>,
    },
    {
        title: 'MgO',
        dataIndex: 'MgO',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.MgO)}</span>
        </Space>,
    },
    {
        title: 'Al2O3',
        dataIndex: 'Al2O3',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.Al2O3)}</span>
        </Space>
    },
    {
        title: 'P',
        dataIndex: 'P',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.P)}</span>
        </Space>,
    },
    {
        title: 'C',
        dataIndex: 'C',
        align: 'center',
        render: (text, record) => <Space>
            <span>{significantNum(record.C)}</span>
        </Space>,
    },
    {
        title: '原料价格',
        dataIndex: 'unit_price',
        align: 'center',
        render: (text) => <Space>
            <span>{significantNum(text)}</span>
        </Space>,
    }
];


const getMaterialComColumns = (funcs) => {
    const {
        materialChange = () => { },
        editItem = () => { },
        moveItem = () => { },
        deleteItem = () => { },
        valueModify = () => { },
    } = (funcs ?? {});
    return [
        {
            title: '',
            dataIndex: 'is_use',
            align: 'center',
            render: (text, record, rowIndex) => <Space>
                <Checkbox onChange={() => materialChange(rowIndex, record.is_use, record.uuid)} checked={record.is_use === 1 ? true : false} />
            </Space>,
            width: 35,
        },
        {
            title: '矿种',
            dataIndex: 'type',
            align: 'center',
            width: 48,
            render: (text, record) => <Space>
                <span className="clickable-text" onClick={() => editItem(record)}>{text}</span>
            </Space>
        },
        {
            title: '矿名',
            dataIndex: 'matl_name',
            align: 'center',
            width: 96,
            render: (text, record) => <Space>
                <span className="clickable-text" onClick={() => editItem(record)}>{record.matl_name}</span>
            </Space>
        },
        {
            title: '原料',
            dataIndex: 'full_name',
            align: 'center',
            width: 304,
            render: (text, record) => <Space>
                <span className="clickable-text" onClick={() => editItem(record)}>{record.full_name}</span>
            </Space>
        },
        {
            title: '实际料比',
            dataIndex: 'weight',
            align: 'center',
            width: 76,
            render: (text, record) => <Space>
                <span className="clickable-text" onClick={valueModify("weight", record, "实际料比")}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            </Space>
        },
        {
            title: '水分',
            dataIndex: 'H2O',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("H2O", record, "水分")}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: '干吨料比',
            dataIndex: 'weight_dry',
            align: 'center',
            width: 76,
            render: (text) => (
                <span>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'Mn',
            dataIndex: 'Mn',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("Mn", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'Fe',
            dataIndex: 'Fe',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("Fe", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'SiO2',
            dataIndex: 'SiO2',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("SiO2", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'CaO',
            dataIndex: 'CaO',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("CaO", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'MgO',
            dataIndex: 'MgO',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("MgO", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'Al2O3',
            dataIndex: 'Al2O3',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("Al2O3", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'P',
            dataIndex: 'P',
            align: 'center',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("P", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: 'C',
            dataIndex: 'C',
            align: 'center',
            render: (text) => typeof text === 'number' ? significantNum(text) : '--',
            width: 62,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("C", record)}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: '吨度价格',
            dataIndex: 'grade_price',
            align: 'center',
            width: 76,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("grade_price", record, "吨度价格")}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: '单价',
            dataIndex: 'unit_price',
            align: 'center',
            width: 76,
            render: (text, record) => (
                <span className="clickable-text" onClick={valueModify("unit_price", record, "单价")}>{(typeof text === 'number' && !isNaN(text)) ? significantNum(text) : '--'}</span>
            ),
        },
        {
            title: '操作',
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => <Space>
                <a onClick={() => moveItem(record.uuid, 'UP')}>上移</a>
                <a onClick={() => moveItem(record.uuid, 'DOWN')}>下移</a>
                <a onClick={() => deleteItem(record.uuid)}>删除</a>
            </Space>,
            width: 130
        }
    ];
};


const calcId = (props) => {
    let parsedId = null;
    let id = props?.match?.params?.id ?? null;
    id = parseFloat(id);
    if (typeof id === 'number' && !isNaN(id)) {
        parsedId = id;
    };
    return parsedId;
};

const roundStrNum = (v, i) => {
    if (isNaN(parseFloat(v))) {
        return null;
    } else {
        return roundNum(v, i);
    }
};

const getStyles = (totalSiO2, refSiO2, totalDolomite, refDolomite, totalCoke, refCoke) => {
    const specialStyle = {
        fontWeight: 'bold',
        color: 'red',
    };
    const commonStyle = {};
    return [
        [totalSiO2, refSiO2],
        [totalDolomite, refDolomite],
        [totalCoke, refCoke],
    ]
        .map(([v1, v2]) => {
            if (
                (typeof v1 === 'number' && !isNaN(v1)) &&
                (typeof v2 === 'number' && !isNaN(v2))
            ) {
                // 进行判断
                if (v1 < v2) {
                    return specialStyle;
                };
            } else {
                // 存在不是数字的，无法判断
            };
            return commonStyle;
        });
};

export {
    econIndexColumns,
    mainIndexColumns,
    materialColumns,
    getMaterialComColumns,
    calcId,
    roundStrNum,
    getStyles,
};