import { useEffect, useState } from 'react'
import { get } from 'common/utils'

/** 获取最常用的模版列表 */
const Hook = () => {
  const [templateList, setTemplateList] = useState([])
  const [refreshFlag, setRefreshFlag] = useState(Symbol())
  /** 刷新常用模版列表 */
  const refreshFun = () => setRefreshFlag(Symbol())
  useEffect(() => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/commonlyTemplateSearch', {})
      .then(res => {
        if (res.data && res.data.error === 0) {
          setTemplateList(res.data.results || [])
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [refreshFlag])
  return [templateList, refreshFun]
}

export default Hook
