import React, { useState } from 'react'
import FilterSetting from './FilterSetting'
import CapsuleTable from './CapsuleTable'
import { useClassifyTagList } from '../hooks'
import style from './index.module.scss'

/** 事件汇总 */
const EventSummary = () => {
  const [tags] = useClassifyTagList([])
  const [filterParams, changeFilterParams] = useState(null) // 查询条件
  const [exportEvents, changeExportEvents] = useState([]) // 勾选的事件集合
  const [addAction, setAddAction] = useState() // 新增事件

  return (
    <div className={style['EventSummary']}>
      <FilterSetting
        tags={tags}
        exportEvents={exportEvents}
        setAddAction={setAddAction}
        changeFilterParams={changeFilterParams}
      />
      <CapsuleTable
        filterParams={filterParams}
        tags={tags}
        addAction={addAction}
        changeExportEvents={changeExportEvents}
      />
    </div>
  )
}
export default EventSummary
