import React from 'react';
import echarts from 'echarts';

import './ImproveChanceComp.scss';

const dataList = [
    {
        name: '熟料烧成',
        color: 'rgba(89, 94, 168, 1)',
        rate: 36,
        value: 56,
    },
    {
        name: '水泥粉磨',
        color: 'rgba(74, 155, 135, 1)',
        rate: 20,
        value: 31,
    },
    {
        name: '煤粉制备',
        color: 'rgba(177, 93, 101, 1)',
        rate: 16,
        value: 24,
    },
    {
        name: '原料粉磨',
        color: 'rgba(83, 146, 188, 1)',
        rate: 10,
        value: 15,
    },
    {
        name: '水泥包装',
        color: 'rgba(196, 158, 112, 1)',
        rate: 9,
        value: 7,
    },
    {
        name: '产品质量',
        color: 'rgba(49, 98, 164, 1)',
        rate: 9,
        value: 7,
    },
    // {
    //     name: '产品质量',
    //     color: 'rgba(49, 98, 164, 1)',
    //     rate: 9,
    //     value: 7,
    // },
    // {
    //     name: '产品质量',
    //     color: 'rgba(49, 98, 164, 1)',
    //     rate: 9,
    //     value: 7,
    // },
];

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.renderChart();
    }

    refFunc = refNode => {
        this.refNode = refNode;
    }

    renderChart = () => {
        if (!!this.refNode) {
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance) {
                instance = echarts.init(this.refNode);
            };
            // console.log('renderChart');
            instance.setOption({
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['85%', '100%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: dataList.map(({ value, name, color }) => {
                            return {
                                value,
                                name,
                                itemStyle: {
                                    color,
                                },
                            };
                        }),
                    },
                ],
            });
        };
    }

    render() {

        return (
            <div className="main_page_bottom_panels_improve_chance_comp_wrapper">
                <div className="left">
                    <div className="position_wrapper">
                        <div className="chart_wrapper" ref={this.refFunc} />
                        <div className="circle">
                            <div className="circle_content">
                                <div className="title">当日次数</div>
                                <div className="value">124</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right scroll_bar_restyle">
                    <div className="comp_content_wrapper">
                        {
                            dataList
                                .map((item, index) => {
                                    const { name, color, rate, value } = item;
                                    return (
                                        <div className="row_wrapper" key={index}>
                                            <div className="icon" style={{ backgroundColor: color }} />
                                            <div className="title">{name}</div>
                                            <div className="rate">{rate}</div>
                                            <div className="value">{value}</div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
