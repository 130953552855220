import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { DatePicker, Select, Space, Modal, Spin } from 'antd';
import { useData, createData } from './hooks';
import moment from 'moment';
import { blank_func, shifts, silosList, actions } from './utils';
import { withRouter } from 'react-router';

let start_time_str = "08:00:00";
let end_time_str = "20:00:00";
if (process.env.REACT_APP_PROJECT_TAG === "jinglei") {
    start_time_str = "07:30:00";
    end_time_str = "19:30:00";
};

const { Option } = Select;

const Comp = (props) => {
    const [furnaceNo] = useState(() => {
        let fNo = null;
        try {
            let id = props?.info?.id
            if (!isNaN(id)) {
                fNo = id;
            };
        } catch (e) { };
        if (typeof fNo !== 'number') {
            fNo = 1;
        };
        return fNo;
    });

    const [currTime] = useState(() => {
        const env_time = props?.info?.end_time ?? null;
        const default_time = (typeof env_time === 'string' && env_time !== "")
            ? (moment(env_time).format("YYYY-MM-DD ") + moment().format("HH:mm:ss"))
            : moment().format("YYYY-MM-DD HH:mm:ss");
        return moment(default_time);
    });

    const [time, setTime] = useState(() => {
        // 页面加载时，默认显示今天
        // 今天8:00之前算上一天
        if (currTime.isBefore(moment(moment(currTime).format(`YYYY-MM-DD ${start_time_str}`)))) {
            return moment(currTime).subtract(1, 'days');
        };
        return moment(currTime);
    });
    const [selectedShift, setSelectedShift] = useState(() => {
        // 今天8:00之前，20:00之后算夜班
        if (
            currTime.isBefore(moment(moment(currTime).format(`YYYY-MM-DD ${start_time_str}`)))
            ||
            currTime.isAfter(moment(moment(currTime).format(`YYYY-MM-DD ${end_time_str}`)))
        ) {
            return shifts?.[1] ?? null;
        };
        return shifts?.[0] ?? null;
    });

    const [data, setData, loading, statusInfo] = useData();
    const setDataFunc = useRef(blank_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : blank_func;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current({
            day: moment(time).format("YYYY-MM-DD"),
            shift_id: selectedShift?.shift_id ?? null,
            furnace_no: furnaceNo
        });
    }, []);

    const [newData, , createloading] = createData();
    const setCreateDataFunc = useRef(blank_func);

    useEffect(() => {
        // 判断如果是不存在的，提示新建
        if (!data) {
            if ((statusInfo?.code ?? null) === 404) {
                // 不存在
                Modal.confirm({
                    content: "该时段尚无表单",
                    onOk: () => {
                    },
                });
            };
        };
    }, [data, statusInfo]);

    const dateOnChange = (date) => {
        console.log(date);
        setTime(date);
        // setDataFunc.current({
        //     day: moment(time).format("YYYY-MM-DD")
        // })
        // setTimeout(()=>queryIngredInfo(), 0);
        setCreateDataFunc.current(null);
        setDataFunc.current({
            day: moment(date).format("YYYY-MM-DD"),
            shift_id: selectedShift?.shift_id ?? null,
            furnace_no: furnaceNo
        });
    };

    const selectOnChange = (v) => {
        const nextSelectedShift = (shifts ?? [])
            .filter(({ key }) => key === v)[0] ?? null;
        setSelectedShift(nextSelectedShift);
        // setDataFunc.current({
        //     shift_id: nextSelectedShift?.shift_id ?? null
        // })
        // setTimeout(()=>queryIngredInfo(), 0);
        setCreateDataFunc.current(null);
        setDataFunc.current({
            day: moment(time).format("YYYY-MM-DD"),
            shift_id: nextSelectedShift?.shift_id ?? null,
            furnace_no: furnaceNo
        });
    };

    const [displayData, setDisplayData] = useState(null);
    useEffect(() => {
        let nextDisplayData = newData ?? data ?? null;
        if (!!nextDisplayData) {
            const silos = nextDisplayData?.parsed_result?.parsed_content?.silos ?? [];
            let parsed_silos = null;
            if (!!silos) {
                parsed_silos = silosList
                    .map((item, index) => {
                        let nextItem = item;
                        const silo = silos.filter(({ silo_id }) => silo_id === index + 1)[0] ?? null;
                        nextItem = Object.assign({}, nextItem, {
                            silo,
                        });
                        return nextItem;
                    });
            };
            let parsed_opts = null;
            const opts = nextDisplayData?.parsed_result?.parsed_content?.opts ?? [];
            if (!!opts) {
                parsed_opts = opts
                    .map((item, index) => {
                        let nextItem = item;
                        const silos = item?.silos ?? [];
                        let parsed_silos = null;
                        if (!!silos) {
                            parsed_silos = silosList
                                .map((item, index) => {
                                    let nextItem = item;
                                    const silo = silos.filter(({ silo_id }) => silo_id === index + 1)[0] ?? null;
                                    nextItem = Object.assign({}, nextItem, {
                                        silo,
                                    });
                                    return nextItem;
                                });
                        };
                        nextItem = Object.assign({}, nextItem, { parsed_silos });
                        return nextItem;
                    });
            };
            nextDisplayData = Object.assign({}, nextDisplayData, {
                parsed_silos,
                parsed_opts,
            });
        };
        // console.log(nextDisplayData);
        setDisplayData(nextDisplayData);
    }, [data, newData]);

    const [reqLoading] = useState(false);

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <Space>
                    <DatePicker value={time} onChange={dateOnChange} allowClear={false} inputReadOnly={true} />
                    <Select
                        value={selectedShift?.key ?? null}
                        onChange={selectOnChange}
                        style={{ width: 120 }}
                        allowClear={false}
                    >
                        {
                            shifts
                                .map((item, index) => {
                                    return (
                                        <Option value={item.key} key={index}>{item.name}</Option>
                                    );
                                })
                        }
                    </Select>
                </Space>
            </div>
            <div className="bottom">
                <div className="inner_wrapper scroll_bar_restyle">
                    <table
                        style={{
                            width: 320 + 180 * (displayData?.parsed_opts ?? []).length + 100
                        }}
                    >
                        <thead>
                            <tr>
                                <th className="silo">料仓</th>
                                <th className="name">矿名</th>
                                {
                                    (displayData?.parsed_opts ?? [])
                                        .map((item, index) => {
                                            const { time, type } = item;
                                            const parsed_time = moment(time).format("MM-DD HH:mm");
                                            const typeName = actions.filter(({ value }) => value === type)[0]?.name ?? "--";
                                            if (index === 0) {
                                                return (
                                                    <th key={index} className="action">
                                                        <span>接班料比</span>
                                                    </th>
                                                );
                                            };
                                            return (
                                                <th key={index} className="action">
                                                    <span>{parsed_time} {typeName}</span>
                                                </th>
                                            );
                                        })
                                }
                                <th className="add">
                                </th>
                                <th className="weight">班总重量</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (displayData?.parsed_silos ?? [])
                                    .map((item, index, arr) => {
                                        let short_name = "";
                                        let output_weight = "";
                                        if (!!item.silo) {
                                            short_name = item.silo?.short_name ?? "--";
                                            output_weight = item.silo?.output_weight ?? "--";
                                        };
                                        // 这里确定矿名显示full_name还是name还是一起显示

                                        return (
                                            <tr key={index} className="data">
                                                <td>{index + 1}</td>
                                                <td
                                                    className="name"
                                                >
                                                    <div className="content">{short_name}</div>
                                                </td>
                                                {
                                                    (displayData?.parsed_opts ?? [])
                                                        .map((item_2, index_2) => {
                                                            const { type } = item_2;
                                                            return (
                                                                <td
                                                                    key={index_2}
                                                                    className={type === "EXTRA" ? "" : "modify"}
                                                                >
                                                                    {
                                                                        type === "ADJUST"
                                                                            ? item_2.parsed_silos[index]?.silo?.weight ?? ""
                                                                            : (
                                                                                type === "CHANGE"
                                                                                    ? (
                                                                                        !!item_2.parsed_silos[index]?.silo
                                                                                            ? (
                                                                                                <div>
                                                                                                    <div>{`已上料：${(item_2.parsed_silos[index]?.silo?.pre_weight ?? "--")}`}</div>
                                                                                                    <div>{`换料：${(item_2.parsed_silos[index]?.silo?.full_name ?? "--")}`}</div>
                                                                                                    <div>{`料比：${(item_2.parsed_silos[index]?.silo?.weight ?? "--")}`}</div>
                                                                                                </div>
                                                                                            )
                                                                                            : ""
                                                                                    )
                                                                                    : ""
                                                                            )
                                                                    }
                                                                </td>
                                                            );
                                                        })
                                                }
                                                {/* {
                                                    index === 0 && (
                                                        <td rowSpan={arr.length}></td>
                                                    )
                                                } */}
                                                <td></td>
                                                <td
                                                    className="weight"
                                                >{output_weight}</td>
                                            </tr>
                                        );
                                    })
                            }
                            <tr>
                                <td colSpan="2">附加</td>
                                {
                                    (displayData?.parsed_opts ?? [])
                                        .map((item, index) => {
                                            const { type, items } = item;
                                            if (type === "EXTRA") {
                                                return (
                                                    <td
                                                        key={index}
                                                        className="modify"
                                                    >
                                                        <div>
                                                            {
                                                                (items ?? [])
                                                                    .map((extraItem, extraIndex) => {
                                                                        const { full_name, weight } = extraItem;
                                                                        return (
                                                                            <div key={extraIndex}>{full_name} {weight ?? "--"}</div>
                                                                        );
                                                                    })
                                                            }
                                                        </div>
                                                    </td>
                                                );
                                            };
                                            return (
                                                <td key={index}></td>
                                            );
                                        })
                                }
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan="2">已上料批次</td>
                                {
                                    (displayData?.parsed_opts ?? [])
                                        .map((item, index) => {
                                            const { total_num } = item;
                                            return (
                                                <td
                                                    key={index}
                                                    {
                                                    ...index === 0 ? {} : {
                                                        className: "total_num",
                                                    }
                                                    }
                                                >
                                                    {total_num ?? "--"}批
                                                </td>
                                            );
                                        })
                                }
                                <td></td>
                                <td
                                    className="total_num"
                                >{displayData?.parsed_result?.parsed_content?.shift_num_total ?? "--"}批</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {
                (reqLoading || loading || createloading) && (
                    <div style={{ position: 'absolute', zIndex: 100, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin />
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Comp);
