import React from 'react';
import { Table } from 'antd';
import style from './RightViewer.module.scss';
import {
    InfoCircleOutlined,
} from '@ant-design/icons';
import DetailModal from '../ExamRuleConfig/DetailModal/DetailModal.js';
import { rightTableHeaderSortableThs, SCORE_METHODS, BOTTOM_CHART_HEIGHT } from './constants.js';
import { getTopChartOption, getFactorDetailChartOption } from './utils.js';
import ICON_FOLD from './res/fold.svg';

const echarts = require('echarts');
export default class RightViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortBy: null,//['weight', true]:[key, sortBigFirst]
            detailModalVisible: false,
            selectedFactor: null,
        };
    }

    componentDidMount() {
        this.renderChart();
    }

    componentDidUpdate({ rightContentWidth: prevRightContentWidth, userValueIndexPath: prevUserValueIndexPath }) {
        const { rightContentWidth, userValueIndexPath } = this.props;
        if (rightContentWidth !== prevRightContentWidth) {
            //页面宽度发生变化
            if (this.refNode) {
                const instance = echarts.getInstanceByDom(this.refNode);
                if (!!instance) {
                    instance.resize();
                };
            };
        };
        if (prevUserValueIndexPath !== userValueIndexPath) {
            this.setState({ sortBy: null });

            this.renderChart(true);
        };
    }

    renderChart = (forceUpdate = false) => {
        if (this.refNode) {
            let instance = echarts.getInstanceByDom(this.refNode);
            if (!instance || forceUpdate === true) {
                instance = echarts.init(this.refNode);
                const { dataSource } = this.props;
                // console.log(dataSource);
                instance.setOption(getTopChartOption(dataSource));
            };
        };
    }

    refFunc = refNode => {
        this.refNode = refNode;
        if (!!refNode) {
            // this.refNode = refNode;
            this.renderChart();
        };
    }

    chartRefFunc = (refNode, factor) => {
        const { tag_name } = factor;
        const { userValueIndexPath } = this.props;
        // console.log("chartRefFunc", tag_name, !!refNode, userValueIndexPath);
        const refNodeName = `chartRefNode-${userValueIndexPath}-${tag_name}`;
        this[refNodeName] = refNode;
        if (!!refNode) {
            // return;
            // 先出来时让刷新
            let instance = echarts.getInstanceByDom(refNode);
            if (!instance) {
                instance = echarts.init(refNode);
                instance.showLoading();
                //因为有刷新功能，所以获取过数据的可以直接展示出来
                const { hisTag2Values } = this.props;
                if (
                    false
                    // hisTag2Values[tag_name]
                ) {
                    this.renderBottomChart(refNodeName, hisTag2Values[tag_name], factor);
                } else {
                    const { getHisTagListData } = this.props;
                    const lockName = `${refNodeName}-lock`;
                    if (this[lockName] === true) {
                        //上次请求未完成，不重新请求
                        return;
                    };
                    this[lockName] = true;
                    getHisTagListData(tag_name)
                        .then(retData => {
                            const { isSuccess } = retData;
                            if (isSuccess === true) {
                                const { hisTag2Values } = this.props;
                                // console.log(isSuccess, hisTag2Values);
                                this.renderBottomChart(refNodeName, hisTag2Values[tag_name], factor);
                            } else {
                                //isSuccess === false
                                const { status } = retData;
                                if (status === 3) {
                                    //该用户无在班时间，显示“暂无数据”？
                                    const refNode = this[refNodeName];
                                    if (!!refNode) {
                                        let instance = echarts.getInstanceByDom(refNode);
                                        if (!instance) {
                                            instance = echarts.init(refNode);
                                        };
                                        instance.showLoading(
                                            'default',
                                            {
                                                text: '暂无数据',
                                                color: '#c23531',
                                                textColor: '#000',
                                                maskColor: 'rgba(255, 255, 255, 0.8)',
                                                zlevel: 0,

                                                // 字体大小。从 `v4.8.0` 开始支持。
                                                fontSize: 12,
                                                // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
                                                showSpinner: false,
                                                // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
                                                // spinnerRadius: 10,
                                                // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
                                                // lineWidth: 5
                                            },
                                        );
                                    };
                                };
                            };
                            this[lockName] = false;
                        });
                };
            };
        };
    }

    renderBottomChart = (refNodeName, hisTag2Value, factor) => {
        if (!hisTag2Value) {
            return;
        };
        const refNode = this[refNodeName];
        if (!!refNode) {
            let instance = echarts.getInstanceByDom(refNode);
            if (!instance) {
                instance = echarts.init(refNode);
            };
            instance.hideLoading();
            instance.setOption(getFactorDetailChartOption(hisTag2Value, factor));
        };
    }

    sortOnClick = key => {
        const { sortBy } = this.state;
        const newSortBy = [key, true];
        if (!!sortBy && sortBy[0] === key) {
            newSortBy[1] = !sortBy[1];
        };
        this.setState({ sortBy: newSortBy });
    }

    render() {
        const { rightContentWidth, dataSource, showFactorDetail, systems } = this.props;
        const { factorShowDetailOnClick } = this.props;
        const { sortBy, detailModalVisible, selectedFactor } = this.state;
        let topChartWrapperWidth = rightContentWidth - 48;
        if (topChartWrapperWidth < 0) {
            topChartWrapperWidth = 0;
        };
        const tableExpandChartWidth = topChartWrapperWidth - 60;
        let sortedDataSource = dataSource;
        if (!!sortBy) {
            const [key, sortBigFirst] = sortBy;
            sortedDataSource = []
                .concat(dataSource)
                .sort((data1, data2) => {
                    if (sortBigFirst) {
                        return data1[key] - data2[key];
                    } else {
                        return data2[key] - data1[key];
                    };
                });
        };
        const getSortableTh = ({ name, key }) => {
            return (
                <div className="th_block_wrapper">
                    <div
                        className="th_sort_wrapper"
                        onClick={() => this.sortOnClick(key)}
                    >
                        <div className="title">{name}</div>
                        <div className="sort_icons_wrapper">
                            <div className={"icon icon_up" + (!!sortBy && sortBy[0] === key && sortBy[1] ? " icon_up_selected" : "")} />
                            <div className={"icon icon_down" + (!!sortBy && sortBy[0] === key && !sortBy[1] ? " icon_down_selected" : "")} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={style["exam_analysis_right_viewer_wrapper"]}>
                {
                    detailModalVisible && selectedFactor && (
                        <DetailModal
                            factor={this.state.selectedFactor}
                            systems={systems}
                            editable={false}
                            onClose={refresh => {
                                this.setState({
                                    detailModalVisible: false,
                                    selectedFactor: null,
                                });
                            }}
                        />
                    )
                }
                {/* 右侧视图 */}
                <div className='title1'>竞赛操作分析</div>
                <div className='title2_wrapper'>
                    <div className="title">各指标得分比对</div>
                    <div className="line_colors_wrapper">
                        <div className="colored_line" style={{ backgroundColor: 'rgba(235,235,235,1)' }} />
                        <div className="text">标准分</div>
                        <div className="colored_line" style={{ backgroundColor: 'rgba(255, 188, 52, 1)' }} />
                        <div className="text">实际分</div>
                    </div>
                </div>
                <div className='viewer' style={{ width: topChartWrapperWidth }}>
                    <div className="top_chart" ref={this.refFunc} />
                </div>
                <div className="cus_table_wrapper">
                    <Table
                        className="cus_table_wrapper_in_board_table"
                        rowKey='factor_id'
                        dataSource={sortedDataSource}
                        columns={[
                            {
                                title: '指标名称',
                                key: 'name',
                                render: (text, { factorName }, index) => factorName,
                                className: 'name_cell',
                                width: 180,
                                onCell: ({ factor_id }, rowIndex) => {
                                    return {
                                        onClick: () => {
                                            if (!showFactorDetail.includes(factor_id)) {
                                                factorShowDetailOnClick(factor_id);
                                            };
                                        },
                                    }
                                },
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[0]),
                                key: 'weight',
                                render: (text, { weight }, index) => {
                                    const weightText = weight === null ? '--' : (weight + '%');
                                    return weightText;
                                },
                                align: 'center',
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[1]),
                                key: 'scoreMethod',
                                render: (text, factor, index) => {
                                    const { scoreMethod, factorInfo } = factor;
                                    let scoreMethodText = '--';
                                    const scoreMethodItem = SCORE_METHODS
                                        .filter(({ id }) => id === scoreMethod)[0];
                                    if (scoreMethodItem !== undefined) {
                                        scoreMethodText = scoreMethodItem.name;
                                    };
                                    return (
                                        <div className="score_method_text_wrapper">
                                            <div className="inner_block">
                                                <div>{scoreMethodText}</div>
                                                <InfoCircleOutlined
                                                    className="cus_icon"
                                                    onClick={() => {
                                                        // console.log(factorInfo);
                                                        this.setState({
                                                            selectedFactor: factorInfo,
                                                            detailModalVisible: true,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                },
                                align: 'center',
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[2]),
                                key: 'rank',
                                render: (text, { rank }, index) => {
                                    return rank + 1;
                                },
                                align: 'center',
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[3]),
                                key: 'rank_score',
                                render: (text, { rank_score }, index) => {
                                    return rank_score;
                                },
                                align: 'center',
                            },
                            {
                                title: () => getSortableTh(rightTableHeaderSortableThs[4]),
                                key: 'total_score',
                                render: (text, { total_score }, index) => {
                                    const totalScoreText = total_score === null ? '--' : Math.floor(total_score * 1000) / 1000;
                                    return totalScoreText;
                                },
                                align: 'center',
                            },
                        ]}
                        pagination={false}
                        scroll={{ y: true }}
                        rowClassName={({ factor_id }, index) => {
                            return (index % 2 === 1 ? "tr_dark_bck" : "") + (showFactorDetail.includes(factor_id) ? " expanded_factor_tr" : "");
                        }}
                        expandable={{
                            expandedRowRender: (factor) => {
                                // console.log("console log new node", tag_name);
                                return (
                                    <div className="chart_wrapper">
                                        <img
                                            alt=""
                                            src={ICON_FOLD}
                                            className="fold_icon"
                                            onClick={() => factorShowDetailOnClick()}
                                        />
                                        <div
                                            className="chart"
                                            ref={refNode => this.chartRefFunc(refNode, factor)}
                                            style={{
                                                width: tableExpandChartWidth,
                                                height: BOTTOM_CHART_HEIGHT,
                                            }}
                                        />
                                    </div>
                                )
                            },
                            rowExpandable: ({ factor_id }) => showFactorDetail.includes(factor_id),//
                            expandedRowKeys: showFactorDetail,
                            expandIconColumnIndex: -1,//antd4.5 support antd4.3 not support
                            // expandIcon: () => (<span />),
                        }}
                    />
                </div>
            </div>
        );
    }
}
