import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, product_id } = conf;
            if (typeof time !== 'string' || typeof product_id !== 'number') {
                setResult(null);
                setLoading(false);
                return;
            };
            let requesting = true;
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_factor_basic_statistics_list', {
                time_type, time, product_id,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data?.results;
                            if (!!nextResult) {
                                const all_pot_cost = nextResult.map(({ pot_cost }) => pot_cost).filter((v) => typeof v === 'number' && !isNaN(v));
                                const max_pot_cost = all_pot_cost.length > 0 ? Math.max(...all_pot_cost) : null;
                                nextResult = nextResult.map((item, index) => {
                                    const { unit, value, lower_limit, upper_limit, cpl_rate, pot_cost_pct, comp_pct, bias_pct, pot_cost } = item;
                                    const unit_text = typeof unit === 'string' ? unit : "";
                                    const avg_value_text = typeof value === 'number' ? significantNum(value, 4) : "--";
                                    let standard_text = "--";
                                    if (typeof lower_limit === 'number' && typeof upper_limit === 'number') {
                                        standard_text = significantNum(lower_limit, 4) + "-" + significantNum(upper_limit, 4);
                                    } else {
                                        if (typeof lower_limit === 'number') {
                                            standard_text = significantNum(lower_limit, 4);
                                        } else if (typeof upper_limit === 'number') {
                                            standard_text = significantNum(upper_limit, 4);
                                        };
                                    };
                                    const cpl_rate_text = (typeof cpl_rate === 'number' ? significantNum(cpl_rate * 100, 3, 2) : "--") + "%";
                                    const pot_cost_pct_text = (typeof pot_cost_pct === 'number' ? significantNum(pot_cost_pct * 100, 3, 2) : "--") + "%";
                                    const parsed_comp_pct = (typeof comp_pct === 'number' ? significantNum(Math.abs(comp_pct) * 100, 3, 2) : "--") + "%";
                                    const parsed_bias_pct = (typeof bias_pct === 'number' ? significantNum(Math.abs(bias_pct) * 100, 3, 2) : "--") + "%";
                                    const parsed_pot_cost = typeof pot_cost === 'number' ? significantNum(pot_cost, 4) : "--";

                                    let barWidth = null;
                                    if (typeof max_pot_cost === 'number') {
                                        if (typeof pot_cost === 'number' && !isNaN(pot_cost)) {
                                            if (max_pot_cost === 0) {
                                                barWidth = "0%";
                                            } else {
                                                let tmp = pot_cost / max_pot_cost;
                                                if (tmp < 0) tmp = 0;
                                                if (tmp > 1) tmp = 1;
                                                barWidth = (tmp * 100) + "%";
                                            };
                                        };
                                    };

                                    return Object.assign({}, item, {
                                        key: index,
                                        unit_text,
                                        avg_value_text,
                                        standard_text,
                                        cpl_rate_text,
                                        pot_cost_pct_text,
                                        parsed_comp_pct,
                                        parsed_bias_pct,
                                        parsed_pot_cost,
                                        barWidth,
                                    });
                                });
                            };
                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
