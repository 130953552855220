import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts~5';
import moment from 'moment';

const renderChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!data) {
            instance.clear();
            return;
        };

        const latestVal = data.slice(-1)[0]?.[1] ?? null;

        const xData = data.map(([time]) => time);
        const yData = data.map(([, value]) => {
            let isGood = null;
            if (typeof value === 'number') {
                if (typeof latestVal === 'number') {
                    if (value > latestVal) {
                        isGood = false;
                    } else if (value < latestVal) {
                        isGood = true;
                    };
                };
            };
            return {
                value,
                ...(
                    typeof isGood === 'boolean' ? {
                        itemStyle: {
                            color: isGood ? '#3AA372' : '#FB6060',
                        },
                    } : {}
                )
            };
        });

        instance.setOption({
            grid: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 2,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [{ axisValueLabel, data: { value }, marker }] = params;
                    const parsed_value = typeof value === 'number' ? Math.floor(value * 1000) / 1000 : "--";
                    return `${moment(axisValueLabel).format("YYYY-MM-DD")}<br />${marker}${parsed_value}`;
                },
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                show: false,
                scale: true,// true时不一定显示0
            },
            series: [
                {
                    data: yData,
                    type: 'bar',
                    barWidth: 8,
                    itemStyle: {
                        color: '#355B99',
                        borderRadius: 3,
                    },
                    markLine: {
                        silent: true,
                        symbol: 'none',
                        lineStyle: {
                            type: "dashed",
                            color: "#355B99",
                        },
                        label: {
                            show: false,
                        },
                        data: []
                            .concat(
                                typeof latestVal === 'number' ? [
                                    {
                                        yAxis: latestVal,
                                    }
                                ] : [],
                            ),
                    },
                },
            ]
        });
    };
};

const Comp = (props) => {
    const { history_values, } = props;

    const refNode = useRef(null);
    useEffect(() => {
        if (!!refNode.current) {
            renderChart(refNode.current, history_values);
        };
    }, [history_values]);

    return (
        <div className={style["wrapper"]}>
            <div className="chart" ref={refNode}></div>
        </div>
    );
};

export default Comp;
