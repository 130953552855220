import React from "react";
import PagePanel from '../../Monitor'

const ProcessPage = () => {
    return (
        <div style={{height: `100%`}}>
            <PagePanel value={{comp: null,diagramId:"3q5hN7c_Sm6gRlBRbHyjpg"}} controlled={true}/>
        </div>
    )
}
export default ProcessPage
