import React, { useRef, useEffect, useState } from 'react';
import style from './index.module.scss';
import Wrapper from '../Wrapper';
import { Table } from 'antd';
import { useData } from './hooks';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';
import { withRouter } from 'react-router';

const Comp = (props) => {
    const { title, icon, timeInfo, columns, path } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        if (!!timeInfo) {
            setDataFunc.current(Object.assign({}, timeInfo, { path }));
        };
    }, [timeInfo, path]);

    const [table_y, set_table_y] = useState(null);
    const onResize = (conf) => {
        set_table_y(conf.height - 12 * 2 - 40);
    };

    const watchDetailOnClick = () => {
        // 这里table是复用的，后续应该根据conf决定跳转地址
        let suffix = "";
        if (!!timeInfo) {
            suffix += `?time=${timeInfo.time}&timeKey=${timeInfo.key}`;
        };
        const pathname = props?.history?.location?.pathname ?? null;
        if (typeof pathname === 'string' && pathname !== "") {
            const layoutName = pathname.split("/")[1] ?? null;
            if (typeof layoutName === 'string' && layoutName !== "") {
                props.history.push(`/${layoutName}/FactorStatistics?${suffix}`);
                return;
            };
        };
        props.history.push(`/PEIMSV2/FactorStatistics${suffix}`);
    };

    const valueItemOnClick = (sys_id, category) => {
        let suffix = `?sysId=${sys_id}&category=${category}`;
        if (!!timeInfo) {
            suffix += `&time=${timeInfo.time}&timeKey=${timeInfo.key}`;
        };
        const pathname = props?.history?.location?.pathname ?? null;
        if (typeof pathname === 'string' && pathname !== "") {
            const layoutName = pathname.split("/")[1] ?? null;
            if (typeof layoutName === 'string' && layoutName !== "") {
                props.history.push(`/${layoutName}/FactorStatistics?${suffix}`);
                return;
            };
        };
        props.history.push(`/PEIMSV2/FactorStatistics${suffix}`);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={icon}
                title={title}
                watchDetail={true}
                watchDetailOnClick={watchDetailOnClick}
            >
                <div className={style['content']}>
                    <div className="resize">
                        <DomResize onResize={onResize} mountedCall={true} debounce_wait={20} />
                    </div>
                    <div className="table_wrapper">
                        {
                            typeof table_y === 'number' && (
                                <Table
                                    columns={(!!columns ? columns : [])
                                        .map((item, index) => {
                                            const { render } = item;
                                            return Object.assign({}, item, typeof render === 'function' ? {
                                                render: (text, record) => {
                                                    const parsed_text = render(text);
                                                    // console.log(item, record);
                                                    return (
                                                        <div
                                                            key={index}
                                                            style={
                                                                Object.assign(
                                                                    {},
                                                                    { cursor: 'pointer' },
                                                                    (typeof text === 'number' && text < 0.75) ? {
                                                                        color: "#FB6060",
                                                                    } : {}
                                                                )
                                                            }
                                                            onClick={() => valueItemOnClick(record.sys_id, item.category)}
                                                        >{parsed_text}</div>
                                                    );
                                                },
                                            } : {});
                                        })}
                                    dataSource={data ?? []}
                                    pagination={false}
                                    // bordered={true}
                                    size="small"
                                    scroll={{ y: table_y }}
                                />
                            )
                        }
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Comp);
