import * as echarts from 'echarts~5';

const colors = [
    "rgba(64, 160, 119, 1)",
    "rgba(251, 152, 49, 1)",
    "rgba(55, 195, 215, 1)",
    "rgba(244, 90, 102, 1)",
];

const mockData = new Array(24)
    .fill(null)
    .map(() => {
        const [val_1, val_2, val_3, val_4] = new Array(4).fill(null).map(() => 10 + Math.ceil(Math.random() * 100));
        return { val_1, val_2, val_3, val_4 };
    });

const renderChartBar = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!data) {
            instance.clear();
            return;
        };
        const xData = data
            .map((item, index) => `${index}时`);
        const data_1 = data
            .map(({ val_1: value }) => ({ value }));
        const data_2 = data
            .map(({ val_2: value }) => ({ value }));
        const data_3 = data
            .map(({ val_3: value }) => ({ value }));
        const data_4 = data
            .map(({ val_4: value }) => ({ value }));
        instance.setOption({
            grid: {
                containLabel: true,
                left: 12,
                top: 15,
                right: 52,
                bottom: 33,
            },
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    color: 'rgba(77, 193, 215, 1)',
                    fontSize: 18,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: "rgba(5, 78, 138, 1)",
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(5, 78, 138, 1)",
                    },
                },
            },
            series: [
                {
                    data: data_1,
                },
                {
                    data: data_2,
                },
                {
                    data: data_3,
                },
                {
                    data: data_4,
                },
            ]
                .map((item, index) => {
                    return Object.assign(
                        {},
                        {
                            type: 'bar',
                            barWidth: 10,
                            stack: '1',
                            itemStyle: {
                                color: colors[index % colors.length],
                                borderRadius: 6,
                            },
                        },
                        item,
                    );
                }),
        });
    };
};


const renderChartPie = (chartNode, value) => {
    if (!!chartNode) {
        // render chart
        let instance = echarts.getInstanceByDom(chartNode);
        if (!instance) {
            instance = echarts.init(chartNode);
        };

        // 计算value内的value的和，为0的话显示灰圈
        const sum = (value ?? [])
            .reduce((pV, cV) => {
                const value = cV;
                if (typeof value === 'number' && !isNaN(value)) {
                    pV += value;
                };
                return pV;
            }, 0);

        instance.setOption({
            tooltip: {
                trigger: 'item',
                // formatter: (params) => {
                //     if (sum === 0) {
                //         return "暂无数据";
                //     };
                //     const { dataIndex, data: { value: value_1 }, marker } = params;
                //     const parsed_value = typeof value_1 === 'number' ? significantNum(value_1) : "--";
                //     return `${value[dataIndex].name ?? ""}<br />${marker}${parsed_value}`;
                // },
            },
            grid: {
                // containLabel: true,
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '80%'],
                    data: sum === 0 ? (
                        [
                            {
                                value: 0,
                                itemStyle: {
                                    color: 'grey',
                                }
                            }
                        ]
                    ) : (
                        (value ?? [])
                            .map((item, index) => {
                                return {
                                    value: item ?? 0,
                                    itemStyle: {
                                        color: colors[index % colors.length],
                                    },
                                };
                            })
                    ),
                    label: {
                        show: false,
                    },
                    emphasis: {
                        scale: false,
                    },
                }
            ]
        });
    };
};


export {
    colors,
    renderChartBar,
    renderChartPie,
    mockData,
};