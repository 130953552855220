import React from 'react';
// import { useState } from 'react';

import ICON_3 from '../res/single_3.png';
import Wrapper from '../../Common/Wrapper.js';
import { getAdviceRankBckColor } from '../utils.js';

import './AdviceBlockModal.scss';

const Comp = (props) => {
    const { onCancel = () => { }, improvements } = props;
    // const [dataSource, setDataSource] = useState([
    //     {
    //         name: '二次风温度二次风温度',
    //         chance: 100,
    //         advice: '降低二次风温度二次风温度',
    //     },
    //     {
    //         name: '一次篦下温度',
    //         chance: 90,
    //         advice: '降低一次篦下温度',
    //     },
    //     {
    //         name: '三次风温度',
    //         chance: 80,
    //         advice: '降低三次风温度',
    //     },
    //     ...(
    //         new Array(7)
    //             .fill(0)
    //             .map(() => ({
    //                 name: '预热器出口温度',
    //                 chance: 70,
    //                 advice: '降低预热器出口温度',
    //             }))
    //     ),
    // ]);
    return (
        <div style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, zIndex: 1000 }} className="aFnAtivod">
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 1)', opacity: 0.7 }} onClick={onCancel} />
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: 1280 }}>
                <Wrapper icon={ICON_3} title="改进建议" onCancel={onCancel}>
                    <div className="wrapper_pos_block">
                        <div className="table_wrapper scroll_bar_restyle">
                            <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 0' }}>
                                <thead>
                                    <tr style={{ color: '#9FA0BA' }}>
                                        <th style={{ padding: "16px 0" }}>排名</th>
                                        <th>影响因子名称</th>
                                        <th>改善机会</th>
                                        <th>当前值</th>
                                        <th>基准值</th>
                                        <th style={{ textAlign: "center" }}>红绿灯</th>
                                        <th>当前KPR</th>
                                        <th>目标KPR</th>
                                        <th>比较</th>
                                        <th style={{ width: 240 }} >改善建议</th>
                                        {/* <th style={{ width: 120 }} /> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        improvements
                                            .map((item, index) => {
                                                const { factor_name, improve_num, lower_limit, upper_limit, avg_value, unit, cpl_rate, cpl_rate_diff, exp_cpl_rate } = item;
                                                // const { name, chance, advice } = item;
                                                const textStyle = { fontSize: 12, lineHeight: '14px', color: '#FFFFFF' };

                                                let advice = (<span>-</span>);
                                                if (typeof avg_value === "number" && ([typeof lower_limit, typeof upper_limit].includes("number"))) {
                                                    if (typeof lower_limit === "number" && typeof upper_limit === "number") {
                                                        advice = (<div>保持参数在<span style={{ color: '#B376FF' }}>{Math.floor(lower_limit * 10) / 10}{unit}-{Math.floor(upper_limit * 10) / 10}{unit}</span>之间</div>);
                                                    } else if (typeof lower_limit === "number") {
                                                        advice = (<div><span style={{ color: '#B376FF' }}>提高</span>参数，保持在<span style={{ color: '#B376FF' }}>{Math.floor(lower_limit * 10) / 10}{unit}</span>以上</div>);
                                                    } else if (typeof upper_limit === "number") {
                                                        advice = (<div><span style={{ color: '#B376FF' }}>降低</span>参数，保持在<span style={{ color: '#B376FF' }}>{Math.floor(upper_limit * 10) / 10}{unit}</span>以下</div>);
                                                    };
                                                };

                                                let redGreen = null;
                                                let weighted_avg_format = '-';
                                                if (typeof avg_value === "number") {
                                                    weighted_avg_format = Math.floor(avg_value * 10) / 10;
                                                };
                                                let standardV = '-';
                                                if (typeof lower_limit === "number") {
                                                    if (typeof avg_value === "number") {
                                                        redGreen = lower_limit > avg_value;
                                                    };
                                                    standardV = lower_limit;
                                                } else if (typeof upper_limit === "number") {
                                                    if (typeof avg_value === "number") {
                                                        redGreen = upper_limit < avg_value;
                                                    };
                                                    standardV = upper_limit;
                                                };
                                                if (typeof standardV === "number") {
                                                    standardV = Math.floor(standardV * 10) / 10;
                                                };

                                                const signStyle = { width: 12, height: 12, borderRadius: '50%', backgroundColor: redGreen === null ? "transparent" : (redGreen ? "#FF6170" : "#44F3DA"), margin: '0 auto' };

                                                let phiWarning = false;
                                                let rateText = '-';
                                                if (typeof parseFloat(cpl_rate) === "number") {
                                                    rateText = Math.floor(parseFloat(cpl_rate) * 1000) / 10;
                                                };
                                                let expCplRate = '-';
                                                if (typeof exp_cpl_rate === "number") {
                                                    expCplRate = Math.floor(exp_cpl_rate * 1000) / 10;
                                                };
                                                let rateDelta = '-';
                                                if (typeof cpl_rate_diff === "number") {
                                                    rateDelta = Math.floor(cpl_rate_diff * 1000) / 10;
                                                    phiWarning = cpl_rate_diff < 0;
                                                };

                                                return (
                                                    <tr key={index}>
                                                        <td style={{ padding: "16px 0" }}>
                                                            <div style={{
                                                                width: 16, height: 16, borderRadius: "50%",
                                                                backgroundColor: getAdviceRankBckColor(index + 1),
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                fontSize: 12, color: '#FFFFFF',
                                                            }}>{index + 1}</div>
                                                        </td>
                                                        <td style={textStyle}>{factor_name}</td>
                                                        <td style={textStyle}>{improve_num} 次</td>
                                                        <td>{weighted_avg_format}</td>
                                                        <td>{standardV}</td>
                                                        <td>
                                                            <div style={signStyle} />
                                                        </td>
                                                        <td style={phiWarning === true ? { color: '#FF6170' } : {}}>{rateText}%</td>
                                                        <td>{expCplRate}%</td>
                                                        <td style={phiWarning === true ? { color: '#FF6170' } : {}}>{rateDelta}%</td>
                                                        <td style={textStyle}>{advice}</td>
                                                        {/* <td>
                                                        <div style={{ marginLeft: 'auto', marginRight: 4, display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}>
                                                            <img alt="" src={ICON_ADD} width="16" height="16" />
                                                            <div style={{ marginLeft: 2, color: '#3DCDB9', fontSize: 14, height: 14, lineHeight: '14px' }}>加入考核计划</div>
                                                        </div>
                                                    </td> */}
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Wrapper>
            </div>
        </div>
    );
};

export default Comp;
