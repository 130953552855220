import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, range, sys_id } = conf;
            if (typeof time !== 'string') {
                setResult(null);
                setLoading(false);
                return;
            };
            let requesting = true;
            setLoading(true);
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_cost_list', {
                time_type, time: time_start, sys_id,
            })
                .then((retData) => {
                    if (requesting) {
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data?.results;
                            if (!!nextResult) {
                                const all_pot_cost = nextResult
                                    .map(({ pot_cost }) => pot_cost)
                                    .filter((v) => typeof v === 'number' && !isNaN(v));
                                let max_pot_cost = null;
                                if (all_pot_cost.length > 0) {
                                    max_pot_cost = Math.max(...all_pot_cost);
                                };
                                nextResult = nextResult.map((item, index) => {
                                    const { cost, opt_cost, pot_cost } = item;
                                    const parsed_cost = typeof cost === 'number' ? significantNum(cost, 4) : "--";
                                    const parsed_opt_cost = typeof opt_cost === 'number' ? significantNum(opt_cost, 4) : "--";
                                    const parsed_pot_cost = typeof pot_cost === 'number' ? significantNum(pot_cost, 4) : "--";
                                    let barWidth = null;
                                    if (typeof max_pot_cost === 'number') {
                                        if (typeof pot_cost === 'number' && !isNaN(pot_cost)) {
                                            if (max_pot_cost === 0) {
                                                barWidth = "0%";
                                            } else {
                                                let tmp = pot_cost / max_pot_cost;
                                                if (tmp < 0) tmp = 0;
                                                if (tmp > 1) tmp = 1;
                                                barWidth = (tmp * 100) + "%";
                                            };
                                        };
                                    };
                                    return Object.assign({}, item, {
                                        key: index,
                                        timeInfo: conf,
                                        parsed_cost,
                                        parsed_opt_cost,
                                        parsed_pot_cost,
                                        barWidth,
                                    });
                                });
                                const tag_list = nextResult
                                    .map(({ history_tag }) => {
                                        const { period } = conf;
                                        const time_end = time_start;
                                        const time_begin = moment(time_end).subtract(period[0], period[1]).startOf(range).format("YYYY-MM-DD 00:00:00");
                                        return {
                                            tag_name: history_tag,
                                            time_type,
                                            time_begin,
                                            time_end,
                                        };
                                    });
                                if (tag_list.length > 0) {
                                    // fetch data
                                    post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/avg/get_avg_datas', { tag_list })
                                        .then((retData) => {
                                            if (requesting) {
                                                setLoading(false);
                                                // console.log(retData);
                                                if (!!retData.data && retData.data.errcode === 0) {
                                                    const tag2DataList = retData.data.results
                                                        .reduce((pV, cV) => {
                                                            return Object.assign({}, pV, { [cV.tag_name]: cV.data_list });
                                                        }, {});
                                                    nextResult = nextResult
                                                        .map((item) => {
                                                            const data_list = tag2DataList?.[item.history_tag] ?? [];
                                                            return Object.assign({}, item, { data_list });
                                                        });
                                                };
                                                // console.log(nextResult);
                                                setResult(nextResult);
                                            };
                                        });
                                    return;
                                };
                                setLoading(false);
                                setResult(nextResult);
                                return;//其他都算请求失败了
                            };
                        };
                        setResult(null);
                        setLoading(false);
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
