import React from 'react';

import FactorItem from './FactorItem.js';

import './RightTable.scss';
import TITLE_ICON from './res/rightTableTitleIcon.png';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            factorList: [],
        };
    }

    componentDidMount() {
        this.setState({
            factorList: new Array(10)
                .fill(0)
                .map((_, index) => {
                    return {
                        name: '生料台时' + index,
                        warning: Math.random() > 0.5,
                    };
                }),
        });
    }

    render() {
        const { factorList } = this.state;

        return (
            <div className="main_page_right_table_wrapper">
                <div className="title_wrapper">
                    <img alt="" src={TITLE_ICON} className="title_icon" />
                    <div className="text">综合指标</div>
                </div>
                <div className="table_aLine" />
                <div className="table_content_wrapper">
                    <div className="content_left_block scroll_bar_restyle">
                        <div className="items_content_wrapper">
                            {
                                factorList
                                    .map((item, index) => {
                                        const retNodes = [];
                                        if (index > 0) {
                                            retNodes.push(
                                                <div key={`aLine_${index}`} className="item_top_line" />
                                            );
                                        };
                                        retNodes.push(
                                            <div key={`item_${index}`} className="item_self_wrapper">
                                                <FactorItem factor={item} />
                                            </div>
                                        );
                                        return retNodes;
                                    })
                            }
                        </div>
                    </div>
                    <div className="content_right_block">
                        {
                            factorList
                                .map((item, index) => {
                                    const { warning } = item;
                                    return (
                                        <div
                                            key={index}
                                            className={"status_bar" + (warning ? ' warning_bar' : ' normal_bar')}
                                        />
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
