import React from 'react'
import './tabPanel.scss'
import DeviceTabLineChart from './DeviceTabLineChart'

const TabPanel = (props) => {
    const {
        typeName,
        totalEfficiencyPercent,
        totalEfficiencyComparison,
        totalEfficiencyFirstRate,
        totalEfficiencySecondRate,
        totalEfficiencyThirdRate,
        totalPowerPercent,
        totalPowerComparison,
        totalPowerFirst,
        totalPowerSecond,
        totalPowerThird,
        electricSingle,
        electricSingleComparison,
        electricSingleWeek,
        electricSingleMonth,
        electricSingleYear,
        useElectricTotal,
        useElectricComparison,
        useElectricWeek,
        useElectricMonth,
        useElectricYear,
        dryGasTotal,
        dryGasComparison,
        deviceTabChart,
        deviceTabTime
    } = props;
    return (
        <div>
            <div className="tabPanel" style={{display: typeName=== '总效率'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{totalEfficiencyPercent}</span>
                        <span style={{fontSize: 22}}> %</span>
                    </div>
                    <div className="tabPanel-data-percent">{totalEfficiencyComparison}%</div>
                    <div className="tabPanel-data-progress">
                        <div className="tabPanel-data-progress-num" style={{width: `${totalEfficiencyPercent}`}}></div>
                        <div className="tabPanel-data-progress-text">标准</div>
                        <div className="tabPanel-data-progress-line"></div>
                        <div className="tabPanel-data-progress-number">79 %</div>
                    </div>
                </div>
                <div className="tabPanel-text">
                    <span>1段效率 {totalEfficiencyFirstRate} %</span>
                    <span>2段效率 {totalEfficiencySecondRate} %</span>
                    <span>34段效率 {totalEfficiencyThirdRate} %</span>
                </div>
            </div>
            <div style={{display: typeName=== '总功耗'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        {totalPowerPercent}
                        <span style={{fontSize: 22}}> kW</span>
                    </div>
                    <div className="tabPanel-data-percent">{totalPowerComparison}%</div>
                    <DeviceTabLineChart deviceTabChart={deviceTabChart} deviceTabTime={deviceTabTime}/>
                </div>
                <div className="tabPanel-text">
                    <span>再生加热循环 {totalPowerFirst} kW</span>
                    <span>齿轮箱损失 {totalPowerSecond} kW </span>
                    <span>驱动主电机 {totalPowerThird} kW</span>
                </div>
            </div>
            <div style={{display: typeName=== '电单耗'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{electricSingle} </span>
                        <span style={{fontSize: 22}}> kWh/万Nm³</span></div>
                    <div className="tabPanel-data-percent">{electricSingleComparison}%</div>
                    <DeviceTabLineChart deviceTabChart={deviceTabChart} deviceTabTime={deviceTabTime}/>
                </div>
                <div className="tabPanel-text">
                    <span>周平均 {electricSingleWeek} kWh/万Nm³</span>
                    <span>月平均 {electricSingleMonth} kWh/万Nm³</span>
                    <span>年平均 {electricSingleYear} kWh/万Nm³</span>
                </div>
            </div>
            <div style={{display: typeName=== '用电量'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{useElectricTotal}</span>
                        <span style={{fontSize: 22}}> 万kWh</span></div>
                    <div className="tabPanel-data-percent">{useElectricComparison}%</div>
                    <DeviceTabLineChart deviceTabChart={deviceTabChart} deviceTabTime={deviceTabTime}/>
                </div>
                <div className="tabPanel-text">
                    <span>周累计 {useElectricWeek} 万kWh</span>
                    <span>月累计 {useElectricMonth} 万kWh</span>
                    <span>年累计 {useElectricYear} 万kWh</span>
                </div>
            </div>
            <div style={{display: typeName=== '干气密封放空'? 'block': 'none'}}>
                <div className="tabPanel-data">
                    <div className="tabPanel-data-big">
                        <span>{dryGasTotal}</span>
                        <span style={{fontSize: 22}}> Nm³/h</span>
                    </div>
                    <div className="tabPanel-data-percent">{dryGasComparison}%</div>
                    <DeviceTabLineChart deviceTabChart={deviceTabChart} deviceTabTime={deviceTabTime}/>
                </div>
                <div className="tabPanel-text"></div>
            </div>
        </div>

    )
}
export default TabPanel
