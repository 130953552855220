import React from 'react';
import style from './index.module.scss';

const Page = (props) => {
    const { icon, title } = props;

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <img src={icon} alt="" className="icon" />
                <div className="title">{title}</div>
            </div>
            <div className="bottom">
                {props.children}
            </div>
        </div>
    );
};

export default Page;
