import React from 'react'
import Content from './Content'
import style from './RaceSetting.module.scss'

export default class RaceSetting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { customStyle } = this.props
    return (
      <div className={style['race_setting_wrapper']}>
        <Content
          id={this.props.id}
          gotoExamResult={this.props.gotoExamResult}
          customStyle={customStyle ? { paddingLeft: 0, paddingRight: 0 } : {}}
        />
      </div>
    )
  }
}
