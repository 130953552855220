import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts';
import moment from 'moment';
import { Spin } from 'antd';

const renderChart = (refNode, datas = []) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        const option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: 3,
                top: 11,
                right: 3,
                bottom: 0,
            },
            xAxis: {
                type: 'time',
                show: false,
            },
            yAxis: {
                type: 'value',
                scale: true,// true时不一定显示0
                show: false,
            },
            series: [
                {
                    data: (datas)
                        .filter(([t, v]) => v !== null)
                        .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                    type: 'line',
                    itemStyle: {
                        color: '#00676B',
                    },
                    symbol: 'none',
                    lineStyle: {
                        width: 1,
                    },
                },
            ],
        };

        instance.setOption(option);
    };
};

const Comp = (props) => {
    const { info } = props;
    const chartNode = useRef(null);
    const [loading] = useState(false);
    useEffect(() => {
        const xs = info?.xs ?? []
        const ys = info?.ys ?? []
        // console.log(xs, ys)
        let data_list = []
        if (xs.length === ys.length) {
            data_list = xs.map((val, ind) => [val, ys[ind]])
            renderChart(chartNode.current, data_list);
        }
    }, [info])

    return (
        <div className={style['wrapper']}>
            <div className="title_row">
                <div className="title">{props?.info?.name ?? '--'}</div>
            </div>
            <div className="value_row_1">
                <div className="value">{props?.info?.pValue ?? '--'}</div>
                <div className="unit">{props?.info?.unit ?? ''}</div>
            </div>
            <div className="chart_block">
                <div style={{ height: 35 }} ref={chartNode} />
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
