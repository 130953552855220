import React, { useMemo } from 'react'
import style from './index.module.scss'

const getValueText = (v, precision = 2) => {
    if (typeof v === 'number') {
        const multiNum = Math.pow(10, precision);
        return Math.floor(v * multiNum) / multiNum;
    };
    return '--';
};

const Comp = (props) => {
    const { name, unit, standard, values, precisions } = props;
    const valueTextList = useMemo(() => {
        const actualValues = !!values ? values : [];
        const actualPrecisions = !!precisions ? precisions : [];
        return new Array(4).fill(null).map((v, index) => getValueText(actualValues[index], typeof actualPrecisions[index] === 'number' ? actualPrecisions[index] : (index === 0 ? 3 : 1)))
    }, [values, precisions]);
    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="header">
                    <div className="title">{name}</div>
                    {
                        typeof standard === 'number' && (
                            <div className="standard_wrapper">
                                <div className="title">基准</div>
                                <div className="value">{getValueText(standard, 0)}%</div>
                            </div>
                        )
                    }
                </div>
                <div className="body">
                    <div className="tip_wrapper">
                        <div className="text">日</div>
                    </div>
                    <div className="value">{valueTextList[1]}</div>
                    {
                        typeof unit === 'string' && (
                            <div className="unit">{unit}</div>
                        )
                    }
                    <div className="arrow_down"></div>
                </div>
                <div className="foot">
                    <div className="value">{valueTextList[2]}</div>
                    <div className="unit">周</div>
                    <div className="value" style={{ marginLeft: 16 }}>{valueTextList[3]}</div>
                    <div className="unit">月</div>
                </div>
            </div>
        </div>
    )
}

export default Comp
