import React from 'react';
import { Input, Button, message, Select, DatePicker } from 'antd';
import { get, post } from 'common/utils.js';
import style from './CementAnalysisBlock.module.scss';
import moment from 'moment';
import { DeleteFilled, PlusCircleTwoTone } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

const BasicFactors = [
    {
        "tag_name": "SNM_fCONSUM",
        "name": "电单耗",
        "unit": "kwh/t"
    },
    {
        "tag_name": "LFEEDR_TOL",
        "name": "台时",
        "unit": "t/h"
    },
];
const Default_Selected_Basic_Factor = BasicFactors[0].tag_name;
const CalcStatus = {
    1: '正在计算',
    2: '计算完成',
    3: '计算失败',
};
class CementAnalysisBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeRange: null,
            display_factors: [],
            isAddingNewFactor: false,
            dataMetaList: [],
            dataMetaId: null,
            prop_factors: [],
            range: '',
            selectedCat: props.selectedCat,
            selectedOption: Default_Selected_Basic_Factor,
            run_second: null,
            factor_group: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.updateBasicFactors();
    }

    componentDidUpdate({ selectedCat: prevSelectedCat, dataMeta: prevDataMeta }) {
        const { selectedCat, dataMeta } = this.props;
        if (prevSelectedCat !== selectedCat) {
            this.setState({ selectedCat });
        };
        if (prevDataMeta !== dataMeta) {
            this.updateBasicFactors();
        };
    }

    componentWillUnmount() {
        this.mySetTimeout && clearTimeout(this.mySetTimeout);
    }

    updateBasicFactors = () => {
        const { dataMeta, dataMetaList } = this.props;
        const selectedDataMeta = dataMetaList.filter(({ id }) => id == dataMeta)[0];
        if (!!selectedDataMeta) {
            this.setState({ prop_factors: [selectedDataMeta] })
        };
    }

    startCalc = () => {
        const { timeRange, range } = this.state;
        if (timeRange === null) {
            message.error('请填写分析起止时间');
            return;
        };
        if (range === '') {
            message.error('请填写统计区域');
            return;
        };
        if (timeRange[1].isAfter(moment())) {//考虑50的情况
            message.error('结束时间不晚于当前时间');
            return;
        };
        if (moment(timeRange[0]).add(3, 'months').isBefore(timeRange[1])) {
            message.error('分析起止时间总时长不大于三个月');
            return;
        };
        this.postCalc();
    }

    postCalc = () => {
        // console.log('postCalc');
        const { dataMeta, dataMetaList } = this.props;
        const selectedDataMeta = dataMetaList.filter(({ id }) => id == dataMeta)[0];
        const { display_factors, timeRange, range } = this.state;
        const [timeBegin, timeEnd] = timeRange;
        this.setState({ loading: true });
        post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/snm_analysis/create', {
            // async_task: false,
            async_task: true,
            details: {
                time_begin: timeBegin.format('YYYY-MM-DD HH:mm:00'),
                time_end: timeEnd.format('YYYY-MM-DD HH:mm:50'),
                range,
                basic_factors: [].concat(BasicFactors),
                factors: []
                    .concat(
                        (!selectedDataMeta ? [] : [selectedDataMeta])
                            .map(({ tag_name, name }) => ({ tag_name, name })),
                    )
                    .concat(display_factors),
            },
        })
            .then(retData => {
                // console.log('postCalc', retData);
                if (retData.data && retData.data.errcode === 0) {
                    this.getCalcData(retData.data._id);
                } else {
                    message.error('计算请求失败!');
                    this.setState({ loading: false });
                };
            });
    }

    getCalcData = (_id) => {
        this.mySetTimeout && clearTimeout(this.mySetTimeout);
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/snm_analysis/get_one', {
            _id,
        })
            .then(retData => {
                if (retData.data && retData.data.errcode === 0) {
                    // console.log(retData.data.result.status, 'getCalcData', retData);
                    if (retData.data.result.status === 1) {
                        setTimeout(() => {
                            this.getCalcData(_id);
                        }, 5000);
                        return;
                    } else if (retData.data.result.status === 2) {
                        //计算完成
                        message.success(CalcStatus[2]);
                        const { factor_group, run_second } = retData.data.result.result;
                        this.setState({ run_second, factor_group });
                    } else if (retData.data.result.status === 3) {
                        //计算失败
                        message.error(CalcStatus[3]);
                    } else {
                        //未知状态
                        message.error('状态未配置，计算失败');
                    };
                    this.setState({ loading: false });
                } else {
                    message.error('网络错误！正在自动重连');
                    setTimeout(() => {
                        this.getCalcData(_id);
                    }, 5000);
                };
            });
    }

    getDataMetas = keyword => {
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/data_metas', {
            ...!keyword ? {} : { keyword },
            status: 1,
            page: 1,
            page_size: 10,
            sys_id: 2,//hardcode
            module_name: 'EXAM_RULE_FACTOR'
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                this.setState({ dataMetaList: retData.data.results })
            };
        });
    }

    addNewFactorOnchange = dataMetaId => {
        const { dataMetaList } = this.state;
        let { display_factors } = this.state;
        const selectedDataMeta = dataMetaList
            .filter(({ id }) => id == dataMetaId)[0];
        if (selectedDataMeta !== undefined) {
            const { tag_name, name } = selectedDataMeta;
            if (
                display_factors
                    .filter(({ tag_name: tagName }) => tagName === tag_name)
                    .length === 0
            ) {
                display_factors = [
                    ...display_factors,
                    { tag_name, name },
                ];
            };
        };
        this.setState({
            isAddingNewFactor: false,
            display_factors,
        });
    }

    addFactorBtn = () => {
        this.setState({
            isAddingNewFactor: true,
            dataMetaId: null,
            dataMetaList: [],
        }, this.getDataMetas);
    }

    deleteFactor = index => {
        const { display_factors } = this.state;
        this.setState({
            display_factors: display_factors
                .filter((_, f_index) => f_index !== index),
        });
    }

    rangeInputOnChange = v => {
        let range = parseInt(v);
        if (isNaN(range)) {
            range = '';
        } else {
            if (range < 0) range = 0;
            if (range > 100) range = 100;
        };
        this.setState({ range });
    }

    render() {
        const { examCats } = this.props;
        const { loading, factor_group, run_second, prop_factors, selectedOption, timeRange, display_factors, isAddingNewFactor, dataMetaList, dataMetaId, range, selectedCat } = this.state;
        let current_factors = [];
        if (!!factor_group) {
            current_factors = (factor_group
                .filter(({ tag_name }) => tag_name === selectedOption)[0] || {})
                .factors || [];
        };
        let totalWorkingTime = null;
        if (!!run_second) {
            if (examCats.filter(({ cat_name }) => run_second[cat_name] !== undefined).length === examCats.length) {
                totalWorkingTime = examCats.reduce((total, { cat_name }) => total += run_second[cat_name], 0) / 3600;
            };
        };
        const totalRangeTime = timeRange === null ? null : (moment(timeRange[1]).add(10, 'seconds').unix() - moment(timeRange[0]).unix()) / 3600;
        return (
            <div className={style["exam_rule_config_cement_analysis"]}>
                <div className="root_title">历史数据分析</div>
                <div className="analysis_content_wrapper">
                    <div className="top_setting_bar">
                        <RangePicker
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY/MM/DD HH:mm'
                            value={timeRange}
                            onChange={timeRange => this.setState({ timeRange })}
                        />
                        <div className="info_bar_content">
                            <div>运行时长/总时长</div>
                            <div>
                                {
                                    totalWorkingTime === null ? '--' : Math.floor(totalWorkingTime * 10) / 10
                                }小时/{
                                    totalRangeTime === null ? '--' : Math.floor(totalRangeTime * 10) / 10
                                }小时</div>
                        </div>
                        <div className="select_bar_wrapper">
                            <div className="title">基准指标</div>
                            <Select
                                style={{ width: 100 }}
                                value={selectedOption}
                                onChange={selectedOption => this.setState({ selectedOption })}
                            >
                                {
                                    BasicFactors.map(({ tag_name, name }, index) => (
                                        <Option value={tag_name} key={`${tag_name}_${index}`}>{name}</Option>
                                    ))
                                }
                            </Select>
                            <div className="title">统计区域</div>
                            <Input
                                style={{ width: 60 }}
                                value={range}
                                onChange={e => this.rangeInputOnChange(e.target.value)}
                            />
                            <div style={{ marginLeft: 5 }}>%</div>
                        </div>
                        <div className="cus_btn_wrapper">
                            <Button
                                type="primary"
                                danger
                                style={{ marginLeft: 'auto' }}
                                onClick={this.startCalc}
                                loading={loading}
                            >{!!loading ? '计算中...' : '开始计算'}</Button>
                        </div>
                    </div>
                    <div className="table_wrapper">
                        <div className="bar_wrapper">
                            <div className="title">水泥品种</div>
                            <Select
                                value={selectedCat}
                                onChange={selectedCat => this.setState({ selectedCat })}
                                style={{ width: 100 }}
                            >
                                {
                                    examCats
                                        .map(({ name, cat_name }, index) => (
                                            <Option value={index} key={index}>{name}</Option>
                                        ))
                                }
                            </Select>
                        </div>
                        <div className="info_bar_content">
                            <div className="flex_wrapper">
                                <div>运行时长</div>
                                <div>{
                                    !run_second ? '--' : Math.floor(run_second[examCats[selectedCat].cat_name] / 3600 * 10) / 10
                                }小时</div>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th />
                                    <th>参数名称</th>
                                    <th>均值</th>
                                    <th>推荐范围</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    BasicFactors
                                        .concat(prop_factors)
                                        .concat(display_factors)
                                        .map(({ tag_name, name, unit }, index) => {
                                            const current_factor = current_factors
                                                .filter(({ tag_name: tagName }) => tagName === tag_name)[0] || {};
                                            // console.log(current_factor);
                                            const catName = examCats[selectedCat].cat_name;
                                            return (
                                                <tr key={index}>
                                                    <td />
                                                    <td className="name_td">
                                                        <span>{name}</span> {unit}</td>
                                                    <td key={`${index}_avg`}>
                                                        {
                                                            typeof current_factor[`${catName}_avg`] === 'number' && (
                                                                Math.floor(current_factor[`${catName}_avg`] * 100) / 100
                                                            )
                                                        }
                                                    </td>
                                                    <td key={`${index}_rec`}>
                                                        {
                                                            (
                                                                typeof current_factor[`${catName}_rec`] === 'object' &&
                                                                typeof current_factor[`${catName}_rec`][0] === 'number' &&
                                                                typeof current_factor[`${catName}_rec`][1] === 'number'
                                                            ) ?
                                                                (
                                                                    Math.floor(current_factor[`${catName}_rec`][0] * 100) / 100
                                                                    + '~' +
                                                                    Math.floor(current_factor[`${catName}_rec`][1] * 100) / 100
                                                                ) : (
                                                                    ''
                                                                )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            index >= (BasicFactors.length + prop_factors.length) && (
                                                                <DeleteFilled onClick={() => this.deleteFactor(index - (BasicFactors.length + prop_factors.length))} />
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                }
                                {
                                    isAddingNewFactor && (
                                        <tr>
                                            <td />
                                            <td className="name_td">
                                                <Select
                                                    style={{ width: 150 }}
                                                    value={dataMetaId}
                                                    onChange={this.addNewFactorOnchange}
                                                    showSearch
                                                    onSearch={this.getDataMetas}
                                                    filterOption={false}
                                                    showArrow={false}
                                                    defaultActiveFirstOption={false}
                                                >
                                                    {
                                                        dataMetaList.map(({ id, name, tag_name }) =>
                                                            <Option
                                                                key={id}
                                                                title={name + ' ' + tag_name}
                                                            >{name} {tag_name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </td>
                                            <td colSpan="2" />
                                            <td>
                                                <DeleteFilled onClick={() => this.setState({ isAddingNewFactor: false })} />
                                            </td>
                                        </tr>
                                    )
                                }
                                <tr>
                                    <td ><PlusCircleTwoTone onClick={this.addFactorBtn} /></td>
                                    <td colSpan="4" />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CementAnalysisBlock;