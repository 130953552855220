import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts~5';
import { significantNum } from 'common/utils';

const renderChart = (refNode) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        // if (!data) {
        //     instance.clear();
        //     return;
        // };
        // instance.resize();
        // const data = [];

        const yData = [4, 6, 1, 8, 9];

        const allValues = yData
            // .map(([, value]) => value)
            .filter((v) => typeof v === 'number');
        let y_min = 0;
        let y_max = 1;
        if (allValues.length > 0) {
            const min = Math.min(...allValues);
            const max = Math.max(...allValues);
            let delta = max - min;
            if (delta === 0) {
                delta += 1;
            };
            // 使delta>0
            const multi = Math.pow(10, 2);
            y_min = Math.floor((min - delta / 10) * multi) / multi;
            y_max = Math.ceil((max + delta / 10) * multi) / multi;
            if (min >= 0 && y_min < 0) {
                y_min = 0;
            };
            if (max <= 0 && y_max > 0) {
                y_max = 0;
            };
        };

        const option = {
            tooltip: {
                trigger: 'axis',
                // formatter: (params) => {
                //     const [{ axisValueLabel, data: [, value], marker }] = params;
                //     const parsed_value = typeof value === 'number' ? significantNum(value, 4) : "--";
                //     return `${axisValueLabel}<br />${marker}${parsed_value}`;
                // },
            },
            grid: {
                show: true,
                backgroundColor: "rgba(53, 91, 153, 0.04)",
                borderWidth: 0,
                containLabel: true,
                left: 16,
                top: 14,
                right: 9,
                bottom: 20,
            },
            xAxis: {
                // type: 'time',
                type: 'category',
                data: ["1", "2", "3", "4", "5"],
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: 'rgba(153, 153, 153, 1)',
                    // formatter: '{HH}:{mm}\n{MM}/{dd}',
                    showMinLabel: true,
                    showMaxLabel: true,
                },
            },
            yAxis: {
                type: 'value',
                min: y_min,
                max: y_max,
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    color: 'rgba(153, 153, 153, 1)',
                },
                splitLine: {
                    show: false,
                },
            },
            series: [
                {
                    data: yData,
                    type: 'line',
                    itemStyle: {
                        color: '#355B99',
                    },
                    symbol: 'none',
                    lineStyle: {
                        width: 2,
                    },
                    markLine: {
                        symbol: "none",
                        precision: 4,
                        lineStyle: {
                            type: "dashed",
                            color: "#FB6060",
                        },
                        label: {
                            show: false,
                        },
                        data: []
                            .concat(
                                [],
                            ),
                    },
                    markArea: {
                        data: []
                            .concat(
                                [],
                            ),
                    },
                },
            ],
        };

        instance.setOption(option);
    };
};

const Comp = (props) => {
    const { info } = props;

    const refNode = useRef(null);
    useEffect(() => {
        if (!!refNode.current) {
            renderChart(refNode.current);
        };
    }, [info]);

    return (
        <div className={style['wrapper']}>
            <div className="chart" ref={refNode}></div>
        </div>
    );
};

export default Comp;
