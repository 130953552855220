import React from "react";
import ReactDOM from "react-dom";
import { get, post } from '../../../common/utils.js';
import { Card, Layout, Form,Breadcrumb, Button, Input, Spin, message, Modal} from 'antd';
import SearchSelectModal from '../../../components/SearchSelectModal/SearchSelectModal.js';
import "./RuleDetails.scss";

export default class RuleDetails extends React.Component {
    constructor(props){
      super(props);
      this.state={
          loading: false,
          ruleId: props.match.params.id,
          ruleName: '',
          alarmModalVisible: false,
          userModalVisible: false,
          user_list: [],
          meta_list: [],
      }
    }

    saveRule(){
        let self = this;
        let { ruleName, ruleId, user_list, meta_list } = this.state;
        if(ruleName == ""){
            message.warn("请输入规则名称");
            return;
        }

        let user_id_list = user_list.map(item=>item.user_id);
        let meta_id_list = meta_list.map(item=>item.meta_id);
        self.setState({loading: true});
        if(ruleId == -1){
            //新建
            post(process.env.REACT_APP_SERVER_PATH + 'api/alarm_push/rules/create_one',{
                "name": ruleName,
                "meta_id_list": meta_id_list,
                "user_id_list": user_id_list,
            }).then((retData)=>{
                self.setState({loading: false});
                if(retData?.data?.errcode === 0){
                    message.success("新建成功");
                    self.setState({
                        ruleId: retData.data.rule_id
                    },()=>{
                        self.props.history.replace("/PEIMSV2/AlarmPushRuleDetails/" + retData.data.rule_id);
                    })
                }else{
                    message.error("新建失败");
                }
            });
        }else{
            // 修改
            post(process.env.REACT_APP_SERVER_PATH + 'api/alarm_push/rules/update_one/' + ruleId,{
                "name": ruleName,
                "meta_id_list": meta_id_list,
                "user_id_list": user_id_list,
            }).then((retData)=>{
                self.setState({loading: false});
                if(retData?.data?.errcode === 0){
                    message.success("修改成功");
                }else{
                    message.error("新建失败");
                }
            });
        }
    }

    componentWillMount(){
        let self = this;
        if(self.state.ruleId != -1){
            self.getData();
        }
    }

    getData(){
        let self = this;
        self.setState({ loading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/alarm_push/rules/get_one/'+self.state.ruleId).then((retData)=>{
            self.setState({ loading: false });
            if(retData.data && retData.data.result){
                let r = retData.data.result;
                self.setState({
                    ruleName: r.name,
                    user_list: r.user_list,
                    meta_list: r.meta_list,
                });
            }
        });
    }

    removeAlarm = (alarmItem) => {
        let self = this;
        let { meta_list } = this.state;
        let index = meta_list.findIndex(e=>e.meta_id==alarmItem.meta_id);
        if(index < 0){
            return;
        }
        Modal.confirm({
            content: `确认删除告警【${alarmItem.meta_tag}】吗?`,
            onOk: () => {
                meta_list.splice(index, 1);
                self.forceUpdate();
            },
        });
    }

    removeUser = (userItem) => {
        let self = this;
        let { user_list } = this.state;
        let index = user_list.findIndex(e=>e.user_id==userItem.user_id);
        if(index < 0){
            return;
        }
        Modal.confirm({
            content: `确认删除接收人【${userItem.user_name}】吗?`,
            onOk: () => {
                user_list.splice(index, 1);
                self.forceUpdate();
            },
        });
    }

    render() {
        let self = this;
        let { ruleName, user_list, meta_list, alarmModalVisible, userModalVisible } = this.state;
        return (
            <Layout className="AlarmPushRuleDetails layout-container">
                <Spin spinning={self.state.loading}>
                    <Layout className="layout-second">
                        <div className="ruleBasicInfo">
                            <span>规则名称：</span>
                            <Input className="ruleNameInfo" value={ruleName} onChange={(e)=>self.setState({ruleName:e.target.value})}/>
                        </div>
                        <div className="ruleDetailInfo">
                            {
                                alarmModalVisible &&
                                <SearchSelectModal
                                    title="选择告警"
                                    searchApi={process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_metas/list'}
                                    labelKey="tag_name"
                                    valueKey="id"
                                    onOk={data=>{
                                        self.setState({alarmModalVisible: false})
                                        meta_list.push({
                                            "meta_id": data.id,
                                            "meta_tag": data.tag_name,
                                        });
                                        self.forceUpdate();
                                    }}
                                    onCancel={_=>this.setState({alarmModalVisible: false})}
                                />
                            }
                            <Card
                                style={{ width: "800px", height: '400px'}}
                                title="报警"
                                className="alertCard"
                                extra={
                                    <a onClick={()=>self.setState({alarmModalVisible: true})}>添加报警</a>
                                }
                            >
                                <div style={{height:"343px",overflowY: "scroll", paddingTop: '5px'}}>
                                {
                                    self.state.meta_list && this.state.meta_list.map((meta)=>
                                        <div className="inBlockLine">
                                            <span>{meta.meta_tag}</span>
                                            <span
                                                className="delBtn"
                                                onClick={()=>this.removeAlarm(meta)}
                                            >移除</span>
                                        </div>
                                    )}
                                </div>
                            </Card>

                            {
                                userModalVisible &&
                                <SearchSelectModal
                                    title="选择接收人"
                                    searchApi={process.env.REACT_APP_SERVER_PATH + 'api/users/get_list'}
                                    labelKey="name"
                                    valueKey="id"
                                    onOk={data=>{
                                        self.setState({userModalVisible: false})
                                        user_list.push({
                                            "user_id": data.id,
                                            "user_name": data.name,
                                        });
                                        self.forceUpdate();
                                    }}
                                    onCancel={_=>this.setState({userModalVisible: false})}
                                />
                            }
                            <Card
                                style={{ width: "800px",marginTop:"26px" }}
                                title="接收人"
                                extra={<a onClick={()=>self.setState({userModalVisible: true})}>添加人员</a>}
                            >
                                <div style={{height:"200px",overflowY: "scroll"}}>
                                {
                                    user_list.map((user)=>
                                    <div style={{
                                        display: "flex",
                                        flexFlow: "row",
                                        justifyContent: "space-between",
                                        width: "600px",
                                        marginBottom: "10px"
                                    }}>
                                        <span style={{width:"100px"}}>{user.user_name}</span>
                                        <a onClick={()=>this.removeUser(user)}>移除</a>
                                    </div>
                                )}
                                </div>
                            </Card>
                        </div>

                        <div className="ruleDetailOpt">
                            <Button type="primary" className="submitOpt" onClick={_=>self.saveRule()}>保存</Button>
                            <Button onClick={
                            ()=>{self.props.history.push("/PEIMSV2/AlarmPushRuleList");}
                            } style={{marginLeft:"20px"}}>返回</Button>
                        </div>
                    </Layout>
                </Spin>
            </Layout>
        );
    }
}
