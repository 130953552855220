import * as echarts from 'echarts~5';
// import moment from 'moment';

const renderChart = (info) => {
    // const { count, start_time, end_time } = info
    return (chartNode, data, nodeObj) => {
        if (!!chartNode) {
            // render chart
            let instance = echarts.getInstanceByDom(chartNode);
            if (!instance) {
                instance = echarts.init(chartNode);
            };
            if (data) {
                const { data_list } = data
                const yVals = data_list.map(([val, time]) => [time, val])

                instance.setOption({
                    grid: {
                        left: 72,
                        top: 24,
                        right: 12,
                        bottom: 12,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            lineStyle: {
                                color: 'rgba(64, 161, 225, 1)',
                                type: [10, 0]
                            }
                        },
                        // formatter: (param) => {
                        //     const [{ axisValueLabel, marker, value }] = param
                        //     let title = axisValueLabel
                        //     if (moment(value[0]).isSameOrAfter(moment(start_time)) && moment(value[0]).isSameOrBefore(moment(end_time))) {
                        //         title += ` 出炉：${count}`
                        //     }
                        //     return `${title}<br/>${marker}${value[1]}`
                        // }
                    },
                    xAxis: {
                        type: 'time',
                        boundaryGap: false,
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        scale: true,// true时不一定显示0
                    },
                    series: [
                        {
                            data: yVals,
                            type: 'line',
                            itemStyle: {
                                color: 'rgba(64, 161, 225, 1)',
                            },
                            symbol: 'none'
                        },
                    ],
                });
            } else {
                instance.clear()
            }
            const ins = Object.keys(nodeObj).map(key => nodeObj[key]).filter(n => n).map(n => echarts.getInstanceByDom(n)).filter(ins => ins)
            echarts.connect(ins)

        };
    }
};

export {
    renderChart
}