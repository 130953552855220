import React from 'react';

import style from './ChartBlock.module.scss';

const echarts = require('echarts');
const moment = require('moment');

export default class ChartBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        //是否首次需要看看
    }

    componentDidUpdate({ chartValues: prevChartValues, contentItemWidth: prevContentItemWidth }) {
        const { chartValues, contentItemWidth } = this.props;
        // console.log(prevHisTag2Value, hisTag2Value, prevHisTag2Value === hisTag2Value);
        // console.log(prevContentItemWidth !== contentItemWidth, prevContentItemWidth, contentItemWidth);
        //针对于hisTag2Value每个单独的图，单独判断每个tagName所属的value
        if (!!chartValues && prevChartValues !== chartValues) {
            // console.log('diff prevChartValues');
            const refName = 'chartNode';
            if (!!this[refName]) {
                let instance = echarts.getInstanceByDom(this[refName]);
                if (!instance) {
                    instance = echarts.init(this[refName]);
                };
                const values = (chartValues || [])
                    .map(([time, value]) => [time * 1000, value]);
                //这里后续需要判断时间首尾
                const yData = values
                    .map(([_, value]) => value);
                const [min, max] = [Math.min(...yData), Math.max(...yData)];
                let yMin = min - (max - min) / 9;
                let yMax = max + (max - min) / 9;
                if (yMin === yMax) {
                    yMin--;
                    yMax++;
                };
                instance.hideLoading();
                instance
                    .setOption({
                        grid: {
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                        },
                        xAxis: {
                            type: 'time',
                            show: false,
                            boundaryGap: false,
                            // data: xData,
                        },
                        yAxis: [{
                            show: false,
                            // name: '能耗指标',
                            min: yMin,
                            max: yMax,
                        }],
                        tooltip: {
                            trigger: 'axis',
                            formatter: (params) => {
                                // console.log(params);
                                return params
                                    .map(({ axisValue, marker, data }, index) => {
                                        let value = data[1];
                                        if (value !== null && !isNaN(value)) {
                                            value = Math.floor(value * 1000) / 1000;
                                        } else {
                                            value = '暂无数据';
                                        }
                                        return (
                                            `${index === 0 ? (
                                                moment.unix(Math.floor(axisValue / 1000)).format('YYYY-MM-DD HH:mm')
                                                // axisValue
                                            ) : ''}<br/>${marker}${value}`
                                        );
                                    })
                                    .join('');
                            },
                        },
                        series: [{
                            data: values,
                            type: 'line',
                            symbol: 'none',
                            lineStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: 'rgba(255, 0, 13, 1)' },
                                        { offset: 1, color: 'rgba(0, 132, 255, 1)' },
                                    ],
                                ),
                                width: 1,
                            },
                            areaStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: 'rgba(255, 0, 13, 1)' },
                                        { offset: 1, color: 'rgba(0, 132, 255, 1)' },
                                    ],
                                ),
                                opacity: 0.12,
                                origin: 'start',
                            },
                            // itemStyle: {
                            //     color: legend[0].color,
                            // }
                        }],
                    });
            };
        };
        if (prevContentItemWidth !== contentItemWidth) {
            //resize自适应
            // console.log(prevContentItemWidth !== contentItemWidth, prevContentItemWidth, contentItemWidth);
            if (this.chartNode) {
                const instance = echarts.getInstanceByDom(this.chartNode);
                if (!!instance) {
                    instance.resize();
                };
            };
        };
    }

    nodeRef = (node) => {
        if (node) {
            this.chartNode = node;
            const instance = echarts.init(node);
            instance.showLoading('default', {
                text: 'loading...',
                color: '#528ef7',
                textColor: '#666666',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                zlevel: 0,
                // 字体大小。从 `v4.8.0` 开始支持。
                fontSize: 16,
                // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
                showSpinner: true,
                // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
                spinnerRadius: 7,
                // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
                lineWidth: 2,
            });
        };
    }

    render() {
        const { } = this.state;
        const { } = this.props;
        return (
            <div
                className={style["sample_maintenance_chart_block_wrapper"]}
                ref={this.nodeRef}
            >
            </div>
        );
    }
}
