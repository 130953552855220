import React from 'react';
import { useEffect } from 'react';
import moment from 'moment';

const ColoredBar = (props) => {
    const { timeBegin, timeEnd, coloredLines } = props;
    useEffect(() => {
    });
    const totalSeconds = moment(timeEnd).unix() - moment(timeBegin).unix();
    // console.log(totalSeconds);
    return (
        <div
            style={{
                height: 20,
                backgroundColor: 'transparent',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            {
                coloredLines
                    .map((item, index, arr) => {
                        const [endTime, status] = item;
                        let startTime = 0;
                        if (index > 0) {
                            startTime = arr[index - 1][0];
                        };
                        const left = `${startTime / totalSeconds * 100}%`;
                        const right = `${(totalSeconds - endTime) / totalSeconds * 100}%`;
                        let backgroundColor = 'transparent';
                        if (status === 1) {
                            backgroundColor = 'rgba(64, 161, 225, 1)';
                        } else if (status === 0) {
                            backgroundColor = 'rgba(255, 97, 112, 1)'
                        } else if (status === -1) {
                            backgroundColor = 'rgba(220,220,220,0.1)';
                        };
                        return (
                            <div
                                key={index}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left,
                                    right,
                                    backgroundColor,
                                }}
                            />
                        );
                    })
            }
        </div>
    );
};

export default ColoredBar;
