import React from 'react'
import './index.scss'
const RulerBar = props => {
  const { rate } = props
  const pointerPosition = Math.ceil((88 * (100 - rate)) / 100)
  return (
    <div className="rulerBar">
      <div className="ruler left" />
      <div className="rulerBar-bar"></div>
      <div className="ruler right" />
      <div className="pointer" style={{ top: pointerPosition + 'px' }}>
        <i className="pointer-left" />
        <i className="pointer-right" />
      </div>
    </div>
  )
}
export default RulerBar
