import React from 'react';

import ICON_PRODUCE_STATISTICS from './res/produce_statistics.png';

import './ProduceStatistics.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="produce_statistics_wrapper scroll_bar_restyle">
                <div className="item_header_wrapper">
                    <img alt="" src={ICON_PRODUCE_STATISTICS} className="item_header_icon" />
                    <div className="title">生产统计</div>
                </div>
                <div className="item_content_wrapper">
                    <div className="inner_content_wrapper">
                        <div className="left scroll_bar_restyle">
                            <table className="">
                                <thead>
                                    <tr>
                                        <th>产品类型</th>
                                        <th>累计产量(t)</th>
                                        <th>计划产量(t)</th>
                                        <th>生产完成度(%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        new Array(10).fill(0)
                                            .map((item, index) => {
                                                const nameYellow = Math.random() > 0.5;
                                                const redBar = Math.random() > 0.8;
                                                const rate = Math.ceil(70 + Math.random() * 30);
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className={"name_block" + (nameYellow ? " name_block_yellow" : "")}>普通生料</div>
                                                        </td>
                                                        <td>203458</td>
                                                        <td>312348</td>
                                                        <td className="bar_td">
                                                            <div className={"bar_block" + (redBar ? " redBar" : " greenBar")}>
                                                                <div className="bar_content">
                                                                    <div className="bar" style={{ width: `${rate}%` }} />
                                                                </div>
                                                                <div className="text">{rate}%</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="divideLine"></div>
                        <div className="right scroll_bar_restyle">
                            <div className="table_wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>平均产量(t/h)</th>
                                            <th>计划产能(t/h）</th>
                                            <th>产能负荷率(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            new Array(10).fill(0)
                                                .map((item, index) => {
                                                    const redBar = Math.random() > 0.8;
                                                    const rate = Math.ceil(70 + Math.random() * 30);
                                                    return (
                                                        <tr key={index}>
                                                            <td>230.33</td>
                                                            <td>240.32</td>
                                                            <td className="bar_td">
                                                                <div className={"bar_block" + (redBar ? " redBar" : " greenBar")}>
                                                                    <div className="bar_content">
                                                                        <div className="bar" style={{ width: `${rate}%` }} />
                                                                    </div>
                                                                    <div className="text">{rate}%</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="divideLine"></div>
                        <div className="right scroll_bar_restyle">
                            <div className="table_wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>生产时长(h)</th>
                                            <th>改善机会(次)</th>
                                            <th>生产PHI(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            new Array(10).fill(0)
                                                .map((item, index) => {
                                                    const redBar = Math.random() > 0.8;
                                                    const rate = Math.ceil(70 + Math.random() * 30);
                                                    return (
                                                        <tr key={index}>
                                                            <td>230.33</td>
                                                            <td>240.32</td>
                                                            <td className="bar_td">
                                                                <div className={"bar_block" + (redBar ? " redBar" : " greenBar")}>
                                                                    <div className="bar_content">
                                                                        <div className="bar" style={{ width: `${rate}%` }} />
                                                                    </div>
                                                                    <div className="text">{rate}%</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Page;