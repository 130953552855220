import React from 'react'
import moment from 'moment'
import style from './index.module.scss'
import { getTemplateContentText } from '../../../../../utils'

/** 事件详情内容主体(只读状态) */
const Com = props => {
  const { capsuleDetailInfo, classifyTags } = props
  const { name, orgName, occur_time, occur_end_time, jsonContent } = capsuleDetailInfo
  const { title, classify: classifyId, info } = jsonContent || {}
  return (
    <div className={style['contentViewWrapper']}>
      <div className="content-top">
        <span>
          {name || ''} <span>{orgName ? `（${orgName}）` : ''}</span>
        </span>
        <div>
          <div className="time">
            {occur_time ? moment(occur_time).format('YYYY/MM/DD HH:mm') : ''}
            {occur_end_time ? `~${moment(occur_end_time).format('HH:mm')}` : ''}
          </div>
          <div className="classify">{classifyTags[classifyId] || ''}</div>
        </div>
      </div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="custom-info">{getTemplateContentText(jsonContent)}</div>
      </div>
    </div>
  )
}

export default Com
