import * as echarts from 'echarts~5';

const renderChart = (chartNode, data) => {
    if (!!chartNode) {
        // render chart
        let instance = echarts.getInstanceByDom(chartNode);
        if (!instance) {
            instance = echarts.init(chartNode);
        };
        instance.clear()
        if (data) {
            const { data_list = [], abn_marker = [] } = data
            const yVals = data_list.map(([, value]) => value)
            const pYVals = yVals.filter(v => typeof v === 'number')
            let max = 1
            let min = 0
            // 该页面目前仅有tengfei使用
            if (pYVals.length > 0) {
                const yMax = Math.max(...pYVals)
                const yMin = Math.min(...pYVals)
                const delta = yMax - yMin
                if (delta === 0) {
                    max = Math.ceil(yMax) + 1
                    min = Math.floor(yMin)
                    if (min > 0) min--
                } else {
                    max = Math.ceil((yMax + delta / 10) * 100) / 100
                    min = Math.floor((yMin - delta / 10) * 100) / 100
                    if (min < 0 && yMin >= 0) min = 0
                    if (max > 0 && yMax <= 0) max = 0
                }
            }

            instance.setOption({
                grid: {
                    // containLabel: true,
                    left: 80,
                    top: 24,
                    right: 12,
                    bottom: 12,
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'time',
                    boundaryGap: false,
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,// true时不一定显示0
                    min,
                    max
                },
                series: [
                    {
                        data: data_list,
                        type: 'line',
                        itemStyle: {
                            color: ({ data: [time] }) => {
                                return abn_marker.indexOf(time) > -1 ? 'red' : 'rgba(64, 161, 225, 1)'
                            },
                        },
                        symbol: ([time]) => {
                            return abn_marker.indexOf(time) > -1 ? 'emptyCircle' : 'none'
                        },
                        animation: false
                    }
                ],
            });
        } else {
            instance.clear()
        }

    };
};

export {
    renderChart
}