import React from 'react';
import style from './index.module.scss';
import TopBreadcrumb from 'components/TopBreadCrumb';
import Tabs from 'components/TabsV2';
import RealTime from 'components/RealTime'

const breadcrumbList = [{ name: '异常诊断' }, { name: '塌料监控' }];

const Comp = (props) => {
    const { sysInfo, setSysInfo, fncs } = props;
    return (
        <div className={style['wrapper']}>
            <TopBreadcrumb breadcrumbList={breadcrumbList} />
            <div className="tabs_wrapper">
                <Tabs tabs={fncs} selectedTab={sysInfo} onChange={setSysInfo} />
            </div>
            <div className='right'>
                <RealTime />
            </div>
        </div>
    );
};

export default Comp;
