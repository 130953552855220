import React, { useState } from 'react';
import style from './index.module.scss';
import PageTabs from 'components/PageTabs';
import DomResize from 'components/DomResize';
import { tabs } from './utils';

const Page = (props) => {
    const [tab, setTab] = useState(null);
    const [tabRect, setTabRect] = useState(null);

    const tabOnChange = (tab) => {
        setTab(tab);
    };

    return (
        <div className={style['wrapper']}>
            <div className="tab_wrapper">
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                        <DomResize onResize={(conf) => { setTabRect(conf); }} mountedCall={true} />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <PageTabs onChange={tabOnChange} tabs={tabs} />
                    </div>
                </div>
            </div>
            {
                !!tab && !!tab.comp && (
                    <div className="content_wrapper">
                        <tab.comp
                            {
                            ...(tab.props || {})
                            }
                            tabRect={tabRect}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default Page;
