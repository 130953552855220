import React, { useRef, useState, useEffect } from 'react';
import ICON_1_GREEN from './res/icon_1_green.png';
// import ICON_1_RED from './res/icon_1_red.png';
import ICON_2_GREEN from './res/icon_2_green.png';
// import ICON_2_RED from './res/icon_2_red.png';
import ICON_3_GREEN from './res/icon_3_green.png';
// import ICON_3_RED from './res/icon_3_red.png';
import ICON_4_GREEN from './res/icon_4_green.png';
// import ICON_4_RED from './res/icon_4_red.png';
import './ThreeComp.scss';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// const ICON_4_ITEMS = [
//     [ICON_1_GREEN, ICON_1_RED],
//     [ICON_2_GREEN, ICON_2_RED],
//     [ICON_3_GREEN, ICON_3_RED],
//     [ICON_4_GREEN, ICON_4_RED],
// ];

const ThemeId2Icon = {
    1: ICON_1_GREEN,
    2: ICON_2_GREEN,
    3: ICON_3_GREEN,
    4: ICON_4_GREEN,
};

const SysId2Cfg = {
    1: {
        position: [235, 98],
        deltaPos: [2, 2],
    },
    2: {
        position: [662, 50],
        deltaPos: [0, 1],
    },
    3: {
        position: [890, 49],
        deltaPos: [-1, 1],
    },
    4: {
        position: [84, 478],
        deltaPos: [2, -2],
    },
    5: {
        position: [450, 480],
        deltaPos: [1, -2],
    },
}

const RotateCircleBar = (props) => {
    const { rotateDeg, outlineCircleColor } = props;
    const [rotate, setRotate] = useState(0);
    const isMounted = useRef(false);
    const setNewRotate = useRef(null);
    setNewRotate.current = () => {
        if (rotate < rotateDeg) {
            let newRotate = rotate + 3;
            if (newRotate > rotateDeg) {
                newRotate = rotateDeg;
            };
            setTimeout(() => {
                setRotate(newRotate);
            }, 20);
        };
    };
    useEffect(() => {
        const mySetTimeout = setTimeout(() => {
            isMounted.current = true
            setNewRotate.current();
        }, 500);
        return () => {
            clearTimeout(mySetTimeout);
        };
    }, []);
    useEffect(() => {
        if (isMounted.current) {
            if (rotate > rotateDeg) {
                setRotate(rotateDeg);
            } else {
                setNewRotate.current();
            };
        };
    }, [rotate, rotateDeg]);
    return (
        <div className="colored_block" style={{
            transform: `rotate(${rotate}deg)`,
            backgroundColor: outlineCircleColor,
        }}></div>
    );
};

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoVisible: false,
            degAngle: null,
            panel3DInfo: null,
        };
    }

    componentDidMount() {
        this.render3D();
        this.getData();
    }

    componentDidUpdate(prevProps) {
        const { timeRange: prevTimeRange } = prevProps;
        const { timeRange } = this.props;
        if (timeRange !== prevTimeRange) {
            this.getData();
        };
    }

    getData = () => {
        const { get3DPanelInfo } = this.props;
        get3DPanelInfo()
            .then((retData) => {
                const { isSuccess, results } = retData;
                if (isSuccess) {
                    this.setState({ panel3DInfo: results });
                };
            });
    }

    componentWillUnmount() {
        if (!!this.mySetTimeout) {
            clearTimeout(this.mySetTimeout);
        };
        if (!!this.onDocumentMouseMove) {
            document.removeEventListener('mousemove', this.onDocumentMouseMove);
        };
    }

    calcSpaceDistance = (pos1, pos2) => {
        const [x1, y1, z1] = pos1;
        const [x2, y2, z2] = pos2;
        // const squireDis = x1 * x1 + y1 * y1 + z1 * z1 + x2 * x2 + y2 * y2 + z2 * z2;
        const squireDis = (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2) + (z1 - z2) * (z1 - z2);
        return Math.floor(Math.sqrt(squireDis) * 100) / 100;
    }

    render3D = () => {
        if (!this.refNode) {
            return;
        };

        const startXAxis = 570000;
        const startYAxis = 1000000;
        const startZAxis = -240000;
        const startLookAtPostion = [startXAxis, 50000, startZAxis];
        const endLookAtPosition = [400000, 30000, -400000];
        const startCameraPosition = [startXAxis - 10000, startYAxis, startZAxis - 10000];
        const endCameraPosition = [530000, 250000, -470000];
        // const startLookAtPostion = endLookAtPosition;
        // const startCameraPosition = endCameraPosition;

        const self = this;
        // console.log(this.refNode.offsetWidth);
        const nodeWidth = this.refNode.offsetWidth;
        const nodeHeight = this.refNode.offsetHeight;
        // console.log(THREE);

        const refNode = this.refNode;
        const deltaCameraPosition = startCameraPosition
            .map((startAxis, index) => endCameraPosition[index] - startAxis);
        const deltaLookAtPosition = startLookAtPostion
            .map((startAxis, index) => endLookAtPosition[index] - startAxis);

        const renderStepNum = 100;
        var container;
        // var stats;

        var camera, scene, raycaster, renderer;
        var mouse = new THREE.Vector2(), INTERSECTED;

        // var mouseX = 0, mouseY = 0;

        // var windowHalfX = nodeWidth / 2;
        // var windowHalfY = nodeHeight / 2;


        init();
        animate();
        this.camera = camera;

        let currentStep = 0;
        const renderNextScene = () => {
            if (currentStep < renderStepNum) {
                currentStep++;
            } else {
                this.setState({ infoVisible: true });
                return;
            };
            const currentCameraPosition = startCameraPosition
                .map((startAxis, index) => startAxis + (deltaCameraPosition[index] * currentStep / renderStepNum));
            const currentLookAtPosition = startLookAtPostion
                .map((startAxis, index) => startAxis + (deltaLookAtPosition[index] * currentStep / renderStepNum));
            const [x, y, z] = currentCameraPosition;
            // console.log(currentCameraPosition);
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            // camera.lookAt(0, 0, -13000);
            camera.lookAt(...currentLookAtPosition);
            // render();
            this.mySetTimeout = setTimeout(renderNextScene, 30);
        };
        this.mySetTimeout = setTimeout(renderNextScene, 100);
        // renderNextScene();




        function init() {

            // container = document.createElement('div');
            // document.body.appendChild(container);

            container = refNode;

            camera = new THREE.PerspectiveCamera(45, nodeWidth / nodeHeight, 100, 13000000);
            const [x, y, z] = startCameraPosition;
            // const [x, y, z] = endCameraPosition;
            // console.log(startCameraPosition);
            camera.position.x = x;
            camera.position.y = y;
            camera.position.z = z;
            // camera.position.x = -150000;
            // camera.position.y = 100000;
            // camera.position.z = 45000;
            // camera.lookAt(0, 0, -13000);
            camera.lookAt(...startLookAtPostion);

            raycaster = new THREE.Raycaster();

            // scene

            scene = new THREE.Scene();

            var ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
            scene.add(ambientLight);

            var pointLight = new THREE.PointLight(0xffffff, 0.8);
            camera.add(pointLight);
            scene.add(camera);

            // add axios
            // const materialX = new THREE.LineBasicMaterial({ color: 0xffffff });
            const geometryX = new THREE.Geometry();
            geometryX.vertices.push(new THREE.Vector3(0, 0, 0));
            geometryX.vertices.push(new THREE.Vector3(300000, 0, 0));
            // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
            // const lineX = new THREE.Line(geometryX, materialX);

            // const materialY = new THREE.LineBasicMaterial({ color: 0xff55ff });
            const geometryY = new THREE.Geometry();
            geometryY.vertices.push(new THREE.Vector3(0, 0, 0));
            geometryY.vertices.push(new THREE.Vector3(0, 300000, 0));
            // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
            // const lineY = new THREE.Line(geometryY, materialY);

            // const materialZ = new THREE.LineBasicMaterial({ color: 0xff0000 });
            const geometryZ = new THREE.Geometry();
            geometryZ.vertices.push(new THREE.Vector3(0, 0, 0));
            geometryZ.vertices.push(new THREE.Vector3(0, 0, 300000));
            // geometry.vertices.push(new THREE.Vector3(10, 0, 0));
            // const lineZ = new THREE.Line(geometryZ, materialZ);

            // add model mid
            const sphereGeometry = new THREE.SphereGeometry(10000, 20, 20);
            const sphereMaterial = new THREE.MeshBasicMaterial({
                color: 0x7777ff,
                wireframe: true
            });
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
            sphere.position.set(...endLookAtPosition);


            // model

            var onProgress = function (xhr) {
                if (xhr.lengthComputable) {
                    var percentComplete = xhr.loaded / xhr.total * 100;
                    console.log(Math.round(percentComplete, 2) + '% downloaded');
                }
            };

            var onError = function (xhr) { };


            var mtlLoader = new MTLLoader();
            mtlLoader.setPath('obj/');
            mtlLoader.load('factory.mtl', function (materials) {

                materials.preload();

                var objLoader = new OBJLoader();
                objLoader.setMaterials(materials);
                objLoader.setPath('obj/');
                objLoader.load('factory.obj', function (object) {
                    console.log(object);

                    // scene.add(lineX);
                    // scene.add(lineY);
                    // scene.add(lineZ);

                    // scene.add(sphere);

                    // object.position.y = - 95;
                    scene.add(object);
                    console.log(scene);
                    // scene.children[6].children.map((mesh, index) => {
                    //     setTimeout(() => {
                    //         console.log(index);
                    //         const originMaterial = mesh.material;
                    //         mesh.material = mesh.material.clone();
                    //         mesh.material.emissive.setHex(0xff0000);
                    //         setTimeout(() => {
                    //             mesh.material = originMaterial;
                    //         }, 900);
                    //     }, index * 1000);
                    //     // const setColor = Math.random() > 0.9;
                    //     // if (index === 1) {
                    //     //     // mesh = mesh.clone();
                    //     //     mesh.material = mesh.material.clone();
                    //     //     mesh.material.emissive.setHex(0xff0000);
                    //     // };
                    // });

                }, onProgress, onError);

            });

            //

            renderer = new THREE.WebGLRenderer();
            // renderer = new THREE.WebGLRenderer({ alpha: true });
            // renderer.setClearColor(0xffffff, 0);
            renderer.setPixelRatio(window.devicePixelRatio);
            // renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setSize(nodeWidth, nodeHeight);
            container.appendChild(renderer.domElement);

            var controls = new OrbitControls(camera, renderer.domElement);
            // controls.addEventListener('change', render); // use only if there is no animation loop
            controls.addEventListener('change', (event) => {
                const { target } = event;
                const azimuthalAngle = target.getAzimuthalAngle();
                const degAngle = Math.floor(azimuthalAngle / Math.PI * 180);
                const { degAngle: prevDegAngle } = self.state;
                if (prevDegAngle !== degAngle && degAngle > 70) {
                    self.setState({ degAngle });
                };
                // console.log(degAngle);
            });
            // controls.minDistance = 10;
            // controls.maxDistance = 100;
            // controls.enableZoom = false;
            controls.enableKeys = false;//禁用键盘
            controls.target = new THREE.Vector3(...endLookAtPosition);

            // 上下旋转范围
            // controls.minPolarAngle = Math.PI * (50 / 180);
            // controls.maxPolarAngle = Math.PI * (50 / 180);
            controls.minPolarAngle = Math.PI * (30 / 180);
            controls.maxPolarAngle = Math.PI * (80 / 180);
            // 左右旋转范围
            // controls.minAzimuthAngle = -Math.PI * (180 / 180);
            // controls.maxAzimuthAngle = Math.PI * (180 / 180);
            controls.minAzimuthAngle = Math.PI * (60 / 180);
            controls.maxAzimuthAngle = Math.PI * (150 / 180);

            controls.update();

            document.addEventListener('mousemove', onDocumentMouseMove, false);

            //

            // window.addEventListener('resize', onWindowResize, false);

        }

        // function onWindowResize() {

        //     windowHalfX = nodeWidth / 2;
        //     windowHalfY = nodeHeight / 2;

        //     camera.aspect = nodeWidth / nodeHeight;
        //     camera.updateProjectionMatrix();

        //     renderer.setSize(nodeWidth, nodeHeight);

        // }

        function onDocumentMouseMove(event) {
            event.preventDefault();
            // console.log(event.clientX, event.clientY, window.innerWidth, window.innerHeight, self.nod.offsetLeft, self.nod.offsetTop);


            // mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            // mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
            // console.log(event.clientX - self.nod.offsetLeft, self.refNode.offsetWidth);
            // console.log(event.clientY - self.nod.offsetTop, self.refNode.offsetHeight);

            mouse.x = ((event.clientX - self.nod.offsetLeft) / self.refNode.offsetWidth) * 2 - 1;
            mouse.y = - ((event.clientY - self.nod.offsetTop - 60) / self.refNode.offsetHeight) * 2 + 1;

        }

        this.onDocumentMouseMove = onDocumentMouseMove;

        //

        function animate() {

            requestAnimationFrame(animate);
            render();

        }

        function render() {
            // camera.position.x += ( mouseX - camera.position.x ) * .05;
            // camera.position.y += ( - mouseY - camera.position.y ) * .05;

            // camera.lookAt(...endLookAtPosition);

            raycaster.setFromCamera(mouse, camera);
            if (scene.children[6]) {
                var intersects = raycaster.intersectObjects(scene.children[2].children);

                if (intersects.length > 0 && intersects[0].object.id !== 16) {

                    if (INTERSECTED !== intersects[0].object) {

                        if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

                        INTERSECTED = intersects[0].object;
                        INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
                        INTERSECTED.material = INTERSECTED.material.clone();
                        INTERSECTED.material.emissive.setHex(0xff0000);

                    }

                } else {

                    if (INTERSECTED) INTERSECTED.material.emissive.setHex(INTERSECTED.currentHex);

                    INTERSECTED = null;

                };
            };


            renderer.render(scene, camera);

        }
    }

    refNodeFunc = refNode => {
        this.refNode = refNode;
    }

    render() {
        const { degAngle, infoVisible, panel3DInfo } = this.state;
        let deltaAngle = 0;
        if (degAngle !== null) {
            deltaAngle = degAngle - 118;
        };
        const MaxDisplayIndex = 2;
        const BlockWidth = 28;
        const BlockMargin = 5;
        const getDeltaX = (index, total) => {
            const totalLength = total * (BlockMargin + BlockWidth) - BlockMargin;
            const currLeft = index * (BlockMargin + BlockWidth);
            const aimLeft = Math.floor((totalLength - BlockWidth) / 2);
            return aimLeft - currLeft;
        };

        return (
            <div className="main_page_three_comp_wrapper" ref={nod => this.nod = nod}>
                <div className="three_chart_block" ref={this.refNodeFunc} />
                {
                    infoVisible &&
                    !!panel3DInfo &&
                    panel3DInfo
                        .map((item, index) => {
                            const { id, name, themes } = item;
                            let rotateDeg = 0;
                            let rate = "-";
                            let textColor = "#ffffff";
                            let bckCircleColor = "transparent";
                            let outlineCircleColor = "transparent";
                            const sysCfg = SysId2Cfg[id];
                            if (!sysCfg) {
                                return null;
                            };
                            const { position, deltaPos } = sysCfg;
                            const [originL, originT] = position;
                            const [deltaL, deltaT] = deltaPos;
                            let left = originL + deltaAngle * deltaL * 2;
                            let top = originT + deltaAngle * deltaT * 2;
                            if (top < 15) {
                                top = 15;
                            } else if (top > 546) {
                                top = 546;
                            };
                            if (left < 10) {
                                left = 10;
                            } else if (left > 1100) {
                                left = 1100;
                            };
                            const totalRate = themes
                                .filter((_, index) => index < MaxDisplayIndex)
                                .reduce((pV, { rate }) => {
                                    if (pV === null || typeof rate !== 'number') return null;
                                    return pV + rate;
                                }, 0);
                            // totalRate = 2.4;
                            if (totalRate !== null) {
                                rate = Math.floor(1000 * totalRate / MaxDisplayIndex) / 10;
                                const warning = rate < 75;
                                textColor = warning ? 'rgba(255, 97, 112, 1)' : 'rgba(255, 255, 255, 1)';
                                bckCircleColor = warning ? '0px 10px 8px 0px rgba(255, 97, 112, 0.34) inset' : '0px 10px 8px 0px rgba(68, 243, 218, 0.34) inset';
                                outlineCircleColor = warning ? 'rgba(255, 97, 112, 1)' : 'rgba(68, 243, 218, 1)';
                                rotateDeg = 180 * rate / 100;
                            };
                            return (
                                <div className="chart_info_wrapper" key={index} style={{ left, top }}>
                                    <div className="top_block_wrapper" style={{ position: 'relative', zIndex: 21 }}>
                                        <div className="circle_outline_wrapper">
                                            <div className="circle_wrapper">
                                                <div className="rotate_block_1">
                                                    <RotateCircleBar rotateDeg={rotateDeg} outlineCircleColor={outlineCircleColor} />
                                                </div>
                                                <div className="inner_hover_circle" />
                                            </div>
                                        </div>
                                        <div className="back_circle_wrapper">
                                            <div className="back_circle" style={{ boxShadow: bckCircleColor }} />
                                        </div>
                                        <div className="value_text" style={{ color: textColor }}>{rate}</div>
                                    </div>
                                    <div className="icons_wrapper" style={{ position: 'relative', zIndex: 20 }}>
                                        {
                                            themes
                                                .map((theme, index, arr) => {
                                                    const { id: themeId, rate } = theme;
                                                    const icon = ThemeId2Icon[themeId];
                                                    let iconColor = '#9FA0BA';
                                                    if (index < MaxDisplayIndex) {
                                                        if (typeof rate === "number") {
                                                            iconColor = rate > 0.75 ? 'rgba(68,243,218, 1)' : '#FF6170';
                                                        };
                                                    };
                                                    let style = { width: '100%' };
                                                    const deltaX = getDeltaX(index, arr.length);
                                                    const deltaY = 45;
                                                    style = Object.assign({
                                                        filter: `drop-shadow(${-1 * deltaX}px ${deltaY}px ${iconColor})`,
                                                        position: 'relative',
                                                        bottom: deltaY,
                                                        left: deltaX,
                                                    }, style);
                                                    return (
                                                        <div key={index} style={{ width: BlockWidth, marginLeft: index > 0 ? BlockMargin : 0 }}>
                                                            <div style={{ width: 38, transform: 'translateX(-5px)' }}>
                                                                <div style={style}>
                                                                    <img alt="" src={icon} style={{ width: '100%', height: 36 }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                    <div className="name">{name}</div>
                                </div>
                            );
                        })
                }
            </div>
        );
    }
}
