import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input, Checkbox } from 'antd'
import style from './custom.module.scss'
const defaultList = [
  { name: '1', value: '', checkStatus: false },
  { name: '2', value: '', checkStatus: false },
  { name: '3', value: '', checkStatus: false }
]

/** UI模版 templateId=5 */
const Com = props => {
  const { capsuleDetailInfo, cacheData, resetAction } = props
  const [libraryList, setLibraryList] = useState(JSON.parse(JSON.stringify(defaultList)))

  useImperativeHandle(props.refInstance, () => ({
    checkValidate,
    getCustomData: () => {
      return { libraryList }
    }
  }))

  useEffect(() => {
    if (capsuleDetailInfo && Object.keys(capsuleDetailInfo).length > 0) {
      // 编辑
      const { jsonContent } = capsuleDetailInfo
      setLibraryList(jsonContent.libraryList)
    } else {
      // 新建时会产生缓存数据
      const cacheInfo = cacheData ? cacheData : null
      if (cacheInfo) {
        const jsonContent = cacheInfo.json_content || {}
        if (Object.keys(jsonContent).length > 0) {
          setLibraryList(jsonContent.libraryList)
        }
      }
    }
  }, [])

  /** 重置 */
  useEffect(() => {
    if (resetAction) {
      setLibraryList([
        { name: '1', value: '', checkStatus: false },
        { name: '2', value: '', checkStatus: false },
        { name: '3', value: '', checkStatus: false }
      ])
    }
  }, [resetAction])

  const libraryInputChange = (newValue, libraryIndex) => {
    const tempList = libraryList.concat()
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (newValue || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return
    }
    tempList[libraryIndex].value = formatValue
    setLibraryList(tempList)
  }
  /** 字符串转数字 */
  const formatNumber = (newValue, libraryIndex) => {
    if (!newValue) return
    const tempList = libraryList.concat()
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    tempList[libraryIndex].value = formatValue
    setLibraryList(tempList)
  }

  const libraryCheckboxChange = (checkStatus, libraryIndex) => {
    const tempList = libraryList.concat()
    tempList[libraryIndex].checkStatus = checkStatus
    setLibraryList(tempList)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    return true
  }

  return (
    <ul className={style['custom_5']}>
      <li>
        <div className="text">库</div>
        <div className="text">库空</div>
        <div className="text">入库</div>
      </li>
      {libraryList.map((item, libraryIndex) => {
        return (
          <li key={libraryIndex} style={{ marginRight: 14 }}>
            <div style={{ lineHeight: '32px' }}>{item.name}</div>
            <Input
              value={item.value}
              onChange={e => libraryInputChange(e.target.value, libraryIndex)}
              onBlur={e => formatNumber(e.target.value, libraryIndex)}
            />
            <Checkbox
              checked={item.checkStatus}
              onChange={e => libraryCheckboxChange(e.target.checked, libraryIndex)}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default Com
