import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import menuImg from '../../assets/menu.png'

export default class LeftMenu extends React.Component {
  constructor(props) {
    super(props)
    const { history } = props
    this.state = {
      isOpen: true, // 标记菜单显示或隐藏
      menuList: [], // 菜单列表
      activeMenu: history.location.pathname // 当前激活的路由
    }
  }

  componentDidMount() {
    // 菜单列表
    const { menuList } = this.props;
    if (!!menuList) {
      this.setState({ menuList });
    };
  }

  componentDidUpdate() {
    const { history: { location: { pathname } } } = this.props;
    if (this.state.activeMenu !== pathname) {
      this.setState({ activeMenu: pathname });
    };
  }

  /** 显示或隐藏导航菜单 */
  onMenuSwitchChange = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  // 路由跳转
  onNavigateNewPage = menuItem => {
    if (this.state.activeMenu === menuItem.menuPath || !menuItem.menuPath) return
    this.props.history.push(menuItem.menuPath)
  }

  render() {
    const { isOpen, menuList, activeMenu } = this.state
    const menuDom = menuList.map((menuItem, index) => {
      return (
        <li
          key={index}
          className={`${activeMenu === menuItem.menuPath ? 'active-menu' : ''}`}
          onClick={() => this.onNavigateNewPage(menuItem)}
        >
          {menuItem.menuName}
        </li>
      )
    })
    return (
      <div className="menu">
        <div onClick={() => this.onMenuSwitchChange()}>
          <img draggable="false" src={menuImg} alt="" />
        </div>
        <ul style={{ display: isOpen ? 'block' : 'none' }}>{menuDom}</ul>
      </div>
    )
  }
}

LeftMenu.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
