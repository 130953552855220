import style from './index.module.scss';
import { useState } from 'react';
import { withRouter } from 'react-router';
import SchemaComp from '../Schema';

const Comp = (props) => {
    const [id] = useState(() => {
        const id = new URLSearchParams(props.location.search).get('id');
        return id ?? null;
    });
    const [code] = useState(() => {
        const id = props?.match?.params?.id ?? null;
        return id;
    });
    const createDone = (id) => {
        console.log("new id", id);
        props.history.replace(`/PEIMSV2/EventSchema/${code}?id=${id}`);
        window.location.reload();
    };

    return (
        <div className={style["wrapper"]}>
            <SchemaComp code={code} id={id} createDone={createDone} />
        </div>
    );
};

export default withRouter(Comp);
