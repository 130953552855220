import React from 'react';

import TITLE_ICON from './res/title_icon.png';

import './QualityItem.scss';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const { item = {} } = this.props;
        const { name, rate, warning } = item;
        let firstRotateDeg = 0;
        let secondRotateDeg = 0;
        if (rate > 50) {
            firstRotateDeg = 180;
            secondRotateDeg = 180 * (rate - 50) / 50;
        } else {
            firstRotateDeg = 180 * rate / 50;
        };

        return (
            <div className="quality_item_wrapper">
                <div className="left">
                    <div className="item_title">
                        <div className="text">{name}</div>
                        <img alt="" src={TITLE_ICON} className="title_icon" />
                    </div>
                    <div className="rows_wrapper">
                        <div className="row_wrapper">
                            <div className="row_title">合格率</div>
                            <div className="row_value">{rate}%</div>
                        </div>
                        <div className="row_wrapper">
                            <div className="row_title">质量告警</div>
                            <div className={"row_value" + (warning > 0 ? " warning_row" : "")}>{warning}次</div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="spin_block">
                        <div className="rotate_block_1">
                            <div className="colored_block" style={{
                                transform: `rotate(${firstRotateDeg}deg)`
                            }}></div>
                        </div>
                        <div className="rotate_block_2">
                            <div className="colored_block" style={{
                                transform: `rotate(${secondRotateDeg}deg)`
                            }}></div>
                        </div>
                        <div className="inner_hover_circle"></div>
                    </div>
                </div>
            </div>
        );
    }
}
