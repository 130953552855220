import { useEffect, useState } from 'react';
import { post } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            let requesting = true;
            setLoading(true);
            const { time_type, time, category, range } = conf;
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/brief/get_cpl_brief', {
                time_type, time: time_start, category,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', category, retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            const nextResult = {
                                factors_brief: retData.data.factors_brief,
                                low_cpl_factors: retData.data.low_cpl_factors,
                            };
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        } else {
                            // 处理失败
                            setResult(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
