import React from 'react';

import style from './RuleFactorImg.module.scss';

export default class RuleFactorImg extends React.Component {

    constructor(props) {
        super(props);
    }

    getColoredBar = () => {
        const RoundFunc = num => parseFloat(parseFloat(num).toFixed(12));
        const { factor: { score_details: { dev_intervals, std_value } = {}, score_method } } = this.props;
        // console.log('dev_intervals', dev_intervals);
        // console.log(this.props.factor);
        let coloredBar = null;
        if (score_method === 3) {
            //区间
            coloredBar = [];
            const intervals = (dev_intervals || []).filter(([l, h]) => !(l === 0 && h === 0));
            intervals.forEach(([l, h, s], index) => {
                if (index === 0) {
                    coloredBar[intervals.length - 1] = {
                        score: s,
                        value: std_value,
                        bordered: true,
                        color: '#33FF33',
                    }
                    coloredBar[intervals.length] = {
                        score: s,
                        value: RoundFunc(std_value + h),
                        color: '#33FF33',
                    }
                } else if (index === intervals.length - 1) {
                    coloredBar[0] = {
                        score: s,
                        value: RoundFunc(std_value - l),
                        color: '#FF3D00',
                    }
                    coloredBar[2 * intervals.length - 1] = {
                        score: s,
                        color: '#FF3D00',
                    }
                } else {
                    coloredBar[intervals.length - 1 - index] = {
                        score: s,
                        value: RoundFunc(std_value - l),
                        color: '#FFFF00',
                    }
                    coloredBar[intervals.length + index] = {
                        score: s,
                        value: RoundFunc(std_value + h),
                        color: '#FFFF00',
                    }
                }
            });
            // console.log(coloredBar);
        } else if (score_method === 4) {
            //限值
            coloredBar = [];
            const intervals = dev_intervals.filter(([l, h]) => !(l === 0 && h === 0));
            intervals.forEach(([l, h, s], index) => {
                if (intervals[0][0] === null) {
                    //小于
                    if (index === 0) {
                        coloredBar[0] = {
                            score: s,
                            value: h,
                            color: '#33FF33',
                        }
                    } else if (index === intervals.length - 1) {
                        coloredBar[intervals.length - 1] = {
                            score: s,
                            color: '#FF3D00',
                        }
                    } else {
                        coloredBar[index] = {
                            score: s,
                            value: h,
                            color: '#FFFF00',
                        }
                    }
                } else {
                    //大于
                    if (index === 0) {
                        coloredBar[intervals.length - 1] = {
                            score: s,
                            color: '#33FF33',
                        }
                    } else if (index === intervals.length - 1) {
                        coloredBar[0] = {
                            score: s,
                            value: h,
                            color: '#FF3D00',
                        }
                    } else {
                        coloredBar[intervals.length - 1 - index] = {
                            score: s,
                            value: h,
                            color: '#FFFF00',
                        }
                    }
                }
            });
        };
        return coloredBar;
    }

    render() {
        const { factor: { rank_method, score_method } } = this.props;
        const coloredBar = this.getColoredBar();
        return (
            <div className={style["rule_factor_img_wrapper"]}>
                {
                    [1, 2].indexOf(score_method) > -1 && (
                        <div className="bar_wrapper">
                            <div className="block" style={{ backgroundColor: rank_method === 2 ? '#FF3D00' : '#33FF33' }} />
                            <div className="block" style={{ backgroundColor: rank_method === 2 ? '#33FF33' : '#FF3D00' }} />
                            <div className="point" style={{ left: '50%' }} />
                            <div className="point" style={{ left: '30%' }} />
                            <div className="point" style={{ left: '70%' }} />
                            <div className="value_bar">
                                <div className="low">低</div>
                                <div className="high">高</div>
                            </div>
                            <div className="score_bar">
                                <div className="low">{rank_method === 2 ? '低' : '高'}</div>
                                <div className="high">{rank_method === 2 ? '高' : '低'}</div>
                            </div>
                        </div>
                    )
                }
                {
                    [3, 4].indexOf(score_method) > -1 && coloredBar && (
                        <div className="bar_wrapper">
                            {
                                coloredBar.map(({ score, value, color: backgroundColor, bordered = false }, index) => (
                                    <div className="block" style={{ backgroundColor }} key={index}>
                                        <div className="score_text">{score}</div>
                                        {
                                            value !== undefined && (
                                                <div className="point" />
                                            )
                                        }
                                        {
                                            value !== undefined && (
                                                <div className={"value" + (bordered ? " bordered_value" : "")} >{value}</div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}
