import React from 'react';

import ICON_TITLE from './res/icon_SLSC.png';
import ICON_STAR from './res/star.png';

import './SystemPanel.scss';

import { dataSource } from './constants.js';

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null,
        };
    }

    itemOnClick = (index) => {
        this.setState({ selectedIndex: index });
    }

    render() {
        const { selectedIndex } = this.state;

        return (
            <div className="factor_statistics_system_panel_wrapper">
                <div className="item_header_wrapper">
                    <img alt="" src={ICON_TITLE} className="item_header_icon" />
                    <div className="title">熟料烧成系统</div>
                    <div
                        style={{
                            position: 'relative',
                            top: 5,
                            fontSize: 14,
                            height: 14,
                            lineHeight: '14px',
                            color: 'rgba(159, 160, 186, 1)',
                            marginLeft: 14,
                        }}
                    >
                        <span>共 </span>
                        <span
                            style={{
                                color: 'rgba(68, 243, 218, 1)',
                            }}
                        >3</span>
                        <span> 项KPI指标，系统KPI指标平均PHI为 </span>
                        <span
                            style={{
                                color: 'rgba(68, 243, 218, 1)',
                            }}
                        >61.2%</span>
                    </div>
                </div>
                <div className="item_content_wrapper">
                    <div
                        style={{
                            height: 518,
                            backgroundColor: 'rgba(21, 25, 30, 1)',
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                width: 811,
                                borderTop: '1px solid transparent',
                                borderBottom: '1px solid transparent',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            className="left_table_wrapper"
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ width: 24 }}></th>
                                        <th style={{ width: 36 }}></th>
                                        <th>产品类型</th>
                                        <th>指标名称</th>
                                        <th>改善机会</th>
                                        <th>KPR</th>
                                        <th
                                            style={{
                                                borderRight: '1px solid rgba(114, 54, 63, 1)',
                                            }}
                                        >目标PHI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataSource
                                            .map((item, index) => {
                                                const { red, category, factorName, improveChance, PHI, aimPHI } = item;
                                                return (
                                                    <tr
                                                        key={index}
                                                        className={(red ? "red_tr" : "") + (selectedIndex === index ? " selected_tr" : "")}
                                                        onClick={() => this.itemOnClick(index)}
                                                    >
                                                        <td>
                                                            {
                                                                selectedIndex === index && (
                                                                    <div
                                                                        style={{
                                                                            width: 4,
                                                                            height: '100%',
                                                                            backgroundColor: 'rgba(255, 97, 112, 1)',
                                                                            borderRadius: 2,
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        </td>
                                                        <td>
                                                            <img alt="" src={ICON_STAR} width="20" height="20" />
                                                        </td>
                                                        <td>
                                                            <div
                                                                style={{
                                                                    width: 57,
                                                                    height: 20,
                                                                    border: '1px solid rgba(255, 217, 78, 1)',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    fontSize: 12,
                                                                    lineHeight: '12px',
                                                                    color: 'rgba(255, 217, 78, 1)',
                                                                }}
                                                            >{category}</div>
                                                        </td>
                                                        <td>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: 28,
                                                                        height: 14,
                                                                        backgroundColor: 'rgba(68, 243, 218, 1)',
                                                                        borderRadius: 9,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        fontSize: 12,
                                                                        lineHeight: '12px',
                                                                        color: 'rgba(21, 25, 34, 1)',
                                                                    }}
                                                                >KPI</div>
                                                                <div style={{ marginLeft: 8 }}>{factorName}</div>
                                                            </div>
                                                        </td>
                                                        <td>{improveChance}</td>
                                                        <td>{`${PHI}%`}</td>
                                                        <td
                                                            style={selectedIndex !== index ? {
                                                                borderRight: '1px solid rgba(114, 54, 63, 1)',
                                                            } : red ? {
                                                                borderRight: "1px solid rgba(255, 97, 112, 0.1)",
                                                            } : {}}
                                                        >{`${aimPHI}%`}</td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                            <div
                                //bottom space flexgrow
                                style={{
                                    height: 0,
                                    flexGrow: 1,
                                    borderRight: '1px solid rgba(114, 54, 63, 1)',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: 0,
                                flexGrow: 1,
                                borderStyle: 'solid',
                                borderWidth: '1px 1px 1px 0',
                                borderColor: 'rgba(255, 97, 112, 0.4) rgba(255, 97, 112, 0.4) rgba(255, 97, 112, 0.4) transparent',
                            }}
                            className="right_table_wrapper"
                        >
                            {
                                typeof selectedIndex === 'number' && !!dataSource[selectedIndex] && (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>影响因子</th>
                                                <th>相关度</th>
                                                <th>平均值</th>
                                                <th>目标值</th>
                                                <th>PHI</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataSource[selectedIndex].details
                                                    .map((item, index) => {
                                                        const { name, related, aveValue, aimValue, PHI, red } = item;
                                                        return (
                                                            <tr key={index} className={red ? "red_tr" : ""}>
                                                                <td>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: 28,
                                                                                height: 14,
                                                                                backgroundColor: 'rgba(179, 118, 255, 1)',
                                                                                borderRadius: 9,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                fontSize: 12,
                                                                                lineHeight: '12px',
                                                                                color: 'rgba(21, 25, 34, 1)',
                                                                            }}
                                                                        >RF</div>
                                                                        <div
                                                                            style={{
                                                                                marginLeft: 8,
                                                                            }}
                                                                        >{name}</div>
                                                                    </div>
                                                                </td>
                                                                <td>{related}</td>
                                                                <td>{aveValue}</td>
                                                                <td>{aimValue}</td>
                                                                <td>{`${PHI}%`}</td>
                                                            </tr>
                                                        );
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Page;