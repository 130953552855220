import { useEffect, useState } from 'react';
import { post, significantNum } from 'common/utils';
import moment from 'moment';

const max_bias_num = 0.1;

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, range } = conf;
            if (typeof time !== 'string') {
                setResult(null);
                return;
            };
            let requesting = true;
            setLoading(true);
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_factor_statistics_list', {
                time_type, time: time_start,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data;
                            let parsed_results = nextResult?.results;
                            if (!!parsed_results) {
                                const all_bias_datas = parsed_results.map(({ bias_pct }) => bias_pct).filter((v) => typeof v === 'number');
                                const max_bias = all_bias_datas.length > 0 ? Math.max(...all_bias_datas) : null;
                                // console.log(all_bias_datas, max_bias);
                                parsed_results = parsed_results
                                    .map((item) => {
                                        const { value, lower_limit, upper_limit, comp_pct, cpl_rate, bias_pct } = item;
                                        const parsed_value = typeof value === 'number' ? significantNum(value, 4) : "--";
                                        let parsed_standard = "--";
                                        if (typeof lower_limit === 'number' && typeof upper_limit === 'number') {
                                            parsed_standard = significantNum(lower_limit, 4) + "~" + significantNum(upper_limit, 4);
                                        } else {
                                            if (typeof lower_limit === 'number') {
                                                parsed_standard = significantNum(lower_limit, 4);
                                            } else if (typeof upper_limit === 'number') {
                                                parsed_standard = significantNum(upper_limit, 4);
                                            };
                                        };
                                        const parsed_comp_pct = (typeof comp_pct === 'number' ? significantNum(Math.abs(comp_pct) * 100, 3, 2) : "--") + "%";
                                        const parsed_cpl_rate = (typeof cpl_rate === 'number' ? significantNum(Math.abs(cpl_rate) * 100, 3, 2) : "--") + "%";
                                        const parsed_bias_pct = (typeof bias_pct === 'number' ? significantNum(Math.abs(bias_pct) * 100, 3, 2) : "--") + "%";
                                        const bias_bar_width = ((typeof bias_pct === 'number' && max_bias !== 0) ? (bias_pct / max_bias_num) * 100 : 0) + "%";
                                        const is_good_cpl = typeof cpl_rate === 'number' ? (cpl_rate < 0.75 ? false : true) : null;

                                        return Object.assign({}, item, {
                                            parsed_value,
                                            parsed_standard,
                                            parsed_comp_pct,
                                            parsed_cpl_rate,
                                            parsed_bias_pct,
                                            bias_bar_width,
                                            is_good_cpl,
                                        });
                                    });
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_results,
                            });
                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        } else {
                            // 处理错误
                            setResult(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
