import React from 'react'

const Com = props => {
  const { count } = props
  const newEventsStyle = {
    position: 'absolute',
    top: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    minHeight: 34,
    minWidth: 112,
    backgroundColor: '#FC5F5F',
    color: '#fff',
    fontSize: 14,
    borderRadius: 4,
    textAlign: 'center',
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1
  }
  const circle = {
    display: 'inline-block',
    backgroundColor: '#fff',
    color: '#FC5F5F',
    borderRadius: 10,
    minWidth: 22,
    height: 22,
    padding: 2,
    textAlign: 'center',
    boxSizing: 'border-box',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    marginLeft: 5,
    fontFamily: 'PingFangSC-Regular PingFang SC'
  }

  return (
    <div
      style={newEventsStyle}
      onClick={() => {
        typeof props.clickFun === 'function' && props.clickFun()
      }}
    >
      <span>您有新事件</span>
      <span style={circle}>{count}</span>
    </div>
  )
}

export default Com
