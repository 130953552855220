import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Input, Select, message, DatePicker } from 'antd'
import moment from 'moment'
import { getCurrentTime } from '../../../../containers/DemoSystemV2/EventCapsules/utils'
const { TextArea } = Input
const { Search } = Input
const { Option } = Select
const resetImg = require('../../../../assets/reset.png')
const defaultFanList = [
  { label: '1', value: '' },
  { label: '2', value: '' },
  { label: '3', value: '' },
  { label: '4', value: '' },
  { label: '5', value: '' },
  { label: '6', value: '' },
  { label: '7', value: '' },
  { label: '8', value: '' },
  { label: '9', value: '' },
  { label: '10', value: '' },
  { label: '离', value: '' },
  { label: '离', value: '' }
]
const FirstTpl = props => {
  const {
    tplId,
    cacheTamplateData,
    capsuleDetailInfo,
    isDisabled,
    templateList,
    actionName
  } = props
  const [title, setTitle] = useState('') // 标题
  const [classify, setClassify] = useState(null) // 分类
  const [fanList, setFanList] = useState(JSON.parse(JSON.stringify(defaultFanList)))
  const [info, setInfo] = useState('') // 说明
  const [dateTime, setDateTime] = useState(moment(getCurrentTime()))

  useImperativeHandle(props.refInstance, () => ({
    getTemplatInfo: getTemplatInfo,
    checkValidate,
    changeTitle,
    changeClassify
  }))

  useEffect(() => {
    // 新建时会产生缓存数据
    const cacheInfo = tplId && cacheTamplateData[tplId] ? cacheTamplateData[tplId] : null
    if (cacheInfo) {
      const jsonContent = cacheInfo.json_content || {}
      cacheInfo.date && setDateTime(moment(cacheInfo.date))
      if (Object.keys(jsonContent).length > 0) {
        jsonContent.classify ? setClassify(jsonContent.classify) : initDefaultClassify()
        setFanList(jsonContent.fanList)
        setInfo(jsonContent.info)
      }
    }
  }, [])
  useEffect(() => {
    const jsonContent = capsuleDetailInfo.jsonContent || {}
    if (Object.keys(jsonContent).length > 0) {
      setDateTime(capsuleDetailInfo.occur_time ? moment(capsuleDetailInfo.occur_time) : '')
      setTitle(jsonContent.title)
      jsonContent.classify
        ? setClassify(jsonContent.classify)
        : capsuleDetailInfo.sn
        ? setClassify(Number(capsuleDetailInfo.sn))
        : initDefaultClassify()
      setFanList(jsonContent.fanList)
      setInfo(jsonContent.info)
    } else {
      initDefaultClassify()
    }
  }, [templateList, isDisabled])

  const changeFanInfo = (newValue, fanIndex) => {
    const tempList = fanList.concat()
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (newValue || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return
    }

    tempList[fanIndex].value = formatValue
    setFanList(tempList)
  }
  /** 字符串转数字 */
  const formatNumber = (newValue, fanIndex) => {
    if (!newValue) return
    const tempList = fanList.concat()
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    tempList[fanIndex].value = formatValue
    setFanList(tempList)
  }

  /** 设置初始值 */
  const initDefaultClassify = () => {
    if (templateList.length > 0) {
      setClassify(templateList[0].id)
    }
  }

  /** 修改标题 */
  const changeTitle = title => {
    setTitle(title)
  }

  /** 修改分类标签 */
  const changeClassify = id => {
    id && setClassify(id)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (!dateTime) {
      message.error('请选择事件发生时间')
      return false
    }

    if (!(title || '').trim()) {
      message.error('请输入标题')
      return false
    }
    return true
  }

  /** 切换分类 */
  const classifyChange = e => {
    setClassify(e)
  }

  /** 重置 */
  const resetForm = () => {
    setDateTime('')
    setTitle('')
    initDefaultClassify()
    setInfo('')
    setFanList(JSON.parse(JSON.stringify(defaultFanList)))
    const { resetClick: propReset } = props
    propReset()
  }

  /** 获取模版信息 */
  const getTemplatInfo = () => {
    return {
      date: dateTime.format('YYYY-MM-DD HH:mm:00'),
      json_content: {
        title,
        classify,
        fanList,
        info,
        templateId: 1
      }
    }
  }

  const onSearch = value => console.log(value)
  const onChangeTime = (value, dateString) => {
    // setDateTime(moment(dateString).format('YYYY-MM-DD HH:mm:00'))
    setDateTime(value)
  }
  const onOkTime = value => {
    // setDateTime(moment(value).format('YYYY-MM-DD HH:mm:00'))
    setDateTime(value)
  }
  return (
    <div className="tpl">
      <div className="tpl-operator">
        <div className="tpl-operator-left">
          <span className="center">
            {isDisabled ? actionName : ''}
            {/*{JSON.parse(window.sessionStorage.getItem('USER_INFO')).name}*/}
          </span>
          <DatePicker
            showTime
            allowClear={false}
            value={dateTime}
            disabled={isDisabled}
            format="YYYY-MM-DD HH:mm"
            onChange={onChangeTime}
            onOk={onOkTime}
          />
        </div>
        <div className="tpl-operator-right">
          <Select value={classify} disabled={isDisabled} onChange={e => classifyChange(e)}>
            {templateList.map(item => {
              return (
                <Option key={item.title} value={item.id}>
                  {item.title}
                </Option>
              )
            })}
          </Select>
          {!isDisabled && (
            <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={resetForm}>
              <i className="reset-icon" />
              {/* <img src={resetImg} width="22" height="16" /> */}
              <span className="reset">重置</span>
            </div>
          )}
        </div>
      </div>
      <div className="tpl-input">
        <Input
          placeholder="请输入"
          maxLength={20}
          disabled={isDisabled}
          style={{ width: 312 }}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <ul className="firstUl">
        <li>
          <span>风机</span>
          <span>位置</span>
        </li>
        {fanList.map((item, index) => {
          return (
            <li key={index}>
              <span>{item.label}</span>
              <Input
                className="float-input"
                value={item.value}
                disabled={isDisabled}
                onChange={e => changeFanInfo(e.target.value, index)}
                onBlur={e => formatNumber(e.target.value, index)}
              />
            </li>
          )
        })}
      </ul>
      <div className="tpl-info">说明</div>
      <div className="tpl-textarea">
        <TextArea
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={200}
          style={{ width: 880 }}
          value={info}
          disabled={isDisabled}
          onChange={e => setInfo(e.target.value)}
        />
      </div>
      <div className="tpl-line"></div>
      {/* <div className="tpl-point">
        <div className="point">点位</div>
        {!isDisabled && (
          <Search placeholder="搜索增加点位" onSearch={onSearch} style={{ width: 264 }} />
        )}
        {isDisabled && <></>}
      </div> */}
    </div>
  )
}
FirstTpl.defaultProps = {
  isDisabled: false
}
export default FirstTpl
