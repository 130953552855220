import React from 'react';

import './ProductionComp.scss';

const products = [
    {
        name: '普通生料',
        warning: false,
        produceCount: 80,
        phi: 91.2,
        produceRate: 190,
        unit: 't/h',
    },
    {
        name: 'P425水泥',
        warning: true,
        produceCount: 40,
        phi: 71.2,
        produceRate: 190,
        unit: 't/h',
    },
    {
        name: '普通熟料',
        warning: false,
        phi: 91.2,
        produceCount: 92,
        produceRate: 190,
        unit: 't/h',
    },
    {
        name: 'M425水泥',
        warning: false,
        phi: 91.2,
        produceCount: 69,
        produceRate: 190,
        unit: 't/h',
    },
];

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="main_page_bottom_panels_production_comp_wrapper scroll_bar_restyle">
                <div className="production_content_wrapper">
                    {
                        products
                            .map((item, index) => {
                                const { name, warning, phi, produceCount, produceRate, unit } = item;
                                let barContentWidthPercent = 0;
                                let barContentColor = 'transparent';
                                let warningTextColor = '#ffffff';
                                let toTopArrow = null;
                                if (typeof produceCount === 'number' && produceCount >= 0 && produceCount <= 100) {
                                    barContentWidthPercent = produceCount;
                                };
                                if (typeof warning === 'boolean') {
                                    if (warning) {
                                        barContentColor = 'rgba(150, 75, 82, 1)';
                                        warningTextColor = 'rgba(255, 97, 112, 1)';
                                        toTopArrow = false;
                                    } else {
                                        barContentColor = 'rgba(74, 155, 135, 1)';
                                        warningTextColor = 'rgba(68, 243, 218, 1)';
                                        toTopArrow = true;
                                    };
                                };
                                return (
                                    <div className="item_wrapper" key={index}>
                                        <div className="left">
                                            <div className="title">{name}</div>
                                            <div className="bar">
                                                <div className="bar_content" style={{ width: `${barContentWidthPercent}%`, backgroundColor: barContentColor }} />
                                            </div>
                                        </div>
                                        <div className="mid">
                                            <div className="title">PHI</div>
                                            <div className="value_wrapper" style={{ color: barContentColor }}>{phi}%</div>
                                        </div>
                                        <div className="right">
                                            <div className="title">平均产量</div>
                                            <div className="value_wrapper">
                                                <div className="text" style={{ color: barContentColor }}>{produceRate}{unit}</div>
                                                <div className={typeof toTopArrow === 'boolean' ? (toTopArrow ? 'arrow_top' : 'arrow_down') : "arrow_null"} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
