import React from 'react';
import style from './LeftViewer.module.scss';
import { CURR_SYSTEM_ICON } from './constants.js';
import HEAD from "./res/HEAD.svg";
// import { tmp_users_exchange } from '../utils';

export default class LeftViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            offsetWidth: 0,
        }
    }

    componentDidMount() {
        this.calcOffset();
        window.addEventListener('resize', this.calcOffset);
        // window.onresize = this.calcOffset;
    }

    componentDidUpdate({ examDetailUniqueId: prevExamDetailUniqueId }) {
        const { examDetailUniqueId } = this.props;
        if (examDetailUniqueId !== prevExamDetailUniqueId) {
            this.calcOffset();
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcOffset);
    }

    calcOffset = () => {
        // console.log(this.outRefNode);
        if (!!this.outRefNode) {
            // console.log(this.outRefNode.offsetWidth, this.outRefNode.scrollWidth, this.outRefNode.clientWidth);
            let offsetWidth = this.outRefNode.offsetWidth - this.outRefNode.scrollWidth;
            if (offsetWidth < 0) {
                offsetWidth = 0;
            };
            const { offsetWidth: prevOffsetWidth } = this.state;
            if (prevOffsetWidth !== offsetWidth) {
                this.setState({ offsetWidth });
            };
        };
    }

    render() {
        const { examDetail, selectedUserValue, attendUsers, systems } = this.props;
        const { selectUserValue } = this.props;
        const { offsetWidth } = this.state;
        // console.log(CURR_SYSTEM_ICON);
        return (
            <div className={style["exam_analysis_left_viewer_wrapper"]}>
                {
                    examDetail && (
                        <div
                            className="common_row_block viewerTitle"
                            style={{ paddingRight: offsetWidth }}
                        >
                            <div className="left_block" />
                            <div className="right_block right_row_block">
                                <div className="right_block_item right_block_item_rank">当前名次</div>
                                <div className="right_block_item">操作员</div>
                                <div className="right_block_item">当前得分</div>
                                <div className="right_block_item">操作时长h</div>
                            </div>
                        </div>
                    )
                }
                {
                    examDetail && (
                        <div className='leftTables' ref={refNode => this.outRefNode = refNode}>
                            {
                                examDetail
                                    .results
                                    .map((item, index) => {
                                        const { sys_id } = item;

                                        //计算当前系统签到人员列表，需要显示人头icon
                                        let attendUserIdsInCurrentSystem = [];
                                        if (!!attendUsers) {
                                            const filterCurSys = attendUsers
                                                .filter(({ sys_id: sysId }) => sysId === sys_id);
                                            if (filterCurSys.length > 0) {
                                                attendUserIdsInCurrentSystem = filterCurSys[0].users
                                                    .map(({ id }) => id);
                                            };
                                        };

                                        // 计算当前系统名字，需要显示名字
                                        let sysName = '';
                                        if (systems.length > 0) {
                                            const filterSystems = systems
                                                .filter(({ id }) => id === sys_id);
                                            if (filterSystems.length > 0) {
                                                sysName = filterSystems[0].name;
                                            };
                                        };

                                        // 计算当前系统图标，需要显示图片
                                        const sysIconSrc = CURR_SYSTEM_ICON[sys_id] || null;
                                        return (
                                            <div
                                                className="sys_block_wrapper common_row_block"
                                                key={index}
                                            >
                                                <div className="left_block">
                                                    <div className="sys_icon_name_wrapper">
                                                        <img src={sysIconSrc} alt="" className="img" />
                                                        <div
                                                            className="sys_name"
                                                            cusattribute={sysName}
                                                        >{sysName}</div>
                                                    </div>
                                                </div>
                                                <div className="right_table_block">
                                                    {
                                                        item.user_values.map((item2, index2) => {
                                                            const isSelected = !!selectedUserValue && selectedUserValue[0] === index && selectedUserValue[1] === index2;
                                                            const userName = examDetail?.details?.[index]?.users?.[index2]?.user_name ?? "";
                                                            // let tmp_name = "";
                                                            // if (!!tmp_users_exchange && typeof tmp_users_exchange[userName] === 'string') {
                                                            //     tmp_name = tmp_users_exchange[userName];
                                                            // };
                                                            return (
                                                                <div
                                                                    className={"right_row_block" + (isSelected ? " selected_right_row_block" : "")}
                                                                    key={index2}
                                                                    {
                                                                    ...isSelected ?
                                                                        {} : {
                                                                            onClick: () => selectUserValue(index, index2),
                                                                        }
                                                                    }
                                                                >
                                                                    <div className="right_block_item">{item2.final_rank + 1}</div>
                                                                    <div className="right_block_item right_block_item_username">
                                                                        {
                                                                            attendUserIdsInCurrentSystem
                                                                                .includes(item2.user_id) && (
                                                                                <img alt="" src={HEAD} className="user_icon" />
                                                                            )
                                                                        }
                                                                        <div>{userName}</div>
                                                                    </div>
                                                                    <div className="right_block_item">{item2.final_score}</div>
                                                                    <div className="right_block_item">{parseInt(item2.minutes_total * 10 / 60) / 10}</div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })

                            }

                        </div>
                    )
                }
            </div>
        );
    }
}
