import React from 'react';
import { useState, useEffect } from 'react';

import FirstBlock from './FirstBlockContainer.js';
import SecondBlock from './SecondBlockContainer.js';
import AdviceBlock from '../Common/AdviceBlockContainer.js';
import ShiftCompareBlock from '../Common/ShiftCompareBlockContainer.js';

const SingleFactorDetail = (props) => {
    const { selectedFactor } = props;
    const [selectedIds, setSelectedIds] = useState(null);
    useEffect(() => {
        setSelectedIds([selectedFactor.id]);
    }, [selectedFactor]);
    return selectedIds !== null && selectedIds.length > 0 ? (
        <div>
            <FirstBlock selectedFactor={selectedFactor} />
            <div style={{ height: 12 }} />
            <SecondBlock factorId={selectedFactor.id} />
            <div style={{ height: 12 }} />
            <AdviceBlock selectedIds={selectedIds} />
            <div style={{ height: 12 }} />
            <ShiftCompareBlock selectedIds={selectedIds} />
        </div>
    ) : null;
};

export default SingleFactorDetail;
