import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import Wrapper from '../Wrapper';
import * as echarts from 'echarts~5';
import { useData } from './hooks';
import { Spin } from 'antd';
import { bckColors } from './utils';
import DomResize from 'components/DomResize';
import { withRouter } from 'react-router';

const renderChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        // 不论如何，都重置
        // 这里如果在data存在时不重置，在月切换回日时有bug。。。???
        instance.clear();
        if (!data) {
            return;
        };

        const parsedData = data
            .filter((item) => typeof item.cost === 'number' && item.cost >= 0)
            .sort(({ cost: cost_1 }, { cost: cost_2 }) => cost_2 - cost_1)
            .map((item, index) => {
                const { name, cost, pot_cost, unit } = item;
                const nameText = typeof name === 'string' ? name : "--";
                const value_unit_text = '\n' + Math.floor(cost * 10) / 10 + (typeof unit === 'string' ? unit : "");
                const color = bckColors[index > bckColors.length - 1 ? bckColors.length - 1 : index];
                let mid_offset = 1;
                if (cost > 0 && typeof pot_cost === 'number' && pot_cost <= cost) {
                    mid_offset = pot_cost / cost;
                };
                return {
                    name: nameText + value_unit_text,
                    label: {
                        show: true,
                        lineHeight: 16,
                    },
                    value: cost,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 1,
                            y2: 0,
                            colorStops: [
                                {
                                    offset: 0, color: color.begin_color, // 0% 处的颜色
                                },
                                {
                                    offset: mid_offset, color: color.begin_color,
                                },
                                {
                                    offset: mid_offset, color: color.end_color,
                                },
                                {
                                    offset: 1, color: color.end_color, // 100% 处的颜色
                                },
                            ],
                        },
                    },
                };
            });
        // 筛选，从小到大排序

        instance.setOption({
            tooltip: {
                formatter: (info) => {
                    const { data: { name }, marker } = info;
                    return `${marker}${name}`;
                },
            },
            series: [{
                type: 'treemap',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                breadcrumb: {
                    show: false,
                },
                nodeClick: false,
                roam: false,
                data: parsedData,
            }]
        });
    };
};

const Comp = (props) => {
    const { title, icon, timeInfo } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        if (!!timeInfo) {
            setDataFunc.current(timeInfo);
        };
    }, [timeInfo]);

    const refNode = useRef(null);
    useEffect(() => {
        if (!!refNode.current) {
            renderChart(refNode.current, data);
        };
    }, [data]);

    const onResize = () => {
        if (!!refNode.current) {
            const instance = echarts.getInstanceByDom(refNode.current);
            if (!!instance) instance.resize();
        };
    };

    const watchDetailOnClick = () => {
        let suffix = "";
        if (!!timeInfo) {
            suffix += `?time=${timeInfo.time}&timeKey=${timeInfo.key}`;
        };
        const pathname = props?.history?.location?.pathname ?? null;
        if (typeof pathname === 'string' && pathname !== "") {
            const layoutName = pathname.split("/")[1] ?? null;
            if (typeof layoutName === 'string' && layoutName !== "") {
                props.history.push(`/${layoutName}/ProductCost?${suffix}`);
                return;
            };
        };
        props.history.push(`/PEIMSV2/ProductCost${suffix}`);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={icon}
                title={title}
                watchDetail={true}
                watchDetailOnClick={watchDetailOnClick}
            >
                <div className={style['content']}>
                    <div className="resize">
                        <DomResize onResize={onResize} debounce_wait={20} />
                    </div>
                    <div className="tree_map_dom" ref={refNode}></div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Comp);
