import { useEffect, useState } from 'react';
import { get } from 'common/utils';

const Hook = () => {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        let requesting = true;
        setLoading(true);
        get(process.env.REACT_APP_SERVER_PATH + 'api/kpi/prod_system/list', {})
            .then((retData) => {
                if (requesting) {
                    setLoading(false);
                    // console.log('hook', retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        let nextResult = retData?.data?.results;
                        if (!!nextResult) {
                            nextResult = nextResult
                                .map((item, index) => Object.assign({}, item, { key: index + "" }));
                        };
                        setResult(nextResult);
                        return;//其他都算请求失败了
                    };
                };
            });
        return () => {
            requesting = false;
        };
    }, []);
    return [result, loading];
};

export default Hook;
