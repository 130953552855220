import React from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';

const MoreLineChart = (props) => {
    const { moreChartData,moreTimeData } = props;
    useEffect(() => {
        renderChart();
    },[moreChartData,moreTimeData]);
    const chartNode = useRef(null);
    const renderChart = () => {
        if(!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption({
                animation: true,
                tooltip: {
                    trigger: 'axis',
                    symbol: 'none',
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '4%',
                    containLabel: true
                },
                xAxis: {
                    show: false,
                    splitLine:{
                        show: false
                    },
                    type: 'category',
                    data: moreTimeData
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisTick:{ //y轴刻度线
                        show:false
                    },
                    axisLine:{ //y轴
                        show:false
                    },
                },
                dataZoom: [{
                    type: 'inside',
                    start: 0,
                    end: 10
                }, {
                    start: 0,
                    end: 10
                }],
                series: moreChartData
            })
        }
    };
    return (
        <div>
            <div ref={chartNode} style={{ width: 808, height: 400 }} />
        </div>
    );
}
export default MoreLineChart
