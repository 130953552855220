import React from 'react';
import style from './index.module.scss';
import Layout from '../Layout';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';

const Page = (props) => {

    return (
        <Layout
            title="碳管家"
            width={2688}
            height={1728}
        >
            <div className={style['wrapper']}>
                <div className="block_1 left">
                    <Block1 />
                </div>
                <div className="block_1 right">
                    <Block2 />
                </div>
                <div className="block_2 left">
                    <Block3 />
                </div>
                <div className="block_2 right">
                    <Block4 />
                </div>
            </div>
        </Layout>
    );
};

export default Page;
