import React from 'react';
import { message } from 'antd';
import ChartBlock from './ChartBlockContainer.js';
import ARROW_ICON from './res/arrow.svg';
import style from './RightBlock.module.scss';
import COLORED_ICON from 'components/Icon';

const moment = require('moment');
const RGBs = [[0, 204, 148], [250, 84, 28], [255, 182, 40]];
export default class RightBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            eventsUnfold: true,
            trendsUnfold: true,
        };
    }

    componentDidUpdate({ contentItemWidth: prevContentItemWidth }) {
        const { contentItemWidth } = this.props;
        if (prevContentItemWidth !== contentItemWidth) {
            //resize自适应
            // console.log(prevContentItemWidth !== contentItemWidth, prevContentItemWidth, contentItemWidth);
        };
    }
    statusOnchange = (hour, status) => {
        const { statusOnchange } = this.props;
        // console.log(hour, status);
        statusOnchange(hour, status)
            .then(isSuccess => {
                // console.log(isSuccess);
                if (isSuccess) {
                    message.success('更新成功！');
                    const { getStatus } = this.props;
                    getStatus();
                } else {
                    message.error('更新失败！');
                };
            });
    }

    render() {
        const { staticTrendMetas, cusMetas, status, events, date } = this.props;
        return (
            <div className={style["sample_maintenance_right_block_wrapper"]}>
                <div className="block_header">
                    <div className="title_block">时刻(时)</div>
                    <div className="items_wrapper">
                        {
                            new Array(24)
                                .fill(0)
                                .map((_, index) => (
                                    <div key={index} className="item_block">
                                        <div className="text">{index}</div>
                                    </div>
                                ))
                        }
                    </div>
                    <div className="text out_text">24</div>
                </div>
                <div className="row_block row_block_item">
                    <div className="block_title block_title_first">是否对标</div>
                    <div className="items_wrapper">
                        {
                            new Array(24)
                                .fill(0)
                                .map((_, index) => (
                                    <div
                                        key={index}
                                        className="block_item"
                                    >
                                        <div
                                            className={"circle" + (status[index] === 1 ? ' circle_checked' : '')}
                                            onClick={() => this.statusOnchange(index, 1 - status[index])}
                                        />
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className="row_block row_block_space">
                    <div className="block_title" />
                    <div className="items_wrapper">
                        {
                            new Array(24)
                                .fill(0)
                                .map((_, index) => (
                                    <div key={index} className="block_item" />
                                ))
                        }
                    </div>
                </div>
                <div className="row_block row_block_item">
                    <div className="block_title block_title_1">
                        <COLORED_ICON width={11} height={16} src={ARROW_ICON} color={[24,144,255]} />
                        <div className="text">事件记录</div>
                    </div>
                    <div className="items_wrapper">
                        {
                            new Array(24)
                                .fill(0)
                                .map((_, index) => (
                                    <div key={index} className="block_item" />
                                ))
                        }
                    </div>
                </div>
                {
                    events
                        .map((event, eventIndex) => {
                            const { name, time_begin, time_end, id } = event;
                            const beginUnix = moment(moment(date).format('YYYY-MM-DD 00:00:00')).unix();
                            const endUnix = moment(moment(date).add(1, 'days').format('YYYY-MM-DD 00:00:00')).unix();
                            const timeBeginUnix = moment(time_begin).unix();
                            const timeEndUnix = moment(time_end).unix();
                            const allSeconds = 86400;
                            let left = (timeBeginUnix - beginUnix) / allSeconds * 100;
                            let right = (endUnix - timeEndUnix) / allSeconds * 100;
                            let showAllTimeStamp = false;
                            let leftBorder = true;
                            if (left < 0 || left > 100) {
                                showAllTimeStamp = true;
                                leftBorder = false;
                                if (left < 0) left = 0;
                                if (left > 100) left = 100;
                            };
                            if (right < 0 || right > 100) {
                                showAllTimeStamp = true;
                                if (right < 0) right = 0;
                                if (right > 100) right = 100;
                            };
                            const displayTimeStr =
                                showAllTimeStamp ?
                                    (
                                        moment(time_begin).format('YYYY-MM-DD HH:mm') + '-' + moment(time_end).format('YYYY-MM-DD HH:mm')
                                    ) : (
                                        moment(time_begin).format('HH:mm') + '-' + moment(time_end).format('HH:mm')
                                    );
                            return (
                                <div className="row_block row_block_item" key={eventIndex}>
                                    <div className="block_title block_title_2">{name}</div>
                                    <div className="items_wrapper">
                                        {
                                            new Array(24)
                                                .fill(0)
                                                .map((_, index) => (
                                                    <div key={index} className="block_item" />
                                                ))
                                        }
                                        <div className="display_wrapper">
                                            <div
                                                className="event_item"
                                                style={{
                                                    left: left + '%',
                                                    right: right + '%',
                                                    backgroundColor: `rgba(${RGBs[id % RGBs.length].join(',')},0.2)`,
                                                    borderLeft: `${leftBorder ? 4 : 0}px solid rgba(${RGBs[id % RGBs.length].join(',')},1)`,
                                                    color: `rgba(${RGBs[id % RGBs.length].join(',')},1)`,
                                                    paddingLeft: (leftBorder ? 12 : 0) + 'px',
                                                }}
                                            >
                                                <div className="name" title={name}>{name}</div>
                                                <div className="time" title={displayTimeStr}>{displayTimeStr}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                }
                {
                    events.length > 0 && (
                        <div className="row_block row_block_events_bottom">
                            <div className="block_title" />
                            <div className="items_wrapper">
                                {
                                    new Array(24)
                                        .fill(0)
                                        .map((_, index) => (
                                            <div key={index} className="block_item" />
                                        ))
                                }
                            </div>
                        </div>
                    )
                }
                <div className="row_block row_block_space">
                    <div className="block_title" />
                    <div className="items_wrapper">
                        {
                            new Array(24)
                                .fill(0)
                                .map((_, index) => (
                                    <div key={index} className="block_item" />
                                ))
                        }
                    </div>
                </div>
                <div className="row_block row_block_item">
                    <div className="block_title block_title_1">
                        <COLORED_ICON width={11} height={16} src={ARROW_ICON} color={[24,144,255]} />
                        <div className="text">参数趋势</div>
                    </div>
                    <div className="items_wrapper">
                        {
                            new Array(24)
                                .fill(0)
                                .map((_, index) => (
                                    <div key={index} className="block_item" />
                                ))
                        }
                    </div>
                </div>
                {
                    staticTrendMetas
                        .concat(cusMetas)
                        .map((trendMeta, index) => (
                            <div className="row_block row_block_chart" key={trendMeta.tag_name + '_' + index}>
                                <div className="block_title block_title_2">{trendMeta.name}</div>
                                <div className="items_wrapper">
                                    {
                                        new Array(24)
                                            .fill(0)
                                            .map((_, index) => (
                                                <div key={index} className="block_item" />
                                            ))
                                    }
                                    <div className="display_wrapper">
                                        <ChartBlock trendMeta={trendMeta} />
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
        );
    }
}
