import Page from './ThreeComp.js';
import { connect } from 'react-redux';
import { get } from '../../../common/utils';
import { queryTimeRangeFormat } from './utils.js';

const get3DPanelInfo = () => {
    return (dispatch, getState) => {
        const { mainPage } = getState();
        const { timeRange } = mainPage;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        return get(process.env.REACT_APP_SERVER_PATH + 'api/overview/get_3d_panel_info', {
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                return {
                    isSuccess: true,
                    results: retData.data.results,
                };
            };
            return {
                isSuccess: false,
            };
        });
    };
};

const mapStateToProps = state => {
    const { mainPage } = state;
    const { panel3DInfo, timeRange } = mainPage;
    return {
        panel3DInfo,
        timeRange,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        get3DPanelInfo: () => dispatch(get3DPanelInfo()),
    };
}

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;