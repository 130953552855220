import React, { useEffect, useRef } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts~5';
import moment from 'moment';

const renderChart = (refNode, data, lower_limit, upper_limit) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!data) {
            instance.clear();
            return;
        };

        const xData = data.map(([time]) => moment(time).format("YYYY-MM-DD"));
        const yData = data.map(([, value]) => {
            let isGood = null;
            if (typeof value === 'number') {
                let lower_good = null;
                if (typeof lower_limit === 'number') {
                    if (lower_limit <= value) {
                        lower_good = true;
                    } else {
                        lower_good = false;
                    };
                };
                let upper_good = null;
                if (typeof upper_limit === 'number') {
                    if (upper_limit >= value) {
                        upper_good = true;
                    } else {
                        upper_good = false;
                    };
                };
                if (lower_good === false || upper_good === false) {
                    isGood = false;
                } else {
                    if (lower_good === true || upper_good === true) {
                        isGood = true;
                    };
                };
            };
            return {
                value,
                ...(
                    typeof isGood === 'boolean' ? {
                        itemStyle: {
                            color: isGood ? '#3AA372' : '#FB6060',
                        },
                    } : {}
                )
            };
        });

        const allValues = yData
            .map(({ value }) => value)
            .concat([upper_limit, lower_limit])
            .filter((v) => typeof v === 'number');
        let y_min = 0;
        let y_max = 1;
        if (allValues.length > 0) {
            const min = Math.min(...allValues);
            const max = Math.max(...allValues);
            const delta = max - min + 1;
            // 使delta>0
            y_min = min - delta / 10;
            y_max = max + delta / 10;
            if (min >= 0 && y_min < 0) {
                y_min = 0;
            };
            if (max <= 0 && y_max > 0) {
                y_max = 0;
            };
        };

        const option = {
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const [{ axisValueLabel, data: { value }, marker }] = params;
                    const parsed_value = typeof value === 'number' ? Math.floor(value * 1000) / 1000 : "--";
                    return `${axisValueLabel}<br />${marker}${parsed_value}`;
                },
            },
            grid: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 2,
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                show: false,
                // scale: true,// true时不一定显示0
                min: y_min,
                max: y_max,
            },
            series: [
                {
                    data: yData,
                    type: 'bar',
                    barWidth: 8,
                    itemStyle: {
                        color: '#355B99',
                        borderRadius: 3,
                    },
                    markLine: {
                        silent: true,
                        symbol: 'none',
                        precision: 4,
                        lineStyle: {
                            type: "dashed",
                            color: "#355B99",
                        },
                        label: {
                            show: false,
                        },
                        data: []
                            .concat(
                                typeof lower_limit === 'number' ? [
                                    {
                                        yAxis: lower_limit,
                                    }
                                ] : [],
                                typeof upper_limit === 'number' ? [
                                    {
                                        yAxis: upper_limit,
                                    }
                                ] : [],
                            ),
                    },
                },
            ]
        };
        instance.setOption(option);
    };
};

const Comp = (props) => {
    const { history_values, lower_limit, upper_limit, } = props;

    const refNode = useRef(null);
    useEffect(() => {
        if (!!refNode.current) {
            renderChart(refNode.current, history_values, lower_limit, upper_limit);
        };
    }, [history_values, lower_limit, upper_limit]);

    return (
        <div className={style["wrapper"]}>
            <div className="chart" ref={refNode}></div>
        </div>
    );
};

export default Comp;
