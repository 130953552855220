import React, { useState, useEffect, useRef, useMemo } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';
import { renderChart } from './utils.js';

const Comp = (props) => {
    const { layoutChange, config } = props;
    const [winResize, setWinResize] = useState(null);
    useEffect(() => {
        const func = () => {
            try {
                setWinResize(Symbol());
            } catch (e) { };
        };
        window.addEventListener("resize", func);
        return () => { window.removeEventListener("resize", func) };
    }, []);
    useEffect(() => {
        // 外部echart size变化;
        if (!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!!instance) {
                instance.resize();
            };
        };
    }, [layoutChange, winResize]);
    const chartNode = useRef(null);

    const [min, max] = useMemo(() => {
        const min = props?.config?.y_axis?.lower_limit ?? null;
        const max = props?.config?.y_axis?.upper_limit ?? null;
        return [min, max];
    }, [props]);
    const chartConf = useMemo(() => {
        return { min, max };
    }, [min, max])
    useEffect(() => {
        renderChart(chartNode.current, chartConf);
    }, [chartConf]);

    return (
        <div className={style["wrapper"]}>
            <div className="header">
                <div className="colored_bar"></div>
                <div className="title">{config?.title ?? "--"}</div>
            </div>
            <div className="content">
                <div ref={chartNode} style={{ height: '100%', overflow: 'hidden' }} />
            </div>
        </div>
    );
};

export default Comp;
