const tableSuffixList = ['_D', '_D', '_W', '_W', '_M', '_M'];
const chartSuffixList = ['_H', '_H', '_D', '_D', '_D', '_D'];

const tableExtdList = ['_kWh2', '_kWh1', '_kWh0', '_elchrg2', '_elchrg1', '_elchrg0'];
// const chartExtdList = ['_kWhZ', '_elchrg', '_elprcx'];
const chartExtdList = ['_kWhZ', '_elchrg', '_elprcx', '_kWh2', '_kWh1', '_kWh0'];

export {
    tableSuffixList,
    chartSuffixList,
    tableExtdList,
    chartExtdList,
};
