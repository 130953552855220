import React, { useState } from 'react';
import style from './index.module.scss';
import PageTabs from './PageTabs';

const Page = (props) => {
    const [selectedPageTab, setSelectedPageTab] = useState(null);
    const pageTabOnChange = (nextPageTab) => {
        setSelectedPageTab(nextPageTab);
    };
    return (
        <div className={style['wrapper']}>
            <PageTabs selectedPageTab={selectedPageTab} onChange={pageTabOnChange} />
            <div className="content">
                {!!selectedPageTab && selectedPageTab.comp}
            </div>
        </div>
    );
};

export default Page;
