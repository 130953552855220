import React from 'react';
import { Input, Select, InputNumber } from 'antd';
import style from './ScoreIntervalLimit.module.scss';
import {
    PlusCircleOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

const Option = Select.Option;
const { TextArea } = Input;
const LIMIT_TYPE = [{
    id: 1,
    name: '≥',
}, {
    id: 2,
    name: '≤',
}];

const MAX_INTERVAL_NUMBER = 6;
const MIN_INTERVAL_NUMBER = 2;
const MIN_INTERVAL_NUMBER_CREATE = 4;
export default class ScoreIntervalLimit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            intervalNumber: props.isCreate ? MIN_INTERVAL_NUMBER_CREATE : MIN_INTERVAL_NUMBER,
        };
    }

    addIntervalNumber = renderIntervalNumber => {
        this.setState({
            intervalNumber: renderIntervalNumber + 1,
        });
    }

    deleteInterval = (displayIndex, renderIntervalNumber) => {
        const { deleteIntervalRow = () => { } } = this.props;
        deleteIntervalRow(displayIndex);
        this.setState({
            intervalNumber: Math.max(renderIntervalNumber - 1, MIN_INTERVAL_NUMBER),
        });
    }

    render() {
        const { intervalNumber } = this.state;
        const { selectedLimitType, selectedLimitTypeOnChange = () => { }, score_details, intervalValueOnChange = () => { }, remark, remarkOnChange = () => { } } = this.props;
        const { dev_intervals = [] } = score_details;
        const propIntervalNumber = dev_intervals
            .filter(([v1, v2, v3] = []) => !!v1 || !!v2 || !!v3)
            .length;
        const renderIntervalNumber = Math.max(intervalNumber, propIntervalNumber);
        return (
            <div className={"score1_wrapper " + style["score_interval_limit_wrapper"]}>
                <div className="title">指标积分规则：</div>
                <table
                    className="content_table"
                >
                    <thead>
                        <tr>
                            <th>序号</th>
                            <th>原始得分</th>
                            <th>指标上下限</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array(renderIntervalNumber)
                                .fill(0)
                                .map((item, index) => {
                                    const displayIndex = index === renderIntervalNumber - 1 ? MAX_INTERVAL_NUMBER - 1 : index;
                                    const curInterval = dev_intervals[displayIndex] || [];
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <InputNumber value={curInterval[2] || 0} onChange={v => intervalValueOnChange(v, displayIndex, 2)} style={{ width: 80 }} />
                                            </td>
                                            <td style={{ width: 240 }}>
                                                <div
                                                    className="range_td"
                                                >
                                                    {
                                                        displayIndex === 0 && (
                                                            <div
                                                                style={{ width: 80 }}
                                                            >X</div>
                                                        )
                                                    }
                                                    {
                                                        displayIndex === 0 ?
                                                            <div
                                                                style={{
                                                                    flexGrow: 1,
                                                                    width: 0,
                                                                }}
                                                            >
                                                                <Select
                                                                    style={{ width: 60 }}
                                                                    value={selectedLimitType}
                                                                    onChange={selectedLimitTypeOnChange}
                                                                >
                                                                    {
                                                                        LIMIT_TYPE.map(({ id, name }) =>
                                                                            <Option key={id}>{name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </div>
                                                            : (displayIndex === MAX_INTERVAL_NUMBER - 1 && selectedLimitType == 1) ?
                                                                <div className="min_max_block"></div>
                                                                :
                                                                <InputNumber
                                                                    // min={0}
                                                                    value={curInterval[0] || 0} onChange={v => intervalValueOnChange(v, displayIndex, 0)}
                                                                    className="min_max_block"
                                                                />
                                                    }
                                                    {
                                                        (index !== 0 && index !== renderIntervalNumber - 1) && (
                                                            <div className="range_mid_text">
                                                                <div className="left_text">{selectedLimitType == 1 ? '≤' : '<'}</div>
                                                                <div className="mid_text">X</div>
                                                                <div className="right_text">{selectedLimitType == 1 ? '<' : '≤'}</div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        index === renderIntervalNumber - 1 && (
                                                            <div className="range_mid_text">
                                                                <div className="left_text">{selectedLimitType == 1 ? '' : '<'}</div>
                                                                <div className="mid_text">X</div>
                                                                <div className="right_text">{selectedLimitType == 1 ? '<' : ''}</div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (displayIndex === MAX_INTERVAL_NUMBER - 1 && selectedLimitType == 2) ?
                                                            <div className="min_max_block"></div>
                                                            :
                                                            <InputNumber
                                                                // min={0}
                                                                value={curInterval[
                                                                    selectedLimitType == 1 ?
                                                                        (displayIndex === 0 ? 0 : 1) :
                                                                        (displayIndex === MAX_INTERVAL_NUMBER - 1 ? 0 : 1)
                                                                ] || 0}
                                                                onChange={v => intervalValueOnChange(
                                                                    v,
                                                                    displayIndex,
                                                                    selectedLimitType == 1 ?
                                                                        (displayIndex === 0 ? 0 : 1) :
                                                                        (displayIndex === MAX_INTERVAL_NUMBER - 1 ? 0 : 1),
                                                                )}
                                                                className="min_max_block"
                                                            />
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    (index !== 0 && index !== renderIntervalNumber - 1) && (
                                                        <DeleteOutlined
                                                            style={{ fontSize: 20 }}
                                                            onClick={() => this.deleteInterval(displayIndex, renderIntervalNumber)}
                                                        />
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                        }
                        {
                            renderIntervalNumber < MAX_INTERVAL_NUMBER && (
                                <tr>
                                    <td>
                                        <PlusCircleOutlined
                                            style={{ fontSize: 20, color: 'blue' }}
                                            onClick={() => this.addIntervalNumber(renderIntervalNumber)}
                                        />
                                    </td>
                                    <td colSpan="4" />
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <div className="row_wrapper">
                    <div className="title" style={{ alignSelf: 'flex-start' }}>备注：</div>
                    <TextArea rows={4} value={remark} onChange={e => remarkOnChange(e.target.value)} />
                </div>
            </div>
        );
    }
}