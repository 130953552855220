import BoardTop from './BoardTop.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { mainPageV2: { sys_id, layout, tag2Value, oneResult } } = state;
    let panel_1 = [];
    let eco_result = {};
    if (!!layout) {
        panel_1 = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0].panel_1;
    };
    if (oneResult) {
        eco_result = oneResult.result.kpi_result.eco_result;
    };
    return {
        panel_1,
        eco_result,
        tag2Value,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

const WrapperedBoardTop = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoardTop);

export default WrapperedBoardTop;