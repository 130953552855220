import React from 'react';
import { message, Spin } from 'antd';
import { get, post } from '../../../../common/utils.js';
import './index.scss';
import Header from '../Header.js';
import ReportShareTabs from '../ReportShareTabs.js';
import TableViewer from '../TableViewer';
import { getDefaultTimeRange } from '../utils.js';

import ICON_PLUS from '../res/plus.png';
import ICON_EDIT from '../res/edit.png';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reportShared: false,
            selectedReportId: null,
            timeTypeConf: null,
            reportList: null,
            tableResult: null,
            timeRange: null,
            hideTimePicker: false,
            loading: false,
        };
    }

    componentDidMount() {
        this.getTimeType();
        this.getReportList();
    }

    getTimeType = () => {
        get(process.env.REACT_APP_SERVER_PATH + 'report/get_int_2_time_type', {})
            .then((retData) => {
                // console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    this.setState({ timeTypeConf: retData.data.results });
                };
            });
    }

    getReportList = () => {
        post(process.env.REACT_APP_SERVER_PATH + 'report/list', {
            user_id: 1,
        })
            .then((retData) => {
                // console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    const reportList = retData.data.results;
                    this.setState({ reportList });

                    const selfList = reportList.filter(({ shared }) => shared === false)[0];
                    if (!!selfList) {
                        this.reportItemOnClick(selfList.id);
                    };
                };
            })
    }

    getReportDetail = (cus_t_range, cus_id) => {
        let { selectedReportId: report_conf_id } = this.state;
        if (!!cus_id) {
            report_conf_id = cus_id;
        };

        let time_begin, time_end;
        if (!!cus_t_range) {
            [time_begin, time_end] = cus_t_range;
        } else {
            const { reportList } = this.state;
            const currReport = reportList.filter(({ id }) => id === report_conf_id)[0];
            [time_begin, time_end] = getDefaultTimeRange(currReport.timeType, currReport.range);
        };
        // console.log(time_begin, time_end, currReport.timeType);
        // return;
        this.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'report/query_report_content', {
            report_conf_id,
            time_begin,
            time_end,
            // time_begin: '2020-10-05 00:00:00',
            // time_end: '2020-10-08 00:00:00',
        })
            .then((retData) => {
                // console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    this.setState({
                        tableResult: null,
                        loading: false,
                        selectedReportId: report_conf_id,
                        timeRange: [time_begin, time_end],
                    }, () => {
                        this.setState({ tableResult: retData.data.results });
                    });
                } else {
                    message.error("获取数据失败，请检查网络或重试！");
                    this.setState({ loading: false });
                };
            });
    }

    reportItemOnClick = (id) => {
        this.getReportDetail(null, id);
    }

    timeOnChange = (t_range) => {
        // console.log(t_range);
        this.getReportDetail(t_range);
    }

    refreshTimePicker = () => {
        this.setState({ hideTimePicker: true }, () => {
            this.setState({ hideTimePicker: false });
        });
    }

    tabOnChange = (reportShared) => {
        this.setState({ reportShared });
    }

    createReport = () => {
        const { editReportOnClick } = this.props;
        if (typeof editReportOnClick === 'function') {
            editReportOnClick();
        };
    }

    editReport = (reportId) => {
        const { editReportOnClick } = this.props;
        if (typeof editReportOnClick === 'function') {
            editReportOnClick(reportId);
        };
    }

    render() {
        const {
            reportShared, selectedReportId,
            reportList, timeTypeConf, tableResult,
            timeRange, hideTimePicker,
            loading,
        } = this.state;

        let tableBlockTitle = "";
        let selectedReport = null;
        if (typeof selectedReportId === "number" && !!reportList) {
            const tmp_selectedReport = reportList.filter(({ id }) => id === selectedReportId)[0];
            selectedReport = tmp_selectedReport;
            if (!!tmp_selectedReport) {
                tableBlockTitle = tmp_selectedReport.name;
            };
        };

        return (
            <div className="statistical_report_Zj3eM" onClick={this.refreshTimePicker} style={{ position: 'relative' }}>
                <div className="statistical_report_header_wrapper">
                    <Header selectedReport={selectedReport} timeRange={timeRange} timeOnChange={this.timeOnChange} hideTimePicker={hideTimePicker} />
                </div>
                <div className="statistical_report_content_wrapper">
                    <div style={{ height: '100%', display: 'flex' }}>
                        <div style={{ width: 360, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: 46, border: '1px solid rgba(68,242,217,0.2)', backgroundColor: '#15191E' }}>
                                <ReportShareTabs shared={reportShared} onChange={this.tabOnChange} />
                            </div>
                            <div style={{ height: 0, flexGrow: 1, backgroundColor: '#212830' }}>
                                <div className="scroll_bar_restyle_0" style={{ height: '100%', overflowY: 'auto' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                                        {
                                            !!reportList
                                            && reportList
                                                .filter(({ shared }) => reportShared === shared)
                                                .map((item, index) => {
                                                    const { timeType, name, id } = item;
                                                    let timeTypeText = "-";
                                                    if (timeTypeConf !== null) {
                                                        const currTimeTypeText = timeTypeConf[timeType];
                                                        if (typeof currTimeTypeText === "string") {
                                                            timeTypeText = currTimeTypeText;
                                                        };
                                                    };
                                                    return (
                                                        <div key={id} className={"left_list_item_wrapper" + (selectedReportId === id ? " selected_left_list_item_wrapper" : "")} onClick={() => this.reportItemOnClick(id)}>
                                                            <div style={{ height: "100%", paddingLeft: 20, display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: 57, height: 20, border: 'solid 1px rgba(68,243,218,0.4)', display: 'flex', alignItems: "center", justifyContent: "center", marginRight: 12 }}>
                                                                    <div style={{ fontSize: 12, lineHeight: '12px', height: 12, color: '#44F3DA' }}>{timeTypeText}</div>
                                                                </div>
                                                                <div style={{ fontSize: 14, lineHeight: '14px', height: 14, color: '#FFFFFF', opacity: 0.8 }}>{name}</div>
                                                            </div>
                                                            <div
                                                                className="edit_icon"
                                                                style={{ width: 24, height: 24, backgroundImage: `url(${ICON_EDIT})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}
                                                                onClick={(e) => { e.stopPropagation(); this.editReport(id) }}
                                                            ></div>
                                                        </div>
                                                    );
                                                })
                                        }
                                        <div style={{ height: 0, flexGrow: 1, borderRight: 'solid 1px rgba(68,243,218,0.4)' }} />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{ height: 60, backgroundColor: '#41ECD4', display: 'flex', alignItems: 'center', justifyContent: 'center', userSelect: 'none', cursor: 'pointer' }}
                                onClick={this.createReport}
                            >
                                <div style={{ width: 30, height: 30, backgroundImage: `url(${ICON_PLUS})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }} />
                                <div style={{ marginLeft: 6, fontSize: 20, color: '#0D1115', lineHeight: '20px', height: 20 }}>新建报表</div>
                            </div>
                        </div>
                        <div style={{ width: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ height: 47, border: '1px solid rgba(68,242,217,0.2)', backgroundColor: '#15191E', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ fontSize: 16, lineHeight: '16px', height: 16, color: '#44F3DA' }}>{tableBlockTitle}</div>
                            </div>
                            <div style={{ height: 0, backgroundColor: 'rgba(21,25,30,1)', flexGrow: 1, borderStyle: 'solid', borderColor: 'rgba(68,243,218,0.4)', borderWidth: '0 1px 1px 0' }}>
                                {
                                    tableResult !== null && !!selectedReport && (
                                        <TableViewer tableResult={tableResult} timeType={selectedReport.timeType} sequence={selectedReport.sequence} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loading && (
                        <div style={{ position: 'absolute', zIndex: 2, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
        );
    }
}
