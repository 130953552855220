import Monitor from 'containers/Monitor';
import MainPageV2 from './MainPageV2';
import SampleMaintenance from './SampleMaintenance/SampleMaintenanceContainer';
import AlarmPage from './AlarmPage/AlarmPage';
import FactorsBoard from './FactorsBoard';

// 支持不存在children的tab
const tabs = [
    {
        name: '指标看板',
        comp: FactorsBoard,
    },
    {
        name: '操作看板',
        children: [
            {
                name: '能耗寻优',
                comp: MainPageV2,
            },
            {
                name: '样本维护',
                comp: SampleMaintenance,
            },
            {
                name: '实时报警',
                comp: AlarmPage,
            },
        ],
    },
    {
        name: '监控看板',
        children: [
            {
                name: '生产流程',
                comp: Monitor,
                props: {
                    value: {
                        comp: null,
                        diagramId: '3q5hN7c_Sm6gRlBRbHyjpg',
                    },
                },
            },
            {
                name: '关键设备',
                comp: Monitor,
                props: {
                    value: {
                        comp: null,
                        diagramId: 'xSbY1TAsSYiMj0x0iZ2MAw',
                    },
                },
            },
        ]
            .map((child) => {
                const { props } = child;
                let nextProps = { controlled: true };
                if (!!props) {
                    nextProps = Object.assign({}, nextProps, props);
                };
                return Object.assign({}, child, { props: nextProps });
            }),
    },
];

export {
    tabs,
};
