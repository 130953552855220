import React from 'react'
import { Menu, Dropdown, Table, Button, message, Modal, Badge, Select } from 'antd';
import TopBreadcrumb from 'components/TopBreadCrumb'
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { get, post, patch, reqDelete } from 'common/utils.js';
import RuleEditor from './RuleEditor.js';
import RuleBar from './RuleBar';
import style from './RuleList.module.scss';

const Column = Table.Column;
const Option = Select.Option;

const breadcrumbList = [{ name: '指标考核' }, { name: '规则配置' }]

const STATUS_ENUM = {
    '1': {
        text: '已启用',
        badge: 'success',
    },
    '0': {
        text: '已停用',
        badge: 'error',
    },
}

const WEIGHT_ENUM = {
    '1': '一般',
    '1.5': '重要',
}

export default class RuleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sys_id: "ALL",
            page: 1,
            pageSize: 8,
            total: undefined,
            dataList: [],
            loading: false,
            editorVisible: false,
            editorData: {}
        }
    }

    componentDidMount() {
        this.getDataList();
    }

    getDataList = (page) => {
        let self = this;
        self.setState({ loading: true });
        let params = {
            page_size: this.state.pageSize,
            page: page,
        };
        if(this.state.sys_id !== 'ALL'){
            params.sys_id = parseInt(this.state.sys_id);
        }
        get(process.env.REACT_APP_SERVER_PATH + 'api/exam/exam_rules', params).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results, total: retData.data.count, page: page})
            };
        });
    }

    editItem = r => {
        this.setState({ editorData: r, editorVisible: true, editorType: 'EDIT' });
    }

    createItem = _ => {
        let editorData = {
            sys_id: 1,
            src_type: 1,
            weight: 1,
            tag_name: null,
            intervals: [{ "color": "#FB6060", "score": -100, "lower_limit": null, "upper_limit": null }, { "color": "#FFDA77", "score": 0, "lower_limit": null, "upper_limit": null }, { "color": "#5BAE8D", "score": 100, "lower_limit": null, "upper_limit": null }]
        };
        this.setState({ editorVisible: true, editorType: 'CREATE', editorData: editorData });
    }

    delRule = (record) => {
        let self = this;
        Modal.confirm({
            content: `确认删除规则 ${record.tag_name} 吗?`,
            onOk: () => {
                self.setState({ loading: true });
                patch(process.env.REACT_APP_SERVER_PATH + 'api/exam/exam_rules/' + record.id, {
                    "status": -1,
                }).then((retData) => {
                    self.setState({ loading: false });
                    if (retData.data) {
                        message.success("删除成功");
                        self.getDataList(1);
                    } else {
                        message.error("删除失败");
                    }
                });
            },
        });
    }

    updateStatus = (record, status) => {
        let self = this;
        self.setState({ loading: true });
        patch(process.env.REACT_APP_SERVER_PATH + 'api/exam/exam_rules/' + record.id, {
            "status": status,
        }).then((retData) => {
            self.setState({ loading: false });
            if (retData.data) {
                message.success("操作成功");
                self.getDataList(1);
            } else {
                message.error("操作失败");
            }
        });
    }

    render() {
        let self = this;
        let { editorVisible, editorData, editorType, sys_id } = this.state;
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                <div className="RuleList">
                    <div className="toolBar">
                        <div className="leftTools">
                            <Select
                                // className="search-select"
                                value={sys_id+''}
                                style={{ width: 200 }}
                                defaultValue={sys_id+''}
                                onChange={v=>this.setState({sys_id: v}, ()=>this.getDataList(1))}
                            >
                                <Option value='ALL' key='ALL'>全部系统</Option>
                                <Option value='1' key='1'>熟料烧成</Option>
                                <Option value='2' key='2'>水泥粉磨</Option>
                                <Option value='3' key='3'>原料粉磨</Option>
                                <Option value='4' key='4'>煤粉制备</Option>
                            </Select>
                        </div>
                        <div className="rightTools">
                            <Button onClick={_ => this.createItem()} type="primary"><PlusOutlined />添加</Button>
                        </div>
                    </div>
                    <div className="table_wrapper">
                        <Table
                            rowKey='id'
                            dataSource={this.state.dataList}
                            loading={this.state.loading}
                            pagination={{
                                defaultCurrent: 1,
                                total: this.state.total,
                                pageSize: this.state.pageSize,
                                showSizeChanger: true,
                                showQuickJumper: false,
                                current: this.state.page,
                            }}
                            onChange={pagination =>
                                this.setState({ pageSize: pagination.pageSize }, () => self.getDataList(pagination.current))
                            }
                            rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                        >
                            <Column
                                title='指标名称'
                                key='name'
                                render={r => {
                                    if (r.name != null && r.name != '') {
                                        return [
                                            <div key='1'>{r.name}</div>
                                            ,
                                            <div key="2">{r.tag_name}</div>
                                        ]
                                    } else {
                                        return r.tag_name
                                    }
                                }}
                            />
                            <Column
                                title='所属系统'
                                key='sys_name'
                                dataIndex='sys_name'
                            />
                            <Column
                                title='状态'
                                key='status'
                                dataIndex='status'
                                render={status => {
                                    if (status == 1 || status == 0) {
                                        return (<span><Badge status={STATUS_ENUM[status + ''].badge} />{STATUS_ENUM[status + ''].text}</span>);
                                    } else {
                                        return '';
                                    }
                                }}
                            />
                            <Column
                                title='指标重要性'
                                key='weight'
                                render={r => {
                                    if (r.weight + '' in WEIGHT_ENUM) {
                                        return WEIGHT_ENUM[r.weight + '']
                                    } else {
                                        return r.weight
                                    }
                                }}
                            />
                            <Column
                                title='指标规则'
                                key='rule_bar'
                                render={(record, index) => {
                                    let items = record.intervals;
                                    return (
                                        <RuleBar
                                            style={{ width: '100%' }}
                                            data={{
                                                color_1: items[0].color,
                                                color_2: items[1].color,
                                                color_3: items[2].color,
                                                limit_1: items[0].lower_limit,
                                                limit_2: items[0].upper_limit,
                                                limit_3: items[2].lower_limit,
                                                limit_4: items[2].upper_limit,
                                                score_1: items[0].score,
                                                score_2: items[1].score,
                                                score_3: items[2].score,
                                            }}
                                        />
                                    )
                                }}
                            />
                            <Column
                                title='创建人'
                                key='user_name'
                                dataIndex='user_name'
                            />
                            <Column
                                title='创建时间'
                                key='create_time'
                                dataIndex='create_time'
                            />
                            <Column
                                title='操作'
                                key='opts'
                                render={(text, record) => [
                                    <span onClick={() => this.setState({ currentUserId: record.id })} key="1">
                                        <Button type="link" size="small" onClick={(e) => this.editItem(record)} style={{ color: '#357CF7' }}>编辑</Button>
                                    </span>
                                    ,
                                    <Dropdown key="dropdown" overlay={(
                                        <Menu onClick={({ key }) => {
                                            if (key == 'PAUSE') {
                                                self.updateStatus(record, 0);
                                            } else if (key == 'RESUME') {
                                                self.updateStatus(record, 1);
                                            } else if (key == 'DEL') {
                                                self.delRule(record);
                                            }
                                        }} key="2">
                                            {record.status !== 0 && <Menu.Item key="PAUSE">停用</Menu.Item>}
                                            {record.status !== 1 && <Menu.Item key="RESUME">启用</Menu.Item>}
                                            <Menu.Item key="DEL">删除</Menu.Item>
                                        </Menu>
                                    )}>
                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: '#357CF7' }}>
                                            更多 <DownOutlined />
                                        </a>
                                    </Dropdown>
                                ]}
                            />
                        </Table>
                    </div>
                </div>
                {
                    editorVisible &&
                    <RuleEditor
                        visible={editorVisible}
                        data={editorData}
                        editorType={editorType}
                        onSuccess={this.getDataList}
                        changeVisible={v => this.setState({ editorVisible: v })}
                    />
                }
            </div>
        )
    }
}
