import moment from 'moment'
const Yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD') // 昨天
const Today = moment().format('YYYY-MM-DD') // 今天

/** 格式化日期 今天/昨天/具体日期 */
const formatDateTimeToText = time => {
  if (!time) return time
  const tempYMD = moment(time).format('YYYY-MM-DD')
  const tempHm = moment(time).format('HH:mm')
  if (tempYMD === Yesterday) return '昨天 ' + tempHm
  if (tempYMD === Today) return '今天 ' + tempHm
  return `${tempYMD} ${tempHm}`
}

/** 判断是否采用默认时间 */
const defaultTime = (YMD = 'YYYY-MM-DD') => {
  if (!!process.env.REACT_APP_DEFAULT_DATA_TIME) {
    return moment(process.env.REACT_APP_DEFAULT_DATA_TIME).format(YMD)
  } else {
    return false
  }
}

/** REACT_APP_DEFAULT_DATA_TIME 环境变量存在时 以该时间取代当前时间的年月日来加载数据
 * @param Hms 时分秒格式
 * @returns YYYY-MM-DD HH:mm:ss
 */
const getCurrentTime = (Hms = 'HH:mm:ss') => {
  if (defaultTime()) {
    return defaultTime() + ' ' + moment().format(Hms)
  } else {
    return moment().format(`YYYY-MM-DD ${Hms}`)
  }
}

export { formatDateTimeToText, defaultTime, getCurrentTime }
