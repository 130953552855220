import Page from './RightTableV2.js';
import { connect } from 'react-redux';
import { post } from '../../../common/utils';
import { queryTimeRangeFormat } from './utils.js';

const getKKPIList = () => {
    return (dispatch, getState) => {
        const { mainPage } = getState();
        const { timeRange } = mainPage;
        const [time_begin, time_end] = queryTimeRangeFormat(timeRange);
        return post(process.env.REACT_APP_SERVER_PATH + 'api/overview/get_kkpi_list', {
            time_begin,
            time_end,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                return {
                    isSuccess: true,
                    results: retData.data.results,
                };
            };
            return {
                isSuccess: false,
            };
        });
    };
};

const mapStateToProps = state => {
    const { mainPage } = state;
    const { timeRange } = mainPage;
    return {
        timeRange,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getKKPIList: () => dispatch(getKKPIList()),
    };
}

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;