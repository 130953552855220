const infoCard_1 = {
    name: '电压',
    left: [
        {
            name: '相电压',
            cards: [
                {
                    name: '平均',
                    isAvg: true,
                    unit: 'v',
                    abnormal: false,
                    highlight: true,
                    key: "Vz",
                },
                {
                    name: 'Ua',
                    extd_tag: '_Va',
                    unit: 'v',
                    abnormal: true,
                    highlight: false,
                    key: "Va",
                },
                {
                    name: 'Ub',
                    extd_tag: '_Vb',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                    key: "Vb",
                },
                {
                    name: 'Uc',
                    extd_tag: '_Vc',
                    unit: 'v',
                    abnormal: false,
                    highlight: false,
                    key: "Vc",
                },
            ],
        },
        // {
        //     name: '线电压',
        //     cards: [
        //         {
        //             name: '平均',
        //             isAvg: true,
        //             unit: 'v',
        //             abnormal: false,
        //             highlight: true,
        //         },
        //         {
        //             name: 'Uab',
        //             extd_tag: '_Vab',
        //             unit: 'v',
        //             abnormal: false,
        //             highlight: false,
        //         },
        //         {
        //             name: 'Ubc',
        //             extd_tag: '_Vbc',
        //             unit: 'v',
        //             abnormal: true,
        //             highlight: false,
        //         },
        //         {
        //             name: 'Uac',
        //             extd_tag: '_Vca',
        //             unit: 'v',
        //             abnormal: false,
        //             highlight: false,
        //         },
        //     ],
        // },
    ],
    right: {
        name: '三相不平衡度',
        extd_tag: '_Vzzub',
        key: "Vzub",
        unit: '%',
    },
};

const infoCard_2 = {
    name: '电流',
    left: [
        {
            name: '电流',
            cards: [
                {
                    name: '平均',
                    isAvg: true,
                    unit: 'A',
                    abnormal: false,
                    highlight: true,
                    key: "Iz",
                },
                {
                    name: 'La',
                    extd_tag: '_Ia',
                    unit: 'A',
                    abnormal: false,
                    highlight: false,
                    key: "Ia",
                },
                {
                    name: 'Lb',
                    extd_tag: '_Ib',
                    unit: 'A',
                    abnormal: false,
                    highlight: false,
                    key: "Ib",
                },
                {
                    name: 'Lc',
                    extd_tag: '_Ic',
                    unit: 'A',
                    abnormal: false,
                    highlight: false,
                    key: "Ic",
                },
            ],
        },
    ],
    right: {
        name: '三相不平衡度',
        extd_tag: '_Iub',
        unit: '%',
        key: "Izub",
    },
};

export {
    infoCard_1, infoCard_2,
};
