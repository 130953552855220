import React from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';

const DeviceTabLineChart = (props) => {
    const { deviceTabChart, deviceTabTime } = props
    useEffect(() => {
        renderChart();
    }, [deviceTabChart, deviceTabTime]);
    const chartNode = useRef(null);
    const renderChart = () => {
        if(!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption({
                animation: true,
                tooltip: {
                    trigger: 'axis',
                    symbol: 'none',
                },
                grid: {
                    left: 0,
                    top: 20,
                    right: 5,
                    bottom: 10,
                },
                xAxis: {
                    show: false,
                    splitLine:{
                        show: false
                    },
                    type: 'category',
                    data: deviceTabTime
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false,
                    },
                    axisTick:{ //y轴刻度线
                        show:false
                    },
                    axisLine:{ //y轴
                        show:false
                    },
                    max: Math.max.apply(null, deviceTabChart),
                    min: Math.min.apply(null, deviceTabChart)
                },
                series: [{
                    data: deviceTabChart,
                    type: 'line',
                    lineStyle:{
                        color:'#FF6161',
                        width: 1,
                    },
                    symbol: 'none',
                }]
            })
        }
    };
    return (
        <div>
            <div ref={chartNode} style={{ width: 350, height: 50 }} />
        </div>
    );
}
export default DeviceTabLineChart
