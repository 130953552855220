import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import { withRouter } from 'react-router';
import TopBreadcrumb from 'components/TopBreadCrumb';
import Tabs from 'components/TabsV2';
import DiagramDisplay from 'containers/DiagramDisplay';
import { get } from 'common/utils';
import { Divider, Space, Modal, message, DatePicker } from 'antd';
import { HistoryOutlined, PlayCircleOutlined, PauseCircleOutlined, FieldTimeOutlined } from '@ant-design/icons'
import moment from 'moment';

const Page = (props) => {

    const [tabs, setTabs] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);

    const [info, setInfo] = useState(null);
    const [layoutKey, setLayoutKey] = useState(null);
    useEffect(() => {
        setLayoutKey(new URLSearchParams(props?.location?.search ?? null).get('layout_key') ?? null);
    }, [props]);
    useEffect(() => {
        if (typeof layoutKey !== 'string' || layoutKey === "") {
            return;
        };
        get(process.env.REACT_APP_SERVER_PATH + "api/page_layouts", {
            page: 1,
            page_size: 999,
            key: layoutKey,
        })
            .then((retData) => {
                let info = null;
                try {
                    const content = retData?.data?.results?.[0]?.content ?? null;
                    info = JSON.parse(content);
                    info = Object.assign({}, info, {
                        diagram_list: info.diagram_list.map((item, index) => Object.assign({}, item, { key: index + "" })),
                    });
                } catch (e) { };
                // console.log(info);
                // setSelectedTab(null);
                setTabs(info?.diagram_list ?? null);
                setInfo(info);
                let initTab = info?.diagram_list?.[0] ?? null;
                if (!!initTab) {
                    initTab = Object.assign({}, initTab);
                };
                setSelectedTab(initTab);
            });
    }, [layoutKey]);

    const breadcrumbList = useMemo(() => {
        return [{ name: '监控' }]
            .concat(
                !!info?.diagram_list ? (
                    [{ name: info?.name ?? "--" }]
                        .concat(
                            info?.diagram_list.length > 1 ? [{ name: selectedTab?.name ?? "--" }] : []
                        )
                ) : []
            );
    }, [info, selectedTab]);

    const tabOnChange = (v) => {
        setSelectedTab(Object.assign({}, v));
    };

    const [isPause, setIsPause] = useState(false)
    const handlePause = useCallback(() => {
        setIsPause(true)
    }, [])
    const handleContinue = useCallback(() => {
        setIsPause(false)
    }, [])

    const [displayHisTime, setDisplayHisTime] = useState(() => "")
    const handleHisTimeChange = useCallback(time => {
        setDisplayHisTime(moment(time).format("YYYY-MM-DD HH:mm:ss"))
    }, [])

    const [playInfo, setPlayInfo] = useState(() => null)
    const [hisInfo, setHisInfo] = useState(() => null)
    const handleHistoryReplay = useCallback(() => {
        setHisInfo({})
    }, [])
    const handleTime = useCallback(time => {
        setHisInfo({ time })
    }, [])
    const handleCancel = useCallback(() => {
        setHisInfo(null)
    }, [])
    const handleOk = useCallback(() => {
        if (hisInfo?.time) {
            setDisplayHisTime("")
            setPlayInfo({ type: 'HISTORY', time: hisInfo.time })
            setHisInfo(null)
        } else {
            message.warning('请选择时间!')
        }
    }, [hisInfo])

    const handleRealtime = useCallback(() => {
        setDisplayHisTime("")
        setPlayInfo({ type: 'REALTIME' })
    }, [])

    return (
        <div className={style['wrapper']}>
            <div className="header">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
            </div>
            {
                !!tabs && (
                    <div className="body">
                        <div className="top">
                            {
                                tabs.length > 1 && (
                                    <Tabs tabs={tabs} selectedTab={selectedTab} onChange={tabOnChange} />
                                )
                            }
                            <div className='right'>
                                <Space>
                                    {
                                        playInfo?.type === "HISTORY" && (
                                            <React.Fragment>
                                                <Space>
                                                    <div>{displayHisTime}</div>
                                                </Space>
                                                <Divider type='vertical' />
                                            </React.Fragment>
                                        )
                                    }
                                    <Space className={style['btn']} onClick={handleHistoryReplay}>
                                        <HistoryOutlined />
                                        <div>历史回放</div>
                                    </Space>
                                    <Space className={style['btn']} onClick={handleRealtime}>
                                        <FieldTimeOutlined />
                                        <div>实时</div>
                                    </Space>
                                    {
                                        isPause ? (
                                            <Space className={style['btn']} onClick={handleContinue}>
                                                <PlayCircleOutlined />
                                                <div>继续</div>
                                            </Space>
                                        ) : (
                                            <Space className={style['btn']} onClick={handlePause}>
                                                <PauseCircleOutlined />
                                                <div>暂停</div>
                                            </Space>
                                        )
                                    }
                                </Space>
                                {
                                    hisInfo && (
                                        <Modal title="历史回放"
                                            visible={true}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                        >
                                            <Space>
                                                <div>选择历史时刻</div>
                                                <DatePicker value={hisInfo?.time ?? null} showTime format="YYYY-MM-DD HH:mm:ss" onChange={handleTime} />
                                            </Space>
                                        </Modal>
                                    )
                                }
                            </div>
                        </div>
                        <div className="bottom">
                            {
                                !!selectedTab && (
                                    <DiagramDisplay deviceId={selectedTab.uid} key={selectedTab.uid} isPause={isPause} playInfo={playInfo} onHisTimeChange={handleHisTimeChange} />
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Page);
