import React from 'react';

export default class PopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			isDisplay: false
        };
		// console.log("PopupWrapper constructor");
    }
	componentWillReceiveProps(nextProps) {
		//console.log("componentWillReceiveProps");
		var self = this;
		if(nextProps.display == true && this.state.isDisplay == false){
			var onpopstateFunc = function(event){
				console.log("onpopstateFunc called");
				console.log(event);
				console.log("window.location.href: "+window.location.href);

				if((window.location.href+"").indexOf('#/'+self.state.myLabel) > -1){
					console.log("onpopstateFunc not matched");
					return;
				}
				console.log("onpopstateFunc matched");
				window.removeEventListener("popstate", onpopstateFunc);
				self.setState({isDisplay: false});
				if(typeof(nextProps.callBack) != "undefined"){
					nextProps.callBack();
				}
			}
			this.setState({isDisplay: true});
			this.state.myLabel = new Date().getTime()+"";
			window.history.pushState(null, null, window.location.hash + '#/'+this.state.myLabel);
			window.addEventListener("popstate", onpopstateFunc);
		}
	}
    componentDidMount() {

		//window.addEventListener("popstate", onpopstateFunc);
    }
    render() {
		//console.log(this.props.display);
        return (
            <div style={{...this.props.style, display:this.state.isDisplay ? "block":"none"}}>
				{this.props.children}
            </div>
        );
    }
}