import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import style from './index.module.scss'
import { message } from 'antd'
import { useShiftConfig } from '../../../hooks'
import {
  TIME_RANGE_HOURS,
  urgencyAndColor,
  calcTimeStamps,
  calcCursorPosion,
  calculateGroupByMinutePosition,
  getCurrentTime
} from '../../../utils'

/** 时间轴3.0 */
const TimeLine = props => {
  const { endTime, innerRangeEvents, innerViewTimeRange } = props
  const [shiftConfig] = useShiftConfig([]) // 排班时间列表
  // 游标位置
  const [cursorPosition, changeCursorPosition] = useState({ bottom: 0, height: 0 })
  const [groupByTimeMarks, setGroupByTimeMarks] = useState({}) // 事件汇总标记

  const timeStamps = useMemo(() => {
    return calcTimeStamps(endTime, shiftConfig)
  }, [endTime, shiftConfig])

  useEffect(() => {
    if (!!innerViewTimeRange && typeof innerViewTimeRange === 'object') {
      const { start, end } = innerViewTimeRange
      if (!start || !end) {
        changeCursorPosition({ bottom: 0, height: 100 })
      } else {
        const position = calcCursorPosion(endTime, innerViewTimeRange)
        changeCursorPosition(position)
      }
    }
  }, [innerViewTimeRange])

  /** 将事件汇总到时间轴
   * 1.按事件发生时间每10分钟汇总
   * 2.按直径从大到小
   * 3.直径相同的按照紧急程度从大到小
   */
  useEffect(() => {
    const groupByTimeMap = {}
    innerRangeEvents &&
      innerRangeEvents.forEach(eventItem => {
        const { occur_time } = eventItem
        const { newTime, bottom } = calculateGroupByMinutePosition(endTime, occur_time)
        if (!groupByTimeMap[newTime]) {
          groupByTimeMap[newTime] = {}
          groupByTimeMap[newTime]['bottom'] = bottom
          groupByTimeMap[newTime]['events'] = [{ ...eventItem }]
        } else {
          groupByTimeMap[newTime].bottom = bottom
          groupByTimeMap[newTime].events.push({ ...eventItem })
        }
      })
    Object.keys(groupByTimeMap).forEach(key => {
      Object.keys(urgencyAndColor).forEach(urgency_code => {
        const urgency = Number(urgency_code)
        const groupByUrgencyList = groupByTimeMap[key]['events'].filter(
          eventItem => eventItem.urgency === urgency
        )
        if (groupByUrgencyList.length === 0) return
        const eventMarks = groupByTimeMap[key]['eventMarks'] || []
        eventMarks.push({
          urgency: urgency,
          color: urgencyAndColor[urgency_code],
          capsules: groupByUrgencyList,
          radius: 2 + Math.min(groupByUrgencyList.length, 5)
        })
        groupByTimeMap[key]['eventMarks'] = eventMarks
      })
      // 排序
      groupByTimeMap[key]['eventMarks'].sort((groupA, groupB) => {
        // 按直径从大到小
        if (groupA.radius - groupB.radius > 0) {
          return -1
        }
        // 直径相同的按照紧急程度从大到小
        if (groupA.radius === groupB.radius) {
          if (groupA.urgency - groupB.urgency > 0) {
            return -1
          }
        }
        return 1
      })
    })

    setGroupByTimeMarks(groupByTimeMap)
  }, [innerRangeEvents])

  /**
   * 向前/向后推8小时
   * @param addon 1:向后(+) -1:向前(-)
   */
  const onClickBeforeOrAfter = addon => {
    const localCurrentTime = moment().startOf('minutes')
    const tempEndTime = moment(endTime).startOf('minutes')
    if (addon === 1 && tempEndTime.isSame(localCurrentTime)) {
      message.info('已是最新时间！')
      return
    }
    const nextEndTime = moment(tempEndTime).add(addon * TIME_RANGE_HOURS, 'hours')
    if (typeof props.setEndTime === 'function')
      props.setEndTime(
        moment(nextEndTime).isAfter(localCurrentTime) ? localCurrentTime : nextEndTime
      )
  }

  /** 点击当前按钮 跳转到当前时刻 */
  const setToCurrent = () => {
    const localCurrentTime = moment(getCurrentTime()).startOf('minutes')
    const tempEndTime = moment(endTime).startOf('minutes')
    if (moment(tempEndTime).isSame(moment(localCurrentTime))) {
      message.info('已是最新时间！')
      return
    }
    if (typeof props.setEndTime === 'function') props.setEndTime(moment(getCurrentTime()))
  }

  /** 点击时间点跳转至指定时刻 */
  const toggleTime = time => {
    if (typeof props.setEndTime === 'function') props.setEndTime(moment(time))
  }

  return (
    <div className={style['TimeLine']}>
      <div className="time-line-warpper">
        {!!timeStamps &&
          timeStamps.map((item, index) => {
            const { time, bottom, text, isHandOverTime, isSameShiftTime } = item
            return (
              <div
                key={index}
                className={`time-point ${
                  isHandOverTime || isSameShiftTime ? 'shift-time-point' : ''
                }`}
                style={{ bottom }}
                onClick={() => toggleTime(time)}
              >
                {text}
              </div>
            )
          })}
        {innerViewTimeRange && innerRangeEvents && innerRangeEvents.length > 0 && (
          <div
            className="cursor-range"
            style={{ bottom: cursorPosition.bottom, height: cursorPosition.height }}
          >
            <div className="active-line" />
          </div>
        )}
        {Object.keys(groupByTimeMarks).map(key => {
          const item = groupByTimeMarks[key]
          return (
            <div
              key={key}
              className="mark-group"
              style={{
                bottom: item.bottom,
                transform: `translateX(${
                  item.eventMarks && item.eventMarks.length > 0
                    ? -(item.eventMarks[0].radius - 1) + 'px'
                    : 0
                })`
              }}
              onClick={() => toggleTime(item.events[0].occur_time)}
            >
              {item.eventMarks.map((items, index) => {
                return (
                  <div
                    key={items.urgency}
                    className="mark-item"
                    style={{
                      width: items.radius * 2,
                      height: items.radius * 2,
                      backgroundColor: items.color,
                      marginLeft: index > 0 ? -items.radius : 0
                    }}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
      <div className="current-btn" onClick={setToCurrent}>
        回到当前
      </div>
    </div>
  )
}

export default TimeLine
