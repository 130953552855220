import React from 'react';
import { withRouter } from 'react-router';
import { post, get } from 'common/utils';
import moment from 'moment';
import { Menu, Dropdown, Button, Modal, DatePicker, Table, Select } from 'antd';
import RuleModal from './RuleModal.js';
import style from './Content.module.scss';
import {
    DownOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { getMyPermissions } from 'common/AuthPermission.js';
import { tmp_users_exchange } from '../utils';

const { Option } = Select;

let RULE_STATUS = [
    {
        status: 0,
        name: '未使用',
    },
    {
        status: 1,
        name: '使用中',
    },
];

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            dataSource: [],
            systems: [],
            page: 1,
            total: 0,
            pageSize: 10,
            ruleStatus: 'all',
            selectedRule: {},
            myPermissions: getMyPermissions(),
        };
    }

    componentDidMount() {
        this.getRuleList();
    }

    getRuleList = () => {
        const self = this;
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/list', {
        }).then(retData => {
            console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({
                    dataSource: retData.data.results.map(item => {
                        item.key = item._id;
                        return item;
                    }),
                });
            };
        });
    }

    deleteRule = _id => {
        const execDel = () => {
            post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/delete', {
                _id
            }).then(retData => {
                console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    this.getRuleList();
                }
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            // content: '确认删除吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    render() {
        let self = this;
        const { modalVisible, dataSource, selectedRule, myPermissions } = this.state;
        return (
            <div className={style["exam_rules_content_wrapper"]}>
                <div className="content_wrapper">
                    <div className="header">
                        <Select
                            style={{ width: 144, marginRight: 24 }}
                            defaultValue="all"
                            value={this.state.ruleStatus}
                            onChange={v => self.setState({ ruleStatus: v })}
                        >
                            <Option value='all'>全部状态</Option>
                            {
                                RULE_STATUS.map(item =>
                                    <Option value={item.status} key={item.status}>{item.name}</Option>
                                )
                            }
                        </Select>
                        <DatePicker.RangePicker
                            value={[this.state.dayBegin, this.state.dayEnd]}
                            onChange={(dateRange) => this.setState({
                                dayBegin: (dateRange == null ? null : dateRange[0]),
                                dayEnd: (dateRange == null ? null : dateRange[1]),
                                dateRangeOption: null
                            }, () => { })}
                            disabledDate={current => current > moment().endOf('day')}
                            style={{ width: 240 }}
                        />
                        <Button
                            type="primary" onClick={this.getRuleList}
                            style={{
                                marginLeft: 24,
                                backgroundColor: '#357CF7',
                                borderColor: '#357CF7',
                            }}
                        >查询</Button>
                        {
                            myPermissions.includes('rule_setting/add_btn') && (
                                <Button
                                    type="primary" onClick={() => this.setState({ modalVisible: true, selectedRule: {} })}
                                    style={{
                                        marginLeft: 'auto',
                                        backgroundColor: '#357CF7',
                                        borderColor: '#357CF7',
                                    }}
                                >添加</Button>
                            )
                        }
                    </div>
                    <div className="content_table_wrapper">
                        <div className="table_wrapper_area">
                            <Table
                                dataSource={dataSource}
                                pagination={false}
                                columns={[
                                    {
                                        title: '规则名称',
                                        dataIndex: 'name',
                                    },
                                    {
                                        title: '创建时间',
                                        key: 'createTime',
                                        render: (text, { create_time }, index) =>
                                            moment(create_time).format('YYYY年M月D日')
                                    },
                                    {
                                        title: '修改时间',
                                        key: 'updateTime',
                                    },
                                    {
                                        title: '备注',
                                        dataIndex: 'remark',
                                    },
                                    {
                                        title: '负责人',
                                        dataIndex: 'user_name',
                                        render: (text) => {
                                            let tmp_name = "";
                                            if (!!tmp_users_exchange && typeof tmp_users_exchange[text] === 'string') {
                                                tmp_name = tmp_users_exchange[text];
                                            };
                                            return tmp_name;
                                        },
                                    },
                                    {
                                        title: '状态',
                                        key: 'status',
                                        render: (text, { status }, index) =>
                                            RULE_STATUS.filter(item => item.status == status)[0].name
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, record, index) => {
                                            const { _id } = record;
                                            return (
                                                [
                                                    myPermissions.includes('rule_setting/edit_link') && (
                                                        <Button key="btn" type="link" onClick={() => this.props.gotoExamRuleConfig(_id)} style={{ color: '#357CF7' }}>编辑</Button>
                                                    )
                                                    ,
                                                    myPermissions.includes('rule_setting/more_link') && (
                                                        <Dropdown key="dropdown" overlay={(
                                                            <Menu onClick={({ key }) => {
                                                                if (key == 2) this.deleteRule(_id);
                                                                if (key == 1) {
                                                                    this.setState({
                                                                        modalVisible: true,
                                                                        selectedRule: record,
                                                                    });
                                                                }
                                                            }}>
                                                                <Menu.Item key="1">修改</Menu.Item>
                                                                <Menu.Item key="2">删除</Menu.Item>
                                                            </Menu>
                                                        )}>
                                                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: '#357CF7' }}>
                                                                更多 <DownOutlined />
                                                            </a>
                                                        </Dropdown>
                                                    )
                                                ]
                                            );
                                        }
                                    },
                                ]}
                                scroll={{ y: true }}
                            />
                        </div>
                    </div>

                </div>
                <RuleModal
                    visible={modalVisible}
                    rule={selectedRule}
                    onClose={refresh => {
                        this.setState({ modalVisible: false });
                        refresh && this.getRuleList();
                    }}
                />
            </div >
        );
    }
}

export default withRouter(Content);
