import React from 'react';
import { Button, Select, DatePicker, Radio, Table, message, Spin } from 'antd';
import { post } from 'common/utils.js';
import moment from 'moment';
import echarts from 'echarts';
import style from './HistoryData.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const SELECTED_DATAMETA_LIST_LOCAL = 'HISTORY_DATA_SELECTED_DATAMETA_LIST';
export default class HistoryData extends React.Component {

    constructor(props) {
        super(props);
        const env_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
        const default_time =
            (typeof env_time === 'string' && env_time !== "")
                ? (moment(env_time).format("YYYY-MM-DD ") + moment().format("HH:mm:ss"))
                : moment();
        this.state = {
            dataMetaList: [],
            selectedDataMeta: null,
            selectedDataMetaList: [],
            selectedRowKeys: [],
            timeRange: [moment(default_time).subtract(12, 'hours'), moment(default_time)],
            chartDatas: [],
            chartColumnNum: 1,
            loading: false,
        };
    }

    componentDidMount() {
        this.getDataMetas();
        this.initLocalData();
    }
    initLocalData = () => {
        try {
            const selectedDataMetaList = JSON.parse(window.localStorage.getItem(SELECTED_DATAMETA_LIST_LOCAL));
            if (selectedDataMetaList) this.setState({ selectedDataMetaList });
        } catch (e) { }
    }
    getDataMetas = (keyword) => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            ...!keyword ? {} : { keyword },
            page: 1,
            page_size: 10,
        }).then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                const { selectedDataMeta } = this.state;
                let dataMetaList = (retData.data.results ?? [])
                    .map((item) => {
                        return Object.assign(
                            {},
                            item,
                            {
                                _id: item.id,
                            },
                        );
                    });
                if (selectedDataMeta && dataMetaList.filter(({ _id }) => _id === selectedDataMeta._id).length === 0) {
                    dataMetaList.unshift(selectedDataMeta);
                }
                this.setState({ dataMetaList })
            }
        });
    }
    dataMetaOnChange = dataMetaId => {
        const { dataMetaList } = this.state;
        const selectedDataMeta = dataMetaList.filter(({ id }) => (id + '') === (dataMetaId + ''))[0] || null;
        if (selectedDataMeta) {
            this.setState({ selectedDataMeta });
        }
    }
    addDataMeta = () => {
        const { selectedDataMeta, selectedDataMetaList } = this.state;
        if (!selectedDataMeta) {
            message.error('未选择点位');
            return;
        };
        if (selectedDataMetaList.filter(({ _id }) => _id === selectedDataMeta._id).length === 0) {
            selectedDataMetaList.unshift(selectedDataMeta);
            this.setState({ selectedDataMetaList }, () => {
                try {
                    window.localStorage.setItem(SELECTED_DATAMETA_LIST_LOCAL, JSON.stringify(selectedDataMetaList));
                } catch (e) { }
            });
        }
        this.setState({ selectedDataMeta: null });
    }
    verify = () => {
        const { timeRange, selectedRowKeys } = this.state;
        if (!timeRange) {
            message.error('未选择时段');
            return false;
        }
        if (selectedRowKeys.length === 0) {
            message.error('未选择点位');
            return false;
        }
        const [time_begin, time_end] = timeRange;
        if (moment(time_begin).add(1, 'days').isBefore(moment(time_end))) {
            message.error('选择时段不得超过24小时');
            return false;
        }
        return true;
    }
    watchCharts = () => {
        if (!this.verify()) return;
        const { timeRange: [time_begin, time_end], selectedRowKeys, selectedDataMetaList } = this.state;
        this.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
            time_type: 'seconds',
            tag_list: selectedRowKeys
                .map(key => selectedDataMetaList.filter(({ _id }) => _id === key)[0] || null)
                .filter(item => item !== null)
                .map(({ tag_name }) => ({
                    tag_name,
                    time_begin: moment(time_begin).format('YYYY-MM-DD HH:mm:00'),
                    time_end: moment(time_end).format('YYYY-MM-DD HH:mm:00'),
                }))
        })
            .then(retData => {
                this.setState({ loading: false });
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    this.setState({ chartDatas: retData.data.results }, this.drawCharts);
                }
            });
    }
    drawCharts = () => {
        const { chartColumnNum, chartDatas } = this.state;
        chartDatas.forEach(({ tag_name, data_list }, index) => {
            const dom = document.getElementById(tag_name);
            if (dom) {
                echarts.dispose(dom);
                var myChart = echarts.init(dom);
                myChart.setOption({
                    grid: {
                        left: 60,
                        top: 20,
                        right: 18,
                        bottom: 30,
                    },
                    xAxis: {
                        type: 'time',
                        // show: false,
                        axisLine: {
                            // show: false,
                            lineStyle: {
                                color: '#3A3F46',
                            },
                        },
                        axisLabel: {
                            // show: false,
                            fontSize: 11,
                            color: '#9FA0BA',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    yAxis: {
                        scale: true,
                        // show: false,
                        splitLine: {
                            lineStyle: {
                                color: '#3A3F46',
                            },
                        },
                        axisLabel: {
                            // show: false,
                            fontSize: 11,
                            color: '#9FA0BA',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    series: [{
                        data: data_list
                            .filter(([_, v]) => v !== null)
                            .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                        type: 'line',
                        symbol: 'none',
                        lineStyle: {
                            color: chartColumnNum > 1 ? ((index % 2 === 0) ? 'rgb(36,149,253)' : '#4A9B87') : "rgb(36,149,253)",
                            width: 2,
                        },
                    }]
                });
            }
        });
    }
    deleteDataMeta = id => {
        const { selectedDataMetaList, selectedRowKeys } = this.state;
        this.setState({
            selectedRowKeys: selectedRowKeys.filter(key => key !== id),
            selectedDataMetaList: selectedDataMetaList.filter(({ _id: s_id }) => s_id !== id),
        }, () => {
            try {
                window.localStorage.setItem(SELECTED_DATAMETA_LIST_LOCAL, JSON.stringify(this.state.selectedDataMetaList));
            } catch (e) { }
        });
    }
    render() {
        const {
            dataMetaList, selectedDataMeta,
            selectedDataMetaList, selectedRowKeys,
            timeRange, chartDatas, chartColumnNum,
            loading,
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: selectedRowKeys => this.setState({ selectedRowKeys }),
        };
        return (
            <div className={style["history_data_wrapper"]}>
                <div className="history_data_header_wrapper">
                    <div className="opt_title">选中时段</div>
                    <RangePicker
                        className="picker"
                        style={{ marginRight: 'auto' }}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        placeholder={['开始时间', '结束时间']}
                        value={timeRange}
                        onChange={timeRange => this.setState({ timeRange })}
                    />
                    <Button
                        onClick={this.watchCharts}
                        type="primary"
                        loading={loading}
                    >查看曲线</Button>
                </div>
                <div className="history_data_content_wrapper">
                    <div className="content_wrapper">
                        <div className="content_top_header">
                            <div style={{ marginLeft: 'auto', marginRight: 24 }}>显示</div>
                            <Radio.Group
                                value={chartColumnNum}
                                onChange={e => this.setState({ chartColumnNum: e.target.value }, this.drawCharts)}
                            >
                                <Radio value={1}>一列</Radio>
                                <Radio value={2}>二列</Radio>
                            </Radio.Group>
                        </div>
                        <div className="content_bottom_wrapper">
                            <div className="left">
                                <div className="top_bar">
                                    <Select
                                        style={{ width: 400, marginRight: 24 }}
                                        value={selectedDataMeta && (selectedDataMeta.id + '')}
                                        onChange={this.dataMetaOnChange}
                                        showSearch
                                        onSearch={this.getDataMetas}
                                        filterOption={false}
                                        // showArrow={false}
                                        defaultActiveFirstOption={false}
                                    >
                                        {
                                            dataMetaList.map((item) => {
                                                const { id, name, tag_name } = item;
                                                return (
                                                    <Option key={id}>{name} {tag_name}</Option>
                                                );
                                            })
                                        }
                                    </Select>
                                    <Button
                                        type="primary"
                                        // disabled={!selectedDataMeta}
                                        onClick={this.addDataMeta}
                                    >添加</Button>
                                </div>
                                <Table
                                    dataSource={selectedDataMetaList.map(item => ({
                                        ...item,
                                        key: item._id,
                                    }))}
                                    rowSelection={rowSelection}
                                    pagination={false}
                                    columns={[{
                                        title: '点位名称',
                                        dataIndex: 'tag_name',
                                    }, {
                                        title: '点位描述',
                                        dataIndex: 'name',
                                    }, {
                                        title: '操作',
                                        key: 'action',
                                        render: (text, { _id }) => (
                                            <Button type="link" onClick={() => this.deleteDataMeta(_id)}>删除</Button>
                                        )
                                    }]}
                                    scroll={{ y: true }}
                                />
                            </div>
                            <div className="right scroll_bar_restyle" style={chartColumnNum > 1 ? {} : { display: 'block' }}>
                                {
                                    chartDatas.map((item, index) => {
                                        const { tag_name } = item;
                                        return (
                                            <div
                                                className="chart_wrapper"
                                                key={index}
                                                style={{
                                                    ...chartColumnNum > 1 ? { width: `${Math.floor(98 / chartColumnNum)}%` } : {},
                                                    ...index >= chartColumnNum ? { marginTop: 12 } : {},
                                                }}
                                            >
                                                <div className="title">
                                                    指标名称
                                                    <span>{tag_name}</span>
                                                    <span>
                                                        {
                                                            (selectedDataMetaList
                                                                .filter(({ tag_name: tagName }) => tagName === tag_name)[0] || {})
                                                                .name || ''
                                                        }
                                                    </span>
                                                </div>
                                                <div id={tag_name} style={{ height: 200 }} />
                                            </div>
                                        );
                                    })
                                }
                                {
                                    loading && (
                                        <div style={{ position: 'absolute', zIndex: 1000, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                                            <Spin />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
