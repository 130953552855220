import { useEffect, useState } from 'react';
import { post } from 'common/utils';
import moment from 'moment';

const Hook = () => {
    const [conf, setConf] = useState(null);
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!!conf) {
            const { time_type, time, product_id, range } = conf;
            if (typeof product_id !== 'number' || typeof time !== 'string') {
                setResult(null);
                return;
            };
            const time_start = moment(time).startOf(range).format("YYYY-MM-DD 00:00:00");
            let requesting = true;
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/kpi/get_product_cost_structure', {
                time_type, time: time_start, product_id,
            })
                .then((retData) => {
                    if (requesting) {
                        setLoading(false);
                        // console.log('hook', retData);
                        if (!!retData.data && retData.data.errcode === 0) {
                            let nextResult = retData?.data;

                            let parsed_results = nextResult?.results;
                            if (!!parsed_results) {
                                // sort
                                // cost text
                                parsed_results = []
                                    .concat(parsed_results)
                                    .sort(({ cost: cost_1 }, { cost: cost_2 }) => cost_2 - cost_1);
                            };

                            let parsed_basic_info = nextResult?.basic_info;
                            if (!!parsed_basic_info) {
                                const time_begin = moment(time).startOf(range).format("YYYY/MM/DD");
                                const time_end = moment(time).endOf(range).format("YYYY/MM/DD");
                                parsed_basic_info = Object.assign({}, parsed_basic_info, {
                                    time: moment(time).format("YYYY/MM/DD"),
                                    time_range_text: `${time_begin} - ${time_end}`,
                                });
                            };

                            nextResult = Object.assign({}, nextResult, {
                                parsed_results,
                                parsed_basic_info,
                            });
                            // console.log(nextResult);
                            setResult(nextResult);
                            return;//其他都算请求失败了
                        } else {
                            // 处理失败
                            setResult(null);
                        };
                    };
                });
            return () => {
                requesting = false;
            };
        } else {
            setResult(null);
        };
    }, [conf]);
    return [result, setConf, loading];
};

export default Hook;
