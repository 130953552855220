import { useEffect, useRef, useState } from 'react'
import { getToken } from 'common/utils'

const reset_timeout = 120000 // 2分钟后重连
const protocolStr = document.location.protocol
const origin = () => {
    let apiPath = process.env.REACT_APP_SERVER_PATH;
    let wsProtocol = '';
    let wsPath = '';
    if(apiPath.indexOf('./') === 0 || (apiPath.indexOf('/') === 0 && apiPath.indexOf('//') !== 0)){
        if(protocolStr === 'http:'){
            wsProtocol = 'ws://';
        }else{
            wsProtocol = 'wss://';
        }
        wsPath = document.location.host + '/';
    }else if(apiPath.indexOf('//') === 0){
        if(protocolStr === 'http:'){
            wsProtocol = 'ws://';
        }else{
            wsProtocol = 'wss://';
        }
        wsPath = apiPath.substring(2);
    }else if(apiPath.indexOf('http://') === 0){
        wsProtocol = 'ws://';
        wsPath = apiPath.substring(7);
    }else if(apiPath.indexOf('https://') === 0){
        wsProtocol = 'wss://'
        wsPath = apiPath.substring(8);
    }else{
        console.log('WebSocket Url Error!');
    }

    let retWsPath = wsProtocol + wsPath;
    console.log('WebSocket Url:', retWsPath);

    return retWsPath;
}
// 服务地址

const Hook = () => {
  const messageService = useRef(null)
  const [messageInfo, setMessageInfo] = useState(null)
  // 2分钟未收到心跳则断开重连
  const heartCheck = {
    serverTimeoutObj: null,
    reset: function () {
      clearTimeout(this.serverTimeoutObj)
      return this
    },
    start: function () {
      var self = this
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      messageService.current.send(
        JSON.stringify({ type: 1, data: '', timestamp: new Date().getTime() })
      )
      self.serverTimeoutObj = setTimeout(function () {
        // 如果超过一定时间还没重置，说明后端主动断开了
        console.log('断开后重新连接')
        messageService.current.close()
        reconnect()
      }, reset_timeout)
    }
  }
  useEffect(() => {
    initMessageService()
    return () => {
      console.log('页面销毁,主动关闭消息服务')
      messageService.current?.close()
      // 清除setTimeout
      heartCheck.reset()
    }
  }, [])

  function initMessageService() {
    const wsUrl = `${origin()}/api/v1/event/event/newCapsuleSocket?Authorization=${getToken()}`
    console.log(wsUrl)
    if (!messageService.current || messageService.current.readyState !== 1) {
      if ('WebSocket' in window) {
        messageService.current = new WebSocket(wsUrl)
        messageService.current.onmessage = websocketonmessage
        messageService.current.onopen = websocketonopen
        messageService.current.onerror = websocketonerror
        messageService.current.onclose = websocketclose
      } else {
        console.log('当前浏览器不支持websocket')
      }
    }
  }

  function websocketonopen() {
    console.log('正在建立连接...')
    const timestamp = new Date().getTime()
    // 订阅消息
    const ACTION = { type: 1, data: '', timestamp }
    /**
     * 0 - 表示连接尚未建立。
     * 1 - 表示连接已建立，可以进行通信。
     * 2 - 表示连接正在进行关闭。
     * 3 - 表示连接已经关闭或者连接不能打开
     */
    setTimeout(() => {
      if (messageService.current.readyState === 1) {
        console.log('连接成功')
        messageService.current.send(JSON.stringify(ACTION))
      }
    }, 100)
  }

  function websocketonmessage(e) {
    console.log('接收消息', e.data)
    // 立即发送响应信息并检查心跳
    heartCheck.reset().start()
    const message = JSON.parse(e.data)
    if (typeof message === 'object') {
      const { type, data } = message
      if (type === 1) console.log('ping')
      if (type === 2) setMessageInfo(data)
      //   typeof callback === 'function' && callback({ data, eventList })
    }
  }

  function websocketonerror(e) {
    console.log('连接失败', e)
  }
  function websocketclose(e) {
    console.log('断开连接', e)
  }

  function reconnect() {
    if (messageService.current && messageService.current.readyState === 1) return
    setTimeout(function () {
      // 没连接上会一直重连，设置延迟避免请求过多
      console.log('重新连接')
      initMessageService()
    }, 5000)
  }

  return [messageService, messageInfo]
}

export default Hook
