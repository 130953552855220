import React from 'react';
import { Button, Select } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import style from './ExamInfoBar.module.scss';

const { Option } = Select;

export default class ExamInfoBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    onChange = (value) => {
        // console.log(value);
        const { getExamDetailData } = this.props;
        getExamDetailData(value);
    }

    render() {
        const { examDetail, examList } = this.props;
        // console.log('in examInfoBar', examDetail);
        let name = ''
        let first_time_begin = ''
        let last_time_end = ''
        let rule_name = ''
        let finish_time = ''
        if (examDetail) {
            name = examDetail.name
            first_time_begin = examDetail.first_time_begin
            last_time_end = examDetail.last_time_end
            rule_name = examDetail.rule_name
            finish_time = examDetail.finish_time
        }
        // console.log(name)

        return (
            <div className={style["wrapper"]}>
                <div className="guide">
                    {/* <span className='mySpan'><strong>&bull;</strong> 当前竞赛: &emsp;<strong>{name}</strong> </span> */}
                    <span className='mySpan'><strong>&bull;</strong> 起止时间: &emsp;<strong>{first_time_begin} 至 {last_time_end}</strong></span>
                    <span className='mySpan'><strong>&bull;</strong> 竞赛规则: &emsp;<strong>{rule_name}</strong></span>
                    {/* <span className='mySpan'><strong>&bull;</strong> 记分截止时间: &emsp;<strong>{finish_time}</strong></span> */}
                    <Select
                        style={{ marginLeft: 'auto', width: 400, }}
                        value={examDetail?._id ?? null}
                        onChange={this.onChange}
                    >
                        {
                            (examList ?? [])
                                .map((item, index) => {
                                    const { _id, name } = item;
                                    return (
                                        <Option key={index} value={_id}>
                                            {name}
                                        </Option>
                                    )
                                })
                        }
                    </Select>
                </div>
            </div>
        );
    }
}
