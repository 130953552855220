const dataSource = [
    {
        category: '普通生料',
        factorName: '生料投入量',
        improveChance: 20,
        PHI: 61.2,
        aimPHI: 94.5,
        red: true,
        details: [
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '生料石灰石含量',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: 'C5预热器出口压力',
                related: 0.884,
                aveValue: -6100,
                aimValue: -6400,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
        ],
    },
    {
        category: '普通生料',
        factorName: '生料投入量',
        improveChance: 20,
        PHI: 61.2,
        aimPHI: 94.5,
        red: false,
        details: [
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: 'C5预热器出口压力',
                related: 0.884,
                aveValue: -6100,
                aimValue: -6400,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '生料石灰石含量',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
        ],
    },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    // {
    //     category: '普通生料',
    //     factorName: '生料投入量',
    //     improveChance: 20,
    //     PHI: 61.2,
    //     aimPHI: 94.5,
    //     red: false,
    // },
    {
        category: '普通生料',
        factorName: '生料投入量',
        improveChance: 20,
        PHI: 61.2,
        aimPHI: 94.5,
        red: true,
        details: [
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: 'C5预热器出口压力',
                related: 0.884,
                aveValue: -6100,
                aimValue: -6400,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '生料石灰石含量',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
        ],
    },
    {
        category: '普通生料',
        factorName: '生料投入量',
        improveChance: 20,
        PHI: 61.2,
        aimPHI: 94.5,
        red: false,
        details: [
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '生料石灰石含量',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: false,
            },
            {
                name: 'C5预热器出口压力',
                related: 0.884,
                aveValue: -6100,
                aimValue: -6400,
                PHI: 64.5,
                red: false,
            },
            {
                name: '窑尾提升机电流',
                related: 0.884,
                aveValue: 139,
                aimValue: 124,
                PHI: 64.5,
                red: true,
            },
        ],
    },
];

export {
    dataSource,
};