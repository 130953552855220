import React from 'react';
import style from './PicEditPart.module.scss';
const PIC_SERVER_PATH = process.env.REACT_APP_SERVER_PATH + 'api/img/download/';

export default class PicEditPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgWrapperStyle: { width: "10px", height: "10px" },
            imgStyle: {},
            mouseCoord: [],
            mouseDownCoord: [],
            mouseDownItemCoord: [],
            dragging: false,
            firstLoad: true,
            currentMouseDownIndex: null
        }
    }
    componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        this.props.data.bckPic !== nextProps.data.bckPic &&
            this.setState({
                imgStyle: {}
            });
    }
    imgOnLoad = e => {
        let self = this, imgInfo = e.target.getBoundingClientRect(), eH = imgInfo.height, eW = imgInfo.width;
        console.log(eH, eW);
        this.computeWH(this.refs.innerBox, eH, eW);
        self.setState({ firstLoad: false });
        window.onresize = () => { this.computeWH(this.refs.innerBox, eH, eW) };
    }
    computeWH = (outside, iH, iW) => {
        let imgStyle, bckSize, outInfo = outside.getBoundingClientRect(),
            oH = outInfo.height, oW = outInfo.width, self = this;
        if (oH * iW > oW * iH) {
            //上下空出
            imgStyle = { width: oW + 'px' };
            bckSize = { width: oW, height: oW * iH / iW }
        } else {
            //左右空出
            imgStyle = { height: oH + 'px' };
            bckSize = { width: oH * iW / iH, height: oH }
        }
        const { boardBckSize } = this.props.data;
        if (boardBckSize == null) {
            // 代表新建
            const nextBoardBckSize = {
                width: bckSize.width,
                height: bckSize.height
            }
            self.props.componentSetState({ boardBckSize: nextBoardBckSize });
        } else {
            // 背景图发生了变化，主要是尺寸变化，把尺寸变换一致，另外把各自top修改下，fontSize和size无影响
            const { width: originWidth, height: originHeight } = boardBckSize;
            const { width, height } = bckSize;
            const nextOriginHeight = originWidth / width * height;
            const nextBoardBckSize = Object.assign({}, boardBckSize, { height: nextOriginHeight });
            const { listData } = this.props.data;
            const nextListData = listData
                .map((item) => {
                    let nextDetails = item.details;
                    if (!!nextDetails) {
                        nextDetails = nextDetails
                            .map((detail) => {
                                const { top } = detail;
                                const nextTop = top * originHeight / nextOriginHeight;
                                const nextDetail = Object.assign({}, detail, { top: nextTop });
                                return nextDetail;
                            });
                    };
                    const nextItem = Object.assign({}, item, { details: nextDetails });
                    return nextItem;
                });
            this.props.componentSetState({
                listData: nextListData,
                boardBckSize: nextBoardBckSize,
            });
        };
        this.setState({ imgStyle });
        // this.updateFontSize(bckSize);
        this.props.componentSetState({ bckSize });
    }
    updateFontSize(nextBckSize) {
        // 默认参考背景宽取750px
        let self = this, listData = self.props.data.listData,
            bckSize = self.props.data.bckSize, oldWidth = bckSize === null ? 750 : bckSize.width, newWidth = nextBckSize === null ? 750 : nextBckSize.width;
        listData.forEach(item => {
            if ([0, 3, 4].includes(item.type)) {
                item.details[0].size *= (newWidth / oldWidth);
                item.details[0].size < 3 && (item.details[0].size = 3);
            }
        })
        self.props.componentSetState({ listData });
    }
    getBckWrapperStyle = () => {
        //imgWrapperStyle
        let BS = this.props.data.bckSize;
        return (BS === null || this.state.firstLoad) ? this.state.imgWrapperStyle : { width: BS.width + "px", height: BS.height + "px" };
    }
    dragOnMouseMove = e => {
        // console.log(e.clientX, e.clientY)
        let x = e.clientX, y = e.clientY;
        if (this.state.dragging) {
            if (this.state.mouseCoord.length !== 0) {
                this.setNewPosition(y - this.state.mouseDownCoord[1], this.state.mouseDownCoord[0] - x, e);
            }
            this.setState({ mouseCoord: [x, y] });
        } else {
            this.setState({ mouseCoord: [x, y] });
        }
    }
    setNewPosition(deltaTop, deltaRight, e) {
        //px to %
        // console.log(deltaTop, deltaRight);
        let spd = this.props.data, listData = spd.listData, NS = listData[spd.nowSelected], coordObj = NS.details[0], self = this;
        NS.type === 1 && (coordObj = NS.details[NS.detailItemNowSelected]);
        let DT = deltaTop * 100 / spd.bckSize.height, DR = deltaRight * 100 / spd.bckSize.width,
            nTop = self.state.mouseDownItemCoord[0] + DT, nRight = self.state.mouseDownItemCoord[1] + DR;
        if (nTop >= -100 && nTop <= 100 && nRight >= -100 && nRight <= 100) {
            coordObj.top = nTop;
            coordObj.right = nRight;
            this.matchLine(e, coordObj);
            this.props.componentSetState({ listData });
        }
    }
    matchLine = (e, dataObj) => {
        let self = this, spd = self.props.data,
            childNodes = self.refs.elementsWrapper.childNodes,
            eRect = e.target.getBoundingClientRect(),
            childRect, childDetail,
            currentDetail = {
                top: eRect.y,
                right: eRect.x + eRect.width,
                bottom: eRect.y + eRect.height,
                left: eRect.x
            },
            dirGp = [['left', 'right'], ['top', 'bottom']],
            dirToDir = {
                left: dirGp[0],
                right: dirGp[0],
                top: dirGp[1],
                bottom: dirGp[1]
            };
        // console.log(JSON.stringify(currentDetail));
        for (let key in currentDetail) {
            dataObj[key + "-line"] = false;
        }
        for (let i = 0; i < childNodes.length; i++) {
            // if (i != 1) continue;
            // console.log(childNodes[i].className, e.target.className);
            if (childNodes[i].className.split(" ")[0] !== e.target.className.split(" ")[0]) {
                childRect = childNodes[i].getBoundingClientRect();
                childDetail = {
                    left: childRect.x,
                    right: childRect.x + childRect.width,
                    top: childRect.y,
                    bottom: childRect.y + childRect.height
                };
                for (let key in currentDetail) {
                    for (let k of dirToDir[key]) {
                        let j = currentDetail[key] - childDetail[k];
                        // console.log(key, j);
                        if (j <= 5 && j >= -5) {
                            dataObj[key + "-line"] = true;
                            // console.log("right");
                            //增加贴合代码
                            // self.setState({ dragging: false });
                            if (j !== 0) {
                                if (key === 'top') {
                                    dataObj.top -= j * 100 / spd.bckSize.height;
                                } else if (key === 'right') {
                                    dataObj.right += j * 100 / spd.bckSize.width;
                                } else if (key === 'bottom') {
                                    if (dataObj['top-line']) dataObj['bottom-line'] = false
                                    else {
                                        dataObj.top -= j * 100 / spd.bckSize.height;
                                    }
                                } else {
                                    if (dataObj['right-line']) dataObj['left-line'] = false
                                    else {
                                        dataObj.right -= j * 100 / spd.bckSize.height;
                                    }
                                }
                            }
                        } else {
                            // dataObj[key + "-line"] = false;
                            // console.log("wrong");
                        }
                    }
                }
            }
        }
    }
    itemMouseDown = (e, nowSelected, detailItemNowSelected) => {
        let listData = this.props.data.listData, self = this,
            item = listData[nowSelected].details[detailItemNowSelected],
            mouseDownCoord = [e.clientX, e.clientY], mouseDownItemCoord = [item.top, item.right];
        // console.log(item);
        listData[nowSelected].detailItemNowSelected = detailItemNowSelected;
        this.props.componentSetState({
            nowSelected,
            listData,
            groupSelected: [nowSelected]
        }, function () {
            self.setState({
                dragging: true,
                currentMouseDownIndex: nowSelected,
                mouseDownCoord,
                mouseDownItemCoord
            }, _ => {
                console.log("mouseDownCoord", mouseDownCoord, mouseDownItemCoord);
            });
        });
    }
    render() {
        let self = this, spd = self.props.data, imgConf = {
            className: "item itemImg",
            alt: "",
            draggable: false
        },
            showEle = () => {
                if (spd.nowSelected !== null && spd.bckSize !== null) {
                    let sList = spd.listData[spd.nowSelected];
                    if ([0, 3, 4].includes(sList.type)) return 1;
                    if (sList.detailItemNowSelected !== null) return 2
                }
                return 0;
            },
            dragConf = {
                // onMouseDown: _ => self.setState({ dragging: true }),
                onMouseUp: _ => self.setState({ dragging: false }),
                onMouseMove: self.dragOnMouseMove,
                onMouseLeave: _ => self.setState({ dragging: false })
            }, reduceMap = mapArr => {
                let retArr = [];
                mapArr.forEach(item => item instanceof Array && retArr.push(...item));
                return retArr;
            }, showType = type => {//0,3表示数字和可修改对象，使用div；其他两种使用img
                if ([0, 3, 4].includes(type)) return 1;
                return 2;
            }, imgSrc = img => img ? PIC_SERVER_PATH + img : undefined,
            gImg = () => {
                let NS = spd.listData[spd.nowSelected], detailIndex = NS.detailItemNowSelected, img = NS.details[detailIndex].img;
                return imgSrc(img);
            },
            itemStyle = (item, type, BS, BBS) => {
                if (BS === null) return;
                let styleConf = {};
                if ([0, 3, 4].includes(type)) {
                    styleConf.color = item.color ? item.color : '#000000';
                    styleConf.lineHeight = (item.lineHeight ? item.lineHeight : 100) + '%';
                    styleConf.fontSize = item.size * BS.width / BBS.width;
                    if (styleConf.fontSize < 12) {
                        styleConf.transform = "scale(" + styleConf.fontSize / 12 + ")";
                        styleConf.transformOrigin = "right top";
                        styleConf.fontSize = 12;
                    }
                    styleConf.fontSize += 'px';
                } else if ([1, 2].includes(type)) {
                    styleConf.width = item.size + '%';
                }
                return {
                    ...styleConf,
                    top: item.top * (BBS.height / BBS.width) * BS.width / 100 + 'px',
                    right: item.right * BS.width / 100 + 'px'
                }
            },
            gStyle = () => {
                let sList = spd.listData[spd.nowSelected], item = sList.details[sList.type === 1 ? sList.detailItemNowSelected : 0],
                    BS = spd.bckSize;
                return itemStyle(item, sList.type, BS, spd.boardBckSize);
            };
        return (
            <div className={style["PicEditPartWrapper"]} {...dragConf}>
                <div className="innerBox" ref="innerBox">
                    {
                        spd.bckPic !== null &&
                        <div
                            className={"imgWrapper" + ((spd.bckSize && !self.state.firstLoad) ? "" : " loadingImg")}
                            style={self.getBckWrapperStyle()}
                            ref="elementsWrapper"
                        >
                            <img
                                className="from--1"
                                src={PIC_SERVER_PATH + spd.bckPic}
                                alt=""
                                draggable={false}
                                style={self.state.imgStyle}
                                onLoad={self.imgOnLoad} />
                            {
                                spd.showAll ?
                                    reduceMap(spd.listData.map((item, index) =>
                                        item.details.map((detail, detailIndex) =>
                                            showType(item.type) === 2 ?
                                                <div
                                                    className={"from-" + index + " item"}
                                                    style={itemStyle(detail, item.type, spd.bckSize, spd.boardBckSize)}
                                                    key={"detail-" + index + "-" + detailIndex}
                                                    onMouseDown={e => self.itemMouseDown(e, index, detailIndex)}
                                                >
                                                    <img
                                                        className={"from-" + index + " itemImg"}
                                                        alt=""
                                                        draggable={false}
                                                        src={imgSrc(detail.img)}
                                                    />
                                                    {
                                                        ['left', 'right', 'top', 'bottom'].map((position, pindex) =>
                                                            <div
                                                                className={`line ${position}${(parseInt(self.state.currentMouseDownIndex) === index && detail[position + '-line'] && self.state.dragging) ? '' : ' hide'}`}
                                                                key={pindex}
                                                            ></div>
                                                        )
                                                    }
                                                </div>
                                                :
                                                showType(item.type) === 1 &&
                                                <div
                                                    className={"from-" + index + " item itemNum"}
                                                    style={itemStyle(detail, item.type, spd.bckSize, spd.boardBckSize)}
                                                    onMouseDown={e => self.itemMouseDown(e, index, detailIndex)}
                                                    key={"detail-" + index + "-" + detailIndex}
                                                >
                                                    {detail.text || "456"}
                                                    {
                                                        ['left', 'right', 'top', 'bottom'].map((position, pindex) =>
                                                            <div
                                                                className={`line ${position}${(parseInt(self.state.currentMouseDownIndex) === index && detail[position + '-line'] && self.state.dragging) ? '' : ' hide'}`}
                                                                key={pindex}
                                                            ></div>
                                                        )
                                                    }
                                                </div>
                                        )
                                    ))
                                    :
                                    showEle() === 2 ?
                                        <img
                                            alt=""
                                            {...imgConf}
                                            src={gImg()}
                                            style={gStyle()}
                                            onMouseDown={e => {
                                                let item = spd.listData[spd.nowSelected].details[spd.listData[spd.nowSelected].detailItemNowSelected];
                                                self.setState({
                                                    dragging: true,
                                                    mouseDownCoord: [e.clientX, e.clientY],
                                                    mouseDownItemCoord: [item.top, item.right]
                                                });
                                            }}
                                        />
                                        : showEle() === 1 &&
                                        <div
                                            className="item itemNum"
                                            style={gStyle()}
                                            onMouseDown={e => {
                                                self.setState({
                                                    dragging: true,
                                                    mouseDownCoord: [e.clientX, e.clientY],
                                                    mouseDownItemCoord: [spd.listData[spd.nowSelected].details[0].top, spd.listData[spd.nowSelected].details[0].right]
                                                });
                                            }}
                                        >{spd.listData[spd.nowSelected].details[0].text || "456"}</div>
                            }
                        </div>
                    }
                </div>
            </div >
        );
    }
}
