import React, { useCallback } from 'react';
import style from './index.module.scss';
import { Select } from 'antd'

const { Option } = Select

const Comp = (props) => {
    const { tabs, selectedTab } = props;
    const onChange = useCallback((item) => {
        return () => {
            if (typeof props.onChange === 'function') {
                props.onChange(item);
            };
        }
    }, [props]);

    const handleChange = useCallback((opts) => {
        return v => {
            const selectedOpt = opts.find(({ time_type }) => time_type === v)
            onChange(selectedOpt)()
        }
    }, [onChange])

    return (
        <div className={style['wrapper']}>
            <div className="tabs">
                {
                    (tabs ?? [])
                        .map((item, index, arr) => {
                            const { name, isOpts, options, time_type } = item;
                            let isSelected = false
                            if (isOpts !== true) {
                                if (time_type === selectedTab?.type) {
                                    isSelected = true
                                }
                            } else {
                                if (options.find(({ time_type }) => time_type === selectedTab?.type)) {
                                    isSelected = true
                                }
                            }
                            return [
                                (
                                    <div className="item" key={index}
                                        {
                                        ...isOpts === true ? {} : {
                                            onClick: onChange(item)
                                        }
                                        }
                                    >
                                        <div className={"text_wrapper" + (isSelected ? " selected" : "")}>
                                            <div className="text">{name}</div>
                                            {
                                                isOpts === true && (
                                                    <Select
                                                        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: -50, overflow: 'hidden', opacity: 0 }}
                                                        value={null}
                                                        onChange={handleChange(options)}>
                                                        {
                                                            (options ?? [])
                                                                .map((opt, optIndex) => {
                                                                    const { name, time_type } = opt
                                                                    return <Option value={time_type} key={optIndex}>{name}</Option>
                                                                })
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            ]
                                .concat(
                                    index < arr.length - 1 ? [
                                        (
                                            <div className="devide_line" key={`line_${index}`}></div>
                                        )
                                    ] : []
                                );
                        })
                }
            </div>
        </div>
    );
};

export default Comp;
