import React from 'react';
import style from './index.module.scss';
import { Spin, Space } from 'antd';
import { significantNum } from 'common/utils';

export default class Comp extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }

    render() {
        const { config, loading, meta_list, data } = this.props;
        const pList = (meta_list ?? []).map(item => {
            const v = data?.[item.tag_name] ?? null
            const pV = typeof v === 'number' ? significantNum(v) : "--"
            return Object.assign({}, item, { v, pV })
        })
        let pDVal = "--"
        if (typeof pList?.[0]?.v === 'number' && typeof pList?.[1]?.v === 'number') {
            const dVal = pList?.[0]?.v - pList?.[1]?.v
            pDVal = significantNum(dVal)
        }

        return (
            <div className={style["wrapper"]}>
                <div className="header">
                    <div className="colored_bar"></div>
                    <div className="title">{config?.title ?? "--"}</div>
                </div>
                <div className="content">
                    <Space>
                        <div>{meta_list?.[0]?.name ?? "--"}：</div>
                        <div className='value'>{pList?.[0]?.pV}</div>
                    </Space>
                    <Space>
                        <div>{meta_list?.[1]?.name ?? "--"}：</div>
                        <div className='value'>{pList?.[1]?.pV}</div>
                    </Space>
                    <Space>
                        <div>{config?.deltaTitle ?? "--"}：</div>
                        <div className='value'>{pDVal}</div>
                    </Space>
                </div>
                {
                    loading === true && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        );
    }
}
