import React, { useRef, useEffect, useMemo } from 'react';
import style from './index.module.scss';
import Wrapper from '../Wrapper';
import * as echarts from 'echarts~5';
import { useData } from './hooks';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';
import { withRouter } from 'react-router';

const renderChart = (refNode, sum_num, non_cpl_num) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (typeof sum_num !== 'number' || typeof non_cpl_num !== 'number' || sum_num < non_cpl_num) {
            instance.clear();
            return;
        };
        instance.setOption({
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['85%', '95%'],
                    emphasis: {
                        scale: false,
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {
                            value: 1048,
                            itemStyle: {
                                color: '#F0F0F0',
                            },
                        },
                    ]
                },
                {
                    name: '',
                    type: 'pie',
                    radius: ['82%', '98%'],
                    itemStyle: {
                        borderRadius: 10,
                    },
                    emphasis: {
                        scale: false,
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {
                            value: non_cpl_num,
                            itemStyle: {
                                color: '#FB6060',
                            },
                        },
                        {
                            value: sum_num - non_cpl_num,
                            itemStyle: {
                                color: 'transparent',
                            },
                        },
                    ]
                }
            ]
        });
    };
};

const Comp = (props) => {
    const { title, icon, category, timeInfo } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        if (!!timeInfo) {
            setDataFunc.current(Object.assign({}, timeInfo, { category }));
        };
    }, [timeInfo, category]);

    const [total_num, cpl_num, non_cpl_num, alarm_num] = useMemo(() => {
        const next_cpl = data?.factors_brief?.cpl_num;
        const next_non_cpl = data?.factors_brief?.non_cpl_num;
        const next_alarm = data?.factors_brief?.alarm_num;
        const next_total = data?.factors_brief?.total_num;
        return [next_total, next_cpl, next_non_cpl, next_alarm];
    }, [data]);
    // 有上下关系
    const refNode = useRef(null);
    useEffect(() => {
        if (!!refNode.current) {
            renderChart(refNode.current, total_num, non_cpl_num);
        };
    }, [total_num, non_cpl_num]);

    const onResize = () => {
        if (!!refNode.current) {
            const instance = echarts.getInstanceByDom(refNode.current);
            if (!!instance) instance.resize();
        };
    };

    const watchDetailOnClick = () => {
        let suffix = `category=${category}`;
        if (!!timeInfo) {
            suffix += `&time=${timeInfo.time}&timeKey=${timeInfo.key}`;
        };
        const pathname = props?.history?.location?.pathname ?? null;
        if (typeof pathname === 'string' && pathname !== "") {
            const layoutName = pathname.split("/")[1] ?? null;
            if (typeof layoutName === 'string' && layoutName !== "") {
                props.history.push(`/${layoutName}/FactorStatistics?${suffix}`);
                return;
            };
        };
        props.history.push(`/PEIMSV2/FactorStatistics?${suffix}`);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={icon}
                title={title}
                watchDetail={true}
                watchDetailOnClick={watchDetailOnClick}
            >
                <div className={style['content']}>
                    <div className="header">
                        <div className="text_wrapper">
                            <div className="title">指标总数：</div>
                            <div className="value value_1">{total_num ?? "--"}项</div>
                        </div>
                        <div className="text_wrapper">
                            <div className="title">已完成指标：</div>
                            <div className="value value_2">{cpl_num ?? "--"}项</div>
                        </div>
                        <div className="text_wrapper">
                            <div className="title">预警指标：</div>
                            <div className="value value_3">{alarm_num ?? "--"}项</div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="left">
                            <div className="title">指标达标情况</div>
                            <div className="chart_wrapper">
                                <div className="resize">
                                    <DomResize onResize={onResize} debounce_wait={20} />
                                </div>
                                <div className="inner_wrapper">
                                    <div className="content">
                                        <div className="title">未完成指标</div>
                                        <div className="value">{non_cpl_num ?? "--"}</div>
                                        <div className="line"></div>
                                    </div>
                                </div>
                                <div className="chart" ref={refNode}></div>
                            </div>
                            <div className="tool_tip">
                                <div className="item">
                                    <div className="icon" style={{ backgroundColor: '#F0F0F0' }}></div>
                                    <div className="text">已达标</div>
                                </div>
                                <div className="item">
                                    <div className="icon" style={{ backgroundColor: '#FB6060' }}></div>
                                    <div className="text">未达标</div>
                                </div>
                            </div>
                        </div>
                        <div className="line_wrapper">
                            <div className="line"></div>
                        </div>
                        <div className="right">
                            <div className="title">合格率最低的指标</div>
                            <div className="rank_wrapper">
                                {
                                    // 去掉括号会有问题，涉及到??优先级
                                    (data?.low_cpl_factors ?? [])
                                        .filter((item, index) => index < 5)
                                        .map((item, index) => {
                                            const { name, cpl_rate } = item;
                                            let rate = "--";
                                            if (typeof cpl_rate === 'number') {
                                                rate = (cpl_rate * 100).toFixed(1);
                                            };
                                            return (
                                                <div className={"item" + (index % 2 === 1 ? "" : " odd")} key={index}>
                                                    <div className="text">{index + 1}.{name}</div>
                                                    <div className="rate">{rate}%</div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Comp);
