import React, { useEffect, useState } from 'react'
import { message, Modal, Table } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import AddTemplate from '../../CapsuleBoard/AddCapsule'
import { getTemplateContentText } from '../../CapsuleBoard/AddCapsule/utils'
import { get, reqDelete } from '../../../common/utils'
import { initTableColumns } from './column'
import style from './index.module.scss'

const baseUrl = process.env.REACT_APP_SERVER_PATH

/** 胶囊汇总表格 */
const CapsuleTable = props => {
  const { filterParams, tags, changeExportIds } = props
  const [loading, setLoading] = useState(false)
  const [selectedRowIds, changeSelectedRowIds] = useState([]) // 勾选的胶囊
  const [capsules, setCapsules] = useState([]) // 事件胶囊列表
  const [isModalVisible, setIsModalVisible] = useState(false) // 标记编辑/详情窗口显隐
  const [capsuleId, setCapsuleId] = useState(null) // 编辑/详情 的胶囊id
  const [isEdit, setIsEdit] = useState(false) // true 编辑 false 详情
  const [total, setTotal] = useState(0) // 某条件下 胶囊总数
  const [pageNum, changePageNum] = useState(1) // 当前页码
  const [pageSize, changePageSize] = useState(20) // 每页条数 首次准备3页数据
  const tableColumns = initTableColumns(tags, pageNum, pageSize) // 列
  const scrollY = window.screen.height - 400

  useEffect(() => {}, [])

  useEffect(() => {
    filterParams && refreshTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams])

  /** 查询 */
  const getFilterCapsules = (page_num, page_size) => {
    setLoading(true)
    const api = 'api/v1/event/event/capsuleQuery'
    get(`${baseUrl}${api}`, {
      ...filterParams,
      pageNum: page_num,
      pageSize: page_size
    }).then(res => {
      handleCapsules(page_num, res.data)
    })
  }

  /** 格式化表格数据源 */
  const handleCapsules = (current, resultData) => {
    if (!!resultData && resultData.error === 0 && !!resultData.results) {
      const tempList = resultData.results.list.map((item, index) => {
        const { id, name: creator, jsonContent: json_content } = item
        let jsonContent = {}
        try {
          jsonContent = JSON.parse(json_content)
        } catch (error) {}
        return Object.assign({}, item, {
          key: id,
          creator,
          ...jsonContent,
          contentText: getTemplateContentText(jsonContent.templateId, jsonContent) || '--',
          operation: (
            <>
              <span style={{ marginRight: 5 }} onClick={e => editCapsuleClick(e, id)}>
                编辑
              </span>
              <span onClick={e => delCapsuleClick(e, id)}>删除</span>
            </>
          )
        })
      })

      setTotal(resultData.results.total || 0)
      setCapsules(tempList)
    } else {
      message.error('查询失败，请稍后重试！')
    }
    changePageNum(current)
    setLoading(false)
  }

  /** 处理多选 */
  const rowSelection = {
    // 用户手动选择/取消选择某行的回调
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      changeSelectedCapsuleIds([record], selected)
    },
    // 用户手动选择/取消选择所有行的回调
    onSelectAll: (selected, selectedRows, changeRows) => {
      changeSelectedCapsuleIds(changeRows, selected)
    }
  }

  /** 选中或取消选中某些胶囊 */
  const changeSelectedCapsuleIds = (rows, selected) => {
    let tempList = selectedRowIds.concat()
    rows.forEach(row => {
      if (selected) {
        tempList.push(row.id)
      } else {
        tempList = tempList.filter(item => item !== row.id)
      }
    })
    const results = [...new Set(tempList)]
    changeSelectedRowIds(results)
    changeExportIds(results)
  }

  /** 编辑胶囊 */
  const editCapsuleClick = (e, id) => {
    e.stopPropagation()
    setCapsuleId(id)
    setIsEdit(true)
    setIsModalVisible(true)
  }

  /** 删除胶囊 */
  const delCapsuleClick = (e, id) => {
    e.stopPropagation()
    changeSelectedCapsuleIds([{ id }], false)
    Modal.confirm({
      title: '确认删除？',
      // content: '确认删除吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        console.log('确认删除')
        // TODO: 删除胶囊接口
        delCapsuleById(id)
      }
    })
  }

  /** 行操作->删除胶囊 */
  const delCapsuleById = id => {
    reqDelete(`${baseUrl}api/v1/event/capsule/capsuleDeleteByIdSearch`, { id }).then(resultData => {
      if (resultData.data && resultData.data.error === 0) {
        message.success('删除成功')
        getFilterCapsules(pageNum, pageSize)
      } else {
        message.error(resultData.data.msg)
      }
    })
  }

  /** 刷新列表 */
  const refreshTableData = () => {
    // 重置已经勾选的胶囊id集合
    changeSelectedRowIds([])
    changeExportIds([])
    getFilterCapsules(1, pageSize)
  }

  /** 单击行查看胶囊详情 */
  const viewCapsuleDetailClick = record => {
    setCapsuleId(record.id)
    setIsModalVisible(true)
  }

  /** 关闭胶囊面板 */
  const closeCapsuleModal = (event, isRefreshEventList) => {
    setCapsuleId(null)
    setIsEdit(false)
    setIsModalVisible(false)
    if (isRefreshEventList) {
      // TODO: 刷新事件列表
      getFilterCapsules(pageNum, pageSize)
    }
  }

  /**
   * 分页事件处理
   * @param {*} pagination
   * @param {*} filters
   * @param {*} sorter
   * @param {*} extra: { currentDataSource: [], action: paginate | sort | filter }
   */
  const tableChangeEvent = (pagination, filters, sorter, extra) => {
    console.log('tableChangeEvent:', pagination, filters, sorter, extra)
    if (extra) {
      // 分页
      if (extra.action === 'paginate') {
        changePageSize(pagination.pageSize)
        getFilterCapsules(pagination.current, pagination.pageSize)
      }
    }
  }
  return (
    <div className={style['capsuleTable']}>
      <Table
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRowIds,
          ...rowSelection
        }}
        bordered
        loading={loading}
        columns={tableColumns}
        dataSource={capsules}
        pagination={{
          total: total,
          current: pageNum,
          pageSize: pageSize,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: [20, 50, 100],
          showTotal: total => `共 ${total || 0} 条`
        }}
        size="middle"
        scroll={{ y: scrollY }}
        onRow={record => {
          return {
            onClick: event => viewCapsuleDetailClick(record)
          }
        }}
        onChange={tableChangeEvent}
      />
      {/* 编辑胶囊/胶囊详情 */}
      {isModalVisible && (
        <AddTemplate
          capsuleId={capsuleId}
          isEdit={isEdit}
          onOk={e => closeCapsuleModal(e, true)}
          onCancel={closeCapsuleModal}
        />
      )}
    </div>
  )
}

CapsuleTable.propTypes = {
  changeExportIds: PropTypes.func.isRequired
}

export default CapsuleTable
