import React from 'react';
import { withRouter } from 'react-router';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './SideMenu.scss';
import { menus } from './constants.js';
import { findPathIndexs } from './utils.js';
import { message } from 'antd';
import IconComp from '../../components/Icon';

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPageIndex: null,//[firstIndex, secondIndex]
            unfoldIndexs: [],
        };
    }

    componentDidMount() {
        const { location: { pathname } } = this.props;
        this.urlCheck(pathname);
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname: prevPathname } } = prevProps;
        const { location: { pathname } } = this.props;
        if (prevPathname !== pathname) {
            // console.log(prevPathname, pathname);
            this.urlCheck(pathname);
        };
    }

    urlCheck = (pathname) => {
        const pathIndexs = findPathIndexs(pathname, menus);
        let nextState = {
            selectedPageIndex: null,
            unfoldIndexs: [],
        };
        if (!!pathIndexs) {
            let unfoldIndexs = [];
            if (!!menus[pathIndexs[0]].children) {
                unfoldIndexs = [pathIndexs[0]];
            };
            nextState = Object.assign({}, nextState, {
                selectedPageIndex: pathIndexs.filter((_, index) => index < 2),
                unfoldIndexs,
            });
        };
        this.setState(nextState);
    }

    menuItemOnClick = (index) => {
        if (!!menus[index].route) {
            // 这种情况下，后续会选中一级路由并展开
            this.props.history.push(menus[index].route);
            return;
        } else {
            if (!menus[index].children) {
                // 没有路由，没有children，呈选中状态
                this.setState({ selectedPageIndex: [index], unfoldIndexs: [] });
            } else {
                const { unfoldIndexs } = this.state;
                // 没有路由，有children，呈展开/关闭状态，其他不变
                this.setState({ unfoldIndexs: unfoldIndexs.includes(index) ? [] : [index] });
            };
        };
    }

    childItemOnClick = (index, childIndex) => {
        const { route } = menus[index].children[childIndex];
        if (!!route) {
            this.props.history.push(route);
            return;
        } else {
            // 二级菜单没有路由时，点击提示未配置
            message.error('菜单尚未配置！');
        };
    }

    render() {
        const { selectedPageIndex, unfoldIndexs } = this.state;
        let firstSelectedIndex = null;
        let secondSelectedIndex = null;
        if (selectedPageIndex !== null) {
            if (typeof selectedPageIndex[0] === 'number') {
                firstSelectedIndex = selectedPageIndex[0];
            };
            if (typeof selectedPageIndex[1] === 'number') {
                secondSelectedIndex = selectedPageIndex[1];
            };
        };

        return (
            <div className="side_menu_wrapper">
                <div className="side_menu_content_wrapper">
                    {
                        menus
                            .map(({ icon, name, children }, index) => {
                                const itemSelected = firstSelectedIndex === index;
                                const color = itemSelected ? "rgba(68, 243, 218, 1)" : "#FFFFFF";
                                const arrowStyle = { color, marginLeft: 'auto', fontSize: 14 };
                                const isUnfold = unfoldIndexs.includes(index);
                                return (
                                    <div className="menu_item_wrapper" key={index}>
                                        <div
                                            className="menu_item"
                                            onClick={() => this.menuItemOnClick(index)}
                                        >
                                            {
                                                itemSelected && (
                                                    <div className="selected_icon" />
                                                )
                                            }
                                            <div style={{ marginLeft: 40 }}>
                                                <IconComp src={icon} width={32} height={32} color={itemSelected ? [68, 243, 218] : [255, 255, 255]} />
                                            </div>
                                            {/* <div style={{ width: 32, height: 32, marginLeft: 40, overflow: 'hidden' }}>
                                                <div style={{ width: '100%', height: '100%', backgroundImage: `url(${icon})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', filter: `drop-shadow(32px 0px 0px ${itemSelected ? "rgba(68, 243, 218, 1)" : "rgba(255, 255, 255, 1)"})`, position: 'relative', left: -32, borderRight: '32px solid transparent' }} />
                                            </div> */}
                                            {/* <div style={{ width: 64, height: 32, marginLeft: 8, overflow: 'hidden', position: 'relative' }}>
                                                <img alt="" src={icon} style={{ filter: 'drop-shadow(32px 0px 0px rgba(68, 243, 218, 1))', width: 32, height: '100%', position: 'relative', left: itemSelected ? 0 : 32 }} />
                                                <div style={{ position: 'absolute', height: 32, width: 32, top: 0, left: 0, backgroundColor: 'rgba(33, 40, 48, 1)' }} />
                                            </div> */}
                                            <div className="name" style={{ color }}>{name}</div>
                                            {
                                                !!children && (
                                                    isUnfold ?
                                                        (
                                                            <UpOutlined style={arrowStyle} />
                                                        ) : (
                                                            <DownOutlined style={arrowStyle} />
                                                        )
                                                )
                                            }
                                        </div>
                                        {
                                            isUnfold && !!children && (
                                                <div className="children_wrapper">
                                                    {
                                                        children
                                                            .map(({ name }, childIndex) => {
                                                                return (
                                                                    <div
                                                                        key={childIndex}
                                                                        className="child_content"
                                                                        style={{
                                                                            color: itemSelected && secondSelectedIndex === childIndex ? "#FFFFFF" : "#9FA0BA",
                                                                        }}
                                                                        onClick={() => this.childItemOnClick(index, childIndex)}
                                                                    >{name}</div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Comp);
