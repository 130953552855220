import MainPage from './MainPage.js'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setValues, setInitialValues } from 'common/redux/MainPageV2/actions.js'
import { post, get, getCachedUserInfo } from 'common/utils'
import { mergeDataSource, getHisTagListData } from './utils'

const getAlarmList = (dispatch, getState) => {
  const {
    mainPageV2: { sys_id }
  } = getState()
  return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/alarm/alarm_events/list', {
    page: 1,
    page_size: 10,
    level: 1,
    sys_id
  }).then(retData => {
    // console.log(retData);
    if (retData.data && retData.data.errcode === 0) {
      dispatch(setValues({ alarms: retData.data.results.filter((item, index) => index < 6) }))
      return true
    }
    return false
  })
}

const getEvents = (dispatch, getState) => {
  const {
    mainPageV2: { sys_id }
  } = getState()
  return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/pc/prod_events', {
    page: 1,
    page_size: 10,
    sys_id,
    index_visible: 1
  }).then(retData => {
    // console.log(retData);
    if (retData.data && retData.data.results) {
      dispatch(setValues({ events: retData.data.results.filter((item, index) => index < 6) }))
      return true
    }
    return false
  })
}

const getChartLayout = (dispatch, getState) => {
  return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/layout_conf/list', {
    key_list: ['main_dashboard']
  }).then(retData => {
    // console.log('getChartLayout', retData);
    if (retData.data && retData.data.errcode === 0) {
      const layout = retData.data
      let sys_id = null
      let customCharts = []
      if (layout.results.length > 0) {
        sys_id = layout.results[0].sys_id
        const layout_id = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0]._id
        customCharts =
          layout.cus_config.filter(({ layout_id: layoutId }) => layoutId === layout_id)[0]
            .cus_history_list || []
      }
      dispatch(
        setValues({
          layout,
          sys_id,
          customCharts
        })
      )
      return true
    }
    return false
  })
}

const getLatestOne = (dispatch, getState) => {
  //似乎只需要请求一次？？
  const {
    mainPageV2: { sys_id, layout }
  } = getState()
  return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/optimization/get_latest_one', {
    sys_id,
    status: 2
  }).then(retData => {
    // console.log('getLatestOne', retData);
    if (retData.data && retData.data.errcode === 0 && retData.data.result) {
      const { ctrl_results, bcmk_results } = retData.data.result.result
      const originDataSource = (['yxsn'].includes(process.env.REACT_APP_PROJECT_TAG)
        ? []
        : ctrl_results
      )
        .concat(bcmk_results)
        .map((item, key) => Object.assign({}, item, { key }))
      // const { mainPageV2: { lockedItems } } = getState();
      let lockedItems = []

      const layout_id = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0]._id
      const cus_config = (layout.cus_config || []).filter(
        ({ layout_id: layoutId }) => layoutId == layout_id
      )[0]
      // console.log('cus_config', cus_config);
      if (!!cus_config) {
        if (!!cus_config.mid_list && !!cus_config.mid_list.locked_items) {
          lockedItems = cus_config.mid_list.locked_items
        }
      }
      // console.log(lockedItems);
      dispatch(
        setValues({
          oneResult: retData.data.result,
          dataSource: mergeDataSource(originDataSource, lockedItems)
        })
      )
      return true
      // const { ctrl_results, bcmk_results } = retData.data.result.result;
      // return [...ctrl_results, ...bcmk_results].map(({ cur_value_tag }) => cur_value_tag);
    }
    {
      //请求失败或者网络有问题时怎么处理？
      return false
      // store.dispatch(mainpageAction.setOneResult(null));
      // return [];
    }
  })
}

const getTagList = getState => {
  const {
    mainPageV2: { sys_id, oneResult, layout }
  } = getState()
  let tag_list = []
  if (oneResult) {
    const {
      result: { state_results, ctrl_results, bcmk_results, ckw_results }
    } = oneResult
    tag_list = tag_list.concat(
      [...state_results, ...ctrl_results, ...bcmk_results, ...ckw_results].map(
        ({ cur_value_tag }) => cur_value_tag
      )
    )
  }
  // console.log(tag_list);
  if (!!layout) {
    const result = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0]
    if (result) {
      const { panel_1, panel_2 } = result
      tag_list = tag_list.concat(
        []
          .concat(...panel_1.map(({ tag_1, tag_2_x, tag_3_x }) => [tag_1, tag_2_x, tag_3_x]))
          .concat(panel_2.map(({ tag_name }) => tag_name))
      )
    }
  }
  // console.log(
  //     tag_list
  //         .filter(tag_name => !!tag_name)
  // );
  return [...new Set(tag_list.filter(tag_name => !!tag_name))]
}

const getLatestData = ownProps => {
  const time_end = new URLSearchParams(ownProps.location.search).get('time') //?time=2020-03-28 12:12:12
  return (dispatch, getState) => {
    const tag_list = getTagList(getState)
    if (tag_list.length === 0) return Promise.resolve(false)
    return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/data/get_latest_data', {
      ...(time_end
        ? {
            time_end
          }
        : {}),
      tag_list
    }).then(retData => {
      // console.log('getLatestData', retData);
      if (retData.data && retData.data.errcode === 0) {
        const tag2Value = {}
        retData.data.results.forEach(([v, _, key]) => (tag2Value[key] = v))
        dispatch(setValues({ tag2Value }))
        return true
      }
      return false
    })
  }
}

const getLastExecStatus = () => {
  return (dispatch, getState) => {
    const {
      mainPageV2: { sys_id }
    } = getState()
    return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/optimization/get_last_exec_status', {
      sys_id
    }).then(retData => {
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0 && retData.data.result) {
        // const { status, create_time } = retData.data.result;
        // if (status == 2 || status == 3) {
        //     if (last_create_time === null) {
        //         this.setState({ create_time });
        //     } else if (create_time !== last_create_time) {
        //         this.setState({ create_time });
        //         this.initPage();
        //     }
        // }
        return {
          isSuccess: true,
          result: retData.data.result
        }
      } else {
        return {
          isSuccess: false,
          result: null
        }
      }
    })
  }
}

const getExamDetailData = () => {
  return (dispatch, getState) => {
    return get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/get_ongoing_one', {}).then(
      retData => {
        // console.log('getExamDetailData', retData);
        const userInfo = getCachedUserInfo()
        // if (userInfo && retData.data && retData.data.errcode === 0 && !!retData.data.result) {
        //     const { user_id, name: cached_name } = userInfo;
        //     const { results, user_name, rule_data, name, status, details } = retData.data.result;
        //     return {
        //         user_name: cached_name,
        //         rule_data,
        //         name,
        //         status,
        //         results: results
        //             .map(item => ({
        //                 ...item,
        //                 user_values: item.user_values.filter(({ user_id: userId }) => userId === user_id),
        //                 detail: details.filter(({ sys_id: sysId }) => sysId === item.sys_id)[0] || {},
        //             }))
        //             .filter(({ user_values }) => user_values.length > 0)
        //             .map((item, index) => ({
        //                 ...item,
        //                 user_value: item.user_values[0],
        //                 selected: index === 0,
        //             }))
        //             .map(item => ({
        //                 ...item,
        //                 user_value: {
        //                     ...item.user_value,
        //                     score_datas: item.user_value.score_datas
        //                         .map((data, index) => {
        //                             const factor = rule_data.factors
        //                                 .filter(({ _id }) => _id === data.factor_id)[0];
        //                             return {
        //                                 ...data,
        //                                 key: index + 1,
        //                                 ...(factor ? {
        //                                     meta_name: factor.meta_name,
        //                                     weight: factor.weight + '%',
        //                                     final_score: Math.floor(factor.weight * data.rank_score * 10) / 1000,
        //                                 } : {})
        //                             };
        //                         })
        //                 }
        //             })),
        //     };
        // }
        return null
      }
    )
  }
}

const selectSystemOnChange = sys_id => {
  return (dispatch, getState) => {
    const {
      mainPageV2: { layout }
    } = getState()
    let customCharts = []
    if (layout.results.length > 0) {
      const layout_id = layout.results.filter(({ sys_id: sysId }) => sysId == sys_id)[0]._id
      customCharts =
        layout.cus_config.filter(({ layout_id: layoutId }) => layoutId === layout_id)[0]
          .cus_history_list || []
    }
    dispatch(setValues({ sys_id, customCharts }))
  }
}

const mapStateToProps = state => {
  const { mainPageV2 } = state

  return {
    ...mainPageV2
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectSystemOnChange: sys_id => {
      dispatch(selectSystemOnChange(sys_id))
    },
    getAlarmList: () => dispatch(getAlarmList),
    getEvents: () => dispatch(getEvents),
    getChartLayout: () => dispatch(getChartLayout),
    getLatestOne: () => dispatch(getLatestOne),
    getLatestData: () => dispatch(getLatestData(ownProps)),
    updateContentWidth: contentItemWidth => {
      dispatch(setValues({ contentItemWidth }))
    },
    getHisTagListData: () => dispatch(getHisTagListData(ownProps)),
    getLastExecStatus: () => dispatch(getLastExecStatus()),
    resetState: () => dispatch(setInitialValues()),
    getExamDetailData: () => dispatch(getExamDetailData())
  }
}

const WrapperedMainPage = connect(mapStateToProps, mapDispatchToProps)(MainPage)

export default withRouter(WrapperedMainPage)
