import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize/index.js'
import * as echarts from 'echarts~5';
import { post } from 'common/utils';

const initVals = {}
const Comp = (props) => {
    const { list } = props;
    const nodes = useRef(initVals)
    const refFunc = useCallback((ind) => {
        return refN => {
            nodes.current[ind] = refN
            const renderFunc = props?.renderFunc ?? (() => { })
            renderFunc(refN, props.list[ind])
        }
    }, [props])
    const handleResize = useCallback(() => {
        Object.keys(nodes.current).some(key => {
            const refN = nodes.current[key]
            if (refN) {
                const ins = echarts.getInstanceByDom(refN)
                if (ins) {
                    ins.resize()
                }
            }
            return false
        })
    }, [])
    const [tag2Name, setTag2Name] = useState(() => ({}))
    useEffect(() => {
        // console.log(list)
        let delay = true
        if (list && list.length > 0) {
            post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
                tag_name_list: list.map(({ tag_name }) => tag_name),
                page: 1,
                page_size: list.length + 1
            })
                .then(retData => {
                    if (delay) {
                        // console.log(retData)
                        if (retData?.data?.errcode === 0) {
                            setTag2Name((retData?.data?.results ?? []).reduce((pV, cV) => {
                                pV[cV.tag_name] = cV.name
                                return pV
                            }, {}))
                        } else {
                            setTag2Name({})
                        }
                    }
                })
        }
        return () => {
            delay = false
        }
    }, [list])
    return (
        <div className={style['wrapper']}>
            <div style={{ height: 0, opacity: 0 }}>
                <DomResize onResize={handleResize} />
            </div>
            {
                (list ?? [])
                    .map((item, index) => {
                        const { tag_name } = item
                        return (
                            <div className='item' key={index}>
                                <div className='top'>{tag2Name?.[tag_name] ?? `--（${tag_name}）`}</div>
                                <div className='bottom' ref={refFunc(index)}></div>
                            </div>
                        )
                    })
            }
        </div>
    );
};

export default Comp;
