import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input, Select, message } from 'antd'
import style from './custom.module.scss'
const { Option } = Select
const actionOptions = [
  { label: '未操作', value: 1 },
  { label: '开启', value: 2 },
  { label: '关闭', value: 3 }
]

/** UI模版 templateId=2 */
const Com = props => {
  const { capsuleDetailInfo, cacheData, resetAction } = props
  const [threshold_1, setThreshold_1] = useState(1) // 阀1
  const [number_1, setNumber_1] = useState(null)
  const [threshold_2, setThreshold_2] = useState(1) // 阀2
  const [number_2, setNumber_2] = useState(null)

  useImperativeHandle(props.refInstance, () => ({
    checkValidate,
    getCustomData: () => {
      return { threshold_1, number_1, threshold_2, number_2 }
    }
  }))

  useEffect(() => {
    if (capsuleDetailInfo && Object.keys(capsuleDetailInfo).length > 0) {
      // 编辑
      const { jsonContent } = capsuleDetailInfo
      setThreshold_1(jsonContent.threshold_1)
      setNumber_1(jsonContent.number_1)
      setThreshold_2(jsonContent.threshold_2)
      setNumber_2(jsonContent.number_2)
    } else {
      // 新建时会产生缓存数据
      const cacheInfo = cacheData ? cacheData : null
      if (cacheInfo) {
        const jsonContent = cacheInfo.json_content || {}
        if (Object.keys(jsonContent).length > 0) {
          setThreshold_1(jsonContent.threshold_1)
          setNumber_1(jsonContent.number_1)
          setThreshold_2(jsonContent.threshold_2)
          setNumber_2(jsonContent.number_2)
        }
      }
    }
  }, [])

  /** 重置 */
  useEffect(() => {
    if (resetAction) {
      setThreshold_1(1)
      setNumber_1(null)
      setThreshold_2(1)
      setNumber_2(null)
    }
  }, [resetAction])

  const handleChangeOne = value => {
    setThreshold_1(value)
    setNumber_1(null)
  }
  const handleChangeTwo = value => {
    setThreshold_2(value)
    setNumber_2(null)
  }
  const changeNumber_1 = newValue => {
    const formatValue = checkNumberValue(newValue)
    if (formatValue !== 'error') {
      setNumber_1(formatValue)
    }
  }
  const changeNumber_2 = newValue => {
    const formatValue = checkNumberValue(newValue)
    if (formatValue !== 'error') {
      setNumber_2(formatValue)
    }
  }

  const checkNumberValue = value => {
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (value || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return 'error'
    }
    return formatValue
  }

  /** 字符串转数字 */
  const formatNumber = (newValue, index) => {
    if (!newValue) return
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    index === 1 ? setNumber_1(formatValue) : setNumber_2(formatValue)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (threshold_1 !== 1 && !number_1 && number_1 !== 0) {
      message.error('请输入阀值1')
      return false
    }
    if (threshold_2 !== 1 && !number_2 && number_2 !== 0) {
      message.error('请输入阀值2')
      return false
    }
    return true
  }
  return (
    <div className={style['custom_2']}>
      <div className="fa-item" style={{ marginBottom: 16 }}>
        <div className="name">阀1</div>
        <Select value={threshold_1} onChange={e => handleChangeOne(e)}>
          {actionOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
        <Input
          disabled={!threshold_1 || threshold_1 === 1}
          value={number_1}
          onChange={e => changeNumber_1(e.target.value)}
          onBlur={e => formatNumber(e.target.value, 1)}
        />
        <div className="unit">cm</div>
      </div>
      <div className="fa-item">
        <div className="name">阀2</div>
        <Select value={threshold_2} onChange={e => handleChangeTwo(e)}>
          {actionOptions.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
        <Input
          disabled={!threshold_2 || threshold_2 === 1}
          value={number_2}
          onChange={e => changeNumber_2(e.target.value)}
          onBlur={e => formatNumber(e.target.value, 2)}
        />
        <div className="unit">cm</div>
      </div>
    </div>
  )
}

export default Com
