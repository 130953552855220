import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import * as echarts from 'echarts~5';
import DomResize from 'components/DomResize';
import { useData } from './hooks';
import { Spin } from 'antd';

const margin_width = 24;

const renderChart = (refNode, total_cost, parsed_results) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        // console.log(total_cost, parsed_results);
        if (typeof total_cost !== 'number') {
            instance.clear();
            return;
        };

        const data = []
            .concat(parsed_results)
            .map(({ cost: value, color }) => ({
                value,
                itemStyle: {
                    color,
                },
            }));

        instance.setOption({
            series: [
                {
                    type: 'pie',
                    radius: ['48%', '70%'],
                    clockwise: false,
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false
                    },
                    data,
                }
            ]
        });
    };
};

const Comp = (props) => {
    const { selectedProduct } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        // 需要把空的selectedProduct id传进去，支持未来空的product选择
        setDataFunc.current(Object.assign({}, selectedProduct ?? {}, selectedProduct?.timeInfo ?? {}));
    }, [selectedProduct]);

    const refNode = useRef(null);
    useEffect(() => {
        const total_cost = data?.total_cost;
        const parsed_results = data?.parsed_results;
        if (!!refNode.current) {
            renderChart(refNode.current, total_cost, parsed_results);
        };
    }, [data]);

    const [width, setWidth] = useState(null);
    const onResize = (conf) => {
        setWidth(conf.width);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="成本构成"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <div className="content">
                        <div className="left">
                            <div className="chart" ref={refNode}></div>
                            <div className="inner_wrapper">
                                <div className="block">
                                    <div className="content">
                                        <div className="title">总成本</div>
                                        <div className="value_wrapper">
                                            <div className="unit">￥</div>
                                            <div className="value">{data?.total_cost_text ?? ""}</div>
                                        </div>
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div style={{ height: 0 }}>
                                <DomResize onResize={onResize} mountedCall={true} />
                            </div>
                            {
                                typeof width === 'number' && (
                                    <div className="content">
                                        <div className="title_wrapper" style={{ paddingRight: margin_width - 2 }}>
                                            <div className="item">
                                                <div className="title">日期：</div>
                                                <div className="value">{data?.parsed_basic_info?.time ?? ""}</div>
                                            </div>
                                            <div className="item">
                                                <div className="title">产品名称：</div>
                                                <div className="value">普通生料</div>
                                            </div>
                                        </div>
                                        <div className="scroll_wrapper scroll_bar_restyle">
                                            <div className="content" style={{ width: width - margin_width }}>
                                                {
                                                    (data?.grouped_parsed_results ?? [])
                                                        .map((group, group_index) => {
                                                            return (
                                                                <div className="part" key={group_index}>
                                                                    {
                                                                        group
                                                                            .map((item, index) => {
                                                                                const { cost_name, cost_text, rate_text, color } = item;
                                                                                return (
                                                                                    <div className="item" key={index}>
                                                                                        <div className="dot" style={{ backgroundColor: color }}></div>
                                                                                        <div className="title">{cost_name}</div>
                                                                                        <div className="rate">{rate_text}%</div>
                                                                                        <div className="cost">￥{cost_text}</div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
