import React from 'react';
import { post, get } from 'common/utils';
import { message, Menu, Button, Modal, Radio, Slider, DatePicker, Checkbox, InputNumber, Drawer, Input, Transfer, Table, Select } from 'antd';
import ToolTipLeft from './res/toolTipLeft.svg';
import ToolTipRight from './res/toolTipRight.svg';
import RangePickerModal from './Header/RangePickerModal.js';
import TransferModal from './Header/TransferModal.js';
import EventsModal from './Header/EventsModal.js';
import style from './OptimizationConfig.module.scss';
import smallLineImg from '../../../assets/small-line.png'
import {
    CloseOutlined
} from '@ant-design/icons';

export default class OptimizationConfig extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeRangesVisible: false,
            transferModalVisible: false,
            issueModalVisible: false,
            config: {},
            bmk_period_list: [],
            boundry_cond: [],
            events: [],
            trigger_type: null,//manual, auto
            opt_method: null,
            goal_pct_left_text: '',
            goal_pct_right_text: '',
            selectedPeriodIndex: null,
            sys_id: props.sys_id,
        };
    }

    componentDidMount() {
        console.log(this.state.sys_id);
        this.getDrawerData();
    }

    getDrawerData = () => {
        const { sys_id } = this.state;
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/optimization/config/get_one', {
            sys_id
        }).then(retData => {
            console.log(retData);
            if (retData.data && retData.data.errcode === 0 && retData.data.result) {
                const { bmk_period_list, boundry_cond, events, opt_method = 'operation_data', goal_pct_left_text, goal_pct_right_text } = retData.data.result;
                const boundry_condWithKey = boundry_cond.map(item => {
                    item.key = item.tag_name;
                    return item;
                });
                this.setState({
                    config: retData.data.result,
                    bmk_period_list,
                    boundry_cond: boundry_condWithKey,
                    events,
                    opt_method,
                    goal_pct_left_text,
                    goal_pct_right_text,
                });
            };
        });
    }

    sliderValueChange = (v) => {
        const { config } = this.state;
        config.coal_pct = 10 - v;
        this.setState({ config });
    }

    eventDelete = (index) => {
        const { events } = this.state;
        events.splice(index, 1);
        this.setState({ events });
    }

    timeRangeModalOnOk = (timeRange, selectedPeriodIndex) => {
        const { bmk_period_list } = this.state;
        if (!timeRange) return;
        if (selectedPeriodIndex !== null) {
            bmk_period_list[selectedPeriodIndex] = timeRange.map(m => m.format('YYYY-MM-DD HH:mm:ss'));
        } else {
            bmk_period_list.push(timeRange.map(m => m.format('YYYY-MM-DD HH:mm:ss')))
        }
        this.setState({ timeRangesVisible: false, bmk_period_list, selectedPeriodIndex: null });
    }
    editPeriodOnClick = selectedPeriodIndex => {
        this.setState({ selectedPeriodIndex, timeRangesVisible: true });
    }
    deletePeriodOnClick = index => {
        const { bmk_period_list } = this.state;
        bmk_period_list.splice(index, 1);
        this.setState({ bmk_period_list });
    }
    eventModalOnOk = eventsSelected => {
        this.setState({
            issueModalVisible: false,
            events: eventsSelected,
        })
    }
    transferModalOnOk = targetKeys => {
        const { boundry_cond } = this.state;
        boundry_cond.forEach(item => {
            if (targetKeys.indexOf(item.key) > -1) {
                if ([1, 2].indexOf(item.status) === -1) item.status = 1;
            } else {
                item.status = 0;
            }
        })
        this.setState({
            transferModalVisible: false,
            boundry_cond
        })
    }

    boundryCheckedOnChange = (checked, tag_name) => {
        const { boundry_cond } = this.state;
        boundry_cond.filter(({ tag_name: t_n }) => t_n == tag_name)[0].status = checked ? 2 : 1;
        this.setState({ boundry_cond });
    }

    drawerOnSave = () => {
        const { onClose = () => { } } = this.props;
        const { config, bmk_period_list, boundry_cond, events, opt_method, coal_calorie, coal_calorie_fix } = this.state;
        config.bmk_period_list = bmk_period_list;
        config.boundry_cond = boundry_cond;
        config.events = events.map(({ id, name, time_begin, time_end }) => ({ id, name, time_begin, time_end }));
        config.opt_method = opt_method;
        console.log(config);
        post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/optimization/config/update', config).then(retData => {
            console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                onClose();
            } else {
                message.error('保存失败');
            }
        });
    }

    eventRadioOnChange = e => {
        const { config } = this.state;
        config.relate_events = e.target.value;
        this.setState({ config });
    }

    boundryRangeOnChange = (v, record) => {
        record.value_range = v;
        this.forceUpdate();//这里key不变也会渲染?
    }

    boundryValueOnChange = (v, record) => {
        record.value = v;
        this.forceUpdate();//这里key不变也会渲染?
    }

    boundryValueTypeOnChange = (e, record) => {
        record.value_type = e.target.value;
        this.forceUpdate();//这里key不变也会渲染?
    }

    bmkPeriodTypeOnChange = e => {
        const { config } = this.state;
        config.bmk_period_type = e.target.value;
        this.setState({ config });
    }

    statPeriodOnChange = e => {
        const { config } = this.state;
        config.stat_period = e.target.value;
        this.setState({ config });
    }

    triggerTypeOnChange = e => {
        const { config } = this.state;
        config.trigger_type = e.target.value;
        this.setState({ config });
        // this.setState({ trigger_type: e.target.value });
    }

    topStateNumOnChange = v => {
        const { config } = this.state;
        config.top_state_num = v;
        this.setState({ config });
    }

    coalCalorieOnChange = e => {
        // console.log(e.target.value);
        const { config } = this.state;
        config.coal_calorie = e.target.value;
        this.setState({ config });
    }

    coalCalorieFixOnChange = e => {
        const { config } = this.state;
        config.coal_calorie_fix = e.target.value;
        this.setState({ config });
    }

    render() {
        let self = this;
        const { onClose = () => { } } = this.props;
        const { timeRangesVisible, transferModalVisible, issueModalVisible, goal_pct_left_text, goal_pct_right_text, sys_id } = this.state;
        const { config, bmk_period_list, boundry_cond, events } = this.state;
        const { relate_events = 0, bmk_period_type = 'all', stat_period, coal_pct, top_state_num, coal_calorie, coal_calorie_fix, trigger_type } = config;
        return (
            <Drawer
                title='系统优化配置'
                onClose={onClose}
                visible={true}
                width={820}
            >
                <div style={{position: "absolute",top: 16,left: 0}}>
                    <img src={smallLineImg}/>
                </div>
                <div className={style["optimization_custom-content-wrapper"]}>
                    <div className="wrapper">
                        <Radio.Group value={trigger_type} onChange={this.triggerTypeOnChange}>
                            <span className="title">寻优方式：</span>
                            <Radio value={'manual'}>手动</Radio>
                            <Radio value={'auto'}>自动</Radio>
                            {/* <Radio value={'special'}>定时</Radio>
                            <Input style={{ width: 50, marginLeft: 10 }} disabled={trigger_type != 'special'} />
                            <span> 小时</span> */}
                        </Radio.Group>
                    </div>

                    <div className="slider-wrapper wrapper">
                        <div className="title">优化目标：</div>
                        <div style={{ marginRight: '2px' }}>{goal_pct_left_text}</div>
                        <div className="custom-tooltip">
                            {/* <img src={ToolTipLeft} alt="" width="56" height="32" /> */}
                            <div className="content" style={{ left: '7px' }}>{coal_pct * 10}%</div>
                        </div>
                        <div className="slider-bar">
                            <Slider value={10 - coal_pct} max={10} onChange={v => this.sliderValueChange(v)} />
                        </div>
                        <div className="custom-tooltip">
                            {/* <img src={ToolTipRight} alt="" width="56" height="32" /> */}
                            <div className="content" style={{ right: '7px' }}>{100 - coal_pct * 10}%</div>
                        </div>
                        <div style={{ marginLeft: '2px' }}>{goal_pct_right_text}</div>
                    </div>
                    <div className="wrapper">
                        <Radio.Group value={stat_period} onChange={this.statPeriodOnChange}>
                            <span className="title">统计时长：</span>
                            {
                                (sys_id == 1 ? [20, 40, 60] : [10, 20, 30]).map(v => (
                                    <Radio value={v} key={v}>{v}分钟</Radio>
                                ))
                            }
                        </Radio.Group>
                    </div>
                    <div className="wrapper">
                        <span className="title">最优工况：</span>
                        <InputNumber value={top_state_num || 0} onChange={this.topStateNumOnChange} />
                        <span className="hint">&nbsp;%&nbsp;&nbsp;搜索历史最优样本的百分比 </span>
                    </div>
                    {
                        (coal_calorie !== undefined || coal_calorie_fix !== undefined)
                        && [
                            <div key="item" className="wrapper">
                                {
                                    coal_calorie !== undefined && [
                                        <span className="title" key="title">燃煤热值：</span>
                                        ,
                                        <Input key="input" style={{ width: 100, marginRight: 10 }} value={coal_calorie} onChange={this.coalCalorieOnChange} />
                                        ,
                                        <span key="unit">kJ/kg</span>
                                    ]
                                }
                                {
                                    coal_calorie_fix !== undefined && [
                                        <span className="title" key="title" style={{ marginLeft: 24 }}>修正系数：</span>
                                        ,
                                        <Input key="input" style={{ width: 100 }} value={coal_calorie_fix} onChange={this.coalCalorieFixOnChange} />
                                    ]
                                }
                            </div>
                        ]
                    }
                    <div  className="wrapper" style={{borderBottom:'none'}}>
                        <div style={{ display: 'flex',marginBottom:'8px' }}>
                            <Radio.Group value={bmk_period_type} onChange={this.bmkPeriodTypeOnChange}>
                                <span className="title">对标时段：</span>
                                <Radio value={'all'}>全部</Radio>
                                <Radio value={'cus'}>指定</Radio>
                            </Radio.Group>
                            <Button type="primary" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ timeRangesVisible: true, selectedPeriodIndex: null })}>添加</Button>
                        </div>
                        <div>
                            {
                                bmk_period_list.map(([start_time, end_time], index) => (
                                    <div key={index} className="period_row">
                                        <div>{start_time} - {end_time}</div>
                                        <Button type="link" onClick={() => this.editPeriodOnClick(index)}>编辑</Button>
                                        <Button type="link" onClick={() => this.deletePeriodOnClick(index)}>删除</Button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex' }} >
                        <div className="title">边界条件 </div>
                        <Button type="primary" style={{ marginLeft: 'auto' }} onClick={() => this.setState({ transferModalVisible: true })}>添加</Button>
                    </div>
                    <div style={{ height: 15 }}></div>
                    <Table
                        columns={[
                            {
                                key: 'tag_name',
                                title: '点位名称',
                                render: (text, { tag_name, status }, index) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox checked={status == 2} onChange={e => this.boundryCheckedOnChange(e.target.checked, tag_name)} />&nbsp;&nbsp;{tag_name}
                                    </div>
                                )
                            },
                            {
                                dataIndex: 'name',
                                title: '点位描述',
                            },
                            {
                                title: '取值方式',
                                key: 'value',
                                render: (text, record, index) => {
                                    const { value_type, value } = record;
                                    return ([
                                        <Radio.Group value={value_type} onChange={e => this.boundryValueTypeOnChange(e, record)} key='1'>
                                            <Radio value={2}>均值</Radio>
                                            <Radio value={1}>给定</Radio>
                                        </Radio.Group>,
                                        <InputNumber disabled={value_type == 2} value={value || 0} key='2' onChange={v => this.boundryValueOnChange(v, record)} />
                                    ])
                                }
                            },
                            {
                                title: '取值范围',
                                key: 'range',
                                render: (text, record, index) => {
                                    const { value_range } = record;
                                    return ([
                                        <span key='1'>±</span>,
                                        <InputNumber value={value_range || 0} key='2' onChange={v => this.boundryRangeOnChange(v, record)} />
                                    ])
                                }
                            },
                        ]}
                        dataSource={boundry_cond.filter(({ status }) => status == 1 || status == 2)}
                        // size="small"
                        pagination={false}
                    // scroll={{ y: 200 }}
                    />
                    <div style={{ height: 15 }}></div>
                    <div style={{ display: 'flex' }}>
                        <Radio.Group value={relate_events} onChange={this.eventRadioOnChange}>
                            <span className="title">事件关联：</span>
                            <Radio value={0}>不关联</Radio>
                            <Radio value={1}>选择事件</Radio>
                        </Radio.Group>
                        <Button type="primary" disabled={relate_events != 1} style={{ marginLeft: 'auto' }} onClick={() => this.setState({ issueModalVisible: true })}>添加</Button>
                    </div>
                    <div style={{ height: 15 }}></div>
                    {
                        relate_events == 1 && (
                            <div className="issue_wrapper">
                                {
                                    events.map(({ name }, index) => (
                                        <div key={index} className="issue_block">
                                            <span className="content">{name}</span>
                                            <CloseOutlined onClick={() => self.eventDelete(index)} style={{ marginLeft: '8px', fontSize: '10px', color: 'rgba(230, 247, 255, 1)' }} />
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                    {
                        timeRangesVisible && (
                            <RangePickerModal
                                selectedPeriodIndex={this.state.selectedPeriodIndex}
                                periods={bmk_period_list}
                                onCancel={() => this.setState({ timeRangesVisible: false })}
                                onOk={this.timeRangeModalOnOk}
                            />
                        )
                    }
                    <TransferModal
                        visible={transferModalVisible}
                        dataSource={boundry_cond}
                        initTargetKeys={dataSource => dataSource.filter(({ status }) => status == 1 || status == 2).map(({ key }) => key)}
                        onOk={this.transferModalOnOk}
                        onCancel={() => this.setState({ transferModalVisible: false })}
                    />
                    <EventsModal
                        visible={issueModalVisible}
                        initSelectedEvents={events}
                        onOk={this.eventModalOnOk}
                        onCancel={() => this.setState({ issueModalVisible: false })}
                    />
                </div>
                <div className={style["optimization_footer"]}>
                    <Button
                        style={{
                            marginRight: 8,
                        }}
                        onClick={onClose}
                    >
                        取消
                    </Button>
                    <Button className="btn" onClick={this.drawerOnSave} type="primary" disabled={!config._id}>
                    保存
                    </Button>
                </div>
            </Drawer>
        );
    }
}
