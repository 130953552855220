import React, { useState } from 'react';
import style from './index.module.scss';
import ICON_DELETE from './res/delete.svg';
import ICON_AUTO from './res/auto.svg';
import ICON_NO_EVENTS from './res/no-events.svg';
import ICON_EDIT from './res/edit.svg';
import { Typography, Popover, message } from 'antd';
import { useEvents } from './hooks';
import EventsTable from './EventsTable';
import { reqDelete } from 'common/utils';

const { Text } = Typography;
const MAX_EVENTS_DISPLAY_NUM = 4;

const Comp = (props) => {
    const [events, forceRefreshEvents, , , total] = useEvents();
    const [moreEventsVisible, setMoreEventsVisible] = useState(false);
    const [deletedEventsVisible, setDeletedEventsVisible] = useState(false);

    const editEventOnClick = (id, title) => {
        if (typeof props.editEventOnClick === "function") props.editEventOnClick(id, title, true);
    };

    const deleteEventOnClick = (id) => {
        reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/capsuleDeleteByIdSearch', {
            capsuleId: [id],
        })
            .then((retData) => {
                // console.log("delete event", retData);
                if (!!retData.data && retData.data.error === 0) {
                    message.success("已删除");
                    // 更新胶囊窗口和自动生成事件
                    forceRefreshEvents();
                } else {
                    message.error("删除失败");
                };
            });
    };

    const closeMoreEventsTable = () => {
        setMoreEventsVisible(false);
    };

    const closeDeletedEventsTable = () => {
        setDeletedEventsVisible(false);
    };

    return (
        <div className={style['wrapper']}>
            {
                !!events && (
                    <div className="auto_wrapper">
                        <div className="header_wrapper">
                            <img alt="" src={ICON_AUTO} className="icon" />
                            <div className="text">自动生成</div>
                        </div>
                        {
                            (events.length > 0) ? (
                                events
                                    .filter((item, index) => index < MAX_EVENTS_DISPLAY_NUM)
                                    .map((item, index, arr) => {
                                        const { titleText, id, title } = item;
                                        const isEventLastOne = (total <= MAX_EVENTS_DISPLAY_NUM) && (index === arr.length - 1);
                                        return (
                                            index > 0 ? [(
                                                <div className="space" key="space"></div>
                                            )] : []
                                        )
                                            .concat([
                                                (
                                                    <div key={index} className={"event_wrapper" + (isEventLastOne ? " last_one" : "")} key="event">
                                                        <div className="text_wrapper">
                                                            <Text
                                                                ellipsis={{
                                                                    tooltip: titleText,
                                                                }}
                                                                className="text"
                                                            >{titleText}</Text>
                                                        </div>
                                                        <div className="tool_tip_wrapper">
                                                            <img alt="" src={ICON_EDIT} className="icon" onClick={() => editEventOnClick(id, title)} />
                                                            <img alt="" src={ICON_DELETE} className="icon" onClick={() => deleteEventOnClick(id)} />
                                                        </div>
                                                    </div>
                                                )
                                            ]);
                                    })
                                    .concat(total > MAX_EVENTS_DISPLAY_NUM ? [(
                                        <Popover
                                            overlayClassName={style['cus_popover_wrapper']}
                                            trigger="click" placement="bottomRight" key="footer" visible={moreEventsVisible}
                                            onVisibleChange={(visible) => setMoreEventsVisible(visible)}
                                            content={(//实例不会消失，不加visible判断的话
                                                moreEventsVisible ? (
                                                    <EventsTable onCancel={closeMoreEventsTable} editEventOnClick={editEventOnClick} deleteEventOnClick={deleteEventOnClick} />
                                                ) : null
                                            )}
                                        >
                                            <div className="footer_wrapper">
                                                <div className="text">+{total - MAX_EVENTS_DISPLAY_NUM}</div>
                                            </div>
                                        </Popover>
                                    )] : [])
                            ) : (
                                    <div className="no-event_wrapper">
                                        <img alt="" src={ICON_NO_EVENTS} className="icon" />
                                        <div className="text">暂无新的事件生成</div>
                                    </div>
                                )
                        }
                    </div>
                )
            }
            <div style={{ width: 40 }}></div>
            {
                false && (
                    <div style={{ width: 8 }}></div>
                )
            }
            {
                false && (
                    <Popover
                        overlayClassName={style['cus_popover_wrapper']}
                        trigger="click" placement="bottomRight" key="footer" visible={deletedEventsVisible}
                        onVisibleChange={(visible) => setDeletedEventsVisible(visible)}
                        content={(
                            deletedEventsVisible ? (
                                <EventsTable onCancel={closeDeletedEventsTable} editEventOnClick={editEventOnClick} deleteEventOnClick={deleteEventOnClick} />
                            ) : null
                        )}
                    >
                        <div className="delete_wrapper">
                            <img alt="" src={ICON_DELETE} className="icon" />
                        </div>
                    </Popover>
                )
            }
        </div>
    );
};

export default Comp;
