import SampleMaintenance from './SampleMaintenance.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/SampleMaintenance/actions.js';
import { post } from 'common/utils';
import { getDataMetas, getHistoryData, getStatus, getEvents } from './utils.js';
const moment = require('moment');

const getLayoutConfig = (dispatch, getState) => {
    return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/layout_conf/list', {
        "key_list": [
            "sample_maintence"
        ]
    }).then(retData => {
        // console.log(retData);
        if (retData.data && retData.data.errcode === 0) {
            const { results, cus_config } = retData.data;
            dispatch(setValues({
                layoutResults: results,
                layoutCusConfig: cus_config,
                sys_id: (results[0] || {}).sys_id,
            }));
            return true;
        };
        return false;
    });
}

const mapStateToProps = state => {
    const { sampleMaintenance = {} } = state;
    const { sys_id, layoutResults, contentItemWidth } = sampleMaintenance;
    return {
        sys_id,
        contentItemWidth,
        systems: layoutResults,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSelect: date => dispatch(setValues({ date })),
        getLayoutConfig: () => dispatch(getLayoutConfig),
        selectSystemOnChange: sys_id => {
            dispatch(setValues({ sys_id }));
        },
        updateContentWidth: contentItemWidth => {
            dispatch(setValues({ contentItemWidth }));
        },
        getHistoryData: () => dispatch(getHistoryData()),
        getDataMetas: keyword => dispatch(getDataMetas(keyword)),
        getStatus: () => dispatch(getStatus),
        getEvents: () => dispatch(getEvents),
    };
}

const WrapperedSampleMaintenance = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SampleMaintenance);

export default WrapperedSampleMaintenance;