import React from 'react';
import style from './index.module.scss';
import TopBreadcrumb from 'components/TopBreadCrumb';
import Tabs from './Tabs';

const breadcrumbList = [{ name: '看板' }, { name: '工艺看板' }];

const Comp = (props) => {
    const { sysInfo, setSysInfo } = props;
    return (
        <div className={style['wrapper']}>
            <div className="bread_wrapper">
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
            </div>
            <div className="tabs_wrapper">
                <Tabs sysInfo={sysInfo} setSysInfo={setSysInfo} />
            </div>
        </div>
    );
};

export default Comp;
