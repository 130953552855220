import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { useAutoEvents, useCommonlyTemplateSearch, useLatelyTemplateSearch } from '../../../hooks'
import AutoEventsTable from './AutoEventsTable'
import style from './index.module.scss'
const { TabPane } = Tabs
const REFRESH_PERIOD = 60 * 1000 //1 minute
const tabList = [
  { key: '1', title: '自动生成' },
  { key: '2', title: '最常用' },
  { key: '3', title: '最近使用' },
  { key: '4', title: '分类选择' }
]

/** 指定模版新建/编辑选择器 */
const Com = props => {
  const { refreshNum, showAddModal, addCapsuleOnClick, editEventOnClick } = props
  const [tabIndex, setTabIndex] = useState('1')
  const [events, , total, , refreshEvents] = useAutoEvents(6) // 自动生成事件列表

  const [frequentlyUsedList, refreshFrequentlyUsedList] = useCommonlyTemplateSearch([]) // 最常用的
  const [recentlyUsedList, refreshRecentlyUsedList] = useLatelyTemplateSearch([]) // 最近使用的
  const [showMoreEventsPanel, setShowMoreEventsPanel] = useState(false) // 标记是否显示

  useEffect(() => {
    const autoEventsInterval = setInterval(!showMoreEventsPanel && refreshEvents, REFRESH_PERIOD)
    return () => {
      clearInterval(autoEventsInterval)
    }
  }, [])

  /** 编辑胶囊后刷新自动生成列表 */
  useEffect(() => {
    refreshNum > 0 && refreshEvents()
  }, [refreshNum])

  // 切换tab
  const changeTab = index => {
    if (index === '4') {
      // TODO: 新建胶囊 并默认激活分类选择页签
      showAddModal(2)
    } else {
      setTabIndex(index)
    }
    setShowMoreEventsPanel(false)
  }

  return (
    <Tabs className={style['wrapper']} activeKey={tabIndex} onChange={changeTab}>
      {tabList.map((tabItem, tabKey) => {
        if (tabItem.key === '1') {
          // 自动生成事件
          return (
            <TabPane tab={tabItem.title} key={tabItem.key}>
              {total > 0 && (
                <ul className="container">
                  {events.map(eventItem => {
                    return (
                      <li
                        className="item"
                        key={eventItem.id}
                        onClick={() => {
                          setShowMoreEventsPanel(false)
                          editEventOnClick(eventItem.id, true)
                        }}
                      >
                        <span> {eventItem.titleText}</span>
                      </li>
                    )
                  })}
                </ul>
              )}
              {total === 0 && <span className="empty-text">暂无新的事件生成</span>}
              {total > events.length && (
                <AutoEventsTable
                  moreCount={total - events.length}
                  visible={showMoreEventsPanel}
                  setVisible={setShowMoreEventsPanel}
                  editEventOnClick={editEventOnClick}
                  refreshEvents={refreshEvents}
                />
              )}
            </TabPane>
          )
        } else {
          // 最常用/最近使用
          const dataList =
            tabItem.key === '2' ? frequentlyUsedList : tabItem.key === '3' ? recentlyUsedList : []
          if (tabItem.key !== '4' && dataList.length === 0) return ''
          return (
            <TabPane tab={tabItem.title} key={tabItem.key}>
              <ul className="container">
                {dataList.map((item, index) => {
                  if (index >= 6) return <></>
                  return (
                    <li
                      className="item"
                      key={item.id}
                      onClick={() => {
                        addCapsuleOnClick(item.id, item.title, item.sn, tabKey - 1)
                      }}
                    >
                      <span>{item.title}</span>
                    </li>
                  )
                })}
              </ul>
            </TabPane>
          )
        }
      })}
    </Tabs>
  )
}

export default Com
