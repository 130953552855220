import React from 'react'
import Empty_ICON from '../../../../../assets/images/v3/empty.png'

const Com = props => {
  const { tipMsg } = props

  const emptyIcon = {
    height: 116,
    background: `url(${Empty_ICON}) no-repeat center`
  }
  const emptyText = {
    fontSize: 16,
    color: '#B7B7B7',
    textAlign: 'center',
    lineHeight: '20px',
    marginTop: 8
  }
  return (
    <div>
      <div style={emptyIcon} />
      <div style={emptyText}>{tipMsg || '暂无数据'}</div>
    </div>
  )
}

export default Com
