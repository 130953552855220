import React from 'react';
import style from './ExamInfoBar.module.scss';
import TimeSelector from './TimeSelector';

export default class ExamInfoBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timeInfo: null,
        }
    }

    componentDidMount() {
    };

    setTimeInfo = (timeInfo) => {
        this.setState({ timeInfo }, this.propOnChange);
    };

    propOnChange = () => {
        const { timeInfo } = this.state;
        if (typeof this.props?.timeOnChange === 'function') {
            this.props.timeOnChange(timeInfo);
        };
    };

    render() {
        const { timeInfo } = this.state;

        return (
            <div className={style["wrapper"]}>
                <div style={{ marginLeft: 'auto' }}></div>
                <TimeSelector
                    setTimeInfo={this.setTimeInfo} timeInfo={timeInfo}
                // defaultTime="2021-06-01"
                />
            </div>
        );
    }
}
