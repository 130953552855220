import React from 'react';
import { notification } from 'antd';
import { post } from '../../common/utils';

const moment = require('moment');
const INTERVAL_TIME = 10;
const TIME_PER_SPEAK = 2;
const TIME_PER_ALARM = 3;
const AlarmTypes = {
    1: '超上限告警',
    2: '超下限告警',
    3: '波动告警',
};
const LOCALSTORAGE_KEY = 'SYSTEM_ALARM_SPEAKING_DONE';
const MAX_LOCAL_NUM = 100;
class AlarmEvents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
        };
        this.speakingTaskWorking = false;
        this.speakTimes = 0;//1,2,3
        this.alarms = [];
    }

    componentDidMount() {
        // console.log('componentDidMount');
        this.getAlarmList();
        this.mySetInterval = setInterval(this.getAlarmList, INTERVAL_TIME * 1000);
        // console.log(localStorage.getItem(LOCALSTORAGE_KEY));
    }

    componentWillUnmount() {
        if (this.mySetInterval) {
            clearInterval(this.mySetInterval);
        };
    }

    getAlarmList = () => {
        const { page, pageSize } = this.state;
        post(process.env.REACT_APP_SERVER_PATH + 'api/alarm/alarm_events/list', {
            page,
            pageSize,
        }).then(retData => {
            if (retData.data && retData.data.errcode === 0) {
                // console.log(retData.data.results);
                let speakingDone = localStorage.getItem(LOCALSTORAGE_KEY);
                if (speakingDone === null) {
                    speakingDone = [];
                } else {
                    speakingDone = speakingDone
                        .split(',');
                };
                const currentTime = moment().subtract(30, 'seconds');
                retData.data.results.forEach(item => {
                    const { time_begin, time_end } = item;
                    if (currentTime.isBefore(moment(time_begin)) && !time_end) {
                        const currentIds = this.alarms.map(({ id }) => id + '');
                        if (!currentIds.concat(speakingDone).includes(item.id + '')) {
                            this.alarms.push(item);
                        };
                    };
                });
                this.runSpeakingTask();
            };
        });
    }

    runSpeakingTask = () => {
        if (this.speakingTaskWorking) {
            return;
        };
        if (!this.speakingTaskWorking) {
            //false,not work
            this.speakingTaskWorking = true;
            this.speakingTask();
            return;
        };
    }

    speakingTask = () => {
        if (this.alarms.length === 0) {
            this.speakTimes = 0;
            this.speakingTaskWorking = false;
            return;
        };
        if (this.speakTimes >= 3) {
            this.speakTimes = 0;
            this.alarms.shift();
            this.speakingTask();
            return;
        };
        this.speakTimes++;
        const { type, alarm_meta: { data_meta: { name } = {} }, id } = this.alarms[0];
        const speakingSentence = (name || '') + ' ' + (AlarmTypes[type] || '');
        if (this.speakTimes === 1) {
            // console.log(localStorage.getItem(LOCALSTORAGE_KEY));
            let speakingDone = localStorage.getItem(LOCALSTORAGE_KEY);
            if (speakingDone === null) {
                speakingDone = [];
            } else {
                speakingDone = speakingDone
                    .split(',');
            };
            if (!speakingDone.includes(id + '')) {
                speakingDone.push(id + '');
            };
            while (speakingDone.length > MAX_LOCAL_NUM) {
                speakingDone.shift();
            };
            const saveStr = speakingDone
                .join(',');
            localStorage.setItem(LOCALSTORAGE_KEY, saveStr);
            this.openNotification(speakingSentence);
        };
        // console.log(speakingSentence, this.alarms.length);
        const utterThis = new window.SpeechSynthesisUtterance(speakingSentence);
        utterThis.pitch = 2;
        utterThis.onend = () => {
            if (!!this.speakingTask) {
                //防止页面跳转后报错?
                setTimeout(this.speakingTask, (this.speakTimes >= 3 ? TIME_PER_ALARM : TIME_PER_SPEAK) * 1000);
            };
        };
        window.speechSynthesis.speak(utterThis);
    }

    openNotification = (speakingSentence) => {
        notification.open({
            message: '语音报警',
            description: speakingSentence,
            onClick: () => {
                // console.log('Notification Clicked!');
            },
        });
    }

    render() {

        return (
            null
        );
    }
}
export default AlarmEvents;
