import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import DomResize from 'components/DomResize';
import { useData } from './hooks';
import { Spin } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons'
import SortIcons from 'components/SortIcons';

const defaultFunc = () => { };

const Comp = (props) => {
    const { selectedAbnormal, timeInfo, id } = props;

    const onChange = useRef(defaultFunc);
    const setDetail = useRef(defaultFunc);
    useEffect(() => {
        let nextOnChange = defaultFunc;
        if (!!props && typeof props.onChange === 'function') {
            nextOnChange = props.onChange;
        };
        onChange.current = nextOnChange;
        let nextSetDetail = defaultFunc;
        if (!!props && typeof props.setDetail === 'function') {
            nextSetDetail = props.setDetail;
        };
        setDetail.current = nextSetDetail;
    }, [props]);

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current(Object.assign({}, timeInfo ?? {}, {
            base_id: id,
        }));
    }, [timeInfo, id]);

    useEffect(() => {
        onChange.current(null);
        setDetail.current(data);
    }, [data]);

    const [margin, setMargin] = useState(null);
    const [width, setWidth] = useState(null);
    const shouldRender = useMemo(() => {
        if (typeof margin === 'number' && typeof width === 'number') return true;
        return false;
    }, [margin, width]);

    const outerWidth = useRef(null);
    const innerWidth = useRef(null);

    const onResize = (conf, isInner) => {
        const { width } = conf;
        if (isInner === true) {
            innerWidth.current = width;
        } else {
            outerWidth.current = width;
        };
        // calc width and margin
        if (typeof outerWidth.current === 'number' && typeof innerWidth.current === 'number') {
            // width固定outerWidth - 2*18
            // margin根据innerWidth和outerWidth判断
            // 会有overflowx hidden
            const defaultDelta = 18;
            let nextMargin = defaultDelta;
            const nextWidth = outerWidth.current - defaultDelta * 2;
            if (nextWidth + nextMargin > innerWidth.current) {
                // scroll 宽大于defaultDelta
                nextMargin = innerWidth.current - nextWidth;
                if (nextMargin < 0) nextMargin = 0;
            };
            setMargin(nextMargin);
            setWidth(nextWidth);
        };
    };

    const itemOnClick = (item) => {
        onChange.current(item);
    };

    const clearSelect = (e) => {
        e.stopPropagation();
        onChange.current(null);
    };

    const [sortInfo, setSortInfo] = useState(null);
    const updateSortInfo = (key) => {
        setSortInfo((prevSortInfo) => {
            let nextIsDesc = false;
            if (prevSortInfo?.key === key) {
                nextIsDesc = !prevSortInfo.is_desc;
            };
            return {
                key,
                is_desc: nextIsDesc,
            };
        });
    };

    const sorted_datas = useMemo(() => {
        const origin_datas = data?.abnormal_history;
        if (!!origin_datas) {
            let ret_datas = [].concat(origin_datas);
            if (!!sortInfo) {
                const { key, is_desc } = sortInfo;
                ret_datas = ret_datas
                    .sort(({ [key]: v1 }, { [key]: v2 }) => {
                        const v_1 = is_desc ? v1 : v2;
                        const v_2 = is_desc ? v2 : v1;
                        if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                            return v_2 - v_1;
                        };
                        if (typeof v1 === 'number') return -1;
                        if (typeof v2 === 'number') return 1;
                        return 0;
                        // 1是交换
                    });
            };
            return ret_datas;
        };
        return null;
    }, [data, sortInfo]);


    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="指标详情"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <div className="top">
                        <div className="content">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="title">指标名称：</td>
                                        <td className="value" colSpan="3">{data?.detail?.name ?? ""}<span className="btn">修改</span></td>
                                    </tr>
                                    <tr>
                                        <td className="title">指标类型：</td>
                                        <td className="value">{data?.detail?.category_name ?? ""}</td>
                                        <td className="title">指标单位：</td>
                                        <td className="value">{data?.detail?.unit ?? ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">所属系统：</td>
                                        <td className="value">{data?.detail?.sys_name ?? ""}</td>
                                        <td className="title">产品类型：</td>
                                        <td className="value">{data?.detail?.product_name ?? ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="title">分析时段：</td>
                                        <td className="value" colSpan="3">{data?.detail?.period_text ?? ""}</td>
                                        {/* ??? 可能需要从url读取 */}
                                    </tr>
                                    <tr>
                                        <td className="title">指标均值：</td>
                                        <td className="value">{data?.detail?.avg_value_text ?? ""}</td>
                                        <td className="title">指标标准：</td>
                                        <td className="value">{data?.detail?.standard_text ?? ""}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="resize_wrapper">
                            <DomResize mountedCall={true} onResize={(conf) => onResize(conf, false)} />
                        </div>
                        {
                            shouldRender && (
                                <div className="header" style={{ width, marginLeft: margin }}>
                                    <div className={style["item"]}>
                                        <div className="td td_1 sort">
                                            <div className="wrapper" onClick={() => updateSortInfo("time_begin_unix")}>
                                                <div className="content">
                                                    <div className="text">异常时段</div>
                                                    <SortIcons is_desc={sortInfo?.key === "time_begin_unix" ? sortInfo?.is_desc : null} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="td td_2 sort">
                                            <div className="wrapper" onClick={() => updateSortInfo("minutes")}>
                                                <div className="content">
                                                    <div className="text">持续时长</div>
                                                    <SortIcons is_desc={sortInfo?.key === "minutes" ? sortInfo?.is_desc : null} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="td td_3 sort">
                                            <div className="wrapper" onClick={() => updateSortInfo("avg_value")}>
                                                <div className="content">
                                                    <div className="text">指标均值</div>
                                                    <SortIcons is_desc={sortInfo?.key === "avg_value" ? sortInfo?.is_desc : null} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="content scroll_bar_restyle">
                            <div className="resize_wrapper">
                                <DomResize mountedCall={true} onResize={(conf) => onResize(conf, true)} />
                            </div>
                            {
                                shouldRender && (
                                    <div className="items_wrapper" style={{ width, marginLeft: margin }}>
                                        {
                                            (sorted_datas ?? [])
                                                .map((item, index) => {
                                                    const { avg_value_text, minutes_text, time_range_text } = item;
                                                    return (
                                                        <div
                                                            className={style["item"] + " item" + (selectedAbnormal === item ? " selected" : "")}
                                                            key={index}
                                                            onClick={() => itemOnClick(item)}
                                                        >
                                                            <div className="td td_1">{time_range_text}</div>
                                                            <div className="td td_2 tbody">
                                                                <div className="value">{minutes_text}</div>
                                                                <div className="unit">小时</div>
                                                            </div>
                                                            <div className="td td_3">{avg_value_text}</div>
                                                            {
                                                                selectedAbnormal === item ? (
                                                                    <div className="close_icon">
                                                                        <CloseCircleOutlined onClick={clearSelect} />
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
