import React from 'react';
import style from './index.module.scss';
import { Modal } from 'antd';

const Comp = (props) => {
    const { title, children, ...rest } = props;

    return (
        <Modal
            title={(
                <div className={style["title_wrapper"]}>
                    <div className="text">{title ?? ""}</div>
                </div>
            )}
            {
            ...rest
            }
            wrapClassName={style["modal"]}
        >
            <div className={style["wrapper"]}>
                {children}
            </div>
        </Modal >
    );
};

export default Comp;
