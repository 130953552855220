import Calendar from './Calendar.js';
import { connect } from 'react-redux';
import {
    setValues,
} from 'common/redux/SampleMaintenance/actions.js';
import { getHistoryData, getEvents, getStatus } from './utils.js';

const mapStateToProps = state => {
    const { sampleMaintenance = {} } = state;
    const { date } = sampleMaintenance;
    return {
        date,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSelect: date => dispatch(setValues({ date })),
        getHistoryData: () => dispatch(getHistoryData()),
        getStatus: () => dispatch(getStatus),
        getEvents: () => dispatch(getEvents),
    };
}

const WrapperedCalendar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Calendar);

export default WrapperedCalendar;