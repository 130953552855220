import React, { useImperativeHandle } from 'react'
import style from './index.module.scss'

/** UI模版 templateId=4 */
const Com = props => {
  useImperativeHandle(props.refInstance, () => ({
    checkValidate,
    getCustomData: () => {
      return {}
    }
  }))

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    return true
  }

  return <></>
}

export default Com
