import React from 'react';
import { Button, Select, Table, message } from 'antd';
import CLOSE_ICON from './res/close.svg';
import NAME_ICON from './res/name.svg';
import DESCRIPTION_ICON from './res/description.svg';
import ACTION_ICON from './res/action.svg';
import style from './LeftTable.module.scss';

const { Option } = Select;
export default class LeftTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataMeta: null,
        };
    }

    componentDidMount() {
    }

    listOnSelect = dataMetaId => {
        const { dataMetaList } = this.props;
        const dataMeta = dataMetaList
            .filter(({ id }) => id === dataMetaId)[0] || null;
        this.setState({ dataMeta });
    }

    addNewMeta = () => {
        // console.log('addNewMeta');
        const { dataMeta } = this.state;
        if (!dataMeta) {
            message.error('尚未选择数据点!');
            return;
        };
        const { cusMetas, layoutConfigSave } = this.props;
        // console.log(cusMetas, dataMeta);
        if (
            cusMetas
                .filter(({ id: metaId }) => metaId === dataMeta.id)
                .length > 0
        ) {
            message.error('已有该数据点，请勿重复添加!');
            return;
        };
        layoutConfigSave(
            cusMetas
                .concat([{
                    id: dataMeta.id,
                    name: dataMeta.name,
                    tag_name: dataMeta.tag_name,
                }])
        )
            .then(isSuccess => {
                if (isSuccess) {
                    message.success('添加成功!');
                    const { getHistoryData } = this.props;
                    getHistoryData([dataMeta.tag_name]);
                    this.setState({ dataMeta: null });
                } else {
                    message.error('添加失败!');
                };
            });
    }

    deleteMeta = index => {
        const { cusMetas, layoutConfigSave } = this.props;
        // console.log(index, cusMetas[index]);
        layoutConfigSave(
            cusMetas
                .filter((_, metaIndex) => metaIndex !== index)
        )
            .then(isSuccess => {
                if (isSuccess) {
                    message.success('删除成功!');
                } else {
                    message.error('删除失败!');
                };
            });
    }

    render() {
        const { dataMetaList, cusMetas } = this.props;
        const { getDataMetas } = this.props;
        const { dataMeta } = this.state;
        // console.log(dataSource);
        return (
            <div className={style["sample_maintenance_left_table_wrapper"]}>
                <div className="top_row">
                    <Select
                        showSearch
                        style={{ width: 232 }}
                        onSelect={this.listOnSelect}
                        onSearch={getDataMetas}
                        value={dataMeta && dataMeta.id}
                        filterOption={false}
                    >
                        {
                            dataMetaList
                                .map(({ id, name, tag_name }, index) => (
                                    <Option value={id} key={index}>{name + ' ' + tag_name}</Option>
                                ))
                        }
                    </Select>
                    <Button
                        type="primary"
                        style={{
                            width: 86,
                            backgroundColor: '#357CF7',
                            borderColor: '#357CF7',
                        }}
                        onClick={this.addNewMeta}
                    >添加</Button>
                </div>
                <div className="table_size_wrapper">
                    <Table
                        rowKey='id'
                        columns={[
                            {
                                dataIndex: 'tag_name',
                                title: () => (
                                    <div className="td_wrapper">
                                        <div className="flex_wrapper">
                                            <img alt="" src={NAME_ICON} className="icon" />
                                            <div className="name">名称</div>
                                        </div>
                                    </div>
                                ),
                                width: 143,
                                align: 'center',
                            },
                            {
                                dataIndex: 'name',
                                title: () => (
                                    <div className="td_wrapper">
                                        <div className="flex_wrapper">
                                            <img alt="" src={DESCRIPTION_ICON} className="icon" />
                                            <div className="name">描述</div>
                                        </div>
                                    </div>
                                ),
                                align: 'center',
                            },
                            {
                                key: 'action',
                                title: () => (
                                    <div className="td_wrapper">
                                        <div className="flex_wrapper">
                                            <img alt="" src={ACTION_ICON} className="icon" />
                                            <div className="name">操作</div>
                                        </div>
                                    </div>
                                ),
                                width: 70,
                                align: 'center',
                                render: (text, record, index) => (
                                    <div
                                        className="action_td_wrapper"
                                        onClick={() => this.deleteMeta(index)}
                                    >
                                        <div className="action_wrapper">
                                            <img alt="" src={CLOSE_ICON} className="close_img" />
                                        </div>
                                    </div>
                                )
                            },
                        ]}
                        dataSource={cusMetas}
                        size="small"
                        pagination={false}
                        scroll={{ y: true }}
                    />
                </div>
            </div >
        );
    }
}
