import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts~5';

const Comp = (props) => {
    const { data } = props;
    const chartNode = useRef(null);
    useEffect(() => {
        let usingData = 0;
        if (typeof data === 'number') {
            usingData = data;
        };

        if (!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            const rangeMin = Math.floor(usingData * 0.5);
            const rangeMax = Math.ceil(usingData * 1.5);
            const option = {

                series: [{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: rangeMin,
                    max: rangeMax,
                    splitNumber: 8,
                    radius: '190%',
                    center: ['50%', '96%'],
                    axisLine: {
                        lineStyle: {
                            width: 2,
                            color: [[1, '#355B99']]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: 18,
                        width: 9,
                        offsetCenter: [0, -50],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 4,
                        lineStyle: {
                            color: 'auto',
                            width: 1
                        },
                        distance: 4,
                    },
                    axisLabel: {
                        color: '#727272',
                        fontSize: 10,
                        distance: 8,
                        formatter: (value) => {
                            if (typeof data !== 'number') {
                                return "";
                            };
                            return value;
                        },
                    },
                    splitLine: {
                        length: 8,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        },
                        distance: 4,
                    },
                    data: [{
                        value: usingData,
                        // name: '成绩评定',
                        detail: {
                            fontSize: 28,
                            offsetCenter: [0, -5],
                            color: '#355B99',
                            formatter: (v) => {
                                return typeof data === 'number' ? Math.floor(v * 100) / 100 : "--";
                            },
                        },
                    }]
                }]
            };
            instance.setOption(option);
        };
    }, [data]);
    return (
        <div style={{ height: 108 }} ref={chartNode}>
        </div>
    );
};

export default Comp;
