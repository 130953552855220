import React, { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';
import { Table } from 'antd';
import DomResize from 'components/DomResize';
import { get, significantNum } from 'common/utils';

const getColumns = () => {
    return [
        {
            title: "炉次",
            dataIndex: "fncId",
            width: 90,
            render: (t, r) => {
                const { fncId, fncCount } = r;
                return `${fncId}#-${fncCount}`;
            },
        },
        {
            title: "出炉时间",
            dataIndex: "endTime",
            width: 170,
            align: "center",
        },
        {
            title: "合金Mn(%)",
            dataIndex: "mnxPrd",
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "合金Si(%)",
            dataIndex: "sixPrd",
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "渣Mn(%)",
            dataIndex: "mnxSlg",
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "产量(t)",
            dataIndex: "prdWt",
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "渣铁比(%)",
            dataIndex: "slgPrd",
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "耗电量(kWh)",
            dataIndex: "prdWtMHours",
            width: 120,
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "吨铁电单耗(kWh/t)",
            dataIndex: "elcons",
            width: 160,
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
        {
            title: "小时铁产量(t/h)",
            dataIndex: "prdWtDHours",
            width: 140,
            render: (t) => {
                const parsed_t = (typeof t === 'number' && !isNaN(t)) ? significantNum(t) : "--";
                return parsed_t;
            },
            align: "center",
        },
    ];
};

const Comp = (props) => {
    const { sysInfo } = props;
    const [y, setY] = useState(null);
    const onResize = useCallback((conf) => {
        setY(conf.height - 180);
    }, []);
    const [page, setPage] = useState(1);
    const [info, setInfo] = useState(null);
    useEffect(() => {
        setPage(1);
    }, [sysInfo]);
    useEffect(() => {
        if (!!sysInfo) {
            let waiting = true;
            const { id } = sysInfo;
            // get("http://192.168.0.95:8073/api/puyuan/queryDischargAlloyList", { fncId: parseInt(id), pageNum: page, pageSize: 20 })
            get(process.env.REACT_APP_SERVER_PATH + "api/puyuan/queryDischargAlloyList", { fncId: parseInt(id), pageNum: page, pageSize: 20 })
                .then(retData => {
                    if (waiting) {
                        // console.log(retData);
                        if (retData?.data?.error === 0) {
                            let n_info = retData?.data?.results ?? null;
                            if (!!n_info) {
                                const parsed_list = (n_info?.list ?? []).map((item, index) => Object.assign({}, item, { key: `${index}` }));
                                n_info = Object.assign({}, n_info, { parsed_list });
                            };
                            setInfo(n_info);
                        };
                    };
                });
            return () => {
                waiting = false;
            };
        };
    }, [sysInfo, page]);
    const pageOnChange = useCallback((page) => {
        setPage(page);
    }, []);
    const innerGetColumns = useCallback(() => {
        return getColumns(sysInfo);
    }, [sysInfo]);
    return (
        <div className={style['wrapper']}>
            <div className="resize">
                <DomResize mountedCall={true} onResize={onResize} />
            </div>
            <div className="content">
                {
                    (typeof y === 'number' && !isNaN(y)) && (
                        <Table
                            dataSource={info?.parsed_list ?? []}
                            columns={innerGetColumns()}
                            scroll={{
                                y,
                            }}
                            pagination={{
                                pageSize: 20,
                                total: info?.total ?? 0,
                                current: page,
                                showSizeChanger: false,
                                onChange: pageOnChange
                            }}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default Comp;
