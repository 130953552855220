// 生产情况tag当日
export const proTagNameList_today = [
    'PLANT_CNG_PRDT_CUM_HD',
    'PLANT_CNG_PRDT_CUM_HDQOQ',
    'PLANT_CNG_PRDT_CUM_HM',
    'PLANT_CNG_PRDT_CUM_DY',
    'PLANT_CNG_PRDT_RATE_HD',

    'PLANT_C2H6_PRDT_CUM_HD',
    'PLANT_C2H6_PRDT_CUM_HDQOQ',
    'PLANT_C2H6_PRDT_CUM_HM',
    'PLANT_C2H6_PRDT_CUM_DY',
    'PLANT_C2H6_PRDT_RATE_HD',

    'PLANT_C3H8_PRDT_CUM_HD',
    'PLANT_C3H8_PRDT_CUM_HDQOQ',
    'PLANT_C3H8_PRDT_CUM_HM',
    'PLANT_C3H8_PRDT_CUM_DY',
    'PLANT_C3H8_PRDT_RATE_HD',

    'PLANT_C4H10_PRDT_CUM_HD',
    'PLANT_C4H10_PRDT_CUM_HDQOQ',
    'PLANT_C4H10_PRDT_CUM_HM',
    'PLANT_C4H10_PRDT_CUM_DY',
    'PLANT_C4H10_PRDT_RATE_HD',

    'PLANT_C5P_PRDT_CUM_HD',
    'PLANT_C5P_PRDT_CUM_HDQOQ',
    'PLANT_C5P_PRDT_CUM_HM',
    'PLANT_C5P_PRDT_CUM_DY',
    'PLANT_C5P_PRDT_RATE_HD',

    'PLANT_RAWG_RAW_CUM_HD',
    'PLANT_RAWG_RAW_CUM_HDQOQ',
    'PLANT_RAWG_RAW_CUM_HM',
    'PLANT_RAWG_RAW_CUM_DY',
    'PLANT_RAWG_RAW_RATE_HD']
export const surviveTagList_today = [
    'PLANT_FUEL_FG_CONSUM_HD',
    'PLANT_FUEL_FG_CONSUM_HDQOQ',
    'PLANT_FUEL_FG_CUM_HD',
    'PLANT_FUEL_FG_CUM_HM',
    'PLANT_FUEL_FG_CUM_DY',
    'AUX_OBLR_FUEL_CUM_HD',
    'AUX_FLARE_FUEL_CUM_HD',
    'PLANT_OTHER_FUEL_CUM_HD',
    'COMP_DGS_SEALG_CUMD_HD',
    'PLANT_ALL_ELEC_CONSUM_HD',
    'PLANT_ALL_ELEC_CONSUM_HDQOQ',
    'PLANT_ALL_ELEC_CUM_HD',
    'PLANT_ALL_ELEC_CUM_HM',
    'PLANT_ALL_ELEC_CUM_DY',
    'COMP_MOTOR_ELEC_CUM_HD',
    'C3CHL_C3CHL_ELEC_CUM_HD',
    'PLANT_PID_ELEC_CUM_HD',
    'PLANT_OTHER_ELEC_CUM_HD',
    'PLANT_COOL_H2O_CONSUM_HD',
    'PLANT_COOL_H2O_CONSUM_HDQOQ',
    'PLANT_COOL_H2O_CUM_HD',
    'PLANT_COOL_H2O_CUM_HM',
    'PLANT_COOL_H2O_CUM_DY',
    // 'PLANT_N2GEN_N2_CUM_HD',
    // 'PLANT_N2GEN_N2_CUM_HDQOQ',
    // 'PLANT_N2GEN_N2_CONSUM_HD',
    // 'PLANT_N2GEN_N2_CONSUM_HM',
    // 'PLANT_N2GEN_N2_CONSUM_DY',
    'PLANT_N2GEN_N2_CONSUM_HD',
    'PLANT_N2GEN_N2_CONSUM_HDQOQ',
    'PLANT_N2GEN_N2_CUM_HD',
    'PLANT_N2GEN_N2_CUM_HM',
    'PLANT_N2GEN_N2_CUM_DY',
    'PLANT_FUEL_FG_CONCOST_HD',
    'PLANT_ALL_ELEC_CONCOST_HD',
    'PLANT_COOL_H2O_CONCOST_HD',
    'PLANT_N2GEN_N2_CONCOST_HD'
]
export const energyTagList_today = [
    'PLANT_RAWG_RAW_RATE_HD',
    'PLANT_RAWG_RAW_RATE_HDQOQ',
    'PLANT_C2H6_PRDT_RCRATE_HD',
    'PLANT_C2H6_PRDT_RCRATE_HDQOQ',
    'PLANT_C3H8_PRDT_RCRATE_HD',
    'PLANT_C3H8_PRDT_RCRATE_HDQOQ',
    'PLANT_C4H10_PRDT_RCRATE_HD',
    'PLANT_C4H10_PRDT_RCRATE_HDQOQ',
    'PLANT_ALL_ELEC_CONSUM_HD',
    'PLANT_ALL_ELEC_CONSUM_HDQOQ'
]
export const abnormalTagList_today = [
    'PLANT_C2H6_PRDT_RCRATE_D',
    'PLANT_C2H6_PRDT_RCFAILT',
    'PLANT_C2H6_PRDT_RCFAILS_DW',
    'PLANT_C2H6_PRDT_RCFAILS_DM',
    'PLANT_C2H6_PRDT_RCFAILS_DY',
    'PLANT_C3H8_PRDT_RCRATE_D',
    'PLANT_C3H8_PRDT_RCFAILT',
    'PLANT_C3H8_PRDT_RCFAILS_DW',
    'PLANT_C3H8_PRDT_RCFAILS_DM',
    'PLANT_C3H8_PRDT_RCFAILS_DY',
    'PLANT_C4H10_PRDT_RCRATE_D',
    'PLANT_C4H10_PRDT_RCFAILT',
    'PLANT_C4H10_PRDT_RCFAILS_DW',
    'PLANT_C4H10_PRDT_RCFAILS_DM',
    'PLANT_C4H10_PRDT_RCFAILS_DY'
]
export const totalEfficiencyTagList_today = [
    'COMP_TOT_EFF_RATE_H',
    'COMP_TOT_EFF_RATE_HDQOQ',
    'COMP_CASE1_EFF_RATE',
    'COMP_CASE2_EFF_RATE',
    'COMP_CASE34_EFF_RATE'
]
export const totalPowerTagList_today = [
    'COMP_TOT_SHAFT_RATE_H',
    'COMP_TOT_SHAFT_RATE_HDQOQ',
    'REG_D301AB_SHAFT_RATE',
    'COMP_GEAR_PLOSS_RATE',
    'COMP_MOTOR_ELEC_RATE'
]
export const powerConsumptionTagList_today = [
    'COMP_MOTOR_ELEC_CONSUM_HD',
    'COMP_MOTOR_ELEC_CONSUM_HDQOQ',
    'COMP_MOTOR_ELEC_CONSUM_HW',
    'COMP_MOTOR_ELEC_CONSUM_HM',
    'COMP_MOTOR_ELEC_CONSUM_DY'
]
export const electricTotalTagList_today = [
    'COMP_MOTOR_ELEC_CUM_HD',
    'COMP_MOTOR_ELEC_CUM_HDQOQ',
    'COMP_MOTOR_ELEC_CUM_HW',
    'COMP_MOTOR_ELEC_CUM_HM',
    'COMP_MOTOR_ELEC_CUM_DY'
]
export const dryGasSealTagList_today = [
    'COMP_DGS_SEALG_RATE_H',
    'COMP_DGS_SEALG_RATE_HDQOQ',
]
export const expanderRateTagList_today = [
    'EXPC_EXP_EFF_RATE_H',
    'EXPC_EXP_EFF_RATE_HDQOQ'
]
export const expanderCompressorRateTagList_today = [
    'EXPC_EC_EFF_RATE_H',
    'EXPC_EC_EFF_RATE_HDQOQ'
]
export const recoveryRateTagList_today = [
    'EXPC_EXP_SHAFT_RATE_H',
    'EXPC_EXP_SHAFT_RATE_HDQOQ'
]

