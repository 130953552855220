import React from 'react'
import { Table, Button, message, Modal, Space, Typography, Input } from 'antd';
import { post } from 'common/utils.js';
import style from './MetaDataList.module.scss';
import DomResize from 'components/DomResize';

const { Text } = Typography;

export default class MetaDataList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pageSize: 20,
            total: 0,
            dataList: [],
            current: 1,
            tableYHeight: 0,
            visible: false,
            editMetaInfo: null,
            keyword: null,
        };
        this.refNode = null;
    }

    componentDidMount() {
        this.getDataList(1);
    }

    getDataList = (page) => {
        let self = this;
        const { keyword } = this.state;
        self.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            page_size: this.state.pageSize,
            page,
            // module_name: "COMMON_METAS",
            // tag_name_list: [
            //     "ABCD",
            //     "EFGH"
            // ],
            keyword: keyword ?? "",
        }).then((retData) => {
            // console.log(retData);
            if (retData?.data?.errcode === 0) {
                const results = retData?.data?.results ?? null;
                self.setState({
                    dataList: results,
                    loading: false,
                    current: page,
                    total: retData?.data?.total,
                });
            } else {
                message.error("加载失败");
            }
        });
    }

    onResize = () => {
        if (!!this.refNode) {
            const height = this.refNode?.offsetHeight ?? 0;
            this.setState({
                tableYHeight: height - 150,
            });
        };
    }

    refFunc = (refNode) => {
        this.refNode = refNode;
    }

    editMeta = (item) => {
        return () => {
            this.setState({
                visible: true,
                editMetaInfo: Object.assign({}, item),
            });
        };
    }

    onOk = () => {
        const { editMetaInfo } = this.state;
        const { name, unit, id } = editMetaInfo;
        if (typeof id === 'number' && !isNaN(id)) {
            post(process.env.REACT_APP_SERVER_PATH + `api/meta_data/update_one/${id}`, {
                name,
                unit,
            })
                .then(retData => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        message.success("修改成功");
                        this.setState({
                            visible: false,
                            editMetaInfo: null,
                        }, () => {
                            const { current } = this.state;
                            this.getDataList(current);
                        });
                    } else {
                        message.error("修改失败");
                    };
                });
        };
    }

    inputOnChange = key => {
        return e => {
            const v = e.target.value;
            this.setState({ [key]: v });
        };
    }

    filterOnClick = () => {
        this.getDataList(1);
    };

    render() {
        let self = this;
        let {
            pageSize, current, total, dataList, loading, tableYHeight, visible, editMetaInfo,
            keyword,
        } = this.state;
        return (
            <div className={style["wrapper"]}>
                <div className="bottom">
                    <div className="resize_wrapper">
                        <DomResize mountedCall={true} onResize={this.onResize} />
                    </div>
                    <div className="top">
                        <div className="title">
                            <span> 数据点位管理</span>
                        </div>
                        <div className="right">
                            <Space>
                                <div style={{ opacity: 0.6 }}>关键字:</div>
                                <Input placeholder="请输入" style={{ width: 300 }} value={keyword ?? ""} onChange={this.inputOnChange("keyword")}></Input>
                                <Button type="primary" onClick={this.filterOnClick}>查询</Button>
                            </Space>
                        </div>
                    </div>
                    <div className="bottom" ref={this.refFunc}>
                        <Table
                            rowKey='id'
                            dataSource={dataList}
                            style={{ marginTop: 10 }}
                            pagination={{
                                defaultCurrent: 1,
                                current: current,
                                pageSize: pageSize,
                                total: total,
                                showSizeChanger: true,
                                showQuickJumper: true,
                            }}
                            size="small"
                            loading={loading}
                            onChange={pagination => {
                                self.setState({ pageSize: pagination.pageSize }, () => self.getDataList(pagination.current));
                            }}
                            columns={[
                                {
                                    title: "数据点位地址",
                                    dataIndex: "tag_name",
                                    // width: 260,
                                }, {
                                    title: "数据点位名称",
                                    dataIndex: "name",
                                    // width: 260,
                                }, {
                                    title: "单位",
                                    dataIndex: "unit",
                                }, {
                                    title: "操作",
                                    key: "operate",
                                    align: "center",
                                    width: 150,
                                    render: (text, record) => (
                                        <span>
                                            <Button size="small" type="link" onClick={this.editMeta(record)}>编辑</Button>
                                        </span>
                                    )
                                }
                            ]}
                            scroll={{
                                y: tableYHeight,
                            }}
                        />
                    </div>
                </div>
                {
                    visible && !!editMetaInfo && (
                        <Modal
                            visible={true}
                            title="点位编辑"
                            onCancel={() => this.setState({ visible: false })}
                            onOk={this.onOk}
                        >
                            <Space direction="vertical">
                                <Space>
                                    <Text>名称：</Text>
                                    <Input value={editMetaInfo?.name ?? null} style={{ width: 300 }} onChange={(e) => this.setState({ editMetaInfo: Object.assign({}, editMetaInfo, { name: e.target.value }) })}></Input>
                                </Space>
                                <Space>
                                    <Text>单位：</Text>
                                    <Input value={editMetaInfo?.unit ?? null} style={{ width: 300 }} onChange={(e) => this.setState({ editMetaInfo: Object.assign({}, editMetaInfo, { unit: e.target.value }) })}></Input>
                                </Space>
                            </Space>
                        </Modal>
                    )
                }
            </div>
        )
    }
}
