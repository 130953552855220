import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { DatePicker, Select, Button, Space, message, Modal, Input, TreeSelect, TimePicker, Radio, Spin } from 'antd';
import { useData, createData } from './hooks';
import moment from 'moment';
import { blank_func, shifts, silosList, genSilos, actions } from './utils';
import { post } from 'common/utils';
import {
    DeleteOutlined,
    EditOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router';

let start_time_str = "08:00:00";
let end_time_str = "20:00:00";
if (process.env.REACT_APP_PROJECT_TAG === "jinglei") {
    start_time_str = "07:30:00";
    end_time_str = "19:30:00";
};

const { Option } = Select;

const Comp = (props) => {
    const [furnaceNo] = useState(() => {
        let fNo = null;
        try {
            let id = new URLSearchParams(props.location.search).get('furnaceNo') ?? 1;
            id = parseInt(id);
            if (!isNaN(id)) {
                fNo = id;
            };
        } catch (e) { };
        if (typeof fNo !== 'number') {
            fNo = 1;
        };
        return fNo;
    });

    const [currTime] = useState(() => {
        const env_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
        const default_time = (typeof env_time === 'string' && env_time !== "")
            ? (moment(env_time).format("YYYY-MM-DD ") + moment().format("HH:mm:ss"))
            : moment().format("YYYY-MM-DD HH:mm:ss");
        return moment(default_time);
    });

    const [time, setTime] = useState(() => {
        // 页面加载时，默认显示今天
        // 今天8:00之前算上一天
        if (currTime.isBefore(moment(moment(currTime).format(`YYYY-MM-DD ${start_time_str}`)))) {
            return moment(currTime).subtract(1, 'days');
        };
        return moment(currTime);
    });
    const [selectedShift, setSelectedShift] = useState(() => {
        // 今天8:00之前，20:00之后算夜班
        if (
            currTime.isBefore(moment(moment(currTime).format(`YYYY-MM-DD ${start_time_str}`)))
            ||
            currTime.isAfter(moment(moment(currTime).format(`YYYY-MM-DD ${end_time_str}`)))
        ) {
            return shifts?.[1] ?? null;
        };
        return shifts?.[0] ?? null;
    });

    const [data, setData, loading, statusInfo] = useData();
    const setDataFunc = useRef(blank_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : blank_func;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current({
            day: moment(time).format("YYYY-MM-DD"),
            shift_id: selectedShift?.shift_id ?? null,
            furnace_no: furnaceNo
        });
    }, []);

    const [newData, setCreateData, createloading] = createData();
    const setCreateDataFunc = useRef(blank_func);
    useEffect(() => {
        setCreateDataFunc.current = typeof setCreateData === 'function' ? setCreateData : blank_func;
    }, [setCreateData]);

    useEffect(() => {
        // 判断如果是不存在的，提示新建
        if (!data) {
            if ((statusInfo?.code ?? null) === 404) {
                // 不存在
                Modal.confirm({
                    content: "该时段尚无表单，是否新建?",
                    onOk: () => {
                        // 新建
                        setCreateDataFunc.current({
                            day: moment(time).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                    },
                });
            };
        };
    }, [data, statusInfo]);

    const dateOnChange = (date) => {
        console.log(date);
        setTime(date);
        // setDataFunc.current({
        //     day: moment(time).format("YYYY-MM-DD")
        // })
        // setTimeout(()=>queryIngredInfo(), 0);
        setCreateDataFunc.current(null);
        setDataFunc.current({
            day: moment(date).format("YYYY-MM-DD"),
            shift_id: selectedShift?.shift_id ?? null,
            furnace_no: furnaceNo
        });
    };

    const selectOnChange = (v) => {
        const nextSelectedShift = (shifts ?? [])
            .filter(({ key }) => key === v)[0] ?? null;
        setSelectedShift(nextSelectedShift);
        // setDataFunc.current({
        //     shift_id: nextSelectedShift?.shift_id ?? null
        // })
        // setTimeout(()=>queryIngredInfo(), 0);
        setCreateDataFunc.current(null);
        setDataFunc.current({
            day: moment(time).format("YYYY-MM-DD"),
            shift_id: nextSelectedShift?.shift_id ?? null,
            furnace_no: furnaceNo
        });
    };

    const queryIngredInfo = () => {
        if (!!time && !!selectedShift) {
            setCreateDataFunc.current(null);
            setDataFunc.current({
                day: moment(time).format("YYYY-MM-DD"),
                shift_id: selectedShift?.shift_id ?? null,
                furnace_no: furnaceNo
            });
        } else {
            message.warning("请完善信息！")
        };
    };

    const initByPreShiftIngred = () => {
        if (!(displayData && displayData.result && displayData.result.id)) {
            return;
        }
        let ingred_id = displayData.result.id;
        Modal.confirm({
            content: "确认用上一班初始化当前接班数据吗?",
            onOk: () => {
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/init_by_pre_shift_ingred/${ingred_id}`, {
                }).then((retData) => {
                    if (retData?.data?.errcode === 0) {
                        message.success("操作成功!");
                        setCreateDataFunc.current(null);
                        setDataFunc.current({
                            day: moment(time).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                    } else if (retData?.data?.errcode === 4001) {
                        message.success(retData.data.msg);
                    } else {
                        message.warning("操作失败。");
                    };
                });
            },
        });
    };

    const [displayData, setDisplayData] = useState(null);
    useEffect(() => {
        let nextDisplayData = newData ?? data ?? null;
        if (!!nextDisplayData) {
            const silos = nextDisplayData?.parsed_result?.parsed_content?.silos ?? [];
            let parsed_silos = null;
            if (!!silos) {
                parsed_silos = silosList
                    .map((item, index) => {
                        let nextItem = item;
                        const silo = silos.filter(({ silo_id }) => silo_id === index + 1)[0] ?? null;
                        nextItem = Object.assign({}, nextItem, {
                            silo,
                        });
                        return nextItem;
                    });
            };
            let parsed_opts = null;
            const opts = nextDisplayData?.parsed_result?.parsed_content?.opts ?? [];
            if (!!opts) {
                parsed_opts = opts
                    .map((item, index) => {
                        let nextItem = item;
                        const silos = item?.silos ?? [];
                        let parsed_silos = null;
                        if (!!silos) {
                            parsed_silos = silosList
                                .map((item, index) => {
                                    let nextItem = item;
                                    const silo = silos.filter(({ silo_id }) => silo_id === index + 1)[0] ?? null;
                                    nextItem = Object.assign({}, nextItem, {
                                        silo,
                                    });
                                    return nextItem;
                                });
                        };
                        nextItem = Object.assign({}, nextItem, { parsed_silos });
                        return nextItem;
                    });
            };
            nextDisplayData = Object.assign({}, nextDisplayData, {
                parsed_silos,
                parsed_opts,
            });
        };
        // console.log(nextDisplayData);
        setDisplayData(nextDisplayData);
    }, [data, newData]);

    // common
    const [nameInfo, setNameInfo] = useState(() => {
        return {
            list: [],
            selected: null,
        };
    });
    useEffect(() => {
        post(process.env.REACT_APP_SERVER_PATH + "api/form/get_dict_items", {
            sn: "MATL_CAT_TREE",
        })
            .then((retData) => {
                // console.log(retData);
                let results = retData?.data?.results ?? [];
                results = results
                    .map((item, index) => {
                        return Object.assign({}, item, { selectable: false });
                    });
                setNameInfo((prevNameInfo) => {
                    return {
                        list: results,
                        selected: null,
                    };
                });
            });
    }, []);
    const treeSelect = (value, label, extra) => {
        setNameInfo((prevNameInfo) => {
            const selected = {
                key: value,
                value,
                name: value,
            };
            // 必然存在
            // console.log(selected.name);
            keyWordOnSearch("", selected.name);
            return Object.assign({}, prevNameInfo, { selected });
        });
    };

    // selectItem
    const [selectItemInfo, setSelectItemInfo] = useState(null);
    const [subNameInfo, setSubNameInfo] = useState(() => {
        return {
            list: [],
            selected: null,
        };
    });
    const itemOnChange = (siloIndex, item) => {
        // keyWordOnSearch("");
        // 这里点击打开选择矿名的东西
        // 每次打开需要重置nameInfo
        // 判断item.silo
        // 存在，填入缺省
        // 不存在，需要提示是否新建，确认，新建并更新
        if (!!item.silo) {
            // 存在，填入缺省
            setNameInfo((prevNameInfo) => {
                const selected = {
                    name: item.silo.matl_name,
                    value: item.silo.matl_name,
                    key: item.silo.matl_name,
                };

                let subNameSelected = null;
                if (!!(item?.silo ?? null)) {
                    subNameSelected = Object.assign({}, item?.silo);
                };
                if (!!subNameSelected) {
                    subNameSelected = Object.assign({}, subNameSelected, { key: "1" });
                };
                setSubNameInfo(!!subNameSelected ? {
                    list: [subNameSelected],
                    selected: subNameSelected,
                } : {
                    list: [],
                    selected: null,
                });

                if (!!selected?.name) {
                    // 这里需要触发首次搜索
                    keyWordOnSearch("", selected.name);
                };
                return Object.assign({}, prevNameInfo, { selected });
            });
            setSelectItemInfo({
                index: siloIndex,
            });
        } else {
            // 不存在，需要提示是否新建，确认，新建并更新
        };
    };
    const itemDelOnChange = (siloIndex) => {
        const ingred_id = displayData.result.id;
        Modal.confirm({
            content: "确认删除?",
            onOk: () => {
                let nextParsedSilos = displayData.parsed_silos;
                nextParsedSilos = nextParsedSilos
                    .map((item, index) => {
                        if (index !== siloIndex) return item;
                        let nextSilo = {
                            matl_id: null,
                            matl_name: null,
                            output_weight: item.silo.output_weight,
                            silo_id: item.silo.silo_id,
                        };
                        return Object.assign({}, item, { silo: nextSilo });
                    });
                // 保存数据
                const saveSilos = genSilos(nextParsedSilos);
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/silo_material/modify/${ingred_id}`, {
                    silos: saveSilos,
                })
                    .then((retData) => {
                        // console.log(retData);
                        if (retData?.data?.errcode === 0) {
                            // 成功的话刷新
                            message.success("删除成功!");
                            // 重新拉取数据,否则比较复杂
                            setCreateDataFunc.current(null);
                            setDataFunc.current({
                                day: moment(time).format("YYYY-MM-DD"),
                                shift_id: selectedShift?.shift_id ?? null,
                                furnace_no: furnaceNo
                            });
                        } else {
                            message.warning("保存失败!");
                        };
                    });
            },
        });
    };
    const itemHandleOk = () => {
        // 保存新的矿名字段
        // update silos
        const { id, matl_name, full_name, short_name } = subNameInfo.selected;
        let nextParsedSilos = displayData.parsed_silos;
        nextParsedSilos = nextParsedSilos
            .map((item, index) => {
                if (index !== selectItemInfo.index) return item;
                let nextSilo = item.silo;
                nextSilo = Object.assign({}, nextSilo, {
                    matl_name,
                    matl_id: id,
                    full_name,
                    short_name,
                });
                return Object.assign({}, item, { silo: nextSilo });
            });
        const nextDisplayData = Object.assign({}, displayData, {
            parsed_silos: nextParsedSilos,
        })
        // 保存数据
        const saveSilos = genSilos(nextDisplayData.parsed_silos);
        const ingred_id = nextDisplayData.result.id;
        // console.log(saveSilos, ingred_id);
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/silo_material/modify/${ingred_id}`, {
            silos: saveSilos,
        })
            .then((retData) => {
                // console.log(retData);
                if (retData?.data?.errcode === 0) {
                    // 成功的话刷新
                    setDisplayData(nextDisplayData);
                    setSelectItemInfo(null);
                } else {
                    message.warning("保存失败!");
                };
            });
    };
    const itemHandleCancel = () => {
        setSelectItemInfo(null);
    };
    const subNameOnChange = (key) => {
        setSubNameInfo((prevSubNameInfo) => {
            const selected = prevSubNameInfo?.list.filter(({ key: key_1 }) => key_1 === key)[0];
            return Object.assign({}, prevSubNameInfo, { selected });
        });
    };
    const keyWordOnSearch = (val, name) => {
        if (typeof name !== 'string') {
            return;
        };
        const keyword = val ?? "";
        post(process.env.REACT_APP_SERVER_PATH + "api/proj_puyuan/get_material_list", {
            // hard code
            // matl_name: "加蓬矿",
            matl_name: name,
            keyword,
        })
            .then((retData) => {
                // console.log(retData);
                let results = retData?.data?.results ?? [];
                results = results
                    .map((item, index) => {
                        return Object.assign({}, item, { key: index + "" });
                    });
                setSubNameInfo((prevSubNameInfo) => {
                    // 这里需要判断，在同一个matl_name情况下，如果keyword是空字符串，那么需要补全老选项进去
                    const { selected } = prevSubNameInfo;
                    let nextSelected = results.filter(({ id }) => id === selected?.matl_id)?.[0] ?? null;
                    // 在null的情况下去追加判断
                    if (!nextSelected && !!selected) {
                        if ((selected?.matl_name ?? null) === name) {
                            if (keyword === "") {
                                results = []
                                    .concat(
                                        [
                                            Object.assign(
                                                {},
                                                selected,
                                                {
                                                    id: selected.matl_id,
                                                    key: results.length + "",
                                                },
                                            ),
                                        ],
                                        results,
                                    );
                                nextSelected = results.filter(({ id }) => id === selected?.matl_id)?.[0] ?? null;
                            };
                        };
                    };
                    return {
                        list: results,
                        selected: nextSelected,
                    };
                });
            });
    };

    const itemWeightInputRef = useRef(null);
    const [selectItemWeightInfo, setSelectItemWeightInfo] = useState(null);
    const [tmpItemWeight, setTmpItemWeight] = useState(null);
    const itemWeightOnChange = (index, item) => {
        if (!!item.silo) {
            setTmpItemWeight(item.silo.output_weight ?? null);
            setSelectItemWeightInfo({
                index,
                silo: item.silo,
            });
            setTimeout(() => {
                if (!!itemWeightInputRef.current) {
                    itemWeightInputRef.current.focus({ cursor: "all" });
                };
            }, 0);
        };
    };
    const itemWeightHandleOk = () => {
        let nextWeight = parseFloat(tmpItemWeight);
        if (tmpItemWeight === null || tmpItemWeight === "") {
            nextWeight = null;
        };
        if (nextWeight === null || (typeof nextWeight === 'number' && !isNaN(nextWeight))) {
            // console.log(tmpItemWeight, selectItemWeightInfo, displayData);
            let nextParsedSilos = displayData.parsed_silos;
            nextParsedSilos = nextParsedSilos
                .map((item, index) => {
                    if (index !== selectItemWeightInfo.index) return item;
                    let nextSilo = item.silo;
                    nextSilo = Object.assign({}, nextSilo, { output_weight: nextWeight });
                    return Object.assign({}, item, { silo: nextSilo });
                });
            const nextDisplayData = Object.assign({}, displayData, {
                parsed_silos: nextParsedSilos,
            });
            const saveSilos = genSilos(nextDisplayData.parsed_silos);
            const ingred_id = nextDisplayData.result.id;
            // console.log(saveSilos);
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/silo_material/modify/${ingred_id}`, {
                silos: saveSilos,
            })
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        // 成功的话刷新
                        setDisplayData(nextDisplayData);
                        setSelectItemWeightInfo(null);
                    } else {
                        message.warning("保存失败!");
                    };
                });
        } else {
            message.error("重量格式错误!");
        };
    };
    const itemWeightHandleCancel = () => {
        setSelectItemWeightInfo(null);
    };
    const tmpItemWeightOnChange = (e) => {
        setTmpItemWeight(e.target.value);
    };

    const [selectedAction, setSelectedAction] = useState(null);
    const [chooseActionInfo, setChooseActionInfo] = useState(null);
    // addColumn
    const addColumn = () => {
        // console.log("addColumn");
        // 缺省时间
        if ((displayData?.parsed_opts ?? []).length === 0) {
            // 如果当前班组还没有列的话就增加调整以及当前班组时间
            const actTime = moment(time).format((selectedShift?.shift_id ?? null) === 1 ? `YYYY-MM-DD ${start_time_str}` : `YYYY-MM-DD ${end_time_str}`);
            setActionTime(moment(actTime));
        } else {
            // 这里如果目前是白班,查询到了其他夜班班次,time需要处理下
            // 使用opts上一轮time
            const prevTime = moment(displayData.parsed_opts[displayData.parsed_opts.length - 1].time);
            setActionTime(prevTime);
        };
        // 缺省修改类型
        setSelectedAction(actions?.[0] ?? null);
        // 显示Modal
        setChooseActionInfo({ title: '新增' });
    };
    const [actionTime, setActionTime] = useState(null);
    const addAction = () => {
        if (!selectedAction) {
            message.error("请选择操作类型！");
            return;
        };
        let currActionTime = actionTime ?? null;
        if (!currActionTime) {
            message.error("请选择时间！");
            return;
        };
        if ((displayData?.parsed_opts ?? []).length === 0) {
            if (selectedAction.value !== 'ADJUST') {
                message.warning("第一项必须是“调整”类型!");
                return;
            };
        };
        const ingred_id = displayData.result.id;
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/${selectedAction.pathKey}/create/${ingred_id}`, {
            "index": null,
            "opt_item": Object.assign(
                {},
                {
                    "type": selectedAction.value,
                    "time": moment(currActionTime).format("YYYY-MM-DD HH:mm:ss"),
                    "silos": [],
                    total_num: null,
                },
            ),
        })
            .then((retData) => {
                // console.log(retData);
                if (retData?.data?.errcode === 0) {
                    // 创建成功
                    // 重新拉取数据,否则比较复杂
                    setCreateDataFunc.current(null);
                    setDataFunc.current({
                        day: moment(time).format("YYYY-MM-DD"),
                        shift_id: selectedShift?.shift_id ?? null,
                        furnace_no: furnaceNo
                    });
                    setChooseActionInfo(null);
                    setActionTime(null);
                } else {
                    message.error("创建失败");
                };
            });
    };
    const chooseActionHandleCancel = () => {
        setChooseActionInfo(null);
        setActionTime(null);
        setSelectedAction(null);
    };

    // adjust modify
    const adjustInputRef = useRef(null);
    const [tmpAdjustValue, setTmpAdjustValue] = useState(null);
    const [adjustInfo, setAdjustInfo] = useState(null);
    const adjustOnClick = (rowItem, columnIndex, columnItem) => {
        // console.log(rowItem, columnIndex, columnItem);
        setTmpAdjustValue(columnItem?.silo?.weight ?? null);
        setAdjustInfo({
            rowItem, columnIndex, columnItem,
        });
        setTimeout(() => {
            if (!!adjustInputRef.current) {
                adjustInputRef.current.focus({ cursor: "all" });
            };
        }, 0);
    };
    const adjustHandleOk = () => {
        const pageTime = time;
        // console.log(tmpAdjustValue, adjustInfo, displayData.parsed_opts[adjustInfo.columnIndex]);
        // change column silos
        let nextAdjustValue = parseFloat(tmpAdjustValue);
        if (tmpAdjustValue === null || tmpAdjustValue === "") {
            nextAdjustValue = null;
        };
        if (nextAdjustValue === null || (typeof nextAdjustValue === 'number' && !isNaN(nextAdjustValue))) {
            // console.log(tmpItemWeight, selectItemWeightInfo, displayData);
            let nextParsedSilos = displayData.parsed_opts[adjustInfo.columnIndex].parsed_silos;
            nextParsedSilos = nextParsedSilos
                .map((item, index) => {
                    if (index !== adjustInfo.rowItem.silo_id - 1) return item;
                    let nextSilo = item.silo;
                    nextSilo = Object.assign({}, nextSilo, { silo_id: item.silo_id, weight: nextAdjustValue });
                    return Object.assign({}, item, { silo: nextSilo });
                });
            const saveSilos = nextParsedSilos.filter(({ silo }) => !!silo).map(({ silo }) => silo);
            const { time, total_num, type } = displayData.parsed_opts[adjustInfo.columnIndex];
            const conf = Object.assign(
                {},
                {
                    index: adjustInfo.columnIndex,
                    opt_item: {
                        type,
                        time,
                        total_num,
                        silos: saveSilos,
                    },
                },
            );
            const ingred_id = displayData.result.id;
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt_adjust/modify/${ingred_id}`, conf)
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        // 成功的话刷新
                        // 重新拉取数据,否则比较复杂
                        setCreateDataFunc.current(null);
                        setDataFunc.current({
                            day: moment(pageTime).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                        setAdjustInfo(null);
                    } else {
                        message.warning("保存失败!");
                    };
                });
        } else {
            message.error("重量格式错误!");
        };
    };
    const adjustHandleCancel = () => {
        setAdjustInfo(null);
    };
    const tmpAdjustValueOnChange = (e) => {
        setTmpAdjustValue(e.target.value);
    };

    // change modify
    const [tmpChangeValue, setTmpChangeValue] = useState(null);
    const [preWeightValue, setPreWeightValue] = useState(null);
    const [changeInfo, setChangeInfo] = useState(null);
    const changeOnClick = (rowItem, columnIndex, columnItem) => {
        // 选择
        const matl_name = columnItem?.silo?.matl_name ?? null;
        setNameInfo((prevNameInfo) => {
            let selected = typeof matl_name === 'string' ? {
                name: matl_name,
                value: matl_name,
                key: matl_name,
            } : null;
            return Object.assign({}, prevNameInfo, { selected });
        });
        let subNameSelected = null;
        if (!!(columnItem?.silo ?? null)) {
            subNameSelected = Object.assign({}, columnItem?.silo);
        };
        if (!!subNameSelected) {
            subNameSelected = Object.assign({}, subNameSelected, { key: "1" });
        };
        setSubNameInfo(!!subNameSelected ? {
            list: [subNameSelected],
            selected: subNameSelected,
        } : {
            list: [],
            selected: null,
        });
        if (typeof matl_name === 'string') {
            keyWordOnSearch("", matl_name);
        };

        setTmpChangeValue(columnItem?.silo?.weight ?? null);
        setPreWeightValue(columnItem?.silo?.pre_weight ?? null);
        setChangeInfo({
            rowItem, columnIndex, columnItem,
        });
    };
    const changeHandleOk = () => {
        const pageTime = time;
        // 收集信息，然后重置，降低复杂度
        const nextPreWeightValue = parseFloat(preWeightValue);
        let nextChangeValue = parseFloat(tmpChangeValue);
        if (tmpChangeValue === null || tmpChangeValue === "") {
            nextChangeValue = null;
        };
        const matl_id = subNameInfo?.selected?.id ?? null;
        const matl_name = subNameInfo?.selected?.matl_name ?? "";
        const full_name = subNameInfo?.selected?.full_name ?? "";
        const short_name = subNameInfo?.selected?.short_name ?? "";
        if (nextChangeValue === null || (typeof nextChangeValue === 'number' && !isNaN(nextChangeValue) && typeof matl_id === 'number')) {
            let nextParsedSilos = displayData.parsed_opts[changeInfo.columnIndex].parsed_silos;
            nextParsedSilos = nextParsedSilos
                .map((item, index) => {
                    if (index !== changeInfo.rowItem.silo_id - 1) return item;
                    let nextSilo = item.silo;
                    nextSilo = Object.assign(
                        {},
                        nextSilo,
                        {
                            silo_id: item.silo_id, weight: nextChangeValue, matl_id,
                            matl_name,
                            full_name,
                            short_name,
                        },
                        (typeof nextPreWeightValue === 'number' && !isNaN(nextPreWeightValue)) ? {
                            pre_weight: nextPreWeightValue,
                        } : {
                            pre_weight: null,
                        }
                    );
                    return Object.assign({}, item, { silo: nextSilo });
                });
            const saveSilos = nextParsedSilos.filter(({ silo }) => !!silo).map(({ silo }) => silo);
            const { time, total_num, type } = displayData.parsed_opts[changeInfo.columnIndex];
            const conf = Object.assign(
                {},
                {
                    index: changeInfo.columnIndex,
                    opt_item: {
                        type,
                        time,
                        total_num,
                        silos: saveSilos,
                    },
                },
            );
            // console.log(conf);
            const ingred_id = displayData.result.id;
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt_change/modify/${ingred_id}`, conf)
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        // 成功的话刷新
                        // 重新拉取数据,否则比较复杂
                        setCreateDataFunc.current(null);
                        setDataFunc.current({
                            day: moment(pageTime).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                        setChangeInfo(null);
                    } else {
                        message.warning("保存失败!");
                    };
                });
        } else {
            message.warning("信息不全，请补全信息!");
        };
    };
    const changeHandleCancel = () => {
        setChangeInfo(null);
    };
    const tmpChangeValueOnChange = (e) => {
        setTmpChangeValue(e.target.value);
    };

    // extra modify
    const [tmpExtraValue, setTmpExtraValue] = useState(null);
    const [extraInfo, setExtraInfo] = useState(null);
    const extraOnClick = (rowItem, columnIndex, columnItem) => {
        const { items } = displayData.parsed_opts[columnIndex];
        setExtraInfo({
            rowItem, columnIndex, columnItem,
            items,
        });
    };
    const extraHandleOk = () => {
        const pageTime = time;
        // 收集信息，然后重置，降低复杂度
        const { items } = extraInfo;
        const canSave = items.reduce((pV, cV) => {
            if (!pV) return pV;
            if (typeof cV.matl_id === 'number') return true;
            return false;
        }, true);
        if (canSave) {
            const { time, type, total_num } = displayData.parsed_opts[extraInfo.columnIndex];
            const conf = Object.assign(
                {},
                {
                    index: extraInfo.columnIndex,
                    opt_item: {
                        type,
                        time,
                        total_num,
                        items: extraInfo.items
                            .map((item) => {
                                const { weight } = item;
                                let parsedWeight = parseFloat(weight);
                                if (!(typeof parsedWeight === 'number' && !isNaN(parsedWeight))) {
                                    parsedWeight = null;
                                };
                                return Object.assign({}, item, { weight: parsedWeight });
                            }),
                    },
                },
            );
            // console.log(conf);
            // return;
            const ingred_id = displayData.result.id;
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt_extra/modify/${ingred_id}`, conf)
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        // 成功的话刷新
                        // 重新拉取数据,否则比较复杂
                        setCreateDataFunc.current(null);
                        setDataFunc.current({
                            day: moment(pageTime).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                        setExtraInfo(null);
                    } else {
                        message.warning("保存失败!");
                    };
                });
        } else {
            message.warning("信息不全，请补全信息!");
        };
    };
    const extraHandleCancel = () => {
        setExtraInfo(null);
    };
    const tmpExtraValueOnChange = (e) => {
        setTmpExtraValue(e.target.value);
    };
    const extraAdd = () => {
        let nextExtraValue = parseFloat(tmpExtraValue);
        if (!(typeof nextExtraValue === 'number' && !isNaN(nextExtraValue))) {
            nextExtraValue = null;
        };
        const matl_id = subNameInfo?.selected?.id ?? null;
        const matl_name = subNameInfo?.selected?.matl_name ?? "";
        const full_name = subNameInfo?.selected?.full_name ?? "";
        const short_name = subNameInfo?.selected?.short_name ?? "";
        if (typeof matl_id === 'number') {
            const { items } = extraInfo;
            const nextItems = [].concat(items ?? [], [{
                weight: nextExtraValue,
                matl_id,
                matl_name,
                full_name,
                short_name,
            }]);
            setExtraInfo(Object.assign({}, extraInfo, {
                items: nextItems,
            }));
        } else {
            message.warning("请填写完整信息!");
        };
    };
    const extraItemDelete = (index) => {
        const { items } = extraInfo;
        const nextItems = items
            .filter((item, index2) => index2 !== index);
        setExtraInfo(Object.assign({}, extraInfo, {
            items: nextItems,
        }));
    };

    // total_num edit
    const totalNumRef = useRef(null)
    const [tmpTotalNumValue, setTmpTotalNumValue] = useState(null);
    const [totalNumInfo, setTotalNumInfo] = useState(null);
    const totalNumOnClick = (rowItem, columnIndex, columnItem) => {
        setTmpTotalNumValue(columnItem?.total_num ?? null);
        setTotalNumInfo({
            rowItem, columnIndex, columnItem,
        });
        setTimeout(() => {
            if (!!totalNumRef.current) {
                totalNumRef.current.focus({ cursor: "all" });
            };
        }, 0);
    };
    const totalNumHandleOk = () => {
        const pageTime = time;
        // console.log(tmpTotalNumValue, totalNumInfo.columnItem);
        // console.log(tmpItemWeight, selectItemWeightInfo, displayData);
        let nextTotalNumValue = parseFloat(tmpTotalNumValue);
        if (tmpTotalNumValue === null || tmpTotalNumValue === "") {
            nextTotalNumValue = null;
        };
        if (nextTotalNumValue === null || (typeof nextTotalNumValue === 'number' && !isNaN(nextTotalNumValue))) {
            const { time, type, silos } = displayData.parsed_opts[totalNumInfo.columnIndex];
            const conf = Object.assign(
                {},
                {
                    index: totalNumInfo.columnIndex,
                    opt_item: {
                        type,
                        time,
                        total_num: nextTotalNumValue,
                        silos,
                    },
                },
            );
            // console.log(conf);
            if (type === "ADJUST") {
                const ingred_id = displayData.result.id;
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt_adjust/modify/${ingred_id}`, conf)
                    .then((retData) => {
                        console.log(retData);
                        if (retData?.data?.errcode === 0) {
                            // 成功的话刷新
                            // 重新拉取数据,否则比较复杂
                            setCreateDataFunc.current(null);
                            setDataFunc.current({
                                day: moment(pageTime).format("YYYY-MM-DD"),
                                shift_id: selectedShift?.shift_id ?? null,
                                furnace_no: furnaceNo
                            });
                            setTotalNumInfo(null);
                        } else {
                            message.warning("保存失败!");
                        };
                    });
            } else if (type === "CHANGE") {
                const ingred_id = displayData.result.id;
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt_change/modify/${ingred_id}`, conf)
                    .then((retData) => {
                        // console.log(retData);
                        if (retData?.data?.errcode === 0) {
                            // 成功的话刷新
                            // 重新拉取数据,否则比较复杂
                            setCreateDataFunc.current(null);
                            setDataFunc.current({
                                day: moment(pageTime).format("YYYY-MM-DD"),
                                shift_id: selectedShift?.shift_id ?? null,
                                furnace_no: furnaceNo
                            });
                            setTotalNumInfo(null);
                        } else {
                            message.warning("保存失败!");
                        };
                    });
            } else if (type === "EXTRA") {
                const { time, type, items } = displayData.parsed_opts[totalNumInfo.columnIndex];
                const conf = Object.assign(
                    {},
                    {
                        index: totalNumInfo.columnIndex,
                        opt_item: {
                            type,
                            time,
                            total_num: nextTotalNumValue,
                            items,
                        },
                    },
                );
                const ingred_id = displayData.result.id;
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt_extra/modify/${ingred_id}`, conf)
                    .then((retData) => {
                        // console.log(retData);
                        if (retData?.data?.errcode === 0) {
                            // 成功的话刷新
                            // 重新拉取数据,否则比较复杂
                            setCreateDataFunc.current(null);
                            setDataFunc.current({
                                day: moment(pageTime).format("YYYY-MM-DD"),
                                shift_id: selectedShift?.shift_id ?? null,
                                furnace_no: furnaceNo
                            });
                            setTotalNumInfo(null);
                        } else {
                            message.warning("保存失败!");
                        };
                    });
            } else { };
        } else {
            message.error("请正确填写!");
        };
    };
    const totalNumHandleCancel = () => {
        setTotalNumInfo(null);
        setTmpTotalNumValue(null);
    };
    const tmpTotalNumValueOnChange = (e) => {
        setTmpTotalNumValue(e.target.value);
    };

    const deleteColumn = (index) => {
        const ingred_id = displayData.result.id;
        Modal.confirm({
            content: "确认删除?",
            onOk: () => {
                post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt/delete/${ingred_id}`, { index })
                    .then((retData) => {
                        // console.log(retData);
                        if (retData?.data?.errcode === 0) {
                            // 成功的话刷新
                            message.success("删除成功!");
                            // 重新拉取数据,否则比较复杂
                            setCreateDataFunc.current(null);
                            setDataFunc.current({
                                day: moment(time).format("YYYY-MM-DD"),
                                shift_id: selectedShift?.shift_id ?? null,
                                furnace_no: furnaceNo
                            });
                        } else {
                            message.warning("保存失败!");
                        };
                    });
            },
        });
    };

    const shiftTotalNumRef = useRef(null);
    const [tmpShiftTotalNumValue, setTmpShiftTotalNumValue] = useState(null);
    const [shiftTotalNumInfo, setShiftTotalNumInfo] = useState(null);
    const shiftTotalNumOnClick = () => {
        setTmpShiftTotalNumValue(displayData?.parsed_result?.parsed_content?.shift_num_total ?? null);
        setShiftTotalNumInfo({});
        setTimeout(() => {
            if (!!shiftTotalNumRef.current) {
                shiftTotalNumRef.current.focus({ cursor: "all" });
            };
        }, 0);
    };
    const tmpShiftTotalNumValueOnChange = (e) => {
        setTmpShiftTotalNumValue(e.target.value);
    };
    const shiftTotalNumHandleOk = () => {
        const nextShiftTotalNumValue = parseFloat(tmpShiftTotalNumValue);
        if (typeof nextShiftTotalNumValue === 'number' && !isNaN(nextShiftTotalNumValue)) {
            const ingred_id = displayData.result.id;
            post(process.env.REACT_APP_SERVER_PATH + `/api/proj_puyuan/shift_ingred/shift_num_total/modify/${ingred_id}`, {
                shift_num_total: nextShiftTotalNumValue,
            })
                .then((retData) => {
                    // console.log(retData);
                    const errcode = retData?.data?.errcode ?? null;
                    if (errcode === 0) {
                        // 成功的话刷新
                        // 重新拉取数据,否则比较复杂
                        setCreateDataFunc.current(null);
                        setDataFunc.current({
                            day: moment(time).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                        setShiftTotalNumInfo(null);
                        setTmpShiftTotalNumValue(null);
                    } else {
                        message.warning("保存失败!");
                    };
                });
        } else {
            message.error("请正确填写!");
        };
    };
    const shiftTotalNumHandleCancel = () => {
        setShiftTotalNumInfo(null);
        setTmpShiftTotalNumValue(null);
    };

    const actionEdit = (item, index) => {
        // 缺省修改时间
        const editTime = moment(item?.time ?? (time));
        setActionTime(editTime);
        // 缺省修改类型
        setSelectedAction((actions ?? []).filter(({ value }) => value === (item?.type ?? null))[0] ?? null);
        // 显示Modal
        setChooseActionInfo({
            title: '修改',
            prev: item,
            index,
        });
    };
    const actionOnOk = () => {
        // 操作类型 确认按钮
        if (!!(chooseActionInfo?.prev ?? null)) {
            // modify action
            let currActionTime = actionTime ?? null;
            if (!currActionTime) {
                message.error("请选择时间！");
                return;
            };
            const { type, total_num, silos } = chooseActionInfo?.prev;
            const opt_item = {
                type,
                total_num,
                silos,
                time: currActionTime.format("YYYY-MM-DD HH:mm:ss"),
            };

            const ingred_id = displayData.result.id;
            post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/${selectedAction.pathKey}/modify/${ingred_id}`, {
                index: chooseActionInfo.index,
                opt_item,
            })
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        // 修改成功
                        // 重新拉取数据,否则比较复杂
                        setCreateDataFunc.current(null);
                        setDataFunc.current({
                            day: moment(time).format("YYYY-MM-DD"),
                            shift_id: selectedShift?.shift_id ?? null,
                            furnace_no: furnaceNo
                        });
                        setChooseActionInfo(null);
                        setActionTime(null);
                    } else {
                        message.error("修改失败");
                    };
                });

        } else {
            addAction();
        };
    };
    const actionOnChange = (v) => {
        setSelectedAction((actions ?? []).filter(({ key }) => key === v)[0] ?? null);
    };
    const timeOnChange = (t) => {
        if (!!t) {
            // console.log(time.format("HH:mm:00"));
            // 如果是白班,08:00~20:00
            // 如果是夜班,20:00~+08:00
            const nextTime = (selectedShift?.renderTime ?? (() => { return null }))(time, t);
            setActionTime(nextTime);
        } else {
            setActionTime(null);
        };
    };

    const [reqLoading, setLoading] = useState(false);
    const switchPosi = (direction, index) => {
        // console.log(direction, index);
        if (index === 1 && direction === "LEFT") {
            message.warning("最左侧列无法左移!");
            return;
        };
        if (index === (displayData?.parsed_opts ?? []).length - 1 && direction === "RIGHT") {
            message.warning("最右侧列无法右移!");
            return;
        };
        const ingred_id = displayData.result.id;
        setLoading(true);
        post(process.env.REACT_APP_SERVER_PATH + `api/proj_puyuan/shift_ingred/opt/switch_posi/${ingred_id}`, {
            index,
            direction,
        })
            .then((retData) => {
                setLoading(false);
                // console.log(retData);
                if (retData?.data?.errcode === 0) {
                    // 修改成功
                    // 重新拉取数据,否则比较复杂
                    setCreateDataFunc.current(null);
                    setDataFunc.current({
                        day: moment(time).format("YYYY-MM-DD"),
                        shift_id: selectedShift?.shift_id ?? null,
                        furnace_no: furnaceNo
                    });
                } else {
                    message.error("位置修改失败失败!");
                };
            });
    };

    return (
        <div className={style['wrapper']}>
            <div className="top">
                <Space>
                    <DatePicker value={time} onChange={dateOnChange} allowClear={false} inputReadOnly={true} />
                    <Select
                        value={selectedShift?.key ?? null}
                        onChange={selectOnChange}
                        style={{ width: 120 }}
                        allowClear={false}
                    >
                        {
                            shifts
                                .map((item, index) => {
                                    return (
                                        <Option value={item.key} key={index}>{item.name}</Option>
                                    );
                                })
                        }
                    </Select>
                </Space>
                <Button type='default' onClick={initByPreShiftIngred}>拷贝上一班料比</Button>
            </div>
            <div className="bottom">
                <div className="inner_wrapper scroll_bar_restyle">
                    <table
                        style={{
                            width: 320 + 180 * (displayData?.parsed_opts ?? []).length + 100
                        }}
                    >
                        <thead>
                            <tr>
                                <th className="silo">料仓</th>
                                <th className="name">矿名</th>
                                {
                                    (displayData?.parsed_opts ?? [])
                                        .map((item, index) => {
                                            const { time, type } = item;
                                            const parsed_time = moment(time).format("MM-DD HH:mm");
                                            const typeName = actions.filter(({ value }) => value === type)[0]?.name ?? "--";
                                            if (index === 0) {
                                                return (
                                                    <th key={index} className="action">
                                                        <span>接班料比</span>
                                                    </th>
                                                );
                                            };
                                            return (
                                                <th key={index} className="action">
                                                    <span>{parsed_time} {typeName}</span>
                                                    <div className="tool">
                                                        <LeftOutlined
                                                            onClick={() => switchPosi("LEFT", index)}
                                                        />
                                                        <div className="edit">
                                                            <EditOutlined
                                                                onClick={() => actionEdit(item, index)}
                                                            />
                                                            <DeleteOutlined
                                                                onClick={() => deleteColumn(index)}
                                                            />
                                                        </div>
                                                        <RightOutlined
                                                            onClick={() => switchPosi("RIGHT", index)}
                                                        />
                                                    </div>
                                                </th>
                                            );
                                        })
                                }
                                <th className="add">
                                    <Button type="primary" onClick={addColumn}>增加</Button>
                                </th>
                                <th className="weight">班总重量</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (displayData?.parsed_silos ?? [])
                                    .map((item, index, arr) => {
                                        let name = "";
                                        let full_name = "";
                                        let short_name = "";
                                        let output_weight = "";
                                        if (!!item.silo) {
                                            name = item.silo?.matl_name ?? "--";
                                            full_name = item.silo?.full_name ?? "--";
                                            short_name = item.silo?.short_name ?? "--";
                                            output_weight = item.silo?.output_weight ?? "--";
                                        };
                                        // 这里确定矿名显示full_name还是name还是一起显示

                                        return (
                                            <tr key={index} className="data">
                                                <td>{index + 1}</td>
                                                <td
                                                    className="name"
                                                >
                                                    <div className="content">{short_name}</div>
                                                    <div className="tool">
                                                        <div>
                                                            <EditOutlined
                                                                onClick={() => itemOnChange(index, item)}
                                                            />
                                                            {
                                                                (item.silo?.short_name && item.silo?.short_name !== '') &&
                                                                <DeleteOutlined
                                                                    onClick={() => itemDelOnChange(index, item)}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                {
                                                    (displayData?.parsed_opts ?? [])
                                                        .map((item_2, index_2) => {
                                                            const { type } = item_2;
                                                            return (
                                                                <td
                                                                    key={index_2}
                                                                    className={type === "EXTRA" ? "" : "modify"}
                                                                    onClick={
                                                                        type === "ADJUST"
                                                                            ? () => adjustOnClick(item, index_2, item_2.parsed_silos[index])
                                                                            : (
                                                                                type === "CHANGE"
                                                                                    ? () => changeOnClick(item, index_2, item_2.parsed_silos[index])
                                                                                    : () => { }
                                                                            )
                                                                    }
                                                                >
                                                                    {
                                                                        type === "ADJUST"
                                                                            ? item_2.parsed_silos[index]?.silo?.weight ?? ""
                                                                            : (
                                                                                type === "CHANGE"
                                                                                    ? (
                                                                                        !!item_2.parsed_silos[index]?.silo
                                                                                            ? (
                                                                                                <div>
                                                                                                    <div>{`已上料：${(item_2.parsed_silos[index]?.silo?.pre_weight ?? "--")}`}</div>
                                                                                                    <div>{`换料：${(item_2.parsed_silos[index]?.silo?.full_name ?? "--")}`}</div>
                                                                                                    <div>{`料比：${(item_2.parsed_silos[index]?.silo?.weight ?? "--")}`}</div>
                                                                                                </div>
                                                                                            )
                                                                                            : ""
                                                                                    )
                                                                                    : ""
                                                                            )
                                                                    }
                                                                </td>
                                                            );
                                                        })
                                                }
                                                {/* {
                                                    index === 0 && (
                                                        <td rowSpan={arr.length}></td>
                                                    )
                                                } */}
                                                <td></td>
                                                <td
                                                    className="weight"
                                                    onClick={() => itemWeightOnChange(index, item)}
                                                >{output_weight}</td>
                                            </tr>
                                        );
                                    })
                            }
                            <tr>
                                <td colSpan="2">附加</td>
                                {
                                    (displayData?.parsed_opts ?? [])
                                        .map((item, index) => {
                                            const { type, items } = item;
                                            if (type === "EXTRA") {
                                                return (
                                                    <td
                                                        key={index}
                                                        className="modify"
                                                        onClick={() => extraOnClick(null, index, null)}
                                                    >
                                                        <div>
                                                            {
                                                                (items ?? [])
                                                                    .map((extraItem, extraIndex) => {
                                                                        const { full_name, weight } = extraItem;
                                                                        return (
                                                                            <div key={extraIndex}>{full_name} {weight ?? "--"}</div>
                                                                        );
                                                                    })
                                                            }
                                                        </div>
                                                    </td>
                                                );
                                            };
                                            return (
                                                <td key={index}></td>
                                            );
                                        })
                                }
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan="2">已上料批次</td>
                                {
                                    (displayData?.parsed_opts ?? [])
                                        .map((item, index) => {
                                            const { total_num } = item;
                                            return (
                                                <td
                                                    key={index}
                                                    {
                                                    ...index === 0 ? {} : {
                                                        className: "total_num",
                                                        onClick: () => totalNumOnClick(null, index, item)
                                                    }
                                                    }
                                                >
                                                    {total_num ?? "--"}批
                                                </td>
                                            );
                                        })
                                }
                                <td></td>
                                <td
                                    className="total_num"
                                    onClick={() => shiftTotalNumOnClick()}
                                >{displayData?.parsed_result?.parsed_content?.shift_num_total ?? "--"}批</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {
                !!selectItemInfo && (
                    <Modal
                        title="选择原料"
                        visible={true}
                        onOk={itemHandleOk}
                        onCancel={itemHandleCancel}
                        width={800}
                    >
                        <Space>
                            <TreeSelect
                                value={nameInfo?.selected?.value ?? null}
                                style={{ width: 260 }}
                                listHeight={600}
                                treeData={nameInfo.list}
                                placeholder="请选择分类"
                                treeDefaultExpandAll
                                onChange={treeSelect}
                            />
                            {
                                !!nameInfo.selected && (
                                    <Select
                                        showSearch
                                        filterOption={false}
                                        style={{ width: 400 }}
                                        value={subNameInfo?.selected?.key ?? null}
                                        onChange={subNameOnChange}
                                        onSearch={(val) => keyWordOnSearch(val, nameInfo.selected?.name ?? null)}
                                    >
                                        {
                                            subNameInfo
                                                .list
                                                .map((item, index) => {
                                                    return (
                                                        <Option value={item.key} key={index}>{item.full_name}</Option>
                                                    );
                                                })
                                        }
                                    </Select>
                                )
                            }
                        </Space>
                    </Modal>
                )
            }
            {
                !!selectItemWeightInfo && (
                    <Modal
                        title="班总重量修改"
                        visible={true}
                        onOk={itemWeightHandleOk}
                        onCancel={itemWeightHandleCancel}
                    >
                        <Input
                            ref={itemWeightInputRef}
                            value={tmpItemWeight}
                            onPressEnter={itemWeightHandleOk}
                            onChange={tmpItemWeightOnChange}
                        />
                    </Modal>
                )
            }
            {
                !!chooseActionInfo && (
                    <Modal
                        title={chooseActionInfo?.title ?? ""}
                        visible={true}
                        onCancel={chooseActionHandleCancel}
                        onOk={actionOnOk}
                    >
                        <Space direction="vertical" style={{ fontSize: 16 }}>
                            <Space>
                                <span>操作类型：</span>
                                <Radio.Group
                                    style={{ width: 300 }}
                                    onChange={(e) => {
                                        actionOnChange(e.target.value);
                                    }}
                                    value={selectedAction?.key ?? null}
                                    disabled={!!(chooseActionInfo?.prev ?? null)}
                                >
                                    {
                                        (actions ?? [])
                                            .map((item, index) => {
                                                const { key, name } = item;
                                                return (
                                                    <Radio
                                                        value={key}
                                                        key={index}
                                                    >{name}</Radio>
                                                );
                                            })
                                    }
                                </Radio.Group>
                            </Space>
                            <Space style={{ marginTop: 12 }}>
                                <span>修改时间：</span>
                                <span>{!!actionTime ? actionTime.format("YYYY年M月D日") : "--"}</span>
                                <TimePicker
                                    style={{ width: 200 }}
                                    format="HH:mm"
                                    value={actionTime}
                                    showNow={false}
                                    onChange={timeOnChange}
                                    // 这里需要更新disable逻辑
                                    {
                                    ...(() => {
                                        // 计算offset
                                        // 这里先根据jinglei写定制化逻辑
                                        if (process.env.REACT_APP_PROJECT_TAG === "jinglei") {
                                            let disHours = (selectedShift?.disabledHours ?? (() => { return [] }))();
                                            if (disHours?.[0] === 8) {
                                                // 夜班
                                                disHours = disHours.filter(v => v !== 19);
                                            } else if (disHours?.[0] === 20) {
                                                // 白班
                                                disHours = disHours.filter(v => v !== 7);
                                            };
                                            return {
                                                disabledHours: () => disHours,
                                                disabledMinutes: (selectedHour) => {
                                                    if (selectedHour === 7) {
                                                        if (disHours?.[0] === 8) {
                                                            // 夜班
                                                            return new Array(30).fill(null).map((v, index) => 30 + index);
                                                        } else if (disHours?.[0] === 20) {
                                                            // 白班
                                                            return new Array(30).fill(null).map((v, index) => index);
                                                        };
                                                    } else if (selectedHour === 19) {
                                                        if (disHours?.[0] === 8) {
                                                            // 夜班
                                                            return new Array(30).fill(null).map((v, index) => index);
                                                        } else if (disHours?.[0] === 20) {
                                                            // 白班
                                                            return new Array(30).fill(null).map((v, index) => 30 + index);
                                                        };
                                                    };
                                                    return [];
                                                },
                                            };
                                        };
                                        return {
                                            disabledHours: selectedShift?.disabledHours ?? (() => { return [] }),
                                        };
                                    })()
                                    }
                                    size="large"
                                />
                            </Space>
                        </Space>
                    </Modal>
                )
            }
            {
                !!adjustInfo && (
                    <Modal
                        title="调整操作"
                        visible={true}
                        onOk={adjustHandleOk}
                        onCancel={adjustHandleCancel}
                    >
                        <Input
                            ref={adjustInputRef}
                            value={tmpAdjustValue}
                            onPressEnter={adjustHandleOk}
                            onChange={tmpAdjustValueOnChange}
                        />
                    </Modal>
                )
            }
            {
                !!changeInfo && (
                    <Modal
                        title="换料操作"
                        visible={true}
                        onOk={changeHandleOk}
                        onCancel={changeHandleCancel}
                        width={800}
                    >
                        <Space direction="vertical">
                            <Space>
                                <div style={{ width: 60 }}>已上料</div>
                                <Input style={{ width: 260 }} placeholder="填写换料前已上料重量" value={preWeightValue} onChange={(e) => setPreWeightValue(e.target.value)} />
                            </Space>
                            <Space>
                                <div style={{ width: 60 }}>选料</div>
                                <TreeSelect
                                    value={nameInfo?.selected?.value ?? null}
                                    style={{ width: 260 }}
                                    listHeight={600}
                                    treeData={nameInfo.list}
                                    placeholder="请选择分类"
                                    treeDefaultExpandAll
                                    onChange={treeSelect}
                                />
                                {
                                    !!nameInfo.selected && (
                                        <Select
                                            showSearch
                                            filterOption={false}
                                            style={{ width: 400 }}
                                            value={subNameInfo?.selected?.key ?? null}
                                            onChange={subNameOnChange}
                                            onSearch={(val) => keyWordOnSearch(val, nameInfo.selected?.name ?? null)}
                                        >
                                            {
                                                subNameInfo
                                                    .list
                                                    .map((item, index) => {
                                                        return (
                                                            <Option value={item.key} key={index}>{item.full_name}</Option>
                                                        );
                                                    })
                                            }
                                        </Select>
                                    )
                                }
                            </Space>
                            <Space>
                                <div style={{ width: 60 }}>料比</div>
                                <Input
                                    style={{ width: 260 }}
                                    value={tmpChangeValue}
                                    onChange={tmpChangeValueOnChange}
                                />
                            </Space>
                        </Space>
                    </Modal>
                )
            }
            {
                !!extraInfo && (
                    <Modal
                        title="附加操作"
                        visible={true}
                        onOk={extraHandleOk}
                        onCancel={extraHandleCancel}
                        width={800}
                    >
                        <Space direction="vertical">
                            {
                                (extraInfo.items ?? [])
                                    .map((item, index) => {
                                        const { full_name, weight } = item;
                                        return (
                                            <Space key={index} style={{ marginBottom: 12 }}>
                                                <div>{`第${index + 1}组: ${full_name ?? ""} 加料量:${weight ?? "--"}`}</div>
                                                <DeleteOutlined onClick={() => extraItemDelete(index)} />
                                            </Space>
                                        );
                                    })
                            }
                            <Space direction="vertical" style={{ marginTop: 12 }}>
                                <Space>
                                    <div style={{ width: 60 }}>选料</div>
                                    <TreeSelect
                                        value={nameInfo?.selected?.value ?? null}
                                        style={{ width: 260 }}
                                        listHeight={600}
                                        treeData={nameInfo.list}
                                        placeholder="请选择分类"
                                        treeDefaultExpandAll
                                        onChange={treeSelect}
                                    />
                                    {
                                        !!nameInfo.selected && (
                                            <Select
                                                showSearch
                                                filterOption={false}
                                                style={{ width: 400 }}
                                                value={subNameInfo?.selected?.key ?? null}
                                                onChange={subNameOnChange}
                                                onSearch={(val) => keyWordOnSearch(val, nameInfo.selected?.name ?? null)}
                                            >
                                                {
                                                    subNameInfo
                                                        .list
                                                        .map((item, index) => {
                                                            return (
                                                                <Option value={item.key} key={index}>{item.full_name}</Option>
                                                            );
                                                        })
                                                }
                                            </Select>
                                        )
                                    }
                                </Space>
                                <Space>
                                    <div style={{ width: 60 }}>加料量</div>
                                    <Input
                                        style={{ width: 260 }}
                                        value={tmpExtraValue}
                                        onChange={tmpExtraValueOnChange}
                                    />
                                    <Button type="primary" onClick={extraAdd} ghost>添加</Button>
                                </Space>
                            </Space>
                        </Space>
                    </Modal>
                )
            }
            {
                !!totalNumInfo && (
                    <Modal
                        title="已上料批次"
                        visible={true}
                        onOk={totalNumHandleOk}
                        onCancel={totalNumHandleCancel}
                    >
                        <Input
                            ref={totalNumRef}
                            value={tmpTotalNumValue}
                            onPressEnter={totalNumHandleOk}
                            onChange={tmpTotalNumValueOnChange}
                        />
                    </Modal>
                )
            }
            {
                !!shiftTotalNumInfo && (
                    <Modal
                        title="班总上料批次"
                        visible={true}
                        onOk={shiftTotalNumHandleOk}
                        onCancel={shiftTotalNumHandleCancel}
                    >
                        <Input
                            ref={shiftTotalNumRef}
                            value={tmpShiftTotalNumValue}
                            onPressEnter={shiftTotalNumHandleOk}
                            onChange={tmpShiftTotalNumValueOnChange}
                        />
                    </Modal>
                )
            }
            {
                (reqLoading || loading || createloading) && (
                    <div style={{ position: 'absolute', zIndex: 100, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin />
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Comp);
