import React, { useState } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import ICON_GEAR from './res/gear.svg';
import ICON_HALF_CHECKED from './res/half_checked.svg';
import ICON_CHECKED from './res/checked.svg';
import ICON_BLANK from './res/blank.svg';
import Chart1 from './Chart1';
import Chart2 from './Chart2';
import Modal from 'components/Modal';
import {
    Select, Button,
} from 'antd';
import { useSys } from 'containers/DemoSystemV2/hooks';

const { Option } = Select;

const RANGE_HOURS = [1, 2, 3, 4];

const MODAL_WIDTH = 616;

const Comp = (props) => {
    const [visible, setVisible] = useState(false);

    const [systems] = useSys();

    const rangeHoursOnChange = () => { };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="趋势"
                watchDetail={false}
                subtitle={(
                    <div className={style["subtitle"]}>
                        <img alt="" src={ICON_GEAR} className="setting" onClick={() => setVisible(true)} />
                        <div className="period_wrapper">
                            {
                                RANGE_HOURS
                                    .map((hour, index) => (
                                        <div
                                            className={"hour_item" + (0 === index ? ' selected_hour_item' : '')}
                                            key={index}
                                            onClick={() => rangeHoursOnChange(index)}
                                        >{hour}h</div>
                                    ))
                            }
                        </div>
                    </div>
                )}
            >
                <div className={style["content"]}>
                    <div className="scroll_wrapper scroll_bar_restyle">
                        {
                            // type 1 charts
                            new Array(2)
                                .fill(null)
                                .map((item, index) => {
                                    return (
                                        <div className="chart_wrapper" key={index}>
                                            <Chart1 info={item} />
                                        </div>
                                    );
                                })
                        }
                        {
                            // type 2 charts
                            new Array(3)
                                .fill(null)
                                .map((item, index) => {
                                    return (
                                        <div className="chart_wrapper" key={index} info={item}>
                                            <Chart2 info={item} />
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            </Wrapper>
            {
                visible && (
                    <Modal
                        title="选择参数"
                        width={MODAL_WIDTH}
                        visible={true}
                        footer={null}
                        centered={true}
                        onCancel={handleCancel}
                    >
                        <div className={style["modal_content_wrapper"]}>
                            <div className="top">
                                <Select
                                    value={"all"}
                                    style={{ width: 120 }}
                                // onChange={(v) => sysOnChange(v)}
                                >
                                    <Option value="all">全部系统</Option>
                                    {
                                        (systems ?? [])
                                            .map((item, index) => {
                                                return (
                                                    <Option value={item.key} key={index}>{item.name}</Option>
                                                );
                                            })
                                    }
                                </Select>
                                <Select
                                    showSearch
                                    style={{ width: 320, marginLeft: 16 }}
                                    placeholder="请输入"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="jack">FDLD _FF _MFDLD _FF _M </Option>
                                    <Option value="lucy">FDLD _FF _MFDLD _FF _M </Option>
                                    <Option value="tom">FDLD _FF _MFDLD _FF _M </Option>
                                </Select>
                                <Button type="primary" style={{ marginLeft: 'auto' }}>添加</Button>
                            </div>
                            <div className="bottom">
                                <div className="wrapper" style={{ width: MODAL_WIDTH }}>
                                    <div className="scroll_wrapper scroll_bar_restyle">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="select">
                                                        <img alt="" src={ICON_HALF_CHECKED} className="icon" />
                                                    </th>
                                                    <th>点位名称</th>
                                                    <th>点位描述</th>
                                                    <th className="action">操作</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    new Array(10)
                                                        .fill(null)
                                                        .map((item, index) => {
                                                            const selected = Math.random() < 0.5;
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="select">
                                                                        <img alt="" src={selected ? ICON_CHECKED : ICON_BLANK} className="icon" />
                                                                    </td>
                                                                    <td className="name">FDLD _FF _MFDLD _FF _M </td>
                                                                    <td>CI预热器出口温度CI预热器出口温度</td>
                                                                    <td className="action">
                                                                        <div className="btn">删除</div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </div>
    );
};

export default Comp;
