import React, { useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import BarRank from './BarRank';
import { table_datas } from './utils';
import Table from './Table';
import OperatingStatus from './OperatingStatus';
import SystemFactors from './SystemFactors';
import Trend from './Trend';

const right_width = 876;
const margin_width = 24;

const Page = (props) => {
    const [sysInfo, setSysInfo] = useState(null);
    const [timeInfo, setTimeInfo] = useState(null);
    const [selectedFactor, setSelectedFactor] = useState(null);

    return (
        <div className={style['wrapper']}>
            <Header sysInfo={sysInfo} setSysInfo={setSysInfo} timeInfo={timeInfo} setTimeInfo={setTimeInfo} />
            <div className="content_wrapper">
                <div className="inner_wrapper">
                    <div className="left">
                        <SystemFactors timeInfo={timeInfo} sysInfo={sysInfo} setSelectedFactor={setSelectedFactor} />
                    </div>
                    <div className="right scroll_bar_restyle" style={{ width: right_width }}>
                        <div className="content" style={{ width: right_width - margin_width }}>
                            <div className="top">
                                <Trend selectedFactor={selectedFactor} />
                            </div>
                            <div className="mid">
                                <div className="left">
                                    <OperatingStatus />
                                </div>
                                <div className="right">
                                    <Table {...(table_datas?.[0] ?? {})} timeInfo={timeInfo} />
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="left">
                                    <Table {...(table_datas?.[1] ?? {})} timeInfo={timeInfo} />
                                </div>
                                <div className="right">
                                    <BarRank timeInfo={timeInfo} sysInfo={sysInfo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
