import React from 'react'
import './index.scss'
import IndexBar from "./BarChart";
const YieldTarget = (props) => {
    const {
        ethaneEfficiency,
        propaneEfficiency,
        butaneEfficiency,
        ethaneEfficiencyChart,
        propaneEfficiencyChart,
        butaneEfficiencyChart,
    } = props;
    return (
        <div className="yieldTarget">
            <div className="yieldTarget-header">
                <div className="yieldTarget-header-left">收率指标</div>
                <div className="yieldTarget-header-right">小时平均:近10日</div>
            </div>
            <div className="yieldTarget-item">
                <div className="yieldTarget-item-left">
                    <div className="item-name">乙烷收率</div>
                    <div className="item-progress">
                        <div className="triangle-down"></div>
                        <div className="item-progress-proNum" style={{width: `${ethaneEfficiency}%`}}></div>
                        <div className="benchmark">{ethaneEfficiency}%</div>
                    </div>
                </div>
                <IndexBar barChart={ethaneEfficiencyChart}/>
            </div>
            <div className="yieldTarget-item">
                <div className="yieldTarget-item-left">
                    <div className="item-name">丙烷收率</div>
                    <div className="item-progress">
                        <div className="triangle-down"></div>
                        <div className="item-progress-proNum" style={{width: `${propaneEfficiency}%`}}></div>
                        <div className="benchmark">{propaneEfficiency}%</div>
                    </div>
                </div>
                <IndexBar barChart={propaneEfficiencyChart}/>
            </div>
            <div className="yieldTarget-item">
                <div className="yieldTarget-item-left">
                    <div className="item-name">丁烷收率</div>
                    <div className="item-progress">
                        <div className="triangle-down"></div>
                        <div className="item-progress-proNum" style={{width: `${butaneEfficiency}%`}}></div>
                        <div className="benchmark">{butaneEfficiency}%</div>
                    </div>
                </div>
                <IndexBar barChart={butaneEfficiencyChart}/>
            </div>
        </div>
    )
}
export default YieldTarget
