import { useEffect, useState } from 'react'
import { get } from 'common/utils'

/** 获取最近使用的模版列表 */
const Hook = () => {
  const [templateList, setTemplateList] = useState([])
  const [refreshFlag, setRefreshFlag] = useState(Symbol())
  /** 刷新常用模版列表 */
  const refreshFun = () => setRefreshFlag(Symbol())
  useEffect(() => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/latelyTemplateSearch')
      .then(res => {
        setTemplateList(res.data.results || [])
      })
      .catch(err => {
        console.log(err)
      })
  }, [refreshFlag])
  return [templateList, refreshFun]
}

export default Hook
