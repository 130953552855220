const defaultItemConf = {
    type: 'line',
    color: '#40A1E1',
};

const defaultYAxis = {
    upper_limit: null,
    lower_limit: null,
    "unit": null
};

export {
    defaultItemConf,
    defaultYAxis,
};
