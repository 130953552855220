import Page from './ProductionComp.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    const { mainPage } = state;
    const { timeRange } = mainPage;
    return {
        timeRange,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
};

const WrapperedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);

export default WrapperedPage;