import React, { useEffect, useState, useCallback, useReducer } from 'react';
import style from './index.module.scss';
import { Tabs } from 'antd';
import { get } from 'common/utils';
import ChartTable from './ChartTable';
import CommonContext from './context';
import moment from 'moment'

const { TabPane } = Tabs;
const iniState = {
    timeInfo: null,
    conf: null,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return Object.assign({}, state, action.obj)
        default:
            return state;
    };
};

const Page = (props) => {
    const { timeInfo } = props;
    const [state, dispatch] = useReducer(reducer, iniState);

    const [sysList, setSysList] = useState(null);
    const [selectSys, setSelectSys] = useState(null);
    const [selectSubSys, setSelectSubSys] = useState(null);
    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + "puyuan_api/get_pm_tree")
            .then(retData => {
                // console.log(retData);
                if (retData?.data?.errcode === 0) {
                    const result = retData?.data?.result ?? null;
                    const p_list = (result ?? [])
                        .map((item, index) => {
                            const { sys_pm } = item;
                            const n_sys_pm = (sys_pm ?? [])
                                .map((item, sub_index) => Object.assign({}, item, { key: `${index}-${sub_index}` }));
                            const n_item = Object.assign({}, item, {
                                key: `${index}`,
                                sys_pm: n_sys_pm,
                            });
                            return n_item;
                        });
                    setSysList(p_list);
                    // 选择第一个存在的二级节点
                    let n_selectSys = null;
                    let n_selectSubSys = null;
                    p_list
                        .some(item => {
                            const { sys_pm } = item;
                            if ((sys_pm ?? []).length > 0) {
                                n_selectSys = item;
                                n_selectSubSys = sys_pm[0];
                                return true;
                            };
                            return false;
                        });
                    setSelectSys(n_selectSys);
                    setSelectSubSys(n_selectSubSys);
                };
            });
    }, []);

    const callback = useCallback((v) => {
        const n_selectSys = (sysList ?? []).filter(({ key }) => key === v)[0] ?? null;
        setSelectSys(n_selectSys);
        // 切换一级菜单后，二级菜单切换到一级的第一个
        const n_selectSubSys = n_selectSys?.sys_pm?.[0] ?? null;
        setSelectSubSys(n_selectSubSys);
    }, [sysList]);

    const subSysOnClick = useCallback(item => {
        return () => {
            setSelectSubSys(item);
        };
    }, []);

    useEffect(() => {
        // console.log(selectSubSys, timeInfo)
        if (selectSubSys && timeInfo && selectSys) {
            const basic_tag = selectSubSys?.basic_tag ?? null;
            const dtype = timeInfo?.dtype ?? null
            const { time, range } = timeInfo
            const date = moment(time).startOf(range).format("YYYY-MM-DD")
            const conf = { dtype, date, basic_tag }
            dispatch({ type: 'update', obj: { selectSubSys, timeInfo, conf, selectSys } });
        }
    }, [selectSubSys, timeInfo, selectSys]);

    return (
        <CommonContext.Provider value={{ state, dispatch }}>
            <div className={style['wrapper']}>
                <div className="top">
                    <Tabs
                        onChange={callback} activeKey={selectSys?.key ?? null}
                        tabBarStyle={{ marginBottom: 0, paddingLeft: 32 }}
                    >
                        {
                            (sysList ?? [])
                                .map((item) => {
                                    const { sys_name, key } = item;
                                    return (
                                        <TabPane tab={sys_name} key={key}>
                                        </TabPane>
                                    );
                                })
                        }
                    </Tabs>
                </div>
                <div className="bottom">
                    <div className="left">
                        <div className="content scroll_bar_restyle">
                            {
                                (selectSys?.sys_pm ?? [])
                                    .map((item, index) => {
                                        const { name, key } = item;
                                        return (
                                            <div
                                                className={"item" + (selectSubSys?.key === key ? " selected" : "")} key={index}
                                                onClick={subSysOnClick(item)}
                                            >{name}</div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                    <div className="right">
                        <ChartTable />
                    </div>
                </div>
            </div>
        </CommonContext.Provider>
    );
};

export default Page;
