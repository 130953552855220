import React from 'react';

import TITLE_ICON from './res/title_icon.png';

import './EnergyConsumptionComp.scss';

const energyCostItems = [
    {
        name: '耗电量',
        up: false,
        value: 4.678,
        unit: '万kwh',
        rate: 1.2,
        aveName: '日均耗电量',
        aveValue: 23576,
        aveUnit: 'kwh',
    },
    {
        name: '耗煤量',
        up: true,
        value: 1845,
        unit: '吨',
        rate: 1.2,
        aveName: '日均耗煤量',
        aveValue: 235,
        aveUnit: 't',
    },
    {
        name: '耗电量',
        up: false,
        value: 687,
        unit: 't',
        rate: 1.2,
        aveName: '日均耗电量',
        aveValue: 56.7,
        aveUnit: 't',
    },
    {
        name: '余热发电',
        up: false,
        value: 4.678,
        unit: '万kwh',
        rate: 1.2,
        aveName: '日均发电量',
        aveValue: 23576,
        aveUnit: 'kwh',
    },
    {
        name: '耗煤量',
        up: true,
        value: 1845,
        unit: '吨',
        rate: 1.2,
        aveName: '日均耗煤量',
        aveValue: 235,
        aveUnit: 't',
    },
];

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="main_page_bottom_panels_energy_consumption_comp_wrapper scroll_bar_restyle">
                <div className="comp_content_wrapper">
                    {
                        energyCostItems
                            .map((item, index) => {
                                const { name, up, value, unit, rate, aveName, aveValue, aveUnit } = item;
                                return (
                                    <div key={index} className="item_wrapper">
                                        <div className="item_title">
                                            <div className="text">{name}</div>
                                            <img alt="" src={TITLE_ICON} className="title_icon" />
                                        </div>
                                        <div className="rate_text">比目标值</div>
                                        <div className="values_wrapper">
                                            <div className="value">{value}</div>
                                            <div className="unit">{unit}</div>
                                            <div className={"arrow" + (typeof up === 'boolean' ? (up ? " arrow_top" : " arrow_down") : "")} />
                                            <div className="rate">{rate}%</div>
                                        </div>
                                        <div className="item_aLine" />
                                        <div className="ave_wrapper">
                                            <div className="ave_title">{aveName}</div>
                                            <div className="ave_value_wrapper">{aveValue}{aveUnit}</div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
