import React, { useMemo, useRef, useEffect, useState } from 'react';
import style from './index.module.scss';
import { Modal, Table, Spin } from 'antd';
import { useData } from './hooks';
import SortIcons from 'components/SortIcons';

const default_func = () => { };

const Comp = (props) => {
    const { info, timeInfo } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(default_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : default_func;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current(Object.assign({}, timeInfo ?? {}, { product_id: info?.product_id }));
    }, [timeInfo, info]);

    const onCancel = useMemo(() => {
        if (typeof props.onCancel === 'function') {
            return props.onCancel;
        };
        return () => { };
    }, [props]);

    const [sortInfo, setSortInfo] = useState(null);
    const sortOnClick = (key) => {
        setSortInfo((prevSortInfo) => {
            let nextSortInfo = null;
            if (typeof key === 'string') {
                let nextIsDesc = false;
                if (!!prevSortInfo) {
                    if (prevSortInfo.key === key) {
                        nextIsDesc = !prevSortInfo.is_desc;
                    };
                };
                nextSortInfo = { key, is_desc: nextIsDesc };
            };
            return nextSortInfo;
        });
    };

    const sorted_list = useMemo(() => {
        const list = data;
        if (!!list) {
            let ret_list = []
                .concat(list);
            if (!!sortInfo) {
                const { key, is_desc } = sortInfo;
                ret_list = []
                    .concat(ret_list)
                    .sort(({ [key]: v1 }, { [key]: v2 }) => {
                        const v_1 = is_desc ? v1 : v2;
                        const v_2 = is_desc ? v2 : v1;
                        if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                            return v_2 - v_1;
                        };
                        if (typeof v1 === 'number') return -1;
                        if (typeof v2 === 'number') return 1;
                        return 0;
                        // 1是交换
                    });
            };
            // 这里是否要把 不存在的指标均值放最后???
            return ret_list;
        };
        return null;
    }, [data, sortInfo]);

    return (
        <Modal
            title="成本分析"
            visible={true}
            onCancel={onCancel}
            footer={null}
            width={1096}
            centered={true}
        >
            <div className={style["wrapper"]}>
                <div className="top">指标透视</div>
                <div className="bottom">
                    <Table
                        // bordered={true}
                        columns={[
                            {
                                title: '指标名称',
                                dataIndex: 'name',
                            },
                            {
                                title: '指标均值',
                                key: 'avg_value',
                                align: "center",
                                render: (text, record) => {
                                    return record.avg_value_text + record.unit_text;
                                },
                            },
                            {
                                title: '指标标准值',
                                key: 'standard_value',
                                align: "center",
                                render: (text, record) => {
                                    return record.standard_text + record.unit_text;
                                },
                            },
                            {
                                title: '对标比较',
                                key: 'comp',
                                align: "center",
                                render: (text, record) => {
                                    const { comp_status, comp_pct, parsed_comp_pct } = record;
                                    return (
                                        <div className={style["content"] + (comp_status === 0 ? " green" : (comp_status === 1 ? " red" : ""))}>
                                            {
                                                (typeof comp_pct === 'number' && comp_pct !== 0) ? (
                                                    <div className={"arrow" + (comp_pct > 0 ? " up" : " down")}></div>
                                                ) : null
                                            }
                                            <div>{parsed_comp_pct}</div>
                                        </div>
                                    );
                                },
                            },
                            {
                                title: () => {
                                    return (
                                        <div className={style["sort"]}>
                                            <div className="wrapper" onClick={() => sortOnClick("cpl_rate")}>
                                                <div className="content">
                                                    <div className="text">合格率</div>
                                                    <SortIcons is_desc={sortInfo?.key === "cpl_rate" ? sortInfo?.is_desc : null} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                },
                                dataIndex: 'cpl_rate_text',
                                align: "center",
                            },
                            {
                                title: () => {
                                    return (
                                        <div className={style["sort"]}>
                                            <div className="wrapper" onClick={() => sortOnClick("bias_pct")}>
                                                <div className="content">
                                                    <div className="text">改进潜力</div>
                                                    <SortIcons is_desc={sortInfo?.key === "bias_pct" ? sortInfo?.is_desc : null} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                },
                                dataIndex: 'parsed_bias_pct',
                                align: "center",
                            },
                            {
                                title: () => {
                                    return (
                                        <div className={style["sort"]}>
                                            <div className="wrapper" onClick={() => sortOnClick("pot_cost")}>
                                                <div className="content">
                                                    <div className="text">成本影响</div>
                                                    <SortIcons is_desc={sortInfo?.key === "pot_cost" ? sortInfo?.is_desc : null} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                },
                                key: 'pot_cost',
                                align: "center",
                                render: (text, record) => {
                                    const { barWidth, cost_unit, parsed_pot_cost } = record;
                                    return (
                                        <div className={style["cost_td_block"]}>
                                            <div className="left">{parsed_pot_cost}{cost_unit}</div>
                                            <div className="right">
                                                {
                                                    typeof barWidth === 'string' && (
                                                        <div className="content">
                                                            <div className="bar_value" style={{ width: barWidth }}></div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    );
                                },
                            },
                            {
                                key: 'action',
                                render: () => {
                                    return (
                                        <div className={style["watch_detail"]}>查看详情</div>
                                    );
                                },
                            },
                        ]}
                        dataSource={sorted_list ?? []}
                    />
                </div>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
        </Modal>
    );
};

export default Comp;
