import React, { useState } from 'react';
import style from './index.module.scss';
import { getColumns } from './utils';

const Page = (props) => {
    const [columns] = useState(() => {
        return getColumns();
    });

    return (
        <div className={style['wrapper']}>
            <div className="content_wrapper">
                <table>
                    <thead>
                        <tr>
                            {
                                (columns ?? [])
                                    .map((item, index) => {
                                        let { title } = item;
                                        if (typeof title === 'function') {
                                            title = title();
                                        };
                                        return (
                                            <th key={index}>{title}</th>
                                        );
                                    })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            new Array(10)
                                .fill(null)
                                .map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                (columns ?? [])
                                                    .map((item, cIndex) => {
                                                        const { dataIndex } = item;
                                                        return (
                                                            <td key={cIndex}>{dataIndex}</td>
                                                        );
                                                    })
                                            }
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Page;
