import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Input, message } from 'antd'
import style from './custom.module.scss'

/** UI模版 templateId=6 */
const Com = props => {
  const { capsuleDetailInfo, cacheData, resetAction } = props
  const [elec_num, setElecNum] = useState(null) // 发电量

  useImperativeHandle(props.refInstance, () => ({
    checkValidate,
    getCustomData: () => {
      return { elec_num }
    }
  }))

  useEffect(() => {
    if (capsuleDetailInfo && Object.keys(capsuleDetailInfo).length > 0) {
      // 编辑
      const { jsonContent } = capsuleDetailInfo
      setElecNum(jsonContent.elec_num)
    } else {
      // 新建时会产生缓存数据
      const cacheInfo = cacheData ? cacheData : null
      if (cacheInfo) {
        const jsonContent = cacheInfo.json_content || {}
        if (Object.keys(jsonContent).length > 0) {
          setElecNum(jsonContent.elec_num)
        }
      }
    }
  }, [])

  /** 重置 */
  useEffect(() => {
    if (resetAction) {
      setElecNum(null)
    }
  }, [resetAction])

  /** 更新输入框对应数值 */
  const updateElecNum = newValue => {
    const regex = /^\-?(0|[1-9]\d{0,2})?(\.\d{0,2})?$/
    const formatValue = (newValue || '')
      .replace(/[^\-\d^\.]+/g, '')
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
    if (!regex.test(formatValue)) {
      console.log('最多输入3位整数和2位小数', formatValue)
      return
    }
    setElecNum(formatValue)
  }
  /** 字符串转数字 */
  const formatNumber = newValue => {
    if (!newValue) return
    const formatValue = isNaN(parseFloat(newValue)) ? '' : parseFloat(newValue)
    setElecNum(formatValue)
  }

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (!elec_num && elec_num !== 0) {
      message.error('请输入发电量')
      return false
    }
    return true
  }

  return (
    <div className={style['custom_6']}>
      <div className="row-item">
        <div className="name">发电量</div>
        <Input
          value={elec_num}
          onChange={e => updateElecNum(e.target.value)}
          onBlur={e => formatNumber(e.target.value)}
        />
        <div className="unit">万Kwh</div>
      </div>
    </div>
  )
}

export default Com
