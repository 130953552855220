import React, { useRef, useEffect, useMemo } from 'react';
import * as echarts from 'echarts';
import style from './SepTimePie.module.scss';
import { significantNum } from 'common/utils';

const initChart = (refNode, val_1, val_2, val_3, val_4, price_1, price_2, price_3, price_4) => {
    if (!!refNode) {
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        instance.setOption({
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            series: [
                {
                    name: '电量',
                    type: 'pie',
                    radius: [0, '55%'],
                    hoverAnimation: false,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: val_1, name: '峰段', itemStyle: { color: '#FE8569' } },
                        { value: val_2, name: '平段', itemStyle: { color: '#FFC63C' } },
                        { value: val_3, name: '谷段', itemStyle: { color: '#5BA3ED' } },
                        { value: val_4, name: '尖峰', itemStyle: { color: 'red' } },
                    ]
                },
                {
                    name: '电费',
                    type: 'pie',
                    radius: ['73%', '100%'],
                    hoverAnimation: false,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: price_1, name: '峰段', itemStyle: { color: '#FE8569' } },
                        { value: price_2, name: '平段', itemStyle: { color: '#FFC63C' } },
                        { value: price_3, name: '谷段', itemStyle: { color: '#5BA3ED' } },
                        { value: price_4, name: '尖峰', itemStyle: { color: 'red' } },
                    ]
                }
            ]
        });
    }
};

const Comp = (props) => {
    const { pieDatas } = props;
    const chartNode = useRef(null);
    useEffect(() => {
        if (!!pieDatas) {
            const datas = pieDatas.map((v) => typeof v === 'number' ? v : 0);//此处处理null认为是0
            initChart(chartNode.current, ...datas);
        };
    }, [pieDatas]);
    const valueTexts = useMemo(() => {
        if (!!pieDatas) {
            return pieDatas.map((v) => typeof v === 'number' ? significantNum(v) : '--');
        };
        return new Array(8).fill('--');
    }, [pieDatas]);
    const rateTexts = useMemo(() => {
        let ret = Array(8).fill('--');
        if (!!pieDatas) {
            if (pieDatas.filter((v, index) => index < 4 && typeof v === 'number').length === 4) {
                const sum_0_3 = pieDatas[0] + pieDatas[1] + pieDatas[2] + pieDatas[3];
                ret[0] = pieDatas[0] / sum_0_3;
                ret[1] = pieDatas[1] / sum_0_3;
                ret[2] = pieDatas[2] / sum_0_3;
                ret[3] = pieDatas[3] / sum_0_3;
            };
            if (pieDatas.filter((v, index) => (index >= 4 && index < 8) && typeof v === 'number').length === 4) {
                const sum_4_7 = pieDatas[4] + pieDatas[5] + pieDatas[6] + pieDatas[7];
                ret[4] = pieDatas[4] / sum_4_7;
                ret[5] = pieDatas[5] / sum_4_7;
                ret[6] = pieDatas[6] / sum_4_7;
                ret[7] = pieDatas[7] / sum_4_7;
            };
            ret = ret.map((v) => {
                if (typeof v === 'number') {
                    return Math.floor(v * 1000) / 10;
                } else {
                    return v;
                };
            });
        };
        return ret;
    }, [pieDatas]);
    return (
        <div className={style['SepTimePie']}>
            <div className='chartNode' ref={chartNode}></div>
            <div className='details_table'>
                <div className='table_line title_line'>
                    <div className='col_1'>
                    </div>
                    <div className='col_2'>
                        <span className='name'>电量</span><span className='unit'>万kWh</span>
                    </div>
                    <div className='col_3'>
                        <span className='name'>电费</span><span className='unit'>万元</span>
                    </div>
                </div>
                <div className='table_line'>
                    <div className='col_1'>
                        <span className="color_pt" style={{ background: '#FE8569' }}></span><span>峰段</span>
                    </div>
                    <div className='col_2'>
                        <span className='pct'>{rateTexts[0]}%</span><span className='value'>{valueTexts[0]}</span>
                    </div>
                    <div className='col_3'>
                        <span className='pct'>{rateTexts[4]}%</span><span className='value'>{valueTexts[4]}</span>
                    </div>
                </div>
                <div className='table_line'>
                    <div className='col_1'>
                        <span className="color_pt" style={{ background: '#FFC63C' }}></span><span>平段</span>
                    </div>
                    <div className='col_2'>
                        <span className='pct'>{rateTexts[1]}%</span><span className='value'>{valueTexts[1]}</span>
                    </div>
                    <div className='col_3'>
                        <span className='pct'>{rateTexts[5]}%</span><span className='value'>{valueTexts[5]}</span>
                    </div>
                </div>
                <div className='table_line'>
                    <div className='col_1'>
                        <span className="color_pt" style={{ background: '#5BA3ED' }}></span><span>谷段</span>
                    </div>
                    <div className='col_2'>
                        <span className='pct'>{rateTexts[2]}%</span><span className='value'>{valueTexts[2]}</span>
                    </div>
                    <div className='col_3'>
                        <span className='pct'>{rateTexts[6]}%</span><span className='value'>{valueTexts[6]}</span>
                    </div>
                </div>
                <div className='table_line'>
                    <div className='col_1'>
                        <span className="color_pt" style={{ background: 'red' }}></span><span>尖峰</span>
                    </div>
                    <div className='col_2'>
                        <span className='pct'>{rateTexts[3]}%</span><span className='value'>{valueTexts[3]}</span>
                    </div>
                    <div className='col_3'>
                        <span className='pct'>{rateTexts[7]}%</span><span className='value'>{valueTexts[7]}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
