import React from 'react'
import { withRouter } from 'react-router'
import { post, get } from 'common/utils'
import moment from 'moment'
import { Spin, message, Button, Modal, DatePicker, Checkbox, Input, Select } from 'antd'
import style from './Content.module.scss'
import ICON_1 from './res/icon_1.png'
import ICON_2 from './res/icon_2.png'
import ICON_3 from './res/icon_3.png'
import ICON_4 from './res/icon_4.png'
import BLUE_CIRCLE from './res/blue_circle.png'
import RED_CIRCLE from './res/red_circle.png'
import PEOPLE from './res/people.png'
import { tmp_users_exchange } from '../../utils'

const SYSTEM_ICONS = [ICON_1, ICON_2, ICON_3, ICON_4]

const { RangePicker } = DatePicker

const { Option } = Select

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalVisible: false,
      systems: [],
      timeRange: null,
      sys_id: null,
      raceId: props.id,
      loading: false,
      selectRuleModalVisible: false,
      ruleList: [],
      rule_id: null,
      selectedRule: null,
      raceName: ''
    }
  }

  componentDidMount() {
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/cmt_system/list').then(retData => {
      console.log(retData)
      if (retData.data && retData.data.errcode === 0) {
        this.setState({ systems: retData.data.results }, this.getInitialData)
      }
    })
    this.getRuleList()
  }

  getRuleList = () => {
    get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exam/rules/list', {}).then(retData => {
      console.log(retData)
      if (retData.data && retData.data.errcode === 0) {
        this.setState({
          ruleList: retData.data.results,
          rule_id: (retData.data.results.filter(({ status }) => status === 1)[0] || {})._id || null
        })
      }
    })
  }

  getInitialData = () => {
    // console.log(this.state.raceId);
    const { raceId: _id, systems } = this.state
    if (_id !== '-1') {
      get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/get_one', {
        _id
      }).then(retData => {
        console.log(retData)
        if (retData.data && retData.data.errcode === 0) {
          const { details, name } = retData.data.result
          details.forEach(({ sys_id, time_begin, time_end, users }) => {
            const [system] = systems.filter(({ id }) => id === sys_id)
            if (!system) return
            Object.assign(system, {
              time_begin,
              time_end,
              users,
              selected: true
            })
          })
          this.setState({ systems, raceName: name })
        }
      })
    }
  }

  getPeopleList = () => {
    const { sys_id, timeRange } = this.state
    return post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/get_attend_users', {
      sys_id,
      time_begin: timeRange[0].format('YYYY-MM-DD HH:mm:ss'), //'2020-02-25 08:00:00'
      time_end: timeRange[1].format('YYYY-MM-DD HH:mm:ss') //'2020-02-26 00:00:00',
    })
  }

  onChange = value => {
    console.log('Selected Time: ', value)
    this.setState({ timeRange: value })
  }

  selectTime = sys_id => {
    this.setState({ sys_id, modalVisible: true, timeRange: null })
  }

  modalOnOk = () => {
    const { timeRange, sys_id, systems } = this.state
    if (timeRange === null) {
      message.error('尚未选择时间段')
      return
    }
    const system = systems.filter(({ id }) => id === sys_id)[0]
    if (!system) return
    this.setState({ loading: true })
    this.getPeopleList().then(retData => {
      console.log(retData)
      if (retData.data && retData.data.errcode === 0) {
        system.time_begin = timeRange[0].format('YYYY-MM-DD HH:mm:ss')
        system.time_end = timeRange[1].format('YYYY-MM-DD HH:mm:ss')
        system.users = retData.data.results
        this.setState({
          systems,
          modalVisible: false,
          timeRange: null,
          sys_id: null,
          loading: false
        })
      }
    })
  }

  onSelected = (sys_id, e) => {
    const { systems } = this.state
    const system = systems.filter(({ id }) => id === sys_id)[0]
    if (!system) return
    system.selected = e.target.checked
    this.setState({ systems })
  }

  createExam = () => {
    let self = this
    const { raceId, systems, rule_id, raceName } = this.state
    if (raceId === '-1') {
      if (
        systems.filter(({ time_begin, time_end, selected }) => selected && time_end && time_begin)
          .length === 0
      ) {
        message.error('尚未选择或未填写完整')
        return
      }
      this.setState({ loading: true })
      post(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/exams/create', {
        rule_id,
        details: systems
          .filter(({ time_begin, time_end, selected }) => selected && time_end && time_begin)
          .map(({ id: sys_id, time_begin, time_end, users = [] }) => {
            return {
              sys_id,
              time_begin,
              time_end,
              users: users.map(({ user_id, user_name: user_name }) => ({
                user_id,
                user_name: user_name
              }))
            }
          }),
        name: raceName
      }).then(retData => {
        console.log(retData)
        self.setState({ loading: false })
        if (retData.data && retData.data.errcode === 0) {
          this.props.history.push('/System/RaceList')
        } else if (retData.data && retData.data.msg) {
          Modal.warning({ title: '创建失败', content: retData.data.msg })
        } else {
          message.error('创建失败')
        }
      })
    } else {
      // this.props.history.push(`/System/ExamResult/${raceId}`);
      this.props.gotoExamResult(raceId)
    }
  }

  selectRuleModalOnOk = () => {
    const { selectedRule: rule_id } = this.state
    this.setState({ rule_id, selectRuleModalVisible: false })
  }
  selectRuleOnChange = selectedRule => {
    this.setState({ selectedRule })
  }
  selectRuleBtn = () => {
    const { rule_id: selectedRule } = this.state
    this.setState({ selectRuleModalVisible: true, selectedRule })
  }

  render() {
    const {
      modalVisible,
      systems,
      timeRange,
      raceId,
      loading,
      selectRuleModalVisible,
      ruleList,
      selectedRule,
      rule_id,
      raceName
    } = this.state
    const { customStyle } = this.props

    return (
      <div className={style['race_setting_content_wrapper']} style={customStyle}>
        <div className="btn_header_wrapper">
          <div className="opt_title">
            计分规则：{(ruleList.filter(({ _id }) => _id === rule_id)[0] || {}).name || rule_id}
          </div>
          <Button className="btn_setting" disabled={raceId !== '-1'} onClick={this.selectRuleBtn}>
            规则选择
          </Button>
          <Button
            type="primary"
            className="btn_setting"
            onClick={() => this.createExam()}
            style={{
              backgroundColor: '#357CF7',
              borderColor: '#357CF7'
            }}
          >
            {raceId === '-1' ? '开始竞赛' : '竞赛结果'}
          </Button>
        </div>
        <div className="content_wrapper">
          {/* <div className="header">一级菜单 / 二级菜单 / 三级菜单</div> */}
          <div className="header">
            <div className="race_name">竞赛名称：</div>
            {this.state.raceId == -1 && (
              <Input
                value={raceName}
                placeholder="请输入竞赛名称"
                style={{ width: 300 }}
                onChange={e => this.setState({ raceName: e.target.value })}
              />
            )}
            {this.state.raceId != -1 && <div>{this.state.raceName}</div>}
          </div>
          <Spin tip="Loading..." spinning={loading}>
            <div className="content">
              {systems.map(
                ({ id, name, time_begin, time_end, users = [], selected = false }, index) => (
                  <div className="item_block_wrapper" key={index}>
                    <Checkbox
                      disabled={raceId !== '-1'}
                      onChange={e => this.onSelected(id, e)}
                      checked={selected}
                      className="custom_checkbox"
                    />
                    <div className="title_wrapper">
                      <img src={SYSTEM_ICONS[index % 4]} alt="" />
                      <div className="title">{name}</div>
                      <div className="btns">
                        <Button
                          type="primary"
                          ghost
                          style={{ marginRight: 8 }}
                          disabled={raceId !== '-1'}
                          onClick={() => this.selectTime(id)}
                        >
                          选择时段
                        </Button>
                        {/* <Button type="primary" ghost onClick={() => this.props.history.push('/System/RunningRecords')}>运行纪录</Button> */}
                        {/* 迁移系统内，注释掉运行纪录 */}
                      </div>
                    </div>
                    <div className="row_block">
                      <img src={BLUE_CIRCLE} alt="" />
                      <div>
                        <div className="name">开始时间</div>
                        <div className="value">
                          {time_begin && moment(time_begin).format('YYYY年M月D日 HH:mm:ss')}
                        </div>
                      </div>
                    </div>
                    <div className="row_block">
                      <img src={RED_CIRCLE} alt="" />
                      <div>
                        <div className="name">结束时间</div>
                        <div className="value">
                          {time_end && moment(time_end).format('YYYY年M月D日 HH:mm:ss')}
                        </div>
                      </div>
                    </div>
                    <div className="row_block">
                      <img src={PEOPLE} alt="" />
                      <div>
                        <div className="name">参评人员</div>
                        <div className="value people_block">
                          {users.map(({ user_name, user_id }) => {
                            let tmp_name = ''
                            if (
                              !!tmp_users_exchange &&
                              typeof tmp_users_exchange[user_name] === 'string'
                            ) {
                              tmp_name = tmp_users_exchange[user_name]
                            }
                            return <div key={user_id}>{tmp_name}</div>
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
              {new Array(12 - (systems.length % 12)).fill(0).map((_, index) => (
                <div className="item_block_wrapper_blank" key={index} />
              ))}
              <Modal
                title="选择时间段"
                centered
                visible={modalVisible}
                onOk={this.modalOnOk}
                onCancel={() => this.setState({ modalVisible: false })}
                width={1200}
              >
                <div className="race_setting_period_modal_wrapper">
                  <RangePicker
                    showTime={{ format: 'HH:mm:ss' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={['开始时间', '结束时间']}
                    value={timeRange}
                    onChange={this.onChange}
                  />
                </div>
              </Modal>
              <Modal
                title="规则选择"
                centered
                visible={selectRuleModalVisible}
                onOk={this.selectRuleModalOnOk}
                onCancel={() => this.setState({ selectRuleModalVisible: false })}
                width={1200}
              >
                <div
                // className="race_setting_select_rule_modal_wrapper"
                >
                  <Select
                    showSearch
                    style={{ width: 600 }}
                    placeholder="规则选择"
                    optionFilterProp="children"
                    onChange={this.selectRuleOnChange}
                    value={selectedRule}
                  >
                    {ruleList.map(({ _id, name }) => (
                      <Option value={_id} key={_id}>
                        {name} {_id}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Modal>
            </div>
          </Spin>
        </div>
      </div>
    )
  }
}

export default withRouter(Content)
