import DataTable from 'containers/ElecAnalysis/DataTable';
import Report from 'containers/ElecAnalysis/Report';
import ChartTable from 'containers/ElecAnalysis/ChartTable';
import { time_key, timeRange_key } from 'containers/ElecAnalysis/constants';
import ElecAnalysis from 'containers/ElecAnalysis';
import Monitor from 'containers/Monitor';
import CardGroup from 'containers/Monitor/CardGroup';

// 支持不存在children的tab
const tabs = [
            {
                name: '实时监测',
                comp: Monitor,
                props: {
                    value: {
                        comp: CardGroup,
                    },
                    controlled: true,
                },
            },
            {
                name: '图表',
                comp: ElecAnalysis,
                props: {
                    value: {
                        comp: ChartTable,
                        timeType: time_key,
                    },
                    controlled: true
                },
            },
            {
                name: '数据表',
                comp: ElecAnalysis,
                props: {
                    value: {
                        comp: DataTable,
                        timeType: time_key,
                    },
                },
            },
            {
                name: '报表',
                comp: ElecAnalysis,
                props: {
                    value: {
                        comp: Report,
                        timeType: timeRange_key,
                    },
                },
            },
        // ]
        //     .map((child) => {
        //         const { props } = child;
        //         let nextProps = { controlled: true };
        //         if (!!props) {
        //             nextProps = Object.assign({}, nextProps, props);
        //         };
        //         return Object.assign({}, child, { props: nextProps });
        //     }),
    // },
];

export {
    tabs,
};
