import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import BlockLayout from './BlockLayout';
import ContentLeft from './ContentLeft';
import ContentTop from './ContentTop';
import ContentBottom from './ContentBottom';
import { get, post } from 'common/utils';

const Page = (props) => {
    const [sysInfo, setSysInfo] = useState(null);
    const [currData, setCurrData] = useState(null);
    const [radioData, setRadioData] = useState(null);
    const [tag2Val, setTag2Val] = useState(null);
    const [tags, setTags] = useState(null);

    useEffect(() => {
        // console.log(tags);
        if (!!tags) {
            let waiting = true;
            const func = () => {
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                    tag_name_list: tags,
                })
                    .then(retData => {
                        if (waiting) {
                            // console.log(retData);
                            if (retData?.data?.errcode === 0) {
                                setTag2Val(retData?.data?.results ?? null);
                            };
                        };
                    });
            };
            func();
            const myInterval = setInterval(() => {
                func();
            }, 10000);
            return () => {
                clearInterval(myInterval);
                waiting = false;
            };
        };
    }, [tags]);

    useEffect(() => {
        if (!!sysInfo) {
            let waiting = true;
            const { sysTag, id } = sysInfo;
            Promise.all([
                // get("http://192.168.0.95:8073/api/puyuan/queryCurrentStoveTagList", { sysTag: sysTag }),
                get(process.env.REACT_APP_SERVER_PATH + "api/puyuan/queryCurrentStoveTagList", { sysTag: sysTag }),
                // get("http://192.168.0.95:8073/api/puyuan/queryCurrentRadio", { furnaceNo: id }),
                get(process.env.REACT_APP_SERVER_PATH + "api/puyuan/queryCurrentRadio", { furnaceNo: id }),
            ])
                .then(retDatas => {
                    if (waiting) {
                        // 配比，出炉
                        let n_tags = [];
                        const [retCurr, retRadio, retAlloy] = retDatas;
                        // console.log(retCurr, retRadio, retAlloy);
                        if (retCurr?.data?.error === 0) {
                            setCurrData(retCurr?.data?.results ?? null);
                            n_tags = n_tags
                                .concat(
                                    (retCurr?.data?.results ?? []).map(({ tagName }) => tagName),
                                );
                        };
                        if (retRadio?.data?.error === 0) {
                            setRadioData(retRadio?.data?.results ?? null);
                        };
                        n_tags = [...new Set(n_tags)];//去重
                        setTags(n_tags);
                    };
                });
            return () => {
                waiting = false;
            };
        };
    }, [sysInfo]);

    return (
        <div className={style['wrapper']}>
            <Header sysInfo={sysInfo} setSysInfo={setSysInfo} />
            <div className="content_wrapper">
                <div className="left">
                    <BlockLayout title="当前炉况">
                        <ContentLeft info={currData} tag2Val={tag2Val} />
                    </BlockLayout>
                </div>
                <div className="right">
                    <div className="top">
                        <BlockLayout title="当前配比">
                            <ContentTop info={radioData} />
                        </BlockLayout>
                    </div>
                    <div className="bottom">
                        <BlockLayout title="出炉合金">
                            <ContentBottom sysInfo={sysInfo} />
                        </BlockLayout>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
