import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { Modal, message } from 'antd'
import style from './index.module.scss'
import TplSelector from './TplSelector'
import Content from './Content'
import ContentView from './ContentView'
import PointSelector from './PointSelector'
import Attachment from './Attachment'
import Comment from './Comment'
import FullScreenLoading from 'components/FullScreenLoading'
import QrCodeModal from '../QrCodeModal'
import { get, post, reqDelete } from 'common/utils'
import { useClassifyTagList } from '../../../../hooks'
const baseUrl = process.env.REACT_APP_SERVER_PATH

const ForwardContent = forwardRef((props, ref) => {
  return <Content {...props} refInstance={ref} />
})
const ForwardPointSelector = forwardRef((props, ref) => {
  return <PointSelector {...props} refInstance={ref} />
})

const ForwardAttachment = forwardRef((props, ref) => {
  return <Attachment {...props} refInstance={ref} />
})

const ForwardComment = forwardRef((props, ref) => {
  return <Comment {...props} refInstance={ref} />
})

const Com = props => {
  const { capsuleId, isEdit, visible, tplParams, refreshEvents } = props
  const [loading, setLoading] = useState(false)
  const [classifyTags] = useClassifyTagList([])
  const [capsuleDetailInfo, setCapsuleDetailInfo] = useState(null) // 详情对象
  const [tplId, setTplId] = useState(null) // 选择的胶囊模版id
  const [templateId, setTemplateId] = useState(4) // 不同类型的模版ui索引 1-6
  const [isDisabled, setIsDisabled] = useState(false) // true 详情 false 新建/编辑
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isCodeVisible, changeCodeVisible] = useState(false) // 二维码弹窗

  const scrollRef = useRef() // 滚动区域
  const contentRef = useRef() // 内容
  const pointRef = useRef() // 点位
  const attachmentRef = useRef() // 附件
  const commentRef = useRef() // 评论

  useEffect(() => {
    capsuleId && getCapsuleDetail()
    if (!capsuleId) {
      const { tpl_id, tpl_name, tpl_sn } = tplParams
      if (!tpl_id) {
        getDefaultTemplateId()
      } else {
        handleSetTemplate(tpl_id, tpl_name, tpl_sn)
      }
    }
  }, [])
  /** 获取胶囊详情 */
  const getCapsuleDetail = () => {
    setIsDisabled(isEdit ? false : true)
    setLoading(true)
    const requestUrl = `${baseUrl}api/v1/event/capsule/singleCapsuleSearch`
    get(requestUrl, { capsuleId })
      .then(res => {
        setLoading(false)
        if (res.data && res.data.error === 0) {
          const capsuleDetail = res.data.results || {}
          const json_content = JSON.parse(capsuleDetail.json_content) || {}
          capsuleDetail.jsonContent = json_content
          setCapsuleDetailInfo(capsuleDetail)
          setTemplateId(json_content && json_content.templateId ? json_content.templateId : 4)
        } else {
          message.error('获取胶囊详情信息失败')
        }
      })
      .catch(() => setLoading(false))
  }

  // 查找默认模板的id
  const getDefaultTemplateId = () => {
    const apiUrl = baseUrl + 'api/v1/event/capsule/defaultTemplateIdSearch'
    get(apiUrl).then(res => {
      if (res.data && res.data.error === 0) {
        setTplId(res.data.results)
      }
    })
  }

  /** 切换分类标签对应模版 */
  const handleSetTemplate = (newTplId, name, sn) => {
    // 模版切换前，对当前模版数据进行缓存
    contentRef.current && contentRef.current.saveCacheData()
    setTplId(newTplId)
    let newTemplateId = 4
    if (name === '筒体风机操作') {
      newTemplateId = 1
    } else if (name === '三次风闸板阀操作') {
      newTemplateId = 2
    } else if (name === '煤管操作') {
      newTemplateId = 3
    } else if (name === '熟料库空') {
      newTemplateId = 5
    } else if (name === '发电量') {
      newTemplateId = 6
    } else if (
      name === '超上限' ||
      name === '超下限' ||
      name === '设备检修' ||
      name === '设备更换' ||
      name === 'XXX异常' ||
      name === '游离钙异常' ||
      name === '立升重异常' ||
      name === '入窑细度异常' ||
      name === '生料秤调整' ||
      name === '头煤秤调整' ||
      name === '尾煤秤调整' ||
      name === '止料异常' ||
      name === '台时异常'
    ) {
      newTemplateId = 4
    }

    setTemplateId(newTemplateId)

    setTimeout(() => {
      if (contentRef.current) {
        const cacheTamplateData = contentRef.current.cacheData
        if (cacheTamplateData[newTplId]) {
          contentRef.current.setTitle(cacheTamplateData[newTplId].json_content.title || name)
          contentRef.current.setClassify(cacheTamplateData[newTplId].json_content.classify)
        } else {
          contentRef.current.setTitle(name)
          contentRef.current.setClassify(sn ? Number(sn) : null)
        }
      }
    }, 0)
  }

  /** 点击提交 */
  const handleOk = () => {
    let params = {}

    if (contentRef.current && contentRef.current.checkValidate()) {
      let customInfo = contentRef.current.getContentInfo()
      customInfo.json_content['selectPointList'] = pointRef.current
        ? pointRef.current.selectPointList
        : []
      params = { ...customInfo }
    } else {
        return
    }

    params = { ...params, uuid: attachmentRef.current?.uuids || [] }

    if (capsuleId) {
      editTemplateInfo(params)
    } else {
      addTemplate(params)
    }
  }

  // 新建模版
  const addTemplate = obj => {
    const { json_content: jsonContent, date, occurEndTime, uuid } = obj
    const params = {
      tplId,
      jsonContent,
      urgency: 0,
      date,
      occurEndTime,
      uuid
    }
    post(baseUrl + 'api/v1/event/capsule/saveCapsule', params)
      .then(res => {
        if (res.data && res.data.error === 0) {
          message.success('新建胶囊成功')
          const { onOk: propOnOk } = props
          if (typeof propOnOk === 'function') {
            propOnOk({ id: res.data.results, date, type: 'add' })
          }
        } else {
          message.error(res.data.msg)
          setConfirmLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        setConfirmLoading(false)
      })
  }

  /** 编辑胶囊 */
  const editTemplateInfo = obj => {
    const { json_content: jsonContent, date: occurTime, occurEndTime, uuid } = obj
    const params = {
      id: capsuleId,
      jsonContent,
      occurTime,
      occurEndTime,
      uuid
    }
    post(baseUrl + 'api/v1/event/capsule/modifyCapsule', params)
      .then(res => {
        if (res.data && res.data.error === 0) {
          message.success('编辑胶囊成功')
          const { onOk: propOnOk } = props
          if (typeof propOnOk === 'function') {
            propOnOk({ id: capsuleId, type: 'edit' })
          }
        } else {
          message.error(res.data.msg)
          setConfirmLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
        setConfirmLoading(false)
      })
  }

  /** 点击取消 */
  const handleCancel = e => {
    if (e && e.currentTarget.className === 'ant-btn' && capsuleId && !isEdit && !isDisabled) {
      // 查看详情时编辑并点击取消
      getCapsuleDetail()
    } else {
      const { onCancel: propOnCancel } = props
      if (typeof propOnCancel === 'function') {
        propOnCancel()
      }
    }
    commentRef.current &&
      commentRef.current.dirty &&
      typeof refreshEvents === 'function' &&
      refreshEvents()
  }

  // 删除
  const deleteCapsule = () => {
    Modal.confirm({
      content: `确认删除事件胶囊吗?`,
      onOk: () => {
        reqDelete(baseUrl + 'api/v1/event/capsule/capsuleDeleteByIdSearch', {
          capsuleId: [capsuleId]
        })
          .then(res => {
            if (res.data.msg === '删除成功') {
              message.success('删除成功')
              // 刷新事件看板
              if (typeof props.refreshEvents === 'function') props.refreshEvents()
              handleCancel()
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  /** 滚动到视图底部 */
  const moveViewToBottom = () => {
    console.log(scrollRef.current.scrollTop)
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }

  return (
    <Modal
      title={capsuleId ? (isDisabled ? '胶囊详情' : '编辑胶囊') : '新建胶囊'}
      maskClosable={isDisabled}
      okText="提交"
      visible={visible}
      wrapClassName={`common-modal ${style['editAndViewCapsuleModal']} ${
        isDisabled ? 'detail-modal' : ''
      }`}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      width="670px"
    >
      {isDisabled && (
        <div className="header-bar">
          <div className="del-btn" onClick={deleteCapsule} />
          <div className="edit-btn" onClick={() => setIsDisabled(false)} />
          <div className="qrcode" onClick={() => changeCodeVisible(true)} />
        </div>
      )}
      <div ref={scrollRef} className="scroll-wrapper">
        {!capsuleId && (
          <div style={{ marginBottom: 24 }}>
            <TplSelector
              classifyTags={classifyTags}
              tplParams={tplParams}
              handleSetTemplate={handleSetTemplate}
            />
          </div>
        )}

        {!isDisabled && (
          <div className="forward-content">
            <ForwardContent
              ref={contentRef}
              capsuleDetailInfo={capsuleDetailInfo}
              tplId={tplId}
              templateId={templateId}
              tplParams={tplParams}
              classifyTags={classifyTags}
            />
          </div>
        )}

        {capsuleId && isDisabled && (
          <ContentView
            capsuleDetailInfo={capsuleDetailInfo || {}}
            classifyTags={classifyTags || {}}
          />
        )}
        <div style={{ marginBottom: 24 }}>
          <ForwardPointSelector
            ref={pointRef}
            isDisabled={isDisabled}
            occur_time={capsuleDetailInfo?.occur_time}
            points={!!capsuleDetailInfo ? capsuleDetailInfo.jsonContent.selectPointList : null}
          />
        </div>
        <div style={{ marginBottom: 24 }}>
          <ForwardAttachment ref={attachmentRef} capsuleId={capsuleId} isDisabled={isDisabled} />
        </div>
        {capsuleId && (
          <ForwardComment
            ref={commentRef}
            capsuleId={capsuleId}
            moveViewToBottom={moveViewToBottom}
          />
        )}
      </div>
      {loading && <FullScreenLoading />}
      <QrCodeModal
        isCodeVisible={isCodeVisible}
        templateId={templateId}
        capsuleDetailInfo={capsuleDetailInfo || {}}
        handleCancelCodeModal={() => changeCodeVisible(false)}
      />
    </Modal>
  )
}

Com.defaultProps = {
  tplParams: { tabIndex: 0 }
}

export default Com
