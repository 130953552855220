import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import ICON_ADD from './res/add.svg';
import { get } from 'common/utils';

const Comp = (props) => {
    const [mostCommonlyUsedTemplate, setMostCommonlyUsedTemplate] = useState(null);
    useEffect(() => {
        // 暂时未说需要实时刷新
        get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/commonlyTemplateSearch', {})
            .then((retData) => {
                // console.log('toolbar', retData);
                if (!!retData.data && retData.data.error === 0) {
                    if (!!retData.data.results) {
                        setMostCommonlyUsedTemplate(
                            retData.data.results
                                .filter((item, index) => index < 3)
                                .map((item) => {
                                    let titleText = '';
                                    const { title } = item;
                                    if (typeof title === 'string') titleText = title;
                                    return Object.assign({}, item, { titleText });
                                })
                        );
                    } else {
                        setMostCommonlyUsedTemplate(null);
                    };
                };
            });
    }, []);
    const onClick = (id, title) => {
        const { addCapsuleOnClick } = props;
        if (typeof addCapsuleOnClick === 'function') {
            addCapsuleOnClick(id, title);
        };
    };
    return (
        <div className={style['wrapper']}>
            {
                !!mostCommonlyUsedTemplate && mostCommonlyUsedTemplate.length > 0 && (
                    <div className="tabs_wrapper">
                        {
                            mostCommonlyUsedTemplate
                                .map((item, index, arr) => {
                                    const { titleText, id, title } = item;
                                    return [(
                                        <div className="single_tab_wrapper" key={index} onClick={() => onClick(id, title)}>
                                            <div className="text">{titleText}</div>
                                        </div>
                                    )]
                                        .concat(
                                            index < arr.length - 1 ? [
                                                (
                                                    <div className="line" key={`line-${index}`}></div>
                                                )
                                            ] : []
                                        );
                                })
                        }
                    </div>
                )
            }
            <div className="add_btn_wrapper" onClick={onClick}>
                <img alt="" src={ICON_ADD} className="icon" />
                <div className="text">新增</div>
            </div>
        </div>
    );
};

export default Comp;
