import React, { useCallback, useRef } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize/index.js'
import * as echarts from 'echarts~5';

const initVals = {}
const Comp = (props) => {
    const { list } = props;
    const nodes = useRef(initVals)
    const refFunc = useCallback((ind) => {
        return refN => {
            nodes.current[ind] = refN
            const handleClick = props?.click ?? (() => { })
            const renderFunc = props?.renderFunc ?? (() => { })
            renderFunc(refN, props.list[ind], nodes.current, handleClick)
        }
    }, [props])
    const handleResize = useCallback(() => {
        Object.keys(nodes.current).some(key => {
            const refN = nodes.current[key]
            if (refN) {
                const ins = echarts.getInstanceByDom(refN)
                if (ins) {
                    ins.resize()
                }
            }
            return false
        })
    }, [])
    return (
        <div className={style['wrapper']}>
            <div style={{ height: 0, opacity: 0 }}>
                <DomResize onResize={handleResize} />
            </div>
            {
                (list ?? [])
                    .map((item, index) => {
                        const { name } = item
                        return (
                            <div className='item' key={index}>
                                <div className='top'>{name ?? '--'}</div>
                                <div className='bottom' ref={refFunc(index)}></div>
                            </div>
                        )
                    })
            }
        </div>
    );
};

export default Comp;
