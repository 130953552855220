import React from 'react';
import style from './index.module.scss';

const Page = (props) => {

    return (
        <div className={style['wrapper']}>
            <table>
                <thead>
                    <tr>
                        <th className="name"></th>
                        <th className="val"></th>
                        <th className="time"></th>
                        <th className="blank"></th>
                        {
                            new Array(9)
                                .fill(null)
                                .map((item, index) => {
                                    return (
                                        <th key={index}>{`${18 - index}:00`}</th>
                                    );
                                })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        new Array(5)
                            .fill(null)
                            .map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="name">出磨生料KH</td>
                                        <td className="val">0.95</td>
                                        <td className="time">(20日 19:00)</td>
                                        <td className="blank"></td>
                                        {
                                            new Array(9)
                                                .fill(null)
                                                .map((item, index) => {
                                                    const value = (Math.random() > 0.1) ? 0.95 : "--";
                                                    const isRed = typeof value === 'number' && Math.random() > 0.9;
                                                    return (
                                                        <td key={index} className={"value" + (isRed ? " red" : "")}>{value}</td>
                                                    );
                                                })
                                        }
                                    </tr>
                                );
                            })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default Page;
