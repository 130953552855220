import React from 'react'
import { Avatar } from 'antd'
import style from './index.module.scss'

const defaultBgColor = '#99BDFB'
/** 自定义头像 优先级 photoSrc>iconText>defaultIcon
 * @param photoSrc 头像地址 https://
 * @param iconText 没有头像图片或头像图片加载失败时的替代文字 当photoSrc和iconText都没有值时，会采用默认头像
 * @param size 头像大小 number 默认32
 * @param bgColor 头像背景色 默认颜色 #99BDFB
 */
const MyAvatar = props => {
  const { photoSrc, iconText, size, bgColor } = props
  const avatarSize = size || 32
  const avatarBgColor = bgColor || defaultBgColor
  const defaultIcon = (
    <i className={style['defaultIcon']} style={{ width: avatarSize, height: avatarSize }} />
  ) // 默认头像
  const avatar = () => {
    if (photoSrc || iconText) {
      return (
        <Avatar
          size={avatarSize}
          src={photoSrc}
          style={{ backgroundColor: avatarBgColor, userSelect: 'none' }}
        >
          {iconText}
        </Avatar>
      )
    } else {
      return <Avatar size={avatarSize} icon={defaultIcon} />
    }
  }

  return avatar()
}

export default MyAvatar
