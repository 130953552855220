import React from 'react';
import style from './index.module.scss';
import Schema from '../SchemaProp';

const Page = (props) => {
    return (
        <div className={style['wrapper']}>
            <div className="content">
                <Schema />
            </div>
        </div>
    );
};

export default Page;
