import React from "react";
import PagePanel from '../../Monitor'

const DeviceStatus = () => {
    return (
        <div style={{height: `100%`}}>
            <PagePanel value={{comp: null,diagramId:"xSbY1TAsSYiMj0x0iZ2MAw"}} controlled={true}/>
        </div>
    )
}
export default DeviceStatus
