import React, { useCallback } from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';
import moment from 'moment';
import { symbol as symbolBase64 } from './constants.js';

const TrendChart = (props) => {
    const { chartData, upper_limit, lower_limit, max_bound, min_bound } = props;
    const refNode = useRef(null);
    const renderChart = useCallback(() => {
        if (!!refNode.current) {
            // render chart
            let instance = echarts.getInstanceByDom(refNode.current);
            if (!instance) {
                instance = echarts.init(refNode.current);
            };
            const allData = chartData
                .map(([_, v]) => v)
                .concat([upper_limit, lower_limit])
                .filter(v => typeof v === "number");
            let maxY = 1;
            let minY = 0;
            if (allData.length > 0) {
                maxY = Math.max(...allData);
                minY = Math.min(...allData);
            };
            const deltaY = maxY - minY;
            maxY += 0.1 * deltaY;
            minY -= 0.1 * deltaY;
            // console.log(minY, maxY, allData);

            // custom maxY minY
            if (typeof max_bound === "number") {
                maxY = max_bound;
            };
            if (typeof min_bound === "number") {
                minY = min_bound;
            };

            const markLineData = [upper_limit, lower_limit]
                .filter((v) => typeof v === "number");
            instance.setOption({
                grid: {
                    left: 3,
                    top: 0,
                    right: 3,
                    bottom: 0,
                },
                xAxis: {
                    type: 'time',
                    // show: false,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: true,
                        color: 'rgba(255,255,255,0.2)',
                        inside: true,
                        length: 5,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    show: false,
                    scale: true,
                    min: minY,
                    max: maxY,
                },
                series: [
                    {
                        data: chartData.map(([t, v, status]) => [t * 1000, v, status]),
                        type: 'line',
                        itemStyle: {
                            color: 'rgba(64, 161, 225, 1)',
                            // barBorderRadius: 2,
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: 'rgba(64, 161, 225, 1)' },
                                    { offset: 1, color: 'transparent' },
                                ],
                            ),
                        },
                        symbol: (value, obj) => {
                            const [, , status] = value;
                            if (status === 0) {
                                return "image://" + symbolBase64;
                            } else {
                                return 'none';
                            };
                        },
                        symbolSize: 16,
                        // smooth: true,
                        markLine: {
                            silent: true,
                            animation: false,
                            symbol: ['none', 'none'],
                            data: markLineData
                                .map(v => ({
                                    yAxis: v,
                                    label: {
                                        show: false,
                                    },
                                    lineStyle: {
                                        color: 'rgba(68, 243, 218, 0.5)',
                                        type: 'dashed',
                                    },
                                })),
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        // console.log(params);
                        const [param1] = params;
                        const { data } = param1;
                        const [time, value, status] = data;
                        let result = `<div>${moment.unix(time / 1000).format("YYYY-MM-DD HH:mm:ss")}</div>`;
                        let standValue = '';
                        if (typeof upper_limit === "number") {
                            standValue += Math.floor(upper_limit * 100) / 100 + '(上限); ';
                        };
                        if (typeof lower_limit === "number") {
                            standValue += Math.floor(lower_limit * 100) / 100 + '(下限); ';
                        };
                        result += `<div>基准值: ${standValue}</div>`
                        result += `<div>平均值：${Math.floor(value * 100) / 100}(${status === 0 ? '未达标' : status === 1 ? '达标' : '-'})</div>`
                        return result;
                    },
                    position: (point, params, dom, rect, size) => {
                        const { viewSize } = size;
                        const isLeft = point[0] * 2 < viewSize[0];
                        const retPos = Object.assign(
                            {
                                bottom: point[1] - 10,
                            },
                            (isLeft ?
                                {
                                    left: point[0] + 10,
                                } : {
                                    right: viewSize[0] - point[0] + 10,
                                }),
                        );
                        return retPos;
                    },
                },
            });
        };
    }, [chartData, upper_limit, lower_limit, max_bound, min_bound]);
    useEffect(() => {
        // console.log(max_bound, min_bound);
        renderChart();
    }, [renderChart]);
    return (
        <div
            style={{
                height: '100%',
                paddingTop: 7,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    fontSize: 12,
                    lineHeight: '12px',
                    color: 'rgba(68, 243, 218, 1)',
                    height: 12,
                    marginLeft: 9,
                }}
            >基准值</div>
            {/* <div
                style={{
                    marginLeft: 6,
                    marginRight: 4,
                    marginTop: 7,
                    height: 1,
                    backgroundImage: 'linear-gradient(to right, rgba(68, 243, 218, 0.5) 0%, rgba(68, 243, 218, 0.5) 50%, transparent 50%)',
                    backgroundSize: '10px 1px',
                    backgroundRepeat: 'repeat-x',
                }}
            /> */}
            <div
                style={{
                    height: 0,
                    flexGrow: 1,
                }}
            >
                <div
                    ref={refNode}
                    style={{
                        height: '100%',
                    }}
                />
            </div>
        </div>
    );
};

export default TrendChart;
