const parseNode = (treeNode) => {
    if (!treeNode) return null;
    // 目前认为tag是唯一的，取tag作为key
    const { tag_name, children } = treeNode;
    const newTreeNode = Object.assign({}, treeNode, {
        key: tag_name,
        ...(
            !!children ? {
                children: children.map((child) => parseNode(child)),
            } : {}
        )
    });
    return newTreeNode;
};

const findNode = (datas, key) => {
    const node = datas
        .reduce((pV, cData) => {
            if (!!pV) return pV;
            if (key === cData.key) return cData;
            if (!!cData.children) {
                return findNode(cData.children, key);
            };
            return pV;
        }, null);
    return node;
};

const needUpdate = (info, prevInfo) => {
    if (!!info.state.selectedTab) {
        const timeItem = info.state[info.state.selectedTab.timeType];
        if (!!timeItem) {
            const prevTimeItem = prevInfo.state.selectedTab && prevInfo.state[prevInfo.state.selectedTab];
            if (timeItem !== prevTimeItem) {
                // do query;
                return true;
            };
        };
    };
    return false;
};

export {
    parseNode, findNode, needUpdate,
};
