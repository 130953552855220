import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import PIC from './res/pic.png';
import CommonContext from '../../context';
import { post } from 'common/utils';
import ICON_RATE from '../InfoCard/res/rate.png'

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const [value, setValue] = useState(null);
    const [selectedDevice, refreshTick] = useMemo(() => {
        if (!!commonInfo) {
            return [commonInfo.state.selectedDevice, commonInfo.state.refreshTick];
        };
        return [null, null];
    }, [commonInfo]);
    const queryLock = useRef(null);
    useEffect(() => {
        // console.log(selectedDevice, refreshTick);
        if (!!selectedDevice) {
            const tag_name = selectedDevice.tag_name + '_Hz';
            const currLock = (queryLock.current = Symbol());
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                tag_name_list: [tag_name],
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        const nextValue = retData.data.results[tag_name];
                        if (typeof nextValue === 'number') {
                            setValue(nextValue);
                        } else {
                            setValue(null);
                        };
                    };
                });
            return () => {
                queryLock.current = Symbol();
            };
        };
    }, [selectedDevice, refreshTick]);
    const valueText = useMemo(() => {
        if (typeof value === 'number') {
            return Math.floor(value * 100) / 100;
        } else {
            return '--';
        };
    }, [value]);
    return (
        <div className={style['wrapper']}>
            <div className="title"> <img src={ICON_RATE} />频率</div>
            <div className="value_wrapper">
                <div className="value">{valueText}</div>
                <div className="unit">Hz</div>
            </div>
            <img alt="" src={PIC} className="img" />
        </div>
    );
};

export default Comp;
