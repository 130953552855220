import React from 'react'
import Calendar from './CalendarContainer.js'
import LeftTable from './LeftTableContainer.js'
import RightBlock from './RightBlockContainer.js'
import style from './SampleMaintenance.module.scss'
const moment = require('moment')

export default class SampleMaintenance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.onresize = this.resizeCallback
    const { getLayoutConfig, onSelect } = this.props
    onSelect(moment().subtract(1, 'days'))
    getLayoutConfig().then(isSuccess => {
      // console.log(isSuccess);
      const { getHistoryData, getDataMetas, getStatus, getEvents } = this.props
      //这里需要保证date默认设置在之前，虽然暂时没什么问题，但应该有更加稳妥的做法
      getEvents()
      getStatus()
      getHistoryData()
      getDataMetas()
    })
  }

  resizeCallback = () => {
    // console.log('resizeCallback');
    this.setContentItemWidth()
  }

  contentViewRef = node => {
    if (node) {
      this.contentViewNode = node
      this.setContentItemWidth()
    }
  }

  setContentItemWidth = () => {
    if (this.contentViewNode) {
      const { contentItemWidth, updateContentWidth } = this.props
      const newContentItemWidth = this.contentViewNode.offsetWidth - 48
      if (newContentItemWidth !== contentItemWidth) {
        updateContentWidth(newContentItemWidth)
      }
    }
  }

  selectSystemOnChange = sysId => {
    const { selectSystemOnChange, onSelect } = this.props
    selectSystemOnChange(sysId)
    onSelect(moment().subtract(1, 'days'))
    const { getHistoryData, getDataMetas, getStatus, getEvents } = this.props
    //这里需要保证date默认设置在之前，虽然暂时没什么问题，但应该有更加稳妥的做法
    getEvents()
    getStatus()
    getHistoryData()
    getDataMetas()
  }

  render() {
    const { sys_id, systems, tabRect, paddingStyle, tabsStyle } = this.props
    let tabs_left = null
    if (!!tabRect) {
      if (typeof tabRect.width === 'number') {
        tabs_left = 72 + tabRect.width + 40
      }
    }
    return (
      <div className={style['sample_maintenance_wrapper']} style={paddingStyle}>
        <div
          className="tabs_wrapper"
          style={{ left: typeof tabs_left === 'number' ? tabs_left : '', ...tabsStyle }}
          //   style={typeof tabs_left === 'number' ? { left: tabs_left } : {}}
        >
          <div className="content_header">
            {systems.map(({ tab_name, sys_id: sysId }, index) => (
              <div
                key={index}
                className={sysId === sys_id ? 'selected_system' : ''}
                onClick={() => this.selectSystemOnChange(sysId)}
              >
                <div className="text">{tab_name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="sample_maintenance_content_wrapper" ref={this.contentViewRef}>
          <div className="content_body_wrapper">
            <div className="left_wrapper">
              <Calendar />
              <div style={{ height: 12 }} />
              <div className="left_bottom_wrapper">
                <LeftTable />
              </div>
            </div>
            <div className="right_wrapper">
              <RightBlock />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
