import React, { useMemo } from 'react'
import style from './index.module.scss'

const getValueText = (v, precision = 2) => {
    if (typeof v === 'number') {
        let multiNum = Math.pow(10, precision);
        if (v >= 100000) {
            multiNum = 1;
        } else if (v >= 10000) {
            if (multiNum > 10) {
                multiNum = 10;
            };
        } else if (v >= 1000) {
            if (multiNum > 100) {
                multiNum = 100;
            };
        };
        return Math.floor(v * multiNum) / multiNum;
    };
    return '--';
};

const Comp = (props) => {
    const { name, unit, values, precisions } = props;
    const valueTextList = useMemo(() => {
        const actualValues = !!values ? values : [];
        const actualPrecisions = !!precisions ? precisions : [];
        return new Array(3).fill(null).map((v, index) => getValueText(actualValues[index], typeof actualPrecisions[index] === 'number' ? actualPrecisions[index] : (index === 0 ? 3 : 1)))
    }, [values, precisions]);
    const arrowStatus = useMemo(() => {
        if (!!values) {
            const v = values[1];
            if (typeof v === 'number') {
                if (v > 0) { return 'up'; } else if (v < 0) { return 'down' };
            };
        };
        return null;
    }, [values]);
    return (
        <div className={style['wrapper']}>
            <div className="content">
                <div className="title">{name}</div>
                <div className="body">
                    <div className="value">{valueTextList[0]}</div>
                    {
                        typeof unit === 'string' && (
                            <div className="unit">{unit}</div>
                        )
                    }
                    <div className={arrowStatus === 'up' ? "arrow_up" : (arrowStatus === 'down' ? "arrow_down" : "")} style={{ marginLeft: 'auto' }}></div>
                </div>
            </div>
        </div>
    )
}

export default Comp
