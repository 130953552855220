const Extd_Tag_List = [
    '_elprcx',
    '_elchrg',
    '_elprc',
    '_kVAmx',
    '_kWRh',
    '_kWz',
];

const Cards_Info_List = [
    {
        name: '电价指数',
        unit: '',
        extd_tag: '_elprcx',
    },
    {
        name: '电费',
        unit: '万元',
        extd_tag: '_elchrg',
    },
    {
        name: '平均电价',
        unit: '元/kWh',
        extd_tag: '_elprc',
    },
    {
        name: '最大需量',
        unit: '万kVA',
        extd_tag: '_kVAmx',
    },
    {
        name: '无功电量',
        unit: '万kVarh',
        extd_tag: '_kWRh',
    },
    {
        name: '有功功率',
        unit: 'kW',
        extd_tag: '_kWz',
    },
];

const Suffix_List = (() => {
    const list = {
        a: ['HD', 'D', 'HW', 'W', 'HM', 'M'],
        b: ['HDQOQ', 'HDQOQ', 'HWQOQ', 'HWQOQ', 'HMQOQ', 'HMQOQ'],
        chart: ['D', 'D', 'W', 'W', 'M', 'M'],
    };
    return Object.assign({}, ...(Object.keys(list).map((key) => ({ [key]: list[key].map((tag) => `_${tag}`) }))));
})();

export {
    Extd_Tag_List,
    Cards_Info_List,
    Suffix_List,
};