import React from 'react';
import { Menu, Icon, Button, Modal, Radio, Slider, DatePicker, Checkbox, InputNumber, Drawer, Input, Transfer, Table } from 'antd';

// import './Content.css';

export default class TransferModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            targetKeys: [],
            prevPropsVisible: false,
        }
    }

    static getDerivedStateFromProps({ visible, dataSource, initTargetKeys = () => [] }, { prevPropsVisible }) {
        return Object.assign({ prevPropsVisible: visible }, visible && !prevPropsVisible ? { targetKeys: initTargetKeys(dataSource) } : {});
    }

    transferModalOnOk = () => {
        const { targetKeys } = this.state;
        const { onOk = () => { } } = this.props;
        onOk(targetKeys);
    }

    handleTransferChange = (nextTargetKeys, direction, moveKeys) => {
        this.setState({ targetKeys: nextTargetKeys });
    }

    render() {
        const { targetKeys } = this.state;
        const { visible, onCancel = () => { }, dataSource } = this.props;

        return (
            <Modal
                title="选择边界条件"
                centered
                visible={visible}
                onOk={this.transferModalOnOk}
                onCancel={onCancel}
                width={900}
            >
                <div>
                    <Transfer
                        titles={['运行参数', '边界条件']}
                        dataSource={dataSource}
                        targetKeys={targetKeys}
                        showSelectAll={false}
                        // selectedKeys={selectedKeys}
                        onChange={this.handleTransferChange}
                        render={item => item.title}
                        showSearch
                        operations={['加入右侧', '加入左侧']}
                        listStyle={{
                            width: 300,
                            height: 360,
                        }}
                    // disabled={disabled}
                    >
                        {({
                            // direction,
                            filteredItems,
                            onItemSelectAll,
                            onItemSelect,
                            selectedKeys,
                            // disabled: listDisabled,
                        }) => {
                            const rowSelection = {
                                onSelectAll(selected, selectedRows, changeRows) {
                                    onItemSelectAll(changeRows.map(({ key }) => key), selected);
                                },
                                onSelect({ key }, selected) {
                                    onItemSelect(key, selected);
                                },
                                selectedRowKeys: selectedKeys,
                            };
                            return (
                                <Table
                                    rowSelection={rowSelection}
                                    columns={[
                                        {
                                            dataIndex: 'tag_name',
                                            title: '点位名称',
                                        },
                                        {
                                            dataIndex: 'name',
                                            title: '点位描述',
                                        },
                                    ]}
                                    dataSource={filteredItems}
                                    size="small"
                                    onRow={({ key }) => ({
                                        onClick: () => {
                                            onItemSelect(key, !selectedKeys.includes(key));
                                        },
                                    })}
                                    pagination={false}
                                    scroll={{ y: 200 }}
                                />
                            );
                        }}
                    </Transfer>
                </div>
            </Modal>
        );
    }
}
