const TIME_RANGE_HOURS = 8
/** 紧急程度从低到高对应的颜色 */
const urgencyAndColor = { 0: '#238D7A', 1: '#AFDAD8', 2: '#6BBBDD', 3: '#6B95DD', 4: '#FEBC05' }

const debounceTime = (fn, wait) => {
  var timeout = null
  return function (e) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, wait)
  }
}

export { TIME_RANGE_HOURS, urgencyAndColor, debounceTime }
