import React, { useState } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import RangeBlock from './RangeBlock';
import RecordModal from './RecordModal';

const Comp = (props) => {
    const [recordModalList, setRecordModalList] = useState(null);

    const rangeBlockOnClick = (e, item) => {
        //单向的，只管显示
        // const { recordModalList } = this.state;
        // const existRecordIndex = recordModalList
        //     .map(({ record: { cur_value_tag } }) => cur_value_tag)
        //     .indexOf(record.cur_value_tag);
        // const newRecordModal = {
        //     record,
        //     position: [e.clientX, e.clientY],
        // };
        // if (existRecordIndex === -1) {
        //     recordModalList.push(newRecordModal);
        // } else {
        //     recordModalList[existRecordIndex] = newRecordModal;
        // };
        // this.setState({ recordModalList });
        // mock
        const position = [e.clientX, e.clientY];
        setRecordModalList((prevRecordModalList) => {
            const nextRecordModalList = []
                .concat(
                    prevRecordModalList ?? [],
                    [
                        {
                            key: Symbol(),
                            info: item,
                            position,
                        },
                    ],
                );
            return nextRecordModalList;
        });
    };

    const removeRecordModal = (remove_key) => {
        setRecordModalList((prevRecordModalList) => {
            const nextRecordModalList = prevRecordModalList.filter(({ key }) => key !== remove_key);
            return nextRecordModalList;
        });
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="对标"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <div className="top">
                        <div className="title">姓名：</div>
                        <div className="value">张三</div>
                        <div className="title">本班平均得分：</div>
                        <div className="value">105.4</div>
                        <div className="title">本月排名：</div>
                        <div className="value">3</div>
                    </div>
                    <div className="bottom">
                        <div className="scroll_wrapper scroll_bar_restyle">
                            <table>
                                <thead>
                                    <tr>
                                        <th>参数名称</th>
                                        <th>实际值</th>
                                        <th>推荐范围</th>
                                        <th>参数对标</th>
                                        {/* <th>得分</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        new Array(30)
                                            .fill(null)
                                            .map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="name">一次风机A频率反馈</td>
                                                        <td className="value">32.47Hz</td>
                                                        <td>32.3~32.52</td>
                                                        <td className="bar" onClick={(e) => rangeBlockOnClick(e, item)}>
                                                            <div className="wrapper">
                                                                <RangeBlock value={2.5} zone_cut_point={[1, 2, 3, 4]} zone_color={[1, 2, 3]} />
                                                            </div>
                                                        </td>
                                                        {/* <td>60</td> */}
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        (recordModalList ?? [])
                            .map(({ info, position, key }, index) => {
                                return (
                                    <RecordModal
                                        // info={info}
                                        initialPosition={position}
                                        key={index}
                                        onCancel={() => removeRecordModal(key)}
                                    />
                                );
                            })
                    }
                </div>
            </Wrapper>
        </div>
    );
};

export default Comp;
