import React from 'react';
import { Select } from 'antd';
import PanelWrapper from '../Common/PanelWrapper.js';

import ICON_TITLE from './res/icon_1.png';
import ICON_2 from './res/icon_2.png';

import './SystemElectricalStatistic.scss';

const { Option } = Select;

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {

        return (
            <div className="system_electrical_statistic_wrapper scroll_bar_restyle">
                <PanelWrapper
                    icon={ICON_TITLE}
                    title="系统电量统计"
                >
                    <div
                        style={{
                            backgroundColor: "rgba(25, 31, 39, 1)",
                            borderRadius: 2,
                        }}
                    >
                        <div
                            style={{
                                paddingTop: 12,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img alt="" src={ICON_2} style={{ width: 2, height: 16 }} />
                            <div style={{ marginLeft: 8, fontSize: 14, color: 'rgba(159, 160, 186, 1)', lineHeight: '20px', height: 20 }}>比较时段</div>
                            <Select defaultValue="jack" style={{ width: 160, marginLeft: 8 }}>
                                <Option value="jack">前一周</Option>
                                <Option value="lucy">前一月</Option>
                                <Option value="Yiminghe">前三月</Option>
                            </Select>
                        </div>
                        <div style={{ padding: "24px 16px 16px 18px" }}>
                            <div style={{ height: 320, display: 'flex' }}>
                                <div style={{ width: 280, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    {
                                        new Array(3)
                                            .fill(0)
                                            .map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            height: 96,
                                                            backgroundColor: 'rgba(21, 25, 30, 1)',
                                                            borderRadius: 2,
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, background: 'linear-gradient(90deg, #44F3DA 0%, rgba(68, 243, 218, 0) 100%)', opacity: 0.15 }} />
                                                        <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: 2, backgroundColor: 'rgba(68, 243, 218, 1)', borderRadius: '100px 0 0 100px' }} />
                                                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                {
                                                                    index === 2 && (
                                                                        <div style={{ alignSelf: "center", marginRight: 7, width: 0, height: 0, borderStyle: 'solid', borderWidth: '7px 5px 0 5px', borderColor: "rgba(68, 243, 218, 1) transparent transparent transparent" }} />
                                                                    )
                                                                }
                                                                <div style={{ fontSize: 24, lineHeight: '24px', height: 24, color: 'rgba(255, 255, 255, 1)' }}>{index === 2 ? 3.45 : 0.6678}</div>
                                                                <div style={{ marginLeft: 4, height: 14, fontSize: 14, lineHeight: '14px', color: 'rgba(255, 255, 255, 0.5)' }}>{index === 2 ? "%" : "元/kwh"}</div>
                                                            </div>
                                                            <div style={{ marginTop: 12, lineHeight: '14px', fontSize: 14, height: 14, color: 'rgba(140, 143, 147, 1)' }}>{index === 2 ? "环比" : "当前时段平均电费"}</div>
                                                        </div>

                                                    </div>
                                                );
                                            })
                                    }
                                </div>
                                <div style={{ width: 0, flexGrow: 1 }}></div>
                            </div>
                        </div>
                    </div>
                </PanelWrapper>
            </div>
        );
    }
}
