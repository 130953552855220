import React, { useState } from 'react'
import { Button } from 'antd'
import moment from 'moment'
import style from './index.module.scss'
import { withRouter } from 'react-router'
import TimeLine from '../components/TimeLine'
import Events from '../components/Events'
import AddTemplate from '../../../../CapsuleBoard/AddCapsule'
import TempalateSelector from '../components/TemplateSelector'
import EditAndViewCapsuleModal from '../components/Dialogs/EditAndViewCapsuleModal'
import { getCurrentTime } from '../../utils'

const EventPanel = withRouter(props => {
  const { history } = props
  const [endTime, setEndTime] = useState(moment(getCurrentTime()).add(30, 'seconds')) // 时间轴的末尾时间 新建胶囊时需要执行setEndTime 其时间发生时间作为新的时间轴结束时间
  const [innerRangeEvents, setInnerRangeEvents] = useState([]) // 时间轴起始时间范围内的事件 events列表刷新时 需要执行setInnerRangeEvents
  const [innerViewTimeRange, setInnerViewTimeRange] = useState({}) // 事件面板可视范围内的事件发生时间的起始范围 滚动事件面板时，需要执行 setInnerViewTimeRange
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [tplParams, setTplParams] = useState({ tabIndex: 0 })

  const [eventId, setEventId] = useState(null) // 胶囊/事件id
  const [isEdit, setIsEdit] = useState(false) // true 表示打开即编辑状态
  const [refreshNum, setRefreshNum] = useState(0) // 用于编辑胶囊之后触发刷新
  const showModal = tabIndex => {
    tabIndex && setTplParams({ ...tplParams, tabIndex })
    setIsModalVisible(true)
  }

  /** 关闭弹窗 */
  const closeModal = (event, isRefreshEventList) => {
    setTplParams({ tabIndex: 0 })
    setEventId(null)
    setIsEdit(false)
    setIsModalVisible(false)
    if (isRefreshEventList) {
      // TODO: 刷新事件列表
      if (event.type === 'add') {
        // 消息推送取代了主动刷新
        // setEndTime(moment(event.date))
      } else {
        setRefreshNum(refreshNum + 1)
      }
    }
  }

  /** 新建指定模版类型事件 */
  const addCapsuleOnClick = (id, name, sn, tabIndex = 0) => {
    setTplParams({ tpl_id: id, tpl_name: name, tpl_sn: sn, tabIndex })
    showModal()
  }

  /** 编辑事件 */
  const editEventOnClick = (id, isEdit) => {
    if (isEdit === true) {
      setIsEdit(true)
    }
    setEventId(id)
    setIsModalVisible(true)
  }

  return (
    <div className={style['EventPanel']}>
      <div className="header-bar">
        <div className="title">事件</div>
        <div style={{ display: 'flex' }}>
          <Button type="primary" onClick={() => history.push('/PEIMSV2/EventSummary')}>
            <i className="search-icon" />
            列表查询
          </Button>
          <Button type="primary" style={{ marginLeft: 20 }} onClick={showModal}>
            + 新增
          </Button>
        </div>
      </div>
      <div className="center">
        <div className="time-line">
          <TimeLine
            endTime={endTime}
            setEndTime={setEndTime}
            innerRangeEvents={innerRangeEvents}
            innerViewTimeRange={innerViewTimeRange}
          />
        </div>
        <div className="event-container">
          <Events
            endTime={endTime}
            refreshNum={refreshNum}
            setEndTime={setEndTime}
            setInnerRangeEvents={setInnerRangeEvents}
            setInnerViewTimeRange={setInnerViewTimeRange}
            editEventOnClick={editEventOnClick}
          />
        </div>
      </div>
      <div className="footer-bar">
        <TempalateSelector
          refreshNum={refreshNum}
          showAddModal={showModal}
          addCapsuleOnClick={addCapsuleOnClick}
          editEventOnClick={editEventOnClick}
        />
      </div>
      {/* {isModalVisible && (
        <AddTemplate
          capsuleId={eventId}
          isEdit={isEdit}
          tpl_id={tpl_id}
          tpl_name={tpl_name}
          refreshEvents={() => setRefreshNum(refreshNum + 1)}
          onOk={e => closeModal(e, true)}
          onCancel={closeModal}
        />
      )} */}
      {isModalVisible && (
        <EditAndViewCapsuleModal
          visible={isModalVisible}
          capsuleId={eventId}
          isEdit={isEdit}
          tplParams={tplParams}
          refreshEvents={() => setRefreshNum(refreshNum + 1)}
          onOk={e => closeModal(e, true)}
          onCancel={closeModal}
        />
      )}
    </div>
  )
})

export default EventPanel
