import React from 'react'
import ExamInfoBar from './ExamInfoBar';
import LeftViewer from './LeftViewer';
import RightViewer from './RightViewer';
import style from './ExamAnalysis.module.scss'
import { Spin } from 'antd';
import moment from 'moment';
import { post } from '../../../common/utils';

export default class ExamAnalysis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeInfo: null,
      list: null,
      loading: false,
      selectedItem: null,
      rightData: null,
    };
  }

  componentDidMount() {
  }

  timeOnChange = (timeInfo) => {
    this.setState({ timeInfo }, this.getData);
  };

  getData = () => {
    const { timeInfo } = this.state;
    if (!!timeInfo) {
      const { time, range } = timeInfo;
      // console.log(timeInfo);
      const day_begin = moment(time).startOf(range).format("YYYY-MM-DD");
      const day_end = moment(time).endOf(range).format("YYYY-MM-DD");
      this.setState({ loading: true });
      post(process.env.REACT_APP_SERVER_PATH + "api/exam/get_scores_per_shift_and_system", {
        day_begin,
        day_end,
      })
        .then((retData) => {
          this.setState({ loading: false });
          // console.log(retData);
          if (retData?.data?.errcode === 0) {
            let selectedItem = null;
            const nextList = retData?.data?.results ?? null;
            if (!!nextList) {
              nextList
                .some((item) => {
                  if (!!item?.shift_list?.[0]) {
                    selectedItem = Object.assign({}, item, item?.shift_list?.[0]);
                    return true;
                  };
                  return false;
                });
            };
            this.setState({
              selectedItem,
              list: nextList,
            }, this.getRightData);
          };
        });
    };
  };

  onSelect = (info) => {
    this.setState({ selectedItem: info }, this.getRightData);
  };

  getRightData = () => {
    const { timeInfo, selectedItem } = this.state;
    if (!!timeInfo && !!selectedItem) {
      const { time, range } = timeInfo;
      const day_begin = moment(time).startOf(range).format("YYYY-MM-DD");
      const day_end = moment(time).endOf(range).format("YYYY-MM-DD");
      const { sys_id, shift_id } = selectedItem;
      this.setState({ loading: true });
      post(process.env.REACT_APP_SERVER_PATH + "api/exam/get_shift_scores_per_rule", {
        day_begin,
        day_end,
        sys_id,
        shift_id,
      })
        .then((retData) => {
          this.setState({ loading: false });
          // console.log(retData);
          if (retData?.data?.errcode === 0) {
            let nextRightData = retData?.data?.results ?? null;
            if (!!nextRightData) {
              nextRightData = nextRightData.map((item, index) => Object.assign({}, item, { key: `${index}` }));
            };
            this.setState({ rightData: nextRightData });
          } else {
            this.setState({ rightData: null });
          };
        });
    } else {
      this.setState({ rightData: null });
    };
  };

  render() {
    const { list, loading, selectedItem, rightData, timeInfo } = this.state;
    return (
      <div className={style['exam_analysis_root_wrapper']}>
        <ExamInfoBar timeOnChange={this.timeOnChange} />
        {/*------------------------------------ */}
        <div className="root_content_bck_wrapper">
          <div className="root_content">
            <div className="left_content">
              {/* 左侧视图 */}
              <LeftViewer data={list} selectedItem={selectedItem} onSelect={this.onSelect} />
              {/*------------------------------------ */}
            </div>
            <div className="right_content">
              {/* 右侧视图 */}
              <RightViewer data={rightData} timeInfo={timeInfo} />
              {/*------------------------------------ */}
            </div>
          </div>
        </div>
        {
          loading === true && (
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
              <Spin />
            </div>
          )
        }
      </div>
    )
  }
}
