import React from 'react';
import style from './index.module.scss';

const Comp = (props) => {
    return (
        <div className={style['wrapper'] + " scroll_bar_restyle"}>
            <div className="content">
                {
                    (props?.info ?? [])
                        .map((item, index) => {
                            const { fullName, weight } = item;
                            return (
                                <div className="item" key={index}>
                                    <div className="left">{fullName}</div>
                                    <div className="right">{weight ?? "--"}</div>
                                </div>
                            );
                        })
                }
            </div>
        </div>
    );
};

export default Comp;
