import React, { useRef, useState, useEffect, useMemo } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import Degree from './Degree';
import ICON_FACTOR from './res/factor_icon.png';
import IMG_NO_DATA from './res/no-data.svg';
import { useData } from './hooks';
import { Spin, Button } from 'antd';
import SortIcons from 'components/SortIcons';

const default_func = () => { };

const Comp = (props) => {
    const { selectedAbnormal, id, detail } = props;

    const [topHeadScroll, setTopHeadScroll] = useState(false);
    const [topHeadScroll2, setTopHeadScroll2] = useState(false);

    const onScroll = (e) => {
        const scrollTop = e?.target?.scrollTop;
        const offset_height_1 = table_1?.current?.offsetHeight;
        if (typeof scrollTop !== 'number' || typeof offset_height_1 !== 'number') return;

        setTopHeadScroll(scrollTop !== 0 && scrollTop < offset_height_1);
        setTopHeadScroll2(scrollTop !== 0 && scrollTop > offset_height_1)
    };

    const table_1 = useRef(null);

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(default_func);
    useEffect(() => {
        setDataFunc.current = typeof setData === 'function' ? setData : default_func;
    }, [setData]);
    useEffect(() => {
        // console.log(selectedAbnormal);
        setDataFunc.current({});
    }, [selectedAbnormal, id]);

    const [sortInfo, setSortInfo] = useState(null);
    const updateSortInfo = (key) => {
        setSortInfo((prevSortInfo) => {
            let nextIsDesc = false;
            if (prevSortInfo?.key === key) {
                nextIsDesc = !prevSortInfo.is_desc;
            };
            return {
                key,
                is_desc: nextIsDesc,
            };
        });
    };

    const sorted_list = useMemo(() => {
        const list = data?.related_factor_info?.parsed_data ?? null;
        if (!!list) {
            let ret_list = list
                .map((item) => {
                    let parsed_children = item?.children ?? null;
                    if (!!parsed_children) {
                        if (!!sortInfo) {
                            const { key, is_desc } = sortInfo;
                            parsed_children = []
                                .concat(parsed_children)
                                .sort(({ [key]: v1 }, { [key]: v2 }) => {
                                    const v_1 = is_desc ? v1 : v2;
                                    const v_2 = is_desc ? v2 : v1;
                                    if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                                        return v_2 - v_1;
                                    };
                                    if (typeof v1 === 'number') return -1;
                                    if (typeof v2 === 'number') return 1;
                                    return 0;
                                    // 1是交换
                                });
                        };
                    };
                    return Object.assign({}, item ?? {}, { parsed_children });
                });
            return ret_list;
        };
        return null;
    }, [data, sortInfo]);

    const startAnalysis = () => {
        setDataFunc.current(
            Object.assign({}, selectedAbnormal ?? {}, {
                base_id: id,
            }));
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="异常分析"
                watchDetail={false}
                subtitle={(
                    <div className={style["subtitle"]}>
                        {
                            !!selectedAbnormal && (
                                <div className="info">
                                    <div className="title">分析时段：</div>
                                    <div className="value">{selectedAbnormal?.an_range_text ?? ""}</div>
                                    <div className="title">对标时段：</div>
                                    <div className="value">{selectedAbnormal?.cp_range_text ?? ""}</div>
                                </div>
                            )
                        }
                        <Button type="primary" className="btn" onClick={startAnalysis} disabled={!(detail?.detail?.casual_rel === true && !!selectedAbnormal)}>开始分析</Button>
                    </div>
                )}
            >
                <div className={style["content"]}>
                    {
                        !!data ? (
                            <div className="scroll_wrapper scroll_bar_restyle" onScroll={onScroll}>
                                <table className="factor" ref={table_1}>
                                    <thead>
                                        <tr className={topHeadScroll ? "head_scroll" : ""}>
                                            <th className="head">影响因子</th>
                                            <th className="name" colSpan="3">参数名称</th>
                                            <th>单位</th>
                                            <th className="text_2_lines">指标均值<br />(异常时段)</th>
                                            <th className="text_2_lines">指标均值<br />(分析时段)</th>
                                            <th className="text_2_lines">指标均值<br />(对标时段)</th>
                                            <th className="sort">
                                                <div className="wrapper" onClick={() => updateSortInfo("volatility")}>
                                                    <div className="content">
                                                        <div className="text">指标变动率</div>
                                                        <SortIcons is_desc={sortInfo?.key === "volatility" ? sortInfo?.is_desc : null} />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="degree sort">
                                                <div className="wrapper" onClick={() => updateSortInfo("effect")}>
                                                    <div className="content">
                                                        <div className="text">指标影响程度</div>
                                                        <SortIcons is_desc={sortInfo?.key === "effect" ? sortInfo?.is_desc : null} />
                                                    </div>
                                                </div>
                                            </th>
                                            {/* <th>负责人</th> */}
                                            <th>操作</th>
                                            <th className="end"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (sorted_list ?? [])
                                                .map((data, index) => {
                                                    const { parsed_children } = data;
                                                    const datas = [data]
                                                        .concat(parsed_children ?? []);
                                                    return datas
                                                        .map((item, item_index) => {
                                                            const {
                                                                name, unit, parsed_volatility, effect,
                                                                // user_name,
                                                                parsed_abnormal_avg, parsed_analysis_avg, parsed_compare_avg,
                                                            } = item;
                                                            return (
                                                                <tr key={`${index}-${item_index}`} className={item_index === 0 ? "level_1" : "level_2"}>
                                                                    <td></td>
                                                                    {
                                                                        item_index === 0 ? (
                                                                            <td className="icon">
                                                                                <div className="icon_wrapper">
                                                                                    <img alt="" src={ICON_FACTOR} className="icon" />
                                                                                </div>
                                                                            </td>
                                                                        ) : ([
                                                                            (
                                                                                <td key="1"></td>
                                                                            ),
                                                                            // 此处children必然存在[0]
                                                                            parsed_children[0] === item ?
                                                                                (
                                                                                    <td key="2" className="vertical_line" rowSpan={parsed_children.length}></td>
                                                                                ) : null,
                                                                        ])
                                                                    }
                                                                    <td
                                                                        {
                                                                        ...(item_index === 0 ? { colSpan: "2" } : {})
                                                                        }
                                                                        className="name"
                                                                    >{name}</td>
                                                                    <td>{unit}</td>
                                                                    <td>{parsed_abnormal_avg}</td>
                                                                    <td>{parsed_analysis_avg}</td>
                                                                    <td>{parsed_compare_avg}</td>
                                                                    <td>{parsed_volatility}</td>
                                                                    <td>
                                                                        <Degree degree={effect} />
                                                                    </td>
                                                                    {/* <td>{user_name}</td> */}
                                                                    <td className="action">
                                                                        <div className="btn">查看</div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        });
                                                })
                                        }
                                        <tr className="bottom">
                                            <td></td>
                                            {/* 此处10与上面定义column列数相关，如果修改需要注意同步 */}
                                            <td colSpan="11" className="line"></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="event">
                                    <thead>
                                        <tr className={topHeadScroll2 ? "head_scroll" : ""}>
                                            <th className="head">运行事件</th>
                                            <th>时间</th>
                                            <th>事件名称</th>
                                            <th className="detail">事件详情</th>
                                            <th>记录人</th>
                                            <th>操作</th>
                                            <th className="end"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (data?.events ?? [])
                                                .map((item, index) => {
                                                    const { parsed_time, title, content, user_name } = item;
                                                    return []
                                                        .concat(
                                                            index > 0 ? [
                                                                (
                                                                    <tr key={`space-${index}`} className="space">
                                                                    </tr>
                                                                ),
                                                            ] : [],
                                                            [
                                                                (
                                                                    <tr key={index} className={"data" + (index % 2 === 0 ? " odd" : "")}>
                                                                        <td className="blank"></td>
                                                                        <td className="content_first">{parsed_time}</td>
                                                                        <td>{title}</td>
                                                                        <td className="detail">{content}</td>
                                                                        <td>{user_name}</td>
                                                                        <td className="content_last action">
                                                                            <div className="btn">查看</div>
                                                                        </td>
                                                                    </tr>
                                                                ),
                                                            ],
                                                        );
                                                })
                                        }
                                    </tbody>
                                </table>
                                <div className="space"></div>
                            </div>
                        ) : (
                            <div className="no_data_img_wrapper">
                                <img alt="" src={IMG_NO_DATA} className="img" />
                                <div className="text">暂无数据</div>
                            </div>
                        )
                    }
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
