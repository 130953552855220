import React from 'react';
import COAL_ICON from './res/coal.svg';
import ELEC_ICON from './res/elec.svg';
import TREND_UP_ICON from './res/trend_up.svg';
import TREND_DOWN_ICON from './res/trend_down.svg';
import {
    valueFormat,
} from './utils.js';

import style from './BoardTop.module.scss';

const ICONS_NAME2ICON = {
    icon_coal: COAL_ICON,
    icon_elec: ELEC_ICON,
};
export default class BoardTop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    getDataNodes = (x, y) => {
        const { tag2Value, eco_result } = this.props;
        // console.log(x, y, tag2Value[x], eco_result);
        if (typeof tag2Value[x] !== 'number' || tag2Value[x] === 0 || typeof eco_result[y] !== 'number') {
            return (
                <div className="value">--</div>
            );
        } else {
            const value = Math.floor((eco_result[y] - tag2Value[x]) / tag2Value[x] * 10000) / 100;
            return (
                value === 0 ?
                    [] : [
                        <img className="arrow" key="sign" src={value > 0 ? TREND_DOWN_ICON : TREND_UP_ICON}></img> 
                    ]
            )
                .concat(
                    [
                        <div className="value" key="value">{Math.abs(value)}</div>,
                    ],
                );
        };
    }

    render() {
        const { panel_1, tag2Value } = this.props;
        return (
            <div
                className={style["board_top_wrapper"]}
            >
                {
                    panel_1
                        .map((row, index) => {
                            const { icon, name_1, name_2, name_3, unit_1, tag_1, tag_2_x, tag_2_y, tag_3_x, tag_3_y } = row;
                            return (
                                <div className="board_row" key={index}>
                                    <div className="icon_wrapper">
                                        <img alt="" src={ICONS_NAME2ICON[icon]} width="26" />
                                    </div>
                                    <div className="item item_1">
                                        <div className="title">{name_1}</div>
                                        <div className="value_wrapper">
                                            <div className="value">{valueFormat(tag2Value[tag_1], 1)}</div>
                                            <div className="unit">{unit_1}</div>
                                        </div>
                                    </div>
                                    <div className="item item_2">
                                        <div className="title">{name_2}</div>
                                        <div className="value_wrapper">
                                            {/* <div className="trend_icon_wrapper">
                                                <img alt="" src={TREND_UP_ICON} />
                                            </div> */}
                                            {this.getDataNodes(tag_2_x, tag_2_y)}
                                            <div className="unit">%</div>
                                        </div>
                                    </div>
                                    <div className="item item_3">
                                        <div className="title">{name_3}</div>
                                        <div className="value_wrapper">
                                            {/* <div className="trend_icon_wrapper">
                                                <img alt="" src={TREND_UP_ICON} />
                                            </div> */}
                                            {this.getDataNodes(tag_3_x, tag_3_y)}
                                            <div className="unit">%</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                }
            </div>
        );
    }
}
